import {
	Autocomplete,
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import generals from '../../../../../../css/Generals.module.css';
import { addCommas, removeCommas } from '../../../../../../utils/format';
import {
	descripcionServicioData,
	objetoDeImpuestoData,
	productoData,
	unidadData
} from '../constant/conceptData';
import classes from '../css/FacturaModal.module.css';


const FacturaModalNewConceptForm = ({
	facturaContact,
	setFacturaContact,
	conceptoSubmitted,
	cleanErr
}) => {
	const inputLabelStyles = {
		top: 14,
		left: 12.5,
		color: '#57585AD2',
		opacity: 0.4,
	};

	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [inputValue, setInputValue] = useState('');

	const handleFieldChange = (key, value, numeric) => {
		if (numeric) {
			let numericText = value.replace(/\D/g, '');
			const dotPosition = numericText.length - 1;
			if (numericText.length > 2) {
				numericText =
					numericText.substring(0, dotPosition - 1) +
					'.' +
					numericText.substring(dotPosition - 1);
			}

			if (numericText.length > 5) {
				numericText = addCommas(numericText);
			}
			if (key === 'importe') {
				setFacturaContact((prevFields) => ({
					...prevFields,
					total: numericText,
					concepto: { ...prevFields.concepto, [key]: numericText },
				}));
			} else {
				setFacturaContact((prevFields) => ({
					...prevFields,
					concepto: { ...prevFields.concepto, [key]: numericText },
				}));
			}

		} else {
			setFacturaContact((prevFields) => ({
				...prevFields,
				concepto: { ...prevFields.concepto, [key]: value },
			}));
		}
		cleanErr();
	};

	useEffect(() => {
		const { cantidad, precioUnitario } = facturaContact?.concepto;
		if (cantidad && precioUnitario) {
			const newImporte = (
				parseFloat(removeCommas(cantidad)) *
				parseFloat(removeCommas(precioUnitario))
			).toFixed(2);

			setFacturaContact((prevFields) => ({
				...prevFields,
				concepto: { ...prevFields.concepto, importe: addCommas(newImporte) },
				total: addCommas(newImporte),
			}));
		}
	}, [
		facturaContact?.concepto?.cantidad,
		facturaContact?.concepto?.precioUnitario,
		setFacturaContact,
	]);

	useEffect(() => {
		if (open) {
			setLoading(true);
			const initialOptions = productoData.slice(0, 100);
			setOptions(initialOptions);
			setLoading(false);
		}
	}, [open, productoData]);

	useEffect(() => {
		if (inputValue === '') {
			setOptions(productoData.slice(0, 100)); // Reset to first 100 options if input is cleared
			return;
		}

		setLoading(true);
		const filteredOptions = productoData.filter(option =>
			option.label.toLowerCase().includes(inputValue.toLowerCase())
		);
		setOptions(filteredOptions.slice(0, 100)); // Show first 100 filtered options
		setLoading(false);
	}, [inputValue, productoData]);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};


	return (
		<div className={classes.modal_form}>
			<div className={classes.modal_form_wrapper_column}>
				<div className={classes.modal_form_column}>
					<h2 className={generals.text_input_subtitle}>Producto</h2>
					<FormControl>
						<Autocomplete
							className={
								generals.autocomplete_input
							}
							open={open}
							options={options}
							loading={loading}
							onOpen={handleOpen}
							onClose={handleClose}
							value={facturaContact?.concepto?.producto || null}
							onInputChange={(event, newInputValue) => {
								setInputValue(newInputValue);
							}}
							isOptionEqualToValue={(option, value) => option?.label === value?.label}
							renderInput={(params) => (
								<TextField
									sx={{ width: 400, height: 65 }}
									className={
										conceptoSubmitted && !facturaContact?.concepto?.producto
											? `${generals.select_input} ${generals.select_input_error}`
											: generals.select_input
									}
									{...params}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{loading ? <CircularProgress color="inherit" size={20} /> : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
								/>

							)}
							onChange={(e, newValue) => {
								handleFieldChange('producto', (newValue?.label || ''), false)
								handleFieldChange('codigoProducto', (newValue?.code || ''), false)
							}}
						/>
					</FormControl>
					<h2 className={generals.text_input_subtitle}>Precio unitario</h2>
					<TextField
						required
						sx={{ width: 400 }}
						className={
							conceptoSubmitted &&
								!facturaContact?.concepto?.precioUnitario
								? `${generals.text_input} ${generals.text_input_error}`
								: generals.text_input
						}
						id='importe'
						variant='outlined'
						placeholder='Precio unitario'
						value={facturaContact?.concepto?.precioUnitario || ''}
						onChange={(e) => {
							handleFieldChange('precioUnitario', e.target.value, true)
						}}
					/>
					<h2 className={generals.text_input_subtitle}>Descripción detallada</h2>
					<FormControl>
						<InputLabel htmlFor='descripcion-detallada' sx={inputLabelStyles}>
							Descripción detallada
						</InputLabel>
						<Select
							id='descripcion-detallada'
							sx={{ width: 400, height: 65 }}
							className={
								conceptoSubmitted &&
									!facturaContact?.concepto?.descripciondetallada
									? `${generals.select_input} ${generals.select_input_error}`
									: generals.select_input
							}
							value={facturaContact?.concepto?.descripciondetallada || ''}
							onChange={(e) => {
								handleFieldChange('descripciondetallada', e.target.value, false)
							}}
						>
							{descripcionServicioData.map((x) => (
								<MenuItem key={x.label} value={x.label}>
									{x.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				<div className={classes.modal_form_column}>
					<h2 className={generals.text_input_subtitle}>Unidad</h2>
					<FormControl>
						<InputLabel htmlFor='unidad' sx={inputLabelStyles}>
							Unidad
						</InputLabel>
						<Select
							id='unidad'
							sx={{ width: 400, height: 65 }}
							className={
								conceptoSubmitted && !facturaContact?.concepto?.unidad
									? `${generals.select_input} ${generals.select_input_error}`
									: generals.select_input
							}
							renderValue={(value) => value.label}
							value={facturaContact?.concepto?.unidad || ''}
							onChange={(e) => {
								handleFieldChange('unidad', e.target.value, false)
							}}
						>
							{unidadData.map((x) => (
								<MenuItem key={x.label} value={x}>
									{x.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<h2 className={generals.text_input_subtitle}>Cantidad</h2>
					<TextField
						required
						sx={{ width: 400 }}
						className={
							conceptoSubmitted && !facturaContact?.concepto?.cantidad
								? `${generals.text_input} ${generals.text_input_error}`
								: generals.text_input
						}
						id='cantidad'
						variant='outlined'
						placeholder='Cantidad'
						value={facturaContact?.concepto?.cantidad || ''}
						onChange={(e) => {
							handleFieldChange('cantidad', e.target.value, true)
						}}
					/>
					<h2 className={generals.text_input_subtitle}>Objeto de impuesto</h2>
					<FormControl>
						<InputLabel htmlFor='unidad' sx={inputLabelStyles}>
							Objeto de impuesto
						</InputLabel>
						<Select
							id='objeto-de-impuesto'
							sx={{ width: 400, height: 65 }}
							className={
								conceptoSubmitted &&
									!facturaContact?.concepto?.objetoDeImpuesto
									? `${generals.select_input} ${generals.select_input_error}`
									: generals.select_input
							}
							defaultValue={facturaContact?.concepto?.objetoDeImpuesto || ''}
							value={facturaContact?.concepto?.objetoDeImpuesto || ''}
							renderValue={(value) => value.label}
							onChange={(e) => {
								handleFieldChange('objetoDeImpuesto', e.target.value, false);
							}}
						>
							{objetoDeImpuestoData.map((x) => (
								<MenuItem key={x.label} value={x}>
									{x.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<h2 className={generals.text_input_subtitle}>Importe</h2>
					<TextField
						required
						sx={{ width: 400 }}
						className={
							conceptoSubmitted && !facturaContact?.concepto?.importe
								? `${generals.text_input} ${generals.text_input_error}`
								: generals.text_input
						}
						id='importe'
						variant='outlined'
						placeholder='0.00'
						value={facturaContact?.concepto?.importe || ''}
						onChange={(e) => {
							handleFieldChange('importe', e.target.value, true)
						}}
					/>
				</div>
			</div>
		</div>
	);
};
export default FacturaModalNewConceptForm;
