export const regimenFiscalData = [
	{
		label: '606 - Arrendamiento',
		value: '606 - Arrendamiento',
		category: 'regimenFiscal',
	},
	{
		label: '607 - Régimen de Enajenación o Adquisición de Bienes',
		value: '607 - Régimen de Enajenación o Adquisición de Bienes',
		category: 'regimenFiscal',
	},
	{
		label: '608 - Demás ingresos',
		value: '608 - Demás ingresos',
		category: 'regimenFiscal',
	},
	{
		label: '609 - Consolidación',
		value: '609 - Consolidación',
		category: 'regimenFiscal',
	},
	{
		label:
			'610 - Residentes en el Extranjero sin Establecimiento Permanente en México',
		value:
			'610 - Residentes en el Extranjero sin Establecimiento Permanente en México',
		category: 'regimenFiscal',
	},
	{
		label: '611 - Ingresos por Dividendos (socios y accionistas)',
		value: '611 - Ingresos por Dividendos (socios y accionistas)',
		category: 'regimenFiscal',
	},
	{
		label:
			'612 - Personas Físicas con Actividades Empresariales y Profesionales',
		value:
			'612 - Personas Físicas con Actividades Empresariales y Profesionales',
		category: 'regimenFiscal',
	},
	{
		label: '614 - Ingresos por intereses',
		value: '614 - Ingresos por intereses',
		category: 'regimenFiscal',
	},
	{
		label: '615 - Régimen de los ingresos por obtención de premios',
		value: '615 - Régimen de los ingresos por obtención de premios',
		category: 'regimenFiscal',
	},
	{
		label: '616 - Sin obligaciones fiscales',
		value: '616 - Sin obligaciones fiscales',
		category: 'regimenFiscal',
	},
	{
		label: '621 - Incorporación Fiscal',
		value: '621 - Incorporación Fiscal',
		category: 'regimenFiscal',
	},
	{
		label: '622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
		value: '622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
		category: 'regimenFiscal',
	},
	{
		label:
			'625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
		value:
			'625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
		category: 'regimenFiscal',
	},
	{
		label: '626 - Régimen Simplificado de Confianza',
		value: '626 - Régimen Simplificado de Confianza',
		category: 'regimenFiscal',
	},
	{
		label:
			'629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales',
		value:
			'629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales',
		category: 'regimenFiscal',
	},
	{
		label: '630 - Enajenación de acciones en bolsa de valores',
		value: '630 - Enajenación de acciones en bolsa de valores',
		category: 'regimenFiscal',
	},
];

export const firstColumn = [
	{
		title: 'Nombre completo',
		type: 'text',
		fieldValue: 'name',
		fieldUserValue: 'userFullName',
	},
	{
		title: 'Correo electrónico',
		type: 'text',
		fieldValue: 'email',
		fieldUserValue: 'userEmail',
	},
	{
		title: 'Contraseña CIEC',
		type: 'text',
		fieldValue: 'clave_ciec',
		fieldUserValue: 'userCIEC',
		tooltip: true,
	},
	{
		title: 'Regimen fiscal',
		type: 'text',
		fieldValue: 'regimenFiscal',
		fieldUserValue: 'userRegimenFiscal',
		dropdown: true,
		options: regimenFiscalData,
	},
];

export const secondColumn = [
	{
		title: 'Número celular',
		type: 'text',
		fieldValue: 'phone',
		fieldUserValue: 'userPhone',
	},
	{
		title: 'RFC',
		type: 'text',
		fieldValue: 'rfc',
		fieldUserValue: 'userRFC',
		tooltip: true,
	},
	{
		title: 'Contraseña de E firma',
		type: 'text',
		fieldValue: 'key',
		fieldUserValue: 'userEfirma',
		tooltip: true,
	},
	{
		title: 'Código postal fiscal',
		type: 'text',
		fieldValue: 'zipCode',
		fieldUserValue: 'userZipCode',
	},
];
