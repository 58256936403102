import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { default as React, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatMexicoTz } from '../../../../../../utils/format';
import { getUserFiles } from '../../../../../api/userfiles';
import classes from '../css/DetailItem.module.css';
import { roundToTwoDecimals } from '../utils/utils';
import DetailDeclaracionesModal from './DetailDeclaracionesModal';

const DetailDeclaraciones = ({ userData, currentUser, setRefetch }) => {
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const [modalOpen, setModalOpen] = useState(false);

	const handleModalOpen = () => setModalOpen(true);

	const retrieveFiles = async () => {
		const value =
			currentUser.rfc +
			'_DECLARACIÓN_' +
			userData.declarationMonth +
			userData.declarationYear +
			'.pdf';
		const userFile = await getUserFiles(TOKEN, value);
		window.open(userFile.data);
	};

	const getStatusClassName = (status) => {
		switch (status) {
			case 'vigente':
				return `${classes.detail_status}`;
			case 'expirado':
				return `${classes.detail_status} ${classes.pending}`;
			case 'pagado':
				return `${classes.detail_status} ${classes.approved}`;
			default:
				return `${classes.detail_status} ${classes.no_status}`;
		}
	};

	return (
		<>
			<div className={`cursor-pointer ${classes.detail_wrapper}`} onClick={handleModalOpen}>
				<h2 className={classes.detail_element}>
					{userData.declarationMonth} {userData.declarationYear}
				</h2>
				<h2 className={classes.detail_element}>
					${roundToTwoDecimals(userData.payment)}
				</h2>
				<h2 className={classes.detail_element}>
					{formatMexicoTz(userData.createdAt) || '-'}
				</h2>
				<h2 className={classes.detail_element}>
					{formatMexicoTz(userData.effectiveDate) || '-'}
				</h2>
				<h2 className={classes.detail_element}>
					{formatMexicoTz(userData.paidDate) || '-'}
				</h2>
				<div className={classes.detail_element_download}>
					<h2 className={getStatusClassName(userData.currentStatus)}>
						{userData.currentStatus || 'sin estatus'}
					</h2>
				</div>
				<button
					onClick={retrieveFiles}
					className={classes.detail_element_seemore}
				>
					<FileDownloadOutlinedIcon sx={{ fontSize: 25 }} />
				</button>
			</div>

			{modalOpen &&
				<DetailDeclaracionesModal
					userData={userData}
					setModalOpen={setModalOpen}
					TOKEN={TOKEN}
					setRefecth={setRefetch}
				/>}
		</>
	);
};



export default DetailDeclaraciones;
