import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllSatDeclarationById } from '../../../../../api/satDeclarations';
import Loading from '../../../../../common/Loading';
import DetailDeclaraciones from '../components/DetailDeclaraciones';
import DetailHeaderDeclaraciones from '../components/DetailHeaderDeclaraciones';
import classes from '../css/DetallesPrevios.module.css';

const Declaraciones = ({ userData }) => {
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const [userSatDeclaration, setUserSatDeclaration] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({ state: false, msg: '' });
	const [refetch, setRefetch] = useState(false);

	useEffect(() => {
		const fetchUserSatDeclaration = async () => {
			setLoading(true);
			try {
				const response = await getAllSatDeclarationById(TOKEN, userData.id);

				if (response.status === 200) {
					const result = await response.json();
					const resultData = result?.data?.data;
					setUserSatDeclaration(resultData);
					setLoading(false);
				} else {
					setLoading(false);
					setError({
						state: true,
						msg: 'Lo sentimos, parece ser que ocurrió un error.',
					});
				}
			} catch (error) {
				console.error(error);
				setLoading(false);
				setError({
					state: true,
					msg: 'Lo sentimos, parece ser que ocurrió un error.',
				});
			}
		};
		fetchUserSatDeclaration();
	}, [refetch]);

	return (
		<div>
			{!loading && !error.state && userSatDeclaration.length === 0 && (
				<section className={classes.loading_container}>
					<h1>No hay declaraciones presentadas del usuario.</h1>
				</section>
			)}
			{!loading && error.state && (
				<section className={classes.loading_container}>
					<h1>{error.msg}</h1>
				</section>
			)}
			{loading && !error.state && (
				<section className={classes.loading_container}>
					<Loading loading={loading} />
				</section>
			)}
			{!loading && !error.state && userSatDeclaration.length > 0 && (
				<>
					<DetailHeaderDeclaraciones />
					<div className={classes.details_container}>
						{userSatDeclaration.map((satDeclaration) => (
							<DetailDeclaraciones
								key={
									satDeclaration.declarationMonth +
									satDeclaration.declarationYear
								}
								userData={satDeclaration}
								currentUser={userData}
								setRefetch={setRefetch}
							/>
						))}
					</div>
				</>
			)}
		</div>
	);
};

export default Declaraciones;
