import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import generals from '../../../../../../css/Generals.module.css';
import { editSatDeclarationStatus } from '../../../../../api/satDeclarations';
import ClickOutsideReact from '../../../../../common/Clickoutside';
import Loading from '../../../../../common/Loading';
import modalStyles from '../css/Modal.module.css';

const DetailDeclaracionesModal = ({ userData, setModalOpen, TOKEN, setRefecth }) => {

    const [message, setMessage] = useState({ state: false, msg: '', subtext: '' });
    const [loading, setLoading] = useState(false);
    const [paidDate, setPaidDate] = useState(new Date());

    const handleModalClose = () => {
        const dialogOpen = document.querySelectorAll('div[role="dialog"]');
        const presentationOpen = document.querySelectorAll('[role="presentation"]');
        if (dialogOpen.length <= 0 && presentationOpen.length <= 0) {
            setModalOpen(false)
            setLoading(false);
            setMessage({ state: false, msg: '', subtext: '' });
        }
    };

    const getBodyByStatus = (status) => {
        switch (status) {
            case 'pagado':
                return 'Esta declaración fue marcada como pagada. ¿Deseas revertir y cambiar estatus a no pagado?';
            case 'vigente':
                return 'Esta declaración se encuentra vigente. ¿Deseas marcar declaración como pagada por el usuario?';
            case 'expirado':
                return 'La línea de pago de esta declaración se encuentra expirada. ¿Deseas marcarla como vigente y reactivada?';
        }
    };

    const getUpdateBodyByStatus = (status) => {
        switch (status) {
            case 'pagado':
                return {
                    currentStatus: 'vigente',
                    paidDate: null,
                    reactivate: false,
                };
            case 'vigente':
                return {
                    currentStatus: 'pagado',
                    paidDate: paidDate,
                    reactivate: false,
                };
            case 'expirado':
                return {
                    currentStatus: 'vigente',
                    paidDate: null,
                    reactivate: false,
                };
        }
    };

    const changeDeclarationStatus = async () => {
        let updateBody = getUpdateBodyByStatus(userData.currentStatus);
        try {
            setLoading(true);
            const response = await editSatDeclarationStatus(TOKEN, userData.id, updateBody);
            const result = await response.json();
            if (response.status === 200 && result.status === 'SUCCESS') {
                setRefecth(prevState => !prevState);
                setMessage({
                    state: 'success',
                    msg: 'Operación exitosa.',
                    subtext:
                        'El estatus de la declaración ha sido actualizado correctamente.',
                });
            } else {
                setMessage({
                    state: 'error',
                    msg: 'Algo salió mal.',
                    subtext: 'Favor de volver a intentar más tarde.',
                });
            }
        } catch (error) {
            console.error(error);
            setMessage({
                state: 'error',
                msg: 'Algo salió mal.',
                subtext: 'Favor de volver a intentar más tarde.',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={modalStyles.modal_overlay}>
            <ClickOutsideReact onClickOutside={handleModalClose}>
                <div className={`${modalStyles.modal_container} min-w-[500px] min-h-[400px]`}>
                    <button className={modalStyles.icon_close_wrapper} onClick={handleModalClose}>
                        <CloseIcon sx={{ fontSize: 25, color: '#b9b9b9' }} />
                    </button>
                    {!loading && !message.state &&
                        <>
                            <div className='my-10 flex-column justify-center text-center'>
                                <h1 className={`mb-5 ${modalStyles.modal_title}`}>
                                    Cambiar estatus
                                </h1>
                                <h2 className={`max-w-[250px] ${modalStyles.modal_subtitle}`}>
                                    {getBodyByStatus(userData.currentStatus)}
                                </h2>
                            </div>

                            <div className={modalStyles.modal_wrapper_button}>
                                {userData.currentStatus === 'vigente' && <div className='w-[290px] mb-5'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <h2 className={modalStyles.filters_subtitle}>Fecha de pago</h2>
                                        <DatePicker
                                            className={modalStyles.filters_input_box}
                                            defaultValue={dayjs()}
                                            onChange={(newValue) => {
                                                const date = new Date(newValue);
                                                setPaidDate(date);
                                            }}
                                        />
                                    </LocalizationProvider>
                                </div>}
                                <button className={generals.button_blue} onClick={changeDeclarationStatus}>
                                    {userData.currentStatus === 'pagado' ? 'No pagado' : userData.currentStatus === 'vigente' ? 'Cliente pagó' : 'Línea reactivada'}
                                </button>
                                <button className={generals.button_white} onClick={handleModalClose}>
                                    Volver
                                </button>
                            </div>
                        </>}
                    {loading && !message.state && <Loading loading={loading} />}
                    {!loading && message.state && (
                        <div className='h-[300px] flex flex-col gap-2 justify-center items-center'>
                            <h1 className={generals.modal_success_text}>{message.msg}</h1>
                            <h2 className={`mb-5 ${generals.modal_success_subtitle}`}>
                                {message.subtext}
                            </h2>
                            <button className={generals.button_white} onClick={handleModalClose}>
                                Volver
                            </button>
                        </div>
                    )}
                </div>
            </ClickOutsideReact>
        </div>
    );
};



export default DetailDeclaracionesModal;
