export const productoData = [
	{
		code: '20122329',
		label: 'Achicadores mecánicos de cable de recuperación',
	},
	{
		code: '20122330',
		label: 'Plugback mecánico de cable de recuperación',
	},
	{
		code: '20122331',
		label: 'Otras herramientas de cable de recuperación',
	},
	{
		code: '20122332',
		label: 'Raspadores de parafina de cable de recuperación',
	},
	{
		code: '20122333',
		label: 'Enchufe de línea de cable de recuperación',
	},
	{
		code: '20122334',
		label: 'Tenazas para halar o correr el cable de recuperación',

		palabrasSimilares: 'Pinzas Slickline para correr y tirar',
	},
	{
		code: '20122335',
		label: 'Herramientas para cortar el cable de recuperación',
	},
	{
		code: '20122336',
		label: 'Roldanas o bloques de piso de la cable de recuperación',
	},
	{
		code: '20122338',
		label: 'Accesorios de herramientas para halar el cable de recuperación',

		palabrasSimilares: 'Accesorios para herramientas de tracción Slickline',
	},
	{
		code: '20122339',
		label: 'Herramientas para halar el cable de recuperación',
	},
	{
		code: '20122340',
		label: 'Herramientas para correr el cable de recuperación',
	},
	{
		code: '20122341',
		label: 'Unidades de cable de recuperación',
	},
	{
		code: '20122342',
		label: 'Alambre del cable de recuperación',
	},
	{
		code: '20122343',
		label: 'Herramientas sónicas del cable de recuperación',
	},
	{
		code: '20122344',
		label: 'Barras espaciadoras del cable de recuperación',
	},
	{
		code: '20122345',
		label: 'Terminales de prensado del cable de recuperación',
	},
	{
		code: '20122346',
		label: 'Dispositivos de tensión del cable de recuperación',
	},
	{
		code: '20122347',
		label: 'Tapones de tubos de cable de recuperación',
	},
	{
		code: '20122348',
		label: 'Herramientas ultrasónicas cable de recuperación',
	},
	{
		code: '20122349',
		label: 'Agarres del cable de recuperación',
	},
	{
		code: '20122350',
		label: 'Tarros del cable de recuperación',
	},
	{
		code: '20122351',
		label: 'Raspadores del cable de recuperación',
	},
	{
		code: '20122352',
		label: 'Lanza del cable de recuperación',
	},
	{
		code: '20122353',
		label: 'Tallos del cable de recuperación',
	},
	{
		code: '20122354',
		label: 'Válvulas del cable de recuperación',
	},
	{
		code: '20122356',
		label: 'Preventores del cable de recuperación',
	},
	{
		code: '20122357',
		label: 'Aceleradores de tarro del cable de recuperación',
	},
	{
		code: '20122358',
		label: 'Dardo de prueba',
	},
	{
		code: '20122359',
		label:
			'Accesorios y partes de herramientas de corrida del cable de recuperación',
	},
	{
		code: '20122360',
		label: 'Punzón de la línea de cable',
	},
	{
		code: '20122361',
		label: 'Válvula de pie',
	},
	{
		code: '20122362',
		label: 'Guía de campana de la línea de cable',
	},
	{
		code: '20122363',
		label: 'Hallador de cable',
	},
	{
		code: '20122364',
		label: 'Cadena de herramientas de línea de cable',
	},
	{
		code: '20122365',
		label: 'Partes y accesorios del centralizador de cable de recuperación',
	},
	{
		code: '20122366',
		label: 'Descentralizador magnético',
	},
	{
		code: '20122367',
		label: 'Herramienta de alado de la línea de cable',
	},
	{
		code: '20122368',
		label: 'Mandril de la línea de cable',
	},
	{
		code: '20122369',
		label: 'Herramienta de establecer línea de cable',
	},
	{
		code: '20122370',
		label: 'Cruce de línea de cable',
	},
	{
		code: '20122371',
		label: 'Centralizador de cable de recuperación',
	},
	{
		code: '20122372',
		label: 'Acople de pivote de línea de cable',
	},
	{
		code: '20122373',
		label:
			'Partes y accesorios de herramienta de kickover del cable de recuperación',
	},
	{
		code: '20122400',
		label: 'Equipo de sistemas de producción',
	},
	{
		code: '20122401',
		label: 'Dispositivo golpeador de cable',
	},
	{
		code: '20122402',
		label: 'Evaporadores de producción de campo de petróleo',
	},
	{
		code: '20122403',
		label: 'Probadores hipot',
	},
	{
		code: '20122404',
		label: 'Maquinas de vueltas de campo de petróleo',
	},
	{
		code: '20122405',
		label: 'Elevadores finales de motor',
	},
	{
		code: '20122406',
		label: 'Probadores dieléctricos de aceite',
	},
	{
		code: '20122407',
		label: 'Unidades de llenado al vacío de aceite',
	},
	{
		code: '20122408',
		label: 'Enderezadores de eje de producción de campo petrolero',
	},
	{
		code: '20122409',
		label: 'Bobina de producción de campo de petróleo',
	},
	{
		code: '20122410',
		label: 'Analizadores de vibración',
	},
	{
		code: '20122500',
		label: 'Equipo para tubería flexible',
	},
	{
		code: '20122501',
		label: 'Herramientas de arenadora',
	},
	{
		code: '20122502',
		label: 'Unidades de camión grúa de tubería flexible',
	},
	{
		code: '20122503',
		label: 'Unidades de tubería flexible',

		palabrasSimilares: 'Cespol',
	},
	{
		code: '20122504',
		label: 'Paquetes de manguera de tubería flexible',
	},
	{
		code: '20122505',
		label: 'Sistemas inflables de tubería flexible',
	},
	{
		code: '20122506',
		label: 'Cabezales de inyector de tubería flexible',
	},
	{
		code: '20122507',
		label: 'Equipo de elevación de tubería flexible',
	},
	{
		code: '20122508',
		label: 'Casas de operadores',
	},
	{
		code: '20122509',
		label: 'Fuentes de poder de tubería flexible',
	},
	{
		code: '20122510',
		label: 'Rollos de tubería flexible',
	},
	{
		code: '20122511',
		label: 'Carretes de embobinar tubería flexible',
	},
	{
		code: '20122512',
		label: 'Guías de tubería',
	},
	{
		code: '20122513',
		label: 'Enganches de boca de pozo',
	},
	{
		code: '20122514',
		label: 'Estructuras de soporte de boca de pozo',
	},
	{
		code: '20122515',
		label: 'Tubería flexible de campo petrolero',
	},
	{
		code: '20122516',
		label: 'Cadena de herramientas de tubería flexible',
	},
	{
		code: '20122518',
		label: 'Centralizador de tubería flexible',
	},
	{
		code: '20122600',
		label: 'Equipo sísmico',
	},
	{
		code: '20122601',
		label: 'Sensores análogos sísmicos',
	},
	{
		code: '20122602',
		label: 'Arreglos sísmicos',
	},
	{
		code: '20122603',
		label: 'Aves de cable de serpentina sísmica',
	},
	{
		code: '20122604',
		label: 'Tanqueros de taladro sísmico',
	},
	{
		code: '20122605',
		label: 'Geófonos sísmicos',
	},
	{
		code: '20122606',
		label: 'Sistemas de gravedad sísmicos',
	},
	{
		code: '20122607',
		label: 'Sistemas de malacate de cañón sísmico',
	},
	{
		code: '20122608',
		label: 'Hidrofonos sísmicos',
	},
	{
		code: '20122609',
		label: 'Fuentes de impulso sísmicos',
	},
	{
		code: '20122610',
		label: 'Cables serpentinas marinos sísmicos',
	},
	{
		code: '20122611',
		label: 'Cables del fondo del océano sísmico',
	},
	{
		code: '20122612',
		label: 'Sistemas magnéticos sísmicos',
	},
	{
		code: '20122613',
		label: 'Equipo de posicionar sísmico',
	},
	{
		code: '20122614',
		label: 'Arietes sísmicos',
	},
	{
		code: '20122615',
		label: 'Receptores sísmicos',
	},
	{
		code: '20122616',
		label: 'Sistemas de refracción sísmicos',
	},
	{
		code: '20122617',
		label: 'Controladores de fuente sísmicos',
	},
	{
		code: '20122618',
		label: 'Dispositivos de bobinar sísmicos',
	},
	{
		code: '20122619',
		label: 'Bloques de remolque sísmicos',
	},
	{
		code: '20122620',
		label: 'Puntos de remolque sísmicos',
	},
	{
		code: '20122621',
		label: 'Vibradores sísmicos',
	},
	{
		code: '20122622',
		label: 'Sistemas de grabar sísmicos',
	},
	{
		code: '20122623',
		label: 'Sistemas de procesamiento de datos sísmicos',
	},
	{
		code: '20122700',
		label: 'Mercancía tubular para campo petrolero',
	},
	{
		code: '20122701',
		label: 'Revestimiento para campo petrolero',
	},
	{
		code: '20122702',
		label: 'Acoples para campo petrolero',
	},
	{
		code: '20122703',
		label: 'Tubería corta para campo petrolero',
	},
	{
		code: '20122704',
		label: 'Tubería para campo petrolero',
	},
	{
		code: '20122705',
		label: 'Recubrimiento de tubería para campo petrolero',
	},
	{
		code: '20122706',
		label: 'Revestimiento de conductor',
	},
	{
		code: '20122707',
		label: 'Equipo para operar el revestimiento para conductor',
	},
	{
		code: '20122708',
		label: 'Cruces de la tubería de perforación',
	},
	{
		code: '20122709',
		label: 'Protectores de rosca para campo petrolero',
	},
	{
		code: '20122710',
		label: 'Dispositivo para parar el revestimiento',
	},
	{
		code: '20122800',
		label: 'Equipo y plataformas de reacondicionamiento y perforación',
	},
	{
		code: '20122801',
		label: 'Agitadores de lodos',
	},
	{
		code: '20122802',
		label: 'Depósitos de lodos',
	},
	{
		code: '20122803',
		label: 'Equipo de aire para perforaciones',
	},
	{
		code: '20122804',
		label: 'Taladros en barcazas',
	},
	{
		code: '20122806',
		label: 'Desviadores de fluido',
	},
	{
		code: '20122807',
		label: 'Aparejos de maniobras',
	},
	{
		code: '20122808',
		label: 'Equipo para lecho de perforación',
	},
	{
		code: '20122809',
		label: 'Pivotes de perforación',
	},
	{
		code: '20122810',
		label: 'Barco – taladro de perforación',
	},
	{
		code: '20122811',
		label: 'Elevadores de taladro de perforación',
	},
	{
		code: '20122812',
		label: 'Estribos del taladro de perforación',
	},
	{
		code: '20122813',
		label: 'Unidades de reacondicionamiento hidráulico',
	},
	{
		code: '20122814',
		label: 'Sistemas de gatos para mover el taladro de perforación',
	},
	{
		code: '20122815',
		label: 'Taladro de perforación marina movido con gatos',
	},
	{
		code: '20122816',
		label: 'Bujes kelly',
	},
	{
		code: '20122817',
		label: 'Válvulas kelly',
	},
	{
		code: '20122818',
		label: 'Escobillas kelly',
	},
	{
		code: '20122819',
		label: 'Kellys',
	},
	{
		code: '20122820',
		label: 'Taladro de perforación en tierra',
	},
	{
		code: '20122821',
		label: 'Equipo de limpieza de lodo',
	},
	{
		code: '20122822',
		label: 'Múltiples para lodo',
	},
	{
		code: '20122823',
		label: 'Mezcladoras de lodo',
	},
	{
		code: '20122824',
		label: 'Equipo para manejo de tubería',
	},
	{
		code: '20122825',
		label: 'Plataforma de taladro de perforación',
	},
	{
		code: '20122826',
		label: 'Pivote de energía o transmisión superior',
	},
	{
		code: '20122827',
		label: 'Patines para taladro',
	},
	{
		code: '20122828',
		label: 'Elevadores del taladro de perforación',
	},
	{
		code: '20122829',
		label: 'Mesas giratorias de taladro de perforación',
	},
	{
		code: '20122830',
		label: 'Plataformas auto – elevadoras para el reacondicionamiento',
	},
	{
		code: '20122831',
		label: 'Taladros de perforación semi – sumergibles',
	},
	{
		code: '20122832',
		label: 'Deslizadores del lecho de perforación',
	},
	{
		code: '20122833',
		label: 'Tenazas de reposición',
	},
	{
		code: '20122834',
		label: 'Llave doble automática',
	},
	{
		code: '20122835',
		label: 'Equipo viajero',
	},
	{
		code: '20122836',
		label: 'Barcos de reacondicionamiento',
	},
	{
		code: '20122837',
		label: 'Taladros de reacondicionamiento',
	},
	{
		code: '20122838',
		label: 'Separadores de lodo (lutita)',
	},
	{
		code: '20122839',
		label: 'Desgasificadores de lodo',
	},
	{
		code: '20122840',
		label: 'Bloques de corona',
	},
	{
		code: '20122841',
		label: 'Bloques móviles',
	},
	{
		code: '20122842',
		label: 'Desarenadores de lodo',
	},
	{
		code: '20122843',
		label: 'Separadores de granos finos de lodo',
	},
	{
		code: '20122844',
		label: 'Tenazas de energía',

		palabrasSimilares: 'Pinzas eléctricas',
	},
	{
		code: '20122845',
		label: 'Partes y accesorios para el manejo de tubería',
	},
	{
		code: '20122846',
		label: 'Trampolín',
	},
	{
		code: '20122847',
		label: 'Sub y enchufe de elevador',
	},
	{
		code: '20122848',
		label: 'Dispositivo de arreglo horizontal o unidad de trepidación',
	},
	{
		code: '20122849',
		label: 'Araña de revestimiento de pozo',
	},
	{
		code: '20122851',
		label: 'Unidad de potencia hidráulica',
	},
	{
		code: '20122900',
		label: 'Equipo de registrar datos de superficie',
	},
	{
		code: '20122901',
		label: 'Conductos de registrar datos de superficie',
	},
	{
		code: '20122902',
		label: 'Sensores de registrar datos de superficie',
	},
	{
		code: '20122903',
		label: 'Unidades de registrar datos de superficie',
	},
	{
		code: '20123000',
		label: 'Equipo multilateral',
	},
	{
		code: '20123001',
		label: 'Revestimiento multilateral',
	},
	{
		code: '20123002',
		label: 'Uniones multilaterales',
	},
	{
		code: '20123003',
		label: 'Tapones multilaterales',
	},
	{
		code: '20123004',
		label: 'Partes y accesorios de tapones multilaterales',
	},
	{
		code: '20123100',
		label: 'Herramienta para salida de revestimientos',
	},
	{
		code: '20123101',
		label: 'Herramienta de ajuste de salida de revestimientos',
	},
	{
		code: '20123102',
		label: 'Whipstock de salida de revestimientos',
	},
	{
		code: '20123200',
		label: 'Equipo de expansión en profundidad',
	},
	{
		code: '20123201',
		label: 'Herramienta ranurada de ajuste expandible',
	},
	{
		code: '20123202',
		label: 'Kit de reparación expandible',
	},
	{
		code: '20123203',
		label: 'Revestimiento expandible',
	},
	{
		code: '20123300',
		label: 'Herramientas para revestimientos mientras se perfora',
	},
	{
		code: '20123301',
		label: 'Zapata de taladro',
	},
	{
		code: '20123302',
		label: 'Partes y accesorios de zapata de taladro',
	},
	{
		code: '20123303',
		label: 'Lanza de taladro',
	},
	{
		code: '20123304',
		label: 'Partes y accesorios de lanza de taladro',
	},
	{
		code: '20131000',
		label: 'Lodo de perforar y materiales',
	},
	{
		code: '20131001',
		label: 'Agentes de control de filtración',
	},
	{
		code: '20131002',
		label: 'Espaciadores de fluido',
	},
	{
		code: '20131003',
		label: 'Agentes de circulación perdida',
	},
	{
		code: '20131004',
		label: 'Lodos con base de petróleo',
	},
	{
		code: '20131005',
		label: 'Aumentadores de la rata de penetración',
	},
	{
		code: '20131006',
		label: 'Fluidos centradores',
	},
	{
		code: '20131007',
		label: 'Lodos con base sintética',
	},
	{
		code: '20131008',
		label: 'Agentes para adelgazar el lodo',
	},
	{
		code: '20131009',
		label: 'Lodos con base en agua',
	},
	{
		code: '20131010',
		label: 'Agentes para engruesar el lodo',
	},
	{
		code: '20131100',
		label: 'Apuntalantes de fracturamiento de pozo',
	},
	{
		code: '20131101',
		label: 'Apuntaladores cerámicos',
	},
	{
		code: '20131102',
		label: 'Arenas de fracturación',
	},
	{
		code: '20131103',
		label: 'Apuntaladores cerámicos cubiertos de resina',
	},
	{
		code: '20131104',
		label: 'Arenas de fracturación cubiertas de resina',
	},
	{
		code: '20131105',
		label: 'Bauxitas sinterizadas cubierta de resina',
	},
	{
		code: '20131106',
		label: 'Bauxitas sinterizadas',
	},
	{
		code: '20131200',
		label: 'Fluidos de completar',
	},
	{
		code: '20131201',
		label: 'Salmueras divalentes',
	},
	{
		code: '20131202',
		label: 'Salmueras monovalentes',
	},
	{
		code: '20131300',
		label: 'Cemento de pozo petrolero',
	},
	{
		code: '20131301',
		label: 'Cemento a granel de pozo petrolero',
	},
	{
		code: '20131302',
		label: 'Pozo petrolero cemento tipo i clase a',
	},
	{
		code: '20131303',
		label: 'Pozo petrolero cemento tipo ii clase b',
	},
	{
		code: '20131304',
		label: 'Pozo petrolero cemento clase c',
	},
	{
		code: '20131305',
		label: 'Pozo petrolero cemento clase g',
	},
	{
		code: '20131306',
		label: 'Pozo petrolero cemento clase h',
	},
	{
		code: '20131307',
		label: 'Cemento liviano de pozo petrolero',
	},
	{
		code: '20131308',
		label: 'Pozo petrolero cemento estándar fino tipo iii',
	},
	{
		code: '20141000',
		label: 'Equipo de cabeza de pozo',
	},
	{
		code: '20141001',
		label: 'Actuadores de boca de pozo',
	},
	{
		code: '20141002',
		label: 'Bombas de balancín de boca de pozo',
	},
	{
		code: '20141003',
		label: 'Líneas de flujo de boca de pozo',
	},
	{
		code: '20141004',
		label: 'Válvulas de compuerta de boca de pozo',
	},
	{
		code: '20141005',
		label: 'Reguladores de producción de boca de pozo',
	},
	{
		code: '20141006',
		label:
			'Accesorios de boca de pozo o flujo debajo de la superficie de boca de pozo',
	},
	{
		code: '20141007',
		label: 'Accesorios de boca de pozo o flujo de superficie de boca de pozo',
	},
	{
		code: '20141008',
		label: 'Válvulas de seguridad de la superficie de boca de pozo',
	},
	{
		code: '20141011',
		label: 'Adaptador de cabeza de tubería',
	},
	{
		code: '20141012',
		label: 'Revestimiento del cabezal de la carcasa',
	},
	{
		code: '20141013',
		label: 'Carrete del cabezal de la tubería',
	},
	{
		code: '20141014',
		label: 'Carrete del cabezal del revestimiento',
	},
	{
		code: '20141015',
		label: 'Conexiones en t o en cruz de la boca de pozo',
	},
	{
		code: '20141016',
		label: 'Base de llegada de la boca de pozo',
	},
	{
		code: '20141017',
		label: 'Cuerpo de transporte boca de pozo',
	},
	{
		code: '20141018',
		label: 'Colgador de boca de pozo',
	},
	{
		code: '20141100',
		label: 'Sistemas de inyección químicos',
	},
	{
		code: '20141101',
		label: 'Sistemas de inyección de parafina',
	},
	{
		code: '20141200',
		label: 'Equipo de desarenar',
	},
	{
		code: '20141201',
		label: 'Equipo de desarenar producción',
	},
	{
		code: '20141300',
		label: 'Anclas y bombas de chorro de barrenas hacia abajo',
	},
	{
		code: '20141301',
		label: 'Bombas de chorro de fondo de pozo',
	},
	{
		code: '20141302',
		label: 'Partes y accesorios de bombas de chorro de fondo de pozo',
	},
	{
		code: '20141303',
		label: 'Tubería de perforación',
	},
	{
		code: '20141400',
		label: 'Accesorios de producción de barrenas hacia abajo',
	},
	{
		code: '20141401',
		label: 'Topes de tubería',
	},
	{
		code: '20141500',
		label: 'Bombas de barrenas hacia abajo',
	},
	{
		code: '20141501',
		label: 'Bombas eléctricas de fondo de pozo',
	},
	{
		code: '20141502',
		label: 'Bomba de fondo de pozo de cavidad progresiva',
	},
	{
		code: '20141600',
		label: 'Bombas de exportación',
	},
	{
		code: '20141601',
		label: 'Bombas neumáticas de exportación',
	},
	{
		code: '20141700',
		label:
			'Plataformas de almacenaje y producción a poca distancia de la costa',
	},
	{
		code: '20141701',
		label: 'Plataformas fijas de producción costa afuera',
	},
	{
		code: '20141702',
		label: 'Plataformas flotantes de producción costa afuera',
	},
	{
		code: '20141703',
		label: 'Plataformas flotantes de almacenamiento costa afuera',
	},
	{
		code: '20141704',
		label:
			'Plataformas flotantes de brazo de tensión de producción costa afuera',
	},
	{
		code: '20141705',
		label:
			'Plataformas flotantes de brazo de tensión de almacenamiento costa afuera',
	},
	{
		code: '20141800',
		label: 'Contadores para medir el flujo de producción del pozo',
	},
	{
		code: '20141801',
		label: 'Contadores de la turbina de gas de producción del pozo',
	},
	{
		code: '20141900',
		label: 'Equipo de tratamiento de gas',
	},
	{
		code: '20141901',
		label: 'Equipo de tratamiento de gas para producción del pozo',
	},
	{
		code: '20142000',
		label: 'Regeneradores de glicol',
	},
	{
		code: '20142001',
		label: 'Regeneradores de glicol de pozo petrolero',
	},
	{
		code: '20142100',
		label: 'Tratadores de calentadores',
	},
	{
		code: '20142101',
		label: 'Tratadores de calentadores de aceite para pozo',
	},
	{
		code: '20142200',
		label: 'Calentadores de línea',
	},
	{
		code: '20142201',
		label: 'Calentadores de línea eléctrica',
	},
	{
		code: '20142300',
		label: 'Deslizaderos de inyección de producción',
	},
	{
		code: '20142301',
		label: 'Patines de inyección neumática de metanol',
	},
	{
		code: '20142400',
		label: 'Equipo para el lecho del océano',
	},
	{
		code: '20142401',
		label: 'Equipo para producción submarina de boca de pozo',
	},
	{
		code: '20142402',
		label: 'Tuberías submarinas umbilicales o flexibles',
	},
	{
		code: '20142403',
		label: 'Sistema múltiple de distribución de producción submarina',
	},
	{
		code: '20142404',
		label: 'Sistema de bombeo y separación anular vertical',
	},
	{
		code: '20142405',
		label: 'Cruz de producción y componente submarino',
	},
	{
		code: '20142406',
		label: 'Sistema de control submarino',
	},
	{
		code: '20142407',
		label: 'Ensamble de terminación umbilical submarina',
	},
	{
		code: '20142500',
		label: 'Equipo para tratar agua producida',
	},
	{
		code: '20142501',
		label: 'Centrífugas de petróleo del agua del campo petrolífero',
	},
	{
		code: '20142600',
		label: 'Instrumentación del sistema de control de producción',
	},
	{
		code: '20142601',
		label: 'Sistemas inalámbricos de control de producción',
	},
	{
		code: '20142700',
		label: 'Unidades de bombear',
	},
	{
		code: '20142701',
		label: 'Gatos de la varilla de bombeo',
	},
	{
		code: '20142702',
		label: 'Bombas de barra',
	},
	{
		code: '20142703',
		label: 'Bombas de barra mecánica',
	},
	{
		code: '20142704',
		label: 'Ensamble de marco de bombeo y extensión',
	},
	{
		code: '20142705',
		label: 'Ensamble de biela',
	},
	{
		code: '20142706',
		label: 'Ensamble de ecualizador pitman',
	},
	{
		code: '20142707',
		label: 'Ensamble de cabezote',
	},
	{
		code: '20142708',
		label: 'Ensamble de puesto samson',
	},
	{
		code: '20142709',
		label: 'Ensamble de viga de caminar',
	},
	{
		code: '20142710',
		label: 'Ensamble de barra de colgador',
	},
	{
		code: '20142800',
		label: 'Separadores de producción',
	},
	{
		code: '20142801',
		label: 'Separadores de agua petróleo',
	},
	{
		code: '20142900',
		label: 'Tanques y recipientes almacenadores',
	},
	{
		code: '20142901',
		label: 'Tanques almacenadores de petróleo',
	},
	{
		code: '20142902',
		label: 'Tanque de fibra de vidrio',
	},
	{
		code: '20142903',
		label: 'Tanque de acero',
	},
	{
		code: '20142904',
		label: 'Tanque plástico',
	},
	{
		code: '20142905',
		label: 'Tanque receptor de aire',
	},
	{
		code: '20143000',
		label: 'Vástagos de succión',
	},
	{
		code: '20143001',
		label: 'Vástagos de succión de aleación de acero',
	},
	{
		code: '20143002',
		label: 'Varillas cortas',
	},
	{
		code: '20143003',
		label: 'Vástago continuo de succión',
	},
	{
		code: '20143004',
		label: 'Pin de terminación roscada de vástago continuo de succión',
	},
	{
		code: '20143005',
		label: 'Acople deslizante de vástago continuo de succión',
	},
	{
		code: '20143300',
		label: 'Equipo para servicio de oleoductos',
	},
	{
		code: '20143301',
		label: 'Dispositivo limpiador de oleoductos',
	},
	{
		code: '20143302',
		label: 'Equipo para inspeccionar el interior del oleoducto',
	},
	{
		code: '20143303',
		label: 'Cortador robótico del interior del oleoducto',
	},
	{
		code: '21101500',
		label: 'Maquinaria agrícola para preparación del suelo',
	},
	{
		code: '21101501',
		label: 'Arados',
	},
	{
		code: '21101502',
		label: 'Pulverizadores',
	},
	{
		code: '21101503',
		label: 'Cultivadoras',
	},
	{
		code: '21101504',
		label: 'Desmalezadoras',
	},
	{
		code: '21101505',
		label: 'Máquinas desyerbadoras',
	},
	{
		code: '21101506',
		label: 'Máquinas aplanadoras o niveladoras',
	},
	{
		code: '21101507',
		label: 'Rodillos agrícolas',
	},
	{
		code: '21101508',
		label: 'Rodillos para prados o terrenos deportivos',
	},
	{
		code: '21101509',
		label: 'Máquina para drenaje de zanjas',
	},
	{
		code: '21101511',
		label: 'Chorros de riego',
	},
	{
		code: '21101512',
		label: 'Gastos generales de riego',
	},
	{
		code: '21101513',
		label: 'Discos',
	},
	{
		code: '21101514',
		label: 'Arados de subsuelo',
	},
	{
		code: '21101516',
		label: 'Sembradoras',
	},
	{
		code: '21101517',
		label: 'Motocultivador giratorio o de potencia',
	},
	{
		code: '21101518',
		label: 'Esterilizador de suelo',
	},
	{
		code: '21101519',
		label: 'Inyector de suelo',
	},
	{
		code: '21101520',
		label: 'Lanzallamas',
	},
	{
		code: '21101521',
		label: 'Mezclador de fertilizante',
	},
	{
		code: '21101522',
		label: 'Arado de bordes o arado',
	},
	{
		code: '21101523',
		label: 'Sistema automatizado de manejo de maquinaria agrícola',
	},
	{
		code: '21101600',
		label: 'Maquinaria agrícola para siembra y plantado',
	},
	{
		code: '21101601',
		label: 'Plantadoras',
	},
	{
		code: '21101602',
		label: 'Transplantadoras',
	},
	{
		code: '21101603',
		label: 'Sembradoras de grano',
	},
	{
		code: '21101604',
		label: 'Sembradoras de semillas',
	},
	{
		code: '21101605',
		label: 'Equipo para tratamiento de semillas',
	},
	{
		code: '21101606',
		label: 'Excavadoras de agujeros',
	},
	{
		code: '21101607',
		label: 'Remolque de sembradora',
	},
	{
		code: '21101608',
		label: 'Gabinete o cámara para crecimiento de plantas',
	},
	{
		code: '21101609',
		label: 'Cama de semillas (semillero)',
	},
	{
		code: '21101610',
		label: 'Máquina de cubrimiento del suelo',
	},
	{
		code: '21101611',
		label: 'Controlador de tasa de flujo de fertilizante',
	},
	{
		code: '21101612',
		label: 'Germinador de semillas',
	},
	{
		code: '21101700',
		label: 'Maquinaria agrícola para cosechar',
	},
	{
		code: '21101701',
		label: 'Cortadoras de pasto',
	},
	{
		code: '21101702',
		label: 'Máquina segadora de heno',
	},
	{
		code: '21101703',
		label: 'Cosechadoras',
	},
	{
		code: '21101704',
		label: 'Cosechadoras “trilladoras” o mixtas',
	},
	{
		code: '21101705',
		label: 'Trilladoras',
	},
	{
		code: '21101706',
		label: 'Separadores de cultivos',
	},
	{
		code: '21101707',
		label: 'Piezas de cosechadora o accesorios',
	},
	{
		code: '21101708',
		label: 'Piezas de segadora o accesorios',
	},
	{
		code: '21101709',
		label: 'Embaladora',
	},
	{
		code: '21101710',
		label: 'Secadora de cosecha',
	},
	{
		code: '21101711',
		label: 'Desgranadora',
	},
	{
		code: '21101712',
		label: 'Máquina automática cortadora y empaquetadora de flores',
	},
	{
		code: '21101800',
		label: 'Aparatos dispersores o aspersores para agricultura',
	},
	{
		code: '21101801',
		label: 'Rociadores',

		palabrasSimilares: 'Hidrolavadora',
	},
	{
		code: '21101802',
		label: 'Guarda polvos',
	},
	{
		code: '21101803',
		label: 'Aspersores de agua',
	},
	{
		code: '21101804',
		label: 'Dispersores o distribuidores de fertilizante',
	},
	{
		code: '21101805',
		label: 'Generadores de niebla o neblina',
	},
	{
		code: '21101806',
		label: 'Compostadores',
	},
	{
		code: '21101807',
		label: 'Equipo y suministros para polinización',
	},
	{
		code: '21101808',
		label: 'Equipo para protección contra las heladas',
	},
	{
		code: '21101809',
		label: 'Sistema para la fumigación del grano',
	},
	{
		code: '21101900',
		label: 'Equipo para aves de corral y ganado',
	},
	{
		code: '21101901',
		label: 'Ordeñadoras',
	},
	{
		code: '21101902',
		label: 'Equipo para cría de ganado',
	},
	{
		code: '21101903',
		label: 'Incubadoras o polleras para aves de corral',
	},
	{
		code: '21101904',
		label: 'Mezcladoras de forraje',
	},
	{
		code: '21101905',
		label: 'Equipo para identificación de ganado',
	},
	{
		code: '21101906',
		label: 'Equipo de inspección o recolección de huevos',
	},
	{
		code: '21101907',
		label: 'Máquinas para abrevar animales',
	},
	{
		code: '21101908',
		label: 'Tanques refrigeradores de leche',
	},
	{
		code: '21101909',
		label: 'Equipo para el esquilar o peluquear de animales',
	},
	{
		code: '21101910',
		label: 'Peine de lana',
	},
	{
		code: '21101911',
		label: 'Compostador para gallinaza',
	},
	{
		code: '21101912',
		label: 'Espacio para inmovilizar ganado',
	},
	{
		code: '21101913',
		label: 'Cortador de picos',
	},
	{
		code: '21101914',
		label: 'Cerca eléctrica para ganado',
	},
	{
		code: '21101915',
		label: 'Medidor de grasa en la espalda de los cerdos',

		palabrasSimilares:
			'Medidor de grasa en la espalda de los chanchos, Medidor de grasa en la espalda de los cochinillos, Medidor de grasa en la espalda de los cochinos, Medidor de grasa en la espalda de los cuche, Medidor de grasa en la espalda de los cuinos, Medidor de grasa en la espalda de los gorrinos, Medidor de grasa en la espalda de los lechones, Medidor de grasa en la espalda de los marranos, Medidor de grasa en la espalda de los porcinos, Medidor de grasa en la espalda de los puercos,Medidor de grasa en la espalda de los lechones',
	},
	{
		code: '21102000',
		label: 'Maquinaria agrícola para limpieza, selección o clasificación',
	},
	{
		code: '21102001',
		label: 'Máquinas limpiadoras de semillas, grano o legumbres secas',
	},
	{
		code: '21102002',
		label: 'Máquinas seleccionadoras de semillas, grano o legumbres secas',
	},
	{
		code: '21102003',
		label: 'Clasificadoras de semillas, grano o legumbres secas',
	},
	{
		code: '21102004',
		label: 'Equipo para limpieza y descascarillado de arroz',
	},
	{
		code: '21102005',
		label: 'Molino para trituración',
	},
	{
		code: '21102006',
		label: 'Molinos de martillo',
	},
	{
		code: '21102007',
		label: 'Clasificadora de frutas',
	},
	{
		code: '21102008',
		label: 'Equipo para limpiar o descascarar cebada',
	},
	{
		code: '21102100',
		label: 'Maquinaria y equipo para transformación agrícola',
	},
	{
		code: '21102101',
		label: 'Máquinas agrícolas de briqueta',
	},
	{
		code: '21102102',
		label: 'Expulsor de aceite de semilla',
	},
	{
		code: '21102200',
		label: 'Maquinaria y equipo para silvicultura',
	},
	{
		code: '21102201',
		label: 'Descortezadoras',
	},
	{
		code: '21102202',
		label: 'Equipo de explotación forestal',
	},
	{
		code: '21102203',
		label: 'Equipo de reforestación',
	},
	{
		code: '21102204',
		label: 'Sierras para silvicultura',
	},
	{
		code: '21102205',
		label: 'Deslizadores forestales',
	},
	{
		code: '21102206',
		label: 'Barrenos para aumento forestal',
	},
	{
		code: '21102207',
		label: 'Hipsómetro para silvicultura',
	},
	{
		code: '21102300',
		label: 'Equipo para invernadero',
	},
	{
		code: '21102301',
		label: 'Equipo de riego para invernadero',
	},
	{
		code: '21102302',
		label: 'Materas para invernadero',
	},
	{
		code: '21102303',
		label: 'Equipo de ventilación para invernadero',
	},
	{
		code: '21102304',
		label: 'Equipo aislante para invernadero',
	},
	{
		code: '21102305',
		label: 'Material para sombras para agricultura',
	},
	{
		code: '21102306',
		label: 'Inyector de fertilizante hidropónico',
	},
	{
		code: '21102400',
		label: 'Equipo para insectos',
	},
	{
		code: '21102401',
		label: 'Equipo para apicultura',
	},
	{
		code: '21102402',
		label: 'Equipo para gusanos de seda',
	},
	{
		code: '21102403',
		label: 'Equipo para la cría de mariposas',
	},
	{
		code: '21102404',
		label: 'Equipo para la cría de escarabajos',
	},
	{
		code: '21111500',
		label: 'Equipo de pesca comercial',
	},
	{
		code: '21111501',
		label: 'Anzuelos para pesca comercial',
	},
	{
		code: '21111502',
		label: 'Carretes para pesca comercial',
	},
	{
		code: '21111503',
		label: 'Aparejos para pesca comercial',
	},
	{
		code: '21111504',
		label: 'Redes para pesca comercial',
	},
	{
		code: '21111506',
		label: 'Flotadores para pesca comercial',
	},
	{
		code: '21111507',
		label: 'Plomos o pesos comerciales',
	},
	{
		code: '21111508',
		label: 'Transportadores de redes de pesca',
	},
	{
		code: '21111600',
		label: 'Equipo para acuicultura',
	},
	{
		code: '21111601',
		label: 'Equipo para piscicultura marina',
	},
	{
		code: '21111602',
		label: 'Suministros para piscicultura',
	},
	{
		code: '22101500',
		label: 'Maquinaria para trabajo de desmonte',
	},
	{
		code: '22101501',
		label: 'Cargadores frontales',
	},
	{
		code: '22101502',
		label: 'Niveladoras',
	},
	{
		code: '22101504',
		label: 'Piloteadoras',
	},
	{
		code: '22101505',
		label: 'Aplanadoras',

		palabrasSimilares: 'Apisonadora',
	},
	{
		code: '22101507',
		label: 'Bateadoras',
	},
	{
		code: '22101508',
		label: 'Máquinas para abrir zanjas',

		palabrasSimilares: 'Excavadora de zanjas',
	},
	{
		code: '22101509',
		label: 'Retroexcavadoras',

		palabrasSimilares: 'Excavadora mixta, Retrocargadora',
	},
	{
		code: '22101511',
		label: 'Compactadores',
	},
	{
		code: '22101513',
		label: 'Dragalíneas',

		palabrasSimilares: 'Dragalina',
	},
	{
		code: '22101514',
		label: 'Dragas',
	},
	{
		code: '22101516',
		label: 'Excavadoras de fosos',

		palabrasSimilares: 'Perforadora de pozos',
	},
	{
		code: '22101518',
		label: 'Raspadores elevadores',
	},
	{
		code: '22101519',
		label: 'Máquina giratoria con cazoleta de rastrillos abiertas',
	},
	{
		code: '22101520',
		label: 'Máquina giratoria con rastrillos elevadores',
	},
	{
		code: '22101521',
		label: 'Rastrilladora arrastrada',
	},
	{
		code: '22101522',
		label: 'Buldóceres de orugas',
	},
	{
		code: '22101523',
		label: 'Buldóceres de ruedas',
	},
	{
		code: '22101524',
		label: 'Excavadoras móviles',
	},
	{
		code: '22101525',
		label: 'Excavadoras de ruedas',
	},
	{
		code: '22101526',
		label: 'Excavadoras de orugas',
	},
	{
		code: '22101527',
		label: 'Transportes integrados de carga',
	},
	{
		code: '22101528',
		label: 'Cargadores de ruedas',
	},
	{
		code: '22101529',
		label: 'Cargadores sobre patines con dirección',
	},
	{
		code: '22101530',
		label: 'Raspadores abiertos',
	},
	{
		code: '22101531',
		label: 'Cañones para quitar nieve',
	},
	{
		code: '22101532',
		label: 'Cargadores de orugas',

		palabrasSimilares: 'Cargadores de cadenas',
	},
	{
		code: '22101533',
		label: 'Arrancadoras de troncos',
	},
	{
		code: '22101534',
		label: 'Excavadoras de campaña',
	},
	{
		code: '22101535',
		label: 'Extractor de pilotes',
	},
	{
		code: '22101536',
		label: 'Lavador de llantas',
	},
	{
		code: '22101537',
		label: 'Cortadora de pilotes de concreto',
	},
	{
		code: '22101538',
		label: 'Quitanieves',
	},
	{
		code: '22101600',
		label: 'Equipo de pavimentación',
	},
	{
		code: '22101602',
		label: 'Equipo de apisonamiento',
	},
	{
		code: '22101603',
		label: 'Ensanchadores de carreteras',
	},
	{
		code: '22101604',
		label: 'Placas vibradoras',
	},
	{
		code: '22101605',
		label: 'Acabadoras de asfalto',
	},
	{
		code: '22101606',
		label: 'Esparcidoras de gravilla',
	},
	{
		code: '22101607',
		label: 'Pavimentadoras',
	},
	{
		code: '22101608',
		label: 'Aplanadoras en frío',
	},
	{
		code: '22101609',
		label: 'Mezcladoras de material de pavimentación',
	},
	{
		code: '22101610',
		label: 'Distribuidoras de agregados',
	},
	{
		code: '22101611',
		label: 'Distribuidoras de material bituminoso',
	},
	{
		code: '22101612',
		label: 'Escarificadoras de carreteras',
	},
	{
		code: '22101613',
		label: 'Aplanadoras calentadoras de la superficie de la carretera',
	},
	{
		code: '22101614',
		label: 'Revestimientos de pavimento de concreto',
	},
	{
		code: '22101615',
		label: 'Trituradoras de pavimento',
	},
	{
		code: '22101616',
		label: 'Máquinas para hacer andenes',
	},
	{
		code: '22101617',
		label: 'Máquinas de relleno',
	},
	{
		code: '22101618',
		label:
			'Equipos de preparación de superficies de rodamiento o mecanismos para su colocación',
	},
	{
		code: '22101619',
		label: 'Máquinas pulidoras',
	},
	{
		code: '22101620',
		label: 'Máquinas de limpieza o acabado de juntas',
	},
	{
		code: '22101621',
		label: 'Distribuidora de asfalto',
	},
	{
		code: '22101622',
		label: 'Recicladora de asfalto',
	},
	{
		code: '22101623',
		label: 'Máquina pavimentadora y de terminados',
	},
	{
		code: '22101624',
		label: 'Máquina para marcar calles',
	},
	{
		code: '22101625',
		label: 'Máquina pulidora de concreto',
	},
	{
		code: '22101626',
		label: 'Planta mezcladora de asfalto',
	},
	{
		code: '22101627',
		label: 'Fusor de asfalto',
	},
	{
		code: '22101628',
		label: 'Lavadora de agregados',
	},
	{
		code: '22101629',
		label: 'Secadora de agregados',
	},
	{
		code: '22101630',
		label: 'Removedor de marcas de calles',
	},
	{
		code: '22101631',
		label: 'Guía de piloteadora',
	},
	{
		code: '22101700',
		label: 'Componentes de equipo pesado',
	},
	{
		code: '22101701',
		label: 'Palas excavadoras',
	},
	{
		code: '22101702',
		label:
			'Palas mecánicas para el de movimiento de tierra o sus piezas o accesorios',
	},
	{
		code: '22101703',
		label: 'Cuchillas o dientes u otros filos cortantes',
	},
	{
		code: '22101704',
		label: 'Escarificadores',
	},
	{
		code: '22101705',
		label: 'Orugas: eslabones o zapatas o sus piezas',

		palabrasSimilares: 'Orugas, eslabones o zapatas o sus piezas',
	},
	{
		code: '22101706',
		label: 'Cucharas de pala',
	},
	{
		code: '22101707',
		label: 'Cables de retención',
	},
	{
		code: '22101708',
		label: 'Extractores',
	},
	{
		code: '22101709',
		label: 'Cucharones bivalvos',
	},
	{
		code: '22101710',
		label: 'Remolques quitanieves',
	},
	{
		code: '22101711',
		label: 'Herramientas de trituración de pavimento o accesorios',
	},
	{
		code: '22101712',
		label: 'Herramientas de piloteadoras o sus piezas o accesorios',
	},
	{
		code: '22101713',
		label: 'Brazo de retroexcavadora o secciones del brazo',
	},
	{
		code: '22101714',
		label: 'Kits de reparación o piezas de apisonadora',
	},
	{
		code: '22101715',
		label: 'Plantas o alimentadoras de dosificación',
	},
	{
		code: '22101716',
		label: 'Kits de conversión de maquinaria de construcción',
	},
	{
		code: '22101717',
		label: 'Vertederas de movimiento de tierra',
	},
	{
		code: '22101718',
		label: 'Sistemas de control de aplanadora',
	},
	{
		code: '22101719',
		label: 'Portaherramientas de corredera transversal de aplanadora',
	},
	{
		code: '22101720',
		label: 'Zapatas perfiladoras de zanjadora',
	},
	{
		code: '22101721',
		label: 'Brocas y accesorios de la hincadora de estacas',
	},
	{
		code: '22101722',
		label: 'Cuchara dentada',

		palabrasSimilares: 'Dientes del cucharón',
	},
	{
		code: '22101723',
		label: 'Cortador lateral de cuchara',
	},
	{
		code: '22101724',
		label: 'Cincel cortador hidráulico',
	},
	{
		code: '22101800',
		label: 'Grúas de elevación',
	},
	{
		code: '22101801',
		label: 'Manlift o elevador de personal',

		palabrasSimilares: 'Plataforma elevadora',
	},
	{
		code: '22101802',
		label: 'Elevador de plataforma',
	},
	{
		code: '22101803',
		label: 'Elevador de boom articulado',
	},
	{
		code: '22101804',
		label: 'Elevador de boom telescópico',
	},
	{
		code: '22101900',
		label: 'Maquinaria y accesorios de construcción de edificios',
	},
	{
		code: '22101901',
		label: 'Mezcladoras o plantas de concreto',
	},
	{
		code: '22101902',
		label: 'Mezcladoras de argamasa, pañete o mortero',
	},
	{
		code: '22101903',
		label: 'Mezcladoras de grada giratoria',
	},
	{
		code: '22101904',
		label: 'Máquinas de curado',
	},
	{
		code: '22101905',
		label: 'Distribuidoras de concreto',
	},
	{
		code: '22101906',
		label: 'Equipo de apuntalamiento',
	},
	{
		code: '22101907',
		label: 'Puntales de zanja',
	},
	{
		code: '22101908',
		label: 'Drenaje de cartón o drenaje de pabilo',
	},
	{
		code: '22102000',
		label: 'Equipo y maquinaria de demolición de edificios',
	},
	{
		code: '22102001',
		label: 'Kits de equipo de demolición',
	},
	{
		code: '22102002',
		label: 'Bola para demolición',

		palabrasSimilares: 'Bola de derribo',
	},
	{
		code: '23101500',
		label: 'Maquinaria para trabajar madera, piedra, cerámica y similares',
	},
	{
		code: '23101501',
		label: 'Caladoras',

		palabrasSimilares: 'Sierra de Vaivén',
	},
	{
		code: '23101502',
		label: 'Taladros',
	},
	{
		code: '23101503',
		label: 'Brochadoras',
	},
	{
		code: '23101504',
		label: 'Máquinas dobladoras',
	},
	{
		code: '23101505',
		label: 'Maquinas abre huecos',
	},
	{
		code: '23101506',
		label: 'Esmeriladoras',

		palabrasSimilares: 'Amoladora de banco, Esmeril de banco',
	},
	{
		code: '23101507',
		label: 'Troqueladoras',
	},
	{
		code: '23101508',
		label: 'Cortadoras',
	},
	{
		code: '23101509',
		label: 'Lijadoras',
	},
	{
		code: '23101510',
		label: 'Pulidoras',
	},
	{
		code: '23101511',
		label: 'Máquinas para tornear',
	},
	{
		code: '23101512',
		label: 'Sierras mecánicas',
	},
	{
		code: '23101513',
		label: 'Fresadoras',
	},
	{
		code: '23101514',
		label: 'Cepilladoras',
	},
	{
		code: '23101515',
		label: 'Máquinas para grabar',
	},
	{
		code: '23101516',
		label: 'Máquina de martillar con la peña de cuentas de vidrio',
	},
	{
		code: '23101517',
		label: 'Máquinas de sanblasteado',
	},
	{
		code: '23101518',
		label: 'Máquina de picar con chorro de perdigones',
	},
	{
		code: '23101519',
		label: 'Robots',
	},
	{
		code: '23101520',
		label: 'Máquinas Ram de electro descarga',
	},
	{
		code: '23101521',
		label: 'Máquina de descarga de electrodo de cátodo de alambre',
	},
	{
		code: '23101522',
		label: 'Rompevirutas',
	},
	{
		code: '23101525',
		label: 'Lijadora de huso oscilante',
	},
	{
		code: '23101528',
		label: 'Lijadora de tambor',
	},
	{
		code: '23101529',
		label: 'Rodillo esparcidor de goma',
	},
	{
		code: '23101530',
		label: 'Máquina cola de milano',
	},
	{
		code: '23101531',
		label: 'Máquina combinada de carpintería',
	},
	{
		code: '23101532',
		label: 'Máquina espigadora',
	},
	{
		code: '23101533',
		label: 'Prensa fría',
	},
	{
		code: '23101534',
		label: 'Aplicador de banda de orillos',
	},
	{
		code: '23101535',
		label: 'Máquina para unir espesores distintos',
	},
	{
		code: '23101536',
		label: 'Máquina de clavijas',
	},
	{
		code: '23101537',
		label: 'Lijadora de banda y disco',
	},
	{
		code: '23101538',
		label: 'Desbastadora para carpintería',
	},
	{
		code: '23111500',
		label: 'Maquinaria y equipo para destilado y transformación de petróleo',
	},
	{
		code: '23111501',
		label: 'Equipo de hidroprocesamiento de destilado',
	},
	{
		code: '23111502',
		label: 'Máquina para destilación de crudo',
	},
	{
		code: '23111503',
		label: 'Equipo de ruptura catalítica',
	},
	{
		code: '23111504',
		label: 'Equipo de hidro - ruptura',
	},
	{
		code: '23111505',
		label: 'Maquinaria de isomerización',
	},
	{
		code: '23111506',
		label: 'Máquina coquizadora',
	},
	{
		code: '23111507',
		label: 'Maquinaria para recuperación de gas',
	},
	{
		code: '23111600',
		label: 'Maquinaria de hidrotratamiento',
	},
	{
		code: '23111601',
		label: 'Hidrotratador de nafta',

		palabrasSimilares: 'Hidrotratadora de nafta',
	},
	{
		code: '23111602',
		label: 'Hidrotratador de destilado',

		palabrasSimilares: 'Hidrotratadora de destilado',
	},
	{
		code: '23111603',
		label: 'Hidrotratador de alimentación catalítica',

		palabrasSimilares: 'Hidrotratadora de alimentación catalítica',
	},
	{
		code: '23111604',
		label: 'Hidrotratador de lubricantes',

		palabrasSimilares: 'Hidrotratadora de lubricantes',
	},
	{
		code: '23111605',
		label: 'Hidrotratador de gasolina',

		palabrasSimilares: 'Hidrotratadora de gasolina',
	},
	{
		code: '23111606',
		label: 'Hidrotratador de aceite residual',

		palabrasSimilares: 'Hidrotratadora de aceite residual',
	},
	{
		code: '23121500',
		label: 'Maquinaria y accesorios para transformados textiles',
	},
	{
		code: '23121501',
		label: 'Maquinaria para bordados',
	},
	{
		code: '23121502',
		label: 'Maquinaria para afelpado',
	},
	{
		code: '23121503',
		label: 'Devanadoras o encarretadoras',
	},
	{
		code: '23121504',
		label: 'Máquinas de Torción',
	},
	{
		code: '23121505',
		label: 'Máquina para pegar puntadas',
	},
	{
		code: '23121506',
		label: 'Tejedoras',
	},
	{
		code: '23121507',
		label: 'Urdidoras',
	},
	{
		code: '23121508',
		label: 'Máquinas de acabados',
	},
	{
		code: '23121509',
		label: 'Husos',
	},
	{
		code: '23121510',
		label: 'Máquinas para hacer encaje',
	},
	{
		code: '23121511',
		label: 'Máquinas para lavar textiles',
	},
	{
		code: '23121512',
		label: 'Máquinas cardadoras',
	},
	{
		code: '23121513',
		label: 'Enrolladoras de ensamble de hilaza',
	},
	{
		code: '23121514',
		label: 'Cortadora de hilaza',
	},
	{
		code: '23121515',
		label: 'Máquina levantadora',
	},
	{
		code: '23121516',
		label: 'Máquina quemadora',
	},
	{
		code: '23121517',
		label: 'Máquina enrolladora fina',
	},
	{
		code: '23121518',
		label: 'Máquina para desenrollar hebras',
	},
	{
		code: '23121519',
		label: 'Sistema de hilado de laboratorio',
	},
	{
		code: '23121520',
		label: 'Máquina combadora',
	},
	{
		code: '23121600',
		label: 'Maquinaria, equipo y accesorios para trabajo textil',
	},
	{
		code: '23121601',
		label: 'Máquinas para forrar botones',
	},
	{
		code: '23121602',
		label: 'Máquinas para coser botones',
	},
	{
		code: '23121603',
		label: 'Máquinas de hacer ojales',
	},
	{
		code: '23121604',
		label: 'Máquinas cortadoras de tela',
	},
	{
		code: '23121605',
		label: 'Máquinas para rellenar cojines',
	},
	{
		code: '23121606',
		label: 'Máquinas plegadoras o rebobinadoras',
	},
	{
		code: '23121607',
		label: 'Máquinas blanqueadoras',
	},
	{
		code: '23121608',
		label: 'Máquinas para doblar telas o paños',
	},
	{
		code: '23121609',
		label: 'Máquinas bobinadoras o desenrolladoras',
	},
	{
		code: '23121610',
		label: 'Máquinas para teñir',
	},
	{
		code: '23121611',
		label: 'Máquinas cortadoras o festoneadoras',
	},
	{
		code: '23121612',
		label: 'Agujas para máquina de cose',
	},
	{
		code: '23121613',
		label: 'Máquinas para procesar seda',
	},
	{
		code: '23121614',
		label: 'Máquinas de coser',
	},
	{
		code: '23121615',
		label: 'Mesas para cortar telas',
	},
	{
		code: '23121616',
		label: 'Probadoras de tinturas',
	},
	{
		code: '23131500',
		label: 'Equipo y suministros de trituración, lijado y pulido',
	},
	{
		code: '23131501',
		label: 'Compuestos abrasivos',
	},
	{
		code: '23131502',
		label: 'Ruedas de fieltro',
	},
	{
		code: '23131503',
		label: 'Ruedas de esmerilado',
	},
	{
		code: '23131504',
		label: 'Compuestos para brillado',
	},
	{
		code: '23131505',
		label: 'Cabezas de pulir',
	},
	{
		code: '23131506',
		label: 'Ruedas para pulir',
	},
	{
		code: '23131507',
		label: 'Tela para lijar',
	},
	{
		code: '23131508',
		label: 'Tambores para lijar',
	},
	{
		code: '23131509',
		label: 'Cilindros o brilladoras',
	},
	{
		code: '23131510',
		label: 'Suministros o medios de cilindro',
	},
	{
		code: '23131511',
		label: 'Pivotes de agua',
	},
	{
		code: '23131512',
		label: 'Bandejas de agua',
	},
	{
		code: '23131513',
		label: 'Bloques para lijar',
	},
	{
		code: '23131514',
		label: 'Piedras montadas',
	},
	{
		code: '23131515',
		label: 'Rectificadora de piedra de moler',
	},
	{
		code: '23131600',
		label: 'Equipo y accesorios de facetaje',
	},
	{
		code: '23131601',
		label: 'Accesorios para hacer facetas o pulir caras',
	},
	{
		code: '23131602',
		label: 'Máquinas para hacer facetas o pulir caras',
	},
	{
		code: '23131603',
		label: 'Pulidoras para hacer facetas o pulir caras',
	},
	{
		code: '23131604',
		label: 'Sierras para hacer facetas o pulir caras',
	},
	{
		code: '23131700',
		label: 'Equipo del cabbing',
	},
	{
		code: '23131701',
		label: 'Accesorios para hacer cabuchones',

		palabrasSimilares: 'Accesorios para hacer cabujones',
	},
	{
		code: '23131702',
		label: 'Cinturones para hacer cabuchones',

		palabrasSimilares: 'Cinturones para hacer cabujones',
	},
	{
		code: '23131703',
		label: 'Discos para hacer cabuchones',

		palabrasSimilares: 'Discos para hacer cabujones',
	},
	{
		code: '23131704',
		label: 'Máquinas para hacer cabuchones',

		palabrasSimilares: 'Máquinas para hacer cabujones',
	},
	{
		code: '23141600',
		label: 'Maquinaria y accesorios para el preparado del cuero',
	},
	{
		code: '23141601',
		label: 'Máquinas para descarnar cuero',
	},
	{
		code: '23141602',
		label: 'Máquinas para curtir cuero',
	},
	{
		code: '23141603',
		label: 'Máquinas para teñir cuero',
	},
	{
		code: '23141604',
		label: 'Máquinas para desengrasar cuero',
	},
	{
		code: '23141605',
		label: 'Prensadoras de cuero',
	},
	{
		code: '23141606',
		label: 'Máquina escurridora',
	},
	{
		code: '23141607',
		label: 'Impermeable',
	},
	{
		code: '23141608',
		label: 'Máquina para quitar el pelo',
	},
	{
		code: '23141700',
		label: 'Maquinaria y accesorios para trabajar y reparar cuero',
	},
	{
		code: '23141701',
		label: 'Máquinas cortadoras de cuero',
	},
	{
		code: '23141702',
		label: 'Máquinas ribeteadoras de cuero',
	},
	{
		code: '23141703',
		label: 'Máquinas clavadoras de cuero',
	},
	{
		code: '23141704',
		label: 'Equipo para talabartería',

		palabrasSimilares: 'Equipo para guarnicionería',
	},
	{
		code: '23151500',
		label:
			'Maquinaria, equipo y suministros para la elaboración de caucho y plástico',
	},
	{
		code: '23151501',
		label: 'Máquinas de moldeo por soplado',
	},
	{
		code: '23151502',
		label: 'Máquinas revestidoras',
	},
	{
		code: '23151503',
		label: 'Extrusoras',
	},
	{
		code: '23151504',
		label: 'Máquinas de moldeo por inyección',
	},
	{
		code: '23151506',
		label: 'Prensas de caucho o plástico',
	},
	{
		code: '23151507',
		label: 'Máquinas termo modeladoras',
	},
	{
		code: '23151508',
		label: 'Máquinas para moldear al vacío',
	},
	{
		code: '23151509',
		label: 'Vulcanizadoras',
	},
	{
		code: '23151510',
		label: 'Maquinaria para cortar plástico',
	},
	{
		code: '23151511',
		label: 'Maquinaria para triturar plástico',
	},
	{
		code: '23151512',
		label: 'Molinos de caucho o plástico',
	},
	{
		code: '23151513',
		label: 'Moldes de extrusión de caucho o plástico',
	},
	{
		code: '23151514',
		label: 'Moldes de inyección para plástico',
	},
	{
		code: '23151515',
		label: 'Moldes de termoformar',
	},
	{
		code: '23151516',
		label: 'Pasadores del eyector',
	},
	{
		code: '23151517',
		label: 'Cilindro imd para decoración en molde',
	},
	{
		code: '23151518',
		label: 'Equipo de moldeo por inmersión',
	},
	{
		code: '23151519',
		label: 'Equipo de moldeado y procesamiento de uretano',
	},
	{
		code: '23151520',
		label: 'Equipo de moldeado giratorio',
	},
	{
		code: '23151600',
		label:
			'Maquinaria, equipo y suministros para la industria del cemento, cerámica y vidrio',
	},
	{
		code: '23151601',
		label: 'Sopladores o secadores',
	},
	{
		code: '23151602',
		label: 'Trituradoras',
	},
	{
		code: '23151603',
		label: 'Máquinas de soldadura por fusión o de estirado de vidrio',
	},
	{
		code: '23151604',
		label: 'Esmeriladoras p pulidoras',
	},
	{
		code: '23151606',
		label: 'Máquinas moldeadoras de cemento o cerámica o vidrio o similar',
	},
	{
		code: '23151607',
		label: 'Prensas',
	},
	{
		code: '23151608',
		label: 'Tamices',
	},
	{
		code: '23151609',
		label: 'Instrumento para soplar vidrio',
	},
	{
		code: '23151610',
		label: 'Extractor micropipeta',
	},
	{
		code: '23151700',
		label: 'Maquinaria, equipo y suministros de la industria óptica',
	},
	{
		code: '23151701',
		label: 'Máquinas para pulir lentes',
	},
	{
		code: '23151702',
		label: 'Equipo de medición de lentes',
	},
	{
		code: '23151703',
		label: 'Equipo de esmerilado de lentes',
	},
	{
		code: '23151704',
		label: 'Equipos de ensayo de lentes',
	},
	{
		code: '23151705',
		label: 'Equipo para recubrir el vacío óptico',
	},
	{
		code: '23151800',
		label: 'Maquinaria, equipo y suministros de la industria farmacéutica',
	},
	{
		code: '23151801',
		label: 'Equipo para llenado de ampolletas',
	},
	{
		code: '23151802',
		label:
			'Colocadores de tapas, insertadores de algodón o aplicadores de sellos de seguridad',
	},
	{
		code: '23151803',
		label: 'Máquinas encapsuladoras',
	},
	{
		code: '23151804',
		label: 'Reactores, fermentadores o digestores',
	},
	{
		code: '23151805',
		label: 'Máquinas dosificadoras de barrena para llenado o cerrado hermético',
	},
	{
		code: '23151806',
		label: 'Filtros o ultrafiltros farmacéuticos',
	},
	{
		code: '23151807',
		label: 'Secadores por congelación o liofilizadores',
	},
	{
		code: '23151808',
		label: 'Granuladores',
	},
	{
		code: '23151809',
		label: 'Máquinas farmacéuticas de tamizado',
	},
	{
		code: '23151810',
		label: 'Máquinas de procesado o llenado estéril o aséptico',
	},
	{
		code: '23151811',
		label: 'Máquinas para hacer ensayos de tabletas o cápsulas',
	},
	{
		code: '23151812',
		label: 'Contadores de tabletas',
	},
	{
		code: '23151813',
		label: 'Máquinas de hacer tabletas',
	},
	{
		code: '23151814',
		label: 'Equipo de producción de vacunas',
	},
	{
		code: '23151816',
		label: 'Columnas de cromatografía',
	},
	{
		code: '23151817',
		label: 'Medios de cromatografía',
	},
	{
		code: '23151818',
		label: 'Dispositivos de prueba de esterilidad',
	},
	{
		code: '23151819',
		label: 'Comprobadores de integridad de los filtros',
	},
	{
		code: '23151821',
		label: 'Adaptador de cartucho filtro',
	},
	{
		code: '23151822',
		label:
			'Adaptadores o conectores o accesorios para soportes de filtros farmacéuticos',
	},
	{
		code: '23151823',
		label: 'Radiofármaco de diagnóstico',
	},
	{
		code: '23151824',
		label: 'Equipos o accesorios para hacer compuestos farmacológicos',
	},
	{
		code: '23151825',
		label: 'Unidad de tanque para filtrar y mezclar',
	},
	{
		code: '23151900',
		label:
			'Maquinaria, equipo y suministros para la elaboración y el procesamiento de papel',
	},
	{
		code: '23151901',
		label: 'Cortadores',
	},
	{
		code: '23151902',
		label: 'Rajadores',
	},
	{
		code: '23151903',
		label: 'Máquinas de lavado o para sacar el agua',
	},
	{
		code: '23151904',
		label: 'Rebobinadoras',
	},
	{
		code: '23151905',
		label: 'Máquinas para hacer pulpa de madera',
	},
	{
		code: '23151906',
		label: 'Calandrias para hacer papel o cartón',
	},
	{
		code: '23151907',
		label: 'Cedazo o tambor de pantalla',
	},
	{
		code: '23151908',
		label: 'Refinador mecánico de pulpa',
	},
	{
		code: '23151909',
		label: 'Máquina para hacer pulpa',
	},
	{
		code: '23151910',
		label: 'Flotador',
	},
	{
		code: '23151911',
		label: 'Máquina de papel',
	},
	{
		code: '23152000',
		label: 'Manejo del Web y maquinaria de control y equipo y suministros',
	},
	{
		code: '23152001',
		label: 'Tratadores de corona',
	},
	{
		code: '23152002',
		label: 'Tratadores por llama',
	},
	{
		code: '23152100',
		label: 'Maquinaria y equipo de separación',
	},
	{
		code: '23152101',
		label: 'Pantallas o piezas o equipo vibratorios de separación',
	},
	{
		code: '23152102',
		label: 'Pantallas o piezas o equipo estacionario de separación',
	},
	{
		code: '23152103',
		label: 'Pantallas o piezas o equipo de clasificación neumática',
	},
	{
		code: '23152104',
		label: 'Equipo o piezas y pantallas de separación centrífuga',
	},
	{
		code: '23152105',
		label: 'Vibrador electromecánico',
	},
	{
		code: '23152106',
		label: 'Pantalla vibratoria de separación de perfil uretano',
	},
	{
		code: '23152107',
		label: 'Pantalla vibratoria de separación de alambre de perfil',
	},
	{
		code: '23152108',
		label: 'Pantalla vibratoria de separación de la estampadora',
	},
	{
		code: '23152109',
		label:
			'Pantalla vibratoria de separación de la estampadora recubierta de uretano',
	},
	{
		code: '23152110',
		label: 'Pantalla vibratoria de separación de alambre de uretano o caucho',
	},
	{
		code: '23152111',
		label: 'Pantalla vibratoria de separación de alambre tejido',
	},
	{
		code: '23152112',
		label:
			'Pantalla vibratoria de separación de alambre tejido recubierto de uretano',
	},
	{
		code: '23152113',
		label: 'Tamiz vibratorio',
	},
	{
		code: '23152200',
		label: 'Mesa y estantes para fabricación',
	},
	{
		code: '23152201',
		label: 'Mesas rotatorias',
	},
	{
		code: '23152202',
		label: 'Bancos de apilado',
	},
	{
		code: '23152203',
		label: 'Bancos de pruebas de componentes o motores',
	},
	{
		code: '23152204',
		label: 'Resguardo de la máquina',
	},
	{
		code: '23152205',
		label: 'Mesas de sierra de banda',
	},
	{
		code: '23152206',
		label: 'Protección de barrera',
	},
	{
		code: '23152900',
		label: 'Maquinaria empacadora',
	},
	{
		code: '23152901',
		label: 'Maquinaria de envolver',
	},
	{
		code: '23152902',
		label: 'Maquinaria de formar, rellenar o sellar',
	},
	{
		code: '23152903',
		label: 'Empacadora al vacío',
	},
	{
		code: '23152904',
		label: 'Tolvas para empacar',
	},
	{
		code: '23152905',
		label: 'Máquinas para el formado de cartón',
	},
	{
		code: '23152906',
		label: 'Maquinas para encintar',
	},
	{
		code: '23152907',
		label: 'Suministros para maquinaria de empaque',
	},
	{
		code: '23152908',
		label: 'Máquina para lavar botellas',
	},
	{
		code: '23152909',
		label: 'Máquina para organizar materias primas',
	},
	{
		code: '23152910',
		label: 'Máquina para hacer bolsas automáticas',
	},
	{
		code: '23153000',
		label: 'Dispositivos y sistemas de guía, posicionamiento y sujeción',
	},
	{
		code: '23153001',
		label: 'Plantilla de medición',
	},
	{
		code: '23153002',
		label: 'Plantilla de guía',
	},
	{
		code: '23153003',
		label: 'Plantilla maestro',
	},
	{
		code: '23153004',
		label: 'Plantilla de aguja',
	},
	{
		code: '23153005',
		label: 'Plantilla de eje',
	},
	{
		code: '23153006',
		label: 'Plantilla de chequeo',
	},
	{
		code: '23153007',
		label: 'Plantilla de instalación',
	},
	{
		code: '23153008',
		label: 'Plantilla de cámara',
	},
	{
		code: '23153009',
		label: 'Plantilla de captación',
	},
	{
		code: '23153010',
		label: 'Plantilla de eliminación',
	},
	{
		code: '23153011',
		label: 'Plantilla de boquilla',
	},
	{
		code: '23153012',
		label: 'Plantilla de deslizamiento',
	},
	{
		code: '23153013',
		label: 'Plantilla de centrado',
	},
	{
		code: '23153014',
		label: 'Plantilla de inspección',
	},
	{
		code: '23153015',
		label: 'Plantilla de alimentador',
	},
	{
		code: '23153016',
		label: 'Plantilla de embrague',
	},
	{
		code: '23153017',
		label: 'Plantilla de alineación',
	},
	{
		code: '23153018',
		label: 'Plantilla de posicionamiento',
	},
	{
		code: '23153019',
		label: 'Placa elevadora',
	},
	{
		code: '23153020',
		label: 'Guía de cinta',
	},
	{
		code: '23153021',
		label: 'Mordaza de alimentación de cinta',
	},
	{
		code: '23153022',
		label: 'Alimentador mecánico',
	},
	{
		code: '23153023',
		label: 'Arnés del alimentador',
	},
	{
		code: '23153024',
		label: 'Mordaza de alimentación',
	},
	{
		code: '23153025',
		label: 'Agarrador mecánico',
	},
	{
		code: '23153026',
		label: 'Mordaza de soporte',
	},
	{
		code: '23153027',
		label: 'Conjunto de mordaza',
	},
	{
		code: '23153028',
		label: 'Mordaza estacionaria',
	},
	{
		code: '23153029',
		label: 'Bloque de plantilla',
	},
	{
		code: '23153030',
		label: 'Guías de desplazamiento lineal',
	},
	{
		code: '23153031',
		label: 'Plantillas de medición',
	},
	{
		code: '23153032',
		label: 'Plantilla de anillo',
	},
	{
		code: '23153033',
		label: 'Placa de garganta',
	},
	{
		code: '23153034',
		label: 'Rieles de máquina',
	},
	{
		code: '23153035',
		label: 'Placa',
	},
	{
		code: '23153036',
		label: 'Placas de cerrojo',
	},
	{
		code: '23153037',
		label: 'Conjunto de rodillos de alimentación',
	},
	{
		code: '23153038',
		label: 'Caballete',
	},
	{
		code: '23153039',
		label: 'Carro de riel de maquinado',
	},
	{
		code: '23153100',
		label: 'Componentes y accesorios de maquinaria industrial',
	},
	{
		code: '23153101',
		label: 'Tope de paso',
	},
	{
		code: '23153102',
		label: 'Limitador de papel',
	},
	{
		code: '23153103',
		label: 'Almohadilla de topa',
	},
	{
		code: '23153129',
		label: 'Limpiador de la vía de máquina',
	},
	{
		code: '23153130',
		label: 'Soportes de máquina o aisladores de vibración',
	},
	{
		code: '23153131',
		label: 'Placas o barras o cintas de desgaste',
	},
	{
		code: '23153132',
		label: 'Deflectores de polvo',
	},
	{
		code: '23153133',
		label: 'Diente de rueda de cadena',
	},
	{
		code: '23153134',
		label: 'Rodillos de avance o de arrastre',
	},
	{
		code: '23153135',
		label: 'Cubiertas de rodillos de avance',
	},
	{
		code: '23153136',
		label: 'Conductores de aserrín',
	},
	{
		code: '23153137',
		label: 'Cubiertas guardapolvos de máquina',
	},
	{
		code: '23153138',
		label: 'Cabezales de corte o desbastado',
	},
	{
		code: '23153139',
		label: 'Camas guía',
	},
	{
		code: '23153140',
		label: 'Brazos articulados',
	},
	{
		code: '23153141',
		label: 'Alambre de máquina de electro descarga edm',
	},
	{
		code: '23153142',
		label: 'Fuelles de acordeón',
	},
	{
		code: '23153143',
		label: 'Boquillas de rociado',
	},
	{
		code: '23153144',
		label: 'Bloque de rociado',
	},
	{
		code: '23153145',
		label: 'Pistola de rociado',
	},
	{
		code: '23153200',
		label: 'Robótica',
	},
	{
		code: '23153201',
		label: 'Robots de pintura',
	},
	{
		code: '23153202',
		label: 'Robots para levantar o poner',
	},
	{
		code: '23153203',
		label: 'Robots de sellado adhesivo',
	},
	{
		code: '23153204',
		label: 'Robots de soldadura',
	},
	{
		code: '23153205',
		label: 'Robot para atender maquinaria',
	},
	{
		code: '23153206',
		label: 'Robot para remover material',
	},
	{
		code: '23153207',
		label: 'Robot de limpieza',
	},
	{
		code: '23153400',
		label: 'Ensambladoras',
	},
	{
		code: '23153401',
		label: 'Sistemas de aplicación de pegante o adhesivo',
	},
	{
		code: '23153402',
		label: 'Accesorios de ensamblaje',
	},
	{
		code: '23153403',
		label: 'Ensamblaje especializado',
	},
	{
		code: '23153404',
		label: 'Sistemas de ensamblaje para vehículos de chasis (vo)',
	},
	{
		code: '23153405',
		label: 'Ensamblaje de componentes ilimitados',
	},
	{
		code: '23153406',
		label: 'Líneas completas de tren motor',
	},
	{
		code: '23153407',
		label: 'Dispositivo de montaje de superficies',
	},
	{
		code: '23153408',
		label: 'Prueba de llenado',
	},
	{
		code: '23153409',
		label: 'Multihusillo fijo de corredera roscada',
	},
	{
		code: '23153410',
		label: 'Patines de carrocería',
	},
	{
		code: '23153411',
		label: 'Inflado de montaje de llantas',
	},
	{
		code: '23153412',
		label: 'Cizalla de guillotina',
	},
	{
		code: '23153413',
		label: 'Aplicación de recepción (pu) de inserción de vidrio',
	},
	{
		code: '23153414',
		label: 'Brazos articulados de movimiento giratorio',
	},
	{
		code: '23153415',
		label: 'Acoplamiento automático del chasis',
	},
	{
		code: '23153416',
		label: 'Componentes flexibles',
	},
	{
		code: '23153417',
		label: 'Maquinas diversas para ensamble',
	},
	{
		code: '23153418',
		label: 'Accesorios o herramientas par ensamble a la medida',
	},
	{
		code: '23153419',
		label: 'Máquina de estación sencilla a la medida',
	},
	{
		code: '23153420',
		label: 'Máquina para el ensamble de cables y alambres',
	},
	{
		code: '23153421',
		label: 'Máquina torcedora o hebradora',
	},
	{
		code: '23153500',
		label: 'Sistemas de pintura',
	},
	{
		code: '23153501',
		label: 'Sistemas de aplicación de pintura',
	},
	{
		code: '23153502',
		label: 'Caseta para reparación de pintura',
	},
	{
		code: '23153503',
		label: 'Hornos de sistemas de pintura',
	},
	{
		code: '23153504',
		label: 'Ingeniería o disposición de la planta de pintura',
	},
	{
		code: '23153505',
		label: 'Sistema de pintura llave en mano',
	},
	{
		code: '23153506',
		label: 'Sistema de pintura de fosfato o “e coat”',
	},
	{
		code: '23153507',
		label: 'Taller de pintura variado',
	},
	{
		code: '23153508',
		label: 'Sistemas diversos de pintura',
	},
	{
		code: '23153600',
		label: 'Máquinas para el marcado de partes',
	},
	{
		code: '23153601',
		label: 'Máquinas para el arcado al ácido',

		palabrasSimilares: 'Máquinas de marcado ácido',
	},
	{
		code: '23153602',
		label: 'Maquinas para el marcado láser',
	},
	{
		code: '23153603',
		label: 'Máquinas para el marcado pinstamp',
	},
	{
		code: '23153604',
		label: 'Herramienta para el marcado láser',
	},
	{
		code: '23153605',
		label: 'Máquina para el marcado con puntos',
	},
	{
		code: '23153606',
		label: 'Máquina para el marcado por electro – grabado',
	},
	{
		code: '23153607',
		label: 'Máquina para el marcado por chorro de tinta',
	},
	{
		code: '23153608',
		label: 'Máquina para el marcado de tubos electrónicos',
	},
	{
		code: '23153700',
		label: 'Equipo de aseguramiento o torque de precisión',
	},
	{
		code: '23153701',
		label: 'Torquímetro de impacto',
	},
	{
		code: '23153702',
		label: 'Torquímetro',
	},
	{
		code: '23153800',
		label: 'Sistemas de revestimiento',
	},
	{
		code: '23153801',
		label: 'Fluxer electrostático',
	},
	{
		code: '23153802',
		label: 'Máquina para rebobinar bobinas eléctricas',
	},
	{
		code: '23161500',
		label: 'Máquinas y equipo de fundición',
	},
	{
		code: '23161501',
		label: 'Fuelles de fundición',
	},
	{
		code: '23161502',
		label: 'Quemadores de fundición',
	},
	{
		code: '23161503',
		label: 'Horno de secado del núcleo',
	},
	{
		code: '23161506',
		label: 'Crisoles de fundición',
	},
	{
		code: '23161507',
		label: 'Convertidores para fundición',
	},
	{
		code: '23161510',
		label: 'Máquinas fundidoras',
	},
	{
		code: '23161514',
		label: 'Prensas de dimensionamiento o grabado en relieve',
	},
	{
		code: '23161516',
		label: 'Máquina de troqueles de fundición',
	},
	{
		code: '23161517',
		label: 'Maquina moldeadora fundidora',
	},
	{
		code: '23161600',
		label: 'Suministros de fundición',
	},
	{
		code: '23161601',
		label: 'Fuelles de fundición',
	},
	{
		code: '23161602',
		label: 'Arcillas de fundición',
	},
	{
		code: '23161603',
		label: 'Frascos de fundición',
	},
	{
		code: '23161605',
		label: 'Cucharas de fundición',
	},
	{
		code: '23161606',
		label: 'Troqueles de fundición',
	},
	{
		code: '23161607',
		label: 'Arena de fundición',
	},
	{
		code: '23161608',
		label: 'Palas de fundición',
	},
	{
		code: '23161700',
		label: 'Troqueles y herramientas de fundición',
	},
	{
		code: '23161701',
		label: 'Caja para la formación de núcleos de arena',
	},
	{
		code: '23161702',
		label: 'Patrón de formación de troqueles de arena',
	},
	{
		code: '23181500',
		label: 'Maquinaria para la elaboración de alimentos',
	},
	{
		code: '23181501',
		label: 'Maquinaria de llenado',
	},
	{
		code: '23181502',
		label: 'Maquinaria de molido',
	},
	{
		code: '23181504',
		label: 'Maquinaria de tamizado',
	},
	{
		code: '23181505',
		label: 'Maquinaria para deshidratación',
	},
	{
		code: '23181506',
		label: 'Maquinaria de lavado',
	},
	{
		code: '23181507',
		label: 'Maquinaria de triturado',
	},
	{
		code: '23181508',
		label: 'Máquinas blanqueadoras',
	},
	{
		code: '23181509',
		label: 'Maquinaria de clasificación',
	},
	{
		code: '23181510',
		label: 'Atadoras de carne',
	},
	{
		code: '23181511',
		label: 'Conformadora',
	},
	{
		code: '23181512',
		label: 'Enfriadora',
	},
	{
		code: '23181513',
		label: 'Preespolvoreadora',
	},
	{
		code: '23181514',
		label: 'Máquina para elaboración de queso',
	},
	{
		code: '23181515',
		label: 'Máquina para elaborar tofu (queso de soya)',
	},
	{
		code: '23181516',
		label: 'Máquina para elaborar fideos',
	},
	{
		code: '23181517',
		label: 'Máquina para elaborar frijol de soya fermentado',
	},
	{
		code: '23181518',
		label: 'Dispositivo para fermentar alimentos',
	},
	{
		code: '23181600',
		label: 'Maquinaria para corte de alimentos',
	},
	{
		code: '23181601',
		label: 'Maquinaria para cortar en cubos',
	},
	{
		code: '23181602',
		label: 'Máquina tajadora',
	},
	{
		code: '23181603',
		label: 'Máquina picadora',
	},
	{
		code: '23181604',
		label: 'Máquina cortadora',
	},
	{
		code: '23181605',
		label: 'Máquina ralladora',
	},
	{
		code: '23181606',
		label: 'Máquina peladora',
	},
	{
		code: '23181700',
		label: 'Maquinaria para cocinar o ahumar alimentos',
	},
	{
		code: '23181701',
		label: 'Máquina para ahumar',
	},
	{
		code: '23181702',
		label: 'Maquinaria para asar',
	},
	{
		code: '23181703',
		label: 'Maquinaria para cocinar',
	},
	{
		code: '23181704',
		label: 'Máquina para cocinar al vapor',
	},
	{
		code: '23181705',
		label: 'Máquina para esterilizar alimentos',
	},
	{
		code: '23181800',
		label: 'Maquinaria industrial para elaboración de bebidas',
	},
	{
		code: '23181801',
		label: 'Equipo y suministros para elaboración de café',
	},
	{
		code: '23181802',
		label: 'Maquinaria para elaboración de zumos',
	},
	{
		code: '23181803',
		label: 'Máquinas para hacer hielo',
	},
	{
		code: '23181804',
		label: 'Máquinas para hacer helados',
	},
	{
		code: '23181805',
		label: 'Partes y accesorios de máquinas para hacer hielo',
	},
	{
		code: '23181806',
		label: 'Esterilizador de bebidas',
	},
	{
		code: '23191000',
		label: 'Mezcladores por tandas',
	},
	{
		code: '23191001',
		label: 'Mezcladora cambiadora',
	},
	{
		code: '23191002',
		label: 'Mezcladora de cuchilla helicoidal',
	},
	{
		code: '23191003',
		label: 'Mezcladora con doble brazo amasador',
	},
	{
		code: '23191004',
		label: 'Mezcladoras intensivas',
	},
	{
		code: '23191005',
		label: 'Mezcladoras de rodillo',
	},
	{
		code: '23191006',
		label: 'Mezcladora de arena',
	},
	{
		code: '23191100',
		label: 'Mezcladores continuos',
	},
	{
		code: '23191101',
		label: 'Mezcladora de tornillo sencillo',
	},
	{
		code: '23191102',
		label: 'Mezcladora de tornillo doble',
	},
	{
		code: '23191200',
		label: 'Piezas de mezcladora y accesorios',
	},
	{
		code: '23191201',
		label: 'Rastrillos de mezcladora',
	},
	{
		code: '23191202',
		label: 'Cuchillas de mezcladora',
	},
	{
		code: '23201000',
		label: 'Sistemas de contacto líquido gas',
	},
	{
		code: '23201001',
		label: 'Columnas de placa',
	},
	{
		code: '23201002',
		label: 'Columnas cargadas',
	},
	{
		code: '23201003',
		label: 'Contacto de líquido disperso',
	},
	{
		code: '23201004',
		label: 'Columna de pared mojada',
	},
	{
		code: '23201005',
		label: 'Columnas de burbuja',
	},
	{
		code: '23201006',
		label: 'Columna de destilación',
	},
	{
		code: '23201007',
		label: 'Columna cargada de destilación',
	},
	{
		code: '23201008',
		label: 'Bandeja de destilación',
	},
	{
		code: '23201100',
		label: 'Absorción e intercambio de iones',
	},
	{
		code: '23201101',
		label: 'Recipiente absorbente',
	},
	{
		code: '23201102',
		label: 'Recipiente absorbente a presión ambiental',
	},
	{
		code: '23201200',
		label: 'Equipo de secado industrial',
	},
	{
		code: '23201201',
		label: 'Secadores atomizadores',
	},
	{
		code: '23201202',
		label: 'Secadores de aire',
	},
	{
		code: '23201203',
		label: 'Secadores de lecho fluido',
	},
	{
		code: '23201204',
		label: 'Equipo para el secado de comida',
	},
	{
		code: '23211000',
		label: 'Maquinaria de ensamblaje electrónico y equipo de soporte',
	},
	{
		code: '23211001',
		label: 'Colocadores de chips',
	},
	{
		code: '23211002',
		label: 'Maquinaria suministradora de pegamento',
	},
	{
		code: '23211003',
		label: 'Maquina de inserción de terminales',
	},
	{
		code: '23211100',
		label: 'Maquinaria de procesamiento y fabricación electrónica',
	},
	{
		code: '23211101',
		label: 'Sistemas de procesamiento de semiconductores',
	},
	{
		code: '23211102',
		label: 'Máquina para hacer tarjetas de circuitos impresos',
	},
	{
		code: '23211103',
		label: 'Pegador de alambre al wafer',
	},
	{
		code: '23211104',
		label: 'Monitor de inspección de chip semiconductor',
	},
	{
		code: '23211105',
		label: 'Dispositivo para sellar porosidad o impregnación de vacío',
	},
	{
		code: '23211106',
		label: 'Implantador de iones',
	},
	{
		code: '23221000',
		label: 'Equipo y maquinaria del departamento de recepción',
	},
	{
		code: '23221001',
		label: 'Sistema de descarga de jaulas',
	},
	{
		code: '23221002',
		label: 'Lavador de jaulas',
	},
	{
		code: '23221100',
		label: 'Equipo y maquinaria del departamento de matanza y desplumamiento',
	},
	{
		code: '23221101',
		label: 'Aturdidor',
	},
	{
		code: '23221102',
		label: 'Máquina desplumadora de pollos',
	},
	{
		code: '23221200',
		label: 'Equipo y maquinaria del departamento de eviscerado',
	},
	{
		code: '23221201',
		label: 'Cortador de aves',
	},
	{
		code: '23231000',
		label: 'Descortezadoras y accesorios',
	},
	{
		code: '23231001',
		label: 'Portaherramientas',
	},
	{
		code: '23231002',
		label: 'Herramienta de carburo',
	},
	{
		code: '23231100',
		label: 'Sierras de cinta y accesorios',
	},
	{
		code: '23231101',
		label: 'Rueda de sierra de cinta',
	},
	{
		code: '23231102',
		label: 'Guía de sierra',
	},
	{
		code: '23231200',
		label: 'Sierras circulares y accesorios',
	},
	{
		code: '23231201',
		label: 'Espaciador de sierra',
	},
	{
		code: '23231202',
		label: 'Eje de sierra',
	},
	{
		code: '23231300',
		label: 'Canteadoras',
	},
	{
		code: '23231301',
		label: 'Rodillo alimentador',
	},
	{
		code: '23231302',
		label: 'Rodillo de sujeción',
	},
	{
		code: '23231400',
		label: 'Sierras de recorte y accesorios',
	},
	{
		code: '23231401',
		label: 'Guía de alineación de la madera',
	},
	{
		code: '23231402',
		label: 'Sierra cero',
	},
	{
		code: '23231500',
		label: 'Clasificadoras de maderos y accesorios',
	},
	{
		code: '23231501',
		label: 'Barras portasierra',
	},
	{
		code: '23231502',
		label: 'Eslinga de contenedor',
	},
	{
		code: '23231600',
		label: 'Apiladoras de maderos y accesorios',
	},
	{
		code: '23231601',
		label: 'Soporte de reborde',
	},
	{
		code: '23231602',
		label: 'Rodillo de terminación',
	},
	{
		code: '23231700',
		label: 'Secaderos de madera y equipo y accesorios',
	},
	{
		code: '23231701',
		label: 'Deflector',
	},
	{
		code: '23231800',
		label: 'Troceadoras de madera y accesorios',
	},
	{
		code: '23231801',
		label: 'Fijador de cuchilla',
	},
	{
		code: '23231900',
		label: 'Cepilladoras y accesorios',
	},
	{
		code: '23231901',
		label: 'Lecho base',
	},
	{
		code: '23231902',
		label: 'Placa trasera',
	},
	{
		code: '23231903',
		label: 'Guía refrigerada por agua',
	},
	{
		code: '23232000',
		label: 'Terminadoras de madera y accesorios',
	},
	{
		code: '23232001',
		label: 'Porta cuchillas',
	},
	{
		code: '23232100',
		label: 'Sierras retestadoras y accesorios',
	},
	{
		code: '23232101',
		label: 'Soporte guía ajustable',
	},
	{
		code: '23232200',
		label: 'Empalmadoras por entalladura múltiple y accesorios',
	},
	{
		code: '23232201',
		label: 'Boquilla de pegamento',
	},
	{
		code: '23241400',
		label: 'Máquinas rectificadoras de metales',
	},
	{
		code: '23241401',
		label: 'Rectificador de banco',
	},
	{
		code: '23241402',
		label: 'Máquina de rectificar superficies',
	},
	{
		code: '23241403',
		label: 'Maquina de rectificación interna',
	},
	{
		code: '23241404',
		label: 'Maquina de rectificación externa',
	},
	{
		code: '23241405',
		label: 'Maquina de rectificación sin centro',
	},
	{
		code: '23241406',
		label: 'Maquina ultrasónica',
	},
	{
		code: '23241407',
		label: 'Máquina de rectificación cilíndrica',
	},
	{
		code: '23241408',
		label: 'Máquina de rectificación de cuchillo',
	},
	{
		code: '23241409',
		label: 'Máquina de rectificación de perfil óptico',
	},
	{
		code: '23241410',
		label: 'Máquina de rectificación de tornillo sinfín',
	},
	{
		code: '23241411',
		label: 'Máquina pulidora',
	},
	{
		code: '23241500',
		label: 'Máquinas cortadoras de metales',
	},
	{
		code: '23241501',
		label: 'Equipo de maquinado abrasivo a chorro',
	},
	{
		code: '23241502',
		label: 'Máquina electromecánica ecm',
	},
	{
		code: '23241503',
		label: 'Máquina de cortado con llama',
	},
	{
		code: '23241504',
		label: 'Herramienta de corte de engranajes',
	},
	{
		code: '23241505',
		label: 'Máquina láser para cortar',
	},
	{
		code: '23241506',
		label: 'Máquina de corte plasma',
	},
	{
		code: '23241507',
		label: 'Máquina de aserrado y corte',
	},
	{
		code: '23241508',
		label: 'Máquina de corte con chorro de agua',
	},
	{
		code: '23241509',
		label: 'Máquina de sierra de banda para metal',
	},
	{
		code: '23241510',
		label: 'Máquina cizalladora de metal',
	},
	{
		code: '23241511',
		label: 'Máquina para hacer roscas',
	},
	{
		code: '23241600',
		label: 'Herramientas cortadoras de metales',
	},
	{
		code: '23241601',
		label: 'Herramienta perforador',
	},
	{
		code: '23241602',
		label: 'Punzón',
	},
	{
		code: '23241603',
		label: 'Máquina para avellanado',
	},
	{
		code: '23241605',
		label: 'Carburo de perforación',
	},
	{
		code: '23241606',
		label: 'Taladros de acero de alta velocidad',
	},
	{
		code: '23241608',
		label: 'Cortador de quemador',
	},
	{
		code: '23241609',
		label: 'Cuchillos y biseles',
	},
	{
		code: '23241610',
		label: 'Cortador de tubos',
	},
	{
		code: '23241611',
		label: 'Escariador',
	},
	{
		code: '23241612',
		label: 'Cortador de formas',
	},
	{
		code: '23241613',
		label: 'Cortador de cepillado',
	},
	{
		code: '23241614',
		label: 'Fresadora sólida',
	},
	{
		code: '23241615',
		label: 'Grifos',
	},
	{
		code: '23241616',
		label: 'Cortador de cable o alambre',
	},
	{
		code: '23241617',
		label: 'Escariador de hoyos para pines',
	},
	{
		code: '23241618',
		label: 'Escariador alineador de hoyos',
	},
	{
		code: '23241619',
		label: 'Escariador de tubos',
	},
	{
		code: '23241620',
		label: 'Escariador de concha',
	},
	{
		code: '23241621',
		label: 'Cuchilla de sierra de varilla',
	},
	{
		code: '23241622',
		label: 'Cortador de ángulo sencillo',
	},
	{
		code: '23241623',
		label: 'Fresadora lateral',
	},
	{
		code: '23241624',
		label: 'Fresadora cilíndrica plana',
	},
	{
		code: '23241625',
		label: 'Fresadora de concha para extremos',
	},
	{
		code: '23241626',
		label: 'Cortador convexo',
	},
	{
		code: '23241627',
		label: 'Cortador cóncavo',
	},
	{
		code: '23241628',
		label: 'Cortador redondeador de esquinas',
	},
	{
		code: '23241629',
		label: 'Sierra para hacer ranuras en metal',
	},
	{
		code: '23241630',
		label: 'Cortador de ranuras en t',
	},
	{
		code: '23241631',
		label: 'Cortador de asiento woodruff',
	},
	{
		code: '23241632',
		label: 'Cortador de cola de milano',
	},
	{
		code: '23241633',
		label: 'Cortador conformador de engranajes',
	},
	{
		code: '23241634',
		label: 'Taladro largo',
	},
	{
		code: '23241635',
		label: 'Taladro de precisión',
	},
	{
		code: '23241636',
		label: 'Taladros de herrero',
	},
	{
		code: '23241637',
		label: 'Taladro de hoyo profundo',
	},
	{
		code: '23241638',
		label: 'Cortador de hoyos con punta de carburo',
	},
	{
		code: '23241639',
		label: 'Taladro de huso',
	},
	{
		code: '23241640',
		label: 'Taladro multi – pasos',
	},
	{
		code: '23241641',
		label: 'Taladro multi paso hex',
	},
	{
		code: '23241642',
		label: 'Roscadora de velocidad',
	},
	{
		code: '23241643',
		label: 'Escariador largo',
	},
	{
		code: '23241644',
		label: 'Láser de dióxido de carbono',
	},
	{
		code: '23241645',
		label: 'Escariador limador de precisión de agujeros',
	},
	{
		code: '23241646',
		label: 'Escariador ajustable',
	},
	{
		code: '23241647',
		label: 'Escariador cónico de agujero de precisión',
	},
	{
		code: '23241700',
		label: 'Máquinas rebabadoras de metales',
	},
	{
		code: '23241701',
		label: 'Maquina de granallado',
	},
	{
		code: '23241702',
		label: 'Máquina rebabadora de energía térmica',
	},
	{
		code: '23241703',
		label: 'Máquina de terminado vibradora o de barril',
	},
	{
		code: '23241800',
		label: 'Máquinas taladradoras de metales',
	},
	{
		code: '23241801',
		label: 'Taladro radial o prensa de taladro',
	},
	{
		code: '23241802',
		label: 'Taladro de segmentos',
	},
	{
		code: '23241803',
		label: 'Taladro de cañón',
	},
	{
		code: '23241804',
		label: 'Taladro controlado numéricamente',
	},
	{
		code: '23241805',
		label: 'Máquina de taladro de mesa rotativa',
	},
	{
		code: '23241806',
		label: 'Máquina de taladro de múltiples cabezas de huso',
	},
	{
		code: '23241807',
		label: 'Máquina de taladro de brazo radial',
	},
	{
		code: '23241808',
		label: 'Máquina de taladro de hoyo profundo',
	},
	{
		code: '23241809',
		label: 'Máquina de taladro de banco',
	},
	{
		code: '23241900',
		label: 'Máquinas perforadoras de metales',
	},
	{
		code: '23241901',
		label: 'Máquina perforadora original',
	},
	{
		code: '23241902',
		label: 'Máquina perforadora con plantilla',
	},
	{
		code: '23241903',
		label: 'Máquinas para dar formas al metal',
	},
	{
		code: '23241904',
		label: 'Máquinas para dar formas internas al metal',
	},
	{
		code: '23241905',
		label: 'Máquinas para dar formas al metal en la superficie',
	},
	{
		code: '23241906',
		label: 'Máquina perforadora vertical',
	},
	{
		code: '23242100',
		label: 'Accesorios para máquinas de cortar metales',
	},
	{
		code: '23242101',
		label: 'Cortadora de barras o varillas',
	},
	{
		code: '23242102',
		label: 'Soporte de electrodos',
	},
	{
		code: '23242103',
		label: 'Relieve de forma',
	},
	{
		code: '23242104',
		label: 'Herramientas o brocas de formado',
	},
	{
		code: '23242105',
		label: 'Inserto indexable',
	},
	{
		code: '23242106',
		label: 'Cuerpos o soportes de herramientas indexables',
	},
	{
		code: '23242107',
		label: 'Inserto de carburo',
	},
	{
		code: '23242108',
		label: 'Inserto de cerámica',
	},
	{
		code: '23242109',
		label: 'Inserto de diamante',
	},
	{
		code: '23242110',
		label: 'Inserto de acero',
	},
	{
		code: '23242111',
		label: 'Torreta de torno',
	},
	{
		code: '23242112',
		label: 'Cuchilla de sierra de banda para cortar metal',
	},
	{
		code: '23242113',
		label: 'Cuchilla de sierra circular para cortar metal',
	},
	{
		code: '23242114',
		label: 'Soportes firmes',
	},
	{
		code: '23242115',
		label: 'Tailstock',
	},
	{
		code: '23242116',
		label: 'Anexo de máquina de enroscado',
	},
	{
		code: '23242117',
		label: 'Anexo de máquina de hacer roscas',
	},
	{
		code: '23242118',
		label: 'Herramienta de moleteado',
	},
	{
		code: '23242119',
		label: 'Broca de torno',
	},
	{
		code: '23242120',
		label: 'Base de tabla de máquina',
	},
	{
		code: '23242200',
		label: 'Máquinas para fabricación de engranajes',
	},
	{
		code: '23242201',
		label: 'Generador de engranajes biselados',
	},
	{
		code: '23242202',
		label: 'Máquina talladora de engranajes',
	},
	{
		code: '23242203',
		label: 'Máquina para el perfeccionamiento de engranajes',
	},
	{
		code: '23242204',
		label: 'Máquinas de volteado de engranajes',
	},
	{
		code: '23242205',
		label: 'Máquina para dar forma a engranajes',
	},
	{
		code: '23242206',
		label: 'Máquina de cepillado',
	},
	{
		code: '23242207',
		label: 'Máquina pulidora de engranajes',
	},
	{
		code: '23242208',
		label: 'Máquina para biselar los dientes de engranajes',
	},
	{
		code: '23242300',
		label: 'Tornos y centros de torneado',
	},
	{
		code: '23242301',
		label: 'Máquina  automática de barra',
	},
	{
		code: '23242302',
		label: 'Torno o máquina sujetadora automática',
	},
	{
		code: '23242303',
		label: 'Centro giratorio horizontal',
	},
	{
		code: '23242304',
		label: 'Torno para duplicar contornos o trazadora',
	},
	{
		code: '23242305',
		label: 'Torno de torreta',
	},
	{
		code: '23242306',
		label: 'Centro giratorio vertical',
	},
	{
		code: '23242307',
		label: 'Tornillo de avance',
	},
	{
		code: '23242308',
		label: 'Máquina centradora',
	},
	{
		code: '23242400',
		label: 'Centros de maquinado',
	},
	{
		code: '23242401',
		label: 'Centro maquinado horizontal',
	},
	{
		code: '23242402',
		label: 'Centro maquinado universal o multi - tarea',
	},
	{
		code: '23242403',
		label: 'Centro maquinado vertical',
	},
	{
		code: '23242500',
		label: 'Máquinas para el fresado de metales',
	},
	{
		code: '23242501',
		label: 'Máquina fresadora de lecho',
	},
	{
		code: '23242502',
		label: 'Máquina fresadora de puente',
	},
	{
		code: '23242503',
		label: 'Máquina fresadora de columna y rodilla',
	},
	{
		code: '23242504',
		label: 'Máquina fresadora de pórtico',
	},
	{
		code: '23242505',
		label: 'Máquina fresadora de perfilado y duplicación',
	},
	{
		code: '23242506',
		label: 'Máquina fresadora de columna desplazada',
	},
	{
		code: '23242507',
		label: 'Máquina fresadora universal',
	},
	{
		code: '23242508',
		label: 'Máquina fresadora de copia',
	},
	{
		code: '23242509',
		label: 'Máquina fresadora de torreta',
	},
	{
		code: '23242510',
		label: 'Máquina fresadora de estilo planeador',
	},
	{
		code: '23242511',
		label: 'Máquina fresadora de extremo',
	},
	{
		code: '23242600',
		label: 'Maquinas para el cortado de metales especializados o diversos',
	},
	{
		code: '23242601',
		label: 'Máquina biseladora',
	},
	{
		code: '23242602',
		label: 'Máquina de bruñido',
	},
	{
		code: '23242603',
		label: 'Máquina para hacer chaflanes',
	},
	{
		code: '23242604',
		label: 'Máquina para lapeado',
	},
	{
		code: '23242605',
		label: 'Máquina pulidora de metal',
	},
	{
		code: '23242606',
		label: 'Máquina de procesamiento en capas delgadas',
	},
	{
		code: '23242607',
		label: 'Máquina de súper  - terminado',
	},
	{
		code: '23242608',
		label: 'Preseteador de herramientas',
	},
	{
		code: '23242609',
		label: 'Máquina para perfeccionado',
	},
	{
		code: '23242610',
		label: 'Maquina de dimensionamiento de hoyos de diamante',
	},
	{
		code: '23242611',
		label: 'Máquina para hacer bordes',
	},
	{
		code: '23242612',
		label: 'Máquina para grabado de metales',
	},
	{
		code: '23242613',
		label: 'Máquina para hacer tornillos hembra',
	},
	{
		code: '23242614',
		label: 'Máquina para hacer llavería',
	},
	{
		code: '23242615',
		label: 'Máquina para hacer surcos en metal',
	},
	{
		code: '23242700',
		label: 'Maquinaria para molinos de tubos',
	},
	{
		code: '23242701',
		label: 'Máquina para hacer componentes tubulares doblados',
	},
	{
		code: '23242702',
		label: 'Máquina para hacer componentes tubulares soldados',
	},
	{
		code: '23251500',
		label: 'Maquinaria para tubulares doblados',
	},
	{
		code: '23251501',
		label: 'Freno de prensa hidráulica',
	},
	{
		code: '23251502',
		label: 'Freno de prensa manual',
	},
	{
		code: '23251503',
		label: 'Máquina para el doblado de metal',
	},
	{
		code: '23251504',
		label: 'Doblador de tangentes',
	},
	{
		code: '23251505',
		label: 'Máquina dobladora de tubos',
	},
	{
		code: '23251506',
		label: 'Terminador de puntas de tubos',
	},
	{
		code: '23251507',
		label: 'Doblador de alas',
	},
	{
		code: '23251508',
		label: 'Máquina dobladora de barras de acero',
	},
	{
		code: '23251509',
		label: 'Máquina para reducir diámetro de alambres',
	},
	{
		code: '23251600',
		label: 'Máquinas para enrollado de metales',
	},
	{
		code: '23251601',
		label: 'Máquina enrolladora de hebras',
	},
	{
		code: '23251602',
		label: 'Máquina para el formado de láminas metálicas',
	},
	{
		code: '23251603',
		label: 'Prensa de rodillos',
	},
	{
		code: '23251700',
		label: 'Máquinas de forjado',
	},
	{
		code: '23251701',
		label: 'Prensa de forja de cizalla',
	},
	{
		code: '23251702',
		label: 'Prensa de forja de impresión y troquel cerrado',
	},
	{
		code: '23251703',
		label: 'Prensa de forja de troquel abierto',
	},
	{
		code: '23251704',
		label: 'Máquinas de forjado radial',
	},
	{
		code: '23251705',
		label: 'Máquina de forjado rollo',
	},
	{
		code: '23251706',
		label: 'Rebabas giratorias',

		palabrasSimilares: 'Fresas rotativas',
	},
	{
		code: '23251707',
		label: 'Prensa de recortado',

		palabrasSimilares: 'Prensas de recorte',
	},
	{
		code: '23251708',
		label: 'Máquina de forjado de alta velocidad',
	},
	{
		code: '23251709',
		label: 'Máquina de forjado de martillo de aire',
	},
	{
		code: '23251710',
		label: 'Máquina de forjado de martillo de resorte',
	},
	{
		code: '23251711',
		label: 'Máquina de forjado de martillo a vapor',
	},
	{
		code: '23251712',
		label: 'Máquina de forjado de martillo de caída',
	},
	{
		code: '23251713',
		label: 'Máquina de forjado de terminaciones prensadas',

		palabrasSimilares: 'Máquina forjadora de esmerilado',
	},
	{
		code: '23251714',
		label: 'Forja de compresión o prensa de manivela',
	},
	{
		code: '23251800',
		label: 'Troqueles y herramientas para el formado de metales',
	},
	{
		code: '23251801',
		label: 'Troquel de doblado',
	},
	{
		code: '23251802',
		label: 'Troquel de corte',
	},
	{
		code: '23251803',
		label: 'Troquel de fundición a presión',
	},
	{
		code: '23251804',
		label: 'Troquel de terminado de bordes',
	},
	{
		code: '23251805',
		label: 'Troquel de forja',
	},
	{
		code: '23251806',
		label: 'Troquel de extrusión de metal',
	},
	{
		code: '23251807',
		label: 'Sello de metal',
	},
	{
		code: '23251808',
		label: 'Mandril para el doblado de tubos',
	},
	{
		code: '23251809',
		label: 'Troquel giratorio',
	},
	{
		code: '23251810',
		label: 'Troquel de estampado',
	},
	{
		code: '23251811',
		label: 'Troquel de regla de acero',
	},
	{
		code: '23251812',
		label: 'Troquel de roscado',
	},
	{
		code: '23251813',
		label: 'Troquel de cableado',
	},
	{
		code: '23251814',
		label: 'Troquel de dibujo',
	},
	{
		code: '23251900',
		label: 'Maquinaria y equipo para la fabricación de contenedores de metal',
	},
	{
		code: '23251901',
		label: 'Alimentador de láminas de metal',
	},
	{
		code: '23261500',
		label: 'Máquinas para prototipos rápidos',
	},
	{
		code: '23261501',
		label: 'Máquina de modelado  por deposición con fusible fdm',
	},
	{
		code: '23261502',
		label: 'Máquina de método de chorro de tinta',
	},
	{
		code: '23261503',
		label: 'Máquina para la manufactura de objeto laminado',
	},
	{
		code: '23261504',
		label: 'Máquina láser para la formación de polvo',
	},
	{
		code: '23261505',
		label: 'Máquina láser selectiva de sinterización',
	},
	{
		code: '23261506',
		label: 'Máquina de estero – litografía',
	},
	{
		code: '23261507',
		label: 'Máquina impresora tridimensional',
	},
	{
		code: '23271400',
		label: 'Máquinas soldadoras',
	},
	{
		code: '23271401',
		label: 'Máquina para soldadura arco sumergida',
	},
	{
		code: '23271402',
		label: 'Máquina de soldadura ultrasónica',
	},
	{
		code: '23271403',
		label: 'Máquina de soldadura de proyección',
	},
	{
		code: '23271404',
		label: 'Máquina de soldadura arco plasma',
	},
	{
		code: '23271405',
		label: 'Máquina de soldadura láser',
	},
	{
		code: '23271406',
		label: 'Máquina de soldadura aislada',
	},
	{
		code: '23271407',
		label: 'Máquina de soldadura de gas inerte de tungsteno',
	},
	{
		code: '23271408',
		label: 'Máquina de soldadura de gas inerte de metal',
	},
	{
		code: '23271409',
		label: 'Máquina de soldadura de barra o soldadura arco metálico protegida',
	},
	{
		code: '23271410',
		label: 'Rectificador de soldadura',
	},
	{
		code: '23271411',
		label: 'Soldadura plástica',
	},
	{
		code: '23271412',
		label: 'Soldadura termita',
	},
	{
		code: '23271413',
		label: 'Máquina de soldadura de rayo electrón ebw',
	},
	{
		code: '23271414',
		label: 'Máquina de soldadura electro escoria esw',
	},
	{
		code: '23271415',
		label: 'Máquina de soldadura de contacto o presión fría',
	},
	{
		code: '23271416',
		label: 'Máquina de soldadura de fricción fw',
	},
	{
		code: '23271417',
		label: 'Máquina de soldadura con estaño',
	},
	{
		code: '23271418',
		label: 'Máquina de soldadura por junta eléctrica',
	},
	{
		code: '23271419',
		label: 'Soldadura con recalado',
	},
	{
		code: '23271420',
		label: 'Soldadura de cordón',

		palabrasSimilares: 'Soldadura de costura',
	},
	{
		code: '23271421',
		label: 'Soldadura de cuchilla de sierra de banda',
	},
	{
		code: '23271422',
		label: 'Soldadura de montantes o soldadura arco de montantes',
	},
	{
		code: '23271423',
		label: 'Soldadura de arco de corriente alterna ac',
	},
	{
		code: '23271424',
		label: 'Soldadura de arco de corriente directa dc',
	},
	{
		code: '23271500',
		label: 'Maquinaria de soldadura fuerte',
	},
	{
		code: '23271501',
		label: 'Máquina de soldadura fuerte para hornos',
	},
	{
		code: '23271502',
		label: 'Máquina de soldadura fuerte por inducción',
	},
	{
		code: '23271600',
		label: 'Maquinaria de soldadura débil',
	},
	{
		code: '23271601',
		label: 'Máquina de soldadura débil para hornos',
	},
	{
		code: '23271602',
		label: 'Máquina de soldadura por inducción',
	},
	{
		code: '23271603',
		label: 'Hierro de soldadura débil',
	},
	{
		code: '23271604',
		label: 'Máquina de soldadura de onda',
	},
	{
		code: '23271605',
		label: 'Horno de reflujo',
	},
	{
		code: '23271606',
		label: 'Máquina de soldadura débil selectiva',
	},
	{
		code: '23271607',
		label: 'Máquina automática de soldadura débil',
	},
	{
		code: '23271700',
		label: 'Accesorios para soldar, soldadura fuerte y soldadura débil',
	},
	{
		code: '23271701',
		label: 'Soplete',
	},
	{
		code: '23271702',
		label: 'Soplete grande',
	},
	{
		code: '23271703',
		label: 'Anillo de soldadura',
	},
	{
		code: '23271704',
		label: 'Pistola para des – soldar',
	},
	{
		code: '23271705',
		label: 'Bomba para des – soldar',
	},
	{
		code: '23271706',
		label: 'Punta para des – soldar',
	},
	{
		code: '23271707',
		label: 'Aparato para soldar o soldar fuerte o cortar  gas',
	},
	{
		code: '23271708',
		label: 'Barra indicadora de temperatura',
	},
	{
		code: '23271709',
		label: 'Generador de soldadura',
	},
	{
		code: '23271710',
		label: 'Lima limpiadora de soldadura o soldadura fuerte',

		palabrasSimilares: 'Limpiador de puntas de soldadura',
	},
	{
		code: '23271711',
		label: 'Punta de soldadura o corte',
	},
	{
		code: '23271712',
		label: 'Kit de soldadura  o soldadura débil',
	},
	{
		code: '23271713',
		label: 'Manipulador y posicionador de soldadura',
	},
	{
		code: '23271714',
		label: 'Pantalla de soldadura',

		palabrasSimilares: 'Mascara de soldar',
	},
	{
		code: '23271715',
		label: 'Cuchilla perfiladora de punta de soldadura',
	},
	{
		code: '23271716',
		label: 'Accesorios de perfilación de punta de soldadura',
	},
	{
		code: '23271717',
		label: 'Supresor de retorno',

		palabrasSimilares: 'Pararrayos de retroceso',
	},
	{
		code: '23271718',
		label: 'Protector para soldadura',

		palabrasSimilares: 'Manta de soldadura',
	},
	{
		code: '23271800',
		label: 'Suministros para soldar, soldadura fuerte y soldadura débil',
	},
	{
		code: '23271801',
		label: 'Espray anti – salpicaduras',

		palabrasSimilares: 'Aerosol contra salpicaduras para soldadura',
	},
	{
		code: '23271802',
		label: 'Fundente de soldadura fuerte',

		palabrasSimilares: 'Decapantes para soldadura fuerte',
	},
	{
		code: '23271803',
		label: 'Desoldador de trenza',
	},
	{
		code: '23271804',
		label: 'Varilla de soldadura fuerte o soldadura de gas',

		palabrasSimilares: 'Barra de soldadura o soldadura de gas',
	},
	{
		code: '23271805',
		label: 'Polvo de hierro',
	},
	{
		code: '23271806',
		label: 'Cautín',

		palabrasSimilares: 'Soldador eléctrico',
	},
	{
		code: '23271807',
		label: 'Fluido de soldadura',

		palabrasSimilares: 'Líquido de soldadura',
	},
	{
		code: '23271808',
		label: 'Fundente de soldadura débil',

		palabrasSimilares: 'Flujo de soldadura débil',
	},
	{
		code: '23271809',
		label: 'Pinta de soldadura',

		palabrasSimilares: 'Punta de soldadura',
	},
	{
		code: '23271810',
		label: 'Electrodo de soldadura',

		palabrasSimilares: 'Varilla de soldadura',
	},
	{
		code: '23271811',
		label: 'Fundente de soldadura',

		palabrasSimilares: 'Flujo de soldadura',
	},
	{
		code: '23271812',
		label: 'Barra de soldadura',
	},
	{
		code: '23271813',
		label: 'Alambre de soldadura',
	},
	{
		code: '23271814',
		label: 'Electrodo de corte',

		palabrasSimilares: 'Electrodo para corte y biselado',
	},
	{
		code: '23271815',
		label: 'Barra de cautín',

		palabrasSimilares: 'Barra de soldadura',
	},
	{
		code: '23271816',
		label: 'Pasta de cautín',

		palabrasSimilares: 'Pasta de soldadura',
	},
	{
		code: '23271817',
		label: 'Polvo de cautín',

		palabrasSimilares: 'Soldadura en polvo',
	},
	{
		code: '23271818',
		label: 'Alambre de cautín',

		palabrasSimilares: 'Alambre para soldar',
	},
	{
		code: '23271819',
		label: 'Fundente de unión de metal',
	},
	{
		code: '23271820',
		label: 'Estabilizador de humedad de electrodo de soldadura',
	},
	{
		code: '23271821',
		label: 'Soplete de soldadura',
	},
	{
		code: '23281500',
		label: 'Máquinas para recubrir o platear',
	},
	{
		code: '23281501',
		label: 'Máquina anodizdora',

		palabrasSimilares: 'Máquina de anodización',
	},
	{
		code: '23281502',
		label: 'Máquina de baño electrolítico',
	},
	{
		code: '23281503',
		label: 'Máquina de rociado térmico',
	},
	{
		code: '23281504',
		label: 'Máquina de plateado de barril',
	},
	{
		code: '23281600',
		label: 'Máquinas para tratamiento de calor',
	},
	{
		code: '23281601',
		label: 'Máquina de inducción de calentamiento',
	},
	{
		code: '23281602',
		label: 'Máquina de enfriamiento rápido',

		palabrasSimilares: 'Máquina de quench',
	},
	{
		code: '23281603',
		label: 'Horno para endurecimiento durante tratamiento de calor',

		palabrasSimilares:
			'Horno de endurecimiento por edad de tratamiento térmico',
	},
	{
		code: '23281700',
		label: 'Máquinas para tratamiento mecánico de superficie',
	},
	{
		code: '23281701',
		label: 'Máquina de revestimiento',
	},
	{
		code: '23281702',
		label: 'Máquina de perdigonado',
	},
	{
		code: '23281703',
		label: 'Máquina de arena (sand blast)',

		palabrasSimilares: 'Máquina de chorro de arena',
	},
	{
		code: '23281704',
		label: 'Máquina de electro descarga para endurecimiento de superficies',
	},
	{
		code: '23281800',
		label: 'Accesorios y herramientas para el tratamiento de metal',
	},
	{
		code: '23281801',
		label: 'Parrilla de galvanoplastia',

		palabrasSimilares: 'Estante galvanoplastia',
	},
	{
		code: '23281802',
		label: 'Parrilla de deposición de calor físico',

		palabrasSimilares: 'Estante de deposición física de vapor (PVD)',
	},
	{
		code: '23281900',
		label: 'Maquinaria y suministros para la limpieza del metal',
	},
	{
		code: '23281901',
		label: 'Limpiador electroquímico de metal',
	},
	{
		code: '23281902',
		label: 'Limpiador químico de metal',
	},
	{
		code: '23281903',
		label: 'Limpiador de metal mecánico o ultrasónico',
	},
	{
		code: '23281904',
		label: 'Equipo de lavado y limpiado acuoso',
	},
	{
		code: '23281905',
		label: 'Equipo de desengrase térmico',
	},
	{
		code: '23291500',
		label: 'Herramientas de perforación industrial',
	},
	{
		code: '23291501',
		label: 'Taladro soldado',
	},
	{
		code: '23291502',
		label: 'Taladro de parte superior o frontal intercambiable',
	},
	{
		code: '23291503',
		label: 'Taladro de inserto indexable',
	},
	{
		code: '23291504',
		label: 'Molino de parte superior o frontal intercambiable',
	},
	{
		code: '23291600',
		label: 'Herramientas de molienda industrial',
	},
	{
		code: '23291601',
		label: 'Inserto de molienda',

		palabrasSimilares: 'Inserto de fresado',
	},
	{
		code: '23291700',
		label: 'Herramientas de escariado industrial',
	},
	{
		code: '23291701',
		label: 'Cuchilla de escariador',
	},
	{
		code: '23291702',
		label: 'Escariador soldado',
	},
	{
		code: '23291703',
		label: 'Escariador sólido',
	},
	{
		code: '23291704',
		label: 'Escariador indexable',
	},
	{
		code: '23291800',
		label: 'Herramientas de roscado industrial',
	},
	{
		code: '23291801',
		label: 'Soporte de roscado axial',
	},
	{
		code: '23291802',
		label: 'Soporte de roscado externo',
	},
	{
		code: '23291803',
		label: 'Soporte de roscado interno',
	},
	{
		code: '23291804',
		label: 'Inserto de roscado',
	},
	{
		code: '23291900',
		label: 'Herramientas de barrenado industrial',
	},
	{
		code: '23291901',
		label: 'Cabeza de perforación',
	},
	{
		code: '23291902',
		label: 'Herramienta de perforación burda',
	},
	{
		code: '23301500',
		label: 'Maquinaria, equipos y accesorios para el trabajo de alambre',
	},
	{
		code: '23301501',
		label: 'Equipo para cortado de cable y alambre y ensamble de terminales',
	},
	{
		code: '24101500',
		label: 'Camiones industriales',
	},
	{
		code: '24101501',
		label: 'Carretas',
	},
	{
		code: '24101502',
		label: 'Vehículos de transporte a grane',

		palabrasSimilares: 'Transportadores a granel',
	},
	{
		code: '24101503',
		label: 'Vehículo dolly',

		palabrasSimilares: 'Remolque dolly',
	},
	{
		code: '24101504',
		label: 'Carretones de mano o accesorios',

		palabrasSimilares: 'Carros de mano o accesorios',
	},
	{
		code: '24101505',
		label: 'Camiones de pallets',

		palabrasSimilares: 'Carritos con plataforma',
	},
	{
		code: '24101506',
		label: 'Carretas de empujar',
	},
	{
		code: '24101507',
		label: 'Carretillas',
	},
	{
		code: '24101508',
		label: 'Plataformas rodantes',
	},
	{
		code: '24101509',
		label: 'Vagones',
	},
	{
		code: '24101510',
		label: 'Contenedor de basura plástico',
	},
	{
		code: '24101511',
		label: 'Trolleys de estante',

		palabrasSimilares: 'Carritos de estantería',
	},
	{
		code: '24101512',
		label: 'Bugies eléctricos',

		palabrasSimilares: 'Calesa eléctrica',
	},
	{
		code: '24101513',
		label: 'Remolcador',

		palabrasSimilares: 'Remolcador',
	},
	{
		code: '24101514',
		label: 'Camión plataforma',

		palabrasSimilares: 'Camión de plataforma',
	},
	{
		code: '24101515',
		label: 'Camión pórtico',
	},
	{
		code: '24101516',
		label: 'Plataforma auto propulsada',
	},
	{
		code: '24101517',
		label: 'Tractor de remolque eléctrico',
	},
	{
		code: '24101600',
		label: 'Equipo de izaje y accesorios',
	},
	{
		code: '24101601',
		label: 'Ascensores',
	},
	{
		code: '24101602',
		label: 'Montacargas',
	},
	{
		code: '24101603',
		label: 'Elevadores forklift',

		palabrasSimilares: 'Carretilla elevadora',
	},
	{
		code: '24101604',
		label: 'Elevadores',
	},
	{
		code: '24101605',
		label: 'Equipo de carga',
	},
	{
		code: '24101606',
		label: 'Apiladoras',
	},
	{
		code: '24101608',
		label: 'Malacates o güinches',

		palabrasSimilares: 'Malacates o winches',
	},
	{
		code: '24101609',
		label: 'Inclinadores',
	},
	{
		code: '24101610',
		label: 'Manipuladores',
	},
	{
		code: '24101611',
		label: 'Eslingas',
	},
	{
		code: '24101612',
		label: 'Gatos',
	},
	{
		code: '24101613',
		label: 'Bloques o poleas',
	},
	{
		code: '24101614',
		label: 'Sacos de aire para cargar',
	},
	{
		code: '24101615',
		label: 'Rampas de carga',
	},
	{
		code: '24101616',
		label: 'Dispositivo debajo de gancho',
	},
	{
		code: '24101617',
		label: 'Elevador de tijera',

		palabrasSimilares: 'Mesa elevadora',
	},
	{
		code: '24101618',
		label: 'Instalador de tuberías',
	},
	{
		code: '24101619',
		label: 'Puentes grúas',
	},
	{
		code: '24101620',
		label: 'Camión grúas',

		palabrasSimilares: 'Grúa móvil de cadena',
	},
	{
		code: '24101621',
		label: 'Grúas todo terreno',
	},
	{
		code: '24101622',
		label: 'Grúas para terrenos difíciles',
	},
	{
		code: '24101623',
		label: 'Torre grúas',
	},
	{
		code: '24101624',
		label: 'Grúas hidráulicas sobre camión',
	},
	{
		code: '24101625',
		label: 'Camión grúas convencionales',
	},
	{
		code: '24101626',
		label: 'Escaleras mecánicas o cintas rodantes',
	},
	{
		code: '24101627',
		label: 'Troleys de viga',

		palabrasSimilares: 'Carros de viga',
	},
	{
		code: '24101628',
		label: 'Horquillas ajustables',
	},
	{
		code: '24101629',
		label:
			'Accesorios o suministros de elevador forklift o transportador vertical',

		palabrasSimilares: 'Accesorios o suministros de montacargas o ascensores',
	},
	{
		code: '24101630',
		label: 'Grúas de taller',
	},
	{
		code: '24101631',
		label: 'Tazas de succión',
	},
	{
		code: '24101632',
		label: 'Izajes laterales',
	},
	{
		code: '24101633',
		label: 'Tambores de elevación',
	},
	{
		code: '24101634',
		label: 'Sacos de cadena',
	},
	{
		code: '24101635',
		label: 'Gatos de tornillo',
	},
	{
		code: '24101636',
		label: 'Bolsas de contrapeso y contrapesos',
	},
	{
		code: '24101637',
		label: 'Eslinga de material de nylon',
	},
	{
		code: '24101638',
		label: 'Eslinga de cuerda',
	},
	{
		code: '24101639',
		label: 'Molinete',
	},
	{
		code: '24101640',
		label: 'Unidad de engranaje transversal',
	},
	{
		code: '24101641',
		label: 'Grillete',
	},
	{
		code: '24101642',
		label: 'Grúa flotante',
	},
	{
		code: '24101643',
		label: 'Trolly de lavado y secado',

		palabrasSimilares: 'Carritos de limpieza y lavandería',
	},
	{
		code: '24101644',
		label: 'Ascensor de cocina',
	},
	{
		code: '24101645',
		label: 'Grúa sobre orugas',
	},
	{
		code: '24101646',
		label: 'Grúa ferroviaria',
	},
	{
		code: '24101647',
		label: 'Almeja para izaje de material',
	},
	{
		code: '24101648',
		label: 'Almeja para izaje de placas',
	},
	{
		code: '24101649',
		label: 'Grúa güinche',

		palabrasSimilares: 'Grúa de cabrestante',
	},
	{
		code: '24101650',
		label: 'Guy derrick',

		palabrasSimilares: 'Torre derrick',
	},
	{
		code: '24101651',
		label: 'Cabrestante',
	},
	{
		code: '24101652',
		label: 'Grúa de mástil',
	},
	{
		code: '24101653',
		label: 'Puentegrúa',
	},
	{
		code: '24101654',
		label: 'Grúa gantry o de pórtico',
	},
	{
		code: '24101655',
		label: 'Sistema de parqueadero vertical',
	},
	{
		code: '24101656',
		label: 'Grúa halada por tractor',

		palabrasSimilares: 'Grúa tractor',
	},
	{
		code: '24101657',
		label: 'Grúa montada sobre tractor',
	},
	{
		code: '24101658',
		label: 'Imán de izaje',
	},
	{
		code: '24101659',
		label: 'Gato o dolly móvil vertical',

		palabrasSimilares: 'Vehículo conector o plataforma móvil',
	},
	{
		code: '24101660',
		label: 'Elevador de baterías',
	},
	{
		code: '24101661',
		label: 'Elevador de vehículo',
	},
	{
		code: '24101662',
		label: 'Cubierta de güinche',

		palabrasSimilares: 'Cubierta del cabrestante',
	},
	{
		code: '24101700',
		label: 'Transportadores y accesorios',
	},
	{
		code: '24101701',
		label: 'Rodillo transportador',
	},
	{
		code: '24101702',
		label: 'Transbordadores a bolas',

		palabrasSimilares: 'Tablas de transferencia de bolas',
	},
	{
		code: '24101703',
		label: 'Acumuladoras de rocas',
	},
	{
		code: '24101704',
		label: 'Alimentadores para banda transportadora',
	},
	{
		code: '24101705',
		label: 'Tornillo de banda transportadora',
	},
	{
		code: '24101706',
		label: 'Troles o vagonetas y accesorios',
	},
	{
		code: '24101707',
		label: 'Banda transportadora sobre rieles',
	},
	{
		code: '24101708',
		label: 'Banda transportadora extensible',
	},
	{
		code: '24101709',
		label: 'Transportador de rodillos',
	},
	{
		code: '24101710',
		label: 'Paradas de paquetes',
	},
	{
		code: '24101711',
		label: 'Tornamesas',

		palabrasSimilares: 'Placas giratorias',
	},
	{
		code: '24101712',
		label: 'Bandas transportadoras',
	},
	{
		code: '24101713',
		label: 'Aparato de cangilones',

		palabrasSimilares: 'Transportadores de cangilones',
	},
	{
		code: '24101714',
		label: 'Bandas transportadoras aéreas',
	},
	{
		code: '24101715',
		label: 'Sistema de bandas transportadoras',
	},
	{
		code: '24101716',
		label: 'Poleas de banda transportadora',
	},
	{
		code: '24101717',
		label: 'Rodillos de banda transportadora',
	},
	{
		code: '24101718',
		label: 'Sujeciones de la banda transportadora',
	},
	{
		code: '24101719',
		label: 'Cepillos de la banda transportadora',
	},
	{
		code: '24101721',
		label: 'Cubierta de trolley',
	},
	{
		code: '24101722',
		label: 'Banda transportadora de cadena',
	},
	{
		code: '24101723',
		label: 'Rodillos o tambores motorizados',
	},
	{
		code: '24101724',
		label: 'Bastidores de banda transportadora',
	},
	{
		code: '24101725',
		label: 'Articulaciones o eslabones de banda transportadora',
	},
	{
		code: '24101726',
		label: 'Revestimiento de la banda transportadora',
	},
	{
		code: '24101727',
		label: 'Banda transportadora vibratoria',
	},
	{
		code: '24101728',
		label: 'Soportes de la banda transportadora',
	},
	{
		code: '24101729',
		label: 'Balde de elevador de cangilones',

		palabrasSimilares: 'Cucharon elevador',
	},
	{
		code: '24101730',
		label: 'Banda transportadora de tornillo',
	},
	{
		code: '24101731',
		label: 'Faldón de la banda transportadora',

		palabrasSimilares: 'Junta de la cinta transportadora',
	},
	{
		code: '24101732',
		label: 'Soporte del rodillo de la banda transportadora',
	},
	{
		code: '24101733',
		label: 'Soporte del rodillo de retorno de la banda transportadora',
	},
	{
		code: '24101734',
		label: 'Raspador en v',
	},
	{
		code: '24101735',
		label: 'Deslizador de aire',
	},
	{
		code: '24101736',
		label: 'Sistema de transporte de fase densa',
	},
	{
		code: '24101737',
		label: 'Sistema de transporte de fase liviana',
	},
	{
		code: '24101738',
		label: 'Compuerta de barra',
	},
	{
		code: '24101739',
		label: 'Unidad receptora de granel',
	},
	{
		code: '24101740',
		label: 'Banda transportadora de ángulo alto',
	},
	{
		code: '24101741',
		label: 'Raspador interno de banda',
	},
	{
		code: '24101742',
		label: 'Raspador externo de banda',
	},
	{
		code: '24101743',
		label: 'Socavón final de banda transportadora',

		palabrasSimilares: 'Eje trasero del transportador',
	},
	{
		code: '24101744',
		label: 'Socavón de inicio de banda transportadora',

		palabrasSimilares: 'Eje del cabezal del transportador',
	},
	{
		code: '24101745',
		label: 'Banda transportadora sobre ruedas',
	},
	{
		code: '24101746',
		label: 'Banda transportadora portátil',
	},
	{
		code: '24101747',
		label: 'Soporte de banda transportadora',
	},
	{
		code: '24101748',
		label: 'Banda transportadora tripper o disparadora',
	},
	{
		code: '24101749',
		label: 'Sistema de transporte por bandas',
	},
	{
		code: '24101750',
		label: 'Banda transportadora aérea',
	},
	{
		code: '24101800',
		label: 'Equipo para muelles',
	},
	{
		code: '24101801',
		label: 'Niveladores para muelles',
	},
	{
		code: '24101802',
		label: 'Retenedoras para muelles',
	},
	{
		code: '24101803',
		label: 'Rampas para muelles',
	},
	{
		code: '24101804',
		label: 'Puertas de cintas',

		palabrasSimilares: 'Puertas de tira',
	},
	{
		code: '24101805',
		label: 'Parachoques para muelles',

		palabrasSimilares: 'Parachoques de los muelles',
	},
	{
		code: '24101806',
		label: 'Escaleras para muelles',
	},
	{
		code: '24101807',
		label: 'Planchas para muelles',
	},
	{
		code: '24101808',
		label: 'Cuñas para ruedas',

		palabrasSimilares: 'Calzos para ruedas',
	},
	{
		code: '24101809',
		label: 'Carriles de muelle o accesorios',
	},
	{
		code: '24101900',
		label: 'Equipo para manejo de canecas',
	},
	{
		code: '24101901',
		label: 'Abridores de canecas',

		palabrasSimilares: 'Abridores de tambor',
	},
	{
		code: '24101902',
		label: 'Agarradores para canecas',

		palabrasSimilares: 'Agarre de tambor',
	},
	{
		code: '24101903',
		label: 'Elevadores de canecas',

		palabrasSimilares: 'Elevadores de tambor',
	},
	{
		code: '24101904',
		label: 'Soportes para canecas',

		palabrasSimilares: 'Cunas de tambor',
	},
	{
		code: '24101905',
		label: 'Cubierta de derrames',
	},
	{
		code: '24101906',
		label: 'Recuperadores de canecas',
	},
	{
		code: '24101907',
		label: 'Soportes de contención de derrames',
	},
	{
		code: '24101908',
		label: 'Caballete para canecas',

		palabrasSimilares: 'Estantería y estaciones de trasiego',
	},
	{
		code: '24102000',
		label: 'Disposición en estantes y almacenamiento',
	},
	{
		code: '24102001',
		label:
			'Sistemas de estanterías para equipo electrónico montado sobre estantes',

		palabrasSimilares:
			'Sistemas de bastidor para equipos electrónicos de montaje en bastidor',
	},
	{
		code: '24102002',
		label: 'Manipuladores para recipientes',
	},
	{
		code: '24102004',
		label: 'Estanterías para almacenaje',
	},
	{
		code: '24102005',
		label: 'Sistemas automatizados de almacenaje o recuperación',
	},
	{
		code: '24102006',
		label: 'Bancos de trabajo',
	},
	{
		code: '24102007',
		label: 'Carretes de almacenamiento de estantes',
	},
	{
		code: '24102008',
		label: 'Soportes de carretes portátiles',
	},
	{
		code: '24102009',
		label: 'Rieles montados sobre pared',
	},
	{
		code: '24102010',
		label: 'Estantería de vidrio',
	},
	{
		code: '24102011',
		label: 'Accesorio de biblioteca',

		palabrasSimilares: 'Estante y accesorios para libros',
	},
	{
		code: '24102100',
		label: 'Equipo y suministros para almacenaje',
	},
	{
		code: '24102101',
		label: 'Equipo para manejo de carga',

		palabrasSimilares: 'Equipos de manipulación de cargas',
	},
	{
		code: '24102102',
		label: 'Carrusel de bodegaje',

		palabrasSimilares: 'Carruseles de almacén',
	},
	{
		code: '24102103',
		label: 'Encajadoras de bodegaje',

		palabrasSimilares: 'Encajonadoras de almacén',
	},
	{
		code: '24102104',
		label: 'Despaletizadoras',
	},
	{
		code: '24102105',
		label: 'Paletizadoras',
	},
	{
		code: '24102106',
		label: 'Equipo de retractilado industrial',

		palabrasSimilares: 'Equipos de embalaje de plástico',
	},
	{
		code: '24102107',
		label: 'Maquinaria para encartonar',
	},
	{
		code: '24102108',
		label: 'Compactadores de empaque',
	},
	{
		code: '24102109',
		label: 'Ganchos para sacos',
	},
	{
		code: '24102200',
		label: 'Herramientas para envasado',
	},
	{
		code: '24102201',
		label: 'Dispensadores de película elástica',
	},
	{
		code: '24102202',
		label: 'Dispensadores de cinta para sellar cajas',
	},
	{
		code: '24102203',
		label: 'Herramientas o equipos para sellar bolsas',
	},
	{
		code: '24102204',
		label: 'Dispensador de zunchos',

		palabrasSimilares: 'Distribuidor de flejes',
	},
	{
		code: '24102208',
		label: 'Infladores de aire',
	},
	{
		code: '24102209',
		label: 'Máquina para cortar moldes',

		palabrasSimilares: 'Troqueladora',
	},
	{
		code: '24102210',
		label: 'Prensa de embalar',

		palabrasSimilares: 'Prensa de empacado',
	},
	{
		code: '24102300',
		label: 'Vehículos guiados automáticos AGV',
	},
	{
		code: '24102301',
		label: 'Vehículo automático guiado por códigos de barras',
	},
	{
		code: '24102302',
		label: 'Vehículo automático guiado a través del cableado',

		palabrasSimilares: 'Vehículos guiados automáticos AGV',
	},
	{
		code: '24111500',
		label: 'Bolsas',
	},
	{
		code: '24111501',
		label: 'Bolsas de lona',
	},
	{
		code: '24111502',
		label: 'Bolsas de papel',
	},
	{
		code: '24111503',
		label: 'Bolsas plásticas',

		palabrasSimilares: 'Bolsas de plástico',
	},
	{
		code: '24111505',
		label: 'Contendedores de granel intermedios flexibles',
	},
	{
		code: '24111506',
		label: 'Redes o bolsas de lavandería',
	},
	{
		code: '24111507',
		label: 'Bolsas de herramientas',
	},
	{
		code: '24111508',
		label: 'Bolsas de carpa',
	},
	{
		code: '24111509',
		label: 'Bolsas de agua',
	},
	{
		code: '24111510',
		label: 'Bolsas y paquetes de cuerda',
	},
	{
		code: '24111511',
		label: 'Bolsas de malla de fibra',
	},
	{
		code: '24111512',
		label: 'Bolsas de paja',
	},
	{
		code: '24111513',
		label: 'Bolsas de algodón',
	},
	{
		code: '24111514',
		label: 'Bolsas con cremallera',
	},
	{
		code: '24111800',
		label: 'Tanques y cilindros y sus accesorios',
	},
	{
		code: '24111801',
		label: 'Reservorios',

		palabrasSimilares: 'Embalse',
	},
	{
		code: '24111802',
		label: 'Tanques o cilindros de aire o gas',
	},
	{
		code: '24111803',
		label: 'Tanques de almacenamiento',
	},
	{
		code: '24111804',
		label: 'Tanques de calibración',
	},
	{
		code: '24111805',
		label: 'Tanques de productos químicos',
	},
	{
		code: '24111806',
		label: 'Tanques de inmersión',
	},
	{
		code: '24111807',
		label: 'Tanques de expansión',
	},
	{
		code: '24111808',
		label: 'Tanques de almacenaje de combustible',
	},
	{
		code: '24111809',
		label: 'Tanques de procesamiento',
	},
	{
		code: '24111810',
		label: 'Tanques de almacenamiento de agua',

		palabrasSimilares: 'Tinaco',
	},
	{
		code: '24111811',
		label: 'Bombonas',

		palabrasSimilares: 'Tanque de almacenamiento',
	},
	{
		code: '24111812',
		label: 'Pileta de contención',
	},
	{
		code: '24111813',
		label: 'Tanques de lavado',
	},
	{
		code: '24111814',
		label: 'Tanques de agua caliente',
	},
	{
		code: '24111815',
		label: 'Tanques de condensado de vapor',
	},
	{
		code: '24111816',
		label: 'Tanque o cilindro de gas licuado',

		palabrasSimilares: 'Tanque estacionario',
	},
	{
		code: '24111817',
		label: 'Silo de cemento',

		palabrasSimilares: 'Deposito de cemento',
	},
	{
		code: '24111818',
		label: 'Sello de tanque',
	},
	{
		code: '24112000',
		label: 'Arcones y cestas',
	},
	{
		code: '24112003',
		label: 'Tolvas no metálicas',

		palabrasSimilares: 'Contenedores no metálicos',
	},
	{
		code: '24112004',
		label: 'Tolvas metálicas',

		palabrasSimilares: 'Contenedores metálicos',
	},
	{
		code: '24112005',
		label: 'Canastas metálicas',
	},
	{
		code: '24112006',
		label: 'Canastas no metálicas',
	},
	{
		code: '24112007',
		label: 'Estante para el manejo de material',
	},
	{
		code: '24112100',
		label: 'Toneles, cubas y bidones',
	},
	{
		code: '24112101',
		label: 'Toneles',
	},
	{
		code: '24112102',
		label: 'Barriles',
	},
	{
		code: '24112108',
		label: 'Tambores metálicos',

		palabrasSimilares: 'Tambos metálicos',
	},
	{
		code: '24112109',
		label: 'Tambores no metálicos',

		palabrasSimilares: 'Tambos no metálicos',
	},
	{
		code: '24112110',
		label: 'Contenedores intermedios a granel',
	},
	{
		code: '24112111',
		label: 'Estabilizador',
	},
	{
		code: '24112112',
		label: 'Tapa de tambor',
	},
	{
		code: '24112200',
		label: 'Latas y cubos',
	},
	{
		code: '24112204',
		label: 'Cubos metálicos',

		palabrasSimilares: 'Cubetas metálicas',
	},
	{
		code: '24112205',
		label: 'Cubos no metálicos',

		palabrasSimilares: 'Cubetas no metálicas',
	},
	{
		code: '24112206',
		label: 'Bidones metálicos para líquido inflamable',

		palabrasSimilares: 'Latas metálicas con líquido inflamable',
	},
	{
		code: '24112207',
		label: 'Bidones no metálicos para líquido inflamable',

		palabrasSimilares: 'Latas no metálicas con líquido inflamable',
	},
	{
		code: '24112208',
		label: 'Conjunto pulverizador',

		palabrasSimilares: 'Equipo de rociado',
	},
	{
		code: '24112209',
		label: 'Contenedor de paredes rectas',

		palabrasSimilares: 'Bidón',
	},
	{
		code: '24112400',
		label: 'Cofres, armarios y baúles de almacenaje',
	},
	{
		code: '24112401',
		label: 'Cofres, cajas o armarios para herramientas',
	},
	{
		code: '24112402',
		label: 'Armarios para materiales peligrosos',

		palabrasSimilares: 'Gabinetes de materiales peligrosos',
	},
	{
		code: '24112403',
		label: 'Cinturón para herramientas',
	},
	{
		code: '24112404',
		label: 'Caja',
	},
	{
		code: '24112406',
		label: 'Compartimentos de caja o estantería',

		palabrasSimilares: 'Divisiones de caja o estantería',
	},
	{
		code: '24112407',
		label: 'Buzones',
	},
	{
		code: '24112408',
		label: 'Cajas antiestática',
	},
	{
		code: '24112409',
		label: 'Tapas para cajas',
	},
	{
		code: '24112411',
		label: 'Estuches para transporte',

		palabrasSimilares: 'Baúl para transportar',
	},
	{
		code: '24112412',
		label: 'Estuches para transporte de equipo',

		palabrasSimilares: 'Baúl para transporte de equipo',
	},
	{
		code: '24112413',
		label: 'Cajas o estuches para guardar banderas',
	},
	{
		code: '24112414',
		label: 'Caja de almacenamiento refrigerado',
	},
	{
		code: '24112415',
		label: 'Urnas de votos',

		palabrasSimilares: 'Urna electoral',
	},
	{
		code: '24112500',
		label: 'Cartón ondulado y otros materiales para distribución',
	},
	{
		code: '24112501',
		label: 'Cartones acanalados ranurados',
	},
	{
		code: '24112502',
		label: 'Cajas de transporte cortadas con troquel de una sola pieza',
	},
	{
		code: '24112503',
		label:
			'Cartones acanalados de transporte cortados con troquel con tapas separadas',
	},
	{
		code: '24112504',
		label: 'Cajas moldeadas',
	},
	{
		code: '24112505',
		label: 'Formas de cartón corrugado',
	},
	{
		code: '24112600',
		label: 'contenedores para líquidos',
	},
	{
		code: '24112601',
		label: 'Jarras',
	},
	{
		code: '24112602',
		label: 'Frascos',
	},
	{
		code: '24112700',
		label: 'Estibas',
	},
	{
		code: '24112701',
		label: 'Pallets de madera',

		palabrasSimilares: 'Tarimas de madera',
	},
	{
		code: '24112702',
		label: 'Pallets plásticos',

		palabrasSimilares: 'Tarimas de plástico',
	},
	{
		code: '24112800',
		label: 'Contenedores de carga',
	},
	{
		code: '24112801',
		label: 'Contenedor de carga seca',
	},
	{
		code: '24112802',
		label: 'Contenedor de carga open top',

		palabrasSimilares: 'Contenedor superior abierto',
	},
	{
		code: '24112803',
		label: 'Contenedor de carga de plataforma',
	},
	{
		code: '24112804',
		label: 'Contenedor de carga flatrack',

		palabrasSimilares: 'Contenedor de carga plana',
	},
	{
		code: '24112805',
		label: 'Contenedor de carga de temperatura controlada',

		palabrasSimilares: 'Contenedor de carga controlado por temperatura',
	},
	{
		code: '24112900',
		label: 'Embalajes',
	},
	{
		code: '24112901',
		label: 'Embalaje de madera',

		palabrasSimilares: 'Cajas de madera para embalaje',
	},
	{
		code: '24112902',
		label: 'Embalaje de plástico',

		palabrasSimilares: 'Cajas de plástico para embalaje',
	},
	{
		code: '24113000',
		label: 'Lámina termo - retráctil',
	},
	{
		code: '24113001',
		label: 'Lámina termo - retráctil corrogada',

		palabrasSimilares: 'Hoja de deslizamiento ondulado',
	},
	{
		code: '24113002',
		label: 'Lámina termo - retráctil de cartón o cartulina sólida',

		palabrasSimilares: 'Hoja de deslizamiento de cartón o cartulina sólida',
	},
	{
		code: '24113003',
		label: 'Lámina termo - retráctil plástica',

		palabrasSimilares: 'Hoja de deslizamiento de plástico',
	},
	{
		code: '24121500',
		label: 'Cajas, bolsas y sacos para empaquetado',
	},
	{
		code: '24121502',
		label: 'Sacos o bolsas para empacar',
	},
	{
		code: '24121503',
		label: 'Cajas para empacar',
	},
	{
		code: '24121504',
		label: 'Empaques cardados',
	},
	{
		code: '24121506',
		label: 'Cajas conductivas',
	},
	{
		code: '24121507',
		label: 'Cajas instaladas rígidas',
	},
	{
		code: '24121508',
		label: 'Cartones de huevos',
	},
	{
		code: '24121509',
		label: 'Bandejas para empacar',
	},
	{
		code: '24121510',
		label: 'Empaques para bolsas de te',
	},
	{
		code: '24121511',
		label: 'Cartón de empacar',
	},
	{
		code: '24121512',
		label: 'Ladrillos de hielo o “cold packs”',

		palabrasSimilares: 'Embalaje en frío o ladrillo de hielo',
	},
	{
		code: '24121513',
		label: 'Estuche para empacar',
	},
	{
		code: '24121800',
		label: 'Latas para envasado',
	},
	{
		code: '24121801',
		label: 'Latas de aerosol',
	},
	{
		code: '24121802',
		label: 'Latas de pintura o barniz',
	},
	{
		code: '24121803',
		label: 'Latas de bebida',
	},
	{
		code: '24121804',
		label: 'Latas de comida',
	},
	{
		code: '24121805',
		label: 'Latas de acero',
	},
	{
		code: '24121806',
		label: 'Latas de aluminio',
	},
	{
		code: '24121807',
		label: 'Recipientes de plástico',
	},
	{
		code: '24121808',
		label: 'Recipientes de fibra de cartón',
	},
	{
		code: '24122000',
		label: 'Botellas',
	},
	{
		code: '24122001',
		label: 'Botellas para apretar',
	},
	{
		code: '24122002',
		label: 'Botellas de plástico',
	},
	{
		code: '24122003',
		label: 'Botellas de cristal',
	},
	{
		code: '24122004',
		label: 'Tapones o tapas',
	},
	{
		code: '24122005',
		label: 'Botellas de aplicador',
	},
	{
		code: '24122006',
		label: 'Aplicador',
	},
	{
		code: '24131500',
		label: 'Refrigeradores industriales',
	},
	{
		code: '24131501',
		label: 'Refrigerador y congelador combinado',
	},
	{
		code: '24131502',
		label: 'Refrigeradores de nitrógeno líquido',
	},
	{
		code: '24131503',
		label: 'Cuartos fríos',

		palabrasSimilares: 'Cuarto de almacenamiento en frio',
	},
	{
		code: '24131504',
		label: 'Contenedores refrigerados',
	},
	{
		code: '24131505',
		label: 'Buques refrigerados',
	},
	{
		code: '24131506',
		label: 'Tanques refrigerados',
	},
	{
		code: '24131507',
		label: 'Congelador de golpe',

		palabrasSimilares: 'Abatidor de temperatura',
	},
	{
		code: '24131508',
		label: 'Gabinete refrigerado',

		palabrasSimilares: 'Refrigerador de gabinete',
	},
	{
		code: '24131509',
		label: 'Refrigerador bajo el mostrador',
	},
	{
		code: '24131510',
		label: 'Refrigerador de mostrador',
	},
	{
		code: '24131511',
		label: 'Gabinete de refrigeración para pastelería',

		palabrasSimilares: 'Vitrina de refrigeración para pasteles',
	},
	{
		code: '24131512',
		label: 'Estación de preparación con gabinete refrigerado',
	},
	{
		code: '24131513',
		label: 'Refrigerante',
	},
	{
		code: '24131514',
		label: 'Unidad de almacenamiento de pre - enfriamiento y frío',

		palabrasSimilares: 'Unidad de prerefrigeración y almacenamiento en frío',
	},
	{
		code: '24131600',
		label: 'Congeladores industriales',
	},
	{
		code: '24131601',
		label: 'Congeladores horizontales',
	},
	{
		code: '24131602',
		label: 'Congeladores verticales',
	},
	{
		code: '24131603',
		label: 'Congeladores a bajas temperaturas',
	},
	{
		code: '24131604',
		label: 'Equipo de secado por congelación',
	},
	{
		code: '24131605',
		label: 'Cuartos fríos',

		palabrasSimilares: 'Congeladores de almacenamiento',
	},
	{
		code: '24131606',
		label: 'Congeladores de placa',
	},
	{
		code: '24131607',
		label: 'Congeladores de golpe de frío',

		palabrasSimilares: 'Refrigeradores industriales',
	},
	{
		code: '24131608',
		label: 'Gabinete de congelación para pastelería',
	},
	{
		code: '24131609',
		label: 'Congelador bajo el mostrador',

		palabrasSimilares: 'Bajomostradores de congelación',
	},
	{
		code: '24131610',
		label: 'Congelador de mostrador',
	},
	{
		code: '24131900',
		label: 'Máquinas de hacer el hielo',
	},
	{
		code: '24131901',
		label: 'Máquinas para hacer cubos de hielo',
	},
	{
		code: '24131902',
		label: 'Máquinas para hacer bloques de hielo',
	},
	{
		code: '24131903',
		label: 'Máquinas para hacer hojuelas de hielo',

		palabrasSimilares: 'Máquinas industriales para copos  de hielo',
	},
	{
		code: '24141500',
		label: 'Suministros para seguridad y protección',
	},
	{
		code: '24141501',
		label: 'Película elástica para envoltura',
	},
	{
		code: '24141502',
		label: 'Láminas elásticas de embalaje',
	},
	{
		code: '24141504',
		label: 'Sellos de seguridad a prueba de manipulación',
	},
	{
		code: '24141506',
		label: 'Encerados',
	},
	{
		code: '24141507',
		label: 'Película anti-estática para empacar',
	},
	{
		code: '24141508',
		label: 'Soporte angular de cartón',

		palabrasSimilares: 'Soporte de esquinas de cartón',
	},
	{
		code: '24141510',
		label: 'Protectores de cables',
	},
	{
		code: '24141511',
		label: 'Amarres de carga',
	},
	{
		code: '24141512',
		label: 'Absorbentes de empaquetado',
	},
	{
		code: '24141513',
		label: 'Empaques blíster',

		palabrasSimilares: 'Empaques de ampolla de plástico',
	},
	{
		code: '24141514',
		label: 'Películas para empacar',
	},
	{
		code: '24141515',
		label: 'Red de protección',
	},
	{
		code: '24141516',
		label: 'Desecante',

		palabrasSimilares: 'Gel sílice',
	},
	{
		code: '24141517',
		label: 'Polifilm',

		palabrasSimilares: 'Película de polietileno',
	},
	{
		code: '24141518',
		label: 'Láminas separadoras',

		palabrasSimilares: 'Hoja separadora',
	},
	{
		code: '24141519',
		label: 'Bandas o correas de acero para empacar',
	},
	{
		code: '24141520',
		label: 'Bandas o correas no metálicas para empacar',
	},
	{
		code: '24141600',
		label: 'Suministros de acolchados',
	},
	{
		code: '24141601',
		label: 'Empaque de burbujas',
	},
	{
		code: '24141602',
		label: 'Materiales de termoformado',
	},
	{
		code: '24141603',
		label: 'Amortiguación',
	},
	{
		code: '24141604',
		label: 'Relleno',

		palabrasSimilares: 'Materiales de acolchado',
	},
	{
		code: '24141605',
		label: 'Materiales de empaque rellenos de aire',
	},
	{
		code: '24141606',
		label: 'Bolitas de poliestireno',
	},
	{
		code: '24141607',
		label: 'Separadores de cartón',
	},
	{
		code: '24141608',
		label: 'Protecciones externas',
	},
	{
		code: '24141700',
		label: 'Tubos y núcleos de embalaje y etiquetas y accesorios',
	},
	{
		code: '24141701',
		label: 'Tubos o núcleos de papel',
	},
	{
		code: '24141702',
		label: 'Tapones de extremidad tubo o núcleo',
	},
	{
		code: '24141703',
		label: 'Piezas de papel',
	},
	{
		code: '24141704',
		label: 'Instrucciones o insertos impresos',
	},
	{
		code: '24141705',
		label: 'Tubos plegables',
	},
	{
		code: '24141706',
		label: 'Carrete',
	},
	{
		code: '24141707',
		label: 'Carretel',
	},
	{
		code: '24141708',
		label: 'Asas de paquetes',
	},
	{
		code: '24141709',
		label: 'Tubos o tapones de cápsulas',
	},
	{
		code: '24141710',
		label: 'Etiqueta de alambre',
	},
	{
		code: '25101500',
		label: 'Vehículos de pasajeros',
	},
	{
		code: '25101501',
		label: 'Minibuses',

		palabrasSimilares: 'Minibús',
	},
	{
		code: '25101502',
		label: 'Autobuses',

		palabrasSimilares: 'Camiones de pasajeros',
	},
	{
		code: '25101503',
		label: 'Carros',

		palabrasSimilares: 'Automóviles',
	},
	{
		code: '25101504',
		label: 'Station wagons',

		palabrasSimilares: 'Vagones',
	},
	{
		code: '25101505',
		label: 'Minivans o vans',

		palabrasSimilares: 'Furgonetas',
	},
	{
		code: '25101506',
		label: 'Limosinas',
	},
	{
		code: '25101507',
		label: 'Camiones ligeros o vehículos utilitarios deportivos',
	},
	{
		code: '25101508',
		label: 'Carro deportivo',
	},
	{
		code: '25101509',
		label: 'Carro eléctrico',
	},
	{
		code: '25101600',
		label: 'Vehículos de transporte de productos y materiales',
	},
	{
		code: '25101601',
		label: 'Volquetas',

		palabrasSimilares: 'Camión de volteo',
	},
	{
		code: '25101602',
		label: 'Remolques',
	},
	{
		code: '25101604',
		label: 'Camiones de reparto',
	},
	{
		code: '25101609',
		label: 'Camiones de manipulación de sedimento y aguas residuales',
	},
	{
		code: '25101610',
		label: 'Carrotanques',

		palabrasSimilares: 'Camiones para transportar líquidos',
	},
	{
		code: '25101611',
		label: 'Camiones de carga',
	},
	{
		code: '25101612',
		label: 'Camiones concreteros',

		palabrasSimilares: 'Camión de concreto',
	},
	{
		code: '25101613',
		label: 'Camiones para bombear concreto',

		palabrasSimilares: 'Camiones bomba de hormigón',
	},
	{
		code: '25101700',
		label: 'Vehículos de protección y salvamento',
	},
	{
		code: '25101701',
		label: 'Camiones de bomberos y de rescate',
	},
	{
		code: '25101702',
		label: 'Carros policiales',
	},
	{
		code: '25101703',
		label: 'Ambulancias',
	},
	{
		code: '25101800',
		label: 'Bicicletas de motor',
	},
	{
		code: '25101801',
		label: 'Motocicletas',

		palabrasSimilares: 'Motoneta',
	},
	{
		code: '25101802',
		label: 'Scooters',

		palabrasSimilares: 'Patín del diablo',
	},
	{
		code: '25101803',
		label: 'Velomotores',

		palabrasSimilares: 'Bicicleta eléctrica, Ciclomotor',
	},
	{
		code: '25131705',
		label: 'Aviones no tripulados objetivo o de reconocimiento',
	},
	{
		code: '25131706',
		label: 'Hidroaviones militares',
	},
	{
		code: '25131707',
		label: 'Aeronave de reconocimiento o vigilancia',
	},
	{
		code: '25131708',
		label: 'Avión antisubmarino',
	},
	{
		code: '25131709',
		label: 'Avión de transporte militar',
	},
	{
		code: '25131800',
		label: 'Avión especial',
	},
	{
		code: '25131801',
		label: 'Dirigibles',
	},
	{
		code: '25131802',
		label: 'Aeromodelo',
	},
	{
		code: '25131900',
		label: 'Avión militar de ala giratoria',
	},
	{
		code: '25131902',
		label: 'Helicópteros de transporte militar',
	},
	{
		code: '25131903',
		label: 'Helicópteros de ataque',
	},
	{
		code: '25131904',
		label: 'Helicópteros de reconocimiento',
	},
	{
		code: '25131905',
		label: 'Helicópteros antisubmarinos',
	},
	{
		code: '25131906',
		label: 'Aeronave de alas giratorias basculantes',
	},
	{
		code: '25132000',
		label: 'Avión de recreo',
	},
	{
		code: '25132001',
		label: 'Aerodeslizadores',
	},
	{
		code: '25132002',
		label: 'Globos aerostáticos',
	},
	{
		code: '25132003',
		label: 'Planeadores',
	},
	{
		code: '25132004',
		label: 'Parapente',
	},
	{
		code: '25132005',
		label: 'Aviones ultralivianos',
	},
	{
		code: '25151500',
		label: 'Naves espaciales',
	},
	{
		code: '25151501',
		label: 'Nave espacial tripulada',
	},
	{
		code: '25151502',
		label: 'Estructuras de naves espaciales',
	},
	{
		code: '25151700',
		label: 'Satélites',
	},
	{
		code: '25174000',
		label: 'Sistema de refrigerar de motor',

		palabrasSimilares: 'Refacciones automotrices o autopartes',
	},
	{
		code: '25174001',
		label: 'Ventilador',
	},
	{
		code: '25174002',
		label: 'Radiadores de motor',
	},
	{
		code: '25174003',
		label: 'Tapas de radiador',
	},
	{
		code: '25174004',
		label: 'Refrigerante de motor',
	},
	{
		code: '25174005',
		label: 'Indicadores de aguja del tablero',
	},
	{
		code: '25174006',
		label: 'Ensamble de manguera de agua refrigerante',
	},
	{
		code: '25174100',
		label: 'Sistemas de tejado',

		palabrasSimilares: 'Refacciones automotrices o autopartes',
	},
	{
		code: '25174107',
		label: 'Deflectores de viento',
	},
	{
		code: '25174200',
		label: 'Sistema de dirección',

		palabrasSimilares: 'Refacciones automotrices o autopartes',
	},
	{
		code: '25174201',
		label: 'Sacos de dirección',

		palabrasSimilares: 'Visor de viento',
	},
	{
		code: '25174202',
		label: 'Suspensión de dirección',
	},
	{
		code: '25174203',
		label: 'Junta de bola',
	},
	{
		code: '25174204',
		label: 'Sistema de dirección hidráulica',

		palabrasSimilares: 'Rótula de dirección',
	},
	{
		code: '25174205',
		label: 'Bielas',
	},
	{
		code: '25174206',
		label: 'Contramanivela',
	},
	{
		code: '25174207',
		label: 'Varillas de dirección',
	},
	{
		code: '25174208',
		label: 'Eje de bloqueo',
	},
	{
		code: '25174209',
		label: 'Piñones',
	},
	{
		code: '25174210',
		label: 'Cable de afinamiento de dirección hidráulica',

		palabrasSimilares: 'Cable de ajuste de dirección hidráulica',
	},
	{
		code: '25174211',
		label: 'Timones o volantes',

		palabrasSimilares: 'Volantes',
	},
	{
		code: '25174212',
		label: 'Ensambles de columna de dirección',
	},
	{
		code: '25174213',
		label: 'Ensambles de cilindro de dirección',
	},
	{
		code: '25174214',
		label: 'Timones o volantes de madera',

		palabrasSimilares: 'Volante de madera',
	},
	{
		code: '25174215',
		label: 'Timones o volantes de cuero',

		palabrasSimilares: 'Volante de cuero',
	},
	{
		code: '25174216',
		label: 'Brazo articulado de dirección',
	},
	{
		code: '25174217',
		label: 'Engranaje de dirección de vehículo',

		palabrasSimilares: 'Equipo de dirección de vehículos',
	},
	{
		code: '25174400',
		label: 'Sistemas del interior de vehículos',

		palabrasSimilares: 'Refacciones automotrices o autopartes',
	},
	{
		code: '25174401',
		label: 'Biseles',
	},
	{
		code: '25174402',
		label: 'Consolas',
	},
	{
		code: '25174403',
		label: 'Paneles de puerta',
	},
	{
		code: '25174404',
		label: 'Tapizado del techo',
	},
	{
		code: '25174405',
		label: 'Grupos de instrumentos',
	},
	{
		code: '25174406',
		label: 'Paneles de instrumentos',
	},
	{
		code: '25174407',
		label: 'Pedales',
	},
	{
		code: '25174408',
		label: 'Puntos para energía o para encendedores',

		palabrasSimilares: 'Puertos de alimentación o encendedores',
	},
	{
		code: '25174409',
		label: 'Visores de sol',

		palabrasSimilares: 'Viseras de sol',
	},
	{
		code: '25174410',
		label: 'Componentes y sistemas de sonido del vehículo',

		palabrasSimilares: 'Sistema de audio para el vehículo',
	},
	{
		code: '25174411',
		label: 'Acolchado de caucho de la llave',
	},
	{
		code: '25174412',
		label: 'Aplique decorativo del vehículo',

		palabrasSimilares: 'Aplicación decorativa para vehículos',
	},
	{
		code: '25174413',
		label: 'Escudo o emblema interior del vehículo',
	},
	{
		code: '25174414',
		label: 'Tacógrafo',

		palabrasSimilares: 'Tacómetro de un automóvil',
	},
	{
		code: '25174415',
		label: 'Indicador de la presión de aceite del vehículo',
	},
	{
		code: '25174416',
		label: 'Encendedor de cigarrillos del vehículo',
	},
	{
		code: '25174417',
		label: 'Interruptor de combinación del vehículo',
	},
	{
		code: '25174418',
		label: 'Tapetes del vehículo',
	},
	{
		code: '25174419',
		label: 'Sistema de audio video del vehículo',
	},
	{
		code: '25174420',
		label: 'Cenicero del vehículo',
	},
	{
		code: '25174421',
		label: 'Cortina del vehículo',
	},
	{
		code: '25174600',
		label: 'Sistemas de asientos del vehículo',

		palabrasSimilares: 'Refacciones automotrices o autopartes',
	},
	{
		code: '25174601',
		label: 'Fundas de asientos',
	},
	{
		code: '25174602',
		label: 'Cojinería de asientos',

		palabrasSimilares: 'Cojines de asiento',
	},
	{
		code: '25174603',
		label: 'Marcos de asientos',
	},
	{
		code: '25174604',
		label: 'Silla de timonel de bote',

		palabrasSimilares: 'Asiento del timón o del barco',
	},
	{
		code: '25174605',
		label: 'Silla de motocicleta',
	},
	{
		code: '25174700',
		label: 'Componentes de ciclo no motorizado o accesorios',

		palabrasSimilares: 'Componentes de bicicletas o accesorios',
	},
	{
		code: '25174701',
		label: 'Pedales de bicicleta',
	},
	{
		code: '25174702',
		label: 'Rayos de bicicleta',
	},
	{
		code: '25174703',
		label: 'Caja trasera de motocicleta',
	},
	{
		code: '25174704',
		label: 'Canasta para vehículo de dos ruedas',
	},
	{
		code: '25174705',
		label: 'Caja para llevar el correo en vehículo de dos ruedas',
	},
	{
		code: '25174800',
		label: 'Componentes y sistemas de vehículos especializados',

		palabrasSimilares: 'Refacciones automotrices o autopartes',
	},
	{
		code: '25174801',
		label: 'Estabilizador de camión de succión o gato de piso',
	},
	{
		code: '25174802',
		label: 'Boom para vehículos de rescate o de remolque',
	},
	{
		code: '25174803',
		label: 'Gancho de remolcar',
	},
	{
		code: '25174804',
		label: 'Mástil lateral para vehículo',
	},
	{
		code: '25174805',
		label: 'Aspersor de arena para vehículo',

		palabrasSimilares: 'Esparcidor de arena o sal para vehículos',
	},
	{
		code: '25174806',
		label: 'Pijama para vehículo a la medida',

		palabrasSimilares: 'Funda cubre auto',
	},
	{
		code: '25174807',
		label: 'Bisagra de vehículo',
	},
	{
		code: '25174808',
		label: 'Caja de almacenamiento de accesorio para vehículos',
	},
	{
		code: '25174809',
		label: 'Escalera de vehículo',
	},
	{
		code: '25174810',
		label: 'Gato de vehículo',
	},
	{
		code: '25174900',
		label: 'Aisladores y amortiguadores de vibración de vehículos',

		palabrasSimilares: 'Refacciones automotrices o autopartes',
	},
	{
		code: '25174901',
		label: 'Aislador y amortiguador moldeado para la vibración del vehículo',

		palabrasSimilares:
			'Amortiguador y aislador de vibraciones de vehículos moldeados',
	},
	{
		code: '25174902',
		label: 'Aislador y amortiguador extruido para la vibración del vehículo',

		palabrasSimilares:
			'Amortiguador y aislador de vibraciones del vehículo extruido',
	},
	{
		code: '25174903',
		label: 'Aislador y amortiguador fundido para la vibración del vehículo',

		palabrasSimilares: 'Amortiguador y aislador de vibraciones',
	},
	{
		code: '25175000',
		label: 'Sistemas de cargado eléctrico de vehículos',

		palabrasSimilares: 'Refacciones automotrices o autopartes',
	},
	{
		code: '25175001',
		label: 'Cargador portátil para vehículo eléctrico',
	},
	{
		code: '25175002',
		label: 'Estación de carga de vehículo eléctrico',
	},
	{
		code: '25175003',
		label: 'Kit para cargar vehículo eléctrico',
	},
	{
		code: '25181600',
		label: 'Chasis automotriz',

		palabrasSimilares: 'Chasis para automotor',
	},
	{
		code: '25181601',
		label: 'Chasis para automóviles',

		palabrasSimilares: 'Armazón para automóviles',
	},
	{
		code: '25181602',
		label: 'Chasis para camiones',

		palabrasSimilares: 'Armazón para camiones',
	},
	{
		code: '25181603',
		label: 'Bastidores de motos',

		palabrasSimilares: 'Chasis para motos',
	},
	{
		code: '25181604',
		label: 'Carrocería de camión de carga',
	},
	{
		code: '25181605',
		label: 'Carrocería de volqueta',

		palabrasSimilares: 'Carrocería para camión de volteo',
	},
	{
		code: '25181606',
		label: 'Carrocería de tráiler',

		palabrasSimilares: 'Plataforma de tráiler',
	},
	{
		code: '25181607',
		label: 'Cabina de tractor',
	},
	{
		code: '25181608',
		label: 'Bastidor de vehículo',
	},
	{
		code: '25181609',
		label: 'Tráiler granelero',

		palabrasSimilares: 'Tráiler para remolque granelero',
	},
	{
		code: '25181610',
		label: 'Tráiler para carga aérea',

		palabrasSimilares: 'Tráiler para remolcar carga aérea',
	},
	{
		code: '25181611',
		label: 'Tráiler dolly',

		palabrasSimilares: 'Vehículo sin motor para conexión',
	},
	{
		code: '25181612',
		label: 'Tráiler agrícola',

		palabrasSimilares: 'Remolque agrícola',
	},
	{
		code: '25181700',
		label: 'Remolques para productos y materiales',

		palabrasSimilares: 'Vehículos para traslado de productos y materiales',
	},
	{
		code: '25181701',
		label: 'Tráiler para contenedores',

		palabrasSimilares: 'Tráiler para remolque de contenedores',
	},
	{
		code: '25181702',
		label: 'Tráiler cama alta',

		palabrasSimilares: 'Tráiler con plataforma',
	},
	{
		code: '25181703',
		label: 'Remolques para ganado',
	},
	{
		code: '25181704',
		label: 'Remolques carrotanque sin temperatura controlada',

		palabrasSimilares: 'Tanques de almacenamiento sin temperatura controlada',
	},
	{
		code: '25181705',
		label: 'Remolques carrotanque con temperatura controlada',

		palabrasSimilares: 'Tanques de almacenamiento con temperatura controlada',
	},
	{
		code: '25181706',
		label: 'Remolques contenedor con temperatura controlada',

		palabrasSimilares: 'Contenedores de temperatura controlada',
	},
	{
		code: '25181707',
		label: 'Remolques para transporte de automóviles (niñeras)',

		palabrasSimilares:
			'Madrinas para transporte de automóviles, Plataforma para transporte de automóviles',
	},
	{
		code: '25181708',
		label: 'Enganches de remolque',

		palabrasSimilares: 'Gancho de remolque',
	},
	{
		code: '25181709',
		label: 'Pala cargadora',

		palabrasSimilares:
			'Cuchara cargadora, Pala cargadora frontal, Pala mecánic',
	},
	{
		code: '25181710',
		label: 'Placas de matrícula de tráiler',

		palabrasSimilares: 'Placas de tráiler',
	},
	{
		code: '25181711',
		label: 'Remolque de motonieve',

		palabrasSimilares: 'Trineo remolque',
	},
	{
		code: '25181712',
		label: 'Remolque de motocicleta',
	},
	{
		code: '25181713',
		label: 'Remolque de botes',
	},
	{
		code: '25181714',
		label: 'Semi – remolque',

		palabrasSimilares: 'Caja de remolque',
	},
	{
		code: '25181715',
		label: 'Tráiler volqueta',

		palabrasSimilares: 'Remolque góndola, Remolque volquete, Tráiler de volte',
	},
	{
		code: '25181716',
		label: 'Chasís tráiler',

		palabrasSimilares: 'Armazón para tráiler',
	},
	{
		code: '25181717',
		label: 'Tráiler cama baja',

		palabrasSimilares: 'Remolque para cama baja, Tráiler con plataforma baja',
	},
	{
		code: '25181718',
		label: 'Tráiler con carrete de cable',

		palabrasSimilares: 'Tráiler con remolque de carrete de cable',
	},
	{
		code: '25181719',
		label: 'Tráiler refrigerado',

		palabrasSimilares: 'Tráiler con remolque de refrigeración',
	},
	{
		code: '25191500',
		label: 'Sistemas y equipo de apoyo para transporte aéreo',
	},
	{
		code: '25191501',
		label: 'Sistemas de entrenamiento de apoyo terrestre',
	},
	{
		code: '25191502',
		label: 'Sistemas de ensayo o mantenimiento de apoyo terrestre',
	},
	{
		code: '25191503',
		label: 'Sistemas integrados de información de mantenimiento',
	},
	{
		code: '25191504',
		label: 'Simuladores de vuelo para aviones',

		palabrasSimilares: 'Simuladores o entrenadores de vuelo de aeronaves',
	},
	{
		code: '25191505',
		label: 'Medios de carga y descarga para aviones',

		palabrasSimilares: 'Palet de carga y descarga de aviones',
	},
	{
		code: '25191506',
		label: 'Equipo para recargar combustible de aviones',

		palabrasSimilares: 'Equipo de reabastecimiento de combustible de aviones',
	},
	{
		code: '25191507',
		label: 'Equipo para deshielo de aviones',

		palabrasSimilares: 'Equipos de deshielo de aeronaves',
	},
	{
		code: '25191508',
		label: 'Pasarela telescópica para aviones',

		palabrasSimilares: 'Puente de espera, embarque o llegada para aviones',
	},
	{
		code: '25191509',
		label: 'Tractores remolcadores para aviones',

		palabrasSimilares: 'Remolcador de aviones',
	},
	{
		code: '25191510',
		label: 'Unidades de potencia para pista aérea',
	},
	{
		code: '25191511',
		label: 'Equipo de lavabos para aeronaves',

		palabrasSimilares: 'Equipo de servicio de lavado para aviones',
	},
	{
		code: '25191512',
		label: 'Escalerillas transportables o rodantes',

		palabrasSimilares: 'Escaleras transportables o rodantes',
	},
	{
		code: '25191513',
		label: 'Kit de mantenimiento de vehículo de soporte en tierra',
	},
	{
		code: '25191514',
		label: 'Barra de remolque de avión',

		palabrasSimilares: 'Barra de remolque para avión ligero',
	},
	{
		code: '25191515',
		label: 'Sistema de aproximación controlado por tierra',

		palabrasSimilares: 'Sistema de aterrizaje instrumental',
	},
	{
		code: '25191516',
		label: 'Unidad neumática para arranque de aeronaves',

		palabrasSimilares: 'Unidad de arranque de aire de la aeronave',
	},
	{
		code: '25191517',
		label: 'Camión calefactor y de aire acondicionado para aeronaves',
	},
	{
		code: '25191518',
		label: 'Equipo sensor de pruebas aeronáuticas',

		palabrasSimilares: 'Sistema de pruebas aeronáuticas',
	},
	{
		code: '25191519',
		label: 'Adaptador de remolcado de aviones',
	},
	{
		code: '25191520',
		label: 'Cámara de prueba ambiental',
	},
	{
		code: '25191521',
		label: 'Sistema retráctil de soporte de cable gancho',
	},
	{
		code: '25191522',
		label: 'Set de mantenimiento de motor de aeronave',
	},
	{
		code: '25191523',
		label: 'Tractor para remolcar contenedores de equipaje aéreo',

		palabrasSimilares: 'Remolcador o tractor de equipaje de avión',
	},
	{
		code: '25191524',
		label: 'Banda transportadora para cargar equipaje en el avión',
	},
	{
		code: '25191525',
		label: 'Camión para cargar combustible al avión',
	},
	{
		code: '25191600',
		label: 'Sistemas y equipo de apoyo para transporte espacial',
	},
	{
		code: '25191601',
		label: 'Simuladores de vuelo de naves espaciales',
	},
	{
		code: '25191602',
		label: 'Sistemas de suministro de carga para naves espaciales',
	},
	{
		code: '25191603',
		label: 'Vehículos de lanzamiento líquido',
	},
	{
		code: '25191604',
		label: 'Vehículos de lanzamiento sólido',
	},
	{
		code: '25191605',
		label: 'Módulos de servicio para naves espaciales',
	},
	{
		code: '25191700',
		label: 'Equipo para el mantenimiento de vehículo',
	},
	{
		code: '25191701',
		label: 'Equipos de balanceo de llantas',

		palabrasSimilares: 'Equipos de balanceo de neumáticos',
	},
	{
		code: '25191702',
		label: 'Equipos de alineación de llantas',

		palabrasSimilares: 'Equipos de alineación de neumáticos',
	},
	{
		code: '25191703',
		label: 'Máquinas para cambiar llantas',

		palabrasSimilares: 'Cambiadores de neumáticos, Desmontadora de llantas',
	},
	{
		code: '25191704',
		label: 'Pedestales de vehículo o motor',

		palabrasSimilares: 'Soporte de vehículo o motor',
	},
	{
		code: '25191705',
		label: 'Balanza de pistón y varilla de motor',

		palabrasSimilares: 'Balanza de presión y motor de levas',
	},
	{
		code: '25191706',
		label: 'Gato para levantar material rodante férreo',

		palabrasSimilares: 'Colector elevador de material rodante ferroviario',
	},
	{
		code: '25191707',
		label: 'Mesa ferroviaria para levantar motor y componente',
	},
	{
		code: '25191708',
		label: 'Balanceo de carburador',
	},
	{
		code: '25191709',
		label: 'Ajustador de potencia',

		palabrasSimilares: 'Regulador de potencia',
	},
	{
		code: '25191710',
		label: 'Lapeadora de válvulas',
	},
	{
		code: '25191711',
		label: 'Pulidora de válvulas',

		palabrasSimilares: 'Pasta pulidora de válvulas',
	},
	{
		code: '25191712',
		label: 'Cortador de asientos de válvulas',

		palabrasSimilares: 'Cortador de asientos de obturador',
	},
	{
		code: '25191713',
		label: 'Dispositivo para reciclar anticongelante',
	},
	{
		code: '25191714',
		label: 'Torno para frenos de tambor',
	},
	{
		code: '25191715',
		label: 'Pulidora de zapatas de freno',
	},
	{
		code: '25191716',
		label: 'Alicate para resortes de freno',
	},
	{
		code: '25191717',
		label: 'Máquina para enderezar carrocerías de vehículos',
	},
	{
		code: '25191718',
		label: 'Carrito para reparar carros por debajo',
	},
	{
		code: '25191719',
		label: 'Llave para tuercas de llantas',
	},
	{
		code: '25191720',
		label: 'Llave de bujías',
	},
	{
		code: '25191721',
		label: 'Limpiador de bujías',
	},
	{
		code: '25191722',
		label: 'Juego de herramientas de motor',
	},
	{
		code: '25191723',
		label: 'Calentador de pistones',
	},
	{
		code: '25191724',
		label: 'Kit para reconstruir transmisión automática',
	},
	{
		code: '25191725',
		label: 'Máquina perforadora para automóviles',
	},
	{
		code: '25191726',
		label: 'Máquina perfeccionadora para automóviles',
	},
	{
		code: '25191727',
		label: 'Alineador de varillas de conexión',
	},
	{
		code: '25191728',
		label: 'Balanceador de cigüeñal',

		palabrasSimilares: 'Balanceador de eje con engranaje',
	},
	{
		code: '25191729',
		label: 'Pulidora de cigüeñal',
	},
	{
		code: '25191730',
		label: 'Luz de toma de tiempos',

		palabrasSimilares: 'Luz de sincronización',
	},
	{
		code: '25191731',
		label: 'Limpiador de convertidor de torque',
	},
	{
		code: '25191732',
		label: 'Perno roscado para pistón',
	},
	{
		code: '25191733',
		label: 'Pulidora de pistones',
	},
	{
		code: '25191734',
		label: 'Compresor de resorte de válvula',

		palabrasSimilares: 'Compresor de muelles de válvula',
	},
	{
		code: '25191735',
		label: 'Compresor de anillo de pistón',
	},
	{
		code: '25191736',
		label: 'Simulador de conducción de vehículo',
	},
	{
		code: '25191737',
		label: 'Inflador automático de llantas',

		palabrasSimilares: 'Inflador automático de neumáticos',
	},
	{
		code: '25191738',
		label: 'Abridor de llantas',

		palabrasSimilares: 'Esparcidor de neumáticos',
	},
	{
		code: '25191739',
		label: 'Tester de simulación de engranajes de dirección',
	},
	{
		code: '25191740',
		label: 'Máquina para lavar vehículos',

		palabrasSimilares: 'Lavadora de vehículos',
	},
	{
		code: '25191741',
		label: 'Máquina para lavar trenes',

		palabrasSimilares: 'Lavadora de ferrocarril',
	},
	{
		code: '25191742',
		label: 'Máquina para lavar partes de vehículos',

		palabrasSimilares: 'Lavadora de partes de vehículos',
	},
	{
		code: '25191743',
		label: 'Máquina para lavar componentes de ferrocarril',

		palabrasSimilares: 'Lavadora de componentes ferroviarios',
	},
	{
		code: '25191800',
		label: 'Equipo de medición y prueba de vehículos',
	},
	{
		code: '25191801',
		label: 'Probador de inducidos',
	},
	{
		code: '25191802',
		label: 'Tacómetro de ángulo de contacto',
	},
	{
		code: '25191803',
		label: 'Tester de humo diesel',

		palabrasSimilares: 'Probador de humo diésel',
	},
	{
		code: '25191804',
		label: 'Probador de radiador',

		palabrasSimilares: 'Probador de presión del radiador',
	},
	{
		code: '25191805',
		label: 'Probador de distribuidor de motor',
	},
	{
		code: '25191806',
		label: 'Hidrómetro de batería',
	},
	{
		code: '25191807',
		label: 'Detector de fugas de aceite de rodamiento',
	},
	{
		code: '25191808',
		label: 'Probador de freno',
	},
	{
		code: '25191809',
		label: 'Probador de ángulo de deslizamiento',
	},
	{
		code: '25191810',
		label: 'Dinamómetro de chasis',
	},
	{
		code: '25191811',
		label: 'Probador del velocímetro',
	},
	{
		code: '25191812',
		label: 'Probador de bloqueo',
	},
	{
		code: '25191813',
		label: 'Tester de brecha de bujías',

		palabrasSimilares: 'Indicador de espacio entre bujías',
	},
	{
		code: '25191814',
		label: 'Tester de bujías',

		palabrasSimilares: 'Probador de bujías',
	},
	{
		code: '25191815',
		label: 'Tester del inducido',

		palabrasSimilares: 'Probador de armadura',
	},
	{
		code: '25191816',
		label: 'Tester del resorte tipo compresión',

		palabrasSimilares: 'Comprobador de muelles de compresión',
	},
	{
		code: '25191817',
		label: 'Medidor de presión de la compresión',
	},
	{
		code: '25191818',
		label: 'Tester del elemento limpiador de aire',

		palabrasSimilares: 'Probador de elemento de filtro de aire',
	},
	{
		code: '25191819',
		label: 'Tester de simulación de los frenos anti bloqueo abs',

		palabrasSimilares:
			'Sistema de frenado antibloqueo probador de simulación ABS',
	},
	{
		code: '25191820',
		label: 'Multímetro de motor',
	},
	{
		code: '25191821',
		label: 'Tester de simulador de motor',

		palabrasSimilares: 'Probador de simulación de motor',
	},
	{
		code: '25191822',
		label: 'Tester de inyector de motor',

		palabrasSimilares: 'Probador del inyector del motor',
	},
	{
		code: '25191823',
		label: 'Lecho de prueba de motor',

		palabrasSimilares: 'Banco de prueba del motor',
	},
	{
		code: '25191824',
		label: 'Probador de puesta a punto del motor',
	},
	{
		code: '25191825',
		label: 'Medidor de consumo de combustible',
	},
	{
		code: '25191826',
		label: 'Tester de fugas de aceite',

		palabrasSimilares: 'Comprobador de fugas de aceite',
	},
	{
		code: '25191827',
		label: 'Tester de simulación de suspensión de control electrónico ecs',
	},
	{
		code: '25191828',
		label: 'Tester de transmisión automática',

		palabrasSimilares: 'Probador de transmisión automática',
	},
	{
		code: '25191829',
		label: 'Sistema de inspección de control electrónico',
	},
	{
		code: '25191830',
		label: 'Tester de luz frontal',

		palabrasSimilares: 'Probador de faros',
	},
	{
		code: '25191831',
		label: 'Tester del regulador de ventanas del vehículo',
	},
	{
		code: '25191832',
		label: 'Medidor de vacío para mantenimiento de vehículo',

		palabrasSimilares: 'Válvula de vacío para servicio de vehículos',
	},
	{
		code: '25191833',
		label: 'Medidor de avance del camber',

		palabrasSimilares: 'Calibrador de caster',
	},
	{
		code: '25191834',
		label: 'Tester de resistencia de bobina de condensación o ohm',

		palabrasSimilares: 'Resistencia del condensador de bobina o ohm',
	},
	{
		code: '25191835',
		label: 'Válvula de presión de ruedas',
	},
	{
		code: '25191836',
		label: 'Medidor de convergencia de las ruedas delanteras',
	},
	{
		code: '25191837',
		label: 'Medidor de radio de giro',
	},
	{
		code: '25191838',
		label: 'Sistema de inspección de vehículos automotores',
	},
	{
		code: '25201500',
		label: 'Fuselaje y componentes de avión',
	},
	{
		code: '25201501',
		label: 'Spoilers de avión',

		palabrasSimilares: 'Aerofrenos',
	},
	{
		code: '25201502',
		label: 'Aletas de avión',
	},
	{
		code: '25201503',
		label: 'Estabilizadores horizontales de avión',
	},
	{
		code: '25201504',
		label: 'Aletas canard de avión',
	},
	{
		code: '25201505',
		label: 'Listones de avión',
	},
	{
		code: '25201506',
		label: 'Flaps o transmisión de flaps de avión',

		palabrasSimilares: 'Aletas o transmisión de aletas de avión',
	},
	{
		code: '25201507',
		label: 'Timones de avión',

		palabrasSimilares: 'Timones de dirección',
	},
	{
		code: '25201508',
		label: 'Elevadores de avión',
	},
	{
		code: '25201509',
		label: 'Alerones de avión',
	},
	{
		code: '25201510',
		label: 'Propulsores de avión',
	},
	{
		code: '25201511',
		label: 'Alas de avión',
	},
	{
		code: '25201512',
		label: 'Fuselajes de avión',
	},
	{
		code: '25201513',
		label: 'Cúpulas protectoras de la antena de avión',
	},
	{
		code: '25201514',
		label: 'Rotores de avión',
	},
	{
		code: '25201515',
		label: 'Ventilador de elevación de avión',
	},
	{
		code: '25201516',
		label: 'Escudetes de avión',
	},
	{
		code: '25201517',
		label: 'Muebles de avión',
	},
	{
		code: '25201518',
		label: 'Dobladores de avión',
	},
	{
		code: '25201519',
		label: 'Nervaduras de avión',
	},
	{
		code: '25201520',
		label: 'Largueros de avión',
	},
	{
		code: '25201521',
		label: 'Aleta compensadora para aspa de hélice',
	},
	{
		code: '25201522',
		label: 'Vaina universal de helicóptero',
	},
	{
		code: '25201600',
		label: 'Sistemas de situación y navegación aeroespacial y componentes',
	},
	{
		code: '25201601',
		label: 'Sistemas de control digital del altitud del avión',
	},
	{
		code: '25201602',
		label: 'Faros de navegación de avión',
	},
	{
		code: '25201603',
		label: 'Sistemas de seguimiento de aire a tierra',
	},
	{
		code: '25201604',
		label: 'Sistemas de guía aeronáutica',
	},
	{
		code: '25201605',
		label: 'Controles de volante de avión',
	},
	{
		code: '25201606',
		label: 'Sistemas de control de la altitud del nave espacial',
	},
	{
		code: '25201700',
		label: 'Sistemas relacionados con las comunicaciones de vuelo',
	},
	{
		code: '25201701',
		label: 'Sistemas de comunicación del avión',
	},
	{
		code: '25201702',
		label: 'Registradores de datos de vuelo',

		palabrasSimilares: 'Caja negra',
	},
	{
		code: '25201703',
		label: 'Contramedidas de avión',

		palabrasSimilares: 'Contramedidas Infrarrojas',
	},
	{
		code: '25201704',
		label: 'Sistemas de codificación o de decodificación',
	},
	{
		code: '25201705',
		label: 'Sistemas de telemetría de aeronaves',
	},
	{
		code: '25201706',
		label: 'Electrónica de interfaz del avión',
	},
	{
		code: '25201707',
		label: 'Giroscopio del avión',
	},
	{
		code: '25201708',
		label: 'Cámaras de avión',
	},
	{
		code: '25201709',
		label: 'Sondas o sensores de avión',
	},
	{
		code: '25201710',
		label: 'Guías de onda del avión',
	},
	{
		code: '25201800',
		label: 'Sistemas de control principal del avión',
	},
	{
		code: '25201801',
		label: 'Sistemas de computadores de vuelo',
	},
	{
		code: '25201802',
		label: 'Módulos de comando de naves especiales',
	},
	{
		code: '25201900',
		label: 'Sistemas de urgencia del avión',
	},
	{
		code: '25201901',
		label: 'Sistemas de control o extinción de incendios en aeronaves',
	},
	{
		code: '25201902',
		label: 'Sistemas de escape o eyección de aeronaves',
	},
	{
		code: '25201903',
		label: 'Sistemas de advertencia del avión',

		palabrasSimilares:
			'Sistema de alerta de tráfico y evasión de colisión, Sistemas de aviso de aeronaves',
	},
	{
		code: '25201904',
		label: 'Paracaídas',
	},
	{
		code: '25202000',
		label: 'Sistemas de energía de avión',
	},
	{
		code: '25202001',
		label: 'Células solares de la nave espacial',

		palabrasSimilares: 'Celdas solares de la nave especial',
	},
	{
		code: '25202002',
		label: 'Formaciones solares de la nave espacial',
	},
	{
		code: '25202003',
		label: 'Unidades de la fuente de alimentación de avión',

		palabrasSimilares: 'Unidades de suministro de energía para aviones',
	},
	{
		code: '25202004',
		label: 'Sistemas de grupo electrógeno de pista (apu)',

		palabrasSimilares: 'Sistemas de alimentación auxiliar APUs',
	},
	{
		code: '25202100',
		label: 'Instrumentación de vuelo',
	},
	{
		code: '25202101',
		label: 'Indicadores de la cabina aeroespacial',
	},
	{
		code: '25202102',
		label: 'Medidores de la cabina aeroespacial',

		palabrasSimilares: 'Medidores de la cabina de vuelo',
	},
	{
		code: '25202103',
		label: 'Paneles de despliegue aeroespacial de la cabina',
	},
	{
		code: '25202104',
		label: 'Paneles de interruptores aeroespaciales de la cabina',
	},
	{
		code: '25202105',
		label: 'Presentación visual de cabeza levantada (hud)',
	},
	{
		code: '25202200',
		label: 'Sistemas del aterrizaje y de frenos del avión',
	},
	{
		code: '25202201',
		label: 'Sistemas de frenar del avión',

		palabrasSimilares: 'Sistemas de frenado de aeronaves',
	},
	{
		code: '25202202',
		label: 'Rampas de arrastre de avión',

		palabrasSimilares: 'Carro de arrastre de avión, Remolcador de aviones',
	},
	{
		code: '25202203',
		label: 'Ruedas de avión',
	},
	{
		code: '25202204',
		label: 'Montajes del tren de aterrizaje',
	},
	{
		code: '25202205',
		label: 'Llantas de avión',

		palabrasSimilares: 'Neumáticos de avión',
	},
	{
		code: '25202206',
		label: 'Controles anti – deslizamiento de avión',
	},
	{
		code: '25202207',
		label: 'Contrafuerte del tren de aterrizaje',

		palabrasSimilares:
			'Brazo de arrastre del tren de aterrizaje, Estribo del tren de aterrizaje',
	},
	{
		code: '25202300',
		label: 'Controles de pasajeros de avión',
	},
	{
		code: '25202301',
		label: 'Cinturones de seguridad del avión',
	},
	{
		code: '25202302',
		label: 'Arneses de seguridad del avión',
	},
	{
		code: '25202400',
		label: 'Sistemas y depósitos de combustible del avión',
	},
	{
		code: '25202401',
		label: 'Tanques interiores de combustible del avión',

		palabrasSimilares: 'Depósitos de combustible interno de las aeronaves',
	},
	{
		code: '25202402',
		label: 'Depósitos desechables de combustible del avión',
	},
	{
		code: '25202403',
		label: 'Tanques de propelente del avión',

		palabrasSimilares: 'Tanque propulsor de aeronaves',
	},
	{
		code: '25202404',
		label: 'Sistemas híbridos de almacenaje de combustible de avión',
	},
	{
		code: '25202405',
		label: 'Sistemas de manejo de combustible del avión',
	},
	{
		code: '25202406',
		label: 'Post – impulsores',

		palabrasSimilares: 'Sistema de propulsión',
	},
	{
		code: '25202500',
		label: 'Equipo de avión',
	},
	{
		code: '25202501',
		label: 'Sistemas hidráulicos de avión',
	},
	{
		code: '25202502',
		label: 'Iluminación exterior de avión',
	},
	{
		code: '25202503',
		label: 'Iluminación interior de avión',
	},
	{
		code: '25202504',
		label: 'Limpiaparabrisas de avión',
	},
	{
		code: '25202505',
		label:
			'Sistemas de descongelación o sistemas de desempañar a bordo de avión',
	},
	{
		code: '25202506',
		label: 'Puertas de avión',
	},
	{
		code: '25202507',
		label: 'Ventanas de avión',
	},
	{
		code: '25202508',
		label: 'Parabrisas de avión',
	},
	{
		code: '25202509',
		label: 'Montajes de choque del avión',

		palabrasSimilares: 'Soporte de choque para aeronaves',
	},
	{
		code: '25202510',
		label: 'Conjuntos de anillo colector del avión',

		palabrasSimilares: 'Anillo colector de avión',
	},
	{
		code: '25202600',
		label: 'Sistemas y componentes del control ambiental del avión',
	},
	{
		code: '25202601',
		label: 'Controladores ambientales del avión',
	},
	{
		code: '25202602',
		label: 'Reguladores ambientales del avión',
	},
	{
		code: '25202603',
		label: 'Turbinas para enfriar el avión',

		palabrasSimilares: 'Turbinas de enfriamiento de aeronaves',
	},
	{
		code: '25202604',
		label: 'Ventiladores para enfriar el avión',

		palabrasSimilares: 'Ventiladores de enfriamiento de aeronaves',
	},
	{
		code: '25202605',
		label: 'Intercambiadores de calor del avión',
	},
	{
		code: '25202606',
		label: 'Separadores del agua del avión',
	},
	{
		code: '25202607',
		label: 'Equipo del oxígeno del avión',
	},
	{
		code: '25202700',
		label: 'Acumuladores del avión',
	},
	{
		code: '25202701',
		label: 'Acumuladores hidráulicos del avión',

		palabrasSimilares: 'Acumuladores hidráulicos de aeronaves',
	},
	{
		code: '25202702',
		label: 'Acumuladores neumáticos del avión',
	},
	{
		code: '26101100',
		label: 'Motores eléctricos de corriente alterna AC',
	},
	{
		code: '26101101',
		label: 'Motor ac automotriz',

		palabrasSimilares:
			'Motor de corriente alterna para automóvil, Motor eléctrico de corriente alterna para automóvil',
	},
	{
		code: '26101102',
		label: 'Motor ac de freno',

		palabrasSimilares: 'Motor de corriente alterna de freno',
	},
	{
		code: '26101103',
		label: 'Motor ac de trabajo de granja',

		palabrasSimilares: 'Motor de corriente alterna de trabajo de granja',
	},
	{
		code: '26101105',
		label: 'Motor ac de calefacción y enfriamiento',

		palabrasSimilares:
			'Motor de corriente alterna de calefacción y enfriamiento',
	},
	{
		code: '26101106',
		label: 'Motor ac inversor',

		palabrasSimilares: 'Motor de corriente alterna inversor',
	},
	{
		code: '26101107',
		label: 'Motor ac de bomba',

		palabrasSimilares: 'Motor de corriente alterna de bomba',
	},
	{
		code: '26101108',
		label: 'Motor ac de compresor',

		palabrasSimilares: 'Motor de corriente alterna de compresor',
	},
	{
		code: '26101109',
		label: 'Motor ac sincrónico',

		palabrasSimilares: 'Motor de corriente alterna sincrónico',
	},
	{
		code: '26101110',
		label: 'Motor ac multi – velocidad',

		palabrasSimilares: 'Motor de corriente alterna multi velocidad',
	},
	{
		code: '26101111',
		label: 'Motor ac de de lavadora a presión',

		palabrasSimilares: 'Motor de corriente alterna de lavado a presión',
	},
	{
		code: '26101112',
		label: 'Motor ac de propósito general',

		palabrasSimilares: 'Motor de corriente alterna de propósito general',
	},
	{
		code: '26101113',
		label: 'Motor ac sumergible',

		palabrasSimilares: 'Motor de corriente alterna sumergible',
	},
	{
		code: '26101114',
		label: 'Motor ac de inducción',

		palabrasSimilares: 'Motor de corriente alterna de inducción',
	},
	{
		code: '26101115',
		label: 'Motor ac multi – fase',

		palabrasSimilares: 'Motor de corriente alterna multi fase',
	},
	{
		code: '26101116',
		label: 'Motor ac de fase sencilla',

		palabrasSimilares: 'Motor de corriente alterna de fase sencilla',
	},
	{
		code: '26101117',
		label: 'Motor ac de eje motriz',

		palabrasSimilares: 'Motor de corriente alterna de eje motriz',
	},
	{
		code: '26101200',
		label: 'Motores eléctricos de corriente directa DC',
	},
	{
		code: '26101201',
		label: 'Motor dc en derivación',

		palabrasSimilares: 'Motor de corriente directa en derivación',
	},
	{
		code: '26101202',
		label: 'Motor dc de pasos',

		palabrasSimilares: 'Motor de corriente directa de pasos',
	},
	{
		code: '26101203',
		label: 'Motor dc sin núcleo',

		palabrasSimilares: 'Motor de corriente directa sin núcleo',
	},
	{
		code: '26101204',
		label: 'Motor dc con excitación en derivación',

		palabrasSimilares:
			'Motor de corriente directa con excitación en derivación',
	},
	{
		code: '26101205',
		label: 'Servo motor dc',

		palabrasSimilares: 'Servomotor de corriente directa',
	},
	{
		code: '26101206',
		label: 'Motor dc de ángulo de torque limitado',

		palabrasSimilares:
			'Motor de corriente directa de ángulo de torque limitado',
	},
	{
		code: '26101207',
		label: 'Motor dc linear',

		palabrasSimilares: 'Motor de corriente directa linear',
	},
	{
		code: '26101208',
		label: 'Motor dc de imán permanente',

		palabrasSimilares: 'Motor de corriente directa de imán permanente',
	},
	{
		code: '26101209',
		label: 'Motor dc sin escobillas',

		palabrasSimilares: 'Motor de corriente directa sin escobillas',
	},
	{
		code: '26101210',
		label: 'Motor dc de excitación compuesta',

		palabrasSimilares: 'Motor de corriente directa de excitación compuesta',
	},
	{
		code: '26101211',
		label: 'Motor ultrasónico o de vibración',
	},
	{
		code: '26101212',
		label: 'Motor de huso',
	},
	{
		code: '26101300',
		label: 'Motores no eléctricos',
	},
	{
		code: '26101301',
		label: 'Motor de aire',

		palabrasSimilares: 'Motor de arranque',
	},
	{
		code: '26101302',
		label: 'Alternador',
	},
	{
		code: '26101303',
		label: 'Dinamotor',
	},
	{
		code: '26101304',
		label: 'Motor hidráulico',
	},
	{
		code: '26101306',
		label: 'Motor de cohete líquido',
	},
	{
		code: '26101309',
		label: 'Motor de cohete sólido',
	},
	{
		code: '26101310',
		label: 'Motor de torque',
	},
	{
		code: '26101311',
		label: 'Motor universal',
	},
	{
		code: '26101312',
		label: 'Motor de pistón axial',
	},
	{
		code: '26101313',
		label: 'Motor de pistón radial',
	},
	{
		code: '26101400',
		label: 'Componentes de motores o generadores',
	},
	{
		code: '26101401',
		label: 'Inducido',

		palabrasSimilares: 'Bobina de arranque',
	},
	{
		code: '26101402',
		label: 'Adaptador de base de motor',
	},
	{
		code: '26101403',
		label: 'Freno de motor',
	},
	{
		code: '26101404',
		label: 'Escobilla de motor',
	},
	{
		code: '26101405',
		label: 'Bobina de motor',
	},
	{
		code: '26101406',
		label: 'Montura o base de motor',
	},
	{
		code: '26101408',
		label: 'Poste de motor',
	},
	{
		code: '26101409',
		label: 'Rotor de motor',
	},
	{
		code: '26101410',
		label: 'Estator de motor',
	},
	{
		code: '26101411',
		label: 'Laminación de motor',
	},
	{
		code: '26101412',
		label: 'Kit de reparación de motor',
	},
	{
		code: '26101413',
		label: 'Carcasa de motor',
	},
	{
		code: '26101414',
		label: 'Transportador de escobillas de motor',
	},
	{
		code: '26101415',
		label: 'Conmutador de motor',
	},
	{
		code: '26101500',
		label: 'Motores',
	},
	{
		code: '26101501',
		label: 'Motores hidráulicos',
	},
	{
		code: '26101502',
		label: 'Motores neumáticos',
	},
	{
		code: '26101503',
		label: 'Motores a gas',
	},
	{
		code: '26101504',
		label: 'Motores diesel',
	},
	{
		code: '26101505',
		label: 'Motores de vapor',
	},
	{
		code: '26101506',
		label: 'Motores de turbina',
	},
	{
		code: '26101507',
		label: 'Motores de turbina con hélice',
	},
	{
		code: '26101508',
		label: 'Motores térmicos',
	},
	{
		code: '26101509',
		label: 'Motores hidroeléctricos',
	},
	{
		code: '26101510',
		label: 'Máquinas rotativas',
	},
	{
		code: '26101511',
		label: 'Motores de turbina hidráulica',
	},
	{
		code: '26101512',
		label: 'Motores turbohélice',
	},
	{
		code: '26101513',
		label: 'Kit de reparación de motores',
	},
	{
		code: '26101514',
		label: 'Motor de jet',
	},
	{
		code: '26101515',
		label: 'Motor fuera de borda a gasolina',
	},
	{
		code: '26101700',
		label: 'Accesorios y componentes de motor',
	},
	{
		code: '26101701',
		label: 'Quemadores para aeronaves',
	},
	{
		code: '26101702',
		label: 'Compresores de motor de avión',

		palabrasSimilares: 'Compresores de motores de aeronaves',
	},
	{
		code: '26101703',
		label: 'Difusores de motor de avión',
	},
	{
		code: '26101704',
		label: 'Monturas de motor',

		palabrasSimilares: 'Soportes del motor',
	},
	{
		code: '26101705',
		label: 'Gatos de tornillo de los ejes motores del avión',
	},
	{
		code: '26101706',
		label: 'Unidades de transmisión del avión',
	},
	{
		code: '26101707',
		label: 'Ejes compensadores',
	},
	{
		code: '26101708',
		label: 'Seguidores de la leva',
	},
	{
		code: '26101709',
		label: 'Elevadores de árbol de levas',
	},
	{
		code: '26101710',
		label: 'Carburadores',
	},
	{
		code: '26101711',
		label: 'Bielas',
	},
	{
		code: '26101712',
		label: 'Válvulas de ventilación del cárter',
	},
	{
		code: '26101713',
		label: 'Culata de cilindro',

		palabrasSimilares: 'Tapa del cilindro',
	},
	{
		code: '26101715',
		label: 'Tapas de motor',
	},
	{
		code: '26101716',
		label: 'Forjas del motor',
	},
	{
		code: '26101717',
		label: 'Calentadores de motor',
	},
	{
		code: '26101718',
		label: 'Sistemas de ignición de motor',
	},
	{
		code: '26101719',
		label: 'Súper cargadores',
	},
	{
		code: '26101720',
		label: 'Turbocargador',
	},
	{
		code: '26101721',
		label: 'Poleas del motor',
	},
	{
		code: '26101723',
		label: 'Lata de vapor de combustible',
	},
	{
		code: '26101724',
		label: 'Tapón brillante',
	},
	{
		code: '26101725',
		label: 'Medidores de varilla de aceite',

		palabrasSimilares:
			'Bayoneta para medir aceite, Varilla del nivel de aceite',
	},
	{
		code: '26101726',
		label: 'Coladores de aceite',

		palabrasSimilares: 'Filtros de aceite',
	},
	{
		code: '26101727',
		label: 'Anillo de pistón',
	},
	{
		code: '26101728',
		label: 'Tubos de varilla de empuje',
	},
	{
		code: '26101729',
		label: 'Bolas de brazo oscilante',

		palabrasSimilares: 'Brazo oscilante articulación de bolas buje',
	},
	{
		code: '26101730',
		label: 'Ejes de brazo oscilante',

		palabrasSimilares: 'Soporte suspensión',
	},
	{
		code: '26101731',
		label: 'Brazos oscilantes',
	},
	{
		code: '26101732',
		label: 'Bujía de encendido',
	},
	{
		code: '26101733',
		label: 'Chorro de carburador',
	},
	{
		code: '26101734',
		label: 'Diafragmas de carburador',
	},
	{
		code: '26101735',
		label: 'Batea de aceite',

		palabrasSimilares: 'Bandeja recolectora de aceite, Colector de aceite',
	},
	{
		code: '26101736',
		label: 'Pistones',
	},
	{
		code: '26101737',
		label: 'Cadena de distribución',
	},
	{
		code: '26101738',
		label: 'Múltiples de entrada',
	},
	{
		code: '26101740',
		label: 'Inyectores de combustible',
	},
	{
		code: '26101741',
		label: 'Manguitos de motor',

		palabrasSimilares: 'Mangueras de calefacción para motor',
	},
	{
		code: '26101742',
		label: 'Distribuidores de inyección de combustible',
	},
	{
		code: '26101743',
		label: 'Válvulas de motor',
	},
	{
		code: '26101747',
		label: 'Varas de empuje',
	},
	{
		code: '26101748',
		label: 'Volante de inercia del motor',
	},
	{
		code: '26101749',
		label: 'Cigüeñal',
	},
	{
		code: '26101750',
		label: 'Válvula de estrangulación',
	},
	{
		code: '26101751',
		label: 'Controles electrónicos de motor',
	},
	{
		code: '26101754',
		label: 'Asiento de válvula del motor',
	},
	{
		code: '26101755',
		label: 'Guía de válvula',
	},
	{
		code: '26101756',
		label: 'Adaptadores del carburador',
	},
	{
		code: '26101757',
		label: 'Accesorios de las bujías',
	},
	{
		code: '26101758',
		label: 'Ajustadores del balancín',
	},
	{
		code: '26101759',
		label: 'Adaptadores del motor de arranque',
	},
	{
		code: '26101760',
		label: 'Vástagos del estárter',
	},
	{
		code: '26101761',
		label: 'Tapones del árbol de levas',
	},
	{
		code: '26101762',
		label: 'Varillaje de los componentes del motor',
	},
	{
		code: '26101763',
		label: 'Tapones del anticongelante',
	},
	{
		code: '26101764',
		label: 'Revestimiento de los cilindros',
	},
	{
		code: '26101765',
		label: 'Amortiguadores de vibración',
	},
	{
		code: '26101766',
		label: 'Reguladores',
	},
	{
		code: '26101767',
		label: 'Estator de turbina',
	},
	{
		code: '26101768',
		label: 'Eje de turbina',
	},
	{
		code: '26101769',
		label: 'Bobina de ignición',
	},
	{
		code: '26101770',
		label: 'Control electrónico de motor para motores a gasolina',

		palabrasSimilares: 'Control electrónico de motor a gasolina',
	},
	{
		code: '26101771',
		label: 'Control electrónico de motor para motores diesel',

		palabrasSimilares: 'Control electrónico de motor diésel',
	},
	{
		code: '26101772',
		label: 'Limpiador de inyector',
	},
	{
		code: '26101773',
		label: 'Válvula de medición proporcional diesel',

		palabrasSimilares: 'Válvula dosificadora proporcional diésel',
	},
	{
		code: '26101774',
		label: 'Válvula solenoide ara purgar lata',

		palabrasSimilares: 'Válvula solenoide de purga de cárter',
	},
	{
		code: '26101775',
		label: 'Válvula de alta presión diesel',
	},
	{
		code: '26101776',
		label: 'Bobina del inmovilizador',
	},
	{
		code: '26101777',
		label: 'Bobina del inyector de combustible gasolina',
	},
	{
		code: '26101778',
		label: 'Bobina del inyector de combustible diesel',
	},
	{
		code: '26101779',
		label: 'Arnés pasacables para cableado',

		palabrasSimilares: 'Arnés de cableado',
	},
	{
		code: '26101780',
		label: 'Inyector de aceite o válvula de medición',
	},
	{
		code: '26101781',
		label: 'Pasador de pistón',
	},
	{
		code: '26101782',
		label: 'Distribuidor de ignición',
	},
	{
		code: '26101783',
		label: 'Unión de husillos hidráulicos',
	},
	{
		code: '26101784',
		label: 'Araña del diferencial',
	},
	{
		code: '26101785',
		label: 'Elemento colador de aceite',

		palabrasSimilares: 'Colador de aceite',
	},
	{
		code: '26101786',
		label: 'Alza válvulas',

		palabrasSimilares: 'Varilla levanta válvulas',
	},
	{
		code: '26101787',
		label: 'Válvula y bastidor de admisión',
	},
	{
		code: '26101900',
		label: 'Componentes de motor de combustión interna',
	},
	{
		code: '26101903',
		label: 'Árbol de distribución',
	},
	{
		code: '26101904',
		label: 'Boquilla de inyección de combustible',
	},
	{
		code: '26101905',
		label: 'Bloque de cilindros',
	},
	{
		code: '26111500',
		label: 'Transmisión de energía cinética',
	},
	{
		code: '26111503',
		label: 'Dispositivos de velocidad regulables',
	},
	{
		code: '26111504',
		label: 'Correas de la transmisión',

		palabrasSimilares: 'Bandas de transmisión',
	},
	{
		code: '26111505',
		label: 'Cadenas de la transmisión',
	},
	{
		code: '26111506',
		label: 'Aparatos de movimiento rectilíneo',
	},
	{
		code: '26111508',
		label: 'Despegues de energía',
	},
	{
		code: '26111509',
		label: 'Culatas de transmisión',
	},
	{
		code: '26111510',
		label: 'Árboles de transmisión',
	},
	{
		code: '26111512',
		label: 'Ejes',
	},
	{
		code: '26111513',
		label: 'Cadenas transmisoras de potencia',
	},
	{
		code: '26111514',
		label: 'Uniones de charnela',

		palabrasSimilares: 'Uniones para codos',
	},
	{
		code: '26111515',
		label: 'Servo controlador',
	},
	{
		code: '26111516',
		label:
			'Transmisión escalonada o transmisión stepper o graduador escalonado',
	},
	{
		code: '26111517',
		label: 'Portaengranajes',
	},
	{
		code: '26111518',
		label: 'Puntal tensor',
	},
	{
		code: '26111519',
		label: 'Convertidores de torque',
	},
	{
		code: '26111520',
		label: 'Muñones',
	},
	{
		code: '26111521',
		label: 'Cabeza del impulsor',
	},
	{
		code: '26111522',
		label: 'Conjunto del impulsor',
	},
	{
		code: '26111523',
		label: 'Topes de retención',
	},
	{
		code: '26111524',
		label: 'Unidades de engranajes',
	},
	{
		code: '26111525',
		label: 'Transmisiones de tambor',
	},
	{
		code: '26111527',
		label: 'Sistemas de control de movimiento integrados',
	},
	{
		code: '26111528',
		label: 'Transmisiones hidrostáticas',
	},
	{
		code: '26111529',
		label: 'Transmisiones hidrocinéticas',
	},
	{
		code: '26111530',
		label: 'Leva de transmisión',
	},
	{
		code: '26111531',
		label: 'Manguitos de la transmisión',
	},
	{
		code: '26111532',
		label: 'Soportes o conjuntos del eje',
	},
	{
		code: '26111533',
		label: 'Tensores de cadena',
	},
	{
		code: '26111534',
		label: 'Cubos de la transmisión',
	},
	{
		code: '26111535',
		label: 'Tornillos esféricos o conjuntos de tornillos esféricos',
	},
	{
		code: '26111536',
		label: 'Reductor de velocidad montado sobre eje',
	},
	{
		code: '26111537',
		label: 'Reductor de velocidad con tornillo',
	},
	{
		code: '26111538',
		label: 'Reductor de velocidad de hélice',
	},
	{
		code: '26111539',
		label: 'Reductor de velocidad de hélice y tornillo',
	},
	{
		code: '26111540',
		label: 'Reductor de velocidad planetario',
	},
	{
		code: '26111541',
		label: 'Reductor de velocidad cicloidal',
	},
	{
		code: '26111542',
		label: 'Reductor aumentador de velocidad de bisel',
	},
	{
		code: '26111543',
		label: 'Reductor de velocidad de tracción',
	},
	{
		code: '26111544',
		label: 'Controlador de velocidad',
	},
	{
		code: '26111545',
		label: 'Adaptador de freno de tambor',
	},
	{
		code: '26111546',
		label: 'Disco de turbina',
	},
	{
		code: '26111547',
		label: 'Cubo de apriete',
	},
	{
		code: '26111548',
		label: 'Palanca de cambios',
	},
	{
		code: '26111549',
		label: 'Escalón del eje',
	},
	{
		code: '26111600',
		label: 'Generadores de potencia',
	},
	{
		code: '26111601',
		label: 'Generadores diesel',
	},
	{
		code: '26111602',
		label: 'Generadores hidroeléctricos',
	},
	{
		code: '26111603',
		label: 'Generadores eólicos',
	},
	{
		code: '26111604',
		label: 'Generadores a gas',
	},
	{
		code: '26111605',
		label: 'Generadores térmicos',
	},
	{
		code: '26111606',
		label: 'Generadores hidráulicos',
	},
	{
		code: '26111607',
		label: 'Generadores solares',
	},
	{
		code: '26111608',
		label: 'Generadores de vapor',
	},
	{
		code: '26111609',
		label: 'Generador de turbina de gas',
	},
	{
		code: '26111610',
		label: 'Generador selsyn',
	},
	{
		code: '26111611',
		label: 'Generador auxiliar',
	},
	{
		code: '26111612',
		label: 'Generador de impulso',
	},
	{
		code: '26111613',
		label: 'Generador de marea',
	},
	{
		code: '26111700',
		label: 'Baterías, pilas y accesorios',
	},
	{
		code: '26111701',
		label: 'Baterías recargables',

		palabrasSimilares: 'Pilas recacrgables',
	},
	{
		code: '26111702',
		label: 'Pilas alcalinas',

		palabrasSimilares: 'Baterías alcalinas',
	},
	{
		code: '26111703',
		label: 'Baterías para vehículos',
	},
	{
		code: '26111704',
		label: 'Cargadores de baterías',

		palabrasSimilares: 'Cargadores de pilas',
	},
	{
		code: '26111705',
		label: 'Pilas secas',
	},
	{
		code: '26111706',
		label: 'Pilas electrónicas',
	},
	{
		code: '26111707',
		label: 'Baterías de plomo-ácido',
	},
	{
		code: '26111708',
		label: 'Baterías de ferroníquel',
	},
	{
		code: '26111709',
		label: 'Baterías de níquel-cadmio',
	},
	{
		code: '26111710',
		label: 'Bloques de pilas específicas para productos',
	},
	{
		code: '26111711',
		label: 'Baterías de litio',
	},
	{
		code: '26111712',
		label: 'Baterías de níquel- hidrógeno',
	},
	{
		code: '26111713',
		label: 'Baterías térmicas',
	},
	{
		code: '26111714',
		label: 'Zinc aire',

		palabrasSimilares:
			'Batería mecánicamente recargables, Batería no recargable',
	},
	{
		code: '26111715',
		label: 'Batería de carbono zinc',
	},
	{
		code: '26111716',
		label: 'Batería de oxido de mercurio',
	},
	{
		code: '26111717',
		label: 'Baterías del manganeso',
	},
	{
		code: '26111718',
		label: 'Baterías de óxido de plata',

		palabrasSimilares: 'Pila de plata',
	},
	{
		code: '26111719',
		label: 'Probadores de baterías',

		palabrasSimilares: 'Probadores de pilas',
	},
	{
		code: '26111720',
		label: 'Soportes de batería',

		palabrasSimilares: 'Soportes de pilas',
	},
	{
		code: '26111721',
		label: 'Baterías de níquel-hidruro metálico',
	},
	{
		code: '26111722',
		label: 'Adaptador de batería o accesorios',

		palabrasSimilares: 'Adaptador de pilas o accesorios',
	},
	{
		code: '26111723',
		label: 'Puertas, tapas o estantes de baterías',

		palabrasSimilares: 'Tapas o cajas para pilas',
	},
	{
		code: '26111724',
		label: 'Kits de herramientas para baterías',
	},
	{
		code: '26111725',
		label: 'Baterías de níquel-cloruro de sodio',
	},
	{
		code: '26111726',
		label: 'Unidad de balasto de batería fluorescente',
	},
	{
		code: '26111727',
		label: 'Descargador de baterías',

		palabrasSimilares: 'Descargador de pilas',
	},
	{
		code: '26111728',
		label: 'Celda estándar',
	},
	{
		code: '26111729',
		label: 'Unidad de cargador de batería de auto',

		palabrasSimilares: 'Unidad de carga automática de la batería',
	},
	{
		code: '26111800',
		label: 'Componentes de la transmisión',
	},
	{
		code: '26111801',
		label: 'Correas en v',

		palabrasSimilares: 'Accionamientos de correa en v, Bandas en v',
	},
	{
		code: '26111802',
		label: 'Correas de distribución de engranaje',
	},
	{
		code: '26111803',
		label: 'Correas redondas',
	},
	{
		code: '26111804',
		label: 'Correas planas',
	},
	{
		code: '26111805',
		label: 'Tensores de correa',
	},
	{
		code: '26111806',
		label: 'Poleas de transmisión',
	},
	{
		code: '26111807',
		label: 'Polea de distribución',
	},
	{
		code: '26111808',
		label: 'Trantorque',
	},
	{
		code: '26111809',
		label: 'Defensas de correa',
	},
	{
		code: '26111810',
		label: 'Bridas de la polea de distribución',
	},
	{
		code: '26111811',
		label: 'Banda sincrónica',
	},
	{
		code: '26111812',
		label: 'Polea sincrónica',
	},
	{
		code: '26111813',
		label: 'Polea de velocidad variable',
	},
	{
		code: '26111814',
		label: 'Banda hexagonal',
	},
	{
		code: '26111815',
		label: 'Polea de cono',
	},
	{
		code: '26111816',
		label: 'Polea plana',
	},
	{
		code: '26111900',
		label: 'Embragues',
	},
	{
		code: '26111901',
		label: 'Embragues de placa',

		palabrasSimilares: 'Clutch',
	},
	{
		code: '26111902',
		label: 'Embragues de diafragma',
	},
	{
		code: '26111903',
		label: 'Embrague centrífugo',
	},
	{
		code: '26111904',
		label: 'Embrague semi centrífugo',
	},
	{
		code: '26111905',
		label: 'Embrague de rueda libre',
	},
	{
		code: '26111907',
		label: 'Acoplamiento de fluido',
	},
	{
		code: '26111908',
		label: 'Embragues de leva',
	},
	{
		code: '26111909',
		label: 'Embragues eléctricos',
	},
	{
		code: '26111910',
		label: 'Embragues hidráulicos',
	},
	{
		code: '26111911',
		label: 'Embrague neumático',
	},
	{
		code: '26111912',
		label: 'Embrague mecánico',
	},
	{
		code: '26111913',
		label: 'Unidad de detención de embrague',
	},
	{
		code: '26111914',
		label: 'Embrague de mandíbula',
	},
	{
		code: '26111915',
		label: 'Embrague automático',
	},
	{
		code: '26111916',
		label: 'Embrague semi automático',
	},
	{
		code: '26111917',
		label: 'Embrague electromagnético',
	},
	{
		code: '26112000',
		label: 'Piezas y accesorios de embragues',
	},
	{
		code: '26112001',
		label: 'Placa de presión',
	},
	{
		code: '26112002',
		label: 'Placa impulsada',
	},
	{
		code: '26112003',
		label: 'Placas de embrague',
	},
	{
		code: '26112004',
		label: 'Kits de reparación del embrague',

		palabrasSimilares: 'Paquete de reparación de embrague',
	},
	{
		code: '26112100',
		label: 'Sistemas de frenado industriales',
	},
	{
		code: '26112101',
		label: 'Sistemas de frenos neumáticos o de aire',
	},
	{
		code: '26112102',
		label: 'Sistemas de frenos hidráulicos',
	},
	{
		code: '26112103',
		label: 'Sistemas de frenos mecánicos',
	},
	{
		code: '26112104',
		label: 'Conjuntos de embrague de frenado',
	},
	{
		code: '26112105',
		label: 'Sistemas de frenado eléctrico',
	},
	{
		code: '26121500',
		label: 'Alambre eléctrico',
	},
	{
		code: '26121501',
		label: 'Alambre calentador',
	},
	{
		code: '26121505',
		label: 'Alambre para artefactos',
	},
	{
		code: '26121507',
		label: 'Alambre para radio o televisión',
	},
	{
		code: '26121508',
		label: 'Alambre para automoción o aviación',
	},
	{
		code: '26121509',
		label: 'Alambre para imanes',
	},
	{
		code: '26121510',
		label: 'Alambre de trole',
	},
	{
		code: '26121514',
		label: 'Alambre subterráneo',
	},
	{
		code: '26121515',
		label: 'Alambre de silicio-amianto (sa)',
	},
	{
		code: '26121517',
		label: 'Hilo de cobre',
	},
	{
		code: '26121519',
		label: 'Alambre de aluminio revestido de cobre',
	},
	{
		code: '26121520',
		label: 'Alambre de cobre-acero',
	},
	{
		code: '26121521',
		label: 'Alambre de bronce',
	},
	{
		code: '26121522',
		label: 'Alambre pelado',
	},
	{
		code: '26121523',
		label: 'Alambre forrado pero no aislado',
	},
	{
		code: '26121524',
		label: 'Alambre aislado o forrado',
	},
	{
		code: '26121532',
		label: 'Alambre para interconexiones',
	},
	{
		code: '26121533',
		label: 'Alambre de kaptan',
	},
	{
		code: '26121534',
		label: 'Alambre de poliamida',
	},
	{
		code: '26121536',
		label: 'Cordón de extensión',
	},
	{
		code: '26121538',
		label: 'Conjunto de cable',
	},
	{
		code: '26121539',
		label: 'Cables para cableado',
	},
	{
		code: '26121540',
		label: 'Cable galvanizado',

		palabrasSimilares: 'Alambre galvanizado',
	},
	{
		code: '26121541',
		label: 'Conductores de bus',
	},
	{
		code: '26121542',
		label: 'Cable de instalación',
	},
	{
		code: '26121543',
		label: 'Cable resistente al calor',
	},
	{
		code: '26121544',
		label: 'Cable de bajada',
	},
	{
		code: '26121545',
		label: 'Cable portátil eléctrico',
	},
	{
		code: '26121546',
		label: 'Cable de cobre recubierto de estaño',
	},
	{
		code: '26121547',
		label: 'Cable de bronce',
	},
	{
		code: '26121548',
		label: 'Cable rectangular',
	},
	{
		code: '26121600',
		label: 'Cables eléctricos y accesorios',
	},
	{
		code: '26121601',
		label: 'Cable de calentamiento',
	},
	{
		code: '26121602',
		label: 'Cable submarino',
	},
	{
		code: '26121603',
		label: 'Cable de mando',
	},
	{
		code: '26121604',
		label: 'Cable para señales',

		palabrasSimilares: 'Cable HDMI',
	},
	{
		code: '26121606',
		label: 'Cable coaxial',
	},
	{
		code: '26121607',
		label: 'Cable de fibra óptica',
	},
	{
		code: '26121608',
		label: 'Cable aéreo',
	},
	{
		code: '26121609',
		label: 'Cable de redes',
	},
	{
		code: '26121610',
		label: 'Cable de bronce',
	},
	{
		code: '26121611',
		label: 'Cable desnudo',
	},
	{
		code: '26121612',
		label: 'Cable forrado pero no aislado',
	},
	{
		code: '26121613',
		label: 'Cable aislado o forrado',
	},
	{
		code: '26121614',
		label: 'Cable de construcción',
	},
	{
		code: '26121615',
		label: 'Cable para ser enterrado de forma directa',
	},
	{
		code: '26121616',
		label: 'Cable de telecomunicaciones',
	},
	{
		code: '26121617',
		label: 'Cable triaxial',
	},
	{
		code: '26121618',
		label: 'Cable de poliqueno reticulado',
	},
	{
		code: '26121619',
		label: 'Cable de floropolímero',
	},
	{
		code: '26121620',
		label: 'Cable para interconexiones',
	},
	{
		code: '26121621',
		label: 'Cable de kaptan',
	},
	{
		code: '26121622',
		label: 'Cable de poliamida',
	},
	{
		code: '26121623',
		label: 'Cable de radiofrecuencia (rf)',
	},
	{
		code: '26121624',
		label: 'Cable plano o de cinta',
	},
	{
		code: '26121628',
		label: 'Cables blindados',
	},
	{
		code: '26121629',
		label: 'Cable de alimentación',
	},
	{
		code: '26121630',
		label: 'Accesorios de cable',
	},
	{
		code: '26121631',
		label: 'Cable coaxial exterior de planta',
	},
	{
		code: '26121632',
		label: 'Cable de comunicaciones exterior de planta',

		palabrasSimilares: 'Cable de planta exterior',
	},
	{
		code: '26121633',
		label: 'Cable de telecomunicaciones exterior de planta',

		palabrasSimilares: 'Cable de planta exterior',
	},
	{
		code: '26121634',
		label: 'Cable de cobre',
	},
	{
		code: '26121635',
		label: 'Rollos de cable',
	},
	{
		code: '26121636',
		label: 'Cables de alimentación',
	},
	{
		code: '26121637',
		label: 'Cable de fibra óptica de exterior',
	},
	{
		code: '26121638',
		label: 'Materiales de engaste',
	},
	{
		code: '26121639',
		label: 'Multi – cables combinados o a la medida',
	},
	{
		code: '26121640',
		label: 'Cables resistentes al calor',
	},
	{
		code: '26121641',
		label: 'Cables de instalación',
	},
	{
		code: '26121642',
		label: 'Cable de instrumentación',
	},
	{
		code: '26121643',
		label: 'Cable eléctrico reforzado de acero desnudo',
	},
	{
		code: '26121644',
		label: 'Cable eléctrico de aluminio desnudo',
	},
	{
		code: '26121645',
		label: 'Cable eléctrico de cobre desnudo',
	},
	{
		code: '26121646',
		label: 'Cable de seguimiento y máquina de minería',
	},
	{
		code: '26121647',
		label: 'Ensamble de cable de seguimiento y máquina de minería',
	},
	{
		code: '26121648',
		label: 'Kit de terminación de cable de alto voltaje',

		palabrasSimilares: 'Paquete de terminación de cable de alto voltaje',
	},
	{
		code: '26121649',
		label: 'Kit de unión de cable de alto voltaje',

		palabrasSimilares: 'Paquete de unión de cables de alta tensión',
	},
	{
		code: '26121650',
		label: 'Conjunto de cable de fibra óptica',
	},
	{
		code: '26121651',
		label: 'Cable so resistente al aceite',
	},
	{
		code: '26121652',
		label: 'Conjunto de cordón eléctrico',
	},
	{
		code: '26121653',
		label: 'Cable toll',
	},
	{
		code: '26121654',
		label: 'Cable de frecuencia de carrier',
	},
	{
		code: '26121655',
		label: 'Cable de fibra óptica simplex y dúplex',
	},
	{
		code: '26121656',
		label: 'Cable de fibra óptica submarina',
	},
	{
		code: '26121657',
		label: 'Cable de fibra óptica retardante de fuego',
	},
	{
		code: '26121658',
		label:
			'Cable de transmisión de aluminio conductor resistente al movimiento',
	},
	{
		code: '26121659',
		label: 'Cable de bajada de servicio cuádruple de aluminio conductor',
	},
	{
		code: '26121660',
		label: 'Cable de bajada de servicio dúplex de aluminio conductor',
	},
	{
		code: '26121661',
		label: 'Cable de aluminio conductor subterráneo de entrada de servicio',
	},
	{
		code: '26121662',
		label: 'Cable de aluminio conductor de entrada de servicio',
	},
	{
		code: '26121663',
		label: 'Cable sencillo de aluminio conductor de servicio',
	},
	{
		code: '26121664',
		label: 'Alambre de aluminio conductor recubierto de polietileno',
	},
	{
		code: '26121665',
		label: 'Cable de aluminio de bajada de servicio triple',
	},
	{
		code: '26121666',
		label: 'Conjunto de cable de comunicación',
	},
	{
		code: '26121700',
		label: 'Cableado preformado',
	},
	{
		code: '26121701',
		label: 'Cableado preformado de panel',
	},
	{
		code: '26121702',
		label: 'Cableado preformado troncal',
	},
	{
		code: '26121703',
		label: 'Cableado preformado de comunicación',
	},
	{
		code: '26121704',
		label: 'Arnés de alambrado especial',
	},
	{
		code: '26121706',
		label: 'Conjunto coaxial',
	},
	{
		code: '26121707',
		label: 'Conjunto de cable plano flexible',
	},
	{
		code: '26121708',
		label: 'Conjunto de cable de batería',
	},
	{
		code: '26121709',
		label: 'Conjunto de cordón embobinado',
	},
	{
		code: '26121710',
		label: 'Arnés de cableado sensor oxígeno',

		palabrasSimilares: 'Arnés cableado de sensor de oxigeno',
	},
	{
		code: '26121711',
		label: 'Varilla de pistón',
	},
	{
		code: '26121800',
		label: 'Cable automotriz',
	},
	{
		code: '26121802',
		label: 'Cable automotriz de núcleo sencillo de 60 voltios clase a',
	},
	{
		code: '26121803',
		label: 'Cable automotriz de núcleo sencillo de 60 voltios clase b',
	},
	{
		code: '26121804',
		label: 'Cable automotriz de núcleo sencillo de 60 voltios clase c',
	},
	{
		code: '26121805',
		label: 'Cable automotriz de núcleo sencillo de 60 voltios clase d',
	},
	{
		code: '26121806',
		label: 'Cable automotriz de núcleo sencillo de 60 voltios clase e',
	},
	{
		code: '26121807',
		label: 'Cable automotriz de núcleo sencillo de 60 voltios clase f',
	},
	{
		code: '26121808',
		label: 'Cable automotriz de núcleo sencillo de 60 voltios clase g',
	},
	{
		code: '26121809',
		label: 'Cable automotriz de núcleo sencillo de 60 voltios clase h',
	},
	{
		code: '26121810',
		label: 'Cable automotriz de núcleo sencillo de 600 voltios clase a',
	},
	{
		code: '26121811',
		label: 'Cable automotriz de núcleo sencillo de 600 voltios clase b',
	},
	{
		code: '26121812',
		label: 'Cable automotriz de núcleo sencillo de 600 voltios clase c',
	},
	{
		code: '26121813',
		label: 'Cable automotriz de núcleo sencillo de 600 voltios clase d',
	},
	{
		code: '26121814',
		label: 'Cable automotriz de núcleo sencillo de 600 voltios clase e',
	},
	{
		code: '26121815',
		label: 'Cable automotriz de núcleo sencillo de 600 voltios clase f',
	},
	{
		code: '26121816',
		label: 'Cable automotriz de núcleo sencillo de 600 voltios clase g',
	},
	{
		code: '26121817',
		label: 'Cable automotriz de núcleo sencillo de 600 voltios clase h',
	},
	{
		code: '26121818',
		label: 'Cable automotriz de núcleo múltiple de 60 voltios clase a',
	},
	{
		code: '26121819',
		label: 'Cable automotriz de núcleo múltiple de 60 voltios clase b',
	},
	{
		code: '26121820',
		label: 'Cable automotriz de núcleo múltiple de 60 voltios clase c',
	},
	{
		code: '26121821',
		label: 'Cable automotriz de núcleo múltiple de 60 voltios clase d',
	},
	{
		code: '26121822',
		label: 'Cable automotriz de núcleo múltiple de 60 voltios clase e',
	},
	{
		code: '26121823',
		label: 'Cable automotriz de núcleo múltiple de 60 voltios clase f',
	},
	{
		code: '26121824',
		label: 'Cable automotriz de núcleo múltiple de 60 voltios clase g',
	},
	{
		code: '26121825',
		label: 'Cable automotriz de núcleo múltiple de 60 voltios clase h',
	},
	{
		code: '26121826',
		label: 'Cable automotriz de núcleo múltiple de 600 voltios clase a',
	},
	{
		code: '26121827',
		label: 'Cable automotriz de núcleo múltiple de 600 voltios clase b',
	},
	{
		code: '26121828',
		label: 'Cable automotriz de núcleo múltiple de 600 voltios clase c',
	},
	{
		code: '26121829',
		label: 'Cable automotriz de núcleo múltiple de 600 voltios clase d',
	},
	{
		code: '26121830',
		label: 'Cable automotriz de núcleo múltiple de 600 voltios clase e',
	},
	{
		code: '26121831',
		label: 'Cable automotriz de núcleo múltiple de 600 voltios clase f',
	},
	{
		code: '26121832',
		label: 'Cable automotriz de núcleo múltiple de 600 voltios clase g',
	},
	{
		code: '26121833',
		label: 'Cable automotriz de núcleo múltiple de 600 voltios clase h',
	},
	{
		code: '26121834',
		label: 'Cable automotriz trenzado de 60 voltios clase a',
	},
	{
		code: '26121835',
		label: 'Cable automotriz trenzado de 60 voltios clase b',
	},
	{
		code: '26121836',
		label: 'Cable automotriz trenzado de 60 voltios clase c',
	},
	{
		code: '26121837',
		label: 'Cable automotriz trenzado de 60 voltios clase d',
	},
	{
		code: '26121838',
		label: 'Cable automotriz trenzado de 60 voltios clase e',
	},
	{
		code: '26121839',
		label: 'Cable automotriz trenzado de 60 voltios clase f',
	},
	{
		code: '26121840',
		label: 'Cable automotriz trenzado de 60 voltios clase g',
	},
	{
		code: '26121841',
		label: 'Cable automotriz trenzado de 60 voltios clase h',
	},
	{
		code: '26121842',
		label: 'Cable automotriz trenzado de 600 voltios clase a',
	},
	{
		code: '26121843',
		label: 'Cable automotriz trenzado de 600 voltios clase b',
	},
	{
		code: '26121844',
		label: 'Cable automotriz trenzado de 600 voltios clase c',
	},
	{
		code: '26121845',
		label: 'Cable automotriz trenzado de 600 voltios clase d',
	},
	{
		code: '26121846',
		label: 'Cable automotriz trenzado de 600 voltios clase e',
	},
	{
		code: '26121847',
		label: 'Cable automotriz trenzado de 600 voltios clase f',
	},
	{
		code: '26121848',
		label: 'Cable automotriz trenzado de 600 voltios clase g',
	},
	{
		code: '26121849',
		label: 'Cable automotriz trenzado de 600 voltios clase h',
	},
	{
		code: '26121850',
		label: 'Cable automotriz de núcleo múltiple seleccionado de 60 voltios',
	},
	{
		code: '26121851',
		label: 'Cable automotriz de núcleo múltiple seleccionado de 600 voltios',
	},
	{
		code: '26121852',
		label: 'Cable automotriz de núcleo de ignición',

		palabrasSimilares: 'Cable de encendido de automóvil',
	},
	{
		code: '26131500',
		label: 'Centrales eléctricas',
	},
	{
		code: '26131501',
		label: 'Centrales eléctricas de diesel',
	},
	{
		code: '26131502',
		label: 'Centrales eléctricas geotérmicas',
	},
	{
		code: '26131503',
		label: 'Centrales hidroeléctricas',
	},
	{
		code: '26131504',
		label: 'Centrales de gas',
	},
	{
		code: '26131505',
		label: 'Centrales de energía  marina',
	},
	{
		code: '26131506',
		label: 'Centrales de energía a petróleo',
	},
	{
		code: '26131507',
		label: 'Centrales de energía solar',
	},
	{
		code: '26131508',
		label: 'Centrales termoeléctricas',
	},
	{
		code: '26131509',
		label: 'Central de energía eólica',
	},
	{
		code: '26131510',
		label: 'Central térmica',
	},
	{
		code: '26131600',
		label: 'Equipo de cribado o estructuras de tubo de escape',
	},
	{
		code: '26131601',
		label: 'Pantallas de agua móviles',
	},
	{
		code: '26131602',
		label: 'Travesaños corredizos',
	},
	{
		code: '26131603',
		label: 'Rejillas de agua',
	},
	{
		code: '26131604',
		label: 'Filtros fijos',
	},
	{
		code: '26131605',
		label: 'Estructuras de toma',
	},
	{
		code: '26131606',
		label: 'Chimenea de acero',
	},
	{
		code: '26131607',
		label: 'Chimenea de concreto',
	},
	{
		code: '26131608',
		label: 'Chimeneas de ventilación o antorchas',
	},
	{
		code: '26131609',
		label: 'Chimeneas de admisión',

		palabrasSimilares: 'Pilas de admisión de escape',
	},
	{
		code: '26131610',
		label: 'Chimeneas de derivación',
	},
	{
		code: '26131611',
		label: 'Secciones de silenciador',
	},
	{
		code: '26131612',
		label: 'Conductos de salida de escape',
	},
	{
		code: '26131613',
		label: 'Juntas de dilatación de conductos de escape',

		palabrasSimilares: 'Juntas de dilatación de escape',
	},
	{
		code: '26131614',
		label: 'Amortiguadores de cierre de la chimenea',
	},
	{
		code: '26131615',
		label: 'Amortiguadores de desviación de escape',
	},
	{
		code: '26131616',
		label: 'Amortiguadores de aislamiento de escape',
	},
	{
		code: '26131617',
		label: 'Amortiguador de veleta',
	},
	{
		code: '26131618',
		label: 'Amortiguador de cabezal',
	},
	{
		code: '26131700',
		label: 'Equipo de detección o vigilancia de producción de energía',
	},
	{
		code: '26131701',
		label: 'Detectores de gases inflamables o peligrosos para generadores',
	},
	{
		code: '26131702',
		label: 'Detectores de llama de sistemas de combustión de turbina de gas',
	},
	{
		code: '26131800',
		label: 'Equipo de control de producción de energía',
	},
	{
		code: '26131801',
		label: 'Paneles de control eléctrico para generadores',
	},
	{
		code: '26131802',
		label: 'Paneles de control de compresores',
	},
	{
		code: '26131803',
		label: 'Paneles de protección o control de generadores',
	},
	{
		code: '26131804',
		label: 'Paneles de control de turbina de gas',
	},
	{
		code: '26131807',
		label: 'Paneles de control de turbinas de vapor',
	},
	{
		code: '26131808',
		label: 'Conmutadores de control de carga de subestación',
	},
	{
		code: '26131811',
		label: 'Reactores de limitación de intensidad',
	},
	{
		code: '26131812',
		label: 'Conmutadores con aislamiento de gas',
	},
	{
		code: '26131813',
		label: 'Conmutadores de desconexión de estaciones de maniobra',
	},
	{
		code: '26131814',
		label: 'Disipador de sobretensiones de estaciones de maniobra',

		palabrasSimilares: 'Pararrayos de interruptor',
	},
	{
		code: '26141600',
		label: 'Equipo para ensamblaje subcrítico',
	},
	{
		code: '26141601',
		label: 'Combustible para conjunto subcrítico',
	},
	{
		code: '26141602',
		label: 'Componentes para conjunto subcrítico',
	},
	{
		code: '26141603',
		label: 'Moderador para conjunto subcrítico',

		palabrasSimilares: 'Moderador de montaje subcrítico',
	},
	{
		code: '26141700',
		label: 'Equipo para dosimetría',
	},
	{
		code: '26141701',
		label: 'Dosímetros de cámara de ionización',
	},
	{
		code: '26141702',
		label: 'Dosímetros',
	},
	{
		code: '26141703',
		label: 'Sistemas de dosimetría de patrón secundario',
	},
	{
		code: '26141704',
		label: 'Dosímetros fantasma',
	},
	{
		code: '26141800',
		label: 'Aparatos para celda caliente',
	},
	{
		code: '26141801',
		label: 'Equipo teledirigido para recintos radiactivos',
	},
	{
		code: '26141802',
		label: 'Aparato de visión teledirigido para recintos radiactivos',
	},
	{
		code: '26141803',
		label: 'Puertas de blindaje para recintos radiactivos',
	},
	{
		code: '26141804',
		label: 'Toma muestras para recintos radiactivos',
	},
	{
		code: '26141805',
		label: 'Material de elaboración de muestras para recintos radiactivos',
	},
	{
		code: '26141806',
		label: 'Herramientas especiales para recintos radiactivos',
	},
	{
		code: '26141807',
		label: 'Ventanas de vidrio de plomo para recintos radiactivos',
	},
	{
		code: '26141808',
		label: 'Sistemas de descontaminación para recintos radiactivos',
	},
	{
		code: '26141809',
		label: 'Aparatos de penetración para recintos radiactivos',
	},
	{
		code: '26141900',
		label: 'Instrumentos nucleónicos industriales',
	},
	{
		code: '26141901',
		label: 'Sistemas nucleónicos industriales de medida de polvo en el aire',

		palabrasSimilares: 'Sistemas de medición de polvo nuclear industrial',
	},
	{
		code: '26141902',
		label: 'Sistemas nucleónicos industriales de medida de la densidad',
	},
	{
		code: '26141904',
		label: 'Indicadores de nivel de líquido nucleónico industrial',
	},
	{
		code: '26141905',
		label:
			'Sistemas de medida de masa nucleónica industrial por unidad de mineral',
	},
	{
		code: '26141906',
		label: 'Sistemas de medida de la humedad industrial nucleónica',
	},
	{
		code: '26141907',
		label: 'Sistemas de medida el espesor industrial nucleónico',
	},
	{
		code: '26141908',
		label: 'Sistemas de medida del flujo industrial nucleónico',
	},
	{
		code: '26141909',
		label: 'Separadores de isótopos',
	},
	{
		code: '26141910',
		label: 'Instalaciones de producción de isótopos',
	},
	{
		code: '26141911',
		label: 'Medidores de actividad del calibrador de isótopos',
	},
	{
		code: '26142000',
		label: 'Equipo de irradiación',
	},
	{
		code: '26142001',
		label: 'Fuentes de irradiación gamma',
	},
	{
		code: '26142002',
		label: 'Sistemas de imanes',
	},
	{
		code: '26142003',
		label: 'Unidades electrónicas nucleares nim',
	},
	{
		code: '26142004',
		label: 'Irradiadores de neutrones',
	},
	{
		code: '26142005',
		label: 'Cápsulas para ensayos de irradiación',
	},
	{
		code: '26142006',
		label: 'Sistema de transferencia de muestras de irradiación',
	},
	{
		code: '26142007',
		label: 'Generadores de neutrones',
	},
	{
		code: '26142100',
		label: 'Equipos para reactores nucleares',
	},
	{
		code: '26142101',
		label: 'Recipientes de irradiación de especímenes para reactores nucleares',
	},
	{
		code: '26142106',
		label: 'Sistemas de varilla de mando para reactores nucleares',
	},
	{
		code: '26142108',
		label:
			'Instrumentación de flujo de neutrones incorporada al núcleo para reactores nucleares',
	},
	{
		code: '26142117',
		label: 'Instrumentación de terremotos para reactores nucleares',
	},
	{
		code: '26142200',
		label: 'Equipo para combustible nuclear',
	},
	{
		code: '26142201',
		label: 'Tubos con revestimiento para combustible nuclear',
	},
	{
		code: '26142202',
		label:
			'Sistemas de detección de fallo de elementos para reactores nucleares',
	},
	{
		code: '26142300',
		label: 'Equipos protectores contra la radiación',
	},
	{
		code: '26142302',
		label: 'Blindajes de plomo',
	},
	{
		code: '26142303',
		label: 'Distintivo de película',
	},
	{
		code: '26142304',
		label: 'Equipo radiográfico',
	},
	{
		code: '26142306',
		label: 'Recipientes blindados contra la radiación',
	},
	{
		code: '26142307',
		label: 'Cámaras de plomo para protección contra la radiación',
	},
	{
		code: '26142308',
		label: 'Ladrillos de plomo para protección contra la radiación',
	},
	{
		code: '26142310',
		label: 'Cajas selladas con guantes para protección contra la radiación',
	},
	{
		code: '26142311',
		label: 'Ventanas para blindaje contra la radiación',
	},
	{
		code: '26142312',
		label: 'Plomo para blindaje contra la radiación',
	},
	{
		code: '26142400',
		label: 'Equipo para residuos radiactivos',
	},
	{
		code: '26142401',
		label:
			'Compactadores o incineradores para el tratamiento de residuos radiactivos',
	},
	{
		code: '26142402',
		label: 'Absorbentes de radiaciones nucleares',

		palabrasSimilares: 'Absorbentes de radiación nuclear',
	},
	{
		code: '26142403',
		label: 'Evaporadores, concentradores o secadores de energía atómica',
	},
	{
		code: '26142404',
		label: 'Sistemas de inter - bloqueo de las puertas',

		palabrasSimilares: 'Sistema de bloqueo de puertas',
	},
	{
		code: '26142405',
		label: 'Sistemas de dosificación de residuos radiactivos',
	},
	{
		code: '26142406',
		label: 'Sistemas de solidificación de residuos radiactivos',
	},
	{
		code: '26142407',
		label: 'Sistemas de eliminación de residuos radiactivos',
	},
	{
		code: '26142408',
		label: 'Instalaciones para el tratamiento de residuos radiactivos',
	},
	{
		code: '27111500',
		label: 'Herramientas de corte y engarzado y punzones',
	},
	{
		code: '27111501',
		label: 'Hojas de cuchillo',
	},
	{
		code: '27111502',
		label: 'Navajas de afeitar',
	},
	{
		code: '27111503',
		label: 'Cuchillos de diversas aplicaciones',

		palabrasSimilares: 'Cuchillos para uso general',
	},
	{
		code: '27111504',
		label: 'Navajas de bolsillo',
	},
	{
		code: '27111505',
		label: 'Sets para manicure',

		palabrasSimilares: 'Estuche de manicure',
	},
	{
		code: '27111506',
		label: 'Cizallas',
	},
	{
		code: '27111507',
		label: 'Cortadores de metal',

		palabrasSimilares: 'Cúter o cutter',
	},
	{
		code: '27111508',
		label: 'Sierras',
	},
	{
		code: '27111509',
		label: 'Barrenas',
	},
	{
		code: '27111510',
		label: 'Herramientas para desforrar',

		palabrasSimilares: 'Herramienta para cortar',
	},
	{
		code: '27111511',
		label: 'Cortadores de alambres',

		palabrasSimilares: 'Pinzas de cortar alambre',
	},
	{
		code: '27111512',
		label: 'Cortadores de pernos',

		palabrasSimilares: 'Pinzas cortadoras de pernos',
	},
	{
		code: '27111513',
		label: 'Cortadores de manguera',
	},
	{
		code: '27111514',
		label: 'Cortadores de vidrio',
	},
	{
		code: '27111515',
		label: 'Taladro de mano',
	},
	{
		code: '27111516',
		label: 'Alicates de perforación',

		palabrasSimilares: 'Perforadora',
	},
	{
		code: '27111517',
		label: 'Dispensadores o juegos de hojas de cuchilla',
	},
	{
		code: '27111518',
		label: 'Herramienta para engastar y doblar alambre',
	},
	{
		code: '27111519',
		label: 'Tijeras para estaño',
	},
	{
		code: '27111520',
		label: 'Rompetuercas',
	},
	{
		code: '27111521',
		label: 'Recortadoras de chapa de uña vibratoria',
	},
	{
		code: '27111522',
		label: 'Herramienta para aboquillar o ensanchar',
	},
	{
		code: '27111523',
		label: 'Raspador de vidrio',
	},
	{
		code: '27111524',
		label: 'Cortadora de ribetes',

		palabrasSimilares: 'Cortadora de rollos',
	},
	{
		code: '27111525',
		label: 'Tenazas',
	},
	{
		code: '27111526',
		label: 'Herramienta para quitar rebabas',
	},
	{
		code: '27111527',
		label: 'Cortador de tacos',
	},
	{
		code: '27111529',
		label: 'Tijeras aisladas',
	},
	{
		code: '27111530',
		label: 'Tijeras de pescador',
	},
	{
		code: '27111531',
		label: 'Tijeras para el pelo',

		palabrasSimilares: 'Tijeras para el cabello',
	},
	{
		code: '27111532',
		label: 'Tijeras de joyería',
	},
	{
		code: '27111533',
		label: 'Tijeras de carnicero',
	},
	{
		code: '27111534',
		label: 'Compás de corte',
	},
	{
		code: '27111535',
		label: 'Tijeras de alambre',
	},
	{
		code: '27111536',
		label: 'Cortador de círculos',
	},
	{
		code: '27111537',
		label: 'Broca para madera',
	},
	{
		code: '27111538',
		label: 'Broca forstner',
	},
	{
		code: '27111539',
		label: 'Broca para madera plana',
	},
	{
		code: '27111540',
		label: 'Broca para baldosa',
	},
	{
		code: '27111541',
		label: 'Punta guía de bola',
	},
	{
		code: '27111542',
		label: 'Broca de punto de espuela',
	},
	{
		code: '27111543',
		label: 'Broca de mampostería',
	},
	{
		code: '27111544',
		label: 'Sierra de hender',

		palabrasSimilares: 'Serrucho de Hender',
	},
	{
		code: '27111545',
		label: 'Sierra de hender biselada',
	},
	{
		code: '27111546',
		label: 'Sierra de panel',
	},
	{
		code: '27111547',
		label: 'Sierra de pisos',
	},
	{
		code: '27111548',
		label: 'Sierra tenon',
	},
	{
		code: '27111549',
		label: 'Sierra para herraduras',
	},
	{
		code: '27111550',
		label: 'Sierra de moño',
	},
	{
		code: '27111551',
		label: 'Sierra de troncos',
	},
	{
		code: '27111552',
		label: 'Segueta',
	},
	{
		code: '27111553',
		label: 'Sierra de dos hombres',
	},
	{
		code: '27111554',
		label: 'Afilador de cuchillas',
	},
	{
		code: '27111555',
		label: 'Punzón central',
	},
	{
		code: '27111556',
		label: 'Punzón hueco',
	},
	{
		code: '27111557',
		label: 'Punzón cónico',
	},
	{
		code: '27111558',
		label: 'Pinzón de pasador paralelo',
	},
	{
		code: '27111559',
		label: 'Sierra para metales',
	},
	{
		code: '27111560',
		label: 'Mini alicates',
	},
	{
		code: '27111561',
		label: 'Caja de ingletear',

		palabrasSimilares: 'Caja de mitre',
	},
	{
		code: '27111562',
		label: 'Cortadora de cables',
	},
	{
		code: '27111600',
		label: 'Herramientas de perfilar',
	},
	{
		code: '27111601',
		label: 'Mazas de hierro',

		palabrasSimilares: 'Mazos de hierro',
	},
	{
		code: '27111602',
		label: 'Martillos',
	},
	{
		code: '27111603',
		label: 'Yunques',
	},
	{
		code: '27111604',
		label: 'Hachas de mano',
	},
	{
		code: '27111605',
		label: 'Picas',
	},
	{
		code: '27111607',
		label: 'Herramientas de recalcar',

		palabrasSimilares: 'Herramientas de estampado',
	},
	{
		code: '27111608',
		label: 'Enderezadores manuales de cables',
	},
	{
		code: '27111609',
		label: 'Enderezadores eléctricos de cables',
	},
	{
		code: '27111610',
		label: 'Componentes de martillo y mazo',
	},
	{
		code: '27111611',
		label: 'Martillo para picar piedra',
	},
	{
		code: '27111612',
		label: 'Martillo o mazo que no saca chispas',

		palabrasSimilares: 'Mazo',
	},
	{
		code: '27111613',
		label: 'Hachuela que no sacan chispas',

		palabrasSimilares: 'Hacha antichispas',
	},
	{
		code: '27111614',
		label: 'Pica que no saca chispas',

		palabrasSimilares: 'Pica antichispas',
	},
	{
		code: '27111615',
		label: 'Almádena o martillo macho',

		palabrasSimilares: 'Martillo antichispas',
	},
	{
		code: '27111616',
		label: 'Martillo de bola',
	},
	{
		code: '27111617',
		label: 'Martillo de caucho',
	},
	{
		code: '27111618',
		label: 'Martillo de garra',
	},
	{
		code: '27111619',
		label: 'Martillo de bola de cruz y derecho',
	},
	{
		code: '27111620',
		label: 'Martillo de bola de pasador',
	},
	{
		code: '27111621',
		label: 'Martillo mazo',
	},
	{
		code: '27111622',
		label: 'Martillo de carpintería',
	},
	{
		code: '27111623',
		label: 'Tapador de botellas manual',
	},
	{
		code: '27111700',
		label: 'Llaves inglesas y guías',
	},
	{
		code: '27111701',
		label: 'Destornilladores',

		palabrasSimilares: 'Desarmadores',
	},
	{
		code: '27111702',
		label: 'Llaves para tuercas',
	},
	{
		code: '27111703',
		label: 'Juegos de enchufes',
	},
	{
		code: '27111704',
		label: 'Enchufes',
	},
	{
		code: '27111705',
		label: 'Llaves de tuercas de boca cerrada',
	},
	{
		code: '27111706',
		label: 'Llave de tuercas de boca abierta',
	},
	{
		code: '27111707',
		label: 'Llaves ajustables',

		palabrasSimilares: 'Llaves inglesas, Llaves perico',
	},
	{
		code: '27111708',
		label: 'Llaves para tubos',
	},
	{
		code: '27111709',
		label: 'Extractor de tornillos',
	},
	{
		code: '27111710',
		label: 'Llaves allen',

		palabrasSimilares: 'Llaves hexagonales',
	},
	{
		code: '27111711',
		label: 'Trinquetes',
	},
	{
		code: '27111712',
		label: 'Extractores',
	},
	{
		code: '27111713',
		label: 'Llaves de combinación',
	},
	{
		code: '27111714',
		label: 'Llaves de especialidad',
	},
	{
		code: '27111715',
		label: 'Llaves de torsión',
	},
	{
		code: '27111716',
		label: 'Llave torx',
	},
	{
		code: '27111717',
		label: 'Extractores de tubería',
	},
	{
		code: '27111718',
		label: 'Extractores de grifo',
	},
	{
		code: '27111720',
		label: 'Llave manual en t para grifos',
	},
	{
		code: '27111721',
		label: 'Manivelas',
	},
	{
		code: '27111722',
		label: 'Troqueleras',
	},
	{
		code: '27111723',
		label: 'Llaves de tubo',
	},
	{
		code: '27111724',
		label: 'Llaves de gancho',
	},
	{
		code: '27111725',
		label: 'Llaves tubulares de desplazamiento',
	},
	{
		code: '27111726',
		label: 'Llaves de tuercas',
	},
	{
		code: '27111727',
		label: 'Llaves macho con mango en t',

		palabrasSimilares: 'Llave para taladro',
	},
	{
		code: '27111728',
		label: 'Juego de destornilladores',

		palabrasSimilares: 'Juego de desarmadores',
	},
	{
		code: '27111729',
		label: 'Juego de llaves',

		palabrasSimilares: 'Juego de llaves inglesas',
	},
	{
		code: '27111730',
		label: 'Llave giratoria de dos extremos',
	},
	{
		code: '27111731',
		label: 'Destornillador de precisión',

		palabrasSimilares: 'Desarmador de precisión',
	},
	{
		code: '27111732',
		label: 'Destornillador que no saca chispas',

		palabrasSimilares: 'Desarmador antichispas',
	},
	{
		code: '27111733',
		label: 'Destornillador de mango flexible',

		palabrasSimilares: 'Desarmador de varilla flexible',
	},
	{
		code: '27111734',
		label: 'Destornillador de múltiples puntas',

		palabrasSimilares: 'Desarmador de punta múltiple',
	},
	{
		code: '27111735',
		label: 'Destornillador de trinquete',

		palabrasSimilares: 'Desarmador de trinquete',
	},
	{
		code: '27111736',
		label: 'Destornillador aislado',

		palabrasSimilares: 'Desarmador aislado',
	},
	{
		code: '27111737',
		label: 'Destornillador manual automático de empujar',

		palabrasSimilares: 'Desarmador semiautomático',
	},
	{
		code: '27111738',
		label: 'Destornillador tester de voltaje',

		palabrasSimilares: 'Desarmador probador de voltaje',
	},
	{
		code: '27111739',
		label: 'Destornillador de torque',

		palabrasSimilares: 'Desarmador de torsión',
	},
	{
		code: '27111740',
		label: 'Destornillador telescópico',

		palabrasSimilares: 'Desarmador telescópico',
	},
	{
		code: '27111741',
		label: 'Destornillador de mango intercambiable',

		palabrasSimilares: 'Desarmador de varilla intercambiable',
	},
	{
		code: '27111742',
		label: 'Destornillador de impacto',

		palabrasSimilares: 'Desarmador de impacto',
	},
	{
		code: '27111743',
		label: 'Llave de anillos',

		palabrasSimilares: 'Llave inglesa',
	},
	{
		code: '27111744',
		label: 'Llave de compensación de anillos',

		palabrasSimilares: 'Llave anular',
	},
	{
		code: '27111745',
		label: 'Llave de anillo dividido',

		palabrasSimilares: 'Llave inglesa doble',
	},
	{
		code: '27111746',
		label: 'Llave bulldog',
	},
	{
		code: '27111747',
		label: 'Llave de caja',
	},
	{
		code: '27111748',
		label: 'Llave de huella',
	},
	{
		code: '27111749',
		label: 'Llave de tubo o stillson',
	},
	{
		code: '27111750',
		label: 'Pinza de presión',
	},
	{
		code: '27111751',
		label: 'Llave de grifos',

		palabrasSimilares: 'Llave de grifo',
	},
	{
		code: '27111752',
		label: 'Llave de obstrucción',

		palabrasSimilares: 'Llave angular',
	},
	{
		code: '27111753',
		label: 'Llave de trinquete',
	},
	{
		code: '27111754',
		label: 'Llave en s',
	},
	{
		code: '27111755',
		label: 'Llave en y',
	},
	{
		code: '27111756',
		label: 'Llave de pata de cuervo',

		palabrasSimilares: 'Llave de pata',
	},
	{
		code: '27111757',
		label: 'Llave de golpe especial o slugging',

		palabrasSimilares: 'Llave de giro',
	},
	{
		code: '27111758',
		label: 'Llave dinamométrica de disparo automático',

		palabrasSimilares: 'Palanca de Leva',
	},
	{
		code: '27111759',
		label: 'Llave de cuchilla',
	},
	{
		code: '27111760',
		label: 'Llave de tuercas de llantas',
	},
	{
		code: '27111761',
		label: 'Llave de bomba de agua',
	},
	{
		code: '27111762',
		label: 'Llave de trinquete de cabeza flexible',
	},
	{
		code: '27111763',
		label: 'Llave allen',
	},
	{
		code: '27111764',
		label: 'Guías de broca',
	},
	{
		code: '27111765',
		label: 'Llave para destapar tambores',

		palabrasSimilares: 'Llave para abrir tambos',
	},
	{
		code: '27111766',
		label: 'Llave de rin cruzado',
	},
	{
		code: '27111767',
		label: 'Destornillador de compensación o en “l”',

		palabrasSimilares: 'Destornillador',
	},
	{
		code: '27111800',
		label: 'Herramientas de medida y bocetaje',
	},
	{
		code: '27111801',
		label: 'Cintas métricas',

		palabrasSimilares: 'Flexómetro',
	},
	{
		code: '27111802',
		label: 'Escalas',
	},
	{
		code: '27111803',
		label: 'Escuadras',
	},
	{
		code: '27111804',
		label: 'Plomadas',
	},
	{
		code: '27111806',
		label: 'Calibrador de clavos',

		palabrasSimilares: 'Medidor de clavos',
	},
	{
		code: '27111807',
		label: 'Bordes rectos',
	},
	{
		code: '27111809',
		label: 'Biseles',
	},
	{
		code: '27111810',
		label: 'Buscadores de pernos',
	},
	{
		code: '27111811',
		label: 'Regla doblable',

		palabrasSimilares: 'Regla flexible',
	},
	{
		code: '27111812',
		label: 'Regla larga',
	},
	{
		code: '27111813',
		label: 'Línea de plomada',

		palabrasSimilares: 'Línea plomada',
	},
	{
		code: '27111814',
		label: 'Espejo de inspección',
	},
	{
		code: '27111815',
		label: 'Escuadra de combinación',
	},
	{
		code: '27111816',
		label: 'Juego de bola indicadora',

		palabrasSimilares: 'Juego de bolas gage',
	},
	{
		code: '27111817',
		label: 'Regla de encogimiento',
	},
	{
		code: '27111818',
		label: 'Soporte del indicador del',

		palabrasSimilares: 'Soporte indicador de cuadrante',
	},
	{
		code: '27111819',
		label: 'Espécimen de comparación de rugosidad',

		palabrasSimilares: 'Galgas de comparación de rugosidad',
	},
	{
		code: '27111820',
		label: 'Escuadra maestra',
	},
	{
		code: '27111821',
		label: 'Escuadra cilíndrica',
	},
	{
		code: '27111822',
		label: 'Instrumento de medición de ángulos',
	},
	{
		code: '27111823',
		label: 'Calibrador de centro',
	},
	{
		code: '27111824',
		label: 'Calibrador de ángulo',
	},
	{
		code: '27111825',
		label: 'Placa de seno',
	},
	{
		code: '27111826',
		label: 'Placa de superficie de precisión',
	},
	{
		code: '27111827',
		label: 'Placa de ángulo ajustable',
	},
	{
		code: '27111828',
		label: 'Placa de ángulo fijo',
	},
	{
		code: '27111829',
		label: 'Paralelo óptico',
	},
	{
		code: '27111900',
		label: 'Herramientas gruesas y de acabado',
	},
	{
		code: '27111901',
		label: 'Cortafríos',

		palabrasSimilares: 'Cincel corta frío',
	},
	{
		code: '27111903',
		label: 'Cepillos de carpintero',
	},
	{
		code: '27111904',
		label: 'Raspa',
	},
	{
		code: '27111905',
		label: 'Esmeriladoras',
	},
	{
		code: '27111906',
		label: 'Cinceles de madera',
	},
	{
		code: '27111907',
		label: 'Cepillos de alambre',
	},
	{
		code: '27111908',
		label: 'Piedras o herramientas o equipos de afilar',
	},
	{
		code: '27111909',
		label: 'Espátulas',
	},
	{
		code: '27111910',
		label: 'Buriles',

		palabrasSimilares: 'Cuchilla para torno',
	},
	{
		code: '27111911',
		label: 'Formones',

		palabrasSimilares: 'Cinceles de corte transversal',
	},
	{
		code: '27111912',
		label: 'Perforador de madera',
	},
	{
		code: '27111913',
		label: 'Arreglador de bordes',

		palabrasSimilares: 'Recortador de bordes',
	},
	{
		code: '27111914',
		label: 'Cincel de punta de bola',

		palabrasSimilares: 'Cincel de punta',
	},
	{
		code: '27111915',
		label: 'Cincel de cantera',
	},
	{
		code: '27111916',
		label: 'Removedor de techos',
	},
	{
		code: '27111917',
		label: 'Aplicador de goma',
	},
	{
		code: '27111918',
		label: 'Lija manual',
	},
	{
		code: '27111919',
		label: 'Lima bastarda',
	},
	{
		code: '27111920',
		label: 'Lima de segundo corte',
	},
	{
		code: '27111921',
		label: 'Lima de corte liso',
	},
	{
		code: '27111922',
		label: 'Lima de corte en cruz',
	},
	{
		code: '27111923',
		label: 'Lima de sierra de cadena',

		palabrasSimilares: 'Afiladora de dientes de motosierra',
	},
	{
		code: '27111924',
		label: 'Lima de corte raspa',
	},
	{
		code: '27111925',
		label: 'Lima de aguja',
	},
	{
		code: '27111926',
		label: 'Lima plana de aluminio',
	},
	{
		code: '27111927',
		label: 'Lima de aluminio semicircular',
	},
	{
		code: '27111928',
		label: 'Lima rallo de queso',
	},
	{
		code: '27111929',
		label: 'Lima de mano plana',
	},
	{
		code: '27111930',
		label: 'Lima redonda',
	},
	{
		code: '27111931',
		label: 'Lima semicircular',
	},
	{
		code: '27111932',
		label: 'Lima de escuadra',
	},
	{
		code: '27111933',
		label: 'Lima triangular de tres escuadras',
	},
	{
		code: '27111934',
		label: 'Lima plana cónica',
	},
	{
		code: '27111935',
		label: 'Lima de navaja',
	},
	{
		code: '27111936',
		label: 'Lima de guarda',
	},
	{
		code: '27111937',
		label: 'Lima laminada',
	},
	{
		code: '27111938',
		label: 'Lima de torno de ángulo largo',
	},
	{
		code: '27111939',
		label: 'Lima semicircular para tubería',
	},
	{
		code: '27111940',
		label: 'Lima de sierra maderera',
	},
	{
		code: '27111941',
		label: 'Lima de sierra cónica',
	},
	{
		code: '27111942',
		label: 'Lima de sierra de extremo doble',
	},
	{
		code: '27111943',
		label: 'Lima de granjero',
	},
	{
		code: '27111944',
		label: 'Lima de precisión',
	},
	{
		code: '27111945',
		label: 'Lima curva para grabar troqueles',
	},
	{
		code: '27111946',
		label: 'Lima curva de platería',
	},
	{
		code: '27111948',
		label: 'Raspador semicircular de madera',
	},
	{
		code: '27111949',
		label: 'Raspador semicircular de gabinete',
	},
	{
		code: '27111950',
		label: 'Raspador circular de madera',
	},
	{
		code: '27111951',
		label: 'Raspador de zapatas de cuatro en mano',
	},
	{
		code: '27111952',
		label: 'Raspador de caballos',
	},
	{
		code: '27111953',
		label: 'Raspador de peletero',
	},
	{
		code: '27111954',
		label: 'Escariador cónico de vástago de puente',

		palabrasSimilares: 'Escariador vástago de puente',
	},
	{
		code: '27111955',
		label: 'Escariador redondo de vástago de puente',

		palabrasSimilares: 'Escariador redondeado',
	},
	{
		code: '27111956',
		label: 'Bruñidor',
	},
	{
		code: '27111957',
		label: 'Limpiador de boquillas',
	},
	{
		code: '27111958',
		label: 'Lapeador de mano',
	},
	{
		code: '27112000',
		label: 'Herramientas manuales de jardinería, agricultura y forestación',

		palabrasSimilares:
			'Herramientas manuales de floricultura, cultivo y reforestación',
	},
	{
		code: '27112001',
		label: 'Machetes',
	},
	{
		code: '27112002',
		label: 'Azadones',
	},
	{
		code: '27112003',
		label: 'Rastrillos',
	},
	{
		code: '27112004',
		label: 'Palas',
	},
	{
		code: '27112005',
		label: 'Hachas',
	},
	{
		code: '27112006',
		label: 'Guadañas',
	},
	{
		code: '27112007',
		label: 'Tijeras de podar',

		palabrasSimilares: 'Tijeras para podar pasto',
	},
	{
		code: '27112008',
		label: 'Azadas',

		palabrasSimilares: 'Azadillas',
	},
	{
		code: '27112009',
		label: 'Raspadores',
	},
	{
		code: '27112010',
		label: 'Horquilla de jardín',
	},
	{
		code: '27112011',
		label: 'Mangos de herramientas',
	},
	{
		code: '27112012',
		label: 'Criba jardinera',

		palabrasSimilares: 'Cernidor de jardinería',
	},
	{
		code: '27112013',
		label: 'Excavadora de hoyos para postes',

		palabrasSimilares: 'Perforadora de hoyos para postes',
	},
	{
		code: '27112014',
		label: 'Cortadora de pasto',

		palabrasSimilares: 'Desbrozadora para pasto',
	},
	{
		code: '27112015',
		label: 'Escarificador de prados',

		palabrasSimilares: 'Podadora de pasto',
	},
	{
		code: '27112016',
		label: 'Tijeras para setos',

		palabrasSimilares: 'Tijeras podadoras de arbustos',
	},
	{
		code: '27112017',
		label: 'Barras de cavar o barretones',
	},
	{
		code: '27112018',
		label: 'Plantador de bulbos',
	},
	{
		code: '27112019',
		label: 'Sierra podadora',
	},
	{
		code: '27112020',
		label: 'Hoz',
	},
	{
		code: '27112021',
		label: 'Aireador de césped',
	},
	{
		code: '27112022',
		label: 'Cizalla para bordes',

		palabrasSimilares: 'Cortadoras de borde',
	},
	{
		code: '27112023',
		label: 'Cultivador a mano',
	},
	{
		code: '27112024',
		label: 'Juego de herramientas para jardín en miniatura',
	},
	{
		code: '27112025',
		label: 'Azada de mango corto',

		palabrasSimilares: 'Azadillas de mango corto',
	},
	{
		code: '27112026',
		label: 'Cuña de mango',
	},
	{
		code: '27112027',
		label: 'Escoba de jardín',

		palabrasSimilares: 'Rastrillo',
	},
	{
		code: '27112028',
		label: 'Descortezadora de árboles',
	},
	{
		code: '27112029',
		label: 'Regadera de jardín',
	},
	{
		code: '27112030',
		label: 'Gancho podador',
	},
	{
		code: '27112031',
		label: 'Cedazo de jardín',
	},
	{
		code: '27112032',
		label: 'Azuela',

		palabrasSimilares: 'Hacha con hoja curveada',
	},
	{
		code: '27112033',
		label: 'Juego de herramientas de jardín',
	},
	{
		code: '27112034',
		label: 'Cortador de raíces',
	},
	{
		code: '27112035',
		label: 'Cortador de setos',
	},
	{
		code: '27112036',
		label: 'Trituradora de jardín',

		palabrasSimilares: 'Trituradora de ramas',
	},
	{
		code: '27112037',
		label: 'Cortador de brochas',
	},
	{
		code: '27112038',
		label: 'Sierra de cadena de jardín',
	},
	{
		code: '27112039',
		label: 'Podadora de 2 manos',
	},
	{
		code: '27112040',
		label: 'Sonda de suelo',
	},
	{
		code: '27112041',
		label: 'Cuchillo de injertos',

		palabrasSimilares: 'Navaja para injertar',
	},
	{
		code: '27112042',
		label: 'Medidor de ancho de troncos',

		palabrasSimilares: 'Instrumentos de medición forestal',
	},
	{
		code: '27112043',
		label: 'Herramienta para diagnóstico de árboles o resistógrafo',
	},
	{
		code: '27112044',
		label: 'Juego de inyecciones para troncos de árboles',
	},
	{
		code: '27112045',
		label: 'Cortador de césped',
	},
	{
		code: '27112046',
		label: 'Recogedor de pasto cortado',

		palabrasSimilares: 'Recolector de pasto cortado',
	},
	{
		code: '27112047',
		label: 'Cortador de ramas altas',
	},
	{
		code: '27112100',
		label: 'Herramientas de sujeción y fijación',
	},
	{
		code: '27112101',
		label: 'Dobladores de tubos',

		palabrasSimilares: 'Roladora de tubo',
	},
	{
		code: '27112102',
		label: 'Tornillos de banco',
	},
	{
		code: '27112103',
		label: 'Pinza de mano',
	},
	{
		code: '27112104',
		label: 'Tenazas',
	},
	{
		code: '27112105',
		label: 'Pinzas',
	},
	{
		code: '27112106',
		label: 'Alicates de guardalínea',

		palabrasSimilares: 'Pinzas de electricista',
	},
	{
		code: '27112107',
		label: 'Alicates boquianchos ajustables',

		palabrasSimilares: 'Pinzas de boquilla ajustable',
	},
	{
		code: '27112108',
		label: 'Alicates de punta de aguja',

		palabrasSimilares: 'Pinzas de punta de aguja',
	},
	{
		code: '27112109',
		label: 'Herramientas magnéticas',

		palabrasSimilares: 'Herramientas imantadas',
	},
	{
		code: '27112110',
		label: 'Pinzas de anillo de retención',

		palabrasSimilares: 'Pinzas para anillo de retención',
	},
	{
		code: '27112111',
		label: 'Alicates de lagarto',

		palabrasSimilares: 'Pinzas de lagarto',
	},
	{
		code: '27112112',
		label: 'Tenazas de ranura y lengüeta',

		palabrasSimilares: 'Pinzas de ranura y lengüeta',
	},
	{
		code: '27112113',
		label: 'Alicates de articulación movible o de ranura',

		palabrasSimilares: 'Pinzas de junta ajustable o ranura',
	},
	{
		code: '27112114',
		label: 'Pinzas de corte diagonal',
	},
	{
		code: '27112115',
		label: 'Pinzas de cerrado',

		palabrasSimilares: 'Pinzas de presión',
	},
	{
		code: '27112116',
		label: 'Pinzas de cerca',

		palabrasSimilares: 'Pinzas para cercas',
	},
	{
		code: '27112117',
		label: 'Tenazas de corte final',

		palabrasSimilares: 'Pinzas para corte final',
	},
	{
		code: '27112119',
		label: 'Cambiador de bombilla de luz',

		palabrasSimilares: 'Brazo largo para cambiar focos',
	},
	{
		code: '27112120',
		label: 'Grapas c',

		palabrasSimilares: 'Prensa C',
	},
	{
		code: '27112121',
		label: 'Grapa de ángulo',

		palabrasSimilares: 'Abrazaderas de ángulo',
	},
	{
		code: '27112122',
		label: 'Alicates de hoja metálica',

		palabrasSimilares: 'Alicates de chapa',
	},
	{
		code: '27112123',
		label: 'Prensa de mesa',
	},
	{
		code: '27112124',
		label: 'Tensionadores',
	},
	{
		code: '27112125',
		label: 'Alicates de punta redonda',

		palabrasSimilares: 'Pinzas de punta',
	},
	{
		code: '27112126',
		label: 'Alicates planos',

		palabrasSimilares: 'Pinzas de punta plana',
	},
	{
		code: '27112127',
		label: 'Llaves de cinta',
	},
	{
		code: '27112128',
		label: 'Alicates de punta curvada',

		palabrasSimilares: 'Pinzas de punta curva',
	},
	{
		code: '27112129',
		label: 'Abrazaderas de mango redondo',
	},
	{
		code: '27112130',
		label: 'Abrazaderas de tres garras',
	},
	{
		code: '27112131',
		label: 'Abrazadera para la apertura de mandíbula',
	},
	{
		code: '27112132',
		label: 'Abrazaderas de fijación',
	},
	{
		code: '27112133',
		label: 'Abrazaderas con mango en t',

		palabrasSimilares: 'Abrazaderas de manillar en T',
	},
	{
		code: '27112134',
		label: 'Alicates de punta larga',

		palabrasSimilares: 'Pinzas de punta larga',
	},
	{
		code: '27112135',
		label: 'Alicates aislados',

		palabrasSimilares: 'Pinzas aisladas',
	},
	{
		code: '27112136',
		label: 'Alicates que no sacan chispa',

		palabrasSimilares: 'Pinzas antichispas',
	},
	{
		code: '27112137',
		label: 'Juego de alicates',

		palabrasSimilares: 'Juego de pinzas',
	},
	{
		code: '27112138',
		label: 'Enderezador manual de tubos',
	},
	{
		code: '27112139',
		label: 'Alicates de combinación',

		palabrasSimilares: 'Pinzas de combinación',
	},
	{
		code: '27112140',
		label: 'Alicates circlip',

		palabrasSimilares: 'Pinzas circlip',
	},
	{
		code: '27112141',
		label: 'Alicates de vidrio',

		palabrasSimilares: 'Cortadores de vidrio',
	},
	{
		code: '27112142',
		label: 'Alicates muli – herramienta',

		palabrasSimilares: 'Alicates múltiple',
	},
	{
		code: '27112143',
		label: 'Alicates para cortar baldosas',

		palabrasSimilares: 'Cortadoras de loza',
	},
	{
		code: '27112144',
		label: 'Alicates de nariz doblada',
	},
	{
		code: '27112145',
		label: 'Alicates de articulación movible',

		palabrasSimilares: 'Alicates de unión deslizante',
	},
	{
		code: '27112146',
		label: 'Tornillo de base pivote',

		palabrasSimilares: 'Tornillo de sujeción',
	},
	{
		code: '27112147',
		label: 'Alicates de engaste',

		palabrasSimilares: 'Alicates de crimpado',
	},
	{
		code: '27112148',
		label: 'Alicates electrónicos',
	},
	{
		code: '27112149',
		label: 'Alicates electrostáticos',
	},
	{
		code: '27112150',
		label: 'Alicates de joyería',

		palabrasSimilares: 'Pinzas de joyero',
	},
	{
		code: '27112151',
		label: 'Alicates pelacables',

		palabrasSimilares: 'Pinzas pela cables',
	},
	{
		code: '27112152',
		label: 'Almeja triclover',
	},
	{
		code: '27112153',
		label: 'Engrapadora de banda',
	},
	{
		code: '27112154',
		label: 'Tornillo fresador',
	},
	{
		code: '27112155',
		label: 'Expansor de anillo de pistón',
	},
	{
		code: '27112156',
		label: 'Tornillo de mano',
	},
	{
		code: '27112157',
		label: 'Tornillo de taladro',
	},
	{
		code: '27112158',
		label: 'Tornillo de pata',
	},
	{
		code: '27112159',
		label: 'Tornillo de cadena',
	},
	{
		code: '27112160',
		label: 'Torcedor de cables',

		palabrasSimilares: 'Tornillo de alambre',
	},
	{
		code: '27112161',
		label: 'Herramienta para empacar cables',

		palabrasSimilares: 'Herramienta para sujeción de cable',
	},
	{
		code: '27112162',
		label: 'Unión para reglas',
	},
	{
		code: '27112200',
		label: 'Herramientas de albañilería y concreto',
	},
	{
		code: '27112201',
		label: 'Palustres',

		palabrasSimilares: 'Cuchara para mezcla',
	},
	{
		code: '27112202',
		label: 'Palustres o llanas de madera',
	},
	{
		code: '27112203',
		label: 'Bordeadoras',
	},
	{
		code: '27112205',
		label: 'Vibradores de concreto',
	},
	{
		code: '27112206',
		label: 'Tabla de mortero',
	},
	{
		code: '27112207',
		label: 'Ariete de mano',
	},
	{
		code: '27112208',
		label: 'Pica',

		palabrasSimilares: 'Copiador de perfiles para corte',
	},
	{
		code: '27112209',
		label: 'Pala de mampostería',
	},
	{
		code: '27112210',
		label: 'Unidor de ladrillos',
	},
	{
		code: '27112211',
		label: 'Llenador de uniones',
	},
	{
		code: '27112212',
		label: 'Herramienta de frente de brocal',

		palabrasSimilares: 'Herramienta de bordillo',
	},
	{
		code: '27112213',
		label: 'Chute de concreto',

		palabrasSimilares: 'Tolva de concreto',
	},
	{
		code: '27112215',
		label: 'Alisador de bordes',

		palabrasSimilares: 'Alisador de bordes para concreto',
	},
	{
		code: '27112216',
		label: 'Marcador de baldosas',

		palabrasSimilares: 'Marcador de azulejos',
	},
	{
		code: '27112217',
		label: 'Partidor de baldosas',

		palabrasSimilares: 'Cortador de azulejos',
	},
	{
		code: '27112218',
		label: 'Sierra eléctrica para baldosas',

		palabrasSimilares: 'Sierra eléctrica de azulejos',
	},
	{
		code: '27112219',
		label: 'Pellizcador de baldosas',

		palabrasSimilares: 'Pinzas para azulejo',
	},
	{
		code: '27112220',
		label: 'Sierra de mano para baldosas',

		palabrasSimilares: 'Sierra de mano para azulejo',
	},
	{
		code: '27112221',
		label: 'Broca para baldosas',

		palabrasSimilares: 'Broca especial para azulejo',
	},
	{
		code: '27112222',
		label: 'Cortador de baldosas',

		palabrasSimilares: 'Cortador de azulejos',
	},
	{
		code: '27112223',
		label: 'Lima para baldosas',

		palabrasSimilares: 'Lima para azulejo',
	},
	{
		code: '27112224',
		label: 'Copiador de perfiles para corte',
	},
	{
		code: '27112225',
		label: 'Esparcidor muescado',

		palabrasSimilares: 'Espaciador entallado',
	},
	{
		code: '27112226',
		label: 'Palustre para baldosas',

		palabrasSimilares: 'Cuchara para azulejos',
	},
	{
		code: '27112227',
		label: 'Esparcidor de lechada',
	},
	{
		code: '27112228',
		label: 'Removedor de lechada',
	},
	{
		code: '27112229',
		label: 'Cincel para remover baldosas',

		palabrasSimilares: 'Cincel de cuña para remover azulejo',
	},
	{
		code: '27112230',
		label: 'Llenador de juntas',

		palabrasSimilares: 'Puntero tuck para relleno de juntas',
	},
	{
		code: '27112231',
		label: 'Herramienta para escalones',
	},
	{
		code: '27112232',
		label: 'Ranuradora',
	},
	{
		code: '27112300',
		label: 'Herramientas de marcar',

		palabrasSimilares: 'Herramienta de sellado y marcado',
	},
	{
		code: '27112301',
		label: 'Hierro de marcar',

		palabrasSimilares: 'Fierro quemador',
	},
	{
		code: '27112302',
		label: 'Punzones o alwznas',
	},
	{
		code: '27112303',
		label: 'Punzón de trazar',
	},
	{
		code: '27112304',
		label: 'Líneas de marcar con tiza',
	},
	{
		code: '27112305',
		label: 'Marcadores o soportes de metal',
	},
	{
		code: '27112306',
		label: 'Sellos de metal',
	},
	{
		code: '27112307',
		label: 'Tizas para trazar líneas',
	},
	{
		code: '27112308',
		label: 'Carrito marcador',
	},
	{
		code: '27112309',
		label: 'Lápiz marcador',
	},
	{
		code: '27112310',
		label: 'Soporte de tiza',

		palabrasSimilares: 'Sujetadores de gis',
	},
	{
		code: '27112311',
		label: 'Tiza para marcar',

		palabrasSimilares: 'Gis para marcar',
	},
	{
		code: '27112312',
		label: 'Medidor superficial',

		palabrasSimilares: 'Medidor de superficies',
	},
	{
		code: '27112313',
		label: 'Marcador de dimensiones',
	},
	{
		code: '27112400',
		label: 'Herramientas de inserción y ajuste',
	},
	{
		code: '27112401',
		label: 'Pistolas de grapas',
	},
	{
		code: '27112402',
		label: 'Ribeteadoras',

		palabrasSimilares: 'Maquinas de coser u orleadora',
	},
	{
		code: '27112403',
		label: 'Colocadores de abrazaderas',
	},
	{
		code: '27112404',
		label: 'Herramientas para poner anclajes',

		palabrasSimilares: 'Herramienta de fijación',
	},
	{
		code: '27112405',
		label: 'Calentadores de pernos',
	},
	{
		code: '27112406',
		label: 'Pistolas de etiquetado',

		palabrasSimilares: 'Pistola etiquetadora',
	},
	{
		code: '27112407',
		label: 'Desprendedor de etiqueta de seguridad',
	},
	{
		code: '27112408',
		label: 'Removedor industrial de ganchos',
	},
	{
		code: '27112409',
		label: 'Pistola remachadora explosiva',

		palabrasSimilares: 'Pistola remachadora expansiva',
	},
	{
		code: '27112410',
		label: 'Pistola para amarrar cables',

		palabrasSimilares: 'Pistola para atadura de cables',
	},
	{
		code: '27112500',
		label: 'Herramientas para apalancar y curvar',
	},
	{
		code: '27112501',
		label: 'Herramientas curvatubos',

		palabrasSimilares: 'Dobladoras para tubo',
	},
	{
		code: '27112502',
		label: 'Palancas',

		palabrasSimilares: 'Barra para palanquear',
	},
	{
		code: '27112503',
		label: 'Dobladoras de tubos',
	},
	{
		code: '27112504',
		label: 'Cuñas',
	},
	{
		code: '27112505',
		label: 'Ganchos para empaquetaduras',

		palabrasSimilares: 'Ganchos de embalaje',
	},
	{
		code: '27112506',
		label: 'Manchas de impresión',
	},
	{
		code: '27112507',
		label: 'Varilla patecabra',

		palabrasSimilares: 'Barreta pata de cabra',
	},
	{
		code: '27112508',
		label: 'Doblador de cables',
	},
	{
		code: '27112600',
		label: 'Herramientas para precintar',

		palabrasSimilares: 'Herramientas para asegurar y etiquetar',
	},
	{
		code: '27112601',
		label: 'Espátulas para enmasillar',
	},
	{
		code: '27112602',
		label: 'Herramientas de calafateado',
	},
	{
		code: '27112603',
		label: 'Herramienta de inserción',
	},
	{
		code: '27112604',
		label: 'Recipiente para plastilina',

		palabrasSimilares: 'Cuenco de masilla',
	},
	{
		code: '27112700',
		label: 'Herramientas mecánicas',
	},
	{
		code: '27112701',
		label: 'Fuelles eléctricos',

		palabrasSimilares: 'Cubre polvos eléctricos',
	},
	{
		code: '27112702',
		label: 'Pulidoras eléctricas',
	},
	{
		code: '27112703',
		label: 'Taladradoras eléctricas',
	},
	{
		code: '27112704',
		label: 'Amoladora eléctricas',

		palabrasSimilares: 'Pulidor eléctrico',
	},
	{
		code: '27112705',
		label: 'Martillos de demolición',

		palabrasSimilares: 'Rotomartillo de demolición',
	},
	{
		code: '27112706',
		label: 'Cepillos eléctricos',

		palabrasSimilares: 'Cepillo eléctrico industrial',
	},
	{
		code: '27112707',
		label: 'Cuchillas eléctricas',
	},
	{
		code: '27112708',
		label: 'Lijadoras eléctricas',
	},
	{
		code: '27112709',
		label: 'Sierras eléctricas',
	},
	{
		code: '27112710',
		label: 'Pistolas de tornillos eléctricas',

		palabrasSimilares: 'Pistola atornilladora eléctrica',
	},
	{
		code: '27112711',
		label: 'Pistolas de grapas eléctricas',
	},
	{
		code: '27112712',
		label: 'Ribeteadoras eléctricas',

		palabrasSimilares: 'Cortadores eléctricos',
	},
	{
		code: '27112713',
		label: 'Aprietatuercas neumático de percusión',

		palabrasSimilares: 'Pistolas de alto impacto neumáticas',
	},
	{
		code: '27112714',
		label: 'Pistolas de calafateo eléctricas',
	},
	{
		code: '27112715',
		label: 'Martillos cinceladores eléctricos',
	},
	{
		code: '27112716',
		label: 'Pistolas de clavos eléctricas',
	},
	{
		code: '27112717',
		label: 'Pistolas de calor',
	},
	{
		code: '27112718',
		label: 'Grabadores',
	},
	{
		code: '27112719',
		label: 'Pistolas para engomar',
	},
	{
		code: '27112720',
		label: 'Herramientas de par de torsión',

		palabrasSimilares: 'Herramientas de tensionado y de torsión',
	},
	{
		code: '27112721',
		label: 'Juntador de capas de material',
	},
	{
		code: '27112722',
		label: 'Biseladora',
	},
	{
		code: '27112723',
		label: 'Cizallas eléctricas',
	},
	{
		code: '27112724',
		label: 'Pellizcadoras eléctricas',
	},
	{
		code: '27112725',
		label: 'Ribeteadora eléctrica',

		palabrasSimilares: 'Máquina de coser eléctrica',
	},
	{
		code: '27112726',
		label: 'Cortador de varillas eléctrico',
	},
	{
		code: '27112727',
		label: 'Pistola de mortero',
	},
	{
		code: '27112728',
		label: 'Soplete eléctrico',
	},
	{
		code: '27112729',
		label: 'Conector de tubos',
	},
	{
		code: '27112730',
		label: 'Pistola de grasa eléctrica',
	},
	{
		code: '27112731',
		label: 'Tijeras eléctricas',
	},
	{
		code: '27112732',
		label: 'Doblador de tubos eléctrico',
	},
	{
		code: '27112733',
		label: 'Cortador de ángulos',
	},
	{
		code: '27112734',
		label: 'Sierra de vaivén',
	},
	{
		code: '27112735',
		label: 'Sierra de espiral',
	},
	{
		code: '27112736',
		label: 'Cortador de muros',
	},
	{
		code: '27112737',
		label: 'Lija orbital',
	},
	{
		code: '27112738',
		label: 'Lija de detalle',
	},
	{
		code: '27112739',
		label: 'Lija de banda',
	},
	{
		code: '27112740',
		label: 'Lija de banca',
	},
	{
		code: '27112741',
		label: 'Lima lija o lija dedo',
	},
	{
		code: '27112742',
		label: 'Lija de disco',
	},
	{
		code: '27112743',
		label: 'Sierra de brazo elástico',

		palabrasSimilares: 'Sierra de brazo',
	},
	{
		code: '27112744',
		label: 'Sierra neumática',

		palabrasSimilares: 'Sierra caladora neumática',
	},
	{
		code: '27112745',
		label: 'Sierra de concreto',

		palabrasSimilares: 'Sierra para concreto',
	},
	{
		code: '27112746',
		label: 'Sierra de cadena',
	},
	{
		code: '27112747',
		label: 'Sierra recíproca',

		palabrasSimilares: 'Sierra intercambiable',
	},
	{
		code: '27112748',
		label: 'Sierra ingleteadora',

		palabrasSimilares: 'Cortadora ingletadora',
	},
	{
		code: '27112749',
		label: 'Pulidora de ángulo',

		palabrasSimilares: 'Rectificadora angular',
	},
	{
		code: '27112750',
		label: 'Herramienta compresora a batería',
	},
	{
		code: '27112800',
		label: 'Conexiones de herramientas y accesorios',
	},
	{
		code: '27112802',
		label: 'Hojas de sierra',

		palabrasSimilares: 'Discos cortadores',
	},
	{
		code: '27112803',
		label: 'Fresadoras con mango',

		palabrasSimilares: 'Brocas de fresado, Matrices',
	},
	{
		code: '27112804',
		label: 'Troqueles o punzones de estampar',
	},
	{
		code: '27112805',
		label: 'Troqueles de roscado',
	},
	{
		code: '27112806',
		label: 'Machos de roscado',

		palabrasSimilares: 'Machuelos',
	},
	{
		code: '27112807',
		label: 'Cuñas',
	},
	{
		code: '27112808',
		label: 'Anillos metálicos',

		palabrasSimilares: 'Sujetadores de collar para brocas y troqueles',
	},
	{
		code: '27112809',
		label: 'Portaherramientas',
	},
	{
		code: '27112810',
		label: 'Equipos para reparar roscas',
	},
	{
		code: '27112811',
		label: 'Árboles',

		palabrasSimilares: 'Mandril industrial',
	},
	{
		code: '27112812',
		label: 'Avellanadores',
	},
	{
		code: '27112813',
		label: 'Vara de extensión',
	},
	{
		code: '27112814',
		label: 'Brocas de destornillador',
	},
	{
		code: '27112815',
		label: 'Brocas para poner tuercas',
	},
	{
		code: '27112818',
		label: 'Sombreretes o revestimientos de prensas de tornillo',
	},
	{
		code: '27112819',
		label: 'Cuchillas de corte para encuadernación',
	},
	{
		code: '27112820',
		label: 'Troqueles de herramienta engarzadora de lengüetas',
	},
	{
		code: '27112821',
		label: 'Brocas de buriladora',
	},
	{
		code: '27112822',
		label: 'Adaptadores de cubo',
	},
	{
		code: '27112823',
		label: 'Cadenas de corte',
	},
	{
		code: '27112824',
		label: 'Portabrocas',
	},
	{
		code: '27112825',
		label: 'Juegos de plantillas de herramienta',

		palabrasSimilares: 'Plantilla ajustable de medición',
	},
	{
		code: '27112826',
		label: 'Caladora',
	},
	{
		code: '27112827',
		label: 'Dispositivos para imantar y viceversa',

		palabrasSimilares: 'Imantador para herramientas',
	},
	{
		code: '27112828',
		label: 'Enchufes de impacto',

		palabrasSimilares: 'Casquillos de impacto',
	},
	{
		code: '27112829',
		label: 'Anexos de llave de impacto y sus accesorios',

		palabrasSimilares: 'Llaves de impacto, acoplamientos y accesorios',
	},
	{
		code: '27112830',
		label: 'Accesorios y suministros de destornillador',

		palabrasSimilares: 'Desarmador, suministros y accesorios',
	},
	{
		code: '27112831',
		label: 'Accesorios y suministros de llave',

		palabrasSimilares: 'Llave inglesa, suministros y accesorios',
	},
	{
		code: '27112832',
		label: 'Anexos t accesorios de enchufe',
	},
	{
		code: '27112833',
		label: 'Estuche de brocas',
	},
	{
		code: '27112834',
		label: 'Estuche de roscador manual de tubos',
	},
	{
		code: '27112835',
		label: 'Escariador de máquina',
	},
	{
		code: '27112836',
		label: 'Juego de brocas de destornillador',
	},
	{
		code: '27112837',
		label: 'Escariador manual',
	},
	{
		code: '27112838',
		label: 'Disco de corte',
	},
	{
		code: '27112839',
		label: 'Broca de cincel',
	},
	{
		code: '27112840',
		label: 'Broca guía',
	},
	{
		code: '27112841',
		label: 'Broca manual para metal',
	},
	{
		code: '27112842',
		label: 'Broca manual para madera',
	},
	{
		code: '27112843',
		label: 'Broca manual para piedra',
	},
	{
		code: '27112844',
		label: 'Placa de lapeado',
	},
	{
		code: '27112845',
		label: 'Juego de brocas',
	},
	{
		code: '27112846',
		label: 'Adaptador de pistola de calor',
	},
	{
		code: '27112847',
		label: 'Estuche de troqueles',
	},
	{
		code: '27112900',
		label: 'Herramientas dispensadoras',
	},
	{
		code: '27112901',
		label: 'Pistolas de grasa',
	},
	{
		code: '27112902',
		label: 'Chimeneas industriales',

		palabrasSimilares: 'Fogones',
	},
	{
		code: '27112903',
		label: 'Rociador manual',
	},
	{
		code: '27112904',
		label: 'Pistola de resina',

		palabrasSimilares: 'Pistolete de resina',
	},
	{
		code: '27112905',
		label: 'Lata de aceite',

		palabrasSimilares: 'Bote de aceite',
	},
	{
		code: '27112906',
		label: 'Pistolas de calafateado',

		palabrasSimilares: 'Pistolas para impermeabilizar',
	},
	{
		code: '27112907',
		label: 'Separadores de difusión',
	},
	{
		code: '27112908',
		label: 'Pistola de aceite',

		palabrasSimilares: 'Pistolete de aceite',
	},
	{
		code: '27112909',
		label: 'Trapero para techos',
	},
	{
		code: '27112910',
		label: 'Almacenamiento portátil para desperdicio de aceite',

		palabrasSimilares: 'Reposito portátil para desperdicio de aceite',
	},
	{
		code: '27112911',
		label: 'Copa de aceite',
	},
	{
		code: '27112912',
		label: 'Cambiador de aceite',

		palabrasSimilares: 'Dosificador de aceite',
	},
	{
		code: '27112913',
		label: 'Lubricador de aceite',
	},
	{
		code: '27112914',
		label: 'Lubricador de grasa',
	},
	{
		code: '27112915',
		label: 'Dispensador de gas natural',

		palabrasSimilares: 'Dosificador de gas',
	},
	{
		code: '27112916',
		label: 'Dispensador de combustible líquido o bomba de gasolina',

		palabrasSimilares: 'Dosificador de combustible líquido o bomba de',
	},
	{
		code: '27113000',
		label: 'Cepillos',
	},
	{
		code: '27113001',
		label: 'Cepillos de aruñar',

		palabrasSimilares: 'Cepillo de alambre',
	},
	{
		code: '27113002',
		label: 'Cepillos de tubo',

		palabrasSimilares: 'Escobilla de tubo',
	},
	{
		code: '27113003',
		label: 'Cepillos de aplicar',

		palabrasSimilares: 'Cepillo para impermeabilizar',
	},
	{
		code: '27113004',
		label: 'Cepillos de extensor',

		palabrasSimilares: 'Rodillo para impermeabilizar',
	},
	{
		code: '27113005',
		label: 'Cepillo de techado',

		palabrasSimilares: 'Escoba cepillo',
	},
	{
		code: '27113100',
		label: 'Herramientas de arrastre',
	},
	{
		code: '27113101',
		label: 'Cinta guía',
	},
	{
		code: '27113102',
		label: 'Tirador de fusible',

		palabrasSimilares: 'Extractor de fusibles',
	},
	{
		code: '27113103',
		label: 'Buril',

		palabrasSimilares: 'Cuchilla de corte',
	},
	{
		code: '27113104',
		label: 'Extractores de estacas',

		palabrasSimilares: 'Removedor de estaca',
	},
	{
		code: '27113105',
		label: 'Extensor de resortes',
	},
	{
		code: '27113200',
		label: 'Juegos de herramientas',
	},
	{
		code: '27113201',
		label: 'Conjuntos generales de herramientas',
	},
	{
		code: '27113202',
		label: 'Kit de herramientas para ajustar rodamiento',
	},
	{
		code: '27113203',
		label: 'Kit de herramienta para computadores',
	},
	{
		code: '27113204',
		label: 'Kits de electricista',
	},
	{
		code: '27113300',
		label: 'Herramientas manuales de precisión',
	},
	{
		code: '27113301',
		label: 'Resortes de retorno a presión',
	},
	{
		code: '27121500',
		label: 'Prensas hidráulicas',
	},
	{
		code: '27121501',
		label: 'Suspensiones a presión retornables',
	},
	{
		code: '27121502',
		label: 'Bastidores de prensa hidráulica',
	},
	{
		code: '27121503',
		label: 'Columnas de prensa hidráulica',
	},
	{
		code: '27121504',
		label: 'Prensa hidráulica industrial',
	},
	{
		code: '27121600',
		label: 'Pistones y cilindros hidráulicos',
	},
	{
		code: '27121601',
		label: 'Pistones de cilindro',
	},
	{
		code: '27121602',
		label: 'Cilindros hidráulicos',
	},
	{
		code: '27121603',
		label: 'Vástagos de pistón de cilindro hidráulico',
	},
	{
		code: '27121604',
		label: 'Kits de reparación de cilindro hidráulico o sus componentes',
	},
	{
		code: '27121605',
		label: 'Cuerpos de cilindro hidráulico',
	},
	{
		code: '27121606',
		label: 'Soportes de montaje para cilindros hidráulicos',
	},
	{
		code: '27121700',
		label: 'Accesorios de tubería y manga hidráulica',
	},
	{
		code: '27121701',
		label: 'Conectores hidráulicos rápidos',

		palabrasSimilares: 'Coples hidráulicos rápidos',
	},
	{
		code: '27121702',
		label: 'Injertos o injertos dobles hidráulicos',

		palabrasSimilares: 'Injertos dobles hidráulicos',
	},
	{
		code: '27121703',
		label: 'Férulas',

		palabrasSimilares: 'Ferrules',
	},
	{
		code: '27121704',
		label: 'Uniones hidráulicas',

		palabrasSimilares: 'Acoples hidráulicos',
	},
	{
		code: '27121705',
		label: 'Codos hidráulicos o de compresión',

		palabrasSimilares: 'Codos hidráulicos de alta compresión',
	},
	{
		code: '27121706',
		label: 'Tuercas de férula',
	},
	{
		code: '27121707',
		label: 'Conectores de pliegue',

		palabrasSimilares: 'Conectores de cripado',
	},
	{
		code: '27121800',
		label: 'Herramientas hidráulicas',
	},
	{
		code: '27121801',
		label: 'Levantadores de cubierta de manhole',
	},
	{
		code: '27121802',
		label: 'Acumuladores hidráulicos',
	},
	{
		code: '27121803',
		label: 'Almejas hidráulicas',
	},
	{
		code: '27121804',
		label: 'Cizallas hidráulicas',
	},
	{
		code: '27121805',
		label: 'Extractor hidráulico',
	},
	{
		code: '27121806',
		label: 'Doblador de tubos hidráulico',
	},
	{
		code: '27121807',
		label: 'Halador hidráulico',

		palabrasSimilares: 'Extractor hidráulico',
	},
	{
		code: '27121808',
		label: 'Cortador de cadenas hidráulico',
	},
	{
		code: '27121809',
		label: 'Herramienta hidráulica para romper tuercas',

		palabrasSimilares: 'Herramienta hidráulica de corte de tuerca',
	},
	{
		code: '27121810',
		label: 'Herramienta engastadora hidráulica manual',

		palabrasSimilares: 'Herramienta hidráulica de crimpado manual',
	},
	{
		code: '27121811',
		label: 'Accesorio de herramienta engastadora hidráulica',

		palabrasSimilares: 'Accesorio de herramienta crimpado hidráulica',
	},
	{
		code: '27121812',
		label: 'Remoto hidráulico',

		palabrasSimilares: 'Mando a distancia hidráulico',
	},
	{
		code: '27131500',
		label: 'Herramientas neumáticas',
	},
	{
		code: '27131501',
		label: 'Llaves de impacto neumático',

		palabrasSimilares: 'Pistola neumática de impacto',
	},
	{
		code: '27131502',
		label: 'Pistola de aire comprimido',
	},
	{
		code: '27131504',
		label: 'Martillo neumático',

		palabrasSimilares: 'Martillo cincelador neumático',
	},
	{
		code: '27131505',
		label: 'Taladro neumático',

		palabrasSimilares: 'Taladro de impacto neumático',
	},
	{
		code: '27131506',
		label: 'Clavadora de clavos neumática',

		palabrasSimilares: 'Clavadora neumática',
	},
	{
		code: '27131507',
		label: 'Máquinas de lijado neumáticas',
	},
	{
		code: '27131508',
		label: 'Motores neumáticos',

		palabrasSimilares: 'Motores de aire comprimido',
	},
	{
		code: '27131509',
		label: 'Acumuladores neumáticos',

		palabrasSimilares: 'Acumulador de aire comprimido',
	},
	{
		code: '27131510',
		label: 'Depósitos de acumulador',
	},
	{
		code: '27131511',
		label: 'Rectificadoras neumáticas',
	},
	{
		code: '27131512',
		label: 'Destornillador neumático',
	},
	{
		code: '27131513',
		label: 'Lima neumática',
	},
	{
		code: '27131514',
		label: 'Alicates neumáticos',

		palabrasSimilares: 'Alicates neumático de corte',
	},
	{
		code: '27131515',
		label: 'Llave neumática de no – impacto',

		palabrasSimilares: 'Llave de impacto',
	},
	{
		code: '27131516',
		label: 'Lápiz grabador neumático',
	},
	{
		code: '27131517',
		label: 'Grapadora neumática industrial',
	},
	{
		code: '27131518',
		label: 'Herramienta neumática para remover vidrios de autos',

		palabrasSimilares: 'Pistola neumática para remover o desprender parabrisas',
	},
	{
		code: '27131519',
		label: 'Punzón neumático o de aire',
	},
	{
		code: '27131520',
		label: 'Cortador neumático de varillas',
	},
	{
		code: '27131521',
		label: 'Ribeteador neumático',
	},
	{
		code: '27131522',
		label: 'Lija neumática miniatura',
	},
	{
		code: '27131523',
		label: 'Martillo neumático',
	},
	{
		code: '27131524',
		label: 'Avellanadora neumática',

		palabrasSimilares: 'Maquina avellanadora de tubo neumático',
	},
	{
		code: '27131525',
		label: 'Cizallas neumáticas',
	},
	{
		code: '27131526',
		label: 'Prensa neumática',
	},
	{
		code: '27131527',
		label: 'Juego de herramientas neumáticas',
	},
	{
		code: '27131528',
		label: 'Sacador de puntillas neumático',
	},
	{
		code: '27131600',
		label: 'Accesorios de aire y conectores',
	},
	{
		code: '27131601',
		label: 'Múltiples de aire',
	},
	{
		code: '27131603',
		label: 'Reguladores de aire',

		palabrasSimilares: 'Reguladores de aire comprimido',
	},
	{
		code: '27131604',
		label: 'Lubricadores neumáticos',
	},
	{
		code: '27131605',
		label: 'Cortina de aire',
	},
	{
		code: '27131608',
		label: 'Conos o almohadillas de vacío',
	},
	{
		code: '27131609',
		label: 'Silenciadores neumáticos',
	},
	{
		code: '27131610',
		label: 'Kits de reparación del engrasador o el regulador',
	},
	{
		code: '27131613',
		label: 'Acoplamiento neumático',
	},
	{
		code: '27131614',
		label: 'Adaptadores neumáticos',
	},
	{
		code: '27131615',
		label: 'Generador neumático de vacío',
	},
	{
		code: '27131700',
		label: 'Cilindros neumáticos y componentes',
	},
	{
		code: '27131701',
		label: 'Cilindros neumáticos',
	},
	{
		code: '27131702',
		label: 'Accesorios de vástago de cilindro neumático',
	},
	{
		code: '27131703',
		label: 'Pistones de cilindro neumático',
	},
	{
		code: '27131704',
		label: 'Vástagos de pistón de cilindro neumático',
	},
	{
		code: '27131705',
		label: 'Cuerpo de cilindro neumático',
	},
	{
		code: '27131706',
		label: 'Capacetes de cilindro neumático',
	},
	{
		code: '27131707',
		label: 'Kits de reparación de cilindro neumático o sus componentes',
	},
	{
		code: '27131708',
		label: 'Soportes de montaje para cilindros neumáticos',
	},
	{
		code: '27131709',
		label: 'Anillos amortiguadores de cilindro neumático',
	},
	{
		code: '27141000',
		label: 'Herramientas de carrocería',
	},
	{
		code: '27141001',
		label: 'Herramientas de recorte o moldeo',
	},
	{
		code: '27141100',
		label: 'Herramientas de suspensión',
	},
	{
		code: '27141101',
		label: 'Extractor del volante',
	},
	{
		code: '30101500',
		label: 'Ángulos',
	},
	{
		code: '30101501',
		label: 'Ángulos de aleación ferrosa',
	},
	{
		code: '30101502',
		label: 'Ángulos de aleación no ferrosa',
	},
	{
		code: '30101503',
		label: 'Ángulos de hierro',
	},
	{
		code: '30101504',
		label: 'Ángulos de acero',
	},
	{
		code: '30101505',
		label: 'Ángulos de acero inoxidable',
	},
	{
		code: '30101506',
		label: 'Ángulos de aluminio',
	},
	{
		code: '30101507',
		label: 'Ángulos de magnesio',
	},
	{
		code: '30101508',
		label: 'Ángulos de titanio',
	},
	{
		code: '30101509',
		label: 'Ángulos de cobre',
	},
	{
		code: '30101510',
		label: 'Ángulos de latón',
	},
	{
		code: '30101511',
		label: 'Ángulos de bronce',
	},
	{
		code: '30101512',
		label: 'Ángulos de zinc',
	},
	{
		code: '30101513',
		label: 'Ángulos de estaño',
	},
	{
		code: '30101514',
		label: 'Ángulos de plomo',
	},
	{
		code: '30101515',
		label: 'Ángulos de plástico',
	},
	{
		code: '30101516',
		label: 'Ángulos de caucho',
	},
	{
		code: '30101517',
		label: 'Ángulos de metales preciosos',
	},
	{
		code: '30101700',
		label: 'Vigas',
	},
	{
		code: '30101701',
		label: 'Vigas de aleación ferrosa',
	},
	{
		code: '30101702',
		label: 'Vigas de aleación no ferrosa',
	},
	{
		code: '30101703',
		label: 'Vigas de hierro',
	},
	{
		code: '30101704',
		label: 'Vigas de acero',
	},
	{
		code: '30101705',
		label: 'Vigas de acero inoxidable',
	},
	{
		code: '30101706',
		label: 'Vigas de aluminio',
	},
	{
		code: '30101707',
		label: 'Vigas de magnesio',
	},
	{
		code: '30101708',
		label: 'Vigas de titanio',
	},
	{
		code: '30101709',
		label: 'Vigas de cobre',
	},
	{
		code: '30101710',
		label: 'Vigas de latón',
	},
	{
		code: '30101711',
		label: 'Vigas de bronce',
	},
	{
		code: '30101712',
		label: 'Vigas de zinc',
	},
	{
		code: '30101713',
		label: 'Vigas de estaño',
	},
	{
		code: '30101714',
		label: 'Vigas de plomo',
	},
	{
		code: '30101715',
		label: 'Vigas de plástico',
	},
	{
		code: '30101716',
		label: 'Vigas de caucho',
	},
	{
		code: '30101717',
		label: 'Vigas de concreto',
	},
	{
		code: '30101718',
		label: 'Vigas de metales preciosos',
	},
	{
		code: '30101719',
		label: 'Vigas de haydita',
	},
	{
		code: '30101720',
		label: 'Vigas de triplex',
	},
	{
		code: '30101800',
		label: 'Conductos',
	},
	{
		code: '30101801',
		label: 'Conductos de aleación ferrosa',
	},
	{
		code: '30101802',
		label: 'Conductos de aleación no ferrosa',
	},
	{
		code: '30101803',
		label: 'Conductos de hierro',
	},
	{
		code: '30101804',
		label: 'Conductos de acero',
	},
	{
		code: '30101805',
		label: 'Conductos de acero inoxidable',
	},
	{
		code: '30101806',
		label: 'Conductos de aluminio',
	},
	{
		code: '30101807',
		label: 'Conductos de magnesio',
	},
	{
		code: '30101808',
		label: 'Conductos de titanio',
	},
	{
		code: '30101809',
		label: 'Conductos de cobre',
	},
	{
		code: '30101810',
		label: 'Conductos de latón',
	},
	{
		code: '30101811',
		label: 'Conductos de bronce',
	},
	{
		code: '30101812',
		label: 'Conductos de zinc',
	},
	{
		code: '30101813',
		label: 'Conductos de estaño',
	},
	{
		code: '30101814',
		label: 'Conductos de plomo',
	},
	{
		code: '30101815',
		label: 'Conductos de plástico',
	},
	{
		code: '30101816',
		label: 'Conductos de caucho',
	},
	{
		code: '30101817',
		label: 'Conductos de metales preciosos',
	},
	{
		code: '30101818',
		label: 'Conductos de fibra de vidrio',
	},
	{
		code: '30102000',
		label: 'Hoja',
	},
	{
		code: '30102001',
		label: 'Lámina de aleación ferrosa',
	},
	{
		code: '30102002',
		label: 'Lámina de aleación no ferrosa',
	},
	{
		code: '30102003',
		label: 'Lámina de hierro',
	},
	{
		code: '30102004',
		label: 'Lámina de acero',
	},
	{
		code: '30102005',
		label: 'Lámina de acero inoxidable',
	},
	{
		code: '30102006',
		label: 'Lámina de aluminio',
	},
	{
		code: '30102007',
		label: 'Lámina de magnesio',
	},
	{
		code: '30102008',
		label: 'Lámina de titanio',
	},
	{
		code: '30102009',
		label: 'Lámina de cobre',
	},
	{
		code: '30102010',
		label: 'Lámina de latón',
	},
	{
		code: '30102011',
		label: 'Lámina de bronce',
	},
	{
		code: '30102012',
		label: 'Lámina de zinc',
	},
	{
		code: '30102013',
		label: 'Lámina de estaño',
	},
	{
		code: '30102014',
		label: 'Lámina de plomo',
	},
	{
		code: '30102015',
		label: 'Lámina de plástico',
	},
	{
		code: '30102016',
		label: 'Lámina de tántalo',
	},
	{
		code: '30102200',
		label: 'Plancha',
	},
	{
		code: '30102201',
		label: 'Placa de aleación ferrosa',
	},
	{
		code: '30102202',
		label: 'Placa de aleación no ferrosa',
	},
	{
		code: '30102203',
		label: 'Placa de hierro',
	},
	{
		code: '30102204',
		label: 'Placa de acero',
	},
	{
		code: '30102205',
		label: 'Placa de acero inoxidable',
	},
	{
		code: '30102206',
		label: 'Placa de aluminio',
	},
	{
		code: '30102207',
		label: 'Placa de magnesio',

		palabrasSimilares: 'Placa de aleación de magnesio',
	},
	{
		code: '30102208',
		label: 'Placa de titanio',
	},
	{
		code: '30102209',
		label: 'Placa de cobre',
	},
	{
		code: '30102210',
		label: 'Placa de latón',
	},
	{
		code: '30102211',
		label: 'Placa de bronce',
	},
	{
		code: '30102212',
		label: 'Placa de zinc',

		palabrasSimilares: 'Plancha de zinc para revestimiento',
	},
	{
		code: '30102213',
		label: 'Placa de estaño',
	},
	{
		code: '30102214',
		label: 'Placa de plomo',

		palabrasSimilares: 'Plancha de plomo',
	},
	{
		code: '30102215',
		label: 'Placa de plástico',
	},
	{
		code: '30102216',
		label: 'Placa de caucho',
	},
	{
		code: '30102217',
		label: 'Placa de concreto',
	},
	{
		code: '30102218',
		label: 'Placa de metales preciosos',
	},
	{
		code: '30102220',
		label: 'Placa de níquel',
	},
	{
		code: '30102221',
		label: 'Placa de fibra',
	},
	{
		code: '30102222',
		label: 'Placa de viruta',
	},
	{
		code: '30102223',
		label: 'Placa de enchape de madera',
	},
	{
		code: '30102224',
		label: 'Placa de tablero de papel o madera (coreboard)',
	},
	{
		code: '30102225',
		label: 'Placa de corcho',
	},
	{
		code: '30102226',
		label: 'Placa de butilo',
	},
	{
		code: '30102227',
		label: 'Placa de tántalo',
	},
	{
		code: '30102300',
		label: 'Perfiles',
	},
	{
		code: '30102301',
		label: 'Perfiles de aleación ferrosa',
	},
	{
		code: '30102302',
		label: 'Perfiles de aleación no ferrosa',
	},
	{
		code: '30102303',
		label: 'Perfiles de hierro',
	},
	{
		code: '30102304',
		label: 'Perfiles de acero',
	},
	{
		code: '30102305',
		label: 'Perfiles de acero inoxidable',
	},
	{
		code: '30102306',
		label: 'Perfiles de aluminio',
	},
	{
		code: '30102307',
		label: 'Perfiles de magnesio',

		palabrasSimilares: 'Perfiles de aleación de magnesio',
	},
	{
		code: '30102308',
		label: 'Perfiles de titanio',
	},
	{
		code: '30102309',
		label: 'Perfiles de cobre',
	},
	{
		code: '30102310',
		label: 'Perfiles de latón',
	},
	{
		code: '30102311',
		label: 'Perfiles de bronce',
	},
	{
		code: '30102312',
		label: 'Perfiles de zinc',
	},
	{
		code: '30102313',
		label: 'Perfiles de estaño',
	},
	{
		code: '30102314',
		label: 'Perfiles de plomo',
	},
	{
		code: '30102315',
		label: 'Perfiles de plástico',
	},
	{
		code: '30102316',
		label: 'Perfiles de caucho',
	},
	{
		code: '30102400',
		label: 'Varillas',
	},
	{
		code: '30102401',
		label: 'Varillas de aleación ferrosa',
	},
	{
		code: '30102402',
		label: 'Varillas de aleación no ferrosa',
	},
	{
		code: '30102403',
		label: 'Varillas de hierro',
	},
	{
		code: '30102404',
		label: 'Varillas de acero',
	},
	{
		code: '30102405',
		label: 'Varillas de acero inoxidable',
	},
	{
		code: '30102406',
		label: 'Varillas de aluminio',
	},
	{
		code: '30102407',
		label: 'Varillas de magnesio',
	},
	{
		code: '30102408',
		label: 'Varillas de titanio',
	},
	{
		code: '30102409',
		label: 'Varillas de cobre',
	},
	{
		code: '30102410',
		label: 'Varillas de latón',
	},
	{
		code: '30102411',
		label: 'Varillas de bronce',
	},
	{
		code: '30102412',
		label: 'Varillas de zinc',
	},
	{
		code: '30102413',
		label: 'Varillas de estaño',
	},
	{
		code: '30102414',
		label: 'Varillas de plomo',
	},
	{
		code: '30102415',
		label: 'Varillas de plástico',
	},
	{
		code: '30102416',
		label: 'Varillas de caucho',
	},
	{
		code: '30102417',
		label: 'Varillas de níquel',
	},
	{
		code: '30102800',
		label: 'Pilotaje',
	},
	{
		code: '30102801',
		label: 'Pilares de aluminio',
	},
	{
		code: '30102802',
		label: 'Pilares de concreto',
	},
	{
		code: '30102803',
		label: 'Pilares de acero',
	},
	{
		code: '30102804',
		label: 'Pilares de madera',
	},
	{
		code: '30102900',
		label: 'Pilares',
	},
	{
		code: '30102901',
		label: 'Postes de cemento o concreto',
	},
	{
		code: '30102903',
		label: 'Postes de metal',
	},
	{
		code: '30102904',
		label: 'Postes de madera',
	},
	{
		code: '30102905',
		label: 'Postes de plástico',
	},
	{
		code: '30102906',
		label: 'Postes de fibra de vidrio',
	},
	{
		code: '30102907',
		label: 'Postes de madera tratada',
	},
	{
		code: '30103100',
		label: 'Rieles',
	},
	{
		code: '30103101',
		label: 'Rieles de acero',
	},
	{
		code: '30103102',
		label: 'Rieles de aluminio',
	},
	{
		code: '30103103',
		label: 'Rieles de metal',
	},
	{
		code: '30103104',
		label: 'Rieles de madera',
	},
	{
		code: '30103200',
		label: 'Enrejado',
	},
	{
		code: '30103201',
		label: 'Rejilla de acero',
	},
	{
		code: '30103202',
		label: 'Rejilla de acero inoxidable',
	},
	{
		code: '30103203',
		label: 'Rejilla de aluminio',
	},
	{
		code: '30103204',
		label: 'Rejilla de fibra de vidrio',
	},
	{
		code: '30103205',
		label: 'Rejilla de hierro',
	},
	{
		code: '30103206',
		label: 'Rejilla de plástico',
	},
	{
		code: '30103207',
		label: 'Rejilla laminada',
	},
	{
		code: '30103208',
		label: 'Rejilla de madera',
	},
	{
		code: '30103500',
		label: 'Alma de panal',

		palabrasSimilares: 'Núcleo de panal',
	},
	{
		code: '30103501',
		label: 'Núcleo de panal de aluminio',
	},
	{
		code: '30103502',
		label: 'Núcleo de panal de magnesio',
	},
	{
		code: '30103503',
		label: 'Núcleo de panal de espuma',
	},
	{
		code: '30103504',
		label: 'Núcleo de panal de plástico',
	},
	{
		code: '30103505',
		label: 'Núcleo de panal de madera',
	},
	{
		code: '30103506',
		label: 'Núcleo de panal de metal ferroso',
	},
	{
		code: '30103507',
		label: 'Núcleo de panal de bronce',
	},
	{
		code: '30103508',
		label: 'Núcleo de panal de cobre',
	},
	{
		code: '30103509',
		label: 'Núcleo de panal de acero',
	},
	{
		code: '30103510',
		label: 'Núcleo de panal de plomo',
	},
	{
		code: '30103511',
		label: 'Núcleo de panal de zinc',
	},
	{
		code: '30103512',
		label: 'Núcleo de panal de titanio',
	},
	{
		code: '30103513',
		label: 'Núcleo de panal de latón',
	},
	{
		code: '30103514',
		label: 'Núcleo de panal de metal no ferroso',
	},
	{
		code: '30103515',
		label: 'Núcleo de panal de metales preciosos',
	},
	{
		code: '30103600',
		label: 'Productos estructurales',

		palabrasSimilares: 'Solera',
	},
	{
		code: '30103601',
		label: 'Vigas de madera',
	},
	{
		code: '30103602',
		label: 'Vigas compuestas de madera',
	},
	{
		code: '30103603',
		label: 'Madera para marcos',
	},
	{
		code: '30103604',
		label: 'Revestimiento o láminas de madera',
	},
	{
		code: '30103605',
		label: 'Tablones de madera',
	},
	{
		code: '30103606',
		label: 'Armazones de madera',

		palabrasSimilares: 'Estructuras de madera',
	},
	{
		code: '30103607',
		label: 'Viguetas de madera',
	},
	{
		code: '30103608',
		label: 'Postes de madera o postes telefónicos',
	},
	{
		code: '30103609',
		label: 'Tabla de tabique de ventilación',
	},
	{
		code: '30103610',
		label: 'Bloque de soporte en minas',
	},
	{
		code: '30103611',
		label: 'Esteras de troncos',
	},
	{
		code: '30103612',
		label: 'Vínculos de ferrocarril',
	},
	{
		code: '30103613',
		label: 'Elemento prefabricado de hyadita',
	},
	{
		code: '30103614',
		label: 'Vigueta de placa de acero',
	},
	{
		code: '30103615',
		label: 'Viga de placa',

		palabrasSimilares: 'Vigas planas',
	},
	{
		code: '30103616',
		label: 'Marco compuesto',

		palabrasSimilares: 'Marco rígido compuesto',
	},
	{
		code: '30103617',
		label: 'Marco de madera',
	},
	{
		code: '30103618',
		label: 'Marco de acero',
	},
	{
		code: '30103619',
		label: 'Elemento de concreto prefabricado',

		palabrasSimilares: 'Elemento prefabricado de hormigón',
	},
	{
		code: '30103620',
		label: 'Bloque de soporte en minas tratado',
	},
	{
		code: '30103621',
		label: 'Vínculo de ferrocarril no tratado',
	},
	{
		code: '30103622',
		label: 'Troncos cuadrados',

		palabrasSimilares: 'Madera cuadrada',
	},
	{
		code: '30103623',
		label: 'Barra o malla de refuerzo',
	},
	{
		code: '30103700',
		label: 'Trenza',
	},
	{
		code: '30103701',
		label: 'Trenza de acero inoxidable',
	},
	{
		code: '30103800',
		label: 'Fibras y filamentos mecánicos',
	},
	{
		code: '30103801',
		label: 'Fibra de acero',
	},
	{
		code: '30103802',
		label: 'Fibra de estaño',
	},
	{
		code: '30103803',
		label: 'Fibra de aluminio',
	},
	{
		code: '30103804',
		label: 'Fibra de aleación',
	},
	{
		code: '30103900',
		label: 'Ejes',
	},
	{
		code: '30103901',
		label: 'Socavón con cabeza',
	},
	{
		code: '30111500',
		label: 'Concreto y morteros',

		palabrasSimilares: 'Cemento y morteros',
	},
	{
		code: '30111501',
		label: 'Concreto aireado',

		palabrasSimilares: 'Cemento aireado',
	},
	{
		code: '30111502',
		label: 'Concreto conductor',

		palabrasSimilares: 'Cemento conductivo',
	},
	{
		code: '30111503',
		label: 'Concreto aislante',

		palabrasSimilares: 'Cemento aislante',
	},
	{
		code: '30111504',
		label: 'Morteros',
	},
	{
		code: '30111505',
		label: 'Concreto premezclado',

		palabrasSimilares: 'Cemento premezclado',
	},
	{
		code: '30111506',
		label: 'Lechada',

		palabrasSimilares: 'Mezcla de cemento',
	},
	{
		code: '30111507',
		label: 'Concreto curado en suelo',

		palabrasSimilares: 'Suelo cemento',
	},
	{
		code: '30111508',
		label: 'Concreto permeable al agua',

		palabrasSimilares: 'Cemento permeable',
	},
	{
		code: '30111509',
		label: 'Concreto basado en asfalto',

		palabrasSimilares: 'Hormigón asfáltico',
	},
	{
		code: '30111600',
		label: 'Cemento y cal',
	},
	{
		code: '30111601',
		label: 'Cemento',
	},
	{
		code: '30111602',
		label: 'Cal clorada',
	},
	{
		code: '30111603',
		label: 'Cal hidráulica',
	},
	{
		code: '30111604',
		label: 'Cal apagada',
	},
	{
		code: '30111605',
		label: 'Cal magra',
	},
	{
		code: '30111607',
		label: 'Cal viva',
	},
	{
		code: '30111700',
		label: 'Yesos',
	},
	{
		code: '30111701',
		label: 'Enlucido de yeso',

		palabrasSimilares: 'Yeso fino para enlucir',
	},
	{
		code: '30111800',
		label: 'Agregados',
	},
	{
		code: '30111801',
		label: 'Agregado natural',
	},
	{
		code: '30111802',
		label: 'Agregado artificial',

		palabrasSimilares: 'Agregados manufacturados',
	},
	{
		code: '30111900',
		label: 'Elementos para reforzar concreto',
	},
	{
		code: '30111901',
		label: 'Fibra para reforzar concreto',
	},
	{
		code: '30111902',
		label: 'Junta de expansión de construcción',

		palabrasSimilares: 'Juntas de dilatación de construcción',
	},
	{
		code: '30111903',
		label: 'Lámina de malla de alambre',

		palabrasSimilares: 'Armex',
	},
	{
		code: '30121500',
		label: 'Derivados bituminosos',

		palabrasSimilares: 'Materiales bituminosos',
	},
	{
		code: '30121501',
		label: 'Alquitrán de carbón hulla',
	},
	{
		code: '30121503',
		label: 'Creosota',
	},
	{
		code: '30121504',
		label: 'Bitumen',

		palabrasSimilares: 'Betún',
	},
	{
		code: '30121600',
		label: 'Asfaltos',
	},
	{
		code: '30121601',
		label: 'Asfalto',
	},
	{
		code: '30121602',
		label: 'Brea',
	},
	{
		code: '30121603',
		label: 'Gilsonita',
	},
	{
		code: '30121604',
		label: 'Residuos de alquitrán o petróleo',
	},
	{
		code: '30121605',
		label: 'Marcos de pozo con tapas del registro',

		palabrasSimilares: 'Aro y tapa para registro',
	},
	{
		code: '30121700',
		label: 'Materiales de construcción de caminos y carrileras',
	},
	{
		code: '30121701',
		label: 'Geomalla',
	},
	{
		code: '30121702',
		label: 'Geotextil',

		palabrasSimilares: 'Geotejido',
	},
	{
		code: '30121703',
		label: 'Riel de puente',

		palabrasSimilares: 'Viga carril de puente',
	},
	{
		code: '30121704',
		label: 'Losa de concreto',

		palabrasSimilares: 'Forjado',
	},
	{
		code: '30121705',
		label: 'Losa de pavimento',
	},
	{
		code: '30121706',
		label: 'Borde de concreto',

		palabrasSimilares: 'Bordillos de hormigón',
	},
	{
		code: '30121707',
		label: 'Tablero de protección contra el ruido',
	},
	{
		code: '30121708',
		label: 'Baldosa de ladrillo',

		palabrasSimilares: 'Loseta tipo ladrillo',
	},
	{
		code: '30121709',
		label: 'Roca triturada sin grado',
	},
	{
		code: '30121710',
		label: 'Piedra de pavimento',

		palabrasSimilares: 'Piedra pavimentada',
	},
	{
		code: '30121711',
		label: 'Borde de piedra natural',
	},
	{
		code: '30121712',
		label: 'Cubierta de poste',

		palabrasSimilares: 'Cubierta para poste',
	},
	{
		code: '30121713',
		label: 'Tapa de manhole',

		palabrasSimilares: 'Tapa de alcantarilla, Tapa para registro',
	},
	{
		code: '30121714',
		label: 'Caja de manhole',

		palabrasSimilares: 'Caja de registro',
	},
	{
		code: '30121715',
		label: 'Junta de expansión de puente',

		palabrasSimilares: 'Juntas de dilatación de puentes',
	},
	{
		code: '30121716',
		label: 'Montura de asiento de puente',
	},
	{
		code: '30121717',
		label: 'Baranda',

		palabrasSimilares: 'Barandilla',
	},
	{
		code: '30121718',
		label: 'Cerca de seguridad y red cara la caída de rocas',
	},
	{
		code: '30121719',
		label: 'Separador de seguridad de calle',

		palabrasSimilares: 'Barreras de contención',
	},
	{
		code: '30121800',
		label: 'Materiales de arquitectura paisajística',
	},
	{
		code: '30121801',
		label: 'Rejilla para árbol',
	},
	{
		code: '30121802',
		label: 'Varilla de protección de árbol',

		palabrasSimilares: 'Protector de árboles de varilla',
	},
	{
		code: '30121803',
		label: 'Prado artificial',

		palabrasSimilares: 'Césped artificial',
	},
	{
		code: '30121900',
		label: 'Materiales para estabilización y refuerzo de suelos',
	},
	{
		code: '30121901',
		label: 'Estabilizador de suelo',
	},
	{
		code: '30131500',
		label: 'Bloques',
	},
	{
		code: '30131502',
		label: 'Bloques de concreto',

		palabrasSimilares: 'Blocks de concreto',
	},
	{
		code: '30131503',
		label: 'Bloques de piedra',
	},
	{
		code: '30131504',
		label: 'Bloques de cerámica',
	},
	{
		code: '30131505',
		label: 'Bloques de haydita',
	},
	{
		code: '30131506',
		label: 'Losa de roca natural',
	},
	{
		code: '30131507',
		label: 'Bloque de concreto liviano',
	},
	{
		code: '30131508',
		label: 'Bloque de vidrio',
	},
	{
		code: '30131509',
		label: 'Bloque a prueba de sonido',

		palabrasSimilares: 'Bloque de absorción acústica de sonido',
	},
	{
		code: '30131510',
		label: 'Bloque de concreto para revestimiento',

		palabrasSimilares: 'Bloque de hormigón para revestimiento',
	},
	{
		code: '30131511',
		label: 'Unidad de concreto de escollera',

		palabrasSimilares: 'Bloques de concreto para malecón',
	},
	{
		code: '30131512',
		label:
			'Bloque de concreto aireado de peso liviano esterilizado en autoclave',

		palabrasSimilares: 'Bloque de hormigón celular aireado autoclavado',
	},
	{
		code: '30131513',
		label: 'Bloque de caucho',
	},
	{
		code: '30131514',
		label: 'Bloque de culvert construido de concreto reforzado',

		palabrasSimilares: 'Bloque de alcantarilla construido de hormigón armado',
	},
	{
		code: '30131515',
		label: 'Bloque de madera',
	},
	{
		code: '30131516',
		label: 'Bloque braille',
	},
	{
		code: '30131517',
		label: 'Bloque de adobe',
	},
	{
		code: '30131600',
		label: 'Ladrillos',
	},
	{
		code: '30131602',
		label: 'Ladrillos de cerámica',
	},
	{
		code: '30131603',
		label: 'Ladrillos de concreto',

		palabrasSimilares: 'Tabicón, Tabique',
	},
	{
		code: '30131604',
		label: 'Ladrillos de piedra',
	},
	{
		code: '30131605',
		label: 'Ladrillo sílico – calcáreo',
	},
	{
		code: '30131606',
		label: 'Ladrillo de loess',
	},
	{
		code: '30131607',
		label: 'Ladrillo de arcilla',
	},
	{
		code: '30131608',
		label: 'Ladrillo vitrificado con sal',
	},
	{
		code: '30131609',
		label: 'Ladrillo manufacturado',
	},
	{
		code: '30131610',
		label: 'Ladrillo antifuego',

		palabrasSimilares: 'Ladrillo corta fuego',
	},
	{
		code: '30131700',
		label: 'Azulejos y baldosas',
	},
	{
		code: '30131702',
		label: 'Losas o baldosas de piedra',
	},
	{
		code: '30131703',
		label: 'Losas o baldosas de concreto',
	},
	{
		code: '30131704',
		label: 'Losas o baldosas de cerámica',
	},
	{
		code: '30131705',
		label: 'Lápidas',
	},
	{
		code: '30131706',
		label: 'Losas o baldosas de metal',
	},
	{
		code: '30141500',
		label: 'Aislamiento térmico',
	},
	{
		code: '30141501',
		label: 'Burletes',
	},
	{
		code: '30141503',
		label: 'Aislamiento de espuma',
	},
	{
		code: '30141505',
		label: 'Revestimiento de aislamiento térmico',
	},
	{
		code: '30141508',
		label: 'Aislamiento de fibra',

		palabrasSimilares: 'Aislamiento de fibra de vidrio',
	},
	{
		code: '30141510',
		label: 'Barrido de puerta',

		palabrasSimilares: 'Sello de barrido de puerta',
	},
	{
		code: '30141511',
		label: 'Película de ventana',
	},
	{
		code: '30141512',
		label: 'Kits de aislamiento térmico',
	},
	{
		code: '30141513',
		label: 'Ladrillos aislantes térmicos',
	},
	{
		code: '30141514',
		label: 'Aislación de poliestireno expandido eps',
	},
	{
		code: '30141515',
		label: 'Aislación de poliestireno extruido xps',
	},
	{
		code: '30141516',
		label: 'Aislación de lana mineral',
	},
	{
		code: '30141600',
		label: 'Aislamiento especial',
	},
	{
		code: '30141601',
		label: 'Aislación acústica',
	},
	{
		code: '30141603',
		label: 'Escudos térmicas',
	},
	{
		code: '30141604',
		label: 'Aislación por recubrimiento aerosol',
	},
	{
		code: '30141605',
		label: 'Material a prueba de incendios',

		palabrasSimilares: 'Material de aislamiento resistente al calor',
	},
	{
		code: '30141700',
		label: 'Capas de sellamiento de aislamiento',
	},
	{
		code: '30141701',
		label: 'Capa sellante de tabla de asfalto',
	},
	{
		code: '30141702',
		label: 'Capa sellante plástica',
	},
	{
		code: '30141703',
		label: 'Capa sellante ce caucho',

		palabrasSimilares: 'Capa sellante de caucho',
	},
	{
		code: '30151500',
		label: 'Material para tejados y techos',
	},
	{
		code: '30151501',
		label: 'Techado prearmado',

		palabrasSimilares: 'Techos de rollo',
	},
	{
		code: '30151502',
		label: 'Valles de techo',
	},
	{
		code: '30151503',
		label: 'Textiles de techado',
	},
	{
		code: '30151505',
		label: 'Membranas para techos',
	},
	{
		code: '30151507',
		label: 'Huelgos',
	},
	{
		code: '30151508',
		label: 'Tejas de asfalto',
	},
	{
		code: '30151509',
		label: 'Armazones',

		palabrasSimilares: 'Bloque de soporte de goma',
	},
	{
		code: '30151510',
		label: 'Tejados de pizarra',
	},
	{
		code: '30151511',
		label: 'Tejas de concreto',
	},
	{
		code: '30151512',
		label: 'Tejas de ladrillo',
	},
	{
		code: '30151513',
		label: 'Tejas de cerámica',
	},
	{
		code: '30151514',
		label: 'Tejas metálicas',
	},
	{
		code: '30151515',
		label: 'Tejas de madera',
	},
	{
		code: '30151600',
		label: 'Accesorios para tejados',
	},
	{
		code: '30151601',
		label: 'Plafones de tejado',
	},
	{
		code: '30151602',
		label: 'Planchas de escurrimiento',
	},
	{
		code: '30151603',
		label: 'Parada de grava',
	},
	{
		code: '30151605',
		label: 'Drenajes de tejados',
	},
	{
		code: '30151607',
		label: 'Claraboyas de ventilación',

		palabrasSimilares: 'Tragaluces de ventilación',
	},
	{
		code: '30151608',
		label: 'Sofitos',
	},
	{
		code: '30151609',
		label: 'Bordes de tejado',

		palabrasSimilares: 'Bordillos de techo',
	},
	{
		code: '30151610',
		label: 'Escarificadores de tejado',
	},
	{
		code: '30151700',
		label: 'Canalones de tejado y accesorios',
	},
	{
		code: '30151701',
		label: 'Boquillas de bajantes',
	},
	{
		code: '30151702',
		label: 'Dinteles para goteo',

		palabrasSimilares: 'Tapones de goteo',
	},
	{
		code: '30151703',
		label: 'Canaletas',
	},
	{
		code: '30151704',
		label: 'Bloques contra salpicaduras',

		palabrasSimilares: 'Remates contra el agua',
	},
	{
		code: '30151800',
		label: 'Materiales para revestimiento de paredes y exterior',
	},
	{
		code: '30151801',
		label: 'Postigos',

		palabrasSimilares: 'Contraventana',
	},
	{
		code: '30151802',
		label: 'Recubrimiento',
	},
	{
		code: '30151803',
		label: 'Empalmes de revestimiento de paredes',
	},
	{
		code: '30151805',
		label: 'Estucado',
	},
	{
		code: '30151806',
		label: 'Muros de bloque de vidrio',
	},
	{
		code: '30151807',
		label: 'Materiales para terminados exteriores',
	},
	{
		code: '30151808',
		label: 'Molde de ladrillo',
	},
	{
		code: '30151900',
		label: 'Materiales y productos para acabados',
	},
	{
		code: '30151901',
		label: 'Toldos',

		palabrasSimilares: 'Cubierta de lona',
	},
	{
		code: '30151902',
		label: 'Marquesinas',
	},
	{
		code: '30152000',
		label: 'Cercado',
	},
	{
		code: '30152001',
		label: 'Cercado de metal',
	},
	{
		code: '30152002',
		label: 'Cercado de madera',
	},
	{
		code: '30152003',
		label: 'Vallado de fibrocemento',
	},
	{
		code: '30152100',
		label: 'Superficie',
	},
	{
		code: '30152101',
		label: 'Acero de perdigones',
	},
	{
		code: '30161500',
		label: 'Materiales para acabados de paredes',
	},
	{
		code: '30161501',
		label: 'Fibra prensada',

		palabrasSimilares: 'Fibra de madera prensada',
	},
	{
		code: '30161502',
		label: 'Papel de colgadura',

		palabrasSimilares: 'Papel tapiz',
	},
	{
		code: '30161503',
		label: 'Drywall',

		palabrasSimilares: 'Tablaroca',
	},
	{
		code: '30161504',
		label: 'Protectores de esquinas',
	},
	{
		code: '30161505',
		label: 'Paneles o empanelado',
	},
	{
		code: '30161508',
		label: 'Rodillo de papel de colgadura',

		palabrasSimilares: 'Rodillo de papel tapiz',
	},
	{
		code: '30161509',
		label: 'Tabla de yeso',
	},
	{
		code: '30161510',
		label: 'Cobertura de pared plástica',

		palabrasSimilares: 'Revestimiento de pared de plástico',
	},
	{
		code: '30161511',
		label: 'Tela de pared',

		palabrasSimilares: 'Tejidos para pared',
	},
	{
		code: '30161600',
		label: 'Materiales para techos',
	},
	{
		code: '30161601',
		label: 'Baldosines acústicos para techos',

		palabrasSimilares: 'Azulejos de techo acústicos',
	},
	{
		code: '30161602',
		label: 'Paneles para techos',

		palabrasSimilares: 'Lamina panel para techo',
	},
	{
		code: '30161603',
		label: 'Encofrados',
	},
	{
		code: '30161604',
		label: 'Sistemas de cielo raso',
	},
	{
		code: '30161700',
		label: 'Suelos',
	},
	{
		code: '30161701',
		label: 'Alfombrado',
	},
	{
		code: '30161702',
		label: 'Pisos de madera',
	},
	{
		code: '30161703',
		label: 'Linóleo',
	},
	{
		code: '30161705',
		label: 'Pisos de caucho',
	},
	{
		code: '30161706',
		label: 'Pisos de baldosa o piedra',
	},
	{
		code: '30161707',
		label: 'Pisos de vinilo',
	},
	{
		code: '30161708',
		label: 'Alfombras de nudos',
	},
	{
		code: '30161709',
		label: 'Alfombras de penachos',
	},
	{
		code: '30161710',
		label: 'Pisos laminados',
	},
	{
		code: '30161711',
		label: 'Alfombras para exteriores',
	},
	{
		code: '30161712',
		label: 'Perfiles laminados para suelos',
	},
	{
		code: '30161713',
		label: 'Almohadilla para alfombras',
	},
	{
		code: '30161714',
		label: 'Suelo de corcho',
	},
	{
		code: '30161715',
		label: 'Enrejados de madera',
	},
	{
		code: '30161717',
		label: 'Pisos para accesos',
	},
	{
		code: '30161718',
		label: 'Piso de acero antideslizante',
	},
	{
		code: '30161719',
		label: 'Fieltro para alfombras',
	},
	{
		code: '30161720',
		label: 'Panel de piso',
	},
	{
		code: '30161721',
		label: 'Umbral de piso',
	},
	{
		code: '30161800',
		label: 'Ebanistería',
	},
	{
		code: '30161801',
		label: 'Armarios',
	},
	{
		code: '30161803',
		label: 'Armarios domésticos',
	},
	{
		code: '30161804',
		label: 'Armarios de escuela',
	},
	{
		code: '30161805',
		label: 'Armarios de laboratorio',
	},
	{
		code: '30161806',
		label: 'Closet',
	},
	{
		code: '30161807',
		label: 'Cajón',
	},
	{
		code: '30161808',
		label: 'Ensamble para deslizamiento del cajón',
	},
	{
		code: '30161809',
		label: 'Almohadillas contra cierre brusco',
	},
	{
		code: '30161810',
		label: 'Gabinete tri – view',
	},
	{
		code: '30161900',
		label: 'Moldeado y carpintería mecánica',
	},
	{
		code: '30161901',
		label: 'Puertas de rejilla',
	},
	{
		code: '30161902',
		label: 'Columnas',
	},
	{
		code: '30161903',
		label: 'Paneles de madera',
	},
	{
		code: '30161904',
		label: 'Cornisas',
	},
	{
		code: '30161905',
		label: 'Cercos de puertas',
	},
	{
		code: '30161906',
		label: 'Moldes',
	},
	{
		code: '30161908',
		label: 'Piezas de escaleras',
	},
	{
		code: '30162000',
		label: 'Molduras y carpintería',
	},
	{
		code: '30162001',
		label: 'Laminado de alta presión',
	},
	{
		code: '30162002',
		label: 'Laminado especial',
	},
	{
		code: '30162003',
		label: 'Laminado de metal',
	},
	{
		code: '30162004',
		label: 'Laminado de reborde',
	},
	{
		code: '30162005',
		label: 'Lámina térmica rígida o rtf',
	},
	{
		code: '30162100',
		label: 'Laminados interiores',
	},
	{
		code: '30162101',
		label: 'Escaleras de acero',
	},
	{
		code: '30162102',
		label: 'Escaleras de madera',
	},
	{
		code: '30162103',
		label: 'Escaleras de concreto',
	},
	{
		code: '30162104',
		label: 'Arista del escalón',

		palabrasSimilares: 'Escalón',
	},
	{
		code: '30162200',
		label: 'Mostradores',
	},
	{
		code: '30162201',
		label: 'Mesón laminado',

		palabrasSimilares: 'Mesón de cocina laminado',
	},
	{
		code: '30162202',
		label: 'Mesón de mármol cultivado',

		palabrasSimilares: 'Mesón de cocina de mármol cultivado',
	},
	{
		code: '30162203',
		label: 'Mesón de superficie sólida',

		palabrasSimilares: 'Masón de cocina de superficie sólida',
	},
	{
		code: '30162204',
		label: 'Mesón de granito',

		palabrasSimilares: 'Masón de cocina de granito',
	},
	{
		code: '30162300',
		label: 'Accesorios para gabinetes',
	},
	{
		code: '30162301',
		label: 'Piezas o ensamble de “lazy susan”',
	},
	{
		code: '30162302',
		label: 'Engaste de rejilla o cajón para condimentos',
	},
	{
		code: '30162303',
		label: 'Piezas o ensamble de caneca de la basura de sacar',

		palabrasSimilares: 'Piezas para contenedor de basura',
	},
	{
		code: '30162304',
		label: 'Piezas o ensamble de despensa',

		palabrasSimilares: 'Accesorios para muebles de despensa',
	},
	{
		code: '30162305',
		label: 'Piezas o ensamble de bandeja de inclinar',

		palabrasSimilares: 'Piezas para cajón de inclinación hacia afuera',
	},
	{
		code: '30162306',
		label: 'Piezas o ensamble de montura de puerta o toalla deslizable',
	},
	{
		code: '30162307',
		label: 'Piezas o ensamble de ancheta de sacar o voltear',
	},
	{
		code: '30162308',
		label: 'Tapa o ensamble de caja para el pan',
	},
	{
		code: '30162309',
		label: 'Estante para copas',
	},
	{
		code: '30162310',
		label: 'Bandera rodante',

		palabrasSimilares: 'Cajón deslizable para bajo mesa',
	},
	{
		code: '30162311',
		label: 'Recipiente de almacenamiento de deslizar o su ensamble',
	},
	{
		code: '30162400',
		label: 'Muros divisorios',
	},
	{
		code: '30162401',
		label: 'Pared plegable',
	},
	{
		code: '30162402',
		label: 'Pantalla o biombo o cubículo',
	},
	{
		code: '30162403',
		label: 'Sistema de pared interior',
	},
	{
		code: '30162404',
		label: 'Pared de cuarto frío',

		palabrasSimilares: 'Pared de almacenamiento en frio',
	},
	{
		code: '30171500',
		label: 'Puertas',
	},
	{
		code: '30171501',
		label: 'Puertas de cristal',
	},
	{
		code: '30171502',
		label: 'Puertas de pantalla',
	},
	{
		code: '30171503',
		label: 'Puertas rodantes',
	},
	{
		code: '30171504',
		label: 'Puertas de madera',
	},
	{
		code: '30171505',
		label: 'Puertas de metal',
	},
	{
		code: '30171506',
		label: 'Contrapuertas',
	},
	{
		code: '30171507',
		label: 'Marcos de puertas o quicios',
	},
	{
		code: '30171508',
		label: 'Puertas corrediza empotrable',
	},
	{
		code: '30171509',
		label: 'Puertas giratorias',
	},
	{
		code: '30171510',
		label: 'Puertas automáticas',
	},
	{
		code: '30171511',
		label: 'Puerta de vaivén',
	},
	{
		code: '30171512',
		label: 'Abridores de puertas',

		palabrasSimilares: 'Puerta con brazo hidráulico',
	},
	{
		code: '30171513',
		label: 'Rodapiés',

		palabrasSimilares: 'Placa de puerta o protector',
	},
	{
		code: '30171514',
		label: 'Cerradores de puertas',

		palabrasSimilares: 'Brazo hidráulico',
	},
	{
		code: '30171515',
		label: 'Puerta de inspección',
	},
	{
		code: '30171516',
		label: 'Pantalla para puertas',
	},
	{
		code: '30171517',
		label: 'Puertas a prueba de sonido',

		palabrasSimilares: 'Puertas aislantes de sonido',
	},
	{
		code: '30171518',
		label: 'Puertas imperfectas',
	},
	{
		code: '30171519',
		label: 'Puertas anti – explosión',
	},
	{
		code: '30171520',
		label: 'Loop de puerta y ventana',
	},
	{
		code: '30171521',
		label: 'Puerta hermética contra agua',

		palabrasSimilares: 'Puerta contra inundación',
	},
	{
		code: '30171522',
		label: 'Puerta hermética sin circulación de aire',
	},
	{
		code: '30171523',
		label: 'Puerta de escape de incendio',
	},
	{
		code: '30171524',
		label: 'Puerta de protección contra rayos',
	},
	{
		code: '30171525',
		label: 'Puerta de presión',
	},
	{
		code: '30171526',
		label: 'Puerta plegable',
	},
	{
		code: '30171600',
		label: 'Ventanas',
	},
	{
		code: '30171604',
		label: 'Ventanas de guillotina',
	},
	{
		code: '30171605',
		label: 'Ventanas de apertura horizontal',
	},
	{
		code: '30171606',
		label: 'Ventanas giratorias',
	},
	{
		code: '30171607',
		label: 'Ventanas con hoja de desplazamiento horizontal',
	},
	{
		code: '30171608',
		label: 'Ventanas basculantes o de montante',

		palabrasSimilares: 'Ventanas de inclinación o travesaño',
	},
	{
		code: '30171609',
		label: 'Ventanas fijas',
	},
	{
		code: '30171610',
		label: 'Miradores',

		palabrasSimilares: 'Ventanas de bahía',
	},
	{
		code: '30171611',
		label: 'Ventana de miradores',

		palabrasSimilares: 'Ventanas de arco',
	},
	{
		code: '30171612',
		label: 'Ventanas salientes',

		palabrasSimilares: 'Ventanas proyectadas',
	},
	{
		code: '30171613',
		label: 'Paredes de vidrio',

		palabrasSimilares: 'Cancelería, Canceles',
	},
	{
		code: '30171614',
		label: 'Pantallas de ventana',

		palabrasSimilares: 'Película protectora para cristales de ventana',
	},
	{
		code: '30171615',
		label: 'Ventanas francesas o puerta – ventanas',
	},
	{
		code: '30171700',
		label: 'Productos de cristal',
	},
	{
		code: '30171701',
		label: 'Adoquines',
	},
	{
		code: '30171703',
		label: 'Vidrio biselado',
	},
	{
		code: '30171704',
		label: 'Vidrio con plomo',
	},
	{
		code: '30171705',
		label: 'Vidrio laminado',
	},
	{
		code: '30171706',
		label: 'Vidrio templado',
	},
	{
		code: '30171707',
		label: 'Vidrio de seguridad',
	},
	{
		code: '30171708',
		label: 'Vidrio flotado',
	},
	{
		code: '30171709',
		label: 'Vidrio con alambre incrustado',

		palabrasSimilares: 'Vidrio armado',
	},
	{
		code: '30171710',
		label: 'Vidrio de aislación',
	},
	{
		code: '30171711',
		label: 'Vidrio corrugado',
	},
	{
		code: '30171712',
		label: 'Vitrales',
	},
	{
		code: '30171800',
		label: 'Claraboyas',

		palabrasSimilares: 'Tragaluces',
	},
	{
		code: '30171801',
		label: 'Claraboyas fijas',

		palabrasSimilares: 'Tragaluz fijo',
	},
	{
		code: '30171802',
		label: 'Claraboyas con ventilación',

		palabrasSimilares: 'Tragaluz con ventilación',
	},
	{
		code: '30171803',
		label: 'Claraboyas de tubo',

		palabrasSimilares: 'Tragaluz tubular',
	},
	{
		code: '30171804',
		label: 'Tapa de humo',
	},
	{
		code: '30171900',
		label: 'Marcos de ventanas',
	},
	{
		code: '30171901',
		label: 'Marcos para ventanas de guillotina',
	},
	{
		code: '30171902',
		label: 'Marcos para ventanas de apertura horizontal',
	},
	{
		code: '30171903',
		label: 'Marcos para ventanas giratorias',
	},
	{
		code: '30171904',
		label: 'Marcos para ventanas con hojas de desplazamiento horizontal',
	},
	{
		code: '30171905',
		label: 'Marcos para ventanas basculantes o de montante',

		palabrasSimilares: 'Marcos de ventana de inclinación o travesaño',
	},
	{
		code: '30171906',
		label: 'Marcos para ventanas fijas',
	},
	{
		code: '30171907',
		label: 'Alfeizar o antepecho',

		palabrasSimilares: 'Travesaño de ventana',
	},
	{
		code: '30171908',
		label: 'Arco de ventana',
	},
	{
		code: '30172000',
		label: 'Puertas',
	},
	{
		code: '30172001',
		label: 'Puerta de barra sencilla',

		palabrasSimilares: 'Puerta de una sola barra',
	},
	{
		code: '30172002',
		label: 'Puerta de doble barra',

		palabrasSimilares: 'Puerta de doble barra',
	},
	{
		code: '30172100',
		label: 'Puertas para garaje y operadores',
	},
	{
		code: '30172101',
		label: 'Puerta de garaje pan',
	},
	{
		code: '30172102',
		label: 'Puerta de garaje en sándwich aislada',

		palabrasSimilares: 'Puerta de garaje de panel sándwich',
	},
	{
		code: '30172103',
		label: 'Puerta de garaje de madera',
	},
	{
		code: '30172104',
		label: 'Puerta de garaje de carroza',

		palabrasSimilares: 'Puerta de garaje de carro',
	},
	{
		code: '30172105',
		label: 'Puerta de garaje de pantalla',
	},
	{
		code: '30172106',
		label: 'Puerta de garaje de de pvc',
	},
	{
		code: '30172107',
		label: 'Puerta de garaje de eslabones de cadena',
	},
	{
		code: '30172108',
		label: 'Puerta de garaje rodante',
	},
	{
		code: '30172109',
		label: 'Componente de puerta de garaje',
	},
	{
		code: '30172110',
		label: 'Accesorio de puerta de garaje',
	},
	{
		code: '30172111',
		label: 'Operador completo de puerta de garaje',
	},
	{
		code: '30172112',
		label: 'Componente de operador de puerta de garaje',
	},
	{
		code: '30172113',
		label: 'Accesorio de operador de puerta de garaje',
	},
	{
		code: '30181500',
		label: 'Porcelana sanitaria',
	},
	{
		code: '30181501',
		label: 'Tinas o bañeras',
	},
	{
		code: '30181502',
		label: 'Bidés',

		palabrasSimilares: 'Bidet',
	},
	{
		code: '30181503',
		label: 'Duchas',

		palabrasSimilares: 'Regadera',
	},
	{
		code: '30181504',
		label: 'Lavamanos',

		palabrasSimilares: 'Lavabo',
	},
	{
		code: '30181505',
		label: 'Inodoros o excusados',

		palabrasSimilares: 'Cuarto de baño',
	},
	{
		code: '30181506',
		label: 'Orinales',
	},
	{
		code: '30181507',
		label: 'Cerramientos para bañeras o duchas',

		palabrasSimilares: 'Bañera',
	},
	{
		code: '30181508',
		label: 'Divisiones de baños',
	},
	{
		code: '30181511',
		label: 'Inodoros o excusados',
	},
	{
		code: '30181514',
		label: 'Tapa de tanque del inodoro',
	},
	{
		code: '30181515',
		label: 'Tanque del inodoro',
	},
	{
		code: '30181516',
		label: 'Sauna',
	},
	{
		code: '30181517',
		label: 'Jacuzzi',
	},
	{
		code: '30181600',
		label: 'Instalaciones residenciales no sanitarias',

		palabrasSimilares: 'Accesorios para cuarto de baño',
	},
	{
		code: '30181601',
		label: 'Jabonera',
	},
	{
		code: '30181602',
		label: 'Toallero',
	},
	{
		code: '30181603',
		label: 'Bizcocho o asiento del inodoro',

		palabrasSimilares: 'Asiento de inodoro',
	},
	{
		code: '30181604',
		label: 'Tapa del bizcocho',

		palabrasSimilares: 'Tapa de inodoro',
	},
	{
		code: '30181605',
		label: 'Desagüe',
	},
	{
		code: '30181606',
		label: 'Ganchos de baño',
	},
	{
		code: '30181607',
		label: 'Cortina de baño o su ensamble',
	},
	{
		code: '30181608',
		label: 'Varilla de ducha',
	},
	{
		code: '30181609',
		label: 'Soporte para frascos de ducha',
	},
	{
		code: '30181610',
		label: 'Soporte de  papel higiénico',
	},
	{
		code: '30181611',
		label: 'Soporte de cepillos de dientes o vaso',
	},
	{
		code: '30181612',
		label: 'Gancho para la máquina afeitadora',
	},
	{
		code: '30181613',
		label: 'Faldón de la tina o del jacuzzi',
	},
	{
		code: '30181614',
		label: 'Dispensador de jabón',
	},
	{
		code: '30181700',
		label: 'Grifos',

		palabrasSimilares: 'Llaves',
	},
	{
		code: '30181701',
		label: 'Llave de registro',

		palabrasSimilares: 'Fregadero, Llave de paso, Tarj',
	},
	{
		code: '30181800',
		label: 'Cabezas, chorros y partes y accesorios de grifos y duchas',

		palabrasSimilares:
			'Grifería y cabezales de ducha, chorros y piezas y accesorios',
	},
	{
		code: '30181801',
		label: 'Pomo de ducha',

		palabrasSimilares: 'Regadera',
	},
	{
		code: '30181802',
		label: 'Aireador de la ducha',

		palabrasSimilares: 'Filtro aireador para regadera',
	},
	{
		code: '30181803',
		label: 'Ducha teléfono',

		palabrasSimilares: 'Regadera de extensión manual',
	},
	{
		code: '30181804',
		label: 'Grifo',

		palabrasSimilares: 'Llaves para regadera',
	},
	{
		code: '30181805',
		label: 'Ducha combinada fija y teléfono',

		palabrasSimilares: 'Sistema de regadera mixta',
	},
	{
		code: '30181806',
		label: 'Chorros',

		palabrasSimilares: 'Bañera de hidromasaje',
	},
	{
		code: '30181807',
		label: 'Boquilla',

		palabrasSimilares: 'Tubo de bañera con desviador',
	},
	{
		code: '30181808',
		label: 'Válvulas ocultas',

		palabrasSimilares: 'Válvulas para regaderas',
	},
	{
		code: '30181809',
		label: 'Kit de reparación de duchas teléfono',

		palabrasSimilares: 'Juego de reparación para ducha manual',
	},
	{
		code: '30181810',
		label: 'Ajuste de grifo',
	},
	{
		code: '30181811',
		label: 'Kit de reparación de grifo',
	},
	{
		code: '30181812',
		label: 'Rociador de cuerpo',

		palabrasSimilares: 'Regaderas corporales',
	},
	{
		code: '30191500',
		label: 'Escaleras y andamios',
	},
	{
		code: '30191501',
		label: 'Escaleras',
	},
	{
		code: '30191502',
		label: 'Andamios',
	},
	{
		code: '30191505',
		label: 'Taburete escalonado',

		palabrasSimilares: 'Banquillo',
	},
	{
		code: '30191506',
		label: 'Escalón de plataforma',

		palabrasSimilares: 'Escaleras de plataforma',
	},
	{
		code: '30191507',
		label: 'Escalera de combinación',

		palabrasSimilares: 'Escalera combinada extensible',
	},
	{
		code: '30191508',
		label: 'Escalera de techo',

		palabrasSimilares: 'Escaleras plegables para techo',
	},
	{
		code: '30191509',
		label: 'Escalera de escape de incendios',

		palabrasSimilares: 'Escalera de escape contra incendios',
	},
	{
		code: '30191510',
		label: 'Escalera de extinguidor de incendios',

		palabrasSimilares: 'Escalera para sofocar incendios',
	},
	{
		code: '30191600',
		label: 'Escaleras y accesorios de andamiaje',
	},
	{
		code: '30191601',
		label: 'Barandas',

		palabrasSimilares: 'Barandilla',
	},
	{
		code: '30191602',
		label: 'Estabilizadores de andamiaje',
	},
	{
		code: '30191603',
		label: 'Piso del andamiaje',
	},
	{
		code: '30191604',
		label: 'Varillas cruzadas del andamio',
	},
	{
		code: '30191605',
		label: 'Marco del andamio',
	},
	{
		code: '30191606',
		label: 'Escalera del andamio',
	},
	{
		code: '30191607',
		label: 'Contrapeso del andamio',
	},
	{
		code: '30191608',
		label: 'Ménsula del andamio',
	},
	{
		code: '30191609',
		label: 'Plataforma del andamio',
	},
	{
		code: '30191610',
		label: 'Pescante del andamio',
	},
	{
		code: '30191611',
		label: 'Dispositivo de fijación del andamio',
	},
	{
		code: '30191612',
		label: 'Cuerda de alambre del andamio',
	},
	{
		code: '30191613',
		label: 'Plataforma de la escalera',
	},
	{
		code: '30191614',
		label: 'Bandeja de la escalera',
	},
	{
		code: '30191615',
		label: 'Descanso de la escalera',
	},
	{
		code: '30191616',
		label: 'Red de seguridad de construcción',
	},
	{
		code: '30191617',
		label: 'Cerramiento de seguridad de la construcción',
	},
	{
		code: '30191700',
		label: 'Cobertizos y remolques de construcción',
	},
	{
		code: '30191701',
		label: 'Caseta de construcción',
	},
	{
		code: '30191702',
		label: 'Tráiler de construcción',
	},
	{
		code: '30191800',
		label:
			'Equipos y materiales para construcciones temporales y apoyo al mantenimiento',
	},
	{
		code: '30191801',
		label: 'Formaleta estructural',
	},
	{
		code: '30191802',
		label: 'Accesorio de formaleta estructural',
	},
	{
		code: '30191803',
		label: 'Espaciador estructural',
	},
	{
		code: '30241500',
		label: 'Componentes de consolidación de estructuras portátiles',
	},
	{
		code: '30241501',
		label: 'Conector de marcos de tubos',
	},
	{
		code: '30241502',
		label: 'Placa de anclaje',
	},
	{
		code: '30241503',
		label: 'Anillo de soporte',
	},
	{
		code: '30241504',
		label: 'Placa base',
	},
	{
		code: '30241505',
		label: 'Cúpula',
	},
	{
		code: '30241506',
		label: 'Campana funicular',
	},
	{
		code: '30241507',
		label: 'Aguilón',
	},
	{
		code: '30241508',
		label: 'Poste grúa y accesorios',
	},
	{
		code: '30241509',
		label: 'Sección de mástil y poste rey',
	},
	{
		code: '30241510',
		label: 'Poste lateral',
	},
	{
		code: '30241511',
		label: 'Estaca o clavija',
	},
	{
		code: '30241512',
		label: 'Travesaño',
	},
	{
		code: '30241513',
		label: 'Marco en a de acero',
	},
	{
		code: '30241514',
		label: 'Sujetador estructural',
	},
	{
		code: '30241515',
		label: 'Herramienta de alineación estructural',
	},
	{
		code: '30241600',
		label: 'Componentes estructurales de tribunas, gradas y escalera',
	},
	{
		code: '30241601',
		label: 'Tarima y accesorios de la tribuna',
	},
	{
		code: '30241602',
		label: 'Escalón',
	},
	{
		code: '30241603',
		label: 'Durmiente',
	},
	{
		code: '30241604',
		label: 'Elevador de escalones',
	},
	{
		code: '30241700',
		label:
			'Componentes soporte y cobertura de tiendas, carpas y estructuras de membrana',
	},
	{
		code: '30241701',
		label: 'Viga o riel o tubería de marco',
	},
	{
		code: '30241702',
		label: 'Sección de lienzo de estructura portátil',
	},
	{
		code: '30251500',
		label: 'Estructuras de soporte de techos bajo tierra',
	},
	{
		code: '30251501',
		label: 'Perno y armazón de combinación de techo',
	},
	{
		code: '30251502',
		label: 'Placa de techo',
	},
	{
		code: '30251503',
		label: 'Cápsula o cartucho de resina adhesiva',
	},
	{
		code: '30251504',
		label: 'Celda de expansión',
	},
	{
		code: '30251505',
		label: 'Estera de nervadura',
	},
	{
		code: '30261500',
		label: 'Barras de latón',
	},
	{
		code: '30261501',
		label: 'Barra de latón enrollado en caliente c 360',
	},
	{
		code: '30261502',
		label: 'Barra de latón forjado en caliente c 360',
	},
	{
		code: '30261503',
		label: 'Barra de latón enrollado en caliente c 464',
	},
	{
		code: '30261504',
		label: 'Barra de latón forjado en caliente c 464',
	},
	{
		code: '30261505',
		label: 'Barra de latón estirado en frío c 464',
	},
	{
		code: '30261600',
		label: 'Láminas de latón',
	},
	{
		code: '30261601',
		label: 'Lámina de latón enrollado en caliente c 360',
	},
	{
		code: '30261602',
		label: 'Lámina de latón forjado en caliente c 464',
	},
	{
		code: '30261603',
		label: 'Lámina de latón enrollado en frío c 464',
	},
	{
		code: '30261700',
		label: 'Lingotes, bandas, palanquillas y alambres de latón',
	},
	{
		code: '30261701',
		label: 'Alambre de latón',
	},
	{
		code: '30261702',
		label: 'Banda de latón',
	},
	{
		code: '30261703',
		label: 'Palanquilla de latón',
	},
	{
		code: '30261704',
		label: 'Lingote de latón',
	},
	{
		code: '30261800',
		label: 'Barras y láminas de magnesio',
	},
	{
		code: '30261801',
		label: 'Barra de magnesio extruida',
	},
	{
		code: '30261802',
		label: 'Lámina de magnesio enrollada en frío',
	},
	{
		code: '30261900',
		label: 'Lingotes, bandas, palanquillas y alambres de magnesio',
	},
	{
		code: '30261901',
		label: 'Alambre de magnesio',
	},
	{
		code: '30261902',
		label: 'Banda de magnesio',
	},
	{
		code: '30261903',
		label: 'Palanquilla de magnesio',
	},
	{
		code: '30261904',
		label: 'Lingote de magnesio',
	},
	{
		code: '30262000',
		label: 'Barras y láminas de titanio',
	},
	{
		code: '30262001',
		label: 'Barra de titanio fundida',
	},
	{
		code: '30262002',
		label: 'Lámina de titanio enrollada en caliente',
	},
	{
		code: '30262100',
		label: 'Lingotes, bandas, palanquillas y alambres de titanio',
	},
	{
		code: '30262101',
		label: 'Alambre de titanio',
	},
	{
		code: '30262102',
		label: 'Banda de titanio',
	},
	{
		code: '30262103',
		label: 'Palanquilla de titanio',
	},
	{
		code: '30262104',
		label: 'Lingote de titanio',
	},
	{
		code: '30262200',
		label: 'Barras de cobre',
	},
	{
		code: '30262201',
		label: 'Barra de cobre estirada en frío',
	},
	{
		code: '30262202',
		label: 'Barra de cobre enrollada en caliente',
	},
	{
		code: '30262300',
		label: 'Láminas de cobre',
	},
	{
		code: '30262301',
		label: 'Lámina de cobre enrollada en caliente',
	},
	{
		code: '30262302',
		label: 'Lámina de cobre enrollada en frío',
	},
	{
		code: '30262400',
		label: 'Lingotes, bandas, palanquillas y alambres de cobre',
	},
	{
		code: '30262401',
		label: 'Alambre de cobre',
	},
	{
		code: '30262402',
		label: 'Banda de cobre enrollada en caliente',
	},
	{
		code: '30262403',
		label: 'Banda de cobre enrollada en frío',
	},
	{
		code: '30262404',
		label: 'Palanquilla de cobre',
	},
	{
		code: '30262405',
		label: 'Lingote de cobre',
	},
	{
		code: '30262500',
		label: 'Barras de bronce',
	},
	{
		code: '30262501',
		label: 'Barra de bronce fósforo enrollada en caliente',
	},
	{
		code: '30262502',
		label: 'Barra de bronce fósforo enrollada en frío',
	},
	{
		code: '30262503',
		label: 'Barra de bronce aluminio enrollada en caliente',
	},
	{
		code: '30262504',
		label: 'Barra de bronce aluminio estirada en frío',
	},
	{
		code: '30262505',
		label: 'Barra de bronce silicona enrollada en caliente',
	},
	{
		code: '30262506',
		label: 'Barra de bronce silicona estirada en frío',
	},
	{
		code: '30262600',
		label: 'Láminas de bronce',
	},
	{
		code: '30262601',
		label: 'Lámina de bronce fósforo enrollada en caliente',
	},
	{
		code: '30262602',
		label: 'Lámina de bronce fósforo enrollada en frío',
	},
	{
		code: '30262603',
		label: 'Lámina de bronce aluminio enrollada en caliente',
	},
	{
		code: '30262604',
		label: 'Lámina de bronce aluminio estirada en frío',
	},
	{
		code: '30262605',
		label: 'Lámina de bronce silicona enrollada en caliente',
	},
	{
		code: '30262606',
		label: 'Lámina de bronce silicona estirada en frío',
	},
	{
		code: '30262700',
		label: 'Lingotes, bandas, palanquillas y alambres de bronce',
	},
	{
		code: '30262701',
		label: 'Rollo de alambre de bronce',
	},
	{
		code: '30262702',
		label: 'Banda de bronce',
	},
	{
		code: '30262703',
		label: 'Palanquilla de bronce',
	},
	{
		code: '30262704',
		label: 'Lingote de bronce',
	},
	{
		code: '30262800',
		label: 'Barras y láminas de zinc',
	},
	{
		code: '30262801',
		label: 'Barra de zinc enrollada en caliente',
	},
	{
		code: '30262802',
		label: 'Lámina de zinc enrollada en caliente',
	},
	{
		code: '30262900',
		label: 'Lingotes, bandas, palanquillas y alambres de zinc',
	},
	{
		code: '30262901',
		label: 'Alambre de zinc',
	},
	{
		code: '30262902',
		label: 'Banda de zinc',
	},
	{
		code: '30262903',
		label: 'Palanquilla de zinc',
	},
	{
		code: '30262904',
		label: 'Lingote de zinc',
	},
	{
		code: '30263000',
		label: 'Barras de estaño',
	},
	{
		code: '30263001',
		label: 'Barra de estaño estirada en frío',
	},
	{
		code: '30263002',
		label: 'Barra de estaño enrollada en caliente',
	},
	{
		code: '30263100',
		label: 'Láminas de estaño',
	},
	{
		code: '30263101',
		label: 'Lámina de estaño enrollada en caliente',
	},
	{
		code: '30263102',
		label: 'Lámina de estaño enrollada en frío',
	},
	{
		code: '30263200',
		label: 'Lingotes, bandas, palanquillas y alambres de estaño',
	},
	{
		code: '30263201',
		label: 'Alambre de estaño',
	},
	{
		code: '30263202',
		label: 'Banda de estaño',
	},
	{
		code: '30263203',
		label: 'Lingote de estaño',
	},
	{
		code: '30263300',
		label: 'Barras de plomo',
	},
	{
		code: '30263301',
		label: 'Barra de plomo extruido',
	},
	{
		code: '30263302',
		label: 'Barra de plomo enrollada en caliente',
	},
	{
		code: '30263303',
		label: 'Barra de plomo estirada en frío',
	},
	{
		code: '30263400',
		label: 'Láminas de plomo',
	},
	{
		code: '30263401',
		label: 'Lámina de plomo enrollada en caliente',
	},
	{
		code: '30263402',
		label: 'Lámina de plomo enrollada en frío',
	},
	{
		code: '30263500',
		label: 'Lingotes, bandas, palanquillas y alambres de plomo',
	},
	{
		code: '30263501',
		label: 'Alambre de plomo',
	},
	{
		code: '30263502',
		label: 'Banda de plomo',
	},
	{
		code: '30263503',
		label: 'Palanquilla de plomo',
	},
	{
		code: '30263504',
		label: 'Lingote de plomo',
	},
	{
		code: '30263600',
		label: 'Barras de acero al carbón',
	},
	{
		code: '30263601',
		label: 'Barra de acero al carbón serie sae 1000 estirada en frío',
	},
	{
		code: '30263602',
		label: 'Barra de acero al carbón serie sae 1100 estirada en frío',
	},
	{
		code: '30263603',
		label: 'Barra de acero al carbón serie sae 1200 estirada en frío',
	},
	{
		code: '30263604',
		label: 'Barra de acero al carbón serie sae 1500 estirada en frío',
	},
	{
		code: '30263605',
		label: 'Barra de acero al carbón serie sae 1000 enrollada en caliente',
	},
	{
		code: '30263606',
		label: 'Barra de acero al carbón serie sae 1100 enrollada en caliente',
	},
	{
		code: '30263607',
		label: 'Barra de acero al carbón serie sae 1200 enrollada en caliente',
	},
	{
		code: '30263608',
		label: 'Barra de acero al carbón serie sae 1500 enrollada en caliente',
	},
	{
		code: '30263700',
		label: 'Barras de aleación de acero',
	},
	{
		code: '30263701',
		label: 'Barra de aleación de acero  serie sae 4000 estirada en frío',
	},
	{
		code: '30263702',
		label: 'Barra de aleación de acero  serie sae 5000 estirada en frío',
	},
	{
		code: '30263703',
		label: 'Barra de aleación de acero  serie sae 6000 estirada en frío',
	},
	{
		code: '30263704',
		label: 'Barra de aleación de acero  serie sae 8000 estirada en frío',
	},
	{
		code: '30263705',
		label: 'Barra de aleación de acero  serie sae 9000 estirada en frío',
	},
	{
		code: '30263706',
		label: 'Barra de aleación de acero  serie sae 4000 enrollada en caliente',
	},
	{
		code: '30263707',
		label: 'Barra de aleación de acero  serie sae 5000 enrollada en caliente',
	},
	{
		code: '30263708',
		label: 'Barra de aleación de acero  serie sae 6000 enrollada en caliente',
	},
	{
		code: '30263709',
		label: 'Barra de aleación de acero  serie sae 8000 enrollada en caliente',
	},
	{
		code: '30263710',
		label: 'Barra de aleación de acero  serie sae 9000 enrollada en caliente',
	},
	{
		code: '30263800',
		label: 'Barras de acero de herramienta',

		palabrasSimilares: 'Barras de acero para herramientas',
	},
	{
		code: '30263801',
		label: 'Barra de acero de herramienta estirada en frío',
	},
	{
		code: '30263802',
		label: 'Barra de acero de herramienta enrollada en caliente',
	},
	{
		code: '30263900',
		label: 'Barras de acero especial',
	},
	{
		code: '30263901',
		label:
			'Barra de acero de alta resistencia baja aleación hsla enrollada en caliente',

		palabrasSimilares:
			'Barra de acero laminado en caliente de alta resistencia y baja aleación HSLA',
	},
	{
		code: '30264000',
		label: 'Láminas de acero al carbón',

		palabrasSimilares: 'Hojas de acero al carbono',
	},
	{
		code: '30264001',
		label: 'Lámina de acero al carbón serie sae 1000 enrollada en caliente',

		palabrasSimilares: 'Hoja laminada en caliente de acero al carbono SAE 1000',
	},
	{
		code: '30264002',
		label: 'Lámina de acero al carbón serie sae 1100 enrollada en caliente',

		palabrasSimilares: 'Hoja laminada en caliente de acero al carbono SAE 1100',
	},
	{
		code: '30264003',
		label: 'Lámina de acero al carbón serie sae 1200 enrollada en caliente',

		palabrasSimilares: 'Hoja laminada en caliente de acero al carbono SAE 1200',
	},
	{
		code: '30264004',
		label: 'Lámina de acero al carbón serie sae 1500 enrollada en caliente',

		palabrasSimilares: 'Hoja laminada en caliente de acero al carbono SAE 1500',
	},
	{
		code: '30264005',
		label:
			'Lámina de acero al carbón electro galvanizada serie sae 1000 enrollada en caliente',

		palabrasSimilares:
			'Hoja laminada en caliente de acero al carbono SAE 1000 galvanizada',
	},
	{
		code: '30264006',
		label:
			'Lámina de acero al carbón electro galvanizada serie sae 1100 enrollada en caliente',

		palabrasSimilares:
			'Hoja laminada en caliente de acero al carbono SAE 1100 galvanizada',
	},
	{
		code: '30264007',
		label:
			'Lámina de acero al carbón electro galvanizada serie sae 1200 enrollada en caliente',

		palabrasSimilares:
			'Hoja laminada en caliente de acero al carbono SAE 1200 galvanizada',
	},
	{
		code: '30264008',
		label:
			'Lámina de acero al carbón electro galvanizada serie sae 1500 enrollada en caliente',

		palabrasSimilares:
			'Hoja laminada en caliente de acero al carbono SAE 1500 galvanizada',
	},
	{
		code: '30264009',
		label:
			'Lámina de acero al carbón inmersión en caliente galvanizada serie sae 1000 enrollada en caliente',

		palabrasSimilares:
			'Hoja laminada en caliente de acero al carbono SAE 1000 galvanizada en caliente',
	},
	{
		code: '30264010',
		label:
			'Lámina de acero al carbón inmersión en caliente galvanizada serie sae 1100 enrollada en caliente',

		palabrasSimilares:
			'Hoja laminada en caliente de acero al carbono SAE 1100 galvanizada en caliente',
	},
	{
		code: '30264011',
		label:
			'Lámina de acero al carbón inmersión en caliente galvanizada serie sae 1200 enrollada en caliente',

		palabrasSimilares:
			'Hoja laminada en caliente de acero al carbono SAE 1200 galvanizada en caliente',
	},
	{
		code: '30264012',
		label:
			'Lámina de acero al carbón inmersión en caliente galvanizada serie sae 1500 enrollada en caliente',

		palabrasSimilares:
			'Hoja laminada en caliente de acero al carbono SAE 1500 galvanizada en caliente',
	},
	{
		code: '30264013',
		label: 'Lámina de acero al carbón serie sae 1000 enrollada en frío',

		palabrasSimilares: 'Hoja laminada en frío de acero al carbono SAE 1000',
	},
	{
		code: '30264014',
		label: 'Lámina de acero al carbón serie sae 1100 enrollada en frío',

		palabrasSimilares: 'Hoja laminada en frío de acero al carbono SAE 1100',
	},
	{
		code: '30264015',
		label: 'Lámina de acero al carbón serie sae 1200 enrollada en frío',

		palabrasSimilares: 'Hoja laminada en frío de acero al carbono SAE 1200',
	},
	{
		code: '30264016',
		label: 'Lámina de acero al carbón serie sae 1500 enrollada en frío',

		palabrasSimilares: 'Hoja laminada en frío de acero al carbono SAE 1500',
	},
	{
		code: '30264017',
		label:
			'Lámina de acero al carbón electro galvanizada serie sae 1000 enrollada en frío',

		palabrasSimilares:
			'Hoja laminada en frío de acero al carbono SAE 1000 galvanizada',
	},
	{
		code: '30264018',
		label:
			'Lámina de acero al carbón electro galvanizada serie sae 1100 enrollada en frío',

		palabrasSimilares:
			'Hoja laminada en frío de acero al carbono SAE 1100 galvanizada',
	},
	{
		code: '30264019',
		label:
			'Lámina de acero al carbón electro galvanizada serie sae 1200 enrollada en frío',

		palabrasSimilares:
			'Hoja laminada en frío de acero al carbono SAE 1200 galvanizada',
	},
	{
		code: '30264020',
		label:
			'Lámina de acero al carbón electro galvanizada serie sae 1500 enrollada en frío',

		palabrasSimilares:
			'Hoja laminada en frío de acero al carbono SAE 1500 galvanizada',
	},
	{
		code: '30264021',
		label:
			'Lámina de acero al carbón inmersión en caliente galvanizada serie sae 1000 enrollada en frío',

		palabrasSimilares:
			'Hoja laminada en frío de acero al carbono SAE 1000 galvanizada en caliente',
	},
	{
		code: '30264022',
		label:
			'Lámina de acero al carbón inmersión en caliente galvanizada serie sae 1100 enrollada en frío',

		palabrasSimilares:
			'Hoja laminada en frío de acero al carbono SAE 1100 galvanizada en caliente',
	},
	{
		code: '30264023',
		label:
			'Lámina de acero al carbón inmersión en caliente galvanizada serie sae 1200 enrollada en frío',

		palabrasSimilares:
			'Hoja laminada en frío de acero al carbono SAE 1200 galvanizada en caliente',
	},
	{
		code: '30264024',
		label:
			'Lámina de acero al carbón inmersión en caliente galvanizada serie sae 1500 enrollada en frío',

		palabrasSimilares:
			'Hoja laminada en frío de acero al carbono SAE 1500 galvanizada en caliente',
	},
	{
		code: '30264100',
		label: 'Láminas de aleación de acero',

		palabrasSimilares: 'Hojas de aleación de acero',
	},
	{
		code: '30264101',
		label:
			'Lámina de aleación de acero al carbón serie sae 4000 enrollada en caliente',

		palabrasSimilares:
			'Hoja laminada en caliente de aleación de acero SAE 4000',
	},
	{
		code: '30264102',
		label:
			'Lámina de aleación de acero al carbón serie sae 5000 enrollada en caliente',

		palabrasSimilares:
			'Hoja laminada en caliente de aleación de acero SAE 5000',
	},
	{
		code: '30264103',
		label:
			'Lámina de aleación de acero al carbón serie sae 6000 enrollada en caliente',

		palabrasSimilares:
			'Hoja laminada en caliente de aleación de acero SAE 6000',
	},
	{
		code: '30264104',
		label:
			'Lámina de aleación de acero al carbón serie sae 8000 enrollada en caliente',

		palabrasSimilares:
			'Hoja laminada en caliente de aleación de acero SAE 8000',
	},
	{
		code: '30264105',
		label:
			'Lámina de aleación de acero al carbón serie sae 9000 enrollada en caliente',

		palabrasSimilares:
			'Hoja laminada en caliente de aleación de acero SAE 9000',
	},
	{
		code: '30264106',
		label:
			'Lámina de aleación de acero al carbón serie sae 4000 enrollada en frío',

		palabrasSimilares: 'Hoja laminada en frío de aleación de acero SAE 4000',
	},
	{
		code: '30264107',
		label:
			'Lámina de aleación de acero al carbón serie sae 5000 enrollada en frío',

		palabrasSimilares: 'Hoja laminada en frío de aleación de acero SAE 5000',
	},
	{
		code: '30264108',
		label:
			'Lámina de aleación de acero al carbón serie sae 6000 enrollada en frío',

		palabrasSimilares: 'Hoja laminada en frío de aleación de acero SAE 6000',
	},
	{
		code: '30264109',
		label:
			'Lámina de aleación de acero al carbón serie sae 8000 enrollada en frío',

		palabrasSimilares: 'Hoja laminada en frío de aleación de acero SAE 8000',
	},
	{
		code: '30264110',
		label:
			'Lámina de aleación de acero al carbón serie sae 9000 enrollada en frío',

		palabrasSimilares: 'Hoja laminada en frío de aleación de acero SAE 9000',
	},
	{
		code: '30264200',
		label: 'Láminas de acero de herramienta',
	},
	{
		code: '30264201',
		label:
			'Lámina de acero de alta resistencia baja aleación hsla enrollada en caliente',

		palabrasSimilares:
			'Hoja laminada en caliente de alta resistencia de acero de baja aleación HSLA',
	},
	{
		code: '30264202',
		label:
			'Lámina de acero de alta resistencia baja aleación hsla enrollada en frío',

		palabrasSimilares: 'Hoja laminada en frío HSLA de baja resistencia',
	},
	{
		code: '30264203',
		label: 'Lámina de acero aluminizado',

		palabrasSimilares: 'Hoja de acero aluminizado',
	},
	{
		code: '30264204',
		label: 'Lámina de acero corrugada',

		palabrasSimilares: 'Hoja de acero corrugado',
	},
	{
		code: '30264300',
		label: 'Láminas de acero especial',

		palabrasSimilares: 'Hojas de acero especial',
	},
	{
		code: '30264301',
		label: 'Alambre de acero perforado',

		palabrasSimilares: 'Rollo de acero perforado',
	},
	{
		code: '30264302',
		label: 'Alambre de acero embebido',

		palabrasSimilares: 'Rollo de acero en relieve',
	},
	{
		code: '30264303',
		label: 'Alambre de acero aluminizado',

		palabrasSimilares: 'Rollo de acero aluminizado',
	},
	{
		code: '30264304',
		label: 'Alambre de acero inmersión en caliente galvanizado',

		palabrasSimilares: 'Rollo de acero galvanizado en caliente',
	},
	{
		code: '30264305',
		label: 'Alambre de acero electro galvanizado',

		palabrasSimilares: 'Rollo de acero electro galvanizado',
	},
	{
		code: '30264400',
		label: 'Lingotes, bandas, palanquillas y alambres de al carbón',

		palabrasSimilares:
			'Alambre al carbón laminado en caliente sin tratamiento, Alambre de púas, Alambre recocido, Alambrón ,',
	},
	{
		code: '30264401',
		label: 'Alambre de acero al carbón serie sae 1100 enrollada en caliente',

		palabrasSimilares:
			'Rollo laminado en caliente de acero al carbono SAE 1100',
	},
	{
		code: '30264402',
		label: 'Alambre de acero al carbón serie sae 1200 enrollada en caliente',

		palabrasSimilares:
			'Rollo laminado en caliente de acero al carbono SAE 1200',
	},
	{
		code: '30264403',
		label: 'Alambre de acero al carbón serie sae 1500 enrollada en caliente',

		palabrasSimilares:
			'Rollo laminado en caliente de acero al carbono SAE 1500',
	},
	{
		code: '30264404',
		label: 'Banda de acero al carbón serie sae 1100 enrollada en frío',

		palabrasSimilares: 'Rollo laminado en frío de acero al carbono SAE 1100',
	},
	{
		code: '30264405',
		label: 'Banda de acero al carbón serie sae 1200 enrollada en frío',

		palabrasSimilares: 'Rollo laminado en frío de acero al carbono SAE 1200',
	},
	{
		code: '30264406',
		label: 'Banda de acero al carbón serie sae 1500 enrollada en frío',

		palabrasSimilares: 'Rollo laminado en frío de acero al carbono SAE 1500',
	},
	{
		code: '30264407',
		label: 'Banda de acero al carbón serie sae 1100 enrollada en caliente',

		palabrasSimilares:
			'Rollo laminado en caliente de acero al carbono SAE 1100',
	},
	{
		code: '30264408',
		label: 'Banda de acero al carbón serie sae 1200 enrollada en caliente',

		palabrasSimilares:
			'Rollo laminado en caliente de acero al carbono SAE 1200',
	},
	{
		code: '30264409',
		label: 'Banda de acero al carbón serie sae 1500 enrollada en caliente',

		palabrasSimilares:
			'Rollo laminado en caliente de acero al carbono SAE 1500',
	},
	{
		code: '30264410',
		label: 'Palanquilla de acero al carbón enrollado en caliente',

		palabrasSimilares: 'Palanquilla laminada en caliente de acero al carbono',
	},
	{
		code: '30264411',
		label: 'Palanquilla de acero al carbón enrollado en frío',

		palabrasSimilares: 'Palanquilla laminada en frío de acero al carbono',
	},
	{
		code: '30264412',
		label: 'Lingote de acero al carbón enrollado en caliente',

		palabrasSimilares: 'Lingote de acero al carbono laminado en caliente',
	},
	{
		code: '30264413',
		label: 'Lingote de acero al carbón enrollado en frío',

		palabrasSimilares: 'Lingote laminado en frío de acero al carbono',
	},
	{
		code: '30264500',
		label: 'Barras de acero inoxidable',
	},
	{
		code: '30264501',
		label: 'Barra de acero inoxidable serie sae 200 estirada en frío',
	},
	{
		code: '30264502',
		label: 'Barra de acero inoxidable serie sae 300 estirada en frío',
	},
	{
		code: '30264503',
		label: 'Barra de acero inoxidable serie sae 400 estirada en frío',
	},
	{
		code: '30264504',
		label: 'Barra de acero inoxidable serie sae 200 enrollada en caliente',
	},
	{
		code: '30264505',
		label: 'Barra de acero inoxidable serie sae 300 enrollada en caliente',
	},
	{
		code: '30264506',
		label: 'Barra de acero inoxidable serie sae 400 enrollada en caliente',
	},
	{
		code: '30264600',
		label: 'Láminas de acero inoxidable',

		palabrasSimilares: 'Hojas de acero inoxidable',
	},
	{
		code: '30264601',
		label: 'Lámina de acero inoxidable serie sae 200 enrollada en caliente',

		palabrasSimilares:
			'Hojas de acero inoxidable laminada en caliente serie sae 200',
	},
	{
		code: '30264602',
		label: 'Lámina de acero inoxidable serie sae 300 enrollada en caliente',

		palabrasSimilares:
			'Hojas de acero inoxidable laminada en caliente serie sae 300',
	},
	{
		code: '30264603',
		label: 'Lámina de acero inoxidable serie sae 400 enrollada en caliente',

		palabrasSimilares:
			'Hojas de acero inoxidable laminada en caliente serie sae 400',
	},
	{
		code: '30264604',
		label: 'Lámina de acero inoxidable serie sae 200 enrollada en frío',

		palabrasSimilares: 'Hojas de acero inoxidable laminada en frío SAE 200',
	},
	{
		code: '30264605',
		label: 'Lámina de acero inoxidable serie sae 300 enrollada en frío',

		palabrasSimilares: 'Hojas de acero inoxidable laminada en frío SAE 300',
	},
	{
		code: '30264606',
		label: 'Lámina de acero inoxidable serie sae 400 enrollada en frío',

		palabrasSimilares: 'Hojas de acero inoxidable laminada en frío SAE 400',
	},
	{
		code: '30264700',
		label: 'Alambre de acero inoxidable',
	},
	{
		code: '30264701',
		label: 'Alambre de acero inoxidable serie sae 300 enrollada en caliente',

		palabrasSimilares: 'Rollo laminado en caliente de acero inoxidable SAE 300',
	},
	{
		code: '30264702',
		label: 'Alambre de acero inoxidable serie sae 400 enrollada en caliente',

		palabrasSimilares: 'Rollo laminado en caliente de acero inoxidable SAE 400',
	},
	{
		code: '30264703',
		label: 'Alambre de acero inoxidable serie sae 200 enrollada en frío',

		palabrasSimilares: 'Rollo laminada en frío de acero inoxidable SAE 200',
	},
	{
		code: '30264704',
		label: 'Alambre de acero inoxidable serie sae 300 enrollada en frío',

		palabrasSimilares: 'Rollo laminada en frío de acero inoxidable SAE 300',
	},
	{
		code: '30264705',
		label: 'Alambre de acero inoxidable serie sae 400 enrollada en frío',

		palabrasSimilares: 'Rollo laminada en frío de acero inoxidable SAE 400',
	},
	{
		code: '30264800',
		label: 'Bandas de acero inoxidable',

		palabrasSimilares: 'Tiras de acero inoxidable',
	},
	{
		code: '30264801',
		label: 'Banda de acero inoxidable serie sae 200 enrollada en caliente',

		palabrasSimilares: 'Tira laminada en caliente de acero inoxidable SAE 200',
	},
	{
		code: '30264802',
		label: 'Banda de acero inoxidable serie sae 300 enrollada en caliente',

		palabrasSimilares: 'Tira laminada en caliente de acero inoxidable SAE 300',
	},
	{
		code: '30264803',
		label: 'Banda de acero inoxidable serie sae 400 enrollada en caliente',

		palabrasSimilares: 'Tira laminada en caliente de acero inoxidable SAE 400',
	},
	{
		code: '30264804',
		label: 'Banda de acero inoxidable serie sae 200 enrollada en frío',

		palabrasSimilares: 'Tira laminada en frío de acero inoxidable SAE 200',
	},
	{
		code: '30264805',
		label: 'Banda de acero inoxidable serie sae 300 enrollada en frío',

		palabrasSimilares: 'Tira laminada en frío de acero inoxidable SAE 300',
	},
	{
		code: '30264806',
		label: 'Banda de acero inoxidable serie sae 400 enrollada en frío',

		palabrasSimilares: 'Tira laminada en frío de acero inoxidable SAE 400',
	},
	{
		code: '30264900',
		label: 'Barras de aluminio',
	},
	{
		code: '30264901',
		label: 'Barra de aluminio serie sae 1000 estirada en frío',
	},
	{
		code: '30264902',
		label: 'Barra de aluminio serie sae 1000 enrollada en caliente',
	},
	{
		code: '30264903',
		label: 'Barra de aluminio serie sae 2000 estirada en frío',
	},
	{
		code: '30264904',
		label: 'Barra de aluminio serie sae 2000 enrollada en caliente',
	},
	{
		code: '30264905',
		label: 'Barra de aluminio serie sae 3000 estirada en frío',
	},
	{
		code: '30264906',
		label: 'Barra de aluminio serie sae 3000 enrollada en caliente',
	},
	{
		code: '30264907',
		label: 'Barra de aluminio serie sae 4000 estirada en frío',
	},
	{
		code: '30264908',
		label: 'Barra de aluminio serie sae 4000 enrollada en caliente',
	},
	{
		code: '30264909',
		label: 'Barra de aluminio serie sae 5000 estirada en frío',
	},
	{
		code: '30264910',
		label: 'Barra de aluminio serie sae 5000 enrollada en caliente',
	},
	{
		code: '30264911',
		label: 'Barra de aluminio serie sae 6000 estirada en frío',
	},
	{
		code: '30264912',
		label: 'Barra de aluminio serie sae 6000 enrollada en caliente',
	},
	{
		code: '30265000',
		label: 'Láminas de aluminio',

		palabrasSimilares: 'Hojas de aluminio',
	},
	{
		code: '30265001',
		label: 'Lámina de aluminio serie sae 1000 enrollada en frío',

		palabrasSimilares: 'Hoja de aluminio laminada en frío SAE 1000',
	},
	{
		code: '30265002',
		label: 'Lámina de aluminio serie sae 1000 enrollada en caliente',

		palabrasSimilares: 'Hoja de aluminio laminada en caliente SAE 1000',
	},
	{
		code: '30265003',
		label: 'Lámina de aluminio serie sae 2000 enrollada en frío',

		palabrasSimilares: 'Hoja de aluminio laminada en frío SAE 2000',
	},
	{
		code: '30265004',
		label: 'Lámina de aluminio serie sae 2000 enrollada en caliente',

		palabrasSimilares: 'Hoja de aluminio laminada en caliente SAE 2000',
	},
	{
		code: '30265005',
		label: 'Lámina de aluminio serie sae 3000 enrollada en frío',

		palabrasSimilares: 'Hoja de aluminio laminada en frío SAE 3000',
	},
	{
		code: '30265006',
		label: 'Lámina de aluminio serie sae 3000 enrollada en caliente',

		palabrasSimilares: 'Hoja de aluminio laminada en caliente SAE 3000',
	},
	{
		code: '30265007',
		label: 'Lámina de aluminio serie sae 4000 enrollada en frío',

		palabrasSimilares: 'Hoja de aluminio laminada en frío SAE 4000',
	},
	{
		code: '30265008',
		label: 'Lámina de aluminio serie sae 4000 enrollada en caliente',

		palabrasSimilares: 'Hoja de aluminio laminada en caliente SAE 4000',
	},
	{
		code: '30265009',
		label: 'Lámina de aluminio serie sae 5000 enrollada en frío',

		palabrasSimilares: 'Hoja de aluminio laminada en frío SAE 5000',
	},
	{
		code: '30265010',
		label: 'Lámina de aluminio serie sae 5000 enrollada en caliente',

		palabrasSimilares: 'Hoja de aluminio laminada en caliente SAE 5000',
	},
	{
		code: '30265011',
		label: 'Lámina de aluminio serie sae 6000 enrollada en frío',

		palabrasSimilares: 'Hoja de aluminio laminada en frío SAE 6000',
	},
	{
		code: '30265012',
		label: 'Lámina de aluminio serie sae 6000 enrollada en caliente',

		palabrasSimilares: 'Hoja de aluminio corrugado',
	},
	{
		code: '30265100',
		label: 'Alambre de aluminio',
	},
	{
		code: '30265101',
		label: 'Alambre de aluminio serie sae 1000 enrollada en caliente',

		palabrasSimilares: 'Rollo de aluminio laminado en caliente serie SAE 1000',
	},
	{
		code: '30265102',
		label: 'Alambre de aluminio serie sae 2000 enrollada en caliente',

		palabrasSimilares: 'Rollo de aluminio laminado en caliente serie SAE 2000',
	},
	{
		code: '30265103',
		label: 'Alambre de aluminio serie sae 3000 enrollada en caliente',

		palabrasSimilares: 'Rollo de aluminio laminado en caliente serie SAE 3000',
	},
	{
		code: '30265104',
		label: 'Alambre de aluminio serie sae 4000 enrollada en caliente',

		palabrasSimilares: 'Rollo de aluminio laminado en caliente serie SAE 4000',
	},
	{
		code: '30265105',
		label: 'Alambre de aluminio serie sae 5000 enrollada en caliente',

		palabrasSimilares: 'Rollo de aluminio laminado en caliente serie SAE 5000',
	},
	{
		code: '30265106',
		label: 'Alambre de aluminio serie sae 6000 enrollada en caliente',

		palabrasSimilares: 'Rollo de aluminio laminado en caliente serie SAE 6000',
	},
	{
		code: '30265200',
		label: 'Bandas y lingotes de aluminio',

		palabrasSimilares: 'Tiras y lingotes de aluminio',
	},
	{
		code: '30265201',
		label: 'Banda de aluminio',

		palabrasSimilares: 'Tiras de aluminio',
	},
	{
		code: '30265202',
		label: 'Lingote de aluminio',
	},
	{
		code: '30265300',
		label: 'Barras de hierro',
	},
	{
		code: '30265301',
		label: 'Barra fundida de hierro dúctil',
	},
	{
		code: '30265302',
		label: 'Barra fundida de hierro gris',
	},
	{
		code: '30265303',
		label: 'Barra fundida de hierro blanco',
	},
	{
		code: '30265304',
		label: 'Barra fundida de hierro maleable',
	},
	{
		code: '30265400',
		label: 'Láminas de hierro',
	},
	{
		code: '30265401',
		label: 'Lámina de hierro dúctil enrollada en caliente',
	},
	{
		code: '30265402',
		label: 'Lámina de hierro gris enrollada en caliente',
	},
	{
		code: '30265403',
		label: 'Lámina de hierro blanco enrollada en caliente',
	},
	{
		code: '30265404',
		label: 'Lámina de hierro maleable enrollada en caliente',
	},
	{
		code: '30265500',
		label: 'Lingotes, bandas, palanquillas y alambres de hierro',
	},
	{
		code: '30265501',
		label: 'Alambre de hierro dúctil',
	},
	{
		code: '30265502',
		label: 'Banda de hierro dúctil',
	},
	{
		code: '30265503',
		label: 'Palanquilla de hierro dúctil',
	},
	{
		code: '30265504',
		label: 'Palanquilla de hierro gris',
	},
	{
		code: '30265505',
		label: 'Palanquilla de hierro blanco',
	},
	{
		code: '30265506',
		label: 'Palanquilla de hierro maleable',
	},
	{
		code: '30265600',
		label: 'Barras y láminas de aleaciones ferrosas',
	},
	{
		code: '30265601',
		label: 'Barra de aleación ferrosa',

		palabrasSimilares: 'Barra de aleación ferrosa',
	},
	{
		code: '30265602',
		label: 'Lámina de aleación ferrosa',

		palabrasSimilares: 'Hoja de aleación ferrosa',
	},
	{
		code: '30265700',
		label: 'Lingotes, bandas, palanquillas y alambres de aleaciones ferrosas',

		palabrasSimilares:
			'Lingotes, tiras, palanquillas y rollos de aleaciones ferrosas',
	},
	{
		code: '30265701',
		label: 'Alambre de aleación ferrosa',

		palabrasSimilares: 'Rollo de aleación ferrosa',
	},
	{
		code: '30265702',
		label: 'Banda de aleación ferrosa',

		palabrasSimilares: 'Tira de aleación ferrosa',
	},
	{
		code: '30265703',
		label: 'Palanquilla de aleación ferrosa',
	},
	{
		code: '30265704',
		label: 'Lingote de extrusión de aleación ferrosa',
	},
	{
		code: '30265800',
		label: 'Barras y láminas plásticas',

		palabrasSimilares: 'Barras y hojas de plástico',
	},
	{
		code: '30265801',
		label: 'Barra de plástico',
	},
	{
		code: '30265802',
		label: 'Lámina de plástico',

		palabrasSimilares: 'Hoja de plástico',
	},
	{
		code: '30265803',
		label: 'Lámina corrugada de plástico',

		palabrasSimilares: 'Hoja de plástico corrugado',
	},
	{
		code: '30265900',
		label: 'Alambre y bandas de plástico',
	},
	{
		code: '30265901',
		label: 'Alambre de plástico',

		palabrasSimilares: 'Rollo de plástico',
	},
	{
		code: '30265902',
		label: 'Banda de plástico',

		palabrasSimilares: 'Tira de plástico',
	},
	{
		code: '30266000',
		label: 'Barras de metales preciosos y metales especiales',
	},
	{
		code: '30266001',
		label: 'Barra de metal precioso',
	},
	{
		code: '30266002',
		label: 'Barra de aleación no ferrosa',
	},
	{
		code: '30266100',
		label: 'Láminas de metales preciosos y metales especiales',

		palabrasSimilares: 'Metales preciosos y hojas de metales especiales',
	},
	{
		code: '30266101',
		label: 'Lámina de aleación de berilio',

		palabrasSimilares: 'Hoja de aleación de berilio',
	},
	{
		code: '30266102',
		label: 'Lámina de metal compuesto',

		palabrasSimilares: 'Hoja de metal compuesto',
	},
	{
		code: '30266103',
		label: 'Lámina de aleación de no ferrosa',

		palabrasSimilares: 'Hoja de aleación no ferrosa',
	},
	{
		code: '30266200',
		label:
			'Lingotes, bandas, palanquillas y alambres de metales preciosos y metales especiales',
	},
	{
		code: '30266201',
		label: 'Alambre de grafito',

		palabrasSimilares: 'Rollo de grafito',
	},
	{
		code: '30266202',
		label: 'Alambre de aleación no ferrosa',

		palabrasSimilares: 'Rollo de aleación no ferrosa',
	},
	{
		code: '30266203',
		label: 'Alambre de metal precioso',

		palabrasSimilares: 'Rollo de metal precioso',
	},
	{
		code: '30266204',
		label: 'Banda de aleación no ferrosa',

		palabrasSimilares: 'Tira de aleación no ferrosa',
	},
	{
		code: '30266205',
		label: 'Palanquilla de aleación no ferrosa',
	},
	{
		code: '30266206',
		label: 'Palanquilla de metal precioso',
	},
	{
		code: '30266207',
		label: 'Lingote de metal precioso',
	},
	{
		code: '30266208',
		label: 'Lingote de antimonio',
	},
	{
		code: '30266209',
		label: 'Lingote de cadmio',
	},
	{
		code: '30266210',
		label: 'Lingote de circonio',
	},
	{
		code: '30266211',
		label: 'Lingote de cobalto',
	},
	{
		code: '30266212',
		label: 'Lingote de molibdeno',
	},
	{
		code: '30266213',
		label: 'lingote de arsénico',
	},
	{
		code: '30266214',
		label: 'Lingote de bismuto',
	},
	{
		code: '30266215',
		label: 'Lingote de indio',
	},
	{
		code: '30266216',
		label: 'Lingote de extrusión de aleación no ferrosa',
	},
	{
		code: '30266300',
		label: 'Materiales estructurales de madera',
	},
	{
		code: '30266301',
		label: 'Barra de madera',
	},
	{
		code: '30266400',
		label: 'Barras y láminas de materiales especiales',
	},
	{
		code: '30266401',
		label: 'Barra de caucho',
	},
	{
		code: '30266402',
		label: 'Lámina de caucho',

		palabrasSimilares: 'Hoja de caucho',
	},
	{
		code: '30266403',
		label: 'Lámina de caucho espuma',

		palabrasSimilares: 'Hoja de caucho espuma',
	},
	{
		code: '30266404',
		label: 'Lámina de caucho y corcho',

		palabrasSimilares: 'Hoja de caucho y corcho',
	},
	{
		code: '30266405',
		label: 'Lámina de fibra comprimida',

		palabrasSimilares: 'Hoja de fibra comprimida',
	},
	{
		code: '30266406',
		label: 'Lámina de fibra comprimida con metal insertado',

		palabrasSimilares: 'Hoja de fibra comprimida con metal insertado',
	},
	{
		code: '30266407',
		label: 'Lámina de fibra y caucho',

		palabrasSimilares: 'Hoja de fibra y caucho',
	},
	{
		code: '30266408',
		label: 'Lámina de asbesto',

		palabrasSimilares: 'Hoja de asbesto',
	},
	{
		code: '30266410',
		label: 'Barra de tántalo',

		palabrasSimilares: 'Barra de Tantalio',
	},
	{
		code: '30266500',
		label:
			'Lingotes, bandas, palanquillas y alambres de  materiales especiales',
	},
	{
		code: '30266501',
		label: 'Banda de caucho',

		palabrasSimilares: 'Tira de caucho',
	},
	{
		code: '30266502',
		label: 'Alambre de fibra comprimida',

		palabrasSimilares: 'Rollo de fibra comprimida',
	},
	{
		code: '30266503',
		label: 'Alambre de fibra y caucho',

		palabrasSimilares: 'Rollo de fibra y caucho',
	},
	{
		code: '30266504',
		label: 'Banda de tántalo',

		palabrasSimilares: 'Tira de Tantalio',
	},
	{
		code: '30266600',
		label: 'Láminas y lingotes de níckel',

		palabrasSimilares: 'Hojas y lingotes de níquel',
	},
	{
		code: '30266601',
		label: 'Lámina de níquel',

		palabrasSimilares: 'Hoja de níquel',
	},
	{
		code: '30266602',
		label: 'Lingote de níquel',
	},
	{
		code: '30266700',
		label: 'Trasmisiones',
	},
	{
		code: '30266701',
		label: 'Transmisión de acero',

		palabrasSimilares:
			'Barra de transmisión de acero, Eje de transmisión de acero',
	},
	{
		code: '30266702',
		label: 'Transmisión de acero inoxidable',

		palabrasSimilares:
			'Barra de transmisión de acero inoxidable, Eje de transmisión de acero inoxidable',
	},
	{
		code: '31101600',
		label: 'Moldeos en arena',
	},
	{
		code: '31101601',
		label: 'Fundición en arena de aleación no ferrosa',
	},
	{
		code: '31101602',
		label: 'Fundición en arena de aleación ferrosa',
	},
	{
		code: '31101603',
		label: 'Fundición en arena de acero',
	},
	{
		code: '31101604',
		label: 'Fundición en arena de acero inoxidable',
	},
	{
		code: '31101606',
		label: 'Fundición en arena de aluminio',
	},
	{
		code: '31101607',
		label: 'Fundición en arena de magnesio',
	},
	{
		code: '31101608',
		label: 'Fundición en arena de titanio',
	},
	{
		code: '31101609',
		label: 'Fundición en arena de berilio',
	},
	{
		code: '31101610',
		label: 'Fundición en arena de cobre',
	},
	{
		code: '31101611',
		label: 'Fundición en arena de latón',
	},
	{
		code: '31101612',
		label: 'Fundición en arena de bronce',
	},
	{
		code: '31101613',
		label: 'Fundición en arena de zinc',
	},
	{
		code: '31101614',
		label: 'Fundición en arena de estaño',
	},
	{
		code: '31101615',
		label: 'Fundición en arena de plomo',
	},
	{
		code: '31101616',
		label: 'Fundición en arena de metal precioso',
	},
	{
		code: '31101617',
		label: 'Fundición en arena de hierro gris',
	},
	{
		code: '31101618',
		label: 'Fundición en arena de hierro dúctil',
	},
	{
		code: '31101619',
		label: 'Fundición en arena de hierro maleable',
	},
	{
		code: '31101700',
		label: 'Piezas fundidas en molde permanente',
	},
	{
		code: '31101701',
		label: 'Objetos de aleación no ferrosa fundidos en molde fijo',

		palabrasSimilares:
			'Piezas de aleación no ferrosa fundidas en molde permanente',
	},
	{
		code: '31101702',
		label: 'Objetos de aleación ferrosa fundidos en molde fijo',

		palabrasSimilares:
			'Piezas de aleación ferrosa fundidas en molde permanente',
	},
	{
		code: '31101703',
		label: 'Objetos de acero fundidos en molde fijo',

		palabrasSimilares: 'Piezas de acero fundidas en molde permanente',
	},
	{
		code: '31101704',
		label: 'Objetos de acero inoxidable fundidos en molde fijo',

		palabrasSimilares:
			'Piezas de acero inoxidable fundidas en molde permanente',
	},
	{
		code: '31101705',
		label: 'Objetos de hierro fundidos en molde fijo',

		palabrasSimilares: 'Piezas de hierro fundidas en molde permanente',
	},
	{
		code: '31101706',
		label: 'Objetos de aluminio fundidos en molde fijo',

		palabrasSimilares: 'Piezas de aluminio fundidas en molde permanente',
	},
	{
		code: '31101707',
		label: 'Objetos de magnesio fundidos en molde fijo',

		palabrasSimilares: 'Piezas de magnesio fundidas en molde permanente',
	},
	{
		code: '31101708',
		label: 'Objetos de titanio fundidos en molde fijo',

		palabrasSimilares: 'Piezas de titanio fundidas en molde permanente',
	},
	{
		code: '31101709',
		label: 'Objetos de berilio fundidos en molde fijo',

		palabrasSimilares: 'Piezas de berilio fundidas en molde permanente',
	},
	{
		code: '31101710',
		label: 'Objetos de cobre fundidos en molde fijo',

		palabrasSimilares: 'Piezas de cobre fundidas en molde permanente',
	},
	{
		code: '31101711',
		label: 'Objetos de latón fundidos en molde fijo',

		palabrasSimilares: 'Piezas de latón fundidas en molde permanente',
	},
	{
		code: '31101712',
		label: 'Objetos de bronce fundidos en molde fijo',

		palabrasSimilares: 'Piezas de bronce fundidas en molde permanente',
	},
	{
		code: '31101713',
		label: 'Objetos de zinc fundidos en molde fijo',

		palabrasSimilares: 'Piezas de zinc fundidas en molde permanente',
	},
	{
		code: '31101714',
		label: 'Objetos de estaño fundidos en molde fijo',

		palabrasSimilares: 'Piezas de estaño fundidas en molde permanente',
	},
	{
		code: '31101715',
		label: 'Objetos de plomo fundidos en molde fijo',

		palabrasSimilares: 'Piezas de plomo fundidas en molde permanente',
	},
	{
		code: '31101716',
		label: 'Objetos de metal precioso fundidos en molde fijo',

		palabrasSimilares: 'Piezas de metal precioso fundidas en molde permanente',
	},
	{
		code: '31101800',
		label: 'Piezas fundidas por moldeo en cáscara',

		palabrasSimilares: 'Piezas fundidas en moldes de fundición de cáscara',
	},
	{
		code: '31101801',
		label: 'Objetos de aleación no ferrosa fundidos por moldeo en cáscara',

		palabrasSimilares:
			'Piezas de aleación no ferrosa fundidas en molde de cáscara',
	},
	{
		code: '31101802',
		label: 'Objetos de aleación ferrosa fundidos por moldeo en cáscara',

		palabrasSimilares:
			'Piezas de aleación ferrosa fundidas en molde de cáscara',
	},
	{
		code: '31101803',
		label: 'Objetos de acero fundidos por moldeo en cáscara',

		palabrasSimilares: 'Piezas de acero fundidas en molde de cáscara',
	},
	{
		code: '31101804',
		label: 'Objetos de acero inoxidable fundidos por moldeo en cáscara',

		palabrasSimilares:
			'Piezas de acero inoxidable fundidas en molde de cáscara',
	},
	{
		code: '31101806',
		label: 'Objetos de aluminio fundidos por moldeo en cáscara',

		palabrasSimilares: 'Piezas de aluminio fundidas en molde de cáscara',
	},
	{
		code: '31101807',
		label: 'Objetos de magnesio fundidos por moldeo en cáscara',

		palabrasSimilares: 'Piezas de magnesio fundidas en molde de cáscara',
	},
	{
		code: '31101808',
		label: 'Objetos de titanio fundidos por moldeo en cáscara',

		palabrasSimilares: 'Piezas de titanio fundidas en molde de cáscara',
	},
	{
		code: '31101809',
		label: 'Objetos de berilio fundidos por moldeo en cáscara',

		palabrasSimilares: 'Piezas de berilio fundidas en molde de cáscara',
	},
	{
		code: '31101810',
		label: 'Objetos de cobre fundidos por moldeo en cáscara',

		palabrasSimilares: 'Piezas de cobre fundidas en molde de cáscara',
	},
	{
		code: '31101811',
		label: 'Objetos de latón fundidos por moldeo en cáscara',

		palabrasSimilares: 'Piezas de latón fundidas en molde de cáscara',
	},
	{
		code: '31101812',
		label: 'Objetos de bronce fundidos por moldeo en cáscara',

		palabrasSimilares: 'Piezas de bronce fundidas en molde de cáscara',
	},
	{
		code: '31101813',
		label: 'Objetos de zinc fundidos por moldeo en cáscara',

		palabrasSimilares: 'Piezas de zinc fundidas en molde de cáscara',
	},
	{
		code: '31101814',
		label: 'Objetos de estaño fundidos por moldeo en cáscara',

		palabrasSimilares: 'Piezas de estaño fundidas en molde de cáscara',
	},
	{
		code: '31101815',
		label: 'Objetos de plomo fundidos por moldeo en cáscara',

		palabrasSimilares: 'Piezas de plomo fundidas en molde de cáscara',
	},
	{
		code: '31101816',
		label: 'Objetos de metal precioso fundidos por moldeo en cáscara',

		palabrasSimilares: 'Piezas de metal precioso fundidas en molde de cáscara',
	},
	{
		code: '31101817',
		label: 'Objetos de hierro dúctil fundidos por moldeo en cáscara',

		palabrasSimilares: 'Piezas de hierro fundidas en molde de cáscara',
	},
	{
		code: '31101900',
		label: 'Piezas fundidas a la cera perdida',
	},
	{
		code: '31101901',
		label: 'Objetos de aleación no ferrosa fundidos a la cera perdida',

		palabrasSimilares:
			'Piezas de aleación no ferrosa fundidas a la cera perdida',
	},
	{
		code: '31101902',
		label: 'Objetos de aleación ferrosa fundidos a la cera perdida',

		palabrasSimilares: 'Piezas de aleación ferrosa fundidas a la cera perdida',
	},
	{
		code: '31101903',
		label: 'Objetos de acero fundidos a la cera perdida',

		palabrasSimilares: 'Piezas de acero fundidas a la cera perdida',
	},
	{
		code: '31101904',
		label: 'Objetos de acero inoxidable fundidos a la cera perdida',

		palabrasSimilares: 'Piezas de acero inoxidable fundidas a la cera perdida',
	},
	{
		code: '31101905',
		label: 'Objetos de hierro fundidos a la cera perdida',

		palabrasSimilares: 'Piezas de hierro fundidas a la cera perdida',
	},
	{
		code: '31101906',
		label: 'Objetos de aluminio fundidos a la cera perdida',

		palabrasSimilares: 'Piezas de aluminio fundidas a la cera perdida',
	},
	{
		code: '31101907',
		label: 'Objetos de magnesio fundidos a la cera perdida',

		palabrasSimilares: 'Piezas de magnesio fundidas a la cera perdida',
	},
	{
		code: '31101908',
		label: 'Objetos de zinc fundidos a la cera perdida',

		palabrasSimilares: 'Piezas de zinc fundidas a la cera perdida',
	},
	{
		code: '31101909',
		label: 'Objetos de estaño fundidos a la cera perdida',

		palabrasSimilares: 'Piezas de estaño fundidas a la cera perdida',
	},
	{
		code: '31101910',
		label: 'Objetos de plomo fundidos a la cera perdida',

		palabrasSimilares: 'Piezas de plomo fundidas a la cera perdida',
	},
	{
		code: '31101911',
		label: 'Objetos de metal precioso fundidos a la cera perdida',

		palabrasSimilares: 'Piezas de metal precioso fundidas a la cera perdida',
	},
	{
		code: '31101912',
		label: 'Objetos de titanio fundidos a la cera perdida',

		palabrasSimilares: 'Piezas de titanio fundidas a la cera perdida',
	},
	{
		code: '31101914',
		label: 'Objetos de aleación de acero fundidos a la cera perdida',

		palabrasSimilares: 'Piezas de aleación de acero fundidas a la cera perdida',
	},
	{
		code: '31102000',
		label: 'Piezas de fundición centrífuga',

		palabrasSimilares: 'Fundiciones centrífugas',
	},
	{
		code: '31102001',
		label: 'Objetos de fundición centrífuga de aleación no ferrosa',

		palabrasSimilares:
			'Piezas de aleación no ferrosa hechas mediante fundición centrifuga',
	},
	{
		code: '31102002',
		label: 'Objetos de fundición centrífuga de aleación ferrosa',

		palabrasSimilares:
			'Piezas de aleación ferrosa hechas mediante fundición centrifuga',
	},
	{
		code: '31102003',
		label: 'Objetos de fundición centrífuga de acero',

		palabrasSimilares: 'Piezas de acero hechas mediante fundición centrifuga',
	},
	{
		code: '31102004',
		label: 'Objetos de fundición centrífuga de acero inoxidable',

		palabrasSimilares:
			'Piezas de acero inoxidable hechas mediante fundición centrifuga',
	},
	{
		code: '31102005',
		label: 'Objetos de fundición centrífuga de hierro',

		palabrasSimilares: 'Piezas de hierro hechas mediante fundición centrifuga',
	},
	{
		code: '31102006',
		label: 'Objetos de fundición centrífuga de aluminio',

		palabrasSimilares:
			'Piezas de aluminio hechas mediante fundición centrifuga',
	},
	{
		code: '31102007',
		label: 'Objetos de fundición centrífuga de magnesio',

		palabrasSimilares:
			'Piezas de magnesio hechas mediante fundición centrifuga',
	},
	{
		code: '31102008',
		label: 'Objetos de fundición centrífuga de titanio',

		palabrasSimilares: 'Piezas de titanio hechas mediante fundición centrifuga',
	},
	{
		code: '31102009',
		label: 'Objetos de fundición centrífuga de berilio',

		palabrasSimilares: 'Piezas de berilio hechas mediante fundición centrifuga',
	},
	{
		code: '31102010',
		label: 'Objetos de fundición centrífuga de cobre',

		palabrasSimilares: 'Piezas de cobre hechas mediante fundición centrifuga',
	},
	{
		code: '31102011',
		label: 'Objetos de fundición centrífuga de latón',

		palabrasSimilares: 'Piezas de latón hechas mediante fundición centrifuga',
	},
	{
		code: '31102012',
		label: 'Objetos de fundición centrífuga de bronce',

		palabrasSimilares: 'Piezas de bronce hechas mediante fundición centrifuga',
	},
	{
		code: '31102013',
		label: 'Objetos de fundición centrífuga de zinc',

		palabrasSimilares: 'Piezas de zinc hechas mediante fundición centrifuga',
	},
	{
		code: '31102014',
		label: 'Objetos de fundición centrífuga de estaño',

		palabrasSimilares: 'Piezas de estaño hechas mediante fundición centrifuga',
	},
	{
		code: '31102015',
		label: 'Objetos de fundición centrífuga de plomo',

		palabrasSimilares: 'Piezas de plomo hechas mediante fundición centrifuga',
	},
	{
		code: '31102016',
		label: 'Objetos de fundición centrífuga de metal precioso',

		palabrasSimilares:
			'Piezas de metal precioso hechas mediante fundición centrifuga',
	},
	{
		code: '31102100',
		label: 'Piezas fundidas en molde cerámico',
	},
	{
		code: '31102101',
		label: 'Objetos de aleación no ferrosa fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de aleación no ferrosa fundidas en molde de cerámica',
	},
	{
		code: '31102102',
		label: 'Objetos de aleación ferrosa fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de aleación ferrosa fundidas en molde de cerámica',
	},
	{
		code: '31102103',
		label: 'Objetos de acero fundidos en molde cerámico',

		palabrasSimilares: 'Piezas de acero fundidas en molde de cerámica',
	},
	{
		code: '31102104',
		label: 'Objetos de acero inoxidable fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de acero inoxidable fundidas en molde de cerámica',
	},
	{
		code: '31102105',
		label: 'Objetos de hierro fundidos en molde cerámico',

		palabrasSimilares: 'Piezas de hierro fundidas en molde de cerámica',
	},
	{
		code: '31102106',
		label: 'Objetos de aluminio fundidos en molde cerámico',

		palabrasSimilares: 'Piezas de aluminio fundidas en molde de cerámica',
	},
	{
		code: '31102107',
		label: 'Objetos de magnesio fundidos en molde cerámico',

		palabrasSimilares: 'Piezas de magnesio fundidas en molde de cerámica',
	},
	{
		code: '31102108',
		label: 'Objetos de titanio fundidos en molde cerámico',

		palabrasSimilares: 'Piezas de titanio fundidas en molde de cerámica',
	},
	{
		code: '31102109',
		label: 'Objetos de berilio fundidos en molde cerámico',

		palabrasSimilares: 'Piezas de berilio fundidas en molde de cerámica',
	},
	{
		code: '31102110',
		label: 'Objetos de cobre fundidos en molde cerámico',

		palabrasSimilares: 'Piezas de cobre fundidas en molde de cerámica',
	},
	{
		code: '31102111',
		label: 'Objetos de latón fundidos en molde cerámico',

		palabrasSimilares: 'Piezas de latón fundidas en molde de cerámica',
	},
	{
		code: '31102112',
		label: 'Objetos de bronce fundidos en molde cerámico',

		palabrasSimilares: 'Piezas de bronce fundidas en molde de cerámica',
	},
	{
		code: '31102113',
		label: 'Objetos de zinc fundidos en molde cerámico',

		palabrasSimilares: 'Piezas de zinc fundidas en molde de cerámica',
	},
	{
		code: '31102114',
		label: 'Objetos de estaño fundidos en molde cerámico',

		palabrasSimilares: 'Piezas de estaño fundidas en molde de cerámica',
	},
	{
		code: '31102115',
		label: 'Objetos de plomo fundidos en molde cerámico',

		palabrasSimilares: 'Piezas de plomo fundidas en molde de cerámica',
	},
	{
		code: '31102116',
		label: 'Objetos de metal precioso fundidos en molde cerámico',

		palabrasSimilares: 'Piezas de metal precioso fundidas en molde de cerámica',
	},
	{
		code: '31102200',
		label: 'Piezas fundidas en molde de grafito',

		palabrasSimilares: 'Fundiciones en molde de grafito',
	},
	{
		code: '31102201',
		label: 'Objetos de aleación no ferrosa fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de aleación no ferrosa fundidas en molde de grafito',
	},
	{
		code: '31102202',
		label: 'Objetos de aleación ferrosa fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de aleación ferrosa fundidas en molde de grafito',
	},
	{
		code: '31102203',
		label: 'Objetos de acero fundidos en molde de grafito',

		palabrasSimilares: 'Piezas de acero fundidas en molde de grafito',
	},
	{
		code: '31102204',
		label: 'Objetos de acero inoxidable fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de acero inoxidable fundidas en molde de grafito',
	},
	{
		code: '31102205',
		label: 'Objetos de hierro fundidos en molde de grafito',

		palabrasSimilares: 'Piezas de hierro fundidas en molde de grafito',
	},
	{
		code: '31102206',
		label: 'Objetos de aluminio fundidos en molde de grafito',

		palabrasSimilares: 'Piezas de aluminio fundidas en molde de grafito',
	},
	{
		code: '31102207',
		label: 'Objetos de magnesio fundidos en molde de grafito',

		palabrasSimilares: 'Piezas de magnesio fundidas en molde de grafito',
	},
	{
		code: '31102208',
		label: 'Objetos de titanio fundidos en molde de grafito',

		palabrasSimilares: 'Piezas de titanio fundidas en molde de grafito',
	},
	{
		code: '31102209',
		label: 'Objetos de berilio fundidos en molde de grafito',

		palabrasSimilares: 'Piezas de berilio fundidas en molde de grafito',
	},
	{
		code: '31102210',
		label: 'Objetos de cobre fundidos en molde de grafito',

		palabrasSimilares: 'Piezas de cobre fundidas en molde de grafito',
	},
	{
		code: '31102211',
		label: 'Objetos de latón fundidos en molde de grafito',

		palabrasSimilares: 'Piezas de latón fundidas en molde de grafito',
	},
	{
		code: '31102212',
		label: 'Objetos de bronce fundidos en molde de grafito',

		palabrasSimilares: 'Piezas de bronce fundidas en molde de grafito',
	},
	{
		code: '31102213',
		label: 'Objetos de zinc fundidos en molde de grafito',

		palabrasSimilares: 'Piezas de zinc fundidas en molde de grafito',
	},
	{
		code: '31102214',
		label: 'Objetos de estaño fundidos en molde de grafito',

		palabrasSimilares: 'Piezas de estaño fundidas en molde de grafito',
	},
	{
		code: '31102215',
		label: 'Objetos de plomo fundidos en molde de grafito',

		palabrasSimilares: 'Piezas de plomo fundidas en molde de grafito',
	},
	{
		code: '31102216',
		label: 'Objetos de metal precioso fundidos en molde de grafito',

		palabrasSimilares: 'Piezas de metal precioso fundidas en molde de grafito',
	},
	{
		code: '31102300',
		label: 'Piezas fundidas en molde de yeso',
	},
	{
		code: '31102301',
		label: 'Objetos de aleación no ferrosa fundidos en molde de yeso',

		palabrasSimilares:
			'Piezas de aleación no ferrosa fundidas en molde de yeso',
	},
	{
		code: '31102302',
		label: 'Objetos de aleación ferrosa fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de aleación ferrosa fundidas en molde de yeso',
	},
	{
		code: '31102303',
		label: 'Objetos de acero fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de acero fundidas en molde de yeso',
	},
	{
		code: '31102304',
		label: 'Objetos de acero inoxidable fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de acero inoxidable fundidas en molde de yeso',
	},
	{
		code: '31102305',
		label: 'Objetos de hierro fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de hierro fundidas en molde de yeso',
	},
	{
		code: '31102306',
		label: 'Objetos de aluminio fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de aluminio fundidas en molde de yeso',
	},
	{
		code: '31102307',
		label: 'Objetos de magnesio fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de magnesio fundidas en molde de yeso',
	},
	{
		code: '31102308',
		label: 'Objetos de titanio fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de titanio fundidas en molde de yeso',
	},
	{
		code: '31102309',
		label: 'Objetos de berilio fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de berilio fundidas en molde de yeso',
	},
	{
		code: '31102310',
		label: 'Objetos de cobre fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de cobre fundidas en molde de yeso',
	},
	{
		code: '31102311',
		label: 'Objetos de latón fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de latón fundidas en molde de yeso',
	},
	{
		code: '31102312',
		label: 'Objetos de bronce fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de bronce fundidas en molde de yeso',
	},
	{
		code: '31102313',
		label: 'Objetos de zinc fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de zinc fundidas en molde de yeso',
	},
	{
		code: '31102314',
		label: 'Objetos de estaño fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de estaño fundidas en molde de yeso',
	},
	{
		code: '31102315',
		label: 'Objetos de plomo fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de plomo fundidas en molde de yeso',
	},
	{
		code: '31102316',
		label: 'Objetos de metal precioso fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de metal precioso fundidas en molde de yeso',
	},
	{
		code: '31102400',
		label: 'Fundiciones en V',

		palabrasSimilares: 'Fundición en proceso V',
	},
	{
		code: '31102401',
		label: 'Objetos de aleación no ferrosa fundidos por proceso en v',

		palabrasSimilares: 'Piezas de aleación no ferrosa fundidas por proceso V',
	},
	{
		code: '31102402',
		label: 'Objetos de aleación ferrosa fundidos por proceso en v',

		palabrasSimilares: 'Piezas de aleación ferrosa fundidas por proceso V',
	},
	{
		code: '31102403',
		label: 'Objetos de acero fundidos por proceso en v',

		palabrasSimilares: 'Piezas de acero fundidas por proceso V',
	},
	{
		code: '31102404',
		label: 'Objetos de acero inoxidable fundidos por proceso en v',

		palabrasSimilares: 'Piezas de acero inoxidable por proceso  V',
	},
	{
		code: '31102405',
		label: 'Objetos de hierro fundidos por proceso en v',

		palabrasSimilares: 'Piezas de hierro fundidas por proceso V',
	},
	{
		code: '31102406',
		label: 'Objetos de aluminio fundidos por proceso en v',

		palabrasSimilares: 'Piezas de aluminio fundidas por proceso V',
	},
	{
		code: '31102407',
		label: 'Objetos de magnesio fundidos por proceso en v',

		palabrasSimilares: 'Piezas de magnesio fundidas por proceso V',
	},
	{
		code: '31102408',
		label: 'Objetos de titanio fundidos por proceso en v',

		palabrasSimilares: 'Piezas de titanio fundidas por proceso V',
	},
	{
		code: '31102409',
		label: 'Objetos de berilio fundidos por proceso en v',

		palabrasSimilares: 'Piezas de berilio fundidas por proceso V',
	},
	{
		code: '31102410',
		label: 'Objetos de cobre fundidos por proceso en v',

		palabrasSimilares: 'Piezas de cobre fundidas por proceso V',
	},
	{
		code: '31102411',
		label: 'Objetos de latón fundidos por proceso en v',

		palabrasSimilares: 'Piezas de latón fundidas por proceso V',
	},
	{
		code: '31102412',
		label: 'Objetos de bronce fundidos por proceso en v',

		palabrasSimilares: 'Piezas de bronce fundidas por proceso V',
	},
	{
		code: '31102413',
		label: 'Objetos de zinc fundidos por proceso en v',

		palabrasSimilares: 'Piezas de zinc fundidas por proceso V',
	},
	{
		code: '31102414',
		label: 'Objetos de estaño fundidos por proceso en v',

		palabrasSimilares: 'Piezas de estaño fundidas por proceso V',
	},
	{
		code: '31102415',
		label: 'Objetos de plomo fundidos por proceso en v',

		palabrasSimilares: 'Piezas de plomo fundidas por proceso V',
	},
	{
		code: '31102416',
		label: 'Objetos de metal precioso fundidos por proceso en v',

		palabrasSimilares: 'Piezas de metal precioso fundidas por proceso V',
	},
	{
		code: '31102500',
		label:
			'Fundiciones con troquel y ensambles de fundiciones permanentes de baja presión',

		palabrasSimilares:
			'Fundiciones en molde y ensambles de fundiciones permanentes de baja presión',
	},
	{
		code: '31102501',
		label: 'Objetos de aluminio de baja presión fundidos en molde fijo',

		palabrasSimilares:
			'Piezas de aluminio fundidos a baja presión en molde permanente',
	},
	{
		code: '31102600',
		label:
			'Fundiciones con troquel y ensambles de fundiciones  de alta presión',

		palabrasSimilares:
			'Fundiciones en molde y ensambles de fundiciones  de alta presión',
	},
	{
		code: '31102601',
		label: 'Objetos de aluminio de alta presión fundidos en molde fijo',

		palabrasSimilares: 'Piezas de aluminio fundidos en molde a alta presión',
	},
	{
		code: '31102602',
		label: 'Objetos de aluminio de alta presión moldeados con troquel',

		palabrasSimilares: 'Piezas de zinc fundidos en molde a alta presión',
	},
	{
		code: '31102603',
		label: 'Objetos de zinc de alta presión moldeados con troquel',

		palabrasSimilares: 'Piezas de magnesio fundidos en molde a alta presión',
	},
	{
		code: '31102700',
		label: 'Fundiciones y ensambles de fundiciones por apriete',

		palabrasSimilares: 'Fundiciones y ensambles de fundiciones a presión',
	},
	{
		code: '31102701',
		label: 'Objetos de aluminio moldeados por apriete (squeeze)',

		palabrasSimilares: 'Piezas de aluminio fundidas a presión',
	},
	{
		code: '31102800',
		label: 'Fundiciones con troquel y ensambles de fundiciones semi - sólidas',

		palabrasSimilares:
			'Fundiciones en molde y ensambles de fundiciones semi - sólidas',
	},
	{
		code: '31102801',
		label: 'Objetos de aluminio de alta presión moldeados con troquel',

		palabrasSimilares: 'Fundición semi-sólida de aluminio',
	},
	{
		code: '31102900',
		label:
			'Moldeos con troquel y ensambles de fundiciones permanentes a gravedad',

		palabrasSimilares:
			'Fundiciones en molde y ensambles de fundiciones en moldes permanentes a gravedad',
	},
	{
		code: '31102901',
		label: 'Objetos de aluminio moldeados por gravedad permanente',

		palabrasSimilares:
			'Piezas de aluminio fundidas en moldes permanentes a gravedad',
	},
	{
		code: '31111500',
		label: 'Extrusiones de perfiles',
	},
	{
		code: '31111501',
		label: 'Extrusiones de perfiles de aluminio',
	},
	{
		code: '31111502',
		label: 'Extrusiones de perfiles de berilio',
	},
	{
		code: '31111503',
		label: 'Extrusiones de perfiles de latón',
	},
	{
		code: '31111504',
		label: 'Extrusiones de perfiles de bronce',
	},
	{
		code: '31111505',
		label: 'Extrusiones de perfiles de cobre',
	},
	{
		code: '31111506',
		label: 'Extrusiones de perfiles de aleación ferrosa',
	},
	{
		code: '31111507',
		label: 'Extrusiones de perfiles de plomo',
	},
	{
		code: '31111508',
		label: 'Extrusiones de perfiles de magnesio',
	},
	{
		code: '31111509',
		label: 'Extrusiones de perfiles de aleación no ferrosa',
	},
	{
		code: '31111510',
		label: 'Extrusiones de perfiles de plástico',
	},
	{
		code: '31111511',
		label: 'Extrusiones de perfiles de metal precioso',
	},
	{
		code: '31111512',
		label: 'Extrusiones de perfiles de caucho',
	},
	{
		code: '31111513',
		label: 'Extrusiones de perfiles de acero inoxidable',
	},
	{
		code: '31111514',
		label: 'Extrusiones de perfiles de acero',
	},
	{
		code: '31111515',
		label: 'Extrusiones de perfiles de estaño',
	},
	{
		code: '31111516',
		label: 'Extrusiones de perfiles de titanio',
	},
	{
		code: '31111517',
		label: 'Extrusiones de perfiles de zinc',
	},
	{
		code: '31111600',
		label: 'Extrusiones por percusión',

		palabrasSimilares: 'Extrusiones por impacto',
	},
	{
		code: '31111601',
		label: 'Extrusiones por impacto de aluminio',

		palabrasSimilares: 'Extrusiones de perfiles de aluminio por impacto',
	},
	{
		code: '31111602',
		label: 'Extrusiones por impacto de berilio',

		palabrasSimilares: 'Extrusiones de perfiles de berilio por impacto',
	},
	{
		code: '31111603',
		label: 'Extrusiones por impacto de latón',

		palabrasSimilares: 'Extrusiones de perfiles de latón por impacto',
	},
	{
		code: '31111604',
		label: 'Extrusiones por impacto de bronce',

		palabrasSimilares: 'Extrusiones de perfiles de bronce por impacto',
	},
	{
		code: '31111605',
		label: 'Extrusiones por impacto de cobre',

		palabrasSimilares: 'Extrusiones de perfiles de cobre por impacto',
	},
	{
		code: '31111606',
		label: 'Extrusiones por impacto de aleación ferrosa',

		palabrasSimilares:
			'Extrusiones de perfiles de aleación ferrosa por impacto',
	},
	{
		code: '31111607',
		label: 'Extrusiones por impacto de plomo',

		palabrasSimilares: 'Extrusiones de perfiles de plomo por impacto',
	},
	{
		code: '31111608',
		label: 'Extrusiones por impacto de magnesio',

		palabrasSimilares: 'Extrusiones de perfiles de magnesio por impacto',
	},
	{
		code: '31111609',
		label: 'Extrusiones por impacto de aleación no ferrosa',

		palabrasSimilares:
			'Extrusiones de perfiles de aleación no ferrosa por impacto',
	},
	{
		code: '31111610',
		label: 'Extrusiones por impacto de plástico',

		palabrasSimilares: 'Extrusiones de perfiles de plástico por impacto',
	},
	{
		code: '31111611',
		label: 'Extrusiones por impacto de metal precioso',

		palabrasSimilares: 'Extrusiones de perfiles de metal precioso por impacto',
	},
	{
		code: '31111612',
		label: 'Extrusiones por impacto de caucho',

		palabrasSimilares: 'Extrusiones de perfiles de caucho por impacto',
	},
	{
		code: '31111613',
		label: 'Extrusiones por impacto de acero inoxidable',

		palabrasSimilares:
			'Extrusiones de perfiles de acero inoxidable por impacto',
	},
	{
		code: '31111614',
		label: 'Extrusiones por impacto de acero',

		palabrasSimilares: 'Extrusiones de perfiles de acero por impacto',
	},
	{
		code: '31111615',
		label: 'Extrusiones por impacto de estaño',

		palabrasSimilares: 'Extrusiones de perfiles de estaño por impacto',
	},
	{
		code: '31111616',
		label: 'Extrusiones por impacto de titanio',

		palabrasSimilares: 'Extrusiones de perfiles de titanio por impacto',
	},
	{
		code: '31111617',
		label: 'Extrusiones por impacto de zinc',

		palabrasSimilares: 'Extrusiones de perfiles de zinc por impacto',
	},
	{
		code: '31111700',
		label: 'Extrusiones en frío',
	},
	{
		code: '31111701',
		label: 'Extrusiones en frío de aluminio',

		palabrasSimilares: 'Extrusiones de perfiles de aluminio en frío',
	},
	{
		code: '31111702',
		label: 'Extrusiones en frío de berilio',

		palabrasSimilares: 'Extrusiones de perfiles de berilio en frío',
	},
	{
		code: '31111703',
		label: 'Extrusiones en frío de latón',

		palabrasSimilares: 'Extrusiones de perfiles de latón en frío',
	},
	{
		code: '31111704',
		label: 'Extrusiones en frío de bronce',

		palabrasSimilares: 'Extrusiones de perfiles de bronce en frío',
	},
	{
		code: '31111705',
		label: 'Extrusiones en frío de cobre',

		palabrasSimilares: 'Extrusiones de perfiles de cobre en frío',
	},
	{
		code: '31111706',
		label: 'Extrusiones en frío de aleación ferrosa',

		palabrasSimilares: 'Extrusiones de perfiles de aleación ferrosa en frío',
	},
	{
		code: '31111707',
		label: 'Extrusiones en frío de plomo',

		palabrasSimilares: 'Extrusiones de perfiles de plomo en frío',
	},
	{
		code: '31111708',
		label: 'Extrusiones en frío de magnesio',

		palabrasSimilares: 'Extrusiones de perfiles de magnesio en frío',
	},
	{
		code: '31111709',
		label: 'Extrusiones en frío de aleación no ferrosa',

		palabrasSimilares: 'Extrusiones de perfiles de aleación ferrosa en frío',
	},
	{
		code: '31111710',
		label: 'Extrusiones en frío de plástico',

		palabrasSimilares: 'Extrusiones de perfiles de plástico en frío',
	},
	{
		code: '31111711',
		label: 'Extrusiones en frío de metal precioso',

		palabrasSimilares: 'Extrusiones de perfiles de metal precioso en frío',
	},
	{
		code: '31111712',
		label: 'Extrusiones en frío de caucho',

		palabrasSimilares: 'Extrusiones de perfiles de caucho en frío',
	},
	{
		code: '31111713',
		label: 'Extrusiones en frío de acero inoxidable',

		palabrasSimilares: 'Extrusiones de perfiles de acero inoxidable en frío',
	},
	{
		code: '31111714',
		label: 'Extrusiones en frío de acero',

		palabrasSimilares: 'Extrusiones de perfiles de acero en frío',
	},
	{
		code: '31111715',
		label: 'Extrusiones en frío de estaño',

		palabrasSimilares: 'Extrusiones de perfiles de estaño en frío',
	},
	{
		code: '31111716',
		label: 'Extrusiones en frío de titanio',

		palabrasSimilares: 'Extrusiones de perfiles de titanio en frío',
	},
	{
		code: '31111717',
		label: 'Extrusiones en frío de zinc',

		palabrasSimilares: 'Extrusiones de perfiles de zinc en frío',
	},
	{
		code: '31121000',
		label: 'Piezas fundidas mecanizadas de proceso v',

		palabrasSimilares: 'Piezas fundidas por proceso v mecanizadas',
	},
	{
		code: '31121001',
		label:
			'Objetos fundidos maquinados por proceso v de aleaciones no ferrosas',

		palabrasSimilares:
			'Piezas de aleaciones no ferrosas  fundidas por proceso v mecanizadas',
	},
	{
		code: '31121002',
		label: 'Objetos fundidos maquinados por proceso v de aleaciones ferrosas',

		palabrasSimilares:
			'Piezas de aleaciones ferrosas  fundidas por proceso v mecanizadas',
	},
	{
		code: '31121003',
		label: 'Objetos fundidos maquinados por proceso v de acero',

		palabrasSimilares: 'Piezas de acero  fundidas por proceso v mecanizadas',
	},
	{
		code: '31121004',
		label: 'Objetos fundidos maquinados por proceso v de acero inoxidable',

		palabrasSimilares:
			'Piezas de acero inoxidable fundidas por proceso v mecanizadas',
	},
	{
		code: '31121005',
		label: 'Objetos fundidos maquinados por proceso v de hierro',

		palabrasSimilares: 'Piezas de hierro fundidas por proceso v mecanizadas',
	},
	{
		code: '31121006',
		label: 'Objetos fundidos maquinados por proceso v de aluminio',

		palabrasSimilares: 'Piezas de aluminio  fundidas por proceso v mecanizadas',
	},
	{
		code: '31121007',
		label: 'Objetos fundidos maquinados por proceso v de magnesio',

		palabrasSimilares: 'Piezas de magnesio  fundidas por proceso v mecanizadas',
	},
	{
		code: '31121008',
		label: 'Objetos fundidos maquinados por proceso v de titanio',

		palabrasSimilares: 'Piezas de titanio  fundidas por proceso v mecanizadas',
	},
	{
		code: '31121009',
		label: 'Objetos fundidos maquinados por proceso v de berilio',

		palabrasSimilares: 'Piezas de berilio  fundidas por proceso v mecanizadas',
	},
	{
		code: '31121010',
		label: 'Objetos fundidos maquinados por proceso v de cobre',

		palabrasSimilares: 'Piezas de cobre  fundidas por proceso v mecanizadas',
	},
	{
		code: '31121011',
		label: 'Objetos fundidos maquinados por proceso v de latón',

		palabrasSimilares: 'Piezas de latón  fundidas por proceso v mecanizadas',
	},
	{
		code: '31121012',
		label: 'Objetos fundidos maquinados por proceso v de bronce',

		palabrasSimilares: 'Piezas de bronce fundidas por proceso v mecanizadas',
	},
	{
		code: '31121013',
		label: 'Objetos fundidos maquinados por proceso v de zinc',

		palabrasSimilares: 'Piezas de zinc  fundidas por proceso v mecanizadas',
	},
	{
		code: '31121014',
		label: 'Objetos fundidos maquinados por proceso v de estaño',

		palabrasSimilares: 'Piezas de estaño  fundidas por proceso v mecanizadas',
	},
	{
		code: '31121015',
		label: 'Objetos fundidos maquinados por proceso v de plomo',

		palabrasSimilares: 'Piezas de plomo fundidas por proceso v mecanizadas',
	},
	{
		code: '31121016',
		label: 'Objetos fundidos maquinados por proceso v de metal precioso',

		palabrasSimilares:
			'Piezas de metal precioso  fundidas por proceso v mecanizadas',
	},
	{
		code: '31121017',
		label: 'Objetos fundidos maquinados por proceso v de compuestos',

		palabrasSimilares:
			'Piezas de compuestos  fundidas por proceso v mecanizadas',
	},
	{
		code: '31121018',
		label: 'Objetos fundidos maquinados por proceso v de aleación de níquel',

		palabrasSimilares:
			'Piezas de aleación de níquel fundidas por proceso v mecanizadas',
	},
	{
		code: '31121019',
		label: 'Objetos fundidos maquinados por proceso v no metálico',

		palabrasSimilares:
			'Piezas no metálicas  fundidas por proceso v mecanizadas',
	},
	{
		code: '31121100',
		label: 'Fundiciones a troquel mecanizadas',

		palabrasSimilares: 'Fundiciones en molde mecanizadas',
	},
	{
		code: '31121101',
		label: 'Objetos fundidos maquinados con troquel de aleaciones no ferrosas',

		palabrasSimilares: 'Piezas de aluminio fundidas en molde mecanizadas',
	},
	{
		code: '31121102',
		label: 'Objetos fundidos maquinados con troquel de aleaciones ferrosas',

		palabrasSimilares:
			'Piezas de aleaciones ferrosas fundidas en molde mecanizadas',
	},
	{
		code: '31121103',
		label: 'Objetos fundidos maquinados con troquel de acero',

		palabrasSimilares: 'Piezas de hierro fundidas en molde mecanizadas',
	},
	{
		code: '31121104',
		label: 'Objetos fundidos maquinados con troquel de acero inoxidable',

		palabrasSimilares:
			'Piezas de aleación no ferrosa fundidas en molde mecanizadas',
	},
	{
		code: '31121105',
		label: 'Objetos fundidos maquinados con troquel de hierro',

		palabrasSimilares:
			'Piezas de acero inoxidable fundidas en molde mecanizadas',
	},
	{
		code: '31121106',
		label: 'Objetos fundidos maquinados con troquel de aluminio',

		palabrasSimilares: 'Piezas de acero fundidas en molde mecanizadas',
	},
	{
		code: '31121107',
		label: 'Objetos fundidos maquinados con troquel de magnesio',

		palabrasSimilares: 'Piezas de magnesio fundidas en molde mecanizadas',
	},
	{
		code: '31121108',
		label: 'Objetos fundidos maquinados con troquel de titanio',

		palabrasSimilares: 'Piezas de zinc fundidas en molde mecanizadas',
	},
	{
		code: '31121109',
		label: 'Objetos fundidos maquinados con troquel de berilio',

		palabrasSimilares: 'Piezas de estaño fundidas en molde mecanizadas',
	},
	{
		code: '31121110',
		label: 'Objetos fundidos maquinados con troquel de cobre',

		palabrasSimilares: 'Piezas de titanio fundidas en molde mecanizadas',
	},
	{
		code: '31121111',
		label: 'Objetos fundidos maquinados con troquel de latón',

		palabrasSimilares: 'Piezas de berilio fundidas en molde mecanizadas',
	},
	{
		code: '31121112',
		label: 'Objetos fundidos maquinados con troquel de bronce',

		palabrasSimilares: 'Piezas de metal precioso fundidas en molde mecanizadas',
	},
	{
		code: '31121113',
		label: 'Objetos fundidos maquinados con troquel de zinc',

		palabrasSimilares: 'Piezas de cobre fundidas en molde mecanizadas',
	},
	{
		code: '31121114',
		label: 'Objetos fundidos maquinados con troquel de estaño',

		palabrasSimilares: 'Piezas de plomo fundidas en molde mecanizadas',
	},
	{
		code: '31121115',
		label: 'Objetos fundidos maquinados con troquel de plomo',

		palabrasSimilares: 'Piezas de latón fundidas en molde mecanizadas',
	},
	{
		code: '31121116',
		label: 'Objetos fundidos maquinados con troquel de metal precioso',

		palabrasSimilares: 'Piezas de bronce fundidas en molde mecanizadas',
	},
	{
		code: '31121117',
		label: 'Objetos fundidos maquinados con troquel de compuestos',

		palabrasSimilares: 'Piezas de compuestos fundidas en molde mecanizadas',
	},
	{
		code: '31121118',
		label: 'Objetos fundidos maquinados con troquel de aleación de níquel',

		palabrasSimilares:
			'Piezas de aleaciones de níquel fundidas en molde mecanizadas',
	},
	{
		code: '31121119',
		label: 'Objetos fundidos maquinados con troquel no metálico',

		palabrasSimilares: 'Piezas no metálicas fundidas en molde mecanizadas',
	},
	{
		code: '31121120',
		label:
			'Objetos de aluminio de alta presión fundidos maquinados con troquel',

		palabrasSimilares:
			'Piezas de aluminio fundidas a alta presión en molde mecanizadas',
	},
	{
		code: '31121121',
		label:
			'Ensamble de objetos de aluminio de alta presión fundidos maquinados con troquel',

		palabrasSimilares:
			'Ensamble de piezas de aluminio fundidas a alta presión en molde mecanizadas',
	},
	{
		code: '31121122',
		label:
			'Objetos de magnesio de alta presión fundidos maquinados con troquel',

		palabrasSimilares:
			'Piezas de magnesio fundidas a alta presión en molde mecanizadas',
	},
	{
		code: '31121123',
		label:
			'Ensamble de objetos de magnesio de alta presión fundidos maquinados con troquel',

		palabrasSimilares:
			'Ensamble de piezas de magnesio fundidas a alta presión en molde mecanizadas',
	},
	{
		code: '31121124',
		label: 'Objetos de zinc de alta presión fundidos maquinados con troquel',

		palabrasSimilares:
			'Piezas de zinc fundidas a alta presión en molde mecanizadas',
	},
	{
		code: '31121125',
		label:
			'Ensamble de objetos de zinc de alta presión fundidos maquinados con troquel',

		palabrasSimilares:
			'Ensamble de piezas de zinc fundidas a alta presión en molde mecanizadas',
	},
	{
		code: '31121200',
		label: 'Fundiciones en arena mecanizadas',
	},
	{
		code: '31121201',
		label: 'Objetos maquinados de aleación no ferrosa fundidos en arena',

		palabrasSimilares:
			'Piezas de aleación no ferrosa fundidas a la arena mecanizadas',
	},
	{
		code: '31121202',
		label: 'Objetos maquinados de aleación ferrosa fundidos en arena',

		palabrasSimilares:
			'Piezas de aleación ferrosa fundidas a la arena mecanizadas',
	},
	{
		code: '31121203',
		label: 'Objetos maquinados de acero fundidos en arena',

		palabrasSimilares: 'Piezas de acero fundidas a la arena mecanizadas',
	},
	{
		code: '31121204',
		label: 'Objetos maquinados de acero inoxidable fundidos en arena',

		palabrasSimilares:
			'Piezas de acero inoxidable fundidas a la arena mecanizadas',
	},
	{
		code: '31121205',
		label: 'Objetos maquinados de hierro fundidos en arena',

		palabrasSimilares: 'Piezas de hierro fundidas a la arena mecanizadas',
	},
	{
		code: '31121206',
		label: 'Objetos maquinados de aluminio fundidos en arena',

		palabrasSimilares: 'Piezas de aluminio fundidas a la arena mecanizadas',
	},
	{
		code: '31121207',
		label: 'Objetos maquinados de magnesio fundidos en arena',

		palabrasSimilares: 'Piezas de magnesio fundidas a la arena mecanizadas',
	},
	{
		code: '31121208',
		label: 'Objetos maquinados de titanio fundidos en arena',

		palabrasSimilares: 'Piezas de titanio fundidas a la arena mecanizadas',
	},
	{
		code: '31121209',
		label: 'Objetos maquinados de berilio fundidos en arena',

		palabrasSimilares: 'Piezas de berilio fundidas a la arena mecanizadas',
	},
	{
		code: '31121210',
		label: 'Objetos maquinados de cobre fundidos en arena',

		palabrasSimilares: 'Piezas de cobre fundidas a la arena mecanizadas',
	},
	{
		code: '31121211',
		label: 'Objetos maquinados de latón fundidos en arena',

		palabrasSimilares: 'Piezas de latón fundidas a la arena mecanizadas',
	},
	{
		code: '31121212',
		label: 'Objetos maquinados de bronce fundidos en arena',

		palabrasSimilares: 'Piezas de bronce fundidas a la arena mecanizadas',
	},
	{
		code: '31121213',
		label: 'Objetos maquinados de cinc fundidos en arena',

		palabrasSimilares: 'Piezas de zinc fundidas a la arena mecanizadas',
	},
	{
		code: '31121214',
		label: 'Objetos maquinados de estaño fundidos en arena',

		palabrasSimilares: 'Piezas de estaño fundidas a la arena mecanizadas',
	},
	{
		code: '31121215',
		label: 'Objetos maquinados de plomo fundidos en arena',

		palabrasSimilares: 'Piezas de plomo fundidas a la arena mecanizadas',
	},
	{
		code: '31121216',
		label: 'Objetos maquinados de metal precioso fundidos en arena',

		palabrasSimilares:
			'Piezas de metal precioso fundidas a la arena mecanizadas',
	},
	{
		code: '31121217',
		label: 'Objetos maquinados de compuestos fundidos en arena',

		palabrasSimilares: 'Piezas de compuesto fundidas a la arena mecanizadas',
	},
	{
		code: '31121218',
		label: 'Objetos maquinados de aleación de níquel fundidos en arena',

		palabrasSimilares:
			'Piezas de aleación de níquel fundidas a la arena mecanizadas',
	},
	{
		code: '31121219',
		label: 'Objetos maquinados no metálicos fundidos en arena',

		palabrasSimilares: 'Piezas no metálicas fundidas a la arena mecanizadas',
	},
	{
		code: '31121220',
		label: 'Objetos maquinados de aluminio fundidos en arena',

		palabrasSimilares: 'Pieza de aluminio fundida a la arena mecanizadas',
	},
	{
		code: '31121221',
		label: 'Ensamble de objetos maquinados de aluminio fundidos en arena',

		palabrasSimilares:
			'Ensamble de piezas de aluminio fundidos a la arena mecanizadas',
	},
	{
		code: '31121222',
		label: 'Objetos maquinados de hierro dúctil fundidos en arena',

		palabrasSimilares:
			'Piezas de hierro dúctil fundidos a la arena mecanizadas',
	},
	{
		code: '31121223',
		label: 'Ensamble de objetos maquinados de hierro dúctil fundidos en arena',

		palabrasSimilares:
			'Ensamble de piezas de hierro dúctil fundidos a la arena mecanizadas',
	},
	{
		code: '31121224',
		label: 'Objetos maquinados de hierro gris fundidos en arena',

		palabrasSimilares: 'Piezas de hierro gris fundidos a la arena mecanizadas',
	},
	{
		code: '31121225',
		label: 'Ensamble de objetos maquinados de hierro gris fundidos en arena',

		palabrasSimilares:
			'Ensamble de piezas de hierro gris fundidos a la arena mecanizadas',
	},
	{
		code: '31121226',
		label: 'Objetos maquinados de hierro maleable fundidos en arena',

		palabrasSimilares:
			'Piezas de hierro maleable fundidos a la arena mecanizadas',
	},
	{
		code: '31121227',
		label:
			'Ensamble de objetos maquinados de hierro maleable fundidos en arena',

		palabrasSimilares:
			'Ensamble de piezas de hierro maleable fundidos a la arena mecanizadas',
	},
	{
		code: '31121300',
		label: 'Fundiciones de molde permanente mecanizadas',
	},
	{
		code: '31121301',
		label:
			'Objetos maquinados en molde permanente de aleación no ferrosa fundidos',

		palabrasSimilares:
			'Piezas de aleación no ferrosa  fundidas en molde permanente mecanizadas',
	},
	{
		code: '31121302',
		label:
			'Objetos maquinados en molde permanente de aleación ferrosa fundidos',

		palabrasSimilares:
			'Piezas de aleación ferrosa  fundidas en molde permanente mecanizadas',
	},
	{
		code: '31121303',
		label: 'Objetos maquinados en molde permanente de acero fundidos',

		palabrasSimilares:
			'Piezas de acero fundidas en molde permanente mecanizadas',
	},
	{
		code: '31121304',
		label:
			'Objetos maquinados en molde permanente de acero inoxidable fundidos',

		palabrasSimilares:
			'Piezas de acero inoxidable fundidas en molde permanente mecanizadas',
	},
	{
		code: '31121305',
		label: 'Objetos maquinados en molde permanente de hierro fundidos',

		palabrasSimilares:
			'Piezas de hierro fundidas en molde permanente mecanizadas',
	},
	{
		code: '31121306',
		label: 'Objetos maquinados en molde permanente de aluminio fundidos',

		palabrasSimilares:
			'Piezas de aluminio fundidas en molde permanente mecanizadas',
	},
	{
		code: '31121307',
		label: 'Objetos maquinados en molde permanente de magnesio fundidos',

		palabrasSimilares:
			'Piezas de magnesio fundidas en molde permanente mecanizadas',
	},
	{
		code: '31121308',
		label: 'Objetos maquinados en molde permanente de titanio fundidos',

		palabrasSimilares:
			'Piezas de titanio fundidas en molde permanente mecanizadas',
	},
	{
		code: '31121309',
		label: 'Objetos maquinados en molde permanente de berilio fundidos',

		palabrasSimilares:
			'Piezas de berilio fundidas en molde permanente mecanizadas',
	},
	{
		code: '31121310',
		label: 'Objetos maquinados en molde permanente de cobre fundidos',

		palabrasSimilares:
			'Piezas de cobre fundidas en molde permanente mecanizadas',
	},
	{
		code: '31121311',
		label: 'Objetos maquinados en molde permanente de latón fundidos',

		palabrasSimilares:
			'Piezas de latón fundidas en molde permanente mecanizadas',
	},
	{
		code: '31121312',
		label: 'Objetos maquinados en molde permanente de bronce fundidos',

		palabrasSimilares:
			'Piezas de bronce  fundidas en molde permanente mecanizadas',
	},
	{
		code: '31121313',
		label: 'Objetos maquinados en molde permanente de cinc fundidos',

		palabrasSimilares:
			'Piezas de zinc  fundidas en molde permanente mecanizadas',
	},
	{
		code: '31121314',
		label: 'Objetos maquinados en molde permanente de estaño fundidos',

		palabrasSimilares:
			'Piezas de estaño fundidas en molde permanente mecanizadas',
	},
	{
		code: '31121315',
		label: 'Objetos maquinados en molde permanente de plomo fundidos',

		palabrasSimilares:
			'Piezas de plomo fundidas en molde permanente mecanizadas',
	},
	{
		code: '31121316',
		label: 'Objetos maquinados en molde permanente de metal precioso fundidos',

		palabrasSimilares:
			'Piezas de metal precioso fundidas en molde permanente mecanizadas',
	},
	{
		code: '31121317',
		label: 'Objetos maquinados en molde permanente de compuestos fundidos',

		palabrasSimilares:
			'Piezas de compuestos  fundidas en molde permanente mecanizadas',
	},
	{
		code: '31121318',
		label:
			'Objetos maquinados en molde permanente de aleación de níquel fundidos',

		palabrasSimilares:
			'Piezas de aleación de níquel  fundidas en molde permanente mecanizadas',
	},
	{
		code: '31121319',
		label: 'Objetos maquinados en molde permanente no metálicos fundidos',

		palabrasSimilares:
			'Piezas no metálicas  fundidas en molde permanente mecanizadas',
	},
	{
		code: '31121320',
		label:
			'Objetos maquinados en molde permanente de aluminio de baja presión fundidos',

		palabrasSimilares:
			'Piezas de aluminio fundidas a baja presión en molde permanente mecanizadas',
	},
	{
		code: '31121321',
		label:
			'Ensamble de objetos maquinados en molde permanente de aluminio de baja presión fundidos',

		palabrasSimilares:
			'Ensamble de piezas de aluminio fundidas a baja presión en molde permanente mecanizadas',
	},
	{
		code: '31121400',
		label: 'Fundiciones de molde de yeso mecanizadas',
	},
	{
		code: '31121401',
		label:
			'Objetos maquinados de aleación no ferrosa fundidos en molde de yeso',

		palabrasSimilares:
			'Piezas de aleación no ferrosa fundidas en molde de yeso mecanizadas',
	},
	{
		code: '31121402',
		label: 'Objetos maquinados de aleación ferrosa fundidos en molde de yeso',

		palabrasSimilares:
			'Piezas de aleación ferrosa fundidas en molde de yeso mecanizadas',
	},
	{
		code: '31121403',
		label: 'Objetos maquinados de acero fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de acero fundidas en molde de yeso mecanizadas',
	},
	{
		code: '31121404',
		label: 'Objetos maquinados de acero inoxidable fundidos en molde de yeso',

		palabrasSimilares:
			'Piezas de acero inoxidable fundidas en molde de yeso mecanizadas',
	},
	{
		code: '31121405',
		label: 'Objetos maquinados de hierro fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de hierro fundidas en molde de yeso mecanizadas',
	},
	{
		code: '31121406',
		label: 'Objetos maquinados de aluminio fundidos en molde de yeso',

		palabrasSimilares:
			'Piezas de aluminio fundidas en molde de yeso mecanizadas',
	},
	{
		code: '31121407',
		label: 'Objetos maquinados de magnesio fundidos en molde de yeso',

		palabrasSimilares:
			'Piezas de magnesio fundidas en molde de yeso mecanizadas',
	},
	{
		code: '31121408',
		label: 'Objetos maquinados de titanio fundidos en molde de yeso',

		palabrasSimilares:
			'Piezas de titanio fundidas en molde de yeso mecanizadas',
	},
	{
		code: '31121409',
		label: 'Objetos maquinados de berilio fundidos en molde de yeso',

		palabrasSimilares:
			'Piezas de berilio fundidas en molde de yeso mecanizadas',
	},
	{
		code: '31121410',
		label: 'Objetos maquinados de cobre fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de cobre fundidas en molde de yeso mecanizadas',
	},
	{
		code: '31121411',
		label: 'Objetos maquinados de latón fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de latón fundidas en molde de yeso mecanizadas',
	},
	{
		code: '31121412',
		label: 'Objetos maquinados de bronce fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de bronce fundidas en molde de yeso mecanizadas',
	},
	{
		code: '31121413',
		label: 'Objetos maquinados de cinc fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de zinc fundidas en molde de yeso mecanizadas',
	},
	{
		code: '31121414',
		label: 'Objetos maquinados de estaño fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de estaño fundidas en molde de yeso mecanizadas',
	},
	{
		code: '31121415',
		label: 'Objetos maquinados de plomo fundidos en molde de yeso',

		palabrasSimilares: 'Piezas de plomo fundidas en molde de yeso mecanizadas',
	},
	{
		code: '31121416',
		label: 'Objetos maquinados de metal precioso fundidos en molde de yeso',

		palabrasSimilares:
			'Piezas de metal precioso fundidas en molde de yeso mecanizadas',
	},
	{
		code: '31121417',
		label: 'Objetos maquinados de compuestos fundidos en molde de yeso',

		palabrasSimilares:
			'Piezas de compuestos fundidas en molde de yeso mecanizadas',
	},
	{
		code: '31121418',
		label: 'Objetos maquinados de aleación de níquel fundidos en molde de yeso',

		palabrasSimilares:
			'Piezas de aleación de níquel fundidas en molde de yeso mecanizadas',
	},
	{
		code: '31121419',
		label: 'Objetos maquinados no metálicos fundidos en molde de yeso',

		palabrasSimilares:
			'Piezas no metálicas fundidas en molde de yeso mecanizadas',
	},
	{
		code: '31121500',
		label: 'Fundiciones de molde de concha',
	},
	{
		code: '31121501',
		label:
			'Objetos maquinados de aleación no ferrosa fundidos en molde en concha',

		palabrasSimilares:
			'Piezas de aleación no ferrosa fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121502',
		label: 'Objetos maquinados de aleación ferrosa fundidos en molde en concha',

		palabrasSimilares:
			'Piezas de aleación ferrosa fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121503',
		label: 'Objetos maquinados de acero fundidos en molde en concha',

		palabrasSimilares:
			'Piezas de acero fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121504',
		label: 'Objetos maquinados de acero inoxidable fundidos en molde en concha',

		palabrasSimilares:
			'Piezas de acero inoxidable fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121505',
		label: 'Objetos maquinados de hierro fundidos en molde en concha',

		palabrasSimilares:
			'Piezas de hierro fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121506',
		label: 'Objetos maquinados de aluminio fundidos en molde en concha',

		palabrasSimilares:
			'Piezas de aluminio fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121507',
		label: 'Objetos maquinados de magnesio fundidos en molde en concha',

		palabrasSimilares:
			'Piezas de magnesio fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121508',
		label: 'Objetos maquinados de titanio fundidos en molde en concha',

		palabrasSimilares:
			'Piezas de titanio fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121509',
		label: 'Objetos maquinados de berilio fundidos en molde en concha',

		palabrasSimilares:
			'Piezas de berilio fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121510',
		label: 'Objetos maquinados de cobre fundidos en molde en concha',

		palabrasSimilares:
			'Piezas de cobre fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121511',
		label: 'Objetos maquinados de latón fundidos en molde en concha',

		palabrasSimilares:
			'Piezas de latón fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121512',
		label: 'Objetos maquinados de bronce fundidos en molde en concha',

		palabrasSimilares:
			'Piezas de bronce fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121513',
		label: 'Objetos maquinados de cinc fundidos en molde en concha',

		palabrasSimilares:
			'Piezas de zinc fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121514',
		label: 'Objetos maquinados de estaño fundidos en molde en concha',

		palabrasSimilares:
			'Piezas de estaño fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121515',
		label: 'Objetos maquinados de plomo fundidos en molde en concha',

		palabrasSimilares:
			'Piezas de plomo fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121516',
		label: 'Objetos maquinados de metal precioso fundidos en molde en concha',

		palabrasSimilares:
			'Piezas de metal precioso fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121517',
		label: 'Objetos maquinados de compuestos fundidos en molde en concha',

		palabrasSimilares:
			'Piezas de compuestos fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121518',
		label:
			'Objetos maquinados de aleación de níquel fundidos en molde en concha',

		palabrasSimilares:
			'Piezas de aleación de níquel fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121519',
		label: 'Objetos maquinados no metálicos fundidos en molde en concha',

		palabrasSimilares:
			'Piezas no metálicas fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121520',
		label: 'Objetos maquinados de hierro dúctil fundidos en molde en concha',

		palabrasSimilares:
			'Piezas de hierro dúctil fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121521',
		label:
			'Ensamble de objetos maquinados de hierro dúctil fundidos en molde en concha',

		palabrasSimilares:
			'Ensamble de piezas de hierro dúctil fundidas en molde de cáscara mecanizadas',
	},
	{
		code: '31121600',
		label: 'Piezas fundidas mecanizadas de inversión',
	},
	{
		code: '31121601',
		label:
			'Objetos maquinados de aleación no ferrosa fundidos a la cera perdida',

		palabrasSimilares:
			'Piezas de aleación no ferrosa fundidas a la cera perdida mecanizadas',
	},
	{
		code: '31121602',
		label: 'Objetos maquinados de aleación ferrosa fundidos a la cera perdida',

		palabrasSimilares:
			'Piezas de aleación ferrosa fundidas a la cera perdida mecanizadas',
	},
	{
		code: '31121603',
		label: 'Objetos maquinados de acero fundidos a la cera perdida',

		palabrasSimilares: 'Piezas de acero fundidas a la cera perdida mecanizadas',
	},
	{
		code: '31121604',
		label: 'Objetos maquinados de acero inoxidable fundidos a la cera perdida',

		palabrasSimilares:
			'Piezas de acero inoxidable fundidas a la cera perdida mecanizadas',
	},
	{
		code: '31121605',
		label: 'Objetos maquinados de hierro fundidos a la cera perdida',

		palabrasSimilares:
			'Piezas de hierro fundidas a la cera perdida mecanizadas',
	},
	{
		code: '31121606',
		label: 'Objetos maquinados de aluminio fundidos a la cera perdida',

		palabrasSimilares:
			'Piezas de aluminio fundidas a la cera perdida mecanizadas',
	},
	{
		code: '31121607',
		label: 'Objetos maquinados de magnesio fundidos a la cera perdida',

		palabrasSimilares:
			'Piezas de magnesio fundidas a la cera perdida mecanizadas',
	},
	{
		code: '31121608',
		label: 'Objetos maquinados de zinc fundidos a la cera perdida',

		palabrasSimilares: 'Piezas de zinc fundidas a la cera perdida mecanizadas',
	},
	{
		code: '31121609',
		label: 'Objetos maquinados de estaño fundidos a la cera perdida',

		palabrasSimilares:
			'Piezas de estaño fundidas a la cera perdida mecanizadas',
	},
	{
		code: '31121610',
		label: 'Objetos maquinados de plomo fundidos a la cera perdida',

		palabrasSimilares: 'Piezas de plomo fundidas a la cera perdida mecanizadas',
	},
	{
		code: '31121611',
		label: 'Objetos maquinados de metales preciosos fundidos a la cera perdida',

		palabrasSimilares:
			'Piezas de metal precioso fundidas a la cera perdida mecanizadas',
	},
	{
		code: '31121612',
		label: 'Objetos maquinados de titanio fundidos a la cera perdida',

		palabrasSimilares:
			'Piezas de titanio fundidas a la cera perdida mecanizadas',
	},
	{
		code: '31121613',
		label: 'Objetos maquinados compuestos fundidos a la cera perdida',

		palabrasSimilares:
			'Piezas de compuestos fundidas a la cera perdida mecanizadas',
	},
	{
		code: '31121614',
		label:
			'Objetos maquinados de aleación de níquel fundidos a la cera perdida',

		palabrasSimilares:
			'Piezas de aleación de níquel fundidas a la cera perdida mecanizadas',
	},
	{
		code: '31121615',
		label: 'Objetos maquinados no metálicos fundidos a la cera perdida',

		palabrasSimilares:
			'Piezas no metálicas fundidas a la cera perdida mecanizadas',
	},
	{
		code: '31121616',
		label: 'Ensamble de objetos maquinados aluminio fundidos a la cera perdida',

		palabrasSimilares:
			'Ensamble de piezas de acero inoxidable  fundidas a la cera perdida mecanizadas',
	},
	{
		code: '31121617',
		label: 'Objetos maquinados de aleación de acero fundidos a la cera perdida',

		palabrasSimilares:
			'Piezas de aleación de acero fundidas a la cera perdida mecanizadas',
	},
	{
		code: '31121618',
		label:
			'Ensamble de objetos maquinados aleación de acero fundidos a la cera perdida',

		palabrasSimilares:
			'Ensamble de piezas de aleación acero fundidas a la cera perdida mecanizadas',
	},
	{
		code: '31121700',
		label: 'Piezas fundidas centrífugas mecanizadas',
	},
	{
		code: '31121701',
		label: 'Objetos maquinados centrifugados de aleación no ferrosa fundidos',

		palabrasSimilares:
			'Piezas de aleación no ferrosa hechas mediante fundición centrífuga mecanizadas',
	},
	{
		code: '31121702',
		label: 'Objetos maquinados centrifugados de aleación ferrosa fundidos',

		palabrasSimilares:
			'Piezas de aleación ferrosa hechas mediante fundición centrífuga mecanizadas',
	},
	{
		code: '31121703',
		label: 'Objetos maquinados centrifugados de acero fundidos',

		palabrasSimilares:
			'Piezas de acero hechas mediante fundición centrífuga mecanizadas',
	},
	{
		code: '31121704',
		label: 'Objetos maquinados centrifugados de acero inoxidable fundidos',

		palabrasSimilares:
			'Piezas de acero inoxidable hechas mediante fundición centrífuga mecanizadas',
	},
	{
		code: '31121705',
		label: 'Objetos maquinados centrifugados de hierro fundidos',

		palabrasSimilares:
			'Piezas de hierro hechas mediante fundición centrífuga mecanizadas',
	},
	{
		code: '31121706',
		label: 'Objetos maquinados centrifugados de aluminio fundidos',

		palabrasSimilares:
			'Piezas de aluminio hechas mediante fundición centrífuga mecanizadas',
	},
	{
		code: '31121707',
		label: 'Objetos maquinados centrifugados de magnesio fundidos',

		palabrasSimilares:
			'Piezas de magnesio hechas mediante fundición centrífuga mecanizadas',
	},
	{
		code: '31121708',
		label: 'Objetos maquinados centrifugados de titanio fundidos',

		palabrasSimilares:
			'Piezas de titanio hechas mediante fundición centrífuga mecanizadas',
	},
	{
		code: '31121709',
		label: 'Objetos maquinados centrifugados de berilio fundidos',

		palabrasSimilares:
			'Piezas de berilio hechas mediante fundición centrífuga mecanizadas',
	},
	{
		code: '31121710',
		label: 'Objetos maquinados centrifugados de cobre fundidos',

		palabrasSimilares:
			'Piezas de cobre hechas mediante fundición centrífuga mecanizadas',
	},
	{
		code: '31121711',
		label: 'Objetos maquinados centrifugados de latón fundidos',

		palabrasSimilares:
			'Piezas de latón hechas mediante fundición centrífuga mecanizadas',
	},
	{
		code: '31121712',
		label: 'Objetos maquinados centrifugados de bronce fundidos',

		palabrasSimilares:
			'Piezas de bronce hechas mediante fundición centrífuga mecanizadas',
	},
	{
		code: '31121713',
		label: 'Objetos maquinados centrifugados de cinc fundidos',

		palabrasSimilares:
			'Piezas de zinc hechas mediante fundición centrífuga mecanizadas',
	},
	{
		code: '31121714',
		label: 'Objetos maquinados centrifugados de estaño fundidos',

		palabrasSimilares:
			'Piezas de estaño hechas mediante fundición centrífuga mecanizadas',
	},
	{
		code: '31121715',
		label: 'Objetos maquinados centrifugados de plomo fundidos',

		palabrasSimilares:
			'Piezas de plomo hechas mediante fundición centrífuga mecanizadas',
	},
	{
		code: '31121716',
		label: 'Objetos maquinados centrifugados de metal precioso fundidos',

		palabrasSimilares:
			'Piezas de metal precioso hechas mediante fundición centrífuga mecanizadas',
	},
	{
		code: '31121717',
		label: 'Objetos maquinados centrifugados de compuestos fundidos',

		palabrasSimilares:
			'Piezas de compuesto hechas mediante fundición centrífuga mecanizadas',
	},
	{
		code: '31121718',
		label: 'Objetos maquinados centrifugados de aleación de níquel fundidos',

		palabrasSimilares:
			'Piezas de aleación de níquel hechas mediante fundición centrífuga mecanizadas',
	},
	{
		code: '31121719',
		label: 'Objetos maquinados centrifugados no metálicos fundidos',

		palabrasSimilares:
			'Piezas no metálicas hechas mediante fundición centrífuga mecanizadas',
	},
	{
		code: '31121800',
		label: 'Fundiciones labradas de molde de cerámica',
	},
	{
		code: '31121801',
		label:
			'Objetos maquinados de aleación no ferrosa fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de aleación no ferrosa fundidas en molde de cerámica mecanizadas',
	},
	{
		code: '31121802',
		label: 'Objetos maquinados de aleación ferrosa fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de aleación ferrosa fundidas en molde de cerámica mecanizadas',
	},
	{
		code: '31121803',
		label: 'Objetos maquinados de acero fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de acero fundidas en molde de cerámica mecanizadas',
	},
	{
		code: '31121804',
		label: 'Objetos maquinados de acero inoxidable fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de acero inoxidable fundidas en molde de cerámica mecanizadas',
	},
	{
		code: '31121805',
		label: 'Objetos maquinados de hierro fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de hierro fundidas en molde de cerámica mecanizadas',
	},
	{
		code: '31121806',
		label: 'Objetos maquinados de aluminio fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de aluminio fundidas en molde de cerámica mecanizadas',
	},
	{
		code: '31121807',
		label: 'Objetos maquinados de magnesio fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de magnesio fundidas en molde de cerámica mecanizadas',
	},
	{
		code: '31121808',
		label: 'Objetos maquinados de titanio fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de titanio fundidas en molde de cerámica mecanizadas',
	},
	{
		code: '31121809',
		label: 'Objetos maquinados de berilio fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de berilio fundidas en molde de cerámica mecanizadas',
	},
	{
		code: '31121810',
		label: 'Objetos maquinados de cobre fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de cobre fundidas en molde de cerámica mecanizadas',
	},
	{
		code: '31121811',
		label: 'Objetos maquinados de latón fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de latón fundidas en molde de cerámica mecanizadas',
	},
	{
		code: '31121812',
		label: 'Objetos maquinados de bronce fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de bronce fundidas en molde de cerámica mecanizadas',
	},
	{
		code: '31121813',
		label: 'Objetos maquinados de cinc fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de zinc fundidas en molde de cerámica mecanizadas',
	},
	{
		code: '31121814',
		label: 'Objetos maquinados de estaño fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de estaño fundidas en molde de cerámica mecanizadas',
	},
	{
		code: '31121815',
		label: 'Objetos maquinados de plomo fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de plomo fundidas en molde de cerámica mecanizadas',
	},
	{
		code: '31121816',
		label: 'Objetos maquinados de metal precioso fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de metal precioso fundidas en molde de cerámica mecanizadas',
	},
	{
		code: '31121817',
		label: 'Objetos maquinados de compuestos fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de compuesto fundidas en molde de cerámica mecanizadas',
	},
	{
		code: '31121818',
		label:
			'Objetos maquinados de aleación de níquel fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas de aleación de níquel fundidas en molde de cerámica mecanizadas',
	},
	{
		code: '31121819',
		label: 'Objetos maquinados no metálicos fundidos en molde cerámico',

		palabrasSimilares:
			'Piezas no metálicas fundidas en molde de cerámica mecanizadas',
	},
	{
		code: '31121900',
		label: 'Fundiciones labradas de molde de grafito',
	},
	{
		code: '31121901',
		label:
			'Objetos maquinados de aleación no ferrosa fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de aleación no ferrosa fundidas en molde de grafito mecanizadas',
	},
	{
		code: '31121902',
		label:
			'Objetos maquinados de aleación ferrosa fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de aleación ferrosa fundidas en molde de grafito mecanizadas',
	},
	{
		code: '31121903',
		label: 'Objetos maquinados de acero fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de acero fundidas en molde de grafito mecanizadas',
	},
	{
		code: '31121904',
		label:
			'Objetos maquinados de acero inoxidable fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de acero inoxidable fundidas en molde de grafito mecanizadas',
	},
	{
		code: '31121905',
		label: 'Objetos maquinados de hierro fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de hierro fundidas en molde de grafito mecanizadas',
	},
	{
		code: '31121906',
		label: 'Objetos maquinados de aluminio fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de aluminio fundidas en molde de grafito mecanizadas',
	},
	{
		code: '31121907',
		label: 'Objetos maquinados de magnesio fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de magnesio fundidas en molde de grafito mecanizadas',
	},
	{
		code: '31121908',
		label: 'Objetos maquinados de titanio fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de titanio fundidas en molde de grafito mecanizadas',
	},
	{
		code: '31121909',
		label: 'Objetos maquinados de berilio fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de berilio fundidas en molde de grafito mecanizadas',
	},
	{
		code: '31121910',
		label: 'Objetos maquinados de cobre fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de cobre fundidas en molde de grafito mecanizadas',
	},
	{
		code: '31121911',
		label: 'Objetos maquinados de latón fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de latón fundidas en molde de grafito mecanizadas',
	},
	{
		code: '31121912',
		label: 'Objetos maquinados de bronce fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de bronce fundidas en molde de grafito mecanizadas',
	},
	{
		code: '31121913',
		label: 'Objetos maquinados de cinc fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de zinc fundidas en molde de grafito mecanizadas',
	},
	{
		code: '31121914',
		label: 'Objetos maquinados de estaño fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de estaño fundidas en molde de grafito mecanizadas',
	},
	{
		code: '31121915',
		label: 'Objetos maquinados de plomo fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de plomo fundidas en molde de grafito mecanizadas',
	},
	{
		code: '31121916',
		label: 'Objetos maquinados de metal precioso fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de metal precioso fundidas en molde de grafito mecanizadas',
	},
	{
		code: '31121917',
		label: 'Objetos maquinados de compuestos fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de compuesto fundidas en molde de grafito mecanizadas',
	},
	{
		code: '31121918',
		label:
			'Objetos maquinados de aleación de níquel fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas de aleación de níquel fundidas en molde de grafito mecanizadas',
	},
	{
		code: '31121919',
		label: 'Objetos maquinados no metálicos fundidos en molde de grafito',

		palabrasSimilares:
			'Piezas no metálicas fundidas en molde de grafito mecanizadas',
	},
	{
		code: '31122000',
		label: 'Fundiciones y ensambles de fundiciones maquinadas por apriete',
	},
	{
		code: '31122001',
		label: 'Objetos maquinados de aluminio moldeados por apriete (squeeze)',

		palabrasSimilares: 'Piezas de aluminio fundidas a presión mecanizadas',
	},
	{
		code: '31122002',
		label:
			'Ensamble de objetos maquinados de aluminio moldeados por apriete (squeeze)',

		palabrasSimilares:
			'Ensamble de piezas de aluminio fundidas a presión mecanizadas',
	},
	{
		code: '31122100',
		label: 'Fundiciones y ensambles de fundiciones maquinadas semi - sólidas',
	},
	{
		code: '31122101',
		label: 'Objetos maquinados de aluminio moldeados semisólidos',

		palabrasSimilares: 'Piezas de aluminio fundidas semi - solidas mecanizadas',
	},
	{
		code: '31122102',
		label: 'Ensamble de objetos maquinados de aluminio moldeados semisólidos',

		palabrasSimilares:
			'Ensamble de piezas de aluminio fundidas semi - solidas mecanizadas',
	},
	{
		code: '31122200',
		label:
			'Fundiciones y ensambles de fundiciones maquinadas por gravedad permanente',
	},
	{
		code: '31122201',
		label: 'Objetos maquinados de aluminio moldeados por gravedad permanente',

		palabrasSimilares:
			'Piezas de aluminio fundidas en moldes permanentes a gravedad mecanizadas',
	},
	{
		code: '31122202',
		label:
			'Ensamble de objetos maquinados de aluminio moldeados por gravedad permanente',

		palabrasSimilares:
			'Ensamble de piezas de aluminio fundidas en moldes permanentes a gravedad mecanizadas',
	},
	{
		code: '31132000',
		label: 'Componentes de metal en polvo',
	},
	{
		code: '31132001',
		label: 'Componentes de metal ferroso pulverizado',

		palabrasSimilares: 'Materia de metal ferroso en polvo',
	},
	{
		code: '31132002',
		label: 'Partes de metal no ferroso pulverizado',

		palabrasSimilares:
			'Fracción de metal no ferroso en polvo, Pedazo de metal no ferroso en polvo, Trozo de metal no ferroso en polv',
	},
	{
		code: '31132100',
		label: 'Forjas de acero',

		palabrasSimilares: 'Fragua de acero',
	},
	{
		code: '31132101',
		label: 'Forja de acero maquinado forjado en frío',

		palabrasSimilares: 'Fragua de acero forjado en frío mecanizado',
	},
	{
		code: '31132102',
		label: 'Forja de acero tratado con calor forjado en frío',

		palabrasSimilares: 'Fragua de acero tratado con calor forjado en frío',
	},
	{
		code: '31132103',
		label: 'Forja de acero maquinado y tratado con calor forjado en frío',

		palabrasSimilares:
			'Fragua de acero tratado con calor forjado en frío mecanizado',
	},
	{
		code: '31132104',
		label:
			'Forja de acero tratado con calor y dimensionado en frío forjado en frío',

		palabrasSimilares:
			'Fragua de acero tratado con calor forjado y dimensionado en frío',
	},
	{
		code: '31132105',
		label: 'Forja de acero maquinado forjado en tibio',

		palabrasSimilares: 'Fragua de acero forjado en tibio mecanizado',
	},
	{
		code: '31132106',
		label: 'Forja de acero tratado con calor forjado en tibio',

		palabrasSimilares: 'Fragua de acero tratado con calor forjado en tibio',
	},
	{
		code: '31132107',
		label: 'Forja de acero maquinado y tratado con calor forjado en tibio',

		palabrasSimilares:
			'Fragua de acero tratado con calor forjado en tibio mecanizado',
	},
	{
		code: '31132108',
		label:
			'Forja de acero tratado con calor y dimensionado en frío forjado en tibio',

		palabrasSimilares:
			'Fragua de acero tratado con calor forjado en tibio y dimensionado en frío',
	},
	{
		code: '31132109',
		label: 'Forja de acero maquinado forjado en caliente',

		palabrasSimilares: 'Fragua de acero forjado en caliente mecanizado',
	},
	{
		code: '31132110',
		label: 'Forja de acero tratado con calor forjado en caliente',

		palabrasSimilares: 'Fragua de acero tratado y forjado con calor',
	},
	{
		code: '31132111',
		label: 'Forja de acero maquinado y tratado con calor forjado en caliente',

		palabrasSimilares: 'Fragua de acero tratado y forjado con calor mecanizado',
	},
	{
		code: '31132112',
		label:
			'Forja de acero tratado con calor y dimensionado en frío forjado en caliente',

		palabrasSimilares:
			'Fragua de acero tratado y forjado con calor y dimensionado en frío',
	},
	{
		code: '31132200',
		label: 'Forjas de latón',

		palabrasSimilares: 'Fragua de latón',
	},
	{
		code: '31132201',
		label: 'Forja de latón maquinado forjado en frío',

		palabrasSimilares: 'Fragua de latón forjado en frío mecanizado',
	},
	{
		code: '31132202',
		label: 'Forja de latón tratado con calor forjado en frío',

		palabrasSimilares: 'Fragua de latón tratado con calor forjado en frío',
	},
	{
		code: '31132203',
		label: 'Forja de latón maquinado y tratado con calor forjado en frío',

		palabrasSimilares:
			'Fragua de latón tratado con calor forjado en frío mecanizado',
	},
	{
		code: '31132204',
		label:
			'Forja de latón tratado con calor y dimensionado en frío forjado en frío',

		palabrasSimilares:
			'Fragua de latón tratado con calor forjado y dimensionado en frío',
	},
	{
		code: '31132205',
		label: 'Forja de latón maquinado forjado en tibio',

		palabrasSimilares: 'Fragua de latón forjado en tibio mecanizado',
	},
	{
		code: '31132206',
		label: 'Forja de latón tratado con calor forjado en tibio',

		palabrasSimilares: 'Fragua de latón tratado con calor forjado en tibio',
	},
	{
		code: '31132207',
		label: 'Forja de latón maquinado y tratado con calor forjado en tibio',

		palabrasSimilares:
			'Fragua de latón tratado con calor forjado en tibio mecanizado',
	},
	{
		code: '31132208',
		label:
			'Forja de latón tratado con calor y dimensionado en frío forjado en tibio',

		palabrasSimilares:
			'Fragua de latón tratado con calor forjado en tibio y dimensionado en frío',
	},
	{
		code: '31132300',
		label: 'Forjas de aluminio',

		palabrasSimilares: 'Fragua de aluminio',
	},
	{
		code: '31132301',
		label: 'Forja de aluminio maquinado forjado en frío',

		palabrasSimilares: 'Fragua de aluminio forjado en frío mecanizado',
	},
	{
		code: '31132302',
		label: 'Forja de aluminio tratado con calor forjado en frío',

		palabrasSimilares: 'Fragua de aluminio tratado con calor forjado en frío',
	},
	{
		code: '31132303',
		label: 'Forja de aluminio maquinado y tratado con calor forjado en frío',

		palabrasSimilares:
			'Fragua de aluminio tratado con calor forjado en frío mecanizado',
	},
	{
		code: '31132304',
		label:
			'Forja de aluminio tratado con calor y dimensionado en frío forjado en frío',

		palabrasSimilares:
			'Fragua de aluminio tratado con calor forjado en frío y dimensionado en frío',
	},
	{
		code: '31132305',
		label: 'Forja de aluminio maquinado forjado en tibio',

		palabrasSimilares: 'Fragua de aluminio forjado en tibio mecanizado',
	},
	{
		code: '31132306',
		label: 'Forja de aluminio tratado con calor forjado en tibio',

		palabrasSimilares: 'Fragua de aluminio tratado con calor forjado en tibio',
	},
	{
		code: '31132307',
		label: 'Forja de aluminio maquinado y tratado con calor forjado en tibio',

		palabrasSimilares:
			'Fragua de aluminio tratado con calor forjado en tibio mecanizado',
	},
	{
		code: '31132308',
		label:
			'Forja de aluminio tratado con calor y dimensionado en frío forjado en tibio',

		palabrasSimilares:
			'Fragua de aluminio tratado con calor forjado en tibio y dimensionado en frío',
	},
	{
		code: '31132400',
		label: 'Forjas de aleaciones no ferrosas',

		palabrasSimilares: 'Fragua de aleaciones no ferrosas',
	},
	{
		code: '31132401',
		label: 'Forja de aleación no ferrosa maquinado forjado en frío',

		palabrasSimilares:
			'Fragua de aleaciones no ferrosas forjadas en frío mecanizadas',
	},
	{
		code: '31132402',
		label: 'Forja de aleación no ferrosa tratado con calor forjado en frío',

		palabrasSimilares:
			'Fragua de aleaciones no ferrosas tratadas con calor forjadas en frío',
	},
	{
		code: '31132403',
		label:
			'Forja de aleación no ferrosa maquinado y tratado con calor forjado en frío',

		palabrasSimilares:
			'Fragua de aleaciones no ferrosas tratadas con calor forjadas en frío mecanizadas',
	},
	{
		code: '31132404',
		label:
			'Forja de aleación no ferrosa tratado con calor y dimensionado en frío forjado en frío',

		palabrasSimilares:
			'Fragua de aleaciones no ferrosas tratadas con calor forjadas y dimensionadas en frío',
	},
	{
		code: '31132405',
		label: 'Forja de aleación no ferrosa maquinado forjado en tibio',

		palabrasSimilares:
			'Fragua de aleaciones no ferrosas forjadas en tibio mecanizadas',
	},
	{
		code: '31132406',
		label: 'Forja de aleación no ferrosa tratado con calor forjado en tibio',

		palabrasSimilares:
			'Fragua de aleaciones no ferrosas tratadas con calor forjadas en tibio',
	},
	{
		code: '31132407',
		label:
			'Forja de aleación no ferrosa maquinado y tratado con calor forjado en tibio',

		palabrasSimilares:
			'Fragua de aleaciones no ferrosas tratadas con calor forjadas en tibio mecanizadas',
	},
	{
		code: '31132408',
		label:
			'Forja de aleación no ferrosa tratado con calor y dimensionado en frío forjado en tibio',

		palabrasSimilares:
			'Fragua de aleaciones no ferrosas tratadas con calor forjadas en tibio y dimensionadas en frío',
	},
	{
		code: '31132409',
		label: 'Forja de aleación no ferrosa maquinado forjado en caliente',

		palabrasSimilares:
			'Fragua de aleaciones no ferrosas forjadas en calor mecanizadas',
	},
	{
		code: '31132410',
		label: 'Forja de aleación no ferrosa tratado con calor forjado en caliente',

		palabrasSimilares:
			'Fragua de aleaciones no ferrosas tratadas y forjadas con calor',
	},
	{
		code: '31132411',
		label:
			'Forja de aleación no ferrosa maquinado y tratado con calor forjado en caliente',

		palabrasSimilares:
			'Fragua de aleaciones no ferrosas tratadas y forjadas con calor mecanizadas',
	},
	{
		code: '31132412',
		label:
			'Forja de aleación no ferrosa tratado con calor y dimensionado en frío forjado en caliente',

		palabrasSimilares:
			'Fragua de aleaciones no ferrosas tratadas y forjadas con calor y dimensionadas en frío',
	},
	{
		code: '31132500',
		label: 'Forjas de aleaciones ferrosas',

		palabrasSimilares: 'Fragua de aleaciones ferrosas',
	},
	{
		code: '31132501',
		label: 'Forja de aleación ferrosa maquinado forjado en frío',

		palabrasSimilares:
			'Fragua de aleaciones ferrosas forjadas en frío mecanizadas',
	},
	{
		code: '31132502',
		label: 'Forja de aleación ferrosa tratado con calor forjado en frío',

		palabrasSimilares:
			'Fragua de aleaciones ferrosas tratadas con calor forjadas en frío',
	},
	{
		code: '31132503',
		label:
			'Forja de aleación ferrosa maquinado y tratado con calor forjado en frío',

		palabrasSimilares:
			'Fragua de aleaciones ferrosas tratadas con calor forjadas en frío mecanizadas',
	},
	{
		code: '31132504',
		label:
			'Forja de aleación ferrosa tratado con calor y dimensionado en frío forjado en frío',

		palabrasSimilares:
			'Fragua de aleaciones ferrosas tratadas con calor forjadas y dimensionadas en frío',
	},
	{
		code: '31132505',
		label: 'Forja de aleación ferrosa maquinado forjado en tibio',

		palabrasSimilares:
			'Fragua de aleaciones ferrosas forjadas en tibio mecanizadas',
	},
	{
		code: '31132506',
		label: 'Forja de aleación ferrosa tratado con calor forjado en tibio',

		palabrasSimilares:
			'Fragua de aleaciones ferrosas tratadas con calor forjadas en tibio',
	},
	{
		code: '31132507',
		label:
			'Forja de aleación ferrosa maquinado y tratado con calor forjado en tibio',

		palabrasSimilares:
			'Fragua de aleaciones ferrosas tratadas con calor forjadas en tibio mecanizadas',
	},
	{
		code: '31132508',
		label:
			'Forja de aleación ferrosa tratado con calor y dimensionado en frío forjado en tibio',

		palabrasSimilares:
			'Fragua de aleaciones ferrosas tratadas con calor forjadas en tibio y dimensionadas en frío',
	},
	{
		code: '31132509',
		label: 'Forja de aleación ferrosa maquinado forjado en caliente',

		palabrasSimilares:
			'Fragua de aleaciones ferrosas forjadas en calor mecanizadas',
	},
	{
		code: '31132510',
		label: 'Forja de aleación ferrosa tratado con calor forjado en caliente',

		palabrasSimilares:
			'Fragua de aleaciones ferrosas tratadas y forjadas con calor',
	},
	{
		code: '31132511',
		label:
			'Forja de aleación ferrosa maquinado y tratado con calor forjado en caliente',

		palabrasSimilares:
			'Fragua de aleaciones ferrosas tratadas y forjadas con calor mecanizadas',
	},
	{
		code: '31132512',
		label:
			'Forja de aleación ferrosa tratado con calor y dimensionado en frío forjado en caliente',

		palabrasSimilares:
			'Fragua de aleaciones ferrosas tratadas y forjadas con calor y dimensionadas en frío',
	},
	{
		code: '31132600',
		label: 'Forjas de acero inoxidable',

		palabrasSimilares: 'Fragua de acero inoxidable',
	},
	{
		code: '31132601',
		label: 'Forja de acero inoxidable maquinado forjado en frío',

		palabrasSimilares: 'Fragua de acero inoxidable forjado en frío mecanizados',
	},
	{
		code: '31132602',
		label: 'Forja de acero inoxidable tratado con calor forjado en frío',

		palabrasSimilares:
			'Fragua de acero inoxidable tratado con calor forjado en frío',
	},
	{
		code: '31132603',
		label:
			'Forja de acero inoxidable maquinado y tratado con calor forjado en frío',

		palabrasSimilares:
			'Fragua de acero inoxidable tratado con calor forjado en frío mecanizadas',
	},
	{
		code: '31132604',
		label:
			'Forja de acero inoxidable tratado con calor y dimensionado en frío forjado en frío',

		palabrasSimilares:
			'Fragua de acero inoxidable tratado con calor forjado y dimensionado en frío',
	},
	{
		code: '31132605',
		label: 'Forja de acero inoxidable maquinado forjado en tibio',

		palabrasSimilares:
			'Fragua de acero inoxidable forjado en tibio mecanizados',
	},
	{
		code: '31132606',
		label: 'Forja de acero inoxidable tratado con calor forjado en tibio',

		palabrasSimilares:
			'Fragua de acero inoxidable tratado con calor forjado en tibio',
	},
	{
		code: '31132607',
		label:
			'Forja de acero inoxidable maquinado y tratado con calor forjado en tibio',

		palabrasSimilares:
			'Fragua de acero inoxidable tratado con calor forjado en tibio mecanizado',
	},
	{
		code: '31132608',
		label:
			'Forja de acero inoxidable tratado con calor y dimensionado en frío forjado en tibio',

		palabrasSimilares:
			'Fragua de acero inoxidable tratado con calor forjado en tibio y dimensionado en frío',
	},
	{
		code: '31132609',
		label: 'Forja de acero inoxidable maquinado forjado en caliente',

		palabrasSimilares:
			'Fragua de acero inoxidable forjado en calor mecanizados',
	},
	{
		code: '31132610',
		label: 'Forja de acero inoxidable tratado con calor forjado en caliente',

		palabrasSimilares: 'Fragua de acero inoxidable tratado y forjado con calor',
	},
	{
		code: '31132611',
		label:
			'Forja de acero inoxidable maquinado y tratado con calor forjado en caliente',

		palabrasSimilares:
			'Fragua de acero inoxidable tratado y forjado con calor mecanizados',
	},
	{
		code: '31132612',
		label:
			'Forja de acero inoxidable tratado con calor y dimensionado en frío forjado en caliente',

		palabrasSimilares:
			'Fragua de acero inoxidable tratado y forjado con calor y dimensionado en frío',
	},
	{
		code: '31132700',
		label: 'Forjas de hierro',

		palabrasSimilares: 'Fragua de hierro',
	},
	{
		code: '31132701',
		label: 'Forja de hierro maquinado forjado en frío',

		palabrasSimilares: 'Fragua de hierro forjado en frío mecanizado',
	},
	{
		code: '31132702',
		label: 'Forja de hierro tratado con calor forjado en frío',

		palabrasSimilares: 'Fragua de hierro tratado con calor forjado en frío',
	},
	{
		code: '31132703',
		label: 'Forja de hierro maquinado y tratado con calor forjado en frío',

		palabrasSimilares:
			'Fragua de hierro tratado con calor forjado en frío mecanizado',
	},
	{
		code: '31132704',
		label:
			'Forja de hierro tratado con calor y dimensionado en frío forjado en frío',

		palabrasSimilares:
			'Fragua de hierro tratado con calor forjado y dimensionado en frío',
	},
	{
		code: '31132705',
		label: 'Forja de hierro maquinado forjado en tibio',

		palabrasSimilares: 'Fragua de hierro forjado en tibio mecanizado',
	},
	{
		code: '31132706',
		label: 'Forja de hierro tratado con calor forjado en tibio',

		palabrasSimilares: 'Fragua de hierro tratado con calor forjado en tibio',
	},
	{
		code: '31132707',
		label: 'Forja de hierro maquinado y tratado con calor forjado en tibio',

		palabrasSimilares:
			'Fragua de hierro tratado con calor forjado en tibio mecanizado',
	},
	{
		code: '31132708',
		label:
			'Forja de hierro tratado con calor y dimensionado en frío forjado en tibio',

		palabrasSimilares:
			'Fragua de hierro tratado con calor forjado en tibio y dimensionado en frío',
	},
	{
		code: '31132709',
		label: 'Forja de hierro maquinado forjado en caliente',

		palabrasSimilares: 'Fragua de hierro forjado en calor mecanizados',
	},
	{
		code: '31132710',
		label: 'Forja de hierro tratado con calor forjado en caliente',

		palabrasSimilares: 'Fragua de hierro tratado y forjado con calor',
	},
	{
		code: '31132711',
		label: 'Forja de hierro maquinado y tratado con calor forjado en caliente',

		palabrasSimilares:
			'Fragua de hierro tratado y forjado con calor mecanizados',
	},
	{
		code: '31132712',
		label:
			'Forja de hierro tratado con calor y dimensionado en frío forjado en caliente',

		palabrasSimilares:
			'Fragua de hierro tratado y forjado con calor y dimensionado en frío',
	},
	{
		code: '31132800',
		label: 'Forjas de magnesio',

		palabrasSimilares: 'Fragua de magnesio',
	},
	{
		code: '31132801',
		label: 'Forja de magnesio maquinado forjado en frío',

		palabrasSimilares: 'Fragua de magnesio forjado en frío mecanizado',
	},
	{
		code: '31132802',
		label: 'Forja de magnesio tratado con calor forjado en frío',

		palabrasSimilares: 'Fragua de magnesio tratado con calor forjado en frío',
	},
	{
		code: '31132803',
		label: 'Forja de magnesio maquinado y tratado con calor forjado en frío',

		palabrasSimilares:
			'Fragua de magnesio tratado con calor forjado en frío mecanizado',
	},
	{
		code: '31132804',
		label:
			'Forja de magnesio tratado con calor y dimensionado en frío forjado en frío',

		palabrasSimilares:
			'Fragua de magnesio tratado con calor forjado y dimensionado en frío',
	},
	{
		code: '31132805',
		label: 'Forja de magnesio maquinado forjado en tibio',

		palabrasSimilares: 'Fragua de magnesio forjado en tibio mecanizado',
	},
	{
		code: '31132806',
		label: 'Forja de magnesio tratado con calor forjado en tibio',

		palabrasSimilares: 'Fragua de magnesio tratado con calor forjado en tibio',
	},
	{
		code: '31132807',
		label: 'Forja de magnesio maquinado y tratado con calor forjado en tibio',

		palabrasSimilares:
			'Fragua de magnesio tratado con calor forjado en tibio mecanizado',
	},
	{
		code: '31132808',
		label:
			'Forja de magnesio tratado con calor y dimensionado en frío forjado en tibio',

		palabrasSimilares:
			'Fragua de magnesio tratado con calor forjado en tibio y dimensionado en frío',
	},
	{
		code: '31132809',
		label: 'Forja de magnesio maquinado forjado en caliente',

		palabrasSimilares: 'Fragua de magnesio forjado en calor mecanizados',
	},
	{
		code: '31132810',
		label: 'Forja de magnesio tratado con calor forjado en caliente',

		palabrasSimilares: 'Fragua de magnesio tratado y forjado con calor',
	},
	{
		code: '31132811',
		label:
			'Forja de magnesio maquinado y tratado con calor forjado en caliente',

		palabrasSimilares:
			'Fragua de magnesio tratado y forjado con calor mecanizados',
	},
	{
		code: '31132812',
		label:
			'Forja de magnesio tratado con calor y dimensionado en frío forjado en caliente',

		palabrasSimilares:
			'Fragua de magnesio inoxidable tratado y forjado con calor y dimensionado en frío',
	},
	{
		code: '31132900',
		label: 'Forjas de titanio',

		palabrasSimilares: 'Fragua de titanio',
	},
	{
		code: '31132901',
		label: 'Forja de titanio maquinado forjado en frío',

		palabrasSimilares: 'Fragua de titanio forjado en frío mecanizado',
	},
	{
		code: '31132902',
		label: 'Forja de titanio tratado con calor forjado en frío',

		palabrasSimilares: 'Fragua de titanio tratado con calor forjado en frío',
	},
	{
		code: '31132903',
		label: 'Forja de titanio maquinado y tratado con calor forjado en frío',

		palabrasSimilares:
			'Fragua de titanio tratado con calor forjado en frío mecanizado',
	},
	{
		code: '31132904',
		label:
			'Forja de titanio tratado con calor y dimensionado en frío forjado en frío',

		palabrasSimilares:
			'Fragua de titanio tratado con calor forjado y dimensionado en frío',
	},
	{
		code: '31132905',
		label: 'Forja de titanio maquinado forjado en tibio',

		palabrasSimilares: 'Fragua de titanio forjado en tibio mecanizado',
	},
	{
		code: '31132906',
		label: 'Forja de titanio tratado con calor forjado en tibio',

		palabrasSimilares: 'Fragua de titanio tratado con calor forjado en tibio',
	},
	{
		code: '31132907',
		label: 'Forja de titanio maquinado y tratado con calor forjado en tibio',

		palabrasSimilares:
			'Fragua de titanio tratado con calor forjado en tibio mecanizado',
	},
	{
		code: '31132908',
		label:
			'Forja de titanio tratado con calor y dimensionado en frío forjado en tibio',

		palabrasSimilares:
			'Fragua de titanio tratado con calor forjado en tibio y dimensionado en frío',
	},
	{
		code: '31132909',
		label: 'Forja de titanio maquinado forjado en caliente',

		palabrasSimilares: 'Fragua de titanio forjado en calor mecanizados',
	},
	{
		code: '31132910',
		label: 'Forja de titanio tratado con calor forjado en caliente',

		palabrasSimilares: 'Fragua de titanio tratado y forjado con calor',
	},
	{
		code: '31132911',
		label: 'Forja de titanio maquinado y tratado con calor forjado en caliente',

		palabrasSimilares:
			'Fragua de titanio tratado y forjado con calor mecanizados',
	},
	{
		code: '31132912',
		label:
			'Forja de titanio tratado con calor y dimensionado en frío forjado en caliente',

		palabrasSimilares:
			'Fragua de titanio inoxidable tratado y forjado con calor y dimensionado en frío',
	},
	{
		code: '31133000',
		label: 'Forjas de berilio',

		palabrasSimilares: 'Fragua de berilio',
	},
	{
		code: '31133001',
		label: 'Forja de berilio maquinado forjado en frío',

		palabrasSimilares: 'Fragua de berilio forjado en frío mecanizado',
	},
	{
		code: '31133002',
		label: 'Forja de berilio tratado con calor forjado en frío',

		palabrasSimilares: 'Fragua de berilio tratado con calor forjado en frío',
	},
	{
		code: '31133003',
		label: 'Forja de berilio maquinado y tratado con calor forjado en frío',

		palabrasSimilares:
			'Fragua de berilio tratado con calor forjado en frio mecanizado',
	},
	{
		code: '31133004',
		label:
			'Forja de berilio tratado con calor y dimensionado en frío forjado en frío',

		palabrasSimilares:
			'Fragua de berilio tratado con calor forjado y dimensionado en frío',
	},
	{
		code: '31133005',
		label: 'Forja de berilio maquinado forjado en tibio',

		palabrasSimilares: 'Fragua de berilio forjado en tibio mecanizado',
	},
	{
		code: '31133006',
		label: 'Forja de berilio tratado con calor forjado en tibio',

		palabrasSimilares: 'Fragua de berilio tratado con calor forjado en tibio',
	},
	{
		code: '31133007',
		label: 'Forja de berilio maquinado y tratado con calor forjado en tibio',

		palabrasSimilares:
			'Fragua de berilio tratado con calor forjado en tibio mecanizado',
	},
	{
		code: '31133008',
		label:
			'Forja de berilio tratado con calor y dimensionado en frío forjado en tibio',

		palabrasSimilares:
			'Fragua de berilio tratado con calor forjado en tibio y dimensionado en frío',
	},
	{
		code: '31133009',
		label: 'Forja de berilio maquinado forjado en caliente',

		palabrasSimilares: 'Fragua de berilio forjado en calor mecanizados',
	},
	{
		code: '31133010',
		label: 'Forja de berilio tratado con calor forjado en caliente',

		palabrasSimilares: 'Fragua de berilio tratado y forjado con calor',
	},
	{
		code: '31133011',
		label: 'Forja de berilio maquinado y tratado con calor forjado en caliente',

		palabrasSimilares:
			'Fragua de berilio tratado y forjado con calor mecanizados',
	},
	{
		code: '31133012',
		label:
			'Forja de berilio tratado con calor y dimensionado en frío forjado en caliente',

		palabrasSimilares:
			'Fragua de berilio inoxidable tratado y forjado con calor y dimensionado en frío',
	},
	{
		code: '31133100',
		label: 'Forjas de cobre',

		palabrasSimilares: 'Fragua de cobre',
	},
	{
		code: '31133101',
		label: 'Forja de cobre maquinado forjado en frío',

		palabrasSimilares: 'Fragua de cobre forjado en frío mecanizado',
	},
	{
		code: '31133102',
		label: 'Forja de cobre tratado con calor forjado en frío',

		palabrasSimilares: 'Fragua de cobre tratado con calor forjado en frío',
	},
	{
		code: '31133103',
		label: 'Forja de cobre maquinado y tratado con calor forjado en frío',

		palabrasSimilares:
			'Fragua de cobre tratado con calor forjado en frío mecanizado',
	},
	{
		code: '31133104',
		label:
			'Forja de cobre tratado con calor y dimensionado en frío forjado en frío',

		palabrasSimilares:
			'Fragua de cobre tratado con calor forjado y dimensionado en frío',
	},
	{
		code: '31133105',
		label: 'Forja de cobre maquinado forjado en tibio',

		palabrasSimilares: 'Fragua de cobre forjado en tibio mecanizado',
	},
	{
		code: '31133106',
		label: 'Forja de cobre tratado con calor forjado en tibio',

		palabrasSimilares: 'Fragua de cobre tratado con calor forjado en tibio',
	},
	{
		code: '31133107',
		label: 'Forja de cobre maquinado y tratado con calor forjado en tibio',

		palabrasSimilares:
			'Fragua de cobre tratado con calor forjado en tibio mecanizado',
	},
	{
		code: '31133108',
		label:
			'Forja de cobre tratado con calor y dimensionado en frío forjado en tibio',

		palabrasSimilares:
			'Fragua de cobre tratado con calor forjado en tibio y dimensionado en frío',
	},
	{
		code: '31133109',
		label: 'Forja de cobre maquinado forjado en caliente',

		palabrasSimilares: 'Fragua de cobre forjado en calor mecanizados',
	},
	{
		code: '31133110',
		label: 'Forja de cobre tratado con calor forjado en caliente',

		palabrasSimilares: 'Fragua de cobre tratado y forjado con calor',
	},
	{
		code: '31133111',
		label: 'Forja de cobre maquinado y tratado con calor forjado en caliente',

		palabrasSimilares:
			'Fragua de cobre tratado y forjado con calor mecanizados',
	},
	{
		code: '31133112',
		label:
			'Forja de cobre tratado con calor y dimensionado en frío forjado en caliente',

		palabrasSimilares:
			'Fragua de cobre inoxidable tratado y forjado con calor y dimensionado en frío',
	},
	{
		code: '31133200',
		label: 'Forjas de zinc',

		palabrasSimilares: 'Fragua de cinc',
	},
	{
		code: '31133201',
		label: 'Forja de zinc maquinado forjado en frío',

		palabrasSimilares: 'Fragua de cinc forjado en frío mecanizado',
	},
	{
		code: '31133202',
		label: 'Forja de zinc tratado con calor forjado en frío',

		palabrasSimilares: 'Fragua de cinc tratado con calor forjado en frío',
	},
	{
		code: '31133203',
		label: 'Forja de zinc maquinado y tratado con calor forjado en frío',

		palabrasSimilares:
			'Fragua de cinc tratado con calor forjado en frío mecanizado',
	},
	{
		code: '31133204',
		label:
			'Forja de zinc tratado con calor y dimensionado en frío forjado en frío',

		palabrasSimilares:
			'Fragua de cinc tratado con calor forjado y dimensionado en frío',
	},
	{
		code: '31133205',
		label: 'Forja de zinc maquinado forjado en tibio',

		palabrasSimilares: 'Fragua de cinc forjado en tibio mecanizado',
	},
	{
		code: '31133206',
		label: 'Forja de zinc tratado con calor forjado en tibio',

		palabrasSimilares: 'Fragua de cinc tratado con calor forjado en tibio',
	},
	{
		code: '31133207',
		label: 'Forja de zinc maquinado y tratado con calor forjado en tibio',

		palabrasSimilares:
			'Fragua de cinc tratado con calor forjado en tibio mecanizado',
	},
	{
		code: '31133208',
		label:
			'Forja de zinc tratado con calor y dimensionado en frío forjado en tibio',

		palabrasSimilares:
			'Fragua de cinc tratado con calor forjado en tibio y dimensionado en frío',
	},
	{
		code: '31133209',
		label: 'Forja de zinc maquinado forjado en caliente',

		palabrasSimilares: 'Fragua de cinc forjado en calor mecanizados',
	},
	{
		code: '31133210',
		label: 'Forja de zinc tratado con calor forjado en caliente',

		palabrasSimilares: 'Fragua de cinc tratado y forjado con calor',
	},
	{
		code: '31133211',
		label: 'Forja de zinc maquinado y tratado con calor forjado en caliente',

		palabrasSimilares: 'Fragua de cinc tratado y forjado con calor mecanizados',
	},
	{
		code: '31133212',
		label:
			'Forja de zinc tratado con calor y dimensionado en frío forjado en caliente',

		palabrasSimilares:
			'Fragua de cinc inoxidable tratado y forjado con calor y dimensionado en frío',
	},
	{
		code: '31133300',
		label: 'Forjas de bronce',

		palabrasSimilares: 'Fragua de bronce',
	},
	{
		code: '31133301',
		label: 'Forja de bronce maquinado forjado en frío',

		palabrasSimilares: 'Fragua de bronce forjado en frío mecanizado',
	},
	{
		code: '31133302',
		label: 'Forja de bronce tratado con calor forjado en frío',

		palabrasSimilares: 'Fragua de bronce tratado con calor forjado en frío',
	},
	{
		code: '31133303',
		label: 'Forja de bronce maquinado y tratado con calor forjado en frío',

		palabrasSimilares:
			'Fragua de bronce tratado con calor forjado en frío mecanizado',
	},
	{
		code: '31133304',
		label:
			'Forja de bronce tratado con calor y dimensionado en frío forjado en frío',

		palabrasSimilares:
			'Fragua de bronce tratado con calor forjado y dimensionado en frío',
	},
	{
		code: '31133305',
		label: 'Forja de bronce maquinado forjado en tibio',

		palabrasSimilares: 'Fragua de bronce forjado en tibio mecanizado',
	},
	{
		code: '31133306',
		label: 'Forja de bronce tratado con calor forjado en tibio',

		palabrasSimilares: 'Fragua de bronce tratado con calor forjado en tibio',
	},
	{
		code: '31133307',
		label: 'Forja de bronce maquinado y tratado con calor forjado en tibio',

		palabrasSimilares:
			'Fragua de bronce tratado con calor forjado en tibio mecanizado',
	},
	{
		code: '31133308',
		label:
			'Forja de bronce tratado con calor y dimensionado en frío forjado en tibio',

		palabrasSimilares:
			'Fragua de bronce tratado con calor forjado en tibio y dimensionado en frío',
	},
	{
		code: '31133309',
		label: 'Forja de bronce maquinado forjado en caliente',

		palabrasSimilares: 'Fragua de bronce forjado en calor mecanizados',
	},
	{
		code: '31133310',
		label: 'Forja de bronce tratado con calor forjado en caliente',

		palabrasSimilares: 'Fragua de bronce tratado y forjado con calor',
	},
	{
		code: '31133311',
		label: 'Forja de bronce maquinado y tratado con calor forjado en caliente',

		palabrasSimilares:
			'Fragua de bronce tratado y forjado con calor mecanizados',
	},
	{
		code: '31133312',
		label:
			'Forja de bronce tratado con calor y dimensionado en frío forjado en caliente',

		palabrasSimilares:
			'Fragua de bronce inoxidable tratado y forjado con calor y dimensionado en frío',
	},
	{
		code: '31133400',
		label: 'Forjas de estaño',

		palabrasSimilares: 'Fragua de estaño',
	},
	{
		code: '31133401',
		label: 'Forja de estaño maquinado forjado en frío',

		palabrasSimilares: 'Fragua de estaño forjado en frío mecanizado',
	},
	{
		code: '31133402',
		label: 'Forja de estaño tratado con calor forjado en frío',

		palabrasSimilares: 'Fragua de estaño tratado con calor forjado en frío',
	},
	{
		code: '31133403',
		label: 'Forja de estaño maquinado y tratado con calor forjado en frío',

		palabrasSimilares:
			'Fragua de estaño tratado con calor forjado en frío mecanizado',
	},
	{
		code: '31133404',
		label:
			'Forja de estaño tratado con calor y dimensionado en frío forjado en frío',

		palabrasSimilares:
			'Fragua de estaño tratado con calor forjado y dimensionado en frío',
	},
	{
		code: '31133405',
		label: 'Forja de estaño maquinado forjado en tibio',

		palabrasSimilares: 'Fragua de estaño forjado en tibio mecanizado',
	},
	{
		code: '31133406',
		label: 'Forja de estaño tratado con calor forjado en tibio',

		palabrasSimilares: 'Fragua de estaño tratado con calor forjado en tibio',
	},
	{
		code: '31133407',
		label: 'Forja de estaño maquinado y tratado con calor forjado en tibio',

		palabrasSimilares:
			'Fragua de estaño tratado con calor forjado en tibio mecanizado',
	},
	{
		code: '31133408',
		label:
			'Forja de estaño tratado con calor y dimensionado en frío forjado en tibio',

		palabrasSimilares:
			'Fragua de estaño tratado con calor forjado en tibio y dimensionado en frío',
	},
	{
		code: '31133409',
		label: 'Forja de estaño maquinado forjado en caliente',

		palabrasSimilares: 'Fragua de estaño forjado en calor mecanizados',
	},
	{
		code: '31133410',
		label: 'Forja de estaño tratado con calor forjado en caliente',

		palabrasSimilares: 'Fragua de estaño tratado y forjado con calor',
	},
	{
		code: '31133411',
		label: 'Forja de estaño maquinado y tratado con calor forjado en caliente',

		palabrasSimilares:
			'Fragua de estaño tratado y forjado con calor mecanizados',
	},
	{
		code: '31133412',
		label:
			'Forja de estaño tratado con calor y dimensionado en frío forjado en caliente',

		palabrasSimilares:
			'Fragua de estaño inoxidable tratado y forjado con calor y dimensionado en frío',
	},
	{
		code: '31133500',
		label: 'Forjas de plomo',

		palabrasSimilares: 'Fragua de plomo',
	},
	{
		code: '31133501',
		label: 'Forja de plomo maquinado forjado en frío',

		palabrasSimilares: 'Fragua de plomo forjado en frío mecanizado',
	},
	{
		code: '31133502',
		label: 'Forja de plomo tratado con calor forjado en frío',

		palabrasSimilares: 'Fragua de plomo tratado con calor forjado en frío',
	},
	{
		code: '31133503',
		label: 'Forja de plomo maquinado y tratado con calor forjado en frío',

		palabrasSimilares:
			'Fragua de plomo tratado con calor forjado en frío mecanizado',
	},
	{
		code: '31133504',
		label:
			'Forja de plomo tratado con calor y dimensionado en frío forjado en frío',

		palabrasSimilares:
			'Fragua de plomo tratado con calor forjado y dimensionado en frío',
	},
	{
		code: '31133505',
		label: 'Forja de plomo maquinado forjado en tibio',

		palabrasSimilares: 'Fragua de plomo forjado en tibio mecanizado',
	},
	{
		code: '31133506',
		label: 'Forja de plomo tratado con calor forjado en tibio',

		palabrasSimilares: 'Fragua de plomo tratado con calor forjado en tibio',
	},
	{
		code: '31133507',
		label: 'Forja de plomo maquinado y tratado con calor forjado en tibio',

		palabrasSimilares:
			'Fragua de plomo tratado con calor forjado en tibio mecanizado',
	},
	{
		code: '31133508',
		label:
			'Forja de plomo tratado con calor y dimensionado en frío forjado en tibio',

		palabrasSimilares:
			'Fragua de plomo tratado con calor forjado en tibio y dimensionado en frío',
	},
	{
		code: '31133509',
		label: 'Forja de plomo maquinado forjado en caliente',

		palabrasSimilares: 'Fragua de plomo forjado en calor mecanizados',
	},
	{
		code: '31133510',
		label: 'Forja de plomo tratado con calor forjado en caliente',

		palabrasSimilares: 'Fragua de plomo tratado y forjado con calor',
	},
	{
		code: '31133511',
		label: 'Forja de plomo maquinado y tratado con calor forjado en caliente',

		palabrasSimilares:
			'Fragua de plomo tratado y forjado con calor mecanizados',
	},
	{
		code: '31133512',
		label:
			'Forja de plomo tratado con calor y dimensionado en frío forjado en caliente',

		palabrasSimilares:
			'Fragua de plomo inoxidable tratado y forjado con calor y dimensionado en frío',
	},
	{
		code: '31133600',
		label: 'Forjas de metales preciosos',

		palabrasSimilares: 'Fragua de metales preciosas',
	},
	{
		code: '31133601',
		label: 'Forja de metal precioso maquinado forjado en frío',

		palabrasSimilares: 'Fragua de metal precioso forjado en frío mecanizado',
	},
	{
		code: '31133602',
		label: 'Forja de metal precioso tratado con calor forjado en frío',

		palabrasSimilares:
			'Fragua de metal precioso tratado con calor forjado en frío',
	},
	{
		code: '31133603',
		label:
			'Forja de metal precioso maquinado y tratado con calor forjado en frío',

		palabrasSimilares:
			'Fragua de metal precioso tratado con calor forjado en frío mecanizado',
	},
	{
		code: '31133604',
		label:
			'Forja de metal precioso tratado con calor y dimensionado en frío forjado en frío',

		palabrasSimilares:
			'Fragua de metal precioso tratado con calor forjado y dimensionado en frío',
	},
	{
		code: '31133605',
		label: 'Forja de metal precioso maquinado forjado en tibio',

		palabrasSimilares: 'Fragua de metal precioso forjado en tibio mecanizado',
	},
	{
		code: '31133606',
		label: 'Forja de metal precioso tratado con calor forjado en tibio',

		palabrasSimilares:
			'Fragua de metal precioso tratado con calor forjado en tibio',
	},
	{
		code: '31133607',
		label:
			'Forja de metal precioso maquinado y tratado con calor forjado en tibio',

		palabrasSimilares:
			'Fragua de metal precioso tratado con calor forjado en tibio mecanizado',
	},
	{
		code: '31133608',
		label:
			'Forja de metal precioso tratado con calor y dimensionado en frío forjado en tibio',

		palabrasSimilares:
			'Fragua de metal precioso tratado con calor forjado en tibio y dimensionado en frío',
	},
	{
		code: '31133609',
		label: 'Forja de metal precioso maquinado forjado en caliente',

		palabrasSimilares: 'Fragua de metal precioso forjado en calor mecanizados',
	},
	{
		code: '31133610',
		label: 'Forja de metal precioso tratado con calor forjado en caliente',

		palabrasSimilares: 'Fragua de metal precioso tratado y forjado con calor',
	},
	{
		code: '31133611',
		label:
			'Forja de metal precioso maquinado y tratado con calor forjado en caliente',

		palabrasSimilares:
			'Fragua de metal precioso tratado y forjado con calor mecanizados',
	},
	{
		code: '31133612',
		label:
			'Forja de metal precioso tratado con calor y dimensionado en frío forjado en caliente',

		palabrasSimilares:
			'Fragua de metal precioso inoxidable tratado y forjado con calor y dimensionado en frío',
	},
	{
		code: '31133700',
		label: 'Metales en polvos y aleaciones de metal',

		palabrasSimilares: 'Metales y aleaciones de metal pulverizados',
	},
	{
		code: '31133701',
		label: 'Fricción material de metal en polvo y aleación de metal',

		palabrasSimilares:
			'Material de fricción de metal y aleaciones de metal pulverizados',
	},
	{
		code: '31133702',
		label: 'Contacto eléctrico de metal en polvo y aleación de metal',

		palabrasSimilares:
			'Contacto eléctrico de metal y aleaciones de metal pulverizados',
	},
	{
		code: '31133703',
		label: 'Carburo cementado de metal en polvo y aleación de metal',

		palabrasSimilares:
			'Carburo cementado de metal y aleaciones de metal pulverizados',
	},
	{
		code: '31133704',
		label: 'Matriz metal diamante de metal en polvo y aleación de metal',

		palabrasSimilares:
			'Matriz de metal de diamante de metal y aleaciones de metal pulverizados',
	},
	{
		code: '31133705',
		label: 'Recubrimiento conformal de silicona',

		palabrasSimilares: 'Revestimiento conformal de silicón',
	},
	{
		code: '31133706',
		label: 'Recubrimiento conformal de acrílico',

		palabrasSimilares: 'Revestimiento conformal de acrílico',
	},
	{
		code: '31133707',
		label: 'Recubrimiento conformal de poliuretano',

		palabrasSimilares: 'Revestimiento conformal de poliuretano',
	},
	{
		code: '31133708',
		label: 'Recubrimiento conformal de epóxido',

		palabrasSimilares: 'Revestimiento conformal de epóxido',
	},
	{
		code: '31133709',
		label: 'Recubrimiento conformal de parilene',

		palabrasSimilares: 'Revestimiento conformal de pariele',
	},
	{
		code: '31133710',
		label: 'Sellante de silicona',

		palabrasSimilares: 'Sellante de silicón',
	},
	{
		code: '31133711',
		label: 'Sellante de poliuretano',
	},
	{
		code: '31141500',
		label: 'Molduras por inyección',
	},
	{
		code: '31141501',
		label: 'Molduras por inyección de plástico',
	},
	{
		code: '31141502',
		label: 'Molduras por inyección de caucho',
	},
	{
		code: '31141503',
		label: 'Molduras por inyección de vidrio',
	},
	{
		code: '31141600',
		label: 'Molduras al vacío',
	},
	{
		code: '31141601',
		label: 'Molduras al vacío de plástico',
	},
	{
		code: '31141602',
		label: 'Molduras al vacío de caucho',
	},
	{
		code: '31141603',
		label: 'Molduras al vacío de vidrio',
	},
	{
		code: '31141700',
		label: 'Moldeados por inyección de aire',

		palabrasSimilares: 'Molduras por soplado',
	},
	{
		code: '31141701',
		label: 'Moldeados plásticos por inyección de aire',

		palabrasSimilares: 'Moldura plásticos por soplado',
	},
	{
		code: '31141702',
		label: 'Moldeados de caucho por inyección de aire',

		palabrasSimilares: 'Moldura de caucho por soplado',
	},
	{
		code: '31141800',
		label: 'Moldeos por inyección y reacción (RIM)',

		palabrasSimilares: 'Moldura de inyección con reacción RIM',
	},
	{
		code: '31141801',
		label: 'Moldeados de inyección de reacción de plástico',

		palabrasSimilares: 'Moldura de inyección con reacción de plástico',
	},
	{
		code: '31141802',
		label: 'Moldeados de inyección de reacción de caucho',

		palabrasSimilares: 'Moldura de inyección con reacción de caucho',
	},
	{
		code: '31141900',
		label: 'Moldeados de inyección con inserción',

		palabrasSimilares: 'Moldura de inyección con inserción',
	},
	{
		code: '31141901',
		label: 'Moldeado plástico con inserción previa a la inyección',

		palabrasSimilares: 'Moldura de plástico de inyección con inserción',
	},
	{
		code: '31142000',
		label: 'Ensambles de moldeados termoplásticos',

		palabrasSimilares: 'Ensambles de moldura termoplásticos',
	},
	{
		code: '31142001',
		label: 'Ensamble de moldeado termoplástico por compresión',

		palabrasSimilares: 'Ensambles de moldura termoplásticos por compresión',
	},
	{
		code: '31142002',
		label: 'Ensamble de moldeado termoplástico por inmersión',

		palabrasSimilares: 'Ensambles de moldura termoplásticos por inmersión',
	},
	{
		code: '31142003',
		label: 'Ensamble de moldeado termoplástico por aire',

		palabrasSimilares: 'Ensambles de moldura termoplásticos por aire',
	},
	{
		code: '31142004',
		label: 'Ensamble de moldeado termoplástico por inyección',

		palabrasSimilares: 'Ensambles de moldura termoplásticos por inyección',
	},
	{
		code: '31142005',
		label: 'Ensamble de moldeado termoplástico por inyección asistida por gas',

		palabrasSimilares:
			'Ensambles de moldura termoplásticos por inyección asistida por gas',
	},
	{
		code: '31142006',
		label: 'Ensamble de moldeado termoplástico por inyección de alta precisión',

		palabrasSimilares:
			'Ensambles de moldura termoplásticos por inyección de alta precisión',
	},
	{
		code: '31142007',
		label: 'Ensamble de moldeado termoplástico por transferencia',

		palabrasSimilares: 'Ensambles de moldura termoplásticos por transferencia',
	},
	{
		code: '31142100',
		label: 'Engastes de moldeado termoplástico',
	},
	{
		code: '31142101',
		label: 'Engaste de moldeado termoplástico por compresión',

		palabrasSimilares: 'Moldeo de polímeros por compresión',
	},
	{
		code: '31142102',
		label: 'Engaste de moldeado termoplástico por inmersión',

		palabrasSimilares: 'Moldeo de polímeros por inmersión',
	},
	{
		code: '31142103',
		label: 'Engaste de moldeado termoplástico por aire',

		palabrasSimilares: 'Moldeo de polímeros por aire',
	},
	{
		code: '31142104',
		label: 'Engaste de moldeado termoplástico por inyección',

		palabrasSimilares: 'Moldeo de polímeros por inyección',
	},
	{
		code: '31142105',
		label: 'Engaste de moldeado termoplástico por inyección asistida por gas',

		palabrasSimilares: 'Moldeo de polímeros por inyección asistida por gas',
	},
	{
		code: '31142106',
		label: 'Engaste de moldeado termoplástico por inyección de alta precisión',

		palabrasSimilares: 'Moldeo de polímeros por inyección de alta precisión',
	},
	{
		code: '31142107',
		label: 'Engaste de moldeado termoplástico por transferencia',

		palabrasSimilares: 'Moldeo de polímeros por transferencia',
	},
	{
		code: '31142200',
		label: 'Ensambles de engastes termoplástico moldeado por disparo múltiple',
	},
	{
		code: '31142201',
		label:
			'Ensamble de engaste termoplástico moldeado por disparo múltiple por compresión',

		palabrasSimilares:
			'Montura de polímeros por   disparo múltiple por compresión',
	},
	{
		code: '31142202',
		label:
			'Ensamble de engaste termoplástico moldeado por disparo múltiple por inmersión',

		palabrasSimilares:
			'Montura de polímeros por disparo múltiple por inmersión',
	},
	{
		code: '31142203',
		label:
			'Ensamble de engaste termoplástico moldeado por disparo múltiple por aire',

		palabrasSimilares: 'Montura de polímeros por disparo múltiple por aire',
	},
	{
		code: '31142204',
		label:
			'Ensamble de engaste termoplástico moldeado por disparo múltiple por inyección',

		palabrasSimilares:
			'Montura de polímeros por disparo múltiple por inyección',
	},
	{
		code: '31142205',
		label:
			'Ensamble de engaste termoplástico moldeado por disparo múltiple por inyección asistida por gas',

		palabrasSimilares:
			'Montura de polímeros por disparo múltiple por inyección asistida por gas',
	},
	{
		code: '31142206',
		label:
			'Ensamble de engaste termoplástico moldeado por disparo múltiple por inyección de alta precisión',

		palabrasSimilares:
			'Montura de polímeros por disparo múltiple por inyección de alta precisión',
	},
	{
		code: '31142207',
		label:
			'Ensamble de engaste termoplástico moldeado por disparo múltiple por transferencia',

		palabrasSimilares:
			'Montura de polímeros por disparo múltiple por transferencia',
	},
	{
		code: '31142300',
		label: 'Ensambles de engastes termoplástico moldeado por inyección',
	},
	{
		code: '31142301',
		label: 'Ensamble de engaste termoplástico moldeado por compresión',

		palabrasSimilares: 'Montura de polímeros por compresión',
	},
	{
		code: '31142302',
		label: 'Ensamble de engaste termoplástico moldeado por inmersión',

		palabrasSimilares: 'Montura de polímeros por inmersión',
	},
	{
		code: '31142303',
		label: 'Ensamble de engaste termoplástico moldeado por aire',

		palabrasSimilares: 'Montura de polímeros por aire',
	},
	{
		code: '31142304',
		label: 'Ensamble de engaste termoplástico moldeado por inyección',

		palabrasSimilares: 'Montura de polímeros por inyección',
	},
	{
		code: '31142305',
		label:
			'Ensamble de engaste termoplástico moldeado por inyección asistida por gas',

		palabrasSimilares: 'Montura de polímeros por inyección asistida por gas',
	},
	{
		code: '31142306',
		label:
			'Ensamble de engaste termoplástico moldeado por inyección de alta precisión',

		palabrasSimilares: 'Montura de polímeros por inyección de alta precisión',
	},
	{
		code: '31142307',
		label: 'Ensamble de engaste termoplástico moldeado por transferencia',

		palabrasSimilares: 'Montura de polímeros por transferencia',
	},
	{
		code: '31142400',
		label: 'Ensambles de moldeado termofraguado',
	},
	{
		code: '31142401',
		label: 'Ensamble de moldeado termofraguado por compresión',

		palabrasSimilares: 'Ensamble de moldeo termoestable por compresión',
	},
	{
		code: '31142402',
		label: 'Ensamble de moldeado termofraguado por inmersión',

		palabrasSimilares: 'Ensamble de moldeo termoestable por inmersión',
	},
	{
		code: '31142403',
		label: 'Ensamble de moldeado termofraguado por aire',

		palabrasSimilares: 'Ensamble de moldeo termoestable por aire',
	},
	{
		code: '31142404',
		label: 'Ensamble de moldeado termofraguado por inyección',

		palabrasSimilares: 'Ensamble de moldeo termoestable por inyección',
	},
	{
		code: '31142405',
		label: 'Ensamble de moldeado termofraguado por inyección asistida por gas',

		palabrasSimilares:
			'Ensamble de moldeo termoestable por inyección asistida por gas',
	},
	{
		code: '31142406',
		label: 'Ensamble de moldeado termofraguado por inyección de alta precisión',

		palabrasSimilares:
			'Ensamble de moldeo termoestable por inyección de alta precisión',
	},
	{
		code: '31142407',
		label: 'Ensamble de moldeado termofraguado por transferencia',

		palabrasSimilares: 'Ensamble de moldeo termoestable por transferencia',
	},
	{
		code: '31142500',
		label: 'Engastes de moldeado termofraguado',
	},
	{
		code: '31142501',
		label: 'Engaste de moldeado termofraguado por compresión',

		palabrasSimilares: 'Moldeo termoestable por compresión',
	},
	{
		code: '31142502',
		label: 'Engaste de moldeado termofraguado por inmersión',

		palabrasSimilares: 'Moldeo termoestable por inmersión',
	},
	{
		code: '31142503',
		label: 'Engaste de moldeado termofraguado por aire',

		palabrasSimilares: 'Moldeo termoestable por aire',
	},
	{
		code: '31142504',
		label: 'Engaste de moldeado termofraguado por inyección',

		palabrasSimilares: 'Moldeo termoestable por inyección',
	},
	{
		code: '31142505',
		label: 'Engaste de moldeado termofraguado por inyección asistida por gas',

		palabrasSimilares: 'Moldeo termoestable por inyección asistida por gas',
	},
	{
		code: '31142506',
		label: 'Engaste de moldeado termofraguado por inyección de alta precisión',

		palabrasSimilares: 'Moldeo termoestable por inyección de alta precisión',
	},
	{
		code: '31142507',
		label: 'Engaste de moldeado termofraguado por transferencia',

		palabrasSimilares: 'Moldeo termoestable por transferencia',
	},
	{
		code: '31142600',
		label: 'Ensambles de engaste termofraguado moldeado por disparo múltiple',
	},
	{
		code: '31142601',
		label:
			'Ensamble de engaste termofraguado moldeado por disparo múltiple por compresión',

		palabrasSimilares:
			'Ensamblaje de molduras termoestables por disparo múltiple por compresión',
	},
	{
		code: '31142602',
		label:
			'Ensamble de engaste termofraguado moldeado por disparo múltiple por inmersión',

		palabrasSimilares:
			'Ensamble de engaste termofraguado moldeado por disparo múltiple por inmersión',
	},
	{
		code: '31142603',
		label:
			'Ensamble de engaste termofraguado moldeado por disparo múltiple por aire',

		palabrasSimilares:
			'Ensamble de engaste termofraguado moldeado por disparo múltiple por aire',
	},
	{
		code: '31142604',
		label:
			'Ensamble de engaste termofraguado moldeado por disparo múltiple por inyección',

		palabrasSimilares:
			'Ensamble de engaste termofraguado moldeado por disparo múltiple por inyección',
	},
	{
		code: '31142605',
		label:
			'Ensamble de engaste termofraguado moldeado por disparo múltiple por inyección asistida por gas',

		palabrasSimilares:
			'Ensamble de engaste termofraguado moldeado por disparo múltiple por inyección asistida por gas',
	},
	{
		code: '31142606',
		label:
			'Ensamble de engaste termofraguado moldeado por disparo múltiple por inyección de alta precisión',

		palabrasSimilares:
			'Ensamble de engaste termofraguado moldeado por disparo múltiple por inyección de alta precisión',
	},
	{
		code: '31142607',
		label:
			'Ensamble de engaste termofraguado moldeado por disparo múltiple por transferencia',

		palabrasSimilares:
			'Ensamble de engaste termofraguado moldeado por disparo múltiple por transferencia',
	},
	{
		code: '31142700',
		label: 'Ensambles de engaste termofraguado moldeado',
	},
	{
		code: '31142701',
		label: 'Ensamble de engaste termofraguado moldeado por compresión',

		palabrasSimilares: 'Ensamblaje de molduras termoestables por compresión',
	},
	{
		code: '31142702',
		label: 'Ensamble de engaste termofraguado moldeado por inmersión',

		palabrasSimilares: 'Ensamblaje de molduras termoestables por inmersión',
	},
	{
		code: '31142703',
		label: 'Ensamble de engaste termofraguado moldeado por aire',

		palabrasSimilares: 'Ensamblaje de molduras termoestables por aire',
	},
	{
		code: '31142704',
		label: 'Ensamble de engaste termofraguado moldeado por inyección',

		palabrasSimilares: 'Ensamblaje de molduras termoestables por inyección',
	},
	{
		code: '31142705',
		label:
			'Ensamble de engaste termofraguado moldeado por inyección asistida por gas',

		palabrasSimilares:
			'Ensamblaje de molduras termoestables por inyección asistida por gas',
	},
	{
		code: '31142706',
		label:
			'Ensamble de engaste termofraguado moldeado por inyección de alta precisión',

		palabrasSimilares:
			'Ensamblaje de molduras termoestables por inyección de alta precisión',
	},
	{
		code: '31142707',
		label: 'Ensamble de engaste termofraguado moldeado por transferencia',

		palabrasSimilares: 'Ensamblaje de molduras termoestables por transferencia',
	},
	{
		code: '31142800',
		label: 'Moldeados por inmersión',
	},
	{
		code: '31142801',
		label: 'Moldeado por inmersión de plástico o plastisol',
	},
	{
		code: '31142802',
		label: 'Moldeado por inmersión de látex',
	},
	{
		code: '31142803',
		label: 'Moldeado por inmersión de neopreno',
	},
	{
		code: '31142804',
		label: 'Moldeado por inmersión de uretano',
	},
	{
		code: '31142900',
		label: 'Moldeados decorados en el molde por inyección',
	},
	{
		code: '31142901',
		label: 'Aplique en molde para moldeado por inyección',

		palabrasSimilares: 'Aplicación en molde para moldear por inyección',
	},
	{
		code: '31142902',
		label: 'Tinta en molde para traspaso por moldeo por inyección',
	},
	{
		code: '31142903',
		label: 'Molde para moldeado por inyección de color',
	},
	{
		code: '31142904',
		label: 'Moldeado por inyección de disparos múltiples',
	},
	{
		code: '31143000',
		label: 'Moldeados asistidos por gas',
	},
	{
		code: '31143001',
		label: 'Moldeado de plástico asistido por gas',
	},
	{
		code: '31143002',
		label: 'Moldeado de espuma estructural asistido por gas',
	},
	{
		code: '31143100',
		label: 'Ensambles de moldeado termoplástico terminados',
	},
	{
		code: '31143101',
		label: 'Ensamble de moldeado termoplástico terminado por inyección',

		palabrasSimilares: 'Ensamble de molde de polímero terminado por inyección',
	},
	{
		code: '31143102',
		label: 'Ensamble de moldeado termoplástico terminado por compresión',

		palabrasSimilares: 'Ensamble de molde de polímero terminado por compresión',
	},
	{
		code: '31143103',
		label:
			'Ensamble de moldeado termoplástico terminado por inyección asistida por gas',

		palabrasSimilares:
			'Ensamble de molde de polímero terminado por inyección asistida por gas',
	},
	{
		code: '31143200',
		label: 'Moldeados decorados por inyección post moldeado',
	},
	{
		code: '31143201',
		label: 'Moldeado por inyección pintado',
	},
	{
		code: '31143202',
		label: 'Moldeado por inyección plateado',

		palabrasSimilares: 'Moldeado por inyección cromado',
	},
	{
		code: '31143203',
		label: 'Moldeado por inyección metalizado',
	},
	{
		code: '31143204',
		label: 'Moldeado por inyección tratado con láser',
	},
	{
		code: '31143205',
		label: 'Moldeado por inyección pintado y tratado con láser',
	},
	{
		code: '31151500',
		label: 'Cuerdas',
	},
	{
		code: '31151501',
		label: 'Cuerda de algodón',

		palabrasSimilares: 'Lazo de algodón, Soga de algodón',
	},
	{
		code: '31151502',
		label: 'Cuerda de poliéster',

		palabrasSimilares: 'Lazo de poliéster, Soga de poliéster',
	},
	{
		code: '31151503',
		label: 'Cuerda de polipropileno',

		palabrasSimilares: 'Lazo de polipropileno, Soga de polipropileno',
	},
	{
		code: '31151504',
		label: 'Cuerda de nylon',

		palabrasSimilares: 'Lazo de nylon, Soga de nylon',
	},
	{
		code: '31151505',
		label: 'Cable de acero',
	},
	{
		code: '31151506',
		label: 'Cuerda de cáñamo',
	},
	{
		code: '31151507',
		label: 'Cuerda o pita',
	},
	{
		code: '31151508',
		label: 'Cabuya',

		palabrasSimilares: 'Cuerda de ixtle',
	},
	{
		code: '31151509',
		label: 'Cuerda de caucho',

		palabrasSimilares: 'Soga de caucho',
	},
	{
		code: '31151510',
		label: 'Cuerda de rayón',

		palabrasSimilares: 'Soga de rayón',
	},
	{
		code: '31151511',
		label: 'Cuerda de lino',

		palabrasSimilares: 'Soga de lino',
	},
	{
		code: '31151512',
		label: 'Cuerda de polietileno',
	},
	{
		code: '31151513',
		label: 'Cuerda de fibra de coco',
	},
	{
		code: '31151514',
		label: 'Cuerda de yute',
	},
	{
		code: '31151515',
		label: 'Cuerda de esparto',

		palabrasSimilares: 'Mecate',
	},
	{
		code: '31151516',
		label: 'Cuerda de rafia',

		palabrasSimilares: 'Mecate',
	},
	{
		code: '31151517',
		label: 'Cuerda de aloe',
	},
	{
		code: '31151518',
		label: 'Cuerda impregnada',
	},
	{
		code: '31151519',
		label: 'Cuerda de látex',
	},
	{
		code: '31151520',
		label: 'Cuerda de vinylon',

		palabrasSimilares: 'Cuerda de vinalón',
	},
	{
		code: '31151521',
		label: 'Cuerda de asbesto',
	},
	{
		code: '31151600',
		label: 'Cadenas',
	},
	{
		code: '31151601',
		label: 'Cadenas de seguridad',
	},
	{
		code: '31151603',
		label: 'Cadenas de rodillos',
	},
	{
		code: '31151604',
		label: 'Cadenas resistentes de bobina',
	},
	{
		code: '31151605',
		label: 'Cadenas de banda',
	},
	{
		code: '31151606',
		label: 'Cadena "jack"',
	},
	{
		code: '31151607',
		label: 'Cadenas corrientes',
	},
	{
		code: '31151608',
		label: 'Cadenas de bola',
	},
	{
		code: '31151609',
		label: 'Eslabones de cadena',
	},
	{
		code: '31151610',
		label: 'Cadena de pasador hueco',

		palabrasSimilares: 'Cadena de rodillos',
	},
	{
		code: '31151611',
		label: 'Cadena silenciosa',
	},
	{
		code: '31151612',
		label: 'Cadena de bloque',
	},
	{
		code: '31151613',
		label: 'Cadena de ingeniería',
	},
	{
		code: '31151614',
		label: 'Cadena transportadora',
	},
	{
		code: '31151615',
		label: 'Cadena de rodillo o sobre placa',
	},
	{
		code: '31151616',
		label: 'Cadena tipo muela',
	},
	{
		code: '31151617',
		label: 'Cadena draga línea',
	},
	{
		code: '31151618',
		label: 'Cadena de muro alto',
	},
	{
		code: '31151619',
		label: 'Cadena de escalón de escalera eléctrica',
	},
	{
		code: '31151700',
		label: 'Cable mecánico',
	},
	{
		code: '31151702',
		label: 'Cables de control no eléctrico',
	},
	{
		code: '31151703',
		label: 'Cables elevadores',
	},
	{
		code: '31151704',
		label: 'Cable-vías',
	},
	{
		code: '31151705',
		label: 'Cable de acero no eléctrico',
	},
	{
		code: '31151706',
		label: 'Cable de cobre no eléctrico',
	},
	{
		code: '31151707',
		label: 'Cable de aluminio no eléctrico',
	},
	{
		code: '31151708',
		label: 'Cable guy',
	},
	{
		code: '31151709',
		label: 'Cable de hierro no eléctrico',
	},
	{
		code: '31151710',
		label: 'Cable sintético',
	},
	{
		code: '31151800',
		label: 'Alambre mecánico',
	},
	{
		code: '31151803',
		label: 'Cuerda de acero para piano',
	},
	{
		code: '31151804',
		label: 'Alambre de grapa',
	},
	{
		code: '31151805',
		label: 'Alambre de multi – filamentos',
	},
	{
		code: '31151806',
		label: 'Alambre laminado',
	},
	{
		code: '31151900',
		label: 'Correas',
	},
	{
		code: '31151901',
		label: 'Correas de metal',
	},
	{
		code: '31151902',
		label: 'Correas de cuero',
	},
	{
		code: '31151903',
		label: 'Correas de fibra',
	},
	{
		code: '31151904',
		label: 'Correas plásticas',
	},
	{
		code: '31151905',
		label: 'Correas de caucho',
	},
	{
		code: '31151906',
		label: 'Correas para amarrar los raches',

		palabrasSimilares: 'Correa de amarre con trinquete',
	},
	{
		code: '31152000',
		label: 'Cable de seguridad',
	},
	{
		code: '31152001',
		label: 'Alambre de navaja',

		palabrasSimilares: 'Correa de amarre con gancho',
	},
	{
		code: '31152002',
		label: 'Alambre de púas',

		palabrasSimilares: 'Alambre navaja',
	},
	{
		code: '31152100',
		label: 'Cordones',
	},
	{
		code: '31152101',
		label: 'Cordón de algodón',
	},
	{
		code: '31152102',
		label: 'Cordón de nylon',
	},
	{
		code: '31152103',
		label: 'Cordón metálico',
	},
	{
		code: '31152104',
		label: 'Cordón de silicona',
	},
	{
		code: '31152105',
		label: 'Cordón de látex',
	},
	{
		code: '31152106',
		label: 'Cordón de poliéster',
	},
	{
		code: '31152107',
		label: 'Cordón de neopreno',
	},
	{
		code: '31152108',
		label: 'Cordón de polipropileno',
	},
	{
		code: '31152109',
		label: 'Cordón de caucho',
	},
	{
		code: '31152110',
		label: 'Cordón de asbesto',
	},
	{
		code: '31152111',
		label: 'Cordón de fibra de vidrio',
	},
	{
		code: '31152112',
		label: 'Cordón de fibra cerámica',
	},
	{
		code: '31152200',
		label: 'Alambres no eléctricos de hierro y acero',
	},
	{
		code: '31152201',
		label: 'Cable de acero bajo en carbono',
	},
	{
		code: '31152202',
		label: 'Cable de acero semiduro',

		palabrasSimilares: 'Alambre recocido',
	},
	{
		code: '31152203',
		label: 'Cable de acero inoxidable',

		palabrasSimilares: 'Alambre de acero semiduro',
	},
	{
		code: '31152204',
		label: 'Cable de acero trenzado',

		palabrasSimilares: 'Alambre de acero inoxidable',
	},
	{
		code: '31152205',
		label: 'Cable de acero templado con aceite',

		palabrasSimilares: 'Alambre de acero tipo trenzado',
	},
	{
		code: '31152206',
		label: 'Cable de acero recubierto',
	},
	{
		code: '31152207',
		label: 'Cable de acero de concreto pretensado',
	},
	{
		code: '31152208',
		label: 'Cable de acero de concreto pretensado trenzado',
	},
	{
		code: '31152209',
		label: 'Alambre de hierro',
	},
	{
		code: '31152300',
		label: 'Alambres no eléctricos de metales no ferrosos',
	},
	{
		code: '31152301',
		label: 'Alambre de plomo o aleación de plomo',
	},
	{
		code: '31152302',
		label: 'Alambre de níquel o aleación de níquel',
	},
	{
		code: '31152303',
		label: 'Alambre de aleación nicromo',
	},
	{
		code: '31152304',
		label: 'Alambre de cobre o aleación de cobre',
	},
	{
		code: '31152305',
		label: 'Alambre de aluminio o aleación de aluminio',
	},
	{
		code: '31152306',
		label: 'Alambre de estaño o aleación de estaño',
	},
	{
		code: '31152307',
		label: 'Alambre de titanio o aleación de titanio',
	},
	{
		code: '31161500',
		label: 'Tornillos',
	},
	{
		code: '31161501',
		label: 'Tornillos de perno',
	},
	{
		code: '31161502',
		label: 'Tornillos de anclaje',
	},
	{
		code: '31161503',
		label: 'Clavo-tornillo',

		palabrasSimilares: 'Pija',
	},
	{
		code: '31161504',
		label: 'Tornillos de máquina',

		palabrasSimilares: 'Tornillo con punta de clavo',
	},
	{
		code: '31161505',
		label: 'Tornillos de presión',
	},
	{
		code: '31161506',
		label: 'Tornillos para lámina metálica',
	},
	{
		code: '31161507',
		label: 'Tornillos roscadores',
	},
	{
		code: '31161508',
		label: 'Tornillos de rosca para madera',
	},
	{
		code: '31161509',
		label: 'Tornillos para drywall',
	},
	{
		code: '31161510',
		label: 'Tornillo cautivo',

		palabrasSimilares: 'Pija, Tornillos para tablaroca',
	},
	{
		code: '31161511',
		label: 'Tornillos de apriete',

		palabrasSimilares: 'Pija',
	},
	{
		code: '31161512',
		label: 'Tornillos de rosca para laminados',
	},
	{
		code: '31161513',
		label: 'Tornillos de cabeza perdida',
	},
	{
		code: '31161514',
		label: 'Tornillos de soldadura',
	},
	{
		code: '31161516',
		label: 'Tornillo de orejas',
	},
	{
		code: '31161517',
		label: 'Tornillo de hombros',
	},
	{
		code: '31161518',
		label: 'Tornillo de enchufe',
	},
	{
		code: '31161519',
		label: 'Tornillo de balde elevador',
	},
	{
		code: '31161520',
		label: 'Tornillo de cabeza hexagonal',
	},
	{
		code: '31161521',
		label: 'Tornillo de carruaje',
	},
	{
		code: '31161522',
		label: 'Tornillo de ojo',
	},
	{
		code: '31161523',
		label: 'Tronillo de ala',

		palabrasSimilares: 'Tornillo mariposa',
	},
	{
		code: '31161524',
		label: 'Tornillo decorativo',
	},
	{
		code: '31161525',
		label: 'Tornillo para plástico',
	},
	{
		code: '31161526',
		label: 'Tornillo de concreto',
	},
	{
		code: '31161527',
		label: 'Tornillo en t o cabeza de martillo',
	},
	{
		code: '31161528',
		label: 'Tornillo auto – entrante',
	},
	{
		code: '31161529',
		label: 'Tornillo de gato',

		palabrasSimilares: 'Tornillo barrenado',
	},
	{
		code: '31161530',
		label: 'Tornillo grub',

		palabrasSimilares: 'Tornillo mecánico de gato',
	},
	{
		code: '31161531',
		label: 'Tornillo especializado',

		palabrasSimilares: 'Tornillo para excavar',
	},
	{
		code: '31161532',
		label: 'Tornillo allen',
	},
	{
		code: '31161533',
		label: 'Tornillo con arandela ensamblada',
	},
	{
		code: '31161534',
		label: 'Tornillo de rosca trapezoidal',

		palabrasSimilares: 'Pija, Tornillo con rondana',
	},
	{
		code: '31161600',
		label: 'Pernos',
	},
	{
		code: '31161601',
		label: 'Pernos de anclaje',
	},
	{
		code: '31161602',
		label: 'Pernos ciegos',

		palabrasSimilares: 'Armella',
	},
	{
		code: '31161603',
		label: 'Pernos de carruaje',
	},
	{
		code: '31161604',
		label: 'Pernos de horquilla',

		palabrasSimilares: 'Tornillo carrocería',
	},
	{
		code: '31161605',
		label: 'Pernos de cilindro',
	},
	{
		code: '31161606',
		label: 'Cerrojos de puerta',
	},
	{
		code: '31161607',
		label: 'Pernos de expansión',
	},
	{
		code: '31161608',
		label: 'Tirafondos',

		palabrasSimilares: 'Taquete de expansión',
	},
	{
		code: '31161609',
		label: 'Tornillo de fiador',

		palabrasSimilares: 'Perno tirafondo',
	},
	{
		code: '31161610',
		label: 'Pernos de ojo',
	},
	{
		code: '31161611',
		label: 'Pernos de sujeción',
	},
	{
		code: '31161612',
		label: 'Pernos de espiga o de reborde',
	},
	{
		code: '31161613',
		label: 'Pernos de tensión',
	},
	{
		code: '31161614',
		label: 'Pernos estructurales',
	},
	{
		code: '31161616',
		label: 'Pernos en u',
	},
	{
		code: '31161617',
		label: 'Pernos de ala',

		palabrasSimilares: 'Abrazadera tipo U',
	},
	{
		code: '31161618',
		label: 'Varilla roscada',

		palabrasSimilares: 'Tornillo mariposa',
	},
	{
		code: '31161619',
		label: 'Pernos prisioneros',

		palabrasSimilares: 'Varilla barrenada',
	},
	{
		code: '31161620',
		label: 'Pernos de cabeza hexagonal',
	},
	{
		code: '31161621',
		label: 'Pernos elevadores',
	},
	{
		code: '31161622',
		label: 'Perno de cizalla',
	},
	{
		code: '31161623',
		label: 'Perno de cable',

		palabrasSimilares: 'Tornillo de cizalla',
	},
	{
		code: '31161624',
		label: 'Perno de resina',
	},
	{
		code: '31161625',
		label: 'Perno de carrilera',
	},
	{
		code: '31161626',
		label: 'Perno sems de cabeza plana',
	},
	{
		code: '31161627',
		label: 'Ensamble de pernos',
	},
	{
		code: '31161628',
		label: 'Perno de cabeza cuadrada',
	},
	{
		code: '31161629',
		label: 'Perno de cabeza redonda',
	},
	{
		code: '31161630',
		label: 'Perno en blanco',
	},
	{
		code: '31161631',
		label: 'Perno de hombro',
	},
	{
		code: '31161632',
		label: 'Perno de roca',
	},
	{
		code: '31161633',
		label: 'Perno de estufa',
	},
	{
		code: '31161634',
		label: 'Perno de sobre – cuello',
	},
	{
		code: '31161635',
		label: 'Perno con arandela ensamblada',
	},
	{
		code: '31161636',
		label: 'Perno de soldadura',
	},
	{
		code: '31161637',
		label: 'Perno de cabeza de enchufe',
	},
	{
		code: '31161638',
		label: 'Perno t',
	},
	{
		code: '31161639',
		label: 'Perno de gancho',
	},
	{
		code: '31161640',
		label: 'Perno garfio',
	},
	{
		code: '31161700',
		label: 'Tuercas',
	},
	{
		code: '31161701',
		label: 'Tuercas de anclaje',
	},
	{
		code: '31161702',
		label: 'Tuercas de rodamiento',
	},
	{
		code: '31161703',
		label: 'Tuercas ciegas',

		palabrasSimilares: 'Tuerca balero',
	},
	{
		code: '31161704',
		label: 'Tuercas de cañón',
	},
	{
		code: '31161705',
		label: 'Tuerca tapa',
	},
	{
		code: '31161706',
		label: 'Tuercas cautivas',
	},
	{
		code: '31161707',
		label: 'Tuercas almenadas',
	},
	{
		code: '31161708',
		label: 'Tuercas de canal',
	},
	{
		code: '31161709',
		label: 'Tuercas sujetadoras',
	},
	{
		code: '31161710',
		label: 'Tuercas de expansión',
	},
	{
		code: '31161711',
		label: 'Tuercas de ojo',
	},
	{
		code: '31161712',
		label: 'Tuercas de brida',
	},
	{
		code: '31161713',
		label: 'Tuercas de manguera',
	},
	{
		code: '31161714',
		label: 'Tuercas de inserción',
	},
	{
		code: '31161716',
		label: 'Contratuercas',
	},
	{
		code: '31161717',
		label: 'Tuercas de aletas',
	},
	{
		code: '31161718',
		label: 'Tuercas de fiador',

		palabrasSimilares: 'Tuercas de mariposa',
	},
	{
		code: '31161719',
		label: 'Tuercas giratorias',
	},
	{
		code: '31161720',
		label: 'Tuercas limitadoras',
	},
	{
		code: '31161721',
		label: 'Tuercas de resorte',

		palabrasSimilares: 'Tuercas unión macho con macho',
	},
	{
		code: '31161722',
		label: 'Tuercas de unión',
	},
	{
		code: '31161723',
		label: 'Tuercas de placa con rosca',
	},
	{
		code: '31161724',
		label: 'Tuercas de prensa',

		palabrasSimilares: 'Tuerca de clip',
	},
	{
		code: '31161725',
		label: 'Tuercas abrazaderas',
	},
	{
		code: '31161726',
		label: 'Tuercas domo',
	},
	{
		code: '31161727',
		label: 'Tuercas hexagonales',
	},
	{
		code: '31161728',
		label: 'Tuercas de acople',
	},
	{
		code: '31161729',
		label: 'Tuercas estriadas',
	},
	{
		code: '31161730',
		label: 'Tuercas cuadradas',
	},
	{
		code: '31161731',
		label: 'Tuercas soldables',
	},
	{
		code: '31161732',
		label: 'Tuerca medialuna',
	},
	{
		code: '31161733',
		label: 'Tuerca en t o ranura de tuerca en t',
	},
	{
		code: '31161734',
		label: 'Tuerca redonda con ranura',
	},
	{
		code: '31161735',
		label: 'Tuerca de jaula',
	},
	{
		code: '31161736',
		label: 'Tapa de tuerca decorativa',
	},
	{
		code: '31161737',
		label: 'Tuerca de cizalla',
	},
	{
		code: '31161738',
		label: 'Tuerca keps o k',
	},
	{
		code: '31161739',
		label: 'Tuerca de soldadura',

		palabrasSimilares: 'Tuerca dentada',
	},
	{
		code: '31161740',
		label: 'Tuerca perno',
	},
	{
		code: '31161741',
		label: 'Tuerca de llave interna',

		palabrasSimilares: 'Tornillo con tuerca',
	},
	{
		code: '31161800',
		label: 'Arandelas',

		palabrasSimilares: 'Rondanas',
	},
	{
		code: '31161801',
		label: 'Arandelas de seguridad',

		palabrasSimilares: 'Rondanas de seguridad',
	},
	{
		code: '31161802',
		label: 'Arandelas achaflanadas',
	},
	{
		code: '31161803',
		label: 'Arandelas de fijación',

		palabrasSimilares: 'Rondanas de fijación',
	},
	{
		code: '31161804',
		label: 'Arandelas curvas',

		palabrasSimilares: 'Rondanas curvas',
	},
	{
		code: '31161805',
		label: 'Arandelas aislantes eléctricas',

		palabrasSimilares: 'Rondanas aislantes',
	},
	{
		code: '31161806',
		label: 'Arandelas de acabado',

		palabrasSimilares: 'Rondanas de acabado',
	},
	{
		code: '31161807',
		label: 'Arandelas planas',

		palabrasSimilares: 'Rondanas planas',
	},
	{
		code: '31161808',
		label: 'Arandelas abiertas',

		palabrasSimilares: 'Rondanas abiertas',
	},
	{
		code: '31161809',
		label: 'Arandelas reductoras',

		palabrasSimilares: 'Rondanas reductoras',
	},
	{
		code: '31161810',
		label: 'Arandelas de separación',

		palabrasSimilares: 'Rondanas de separación',
	},
	{
		code: '31161811',
		label: 'Arandelas de resorte',

		palabrasSimilares: 'Rondanas de resorte',
	},
	{
		code: '31161812',
		label: 'Arandelas cuadradas',

		palabrasSimilares: 'Rondanas cuadradas',
	},
	{
		code: '31161813',
		label: 'Arandelas giratorias',

		palabrasSimilares: 'Rondanas giratorias',
	},
	{
		code: '31161814',
		label: 'Arandelas de empuje',

		palabrasSimilares: 'Rondanas de empuje',
	},
	{
		code: '31161815',
		label: 'Arandelas de tope',

		palabrasSimilares: 'Rondanas de tope',
	},
	{
		code: '31161816',
		label: 'Espaciadores y separadores',
	},
	{
		code: '31161817',
		label: 'Arandelas cónicas',

		palabrasSimilares: 'Empaque cónico',
	},
	{
		code: '31161818',
		label: 'Arandelas de sellado',

		palabrasSimilares: 'Empaque de sellado',
	},
	{
		code: '31161819',
		label: 'Juegos de arandelas',
	},
	{
		code: '31161820',
		label: 'Arandelas en domo o esféricas',
	},
	{
		code: '31161821',
		label: 'Arandela roscada',

		palabrasSimilares: 'Rondana roscada',
	},
	{
		code: '31161822',
		label: 'Arandelas con dientes de seguridad',
	},
	{
		code: '31161823',
		label: 'Arandela distribuidora',
	},
	{
		code: '31161824',
		label: 'Arandela para perno de techo',
	},
	{
		code: '31161825',
		label: 'Arandela belleville',
	},
	{
		code: '31161826',
		label: 'Arandela dentada',
	},
	{
		code: '31161827',
		label: 'Arandela recortada',
	},
	{
		code: '31161828',
		label: 'Arandela con ranura',
	},
	{
		code: '31161829',
		label: 'Arandela retenedora',
	},
	{
		code: '31161830',
		label: 'Arandelas de ola',
	},
	{
		code: '31161831',
		label: 'Arandela reductora de vibración',
	},
	{
		code: '31161832',
		label: 'Arandelas avellanadas',
	},
	{
		code: '31161833',
		label: 'Arandela empaque',
	},
	{
		code: '31161834',
		label: 'Arandelas de copa',
	},
	{
		code: '31161835',
		label: 'Arandelas de pestaña',
	},
	{
		code: '31161836',
		label: 'Arandelas de placa de anclaje',
	},
	{
		code: '31161837',
		label: 'Arandelas de división',
	},
	{
		code: '31161838',
		label: 'Arandelas lenguadas',
	},
	{
		code: '31161900',
		label: 'Muelles',
	},
	{
		code: '31161901',
		label: 'Muelles helicoidales',

		palabrasSimilares: 'Resortes',
	},
	{
		code: '31161902',
		label: 'Muelles',
	},
	{
		code: '31161903',
		label: 'Muelles espirales',
	},
	{
		code: '31161904',
		label: 'Resortes de compresión',
	},
	{
		code: '31161905',
		label: 'Resortes para estampas',
	},
	{
		code: '31161906',
		label: 'Muelles de disco',
	},
	{
		code: '31161907',
		label: 'Muelles de extensión',
	},
	{
		code: '31161908',
		label: 'Muelles de torsión',
	},
	{
		code: '31161909',
		label: 'Muelle en ola',
	},
	{
		code: '31161910',
		label: 'Resorte de alambre',
	},
	{
		code: '31161911',
		label: 'Ensamble de resorte',
	},
	{
		code: '31161912',
		label: 'Resorte de válvula inyectora',
	},
	{
		code: '31162000',
		label: 'Clavos',
	},
	{
		code: '31162001',
		label: 'Chinches',

		palabrasSimilares: 'Tachuelas',
	},
	{
		code: '31162002',
		label: 'Clavos de sombrerete',

		palabrasSimilares: 'Remaches',
	},
	{
		code: '31162003',
		label: 'Clavos de acabado',
	},
	{
		code: '31162004',
		label: 'Clavos de mampostería',

		palabrasSimilares: 'Clavos para concreto u hormigón',
	},
	{
		code: '31162005',
		label: 'Clavos para tejados',
	},
	{
		code: '31162006',
		label: 'Clavos de alambre',
	},
	{
		code: '31162007',
		label: 'Clavos de tapicería',
	},
	{
		code: '31162008',
		label: 'Pasadores de arrastre',
	},
	{
		code: '31162009',
		label: 'Clavo de fibra cemento',
	},
	{
		code: '31162010',
		label: 'Clavo de herradura',
	},
	{
		code: '31162011',
		label: 'Puntilla decorativa',
	},
	{
		code: '31162012',
		label: 'Clavo de levantamientos topográficos',
	},
	{
		code: '31162013',
		label: 'Clave de botes',
	},
	{
		code: '31162014',
		label: 'Clavo ferroviario',
	},
	{
		code: '31162100',
		label: 'Anclas',
	},
	{
		code: '31162101',
		label: 'Anclajes de concreto',
	},
	{
		code: '31162102',
		label: 'Anclajes de cuña',
	},
	{
		code: '31162103',
		label: 'Anclajes de pared',
	},
	{
		code: '31162104',
		label: 'Anclajes de tornillo',
	},
	{
		code: '31162105',
		label: 'Anclajes de resina',
	},
	{
		code: '31162106',
		label: 'Anclaje de tubería',
	},
	{
		code: '31162107',
		label: 'Anclajes de expansión de clavo',
	},
	{
		code: '31162108',
		label: 'Anclajes de amarre',
	},
	{
		code: '31162109',
		label: 'Anclaje de cimentación',
	},
	{
		code: '31162200',
		label: 'Remaches',
	},
	{
		code: '31162201',
		label: 'Remaches ciegos',
	},
	{
		code: '31162202',
		label: 'Remaches de corona',
	},
	{
		code: '31162203',
		label: 'Remaches de cabeza plana',
	},
	{
		code: '31162204',
		label: 'Remaches completos',
	},
	{
		code: '31162205',
		label: 'Remaches de trinquetes',
	},
	{
		code: '31162206',
		label: 'Remaches de estañador',
	},
	{
		code: '31162207',
		label: 'Remaches de compresión',
	},
	{
		code: '31162208',
		label: 'Remaches de tonelero',
	},
	{
		code: '31162209',
		label: 'Remaches de botón',
	},
	{
		code: '31162210',
		label: 'Remaches articulados',
	},
	{
		code: '31162211',
		label: 'Remaches sólidos',
	},
	{
		code: '31162212',
		label: 'Remache con mandril',
	},
	{
		code: '31162213',
		label: 'Remache aerodinámico',
	},
	{
		code: '31162214',
		label: 'Remache semi – tubular',
	},
	{
		code: '31162215',
		label: 'Remache tubular',
	},
	{
		code: '31162216',
		label: 'Remache de hombro',
	},
	{
		code: '31162300',
		label: 'Ferretería de montaje',
	},
	{
		code: '31162301',
		label: 'Perfiles de montaje',
	},
	{
		code: '31162303',
		label: 'Barras de montaje',
	},
	{
		code: '31162304',
		label: 'Regletas de montaje',
	},
	{
		code: '31162305',
		label: 'Abrazaderas de montaje',
	},
	{
		code: '31162306',
		label: 'Soportes colgantes de montaje',
	},
	{
		code: '31162307',
		label: 'Placas de montaje',
	},
	{
		code: '31162308',
		label: 'Paneles de montaje',
	},
	{
		code: '31162309',
		label: 'Estantes de montaje',
	},
	{
		code: '31162310',
		label: 'Correas de montaje',
	},
	{
		code: '31162311',
		label: 'Bujes de pared',
	},
	{
		code: '31162312',
		label: 'Pasadores de montaje',
	},
	{
		code: '31162313',
		label: 'Kits de montaje',
	},
	{
		code: '31162314',
		label: 'Espaciador de baldosas',
	},
	{
		code: '31162400',
		label: 'Fijadores varios',
	},
	{
		code: '31162401',
		label: 'Aros interiores',
	},
	{
		code: '31162402',
		label: 'Cerraduras',
	},
	{
		code: '31162403',
		label: 'Goznes o bisagras',
	},
	{
		code: '31162404',
		label: 'Grapas',
	},
	{
		code: '31162405',
		label: 'Tensores',
	},
	{
		code: '31162406',
		label: 'Cierres para zunchado',

		palabrasSimilares: 'Sujetador de estructuras verticales.',
	},
	{
		code: '31162407',
		label: 'Pestillo',

		palabrasSimilares: 'Cerrojo, Pasador',
	},
	{
		code: '31162409',
		label: 'Pasador de horquilla',
	},
	{
		code: '31162410',
		label: 'Pasadores estriados',
	},
	{
		code: '31162411',
		label: 'Anillos elásticos',
	},
	{
		code: '31162412',
		label: 'Horquilla',
	},
	{
		code: '31162413',
		label: 'Cierre de presión',
	},
	{
		code: '31162414',
		label: 'Abrazadera',
	},
	{
		code: '31162415',
		label: 'Uñeta',
	},
	{
		code: '31162416',
		label: 'Pasadores de conexión o acoplamiento',
	},
	{
		code: '31162417',
		label: 'Pasadores de alineación',
	},
	{
		code: '31162418',
		label: 'Ataduras de torsión',
	},
	{
		code: '31162419',
		label: 'Anillos y eslabones',
	},
	{
		code: '31162420',
		label: 'Bisagra de ingeniería',
	},
	{
		code: '31162421',
		label: 'Trampa',
	},
	{
		code: '31162500',
		label: 'Soportes y puntales',

		palabrasSimilares: 'Soportería',
	},
	{
		code: '31162501',
		label: 'Soportes para estanterías',
	},
	{
		code: '31162502',
		label: 'Soportes en escuadra',
	},
	{
		code: '31162503',
		label: 'Puntales',
	},
	{
		code: '31162504',
		label: 'Soportes para accesorios eléctricos',
	},
	{
		code: '31162505',
		label: 'Soportes de montaje magnético',
	},
	{
		code: '31162506',
		label: 'Soporte de pared',
	},
	{
		code: '31162507',
		label: 'Soportes de piñón',
	},
	{
		code: '31162508',
		label: 'Soporte de eje de ojo',
	},
	{
		code: '31162600',
		label: 'Ganchos',
	},
	{
		code: '31162601',
		label: 'Ganchos giratorios',
	},
	{
		code: '31162602',
		label: 'Ganchos de resorte',
	},
	{
		code: '31162603',
		label: 'Ganchos en s',
	},
	{
		code: '31162604',
		label: 'Ganchos de seguridad',
	},
	{
		code: '31162605',
		label: 'Ganchos de suspensión',
	},
	{
		code: '31162606',
		label: 'Ganchos en j',
	},
	{
		code: '31162607',
		label: 'Ganchos de alambre para riostras',
	},
	{
		code: '31162608',
		label: 'Ganchos de grúa',
	},
	{
		code: '31162609',
		label: 'Ganchos de atornillar',
	},
	{
		code: '31162610',
		label: 'Ganchos de tablero',
	},
	{
		code: '31162611',
		label: 'Gancho de deslizamiento',
	},
	{
		code: '31162612',
		label: 'Gancho cuadrado',
	},
	{
		code: '31162613',
		label: 'Gancho en forma de 8',
	},
	{
		code: '31162614',
		label: 'Gancho fijo',
	},
	{
		code: '31162700',
		label: 'Roldanas pivotantes y Ruedas',
	},
	{
		code: '31162701',
		label: 'Ruedas para muebles',
	},
	{
		code: '31162702',
		label: 'Ruedas',
	},
	{
		code: '31162703',
		label: 'Deslizadoras',
	},
	{
		code: '31162704',
		label: 'Puntas de rodillo',

		palabrasSimilares: 'Rodillos con puntas',
	},
	{
		code: '31162800',
		label: 'Ferretería en general',
	},
	{
		code: '31162801',
		label: 'Chapas o pomos',
	},
	{
		code: '31162802',
		label: 'Insertos',

		palabrasSimilares: 'Tubería para insertar',
	},
	{
		code: '31162803',
		label: 'Grilletes',
	},
	{
		code: '31162804',
		label: 'Topes de puerta',
	},
	{
		code: '31162805',
		label: 'Cable dedal',
	},
	{
		code: '31162806',
		label: 'Cubiertas de tornillos',
	},
	{
		code: '31162807',
		label: 'Palancas',
	},
	{
		code: '31162808',
		label: 'Barras de pánico',
	},
	{
		code: '31162809',
		label: 'Pasadores posicionadores',
	},
	{
		code: '31162810',
		label: 'Empalmes o placas de unión',
	},
	{
		code: '31162811',
		label: 'Collar del eje',
	},
	{
		code: '31162812',
		label: 'Mangas de prensado',
	},
	{
		code: '31162813',
		label: 'Ganchos para cerda de ropa',
	},
	{
		code: '31162814',
		label: 'Kit de sujetadores varios',
	},
	{
		code: '31162815',
		label: 'Calce',
	},
	{
		code: '31162816',
		label: 'Hilera',
	},
	{
		code: '31162817',
		label: 'Soporte de cuerda de ropa',
	},
	{
		code: '31162818',
		label: 'Ánodo anti corrosión',
	},
	{
		code: '31162819',
		label: 'Palanca de control',
	},
	{
		code: '31162900',
		label: 'Mordazas',
	},
	{
		code: '31162901',
		label: 'Abrazaderas de espiga',
	},
	{
		code: '31162902',
		label: 'Abrazaderas de resorte',
	},
	{
		code: '31162903',
		label: 'Abrazaderas de tornillo',
	},
	{
		code: '31162904',
		label: 'Abrazadera de cable metálico',
	},
	{
		code: '31162905',
		label: 'Abrazadera para viga doble t',
	},
	{
		code: '31162906',
		label: 'Abrazaderas de manguera o tubo',
	},
	{
		code: '31162907',
		label: 'Abrazadera de extensión',
	},
	{
		code: '31162908',
		label: 'Abrazadera en miniatura',
	},
	{
		code: '31162909',
		label: 'Abrazadera isofónica',
	},
	{
		code: '31162910',
		label: 'Abrazadera de taladro',
	},
	{
		code: '31162911',
		label: 'Abrazadera de tornillo manual',
	},
	{
		code: '31162912',
		label: 'Abrazadera de banda',
	},
	{
		code: '31162913',
		label: 'Abrazadera de banco',
	},
	{
		code: '31162914',
		label: 'Abrazadera de red',
	},
	{
		code: '31162915',
		label: 'Abrazadera de ingletes',
	},
	{
		code: '31162916',
		label: 'Abrazadera de acción rápida',
	},
	{
		code: '31162917',
		label: 'Abrazadera de agarre rápido',
	},
	{
		code: '31162918',
		label: 'Abrazadera de potencia',
	},
	{
		code: '31162919',
		label: 'Abrazadera de velocidad',
	},
	{
		code: '31162920',
		label: 'Abrazadera de marco de fotos',
	},
	{
		code: '31163000',
		label: 'Embragues',
	},
	{
		code: '31163001',
		label: 'Acoples elastoméricos',
	},
	{
		code: '31163002',
		label: 'Acoples por engranaje',
	},
	{
		code: '31163003',
		label: 'Acoples metálicos',
	},
	{
		code: '31163004',
		label: 'Acoples en miniatura',
	},
	{
		code: '31163005',
		label: 'Manguitos de acoplamiento',
	},
	{
		code: '31163006',
		label: 'Acoples de rejilla',
	},
	{
		code: '31163007',
		label: 'Acoples de cadena',
	},
	{
		code: '31163008',
		label: 'Acoples de disco',
	},
	{
		code: '31163009',
		label: 'Acoples rígidos',
	},
	{
		code: '31163010',
		label: 'Acoples de araña',
	},
	{
		code: '31163011',
		label: 'Acoples de mordaza',
	},
	{
		code: '31163012',
		label: 'Acoples medios',
	},
	{
		code: '31163013',
		label: 'Acoples de ejes',
	},
	{
		code: '31163014',
		label: 'Acoples giratorio',
	},
	{
		code: '31163015',
		label: 'Acoples oldham',
	},
	{
		code: '31163016',
		label: 'Acoples de varilla',
	},
	{
		code: '31163017',
		label: 'Varilla de acople',
	},
	{
		code: '31163018',
		label: 'Acoples de fuelle',
	},
	{
		code: '31163019',
		label: 'Acoples magnéticos',
	},
	{
		code: '31163020',
		label: 'Acoples de brida',
	},
	{
		code: '31163021',
		label: 'Acople universal flexible',
	},
	{
		code: '31163022',
		label: 'Acoples de abrazadera',
	},
	{
		code: '31163023',
		label: 'Acoples de mariposa',
	},
	{
		code: '31163024',
		label: 'Acoples de brida tipo flexible',
	},
	{
		code: '31163025',
		label: 'Acoples de mariposa partida',
	},
	{
		code: '31163026',
		label: 'Acoples de media vuelta',
	},
	{
		code: '31163027',
		label: 'Acoples de cadena giratoria',
	},
	{
		code: '31163028',
		label: 'Acoples de rejilla de tipo flexible',
	},
	{
		code: '31163029',
		label: 'Acoples de clip de fricción',
	},
	{
		code: '31163030',
		label: 'Acoples duraflex',
	},
	{
		code: '31163031',
		label: 'Acoples de vendedores',
	},
	{
		code: '31163032',
		label: 'Adapatador eléctrico de acoples',
	},
	{
		code: '31163100',
		label: 'Conectores',
	},
	{
		code: '31163101',
		label: 'Desconectores rápidos',

		palabrasSimilares: 'Corta corriente',
	},
	{
		code: '31163102',
		label: 'Férula',

		palabrasSimilares: 'Virola',
	},
	{
		code: '31163103',
		label: 'Conector de remolque',
	},
	{
		code: '31163200',
		label: 'Herraje de retención',
	},
	{
		code: '31163201',
		label: 'Pasadores de resorte',
	},
	{
		code: '31163202',
		label: 'Anillos de retención',
	},
	{
		code: '31163203',
		label: 'Pasador de espiga',
	},
	{
		code: '31163204',
		label: 'Pasador de horquilla',
	},
	{
		code: '31163205',
		label: 'Pasadores de cono',
	},
	{
		code: '31163207',
		label: 'Horquillas de eje o de disco',
	},
	{
		code: '31163208',
		label: 'Bloquecillo',
	},
	{
		code: '31163209',
		label: 'Soportes o retenes del rodamiento',
	},
	{
		code: '31163210',
		label: 'Collares de retención',
	},
	{
		code: '31163211',
		label: 'Grapas de retención',
	},
	{
		code: '31163212',
		label: 'Pasadores roscados',
	},
	{
		code: '31163213',
		label: 'Pasadores de pivote',
	},
	{
		code: '31163214',
		label: 'Pasadores de deslizamiento',
	},
	{
		code: '31163215',
		label: 'Pasadores estriados',
	},
	{
		code: '31163216',
		label: 'Colgador de cable',
	},
	{
		code: '31163217',
		label: 'Bujes de bloqueo de tubo de desconexión rápida',
	},
	{
		code: '31163218',
		label: 'Manga para adaptación de rodamiento',
	},
	{
		code: '31163219',
		label: 'Pasador cilíndrico',
	},
	{
		code: '31163220',
		label: 'Juego de pines diversos',
	},
	{
		code: '31163221',
		label: 'Llave de pasador',
	},
	{
		code: '31163222',
		label: 'Llave cuadrada',
	},
	{
		code: '31163223',
		label: 'Llave rectangular',
	},
	{
		code: '31163224',
		label: 'Llave cónica',
	},
	{
		code: '31163225',
		label: 'Llave de broche de cuña',
	},
	{
		code: '31163226',
		label: 'Anillo de seguridad',
	},
	{
		code: '31163227',
		label: 'Anillo en eje (flinger)',
	},
	{
		code: '31163228',
		label: 'Anillo partido',
	},
	{
		code: '31163229',
		label: 'Clip retenedor metálico',
	},
	{
		code: '31163230',
		label: 'Clip retenedor plástico',

		palabrasSimilares: 'Duola retenedora de plástico',
	},
	{
		code: '31163231',
		label: 'Collar de retención de tubos',

		palabrasSimilares: 'Abrazadera',
	},
	{
		code: '31163300',
		label: 'Espaciadores',
	},
	{
		code: '31163301',
		label: 'Montante de dos extremos',

		palabrasSimilares: 'Separador doble broche',
	},
	{
		code: '31163302',
		label: 'Montante para soldar',

		palabrasSimilares: 'Perno de soldadura, Perno para sujetar',
	},
	{
		code: '31163303',
		label: 'Montante de bola',

		palabrasSimilares: 'Separador de bola',
	},
	{
		code: '31163304',
		label: 'Montante complejo',

		palabrasSimilares: 'Remache espaciador compuesto',
	},
	{
		code: '31163400',
		label: 'Redes de metal y estructuras de cribado',
	},
	{
		code: '31163401',
		label: 'Gavión',

		palabrasSimilares: 'Contenedor de malla de metal',
	},
	{
		code: '31171500',
		label: 'Rodamientos',

		palabrasSimilares: 'Baleros',
	},
	{
		code: '31171501',
		label: 'Rodamientos embridados',
	},
	{
		code: '31171502',
		label: 'Rodamientos radiales',

		palabrasSimilares: 'Baleros con pestaña',
	},
	{
		code: '31171503',
		label: 'Rodamientos de rueda',

		palabrasSimilares: 'Baleros para rueda',
	},
	{
		code: '31171504',
		label: 'Rodamientos de balineras',

		palabrasSimilares: 'Baleros de balines',
	},
	{
		code: '31171505',
		label: 'Rodamientos de rodillos',

		palabrasSimilares: 'Baleros de rodillos',
	},
	{
		code: '31171506',
		label: 'Rodamientos lineales',

		palabrasSimilares: 'Baleros lineales',
	},
	{
		code: '31171507',
		label: 'Rodamientos de empuje',

		palabrasSimilares: 'Baleros de empuje, Baleros de presión',
	},
	{
		code: '31171508',
		label: 'Rodamientos de cabeza de biela',

		palabrasSimilares: 'Balero para biela',
	},
	{
		code: '31171509',
		label: 'Rodamientos de manguito interior',

		palabrasSimilares: 'Balero de manguito',
	},
	{
		code: '31171510',
		label: 'Rodamientos esféricos',

		palabrasSimilares: 'Balero esféricos',
	},
	{
		code: '31171511',
		label: 'Soportes de cojinetes',

		palabrasSimilares: 'Soportes de cojinetes para baleros',
	},
	{
		code: '31171512',
		label: 'Rodamiento de agujas',

		palabrasSimilares: 'Balancines o semibalancines, Balero de agujas',
	},
	{
		code: '31171513',
		label: 'Rodamientos colgaderos',

		palabrasSimilares: 'Baleros colgantes',
	},
	{
		code: '31171515',
		label: 'Rodamientos simples',

		palabrasSimilares: 'Baleros simples',
	},
	{
		code: '31171516',
		label: 'Rodamientos cónicos',

		palabrasSimilares: 'Baleros cónicos',
	},
	{
		code: '31171518',
		label: 'Jaula de rodamiento',

		palabrasSimilares: 'Jaula para baleros',
	},
	{
		code: '31171519',
		label: 'Carcasas o pedestales de rodamientos',
	},
	{
		code: '31171520',
		label: 'Muñones de rodamientos',
	},
	{
		code: '31171522',
		label: 'Rodamientos magnéticos',
	},
	{
		code: '31171523',
		label: 'Rodamientos neumáticos',

		palabrasSimilares: 'Baleros neumáticos',
	},
	{
		code: '31171524',
		label: 'Tapas de rodamientos',

		palabrasSimilares: 'Tapas de baleros',
	},
	{
		code: '31171525',
		label: 'Revestimientos de rodamiento',
	},
	{
		code: '31171526',
		label: 'Almohadillas de rodamiento',

		palabrasSimilares: 'Empaque de balero',
	},
	{
		code: '31171527',
		label: 'Conos de rodamiento',
	},
	{
		code: '31171528',
		label: 'Rodamiento partido',
	},
	{
		code: '31171529',
		label: 'Sombreretes de rodamiento',
	},
	{
		code: '31171530',
		label: 'Rodamiento de seguimiento de levas',
	},
	{
		code: '31171531',
		label: 'Rodamiento de contacto angular',
	},
	{
		code: '31171532',
		label: 'Rodamiento auto – alineante',
	},
	{
		code: '31171533',
		label: 'Rodamiento y marco ajustables',
	},
	{
		code: '31171534',
		label: 'Rodamiento de base cónica',
	},
	{
		code: '31171535',
		label: 'Rodamiento de cartucho',
	},
	{
		code: '31171536',
		label: 'Rodamiento de insertar',
	},
	{
		code: '31171537',
		label: 'Rodamiento de combinación',
	},
	{
		code: '31171538',
		label: 'Rodamiento de cuatro puntos',
	},
	{
		code: '31171539',
		label: 'Balinera',
	},
	{
		code: '31171540',
		label: 'Aguja de rodamiento',
	},
	{
		code: '31171541',
		label: 'Rodillo de rodamiento',
	},
	{
		code: '31171542',
		label: 'Rodamiento tensionador',
	},
	{
		code: '31171543',
		label: 'Rodamiento de flexión',
	},
	{
		code: '31171544',
		label: 'Bloque de rodamiento plummer',
	},
	{
		code: '31171545',
		label: 'Cubierta de rodamiento',
	},
	{
		code: '31171546',
		label: 'Rodamiento reversible',
	},
	{
		code: '31171547',
		label: 'Rodamiento cilíndrico',
	},
	{
		code: '31171548',
		label: 'Manguito de retiro de rodamiento',
	},
	{
		code: '31171549',
		label: 'Rodamiento de joyería',
	},
	{
		code: '31171550',
		label: 'Rodamiento combinado de rodillo y balinera',
	},
	{
		code: '31171551',
		label: 'Rodamiento sin aceite',
	},
	{
		code: '31171552',
		label: 'Rodamiento de balinera de empuje',
	},
	{
		code: '31171553',
		label: 'Rodamiento de rodillo de empuje',
	},
	{
		code: '31171554',
		label: 'Rodamiento guía',
	},
	{
		code: '31171555',
		label: 'Ensamble de adaptador de rodamiento de rodillos',
	},
	{
		code: '31171556',
		label: 'Rodamiento inserto de alto desempeño',
	},
	{
		code: '31171557',
		label: 'Buje de chumacera',
	},
	{
		code: '31171558',
		label: 'Rodillo de movimiento lineal y giratorio',
	},
	{
		code: '31171559',
		label: 'Placa de bloqueo para rodamiento de rodillo',
	},
	{
		code: '31171560',
		label: 'Anillo de presión de bloqueo para rodamiento de rodillo',
	},
	{
		code: '31171561',
		label: 'Manguito adaptador para rodamiento de rodillo',
	},
	{
		code: '31171562',
		label: 'Rodamiento inserto con bloqueo de tornillo',
	},
	{
		code: '31171563',
		label: 'Rodamiento de insertar con collar de bloqueo excéntrico',
	},
	{
		code: '31171564',
		label: 'Rodamiento de insertar con anillo interior estándar',
	},
	{
		code: '31171600',
		label: 'Cojinetes',
	},
	{
		code: '31171603',
		label: 'Bujes de taladro',
	},
	{
		code: '31171604',
		label: 'Bujes pilotos',
	},
	{
		code: '31171605',
		label: 'Bujes de eje',
	},
	{
		code: '31171606',
		label: 'Bujes de brida',
	},
	{
		code: '31171607',
		label: 'Buje de desgaste',
	},
	{
		code: '31171608',
		label: 'Buje en blanco',
	},
	{
		code: '31171609',
		label: 'Manga de buje',
	},
	{
		code: '31171610',
		label: 'Buje forjado en frío',
	},
	{
		code: '31171700',
		label: 'Engranajes',

		palabrasSimilares: 'Engranes',
	},
	{
		code: '31171704',
		label: 'Engranajes de fricción',
	},
	{
		code: '31171706',
		label: 'Engranajes cónicos',
	},
	{
		code: '31171707',
		label: 'Engranajes rectos',
	},
	{
		code: '31171708',
		label: 'Engranajes biselados',
	},
	{
		code: '31171709',
		label: 'Engranajes cremallera',
	},
	{
		code: '31171710',
		label: 'Engranajes piñón',
	},
	{
		code: '31171711',
		label: 'Engranajes de anillo',
	},
	{
		code: '31171712',
		label: 'Engranajes de tornillo sin fin',
	},
	{
		code: '31171713',
		label: 'Engranajes laterales',
	},
	{
		code: '31171714',
		label: 'Engranajes helicoidales',
	},
	{
		code: '31171715',
		label: 'Engranajes cilíndricos',
	},
	{
		code: '31171716',
		label: 'Engranajes en espina de pescado',
	},
	{
		code: '31171800',
		label: 'Ruedas industriales',
	},
	{
		code: '31171801',
		label: 'Ruedas dentadas',
	},
	{
		code: '31171802',
		label: 'Ruedas motrices',
	},
	{
		code: '31171803',
		label: 'Rueda voladora',

		palabrasSimilares: 'Rueda volante',
	},
	{
		code: '31171804',
		label: 'Poleas',
	},
	{
		code: '31171805',
		label: 'Cepillos de rueda',
	},
	{
		code: '31171806',
		label: 'Ruedas de rodillos',
	},
	{
		code: '31171900',
		label: 'Ruedas dentadas',
	},
	{
		code: '31171901',
		label: 'Ruedas dentadas para cadena de rodillos',
	},
	{
		code: '31171902',
		label: 'Ruedas dentadas para cadena de ingeniería',
	},
	{
		code: '31171903',
		label: 'Ruedas dentadas para cadena de transporte',
	},
	{
		code: '31171904',
		label: 'Ruedas dentadas para cadena silenciosa',
	},
	{
		code: '31171905',
		label: 'Rueda dentada o polea de cadena',
	},
	{
		code: '31181700',
		label: 'Embalajes y empaquetaduras',
	},
	{
		code: '31181701',
		label: 'Empaques',

		palabrasSimilares: 'Embalaje',
	},
	{
		code: '31181702',
		label: 'Prensaestopas',

		palabrasSimilares: 'Embalaje de fibra sintética',
	},
	{
		code: '31181703',
		label: 'Deflectores de aceite',
	},
	{
		code: '31181704',
		label: 'Caja de relleno',

		palabrasSimilares: 'Caja para empacar',
	},
	{
		code: '31181705',
		label: 'Anillo de sello de soporte',

		palabrasSimilares: 'Junta de anillo de soporte',
	},
	{
		code: '31181800',
		label: 'Cubiertas automotrices',
	},
	{
		code: '31181801',
		label: 'Manija de palanca de cambios de transmisión automotriz',

		palabrasSimilares: 'Cubierta de palanca de cambios',
	},
	{
		code: '31181802',
		label: 'Cubierta de bujía',
	},
	{
		code: '31191500',
		label: 'Abrasivos y medios de abrasivo',
	},
	{
		code: '31191501',
		label: 'Papeles abrasivos',

		palabrasSimilares: 'Papel de lija',
	},
	{
		code: '31191502',
		label: 'Pulidor',
	},
	{
		code: '31191504',
		label: 'Telas abrasivas',

		palabrasSimilares: 'Fibra abrasiva, Paño abrasivo',
	},
	{
		code: '31191505',
		label: 'Almohadillas abrasivas',

		palabrasSimilares: 'Esponja abrasiva',
	},
	{
		code: '31191506',
		label: 'Discos abrasivos',
	},
	{
		code: '31191507',
		label: 'Cintas abrasivas',
	},
	{
		code: '31191508',
		label: 'Polvo de diamante',
	},
	{
		code: '31191509',
		label: 'Pulidores abrasivos',
	},
	{
		code: '31191510',
		label: 'Piedras abrasivas',
	},
	{
		code: '31191511',
		label: 'Virutas de acero',
	},
	{
		code: '31191512',
		label: 'Chorro de balines o perdigones',

		palabrasSimilares: 'Chorreado abrasivo',
	},
	{
		code: '31191513',
		label: 'Cuentas de vidrio',
	},
	{
		code: '31191514',
		label: 'Medio amortiguador',

		palabrasSimilares: 'Maquina de pulido',
	},
	{
		code: '31191515',
		label: 'Malla abrasiva',
	},
	{
		code: '31191516',
		label: 'Rollos de cartucho abrasivo',
	},
	{
		code: '31191517',
		label: 'Planchas de esmeril',
	},
	{
		code: '31191518',
		label: 'Carburo de tungsteno',

		palabrasSimilares: 'Carburo de wolframio',
	},
	{
		code: '31191519',
		label: 'Tambores abrasivos',
	},
	{
		code: '31191520',
		label: 'Abrasivo estrella',
	},
	{
		code: '31191521',
		label: 'Cepillo abrasivo',
	},
	{
		code: '31191600',
		label: 'Ruedas abrasivas',
	},
	{
		code: '31191601',
		label: 'Ruedas abrasivas cúbicas de nitrato borozon',
	},
	{
		code: '31191602',
		label: 'Ruedas abrasivas de diamante',
	},
	{
		code: '31191603',
		label: 'Ruedas abrasivas de carburo de tungsteno',
	},
	{
		code: '31201500',
		label: 'Cinta adhesiva',
	},
	{
		code: '31201501',
		label: 'Cinta de ductos',

		palabrasSimilares: 'Cinta americana',
	},
	{
		code: '31201502',
		label: 'Cinta aislante eléctrica',

		palabrasSimilares: 'Cinta de aislar o aislante',
	},
	{
		code: '31201503',
		label: 'Cinta de enmascarar',

		palabrasSimilares: 'Masking',
	},
	{
		code: '31201504',
		label: 'Cinta para alfombras',
	},
	{
		code: '31201505',
		label: 'Cinta doble faz',
	},
	{
		code: '31201506',
		label: 'Cinta de bísmalemida',
	},
	{
		code: '31201507',
		label: 'Cinta de fibra de vidrio',
	},
	{
		code: '31201508',
		label: 'Cinta de grafito',
	},
	{
		code: '31201509',
		label: 'Cinta de nylon',
	},
	{
		code: '31201510',
		label: 'Cinta impregnada de resina',
	},
	{
		code: '31201511',
		label: 'Cinta de malla metálica',
	},
	{
		code: '31201512',
		label: 'Cinta transparente',

		palabrasSimilares: 'Diurex',
	},
	{
		code: '31201513',
		label: 'Cintas antideslizantes de seguridad',
	},
	{
		code: '31201514',
		label: 'Cinta de sellado de hilo de poli tetrafluoretileno (ptfe)',

		palabrasSimilares: 'Cinta de teflón',
	},
	{
		code: '31201515',
		label: 'Cintas de papel',
	},
	{
		code: '31201516',
		label: 'Cinta reflectiva',
	},
	{
		code: '31201517',
		label: 'Cinta para empaquetar',

		palabrasSimilares: 'Cinta canela',
	},
	{
		code: '31201518',
		label: 'Cinta conductora de electricidad',
	},
	{
		code: '31201519',
		label: 'Cinta para reparar tubería o manguera',
	},
	{
		code: '31201520',
		label: 'Cinta para marcar los pasillos',
	},
	{
		code: '31201521',
		label: 'Cinta metálica',
	},
	{
		code: '31201522',
		label: 'Cinta de transferencia adhesiva',
	},
	{
		code: '31201523',
		label: 'Cinta de tela',
	},
	{
		code: '31201524',
		label: 'Cinta para codificación de color',
	},
	{
		code: '31201525',
		label: 'Cinta de vinilo',
	},
	{
		code: '31201526',
		label: 'Cinta magnética',
	},
	{
		code: '31201527',
		label: 'Cintas de espuma',
	},
	{
		code: '31201528',
		label: 'Cinta de montaje',
	},
	{
		code: '31201529',
		label: 'Cinta de perfilar',
	},
	{
		code: '31201530',
		label: 'Cinta de papel de aluminio',
	},
	{
		code: '31201531',
		label: 'Cinta de polietileno',
	},
	{
		code: '31201532',
		label: 'Cinta de cloruro de polivinilo pvc',

		palabrasSimilares: 'Cinta adhesiva de PVC, Cinta pvc para tuberia',
	},
	{
		code: '31201533',
		label: 'Cinta de hoja de plomo',
	},
	{
		code: '31201534',
		label: 'Cinta de caucho',
	},
	{
		code: '31201535',
		label: 'Cinta para empalmes de cables',
	},
	{
		code: '31201536',
		label: 'Cinta de lanilla de polietileno',
	},
	{
		code: '31201537',
		label: 'Cinta de arrancar',

		palabrasSimilares: 'Cinta de rasgado para empaques',
	},
	{
		code: '31201600',
		label: 'Otros adhesivos y selladores',
	},
	{
		code: '31201601',
		label: 'Adhesivos químicos',
	},
	{
		code: '31201602',
		label: 'Pastas',
	},
	{
		code: '31201603',
		label: 'Gomas',

		palabrasSimilares: 'Pegamento de cola',
	},
	{
		code: '31201604',
		label: 'Cementos de caucho',
	},
	{
		code: '31201605',
		label: 'Masillas',

		palabrasSimilares: 'Plastilina epóxica',
	},
	{
		code: '31201606',
		label: 'Calafateos',
	},
	{
		code: '31201607',
		label: 'Aglomerante epoxy',

		palabrasSimilares: 'Resina epoxídica',
	},
	{
		code: '31201608',
		label: 'Adhesivos de espuma',
	},
	{
		code: '31201609',
		label: 'Adhesivos de termo impregnación',
	},
	{
		code: '31201610',
		label: 'Pegamentos',

		palabrasSimilares: 'Lápiz adhesivo',
	},
	{
		code: '31201611',
		label: 'Adhesivos de lámina',
	},
	{
		code: '31201612',
		label: 'Selladores de rosca',
	},
	{
		code: '31201613',
		label: 'Adhesivo reusable',
	},
	{
		code: '31201614',
		label: 'Lacre',

		palabrasSimilares: 'Pasta laca',
	},
	{
		code: '31201615',
		label: 'Activadores del adhesivo',
	},
	{
		code: '31201616',
		label: 'Adhesivos líquidos',

		palabrasSimilares: 'Pegamento líquido',
	},
	{
		code: '31201617',
		label: 'Cementos disolventes',
	},
	{
		code: '31201618',
		label: 'Compuesto de unión de drywall',
	},
	{
		code: '31201619',
		label: 'Adhesivo instantáneo',
	},
	{
		code: '31201620',
		label: 'Adhesivo reactivo',
	},
	{
		code: '31201621',
		label: 'Adhesivo en aerosol',
	},
	{
		code: '31201622',
		label: 'Adhesivo multi – propósito',
	},
	{
		code: '31201623',
		label: 'Adhesivo de contacto',
	},
	{
		code: '31201624',
		label: 'Adhesivo de montura',
	},
	{
		code: '31201625',
		label: 'Base de masilla',
	},
	{
		code: '31201626',
		label: 'Adhesivo sensible a la presión psa',
	},
	{
		code: '31201627',
		label: 'Adhesivo anaeróbico',
	},
	{
		code: '31201628',
		label: 'Sellante de impregnación',
	},
	{
		code: '31201629',
		label: 'Encapsulación de silicona',
	},
	{
		code: '31201630',
		label: 'Sellante de espuma',
	},
	{
		code: '31201631',
		label: 'Compuesto sellante',
	},
	{
		code: '31201632',
		label: 'Adhesivo de silicona',
	},
	{
		code: '31201633',
		label: 'Adhesivo de fluorocarbono',
	},
	{
		code: '31201634',
		label: 'Adhesivo acrílico anaeróbico',
	},
	{
		code: '31201635',
		label: 'Adhesivo de poliuretano',
	},
	{
		code: '31201636',
		label: 'Adhesivo de cianoacrilato',
	},
	{
		code: '31201637',
		label: 'Material de comunicación tipo espuma para detener el agua',

		palabrasSimilares: 'Espuma contra fugas de agua',
	},
	{
		code: '31211500',
		label: 'Pinturas y tapa poros',
	},
	{
		code: '31211501',
		label: 'Pinturas de esmalte',
	},
	{
		code: '31211502',
		label: 'Pinturas de agua',
	},
	{
		code: '31211503',
		label: 'Pinturas basadas en pigmentos',
	},
	{
		code: '31211504',
		label: 'Pinturas de revestimiento',
	},
	{
		code: '31211505',
		label: 'Pinturas de aceite',
	},
	{
		code: '31211506',
		label: 'Pinturas de látex',
	},
	{
		code: '31211507',
		label: 'Pinturas en aerosol',
	},
	{
		code: '31211508',
		label: 'Pinturas acrílicas',
	},
	{
		code: '31211509',
		label: 'Bases para esmalte',
	},
	{
		code: '31211510',
		label: 'Bases de poliuretano',
	},
	{
		code: '31211511',
		label: 'Bases de uretano',
	},
	{
		code: '31211512',
		label: 'Bases de látex',
	},
	{
		code: '31211513',
		label: 'Pintura de marcado invertido',
	},
	{
		code: '31211514',
		label: 'Compuesto de galvanización en frío',
	},
	{
		code: '31211515',
		label: 'Pintura basada en disolvente',

		palabrasSimilares: 'Pinturas de aceite',
	},
	{
		code: '31211516',
		label: 'Pintura de terminado al horno',
	},
	{
		code: '31211517',
		label: 'Pintura de terminado patrón',

		palabrasSimilares: 'Pintura de acabados y patrones',
	},
	{
		code: '31211518',
		label: 'Pintura antióxido',

		palabrasSimilares: 'Pintura anticorrosión',
	},
	{
		code: '31211519',
		label: 'Pintura de aluminio',
	},
	{
		code: '31211520',
		label: 'Pintura anti – incrustante',

		palabrasSimilares: 'Pintura anti incrustante',
	},
	{
		code: '31211521',
		label: 'Pintura premezclada',
	},
	{
		code: '31211522',
		label: 'Pintura de trabajo pesado',

		palabrasSimilares: 'Pintura revestimiento',
	},
	{
		code: '31211600',
		label: 'Aditivos para pinturas',
	},
	{
		code: '31211601',
		label: 'Lechada de cal con cola para blanquear paredes',

		palabrasSimilares: 'Revestimiento de cal',
	},
	{
		code: '31211602',
		label: 'Lechada de cal con cola para blanquear paredes',

		palabrasSimilares: 'Materiales y acabados texturizados',
	},
	{
		code: '31211603',
		label: 'Secantes de pintura',

		palabrasSimilares: 'Secador de pintura',
	},
	{
		code: '31211604',
		label: 'Diluyentes para pinturas',
	},
	{
		code: '31211605',
		label: 'Agentes antideslizantes',
	},
	{
		code: '31211606',
		label: 'Agentes niveladores',

		palabrasSimilares: 'Aditivos para aplanar',
	},
	{
		code: '31211607',
		label: 'Inhibidor ultravioleta',

		palabrasSimilares: 'Aditivo anti radiación ultravioleta, Aditivo anti-UV',
	},
	{
		code: '31211700',
		label: 'Acabados en general',
	},
	{
		code: '31211701',
		label: 'Vitrificados',

		palabrasSimilares: 'Esmalte',
	},
	{
		code: '31211702',
		label: 'Lustres',

		palabrasSimilares: 'Aditivos abrillantadores',
	},
	{
		code: '31211703',
		label: 'Lacas',
	},
	{
		code: '31211704',
		label: 'Sellantes',

		palabrasSimilares: 'Selladores',
	},
	{
		code: '31211705',
		label: 'Barniz de laca',
	},
	{
		code: '31211706',
		label: 'Tinturas',

		palabrasSimilares: 'Pinturas para teñir, Tintes colorantes',
	},
	{
		code: '31211707',
		label: 'Barnices',

		palabrasSimilares: 'Barniz',
	},
	{
		code: '31211708',
		label: 'Recubrimiento de polvo',
	},
	{
		code: '31211709',
		label: 'Tinturas de terminación',

		palabrasSimilares: 'Colorantes para acabado',
	},
	{
		code: '31211800',
		label: 'Disolventes y diluyentes para pinturas',
	},
	{
		code: '31211801',
		label: 'Removedores de pintura o barniz',
	},
	{
		code: '31211802',
		label: 'Compuestos para arrancar pintura o barniz',
	},
	{
		code: '31211803',
		label: 'Diluyentes para pinturas y barnices',

		palabrasSimilares: 'Thinner',
	},
	{
		code: '31211900',
		label: 'Aplicadores de pintura y accesorios para pintar',
	},
	{
		code: '31211901',
		label: 'Paños para herramientas',
	},
	{
		code: '31211902',
		label: 'Herramientas para bordes',
	},
	{
		code: '31211903',
		label: 'Equipo para protección',
	},
	{
		code: '31211904',
		label: 'Brochas',
	},
	{
		code: '31211905',
		label: 'Mezcladores de pintura',
	},
	{
		code: '31211906',
		label: 'Rodillos de pintar',
	},
	{
		code: '31211908',
		label: 'Pistolas de pintar',
	},
	{
		code: '31211909',
		label: 'Bandejas de pintura',
	},
	{
		code: '31211910',
		label: 'Guantes para pintar',
	},
	{
		code: '31211912',
		label: 'Varillas telescópicas',

		palabrasSimilares: 'Extensión telescópica para rodillo',
	},
	{
		code: '31211913',
		label: 'Boquillas de pintura',

		palabrasSimilares: 'Caps para aerosol, Válvulas para aerosol',
	},
	{
		code: '31211914',
		label: 'Cepillos de aire',

		palabrasSimilares: 'Aerógrafo',
	},
	{
		code: '31211915',
		label: 'Coladores de pintura',
	},
	{
		code: '31211916',
		label: 'Forradores de bandeja de pintura',
	},
	{
		code: '31211917',
		label: 'Cubiertas para rodillos de pintura',
	},
	{
		code: '31211918',
		label: 'Accesorio o máscara de pintura',
	},
	{
		code: '31221600',
		label: 'Productos para curtidos',
	},
	{
		code: '31221601',
		label: 'Extractos inorgánicos para curtiembre',

		palabrasSimilares: 'Extractos inorgánicos para curtir piel',
	},
	{
		code: '31221602',
		label: 'Extractos orgánicos de origen animal para curtiembre',

		palabrasSimilares: 'Extractos orgánicos de origen animal para curtir piel',
	},
	{
		code: '31221603',
		label: 'Extractos orgánicos de origen vegetal para curtiembre',

		palabrasSimilares: 'Extractos orgánicos de origen vegetal para curtir piel',
	},
	{
		code: '31231100',
		label: 'Material en barra labrada',
	},
	{
		code: '31231101',
		label: 'Aluminio en barra labrada',
	},
	{
		code: '31231102',
		label: 'Berilio en barra labrada',
	},
	{
		code: '31231103',
		label: 'Latón en barra labrada',
	},
	{
		code: '31231104',
		label: 'Bronce en barra labrada',
	},
	{
		code: '31231105',
		label: 'Cobre en barra labrada',
	},
	{
		code: '31231106',
		label: 'Hierro en barra labrada',
	},
	{
		code: '31231107',
		label: 'Plomo en barra labrada',
	},
	{
		code: '31231108',
		label: 'Magnesio en barra labrada',
	},
	{
		code: '31231109',
		label: 'Metal precioso en barra labrada',
	},
	{
		code: '31231110',
		label: 'Acero inoxidable en barra labrada',
	},
	{
		code: '31231111',
		label: 'Estaño en barra labrada',
	},
	{
		code: '31231112',
		label: 'Titanio en barra labrada',
	},
	{
		code: '31231113',
		label: 'Cinc en barra labrada',
	},
	{
		code: '31231114',
		label: 'Aleación no ferrosa en barra labrada',
	},
	{
		code: '31231115',
		label: 'Aleación ferrosa en barra labrada',
	},
	{
		code: '31231116',
		label: 'Acero en barra labrada',
	},
	{
		code: '31231117',
		label: 'Compuestos en barra labrada',
	},
	{
		code: '31231118',
		label: 'Aleación de níquel en barra labrada',
	},
	{
		code: '31231119',
		label: 'Material no metálico en barra labrada',
	},
	{
		code: '31231120',
		label: 'Acero plata en barra labrada',
	},
	{
		code: '31231200',
		label: 'Material en placas labrado',
	},
	{
		code: '31231201',
		label: 'Aluminio en placa labrada',
	},
	{
		code: '31231202',
		label: 'Berilio en placa labrada',
	},
	{
		code: '31231203',
		label: 'Latón en placa labrada',
	},
	{
		code: '31231204',
		label: 'Bronce en placa labrada',
	},
	{
		code: '31231205',
		label: 'Cobre en placa labrada',
	},
	{
		code: '31231206',
		label: 'Hierro en placa labrada',
	},
	{
		code: '31231207',
		label: 'Plomo en placa labrada',
	},
	{
		code: '31231208',
		label: 'Magnesio en placa labrada',
	},
	{
		code: '31231209',
		label: 'Metal precioso en placa labrada',
	},
	{
		code: '31231210',
		label: 'Acero inoxidable en placa labrada',
	},
	{
		code: '31231211',
		label: 'Estaño en placa labrada',
	},
	{
		code: '31231212',
		label: 'Titanio en placa labrada',
	},
	{
		code: '31231213',
		label: 'Cinc en placa labrada',
	},
	{
		code: '31231214',
		label: 'Aleación no ferrosa en placa labrada',
	},
	{
		code: '31231215',
		label: 'Aleación ferrosa en placa labrada',
	},
	{
		code: '31231216',
		label: 'Acero en placa labrada',
	},
	{
		code: '31231217',
		label: 'Compuestos en placa labrada',
	},
	{
		code: '31231218',
		label: 'Aleación de níquel en placa labrada',
	},
	{
		code: '31231219',
		label: 'Material no metálico en placa labrada',
	},
	{
		code: '31231400',
		label: 'Suplementos',
	},
	{
		code: '31231401',
		label: 'Platina de latón',
	},
	{
		code: '31231402',
		label: 'Platina de acero',
	},
	{
		code: '31231403',
		label: 'Platina de acero inoxidable',
	},
	{
		code: '31231404',
		label: 'Platina de aluminio',
	},
	{
		code: '31231405',
		label: 'Platina de cobre',
	},
	{
		code: '31241500',
		label: 'Lentes y prismas',
	},
	{
		code: '31241501',
		label: 'Lentes',
	},
	{
		code: '31241502',
		label: 'Prismas',
	},
	{
		code: '31241600',
		label: 'Cristales ópticos',
	},
	{
		code: '31241601',
		label: 'Cristales de filtro',

		palabrasSimilares: 'Cristales bloqueadores',
	},
	{
		code: '31241602',
		label: 'Discos de cristal',
	},
	{
		code: '31241603',
		label: 'Vidrio moldeado',
	},
	{
		code: '31241604',
		label: 'Cristales de prismas',
	},
	{
		code: '31241605',
		label: 'Cristales de silicio',
	},
	{
		code: '31241606',
		label: 'Cristales de germanio',
	},
	{
		code: '31241607',
		label: 'Barras redondas',
	},
	{
		code: '31241608',
		label: 'Barras cuadradas',
	},
	{
		code: '31241609',
		label: 'Cristales indicadores de muestra',
	},
	{
		code: '31241610',
		label: 'Blancos de material óptico infrarrojo',

		palabrasSimilares: 'Cristal liso',
	},
	{
		code: '31241700',
		label: 'Espejos',
	},
	{
		code: '31241701',
		label: 'Espejos torneados con herramienta de diamante',
	},
	{
		code: '31241702',
		label: 'Espejos metálicos',
	},
	{
		code: '31241703',
		label: 'Espejos parabólicos',
	},
	{
		code: '31241704',
		label: 'Espejos sin revestimiento',
	},
	{
		code: '31241705',
		label: 'Espejos para láser',
	},
	{
		code: '31241706',
		label: 'Espejos planos',
	},
	{
		code: '31241800',
		label: 'Filtros ópticos',
	},
	{
		code: '31241801',
		label: 'Filtros ópticos de banda ancha',
	},
	{
		code: '31241802',
		label: 'Filtros de gradiente',
	},
	{
		code: '31241803',
		label: 'Filtros infrarrojos',
	},
	{
		code: '31241804',
		label: 'Filtros de láser',
	},
	{
		code: '31241805',
		label: 'Filtros de banda estrecha',
	},
	{
		code: '31241806',
		label: 'Filtros de película de plástico',
	},
	{
		code: '31241807',
		label: 'Filtros visuales',
	},
	{
		code: '31241808',
		label: 'Filtros dicróicos',
	},
	{
		code: '31241809',
		label: 'Filtros de lyot',
	},
	{
		code: '31241810',
		label: 'Filtros de gelatina',
	},
	{
		code: '31241811',
		label: 'Filtros de color',
	},
	{
		code: '31241812',
		label: 'Filtros polarizantes',
	},
	{
		code: '31241813',
		label: 'Filtros de reflexión',
	},
	{
		code: '31241814',
		label: 'Filtros de succión',
	},
	{
		code: '31241815',
		label: 'Filtros de interferencia',
	},
	{
		code: '31241900',
		label: 'Bóvedas ópticas',
	},
	{
		code: '31241901',
		label: 'Bóvedas de especialidad',
	},
	{
		code: '31241902',
		label: 'Bóvedas torneadas con diamante',
	},
	{
		code: '31241903',
		label: 'Bóvedas metálicas',
	},
	{
		code: '31241904',
		label: 'Bóvedas de vidrio moldeado',
	},
	{
		code: '31241905',
		label: 'Bóvedas moldeadas del policarbonato',
	},
	{
		code: '31241906',
		label: 'Bóvedas reproducidas',
	},
	{
		code: '31241907',
		label: 'Bóvedas formadas',
	},
	{
		code: '31241908',
		label: 'Bóvedas frangibles',
	},
	{
		code: '31242000',
		label: 'Ventanas de láser y lentes',
	},
	{
		code: '31242001',
		label: 'Ventana externa de láser o lentes',
	},
	{
		code: '31242002',
		label: 'Ventanas de lentes infrarrojos o de láser',
	},
	{
		code: '31242003',
		label: 'Ventana visual de láser o lentes',
	},
	{
		code: '31242100',
		label: 'Accesorios de dispositivos ópticos',
	},
	{
		code: '31242101',
		label: 'Monturas ópticas',
	},
	{
		code: '31242103',
		label: 'Aberturas o ranuras ópticas',
	},
	{
		code: '31242104',
		label: 'Soportes o carriles ópticos',
	},
	{
		code: '31242105',
		label: 'Identificadores de fibra óptica',
	},
	{
		code: '31242106',
		label: 'Recubrimientos ópticos',
	},
	{
		code: '31242107',
		label: 'Tablero de pruebas ópticas',
	},
	{
		code: '31242200',
		label: 'Componentes ópticos variados',
	},
	{
		code: '31242201',
		label: 'Divisores del haz óptico',
	},
	{
		code: '31242202',
		label: 'Polarizadores',
	},
	{
		code: '31242203',
		label: 'Despolarizadores',
	},
	{
		code: '31242204',
		label: 'Difusores ópticos',
	},
	{
		code: '31242205',
		label: 'Retardadores ópticos',
	},
	{
		code: '31242206',
		label: 'Vidrios ópticamente planos',
	},
	{
		code: '31242207',
		label: 'Montajes ópticos experimentales',
	},
	{
		code: '31242208',
		label: 'Picadores ópticos',
	},
	{
		code: '31251500',
		label: 'Activadores',
	},
	{
		code: '31251501',
		label: 'Activadores eléctricos',
	},
	{
		code: '31251502',
		label: 'Activadores electrónicos',
	},
	{
		code: '31251503',
		label: 'Activadores hidráulicos',
	},
	{
		code: '31251504',
		label: 'Activadores neumáticos',
	},
	{
		code: '31251505',
		label: 'Servoválvula',
	},
	{
		code: '31251506',
		label: 'Activadores por engranajes',
	},
	{
		code: '31251507',
		label: 'Activadores giratorios',
	},
	{
		code: '31251508',
		label: 'Activadores fotoeléctricos',
	},
	{
		code: '31251509',
		label: 'Activadores electromagnéticos',
	},
	{
		code: '31251510',
		label: 'Solenoides',
	},
	{
		code: '31251511',
		label: 'Activadores lineales',
	},
	{
		code: '31251512',
		label: 'Activadores automotrices hvac',
	},
	{
		code: '31251513',
		label: 'Activadores  de compuerta',
	},
	{
		code: '31251514',
		label: 'Activador electromecánico de aeronaves',
	},
	{
		code: '31251600',
		label: 'Componentes de robot',
	},
	{
		code: '31251601',
		label: 'Efectores finales robóticos',
	},
	{
		code: '31261500',
		label: 'Cubiertas y cajas',
	},
	{
		code: '31261501',
		label: 'Cubiertas y carcasas de plástico',
	},
	{
		code: '31261502',
		label: 'Cubiertas y carcasas de metal',
	},
	{
		code: '31261503',
		label: 'Cubiertas y carcasas de acero',
	},
	{
		code: '31261504',
		label: 'Carcasas de cajas de cambios',
	},
	{
		code: '31261505',
		label: 'Carcasas de embrague',
	},
	{
		code: '31261600',
		label: 'Cascos y envolturas',
	},
	{
		code: '31261601',
		label: 'Envoltorios o recubrimientos de plástico',

		palabrasSimilares: 'Armazón o cubierta de plástico',
	},
	{
		code: '31261602',
		label: 'Envoltorios o recubrimientos de metal',

		palabrasSimilares: 'Armazón o cubierta metálica',
	},
	{
		code: '31261603',
		label: 'Envoltorios o recubrimientos de acero',

		palabrasSimilares: 'Armazón o cubierta de acero',
	},
	{
		code: '31261700',
		label: 'Cerramientos o alojamientos insonorizantes',
	},
	{
		code: '31261701',
		label: 'Carcasa  insonorizante de máquina',
	},
	{
		code: '31261702',
		label: 'Cerramiento insonorizante de conjunto de generador montado',
	},
	{
		code: '31261703',
		label: 'Carcasa insonorizante de bomba',
	},
	{
		code: '31261704',
		label: 'Carcasa insonorizante de admisión de aire',
	},
	{
		code: '31271600',
		label: 'Piezas hechas con torno de roscar',
	},
	{
		code: '31271601',
		label: 'Piezas hechas en torno de roscar de metal',
	},
	{
		code: '31271602',
		label: 'Piezas hechas en torno de roscar no metales',
	},
	{
		code: '31281500',
		label: 'Componentes estampados',

		palabrasSimilares: 'Elementos para estampados',
	},
	{
		code: '31281502',
		label: 'Componentes de aluminio estampados',
	},
	{
		code: '31281503',
		label: 'Componentes de aleaciones ferrosas estampados',
	},
	{
		code: '31281504',
		label: 'Componentes de hierro estampados',
	},
	{
		code: '31281505',
		label: 'Componentes de aleaciones no ferrosas estampados',
	},
	{
		code: '31281506',
		label: 'Componentes de acero inoxidable estampados',
	},
	{
		code: '31281507',
		label: 'Componentes de acero al carbono estampados',
	},
	{
		code: '31281508',
		label: 'Componentes de magnesio estampados',
	},
	{
		code: '31281509',
		label: 'Componentes de zinc estampados',
	},
	{
		code: '31281510',
		label: 'Componentes de estaño estampados',
	},
	{
		code: '31281511',
		label: 'Componentes de titanio estampados',
	},
	{
		code: '31281512',
		label: 'Componentes de berilio estampados',
	},
	{
		code: '31281513',
		label: 'Componentes de metal precioso estampados',
	},
	{
		code: '31281514',
		label: 'Componentes de cobre estampados',
	},
	{
		code: '31281515',
		label: 'Componentes de plomo estampados',
	},
	{
		code: '31281516',
		label: 'Componentes de latón estampados',
	},
	{
		code: '31281517',
		label: 'Componentes de bronce estampados',
	},
	{
		code: '31281518',
		label: 'Componentes compuestos estampados',
	},
	{
		code: '31281519',
		label: 'Componentes de aleación de níquel estampados',
	},
	{
		code: '31281520',
		label: 'Componentes no metálicos estampados',
	},
	{
		code: '31281521',
		label: 'Estampados recubiertos',
	},
	{
		code: '31281522',
		label: 'Componente de berilio cobre estampado',

		palabrasSimilares: 'Elemento para estampado de cobre berilio',
	},
	{
		code: '31281523',
		label: 'Componente estampado de cobre latón plateado',

		palabrasSimilares: 'Elemento para estampado de cobre latón plateado',
	},
	{
		code: '31281524',
		label: 'Componente estampado de aluminio sae serie 1000',

		palabrasSimilares: 'Elemento para estampado de aluminio sae serie 1000',
	},
	{
		code: '31281525',
		label: 'Componente estampado de aluminio sae serie 3000',

		palabrasSimilares: 'Elemento para estampado de aluminio sae serie 3000',
	},
	{
		code: '31281526',
		label: 'Componente estampado de aluminio sae serie 5000',

		palabrasSimilares: 'Elemento para estampado de aluminio sae serie 5000',
	},
	{
		code: '31281527',
		label: 'Componente estampado de aluminio sae serie 6000',

		palabrasSimilares: 'Elemento para estampado de aluminio sae serie 6000',
	},
	{
		code: '31281528',
		label: 'Componente estampado de inconel',

		palabrasSimilares: 'Elemento para estampado de inconel',
	},
	{
		code: '31281529',
		label:
			'Componente estampado de acero al carbón sae serie 1000 enrollado en frío recubierto de estaño',
	},
	{
		code: '31281530',
		label:
			'Componente estampado de acero al carbón sae serie 1000 enrollado en caliente',
	},
	{
		code: '31281531',
		label:
			'Componente estampado de aleación de aleación de acero enrollada en caliente',
	},
	{
		code: '31281532',
		label:
			'Componente estampado de acero al carbón sae serie 1000 enrollado en frío',
	},
	{
		code: '31281533',
		label:
			'Componente estampado de acero inoxidable sae serie 200 enrollado en frío',
	},
	{
		code: '31281534',
		label:
			'Componente estampado de acero inoxidable sae serie 300 enrollado en frío',
	},
	{
		code: '31281535',
		label:
			'Componente estampado de acero inoxidable sae serie 400 enrollado en frío',
	},
	{
		code: '31281536',
		label:
			'Componente estampado de aleación de aleación de acero enrollada en frío',
	},
	{
		code: '31281537',
		label:
			'Componente estampado de acero al carbón sae serie 1000 enrollado en frío galvanizado en caliente por inmersión',
	},
	{
		code: '31281538',
		label:
			'Componente estampado de acero al carbón sae serie 1000 enrollado en frío electro  galvanizado',
	},
	{
		code: '31281700',
		label: 'Componentes soldados',
	},
	{
		code: '31281701',
		label: 'Componentes de metal soldado',

		palabrasSimilares: 'Elementos de metal soldado',
	},
	{
		code: '31281800',
		label: 'Componentes perforados',
	},
	{
		code: '31281801',
		label: 'Componentes de aluminio perforados',
	},
	{
		code: '31281802',
		label: 'Componentes de aleación ferrosa perforados',
	},
	{
		code: '31281803',
		label: 'Componentes de hierro perforados',
	},
	{
		code: '31281804',
		label: 'Componentes de aleaciones no ferrosas perforados',
	},
	{
		code: '31281805',
		label: 'Componentes de acero inoxidable perforados',
	},
	{
		code: '31281806',
		label: 'Componentes de acero al carbono perforados',
	},
	{
		code: '31281807',
		label: 'Componentes compuestos perforados',

		palabrasSimilares: 'Compuestos perforados',
	},
	{
		code: '31281808',
		label: 'Componentes de aleación de níquel perforados',

		palabrasSimilares: 'Aleación de níquel perforado',
	},
	{
		code: '31281809',
		label: 'Componentes no metálicos perforados',
	},
	{
		code: '31281810',
		label: 'Componentes de titanio perforados',
	},
	{
		code: '31281811',
		label: 'Componentes de berilio perforados',
	},
	{
		code: '31281812',
		label: 'Componentes de metal precioso perforados',
	},
	{
		code: '31281813',
		label: 'Componentes de cobre perforados',
	},
	{
		code: '31281814',
		label: 'Componentes de plomo perforados',
	},
	{
		code: '31281815',
		label: 'Componentes de latón perforados',
	},
	{
		code: '31281816',
		label: 'Componentes de bronce perforados',
	},
	{
		code: '31281817',
		label: 'Componentes de magnesio perforados',
	},
	{
		code: '31281818',
		label: 'Componentes de zinc perforados',
	},
	{
		code: '31281819',
		label: 'Componentes de estaño perforados',
	},
	{
		code: '31281900',
		label: 'Componentes formados por estiraje',
	},
	{
		code: '31281901',
		label: 'Componentes de aluminio formados por estiramiento',

		palabrasSimilares: 'Alambre de aluminio',
	},
	{
		code: '31281902',
		label: 'Componentes de berilio formados por estiramiento',

		palabrasSimilares: 'Alambre de berilio',
	},
	{
		code: '31281903',
		label: 'Componentes de latón formados por estiramiento',

		palabrasSimilares: 'Alambre de latón',
	},
	{
		code: '31281904',
		label: 'Componentes de bronce formados por estiramiento',

		palabrasSimilares: 'Alambre de bronce',
	},
	{
		code: '31281905',
		label: 'Componentes compuestos formados por estiramiento',
	},
	{
		code: '31281906',
		label: 'Componentes de cobre formados por estiramiento',

		palabrasSimilares: 'Alambre de cobre',
	},
	{
		code: '31281907',
		label: 'Componentes de aleación ferrosa formados por estiramiento',
	},
	{
		code: '31281908',
		label: 'Componentes de hierro formados por estiramiento',
	},
	{
		code: '31281909',
		label: 'Componentes de plomo formados por estiramiento',
	},
	{
		code: '31281910',
		label: 'Componentes de magnesio formados por estiramiento',
	},
	{
		code: '31281911',
		label: 'Componentes de aleación de níquel formados por estiramiento',
	},
	{
		code: '31281912',
		label: 'Componentes de aleación no ferrosa formados por estiramiento',
	},
	{
		code: '31281913',
		label: 'Componentes no metálicos formados por estiramiento',
	},
	{
		code: '31281914',
		label: 'Componentes de metal precioso formados por estiramiento',
	},
	{
		code: '31281915',
		label: 'Componentes de acero inoxidable formados por estiramiento',
	},
	{
		code: '31281916',
		label: 'Componentes de acero formados por estiramiento',
	},
	{
		code: '31281917',
		label: 'Componentes de estaño formados por estiramiento',
	},
	{
		code: '31281918',
		label: 'Componentes de titanio formados por estiramiento',
	},
	{
		code: '31281919',
		label: 'Componentes de zinc formados por estiramiento',
	},
	{
		code: '31282000',
		label: 'Componentes hidro formados',
	},
	{
		code: '31282001',
		label: 'Componentes de aluminio hidroformados',
	},
	{
		code: '31282002',
		label: 'Componentes de berilio hidroformados',
	},
	{
		code: '31282003',
		label: 'Componentes de latón hidroformados',
	},
	{
		code: '31282004',
		label: 'Componentes de bronce hidroformados',
	},
	{
		code: '31282005',
		label: 'Componentes compuestos hidroformados',
	},
	{
		code: '31282006',
		label: 'Componentes de cobre hidroformados',
	},
	{
		code: '31282007',
		label: 'Componentes de aleación ferrosa hidroformados',
	},
	{
		code: '31282008',
		label: 'Componentes de hierro hidroformados',
	},
	{
		code: '31282009',
		label: 'Componentes de plomo hidroformados',
	},
	{
		code: '31282010',
		label: 'Componentes de magnesio hidroformados',
	},
	{
		code: '31282011',
		label: 'Componentes de aleación de níquel hidroformados',
	},
	{
		code: '31282012',
		label: 'Componentes de aleación no ferrosa hidroformados',
	},
	{
		code: '31282013',
		label: 'Componentes no metálicos hidroformados',
	},
	{
		code: '31282014',
		label: 'Componentes de metal precioso hidroformados',
	},
	{
		code: '31282015',
		label: 'Componentes de acero inoxidable hidroformados',
	},
	{
		code: '31282016',
		label: 'Componentes de acero hidroformados',
	},
	{
		code: '31282017',
		label: 'Componentes de estaño hidroformados',
	},
	{
		code: '31282018',
		label: 'Componentes de titanio hidroformados',
	},
	{
		code: '31282019',
		label: 'Componentes de zinc hidroformados',
	},
	{
		code: '31282100',
		label: 'Componentes formados con rotación',
	},
	{
		code: '31282101',
		label: 'Componentes de aluminio formados en torno',
	},
	{
		code: '31282102',
		label: 'Componentes de berilio formados en torno',
	},
	{
		code: '31282103',
		label: 'Componentes de latón formados en torno',
	},
	{
		code: '31282104',
		label: 'Componentes de bronce formados en torno',
	},
	{
		code: '31282105',
		label: 'Componentes compuestos formados en torno',
	},
	{
		code: '31282106',
		label: 'Componentes de cobre formados en torno',
	},
	{
		code: '31282107',
		label: 'Componentes de aleación ferrosa formados en torno',
	},
	{
		code: '31282108',
		label: 'Componentes de hierro formados en torno',
	},
	{
		code: '31282109',
		label: 'Componentes de plomo formados en torno',
	},
	{
		code: '31282110',
		label: 'Componentes de magnesio formados en torno',
	},
	{
		code: '31282111',
		label: 'Componentes de aleación de níquel formados en torno',
	},
	{
		code: '31282112',
		label: 'Componentes de aleación no ferrosa formados en torno',
	},
	{
		code: '31282113',
		label: 'Componentes no metálicos formados en torno',
	},
	{
		code: '31282114',
		label: 'Componentes de metal precioso formados en torno',
	},
	{
		code: '31282115',
		label: 'Componentes de acero inoxidable formados en torno',
	},
	{
		code: '31282116',
		label: 'Componentes de acero formados en torno',
	},
	{
		code: '31282117',
		label: 'Componentes de estaño formados en torno',
	},
	{
		code: '31282118',
		label: 'Componentes de titanio formados en torno',
	},
	{
		code: '31282119',
		label: 'Componentes de zinc formados en torno',
	},
	{
		code: '31282200',
		label: 'Componentes formados con rodillo',
	},
	{
		code: '31282201',
		label: 'Componentes de aluminio formados enrollados',
	},
	{
		code: '31282202',
		label: 'Componentes de berilio formados enrollados',
	},
	{
		code: '31282203',
		label: 'Componentes de latón formados enrollados',
	},
	{
		code: '31282204',
		label: 'Componentes de bronce formados enrollados',
	},
	{
		code: '31282205',
		label: 'Componentes compuestos formados enrollados',
	},
	{
		code: '31282206',
		label: 'Componentes de cobre formados enrollados',
	},
	{
		code: '31282207',
		label: 'Componentes de aleación ferrosa formados enrollados',
	},
	{
		code: '31282208',
		label: 'Componentes de hierro formados enrollados',
	},
	{
		code: '31282209',
		label: 'Componentes de plomo formados enrollados',
	},
	{
		code: '31282210',
		label: 'Componentes de magnesio formados enrollados',
	},
	{
		code: '31282211',
		label: 'Componentes de aleación de níquel formados enrollados',
	},
	{
		code: '31282212',
		label: 'Componentes de aleación no ferrosa formados enrollados',
	},
	{
		code: '31282213',
		label: 'Componentes no metálicos formados enrollados',
	},
	{
		code: '31282214',
		label: 'Componentes de metal precioso formados enrollados',
	},
	{
		code: '31282215',
		label: 'Componentes de acero inoxidable formados enrollados',
	},
	{
		code: '31282216',
		label: 'Componentes de acero formados enrollados',
	},
	{
		code: '31282217',
		label: 'Componentes de estaño formados enrollados',
	},
	{
		code: '31282218',
		label: 'Componentes de titanio formados enrollados',
	},
	{
		code: '31282219',
		label: 'Componentes de zinc formados enrollados',
	},
	{
		code: '31282300',
		label: 'Componentes formados por estiramiento por presión',
	},
	{
		code: '31282301',
		label: 'Componentes de aluminio formados por estiramiento por presión',
	},
	{
		code: '31282302',
		label: 'Componentes de berilio formados por estiramiento por presión',
	},
	{
		code: '31282303',
		label: 'Componentes de latón formados por estiramiento por presión',
	},
	{
		code: '31282304',
		label: 'Componentes de bronce formados por estiramiento por presión',
	},
	{
		code: '31282305',
		label: 'Componentes compuestos formados por estiramiento por presión',
	},
	{
		code: '31282306',
		label: 'Componentes de cobre formados por estiramiento por presión',
	},
	{
		code: '31282307',
		label:
			'Componentes de aleación ferrosa formados por estiramiento por presión',
	},
	{
		code: '31282308',
		label: 'Componentes de hierro formados por estiramiento por presión',
	},
	{
		code: '31282309',
		label: 'Componentes de plomo formados por estiramiento por presión',
	},
	{
		code: '31282310',
		label: 'Componentes de magnesio formados por estiramiento por presión',
	},
	{
		code: '31282311',
		label:
			'Componentes de aleación de níquel formados por estiramiento por presión',
	},
	{
		code: '31282312',
		label:
			'Componentes de aleación no ferrosa formados por estiramiento por presión',
	},
	{
		code: '31282313',
		label: 'Componentes no metálicos formados por estiramiento por presión',
	},
	{
		code: '31282314',
		label:
			'Componentes de metal precioso formados por estiramiento por presión',
	},
	{
		code: '31282315',
		label:
			'Componentes de acero inoxidable formados por estiramiento por presión',
	},
	{
		code: '31282316',
		label: 'Componentes de acero formados por estiramiento por presión',
	},
	{
		code: '31282317',
		label: 'Componentes de estaño formados por estiramiento por presión',
	},
	{
		code: '31282318',
		label: 'Componentes de titanio formados por estiramiento por presión',
	},
	{
		code: '31282319',
		label: 'Componentes de zinc formados por estiramiento por presión',
	},
	{
		code: '31282400',
		label: 'Componentes formados de explosivos',
	},
	{
		code: '31282401',
		label: 'Componentes de aluminio formados con explosivos',
	},
	{
		code: '31282402',
		label: 'Componentes de berilio formados con explosivos',
	},
	{
		code: '31282403',
		label: 'Componentes de latón formados con explosivos',
	},
	{
		code: '31282404',
		label: 'Componentes de bronce formados con explosivos',
	},
	{
		code: '31282405',
		label: 'Componentes compuestos formados con explosivos',
	},
	{
		code: '31282406',
		label: 'Componentes de cobre formados con explosivos',
	},
	{
		code: '31282407',
		label: 'Componentes de aleación ferrosa formados con explosivos',
	},
	{
		code: '31282408',
		label: 'Componentes de hierro formados con explosivos',
	},
	{
		code: '31282409',
		label: 'Componentes de plomo formados con explosivos',
	},
	{
		code: '31282410',
		label: 'Componentes de magnesio formados con explosivos',
	},
	{
		code: '31282411',
		label: 'Componentes de aleación de níquel formados con explosivos',
	},
	{
		code: '31282412',
		label: 'Componentes de aleación no ferrosa formados con explosivos',
	},
	{
		code: '31282413',
		label: 'Componentes no metálicos formados con explosivos',
	},
	{
		code: '31282414',
		label: 'Componentes de metal precioso formados con explosivos',
	},
	{
		code: '31282415',
		label: 'Componentes de acero inoxidable formados con explosivos',
	},
	{
		code: '31282416',
		label: 'Componentes de acero formados con explosivos',
	},
	{
		code: '31282417',
		label: 'Componentes de estaño formados con explosivos',
	},
	{
		code: '31282418',
		label: 'Componentes de titanio formados con explosivos',
	},
	{
		code: '31282419',
		label: 'Componentes de zinc formados con explosivos',
	},
	{
		code: '31291100',
		label: 'Estiramientos por presión hidrostática labrados',
	},
	{
		code: '31291101',
		label: 'Componentes de aluminio maquinados por extrusión hidrostática',
	},
	{
		code: '31291102',
		label: 'Componentes de berilio maquinados por extrusión hidrostática',
	},
	{
		code: '31291103',
		label: 'Componentes de latón maquinados por extrusión hidrostática',
	},
	{
		code: '31291104',
		label: 'Componentes de bronce maquinados por extrusión hidrostática',
	},
	{
		code: '31291105',
		label: 'Componentes de cobre maquinados por extrusión hidrostática',
	},
	{
		code: '31291106',
		label:
			'Componentes de aleación ferrosa maquinados por extrusión hidrostática',
	},
	{
		code: '31291107',
		label: 'Componentes de plomo maquinados por extrusión hidrostática',
	},
	{
		code: '31291108',
		label: 'Componentes de magnesio maquinados por extrusión hidrostática',
	},
	{
		code: '31291109',
		label:
			'Componentes de aleación no ferrosa maquinados por extrusión hidrostática',
	},
	{
		code: '31291110',
		label: 'Componentes plásticos maquinados por extrusión hidrostática',
	},
	{
		code: '31291111',
		label:
			'Componentes de metal precioso maquinados por extrusión hidrostática',
	},
	{
		code: '31291112',
		label: 'Componentes de caucho maquinados por extrusión hidrostática',
	},
	{
		code: '31291113',
		label:
			'Componentes de acero inoxidable maquinados por extrusión hidrostática',
	},
	{
		code: '31291114',
		label: 'Componentes de acero maquinados por extrusión hidrostática',
	},
	{
		code: '31291115',
		label: 'Componentes de estaño maquinados por extrusión hidrostática',
	},
	{
		code: '31291116',
		label: 'Componentes de titanio maquinados por extrusión hidrostática',
	},
	{
		code: '31291117',
		label: 'Componentes de zinc maquinados por extrusión hidrostática',
	},
	{
		code: '31291118',
		label: 'Componentes compuestos maquinados por extrusión hidrostática',
	},
	{
		code: '31291119',
		label:
			'Componentes de aleación de níquel maquinados por extrusión hidrostática',
	},
	{
		code: '31291120',
		label: 'Componentes no metálicos maquinados por extrusión hidrostática',
	},
	{
		code: '31291200',
		label: 'Estiramientos por presión de impacto labrados',
	},
	{
		code: '31291201',
		label: 'Componentes de aluminio maquinados por extrusión de impacto',
	},
	{
		code: '31291202',
		label: 'Componentes de berilio maquinados por extrusión de impacto',
	},
	{
		code: '31291203',
		label: 'Componentes de latón maquinados por extrusión de impacto',
	},
	{
		code: '31291204',
		label: 'Componentes de bronce maquinados por extrusión de impacto',
	},
	{
		code: '31291205',
		label: 'Componentes de cobre maquinados por extrusión de impacto',
	},
	{
		code: '31291206',
		label:
			'Componentes de aleación ferrosa maquinados por extrusión de impacto',
	},
	{
		code: '31291207',
		label: 'Componentes de plomo maquinados por extrusión de impacto',
	},
	{
		code: '31291208',
		label: 'Componentes de magnesio maquinados por extrusión de impacto',
	},
	{
		code: '31291209',
		label:
			'Componentes de aleación no ferrosa maquinados por extrusión de impacto',
	},
	{
		code: '31291210',
		label: 'Componentes plásticos maquinados por extrusión de impacto',
	},
	{
		code: '31291211',
		label: 'Componentes de metal precioso maquinados por extrusión de impacto',
	},
	{
		code: '31291212',
		label: 'Componentes de caucho maquinados por extrusión de impacto',
	},
	{
		code: '31291213',
		label:
			'Componentes de acero inoxidable maquinados por extrusión de impacto',
	},
	{
		code: '31291214',
		label: 'Componentes de acero maquinados por extrusión de impacto',
	},
	{
		code: '31291215',
		label: 'Componentes de estaño maquinados por extrusión de impacto',
	},
	{
		code: '31291216',
		label: 'Componentes de titanio maquinados por extrusión de impacto',
	},
	{
		code: '31291217',
		label: 'Componentes de zinc maquinados por extrusión de impacto',
	},
	{
		code: '31291218',
		label: 'Componentes compuestos maquinados por extrusión de impacto',
	},
	{
		code: '31291219',
		label:
			'Componentes de aleación de níquel maquinados por extrusión de impacto',
	},
	{
		code: '31291220',
		label: 'Componentes no metálicos maquinados por extrusión de impacto',
	},
	{
		code: '31291300',
		label: 'Estiramientos por presión en frío labrados',
	},
	{
		code: '31291301',
		label: 'Componentes de aluminio maquinados por extrusión en frío',
	},
	{
		code: '31291302',
		label: 'Componentes de berilio maquinados por extrusión en frío',
	},
	{
		code: '31291303',
		label: 'Componentes de latón maquinados por extrusión en frío',
	},
	{
		code: '31291304',
		label: 'Componentes de bronce maquinados por extrusión en frío',
	},
	{
		code: '31291305',
		label: 'Componentes de cobre maquinados por extrusión en frío',
	},
	{
		code: '31291306',
		label: 'Componentes de aleación ferrosa maquinados por extrusión en frío',
	},
	{
		code: '31291307',
		label: 'Componentes de plomo maquinados por extrusión en frío',
	},
	{
		code: '31291308',
		label: 'Componentes de magnesio maquinados por extrusión en frío',
	},
	{
		code: '31291309',
		label:
			'Componentes de aleación no ferrosa maquinados por extrusión en frío',
	},
	{
		code: '31291310',
		label: 'Componentes plásticos maquinados por extrusión en frío',
	},
	{
		code: '31291311',
		label: 'Componentes de metal precioso maquinados por extrusión en frío',
	},
	{
		code: '31291312',
		label: 'Componentes de caucho maquinados por extrusión en frío',
	},
	{
		code: '31291313',
		label: 'Componentes de acero inoxidable maquinados por extrusión en frío',
	},
	{
		code: '31291314',
		label: 'Componentes de acero maquinados por extrusión en frío',
	},
	{
		code: '31291315',
		label: 'Componentes de estaño maquinados por extrusión en frío',
	},
	{
		code: '31291316',
		label: 'Componentes de titanio maquinados por extrusión en frío',
	},
	{
		code: '31291317',
		label: 'Componentes de zinc maquinados por extrusión en frío',
	},
	{
		code: '31291318',
		label: 'Componentes compuestos maquinados por extrusión en frío',
	},
	{
		code: '31291319',
		label: 'Componentes de aleación de níquel maquinados por extrusión en frío',
	},
	{
		code: '31291320',
		label: 'Componentes no metálicos maquinados por extrusión en frío',
	},
	{
		code: '31291400',
		label: 'Estiramientos por presión en caliente labrados',
	},
	{
		code: '31291401',
		label: 'Componentes de aluminio maquinados por extrusión en caliente',
	},
	{
		code: '31291402',
		label: 'Componentes de berilio maquinados por extrusión en caliente',
	},
	{
		code: '31291403',
		label: 'Componentes de latón maquinados por extrusión en caliente',
	},
	{
		code: '31291404',
		label: 'Componentes de bronce maquinados por extrusión en caliente',
	},
	{
		code: '31291405',
		label: 'Componentes de cobre maquinados por extrusión en caliente',
	},
	{
		code: '31291406',
		label:
			'Componentes de aleación ferrosa maquinados por extrusión en caliente',
	},
	{
		code: '31291407',
		label: 'Componentes de plomo maquinados por extrusión en caliente',
	},
	{
		code: '31291408',
		label: 'Componentes de magnesio maquinados por extrusión en caliente',
	},
	{
		code: '31291409',
		label:
			'Componentes de aleación no ferrosa maquinados por extrusión en caliente',
	},
	{
		code: '31291410',
		label: 'Componentes plásticos maquinados por extrusión en caliente',
	},
	{
		code: '31291411',
		label: 'Componentes de metal precioso maquinados por extrusión en caliente',
	},
	{
		code: '31291412',
		label: 'Componentes de caucho maquinados por extrusión en caliente',
	},
	{
		code: '31291413',
		label:
			'Componentes de acero inoxidable maquinados por extrusión en caliente',
	},
	{
		code: '31291414',
		label: 'Componentes de acero maquinados por extrusión en caliente',
	},
	{
		code: '31291415',
		label: 'Componentes de estaño maquinados por extrusión en caliente',
	},
	{
		code: '31291416',
		label: 'Componentes de titanio maquinados por extrusión en caliente',
	},
	{
		code: '31291417',
		label: 'Componentes de zinc maquinados por extrusión en caliente',
	},
	{
		code: '31291418',
		label: 'Componentes compuestos maquinados por extrusión en caliente',
	},
	{
		code: '31291419',
		label:
			'Componentes de aleación de níquel maquinados por extrusión en caliente',
	},
	{
		code: '31291420',
		label: 'Componentes no metálicos maquinados por extrusión en caliente',
	},
	{
		code: '31301100',
		label: 'Forjas labradas con troquel abierto',
	},
	{
		code: '31301101',
		label: 'Forjas de aleación no ferrosa maquinadas con troquel abierto',
	},
	{
		code: '31301102',
		label: 'Forjas de aleación ferrosa maquinadas con troquel abierto',
	},
	{
		code: '31301103',
		label: 'Forjas de acero maquinadas con troquel abierto',
	},
	{
		code: '31301104',
		label: 'Forjas de acero inoxidable maquinadas con troquel abierto',
	},
	{
		code: '31301105',
		label: 'Forjas de hierro maquinadas con troquel abierto',
	},
	{
		code: '31301106',
		label: 'Forjas de aluminio maquinadas con troquel abierto',
	},
	{
		code: '31301107',
		label: 'Forjas de magnesio maquinadas con troquel abierto',
	},
	{
		code: '31301108',
		label: 'Forjas de titanio maquinadas con troquel abierto',
	},
	{
		code: '31301109',
		label: 'Forjas de berilio maquinadas con troquel abierto',
	},
	{
		code: '31301110',
		label: 'Forjas de cobre maquinadas con troquel abierto',
	},
	{
		code: '31301111',
		label: 'Forjas de latón maquinadas con troquel abierto',
	},
	{
		code: '31301112',
		label: 'Forjas de bronce maquinadas con troquel abierto',
	},
	{
		code: '31301113',
		label: 'Forjas de zinc maquinadas con troquel abierto',
	},
	{
		code: '31301114',
		label: 'Forjas de estaño maquinadas con troquel abierto',
	},
	{
		code: '31301115',
		label: 'Forjas de plomo maquinadas con troquel abierto',
	},
	{
		code: '31301116',
		label: 'Forjas de metales preciosos maquinadas con troquel abierto',
	},
	{
		code: '31301117',
		label: 'Forjas compuestas maquinadas con troquel abierto',
	},
	{
		code: '31301118',
		label: 'Forjas de aleación de níquel maquinadas con troquel abierto',
	},
	{
		code: '31301119',
		label: 'Forjas no metálicas maquinadas con troquel abierto',
	},
	{
		code: '31301200',
		label: 'Forjas labradas con troquel cerrado',
	},
	{
		code: '31301201',
		label: 'Forjas de aleación no ferrosa maquinadas con troquel cerrado',
	},
	{
		code: '31301202',
		label: 'Forjas de aleación ferrosa maquinadas con troquel cerrado',
	},
	{
		code: '31301203',
		label: 'Forjas de acero maquinadas con troquel cerrado',
	},
	{
		code: '31301204',
		label: 'Forjas de acero inoxidable maquinadas con troquel cerrado',
	},
	{
		code: '31301205',
		label: 'Forjas de hierro maquinadas con troquel cerrado',
	},
	{
		code: '31301206',
		label: 'Forjas de aluminio maquinadas con troquel cerrado',
	},
	{
		code: '31301207',
		label: 'Forjas de magnesio maquinadas con troquel cerrado',
	},
	{
		code: '31301208',
		label: 'Forjas de titanio maquinadas con troquel cerrado',
	},
	{
		code: '31301209',
		label: 'Forjas de berilio maquinadas con troquel cerrado',
	},
	{
		code: '31301210',
		label: 'Forjas de cobre maquinadas con troquel cerrado',
	},
	{
		code: '31301211',
		label: 'Forjas de latón maquinadas con troquel cerrado',
	},
	{
		code: '31301212',
		label: 'Forjas de bronce maquinadas con troquel cerrado',
	},
	{
		code: '31301213',
		label: 'Forjas de zinc maquinadas con troquel cerrado',
	},
	{
		code: '31301214',
		label: 'Forjas de estaño maquinadas con troquel cerrado',
	},
	{
		code: '31301215',
		label: 'Forjas de plomo maquinadas con troquel cerrado',
	},
	{
		code: '31301216',
		label: 'Forjas de metales preciosos maquinadas con troquel cerrado',
	},
	{
		code: '31301217',
		label: 'Forjas compuestas maquinadas con troquel cerrado',
	},
	{
		code: '31301218',
		label: 'Forjas de aleación de níquel maquinadas con troquel cerrado',
	},
	{
		code: '31301219',
		label: 'Forjas no metálicas maquinadas con troquel cerrado',
	},
	{
		code: '31301300',
		label: 'Forjas labradas de troquel de impresión',
	},
	{
		code: '31301301',
		label: 'Forjas de aleación no ferrosa maquinadas con impresión por troquel',
	},
	{
		code: '31301302',
		label: 'Forjas de aleación ferrosa maquinadas con impresión por troquel',
	},
	{
		code: '31301303',
		label: 'Forjas de acero maquinadas con impresión por troquel',
	},
	{
		code: '31301304',
		label: 'Forjas de acero inoxidable maquinadas con impresión por troquel',
	},
	{
		code: '31301305',
		label: 'Forjas de hierro maquinadas con impresión por troquel',
	},
	{
		code: '31301306',
		label: 'Forjas de aluminio maquinadas con impresión por troquel',
	},
	{
		code: '31301307',
		label: 'Forjas de magnesio maquinadas con impresión por troquel',
	},
	{
		code: '31301308',
		label: 'Forjas de titanio maquinadas con impresión por troquel',
	},
	{
		code: '31301309',
		label: 'Forjas de berilio maquinadas con impresión por troquel',
	},
	{
		code: '31301310',
		label: 'Forjas de cobre maquinadas con impresión por troquel',
	},
	{
		code: '31301311',
		label: 'Forjas de latón maquinadas con impresión por troquel',
	},
	{
		code: '31301312',
		label: 'Forjas de bronce maquinadas con impresión por troquel',
	},
	{
		code: '31301313',
		label: 'Forjas de zinc maquinadas con impresión por troquel',
	},
	{
		code: '31301314',
		label: 'Forjas de estaño maquinadas con impresión por troquel',
	},
	{
		code: '31301315',
		label: 'Forjas de plomo maquinadas con impresión por troquel',
	},
	{
		code: '31301316',
		label: 'Forjas de metales preciosos maquinadas con impresión por troquel',
	},
	{
		code: '31301317',
		label: 'Forjas compuestas maquinadas con impresión por troquel',
	},
	{
		code: '31301318',
		label: 'Forjas de aleación de níquel maquinadas con impresión por troquel',
	},
	{
		code: '31301319',
		label: 'Forjas no metálicas maquinadas con impresión por troquel',
	},
	{
		code: '31301400',
		label: 'Forjas labradas por reducción',
	},
	{
		code: '31301401',
		label: 'Forjas de aleación no ferrosa maquinadas por reducción',
	},
	{
		code: '31301402',
		label: 'Forjas de aleación ferrosa maquinadas por reducción',
	},
	{
		code: '31301403',
		label: 'Forjas de acero maquinadas por reducción',
	},
	{
		code: '31301404',
		label: 'Forjas de acero inoxidable maquinadas por reducción',
	},
	{
		code: '31301405',
		label: 'Forjas de hierro maquinadas por reducción',
	},
	{
		code: '31301406',
		label: 'Forjas de aluminio maquinadas por reducción',
	},
	{
		code: '31301407',
		label: 'Forjas de magnesio maquinadas por reducción',
	},
	{
		code: '31301408',
		label: 'Forjas de titanio maquinadas por reducción',
	},
	{
		code: '31301409',
		label: 'Forjas de berilio maquinadas por reducción',
	},
	{
		code: '31301410',
		label: 'Forjas de cobre maquinadas por reducción',
	},
	{
		code: '31301411',
		label: 'Forjas de latón maquinadas por reducción',
	},
	{
		code: '31301412',
		label: 'Forjas de bronce maquinadas por reducción',
	},
	{
		code: '31301413',
		label: 'Forjas de zinc maquinadas por reducción',
	},
	{
		code: '31301414',
		label: 'Forjas de estaño maquinadas por reducción',
	},
	{
		code: '31301415',
		label: 'Forjas de plomo maquinadas por reducción',
	},
	{
		code: '31301416',
		label: 'Forjas de metales preciosos maquinadas por reducción',
	},
	{
		code: '31301417',
		label: 'Forjas compuestas maquinadas por reducción',
	},
	{
		code: '31301418',
		label: 'Forjas de aleación de níquel maquinadas por reducción',
	},
	{
		code: '31301419',
		label: 'Forjas no metálicas maquinadas por reducción',
	},
	{
		code: '31301500',
		label: 'Forjas labradas de anillo cilindrado',
	},
	{
		code: '31301501',
		label: 'Forjas de aluminio maquinadas por anillo enrollado',
	},
	{
		code: '31301502',
		label: 'Forjas de berilio maquinadas por anillo enrollado',
	},
	{
		code: '31301503',
		label: 'Forjas de latón maquinadas por anillo enrollado',
	},
	{
		code: '31301504',
		label: 'Forjas de bronce maquinadas por anillo enrollado',
	},
	{
		code: '31301505',
		label: 'Forjas de cobre maquinadas por anillo enrollado',
	},
	{
		code: '31301506',
		label: 'Forjas de hierro maquinadas por anillo enrollado',
	},
	{
		code: '31301507',
		label: 'Forjas de plomo maquinadas por anillo enrollado',
	},
	{
		code: '31301508',
		label: 'Forjas de magnesio maquinadas por anillo enrollado',
	},
	{
		code: '31301509',
		label: 'Forjas de metal precioso maquinadas por anillo enrollado',
	},
	{
		code: '31301510',
		label: 'Forjas de acero inoxidable maquinadas por anillo enrollado',
	},
	{
		code: '31301511',
		label: 'Forjas de estaño maquinadas por anillo enrollado',
	},
	{
		code: '31301512',
		label: 'Forjas de titanio maquinadas por anillo enrollado',
	},
	{
		code: '31301513',
		label: 'Forjas de zinc maquinadas por anillo enrollado',
	},
	{
		code: '31301514',
		label: 'Forjas de aleación no ferrosa maquinadas por anillo enrollado',
	},
	{
		code: '31301515',
		label: 'Forjas de aleación ferrosa maquinadas por anillo enrollado',
	},
	{
		code: '31301516',
		label: 'Forjas de acero maquinadas por anillo enrollado',
	},
	{
		code: '31301517',
		label: 'Forjas compuestas maquinadas por anillo enrollado',
	},
	{
		code: '31301518',
		label: 'Forjas de aleación de níquel maquinadas por anillo enrollado',
	},
	{
		code: '31301519',
		label: 'Forjas no metálicos maquinadas por anillo enrollado',
	},
	{
		code: '31311100',
		label: 'Conjuntos de tubería soldada de solvente',
	},
	{
		code: '31311101',
		label: 'Ensambles de tubería soldada de solvente de aluminio',
	},
	{
		code: '31311102',
		label: 'Ensambles de tubería soldada de solvente de acero de carbono',
	},
	{
		code: '31311103',
		label: 'Ensambles de tubería soldada de solvente de aleación hast x',

		palabrasSimilares:
			'Ensambles de tubería soldada de solvente de aleación de hastelloy x',
	},
	{
		code: '31311104',
		label: 'Ensambles de tubería soldada de solvente de inconel',
	},
	{
		code: '31311105',
		label: 'Ensambles de tubería soldada de solvente de acero de aleación baja',
	},
	{
		code: '31311106',
		label: 'Ensambles de tubería soldada de solvente no metálico',
	},
	{
		code: '31311109',
		label: 'Ensambles de tubería soldada de solvente de acero inoxidable',
	},
	{
		code: '31311110',
		label: 'Ensambles de tubería soldada de solvente de titanio',
	},
	{
		code: '31311111',
		label: 'Ensambles de tubería soldada de solvente de aleación wasp',
	},
	{
		code: '31311112',
		label: 'Ensambles de tubería soldada de solvente de cobre',
	},
	{
		code: '31311113',
		label: 'Ensambles de tubería soldada de solvente de latón',
	},
	{
		code: '31311200',
		label: 'Conjuntos de tubería remachada',
	},
	{
		code: '31311201',
		label: 'Ensambles de tubería remachada de aluminio',
	},
	{
		code: '31311202',
		label: 'Ensambles de tubería remachada de acero al carbono',
	},
	{
		code: '31311203',
		label: 'Ensambles de tubería remachada de aleación hast x',

		palabrasSimilares:
			'Ensambles de tubería remachada de aleación de hastelloy x',
	},
	{
		code: '31311204',
		label: 'Ensambles de tubería remachada de inconel',
	},
	{
		code: '31311205',
		label: 'Ensambles de tubería remachada de acero de aleación baja',
	},
	{
		code: '31311206',
		label: 'Ensambles de tubería remachada no metálica',
	},
	{
		code: '31311209',
		label: 'Ensambles de tubería remachada de acero inoxidable',
	},
	{
		code: '31311210',
		label: 'Ensambles de tubería remachada de titanio',
	},
	{
		code: '31311211',
		label: 'Ensambles de tubería remachada de aleación wasp',
	},
	{
		code: '31311212',
		label: 'Ensambles de tubería remachada de cobre',
	},
	{
		code: '31311213',
		label: 'Ensambles de tubería remachada de latón',
	},
	{
		code: '31311300',
		label: 'Conjuntos empernados de tubería',
	},
	{
		code: '31311301',
		label: 'Ensambles de tubería atornillada de aluminio',
	},
	{
		code: '31311302',
		label: 'Ensambles de tubería atornillada de acero al carbono',
	},
	{
		code: '31311303',
		label: 'Ensambles de tubería atornillada de aleación hast x',

		palabrasSimilares:
			'Ensambles de tubería atornillada de aleación hastelloy x',
	},
	{
		code: '31311304',
		label: 'Ensambles de tubería atornillada de inconel',
	},
	{
		code: '31311305',
		label: 'Ensambles de tubería atornillada de acero de aleación baja',
	},
	{
		code: '31311306',
		label: 'Ensambles de tubería atornillada no metálica',
	},
	{
		code: '31311309',
		label: 'Ensambles de tubería atornillada de acero inoxidable',
	},
	{
		code: '31311310',
		label: 'Ensambles de tubería atornillada de titanio',
	},
	{
		code: '31311311',
		label: 'Ensambles de tubería atornillada de aleación wasp',
	},
	{
		code: '31311312',
		label: 'Ensambles de tubería atornillada de cobre',
	},
	{
		code: '31311313',
		label: 'Ensambles de tubería atornillada de latón',
	},
	{
		code: '31311400',
		label: 'Conjuntos de tuberías soldadas con ultra violeta UV',
	},
	{
		code: '31311401',
		label: 'Ensambles de tubería soldada con ultra violeta de aluminio',
	},
	{
		code: '31311402',
		label: 'Ensambles de tubería soldada con ultra violeta de acero al carbono',
	},
	{
		code: '31311403',
		label: 'Ensambles de tubería soldada con ultra violeta de aleación hast x',

		palabrasSimilares:
			'Ensambles de tubería soldada con ultra violeta de aleación hastelloy x',
	},
	{
		code: '31311404',
		label: 'Ensambles de tubería soldada con ultra violeta de inconel',
	},
	{
		code: '31311405',
		label:
			'Ensambles de tubería soldada con ultra violeta de acero de aleación baja',
	},
	{
		code: '31311406',
		label: 'Ensambles de tubería soldada con ultra violeta no metálica',
	},
	{
		code: '31311409',
		label: 'Ensambles de tubería soldada con ultra violeta de acero inoxidable',
	},
	{
		code: '31311410',
		label: 'Ensambles de tubería soldada con ultra violeta de titanio',
	},
	{
		code: '31311411',
		label: 'Ensambles de tubería soldada con ultra violeta de aleación wasp',
	},
	{
		code: '31311412',
		label: 'Ensambles de tubería soldada con ultra violeta de cobre',
	},
	{
		code: '31311413',
		label: 'Ensambles de tubería soldada con ultra violeta de latón',
	},
	{
		code: '31311500',
		label: 'Conjuntos de tubería soldado o soldado con latón',
	},
	{
		code: '31311501',
		label: 'Ensambles de tubería con soldadura fuerte o débil de aluminio',
	},
	{
		code: '31311502',
		label:
			'Ensambles de tubería con soldadura fuerte o débil de acero al carbono',
	},
	{
		code: '31311503',
		label:
			'Ensambles de tubería con soldadura fuerte o débil de aleación hast x',

		palabrasSimilares:
			'Ensambles de tubería con soldadura fuerte o débil de aleación hastelloy x',
	},
	{
		code: '31311504',
		label: 'Ensambles de tubería con soldadura fuerte o débil de inconel',
	},
	{
		code: '31311505',
		label:
			'Ensambles de tubería con soldadura fuerte o débil de acero de aleación baja',
	},
	{
		code: '31311506',
		label: 'Ensambles de tubería con soldadura fuerte o débil no metálica',
	},
	{
		code: '31311509',
		label:
			'Ensambles de tubería con soldadura fuerte o débil de acero inoxidable',
	},
	{
		code: '31311510',
		label: 'Ensambles de tubería con soldadura fuerte o débil de titanio',
	},
	{
		code: '31311511',
		label: 'Ensambles de tubería con soldadura fuerte o débil de aleación wasp',
	},
	{
		code: '31311512',
		label: 'Ensambles de tubería con soldadura fuerte o débil de cobre',
	},
	{
		code: '31311513',
		label: 'Ensambles de tubería con soldadura fuerte o débil de latón',
	},
	{
		code: '31311600',
		label: 'Conjuntos de tubería soldada sónica',
	},
	{
		code: '31311601',
		label: 'Ensambles de tubería con soldadura sónica de aluminio',
	},
	{
		code: '31311602',
		label: 'Ensambles de tubería con soldadura sónica de acero al carbono',
	},
	{
		code: '31311603',
		label: 'Ensambles de tubería con soldadura sónica de aleación hast x',

		palabrasSimilares:
			'Ensambles de tubería con soldadura sónica de aleación hastelloy x',
	},
	{
		code: '31311604',
		label: 'Ensambles de tubería con soldadura sónica de inconel',
	},
	{
		code: '31311605',
		label:
			'Ensambles de tubería con soldadura sónica de acero de aleación baja',
	},
	{
		code: '31311606',
		label: 'Ensambles de tubería con soldadura sónica no metálica',
	},
	{
		code: '31311609',
		label: 'Ensambles de tubería con soldadura sónica de acero inoxidable',
	},
	{
		code: '31311610',
		label: 'Ensambles de tubería con soldadura sónica de titanio',
	},
	{
		code: '31311611',
		label: 'Ensambles de tubería con soldadura sónica de aleación wasp',
	},
	{
		code: '31311612',
		label: 'Ensambles de tubería con soldadura sónica de cobre',
	},
	{
		code: '31311613',
		label: 'Ensambles de tubería con soldadura sónica de latón',
	},
	{
		code: '31311700',
		label: 'Conjuntos de tubería pegada',
	},
	{
		code: '31311701',
		label: 'Ensambles de tubería pegada de aluminio',
	},
	{
		code: '31311702',
		label: 'Ensambles de tubería pegada de acero al carbono',
	},
	{
		code: '31311703',
		label: 'Ensambles de tubería pegada de aleación hast x',

		palabrasSimilares: 'Ensambles de tubería pegada de aleación hastelloy x',
	},
	{
		code: '31311704',
		label: 'Ensambles de tubería pegada de inconel',
	},
	{
		code: '31311705',
		label: 'Ensambles de tubería pegada de acero de aleación baja',
	},
	{
		code: '31311706',
		label: 'Ensambles de tubería pegada no metálica',
	},
	{
		code: '31311709',
		label: 'Ensambles de tubería pegada de acero inoxidable',
	},
	{
		code: '31311710',
		label: 'Ensambles de tubería pegada de titanio',
	},
	{
		code: '31311711',
		label: 'Ensambles de tubería pegada de aleación wasp',
	},
	{
		code: '31311712',
		label: 'Ensambles de tubería pegada de cobre',
	},
	{
		code: '31311713',
		label: 'Ensambles de tubería pegada de latón',
	},
	{
		code: '31321100',
		label: 'Conjuntos ligados de material en barras',
	},
	{
		code: '31321101',
		label: 'Ensambles de barras pegadas de aluminio',
	},
	{
		code: '31321102',
		label: 'Ensambles de barras pegadas de acero al carbono',
	},
	{
		code: '31321103',
		label: 'Ensambles de barras pegadas de aleación hast x',

		palabrasSimilares: 'Ensambles de barras pegadas de aleación hastelloy x',
	},
	{
		code: '31321104',
		label: 'Ensambles de barras pegadas de inconel',
	},
	{
		code: '31321105',
		label: 'Ensambles de barras pegadas de acero de aleación baja',
	},
	{
		code: '31321106',
		label: 'Ensambles de barras pegadas no metálica',
	},
	{
		code: '31321109',
		label: 'Ensambles de barras pegadas de acero inoxidable',
	},
	{
		code: '31321110',
		label: 'Ensambles de barras pegadas de titanio',
	},
	{
		code: '31321111',
		label: 'Ensambles de barras pegadas de aleación wasp',
	},
	{
		code: '31321112',
		label: 'Ensambles de barras pegadas de cobre',
	},
	{
		code: '31321113',
		label: 'Ensambles de barras pegadas de latón',
	},
	{
		code: '31321200',
		label: 'Conjuntos soldados de solvente de material en barras',
	},
	{
		code: '31321201',
		label: 'Ensambles de barras soldadas con solvente de aluminio',
	},
	{
		code: '31321202',
		label: 'Ensambles de barras soldadas con solvente de acero al carbono',
	},
	{
		code: '31321203',
		label: 'Ensambles de barras soldadas con solvente de aleación hast x',

		palabrasSimilares:
			'Ensambles de barras soldadas con solvente de aleación hastelloy x',
	},
	{
		code: '31321204',
		label: 'Ensambles de barras soldadas con solvente de inconel',
	},
	{
		code: '31321205',
		label:
			'Ensambles de barras soldadas con solvente de acero de aleación baja',
	},
	{
		code: '31321206',
		label: 'Ensambles de barras soldadas con solvente no metálica',
	},
	{
		code: '31321209',
		label: 'Ensambles de barras soldadas con solvente de acero inoxidable',
	},
	{
		code: '31321210',
		label: 'Ensambles de barras soldadas con solvente de titanio',
	},
	{
		code: '31321211',
		label: 'Ensambles de barras soldadas con solvente de aleación wasp',
	},
	{
		code: '31321212',
		label: 'Ensambles de barras soldadas con solvente de cobre',
	},
	{
		code: '31321213',
		label: 'Ensambles de barras soldadas con solvente de latón',
	},
	{
		code: '31321300',
		label: 'Conjuntos remachados de material en barras',
	},
	{
		code: '31321301',
		label: 'Ensambles de barras remachadas de aluminio',
	},
	{
		code: '31321302',
		label: 'Ensambles de barras remachadas de acero al carbono',
	},
	{
		code: '31321303',
		label: 'Ensambles de barras remachadas de aleación hast x',

		palabrasSimilares: 'Ensambles de barras remachadas de aleación hastelloy x',
	},
	{
		code: '31321304',
		label: 'Ensambles de barras remachadas de inconel',
	},
	{
		code: '31321305',
		label: 'Ensambles de barras remachadas de acero de aleación baja',
	},
	{
		code: '31321306',
		label: 'Ensambles de barras remachadas no metálica',

		palabrasSimilares: 'Ensambles de barras remachadas plásticas',
	},
	{
		code: '31321309',
		label: 'Ensambles de barras remachadas de acero inoxidable',
	},
	{
		code: '31321310',
		label: 'Ensambles de barras remachadas de titanio',
	},
	{
		code: '31321311',
		label: 'Ensambles de barras remachadas de aleación wasp',
	},
	{
		code: '31321312',
		label: 'Ensambles de barras remachadas de cobre',
	},
	{
		code: '31321313',
		label: 'Ensambles de barras remachadas de latón',
	},
	{
		code: '31321400',
		label: 'Conjuntos soldados o soldados en fuerte de material en barras',
	},
	{
		code: '31321401',
		label:
			'Ensambles de barras soldadas con soldadura fuerte o débil de aluminio',
	},
	{
		code: '31321402',
		label:
			'Ensambles de barras soldadas con soldadura fuerte o débil de acero al carbono',
	},
	{
		code: '31321403',
		label:
			'Ensambles de barras soldadas con soldadura fuerte o débil de aleación hast x',

		palabrasSimilares:
			'Ensambles de barras soldadas con soldadura fuerte o débil de aleación hastelloy x',
	},
	{
		code: '31321404',
		label:
			'Ensambles de barras soldadas con soldadura fuerte o débil de inconel',
	},
	{
		code: '31321405',
		label:
			'Ensambles de barras soldadas con soldadura fuerte o débil de acero de aleación baja',
	},
	{
		code: '31321406',
		label:
			'Ensambles de barras soldadas con soldadura fuerte o débil no metálica',

		palabrasSimilares:
			'Ensambles de barras soldadas con soldadura fuerte o débil plástica',
	},
	{
		code: '31321409',
		label:
			'Ensambles de barras soldadas con soldadura fuerte o débil de acero inoxidable',
	},
	{
		code: '31321410',
		label:
			'Ensambles de barras soldadas con soldadura fuerte o débil de titanio',
	},
	{
		code: '31321411',
		label:
			'Ensambles de barras soldadas con soldadura fuerte o débil de aleación wasp',
	},
	{
		code: '31321412',
		label: 'Ensambles de barras soldadas con soldadura fuerte o débil de cobre',
	},
	{
		code: '31321413',
		label: 'Ensambles de barras soldadas con soldadura fuerte o débil de latón',
	},
	{
		code: '31321500',
		label: 'Conjuntos de barras soldadas con ultra violeta UV',
	},
	{
		code: '31321501',
		label:
			'Ensambles de barras soldadas con soldadura ultra violeta de aluminio',
	},
	{
		code: '31321502',
		label:
			'Ensambles de barras soldadas con soldadura ultra violeta de acero al carbono',
	},
	{
		code: '31321503',
		label:
			'Ensambles de barras soldadas con soldadura ultra violeta de aleación hast x',

		palabrasSimilares:
			'Ensambles de barras soldadas con soldadura ultra violeta de aleación hastelloy x',
	},
	{
		code: '31321504',
		label:
			'Ensambles de barras soldadas con soldadura ultra violeta de inconel',
	},
	{
		code: '31321505',
		label:
			'Ensambles de barras soldadas con soldadura ultra violeta de acero de aleación baja',
	},
	{
		code: '31321506',
		label:
			'Ensambles de barras soldadas con soldadura ultra violeta no metálica',

		palabrasSimilares:
			'Ensambles de barras soldadas con soldadura ultra violeta de plástico',
	},
	{
		code: '31321509',
		label:
			'Ensambles de barras soldadas con soldadura ultra violeta de acero inoxidable',
	},
	{
		code: '31321510',
		label:
			'Ensambles de barras soldadas con soldadura ultra violeta de titanio',
	},
	{
		code: '31321511',
		label:
			'Ensambles de barras soldadas con soldadura ultra violeta de aleación wasp',
	},
	{
		code: '31321512',
		label: 'Ensambles de barras soldadas con soldadura ultra violeta de cobre',
	},
	{
		code: '31321513',
		label: 'Ensambles de barras soldadas con soldadura ultra violeta de latón',
	},
	{
		code: '31321600',
		label: 'Conjuntos sónicos soldados de material en barras',
	},
	{
		code: '31321601',
		label: 'Ensambles de barras soldadas con soldadura sónica de aluminio',
	},
	{
		code: '31321602',
		label:
			'Ensambles de barras soldadas con soldadura sónica de acero al carbono',
	},
	{
		code: '31321603',
		label:
			'Ensambles de barras soldadas con soldadura sónica de aleación hast x',

		palabrasSimilares:
			'Ensambles de barras soldadas con soldadura sónica de aleación hastelloy x',
	},
	{
		code: '31321604',
		label: 'Ensambles de barras soldadas con soldadura sónica de inconel',
	},
	{
		code: '31321605',
		label:
			'Ensambles de barras soldadas con soldadura sónica de acero de aleación baja',
	},
	{
		code: '31321606',
		label: 'Ensambles de barras soldadas con soldadura sónica no metálica',

		palabrasSimilares:
			'Ensambles de barras soldadas con soldadura sónica plástica',
	},
	{
		code: '31321609',
		label:
			'Ensambles de barras soldadas con soldadura sónica de acero inoxidable',
	},
	{
		code: '31321610',
		label: 'Ensambles de barras soldadas con soldadura sónica de titanio',
	},
	{
		code: '31321611',
		label: 'Ensambles de barras soldadas con soldadura sónica de aleación wasp',
	},
	{
		code: '31321612',
		label: 'Ensambles de barras soldadas con soldadura sónica de cobre',
	},
	{
		code: '31321613',
		label: 'Ensambles de barras soldadas con soldadura sónica de latón',
	},
	{
		code: '31321700',
		label: 'Conjuntos empernados de material en barras',
	},
	{
		code: '31321701',
		label: 'Ensambles de barras atornilladas de aluminio',
	},
	{
		code: '31321702',
		label: 'Ensambles de barras atornilladas de acero al carbono',
	},
	{
		code: '31321703',
		label: 'Ensambles de barras atornilladas de aleación hast x',

		palabrasSimilares:
			'Ensamble de barras atornilladas de aleación hastelloy x',
	},
	{
		code: '31321704',
		label: 'Ensambles de barras atornilladas de inconel',
	},
	{
		code: '31321705',
		label: 'Ensambles de barras atornilladas de acero de aleación baja',
	},
	{
		code: '31321706',
		label: 'Ensambles de barras atornilladas no metálica',

		palabrasSimilares: 'Ensambles de barras atornilladas de plástico',
	},
	{
		code: '31321709',
		label: 'Ensambles de barras atornilladas de acero inoxidable',
	},
	{
		code: '31321710',
		label: 'Ensambles de barras atornilladas de titanio',
	},
	{
		code: '31321711',
		label: 'Ensambles de barras atornilladas de aleación wasp',
	},
	{
		code: '31321712',
		label: 'Ensambles de barras atornilladas de cobre',
	},
	{
		code: '31321713',
		label: 'Ensambles de barras atornilladas de latón',
	},
	{
		code: '31331100',
		label: 'Conjuntos estructurales con acabado ligado',
	},
	{
		code: '31331101',
		label: 'Ensambles estructurales pegados de aluminio',
	},
	{
		code: '31331102',
		label: 'Ensambles estructurales pegados de acero al carbono',
	},
	{
		code: '31331103',
		label: 'Ensambles estructurales pegados de aleación hast x',

		palabrasSimilares:
			'Ensambles estructurales pegados de aleación hastelloy x',
	},
	{
		code: '31331104',
		label: 'Ensambles estructurales pegados de inconel',
	},
	{
		code: '31331105',
		label: 'Ensambles estructurales pegados de acero de aleación baja',
	},
	{
		code: '31331106',
		label: 'Ensambles estructurales pegados no metálica',

		palabrasSimilares: 'Ensambles estructurales pegados de plástico',
	},
	{
		code: '31331109',
		label: 'Ensambles estructurales pegados de acero inoxidable',
	},
	{
		code: '31331110',
		label: 'Ensambles estructurales pegados de titanio',
	},
	{
		code: '31331111',
		label: 'Ensambles estructurales pegados de aleación wasp',
	},
	{
		code: '31331112',
		label: 'Ensambles estructurales pegados de cobre',
	},
	{
		code: '31331113',
		label: 'Ensambles estructurales pegados de latón',
	},
	{
		code: '31331200',
		label: 'Conjuntos estructurales empernados',
	},
	{
		code: '31331201',
		label: 'Ensambles estructurales atornillados de aluminio',
	},
	{
		code: '31331202',
		label: 'Ensambles estructurales atornillados de acero al carbono',
	},
	{
		code: '31331203',
		label: 'Ensambles estructurales atornillados de aleación hast x',

		palabrasSimilares:
			'Ensambles estructurales atornillados de aleación hastelloy x',
	},
	{
		code: '31331204',
		label: 'Ensambles estructurales atornillados de inconel',
	},
	{
		code: '31331205',
		label: 'Ensambles estructurales atornillados de acero de aleación baja',
	},
	{
		code: '31331206',
		label: 'Ensambles estructurales atornillados no metálica',

		palabrasSimilares: 'Ensambles estructurales atornillados de plástico',
	},
	{
		code: '31331209',
		label: 'Ensambles estructurales atornillados de acero inoxidable',
	},
	{
		code: '31331210',
		label: 'Ensambles estructurales atornillados de titanio',
	},
	{
		code: '31331211',
		label: 'Ensambles estructurales atornillados de aleación wasp',
	},
	{
		code: '31331212',
		label: 'Ensambles estructurales atornillados de cobre',
	},
	{
		code: '31331213',
		label: 'Ensambles estructurales atornillados de latón',
	},
	{
		code: '31331300',
		label: 'Conjuntos estructurales soldado sónico',
	},
	{
		code: '31331301',
		label: 'Ensambles estructurales con soldadura sónica de aluminio',
	},
	{
		code: '31331302',
		label: 'Ensambles estructurales con soldadura sónica de acero al carbono',
	},
	{
		code: '31331303',
		label: 'Ensambles estructurales con soldadura sónica de aleación hast x',

		palabrasSimilares:
			'Ensambles estructurales con soldadura sónica de aleación hastelloy x',
	},
	{
		code: '31331304',
		label: 'Ensambles estructurales con soldadura sónica de inconel',
	},
	{
		code: '31331305',
		label:
			'Ensambles estructurales con soldadura sónica de acero de aleación baja',
	},
	{
		code: '31331306',
		label: 'Ensambles estructurales con soldadura sónica no metálica',

		palabrasSimilares: 'Ensambles estructurales con soldadura sónica plástica',
	},
	{
		code: '31331309',
		label: 'Ensambles estructurales con soldadura sónica de acero inoxidable',
	},
	{
		code: '31331310',
		label: 'Ensambles estructurales con soldadura sónica de titanio',
	},
	{
		code: '31331311',
		label: 'Ensambles estructurales con soldadura sónica de aleación wasp',
	},
	{
		code: '31331312',
		label: 'Ensambles estructurales con soldadura sónica de cobre',
	},
	{
		code: '31331313',
		label: 'Ensambles estructurales con soldadura sónica de latón',
	},
	{
		code: '31331400',
		label: 'Conjuntos estructurales soldados con ultra violeta UV',
	},
	{
		code: '31331401',
		label: 'Ensambles estructurales con soldadura ultra violeta de aluminio',
	},
	{
		code: '31331402',
		label:
			'Ensambles estructurales con soldadura ultra violeta de acero al carbono',
	},
	{
		code: '31331403',
		label:
			'Ensambles estructurales con soldadura ultra violeta de aleación hast x',

		palabrasSimilares:
			'Ensambles estructurales con soldadura ultra violeta de aleación hastelloy x',
	},
	{
		code: '31331404',
		label: 'Ensambles estructurales con soldadura ultra violeta de inconel',
	},
	{
		code: '31331405',
		label:
			'Ensambles estructurales con soldadura ultra violeta de acero de aleación baja',
	},
	{
		code: '31331406',
		label: 'Ensambles estructurales con soldadura ultra violeta no metálica',

		palabrasSimilares:
			'Ensambles estructurales con soldadura ultra violeta plástica',
	},
	{
		code: '31331409',
		label:
			'Ensambles estructurales con soldadura ultra violeta de acero inoxidable',
	},
	{
		code: '31331410',
		label: 'Ensambles estructurales con soldadura ultra violeta de titanio',
	},
	{
		code: '31331411',
		label:
			'Ensambles estructurales con soldadura ultra violeta de aleación wasp',
	},
	{
		code: '31331412',
		label: 'Ensambles estructurales con soldadura ultra violeta de cobre',
	},
	{
		code: '31331413',
		label: 'Ensambles estructurales con soldadura ultra violeta de latón',
	},
	{
		code: '31331500',
		label: 'Conjuntos estructurales soldado de solvente',
	},
	{
		code: '31331501',
		label: 'Ensambles estructurales con soldadura de solvente de aluminio',
	},
	{
		code: '31331502',
		label:
			'Ensambles estructurales con soldadura de solvente de acero al carbono',
	},
	{
		code: '31331503',
		label:
			'Ensambles estructurales con soldadura de solvente de aleación hast x',

		palabrasSimilares:
			'Ensambles estructurales con soldadura de solvente de aleación hestalloy x',
	},
	{
		code: '31331504',
		label: 'Ensambles estructurales con soldadura de solvente de inconel',
	},
	{
		code: '31331505',
		label:
			'Ensambles estructurales con soldadura de solvente de acero de aleación baja',
	},
	{
		code: '31331506',
		label: 'Ensambles estructurales con soldadura de solvente no metálica',
	},
	{
		code: '31331509',
		label:
			'Ensambles estructurales con soldadura de solvente de acero inoxidable',
	},
	{
		code: '31331510',
		label: 'Ensambles estructurales con soldadura de solvente de titanio',
	},
	{
		code: '31331511',
		label: 'Ensambles estructurales con soldadura de solvente de aleación wasp',
	},
	{
		code: '31331512',
		label: 'Ensambles estructurales con soldadura de solvente de cobre',
	},
	{
		code: '31331513',
		label: 'Ensambles estructurales con soldadura de solvente de latón',
	},
	{
		code: '31331600',
		label: 'Conjuntos estructurales soldado o soldado con latón',
	},
	{
		code: '31331601',
		label:
			'Ensambles estructurales con soldadura de fuerte o débil de aluminio',
	},
	{
		code: '31331602',
		label:
			'Ensambles estructurales con soldadura de fuerte o débil de acero al carbono',
	},
	{
		code: '31331603',
		label:
			'Ensambles estructurales con soldadura de fuerte o débil de aleación hast x',

		palabrasSimilares:
			'Ensambles estructurales con soldadura de fuerte o débil de aleación hastelloy x',
	},
	{
		code: '31331604',
		label: 'Ensambles estructurales con soldadura de fuerte o débil de inconel',
	},
	{
		code: '31331605',
		label:
			'Ensambles estructurales con soldadura de fuerte o débil de acero de aleación baja',
	},
	{
		code: '31331606',
		label:
			'Ensambles estructurales con soldadura de fuerte o débil no metálica',

		palabrasSimilares:
			'Ensambles estructurales con soldadura de fuerte o débil plástica',
	},
	{
		code: '31331609',
		label:
			'Ensambles estructurales con soldadura de fuerte o débil de acero inoxidable',
	},
	{
		code: '31331610',
		label: 'Ensambles estructurales con soldadura de fuerte o débil de titanio',
	},
	{
		code: '31331611',
		label:
			'Ensambles estructurales con soldadura de fuerte o débil de aleación wasp',
	},
	{
		code: '31331612',
		label: 'Ensambles estructurales con soldadura de fuerte o débil de cobre',
	},
	{
		code: '31331613',
		label: 'Ensambles estructurales con soldadura de fuerte o débil de latón',
	},
	{
		code: '31331700',
		label: 'Conjuntos estructurales remachados',
	},
	{
		code: '31331701',
		label: 'Ensambles estructurales remachados de aluminio',
	},
	{
		code: '31331702',
		label: 'Ensambles estructurales remachados de acero al carbono',
	},
	{
		code: '31331703',
		label: 'Ensambles estructurales remachados de aleación hast x',

		palabrasSimilares:
			'Ensambles estructurales remachados de aleación hastelloy x',
	},
	{
		code: '31331704',
		label: 'Ensambles estructurales remachados de inconel',
	},
	{
		code: '31331705',
		label: 'Ensambles estructurales remachados de acero de aleación baja',
	},
	{
		code: '31331706',
		label: 'Ensambles estructurales remachados no metálica',

		palabrasSimilares: 'Ensambles estructurales remachados de plástico',
	},
	{
		code: '31331709',
		label: 'Ensambles estructurales remachados de acero inoxidable',
	},
	{
		code: '31331710',
		label: 'Ensambles estructurales remachados de titanio',
	},
	{
		code: '31331711',
		label: 'Ensambles estructurales remachados de aleación wasp',
	},
	{
		code: '31331712',
		label: 'Ensambles estructurales remachados de cobre',
	},
	{
		code: '31331713',
		label: 'Ensambles estructurales remachados de latón',
	},
	{
		code: '31341100',
		label: 'Conjuntos de placa soldado o soldado con latón',
	},
	{
		code: '31341101',
		label:
			'Ensambles de láminas soldadas con soldadura fuerte o débil de aluminio',
	},
	{
		code: '31341102',
		label:
			'Ensambles de láminas soldadas con soldadura fuerte o débil de acero al carbono',
	},
	{
		code: '31341103',
		label:
			'Ensambles de láminas soldadas con soldadura fuerte o débil de aleación hast x',

		palabrasSimilares:
			'Ensambles de láminas soldadas con soldadura fuerte o débil de aleación hastelloy x',
	},
	{
		code: '31341104',
		label:
			'Ensambles de láminas soldadas con soldadura fuerte o débil de inconel',
	},
	{
		code: '31341105',
		label:
			'Ensambles de láminas soldadas con soldadura fuerte o débil de acero de aleación baja',
	},
	{
		code: '31341106',
		label:
			'Ensambles de láminas soldadas con soldadura fuerte o débil no metálica',

		palabrasSimilares:
			'Ensambles de láminas soldadas con soldadura fuerte o débil plástica',
	},
	{
		code: '31341109',
		label:
			'Ensambles de láminas soldadas con soldadura fuerte o débil de acero inoxidable',
	},
	{
		code: '31341110',
		label:
			'Ensambles de láminas soldadas con soldadura fuerte o débil de titanio',
	},
	{
		code: '31341111',
		label:
			'Ensambles de láminas soldadas con soldadura fuerte o débil de aleación wasp',
	},
	{
		code: '31341112',
		label:
			'Ensambles de láminas soldadas con soldadura fuerte o débil de cobre',
	},
	{
		code: '31341113',
		label:
			'Ensambles de láminas soldadas con soldadura fuerte o débil de latón',
	},
	{
		code: '31341200',
		label: 'Conjuntos remachados de placa',
	},
	{
		code: '31341201',
		label: 'Ensambles de láminas remachadas de aluminio',
	},
	{
		code: '31341202',
		label: 'Ensambles de láminas remachadas de acero al carbono',
	},
	{
		code: '31341203',
		label: 'Ensambles de láminas remachadas de aleación hast x',

		palabrasSimilares:
			'Ensambles de láminas remachadas de aleación hastelloy x',
	},
	{
		code: '31341204',
		label: 'Ensambles de láminas remachadas de inconel',
	},
	{
		code: '31341205',
		label: 'Ensambles de láminas remachadas de acero de aleación baja',
	},
	{
		code: '31341206',
		label: 'Ensambles de láminas remachadas no metálica',

		palabrasSimilares: 'Ensambles de láminas remachadas de plástico',
	},
	{
		code: '31341209',
		label: 'Ensambles de láminas remachadas de acero inoxidable',
	},
	{
		code: '31341210',
		label: 'Ensambles de láminas remachadas de titanio',
	},
	{
		code: '31341211',
		label: 'Ensambles de láminas remachadas de aleación wasp',
	},
	{
		code: '31341212',
		label: 'Ensambles de láminas remachadas de cobre',
	},
	{
		code: '31341213',
		label: 'Ensambles de láminas remachadas de latón',
	},
	{
		code: '31341300',
		label: 'Conjuntos de láminas soldadas con ultra violeta UV',
	},
	{
		code: '31341301',
		label:
			'Ensambles de láminas soldadas con soldadura ultra violeta de aluminio',
	},
	{
		code: '31341302',
		label:
			'Ensambles de láminas soldadas con soldadura ultra violeta de acero al carbono',
	},
	{
		code: '31341303',
		label:
			'Ensambles de láminas soldadas con soldadura ultra violeta de aleación hast x',

		palabrasSimilares:
			'Ensamble de láminas soldadas con soldadura ultra violeta de aleación hastelloy x',
	},
	{
		code: '31341304',
		label:
			'Ensambles de láminas soldadas con soldadura ultra violeta de inconel',
	},
	{
		code: '31341305',
		label:
			'Ensambles de láminas soldadas con soldadura ultra violeta de acero de aleación baja',
	},
	{
		code: '31341306',
		label:
			'Ensambles de láminas soldadas con soldadura ultra violeta no metálica',

		palabrasSimilares:
			'Ensambles de láminas soldadas con soldadura ultra violeta plástica',
	},
	{
		code: '31341309',
		label:
			'Ensambles de láminas soldadas con soldadura ultra violeta de acero inoxidable',
	},
	{
		code: '31341310',
		label:
			'Ensambles de láminas soldadas con soldadura ultra violeta de titanio',
	},
	{
		code: '31341311',
		label:
			'Ensambles de láminas soldadas con soldadura ultra violeta de aleación wasp',
	},
	{
		code: '31341312',
		label: 'Ensambles de láminas soldadas con soldadura ultra violeta de cobre',
	},
	{
		code: '31341313',
		label: 'Ensambles de láminas soldadas con soldadura ultra violeta de latón',
	},
	{
		code: '31341400',
		label: 'Conjuntos de lámina soldada sónica',
	},
	{
		code: '31341401',
		label: 'Ensambles de láminas soldadas con soldadura sónica de aluminio',
	},
	{
		code: '31341402',
		label:
			'Ensambles de láminas soldadas con soldadura sónica de acero al carbono',
	},
	{
		code: '31341403',
		label:
			'Ensambles de láminas soldadas con soldadura sónica de aleación hast x',

		palabrasSimilares:
			'Ensambles de láminas soldadas con soldadura sónica de aleación hastelloy x',
	},
	{
		code: '31341404',
		label: 'Ensambles de láminas soldadas con soldadura sónica de inconel',
	},
	{
		code: '31341405',
		label:
			'Ensambles de láminas soldadas con soldadura sónica de acero de aleación baja',
	},
	{
		code: '31341406',
		label: 'Ensambles de láminas soldadas con soldadura sónica no metálica',

		palabrasSimilares: 'Ensamble de láminas soldadas con soldadura plástica',
	},
	{
		code: '31341409',
		label:
			'Ensambles de láminas soldadas con soldadura sónica de acero inoxidable',
	},
	{
		code: '31341410',
		label: 'Ensambles de láminas soldadas con soldadura sónica de titanio',
	},
	{
		code: '31341411',
		label:
			'Ensambles de láminas soldadas con soldadura sónica de aleación wasp',
	},
	{
		code: '31341412',
		label: 'Ensambles de láminas soldadas con soldadura sónica de cobre',
	},
	{
		code: '31341413',
		label: 'Ensambles de láminas soldadas con soldadura sónica de latón',
	},
	{
		code: '31341500',
		label: 'Conjuntos de lámina soldada de solvente',
	},
	{
		code: '31341501',
		label: 'Ensambles de láminas soldadas con soldadura solvente de aluminio',
	},
	{
		code: '31341502',
		label:
			'Ensambles de láminas soldadas con soldadura solvente de acero al carbono',
	},
	{
		code: '31341503',
		label:
			'Ensambles de láminas soldadas con soldadura solvente de aleación hast x',

		palabrasSimilares:
			'Ensambles de láminas soldadas con soldadura solvente de aleación hastelloy x',
	},
	{
		code: '31341504',
		label: 'Ensambles de láminas soldadas con soldadura solvente de inconel',
	},
	{
		code: '31341505',
		label:
			'Ensambles de láminas soldadas con soldadura solvente de acero de aleación baja',
	},
	{
		code: '31341506',
		label: 'Ensambles de láminas soldadas con soldadura solvente no metálica',
	},
	{
		code: '31341509',
		label:
			'Ensambles de láminas soldadas con soldadura solvente de acero inoxidable',
	},
	{
		code: '31341510',
		label: 'Ensambles de láminas soldadas con soldadura solvente de titanio',
	},
	{
		code: '31341511',
		label:
			'Ensambles de láminas soldadas con soldadura solvente de aleación wasp',
	},
	{
		code: '31341512',
		label: 'Ensambles de láminas soldadas con soldadura solvente de cobre',
	},
	{
		code: '31341513',
		label: 'Ensambles de láminas soldadas con soldadura solvente de latón',
	},
	{
		code: '31341600',
		label: 'Conjuntos de lámina con acabado ligado',
	},
	{
		code: '31341601',
		label: 'Ensambles de láminas pegadas de aluminio',
	},
	{
		code: '31341602',
		label: 'Ensambles de láminas pegadas de acero al carbono',
	},
	{
		code: '31341603',
		label: 'Ensambles de láminas pegadas de aleación hast x',

		palabrasSimilares: 'Ensamble de láminas pegadas de aleación hastelloy x',
	},
	{
		code: '31341604',
		label: 'Ensambles de láminas pegadas de inconel',
	},
	{
		code: '31341605',
		label: 'Ensambles de láminas pegadas de acero de aleación baja',
	},
	{
		code: '31341606',
		label: 'Ensambles de láminas pegadas no metálica',
	},
	{
		code: '31341609',
		label: 'Ensambles de láminas pegadas de acero inoxidable',
	},
	{
		code: '31341610',
		label: 'Ensambles de láminas pegadas de titanio',
	},
	{
		code: '31341611',
		label: 'Ensambles de láminas pegadas de aleación wasp',
	},
	{
		code: '31341612',
		label: 'Ensambles de láminas pegadas de cobre',
	},
	{
		code: '31341613',
		label: 'Ensambles de láminas pegadas de latón',
	},
	{
		code: '31341700',
		label: 'Conjuntos de lámina empernada',
	},
	{
		code: '31341701',
		label: 'Ensambles de láminas atornilladas de aluminio',
	},
	{
		code: '31341702',
		label: 'Ensambles de láminas atornilladas de acero al carbono',
	},
	{
		code: '31341703',
		label: 'Ensambles de láminas atornilladas de aleación hast x',

		palabrasSimilares:
			'Ensambles de láminas atornilladas de aleación hastelloy x',
	},
	{
		code: '31341704',
		label: 'Ensambles de láminas atornilladas de inconel',
	},
	{
		code: '31341705',
		label: 'Ensambles de láminas atornilladas de acero de aleación baja',
	},
	{
		code: '31341706',
		label: 'Ensambles de láminas atornilladas no metálica',

		palabrasSimilares: 'Ensambles de láminas atornilladas de plástico',
	},
	{
		code: '31341709',
		label: 'Ensambles de láminas atornilladas de acero inoxidable',
	},
	{
		code: '31341710',
		label: 'Ensambles de láminas atornilladas de titanio',
	},
	{
		code: '31341711',
		label: 'Ensambles de láminas atornilladas de aleación wasp',
	},
	{
		code: '31341712',
		label: 'Ensambles de láminas atornilladas de cobre',
	},
	{
		code: '31341713',
		label: 'Ensambles de láminas atornilladas de latón',
	},
	{
		code: '31351100',
		label: 'Conjuntos de tubos soldados con ultra violeta UV',
	},
	{
		code: '31351101',
		label:
			'Ensambles de tubos soldados con soldadura ultra violeta de aluminio',
	},
	{
		code: '31351102',
		label:
			'Ensambles de tubos soldados con soldadura ultra violeta de acero al carbono',
	},
	{
		code: '31351103',
		label:
			'Ensambles de tubos soldados con soldadura ultra violeta de aleación hast x',

		palabrasSimilares:
			'Ensambles de tubos soldados con soldadura ultra violeta de aleación hastelloy x',
	},
	{
		code: '31351104',
		label: 'Ensambles de tubos soldados con soldadura ultra violeta de inconel',
	},
	{
		code: '31351105',
		label:
			'Ensambles de tubos soldados con soldadura ultra violeta de acero de aleación baja',
	},
	{
		code: '31351106',
		label:
			'Ensambles de tubos soldados con soldadura ultra violeta no metálica',

		palabrasSimilares:
			'Ensambles de tubos soldados con soldadura ultra violeta plástica',
	},
	{
		code: '31351109',
		label:
			'Ensambles de tubos soldados con soldadura ultra violeta de acero inoxidable',
	},
	{
		code: '31351110',
		label: 'Ensambles de tubos soldados con soldadura ultra violeta de titanio',
	},
	{
		code: '31351111',
		label:
			'Ensambles de tubos soldados con soldadura ultra violeta de aleación wasp',
	},
	{
		code: '31351112',
		label: 'Ensambles de tubos soldados con soldadura ultra violeta de cobre',
	},
	{
		code: '31351113',
		label: 'Ensambles de tubos soldados con soldadura ultra violeta de latón',
	},
	{
		code: '31351200',
		label: 'Conjuntos de tubería soldada o soldada con latón',
	},
	{
		code: '31351201',
		label:
			'Ensambles de tubos soldados con soldadura fuerte o débil de aluminio',
	},
	{
		code: '31351202',
		label:
			'Ensambles de tubos soldados con soldadura fuerte o débil de acero al carbono',
	},
	{
		code: '31351203',
		label:
			'Ensambles de tubos soldados con soldadura fuerte o débil de aleación hast x',

		palabrasSimilares:
			'Ensambles de tubos soldados con soldadura fuerte o débil de aleación hastelloy x',
	},
	{
		code: '31351204',
		label:
			'Ensambles de tubos soldados con soldadura fuerte o débil de inconel',
	},
	{
		code: '31351205',
		label:
			'Ensambles de tubos soldados con soldadura fuerte o débil de acero de aleación baja',
	},
	{
		code: '31351206',
		label:
			'Ensambles de tubos soldados con soldadura fuerte o débil no metálica',

		palabrasSimilares:
			'Ensambles de tubos soldados con soldadura fuerte o débil plástica',
	},
	{
		code: '31351209',
		label:
			'Ensambles de tubos soldados con soldadura fuerte o débil de acero inoxidable',
	},
	{
		code: '31351210',
		label:
			'Ensambles de tubos soldados con soldadura fuerte o débil de titanio',
	},
	{
		code: '31351211',
		label:
			'Ensambles de tubos soldados con soldadura fuerte o débil de aleación wasp',
	},
	{
		code: '31351212',
		label: 'Ensambles de tubos soldados con soldadura fuerte o débil de cobre',
	},
	{
		code: '31351213',
		label: 'Ensambles de tubos soldados con soldadura fuerte o débil de latón',
	},
	{
		code: '31351300',
		label: 'Conjuntos de tubería remachada',
	},
	{
		code: '31351301',
		label: 'Ensambles de tubos remachados de aluminio',
	},
	{
		code: '31351302',
		label: 'Ensambles de tubos remachados de acero al carbono',
	},
	{
		code: '31351303',
		label: 'Ensambles de tubos remachados de aleación hast x',

		palabrasSimilares: 'Ensambles de tubos remachados de aleación hastelloy x',
	},
	{
		code: '31351304',
		label: 'Ensambles de tubos remachados de inconel',
	},
	{
		code: '31351305',
		label: 'Ensambles de tubos remachados de acero de aleación baja',
	},
	{
		code: '31351306',
		label: 'Ensambles de tubos remachados no metálica',

		palabrasSimilares: 'Ensambles de tubos remangados de plástico',
	},
	{
		code: '31351309',
		label: 'Ensambles de tubos remachados de acero inoxidable',
	},
	{
		code: '31351310',
		label: 'Ensambles de tubos remachados de titanio',
	},
	{
		code: '31351311',
		label: 'Ensambles de tubos remachados de aleación wasp',
	},
	{
		code: '31351312',
		label: 'Ensambles de tubos remachados de cobre',
	},
	{
		code: '31351313',
		label: 'Ensambles de tubos remachados de latón',
	},
	{
		code: '31351400',
		label: 'Conjuntos de tubería ligada',
	},
	{
		code: '31351401',
		label: 'Ensambles de tubos pegados de aluminio',
	},
	{
		code: '31351402',
		label: 'Ensambles de tubos pegados de acero al carbono',
	},
	{
		code: '31351403',
		label: 'Ensambles de tubos pegados de aleación hast x',

		palabrasSimilares: 'Ensambles de tubos pegados de aleación hastelloy x',
	},
	{
		code: '31351404',
		label: 'Ensambles de tubos pegados de inconel',
	},
	{
		code: '31351405',
		label: 'Ensambles de tubos pegados de acero de aleación baja',
	},
	{
		code: '31351406',
		label: 'Ensambles de tubos pegados no metálica',

		palabrasSimilares: 'ensambles de tubos pegados de plástico',
	},
	{
		code: '31351409',
		label: 'Ensambles de tubos pegados de acero inoxidable',
	},
	{
		code: '31351410',
		label: 'Ensambles de tubos pegados de titanio',
	},
	{
		code: '31351411',
		label: 'Ensambles de tubos pegados de aleación wasp',
	},
	{
		code: '31351412',
		label: 'Ensambles de tubos pegados de cobre',
	},
	{
		code: '31351413',
		label: 'Ensambles de tubos pegados de latón',
	},
	{
		code: '31351500',
		label: 'Conjuntos de tubería empernados',
	},
	{
		code: '31351501',
		label: 'Ensambles de tubos atornillados de aluminio',
	},
	{
		code: '31351502',
		label: 'Ensambles de tubos atornillados de acero al carbono',
	},
	{
		code: '31351503',
		label: 'Ensambles de tubos atornillados de aleación hast x',

		palabrasSimilares:
			'Ensambles de tubos atornillados de aleación hastelloy x',
	},
	{
		code: '31351504',
		label: 'Ensambles de tubos atornillados de inconel',
	},
	{
		code: '31351505',
		label: 'Ensambles de tubos atornillados de acero de aleación baja',
	},
	{
		code: '31351506',
		label: 'Ensambles de tubos atornillados no metálica',

		palabrasSimilares: 'Ensambles de tubos atornillados de plástico',
	},
	{
		code: '31351509',
		label: 'Ensambles de tubos atornillados de acero inoxidable',
	},
	{
		code: '31351510',
		label: 'Ensambles de tubos atornillados de titanio',
	},
	{
		code: '31351511',
		label: 'Ensambles de tubos atornillados de aleación wasp',
	},
	{
		code: '31351512',
		label: 'Ensambles de tubos atornillados de cobre',
	},
	{
		code: '31351513',
		label: 'Ensambles de tubos atornillados de latón',
	},
	{
		code: '31351600',
		label: 'Conjuntos de tubería soldada de solvente',
	},
	{
		code: '31351601',
		label: 'Ensambles de tubos soldados con disolvente de aluminio',
	},
	{
		code: '31351602',
		label: 'Ensambles de tubos soldados con disolvente de acero al carbono',
	},
	{
		code: '31351603',
		label: 'Ensambles de tubos soldados con disolvente de aleación hast x',

		palabrasSimilares:
			'Ensambles de tubos soldados con disolvente de aleación hastelloy x',
	},
	{
		code: '31351604',
		label: 'Ensambles de tubos soldados con disolvente de inconel',
	},
	{
		code: '31351605',
		label:
			'Ensambles de tubos soldados con disolvente de acero de aleación baja',
	},
	{
		code: '31351606',
		label: 'Ensambles de tubos soldados con disolvente no metálica',
	},
	{
		code: '31351609',
		label: 'Ensambles de tubos soldados con disolvente de acero inoxidable',
	},
	{
		code: '31351610',
		label: 'Ensambles de tubos soldados con disolvente de titanio',
	},
	{
		code: '31351611',
		label: 'Ensambles de tubos soldados con disolvente de aleación wasp',
	},
	{
		code: '31351612',
		label: 'Ensambles de tubos soldados con disolvente de cobre',
	},
	{
		code: '31351613',
		label: 'Ensambles de tubos soldados con disolvente de latón',
	},
	{
		code: '31351700',
		label: 'Conjuntos de tubería soldada sónica',
	},
	{
		code: '31351701',
		label: 'Ensambles de tubos soldados con soldadura sónica de aluminio',
	},
	{
		code: '31351702',
		label:
			'Ensambles de tubos soldados con soldadura sónica de acero al carbono',
	},
	{
		code: '31351703',
		label:
			'Ensambles de tubos soldados con soldadura sónica de aleación hast x',

		palabrasSimilares:
			'Ensambles de tubos soldados con soldadura sónica de aleación hastelloy x',
	},
	{
		code: '31351704',
		label: 'Ensambles de tubos soldados con soldadura sónica de inconel',
	},
	{
		code: '31351705',
		label:
			'Ensambles de tubos soldados con soldadura sónica de acero de aleación baja',
	},
	{
		code: '31351706',
		label: 'Ensambles de tubos soldados con soldadura sónica no metálica',

		palabrasSimilares:
			'Ensambles de tubos soldados con soldadura sónica plástica',
	},
	{
		code: '31351709',
		label:
			'Ensambles de tubos soldados con soldadura sónica de acero inoxidable',
	},
	{
		code: '31351710',
		label: 'Ensambles de tubos soldados con soldadura sónica de titanio',
	},
	{
		code: '31351711',
		label: 'Ensambles de tubos soldados con soldadura sónica de aleación wasp',
	},
	{
		code: '31351712',
		label: 'Ensambles de tubos soldados con soldadura sónica de cobre',
	},
	{
		code: '31351713',
		label: 'Ensambles de tubos soldados con soldadura sónica de latón',
	},
	{
		code: '31361100',
		label: 'Conjuntos de placa ligados',
	},
	{
		code: '31361101',
		label: 'Ensambles de placas soldadas de aluminio',
	},
	{
		code: '31361102',
		label: 'Ensambles de placas soldadas de acero al carbono',
	},
	{
		code: '31361103',
		label: 'Ensambles de placas soldadas de aleación hast x',

		palabrasSimilares: 'Ensambles de placas soldadas de aleación hastelloy x',
	},
	{
		code: '31361104',
		label: 'Ensambles de placas soldadas de inconel',
	},
	{
		code: '31361105',
		label: 'Ensambles de placas soldadas de acero de aleación baja',
	},
	{
		code: '31361106',
		label: 'Ensambles de placas soldadas no metálica',

		palabrasSimilares: 'Ensambles de placas soldadas de plástico',
	},
	{
		code: '31361109',
		label: 'Ensambles de placas soldadas de acero inoxidable',
	},
	{
		code: '31361110',
		label: 'Ensambles de placas soldadas de titanio',
	},
	{
		code: '31361111',
		label: 'Ensambles de placas soldadas de aleación wasp',
	},
	{
		code: '31361112',
		label: 'Ensambles de placas soldadas de cobre',
	},
	{
		code: '31361113',
		label: 'Ensambles de placas soldadas de latón',
	},
	{
		code: '31361200',
		label: 'Conjuntos de placa empernados',
	},
	{
		code: '31361201',
		label: 'Ensambles de placas atornilladas de aluminio',
	},
	{
		code: '31361202',
		label: 'Ensambles de placas atornilladas de acero al carbono',
	},
	{
		code: '31361203',
		label: 'Ensambles de placas atornilladas de aleación hast x',

		palabrasSimilares:
			'Ensambles de placas atornilladas de aleación hastelloy x',
	},
	{
		code: '31361204',
		label: 'Ensambles de placas atornilladas de inconel',
	},
	{
		code: '31361205',
		label: 'Ensambles de placas atornilladas de acero de aleación baja',
	},
	{
		code: '31361206',
		label: 'Ensambles de placas atornilladas no metálica',

		palabrasSimilares: 'Ensambles de placas atornilladas de plástico',
	},
	{
		code: '31361209',
		label: 'Ensambles de placas atornilladas de acero inoxidable',
	},
	{
		code: '31361210',
		label: 'Ensambles de placas atornilladas de titanio',
	},
	{
		code: '31361211',
		label: 'Ensambles de placas atornilladas de aleación wasp',
	},
	{
		code: '31361212',
		label: 'Ensambles de placas atornilladas de cobre',
	},
	{
		code: '31361213',
		label: 'Ensambles de placas atornilladas de latón',
	},
	{
		code: '31361300',
		label: 'Conjuntos de placa soldados de solvente',
	},
	{
		code: '31361301',
		label: 'Ensambles de placas soldadas con solvente de aluminio',
	},
	{
		code: '31361302',
		label: 'Ensambles de placas soldadas con solvente de acero al carbono',
	},
	{
		code: '31361303',
		label: 'Ensambles de placas soldadas con solvente de aleación hast x',

		palabrasSimilares:
			'Ensambles de placas soldadas con solvente de aleación hastelloy x',
	},
	{
		code: '31361304',
		label: 'Ensambles de placas soldadas con solvente de inconel',
	},
	{
		code: '31361305',
		label:
			'Ensambles de placas soldadas con solvente de acero de aleación baja',
	},
	{
		code: '31361306',
		label: 'Ensambles de placas soldadas con solvente no metálica',
	},
	{
		code: '31361309',
		label: 'Ensambles de placas soldadas con solvente de acero inoxidable',
	},
	{
		code: '31361310',
		label: 'Ensambles de placas soldadas con solvente de titanio',
	},
	{
		code: '31361311',
		label: 'Ensambles de placas soldadas con solvente de aleación wasp',
	},
	{
		code: '31361312',
		label: 'Ensambles de placas soldadas con solvente de cobre',
	},
	{
		code: '31361313',
		label: 'Ensambles de placas soldadas con solvente de latón',
	},
	{
		code: '31361400',
		label: 'Conjuntos de placas soldadas o soldadas con latón',
	},
	{
		code: '31361401',
		label:
			'Ensambles de placas soldadas con soldadura fuerte o débil de aluminio',
	},
	{
		code: '31361402',
		label:
			'Ensambles de placas soldadas con soldadura fuerte o débil de acero al carbono',
	},
	{
		code: '31361403',
		label:
			'Ensambles de placas soldadas con soldadura fuerte o débil de aleación hast x',

		palabrasSimilares:
			'Ensambles de placas soldadas con soldadura fuerte o débil de aleación hastelloy x',
	},
	{
		code: '31361404',
		label:
			'Ensambles de placas soldadas con soldadura fuerte o débil de inconel',
	},
	{
		code: '31361405',
		label:
			'Ensambles de placas soldadas con soldadura fuerte o débil de acero de aleación baja',
	},
	{
		code: '31361406',
		label:
			'Ensambles de placas soldadas con soldadura fuerte o débil no metálica',
	},
	{
		code: '31361409',
		label:
			'Ensambles de placas soldadas con soldadura fuerte o débil de acero inoxidable',
	},
	{
		code: '31361410',
		label:
			'Ensambles de placas soldadas con soldadura fuerte o débil de titanio',
	},
	{
		code: '31361411',
		label:
			'Ensambles de placas soldadas con soldadura fuerte o débil de aleación wasp',

		palabrasSimilares:
			'Ensambles de placas soldadas con soldadura fuerte o débil de aleación waspaloy',
	},
	{
		code: '31361412',
		label: 'Ensambles de placas soldadas con soldadura fuerte o débil de cobre',
	},
	{
		code: '31361413',
		label: 'Ensambles de placas soldadas con soldadura fuerte o débil de latón',
	},
	{
		code: '31361500',
		label: 'Conjuntos de placas soldadas con ultra violeta UV',
	},
	{
		code: '31361501',
		label:
			'Ensambles de placas soldadas con soldadura ultra violeta de aluminio',
	},
	{
		code: '31361502',
		label:
			'Ensambles de placas soldadas con soldadura ultra violeta de acero al carbono',
	},
	{
		code: '31361503',
		label:
			'Ensambles de placas soldadas con soldadura ultra violeta de aleación hast x',

		palabrasSimilares:
			'Ensambles de placas soldadas con soldadura ultra violeta de aleación hastelloy x',
	},
	{
		code: '31361504',
		label:
			'Ensambles de placas soldadas con soldadura ultra violeta de inconel',
	},
	{
		code: '31361505',
		label:
			'Ensambles de placas soldadas con soldadura ultra violeta de acero de aleación baja',
	},
	{
		code: '31361506',
		label:
			'Ensambles de placas soldadas con soldadura ultra violeta no metálica',
	},
	{
		code: '31361509',
		label:
			'Ensambles de placas soldadas con soldadura ultra violeta de acero inoxidable',
	},
	{
		code: '31361510',
		label:
			'Ensambles de placas soldadas con soldadura ultra violeta de titanio',
	},
	{
		code: '31361511',
		label:
			'Ensambles de placas soldadas con soldadura ultra violeta de aleación wasp',

		palabrasSimilares:
			'Ensambles de placas soldadas con soldadura ultra violeta de aleación waspaloy',
	},
	{
		code: '31361512',
		label: 'Ensambles de placas soldadas con soldadura ultra violeta de cobre',
	},
	{
		code: '31361513',
		label: 'Ensambles de placas soldadas con soldadura ultra violeta de latón',
	},
	{
		code: '31361600',
		label: 'Conjuntos de placas soldadas con sónico',
	},
	{
		code: '31361601',
		label: 'Ensambles de placas soldadas con soldadura sónica de aluminio',
	},
	{
		code: '31361602',
		label:
			'Ensambles de placas soldadas con soldadura sónica de acero al carbono',
	},
	{
		code: '31361603',
		label:
			'Ensambles de placas soldadas con soldadura sónica de aleación hast x',

		palabrasSimilares:
			'Ensambles de placas soldadas con soldadura sónica de aleación hastelloy x',
	},
	{
		code: '31361604',
		label: 'Ensambles de placas soldadas con soldadura sónica de inconel',
	},
	{
		code: '31361605',
		label:
			'Ensambles de placas soldadas con soldadura sónica de acero de aleación baja',
	},
	{
		code: '31361606',
		label: 'Ensambles de placas soldadas con soldadura sónica no metálica',
	},
	{
		code: '31361609',
		label:
			'Ensambles de placas soldadas con soldadura sónica de acero inoxidable',
	},
	{
		code: '31361610',
		label: 'Ensambles de placas soldadas con soldadura sónica de titanio',
	},
	{
		code: '31361611',
		label: 'Ensambles de placas soldadas con soldadura sónica de aleación wasp',

		palabrasSimilares:
			'Ensambles de placas soldadas con soldadura sónica de aleación waspaloy',
	},
	{
		code: '31361612',
		label: 'Ensambles de placas soldadas con soldadura sónica de cobre',
	},
	{
		code: '31361613',
		label: 'Ensambles de placas soldadas con soldadura sónica de latón',
	},
	{
		code: '31361700',
		label: 'Conjuntos de placas remachadas',
	},
	{
		code: '31361701',
		label: 'Ensambles de placas remachadas de aluminio',
	},
	{
		code: '31361702',
		label: 'Ensambles de placas remachadas de acero al carbono',
	},
	{
		code: '31361703',
		label: 'Ensambles de placas remachadas de aleación hast x',

		palabrasSimilares: 'Ensambles de placas remachadas de aleación hastelloy x',
	},
	{
		code: '31361704',
		label: 'Ensambles de placas remachadas de inconel',
	},
	{
		code: '31361705',
		label: 'Ensambles de placas remachadas de acero de aleación baja',
	},
	{
		code: '31361706',
		label: 'Ensambles de placas remachadas no metálica',
	},
	{
		code: '31361709',
		label: 'Ensambles de placas remachadas de acero inoxidable',
	},
	{
		code: '31361710',
		label: 'Ensambles de placas remachadas de titanio',
	},
	{
		code: '31361711',
		label: 'Ensambles de placas remachadas de aleación wasp',

		palabrasSimilares: 'Ensambles de placas remachadas de aleación waspaloy',
	},
	{
		code: '31361712',
		label: 'Ensambles de placas remachadas de cobre',
	},
	{
		code: '31361713',
		label: 'Ensambles de placas remachadas de latón',
	},
	{
		code: '31371000',
		label: 'Productos de fibra de cerámica',
	},
	{
		code: '31371001',
		label: 'Paneles aislantes',
	},
	{
		code: '31371002',
		label: 'Lana aislante',
	},
	{
		code: '31371003',
		label: 'Sábanas refractarias',
	},
	{
		code: '31371100',
		label: 'Ladrillos refractarios',
	},
	{
		code: '31371101',
		label: 'Ladrillo de mulita',
	},
	{
		code: '31371102',
		label: 'Ladrillos de silimanita',
	},
	{
		code: '31371103',
		label: 'Ladrillos resistentes al ácido',
	},
	{
		code: '31371104',
		label: 'Ladrillos de sílice',
	},
	{
		code: '31371105',
		label: 'Ladrillos de alta alúmina',
	},
	{
		code: '31371106',
		label: 'Ladrillos de silicato de calcio',
	},
	{
		code: '31371107',
		label: 'Ladrillos con formas',
	},
	{
		code: '31371108',
		label: 'Ladrillos de arcilla de fuego',
	},
	{
		code: '31371200',
		label: 'Compuestos para moldear',
	},
	{
		code: '31371201',
		label: 'Moldeables densos',
	},
	{
		code: '31371202',
		label: 'Moldeables de aislación',
	},
	{
		code: '31371203',
		label: 'Moldeables bajos en cemento',
	},
	{
		code: '31371204',
		label: 'Moldeables resistentes a ácidos o a álcalis',
	},
	{
		code: '31371205',
		label: 'Moldeables resistentes a abrasión',
	},
	{
		code: '31371206',
		label: 'Moldeables sic',
	},
	{
		code: '31371207',
		label: 'Moldeables de auto flujo',
	},
	{
		code: '31371208',
		label: 'Moldeable tabular alúmina',
	},
	{
		code: '31371209',
		label: 'Moldeable resistente a la erosión',
	},
	{
		code: '31371210',
		label: 'Moldeable de alta alúmina',
	},
	{
		code: '31371211',
		label: 'Moldeable de arcilla de fuego',
	},
	{
		code: '31371212',
		label: 'Moldeable de servicio especial',
	},
	{
		code: '31371300',
		label: 'Refractarios formados',
	},
	{
		code: '31371301',
		label: 'Bloques porosos',
	},
	{
		code: '31371302',
		label: 'Boquillas de zircón',
	},
	{
		code: '31371400',
		label: 'Losetas refractarias',
	},
	{
		code: '31371401',
		label: 'Tejas de sílice',
	},
	{
		code: '31381100',
		label: 'Imanes fundidos y ensambles magnéticos',
	},
	{
		code: '31381101',
		label: 'Imán isotrópico fundido maquinado de ferrita',
	},
	{
		code: '31381102',
		label: 'Imán isotrópico fundido maquinado de bario ferrita',
	},
	{
		code: '31381103',
		label: 'Imán isotrópico fundido maquinado de estroncio ferrita',
	},
	{
		code: '31381104',
		label: 'Imán isotrópico fundido maquinado de neodimio',
	},
	{
		code: '31381105',
		label: 'Imán isotrópico fundido maquinado de samario cobalto',
	},
	{
		code: '31381106',
		label:
			'Imán isotrópico fundido maquinado de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381107',
		label: 'Imán anisotrópico fundido maquinado de ferrita',
	},
	{
		code: '31381108',
		label: 'Imán anisotrópico fundido maquinado de bario ferrita',
	},
	{
		code: '31381109',
		label: 'Imán anisotrópico fundido maquinado de estroncio ferrita',
	},
	{
		code: '31381110',
		label: 'Imán anisotrópico fundido maquinado de samario cobalto',
	},
	{
		code: '31381111',
		label:
			'Imán anisotrópico fundido maquinado de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381112',
		label: 'Imán isotrópico fundido, maquinado y recubierto de ferrita',
	},
	{
		code: '31381113',
		label: 'Imán isotrópico fundido, maquinado y recubierto de bario ferrita',
	},
	{
		code: '31381114',
		label:
			'Imán isotrópico fundido, maquinado y recubierto de estroncio ferrita',
	},
	{
		code: '31381115',
		label: 'Imán isotrópico fundido, maquinado y recubierto de neodimio',
	},
	{
		code: '31381116',
		label: 'Imán isotrópico fundido, maquinado y recubierto de samario cobalto',
	},
	{
		code: '31381117',
		label:
			'Imán isotrópico fundido, maquinado y recubierto de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381118',
		label: 'Imán anisotrópico fundido, maquinado y recubierto  de ferrita',
	},
	{
		code: '31381119',
		label:
			'Imán anisotrópico fundido, maquinado y recubierto  de bario ferrita',
	},
	{
		code: '31381120',
		label:
			'Imán anisotrópico fundido, maquinado y recubierto de estroncio ferrita',
	},
	{
		code: '31381121',
		label:
			'Imán anisotrópico fundido, maquinado y recubierto de samario cobalto',
	},
	{
		code: '31381122',
		label:
			'Imán anisotrópico fundido, maquinado y recubierto de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381123',
		label: 'Imán isotrópico fundido recubierto de ferrita',
	},
	{
		code: '31381124',
		label: 'Imán isotrópico fundido recubierto de bario ferrita',
	},
	{
		code: '31381125',
		label: 'Imán isotrópico fundido recubierto de estroncio ferrita',
	},
	{
		code: '31381126',
		label: 'Imán isotrópico fundido recubierto de neodimio',
	},
	{
		code: '31381127',
		label: 'Imán isotrópico fundido recubierto de samario cobalto',
	},
	{
		code: '31381128',
		label:
			'Imán isotrópico fundido recubierto de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381129',
		label: 'Imán anisotrópico fundido recubierto de ferrita',
	},
	{
		code: '31381130',
		label: 'Imán anisotrópico fundido recubierto de bario ferrita',
	},
	{
		code: '31381131',
		label: 'Imán anisotrópico fundido recubierto de estroncio ferrita',
	},
	{
		code: '31381132',
		label: 'Imán anisotrópico fundido recubierto de samario cobalto',
	},
	{
		code: '31381133',
		label:
			'Imán anisotrópico fundido recubierto de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381134',
		label: 'Herramienta isotrópica fundida maquinada de ferrita',
	},
	{
		code: '31381135',
		label: 'Herramienta isotrópica fundida maquinada de bario ferrita',
	},
	{
		code: '31381136',
		label: 'Herramienta isotrópica fundida maquinada de estroncio ferrita',
	},
	{
		code: '31381137',
		label: 'Herramienta isotrópica fundida maquinada de neodimio',
	},
	{
		code: '31381138',
		label: 'Herramienta isotrópica fundida maquinada de samario cobalto',
	},
	{
		code: '31381139',
		label:
			'Herramienta isotrópica fundida maquinada de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381140',
		label: 'Herramienta anisotrópica fundida maquinada de ferrita',
	},
	{
		code: '31381141',
		label: 'Herramienta anisotrópica fundida maquinada de bario ferrita',
	},
	{
		code: '31381142',
		label: 'Herramienta anisotrópica fundida maquinada de estroncio ferrita',
	},
	{
		code: '31381143',
		label: 'Herramienta anisotrópica fundida maquinada de samario cobalto',
	},
	{
		code: '31381144',
		label:
			'Herramienta anisotrópica fundida maquinada de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381145',
		label: 'Ensamble de imán isotrópico fundido de ferrita',
	},
	{
		code: '31381146',
		label: 'Ensamble de imán isotrópico fundido de bario ferrita',
	},
	{
		code: '31381147',
		label: 'Ensamble de imán isotrópico fundido de estroncio ferrita',
	},
	{
		code: '31381148',
		label: 'Ensamble de imán isotrópico fundido de neodimio',
	},
	{
		code: '31381149',
		label: 'Ensamble de imán isotrópico fundido de samario cobalto',
	},
	{
		code: '31381150',
		label:
			'Ensamble de imán isotrópico fundido de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381151',
		label: 'Ensamble de imán anisotrópico fundido de ferrita',
	},
	{
		code: '31381152',
		label: 'Ensamble de imán anisotrópico fundido de bario ferrita',
	},
	{
		code: '31381153',
		label: 'Ensamble de imán anisotrópico fundido de estroncio ferrita',
	},
	{
		code: '31381154',
		label: 'Ensamble de imán anisotrópico fundido de samario cobalto',
	},
	{
		code: '31381155',
		label:
			'Ensamble de imán anisotrópico fundido de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381200',
		label: 'Imanes sinterizados y ensambles magnéticos',
	},
	{
		code: '31381201',
		label: 'Imán isotrópico sinterizado maquinado de ferrita',
	},
	{
		code: '31381202',
		label: 'Imán isotrópico sinterizado maquinado de bario ferrita',
	},
	{
		code: '31381203',
		label: 'Imán isotrópico sinterizado maquinado de estroncio ferrita',
	},
	{
		code: '31381204',
		label: 'Imán isotrópico sinterizado maquinado de neodimio',
	},
	{
		code: '31381205',
		label: 'Imán isotrópico sinterizado maquinado de samario cobalto',
	},
	{
		code: '31381206',
		label:
			'Imán isotrópico sinterizado maquinado de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381207',
		label: 'Imán anisotrópico sinterizado maquinado de ferrita',
	},
	{
		code: '31381208',
		label: 'Imán anisotrópico sinterizado maquinado de bario ferrita',
	},
	{
		code: '31381209',
		label: 'Imán anisotrópico sinterizado maquinado de estroncio ferrita',
	},
	{
		code: '31381210',
		label: 'Imán anisotrópico sinterizado maquinado de samario cobalto',
	},
	{
		code: '31381211',
		label:
			'Imán anisotrópico sinterizado maquinado de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381212',
		label: 'Imán isotrópico sinterizado, maquinado y recubierto de ferrita',
	},
	{
		code: '31381213',
		label:
			'Imán isotrópico sinterizado, maquinado y recubierto de bario ferrita',
	},
	{
		code: '31381214',
		label:
			'Imán isotrópico sinterizado, maquinado y recubierto de estroncio ferrita',
	},
	{
		code: '31381215',
		label: 'Imán isotrópico sinterizado, maquinado y recubierto de neodimio',
	},
	{
		code: '31381216',
		label:
			'Imán isotrópico sinterizado, maquinado y recubierto de samario cobalto',
	},
	{
		code: '31381217',
		label:
			'Imán isotrópico sinterizado, maquinado y recubierto de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381218',
		label: 'Imán anisotrópico sinterizado, maquinado y recubierto  de ferrita',
	},
	{
		code: '31381219',
		label:
			'Imán anisotrópico sinterizado, maquinado y recubierto  de bario ferrita',
	},
	{
		code: '31381220',
		label:
			'Imán anisotrópico sinterizado, maquinado y recubierto de estroncio ferrita',
	},
	{
		code: '31381221',
		label:
			'Imán anisotrópico sinterizado, maquinado y recubierto de samario cobalto',
	},
	{
		code: '31381222',
		label:
			'Imán anisotrópico sinterizado, maquinado y recubierto de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381223',
		label: 'Imán isotrópico sinterizado recubierto de ferrita',
	},
	{
		code: '31381224',
		label: 'Imán isotrópico sinterizado recubierto de bario ferrita',
	},
	{
		code: '31381225',
		label: 'Imán isotrópico sinterizado recubierto de estroncio ferrita',
	},
	{
		code: '31381226',
		label: 'Imán isotrópico sinterizado recubierto de neodimio',
	},
	{
		code: '31381227',
		label: 'Imán isotrópico sinterizado recubierto de samario cobalto',
	},
	{
		code: '31381228',
		label:
			'Imán isotrópico sinterizado recubierto de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381229',
		label: 'Imán anisotrópico sinterizado recubierto de ferrita',
	},
	{
		code: '31381230',
		label: 'Imán anisotrópico sinterizado recubierto de bario ferrita',
	},
	{
		code: '31381231',
		label: 'Imán anisotrópico sinterizado recubierto de estroncio ferrita',
	},
	{
		code: '31381232',
		label: 'Imán anisotrópico sinterizado recubierto de samario cobalto',
	},
	{
		code: '31381233',
		label:
			'Imán anisotrópico sinterizado recubierto de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381234',
		label: 'Herramienta isotrópica sinterizada maquinada de ferrita',
	},
	{
		code: '31381235',
		label: 'Herramienta isotrópica sinterizada maquinada de bario ferrita',
	},
	{
		code: '31381236',
		label: 'Herramienta isotrópica sinterizada maquinada de estroncio ferrita',
	},
	{
		code: '31381237',
		label: 'Herramienta isotrópica sinterizada maquinada de neodimio',
	},
	{
		code: '31381238',
		label: 'Herramienta isotrópica sinterizada maquinada de samario cobalto',
	},
	{
		code: '31381239',
		label:
			'Herramienta isotrópica sinterizada maquinada de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381240',
		label: 'Herramienta anisotrópica sinterizada maquinada de ferrita',
	},
	{
		code: '31381241',
		label: 'Herramienta anisotrópica sinterizada maquinada de bario ferrita',
	},
	{
		code: '31381242',
		label:
			'Herramienta anisotrópica sinterizada maquinada de estroncio ferrita',
	},
	{
		code: '31381243',
		label: 'Herramienta anisotrópica sinterizada maquinada de samario cobalto',
	},
	{
		code: '31381244',
		label:
			'Herramienta anisotrópica sinterizada maquinada de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381245',
		label: 'Ensamble de imán isotrópico sinterizado de ferrita',
	},
	{
		code: '31381246',
		label: 'Ensamble de imán isotrópico sinterizado de bario ferrita',
	},
	{
		code: '31381247',
		label: 'Ensamble de imán isotrópico sinterizado de estroncio ferrita',
	},
	{
		code: '31381248',
		label: 'Ensamble de imán isotrópico sinterizado de neodimio',
	},
	{
		code: '31381249',
		label: 'Ensamble de imán isotrópico sinterizado de samario cobalto',
	},
	{
		code: '31381250',
		label:
			'Ensamble de imán isotrópico sinterizado de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381251',
		label: 'Ensamble de imán anisotrópico sinterizado de ferrita',
	},
	{
		code: '31381252',
		label: 'Ensamble de imán anisotrópico sinterizado de bario ferrita',
	},
	{
		code: '31381253',
		label: 'Ensamble de imán anisotrópico sinterizado de estroncio ferrita',
	},
	{
		code: '31381254',
		label: 'Ensamble de imán anisotrópico sinterizado de samario cobalto',
	},
	{
		code: '31381255',
		label:
			'Ensamble de imán anisotrópico sinterizado de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381300',
		label: 'Imanes prensados y ensambles magnéticos',
	},
	{
		code: '31381301',
		label: 'Imán isotrópico prensado y sinterizado maquinado de ferrita',
	},
	{
		code: '31381302',
		label: 'Imán isotrópico prensado y sinterizado maquinado de bario ferrita',
	},
	{
		code: '31381303',
		label:
			'Imán isotrópico prensado y sinterizado maquinado de estroncio ferrita',
	},
	{
		code: '31381304',
		label: 'Imán isotrópico prensado y sinterizado maquinado de neodimio',
	},
	{
		code: '31381305',
		label:
			'Imán isotrópico prensado y sinterizado maquinado de samario cobalto',
	},
	{
		code: '31381306',
		label:
			'Imán isotrópico prensado y sinterizado maquinado de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381307',
		label: 'Imán anisotrópico prensado y sinterizado maquinado de ferrita',
	},
	{
		code: '31381308',
		label:
			'Imán anisotrópico prensado y sinterizado maquinado de bario ferrita',
	},
	{
		code: '31381309',
		label:
			'Imán anisotrópico prensado y sinterizado maquinado de estroncio ferrita',
	},
	{
		code: '31381310',
		label:
			'Imán anisotrópico prensado y sinterizado maquinado de samario cobalto',
	},
	{
		code: '31381311',
		label:
			'Imán anisotrópico prensado y sinterizado maquinado de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381312',
		label:
			'Imán isotrópico prensado y sinterizado, maquinado y recubierto de ferrita',
	},
	{
		code: '31381313',
		label:
			'Imán isotrópico prensado y sinterizado, maquinado y recubierto de bario ferrita',
	},
	{
		code: '31381314',
		label:
			'Imán isotrópico prensado y sinterizado, maquinado y recubierto de estroncio ferrita',
	},
	{
		code: '31381315',
		label:
			'Imán isotrópico prensado y sinterizado, maquinado y recubierto de neodimio',
	},
	{
		code: '31381316',
		label:
			'Imán isotrópico prensado y sinterizado, maquinado y recubierto de samario cobalto',
	},
	{
		code: '31381317',
		label:
			'Imán isotrópico prensado y sinterizado, maquinado y recubierto de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381318',
		label:
			'Imán anisotrópico prensado y sinterizado, maquinado y recubierto  de ferrita',
	},
	{
		code: '31381319',
		label:
			'Imán anisotrópico prensado y sinterizado, maquinado y recubierto  de bario ferrita',
	},
	{
		code: '31381320',
		label:
			'Imán anisotrópico prensado y sinterizado, maquinado y recubierto de estroncio ferrita',
	},
	{
		code: '31381321',
		label:
			'Imán anisotrópico prensado y sinterizado, maquinado y recubierto de samario cobalto',
	},
	{
		code: '31381322',
		label:
			'Imán anisotrópico prensado y sinterizado, maquinado y recubierto de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381323',
		label: 'Imán isotrópico prensado y sinterizado recubierto de ferrita',
	},
	{
		code: '31381324',
		label: 'Imán isotrópico prensado y sinterizado recubierto de bario ferrita',
	},
	{
		code: '31381325',
		label:
			'Imán isotrópico prensado y sinterizado recubierto de estroncio ferrita',
	},
	{
		code: '31381326',
		label: 'Imán isotrópico prensado y sinterizado recubierto de neodimio',
	},
	{
		code: '31381327',
		label:
			'Imán isotrópico prensado y sinterizado recubierto de samario cobalto',
	},
	{
		code: '31381328',
		label:
			'Imán isotrópico prensado y sinterizado recubierto de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381329',
		label: 'Imán anisotrópico prensado y sinterizado recubierto de ferrita',
	},
	{
		code: '31381330',
		label:
			'Imán anisotrópico prensado y sinterizado recubierto de bario ferrita',
	},
	{
		code: '31381331',
		label:
			'Imán anisotrópico prensado y sinterizado recubierto de estroncio ferrita',
	},
	{
		code: '31381332',
		label:
			'Imán anisotrópico prensado y sinterizado recubierto de samario cobalto',
	},
	{
		code: '31381333',
		label:
			'Imán anisotrópico prensado y sinterizado recubierto de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381334',
		label: 'Herramienta isotrópica prensada y sinterizada maquinada de ferrita',
	},
	{
		code: '31381335',
		label:
			'Herramienta isotrópica prensada y sinterizada maquinada de bario ferrita',
	},
	{
		code: '31381336',
		label:
			'Herramienta isotrópica prensada y sinterizada maquinada de estroncio ferrita',
	},
	{
		code: '31381337',
		label:
			'Herramienta isotrópica prensada y sinterizada maquinada de neodimio',
	},
	{
		code: '31381338',
		label:
			'Herramienta isotrópica prensada y sinterizada maquinada de samario cobalto',
	},
	{
		code: '31381339',
		label:
			'Herramienta isotrópica prensada y sinterizada maquinada de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381340',
		label:
			'Herramienta anisotrópica prensada y sinterizada maquinada de ferrita',
	},
	{
		code: '31381341',
		label:
			'Herramienta anisotrópica prensada y sinterizada maquinada de bario ferrita',
	},
	{
		code: '31381342',
		label:
			'Herramienta anisotrópica prensada y sinterizada maquinada de estroncio ferrita',
	},
	{
		code: '31381343',
		label:
			'Herramienta anisotrópica prensada y sinterizada maquinada de samario cobalto',
	},
	{
		code: '31381344',
		label:
			'Herramienta anisotrópica prensada y sinterizada maquinada de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381345',
		label: 'Ensamble de imán isotrópico prensado y sinterizado de ferrita',
	},
	{
		code: '31381346',
		label:
			'Ensamble de imán isotrópico prensado y sinterizado de bario ferrita',
	},
	{
		code: '31381347',
		label:
			'Ensamble de imán isotrópico prensado y sinterizado de estroncio ferrita',
	},
	{
		code: '31381348',
		label: 'Ensamble de imán isotrópico prensado y sinterizado de neodimio',
	},
	{
		code: '31381349',
		label:
			'Ensamble de imán isotrópico prensado y sinterizado de samario cobalto',
	},
	{
		code: '31381350',
		label:
			'Ensamble de imán isotrópico prensado y sinterizado de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381351',
		label: 'Ensamble de imán anisotrópico prensado y sinterizado de ferrita',
	},
	{
		code: '31381352',
		label:
			'Ensamble de imán anisotrópico prensado y sinterizado de bario ferrita',
	},
	{
		code: '31381353',
		label:
			'Ensamble de imán anisotrópico prensado y sinterizado de estroncio ferrita',
	},
	{
		code: '31381354',
		label:
			'Ensamble de imán anisotrópico prensado y sinterizado de samario cobalto',
	},
	{
		code: '31381355',
		label:
			'Ensamble de imán anisotrópico prensado y sinterizado de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381400',
		label: 'Imanes pegados con plástico y ensambles magnéticos',
	},
	{
		code: '31381401',
		label: 'Imán isotrópico pegado con plástico maquinado de ferrita',
	},
	{
		code: '31381402',
		label: 'Imán isotrópico pegado con plástico maquinado de bario ferrita',
	},
	{
		code: '31381403',
		label: 'Imán isotrópico pegado con plástico maquinado de estroncio ferrita',
	},
	{
		code: '31381404',
		label: 'Imán isotrópico pegado con plástico maquinado de neodimio',
	},
	{
		code: '31381405',
		label: 'Imán isotrópico pegado con plástico maquinado de samario cobalto',
	},
	{
		code: '31381406',
		label:
			'Imán isotrópico pegado con plástico maquinado de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381407',
		label: 'Imán anisotrópico pegado con plástico maquinado de ferrita',
	},
	{
		code: '31381408',
		label: 'Imán anisotrópico pegado con plástico maquinado de bario ferrita',
	},
	{
		code: '31381409',
		label:
			'Imán anisotrópico pegado con plástico maquinado de estroncio ferrita',
	},
	{
		code: '31381410',
		label: 'Imán anisotrópico pegado con plástico maquinado de samario cobalto',
	},
	{
		code: '31381411',
		label:
			'Imán anisotrópico pegado con plástico maquinado de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381412',
		label:
			'Imán isotrópico pegado con plástico, maquinado y recubierto de ferrita',
	},
	{
		code: '31381413',
		label:
			'Imán isotrópico pegado con plástico, maquinado y recubierto de bario ferrita',
	},
	{
		code: '31381414',
		label:
			'Imán isotrópico pegado con plástico, maquinado y recubierto de estroncio ferrita',
	},
	{
		code: '31381415',
		label:
			'Imán isotrópico pegado con plástico, maquinado y recubierto de neodimio',
	},
	{
		code: '31381416',
		label:
			'Imán isotrópico pegado con plástico, maquinado y recubierto de samario cobalto',
	},
	{
		code: '31381417',
		label:
			'Imán isotrópico pegado con plástico, maquinado y recubierto de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381418',
		label:
			'Imán anisotrópico pegado con plástico, maquinado y recubierto  de ferrita',
	},
	{
		code: '31381419',
		label:
			'Imán anisotrópico pegado con plástico, maquinado y recubierto  de bario ferrita',
	},
	{
		code: '31381420',
		label:
			'Imán anisotrópico pegado con plástico, maquinado y recubierto de estroncio ferrita',
	},
	{
		code: '31381421',
		label:
			'Imán anisotrópico pegado con plástico, maquinado y recubierto de samario cobalto',
	},
	{
		code: '31381422',
		label:
			'Imán anisotrópico pegado con plástico, maquinado y recubierto de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381423',
		label: 'Imán isotrópico pegado con plástico recubierto de ferrita',
	},
	{
		code: '31381424',
		label: 'Imán isotrópico pegado con plástico recubierto de bario ferrita',
	},
	{
		code: '31381425',
		label:
			'Imán isotrópico pegado con plástico recubierto de estroncio ferrita',
	},
	{
		code: '31381426',
		label: 'Imán isotrópico pegado con plástico recubierto de neodimio',
	},
	{
		code: '31381427',
		label: 'Imán isotrópico pegado con plástico recubierto de samario cobalto',
	},
	{
		code: '31381428',
		label:
			'Imán isotrópico pegado con plástico recubierto de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381429',
		label: 'Imán anisotrópico pegado con plástico recubierto de ferrita',
	},
	{
		code: '31381430',
		label: 'Imán anisotrópico pegado con plástico recubierto de bario ferrita',
	},
	{
		code: '31381431',
		label:
			'Imán anisotrópico pegado con plástico recubierto de estroncio ferrita',
	},
	{
		code: '31381432',
		label:
			'Imán anisotrópico pegado con plástico recubierto de samario cobalto',
	},
	{
		code: '31381433',
		label:
			'Imán anisotrópico pegado con plástico recubierto de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381434',
		label: 'Herramienta isotrópica pegada con plástico maquinada de ferrita',
	},
	{
		code: '31381435',
		label:
			'Herramienta isotrópica pegada con plástico maquinada de bario ferrita',
	},
	{
		code: '31381436',
		label:
			'Herramienta isotrópica pegada con plástico maquinada de estroncio ferrita',
	},
	{
		code: '31381437',
		label: 'Herramienta isotrópica pegada con plástico maquinada de neodimio',
	},
	{
		code: '31381438',
		label:
			'Herramienta isotrópica pegada con plástico maquinada de samario cobalto',
	},
	{
		code: '31381439',
		label:
			'Herramienta isotrópica pegada con plástico maquinada de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381440',
		label: 'Herramienta anisotrópica pegada con plástico maquinada de ferrita',
	},
	{
		code: '31381441',
		label:
			'Herramienta anisotrópica pegada con plástico maquinada de bario ferrita',
	},
	{
		code: '31381442',
		label:
			'Herramienta anisotrópica pegada con plástico maquinada de estroncio ferrita',
	},
	{
		code: '31381443',
		label:
			'Herramienta anisotrópica pegada con plástico maquinada de samario cobalto',
	},
	{
		code: '31381444',
		label:
			'Herramienta anisotrópica pegada con plástico maquinada de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381445',
		label: 'Ensamble de imán isotrópico pegado con plástico de ferrita',
	},
	{
		code: '31381446',
		label: 'Ensamble de imán isotrópico pegado con plástico de bario ferrita',
	},
	{
		code: '31381447',
		label:
			'Ensamble de imán isotrópico pegado con plástico de estroncio ferrita',
	},
	{
		code: '31381448',
		label: 'Ensamble de imán isotrópico pegado con plástico de neodimio',
	},
	{
		code: '31381449',
		label: 'Ensamble de imán isotrópico pegado con plástico de samario cobalto',
	},
	{
		code: '31381450',
		label:
			'Ensamble de imán isotrópico pegado con plástico de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381451',
		label: 'Ensamble de imán anisotrópico pegado con plástico de ferrita',
	},
	{
		code: '31381452',
		label: 'Ensamble de imán anisotrópico pegado con plástico de bario ferrita',
	},
	{
		code: '31381453',
		label:
			'Ensamble de imán anisotrópico pegado con plástico de estroncio ferrita',
	},
	{
		code: '31381454',
		label:
			'Ensamble de imán anisotrópico pegado con plástico de samario cobalto',
	},
	{
		code: '31381455',
		label:
			'Ensamble de imán anisotrópico pegado con plástico de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381500',
		label: 'Imanes moldeados por inyección y ensambles magnéticos',
	},
	{
		code: '31381501',
		label:
			'Imán isotrópico pegado con plástico moldeado por inyección maquinado de ferrita',
	},
	{
		code: '31381502',
		label:
			'Imán isotrópico pegado con plástico moldeado por inyección maquinado de bario ferrita',
	},
	{
		code: '31381503',
		label:
			'Imán isotrópico pegado con plástico moldeado por inyección maquinado de estroncio ferrita',
	},
	{
		code: '31381504',
		label:
			'Imán isotrópico pegado con plástico moldeado por inyección maquinado de neodimio',
	},
	{
		code: '31381505',
		label:
			'Imán isotrópico pegado con plástico moldeado por inyección maquinado de samario cobalto',
	},
	{
		code: '31381506',
		label:
			'Imán isotrópico pegado con plástico moldeado por inyección maquinado de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381507',
		label:
			'Imán anisotrópico pegado con plástico moldeado por inyección maquinado de ferrita',
	},
	{
		code: '31381508',
		label:
			'Imán anisotrópico pegado con plástico moldeado por inyección maquinado de bario ferrita',
	},
	{
		code: '31381509',
		label:
			'Imán anisotrópico pegado con plástico moldeado por inyección maquinado de estroncio ferrita',
	},
	{
		code: '31381510',
		label:
			'Imán anisotrópico pegado con plástico moldeado por inyección maquinado de samario cobalto',
	},
	{
		code: '31381511',
		label:
			'Imán anisotrópico pegado con plástico moldeado por inyección maquinado de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381512',
		label:
			'Imán isotrópico pegado con plástico moldeado por inyección, maquinado y recubierto de ferrita',
	},
	{
		code: '31381513',
		label:
			'Imán isotrópico pegado con plástico moldeado por inyección, maquinado y recubierto de bario ferrita',
	},
	{
		code: '31381514',
		label:
			'Imán isotrópico pegado con plástico moldeado por inyección, maquinado y recubierto de estroncio ferrita',
	},
	{
		code: '31381515',
		label:
			'Imán isotrópico pegado con plástico moldeado por inyección, maquinado y recubierto de neodimio',
	},
	{
		code: '31381516',
		label:
			'Imán isotrópico pegado con plástico moldeado por inyección, maquinado y recubierto de samario cobalto',
	},
	{
		code: '31381517',
		label:
			'Imán isotrópico pegado con plástico moldeado por inyección, maquinado y recubierto de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381518',
		label:
			'Imán anisotrópico pegado con plástico moldeado por inyección, maquinado y recubierto  de ferrita',
	},
	{
		code: '31381519',
		label:
			'Imán anisotrópico pegado con plástico moldeado por inyección, maquinado y recubierto  de bario ferrita',
	},
	{
		code: '31381520',
		label:
			'Imán anisotrópico pegado con plástico moldeado por inyección, maquinado y recubierto de estroncio ferrita',
	},
	{
		code: '31381521',
		label:
			'Imán anisotrópico pegado con plástico moldeado por inyección, maquinado y recubierto de samario cobalto',
	},
	{
		code: '31381522',
		label:
			'Imán anisotrópico pegado con plástico moldeado por inyección, maquinado y recubierto de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381523',
		label:
			'Imán isotrópico pegado con plástico moldeado por inyección recubierto de ferrita',
	},
	{
		code: '31381524',
		label:
			'Imán isotrópico pegado con plástico moldeado por inyección recubierto de bario ferrita',
	},
	{
		code: '31381525',
		label:
			'Imán isotrópico pegado con plástico moldeado por inyección recubierto de estroncio ferrita',
	},
	{
		code: '31381526',
		label:
			'Imán isotrópico pegado con plástico moldeado por inyección recubierto de neodimio',
	},
	{
		code: '31381527',
		label:
			'Imán isotrópico pegado con plástico moldeado por inyección recubierto de samario cobalto',
	},
	{
		code: '31381528',
		label:
			'Imán isotrópico pegado con plástico moldeado por inyección recubierto de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381529',
		label:
			'Imán anisotrópico pegado con plástico moldeado por inyección recubierto de ferrita',
	},
	{
		code: '31381530',
		label:
			'Imán anisotrópico pegado con plástico moldeado por inyección recubierto de bario ferrita',
	},
	{
		code: '31381531',
		label:
			'Imán anisotrópico pegado con plástico moldeado por inyección recubierto de estroncio ferrita',
	},
	{
		code: '31381532',
		label:
			'Imán anisotrópico pegado con plástico moldeado por inyección recubierto de samario cobalto',
	},
	{
		code: '31381533',
		label:
			'Imán anisotrópico pegado con plástico moldeado por inyección recubierto de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381534',
		label:
			'Herramienta isotrópica pegada con plástico moldeada por inyección maquinada de ferrita',
	},
	{
		code: '31381535',
		label:
			'Herramienta isotrópica pegada con plástico moldeada por inyección maquinada de bario ferrita',
	},
	{
		code: '31381536',
		label:
			'Herramienta isotrópica pegada con plástico moldeada por inyección maquinada de estroncio ferrita',
	},
	{
		code: '31381537',
		label:
			'Herramienta isotrópica pegada con plástico moldeada por inyección maquinada de neodimio',
	},
	{
		code: '31381538',
		label:
			'Herramienta isotrópica pegada con plástico moldeada por inyección maquinada de samario cobalto',
	},
	{
		code: '31381539',
		label:
			'Herramienta isotrópica pegada con plástico moldeada por inyección maquinada de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381540',
		label:
			'Herramienta anisotrópica pegada con plástico moldeada por inyección maquinada de ferrita',
	},
	{
		code: '31381541',
		label:
			'Herramienta anisotrópica pegada con plástico moldeada por inyección maquinada de bario ferrita',
	},
	{
		code: '31381542',
		label:
			'Herramienta anisotrópica pegada con plástico moldeada por inyección maquinada de estroncio ferrita',
	},
	{
		code: '31381543',
		label:
			'Herramienta anisotrópica pegada con plástico moldeada por inyección maquinada de samario cobalto',
	},
	{
		code: '31381544',
		label:
			'Herramienta anisotrópica pegada con plástico moldeada por inyección maquinada de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381545',
		label:
			'Ensamble de imán isotrópico pegado con plástico moldeado por inyección de ferrita',
	},
	{
		code: '31381546',
		label:
			'Ensamble de imán isotrópico pegado con plástico moldeado por inyección de bario ferrita',
	},
	{
		code: '31381547',
		label:
			'Ensamble de imán isotrópico pegado con plástico moldeado por inyección de estroncio ferrita',
	},
	{
		code: '31381548',
		label:
			'Ensamble de imán isotrópico pegado con plástico moldeado por inyección de neodimio',
	},
	{
		code: '31381549',
		label:
			'Ensamble de imán isotrópico pegado con plástico moldeado por inyección de samario cobalto',
	},
	{
		code: '31381550',
		label:
			'Ensamble de imán isotrópico pegado con plástico moldeado por inyección de aluminio níquel cobalto ferroso',
	},
	{
		code: '31381551',
		label:
			'Ensamble de imán anisotrópico pegado con plástico moldeado por inyección de ferrita',
	},
	{
		code: '31381552',
		label:
			'Ensamble de imán anisotrópico pegado con plástico moldeado por inyección de bario ferrita',
	},
	{
		code: '31381553',
		label:
			'Ensamble de imán anisotrópico pegado con plástico moldeado por inyección de estroncio ferrita',
	},
	{
		code: '31381554',
		label:
			'Ensamble de imán anisotrópico pegado con plástico moldeado por inyección de samario cobalto',
	},
	{
		code: '31381555',
		label:
			'Ensamble de imán anisotrópico pegado con plástico moldeado por inyección de aluminio níquel cobalto ferroso',
	},
	{
		code: '31391500',
		label: 'Maquinados de precisión estándar',
	},
	{
		code: '31391501',
		label: 'Maquinado de precisión estándar de plástico',
	},
	{
		code: '31391502',
		label: 'Maquinado de precisión estándar de cerámica',
	},
	{
		code: '31391503',
		label: 'Maquinado de precisión estándar de acero',
	},
	{
		code: '31391504',
		label: 'Maquinado de precisión estándar de acero inoxidable',
	},
	{
		code: '31391505',
		label: 'Maquinado de precisión estándar de aluminio',
	},
	{
		code: '31391506',
		label: 'Maquinado de precisión estándar de latón',
	},
	{
		code: '31391600',
		label: 'Maquinados de precisión media',
	},
	{
		code: '31391601',
		label: 'Maquinado de precisión media de plástico',
	},
	{
		code: '31391602',
		label: 'Maquinado de precisión media de cerámica',
	},
	{
		code: '31391603',
		label: 'Maquinado de precisión media de acero',
	},
	{
		code: '31391604',
		label: 'Maquinado de precisión media de acero inoxidable',
	},
	{
		code: '31391605',
		label: 'Maquinado de precisión media de aluminio',
	},
	{
		code: '31391606',
		label: 'Maquinado de precisión media de latón',
	},
	{
		code: '31391700',
		label: 'Maquinados de precisión alta',
	},
	{
		code: '31391701',
		label: 'Maquinado de alta precisión de plástico',
	},
	{
		code: '31391702',
		label: 'Maquinado de alta precisión de cerámica',
	},
	{
		code: '31391703',
		label: 'Maquinado de alta precisión de acero',
	},
	{
		code: '31391704',
		label: 'Maquinado de alta precisión de acero inoxidable',
	},
	{
		code: '31391705',
		label: 'Maquinado de alta precisión de aluminio',
	},
	{
		code: '31391706',
		label: 'Maquinado de alta precisión de latón',
	},
	{
		code: '31401500',
		label: 'Empaques Moldeados',
	},
	{
		code: '31401501',
		label: 'Empaque moldeado de caucho',
	},
	{
		code: '31401502',
		label: 'Empaque moldeado de plástico',
	},
	{
		code: '31401503',
		label: 'Empaque moldeado o – ring',

		palabrasSimilares: 'Juntas moldeadas con anillo tórico',
	},
	{
		code: '31401504',
		label: 'Empaque moldeado de interferencia electromagnética',
	},
	{
		code: '31401505',
		label: 'Empaque moldeado de silicona',
	},
	{
		code: '31401506',
		label: 'Empaque moldeado inflable',
	},
	{
		code: '31401507',
		label: 'Kit de empaque moldeado',
	},
	{
		code: '31401600',
		label: 'Empaques troquelados',
	},
	{
		code: '31401601',
		label: 'Empaque troquelado de caucho',
	},
	{
		code: '31401602',
		label: 'Empaque troquelado de plástico',
	},
	{
		code: '31401603',
		label: 'Empaque troquelado de textil',
	},
	{
		code: '31401604',
		label: 'Empaque troquelado de corcho',
	},
	{
		code: '31401605',
		label: 'Empaque troquelado de interferencia electromagnética o emi',
	},
	{
		code: '31401606',
		label: 'Empaque troquelado de silicona',
	},
	{
		code: '31401607',
		label: 'Empaque troquelado de fibra comprimida',
	},
	{
		code: '31401608',
		label: 'Empaque troquelado de bi – material',
	},
	{
		code: '31401609',
		label: 'Empaque troquelado de polietifluroetileno',
	},
	{
		code: '31401610',
		label: 'Kit de empaque troquelado',
	},
	{
		code: '31401700',
		label: 'Empaques estampados',
	},
	{
		code: '31401701',
		label: 'Empaque de caucho estampado recubierto de acero',
	},
	{
		code: '31401702',
		label: 'Empaque estampado de plástico',
	},
	{
		code: '31401703',
		label: 'Empaque estampado de caucho',
	},
	{
		code: '31401704',
		label: 'Empaque estampado de metal',
	},
	{
		code: '31401705',
		label: 'Empaque estampado de textil',
	},
	{
		code: '31401706',
		label: 'Empaque estampado de corcho',
	},
	{
		code: '31401707',
		label: 'Empaque estampado atornillado',
	},
	{
		code: '31401708',
		label: 'Kit de empaque estampado',

		palabrasSimilares: 'Juego de juntas estampadas',
	},
	{
		code: '31401800',
		label: 'Empaques cortados con chorro de agua',
	},
	{
		code: '31401801',
		label:
			'Empaque de interferencia electromagnética cortado con chorro de agua',
	},
	{
		code: '31401802',
		label: 'Empaque  de caucho cortado con chorro de agua',
	},
	{
		code: '31401803',
		label: 'Empaque  de plástico cortado con chorro de agua',
	},
	{
		code: '31401804',
		label: 'Empaque de textil cortado con chorro de agua',
	},
	{
		code: '31401805',
		label: 'Empaque de corcho cortado con chorro de agua',
	},
	{
		code: '31401806',
		label: 'Empaque de silicona cortado con chorro de agua',
	},
	{
		code: '31401807',
		label: 'Kit de empaque cortado con chorro de agua',

		palabrasSimilares: 'Juego de juntas de corte por chorro de agua',
	},
	{
		code: '31401900',
		label: 'Empaques líquidos',
	},
	{
		code: '31401901',
		label: 'Empaque líquido de poli acrilato',
	},
	{
		code: '31401902',
		label: 'Empaque líquido de silicona',
	},
	{
		code: '31401903',
		label: 'Empaque líquido de solvente',
	},
	{
		code: '31401904',
		label: 'Empaque líquido anaeróbico',
	},
	{
		code: '31411500',
		label: 'Sellos torneados',
	},
	{
		code: '31411501',
		label: 'Sello torneado de caucho',
	},
	{
		code: '31411502',
		label: 'Sello torneado de politetrafluroetileno',
	},
	{
		code: '31411503',
		label: 'Sello torneado de neopreno',
	},
	{
		code: '31411504',
		label: 'Sello torneado de poliacrilato',
	},
	{
		code: '31411505',
		label: 'Sello torneado metálico',
	},
	{
		code: '31411506',
		label: 'Kit de sello torneado',

		palabrasSimilares: 'Juego de sellos de corte de torno',
	},
	{
		code: '31411600',
		label: 'Sellos troquelados',
	},
	{
		code: '31411601',
		label: 'Sello troquelado de caucho',
	},
	{
		code: '31411602',
		label: 'Sello troquelado de espuma',
	},
	{
		code: '31411603',
		label: 'Sello troquelado de plástico',
	},
	{
		code: '31411604',
		label: 'Kit de sello troquelado',

		palabrasSimilares: 'Juego de sellos troquelados',
	},
	{
		code: '31411605',
		label: 'Sello troquelado de silicona',
	},
	{
		code: '31411700',
		label: 'Sellos mecánicos',
	},
	{
		code: '31411701',
		label: 'Sello mecánico de empuje',
	},
	{
		code: '31411702',
		label: 'Sello mecánico de fuelle',
	},
	{
		code: '31411703',
		label: 'Kit de sello mecánico',

		palabrasSimilares: 'Juego de sellos mecánicos',
	},
	{
		code: '31411704',
		label: 'Sello  v – ring',
	},
	{
		code: '31411705',
		label: 'Sello de labio',
	},
	{
		code: '31411800',
		label: 'Sellos de diafragma',
	},
	{
		code: '31411801',
		label: 'Sello de diafragma soldado',
	},
	{
		code: '31411802',
		label: 'Sello de diafragma de abrazadera',
	},
	{
		code: '31411803',
		label: 'Sello de diafragma de cápsula',
	},
	{
		code: '31411804',
		label: 'Sello de diafragma de caucho',
	},
	{
		code: '31411805',
		label: 'Sello de diafragma de fibra reforzada',
	},
	{
		code: '31411900',
		label: 'Sellos moldeados',
	},
	{
		code: '31411901',
		label: 'Sello moldeado de caucho',
	},
	{
		code: '31411902',
		label: 'Sello moldeado de plástico',
	},
	{
		code: '31411903',
		label: 'Sello moldeado de precisión',
	},
	{
		code: '31411904',
		label: 'Sello moldeado de caucho sobre metal o plástico',
	},
	{
		code: '31411905',
		label: 'Sello moldeado de silicona',
	},
	{
		code: '31421500',
		label: 'Sellos sinterizados',
	},
	{
		code: '31421501',
		label: 'Filtro sinterizado estándar de hierro cobre',
	},
	{
		code: '31421502',
		label: 'Filtro sinterizado estándar compuesto de hierro magnético suave',
	},
	{
		code: '31421503',
		label:
			'Filtro sinterizado de alta temperatura compuesto de hierro magnético suave',
	},
	{
		code: '31421504',
		label: 'Filtro sinterizado estándar de acero inoxidable',
	},
	{
		code: '31421505',
		label: 'Filtro sinterizado de alta temperatura de acero inoxidable',
	},
	{
		code: '31421506',
		label: 'Filtro sinterizado compacto estándar de acero inoxidable',
	},
	{
		code: '31421507',
		label: 'Filtro sinterizado estándar de níquel cobalto',
	},
	{
		code: '31421508',
		label: 'Filtro sinterizado de alta temperatura de níquel cobalto',
	},
	{
		code: '31421509',
		label: 'Filtro sinterizado compacto estándar de níquel cobalto',
	},
	{
		code: '31421510',
		label: 'Filtro sinterizado estándar de aluminio',
	},
	{
		code: '31421511',
		label: 'Filtro sinterizado alta temperatura de níquel cobalto',
	},
	{
		code: '31421512',
		label: 'Filtro sinterizado estándar de base de cobre',
	},
	{
		code: '31421513',
		label: 'Filtro sinterizado estándar de aleación de titanio',
	},
	{
		code: '31421514',
		label: 'Filtro sinterizado de alta temperatura de aleación de titanio',
	},
	{
		code: '31421515',
		label: 'Filtro sinterizado estándar de berilio',
	},
	{
		code: '31421516',
		label: 'Filtro sinterizado de alta temperatura de berilio',
	},
	{
		code: '31421517',
		label: 'Filtro sinterizado estándar de metal refractario',
	},
	{
		code: '31421518',
		label: 'Filtro sinterizado de alta temperatura de metal refractario',
	},
	{
		code: '31421519',
		label: 'Filtro sinterizado estándar de cerámica',
	},
	{
		code: '31421520',
		label: 'Filtro sinterizado de alta temperatura de cerámica',
	},
	{
		code: '31421521',
		label: 'Filtro sinterizado estándar de polvo de hierro puro',
	},
	{
		code: '31421522',
		label: 'Filtro sinterizado de alta temperatura de polvo de hierro puro',
	},
	{
		code: '32101500',
		label: 'Conjuntos de circuitos y componentes de radiofrecuencia (RF)',
	},
	{
		code: '32101502',
		label: 'Montajes de circuitos impresos (pca)',

		palabrasSimilares: 'Montajes de circuitos impresos en placa',
	},
	{
		code: '32101503',
		label: 'Conjuntos de circuitos mixtos',
	},
	{
		code: '32101504',
		label: 'Ensamblajes de circuitos montados en superficie',
	},
	{
		code: '32101505',
		label: 'Ensamblajes de circuitos electro plateados',
	},
	{
		code: '32101506',
		label: 'Tarjetas de circuito de doble cara',
	},
	{
		code: '32101507',
		label: 'Tarjetas de tablero de conectores de circuitos',
	},
	{
		code: '32101508',
		label: 'Tarjetas de circuito multi capa',
	},
	{
		code: '32101509',
		label: 'Tarjetas de circuito de una cara',
	},
	{
		code: '32101510',
		label: 'Tablero de cables impreso',
	},
	{
		code: '32101512',
		label: 'Demoduladores',
	},
	{
		code: '32101513',
		label: 'Conjuntos de circuitos de aplicaciones específicas',
	},
	{
		code: '32101514',
		label: 'Amplificadores',
	},
	{
		code: '32101515',
		label: 'Atenuadores',
	},
	{
		code: '32101516',
		label: 'Circuladores',
	},
	{
		code: '32101517',
		label: 'Acopladores',
	},
	{
		code: '32101518',
		label: 'Líneas de retardo',
	},
	{
		code: '32101519',
		label: 'Detectores',
	},
	{
		code: '32101520',
		label: 'Cargas simuladas',
	},
	{
		code: '32101521',
		label: 'Filtros de radiofrecuencia (rf)',
	},
	{
		code: '32101522',
		label: 'Aisladores',
	},
	{
		code: '32101523',
		label: 'Mezcladores',
	},
	{
		code: '32101524',
		label: 'Variadores de fase',
	},
	{
		code: '32101525',
		label: 'Multiplexores',
	},
	{
		code: '32101526',
		label: 'Amplificadores de tubos de onda',
	},
	{
		code: '32101527',
		label: 'Terminaciones',
	},
	{
		code: '32101528',
		label: 'Moduladores',
	},
	{
		code: '32101529',
		label: 'Divisores o splitters',
	},
	{
		code: '32101530',
		label: 'Combinado de radio frecuencia rf',

		palabrasSimilares: 'Combinado de radiofrecuencia (RF)',
	},
	{
		code: '32101531',
		label: 'Pad atenuador de red',
	},
	{
		code: '32101532',
		label: 'Sustrato cerámico',
	},
	{
		code: '32101533',
		label: 'Transformador de salid',
	},
	{
		code: '32101534',
		label: 'Trampa de ondas',
	},
	{
		code: '32101535',
		label: 'Circuito de fase modulada',
	},
	{
		code: '32101536',
		label: 'Compensador de desbalance de fase y factor de potencia',
	},
	{
		code: '32101537',
		label: 'Repetidor de sincro',
	},
	{
		code: '32101538',
		label: 'Estabilizador de impedancia',
	},
	{
		code: '32101539',
		label: 'Selector de frecuencia',
	},
	{
		code: '32101540',
		label: 'Resolvedor',
	},
	{
		code: '32101541',
		label: 'Transmisor de sincro',
	},
	{
		code: '32101542',
		label: 'Receptor de sincro',
	},
	{
		code: '32101543',
		label: 'Controlador e indicador de calibre',
	},
	{
		code: '32101544',
		label: 'Oscilador de frecuencia',
	},
	{
		code: '32101545',
		label: 'Bobina de antena',
	},
	{
		code: '32101546',
		label: 'Multiplicador de frecuencia',
	},
	{
		code: '32101547',
		label: 'Limitador',
	},
	{
		code: '32101548',
		label: 'Discriminador',
	},
	{
		code: '32101600',
		label: 'Circuitos integrados',
	},
	{
		code: '32101601',
		label: 'Memoria de acceso aleatorio (ram)',
	},
	{
		code: '32101602',
		label: 'Memoria ram dinámica (dram)',

		palabrasSimilares: 'Memoria dinámica de acceso aleatorio (DRAM)',
	},
	{
		code: '32101603',
		label: 'Memoria ram estática (sram)',

		palabrasSimilares: 'Memoria estática de acceso aleatorio (SRAM)',
	},
	{
		code: '32101604',
		label: 'Memoria rom programable (prom)',

		palabrasSimilares: 'Memoria de lectura programable (PROM)',
	},
	{
		code: '32101605',
		label: 'Memoria rom programable borrable (eprom)',

		palabrasSimilares: 'Memoria de sólo lectura programable borrable (EPROM)',
	},
	{
		code: '32101606',
		label: 'Memoria rom programable borrable eléctricamente (eeprom)',

		palabrasSimilares:
			'Memoria de sólo lectura programable borrable electrónicamente (EEPROM)',
	},
	{
		code: '32101607',
		label: 'Circuitos integrados monolíticos de memoria (mmic)',

		palabrasSimilares: 'Circuitos integrados de memoria monolítica (MMIC)',
	},
	{
		code: '32101608',
		label: 'Memoria de sólo lectura (rom)',

		palabrasSimilares: 'Memoria sólo de lectura (ROM)',
	},
	{
		code: '32101609',
		label: 'Circuitos integrados de aplicaciones específicas (asic)',

		palabrasSimilares:
			'Circuitos integrados específicos de la aplicación (ASIC)',
	},
	{
		code: '32101611',
		label: 'Lógica de matriz programable (pal)',
	},
	{
		code: '32101612',
		label: 'Lógica de matriz de puertas (gal)',
	},
	{
		code: '32101613',
		label: 'Lógica transistor-transistor (ttl)',
	},
	{
		code: '32101614',
		label: 'Lógica de emisor acoplado (ecl)',
	},
	{
		code: '32101615',
		label: 'Tecnología mos bipolar (bimos)',

		palabrasSimilares:
			'Tecnología de semiconductores de óxido metálico o bipolar (BIMOS)',
	},
	{
		code: '32101616',
		label: 'Tecnología cmos bipolar (bicmos)',

		palabrasSimilares:
			'Tecnología de semiconductores de óxido metálico complementario bipolar (BICMOS)',
	},
	{
		code: '32101617',
		label: 'Tarjetas inteligentes',
	},
	{
		code: '32101618',
		label: 'Circuitos integrados ordenados hacia arriba',
	},
	{
		code: '32101619',
		label: 'Circuitos integrados lineales',
	},
	{
		code: '32101620',
		label: 'Circuitos integrados digitales',
	},
	{
		code: '32101621',
		label: 'Memoria dram síncrona (sdram)',

		palabrasSimilares: 'Memoria síncrona dinámica de acceso aleatorio (SDRAM)',
	},
	{
		code: '32101622',
		label: 'Memoria flash',

		palabrasSimilares: 'Tarjeta de memoria para celular',
	},
	{
		code: '32101623',
		label: 'Memoria rdram (rambus)',

		palabrasSimilares: 'Memoria de acceso aleatorio dinámica rambus (RDRAM)',
	},
	{
		code: '32101624',
		label: 'Memoria sgram',
	},
	{
		code: '32101625',
		label: 'Circuitos integrados de accionamiento o control por motor',
	},
	{
		code: '32101626',
		label: 'Microprocesadores',
	},
	{
		code: '32101627',
		label: 'Osciladores de reloj',
	},
	{
		code: '32101628',
		label: 'Microcontroladores',
	},
	{
		code: '32101629',
		label: 'Amplificadores operacionales',
	},
	{
		code: '32101630',
		label: 'Circuitos integrados de regulador de tensión',
	},
	{
		code: '32101631',
		label: 'Circuitos integrados de comparador de tensión',
	},
	{
		code: '32101632',
		label: 'Circuitos integrados de temporizador',
	},
	{
		code: '32101633',
		label: 'Puertas lógicas',
	},
	{
		code: '32101634',
		label: 'Circuitos basculantes',
	},
	{
		code: '32101635',
		label: 'Registros de desplazamiento',
	},
	{
		code: '32101636',
		label: 'Procesador de señal digital (dsp)',
	},
	{
		code: '32101637',
		label: 'Procesadores de red',
	},
	{
		code: '32101638',
		label: 'Circuito integrado de aplicación específica',
	},
	{
		code: '32101639',
		label: 'Microcontrolador de 8 bits',
	},
	{
		code: '32101640',
		label: 'Microcontrolador de 16 bits',
	},
	{
		code: '32101641',
		label: 'Procesador de señal digital de extremo bajo dsp',
	},
	{
		code: '32101642',
		label: 'Microcontrolador de rango medio de 32 bits',
	},
	{
		code: '32101643',
		label: 'Circuito integrado lógico programable',
	},
	{
		code: '32101644',
		label: 'Procesador de señal digital de extremo alto dsp',
	},
	{
		code: '32101645',
		label: 'Microcontrolador de rango alto de 32 bits',
	},
	{
		code: '32101646',
		label: 'Memoria flash paralelo (nor)',

		palabrasSimilares: 'Memoria flash paralela (NOR)',
	},
	{
		code: '32101647',
		label: 'Memoria flash serial (nor)',
	},
	{
		code: '32101648',
		label: 'Memoria flash (nand)',
	},
	{
		code: '32101649',
		label: 'Circuito integrado estándar análogo o lineal',
	},
	{
		code: '32101650',
		label: 'Circuito integrado de amplificador de audio análogo',
	},
	{
		code: '32101651',
		label: 'Circuito integrado de amplificador de audio digital',
	},
	{
		code: '32101652',
		label: 'Circuito integrado lógico estándar',
	},
	{
		code: '32101653',
		label: 'Circuito integrado microondas',
	},
	{
		code: '32101654',
		label: 'Circuito integrado bus transceptor',
	},
	{
		code: '32101655',
		label: 'Circuito integrado de servicio de radio audio digital satelital',
	},
	{
		code: '32101656',
		label: 'Circuito integrado de sistema de posicionamiento geográfico gps',
	},
	{
		code: '32101657',
		label: 'Circuito integrado de sintonizador',
	},
	{
		code: '32101658',
		label: 'Circuito integrado de acelerador de gráficos',
	},
	{
		code: '32101659',
		label: 'Circuito integrado de exhibición de drivers',
	},
	{
		code: '32101660',
		label: 'Circuito integrado de sensor de tasa angular',
	},
	{
		code: '32101661',
		label: 'Circuito integrado acelerómetro',
	},
	{
		code: '32101662',
		label: 'Circuito integrado efecto hall',
	},
	{
		code: '32101663',
		label: 'Circuito integrado sensor temperatura infrarroja ir',

		palabrasSimilares:
			'Circuito integrado del sensor infrarrojo de la temperatura del IR',
	},
	{
		code: '32101664',
		label: 'Circuito integrado ultrasónico',
	},
	{
		code: '32101665',
		label: 'Circuito integrado de sensor de imagen',
	},
	{
		code: '32101666',
		label: 'Circuito integrado de procesador de videos o media',
	},
	{
		code: '32101667',
		label: 'Circuito integrado de procesador de visión',
	},
	{
		code: '32101668',
		label: 'Circuito integrado condecs',

		palabrasSimilares: 'Circuito integrado de códec',
	},
	{
		code: '32101669',
		label: 'Circuito integrado de protocolo de comunicación',
	},
	{
		code: '32101670',
		label: 'Circuito integrado de transponedor',
	},
	{
		code: '32101671',
		label: 'Circuito integrado de sensor de presión',
	},
	{
		code: '32101672',
		label: 'Bastidor de conductores',
	},
	{
		code: '32111500',
		label: 'Diodos',
	},
	{
		code: '32111501',
		label: 'Diodos de microondas',
	},
	{
		code: '32111502',
		label: 'Diodos zener',
	},
	{
		code: '32111503',
		label: 'Diodos emisores de luz (led)',
	},
	{
		code: '32111504',
		label: 'Diodos schottky',
	},
	{
		code: '32111505',
		label: 'Diodos con efecto túnel',
	},
	{
		code: '32111506',
		label: 'Diodos fotosensibles',
	},
	{
		code: '32111507',
		label: 'Diodos de capacitancia variable',
	},
	{
		code: '32111508',
		label: 'Diodos solares',
	},
	{
		code: '32111509',
		label: 'Diodos de energía',
	},
	{
		code: '32111510',
		label: 'Diodos de radiofrecuencia (rf)',
	},
	{
		code: '32111511',
		label: 'Diodos de señal pequeña',
	},
	{
		code: '32111512',
		label: 'Diodo láser',
	},
	{
		code: '32111513',
		label: 'Diodo varactor',
	},
	{
		code: '32111514',
		label: 'Diodo pin',
	},
	{
		code: '32111515',
		label: 'Diodo óptico',
	},
	{
		code: '32111600',
		label: 'Transistores',
	},
	{
		code: '32111601',
		label: 'Transistores fotosensibles',
	},
	{
		code: '32111602',
		label: 'Transistor de efecto de campo (fet)',
	},
	{
		code: '32111603',
		label: 'Transistores mos de efecto de campo (mosfet)',

		palabrasSimilares:
			'Transistores de efecto de campo de silicona de óxido metálico (MOSFET)',
	},
	{
		code: '32111604',
		label: 'Chips de transistor',
	},
	{
		code: '32111607',
		label: 'Transistores bipolares de radiofrecuencia (rf) o darlington',
	},
	{
		code: '32111608',
		label: 'Transistores uniempalme',
	},
	{
		code: '32111609',
		label: 'Transistores bipolares de puerta aislada (igbt)',
	},
	{
		code: '32111610',
		label: 'Transistores de efecto de campo de unión (jfet)',
	},
	{
		code: '32111611',
		label: 'Transistores bipolares de unión (bjt)',
	},
	{
		code: '32111612',
		label: 'Transistor de efecto de campo de potencia',
	},
	{
		code: '32111613',
		label: 'Transistor bipolar o de radio frecuencia bipolar',
	},
	{
		code: '32111614',
		label: 'Transistor de efecto de campo inteligente',
	},
	{
		code: '32111615',
		label: 'Transistor de efecto de campo de señal pequeña',
	},
	{
		code: '32111616',
		label: 'Transistor de efecto de campo de radio frecuencia',
	},
	{
		code: '32111700',
		label: 'Aparatos semiconductores',
	},
	{
		code: '32111701',
		label: 'Células fotovoltaicas',
	},
	{
		code: '32111702',
		label: 'Tiristores',
	},
	{
		code: '32111703',
		label: 'Diacs',

		palabrasSimilares: 'Diodo para corriente alterna',
	},
	{
		code: '32111704',
		label: 'Triacs',

		palabrasSimilares: 'Tríodo para corriente alterna',
	},
	{
		code: '32111705',
		label: 'Aisladores acoplados ópticos',
	},
	{
		code: '32111706',
		label: 'Osciladores a cristal',
	},
	{
		code: '32111707',
		label: 'Suspector de semiconductor',
	},
	{
		code: '32111708',
		label: 'Red de adaptación de impedancia',
	},
	{
		code: '32111709',
		label: 'Red de compensación de temperatura',
	},
	{
		code: '32121500',
		label: 'Capacitores',
	},
	{
		code: '32121501',
		label: 'Capacitores fijos',
	},
	{
		code: '32121502',
		label: 'Capacitores o varactores variables',
	},
	{
		code: '32121503',
		label: 'Capacitores ajustables pre - ajustados',
	},
	{
		code: '32121504',
		label: 'Redes de capacitores',
	},
	{
		code: '32121505',
		label: 'Capacitor fijo de aluminio electrolítico',
	},
	{
		code: '32121506',
		label: 'Capacitor cerámico fijo',
	},
	{
		code: '32121507',
		label: 'Capacitor de película fijo',
	},
	{
		code: '32121508',
		label: 'Capacitor fijo de tántalo',
	},
	{
		code: '32121509',
		label: 'Capacitor de aire',
	},
	{
		code: '32121510',
		label: 'Capacitor de gas',
	},
	{
		code: '32121511',
		label: 'Capacitor de aceite',
	},
	{
		code: '32121512',
		label: 'Capacitor de mica',
	},
	{
		code: '32121513',
		label: 'Capacitor de vacío',
	},
	{
		code: '32121514',
		label: 'Capacitor de papel',
	},
	{
		code: '32121515',
		label: 'Capacitor de papel metalizado',
	},
	{
		code: '32121600',
		label: 'Resistores',
	},
	{
		code: '32121602',
		label: 'Resistores fusibles',
	},
	{
		code: '32121603',
		label: 'Resistores o varistores variables',
	},
	{
		code: '32121607',
		label: 'Redes de resistores',
	},
	{
		code: '32121609',
		label: 'Resistores fijos',
	},
	{
		code: '32121610',
		label: 'Termistor',
	},
	{
		code: '32121611',
		label: 'Placa restrictora o restrictor de aire',
	},
	{
		code: '32121612',
		label: 'Resistor montado en superficie',
	},
	{
		code: '32121613',
		label: 'Resistor embobinado',

		palabrasSimilares: 'Resistencia bobinada',
	},
	{
		code: '32121614',
		label: 'Resistor de óxido de película de metal',
	},
	{
		code: '32121615',
		label: 'Resistor de película de carbono',
	},
	{
		code: '32121616',
		label: 'Resistor recortador',
	},
	{
		code: '32121617',
		label: 'Resistor de potencia',
	},
	{
		code: '32121618',
		label: 'Resistor de película de metal',
	},
	{
		code: '32121700',
		label: 'Componentes discretos',
	},
	{
		code: '32121701',
		label: 'Rectificadores',
	},
	{
		code: '32121702',
		label: 'Inductores',
	},
	{
		code: '32121703',
		label: 'Ferritas',
	},
	{
		code: '32121704',
		label: 'Convertidores estáticos',
	},
	{
		code: '32121705',
		label: 'Inversores',
	},
	{
		code: '32121706',
		label: 'Redes r/c de resistores o capacitores',
	},
	{
		code: '32121707',
		label: 'Rectificador controlado de silicona',
	},
	{
		code: '32121708',
		label: 'Rectificador de puente',
	},
	{
		code: '32121709',
		label: 'Inductor de embobinado',
	},
	{
		code: '32121710',
		label: 'Inductor multi capa',
	},
	{
		code: '32121711',
		label: 'Inductor de estrangulador',
	},
	{
		code: '32121712',
		label: 'Bobina de alta frecuencia',
	},
	{
		code: '32121800',
		label: 'Filtros de señales',
	},
	{
		code: '32121801',
		label: 'Filtro de onda acústica de superficie saw',
	},
	{
		code: '32121802',
		label: 'Filtro cerámico',
	},
	{
		code: '32121803',
		label: 'Filtro de microondas',
	},
	{
		code: '32121804',
		label: 'Filtro de cristal',
	},
	{
		code: '32121900',
		label: 'Resonadores',
	},
	{
		code: '32121901',
		label: 'Resonador de  onda acústica de superficie saw',
	},
	{
		code: '32121902',
		label: 'Resonador cerámico',
	},
	{
		code: '32131000',
		label: 'Accesorios, materias primas y piezas de componentes electrónicos',
	},
	{
		code: '32131001',
		label: 'Piletas térmicas',
	},
	{
		code: '32131002',
		label: 'Matrices de semiconductor',
	},
	{
		code: '32131003',
		label: 'Pastillas de semiconductor',
	},
	{
		code: '32131005',
		label: 'Paquetes de circuitos integrados',
	},
	{
		code: '32131006',
		label: 'Soportes o zócalos de circuito integrado',
	},
	{
		code: '32131007',
		label: 'Soportes de componentes discretos',
	},
	{
		code: '32131008',
		label: 'Compuestos disipadores de calor',
	},
	{
		code: '32131009',
		label: 'Aisladores para disipadores de calor',
	},
	{
		code: '32131010',
		label: 'Tarjetas sencillas de circuitos impresos',
	},
	{
		code: '32131011',
		label: 'Cubiertas de circuitos integrados',
	},
	{
		code: '32131012',
		label: 'Objetivos de erosión superficial',
	},
	{
		code: '32131013',
		label: 'Máscara de foto',
	},
	{
		code: '32131014',
		label: 'Tablero de circuito impreso flexible, desnudo',
	},
	{
		code: '32131015',
		label: 'Tablero de circuito impreso por un solo lado, desnudo',
	},
	{
		code: '32131016',
		label: 'Tablero de circuito impreso por un ambos lados, desnudo',
	},
	{
		code: '32131017',
		label: 'Tablero de circuito impreso multicapa, desnudo',
	},
	{
		code: '32131018',
		label: 'Tablero de circuito impreso con capa pesada de bronce, desnudo',
	},
	{
		code: '32131019',
		label: 'Tablero de circuito impreso electroplateado con oro, desnudo',
	},
	{
		code: '32131020',
		label: 'Tablero de circuito impreso ensamblado flexible',
	},
	{
		code: '32131021',
		label: 'Sustrato cerámico combustión combinada a baja temperatura',
	},
	{
		code: '32131022',
		label: 'Cerámica para sustratos de tableros de circuitos impresos pcb',

		palabrasSimilares: 'Cerámica para placas de circuitos impresos (PCB)',
	},
	{
		code: '32131023',
		label: 'Soporte de dispositivos eléctricos o electrónicos',
	},
	{
		code: '32141000',
		label: 'Tubos electrónicos',
	},
	{
		code: '32141001',
		label: 'Tubos de rayos catódicos',
	},
	{
		code: '32141002',
		label: 'Klistrones',

		palabrasSimilares: 'Válvula de vacío',
	},
	{
		code: '32141003',
		label: 'Magnetrones',
	},
	{
		code: '32141004',
		label: 'Tubos de ondas progresivas',
	},
	{
		code: '32141005',
		label: 'Tubos de disco sellado',
	},
	{
		code: '32141006',
		label: 'Resnatrones',

		palabrasSimilares: 'Resnatron',
	},
	{
		code: '32141007',
		label: 'Tiratrones',
	},
	{
		code: '32141008',
		label: 'Ignitrones',
	},
	{
		code: '32141009',
		label: 'Tubos fotoeléctricos',
	},
	{
		code: '32141010',
		label: 'Tubos fotomultiplicadores',
	},
	{
		code: '32141011',
		label: 'Tubos receptores de televisión o cámara',
	},
	{
		code: '32141012',
		label: 'Tubo de diodo',
	},
	{
		code: '32141013',
		label: 'Tubo de tríodo',
	},
	{
		code: '32141014',
		label: 'Tubos de tetrodo',
	},
	{
		code: '32141015',
		label: 'Tubos de pentodo',
	},
	{
		code: '32141016',
		label: 'Tubos múltiples',
	},
	{
		code: '32141017',
		label: 'Tubo contador',
	},
	{
		code: '32141018',
		label: 'Tubo de rayo de salida',
	},
	{
		code: '32141019',
		label: 'Tubo electrón de microondas',
	},
	{
		code: '32141020',
		label: 'Tubo convertidor de frecuencia',
	},
	{
		code: '32141021',
		label: 'Tubo rectificador',
	},
	{
		code: '32141022',
		label: 'Tubo regulador electrónico de voltaje',
	},
	{
		code: '32141100',
		label: 'Piezas y accesorios de tubos electrónicos',
	},
	{
		code: '32141101',
		label: 'Cátodos o emisores',
	},
	{
		code: '32141102',
		label: 'Elementos de ánodo',
	},
	{
		code: '32141103',
		label: 'Elementos de rejilla',
	},
	{
		code: '32141104',
		label: 'Elementos deflectores',
	},
	{
		code: '32141105',
		label: 'Obturadores o fundas de tubo',
	},
	{
		code: '32141106',
		label: 'Bases de tubo',
	},
	{
		code: '32141107',
		label: 'Zócalos de tubo',
	},
	{
		code: '32141108',
		label: 'Clavijas de electrodo',
	},
	{
		code: '32141109',
		label: 'Portaelectrodos',
	},
	{
		code: '32141110',
		label: 'Pieza de polo magnético',
	},
	{
		code: '32151500',
		label: 'Dispositivos de control de indicación y de señalización',
	},
	{
		code: '32151501',
		label: 'Módulo de sonido de control',
	},
	{
		code: '32151502',
		label: 'Módulo de luz',
	},
	{
		code: '32151503',
		label: 'Apilamiento de luz',
	},
	{
		code: '32151504',
		label: 'Ecualizador de circuito electrónico',
	},
	{
		code: '32151600',
		label: 'Subsistemas programables de controlador lógico',
	},
	{
		code: '32151601',
		label: 'Subsistema i/o chasis controlador lógico programable',
	},
	{
		code: '32151602',
		label:
			'Subsistema i/o chasis controlador lógico programable distribuido en gabinete',
	},
	{
		code: '32151603',
		label:
			'Subsistema i/o chasis controlador lógico programable distribuido en la máquina',
	},
	{
		code: '32151700',
		label: 'Controladores lógicos programables',
	},
	{
		code: '32151701',
		label: 'Dispositivo de unión control de red',
	},
	{
		code: '32151702',
		label: 'Interfaz pc red control',
	},
	{
		code: '32151703',
		label: 'Accesorios de controlador lógico programable',
	},
	{
		code: '32151704',
		label: 'Chasis de controlador lógico programable',
	},
	{
		code: '32151705',
		label: 'Módulo de controlador lógico programable',
	},
	{
		code: '32151706',
		label: 'Suministro de energía de controlador lógico programable',
	},
	{
		code: '32151707',
		label: 'Dispositivo de programación del controlador lógico programable',
	},
	{
		code: '32151800',
		label: 'Dispositivos de control de seguridad',
	},
	{
		code: '32151801',
		label: 'Interruptor de carga iec',
	},
	{
		code: '32151802',
		label: 'Módulo de control de seguridad',
	},
	{
		code: '32151803',
		label: 'Sistema de aislación de seguridad',
	},
	{
		code: '32151804',
		label: 'Cortina y scanner de luz de seguridad',

		palabrasSimilares: 'Escáner y barrera de seguridad',
	},
	{
		code: '32151805',
		label: 'Estera (mat) y borde de seguridad',
	},
	{
		code: '32151900',
		label: 'Dispositivos de automatización de control de la conectividad',
	},
	{
		code: '32151901',
		label: 'Conector de paso de mamparo',
	},
	{
		code: '32151902',
		label: 'Conjunto de cables del sistema de control',
	},
	{
		code: '32151903',
		label: 'Caja de distribución del sistema de control',
	},
	{
		code: '32151904',
		label: 'Cable de conexión del sistema de control',
	},
	{
		code: '32151905',
		label: 'Receptáculo del sistema de control',
	},
	{
		code: '32151906',
		label: 'Splitter del sistema de control',
	},
	{
		code: '32151907',
		label: 'Cable en y del sistema de control',
	},
	{
		code: '32151908',
		label: 'Cableado del sistema de control',
	},
	{
		code: '32151909',
		label: 'Conector desmontable de campo',
	},
	{
		code: '32151910',
		label: 'Sistema de control del panel de paso',

		palabrasSimilares: 'Sistema de conexión de control de panel',
	},
	{
		code: '32152000',
		label: 'Control de procesos o sistemas empaquetados de automatización',
	},
	{
		code: '32152001',
		label: 'Sistema empaquetado de control distribuido dcs',
	},
	{
		code: '32152002',
		label:
			'Sistema empaquetado de control de supervisión y adquisición de datos scada',
	},
	{
		code: '32152003',
		label: 'Sistema empaquetado de seguridad instrumentada sis',

		palabrasSimilares: 'Sistema de embalaje instrumentado de seguridad (SIS)',
	},
	{
		code: '39101600',
		label: 'Lámparas y bombillas',

		palabrasSimilares: 'Lámparas y focos',
	},
	{
		code: '39101601',
		label: 'Lámparas halógenas',

		palabrasSimilares: 'Focos halógenos',
	},
	{
		code: '39101602',
		label: 'Lámparas médicas',
	},
	{
		code: '39101603',
		label: 'Lámparas solares',
	},
	{
		code: '39101605',
		label: 'Lámparas fluorescentes',

		palabrasSimilares: 'Focos fluorescentes',
	},
	{
		code: '39101608',
		label: 'Luz asómbrica o scialytica de operación',

		palabrasSimilares: 'Lámpara de operación asómbrica o cialítica',
	},
	{
		code: '39101609',
		label: 'Lámparas de escenario o estudio',
	},
	{
		code: '39101612',
		label: 'Lámparas incandescentes',

		palabrasSimilares: 'Focos incandescentes',
	},
	{
		code: '39101613',
		label: 'Lámparas infrarrojas',
	},
	{
		code: '39101614',
		label: 'Lámparas de haluro- metálico',
	},
	{
		code: '39101615',
		label: 'Lámparas de vapor de mercurio',
	},
	{
		code: '39101616',
		label: 'Lámparas de rayos ultravioleta (uv)',
	},
	{
		code: '39101617',
		label: 'Lámparas de sodio de alta presión hid',
	},
	{
		code: '39101618',
		label: 'Lámparas de neón',

		palabrasSimilares: 'Focos de neón',
	},
	{
		code: '39101619',
		label: 'Lámparas compactas fluorescentes cfl',
	},
	{
		code: '39101620',
		label: 'Lámparas de inducción',
	},
	{
		code: '39101621',
		label: 'Lámparas de sodio de baja presión hid',
	},
	{
		code: '39101622',
		label: 'Lámparas en miniatura',
	},
	{
		code: '39101623',
		label: 'Lámparas de rayo sellado',
	},
	{
		code: '39101624',
		label: 'Luz negra',
	},
	{
		code: '39101625',
		label: 'Lámparas de xenón',
	},
	{
		code: '39101626',
		label: 'Lámparas de criptón',
	},
	{
		code: '39101627',
		label: 'Lámparas de arco',
	},
	{
		code: '39101800',
		label: 'Componentes y accesorios de Lámpara',
	},
	{
		code: '39101801',
		label: 'Filamento de lámpara',
	},
	{
		code: '39101802',
		label: 'Vidrio de lámpara',
	},
	{
		code: '39101803',
		label: 'Base de lámpara',

		palabrasSimilares: 'Socket para foco',
	},
	{
		code: '39101804',
		label: 'Ignitor de lámpara de sodio de alta presión',
	},
	{
		code: '39101805',
		label: 'Capacitor seco de haluro de metal',
	},
	{
		code: '39101806',
		label: 'Capacitor seco de lámpara de sodio de alta presión',
	},
	{
		code: '39101900',
		label: 'Balastos de lámparas y transformadores de lámparas',
	},
	{
		code: '39101901',
		label: 'Balasto fluorescente',
	},
	{
		code: '39101902',
		label: 'Balasto de alta intensidad de descarga hid',
	},
	{
		code: '39101903',
		label: 'Sistema de inducción de iluminación',
	},
	{
		code: '39101904',
		label: 'Transformador de iluminación de bajo voltaje',
	},
	{
		code: '39101905',
		label: 'Balasto de neón',

		palabrasSimilares: 'Balastro de neón',
	},
	{
		code: '39101906',
		label: 'Transformador de lámpara reductora',
	},
	{
		code: '39101907',
		label: 'Balasto de alta intensidad de descarga  electrónica ehid',

		palabrasSimilares:
			'Balastro de electrónico de alta intensidad descarga EHID',
	},
	{
		code: '39111500',
		label: 'Iluminación de interiores y artefactos',
	},
	{
		code: '39111501',
		label: 'Artefactos fluorescentes',
	},
	{
		code: '39111503',
		label: 'Dispositivos de pared',
	},
	{
		code: '39111504',
		label: 'Sistemas de iluminación de escenario o estudio',
	},
	{
		code: '39111505',
		label: 'Iluminación empotrada',
	},
	{
		code: '39111506',
		label: 'Arañas de luces',

		palabrasSimilares: 'Candelabros',
	},
	{
		code: '39111507',
		label: 'Artefactos de escritorio',
	},
	{
		code: '39111508',
		label: 'Alumbrado de pista',
	},
	{
		code: '39111509',
		label: 'Lámparas de pie',
	},
	{
		code: '39111510',
		label: 'Lámparas de mesa',
	},
	{
		code: '39111512',
		label: 'Luces de banco de laboratorio',
	},
	{
		code: '39111515',
		label: 'Artefactos para lámpara proyectada hacia abajo',
	},
	{
		code: '39111520',
		label: 'Artefactos de alumbrado halógeno',
	},
	{
		code: '39111521',
		label: 'Plafones',
	},
	{
		code: '39111522',
		label: 'Iluminación colgante',
	},
	{
		code: '39111524',
		label: 'Accesorio de alta intensidad de descarga hid',
	},
	{
		code: '39111525',
		label: 'Accesorio incandescente',
	},
	{
		code: '39111527',
		label: 'Accesorio de iluminación solar',
	},
	{
		code: '39111528',
		label: 'Accesorio para debajo de gabinete',
	},
	{
		code: '39111529',
		label: 'Gobo de iluminación',
	},
	{
		code: '39111530',
		label: 'Accesorio para lámpara proyectada hacia abajo',
	},
	{
		code: '39111531',
		label: 'Orientación para iluminación comercial',
	},
	{
		code: '39111532',
		label: 'Accesorio fluorescente para grandes áreas',
	},
	{
		code: '39111533',
		label: 'Banda de iluminación',

		palabrasSimilares: 'Tira fluorescente',
	},
	{
		code: '39111534',
		label: 'Luminaria fluorescente con lente',
	},
	{
		code: '39111535',
		label: 'Accesorio parabólico',
	},
	{
		code: '39111536',
		label: 'Iluminador de tocador',

		palabrasSimilares: 'Iluminación de vanidad',
	},
	{
		code: '39111537',
		label: 'Dispositivo para iluminaciones deportivas',
	},
	{
		code: '39111538',
		label: 'Dispositivo para iluminaciones de garaje o marquesina',
	},
	{
		code: '39111539',
		label: 'Caja de iluminación',
	},
	{
		code: '39111540',
		label: 'Sistema de iluminación de auto – elevación',
	},
	{
		code: '39111541',
		label: 'Accesorio de dispositivo de iluminación interior',
	},
	{
		code: '39111600',
		label: 'Iluminación exterior y artefactos',
	},
	{
		code: '39111603',
		label: 'Alumbrado de la vía pública',
	},
	{
		code: '39111605',
		label: 'Iluminación paisajística',
	},
	{
		code: '39111606',
		label: 'Alumbrado submarino',
	},
	{
		code: '39111608',
		label: 'Alumbrado de zonas residenciales',
	},
	{
		code: '39111609',
		label: 'Linternas de queroseno, propano o butano',

		palabrasSimilares: 'Lámpara de queroseno, propano o butano',
	},
	{
		code: '39111610',
		label: 'Linternas',
	},
	{
		code: '39111611',
		label: 'Reflectores',
	},
	{
		code: '39111612',
		label: 'Iluminación de áreas',
	},
	{
		code: '39111613',
		label: 'Iluminación de seguridad',
	},
	{
		code: '39111614',
		label: 'Dispositivo de iluminación de buzones',
	},
	{
		code: '39111615',
		label: 'Luces exteriores para iluminación puntual instaladas en el suelo',
	},
	{
		code: '39111616',
		label: 'Accesorios de iluminación exterior',
	},
	{
		code: '39111700',
		label: 'Alumbrado de emergencia',
	},
	{
		code: '39111703',
		label: 'Luces de tormenta',
	},
	{
		code: '39111705',
		label: 'Barras fluorescentes o de iluminación',
	},
	{
		code: '39111706',
		label: 'Luces de emergencia o estroboscópicas (licuadoras)',
	},
	{
		code: '39111707',
		label: 'Unidad de salida de combo de luz',
	},
	{
		code: '39111708',
		label: 'Señal iluminada de salida de emergencia',

		palabrasSimilares: 'Señal luminosa de salida de emergencia',
	},
	{
		code: '39111709',
		label: 'Unidad de luz de emergencia',
	},
	{
		code: '39111710',
		label: 'Accesorios de iluminación de emergencia',
	},
	{
		code: '39111711',
		label: 'Kit de conversión de salida de luz',
	},
	{
		code: '39111712',
		label: 'Galería de montaje de luz de salida',
	},
	{
		code: '39111713',
		label: 'Dispositivo de iluminación remota',
	},
	{
		code: '39111714',
		label: 'Bengala de señalización',
	},
	{
		code: '39111800',
		label: 'Accesorios de iluminación',
	},
	{
		code: '39111801',
		label: 'Balastos de lámparas',
	},
	{
		code: '39111802',
		label: 'Cubiertas de lámpara',
	},
	{
		code: '39111803',
		label: 'Enchufes de lámparas',
	},
	{
		code: '39111806',
		label: 'Cajas de iluminación',
	},
	{
		code: '39111808',
		label: 'Parrillas',
	},
	{
		code: '39111809',
		label: 'Filtros acondicionadores de luz',
	},
	{
		code: '39111810',
		label: 'Interruptor de lámpara',
	},
	{
		code: '39111811',
		label: 'Líneas férreas electrificadas',
	},
	{
		code: '39111812',
		label: 'Pantallas de lámparas',
	},
	{
		code: '39111813',
		label: 'Brazos de lámparas',
	},
	{
		code: '39111814',
		label: 'Kit de brida de iluminación de marco de cielorraso',

		palabrasSimilares: 'Juego de tapa de luz de marco de techo',
	},
	{
		code: '39111815',
		label: 'Protector de lámpara y de dispositivo de lámpara',
	},
	{
		code: '39111816',
		label: 'Lente de lámpara',
	},
	{
		code: '39111817',
		label: 'Accesorio para montaje de lámpara',
	},
	{
		code: '39111818',
		label: 'Reflector de lámpara',
	},
	{
		code: '39111819',
		label: 'Accesorio de seguridad de lámpara',
	},
	{
		code: '39111820',
		label: 'Kit de ensamble de cableado de lámpara',
	},
	{
		code: '39111821',
		label: 'Kit de retro – adaptación de iluminación',
	},
	{
		code: '39111822',
		label: 'Soporte de pared para iluminación',
	},
	{
		code: '39111823',
		label: 'Tapa para poste de luz',
	},
	{
		code: '39111824',
		label: 'Lente difusor de iluminación',
	},
	{
		code: '39111825',
		label: 'Lámpara con bisagras',
	},
	{
		code: '39111826',
		label: 'Juego de cables de iluminación',
	},
	{
		code: '39111827',
		label: 'Reflector o redireccionador de iluminación',
	},
	{
		code: '39111828',
		label: 'Dispositivo de puerta de iluminación',
	},
	{
		code: '39111829',
		label: 'Cubierta del canal de iluminación',
	},
	{
		code: '39111830',
		label: 'Brazo de soporte de iluminación',
	},
	{
		code: '39111900',
		label: 'Señalización e iluminación de emplazamientos peligrosos',
	},
	{
		code: '39111903',
		label: 'Iluminación de minería subterránea',
	},
	{
		code: '39111904',
		label: 'Dispositivo de ubicación marina',

		palabrasSimilares: 'Dispositivo de localización marina',
	},
	{
		code: '39111905',
		label: 'Dispositivo de iluminación de sala blanca',
	},
	{
		code: '39111906',
		label: 'Dispositivo de luz de servicio en condiciones difíciles',
	},
	{
		code: '39111907',
		label: 'Dispositivo de luz hermética de vapor',
	},
	{
		code: '39111908',
		label: 'Dispositivo de iluminación protector contra vandalismo',
	},
	{
		code: '39111909',
		label: 'Accesorio de dispositivo de iluminación de ambiente especial',
	},
	{
		code: '39112000',
		label: 'Proyectores móviles',
	},
	{
		code: '39112001',
		label: 'Torre de luz',
	},
	{
		code: '39112002',
		label: 'Carro de iluminación',
	},
	{
		code: '39112003',
		label: 'Stand de iluminación',
	},
	{
		code: '39112004',
		label: 'Luz para puertos de cargue',
	},
	{
		code: '39112005',
		label: 'Luz fluorescente portátil',
	},
	{
		code: '39112006',
		label: 'Lámpara de mano portátil',
	},
	{
		code: '39112007',
		label: 'Lámpara de mano portátil de descarga de alta intensidad hid',
	},
	{
		code: '39112008',
		label: 'Luz incandescente portátil',
	},
	{
		code: '39112009',
		label: 'Luz de mecánicos',
	},
	{
		code: '39112010',
		label: 'Luz de cuerda',
	},
	{
		code: '39112011',
		label: 'Árbol o hilo de luz',
	},
	{
		code: '39112012',
		label: 'Luz manual o extensión',
	},
	{
		code: '39112013',
		label: 'Accesorio de iluminación portátil y temporal',
	},
	{
		code: '39112100',
		label: 'Iluminación óptica',
	},
	{
		code: '39112101',
		label: 'Iluminación de fibra óptica',
	},
	{
		code: '39112102',
		label: 'Iluminación óptica de diodo emisor de luz led',
	},
	{
		code: '39112200',
		label: 'Dispositivos de efectos especiales',
	},
	{
		code: '39112201',
		label: 'Aparato de efecto atmosférico',
	},
	{
		code: '39112202',
		label: 'Máquina de burbujas',
	},
	{
		code: '39112300',
		label: 'Iluminación y accesorios de escenarios y estudios',
	},
	{
		code: '39112301',
		label: 'Cambiador de color',
	},
	{
		code: '39112302',
		label: 'Filtros de color',
	},
	{
		code: '39112303',
		label: 'Soportes y marcos de filtros',
	},
	{
		code: '39112304',
		label: 'Soportes gobo',
	},
	{
		code: '39112305',
		label: 'Accesorios de rotadores gobo y efecto de movimiento de luz',
	},
	{
		code: '39112306',
		label: 'Diafragma iris',
	},
	{
		code: '39112307',
		label: 'Barras de iluminación',
	},
	{
		code: '39112308',
		label: 'Sillas iluminadas',
	},
	{
		code: '39112309',
		label: 'Bola de disco o bola de espejos',
	},
	{
		code: '39112400',
		label:
			'Dispositivos para manejo de luces y control de escenarios y estudios',
	},
	{
		code: '39112401',
		label: 'Dimers y accesorios',
	},
	{
		code: '39112402',
		label: 'Consolas y accesorios para el control de iluminación',
	},
	{
		code: '39112403',
		label: 'Suministro de energía y unidades de control de iluminación',
	},
	{
		code: '39112500',
		label: 'Luminarias de escenarios y estudios',
	},
	{
		code: '39112501',
		label: 'Luz de foco',
	},
	{
		code: '39112502',
		label: 'Luz de seguir',
	},
	{
		code: '39112503',
		label: 'Luces móviles',
	},
	{
		code: '39112504',
		label: 'Spots de latas par y pasadores',

		palabrasSimilares: 'Lámparas parcan y pinspot',
	},
	{
		code: '39112505',
		label: 'Luminarias tipo perfil',
	},
	{
		code: '39112506',
		label: 'Bancos spot',
	},
	{
		code: '39112507',
		label: 'Luces de franjas',
	},
	{
		code: '39112508',
		label: 'Luminarias ultravioleta y de luz negra',

		palabrasSimilares: 'Lámparas ultravioleta y de luz negra',
	},
	{
		code: '39112600',
		label: 'Dispositivos de iluminación no - eléctrica',
	},
	{
		code: '39112601',
		label: 'Lámpara de alcohol',
	},
	{
		code: '39112602',
		label: 'Candelero',
	},
	{
		code: '39112603',
		label: 'Lámpara de kerosene o de propano o de gas natural o de butano',
	},
	{
		code: '39112604',
		label: 'Velas de cera',
	},
	{
		code: '39121000',
		label: 'Equipamiento para distribución y conversión de alimentación',
	},
	{
		code: '39121001',
		label: 'Transformadores de distribución de potencia',
	},
	{
		code: '39121002',
		label: 'Transformadores de suministro de potencia',
	},
	{
		code: '39121003',
		label: 'Transformadores de instrumentos',
	},
	{
		code: '39121004',
		label: 'Unidades de suministro de energía',

		palabrasSimilares: 'Fuente de poder',
	},
	{
		code: '39121006',
		label: 'Adaptadores o inversores de potencia',
	},
	{
		code: '39121007',
		label: 'Conversores de frecuencia',
	},
	{
		code: '39121008',
		label: 'Conversores de señales',
	},
	{
		code: '39121009',
		label: 'Reguladores eléctricos o de potencia',
	},
	{
		code: '39121010',
		label: 'Bobinas magnéticas',
	},
	{
		code: '39121011',
		label: 'Fuentes ininterrumpibles de potencia',
	},
	{
		code: '39121012',
		label: 'Estrangulador',
	},
	{
		code: '39121013',
		label: 'Convertidores rotativos eléctricos',
	},
	{
		code: '39121014',
		label: 'Bancos de capacitores',
	},
	{
		code: '39121015',
		label: 'Reactores',
	},
	{
		code: '39121016',
		label: 'Anillos colectores',
	},
	{
		code: '39121017',
		label: 'Unidades de distribución de alimentación (pdus)',

		palabrasSimilares: 'Unidades de distribución de energía (PDU)',
	},
	{
		code: '39121018',
		label: 'Barreras de seguridad intrínseca',
	},
	{
		code: '39121019',
		label: 'Dispositivos de acoplamiento por inducción',
	},
	{
		code: '39121020',
		label: 'Acondicionadores de señal',
	},
	{
		code: '39121021',
		label: 'Unidades servo',
	},
	{
		code: '39121022',
		label: 'Transformadores electrónicos',
	},
	{
		code: '39121023',
		label: 'Compensadores var estática',
	},
	{
		code: '39121024',
		label: 'Condensadores síncronos',
	},
	{
		code: '39121025',
		label: 'Transformadores buck – boost',
	},
	{
		code: '39121026',
		label: 'Transformadores de control de potencia',
	},
	{
		code: '39121027',
		label: 'Transformador encapsulado',
	},
	{
		code: '39121028',
		label: 'Transformador de mitigación armónica',
	},
	{
		code: '39121029',
		label: 'Transformador de aislación',
	},
	{
		code: '39121030',
		label: 'Transformador montado en pad',
	},
	{
		code: '39121031',
		label: 'Strip de salida del suministro eléctrico',
	},
	{
		code: '39121032',
		label: 'Transformador de corriente',
	},
	{
		code: '39121033',
		label: 'Transformador potencial',
	},
	{
		code: '39121034',
		label: 'Transformador de corriente de fase cero',
	},
	{
		code: '39121035',
		label: 'Compensador de arranque de motor',
	},
	{
		code: '39121036',
		label: 'Transformador de radio frecuencia rf',
	},
	{
		code: '39121037',
		label: 'Transformador de limite',
	},
	{
		code: '39121038',
		label: 'Yugo deflector',

		palabrasSimilares: 'Yugo de desviación',
	},
	{
		code: '39121039',
		label: 'Suministro de potencia de celda de combustible',
	},
	{
		code: '39121040',
		label: 'Ahorrador de energía eléctrica',
	},
	{
		code: '39121041',
		label: 'Transformador de voltaje constante',
	},
	{
		code: '39121042',
		label: 'Transformador de filamento',
	},
	{
		code: '39121043',
		label: 'Transformador rotativo',
	},
	{
		code: '39121044',
		label: 'Transformador de audio frecuencia',
	},
	{
		code: '39121045',
		label: 'Transformador de modulación',
	},
	{
		code: '39121046',
		label: 'Transformador para igualar impedancia',
	},
	{
		code: '39121047',
		label: 'Transformador de frecuencia intermedia',
	},
	{
		code: '39121100',
		label: 'Centros de control y distribución y accesorios',
	},
	{
		code: '39121101',
		label: 'Centros de carga',
	},
	{
		code: '39121102',
		label: 'Tomas o centros de medidores',
	},
	{
		code: '39121103',
		label: 'Paneles',
	},
	{
		code: '39121104',
		label: 'Centros de control de motor',
	},
	{
		code: '39121105',
		label: 'Sistemas de conmutadores',
	},
	{
		code: '39121106',
		label: 'Sistemas de control o vigilancia de potencia',
	},
	{
		code: '39121107',
		label: 'Sistemas de control de iluminación',
	},
	{
		code: '39121108',
		label: 'Accesorios del panel de control o distribución',
	},
	{
		code: '39121109',
		label: 'Transformadores de transmisión',
	},
	{
		code: '39121110',
		label: 'Tablero de interruptor de circuito',
	},
	{
		code: '39121111',
		label: 'Tablero de fusibles',
	},
	{
		code: '39121112',
		label: 'Tablero de bajo voltaje de corriente alterna y directa ac dc',
	},
	{
		code: '39121113',
		label: 'Centro de control de motor de bajo voltaje',
	},
	{
		code: '39121114',
		label: 'Centro de control de motor de medio voltaje',
	},
	{
		code: '39121115',
		label: 'Tablero de controles de medio voltaje',
	},
	{
		code: '39121116',
		label: 'Tablero de bajo voltaje',
	},
	{
		code: '39121117',
		label: 'Bus bar',

		palabrasSimilares: 'Barra colectora',
	},
	{
		code: '39121300',
		label: 'Cuadros, registros y menaje para electricidad',
	},
	{
		code: '39121301',
		label: 'Cerramientos del panel de control o distribución',
	},
	{
		code: '39121302',
		label: 'Placas o cubiertas de cerramiento',
	},
	{
		code: '39121303',
		label: 'Cajas eléctricas',
	},
	{
		code: '39121304',
		label: 'Cubiertas de cajas eléctricas',
	},
	{
		code: '39121305',
		label: 'Cajas a prueba de intemperie',
	},
	{
		code: '39121306',
		label: 'Cajas de conmutadores',
	},
	{
		code: '39121307',
		label: 'Cajas de suelo',
	},
	{
		code: '39121308',
		label: 'Cajas de toma de corriente',
	},
	{
		code: '39121309',
		label: 'Cajas eléctricas especiales',
	},
	{
		code: '39121310',
		label: 'Cajas de uso general',
	},
	{
		code: '39121311',
		label: 'Accesorios eléctricos',
	},
	{
		code: '39121312',
		label: 'Bujes eléctricos',
	},
	{
		code: '39121313',
		label: 'Bridas de techo',
	},
	{
		code: '39121314',
		label: 'Cerramiento a prueba de explosión',
	},
	{
		code: '39121315',
		label: 'Caja de dispositivos',
	},
	{
		code: '39121316',
		label: 'Caja de cielorraso',
	},
	{
		code: '39121317',
		label: 'Platillo de cielorraso',
	},
	{
		code: '39121318',
		label: 'Cerramiento del interruptor de circuito',
	},
	{
		code: '39121319',
		label: 'Cerramiento de transformador de corriente',
	},
	{
		code: '39121320',
		label: 'Extensión de caja eléctrica',
	},
	{
		code: '39121321',
		label: 'Ferretería y accesorios de caja eléctrica',
	},
	{
		code: '39121322',
		label: 'Partición de caja eléctrica',
	},
	{
		code: '39121323',
		label: 'Cerramiento de consola eléctrica',
	},
	{
		code: '39121324',
		label: 'Cerramiento eléctrico de propósito general',
	},
	{
		code: '39121325',
		label: 'Cerramiento de unidad de interfaz de operador eléctrico',
	},
	{
		code: '39121326',
		label: 'Cerramiento de botón eléctrico',
	},
	{
		code: '39121327',
		label: 'Cerramiento de terminal eléctrico',
	},
	{
		code: '39121328',
		label: 'Caja de perforación calificada de fuego',
	},
	{
		code: '39121329',
		label: 'Gabinete de fusibles',
	},
	{
		code: '39121330',
		label: 'Caja de distribución eléctrica',
	},
	{
		code: '39121331',
		label: 'Caja de ubicación peligrosa',
	},
	{
		code: '39121332',
		label: 'Caja de unión eléctrica',
	},
	{
		code: '39121333',
		label: 'Caja de mampostería',
	},
	{
		code: '39121334',
		label: 'Cerramiento eléctrico modular',
	},
	{
		code: '39121335',
		label: 'Gabinete de terminaciones telefónicas',
	},
	{
		code: '39121336',
		label: 'Cerramiento eléctrico subterráneo',

		palabrasSimilares: 'Recinto eléctrico subterráneo',
	},
	{
		code: '39121337',
		label: 'Compuesto de sellamiento eléctrico',
	},
	{
		code: '39121400',
		label: 'Lengüetas de conexión, conectadores y terminales',
	},
	{
		code: '39121402',
		label: 'Enchufes eléctricos',
	},
	{
		code: '39121403',
		label: 'Enchufe de bloqueo',

		palabrasSimilares: 'Bloqueo para enchufes',
	},
	{
		code: '39121404',
		label: 'Manguitos eléctricos',

		palabrasSimilares: 'Manguito de empalme',
	},
	{
		code: '39121405',
		label: 'Terminales de cable o alambre',

		palabrasSimilares: 'Terminal conector de empalme',
	},
	{
		code: '39121406',
		label: 'Receptáculos eléctricos',
	},
	{
		code: '39121407',
		label: 'Strips de conexiones',
	},
	{
		code: '39121408',
		label: 'Conexiones mecánicas',
	},
	{
		code: '39121409',
		label: 'Conectores de cables eléctricos',
	},
	{
		code: '39121410',
		label: 'Bloques de terminales',
	},
	{
		code: '39121412',
		label: 'Conectores de soporte posterior',
	},
	{
		code: '39121413',
		label: 'Conectores circulares',
	},
	{
		code: '39121414',
		label: 'Conectores coaxiales',
	},
	{
		code: '39121415',
		label: 'Conectores planos',
	},
	{
		code: '39121416',
		label: 'Tapas de conectores eléctricos',
	},
	{
		code: '39121419',
		label: 'Conexiones flexibles',
	},
	{
		code: '39121420',
		label: 'Conectores herméticos',
	},
	{
		code: '39121421',
		label: 'Ensambles de conectores',
	},
	{
		code: '39121423',
		label: 'Conectores de resorte',
	},
	{
		code: '39121424',
		label: 'Tapas del bloque de terminales',
	},
	{
		code: '39121425',
		label: 'Separador de tablero de terminales',

		palabrasSimilares: 'PCB terminal',
	},
	{
		code: '39121426',
		label: 'Puente de conexión',
	},
	{
		code: '39121427',
		label: 'Bobinadoras de cable',
	},
	{
		code: '39121428',
		label: 'Bobinadoras eléctricas',
	},
	{
		code: '39121431',
		label: 'Conectores estancos de cables',
	},
	{
		code: '39121432',
		label: 'Terminales eléctricos',
	},
	{
		code: '39121433',
		label: 'Conectores de radiofrecuencia (rf)',
	},
	{
		code: '39121434',
		label: 'Conectores de tubos metálicos eléctricos (emt)',
	},
	{
		code: '39121435',
		label: 'Hilos o cables de conexión',
	},
	{
		code: '39121436',
		label: 'Electrodos',
	},
	{
		code: '39121437',
		label: 'Patines de toma de corriente',
	},
	{
		code: '39121438',
		label: 'Conector automático de hilos o cables',
	},
	{
		code: '39121440',
		label: 'Cable de extensión eléctrica',

		palabrasSimilares: 'Extensión eléctrica',
	},
	{
		code: '39121441',
		label: 'Cable de puente eléctrico',

		palabrasSimilares: 'Cable pasa corriente',
	},
	{
		code: '39121442',
		label: 'Ensamble de puerto eléctrico',
	},
	{
		code: '39121443',
		label: 'Conector de bus subterráneo eléctrico',

		palabrasSimilares: 'Conector de bus',
	},
	{
		code: '39121444',
		label: 'Enchufe y módulo rj',
	},
	{
		code: '39121445',
		label: 'Torsión en conector de cable',
	},
	{
		code: '39121446',
		label: 'Equipo conector de terminal de cable',

		palabrasSimilares: 'Conector para cable',
	},
	{
		code: '39121447',
		label: 'Desconexión de terminal de cable',

		palabrasSimilares: 'Terminal hembra de desconexión',
	},
	{
		code: '39121448',
		label: 'Conector de cable o alambre de compresión',

		palabrasSimilares: 'Conector de compresión de cable',
	},
	{
		code: '39121449',
		label: 'Empalme de cable o alambre de compresión',

		palabrasSimilares: 'Empalme de compresión de cables',
	},
	{
		code: '39121450',
		label: 'Cabeza de martillo',

		palabrasSimilares: 'Perno de cabeza de martillo',
	},
	{
		code: '39121451',
		label: 'Conectores tap off',

		palabrasSimilares: 'Acoplador direccional',
	},
	{
		code: '39121452',
		label: 'Unión de cable',

		palabrasSimilares: 'Articulación para cable',
	},
	{
		code: '39121453',
		label: 'Unión de cangrejo',

		palabrasSimilares: 'Conector de cangrejo',
	},
	{
		code: '39121454',
		label: 'Terminal de batería',
	},
	{
		code: '39121455',
		label: 'Conector de molde de inserción eléctrica',
	},
	{
		code: '39121456',
		label: 'Conector de subestación',
	},
	{
		code: '39121457',
		label: 'Conector exotérmico',

		palabrasSimilares: 'Soldadura exotérmica',
	},
	{
		code: '39121458',
		label: 'Reductor de compresión de cable o alambre',

		palabrasSimilares: 'Terminal compresión para cable',
	},
	{
		code: '39121459',
		label: 'Placa de circuito impreso pcb con prensa conector de cabecera',
	},
	{
		code: '39121460',
		label: 'Conector con filtro de cabecera',
	},
	{
		code: '39121461',
		label: 'Sello de conector y cable',

		palabrasSimilares: 'Conector de la glándula',
	},
	{
		code: '39121462',
		label: 'Conector de telecomunicaciones',
	},
	{
		code: '39121463',
		label: 'Conector para enchufe',
	},
	{
		code: '39121464',
		label: 'Codo de tubo metálico eléctrico emt',
	},
	{
		code: '39121465',
		label: 'Trenza flexible',
	},
	{
		code: '39121466',
		label: 'Terminal y tapa de conexión mecánica',
	},
	{
		code: '39121467',
		label: 'Enchufe y tapa de conexión mecánica',
	},
	{
		code: '39121500',
		label: 'Conmutadores, controles y relés y accesorios',
	},
	{
		code: '39121521',
		label: 'Controles de motor de arranque',
	},
	{
		code: '39121522',
		label: 'Contactos eléctricos',
	},
	{
		code: '39121523',
		label: 'Temporizadores',
	},
	{
		code: '39121524',
		label: 'Fotocontroles',
	},
	{
		code: '39121527',
		label: 'Codificadores',
	},
	{
		code: '39121528',
		label: 'Sensores fotoeléctricos',
	},
	{
		code: '39121529',
		label: 'Contactores',

		palabrasSimilares: 'Contactores electromagnéticos',
	},
	{
		code: '39121534',
		label: 'Luces indicadoras o indicadores luminosos',
	},
	{
		code: '39121544',
		label: 'Piezas de luces indicadoras o accesorios',
	},
	{
		code: '39121545',
		label: 'Paradas de emergencia',

		palabrasSimilares: 'Botón paro de emergencia',
	},
	{
		code: '39121551',
		label: 'Piezas de reflector',
	},
	{
		code: '39121552',
		label: 'Módulos de control eléctrico',
	},
	{
		code: '39121555',
		label: 'Control de contador',
	},
	{
		code: '39121561',
		label: 'Estación colgante de control',
	},
	{
		code: '39121565',
		label: 'Estación de control de montaje de superficie',
	},
	{
		code: '39121568',
		label: 'Reactor de derivación',
	},
	{
		code: '39121569',
		label: 'Reacción en serie',

		palabrasSimilares: 'Reactores en serie',
	},
	{
		code: '39121570',
		label: 'Re - cerrador',

		palabrasSimilares: 'Interruptor, Reconectador',
	},
	{
		code: '39121600',
		label: 'Dispositivos y accesorios para la protección de circuitos',
	},
	{
		code: '39121601',
		label: 'Breakers de circuito',
	},
	{
		code: '39121602',
		label: 'Breakers de circuito magnético',
	},
	{
		code: '39121603',
		label: 'Micro disyuntores',
	},
	{
		code: '39121604',
		label: 'Fusibles de retardo',
	},
	{
		code: '39121605',
		label: 'Fusibles de tapón',
	},
	{
		code: '39121606',
		label: 'Fusibles de cartucho',
	},
	{
		code: '39121607',
		label: 'Fusibles de cuerpo de vidrio',
	},
	{
		code: '39121609',
		label: 'Microfusibles',
	},
	{
		code: '39121610',
		label: 'Supresor de ondas',
	},
	{
		code: '39121611',
		label: 'Fusibles de cerámicas',
	},
	{
		code: '39121612',
		label: 'Fusibles de cuchilla',
	},
	{
		code: '39121613',
		label: 'Conjuntos o dispositivos de polo a tierra',
	},
	{
		code: '39121614',
		label: 'Disyuntores de pérdida a tierra',
	},
	{
		code: '39121615',
		label: 'Breakers de circuito de aire',
	},
	{
		code: '39121616',
		label: 'Breakers de circuito de caja moldeada',
	},
	{
		code: '39121617',
		label: 'Piezas de fusible o accesorios',
	},
	{
		code: '39121618',
		label: 'Alambre para fusible',
	},
	{
		code: '39121619',
		label: 'Fusibles tipo botella',
	},
	{
		code: '39121620',
		label: 'Materiales transientes de protección',
	},
	{
		code: '39121621',
		label: 'Accesorios y aparatos de protección contra rayos',
	},
	{
		code: '39121622',
		label: 'Fusible automotriz',
	},
	{
		code: '39121623',
		label: 'Fusible electrónico',
	},
	{
		code: '39121624',
		label: 'Soporte de fusible',
	},
	{
		code: '39121625',
		label: 'Fusible de alta velocidad',
	},
	{
		code: '39121626',
		label: 'Fusible de medio voltaje',
	},
	{
		code: '39121627',
		label: 'Fusible de potencia',
	},
	{
		code: '39121628',
		label: 'Fusible semiconductor',
	},
	{
		code: '39121629',
		label: 'Bloque de fusibles',
	},
	{
		code: '39121630',
		label: 'Clip de fusible',
	},
	{
		code: '39121631',
		label: 'Interruptor de circuito de alto voltaje lleno de aceite',
	},
	{
		code: '39121632',
		label: 'Protector de redes',
	},
	{
		code: '39121633',
		label: 'Interruptor de circuito',
	},
	{
		code: '39121634',
		label: 'Protector de sobretensiones',
	},
	{
		code: '39121635',
		label: 'Regulador de voltaje',
	},
	{
		code: '39121636',
		label: 'Limitador de corriente',
	},
	{
		code: '39121637',
		label: 'Supresor de arco',
	},
	{
		code: '39121638',
		label: 'Corte de fusible',
	},
	{
		code: '39121639',
		label: 'Interruptor de vacío',
	},
	{
		code: '39121640',
		label: 'Breaker de circuito eléctrico',
	},
	{
		code: '39121641',
		label: 'Fusible de gancho',
	},
	{
		code: '39121642',
		label: 'Fusible de tornillo',
	},
	{
		code: '39121643',
		label: 'Panel protector de sobrecorriente',
	},
	{
		code: '39121644',
		label: 'Breaker de circuito de alta velocidad',
	},
	{
		code: '39121645',
		label: 'Breaker de circuito de aceite',
	},
	{
		code: '39121646',
		label: 'Breaker de circuito de gas',
	},
	{
		code: '39121647',
		label: 'Descargador electrostático',
	},
	{
		code: '39121648',
		label: 'Aditivo de tierra',
	},
	{
		code: '39121700',
		label: 'Ferretería eléctrica y suministros',
	},
	{
		code: '39121701',
		label: 'Soportes eléctricos',
	},
	{
		code: '39121702',
		label: 'Clips para cables',
	},
	{
		code: '39121703',
		label: 'Enlaces de cables',
	},
	{
		code: '39121704',
		label: 'Placas de pared',
	},
	{
		code: '39121705',
		label: 'Grapas para cables',
	},
	{
		code: '39121706',
		label: 'Bujes de transformadores',
	},
	{
		code: '39121707',
		label: 'Clavos de tabla de arneses',
	},
	{
		code: '39121708',
		label: 'Riel din',
	},
	{
		code: '39121709',
		label: 'Atadura de mango',
	},
	{
		code: '39121710',
		label: 'Receptáculo multiplicador eléctrico',
	},
	{
		code: '39121717',
		label: 'Montaje de fijador de cable',
	},
	{
		code: '39121718',
		label: 'Kits de empalme de cables',
	},
	{
		code: '39121719',
		label: 'Protectores',
	},
	{
		code: '39121720',
		label: 'Asas de transformadores',
	},
	{
		code: '39121721',
		label: 'Aislantes eléctricos',
	},
	{
		code: '39121722',
		label: 'Dispositivo para sacar alambres y cables',
	},
	{
		code: '39121723',
		label: 'Tubo encogible al calor',
	},
	{
		code: '39121724',
		label: 'Artículos para montaje de conector',
	},
	{
		code: '39121725',
		label: 'Mango de cable eléctrico',
	},
	{
		code: '39121726',
		label: 'Atador de cables eléctricos',
	},
	{
		code: '39121727',
		label: 'Varilla aislante eléctrica',
	},
	{
		code: '39121728',
		label: 'Tubo de aislamiento',
	},
	{
		code: '39121729',
		label: 'Juego de cables eléctricos',
	},
	{
		code: '39121730',
		label: 'Roseta eléctrica',
	},
	{
		code: '39121731',
		label: 'Equipo para atar cables',
	},
	{
		code: '39121800',
		label: 'Instalaciones de Edificios Inteligentes - IBI',
	},
	{
		code: '39121801',
		label: 'Unidades o dispositivos de control',
	},
	{
		code: '39121802',
		label: 'Unidades o dispositivos inactivos',
	},
	{
		code: '39121803',
		label: 'Sistema de control de construcción ambiental',
	},
	{
		code: '39121900',
		label: 'Dispositivos y accesorios de seguridad eléctrica',
	},
	{
		code: '39121901',
		label: 'Bloqueo de breaker de circuito',
	},
	{
		code: '39121902',
		label: 'Cerramiento de bloqueo',
	},
	{
		code: '39121903',
		label: 'Bloqueo de pasador y candado',
	},
	{
		code: '39121904',
		label: 'Kit y estación de bloqueo',
	},
	{
		code: '39121905',
		label: 'Dispositivo de bloqueo múltiple',
	},
	{
		code: '39121906',
		label: 'Bloqueo de enchufe y cable',
	},
	{
		code: '39121907',
		label: 'Bloqueo de receptáculo',
	},
	{
		code: '39121908',
		label: 'Bloqueo de interruptor',
	},
	{
		code: '39121909',
		label: 'Bloqueo de válvula',
	},
	{
		code: '39121910',
		label: 'Marcador de voltaje',
	},
	{
		code: '39122000',
		label: 'Unidades eléctricas de velocidades variables',
	},
	{
		code: '39122001',
		label: 'Unidad ac invertida',

		palabrasSimilares: 'Inversor accionamiento AC',
	},
	{
		code: '39122002',
		label: 'Unidad dc de control de motor',
	},
	{
		code: '39122003',
		label: 'Unidad de servo control',
	},
	{
		code: '39122100',
		label: 'Equipo de transmisión y distribución eléctrica',
	},
	{
		code: '39122101',
		label: 'Potheads de cable para subestaciones',
	},
	{
		code: '39122102',
		label: 'Cambiador de tomas',
	},
	{
		code: '39122103',
		label: 'Poste de utilidad',
	},
	{
		code: '39122104',
		label: 'Cable galvanizado de refuerzo',
	},
	{
		code: '39122105',
		label: 'Espaciador eléctrico',
	},
	{
		code: '39122106',
		label: 'Abrazadera para cruceta',
	},
	{
		code: '39122107',
		label: 'Bloque de madera de cableado',
	},
	{
		code: '39122108',
		label: 'Varilla de anclaje para cable galvanizado de refuerzo',
	},
	{
		code: '39122109',
		label: 'Cruceta de acero tipo cuadrado',
	},
	{
		code: '39122110',
		label: 'Arandela de conexión para línea eléctrica aérea',
	},
	{
		code: '39122111',
		label: 'Dispositivo para dividir la línea eléctrica del carro',
	},
	{
		code: '39122112',
		label: 'Ojo del juego de aislamiento',
	},
	{
		code: '39122113',
		label: 'Cruceta del poste de utilidad',
	},
	{
		code: '39122114',
		label: 'Toma de vía de bus eléctrico o toma de bus',
	},
	{
		code: '39122115',
		label: 'Varilla de armadura eléctrica',
	},
	{
		code: '39122116',
		label: 'Ajuste de vínculo de línea aérea',
	},
	{
		code: '39122117',
		label: 'Tensión yugo de polo de suspensión',
	},
	{
		code: '39122118',
		label: 'Conector de bloque de cable eléctrico',
	},
	{
		code: '39122119',
		label: 'Torre de acero de transmisión eléctrica',
	},
	{
		code: '39122120',
		label: 'Banda de polo de utilidad',
	},
	{
		code: '39122200',
		label: 'Interruptores eléctricos y accesorios',
	},
	{
		code: '39122201',
		label: 'Interruptor de código',
	},
	{
		code: '39122202',
		label: 'Interruptor de cuchilla',

		palabrasSimilares: 'Switch de cuchillas',
	},
	{
		code: '39122203',
		label: 'Interruptor sensible',
	},
	{
		code: '39122204',
		label: 'Interruptor de vueltas',
	},
	{
		code: '39122205',
		label: 'Interruptores de seguridad',

		palabrasSimilares: 'Switch de seguridad',
	},
	{
		code: '39122206',
		label: 'Reductores o dimers',
	},
	{
		code: '39122207',
		label: 'Interruptores de tambor',
	},
	{
		code: '39122208',
		label: 'Interruptores de tiempos',
	},
	{
		code: '39122209',
		label: 'Interruptores de resorte',
	},
	{
		code: '39122210',
		label: 'Interruptores automáticos por caída de presión',
	},
	{
		code: '39122211',
		label: 'Interruptores de volquete',
	},
	{
		code: '39122212',
		label: 'Conmutadores de botón deslizante',
	},
	{
		code: '39122213',
		label: 'Interruptores de límite',
	},
	{
		code: '39122214',
		label: 'Interruptores de controlador',
	},
	{
		code: '39122215',
		label: 'Interruptores variables',
	},
	{
		code: '39122216',
		label: 'Interruptores de botón',
	},
	{
		code: '39122217',
		label: 'Interruptores giratorios',
	},
	{
		code: '39122218',
		label: 'Interruptor no fusible',

		palabrasSimilares: 'Switch no fusible',
	},
	{
		code: '39122219',
		label: 'Interruptor nivel o flotador',
	},
	{
		code: '39122220',
		label: 'Interruptor de radio frecuencia rf',
	},
	{
		code: '39122221',
		label: 'Parte o accesorio de interruptor',

		palabrasSimilares: 'Partes y accesorios de switch',
	},
	{
		code: '39122222',
		label: 'Interruptor de pie',
	},
	{
		code: '39122223',
		label: 'Interruptor de flujo',
	},
	{
		code: '39122224',
		label: 'Interruptor de cerradura',
	},
	{
		code: '39122225',
		label: 'Interruptor de mercurio',
	},
	{
		code: '39122226',
		label: 'Interruptor rocker',
	},
	{
		code: '39122227',
		label: 'Interruptor o control joystick',
	},
	{
		code: '39122228',
		label: 'Interruptor de vacío',
	},
	{
		code: '39122229',
		label: 'Interruptor de temperatura',
	},
	{
		code: '39122230',
		label: 'Interruptor de proximidad',
	},
	{
		code: '39122231',
		label: 'Interruptor dispositivo de combinación',
	},
	{
		code: '39122232',
		label: 'Interruptor de combinación',
	},
	{
		code: '39122233',
		label: 'Interruptor de desconexión',
	},
	{
		code: '39122234',
		label: 'Interruptor de bloqueo',
	},
	{
		code: '39122235',
		label: 'Interruptor de sistema de cableado modular',
	},
	{
		code: '39122236',
		label: 'Interruptor de sensor de ocupación o movimiento',
	},
	{
		code: '39122237',
		label: 'Interruptor de montura de panel',
	},
	{
		code: '39122238',
		label: 'Interruptor de fotocelda',
	},
	{
		code: '39122239',
		label: 'Interruptor de halar',
	},
	{
		code: '39122240',
		label: 'Interruptor de velocidad',
	},
	{
		code: '39122241',
		label: 'Interruptor de lengüeta',
	},
	{
		code: '39122242',
		label: 'Interruptor de suelo',
	},
	{
		code: '39122243',
		label: 'Micro – interruptor',
	},
	{
		code: '39122244',
		label: 'Interruptor de corte',
	},
	{
		code: '39122245',
		label: 'Interruptor magnético',
	},
	{
		code: '39122246',
		label: 'Interruptor de potenciómetro eléctrico',
	},
	{
		code: '39122247',
		label: 'Interruptor de potenciómetro automotriz hvac',
	},
	{
		code: '39122248',
		label: 'Interruptor de distribuidor de señal de potencia',
	},
	{
		code: '39122249',
		label: 'Interruptor de control de timón',
	},
	{
		code: '39122250',
		label: 'Interruptor selector de cambio de transmisión automática',
	},
	{
		code: '39122251',
		label: 'Interruptor detent',
	},
	{
		code: '39122300',
		label: 'Relés eléctricos y accesorios',

		palabrasSimilares: 'Relevadores eléctricos y accesorios',
	},
	{
		code: '39122301',
		label: 'Relé buchholtz',

		palabrasSimilares: 'Relevador buchholz',
	},
	{
		code: '39122302',
		label: 'Relé de distancia',

		palabrasSimilares: 'Relevador de distancia',
	},
	{
		code: '39122303',
		label: 'Relé multicontacto',

		palabrasSimilares: 'Relevador multicontacto',
	},
	{
		code: '39122304',
		label: 'Relé de lengüeta',

		palabrasSimilares: 'Relevador de lengüeta',
	},
	{
		code: '39122305',
		label: 'Relé de medidor',

		palabrasSimilares: 'Relevador de medidor',
	},
	{
		code: '39122306',
		label: 'Relé de tierra direccional',

		palabrasSimilares: 'Relevador de tierra direccional',
	},
	{
		code: '39122307',
		label: 'Relé auxiliar',

		palabrasSimilares: 'Relevador auxiliar',
	},
	{
		code: '39122308',
		label: 'Relé de índice diferencial',

		palabrasSimilares: 'Relevador de índice diferencial',
	},
	{
		code: '39122309',
		label: 'Relé subterráneo',

		palabrasSimilares: 'Relevador subterráneo',
	},
	{
		code: '39122310',
		label: 'Relé selector de tierra',

		palabrasSimilares: 'Relevador selector de tierra',
	},
	{
		code: '39122311',
		label: 'Relé de presión',

		palabrasSimilares: 'Relevador de presión',
	},
	{
		code: '39122312',
		label: 'Relé de polaridad',

		palabrasSimilares: 'Relevador de polaridad',
	},
	{
		code: '39122313',
		label: 'Relé trip free',

		palabrasSimilares: 'Relevador sin disparo',
	},
	{
		code: '39122314',
		label: 'Relé de bloqueo',

		palabrasSimilares: 'Relevador de bloqueo',
	},
	{
		code: '39122315',
		label: 'Relé electromagnético',

		palabrasSimilares: 'Relevador electromagnético',
	},
	{
		code: '39122316',
		label: 'Relé de re – cerrado',

		palabrasSimilares: 'Relevador de reconexión',
	},
	{
		code: '39122317',
		label: 'Relé de aseguramiento',

		palabrasSimilares: 'Relevador de aseguramiento',
	},
	{
		code: '39122318',
		label: 'Relé de frecuencia',

		palabrasSimilares: 'Relevador de frecuencia',
	},
	{
		code: '39122319',
		label: 'Relé de corriente directa',

		palabrasSimilares: 'Relevador de corriente directa',
	},
	{
		code: '39122320',
		label: 'Relé horizontal',

		palabrasSimilares: 'Relevador horizontal',
	},
	{
		code: '39122321',
		label: 'Relé no polarizado',

		palabrasSimilares: 'Relevador no polarizado',
	},
	{
		code: '39122322',
		label: 'Relé de voltaje de corriente directa',

		palabrasSimilares: 'Relevador de voltaje de corriente directa',
	},
	{
		code: '39122323',
		label: 'Relé digital multi – función',

		palabrasSimilares: 'Relevador digital multifunción',
	},
	{
		code: '39122324',
		label: 'Relés de potencia',

		palabrasSimilares: 'Relevadores de potencia',
	},
	{
		code: '39122325',
		label: 'Relés universales',

		palabrasSimilares: 'Relevadores universales',
	},
	{
		code: '39122326',
		label: 'Relés de clavija bipolar',

		palabrasSimilares: 'Relevadores de clavija bipolar',
	},
	{
		code: '39122327',
		label: 'Relés de voltaje alterno',

		palabrasSimilares: 'Relevadores de voltaje alterno',
	},
	{
		code: '39122328',
		label: 'Relés de mercurio',

		palabrasSimilares: 'Relevadores de mercurio',
	},
	{
		code: '39122329',
		label: 'Relés de acción diferida',

		palabrasSimilares: 'Relevadores de acción diferida',
	},
	{
		code: '39122330',
		label: 'Relés de sobrecarga',

		palabrasSimilares: 'Relevadores de sobrecarga',
	},
	{
		code: '39122331',
		label: 'Relés de control',

		palabrasSimilares: 'Relevadores de control',
	},
	{
		code: '39122332',
		label: 'Relés de interrupción de fase',

		palabrasSimilares: 'Relevadores de interrupción de fase',
	},
	{
		code: '39122333',
		label: 'Relés de estado sólido',

		palabrasSimilares: 'Relevadores de estado sólido',
	},
	{
		code: '39122334',
		label: 'Módulo de relés múltiples o de placa de relés',
	},
	{
		code: '39122335',
		label: 'Base o toma de relé',
	},
	{
		code: '39122336',
		label: 'Relé intermitente de luz direccional',
	},
	{
		code: '39122337',
		label: 'Relé de tablero de circuito impreso',
	},
	{
		code: '39131500',
		label: 'Marcadores de alambre y dispositivos para marcar alambre',
	},
	{
		code: '39131501',
		label: 'Marcador de cable de pinza',
	},
	{
		code: '39131502',
		label: 'Marcador de cable de encogimiento por calor',
	},
	{
		code: '39131503',
		label: 'Marcador de cable de deslizar',
	},
	{
		code: '39131504',
		label: 'Marcador de cable de identificación',
	},
	{
		code: '39131505',
		label: 'Herramienta e impresora para rotular cable',
	},
	{
		code: '39131506',
		label: 'Libro de marcador de cable',
	},
	{
		code: '39131507',
		label: 'Tarjeta de arcador de cable',

		palabrasSimilares: 'Marcador para cable y alambre',
	},
	{
		code: '39131508',
		label: 'Rollo y dispensador de arcador de cable',

		palabrasSimilares: 'Rollo y dispensador de marcador de cable',
	},
	{
		code: '39131509',
		label: 'Marcador de cable de escribir',
	},
	{
		code: '39131600',
		label: 'Dispositivos de protección de alambre',
	},
	{
		code: '39131601',
		label: 'Tubo corrugado para cableado interno',
	},
	{
		code: '39131602',
		label: 'Manga trenzada expandible',
	},
	{
		code: '39131603',
		label: 'Perfil pasacable',

		palabrasSimilares: 'Borde del ojal de caucho',
	},
	{
		code: '39131604',
		label: 'Empaque de espiral',
	},
	{
		code: '39131605',
		label: 'Piso de cables track or guard',
	},
	{
		code: '39131606',
		label: 'Placa de clavos para protección de cable',
	},
	{
		code: '39131607',
		label: 'Cordón para amarrar cables',
	},
	{
		code: '39131608',
		label: 'Sistema de sellamiento de ductos',
	},
	{
		code: '39131700',
		label: 'Conductos eléctricos, electroductos y cables aéreos',
	},
	{
		code: '39131701',
		label: 'Electroducto',
	},
	{
		code: '39131702',
		label: 'Dispositivos y accesorios de electroducto',
	},
	{
		code: '39131703',
		label: 'Escalerilla de cables',
	},
	{
		code: '39131704',
		label: 'Bandeja de cables',
	},
	{
		code: '39131705',
		label: 'Dispositivos y accesorios de bandeja de cable',
	},
	{
		code: '39131706',
		label: 'Conducto eléctrico',
	},
	{
		code: '39131707',
		label: 'Acoples de conducto eléctrico',
	},
	{
		code: '39131708',
		label: 'Cuerpo de ajuste de conducto eléctrico',
	},
	{
		code: '39131709',
		label: 'Cableado eléctrico o conductos de cables',
	},
	{
		code: '39131710',
		label: 'Cables aéreos',
	},
	{
		code: '39131711',
		label: 'Dispositivos y accesorios de cableado eléctrico',
	},
	{
		code: '39131712',
		label: 'Ducto de cableado',
	},
	{
		code: '39131713',
		label: 'Dispositivos y accesorios de ducto de cableado',
	},
	{
		code: '39131714',
		label: 'Canaletas para cables',
	},
	{
		code: '39131715',
		label: 'Conducto subterráneo de manguera',
	},
	{
		code: '39131716',
		label: 'Manipulador de cable',
	},
	{
		code: '39131717',
		label: 'Codo de conducto eléctrico',
	},
	{
		code: '39131718',
		label: 'Boquilla de conducto eléctrico',
	},
	{
		code: '39131719',
		label: 'Tubería metálica eléctrica emt',
	},
	{
		code: '39131720',
		label: 'Tubería metálica eléctrica emt roscada',
	},
	{
		code: '40101500',
		label: 'Ventilación',
	},
	{
		code: '40101501',
		label: 'Colectores de aire',
	},
	{
		code: '40101502',
		label: 'Extractores de aire',
	},
	{
		code: '40101503',
		label: 'Rejilla de ventilación',
	},
	{
		code: '40101504',
		label: 'Compuertas de ventilación',
	},
	{
		code: '40101505',
		label: 'Difusores de aire',
	},
	{
		code: '40101506',
		label: 'Tubos de ventilación',
	},
	{
		code: '40101507',
		label: 'Tubería de ventilación en espiral',
	},
	{
		code: '40101508',
		label: 'Cortina de ventilación',
	},
	{
		code: '40101509',
		label: 'Clavos para sistemas de ventilación',
	},
	{
		code: '40101510',
		label: 'Tubería de ventilación',
	},
	{
		code: '40101511',
		label: 'Colgador para tubos de ventilación',
	},
	{
		code: '40101512',
		label: 'Dispositivos para tubos de ventilación',
	},
	{
		code: '40101600',
		label: 'Circulación del aire y piezas y accesorios',
	},
	{
		code: '40101601',
		label: 'Secadores',
	},
	{
		code: '40101602',
		label: 'Circuladores de aire',
	},
	{
		code: '40101603',
		label: 'Impulsores',
	},
	{
		code: '40101604',
		label: 'Ventiladores',
	},
	{
		code: '40101605',
		label: 'Protectores o sus accesorios para ventiladores',
	},
	{
		code: '40101606',
		label: 'Dispositivos de seguridad para ventiladores',
	},
	{
		code: '40101607',
		label: 'Válvula de control de volumen de aire',
	},
	{
		code: '40101608',
		label: 'Unidad de filtrado para ventiladores',
	},
	{
		code: '40101609',
		label: 'Ventiladores de techo',
	},
	{
		code: '40101610',
		label: 'Kit de ventiladores de techo',
	},
	{
		code: '40101700',
		label: 'Enfriamiento',
	},
	{
		code: '40101701',
		label: 'Aires acondicionados',

		palabrasSimilares: 'Aire acondicionado',
	},
	{
		code: '40101702',
		label: 'Intercambiadores de enfriado',
	},
	{
		code: '40101703',
		label: 'Enfriadores de evaporación',
	},
	{
		code: '40101704',
		label: 'Unidades de condensación',
	},
	{
		code: '40101705',
		label: 'Ensamblajes de tubos capilares',
	},
	{
		code: '40101706',
		label: 'Obturadores de aire acondicionado',
	},
	{
		code: '40101707',
		label: 'Accesorios para torres de enfriamiento',
	},
	{
		code: '40101708',
		label: 'Unidad de ventilador de bobina',
	},
	{
		code: '40101709',
		label: 'Unidad de manejo de aire',
	},
	{
		code: '40101710',
		label: 'Enfriador de reciprocidad',
	},
	{
		code: '40101711',
		label: 'Enfriador de líquido centrífugo',
	},
	{
		code: '40101712',
		label: 'Enfriador de tornillo',
	},
	{
		code: '40101713',
		label: 'Enfriador de absorción',
	},
	{
		code: '40101714',
		label: 'Enfriador calentador de absorción',
	},
	{
		code: '40101715',
		label: 'Cámara de temperatura y humedad constantes',
	},
	{
		code: '40101716',
		label: 'Torre enfriadora',
	},
	{
		code: '40101717',
		label: 'Ahorrador de energía para aire acondicionado',
	},
	{
		code: '40101718',
		label: 'Bobina enfriadora',
	},
	{
		code: '40101719',
		label: 'Receptor de líquido refrigerante',
	},
	{
		code: '40101720',
		label: 'Unidad de enfriamiento',
	},
	{
		code: '40101721',
		label: 'Condensador de agua enfriada',
	},
	{
		code: '40101722',
		label: 'Condensador de vapor',
	},
	{
		code: '40101800',
		label: 'Equipo de calefacción y piezas y accesorios',
	},
	{
		code: '40101801',
		label: 'Radiadores',
	},
	{
		code: '40101802',
		label: 'Intercambiadores de calor',
	},
	{
		code: '40101805',
		label: 'Calefacción',
	},
	{
		code: '40101806',
		label: 'Bombas de calor',
	},
	{
		code: '40101807',
		label: 'Unidades de calefacción solar',
	},
	{
		code: '40101808',
		label: 'Estufas de calefacción',
	},
	{
		code: '40101809',
		label: 'Calentadores de circulación',
	},
	{
		code: '40101810',
		label: 'Calentadores de conductos de bobina',
	},
	{
		code: '40101811',
		label: 'Calentadores de convección',
	},
	{
		code: '40101812',
		label: 'Intercambiadores divididos',
	},
	{
		code: '40101813',
		label: 'Intercambiadores de doble división',
	},
	{
		code: '40101814',
		label: 'Calentadores de aletas tubulares',
	},
	{
		code: '40101815',
		label: 'Calentadores de inmersión',
	},
	{
		code: '40101816',
		label: 'Intercambiadores de teteras',
	},
	{
		code: '40101817',
		label: 'Intercambiadores de una vía',
	},
	{
		code: '40101818',
		label: 'Calentadores de procesamiento de aire',
	},
	{
		code: '40101819',
		label: 'Calentadores de espacio',
	},
	{
		code: '40101820',
		label: 'Intercambiadores divididos',
	},
	{
		code: '40101821',
		label: 'Calentadores de banda',
	},
	{
		code: '40101822',
		label: 'Calentadores tubulares',
	},
	{
		code: '40101823',
		label: 'Intercambiadores de dos vías',
	},
	{
		code: '40101824',
		label: 'Calentador de cuarzo',
	},
	{
		code: '40101825',
		label: 'Calentadores de agua para uso doméstico',
	},
	{
		code: '40101826',
		label: 'Calentadores de agua comerciales',
	},
	{
		code: '40101827',
		label: 'Calentadores de fibra cerámica',
	},
	{
		code: '40101828',
		label: 'Calentadores de cartucho',
	},
	{
		code: '40101829',
		label: 'Calentadores de tiro',
	},
	{
		code: '40101830',
		label: 'Elementos de calefacción',
	},
	{
		code: '40101831',
		label: 'Calentadores de inducción',
	},
	{
		code: '40101832',
		label: 'Puertas para equipos de calefacción',
	},
	{
		code: '40101833',
		label: 'Encendedor para calderas o calentadores',
	},
	{
		code: '40101834',
		label: 'Quemadores (fogones)',
	},
	{
		code: '40101835',
		label: 'Plato intercambiador de calor',
	},
	{
		code: '40101836',
		label: 'Intercambiador de calor de concha y de tubo',
	},
	{
		code: '40101837',
		label: 'Chimenea',
	},
	{
		code: '40101838',
		label: 'Economizador',
	},
	{
		code: '40101839',
		label: 'Placas difusoras',
	},
	{
		code: '40101840',
		label: 'Calentador de película',
	},
	{
		code: '40101841',
		label: 'Pre calentador de aire',
	},
	{
		code: '40101842',
		label: 'Dispositivo para derretir hielo',
	},
	{
		code: '40101843',
		label: 'Horno eléctrico',
	},
	{
		code: '40101844',
		label: 'Horno de alta frecuencia de inducción al vacío',
	},
	{
		code: '40101845',
		label: 'Horno de reverberación',
	},
	{
		code: '40101846',
		label: 'Horno de baño de sal',

		palabrasSimilares: 'Horno de tratamiento térmico',
	},
	{
		code: '40101847',
		label: 'Horno eléctrico de carbonización y nitrificación',
	},
	{
		code: '40101848',
		label: 'Sistema de calefacción de aire forzado',
	},
	{
		code: '40101849',
		label: 'Cabezal de distribución de agua caliente',
	},
	{
		code: '40101850',
		label: 'Horno crematorio',
	},
	{
		code: '40101900',
		label: 'Control de humedad',
	},
	{
		code: '40101901',
		label: 'Vaporizadores',
	},
	{
		code: '40101902',
		label: 'Deshumidificadores',
	},
	{
		code: '40101903',
		label: 'Humidificadores',
	},
	{
		code: '40102000',
		label: 'Calderas',
	},
	{
		code: '40102001',
		label: 'Quemadores de tubo radiante',
	},
	{
		code: '40102002',
		label: 'Quemadores de tubo de agua',
	},
	{
		code: '40102003',
		label: 'Quemadores eléctricos',
	},
	{
		code: '40102004',
		label: 'Quemadores operados con gas natural',
	},
	{
		code: '40102005',
		label: 'Quemadores operados con gas propano',
	},
	{
		code: '40102006',
		label: 'Quemadores operados con calor sobrante',
	},
	{
		code: '40102007',
		label: 'Mini quemadores de aceite',
	},
	{
		code: '40102100',
		label: 'Chimeneas y accesorios',
	},
	{
		code: '40102101',
		label: 'Chimeneas operadas con leña',
	},
	{
		code: '40102102',
		label: 'Chimenea a gas de ventilador b',
	},
	{
		code: '40102103',
		label: 'Chimenea a gas de ventilador directo',
	},
	{
		code: '40102104',
		label: 'Chimenea a gas de ventilador libre',
	},
	{
		code: '40102105',
		label: 'Chimenea eléctrica',
	},
	{
		code: '40102106',
		label: 'Fachadas de chimeneas',
	},
	{
		code: '40102107',
		label: 'Repisas para chimeneas',
	},
	{
		code: '40141600',
		label: 'Válvulas',
	},
	{
		code: '40141602',
		label: 'Válvulas de aguja',
	},
	{
		code: '40141603',
		label: 'Válvulas neumáticas',
	},
	{
		code: '40141604',
		label: 'Válvulas de seguridad',
	},
	{
		code: '40141605',
		label: 'Válvulas solenoides',
	},
	{
		code: '40141606',
		label: 'Válvulas de relevo',
	},
	{
		code: '40141607',
		label: 'Válvulas de bola',
	},
	{
		code: '40141608',
		label: 'Válvulas hidráulicas',
	},
	{
		code: '40141609',
		label: 'Válvulas de control',
	},
	{
		code: '40141610',
		label: 'Válvulas de flotación',
	},
	{
		code: '40141611',
		label: 'Válvulas de globo',
	},
	{
		code: '40141612',
		label: 'Válvulas de expansión',
	},
	{
		code: '40141613',
		label: 'Válvulas de compuerta',
	},
	{
		code: '40141615',
		label: 'Válvulas de lengüeta',
	},
	{
		code: '40141616',
		label: 'Partes o accesorios para válvulas',
	},
	{
		code: '40141617',
		label: 'Válvulas esféricas de ángulo',
	},
	{
		code: '40141618',
		label: 'Válvulas de seguridad de bola',
	},
	{
		code: '40141619',
		label: 'Válvulas de mariposa con diseño de casquillo',
	},
	{
		code: '40141620',
		label: 'Válvulas de mariposa con diseño de disco',
	},
	{
		code: '40141621',
		label: 'Válvulas de diafragma',
	},
	{
		code: '40141622',
		label: 'Válvulas de seguridad en línea',
	},
	{
		code: '40141623',
		label: 'Válvulas tipo compuerta',
	},
	{
		code: '40141624',
		label: 'Válvulas con pistones lubricados',
	},
	{
		code: '40141625',
		label: 'Válvulas purgadoras de sedimentos (barro o lodo)',
	},
	{
		code: '40141626',
		label: 'Válvulas con pistón no lubricado',
	},
	{
		code: '40141627',
		label: 'Válvulas de orificio',
	},
	{
		code: '40141628',
		label: 'Válvulas piloto',
	},
	{
		code: '40141629',
		label: 'Válvulas de pinzamiento',
	},
	{
		code: '40141630',
		label: 'Válvulas de seguridad de pistón',
	},
	{
		code: '40141631',
		label: 'Válvulas de bombeo',
	},
	{
		code: '40141632',
		label: 'Válvulas de monitoreo',
	},
	{
		code: '40141633',
		label: 'Válvulas deslizantes',
	},
	{
		code: '40141634',
		label: 'Válvulas de control de rotación',
	},
	{
		code: '40141635',
		label: 'Válvulas de turbina',
	},
	{
		code: '40141636',
		label: 'Kits de válvulas',
	},
	{
		code: '40141637',
		label: 'Válvulas de control de disco',
	},
	{
		code: '40141638',
		label: 'Válvulas alternadoras',
	},
	{
		code: '40141639',
		label: 'Válvulas de llenado del inodoro',
	},
	{
		code: '40141640',
		label: 'Válvulas de inodoro',
	},
	{
		code: '40141641',
		label: 'Válvulas solenoides',
	},
	{
		code: '40141642',
		label: 'Válvulas de asiento (o auxiliares)',
	},
	{
		code: '40141643',
		label: 'Válvulas de contención',
	},
	{
		code: '40141644',
		label: 'Válvulas de esclusa rotativas',
	},
	{
		code: '40141645',
		label: 'Vástagos de válvulas',
	},
	{
		code: '40141646',
		label: 'Soportes (asientos) de válvulas',
	},
	{
		code: '40141647',
		label: 'Válvulas de segmento',
	},
	{
		code: '40141648',
		label: 'Válvulas magnéticas',
	},
	{
		code: '40141649',
		label: 'Ensamblaje de bobina de válvula de control direccional',
	},
	{
		code: '40141650',
		label: 'Válvula de gas comprimido',
	},
	{
		code: '40141651',
		label: 'Válvula de aire',
	},
	{
		code: '40141652',
		label: 'Válvula reductora de presión',
	},
	{
		code: '40141653',
		label: 'Válvula de compuerta',
	},
	{
		code: '40141654',
		label: 'Válvula de regulación',
	},
	{
		code: '40141655',
		label: 'Válvula de vacío',
	},
	{
		code: '40141656',
		label: 'Válvula de brida tipo mariposa',
	},
	{
		code: '40141657',
		label: 'Válvula de control de rociadores',
	},
	{
		code: '40141658',
		label: 'Válvula de bloqueo y sangrado',
	},
	{
		code: '40141659',
		label: 'Válvula horizontal de elevación',
	},
	{
		code: '40141700',
		label: 'Material de ferretería y accesorios',
	},
	{
		code: '40141719',
		label: 'Adaptadores para plomería',
	},
	{
		code: '40141720',
		label: 'Conectores para plomería',
	},
	{
		code: '40141725',
		label: 'Ganchos (soportes) para plomería',
	},
	{
		code: '40141726',
		label: 'Hidrantes',
	},
	{
		code: '40141727',
		label: 'Plomería de ventilación',
	},
	{
		code: '40141731',
		label: 'Boquillas',
	},
	{
		code: '40141732',
		label: 'Centradores (arañas) para plomería',
	},
	{
		code: '40141734',
		label: 'Conectores para mangueras',

		palabrasSimilares: 'Adaptadores de manguera',
	},
	{
		code: '40141735',
		label: 'Embudos',
	},
	{
		code: '40141736',
		label: 'Graseras (de lubricación)',
	},
	{
		code: '40141737',
		label: 'Diafragmas',
	},
	{
		code: '40141738',
		label: 'Tapones de drenaje (de aceite)',
	},
	{
		code: '40141739',
		label: 'Tapas de desagüe',
	},
	{
		code: '40141740',
		label: 'Llave de la gasolina',
	},
	{
		code: '40141741',
		label: 'Orificios de ajuste',
	},
	{
		code: '40141742',
		label: 'Atomizadores',
	},
	{
		code: '40141743',
		label: 'Puntas o capas de boquillas',
	},
	{
		code: '40141744',
		label: 'Cuencos de drenaje',
	},
	{
		code: '40141745',
		label: 'Fusibles de alta temperatura',
	},
	{
		code: '40141746',
		label: 'Mirillas (indicadores de nivel)',
	},
	{
		code: '40141747',
		label: 'Trampa de grasas',
	},
	{
		code: '40141749',
		label: 'Interruptor de vacío',
	},
	{
		code: '40141750',
		label: 'Brazo (de tubería)',
	},
	{
		code: '40141751',
		label: 'Codos (de tubería)',
	},
	{
		code: '40141755',
		label: 'Resorte para evitar movimiento de tubería',
	},
	{
		code: '40141756',
		label: 'Caja de accesorios para conectar tubos',
	},
	{
		code: '40141757',
		label: 'Escudetes',

		palabrasSimilares: 'Ojo de cerradura',
	},
	{
		code: '40141758',
		label: 'Canal de drenaje',
	},
	{
		code: '40141759',
		label: 'Desagües de piso',

		palabrasSimilares: 'Drenaje de piso',
	},
	{
		code: '40141760',
		label: 'Hidrante anti congelamiento',
	},
	{
		code: '40141761',
		label: 'Múltiple (colector) de tubería',
	},
	{
		code: '40141762',
		label: 'Dispositivo protector del medidor de agua',
	},
	{
		code: '40141763',
		label: 'Supresor de golpes de ariete',
	},
	{
		code: '40141764',
		label: 'Ensamblaje de mangueras',
	},
	{
		code: '40141765',
		label: 'Separador de drenaje',
	},
	{
		code: '40141766',
		label: 'Regulador de cierre con asiento para trabajos de agua',
	},
	{
		code: '40141767',
		label: 'Zapatas para tubería',
	},
	{
		code: '40141768',
		label: 'Adaptador desmontable',
	},
	{
		code: '40141769',
		label: 'Caja o cámara de válvulas',
	},
	{
		code: '40141770',
		label: 'Máquina de fusión de tubos',
	},
	{
		code: '40141900',
		label: 'Conductos',
	},
	{
		code: '40141901',
		label: 'Conductos flexibles',
	},
	{
		code: '40141902',
		label: 'Conductos rígidos',
	},
	{
		code: '40141903',
		label: 'Conductos o red de conductos de manganeso',
	},
	{
		code: '40141904',
		label: 'Conductos o red de conductos de aleación ferrosa',
	},
	{
		code: '40141905',
		label: 'Conductos o red de conductos de titanio',
	},
	{
		code: '40141906',
		label: 'Conductos o red de conductos de latón',
	},
	{
		code: '40141907',
		label: 'Conductos o red de conductos de latón',
	},
	{
		code: '40141908',
		label: 'Conductos o red de conductos de plomo',
	},
	{
		code: '40141909',
		label: 'Conductos o red de conductos de bronce',
	},
	{
		code: '40141910',
		label: 'Conductos o red de conductos de zinc',
	},
	{
		code: '40141911',
		label: 'Conductos o red de conductos de acero',
	},
	{
		code: '40141912',
		label: 'Conductos o red de conductos de hierro',
	},
	{
		code: '40141913',
		label: 'Conductos o red de conductos de cemento',
	},
	{
		code: '40141914',
		label: 'Conductos o red de conductos de plástico',
	},
	{
		code: '40141915',
		label: 'Conductos o red de conductos de caucho',
	},
	{
		code: '40141916',
		label: 'Conductos o red de conductos de vidrio',
	},
	{
		code: '40141917',
		label: 'Conductos o red de conductos de piedra',
	},
	{
		code: '40141918',
		label: 'Conductos o red de conductos de aleación no ferrosa',
	},
	{
		code: '40141919',
		label: 'Conductos o red de conductos de aluminio',
	},
	{
		code: '40141920',
		label: 'Conductos o red de conductos de acero inoxidable',
	},
	{
		code: '40141921',
		label: 'Conductos o red de conductos de metales preciosos',
	},
	{
		code: '40141922',
		label: 'Conductos o red de conductos de cobre',
	},
	{
		code: '40141923',
		label: 'Conductos o red de conductos de látex',
	},
	{
		code: '40141924',
		label: 'Conductos o red de conductos de nylon',
	},
	{
		code: '40142000',
		label: 'Mangueras',
	},
	{
		code: '40142001',
		label: 'Mangueras de ácido',
	},
	{
		code: '40142002',
		label: 'Mangueras de aire',
	},
	{
		code: '40142003',
		label: 'Mangueras de perforación',
	},
	{
		code: '40142004',
		label: 'Mangueras marítimas',
	},
	{
		code: '40142005',
		label: 'Mangueras para manipular material',
	},
	{
		code: '40142006',
		label: 'Mangueras de aceite',
	},
	{
		code: '40142007',
		label: 'Mangueras especiales',
	},
	{
		code: '40142008',
		label: 'Mangueras de agua',
	},
	{
		code: '40142009',
		label: 'Mangueras multipropósito de aire, agua y gas',
	},
	{
		code: '40142010',
		label: 'Mangueras recubiertas de fluoropolímero',
	},
	{
		code: '40142011',
		label: 'Manguera química',
	},
	{
		code: '40142012',
		label: 'Manguera para alimentos y bebidas',
	},
	{
		code: '40142013',
		label: 'Mangueras para minería',
	},
	{
		code: '40142014',
		label: 'Mangueras de petróleo',
	},
	{
		code: '40142015',
		label: 'Mangueras para limpieza al vapor',
	},
	{
		code: '40142016',
		label: 'Mangueras al vacío',
	},
	{
		code: '40142017',
		label: 'Mangueras de soldadura',
	},
	{
		code: '40142018',
		label: 'Mangueras para rociar',
	},
	{
		code: '40142019',
		label: 'Mangueras de conductos',
	},
	{
		code: '40142020',
		label: 'Mangueras hidráulicas',
	},
	{
		code: '40142021',
		label: 'Mangueras de polvo de piedra',
	},
	{
		code: '40142022',
		label: 'Rollos de mangueras',
	},
	{
		code: '40142200',
		label: 'Reguladores de gas y fluido',
	},
	{
		code: '40142201',
		label: 'Reguladores de gas',
	},
	{
		code: '40142202',
		label: 'Reguladores de fluido',
	},
	{
		code: '40142203',
		label: 'Kits de reparación de reguladores de fluido',
	},
	{
		code: '40142204',
		label: 'Regulador de oxígeno para soldadura',
	},
	{
		code: '40142205',
		label: 'Regulador de acetileno para soldadura',
	},
	{
		code: '40142206',
		label: 'Compuerta de regulación de flujo',
	},
	{
		code: '40142207',
		label: 'Regulador del nivel del agua',
	},
	{
		code: '40142208',
		label: 'Mezclador de gas',
	},
	{
		code: '40142500',
		label: 'Interceptores y coladores',
	},
	{
		code: '40142501',
		label: 'Filtros (coladores) de líquido',
	},
	{
		code: '40142502',
		label: 'Trampas de líquido',
	},
	{
		code: '40142503',
		label: 'Trampas de vapor',
	},
	{
		code: '40142504',
		label: 'Filtros (coladores) de vapor',
	},
	{
		code: '40142505',
		label: 'Filtros (coladores) en y',
	},
	{
		code: '40142506',
		label: 'Filtros (coladores) en t',
	},
	{
		code: '40142507',
		label: 'Filtros (coladores) cónicos',
	},
	{
		code: '40142508',
		label: 'Filtros (coladores) de canasta',
	},
	{
		code: '40142509',
		label: 'Trampa de aceite',
	},
	{
		code: '40142510',
		label: 'Trampa de vapor termostática',
	},
	{
		code: '40142511',
		label: 'Trampa de vapor termodinámica',
	},
	{
		code: '40142512',
		label: 'Respiradero',
	},
	{
		code: '40142513',
		label: 'Trampa p',
	},
	{
		code: '40142514',
		label: 'Trampa j',
	},
	{
		code: '40142515',
		label: 'Trampa s',
	},
	{
		code: '40151500',
		label: 'Bombas',
	},
	{
		code: '40151501',
		label: 'Bombas de aire',
	},
	{
		code: '40151502',
		label: 'Bombas de vacío',
	},
	{
		code: '40151503',
		label: 'Bombas centrífugas',
	},
	{
		code: '40151504',
		label: 'Bombas de circulación',
	},
	{
		code: '40151505',
		label: 'Bombas dosificadoras',
	},
	{
		code: '40151506',
		label: 'Bombas de mano',
	},
	{
		code: '40151507',
		label: 'Bombas de irrigación',
	},
	{
		code: '40151508',
		label: 'Bombas de barro',
	},
	{
		code: '40151509',
		label: 'Bombas recíprocas',
	},
	{
		code: '40151510',
		label: 'Bombas de agua',
	},
	{
		code: '40151511',
		label: 'Bombas para pozos',
	},
	{
		code: '40151512',
		label: 'Bombas para sumideros',
	},
	{
		code: '40151513',
		label: 'Bombas sumergibles',
	},
	{
		code: '40151514',
		label: 'Bombas de vapor',
	},
	{
		code: '40151515',
		label: 'Bombas solenoides',
	},
	{
		code: '40151516',
		label: 'Bombas de corte',
	},
	{
		code: '40151517',
		label: 'Bombas de alcantarillado',
	},
	{
		code: '40151518',
		label: 'Bombas no selladas',
	},
	{
		code: '40151519',
		label: 'Bombas sanitarias',
	},
	{
		code: '40151520',
		label: 'Bombas de muestreo',
	},
	{
		code: '40151521',
		label: 'Bombas giratorias',
	},
	{
		code: '40151522',
		label: 'Bombas de osmosis inversa',
	},
	{
		code: '40151523',
		label: 'Bombas de desplazamiento positivo',
	},
	{
		code: '40151524',
		label: 'Bombas de aceite',
	},
	{
		code: '40151525',
		label: 'Bombas de lodo',
	},
	{
		code: '40151526',
		label: 'Bombas de turbina',
	},
	{
		code: '40151527',
		label: 'Bombas de émbolo',
	},
	{
		code: '40151528',
		label: 'Bombas oscilantes',
	},
	{
		code: '40151529',
		label: 'Bombas de tambor',
	},
	{
		code: '40151530',
		label: 'Bombas de dragado',
	},
	{
		code: '40151531',
		label: 'Bombas para remover agua',
	},
	{
		code: '40151532',
		label: 'Bombas de combustible',
	},
	{
		code: '40151533',
		label: 'Bombas hidráulicas',
	},
	{
		code: '40151534',
		label: 'Bombas criogénicas',
	},
	{
		code: '40151546',
		label: 'Bombas de partición axial',
	},
	{
		code: '40151547',
		label: 'Bombas de pozo profundo',
	},
	{
		code: '40151548',
		label: 'Bombas de diafragma',
	},
	{
		code: '40151549',
		label: 'Bombas de doble diafragma',
	},
	{
		code: '40151550',
		label: 'Bombas dúplex',
	},
	{
		code: '40151551',
		label: 'Bombas de engranaje',
	},
	{
		code: '40151552',
		label: 'Bombas de metraje o inyección o proporcionales',
	},
	{
		code: '40151553',
		label: 'Bombas de cavidad progresiva',
	},
	{
		code: '40151554',
		label: 'Bombas de pistón',
	},
	{
		code: '40151555',
		label: 'Bombas de resalte rotatorias',
	},
	{
		code: '40151556',
		label: 'Bombas de leva rotatorias',
	},
	{
		code: '40151557',
		label: 'Bombas de pistón rotatorias',
	},
	{
		code: '40151558',
		label: 'Bombas de tuerca',
	},
	{
		code: '40151559',
		label: 'Bombas simplex',
	},
	{
		code: '40151560',
		label: 'Bombas de paleta deslizante',
	},
	{
		code: '40151561',
		label: 'Bombas triplex',
	},
	{
		code: '40151562',
		label: 'Bombas de tornillo',
	},
	{
		code: '40151563',
		label: 'Sets de bombas contra incendio',
	},
	{
		code: '40151564',
		label: 'Bombas químicas',
	},
	{
		code: '40151565',
		label: 'Bombas de macerar',
	},
	{
		code: '40151566',
		label: 'Bomba elevadora de presión',
	},
	{
		code: '40151567',
		label: 'Bomba de alta temperatura',
	},
	{
		code: '40151568',
		label: 'Bomba de pulpa',
	},
	{
		code: '40151569',
		label: 'Bomba monoflex',
	},
	{
		code: '40151570',
		label: 'Bomba de flujo mixto',
	},
	{
		code: '40151571',
		label: 'Bomba longitudinal',
	},
	{
		code: '40151572',
		label: 'Bomba de elevación de aire',
	},
	{
		code: '40151573',
		label: 'Bomba de magneto',
	},
	{
		code: '40151574',
		label: 'Bomba eyectora',
	},
	{
		code: '40151575',
		label: 'Bomba enfriadora sin cepillo',
	},
	{
		code: '40151576',
		label: 'Bomba eléctrica',
	},
	{
		code: '40151577',
		label: 'Bomba de lechada',
	},
	{
		code: '40151578',
		label: 'Bomba de aire para buceo',
	},
	{
		code: '40151579',
		label: 'Bomba de gravedad',
	},
	{
		code: '40151580',
		label: 'Bomba lubricante',
	},
	{
		code: '40151600',
		label: 'Compresores',
	},
	{
		code: '40151601',
		label: 'Compresores de aire',
	},
	{
		code: '40151602',
		label: 'Compresores de flujo axiales',
	},
	{
		code: '40151603',
		label: 'Compresores de diafragma',
	},
	{
		code: '40151604',
		label: 'Compresores de gas',
	},
	{
		code: '40151605',
		label: 'Compresores de motor',
	},
	{
		code: '40151606',
		label: 'Compresores recíprocos',
	},
	{
		code: '40151607',
		label: 'Compresores refrigerantes',
	},
	{
		code: '40151608',
		label: 'Compresores rotativos',
	},
	{
		code: '40151609',
		label: 'Compresores de tuerca',
	},
	{
		code: '40151611',
		label: 'Compresores de barril',
	},
	{
		code: '40151612',
		label: 'Compresores centrífugos',
	},
	{
		code: '40151613',
		label: 'Compresores de combinación',
	},
	{
		code: '40151614',
		label: 'Compresores semi radiales',
	},
	{
		code: '40151615',
		label: 'Turbo compresores',
	},
	{
		code: '40151616',
		label: 'Kits de compresores',
	},
	{
		code: '40151700',
		label: 'Piezas y accesorios de bomba',
	},
	{
		code: '40151701',
		label: 'Carcasas para bombas',
	},
	{
		code: '40151712',
		label: 'Empaques para bombas',
	},
	{
		code: '40151713',
		label: 'Revestimientos para bombas',
	},
	{
		code: '40151714',
		label: 'Barriles para bombas',
	},
	{
		code: '40151715',
		label: 'Poleas para bombas',
	},
	{
		code: '40151716',
		label: 'Cabezas para bombas',
	},
	{
		code: '40151717',
		label: 'Discos para bombas',
	},
	{
		code: '40151718',
		label: 'Partes de repuesto para bombas de lodo',
	},
	{
		code: '40151719',
		label: 'Partes de repuesto para bombas de alcantarillado',
	},
	{
		code: '40151720',
		label: 'Partes de repuesto para bombas sumergibles',
	},
	{
		code: '40151721',
		label: 'Partes de repuesto para bombas de agua',
	},
	{
		code: '40151722',
		label: 'Partes de repuesto para bombas de pozo',
	},
	{
		code: '40151723',
		label: 'Partes de repuesto para bombas de sumidero',
	},
	{
		code: '40151724',
		label: 'Partes de repuesto para bombas dosificadoras',
	},
	{
		code: '40151725',
		label: 'Partes de repuesto para bombas centrífugas',
	},
	{
		code: '40151726',
		label: 'Partes de repuesto para bombas de circulación',
	},
	{
		code: '40151727',
		label: 'Partes de repuesto para bombas rotatorias',
	},
	{
		code: '40151728',
		label: 'Kits de reparación de bombas',
	},
	{
		code: '40151729',
		label: 'Estator de bombas',
	},
	{
		code: '40151730',
		label: 'Impulsores de bombas',
	},
	{
		code: '40151731',
		label: 'Rotores de bombas',
	},
	{
		code: '40151732',
		label: 'Ejes de bombas',
	},
	{
		code: '40151733',
		label: 'Placas de asiento de bombas',
	},
	{
		code: '40151734',
		label: 'Placas de base de bombas',
	},
	{
		code: '40151735',
		label: 'Ensamblaje de columnas de bombas',
	},
	{
		code: '40151736',
		label: 'Tubos de columna de bombas',
	},
	{
		code: '40151737',
		label: 'Ensamblaje de caja de bombas',
	},
	{
		code: '40151738',
		label: 'Campana de succión de bombas',
	},
	{
		code: '40151739',
		label: 'Partes para bombas recíprocas',
	},
	{
		code: '40151800',
		label: 'Partes o accesorios de compresores',
	},
	{
		code: '40151801',
		label: 'Partes para compresores rotatorios',
	},
	{
		code: '40151802',
		label: 'Partes para compresores de aire',
	},
	{
		code: '40151803',
		label: 'Partes para compresores recíprocos',
	},
	{
		code: '40151804',
		label: 'Partes para compresores centrífugos',
	},
	{
		code: '40161500',
		label: 'Filtros',
	},
	{
		code: '40161501',
		label: 'Filtros al vacío',
	},
	{
		code: '40161502',
		label: 'Filtros de agua',
	},
	{
		code: '40161503',
		label: 'Recolectores de polvo',
	},
	{
		code: '40161504',
		label: 'Filtros de aceite',
	},
	{
		code: '40161505',
		label: 'Filtros de aire',
	},
	{
		code: '40161506',
		label: 'Maquinaria de filtrado',
	},
	{
		code: '40161507',
		label: 'Membranas de filtrado',
	},
	{
		code: '40161508',
		label: 'Filtros de bolsa',
	},
	{
		code: '40161509',
		label: 'Filtros de absorción',
	},
	{
		code: '40161511',
		label: 'Filtros coalescentes',
	},
	{
		code: '40161512',
		label: 'Filtros electrónicos',
	},
	{
		code: '40161513',
		label: 'Filtros de combustible',
	},
	{
		code: '40161514',
		label: 'Filtros para tuberías de gas',
	},
	{
		code: '40161515',
		label: 'Filtros hidráulicos',
	},
	{
		code: '40161516',
		label: 'Filtros en línea',
	},
	{
		code: '40161517',
		label: 'Filtros de luz',
	},
	{
		code: '40161518',
		label: 'Filtros de microfibra',
	},
	{
		code: '40161519',
		label: 'Filtros de panel',
	},
	{
		code: '40161520',
		label: 'Filtros de aletas radiales',
	},
	{
		code: '40161521',
		label: 'Bases para filtros',
	},
	{
		code: '40161522',
		label: 'Aletas para filtros',
	},
	{
		code: '40161524',
		label: 'Filtros de pintura',
	},
	{
		code: '40161525',
		label: 'Contenedores para filtros',
	},
	{
		code: '40161526',
		label: 'Retenedores o accesorios para filtros',
	},
	{
		code: '40161527',
		label: 'Kits de reparación de filtros',
	},
	{
		code: '40161528',
		label: 'Filtros de arena',
	},
	{
		code: '40161529',
		label: 'Filtros de tubo',
	},
	{
		code: '40161530',
		label: 'Filtros de cabina de aire',
	},
	{
		code: '40161531',
		label: 'Ensamblaje de filtros',
	},
	{
		code: '40161532',
		label: 'Malla para filtros',
	},
	{
		code: '40161533',
		label: 'Filtros para desechos sólidos',
	},
	{
		code: '40161600',
		label: 'Purificación',
	},
	{
		code: '40161601',
		label: 'Neutralizador (depurador) de aire',
	},
	{
		code: '40161602',
		label: 'Limpiadores de aire',
	},
	{
		code: '40161603',
		label: 'Máquinas limpiadoras de tubos y tuberías',
	},
	{
		code: '40161604',
		label: 'Regenerador de aceite',
	},
	{
		code: '40161605',
		label: 'Torre desodorizante',
	},
	{
		code: '40161606',
		label: 'Generador de ozono para limpiar el aire',
	},
	{
		code: '40161607',
		label: 'Sistema para desulfurar el gas de combustión',
	},
	{
		code: '40161608',
		label: 'Esterilizador de aire',
	},
	{
		code: '40161700',
		label: 'Separadores',
	},
	{
		code: '40161701',
		label: 'Centrífugas',
	},
	{
		code: '40161702',
		label: 'Lavador húmedo',
	},
	{
		code: '40161703',
		label: 'Eliminadores de niebla',
	},
	{
		code: '40161704',
		label: 'Hidrociclones',
	},
	{
		code: '40161705',
		label: 'Tamiz separador de partículas líquidas',
	},
	{
		code: '40161800',
		label: 'Medios de filtrado',
	},
	{
		code: '40161801',
		label: 'Medios de textiles metálicos',
	},
	{
		code: '40161802',
		label: 'Fieltros prensados',
	},
	{
		code: '40161803',
		label: 'Papeles filtrantes',
	},
	{
		code: '40161804',
		label: 'Ayudas filtrantes',

		palabrasSimilares: 'Ayudas de filtro',
	},
	{
		code: '40161805',
		label: 'Paño filtrante',
	},
	{
		code: '40161806',
		label: 'Malla filtrante',
	},
	{
		code: '40161807',
		label: 'Elemento (dispositivo poroso) filtrante',
	},
	{
		code: '40161808',
		label: 'Medio biológico filtrante',
	},
	{
		code: '40161809',
		label: 'Cartucho filtrante',
	},
	{
		code: '40171500',
		label: 'Tubos y tuberías comerciales',
	},
	{
		code: '40171501',
		label: 'Tubo de acero al carbono soldado para uso comercial',
	},
	{
		code: '40171502',
		label: 'Tubo de acero al carbono sin uniones para uso comercial',
	},
	{
		code: '40171503',
		label: 'Tubo de hierro dúctil para uso comercial',
	},
	{
		code: '40171504',
		label: 'Tubo de aleación de alto níquel para uso comercial',
	},
	{
		code: '40171505',
		label: 'Tubo de acero de alto rendimiento para uso comercial',
	},
	{
		code: '40171506',
		label: 'Tubo de aleación ferrosa para uso comercial',
	},
	{
		code: '40171507',
		label: 'Tubo de aluminio para uso comercial',
	},
	{
		code: '40171508',
		label: 'Tubo de latón para uso comercial',
	},
	{
		code: '40171509',
		label: 'Tubo de bronce para uso comercial',
	},
	{
		code: '40171510',
		label: 'Tubo de concreto para uso comercial',
	},
	{
		code: '40171511',
		label: 'Tubo de cobre para uso comercial',
	},
	{
		code: '40171512',
		label: 'Tubo de hierro forjado para uso comercial',
	},
	{
		code: '40171513',
		label:
			'Concentrador de tubo de hierro forjado ensamblado para uso comercial',
	},
	{
		code: '40171514',
		label: 'Tubo de plomo para uso comercial',
	},
	{
		code: '40171515',
		label: 'Tubo de magnesio para uso comercial',
	},
	{
		code: '40171516',
		label: 'Tubo no ferroso para uso comercial',
	},
	{
		code: '40171517',
		label: 'Tubo pvc para uso comercial',
	},
	{
		code: '40171518',
		label: 'Tubo cpvc para uso comercial',
	},
	{
		code: '40171519',
		label: 'Tubo abs para uso comercial',
	},
	{
		code: '40171520',
		label: 'Tubo hdpe para uso comercial',
	},
	{
		code: '40171521',
		label: 'Tubo de acero inoxidable soldado para uso comercial',
	},
	{
		code: '40171522',
		label: 'Tubo de acero inoxidable sin uniones para uso comercial',
	},
	{
		code: '40171523',
		label: 'Tubo de latón para uso comercial',
	},
	{
		code: '40171524',
		label: 'Tubo de titanio para uso comercial',
	},
	{
		code: '40171525',
		label: 'Tubo de zinc para uso comercial',
	},
	{
		code: '40171526',
		label: 'Tubo de vidrio para uso comercial',
	},
	{
		code: '40171527',
		label: 'Tubo galvanizado corrugado soldado para uso comercial',
	},
	{
		code: '40171600',
		label: 'Tubos y tuberías industriales',
	},
	{
		code: '40171601',
		label: 'Tubo de acero al carbono soldado para uso industrial',
	},
	{
		code: '40171602',
		label: 'Tubo de acero al carbono sin uniones para uso industrial',
	},
	{
		code: '40171603',
		label: 'Tubo de hierro dúctil para uso industrial',
	},
	{
		code: '40171604',
		label: 'Tubo de aleación de alto níquel para uso industrial',
	},
	{
		code: '40171605',
		label: 'Tubo de acero de alto rendimiento para uso industrial',
	},
	{
		code: '40171606',
		label: 'Tubo de aleación ferrosa para uso industrial',
	},
	{
		code: '40171607',
		label: 'Tubo de aluminio para uso industrial',
	},
	{
		code: '40171608',
		label: 'Tubo de latón para uso industrial',
	},
	{
		code: '40171609',
		label: 'Tubo de bronce para uso industrial',
	},
	{
		code: '40171610',
		label: 'Tubo de concreto para uso industrial',
	},
	{
		code: '40171611',
		label: 'Tubo de cobre para uso industrial',
	},
	{
		code: '40171612',
		label: 'Tubo de hierro forjado para uso industrial',
	},
	{
		code: '40171613',
		label:
			'Concentrador de tubo de hierro forjado ensamblado para uso industrial',
	},
	{
		code: '40171614',
		label: 'Tubo de plomo para uso industrial',
	},
	{
		code: '40171615',
		label: 'Tubo de magnesio para uso industrial',
	},
	{
		code: '40171616',
		label: 'Tubo no ferroso para uso industrial',
	},
	{
		code: '40171617',
		label: 'Tubo pvc para uso industrial',
	},
	{
		code: '40171618',
		label: 'Tubo cpvc para uso industrial',
	},
	{
		code: '40171619',
		label: 'Tubo abs para uso industrial',
	},
	{
		code: '40171620',
		label: 'Tubo hdpe para uso industrial',
	},
	{
		code: '40171621',
		label: 'Tubo de acero inoxidable soldado para uso industrial',
	},
	{
		code: '40171622',
		label: 'Tubo de acero inoxidable sin uniones para uso industrial',
	},
	{
		code: '40171623',
		label: 'Tubo de latón para uso industrial',
	},
	{
		code: '40171624',
		label: 'Tubo de titanio para uso industrial',
	},
	{
		code: '40171625',
		label: 'Tubo de zinc para uso industrial',
	},
	{
		code: '40171626',
		label: 'Tubo de vidrio para uso industrial',
	},
	{
		code: '40171700',
		label: 'Adaptadores de tubos',
	},
	{
		code: '40171701',
		label: 'Adaptador de tubo de latón',
	},
	{
		code: '40171702',
		label: 'Adaptador de tubo de acero al carbono',
	},
	{
		code: '40171703',
		label: 'Adaptador de tubo de hierro forjado',
	},
	{
		code: '40171704',
		label: 'Adaptador de tubo de hierro dúctil',
	},
	{
		code: '40171705',
		label: 'Adaptador de tubo de acero forjado',
	},
	{
		code: '40171706',
		label: 'Adaptador de tubo de hierro maleable',
	},
	{
		code: '40171707',
		label: 'Adaptador de tubo de acero inoxidable',
	},
	{
		code: '40171708',
		label: 'Adaptador de tubo plástico pvc',
	},
	{
		code: '40171709',
		label: 'Adaptador de tubo plástico cpvc',
	},
	{
		code: '40171710',
		label: 'Adaptador de tubo plástico abs',
	},
	{
		code: '40171711',
		label: 'Adaptador de tubo plástico hdpe',
	},
	{
		code: '40171800',
		label: 'Anillos frontales de ángulos de tubos',
	},
	{
		code: '40171801',
		label: 'Anillo frontal de ángulo de tubo de acero al carbono',
	},
	{
		code: '40171802',
		label: 'Anillo frontal de ángulo de tubo de acero forjado',
	},
	{
		code: '40171803',
		label: 'Anillo frontal de ángulo de tubo de acero inoxidable',
	},
	{
		code: '40171900',
		label: 'Bridas de respaldo para tubos',
	},
	{
		code: '40171901',
		label: 'Brida de respaldo para tubos de acero al carbono',
	},
	{
		code: '40171902',
		label: 'Brida de respaldo para tubos de acero forjado',
	},
	{
		code: '40171903',
		label: 'Brida de respaldo para tubos de acero inoxidable',
	},
	{
		code: '40171904',
		label: 'Brida de respaldo para tubos de hierro dúctil',
	},
	{
		code: '40171905',
		label: 'Brida de respaldo para tubos de cobre',
	},
	{
		code: '40172000',
		label: 'Deflectores para tubos',
	},
	{
		code: '40172001',
		label: 'Deflectores para tubos de latón',

		palabrasSimilares: 'Coples para tubos de latón',
	},
	{
		code: '40172002',
		label: 'Deflectores para tubos de hierro dúctil',

		palabrasSimilares: 'Coples para tubos de hierro dúctil',
	},
	{
		code: '40172003',
		label: 'Deflectores para tubos de acero forjado',

		palabrasSimilares: 'Coples para tubos de acero forjado',
	},
	{
		code: '40172004',
		label: 'Deflectores para tubos de acero inoxidable',

		palabrasSimilares: 'Coples para tubos de acero inoxidable',
	},
	{
		code: '40172005',
		label: 'Deflectores para tubos de plástico pvc',

		palabrasSimilares: 'Coples para tubos de plástico PVC',
	},
	{
		code: '40172006',
		label: 'Deflectores para tubos de plástico cpvc',

		palabrasSimilares: 'Coples para tubos de plástico cpvc',
	},
	{
		code: '40172007',
		label: 'Deflectores para tubos de plástico abs',

		palabrasSimilares: 'Coples para tubos de plástico ABS',
	},
	{
		code: '40172008',
		label: 'Deflectores para tubos de plástico hdpe',

		palabrasSimilares: 'Coples para tubos de plástico hdpe',
	},
	{
		code: '40172100',
		label: 'Codos para tubos',
	},
	{
		code: '40172101',
		label: 'Codos para tubos de hierro forjado',
	},
	{
		code: '40172102',
		label: 'Codos para tubos de hierro maleable',
	},
	{
		code: '40172103',
		label: 'Codos para tubos de cobre',
	},
	{
		code: '40172200',
		label: 'Bridas ciegas para tubos',
	},
	{
		code: '40172201',
		label: 'Bridas ciegas para tubos de acero al carbono',
	},
	{
		code: '40172202',
		label: 'Bridas ciegas para tubos de hierro forjado',
	},
	{
		code: '40172203',
		label: 'Bridas ciegas para tubos de hierro dúctil',
	},
	{
		code: '40172204',
		label: 'Bridas ciegas para tubos de acero forjado',
	},
	{
		code: '40172205',
		label: 'Bridas ciegas para tubos de acero inoxidable',
	},
	{
		code: '40172206',
		label: 'Bridas ciegas para tubos de cobre',
	},
	{
		code: '40172300',
		label: 'Casquillos para tubos',
	},
	{
		code: '40172301',
		label: 'Casquillos para tubos de latón',
	},
	{
		code: '40172302',
		label: 'Casquillos para tubos de acero al carbono',
	},
	{
		code: '40172303',
		label: 'Casquillos para tubos de hierro forjado',
	},
	{
		code: '40172304',
		label: 'Casquillos para tubos de hierro dúctil',
	},
	{
		code: '40172305',
		label: 'Casquillos para tubos de acero forjado',
	},
	{
		code: '40172306',
		label: 'Casquillos para tubos de hierro maleable',
	},
	{
		code: '40172307',
		label: 'Casquillos para tubos de acero inoxidable',
	},
	{
		code: '40172308',
		label: 'Casquillos para tubos de plástico pvc',
	},
	{
		code: '40172309',
		label: 'Casquillos para tubos de plástico cpvc',
	},
	{
		code: '40172310',
		label: 'Casquillos para tubos de plástico abs',
	},
	{
		code: '40172311',
		label: 'Casquillos para tubos de plástico hdpe',
	},
	{
		code: '40172312',
		label: 'Casquillos para tubos de cobre',
	},
	{
		code: '40172313',
		label: 'Casquillos para tubos de caucho',
	},
	{
		code: '40172400',
		label: 'Tapas para tubos',
	},
	{
		code: '40172401',
		label: 'Tapa de tubo de latón',
	},
	{
		code: '40172402',
		label: 'Tapa de tubo de acero al carbono',
	},
	{
		code: '40172403',
		label: 'Tapa de tubo de hierro forjado',
	},
	{
		code: '40172404',
		label: 'Tapa de tubo de hierro dúctil',
	},
	{
		code: '40172405',
		label: 'Tapa de tubo de acero forjado',
	},
	{
		code: '40172406',
		label: 'Tapa de tubo de hierro maleable',
	},
	{
		code: '40172407',
		label: 'Tapa de tubo de acero inoxidable',
	},
	{
		code: '40172408',
		label: 'Tapa de tubo de plástico pvc',
	},
	{
		code: '40172409',
		label: 'Tapa de tubo de plástico cpvc',
	},
	{
		code: '40172410',
		label: 'Tapa de tubo de plástico abs',
	},
	{
		code: '40172411',
		label: 'Tapa de tubo de plástico hdpe',
	},
	{
		code: '40172412',
		label: 'Tapa de tubo de cobre',
	},
	{
		code: '40172413',
		label: 'Tapa de tubo de caucho',
	},
	{
		code: '40172500',
		label: 'Conectores de tubos',
	},
	{
		code: '40172501',
		label: 'Conector de tubo de latón',
	},
	{
		code: '40172502',
		label: 'Conector de tubo de acero al carbono',
	},
	{
		code: '40172503',
		label: 'Conector de tubo de hierro forjado',
	},
	{
		code: '40172504',
		label: 'Conector de tubo de hierro dúctil',
	},
	{
		code: '40172505',
		label: 'Conector de tubo de acero forjado',
	},
	{
		code: '40172506',
		label: 'Conector de tubo de hierro maleable',
	},
	{
		code: '40172507',
		label: 'Conector de tubo de acero inoxidable',
	},
	{
		code: '40172508',
		label: 'Conector de tubo de plástico pvc',
	},
	{
		code: '40172509',
		label: 'Conector de tubo de plástico cpvc',
	},
	{
		code: '40172510',
		label: 'Conector de tubo de plástico abs',
	},
	{
		code: '40172511',
		label: 'Conector de tubo de plástico hdpe',
	},
	{
		code: '40172512',
		label: 'Conector de tubo de plástico vidrio reforzado termoestable',
	},
	{
		code: '40172513',
		label: 'Conector de tubo flexible',
	},
	{
		code: '40172514',
		label: 'Conector de tubo pre aislado',
	},
	{
		code: '40172515',
		label: 'Conector de tubo de poli butileno',
	},
	{
		code: '40172516',
		label: 'Conector de tubo de polietileno',
	},
	{
		code: '40172517',
		label: 'Conector de tubo de polipropileno',
	},
	{
		code: '40172518',
		label: 'Conector de tubo de acero revestido',
	},
	{
		code: '40172519',
		label: 'Conector de tubo de aleación de aluminio y de aluminio',
	},
	{
		code: '40172520',
		label: 'Conector de tubo de clorhidrato de polivinilo rígido',
	},
	{
		code: '40172521',
		label: 'Conector de tubo de cobre y de aleación de cobre',
	},
	{
		code: '40172522',
		label: 'Uniones de tubo flexible',
	},
	{
		code: '40172600',
		label: 'Acoples de tubos',
	},
	{
		code: '40172601',
		label: 'Acoples de tubos de latón',
	},
	{
		code: '40172602',
		label: 'Acoples de tubos de acero al carbono',
	},
	{
		code: '40172603',
		label: 'Acoples de tubos de hierro forjado',
	},
	{
		code: '40172604',
		label: 'Acoples de tubos de hierro dúctil',
	},
	{
		code: '40172605',
		label: 'Acoples de tubos de acero forjado',
	},
	{
		code: '40172606',
		label: 'Acoples de tubos de hierro maleable',
	},
	{
		code: '40172607',
		label: 'Acoples de tubos de acero inoxidable',
	},
	{
		code: '40172608',
		label: 'Acoples de tubos de plástico pvc',
	},
	{
		code: '40172609',
		label: 'Acoples de tubos de plástico cpvc',
	},
	{
		code: '40172610',
		label: 'Acoples de tubos abs',
	},
	{
		code: '40172611',
		label: 'Acoples de tubos de plástico hdpe',
	},
	{
		code: '40172612',
		label: 'Acoples de tubos de cobre',
	},
	{
		code: '40172700',
		label: 'Cruces de tubos',
	},
	{
		code: '40172701',
		label: 'Cruces de tubos de latón',
	},
	{
		code: '40172702',
		label: 'Cruces de tubos de acero al carbono',
	},
	{
		code: '40172703',
		label: 'Cruces de tubos de hierro forjado',
	},
	{
		code: '40172704',
		label: 'Cruces de tubos de hierro dúctil',
	},
	{
		code: '40172705',
		label: 'Cruces de tubos de acero forjado',
	},
	{
		code: '40172706',
		label: 'Cruces de tubos de hierro maleable',
	},
	{
		code: '40172707',
		label: 'Cruces de tubos de acero inoxidable',
	},
	{
		code: '40172708',
		label: 'Cruces de tubos de plástico pvc',
	},
	{
		code: '40172709',
		label: 'Cruces de tubos de plástico cpvc',
	},
	{
		code: '40172710',
		label: 'Cruces de tubos de plástico abs',
	},
	{
		code: '40172711',
		label: 'Cruces de tubos de plástico hdpe',
	},
	{
		code: '40172712',
		label: 'Cruces de tubos de cobre',
	},
	{
		code: '40172800',
		label: 'Codos de tubos',
	},
	{
		code: '40172801',
		label: 'Codos para tubos de latón',
	},
	{
		code: '40172802',
		label: 'Codos para tubos de acero al carbono',
	},
	{
		code: '40172803',
		label: 'Codos para tubos de hierro forjado',
	},
	{
		code: '40172804',
		label: 'Codos para tubos de hierro dúctil',
	},
	{
		code: '40172805',
		label: 'Codos para tubos de acero forjado',
	},
	{
		code: '40172806',
		label: 'Codos para tubos de hierro maleable',
	},
	{
		code: '40172807',
		label: 'Codos para tubos de acero inoxidable',
	},
	{
		code: '40172808',
		label: 'Codos para tubos de plástico pvc',
	},
	{
		code: '40172809',
		label: 'Codos para tubos de plástico cpvc',
	},
	{
		code: '40172810',
		label: 'Codos para tubos de plástico abs',
	},
	{
		code: '40172811',
		label: 'Codos para tubos de plástico hdpe',
	},
	{
		code: '40172812',
		label: 'Codos para tubos de cobre',
	},
	{
		code: '40172900',
		label: 'Uniones de extensión de tubos',

		palabrasSimilares: 'Uniones o niples de extensión de tubos',
	},
	{
		code: '40172901',
		label: 'Uniones de extensión para tubos de acero al carbono',

		palabrasSimilares:
			'Uniones o niples de extensión para tubos de acero al carbono',
	},
	{
		code: '40172902',
		label: 'Uniones de extensión para tubos de hierro forjado',

		palabrasSimilares:
			'Uniones o niples de extensión para tubos de hierro forjado',
	},
	{
		code: '40172903',
		label: 'Uniones de extensión para tubos de hierro dúctil',

		palabrasSimilares:
			'Uniones o niples de extensión para tubos de hierro dúctil',
	},
	{
		code: '40172904',
		label: 'Uniones de extensión para tubos de acero forjado',

		palabrasSimilares:
			'Uniones o niples de extensión para tubos de acero forjado',
	},
	{
		code: '40172905',
		label: 'Uniones de extensión para tubos de acero inoxidable',

		palabrasSimilares:
			'Uniones o niples de extensión para tubos de acero inoxidable',
	},
	{
		code: '40172906',
		label: 'Uniones de extensión para tubos de plástico pvc',

		palabrasSimilares:
			'Uniones o niples de extensión para tubos de plástico PVC',
	},
	{
		code: '40172907',
		label: 'Uniones de extensión para tubos de plástico cpvc',

		palabrasSimilares:
			'Uniones o niples de extensión para tubos de plástico cpvc',
	},
	{
		code: '40172908',
		label: 'Uniones de extensión para tubos de plástico abs',

		palabrasSimilares:
			'Uniones o niples de extensión para tubos de plástico ABS',
	},
	{
		code: '40172909',
		label: 'Uniones de extensión para tubos de plástico hdpe',

		palabrasSimilares:
			'Uniones o niples de extensión para tubos de plástico hdpe',
	},
	{
		code: '40172910',
		label: 'Uniones de extensión para tubos de caucho',

		palabrasSimilares: 'Uniones o niples de extensión para tubos de caucho',
	},
	{
		code: '40172911',
		label: 'Uniones de extensión para tubos de cobre',

		palabrasSimilares: 'Uniones o niples de extensión para tubos de cobre',
	},
	{
		code: '40173000',
		label: 'Semiacoplamientos de tubería',

		palabrasSimilares: 'Reducciones para tubos',
	},
	{
		code: '40173001',
		label: 'Semiacoplamientos para tubos de latón',

		palabrasSimilares: 'Reducciones de latón',
	},
	{
		code: '40173002',
		label: 'Semiacoplamientos para tubos de acero al carbono',

		palabrasSimilares: 'Reducciones de acero al carbono',
	},
	{
		code: '40173003',
		label: 'Semiacoplamientos para tubos de acero forjado',

		palabrasSimilares: 'Reducciones de acero forjado',
	},
	{
		code: '40173004',
		label: 'Semiacoplamientos para tubos de hierro maleable',

		palabrasSimilares: 'Reducciones de hierro maleable',
	},
	{
		code: '40173005',
		label: 'Semiacoplamientos para tubos de acero inoxidable',

		palabrasSimilares: 'Reducciones de acero inoxidable',
	},
	{
		code: '40173006',
		label: 'Semiacoplamientos para tubos de plástico pvc',

		palabrasSimilares: 'Reducciones de plástico PVC',
	},
	{
		code: '40173007',
		label: 'Semiacoplamientos para tubos de plástico cpvc',

		palabrasSimilares: 'Reducciones de plástico cpvc',
	},
	{
		code: '40173008',
		label: 'Semiacoplamientos para tubos de plástico abs',

		palabrasSimilares: 'Reducciones de plástico ABS',
	},
	{
		code: '40173009',
		label: 'Semiacoplamientos para tubos de plástico hdpe',

		palabrasSimilares: 'Reducciones de plástico hdpe',
	},
	{
		code: '40173100',
		label: 'Brida de junta de empalme para tubos',

		palabrasSimilares: 'Juntas de unión para tubos',
	},
	{
		code: '40173101',
		label: 'Brida de junta de empalme para tubos de acero al carbono',

		palabrasSimilares: 'Junta de unión para tubos de acero al carbono',
	},
	{
		code: '40173102',
		label: 'Brida de junta de empalme para tubos de acero forjado',

		palabrasSimilares: 'Junta de unión para tubos de acero forjado',
	},
	{
		code: '40173103',
		label: 'Brida de junta de empalme para tubos de acero inoxidable',

		palabrasSimilares: 'Junta de unión para tubos de acero inoxidable',
	},
	{
		code: '40173104',
		label: 'Brida de junta de empalme para tubos de cobre',

		palabrasSimilares: 'Junta de unión para tubos de cobre',
	},
	{
		code: '40173200',
		label: 'Bridas cuello largas para soldar',

		palabrasSimilares: 'Juntas de cuello largo para soldar',
	},
	{
		code: '40173201',
		label: 'Bridas cuello largas para soldar para tubos de acero al carbono',

		palabrasSimilares:
			'Junta de cuello largo para soldar para tubos de acero al carbono',
	},
	{
		code: '40173202',
		label: 'Bridas cuello largas para soldar para tubos de acero forjado',

		palabrasSimilares:
			'Junta de cuello largo para soldar para tubos de acero forjado',
	},
	{
		code: '40173203',
		label: 'Bridas cuello largas para soldar para tubos de acero inoxidable',

		palabrasSimilares:
			'Junta de cuello largo para soldar para tubos de acero inoxidable',
	},
	{
		code: '40173300',
		label: 'Boquillas de tubos',
	},
	{
		code: '40173301',
		label: 'Boquillas para tubos de latón',
	},
	{
		code: '40173302',
		label: 'Boquillas para tubos de acero al carbono',
	},
	{
		code: '40173303',
		label: 'Boquillas para tubos de acero inoxidable',
	},
	{
		code: '40173304',
		label: 'Boquillas para tubos de plástico pvc',
	},
	{
		code: '40173305',
		label: 'Boquillas para tubos de plástico cpvc',
	},
	{
		code: '40173306',
		label: 'Boquillas para tubos de plástico abs',
	},
	{
		code: '40173307',
		label: 'Boquillas para tubos de plástico hdpe',
	},
	{
		code: '40173400',
		label: 'Bridas de plato',
	},
	{
		code: '40173401',
		label: 'Bridas de plato para tubos de latón',
	},
	{
		code: '40173402',
		label: 'Bridas de plato para tubos de acero al carbono',
	},
	{
		code: '40173403',
		label: 'Bridas de plato para tubos de hierro forjado',
	},
	{
		code: '40173404',
		label: 'Bridas de plato para tubos de hierro dúctil',
	},
	{
		code: '40173405',
		label: 'Bridas de plato para tubos de hierro maleable',
	},
	{
		code: '40173406',
		label: 'Bridas de plato para tubos de acero inoxidable',
	},
	{
		code: '40173407',
		label: 'Bridas de plato para tubos de plástico abs',
	},
	{
		code: '40173408',
		label: 'Bridas de plato para tubos de plástico pvc',
	},
	{
		code: '40173409',
		label: 'Bridas de plato para tubos de cobre',
	},
	{
		code: '40173500',
		label: 'Tapones para tubos',
	},
	{
		code: '40173501',
		label: 'Tapones para tubos de latón',
	},
	{
		code: '40173502',
		label: 'Tapones para tubos de acero al carbono',
	},
	{
		code: '40173503',
		label: 'Tapones para tubos de hierro forjado',
	},
	{
		code: '40173504',
		label: 'Tapones para tubos de hierro dúctil',
	},
	{
		code: '40173505',
		label: 'Tapones para tubos de acero forjado',
	},
	{
		code: '40173506',
		label: 'Tapones para tubos de hierro maleable',
	},
	{
		code: '40173507',
		label: 'Tapones para tubos de acero inoxidable',
	},
	{
		code: '40173508',
		label: 'Tapones para tubos de plástico pvc',
	},
	{
		code: '40173509',
		label: 'Tapones para tubos de plástico cpvc',
	},
	{
		code: '40173510',
		label: 'Tapones para tubos de plástico abs',
	},
	{
		code: '40173511',
		label: 'Tapones para tubos de plástico hdpe',
	},
	{
		code: '40173512',
		label: 'Tapones para tubos de cobre',
	},
	{
		code: '40173513',
		label: 'Tapones para tubos de caucho',
	},
	{
		code: '40173600',
		label: 'Acoplamientos reductores para tubos',

		palabrasSimilares: 'Coples reductores',
	},
	{
		code: '40173601',
		label: 'Acoplamiento reductor para tubos de latón',

		palabrasSimilares: 'Cople reductor para tubos de latón',
	},
	{
		code: '40173602',
		label: 'Acoplamiento reductor para tubos de acero al carbono',

		palabrasSimilares: 'Cople reductor para tubos de acero al carbono',
	},
	{
		code: '40173603',
		label: 'Acoplamiento reductor para tubos de hierro forjado',

		palabrasSimilares: 'Cople reductor para tubos de hierro forjado',
	},
	{
		code: '40173604',
		label: 'Acoplamiento reductor para tubos de hierro dúctil',

		palabrasSimilares: 'Cople reductor para tubos de hierro dúctil',
	},
	{
		code: '40173605',
		label: 'Acoplamiento reductor para tubos de acero forjado',

		palabrasSimilares: 'Cople reductor para tubos de acero forjado',
	},
	{
		code: '40173606',
		label: 'Acoplamiento reductor para tubos de hierro maleable',

		palabrasSimilares: 'Cople reductor para tubos de hierro maleable',
	},
	{
		code: '40173607',
		label: 'Acoplamiento reductor para tubos de acero inoxidable',

		palabrasSimilares: 'Cople reductor para tubos de acero inoxidable',
	},
	{
		code: '40173608',
		label: 'Acoplamiento reductor para tubos de plástico pvc',

		palabrasSimilares: 'Cople reductor para tubos de plástico PVC',
	},
	{
		code: '40173609',
		label: 'Acoplamiento reductor para tubos de plástico cpvc',

		palabrasSimilares: 'Cople reductor para tubos de plástico cpvc',
	},
	{
		code: '40173610',
		label: 'Acoplamiento reductor para tubos de plástico abs',
	},
	{
		code: '40173611',
		label: 'Acoplamiento reductor para tubos de plástico hdpe',
	},
	{
		code: '40173612',
		label: 'Acoplamiento reductor para tubos de caucho',

		palabrasSimilares: 'Cople reductor para tubos de caucho',
	},
	{
		code: '40173613',
		label: 'Acoplamiento reductor para tubos de cobre',

		palabrasSimilares: 'Cople reductor para tubos de cobre',
	},
	{
		code: '40173700',
		label: 'Bridas reductoras para tubos',
	},
	{
		code: '40173701',
		label: 'Brida reductora para tubos de latón',
	},
	{
		code: '40173702',
		label: 'Brida reductora para tubos de hierro forjado',
	},
	{
		code: '40173703',
		label: 'Brida reductora para tubos de hierro dúctil',
	},
	{
		code: '40173704',
		label: 'Brida reductora para tubos de acero forjado',
	},
	{
		code: '40173705',
		label: 'Brida reductora para tubos de hierro maleable',
	},
	{
		code: '40173706',
		label: 'Brida reductora para tubos de acero inoxidable',
	},
	{
		code: '40173707',
		label: 'Brida reductora para tubos de plástico pvc',
	},
	{
		code: '40173708',
		label: 'Brida reductora para tubos de plástico cpvc',
	},
	{
		code: '40173709',
		label: 'Brida reductora para tubos de plástico abs',
	},
	{
		code: '40173710',
		label: 'Brida reductora para tubos de plástico hdpe',
	},
	{
		code: '40173711',
		label: 'Brida reductora para tubos de cobre',
	},
	{
		code: '40173800',
		label: 'Abrazaderas de reparación para tubos',
	},
	{
		code: '40173801',
		label: 'Abrazadera de reparación para tubos de hierro forjado',
	},
	{
		code: '40173802',
		label: 'Abrazadera de reparación para tubos de hierro dúctil',
	},
	{
		code: '40173803',
		label: 'Abrazadera de reparación para tubos de acero forjado',
	},
	{
		code: '40173804',
		label: 'Abrazadera de reparación para tubos de acero inoxidable',
	},
	{
		code: '40173805',
		label: 'Abrazadera de reparación para tubos de plástico pvc',
	},
	{
		code: '40173806',
		label: 'Abrazadera de reparación para tubos de plástico cpvc',
	},
	{
		code: '40173807',
		label: 'Abrazadera de reparación para tubos de plástico abs',
	},
	{
		code: '40173808',
		label: 'Abrazadera de reparación para tubos de plástico hdpe',
	},
	{
		code: '40173900',
		label: 'Disco de ruptura para tubos',
	},
	{
		code: '40173901',
		label: 'Disco de ruptura para tubos de acero al carbono',
	},
	{
		code: '40173902',
		label: 'Disco de ruptura para tubos de hierro forjado',
	},
	{
		code: '40173903',
		label: 'Disco de ruptura para tubos de hierro dúctil',
	},
	{
		code: '40173904',
		label: 'Disco de ruptura para tubos de acero forjado',
	},
	{
		code: '40173905',
		label: 'Disco de ruptura para tubos de hierro maleable',
	},
	{
		code: '40173906',
		label: 'Disco de ruptura para tubos de acero inoxidable',
	},
	{
		code: '40173907',
		label: 'Disco de ruptura para tubos de plástico pvc',
	},
	{
		code: '40173908',
		label: 'Disco de ruptura para tubos de plástico cpvc',
	},
	{
		code: '40173909',
		label: 'Disco de ruptura para tubos de plástico abs',
	},
	{
		code: '40173910',
		label: 'Disco de ruptura para tubos de plástico hdpe',
	},
	{
		code: '40174000',
		label: 'Brida deslizante para tubos',
	},
	{
		code: '40174001',
		label: 'Brida deslizante para tubos de latón',
	},
	{
		code: '40174002',
		label: 'Brida deslizante para tubos de acero al carbono',
	},
	{
		code: '40174003',
		label: 'Brida deslizante para tubos de hierro forjado',
	},
	{
		code: '40174004',
		label: 'Brida deslizante para tubos de hierro dúctil',
	},
	{
		code: '40174005',
		label: 'Brida deslizante para tubos de acero forjado',
	},
	{
		code: '40174006',
		label: 'Brida deslizante para tubos de acero inoxidable',
	},
	{
		code: '40174007',
		label: 'Brida deslizante para tubos de cobre',
	},
	{
		code: '40174100',
		label: 'Brida encajada por soldadura para tubos',
	},
	{
		code: '40174101',
		label: 'Brida encajada por soldadura para tubos de acero al carbono',
	},
	{
		code: '40174102',
		label: 'Brida encajada por soldadura para tubos de acero forjado',
	},
	{
		code: '40174103',
		label: 'Brida encajada por soldadura para tubos de acero inoxidable',
	},
	{
		code: '40174104',
		label: 'Brida encajada por soldadura para tubos de plástico pvc',
	},
	{
		code: '40174105',
		label: 'Brida encajada por soldadura para tubos de plástico cpvc',
	},
	{
		code: '40174106',
		label: 'Brida encajada por soldadura para tubos de plástico abs',
	},
	{
		code: '40174107',
		label: 'Brida encajada por soldadura para tubos de plástico hdpe',
	},
	{
		code: '40174108',
		label: 'Brida encajada por soldadura para tubos de cobre',
	},
	{
		code: '40174200',
		label: 'Sockolets para tubos',
	},
	{
		code: '40174201',
		label: 'Sockolet para tubos de acero al carbono',
	},
	{
		code: '40174202',
		label: 'Sockolet para tubos de acero forjado',
	},
	{
		code: '40174203',
		label: 'Sockolet para tubos de acero inoxidable',
	},
	{
		code: '40174300',
		label: 'Espaciadores para tubos',

		palabrasSimilares: 'Separadores para tubos',
	},
	{
		code: '40174301',
		label: 'Espaciador para tubos de latón',

		palabrasSimilares: 'Separador para tubos de latón',
	},
	{
		code: '40174302',
		label: 'Espaciador para tubos de acero al carbono',

		palabrasSimilares: 'Separador para tubos de acero al carbono',
	},
	{
		code: '40174303',
		label: 'Espaciador para tubos de hierro forjado',

		palabrasSimilares: 'Separador para tubos de hierro forjado',
	},
	{
		code: '40174304',
		label: 'Espaciador para tubos de hierro dúctil',

		palabrasSimilares: 'Separador para tubos de hierro dúctil',
	},
	{
		code: '40174305',
		label: 'Espaciador para tubos de acero forjado',

		palabrasSimilares: 'Separador para tubos de acero forjado',
	},
	{
		code: '40174306',
		label: 'Espaciador para tubos de acero inoxidable',

		palabrasSimilares: 'Separador para tubos de acero inoxidable',
	},
	{
		code: '40174307',
		label: 'Espaciador para tubos de plástico pvc',

		palabrasSimilares: 'Separador para tubos de plástico PVC',
	},
	{
		code: '40174308',
		label: 'Espaciador para tubos de plástico cpvc',

		palabrasSimilares: 'Separador para tubos de plástico cpvc',
	},
	{
		code: '40174309',
		label: 'Espaciador para tubos de plástico abs',

		palabrasSimilares: 'Separador para tubos de plástico ABS',
	},
	{
		code: '40174310',
		label: 'Espaciador para tubos de plástico hdpe',

		palabrasSimilares: 'Separador para tubos de plástico hdpe',
	},
	{
		code: '40174400',
		label: 'Bridas ciegas para tubos',
	},
	{
		code: '40174401',
		label: 'Bridas ciegas para tubos de acero al carbono',
	},
	{
		code: '40174402',
		label: 'Bridas ciegas para tubos de acero forjado',
	},
	{
		code: '40174403',
		label: 'Bridas ciegas para tubos de acero forjado',
	},
	{
		code: '40174500',
		label: 'Pivotes o uniones rotativas para tubos',
	},
	{
		code: '40174501',
		label: 'Pivotes o uniones rotativas para tubos de latón',
	},
	{
		code: '40174502',
		label: 'Pivotes o uniones rotativas para tubos de plástico pvc',
	},
	{
		code: '40174503',
		label: 'Pivotes o uniones rotativas para tubos de plástico cpvc',
	},
	{
		code: '40174504',
		label: 'Pivotes o uniones rotativas para tubos de plástico abs',
	},
	{
		code: '40174505',
		label: 'Pivotes o uniones rotativas para tubos de plástico hdpe',
	},
	{
		code: '40174506',
		label: 'Pivotes o uniones rotativas para tubos de cobre',
	},
	{
		code: '40174600',
		label: 'T para tubos',

		palabrasSimilares: 'Conexiones en T para tubos',
	},
	{
		code: '40174601',
		label: 'T para tubos de latón',

		palabrasSimilares: 'Conexión en T para tubos de latón',
	},
	{
		code: '40174602',
		label: 'T para tubos de acero al carbono',

		palabrasSimilares: 'Conexión en T para tubos de acero al carbono',
	},
	{
		code: '40174603',
		label: 'T para tubos de hierro forjado',

		palabrasSimilares: 'Conexión en T para tubos de hierro forjado',
	},
	{
		code: '40174604',
		label: 'T para tubos de hierro dúctil',

		palabrasSimilares: 'Conexión en T para tubos de hierro dúctil',
	},
	{
		code: '40174605',
		label: 'T para tubos de acero forjado',

		palabrasSimilares: 'Conexión en T para tubos de acero forjado',
	},
	{
		code: '40174606',
		label: 'T para tubos de hierro maleable',

		palabrasSimilares: 'Conexión en T para tubos de hierro maleable',
	},
	{
		code: '40174607',
		label: 'T para tubos de acero inoxidable',

		palabrasSimilares: 'Conexión en T para tubos de acero inoxidable',
	},
	{
		code: '40174608',
		label: 'T para tubos de plástico pvc',

		palabrasSimilares: 'Conexión en T para tubos de plástico PVC',
	},
	{
		code: '40174609',
		label: 'T para tubos de plástico cpvc',

		palabrasSimilares: 'Conexión en T para tubos de plástico cpvc',
	},
	{
		code: '40174610',
		label: 'T para tubos de plástico abs',

		palabrasSimilares: 'Conexión en T para tubos de plástico ABS',
	},
	{
		code: '40174611',
		label: 'T para tubos de plástico hdpe',

		palabrasSimilares: 'Conexión en T para tubos de plástico hdpe',
	},
	{
		code: '40174612',
		label: 'T para tubos de cobre',

		palabrasSimilares: 'Conexión en T para tubos de cobre',
	},
	{
		code: '40174700',
		label: 'Brida roscada para tubos',
	},
	{
		code: '40174701',
		label: 'Brida roscada para tubos de latón',
	},
	{
		code: '40174702',
		label: 'Brida roscada para tubos de acero al carbono',
	},
	{
		code: '40174703',
		label: 'Brida roscada para tubos de hierro forjado',
	},
	{
		code: '40174704',
		label: 'Brida roscada para tubos de hierro dúctil',
	},
	{
		code: '40174705',
		label: 'Brida roscada para tubos de acero forjado',
	},
	{
		code: '40174706',
		label: 'Brida roscada para tubos de hierro maleable',
	},
	{
		code: '40174707',
		label: 'Brida roscada para tubos de acero inoxidable',
	},
	{
		code: '40174708',
		label: 'Brida roscada para tubos de plástico pvc',
	},
	{
		code: '40174709',
		label: 'Brida roscada para tubos de plástico cpvc',
	},
	{
		code: '40174710',
		label: 'Brida roscada para tubos de plástico abs',
	},
	{
		code: '41113673',
		label: 'Medidor de panel y tablero de control',
	},
	{
		code: '41113674',
		label: 'Probador de máquina pulidora',
	},
	{
		code: '41113675',
		label: 'Equipo de prueba de control de frecuencia',
	},
	{
		code: '41113676',
		label: 'Probador de bajo voltaje',
	},
	{
		code: '41113677',
		label: 'Probador de resistencia de voltaje',
	},
	{
		code: '41113678',
		label: 'Probador de abrazadera',
	},
	{
		code: '41113679',
		label:
			'Equipo de prueba de transformador de corriente y transformador potencial',
	},
	{
		code: '41113680',
		label: 'Medidor de factor de energía',
	},
	{
		code: '41113681',
		label: 'Probador combi (voltaje, corriente, frecuencia y otras)',
	},
	{
		code: '41113682',
		label: 'Probador de corriente eléctrica',
	},
	{
		code: '41113683',
		label: 'Probador de lámparas',
	},
	{
		code: '41113684',
		label: 'Calibrador medidor de voltaje y corriente',
	},
	{
		code: '41113685',
		label: 'Equipo para medir electricidad estática',
	},
	{
		code: '41113686',
		label: 'Medidor de pulso',

		palabrasSimilares: 'Oxímetro de pulso',
	},
	{
		code: '41113687',
		label: 'Máquina para probar freno',
	},
	{
		code: '41113688',
		label: 'Equipo para probar convertidores',
	},
	{
		code: '41113689',
		label: 'Equipo para probar controladores',
	},
	{
		code: '41113700',
		label:
			'Instrumentos de medición y comprobación de comunicación electrónica',
	},
	{
		code: '41113701',
		label: 'Probador de tubos de rayos catódicos',
	},
	{
		code: '41113702',
		label: 'Comparadores',

		palabrasSimilares: 'Comparadores analógicos o digitales',
	},
	{
		code: '41113703',
		label: 'Acoplamiento direccional',
	},
	{
		code: '41113704',
		label: 'Probadores de circuitos integrados',
	},
	{
		code: '41113705',
		label: 'Probadores de estado lógico',
	},
	{
		code: '41113706',
		label: 'Probadores de semiconductores',
	},
	{
		code: '41113707',
		label: 'Probadores de circuitos de transistores',
	},
	{
		code: '41113708',
		label: 'Medidores de energía',
	},
	{
		code: '41113709',
		label: 'Medidores de modulación',
	},
	{
		code: '41113710',
		label: 'Medidor de nivel',
	},
	{
		code: '41113711',
		label: 'Analizadores de red',
	},
	{
		code: '41113712',
		label: 'Probadores de cintas',
	},
	{
		code: '41113713',
		label: 'Probadores de velocidad de la cinta',
	},
	{
		code: '41113714',
		label: 'Diferenciador',
	},
	{
		code: '41113715',
		label: 'Probadores de redes digitales de servicios integrados isdn',
	},
	{
		code: '41113716',
		label: 'Localizadores de fallas de fibra óptica',
	},
	{
		code: '41113717',
		label: 'Fuentes de prueba de fibra óptica',
	},
	{
		code: '41113718',
		label: 'Analizadores de protocolo',
	},
	{
		code: '41113719',
		label: 'Probador de pérdida óptica',
	},
	{
		code: '41113720',
		label: 'Equipo de prueba de intensidad del tráfico',
	},
	{
		code: '41113721',
		label: 'Instrumento de medición de señal de video',
	},
	{
		code: '41113722',
		label: 'Probador de interferencia electromagnética (emi)',

		palabrasSimilares: 'Probador de interferencia electromagnética (EMI)',
	},
	{
		code: '41113723',
		label: 'Probador de equipo de radio',
	},
	{
		code: '41113724',
		label: 'Set de prueba de teléfonos',

		palabrasSimilares: 'Equipo de prueba de teléfonos',
	},
	{
		code: '41113725',
		label: 'Medidor de energía óptica',
	},
	{
		code: '41113726',
		label: 'Probador de resistencia al calor',
	},
	{
		code: '41113727',
		label: 'Medidor de distorsión',
	},
	{
		code: '41113728',
		label: 'Cámara ambiental de escudo electromagnético',
	},
	{
		code: '41113729',
		label: 'Medidor de salida del amplificador',
	},
	{
		code: '41113730',
		label: 'Probador de televisiones',
	},
	{
		code: '41113731',
		label: 'Probador de cristales',
	},
	{
		code: '41113732',
		label: 'Probador de amplificadores',
	},
	{
		code: '41113733',
		label: 'Probador de antenas',
	},
	{
		code: '41113734',
		label: 'Medidor de desviación de frecuencia',
	},
	{
		code: '41113735',
		label: 'Probador de equipo de microondas',
	},
	{
		code: '41113736',
		label: 'Refractómetro electrónico y de comunicación',
	},
	{
		code: '41113737',
		label: 'Medidor de ruido',
	},
	{
		code: '41113738',
		label: 'Probador de sobrecarga de la línea de comunicación',
	},
	{
		code: '41113739',
		label: 'Probador de susceptibilidad electromagnética',
	},
	{
		code: '41113740',
		label: 'Probador de cable de video, voz y datos',
	},
	{
		code: '41113800',
		label: 'Instrumentos geofísicos, geotécnicos e hidrológicos',
	},
	{
		code: '41113801',
		label: 'Compases geológicos',

		palabrasSimilares: 'Brújula Brunton, Brújula de geólogo',
	},
	{
		code: '41113802',
		label: 'Aparatos de prospección geológica',
	},
	{
		code: '41113803',
		label: 'Instrumentos geofísicos electromagnéticos',
	},
	{
		code: '41113804',
		label: 'Instrumentos geofísicos de gravedad',
	},
	{
		code: '41113805',
		label: 'Instrumentos geofísicos de polarización inducida ip',
	},
	{
		code: '41113806',
		label: 'Instrumentos geofísicos magnetómetros',
	},
	{
		code: '41113807',
		label: 'Instrumentos geofísicos de resistividad',
	},
	{
		code: '41113808',
		label: 'Gravímetros',

		palabrasSimilares: 'Gravitómetro',
	},
	{
		code: '41113809',
		label: 'Radar de penetración del suelo',
	},
	{
		code: '41113810',
		label: 'Equipo de prueba hidrotérmica',
	},
	{
		code: '41113811',
		label: 'Probador de resistencia sólida',
	},
	{
		code: '41113812',
		label: 'Probador de fricción del suelo',
	},
	{
		code: '41113813',
		label: 'Agregado estándar',
	},
	{
		code: '41113814',
		label: 'Probador de límite plástico del suelo',
	},
	{
		code: '41113815',
		label: 'Probador del límite de encogimiento del suelo',
	},
	{
		code: '41113816',
		label: 'Probador de consolidación',
	},
	{
		code: '41113817',
		label: 'Probador del límite líquido del suelo',
	},
	{
		code: '41113818',
		label: 'Probador de resistencia de la placa',

		palabrasSimilares: 'Probador de rodamientos de placas',
	},
	{
		code: '41113819',
		label: 'Analizador de suelos',
	},
	{
		code: '41113820',
		label: 'Probador de gravedad específica agregada',

		palabrasSimilares: 'Probador agregado de gravedad específica',
	},
	{
		code: '41113821',
		label: 'Probador de penetración del suelo',
	},
	{
		code: '41113822',
		label: 'Probador de tasa de resistencia california',
	},
	{
		code: '41113823',
		label: 'Probador de expansión de espécimen',

		palabrasSimilares: 'Probador de expansión de muestras',
	},
	{
		code: '41113824',
		label: 'Probador de permeabilidad de cabeza de suelo',
	},
	{
		code: '41113825',
		label: 'Analizador de agregado del suelo',
	},
	{
		code: '41113826',
		label: 'Analizador de textura del suelo',
	},
	{
		code: '41113827',
		label: 'Aparato de compresión no confinada del suelo',
	},
	{
		code: '41113828',
		label: 'Aparato de cono de densidad de la arena',
	},
	{
		code: '41113829',
		label: 'Equipo de recolección de suelo bajo el agua',
	},
	{
		code: '41113830',
		label: 'Instrumento para medir el peso de la unidad de agregado',
	},
	{
		code: '41113831',
		label:
			'Instrumento para medir el agua superficial en la superficie del agregado',
	},
	{
		code: '41113900',
		label: 'Equipo para medición de suelos',
	},
	{
		code: '41113901',
		label: 'Instrumentos de medición de perforación',
	},
	{
		code: '41113902',
		label: 'Probadores de disolución o desintegración',
	},
	{
		code: '41113903',
		label: 'Aparatos medidores del tamaño de partículas',
	},
	{
		code: '41113904',
		label: 'Penetrómetros',
	},
	{
		code: '41113905',
		label: 'Aparatos para probar permeabilidad',
	},
	{
		code: '41113906',
		label: 'Aparatos para estimar permeabilidad o porosidad',
	},
	{
		code: '41113907',
		label: 'Porosímetros',
	},
	{
		code: '41113908',
		label: 'Aparatos para probar arena',

		palabrasSimilares: 'Aparatos de prueba de arena',
	},
	{
		code: '41113909',
		label: 'Aparatos muestreadores del núcleo del suelo',
	},
	{
		code: '41113910',
		label: 'Kits de probadores del suelo',
	},
	{
		code: '41114000',
		label: 'Equipo de medida de rocas y estratos',
	},
	{
		code: '41114001',
		label: 'Clinómetros',
	},
	{
		code: '41114100',
		label: 'Instrumentos sismológicos',
	},
	{
		code: '41114102',
		label: 'Simuladores de terremotos',
	},
	{
		code: '41114103',
		label: 'Módulos de alarma sísmica',
	},
	{
		code: '41114104',
		label: 'Amplificadores sísmicos',
	},
	{
		code: '41114105',
		label: 'Aparatos sísmicos portátiles',
	},
	{
		code: '41114106',
		label: 'Sismógrafos o grabadoras sísmicas',
	},
	{
		code: '41114107',
		label: 'Sismómetros',
	},
	{
		code: '41114108',
		label: 'Vibrómetros',
	},
	{
		code: '41114200',
		label: 'Instrumentos de agrimensión',
	},
	{
		code: '41114201',
		label: 'Cintas medidoras',

		palabrasSimilares: 'Cintas métricas',
	},
	{
		code: '41114202',
		label: 'Varillas medidoras',
	},
	{
		code: '41114203',
		label: 'Tablas medidoras',
	},
	{
		code: '41114204',
		label: 'Teodolitos',
	},
	{
		code: '41114205',
		label: 'Estacas de localización',
	},
	{
		code: '41114206',
		label: 'Centro de localización',
	},
	{
		code: '41114207',
		label: 'Estación total',
	},
	{
		code: '41114208',
		label: 'Instrumento de longimetría',
	},
	{
		code: '41114209',
		label: 'Taquímetro o taqueómetro',
	},
	{
		code: '41114210',
		label: 'Nivel óptico',

		palabrasSimilares: 'Nivel topográfico',
	},
	{
		code: '41114211',
		label: 'Altímetro',
	},
	{
		code: '41114212',
		label: 'Estereoscopio de espejo',
	},
	{
		code: '41114213',
		label: 'Comparador de coordinadas',

		palabrasSimilares: 'Comparador de coordenadas',
	},
	{
		code: '41114214',
		label: 'Plantilla de encuestas',
	},
	{
		code: '41114215',
		label: 'Dendrómetro',
	},
	{
		code: '41114216',
		label: 'Alidada',
	},
	{
		code: '41114217',
		label: 'Línea de plomo',
	},
	{
		code: '41114218',
		label: 'Planímetros',
	},
	{
		code: '41114219',
		label: 'Brazo de la plomada',
	},
	{
		code: '41114220',
		label: 'Tránsito',

		palabrasSimilares: 'Niveles de tránsito',
	},
	{
		code: '41114221',
		label: 'Escuadra óptica',
	},
	{
		code: '41114300',
		label: 'Instrumentos hidrológicos',
	},
	{
		code: '41114301',
		label: 'Medidores de corriente para corriente abierta',
	},
	{
		code: '41114302',
		label: 'Instrumentos de acceso a pozos de agua',

		palabrasSimilares: 'Instrumentos de registro para pozos de agua',
	},
	{
		code: '41114303',
		label: 'Grabadoras de nivel del agua en corrientes abiertas',

		palabrasSimilares: 'Registradores de nivel de agua de corriente abierta',
	},
	{
		code: '41114400',
		label: 'Instrumentos meteorológicos',
	},
	{
		code: '41114401',
		label: 'Anemómetros',
	},
	{
		code: '41114402',
		label: 'Barómetros',
	},
	{
		code: '41114403',
		label: 'Grabadoras de precipitación o evaporación',

		palabrasSimilares: 'Registradores de precipitación o evaporación',
	},
	{
		code: '41114404',
		label: 'Aparatos de radiosonda',
	},
	{
		code: '41114405',
		label: 'Grabadoras de lluvia',
	},
	{
		code: '41114406',
		label:
			'Aparatos para observar la precipitación o evaporación en la superficie',
	},
	{
		code: '41114407',
		label: 'Aparatos para observar la radiación solar en la superficie',

		palabrasSimilares: 'Aparatos para observar la radiación solar superficial',
	},
	{
		code: '41114408',
		label: 'Aparatos para observar la temperatura o humedad en la superficie',
	},
	{
		code: '41114409',
		label: 'Aparatos para observar el viento en la superficie',
	},
	{
		code: '41114410',
		label: 'Estaciones meteorológicas',
	},
	{
		code: '41114411',
		label: 'Accesorios para instrumentos de meteorología',
	},
	{
		code: '41114412',
		label: 'Sistemas sonoros de radio acústica',
	},
	{
		code: '41114413',
		label: 'Ceilómetro',
	},
	{
		code: '41114414',
		label: 'Equipo receptor de satélite meteorológico',
	},
	{
		code: '41114415',
		label: 'Sistema de análisis de rayos',
	},
	{
		code: '41114416',
		label: 'Grabadora o escáner de tabla meteorológica',
	},
	{
		code: '41114417',
		label: 'Robot boya meteorológica',
	},
	{
		code: '41114418',
		label:
			'Sistema para recibir y analizar información del satélite meteorológico',
	},
	{
		code: '41114419',
		label: 'Sistema de alerta del nivel bajo del corte de viento',

		palabrasSimilares: 'Sistema de alerta de corte de viento de bajo nivel',
	},
	{
		code: '41114420',
		label: 'Calibrador de barómetros',
	},
	{
		code: '41114421',
		label: 'Calibrador de higrómetros',
	},
	{
		code: '41114422',
		label: 'Calibrador de medidor de lluvia',
	},
	{
		code: '41114423',
		label: 'Calibrador de sistema automático de clima',
	},
	{
		code: '41114424',
		label: 'Calibrador de anemómetros',
	},
	{
		code: '41114425',
		label: 'Calibrador de actinómetros',
	},
	{
		code: '41114426',
		label: 'Interruptor barométrico',
	},
	{
		code: '41114427',
		label: 'Veletas',
	},
	{
		code: '41114500',
		label: 'Instrumentos mecánicos',
	},
	{
		code: '41114501',
		label: 'Dinamómetros',
	},
	{
		code: '41114502',
		label: 'Elastómetros',
	},
	{
		code: '41114503',
		label: 'Extensómetros',
	},
	{
		code: '41114504',
		label: 'Instrumentos de medición de densidad',
	},
	{
		code: '41114505',
		label: 'Instrumentos de prueba de redondez',
	},
	{
		code: '41114506',
		label: 'Esferómetros',
	},
	{
		code: '41114507',
		label: 'Maquinas de prueba de resortes',
	},
	{
		code: '41114508',
		label: 'Probadores de superficie',
	},
	{
		code: '41114509',
		label: 'Tensiómetros',

		palabrasSimilares:
			'Baumanómetro, Esfigmomanómetro, Esfingomanómetron, Manómetro ,',
	},
	{
		code: '41114510',
		label: 'Limitador de torsión',
	},
	{
		code: '41114511',
		label: 'Probador de suavidad',
	},
	{
		code: '41114512',
		label: 'Probador de balance rotatorio de alta velocidad',
	},
	{
		code: '41114513',
		label: 'Instrumento de medición de excentricidad',
	},
	{
		code: '41114514',
		label: 'Instrumento de medición de vibración de torsión',
	},
	{
		code: '41114515',
		label: 'Probador de fuerza centrífuga',
	},
	{
		code: '41114516',
		label: 'Instrumento de medición de fuerza de corte',
	},
	{
		code: '41114517',
		label: 'Probador de hinchazón hidráulica',
	},
	{
		code: '41114518',
		label: 'Probador de soporte de cremallera',
	},
	{
		code: '41114519',
		label: 'Sistema de remolque y carrito',
	},
	{
		code: '41114520',
		label: 'Probador de caída',
	},
	{
		code: '41114521',
		label: 'Probador de compresor de aire',
	},
	{
		code: '41114522',
		label: 'Probador de engranajes',
	},
	{
		code: '41114523',
		label: 'Barra de prueba',
	},
	{
		code: '41114524',
		label: 'Probador hidráulico',
	},
	{
		code: '41114525',
		label: 'Probador de filtros de aceite',
	},
	{
		code: '41114526',
		label: 'Probador de limpiador de aire',
	},
	{
		code: '41114527',
		label: 'Probador de correa',
	},
	{
		code: '41114528',
		label: 'Instrumento de medición del mecanismo del movimiento de planeación',
	},
	{
		code: '41114529',
		label: 'Probador de llantas en movimiento',
	},
	{
		code: '41114530',
		label: 'Probador del estrés del pegante',
	},
	{
		code: '41114600',
		label:
			'Instrumentos de verificación de metales, metalurgia y materiales estructurales',
	},
	{
		code: '41114601',
		label: 'Probadores de abrasión',
	},
	{
		code: '41114602',
		label: 'Probadores de compresión',
	},
	{
		code: '41114603',
		label: 'Instrumentos para probar concreto o cemento',
	},
	{
		code: '41114604',
		label: 'Probadores de corrosión',
	},
	{
		code: '41114605',
		label: 'Detectores de grietas o corrosión',
	},
	{
		code: '41114606',
		label: 'Probadores de fuga',
	},
	{
		code: '41114607',
		label: 'Máquinas para probar ductilidad',
	},
	{
		code: '41114608',
		label: 'Probadores de fatiga',
	},
	{
		code: '41114609',
		label: 'Aparatos probadores de forja',
	},
	{
		code: '41114610',
		label: 'Aparatos probadores de fundición',
	},
	{
		code: '41114611',
		label: 'Probadores de dureza',
	},
	{
		code: '41114612',
		label: 'Probadores de impacto',
	},
	{
		code: '41114613',
		label: 'Marco de carga',
	},
	{
		code: '41114614',
		label: 'Instrumentos para probar metales',
	},
	{
		code: '41114615',
		label: 'Instrumentos probadores foto elásticos',
	},
	{
		code: '41114616',
		label: 'Indicadores de resistencia al estrés',
	},
	{
		code: '41114617',
		label: 'Probadores de relajación',
	},
	{
		code: '41114618',
		label: 'Instrumentos de medición de rugosidad',
	},
	{
		code: '41114619',
		label: 'Probadores de fuerza de corte',
	},
	{
		code: '41114620',
		label: 'Aparatos para probar choque',
	},
	{
		code: '41114621',
		label: 'Probadores de tensión',
	},
	{
		code: '41114622',
		label: 'Probadores de torsión',
	},
	{
		code: '41114623',
		label: 'Máquinas para pruebas de flexión o transversales',
	},
	{
		code: '41114624',
		label: 'Probadores de vibración',
	},
	{
		code: '41114625',
		label: 'Probadores de desgaste',
	},
	{
		code: '41114626',
		label: 'Aparatos para probar soldaduras',

		palabrasSimilares: 'Aparatos para pruebas de soldadura',
	},
	{
		code: '41114627',
		label: 'Aro de calibración',
	},
	{
		code: '41114628',
		label: 'Probador de plasticidad',
	},
	{
		code: '41114629',
		label: 'Probador de distorsión por calor',
	},
	{
		code: '41114630',
		label: 'Probador de choque térmico',
	},
	{
		code: '41114631',
		label: 'Probador de estrés',
	},
	{
		code: '41114632',
		label: 'Probador de fuerza tensora',
	},
	{
		code: '41114633',
		label: 'Probador de expansión térmica',
	},
	{
		code: '41114634',
		label: 'Probador erichsen',
	},
	{
		code: '41114635',
		label: 'Probador avalador de joyas',

		palabrasSimilares: 'Probador de evaluación de joyas',
	},
	{
		code: '41114636',
		label: 'Instrumento de medición de condición moldeadora de polímeros',

		palabrasSimilares:
			'Instrumento de medición de condiciones de moldeo de polímero',
	},
	{
		code: '41114637',
		label: 'Aparatos de prueba de haces de luz',

		palabrasSimilares: 'Aparatos de prueba de haz de luz',
	},
	{
		code: '41114638',
		label: 'Probador de carga',
	},
	{
		code: '41114639',
		label: 'Probador de flujo de cemento',
	},
	{
		code: '41114640',
		label: 'Equipo de medición de plano de carretera',
	},
	{
		code: '41114641',
		label: 'Equipo de curar cemento para laboratorio',
	},
	{
		code: '41114642',
		label: 'Detector de perforación',
	},
	{
		code: '41114643',
		label: 'Probador de estabilidad del cemento',
	},
	{
		code: '41114644',
		label: 'Instrumento para medir aire en el concreto',
	},
	{
		code: '41114645',
		label: 'Probador de longitud de mortero',
	},
	{
		code: '41114646',
		label: 'Probador de aguja vicat',
	},
	{
		code: '41114647',
		label: 'Probador de asentamiento',
	},
	{
		code: '41114648',
		label: 'Probador de vibración del cemento o concreto',
	},
	{
		code: '41114649',
		label: 'Probador de permeabilidad del mortero',
	},
	{
		code: '41114650',
		label: 'Molde cilíndrico de concreto',
	},
	{
		code: '41114700',
		label: 'Instrumentos para comprobación de papel, madera y tejidos',
	},
	{
		code: '41114701',
		label: 'Instrumentos de prueba de cartón',
	},
	{
		code: '41114702',
		label: 'Probadores de resistencia de textiles',
	},
	{
		code: '41114703',
		label: 'Instrumentos para probar cuero',

		palabrasSimilares: 'Instrumentos de prueba de cuero',
	},
	{
		code: '41114704',
		label: 'Instrumentos para probar papel',
	},
	{
		code: '41114705',
		label: 'Instrumentos para probar textiles',
	},
	{
		code: '41114706',
		label: 'Instrumentos para probar madera',
	},
	{
		code: '41114707',
		label: 'Probador de fuerza de textiles o papel',
	},
	{
		code: '41114708',
		label: 'Probador de permeabilidad de vapor de agua',
	},
	{
		code: '41114709',
		label: 'Instrumento para probar yam (textil sintético)',
	},
	{
		code: '41114710',
		label: 'Probador de transmisión térmica',
	},
	{
		code: '41114711',
		label: 'Probador de caída de textiles',
	},
	{
		code: '41114712',
		label: 'Probador de repelencia de agua para textiles',
	},
	{
		code: '41114713',
		label: 'Probador de recuperación de arrugas para textiles',
	},
	{
		code: '41114714',
		label: 'Probador de maduración de textiles',

		palabrasSimilares: 'Probador de madurez textil',
	},
	{
		code: '41114715',
		label: 'Probador de permeabilidad al aire, papel o textiles',
	},
	{
		code: '41114716',
		label: 'Probador de encogimiento de textiles',
	},
	{
		code: '41114717',
		label: 'Probador de cocción de textiles',

		palabrasSimilares: 'Probador para hornear textiles',
	},
	{
		code: '41114718',
		label: 'Probador de grado de formación de motas para textiles',

		palabrasSimilares: 'Probador de grado pilling textil',
	},
	{
		code: '41114719',
		label: 'Máquina probadora de hojas de papel',

		palabrasSimilares: 'Máquina de prueba de hojas de papel',
	},
	{
		code: '41114720',
		label: 'Probador de absorción de agua de papel y paño',
	},
	{
		code: '41114721',
		label: 'Probador de longitud de fibra',
	},
	{
		code: '41114722',
		label: 'Probador de resistencia al rompimiento',

		palabrasSimilares: 'Probador de fuerza de desgarro',
	},
	{
		code: '41114723',
		label: 'Probador de fuerza de estallido',
	},
	{
		code: '41114800',
		label: 'Instrumentos para comprobación de cerámica y vidrio',
	},
	{
		code: '41114801',
		label: 'Instrumentos para probar cerámicas',

		palabrasSimilares: 'Instrumentos de ensayo de cerámica',
	},
	{
		code: '41114802',
		label: 'Instrumentos para probar vidrio',

		palabrasSimilares: 'Instrumentos de prueba de vidrio',
	},
	{
		code: '41114803',
		label: 'Instrumentos para probar alfarería',

		palabrasSimilares: 'Instrumentos de prueba de cerámica',
	},
	{
		code: '41115100',
		label: 'Instrumentos para comprobación de carbón y mineral',
	},
	{
		code: '41115101',
		label: 'Instrumentos para probar carbón',

		palabrasSimilares: 'Instrumentos de prueba de carbón',
	},
	{
		code: '41115102',
		label: 'Goniómetro',

		palabrasSimilares: 'Transportador',
	},
	{
		code: '41115200',
		label: 'Sistemas y componentes de radar y sonar',
	},
	{
		code: '41115201',
		label: 'Sistemas de vigilancia basados en radar',
	},
	{
		code: '41115202',
		label: 'Polarrotores (elementos centrales de la antena)',
	},
	{
		code: '41115203',
		label: 'Radar meteorológico',
	},
	{
		code: '41115300',
		label: 'Equipo de generación y medición de luz y ondas',
	},
	{
		code: '41115301',
		label: 'Medidores de absorción de luz',
	},
	{
		code: '41115302',
		label: 'Cámaras anecóicas',
	},
	{
		code: '41115303',
		label: 'Analizadores de frecuencia',
	},
	{
		code: '41115304',
		label: 'Contadores o temporizadores o divisores de frecuencia',
	},
	{
		code: '41115305',
		label: 'Medidores de frecuencia eléctrica',
	},
	{
		code: '41115306',
		label: 'Interferómetros',
	},
	{
		code: '41115307',
		label: 'Láseres',
	},
	{
		code: '41115308',
		label: 'Medidores de luz',
	},
	{
		code: '41115309',
		label: 'Luxómetros',
	},
	{
		code: '41115310',
		label: 'Set de calibración óptica',
	},
	{
		code: '41115311',
		label: 'Fotómetros',
	},
	{
		code: '41115312',
		label: 'Polarímetros o refractómetros de mesa',
	},
	{
		code: '41115313',
		label: 'Polarímetros o refractómetros manuales',
	},
	{
		code: '41115314',
		label: 'Polarímetros',
	},
	{
		code: '41115315',
		label: 'Polariscopios',
	},
	{
		code: '41115316',
		label: 'Reflectómetros',
	},
	{
		code: '41115317',
		label: 'Estroboscopios',
	},
	{
		code: '41115318',
		label: 'Colorímetros',
	},
	{
		code: '41115319',
		label: 'Lectores de tubo o disco',
	},
	{
		code: '41115320',
		label: 'Generadores de señales',
	},
	{
		code: '41115321',
		label: 'Imágenes infrarrojas',

		palabrasSimilares: 'Cámaras infrarrojas',
	},
	{
		code: '41115322',
		label: 'Analizadores de rayos láser',
	},
	{
		code: '41115323',
		label: 'Generador de funciones',
	},
	{
		code: '41115324',
		label: 'Sensor ultravioleta',
	},
	{
		code: '41115325',
		label: 'Generador de imágenes térmico',
	},
	{
		code: '41115326',
		label: 'Colimador óptico',

		palabrasSimilares: 'Mira telescópica',
	},
	{
		code: '41115327',
		label: 'Equipo de medición foto eléctrica',

		palabrasSimilares: 'Equipo de medición fotoeléctrico',
	},
	{
		code: '41115328',
		label: 'Sintetizador de forma de onda',
	},
	{
		code: '41115329',
		label: 'Equipo de medición de propagación de radioonda',

		palabrasSimilares:
			'Equipo de medición de la propagación de ondas radioeléctricas',
	},
	{
		code: '41115330',
		label: 'Medidor de opacidad',
	},
	{
		code: '41115331',
		label: 'Instrumento medidor de brillo',
	},
	{
		code: '41115332',
		label: 'Medidor de largo de onda óptica',
	},
	{
		code: '41115333',
		label: 'Instrumento medidor de fotones',
	},
	{
		code: '41115334',
		label: 'Probador de blancura',
	},
	{
		code: '41115335',
		label: 'Vectorscopio',
	},
	{
		code: '41115336',
		label: 'Lámpara de rayos ultravioleta para laboratorio',
	},
	{
		code: '41115337',
		label: 'Instrumento de medición de ondas',
	},
	{
		code: '41115338',
		label: 'Medidor de iluminancia',
	},
	{
		code: '41115339',
		label: 'Elipsómetro',
	},
	{
		code: '41115400',
		label: 'Equipo espectroscópico',
	},
	{
		code: '41115401',
		label: 'Espectrofluorímetros o fluorímetros',
	},
	{
		code: '41115402',
		label: 'Espectrógrafos',
	},
	{
		code: '41115403',
		label: 'Espectrómetros',
	},
	{
		code: '41115404',
		label: 'Espectrómetros de masa',
	},
	{
		code: '41115405',
		label: 'Espectrómetros de protón',
	},
	{
		code: '41115406',
		label: 'Espectrofotómetros',
	},
	{
		code: '41115407',
		label: 'Espectrómetros de absorción atómica aa',
	},
	{
		code: '41115408',
		label: 'Espectrómetros infrarrojos',
	},
	{
		code: '41115409',
		label: 'Espectrómetros de resonancia magnética nuclear nmr',
	},
	{
		code: '41115411',
		label: 'Espectrómetros de plasma acoplado inductivamente icp',
	},
	{
		code: '41115412',
		label: 'Espectro bolómetro',
	},
	{
		code: '41115413',
		label: 'Citómetro de flujo',
	},
	{
		code: '41115414',
		label: 'Sistema de espectroscopia de rayo radio',
	},
	{
		code: '41115415',
		label: 'Sistema de espectroscopio de electrones para análisis químico',
	},
	{
		code: '41115416',
		label: 'Espectrómetro de resonancia de giro de electrones',
	},
	{
		code: '41115500',
		label: 'Equipo de generación y medición de sonido',
	},
	{
		code: '41115501',
		label: 'Sonares',
	},
	{
		code: '41115502',
		label: 'Sonómetros',
	},
	{
		code: '41115503',
		label: 'Aparatos para medir el sonido o medidores de decibeles',

		palabrasSimilares:
			'Instrumento para medir el sonido o medidores de decibeles',
	},
	{
		code: '41115504',
		label: 'Analizadores de velocidad del sonido',
	},
	{
		code: '41115505',
		label: 'Cuartos para pruebas acústicas',
	},
	{
		code: '41115506',
		label: 'Generador de ruidos',
	},
	{
		code: '41115507',
		label: 'Analizador de voz',
	},
	{
		code: '41115508',
		label: 'Detector de sonido',
	},
	{
		code: '41115509',
		label: 'Detector de cables y tubos subterráneos',
	},
	{
		code: '41115510',
		label: 'Detector de fuga de tubos de agua',
	},
	{
		code: '41115511',
		label: 'Probador de audífonos',
	},
	{
		code: '41115512',
		label: 'Emisor de onda acústica',
	},
	{
		code: '41115513',
		label: 'Generador acústico',
	},
	{
		code: '41115514',
		label: 'Sonoboya',
	},
	{
		code: '41115515',
		label: 'Hidrófono',
	},
	{
		code: '41115600',
		label: 'Instrumentos y accesorios de medición electroquímica',
	},
	{
		code: '41115601',
		label: 'Equipo de análisis volumétrico karl fischer',

		palabrasSimilares: 'Valorador volumétrico Karl Fischer',
	},
	{
		code: '41115602',
		label: 'Equipo de análisis volumétrico',
	},
	{
		code: '41115603',
		label: 'Medidores de ph',

		palabrasSimilares: 'Medidores de pH',
	},
	{
		code: '41115604',
		label: 'Electrodos de ph',

		palabrasSimilares: 'Electrodos de pH',
	},
	{
		code: '41115606',
		label: 'Medidores de electrodos selectivos de iones ise',
	},
	{
		code: '41115607',
		label: 'Tiras de prueba de iones selectivos',
	},
	{
		code: '41115608',
		label: 'Electrodos selectivos de iones',
	},
	{
		code: '41115609',
		label: 'Medidores de conductividad',
	},
	{
		code: '41115610',
		label: 'Celdas de conductividad',
	},
	{
		code: '41115611',
		label: 'Medidores de oxígeno disuelto',
	},
	{
		code: '41115612',
		label: 'Sondas de oxigeno disuelto',
	},
	{
		code: '41115613',
		label: 'Medidor de salinidad',
	},
	{
		code: '41115614',
		label: 'Transmisores de ph',
	},
	{
		code: '41115615',
		label: 'Controlador de ph',
	},
	{
		code: '41115700',
		label: 'Instrumentos y accesorios de medición cromatográfica',
	},
	{
		code: '41115701',
		label: 'Detectores cromatográficos',
	},
	{
		code: '41115702',
		label: 'Escáneres cromatográficos',
	},
	{
		code: '41115703',
		label: 'Gases cromatográficos',

		palabrasSimilares: 'Cromatógrafos de gas',
	},
	{
		code: '41115704',
		label: 'Iones cromatográficos',

		palabrasSimilares: 'Cromatógrafo de iones',
	},
	{
		code: '41115705',
		label: 'Cromatógrafos líquidos',
	},
	{
		code: '41115706',
		label: 'Cromatógrafos de capa delgada',
	},
	{
		code: '41115707',
		label: 'Cromatógrafos para cromatografía de líquido de alta presión',
	},
	{
		code: '41115708',
		label: 'Cromatógrafos de capa delgada de alta presión tlc',
	},
	{
		code: '41115709',
		label: 'Columnas para cromatografía líquida de alta presión hplc',
	},
	{
		code: '41115710',
		label: 'Columnas para cromatografía de gas',

		palabrasSimilares: 'Columnas GC para cromatografía de gas',
	},
	{
		code: '41115711',
		label: 'Columnas para cromatografía líquida lc',

		palabrasSimilares: 'Columnas LC para cromatografía líquida',
	},
	{
		code: '41115712',
		label: 'Columnas para extracción de fase sólida spe',

		palabrasSimilares: 'Columnas SPE de extracción en fase sólida',
	},
	{
		code: '41115713',
		label: 'Tanques para cromatografía de capa delgada',
	},
	{
		code: '41115714',
		label: 'Auto muestreadores',
	},
	{
		code: '41115715',
		label: 'Inyectores',
	},
	{
		code: '41115716',
		label: 'Accesorios para cromatografía líquida',
	},
	{
		code: '41115717',
		label: 'Accesorios para cromatografía de gas',
	},
	{
		code: '41115718',
		label: 'Inyector de paredes de membrana',
	},
	{
		code: '41115719',
		label: 'Revestimientos para cromatografía de gas',
	},
	{
		code: '41115720',
		label: 'Tubos para cromatografía',
	},
	{
		code: '41115721',
		label: 'Sistema de cromatografía líquida de proteínas rápidas fplc',
	},
	{
		code: '41115722',
		label: 'Columna para cromatografía líquida de proteínas rápidas fplc',
	},
	{
		code: '41115723',
		label: 'Cromatógrafo de fluido súper crítico',
	},
	{
		code: '41115800',
		label: 'Analizadores, accesorios y suministros clínicos y diagnósticos',
	},
	{
		code: '41115801',
		label: 'Analizadores de amino ácidos',
	},
	{
		code: '41115802',
		label: 'Accesorios o suministros para analizadores de amino ácidos',
	},
	{
		code: '41115803',
		label: 'Analizadores de bancos de sangre',
	},
	{
		code: '41115804',
		label: 'Accesorios o suministros para analizadores de bancos de sangre',
	},
	{
		code: '41115805',
		label: 'Analizadores de gas en la sangre',
	},
	{
		code: '41115806',
		label: 'Accesorios o suministros para analizadores de gas en la sangre',
	},
	{
		code: '41115807',
		label: 'Analizadores químicos',
	},
	{
		code: '41115808',
		label: 'Accesorios o suministros para analizadores químico',
	},
	{
		code: '41115809',
		label: 'Analizadores de coagulación',
	},
	{
		code: '41115810',
		label: 'Accesorios o suministros para analizadores de coagulación',
	},
	{
		code: '41115811',
		label: 'Analizadores de secuencia desoxirribonucleica',
	},
	{
		code: '41115812',
		label:
			'Accesorios o suministros para analizadores de secuencia desoxirribonucleica',
	},
	{
		code: '41115813',
		label: 'Analizadores de toxicología',
	},
	{
		code: '41115814',
		label: 'Accesorios o suministros para analizadores de toxicología',
	},
	{
		code: '41115815',
		label: 'Analizadores de hematología',
	},
	{
		code: '41115816',
		label: 'Accesorios o suministros para analizadores de hematología',
	},
	{
		code: '41115817',
		label: 'Analizadores de histología',
	},
	{
		code: '41115818',
		label: 'Accesorios o suministros para analizadores de histología',
	},
	{
		code: '41115819',
		label: 'Analizadores de inmunología',
	},
	{
		code: '41115820',
		label: 'Accesorios o suministros para analizadores de inmunología',
	},
	{
		code: '41115821',
		label: 'Analizadores de microbiología',
	},
	{
		code: '41115822',
		label: 'Accesorios o suministros para analizadores de microbiología',
	},
	{
		code: '41115823',
		label: 'Analizadores de proteína',
	},
	{
		code: '41115824',
		label: 'Accesorios o suministros para analizadores de proteína',
	},
	{
		code: '41115825',
		label: 'Analizadores radio isotópicos',
	},
	{
		code: '41115826',
		label: 'Accesorios o suministros para analizadores radio isotópicos',
	},
	{
		code: '41115827',
		label: 'Analizadores de orina',
	},
	{
		code: '41115828',
		label: 'Accesorios o suministros para analizadores de orina',
	},
	{
		code: '41115829',
		label: 'Analizadores de productos cárnicos o lácteos',
	},
	{
		code: '41115830',
		label: 'Analizadores de glucosa',
	},
	{
		code: '41115831',
		label: 'Estante o revistero para la manipulación de micro placas',
	},
	{
		code: '41115832',
		label: 'Equipo para detección de antibióticos',
	},
	{
		code: '41115833',
		label: 'Analizador de piel',
	},
	{
		code: '41115834',
		label: 'Accesorios o suministros para citómetro de flujo',
	},
	{
		code: '41115835',
		label: 'Contador automático de androginia y fertilidad',
	},
	{
		code: '41115836',
		label:
			'Accesorios o suministros para contador automático de androginia y fertilidad',
	},
	{
		code: '41115837',
		label: 'Contador y medidor de tamaño de partículas',
	},
	{
		code: '41115838',
		label:
			'Accesorios o suministros para contador y medidor de tamaño de partículas',
	},
	{
		code: '41115839',
		label:
			'Analizador de unidad procesadora de aféresis de banco de sangre y donantes',
	},
	{
		code: '41115840',
		label:
			'Accesorios o suministros para analizador de unidad procesadora de aféresis de banco de sangre y donantes',
	},
	{
		code: '41116000',
		label: 'Reactivos de analizadores clínicos y diagnósticos',
	},
	{
		code: '41116001',
		label: 'Reactivos analizadores de amino ácidos',
	},
	{
		code: '41116002',
		label: 'Reactivos analizadores de bancos de sangre',
	},
	{
		code: '41116003',
		label: 'Reactivos analizadores de gas en la sangre',
	},
	{
		code: '41116004',
		label: 'Reactivos analizadores de química',
	},
	{
		code: '41116005',
		label: 'Reactivos analizadores de coagulación',
	},
	{
		code: '41116006',
		label:
			'Reactivos analizadores de secuencia de ácido desoxirribonucleico dna',
	},
	{
		code: '41116007',
		label: 'Reactivos analizadores de toxicología',
	},
	{
		code: '41116008',
		label: 'Reactivos analizadores de hematología',
	},
	{
		code: '41116009',
		label: 'Reactivos analizadores de histología',
	},
	{
		code: '41116010',
		label: 'Reactivos analizadores de inmunología',
	},
	{
		code: '41116011',
		label: 'Reactivos analizadores de microbiología',
	},
	{
		code: '41116012',
		label: 'Reactivos analizadores de proteínas',
	},
	{
		code: '41116013',
		label: 'Reactivos analizadores radio isotópicos',
	},
	{
		code: '41116014',
		label: 'Reactivos analizadores de análisis de orina',
	},
	{
		code: '41116015',
		label: 'Reactivos o anticuerpos analizadores de citometría de flujo',
	},
	{
		code: '41116016',
		label: 'Reactivo analizador de electrolitos',
	},
	{
		code: '41116017',
		label: 'Reactivo contador automático de androginia y fertilidad',
	},
	{
		code: '41116018',
		label: 'Reactivo analizador contador y medidor de tamaño de partículas',
	},
	{
		code: '41116100',
		label:
			'Kits de ensayos manuales, controles de calidad, calibradores y normativas',
	},
	{
		code: '41116101',
		label: 'Kits o suministros de prueba de bancos de sangre',
	},
	{
		code: '41116102',
		label: 'Reactivos o soluciones de bancos de sangre',
	},
	{
		code: '41116103',
		label:
			'Controles de calidad o calibradores o estándares de bancos de sangre',
	},
	{
		code: '41116104',
		label: 'Kits o suministros para pruebas químicas',
	},
	{
		code: '41116105',
		label: 'Reactivos o soluciones químicas',
	},
	{
		code: '41116106',
		label: 'Tiras de prueba o papel de prueba químico',
	},
	{
		code: '41116107',
		label: 'Controles de calidad o calibradores o estándares químicos',
	},
	{
		code: '41116108',
		label: 'Kits o suministros para pruebas de coagulación',
	},
	{
		code: '41116109',
		label: 'Reactivos o soluciones de coagulación',
	},
	{
		code: '41116110',
		label: 'Controles de calidad o calibradores o estándares de coagulación',
	},
	{
		code: '41116111',
		label: 'Kits o suministros para pruebas de citología',
	},
	{
		code: '41116112',
		label: 'Controles de calidad o calibradores o estándares de citología',
	},
	{
		code: '41116113',
		label: 'Reactivos o soluciones o tinturas para citología',
	},
	{
		code: '41116116',
		label: 'Kits o suministros para pruebas ambientales',
	},
	{
		code: '41116117',
		label: 'Reactivos o soluciones o tinturas ambientales',
	},
	{
		code: '41116118',
		label: 'Kits o suministros para pruebas de alimentos',
	},
	{
		code: '41116119',
		label:
			'Reactivos o soluciones o tinturas para kits de pruebas de alimentos',
	},
	{
		code: '41116120',
		label: 'Kits o suministros para pruebas de hematología',
	},
	{
		code: '41116121',
		label: 'Reactivos o soluciones o tinturas para hematología',
	},
	{
		code: '41116122',
		label: 'Controles de calidad o calibradores o estándares para hematología',
	},
	{
		code: '41116123',
		label: 'Kits o suministros para pruebas de histología',
	},
	{
		code: '41116124',
		label: 'Reactivos o soluciones o tinturas para histología',
	},
	{
		code: '41116125',
		label: 'Controles de calidad o calibradores o estándares para histología',
	},
	{
		code: '41116126',
		label: 'Kits o suministros para pruebas de inmunología o serología',
	},
	{
		code: '41116127',
		label: 'Reactivos o soluciones o tinturas para inmunología o serología',
	},
	{
		code: '41116128',
		label:
			'Controles de calidad o calibradores o estándares para inmunología o serología',
	},
	{
		code: '41116129',
		label: 'Kits o suministros para pruebas de microbiología o bacteriología',
	},
	{
		code: '41116130',
		label:
			'Reactivos o soluciones o tinturas para microbiología o bacteriología',
	},
	{
		code: '41116131',
		label:
			'Discos o paneles de identificación o sensibilidad para microbiología o bacteriología',
	},
	{
		code: '41116132',
		label:
			'Controles de calidad o calibradores o estándares para microbiología o bacteriología',
	},
	{
		code: '41116133',
		label: 'Kits o suministros para pruebas de biología molecular',
	},
	{
		code: '41116134',
		label: 'Reactivos o soluciones o tinturas para biología molecular',
	},
	{
		code: '41116135',
		label:
			'Controles de calidad o calibradores o estándares para biología molecular',
	},
	{
		code: '41116136',
		label: 'Kits o suministros para análisis de orina',
	},
	{
		code: '41116137',
		label: 'Reactivos o soluciones o tinturas para análisis de orina',
	},
	{
		code: '41116138',
		label: 'Tiras para análisis de orina',
	},
	{
		code: '41116139',
		label:
			'Controles de calidad o calibradores o estándares de análisis de orina',
	},
	{
		code: '41116140',
		label: 'Kits o suministros para parasitología o micología',
	},
	{
		code: '41116141',
		label: 'Reactivos o soluciones o tinturas para parasitología o micología',
	},
	{
		code: '41116142',
		label: 'Medio para parasitología o micología',

		palabrasSimilares: 'Instrumentos para parasitología o micología',
	},
	{
		code: '41116143',
		label:
			'Controles de calidad o calibradores o estándares de parasitología o micología',
	},
	{
		code: '41116144',
		label: 'Kits o suministros para pruebas de virología',
	},
	{
		code: '41116145',
		label: 'Controles de calidad o calibradores o estándares para virología',
	},
	{
		code: '41116146',
		label: 'Kits o suministros para pruebas de toxicología',

		palabrasSimilares: 'Kits de prueba de toxicología o suministros',
	},
	{
		code: '41116147',
		label: 'Controles de calidad o calibradores o estándares para toxicología',
	},
	{
		code: '41116148',
		label: 'Kits o suministros para pruebas de citometría de flujo',
	},
	{
		code: '41116149',
		label: 'Probador de lisado de limulus amebocita',
	},
	{
		code: '41116150',
		label: 'Kits o suministros para pruebas de androgénesis y fertilidad',
	},
	{
		code: '41116151',
		label:
			'Controles de calidad o calibradores o estándares para androgénesis y fertilidad',
	},
	{
		code: '41116152',
		label:
			'Controles de calidad o calibradores o estándares para citometría de flujo',
	},
	{
		code: '41116153',
		label:
			'Controles de calidad o calibradores o estándares para inmunohistoquímica',
	},
	{
		code: '41116154',
		label:
			'Controles de calidad o calibradores o estándares para contar y determinar el tamaño de las partículas',
	},
	{
		code: '41116155',
		label: 'Medio de crecimiento de cultura de células y biología molecular',

		palabrasSimilares:
			'Materiales de crecimiento de cultivo de células y biología molecular',
	},
	{
		code: '41116156',
		label: 'Reactivos o soluciones o tinturas de virología',
	},
	{
		code: '41116157',
		label: 'Medio de cultura de tejidos y células de virología',

		palabrasSimilares:
			'Materiales de cultivo de tejidos y células de virología',
	},
	{
		code: '41116200',
		label: 'Suministros y equipo para lugares de tratamiento de pacientes',
	},
	{
		code: '41116201',
		label: 'Monitores o medidores de glucosa',
	},
	{
		code: '41116202',
		label: 'Monitores o medidores de colesterol',
	},
	{
		code: '41116203',
		label: 'Accesorios para monitores o medidores',
	},
	{
		code: '41116205',
		label: 'Kits de pruebas rápidas',
	},
	{
		code: '41116206',
		label: 'Bebidas de diagnóstico para pruebas de laboratorio',
	},
	{
		code: '41116207',
		label: 'Monitores o medidores de hemoglobina o hematocritos',
	},
	{
		code: '41116208',
		label: 'Monitores o medidores de parámetros múltiples de química sanguínea',
	},
	{
		code: '41116209',
		label:
			'Monitores o medidores de múltiples parámetros de coagulación de la sangre',
	},
	{
		code: '41116210',
		label:
			'Monitores o medidores analizadores de aliento de desorden metabólico',
	},
	{
		code: '41116211',
		label: 'Analizador de aliento de etanol',
	},
	{
		code: '41116212',
		label:
			'Controles de calidad o calibradores o estándares para monitores o medidores',
	},
	{
		code: '41116300',
		label: 'Comprobadores de punto de inflamación para laboratorio',
	},
	{
		code: '41116301',
		label: 'Probadores de punto de inflamación',
	},
	{
		code: '41116302',
		label: 'Probador de incombustibilidad',
	},
	{
		code: '41116303',
		label: 'Probador de inflamabilidad',
	},
	{
		code: '41116304',
		label: 'Probador de punto de ignición',
	},
	{
		code: '41116400',
		label: 'Instrumentos de medición de la aceleración y la vibración',
	},
	{
		code: '41116401',
		label: 'Martillos de impacto',
	},
	{
		code: '41116500',
		label: 'Piezas y accesorios de instrumentos',
	},
	{
		code: '41116501',
		label: 'Diales o kits de diales de medición',
	},
	{
		code: '41116502',
		label: 'Clip eléctrico',
	},
	{
		code: '41121500',
		label: 'Equipo y suministros de pipetas y manipulación de líquidos',
	},
	{
		code: '41121501',
		label: 'Sistemas de manipulación líquida automática o robótica',
	},
	{
		code: '41121502',
		label: 'Diluidores de laboratorio',
	},
	{
		code: '41121503',
		label: 'Pipeta de desplazamiento de aire multicanal manuales',
	},
	{
		code: '41121504',
		label: 'Pipeta de desplazamiento de aire de un solo canal manuales',
	},
	{
		code: '41121505',
		label: 'Pipeta de desplazamiento positivo de un solo canal manuales',
	},
	{
		code: '41121506',
		label: 'Pipeta repetidoras de un solo canal manuales',
	},
	{
		code: '41121507',
		label: 'Pipeta de un solo canal electrónicas',
	},
	{
		code: '41121508',
		label: 'Pipeta multicanales electrónicas',
	},
	{
		code: '41121509',
		label: 'Pipetas pasteur o de transferencia',
	},
	{
		code: '41121510',
		label: 'Pipetas volumétricas',
	},
	{
		code: '41121511',
		label: 'Pipetas serológicas',
	},
	{
		code: '41121513',
		label: 'Pipetas de caída',
	},
	{
		code: '41121514',
		label: 'Bombas de pipetas',
	},
	{
		code: '41121515',
		label: 'Bombillos de pipetas',

		palabrasSimilares: 'Bombillas de pipetas',
	},
	{
		code: '41121516',
		label: 'Dispensadores de tapas de botella',
	},
	{
		code: '41121517',
		label: 'Insertos o accesorios para pipeteras',
	},
	{
		code: '41121600',
		label: 'Puntas de pipeta',
	},
	{
		code: '41121601',
		label: 'Filtro de boquilla de pipeta',
	},
	{
		code: '41121602',
		label: 'Puntas de pipeta de barrera de aerosol',
	},
	{
		code: '41121603',
		label: 'Puntas de pipeta de baja retención',
	},
	{
		code: '41121604',
		label: 'Puntas de pipeta de referencia',
	},
	{
		code: '41121605',
		label: 'Puntas de pipeta ultra micro',
	},
	{
		code: '41121606',
		label: 'Puntas de pipeta de carga de gel',
	},
	{
		code: '41121607',
		label: 'Puntas de pipeta universales',
	},
	{
		code: '41121608',
		label: 'Puntas de pipeta robóticas',
	},
	{
		code: '41121609',
		label: 'Puntas de pipeta de volumen variable',
	},
	{
		code: '41121700',
		label: 'Tubos de ensayo',
	},
	{
		code: '41121701',
		label: 'Tubos de ensayo general o multipropósito',
	},
	{
		code: '41121702',
		label: 'Tubos micro centrífugos',
	},
	{
		code: '41121703',
		label: 'Tubos centrífugos',
	},
	{
		code: '41121704',
		label: 'Tubos criogénicos',
	},
	{
		code: '41121705',
		label: 'Tubos de resonancia magnética nuclear nmr',
	},
	{
		code: '41121706',
		label: 'Tubos de cultivo',
	},
	{
		code: '41121707',
		label: 'Tubos de pruebas de separador',
	},
	{
		code: '41121708',
		label: 'Tubos de pruebas de anti coagulación',
	},
	{
		code: '41121709',
		label: 'Tubos capilares o hematocritos',
	},
	{
		code: '41121710',
		label: 'Cierres o tapas para tubos de ensayo',
	},
	{
		code: '41121711',
		label: 'Tubos o accesorios para pruebas de análisis de orina',
	},
	{
		code: '41121712',
		label: 'Tubos viscosímetros',
	},
	{
		code: '41121713',
		label: 'Analizador de tamaño de arena',
	},
	{
		code: '41121800',
		label:
			'Artículos de vidrio o plástico y suministros generales de laboratorio',
	},
	{
		code: '41121801',
		label: 'Vasos de observación para laboratorio',
	},
	{
		code: '41121802',
		label: 'Varillas para revolver para laboratorio',
	},
	{
		code: '41121803',
		label: 'Vasos de precipitados para laboratorio',
	},
	{
		code: '41121804',
		label: 'Redomas para laboratorio',
	},
	{
		code: '41121805',
		label: 'Cilindros graduados para laboratorio',
	},
	{
		code: '41121806',
		label: 'Frascos para laboratorio',
	},
	{
		code: '41121807',
		label: 'Ampollas para laboratorio',
	},
	{
		code: '41121808',
		label: 'Buretas para laboratorio',
	},
	{
		code: '41121809',
		label: 'Embudos para laboratorio',
	},
	{
		code: '41121810',
		label: 'Platos o frascos para tintura para laboratorio',
	},
	{
		code: '41121811',
		label: 'Kits de micro química para laboratorio',
	},
	{
		code: '41121812',
		label: 'Platos para laboratorio',
	},
	{
		code: '41121813',
		label: 'Cubetas',
	},
	{
		code: '41121814',
		label: 'Tapas o forros o forros deslizantes para laboratorio',
	},
	{
		code: '41121815',
		label: 'Adaptadores o conectores o instalaciones para laboratorio',
	},
	{
		code: '41121816',
		label: 'Tubo de vidrio para laboratorio',
	},
	{
		code: '41121817',
		label: 'Vidrio curvado para laboratorio',

		palabrasSimilares: 'Vidrio curvo para laboratorio',
	},
	{
		code: '41121818',
		label: 'Botella de almacenamiento para laboratorio',
	},
	{
		code: '41121819',
		label: 'Botella de gotero y tapa de gotero',
	},
	{
		code: '41122000',
		label: 'Jeringuillas de laboratorio o de muestreo',
	},
	{
		code: '41122001',
		label: 'Jeringas de cromatografía',
	},
	{
		code: '41122002',
		label: 'Agujas para jeringas de cromatografía',
	},
	{
		code: '41122003',
		label: 'Adaptadores o accesorios para jeringa',
	},
	{
		code: '41122004',
		label: 'Jeringas para muestras',
	},
	{
		code: '41122100',
		label: 'Suministros de cultivo de tejido y cribado de alta definición',
	},
	{
		code: '41122101',
		label: 'Platos o placas petri',
	},
	{
		code: '41122102',
		label: 'Platos multi pocillo',

		palabrasSimilares: 'Placas multipocillo',
	},
	{
		code: '41122103',
		label: 'Espátulas de células',
	},
	{
		code: '41122104',
		label: 'Frascos de cultivo de tejidos',
	},
	{
		code: '41122105',
		label: 'Botellas de cultivo rotatorias',
	},
	{
		code: '41122106',
		label: 'Dispositivos de inoculación',
	},
	{
		code: '41122107',
		label: 'Platos o placas o insertos recubiertos para cultivo de tejidos',
	},
	{
		code: '41122108',
		label: 'Presillos o agujas para inoculación microbiológica',
	},
	{
		code: '41122109',
		label: 'Almohadilla de petri',
	},
	{
		code: '41122110',
		label: 'Dispensador de almohadillas de petri',
	},
	{
		code: '41122200',
		label: 'Crisoles',
	},
	{
		code: '41122201',
		label: 'Crisoles de vidrio',
	},
	{
		code: '41122202',
		label: 'Crisoles cerámicos',
	},
	{
		code: '41122203',
		label: 'Crisoles de metal',
	},
	{
		code: '41122300',
		label: 'Protectores y revestimientos de banco de laboratorio',
	},
	{
		code: '41122301',
		label: 'Protectores o revestimientos de mesa de trabajo',
	},
	{
		code: '41122400',
		label: 'Instrumentos de laboratorio',
	},
	{
		code: '41122401',
		label:
			'Barras giratorias, barras para revolver o gotas para revolver magnéticas',
	},
	{
		code: '41122402',
		label:
			'Recuperadores magnéticos de barras giratorias o recuperadores de barras giratorias',
	},
	{
		code: '41122403',
		label: 'Espátulas para laboratorio',
	},
	{
		code: '41122404',
		label: 'Tenazas para laboratorio',
	},
	{
		code: '41122405',
		label: 'Fórceps para laboratorio',
	},
	{
		code: '41122406',
		label: 'Cuchillos para laboratorio',
	},
	{
		code: '41122407',
		label: 'Escalpelos para laboratorio',
	},
	{
		code: '41122408',
		label: 'Tijeras para laboratorio',
	},
	{
		code: '41122409',
		label: 'Herramientas para laboratorio',
	},
	{
		code: '41122410',
		label: 'Película sellante para laboratorio',
	},
	{
		code: '41122411',
		label: 'Cronómetros o relojes para laboratorio',
	},
	{
		code: '41122412',
		label: 'Sellantes de tubos para laboratorio',
	},
	{
		code: '41122413',
		label: 'Pinzas para laboratorio',
	},
	{
		code: '41122414',
		label: 'Cuchara para laboratorio',
	},
	{
		code: '41122415',
		label: 'Cristales de bioseparación centrífuga',
	},
	{
		code: '41122500',
		label: 'Corchos, tapones y accesorios de laboratorio',
	},
	{
		code: '41122501',
		label: 'Corchos para laboratorio',
	},
	{
		code: '41122502',
		label: 'Tapones para laboratorio',
	},
	{
		code: '41122503',
		label: 'Porta corchos para laboratorio',
	},
	{
		code: '41122600',
		label: 'Portaobjetos de laboratorio y suministros',
	},
	{
		code: '41122601',
		label: 'Portaobjetos para microscopios',
	},
	{
		code: '41122602',
		label: 'Portaobjetos de microscopio',
	},
	{
		code: '41122603',
		label: 'Papel de lentes para microscopio',
	},
	{
		code: '41122604',
		label: 'Hemocitómetros',
	},
	{
		code: '41122605',
		label: 'Aceite de inmersión para microscopios',
	},
	{
		code: '41122606',
		label: 'Dispensadores de portaobjetos para microscopio',
	},
	{
		code: '41122607',
		label: 'Solución limpiadora de lentes para microscopio',
	},
	{
		code: '41122700',
		label: 'Cintas y etiquetas de laboratorio',
	},
	{
		code: '41122701',
		label: 'Etiquetas para portaobjetos o especímenes',
	},
	{
		code: '41122702',
		label: 'Cintas etiquetadoras',
	},
	{
		code: '41122703',
		label: 'Cintas de seguridad',
	},
	{
		code: '41122704',
		label: 'Cintas contra alteración',

		palabrasSimilares: 'Cintas de seguridad contra alteración',
	},
	{
		code: '41122800',
		label: 'Estantes, soportes y bandejas de laboratorio',
	},
	{
		code: '41122801',
		label: 'Estantes o soportes para pipetas',
	},
	{
		code: '41122802',
		label: 'Estantes para portaobjetos de microscopios',
	},
	{
		code: '41122803',
		label: 'Estantes o soportes para tubos para sedimentación',
	},
	{
		code: '41122804',
		label: 'Estantes para tubos de ensayo',
	},
	{
		code: '41122805',
		label: 'Estantes para secado',
	},
	{
		code: '41122806',
		label: 'Estantes para unidades de criopreservación',
	},
	{
		code: '41122807',
		label: 'Bandejas para disección',
	},
	{
		code: '41122808',
		label: 'Bandejas para propósitos generales',

		palabrasSimilares: 'Bandejas de uso general de laboratorio',
	},
	{
		code: '41122809',
		label: 'Estantes para placas petri',
	},
	{
		code: '41122810',
		label: 'Estantes de almacenamiento de reactivos para la mesa de trabajo',
	},
	{
		code: '41122811',
		label: 'Dispensador de tubos de cultivo y prueba',
	},
	{
		code: '41123000',
		label: 'Desecadores y desecantes de laboratorio',
	},
	{
		code: '41123001',
		label: 'Deshidratadores de frasco',
	},
	{
		code: '41123002',
		label: 'Deshidratadores de gabinete',
	},
	{
		code: '41123003',
		label: 'Desecantes',
	},
	{
		code: '41123004',
		label: 'Deshidratadores de vacío',
	},
	{
		code: '41123005',
		label:
			'Contenedor de almacenamiento de disco desecante microbiológicamente susceptible',
	},
	{
		code: '41123100',
		label: 'Suministros de diálisis para laboratorio',
	},
	{
		code: '41123101',
		label: 'Tubos de diálisis',
	},
	{
		code: '41123102',
		label: 'Pinzas de diálisis',
	},
	{
		code: '41123103',
		label: 'Casete de diálisis',
	},
	{
		code: '41123104',
		label: 'Membrana de diálisis',
	},
	{
		code: '41123105',
		label: 'Porta casete de diálisis',

		palabrasSimilares: 'Porta casete de diálisis',
	},
	{
		code: '41123200',
		label: 'Especímenes preservados y suministros',
	},
	{
		code: '41123201',
		label: 'Portaobjetos preparados preservados',
	},
	{
		code: '41123202',
		label: 'Animales y organismos preservados',
	},
	{
		code: '41123300',
		label: 'Contenedores y armarios de almacenamiento general para laboratorio',
	},
	{
		code: '41123302',
		label: 'Cajas o folders para portaobjetos para microscopios',
	},
	{
		code: '41123303',
		label: 'Gabinetes para portaobjetos para microscopios',

		palabrasSimilares: 'Caja para portar objetos para microscopios',
	},
	{
		code: '41123304',
		label: 'Cajas de almacenamiento criogénicas',

		palabrasSimilares: 'Cajas para almacenamiento de viales criogénicos',
	},
	{
		code: '41123305',
		label: 'Gabinetes para casetes de tejidos o histología',

		palabrasSimilares: 'Gabinetes para casete de tejidos o histología',
	},
	{
		code: '41123306',
		label: 'Accesorios para casetes de tejidos o histología',

		palabrasSimilares: 'Accesorios para casete de tejido o histología',
	},
	{
		code: '41123400',
		label: 'Dispositivos de dosificación',
	},
	{
		code: '41123401',
		label: 'Tazas dosificadoras',
	},
	{
		code: '41123402',
		label: 'Cucharas dosificadoras',
	},
	{
		code: '41123403',
		label: 'Goteros dosificadores',
	},
	{
		code: '42121500',
		label: 'Equipo veterinario',
	},
	{
		code: '42121501',
		label: 'Probadores de presión sanguínea para uso veterinario',
	},
	{
		code: '42121502',
		label: 'Probador quimiógrafo para uso veterinario',

		palabrasSimilares: 'Probador quimógrafo para uso veterinario',
	},
	{
		code: '42121503',
		label: 'Probador pirogénico para uso veterinario',
	},
	{
		code: '42121504',
		label: 'Equipo estereotóxico para uso veterinario',

		palabrasSimilares: 'Equipo esterotáctico para uso veterinario',
	},
	{
		code: '42121505',
		label: 'Electrocardiógrafo ecg para uso veterinario',

		palabrasSimilares: 'Electrocardiógrafo para veterinaria',
	},
	{
		code: '42121506',
		label: 'Sets de instrumentos quirúrgicos para uso veterinario',

		palabrasSimilares: 'Conjuntos de instrumentos quirúrgicos veterinarios',
	},
	{
		code: '42121507',
		label: 'Unidades o accesorios de inyección o succión para uso veterinario',
	},
	{
		code: '42121508',
		label: 'Sets de botellas para uso veterinario',

		palabrasSimilares: 'Conjuntos de botellas veterinarias',
	},
	{
		code: '42121509',
		label: 'Termómetros clínicos para uso veterinario',
	},
	{
		code: '42121510',
		label: 'Cajas de instrumentos o accesorios para uso veterinario',
	},
	{
		code: '42121511',
		label:
			'Estuches enrollables para instrumentos o accesorios de uso veterinario',
	},
	{
		code: '42121512',
		label: 'Espéculos para uso veterinario',
	},
	{
		code: '42121513',
		label: 'Instrumentos de castración para uso veterinario',
	},
	{
		code: '42121514',
		label: 'Kits de fijación externa para uso veterinario',

		palabrasSimilares: 'Juegos veterinarios de fijación externa',
	},
	{
		code: '42121515',
		label: 'Limas o cortaúñas para uso veterinario',
	},
	{
		code: '42121600',
		label: 'Productos veterinarios',
	},
	{
		code: '42121601',
		label: 'Productos gastrointestinales para uso veterinario',

		palabrasSimilares:
			'Medicamentos contra problemas gastrointestinales de uso veterinario',
	},
	{
		code: '42121602',
		label: 'Productos para sangre o formación de sangre para uso veterinario',

		palabrasSimilares:
			'Medicamentos para sangre o formación de sangre para uso veterinario',
	},
	{
		code: '42121603',
		label: 'Productos para el sistema respiratorio para uso veterinario',

		palabrasSimilares:
			'Medicamentos para el sistema respiratorio para uso veterinario',
	},
	{
		code: '42121604',
		label:
			'Productos para el sistema musculo esquelético o nervioso para uso veterinario',

		palabrasSimilares:
			'Medicamentos para el sistema musculo esquelético o nervioso para uso veterinario',
	},
	{
		code: '42121605',
		label: 'Productos para el sistema cardiovascular para uso veterinario',

		palabrasSimilares:
			'Medicamentos para el sistema cardiovascular para uso veterinario',
	},
	{
		code: '42121606',
		label: 'Productos dermatológicos o anti protozoarios para uso veterinario',

		palabrasSimilares:
			'Medicamentos dermatológicos o anti protozoarios para uso veterinario',
	},
	{
		code: '42121607',
		label:
			'Productos para el sistema sexual genital urinario u hormonales para uso veterinario',

		palabrasSimilares:
			'Medicamentos para el sistema sexual genital urinario u hormonales para uso veterinario',
	},
	{
		code: '42121608',
		label: 'Productos dentales para uso veterinario',

		palabrasSimilares: 'Medicamentos dentales para uso veterinario',
	},
	{
		code: '42121700',
		label: 'Mobiliario clínico veterinario',
	},
	{
		code: '42121701',
		label: 'Tablas quirúrgicas para uso veterinario',

		palabrasSimilares: 'Mesas quirúrgicas para uso veterinario',
	},
	{
		code: '42121702',
		label: 'Armarios de almacenamiento para uso veterinario',
	},
	{
		code: '42121800',
		label: 'Equipo y suministros para inseminación artificial veterinaria',
	},
	{
		code: '42121801',
		label: 'Máquina para inseminación artificial',
	},
	{
		code: '42121802',
		label: 'Instrumento para recolección de semen',
	},
	{
		code: '42121803',
		label: 'Estimulador de eyaculación eléctrico',
	},
	{
		code: '42121804',
		label: 'Instrumento para empaquetar semen',
	},
	{
		code: '42131500',
		label: 'Vestuario para pacientes',

		palabrasSimilares: 'Ropa para pacientes',
	},
	{
		code: '42131501',
		label: 'Petos para pacientes',

		palabrasSimilares: 'Babero para pacientes',
	},
	{
		code: '42131502',
		label: 'Gorras para pacientes',
	},
	{
		code: '42131503',
		label: 'Capas de examen para pacientes',
	},
	{
		code: '42131504',
		label: 'Batas para pacientes',
	},
	{
		code: '42131505',
		label: 'Camisas o chalecos para pacientes bebés',
	},
	{
		code: '42131506',
		label: 'Chaquetas para pacientes',

		palabrasSimilares: 'Chaqueta de fuerza para pacientes',
	},
	{
		code: '42131507',
		label: 'Pantuflas para pacientes',
	},
	{
		code: '42131508',
		label: 'Pijamas para pacientes',
	},
	{
		code: '42131509',
		label: 'Batas de hospital',
	},
	{
		code: '42131510',
		label: 'Pantalones para pacientes',
	},
	{
		code: '42131511',
		label: 'Prenda para hidroterapia',
	},
	{
		code: '42131512',
		label: 'Gorra o sombrero o material para gorra para paciente bebé',
	},
	{
		code: '42131600',
		label: 'Vestuario para el personal sanitario y artículos relacionados',

		palabrasSimilares: 'Ropa de personal médico y artículos relacionados',
	},
	{
		code: '42131601',
		label: 'Delantales o petos para personal médico',

		palabrasSimilares: 'Delantales o baberos para personal médico',
	},
	{
		code: '42131602',
		label: 'Cobertores de barba para personal médico',

		palabrasSimilares: 'Cubierta de barba para personal médico',
	},
	{
		code: '42131603',
		label: 'Blusas o blusones para personal médico',

		palabrasSimilares: 'Bata o blusa para personal médico',
	},
	{
		code: '42131604',
		label: 'Gorro de quirófano para personal médico',
	},
	{
		code: '42131605',
		label: 'Overoles para personal médico',
	},
	{
		code: '42131606',
		label: 'Máscaras quirúrgicas o de aislamiento para personal médico',

		palabrasSimilares: 'Cubrebocas',
	},
	{
		code: '42131607',
		label: 'Chaquetas o batas para personal médico',
	},
	{
		code: '42131608',
		label: 'Vestidos para cirugía para personal médico',

		palabrasSimilares: 'Ropa de cirugía para personal médico',
	},
	{
		code: '42131609',
		label: 'Cubiertas para zapatos para personal médico',
	},
	{
		code: '42131610',
		label: 'Protección de mangas para personal médico',
	},
	{
		code: '42131611',
		label: 'Gorros o capuchas para cirujano',
	},
	{
		code: '42131612',
		label: 'Batas de aislamiento para personal médico',
	},
	{
		code: '42131613',
		label: 'Protectores de ojos o visores para personal médico',
	},
	{
		code: '42131700',
		label: 'Prendas textiles quirúrgicas',
	},
	{
		code: '42131701',
		label: 'Cortinas de cirugía',

		palabrasSimilares: 'Cortinas quirúrgicas',
	},
	{
		code: '42131702',
		label: 'Batas de cirugía',

		palabrasSimilares: 'Batas quirúrgicas',
	},
	{
		code: '42131704',
		label: 'Toallas de cirugía',

		palabrasSimilares: 'Toallas quirúrgicas',
	},
	{
		code: '42131705',
		label: 'Pantalones (“leggings”) de cirugía',

		palabrasSimilares: 'Polainas quirúrgicas',
	},
	{
		code: '42131706',
		label: 'Vestidos enteros de cirugía',

		palabrasSimilares: 'Ropa quirúrgica desechable',
	},
	{
		code: '42131707',
		label:
			'Vestidos o cascos o máscaras faciales o accesorios de aislamiento de cirugía',

		palabrasSimilares:
			'Trajes de aislamiento quirúrgico o cascos o mascarillas o accesorios',
	},
	{
		code: '42131708',
		label:
			'Paquete o kit para limpieza quirúrgica o rotación de salas quirúrgicas',
	},
	{
		code: '42132100',
		label: 'Ropa blanca del hospital',
	},
	{
		code: '42132101',
		label: 'Protectores de colchón o silla para hospital',
	},
	{
		code: '42132102',
		label: 'Sábanas elásticas médicas',
	},
	{
		code: '42132103',
		label: 'Cortinas de barrera para pacientes',
	},
	{
		code: '42132104',
		label: 'Almohadas antimicrobianas para hospital',
	},
	{
		code: '42132105',
		label: 'Sábanas para hospital',
	},
	{
		code: '42132106',
		label: 'Colchas o cubrecamas para hospital',
	},
	{
		code: '42132107',
		label: 'Cobijas para hospital',
	},
	{
		code: '42132108',
		label: 'Fundas protectoras de almohada para hospital',
	},
	{
		code: '42132200',
		label: 'Guantes y accesorios médicos',
	},
	{
		code: '42132201',
		label: 'Cajas o dispensadores de guantes médicos',
	},
	{
		code: '42132202',
		label: 'Protector de caucho para dedos.',

		palabrasSimilares: 'Protectores de látex para dedos',
	},
	{
		code: '42132203',
		label: 'Guantes de examen o para procedimientos no quirúrgicos',
	},
	{
		code: '42132204',
		label: 'Recubrimientos interiores para guantes médicos',

		palabrasSimilares: 'Guantes quirúrgicos interiores',
	},
	{
		code: '42132205',
		label: 'Guantes de cirugía',

		palabrasSimilares: 'Guantes quirúrgicos',
	},
	{
		code: '42141500',
		label: 'Bolas de algodón y copitos aplicadores',
	},
	{
		code: '42141501',
		label: 'Bolas o fibra de algodón',
	},
	{
		code: '42141502',
		label: 'Palitos (copitos) con punta de fibra',

		palabrasSimilares: 'Cotonete, Hisopo',
	},
	{
		code: '42141503',
		label: 'Toallitas de preparación de la piel',
	},
	{
		code: '42141504',
		label: 'Aplicadores o absorbentes medicados',
	},
	{
		code: '42141600',
		label: 'Palanganas y Bacinillas de cama y orinales y equipos de ingreso',
	},
	{
		code: '42141601',
		label: 'Kits de admisión para el cuidado del paciente',
	},
	{
		code: '42141602',
		label: 'Patos (bacinillas) para uso general',
	},
	{
		code: '42141603',
		label: 'Recipientes para emesis (vómito)',
	},
	{
		code: '42141605',
		label: 'Recipientes o cuencos para soluciones o mezclas médicas',
	},
	{
		code: '42141606',
		label: 'Recipientes multipropósito para usos médicos',

		palabrasSimilares: 'Recipientes multifuncionales para uso médico',
	},
	{
		code: '42141607',
		label: 'Orinales de uso general para pacientes',
	},
	{
		code: '42141608',
		label: 'Bolsas o recubrimientos para patos (bacinillas)',
	},
	{
		code: '42141700',
		label: 'Productos para la prevención de decúbito',
	},
	{
		code: '42141701',
		label: 'Sistemas de presión alterna',

		palabrasSimilares: 'Colchón de presión alterna',
	},
	{
		code: '42141702',
		label: 'Marcos o elevadores de cobijas',
	},
	{
		code: '42141703',
		label: 'Cunas para extremidades',
	},
	{
		code: '42141704',
		label: 'Recubrimientos para colchones',
	},
	{
		code: '42141705',
		label:
			'Almohadones o almohadillas o almohadas para la posición del paciente',
	},
	{
		code: '42141800',
		label: 'Equipo de electroterapia',
	},
	{
		code: '42141801',
		label: 'Unidades de combinación de electroterapia',
	},
	{
		code: '42141802',
		label: 'Electrodos o accesorios para electroterapia',
	},
	{
		code: '42141803',
		label: 'Cables o alambres de plomo para electroterapia',
	},
	{
		code: '42141804',
		label: 'Simuladores galvánicos o farádicos',
	},
	{
		code: '42141805',
		label: 'Estimuladores o kits neuromusculares',
	},
	{
		code: '42141806',
		label: 'Unidades de diatermia de onda corta',
	},
	{
		code: '42141807',
		label: 'Unidades de estimulación nerviosa eléctrica transcutánea',
	},
	{
		code: '42141808',
		label: 'Electrodos de diatermia',
	},
	{
		code: '42141809',
		label: 'Sets de estimulador muscular',
	},
	{
		code: '42141810',
		label: 'Electrodo de iontoforesis',
	},
	{
		code: '42141811',
		label: 'Sistema de terapia de ondas de choque extracorpóreas',
	},
	{
		code: '42141812',
		label: 'Generador de electricidad estática para estimulación eléctrica',
	},
	{
		code: '42141813',
		label: 'Dispositivo electro convulsivo',
	},
	{
		code: '42141814',
		label: 'Estimulador de electricidad estática',
	},
	{
		code: '42141815',
		label: 'Estimulador de ondas electromagnéticas de alta frecuencia',
	},
	{
		code: '42141816',
		label: 'Estimulador de terapia electromagnética',
	},
	{
		code: '42141817',
		label: 'Estimulador de pulso magnético',
	},
	{
		code: '42141900',
		label: 'Suministros para la administración de enema',
	},
	{
		code: '42141901',
		label: 'Baldes para enema',
	},
	{
		code: '42141902',
		label: 'Bolsas para enema',
	},
	{
		code: '42141903',
		label: 'Kits o accesorios para enema',
	},
	{
		code: '42141904',
		label: 'Tubos o tapas o pinzas para enema',
	},
	{
		code: '42141905',
		label: 'Jabones para enema',
	},
	{
		code: '42142000',
		label: 'Instrumentos de grado bajo "floor grade"',
	},
	{
		code: '42142001',
		label: 'Fórceps o homeostatos de bajo grado',

		palabrasSimilares: 'Pinzas homeostáticas',
	},
	{
		code: '42142002',
		label: 'Cuchillo de bajo grado',
	},
	{
		code: '42142003',
		label: 'Manijas para cuchillo de bajo grado',
	},
	{
		code: '42142004',
		label: 'Corta uñas de bajo grado',

		palabrasSimilares: 'Pinza tipo alicata',
	},
	{
		code: '42142005',
		label: 'Sujeta agujas de bajo grado',

		palabrasSimilares: 'Porta agujas',
	},
	{
		code: '42142006',
		label: 'Retractores de bajo grado',
	},
	{
		code: '42142007',
		label: 'Tijeras de bajo grado',
	},
	{
		code: '42142100',
		label: 'Productos de terapia de frío y de calor',
	},
	{
		code: '42142101',
		label: 'Cubiertas para productos para terapia de calor o frío',
	},
	{
		code: '42142102',
		label:
			'Unidades o accesorios de enfriamiento para almacenamiento frío para uso médico',

		palabrasSimilares:
			'Unidades frigoríficas o accesorios de refrigeración médica',
	},
	{
		code: '42142103',
		label: 'Lámparas de calor o sus accesorios para uso médico',
	},
	{
		code: '42142104',
		label: 'Hidro coladores o sus accesorios para uso médico',
	},
	{
		code: '42142105',
		label: 'Unidades o sistemas de calentamiento o enfriamiento terapéutico',

		palabrasSimilares:
			'Unidades o sistemas de calefacción o refrigeración terapéutica',
	},
	{
		code: '42142106',
		label: 'Cobijas o cortinas de calentamiento o enfriamiento terapéutico',

		palabrasSimilares:
			'Mantas o cortinas para calefacción o refrigeración terapéutica',
	},
	{
		code: '42142107',
		label: 'Sistema y accesorios de terapia de compresión crio terapéutica',
	},
	{
		code: '42142108',
		label:
			'Almohadillas o compresas o bolsas de calentamiento o enfriamiento terapéutico',

		palabrasSimilares:
			'Almohadillas o compresas o bolsas de calefacción o refrigeración terapéutica',
	},
	{
		code: '42142109',
		label: 'Guantes de terapia para terapia de calor o frío',

		palabrasSimilares: 'Guantes para tratamiento terapéutico con frío o calor',
	},
	{
		code: '42142110',
		label: 'Botellas para terapia de calor o frío',

		palabrasSimilares: 'Bolsa terapéuticas de agua caliente o fría',
	},
	{
		code: '42142111',
		label: 'Bolsas o almohadas de hielo terapéuticas',
	},
	{
		code: '42142112',
		label: 'Baños de parafina terapéuticos o sus accesorios',
	},
	{
		code: '42142113',
		label: 'Máscaras para senos nasales terapéuticas',

		palabrasSimilares: 'Antifaz terapéutico para senos nasales',
	},
	{
		code: '42142114',
		label: 'Pantalones terapéuticos para terapia fría o caliente',
	},
	{
		code: '42142119',
		label: 'Aparatos de hipotermia',
	},
	{
		code: '42142120',
		label: 'Manga terapéutica para terapia de crio compresión',
	},
	{
		code: '42142121',
		label: 'Gabinete de vapor húmedo',
	},
	{
		code: '42142200',
		label: 'Productos de hidroterapia',
	},
	{
		code: '42142201',
		label: 'Baños o tanques de hidroterapia para las extremidades',
	},
	{
		code: '42142202',
		label: 'Baños o tanques de hidroterapia para inmersión total del cuerpo',
	},
	{
		code: '42142203',
		label: 'Accesorios para baños o tanques de hidroterapia',
	},
	{
		code: '42142204',
		label: 'Asientos para baños de hidroterapia',
	},
	{
		code: '42142300',
		label: 'Productos de documentación médica',
	},
	{
		code: '42142301',
		label: 'Etiquetas médicas para uso general',

		palabrasSimilares: 'Etiquetas para uso médico',
	},
	{
		code: '42142302',
		label: 'Componentes o accesorios para sistemas de planillas médicas',
	},
	{
		code: '42142303',
		label: 'Productos de identificación de pacientes',
	},
	{
		code: '42142400',
		label: 'Productos médicos de vacío o de succión',
	},
	{
		code: '42142401',
		label: 'Solidificadores de fluidos para uso médico',
	},
	{
		code: '42142402',
		label: 'Cánulas o tubos o accesorios de succión para uso médico',
	},
	{
		code: '42142403',
		label: 'Contenedores de succión para uso médico',
	},
	{
		code: '42142404',
		label: 'Aplicaciones al vacío o de succión para uso médico',

		palabrasSimilares: 'Aparatos médicos de succión o de vacío',
	},
	{
		code: '42142406',
		label: 'Sets o kits de succión para uso médico',
	},
	{
		code: '42142407',
		label: 'Estuches para cánulas de succión para uso médico',
	},
	{
		code: '42142500',
		label: 'Agujas de inyección y aspiración y accesorios',
	},
	{
		code: '42142501',
		label: 'Agujas para amniocentesis',
	},
	{
		code: '42142502',
		label: 'Agujas para anestesia',
	},
	{
		code: '42142503',
		label: 'Agujas arteriales',

		palabrasSimilares: 'Catéteres arteriales',
	},
	{
		code: '42142504',
		label: 'Agujas para biopsia',
	},
	{
		code: '42142505',
		label: 'Soportes para agujas de recolección de sangre',

		palabrasSimilares: 'Porta aguja de extracción de sangre',
	},
	{
		code: '42142506',
		label: 'Agujas romas',

		palabrasSimilares: 'Agujas punta roma',
	},
	{
		code: '42142507',
		label: 'Agujas mariposa',
	},
	{
		code: '42142509',
		label: 'Bandejas o accesorios epidurales',
	},
	{
		code: '42142510',
		label: 'Agujas de filtro',
	},
	{
		code: '42142511',
		label: 'Tapas o dispositivos protectores o accesorios para agujas',
	},
	{
		code: '42142512',
		label: 'Agujas de fístula',

		palabrasSimilares: 'Agujas para fístula',
	},
	{
		code: '42142513',
		label: 'Agujas para procedimientos de radiología',
	},
	{
		code: '42142514',
		label: 'Bandejas o agujas espinales',
	},
	{
		code: '42142515',
		label: 'Agujas de tubo al vacío',

		palabrasSimilares: 'Agujas de tuvo de vacío',
	},
	{
		code: '42142516',
		label: 'Agujas de transferencia',
	},
	{
		code: '42142517',
		label: 'Tubos de extensión',
	},
	{
		code: '42142518',
		label: 'Productos o accesorios aspiradores para biopsia',
	},
	{
		code: '42142519',
		label: 'Guías para agujas',
	},
	{
		code: '42142520',
		label: 'Alfileres dispensadores',
	},
	{
		code: '42142521',
		label: 'Agujas para recolección de sangre',
	},
	{
		code: '42142522',
		label: 'Agujas quirúrgicas para oídos o narices o gargantas',
	},
	{
		code: '42142523',
		label: 'Agujas hipodérmicas',
	},
	{
		code: '42142524',
		label: 'Agujas o sets para grabar',
	},
	{
		code: '42142525',
		label: 'Agujas de irrigación',
	},
	{
		code: '42142526',
		label: 'Protectores de agujas',
	},
	{
		code: '42142527',
		label: 'Alambres para limpiar agujas',
	},
	{
		code: '42142528',
		label: 'Agujas o sets para punción del esternón',
	},
	{
		code: '42142529',
		label: 'Bandejas para agujas o porta agujas',
	},
	{
		code: '42142530',
		label: 'Agujas para procedimientos diagnósticos',
	},
	{
		code: '42142531',
		label:
			'Contenedores o carritos o accesorios para desecho de agujas o cuchillas u otros objetos afilados',
	},
	{
		code: '42142532',
		label: 'Agujas o kits o accesorios para pericardiocentesis',
	},
	{
		code: '42142533',
		label: 'Agujas para administración de puertos intravenosos o arteriales',
	},
	{
		code: '42142534',
		label: 'Aguja interósea',
	},
	{
		code: '42142535',
		label: 'Aguja o bandeja para bloqueo nervioso anestésico',
	},
	{
		code: '42142536',
		label: 'Aguja y catéter para toracentesis o paracentesis',
	},
	{
		code: '42142537',
		label: 'Set o bandeja para toracentesis',
	},
	{
		code: '42142538',
		label: 'Set o bandeja para paracentesis',
	},
	{
		code: '42142600',
		label: 'Jeringas y accesorios',
	},
	{
		code: '42142601',
		label: 'Jeringas para aspiración o irrigación médica',
	},
	{
		code: '42142602',
		label: 'Peras de caucho para usos médicos',
	},
	{
		code: '42142603',
		label: 'Jeringas de cartucho para uso médico',
	},
	{
		code: '42142604',
		label: 'Jeringas de punta de catéter para uso médico',
	},
	{
		code: '42142605',
		label: 'Jeringas para oídos para uso médico',
	},
	{
		code: '42142606',
		label: 'Jeringas de entrega medida para uso médico',
	},
	{
		code: '42142607',
		label: 'Micro jeringas para uso médico',
	},
	{
		code: '42142608',
		label: 'Jeringas sin agujas para uso médico',
	},
	{
		code: '42142609',
		label: 'Jeringas con agujas para uso médico',
	},
	{
		code: '42142610',
		label: 'Jeringas de medicación líquida oral',
	},
	{
		code: '42142611',
		label: 'Jeringas de tuberculina',
	},
	{
		code: '42142612',
		label: 'Sets de jeringas de irrigación',
	},
	{
		code: '42142613',
		label: 'Pistolas de inyección',
	},
	{
		code: '42142614',
		label: 'Aparatos o accesorios para inyección hipodérmica',
	},
	{
		code: '42142615',
		label: 'Accesorios para jeringas',
	},
	{
		code: '42142616',
		label: 'Jeringas de recolección de sangre',
	},
	{
		code: '42142617',
		label: 'Jeringas de fuente',
	},
	{
		code: '42142618',
		label: 'Kits de jeringas de análisis de gas en la sangre',
	},
	{
		code: '42142619',
		label: 'Jeringa de lavado pre llenada',
	},
	{
		code: '42142620',
		label: 'Controlador o pistola de inyección de aguja interósea',
	},
	{
		code: '42142700',
		label: 'Suministros urológicos',
	},
	{
		code: '42142701',
		label: 'Catéteres urinarios supra púbicos',
	},
	{
		code: '42142702',
		label: 'Catéteres urinarios uretrales',
	},
	{
		code: '42142703',
		label: 'Tapones o pinzas para catéteres urinarios',
	},
	{
		code: '42142704',
		label: 'Bolsas o medidores para drenaje urinario',
	},
	{
		code: '42142705',
		label: 'Tiras o sujetadores para bolsas de drenaje urinario',
	},
	{
		code: '42142706',
		label: 'Bandejas o paquetes o kits para procedimientos urológicos',
	},
	{
		code: '42142707',
		label: 'Tubos de irrigación urinaria',
	},
	{
		code: '42142708',
		label: 'Adaptadores para instrumentos de urología',
	},
	{
		code: '42142709',
		label: 'Accesorios o sets para nefrostomía',
	},
	{
		code: '42142710',
		label: 'Tubos o accesorios para drenaje urinario',
	},
	{
		code: '42142711',
		label: 'Sets de auscultación uretral',
	},
	{
		code: '42142712',
		label: 'Sets o accesorios de remoción de cálculos',
	},
	{
		code: '42142713',
		label: 'Cubiertas o sets urológicos',
	},
	{
		code: '42142714',
		label: 'Percoladores urológicos',
	},
	{
		code: '42142715',
		label: 'Kits o accesorios de cateterización urológica',
	},
	{
		code: '42142716',
		label: 'Dispositivo pesario',
	},
	{
		code: '42142717',
		label: 'Catéter o drenaje para nefrostomía',
	},
	{
		code: '42142718',
		label: 'Dispositivo de soporte y alargamiento del pene',
	},
	{
		code: '42142719',
		label: 'Uretrotomo',
	},
	{
		code: '42142720',
		label: 'Catéter urinario externo masculino',
	},
	{
		code: '42142721',
		label: 'Alambre guía o alambre de deslizamiento urológico',
	},
	{
		code: '42142800',
		label: 'Suministros y equipos para terapia vascular y de compresión',
	},
	{
		code: '42142801',
		label: 'Dispositivos o tubos para compresión secuencial vascular',
	},
	{
		code: '42142802',
		label: 'Prendas o soporte para compresión o vasculares',
	},
	{
		code: '42142900',
		label:
			'Corrección de la visión o gafas cosméticas y productos relacionados',
	},
	{
		code: '42142901',
		label: 'Anteojos',
	},
	{
		code: '42142902',
		label: 'Lentes para anteojos',
	},
	{
		code: '42142903',
		label: 'Monturas para anteojos',
	},
	{
		code: '42142904',
		label: 'Hardware para anteojos',
	},
	{
		code: '42142905',
		label: 'Anteojos de sol',
	},
	{
		code: '42142906',
		label: 'Estuches para anteojos',
	},
	{
		code: '42142907',
		label: 'Pañitos para limpiar anteojos',
	},
	{
		code: '42142908',
		label: 'Kits para limpiar anteojos',
	},
	{
		code: '42142909',
		label: 'Retenedores de anteojos',
	},
	{
		code: '42142910',
		label: 'Estuches para lentes de contacto',
	},
	{
		code: '42142911',
		label: 'Insertores o removedores de lentes de contacto',
	},
	{
		code: '42142912',
		label: 'Medidores de radio de lentes de contacto',
	},
	{
		code: '42142913',
		label: 'Lentes de contacto',
	},
	{
		code: '42142914',
		label: 'Solución limpiadora o humidificadora de lentes de contacto',
	},
	{
		code: '42143100',
		label: 'Equipo y suministros de obstetricia y ginecología',
	},
	{
		code: '42143101',
		label: 'Catéteres o kits de cateterización intrauterina',
	},
	{
		code: '42143102',
		label: 'Dispositivos o accesorios uterinos',
	},
	{
		code: '42143103',
		label: 'Dispositivos o accesorios contraceptivos intrauterinos',
	},
	{
		code: '42143104',
		label: 'Kits o accesorios para amniocentesis',
	},
	{
		code: '42143105',
		label: 'Unidades o accesorios de extracción obstétrica',
	},
	{
		code: '42143106',
		label: 'Kits o accesorios de drenaje ginecológico',
	},
	{
		code: '42143107',
		label: 'Perinómetro',
	},
	{
		code: '42143108',
		label: 'Cámara de descompresión abdominal',
	},
	{
		code: '42143200',
		label:
			'Equipo y suministros de tratamiento de la fertilidad y la esterilidad',
	},
	{
		code: '42143201',
		label: 'Kits o accesorios de preparación de semen',
	},
	{
		code: '42143202',
		label: 'Productos o kits o accesorios para el tratamiento de la impotencia',
	},
	{
		code: '42143203',
		label: 'Catéter de inseminación artificial',
	},
	{
		code: '42143204',
		label: 'Catéter de transferencia de embriones',
	},
	{
		code: '42143300',
		label: 'Equipo y suministros de quimioterapia',
	},
	{
		code: '42143301',
		label: 'Sets o kits de administración de quimioterapia',
	},
	{
		code: '42143302',
		label: 'Almohadilla de preparación para quimioterapia',
	},
	{
		code: '42143303',
		label: 'Bolsa para transportar quimioterapia',
	},
	{
		code: '42143400',
		label: 'Equipo y suministros de control de hiperhidrosis',
	},
	{
		code: '42143401',
		label: 'Equipos o accesorios para el control del sudor',
	},
	{
		code: '42143500',
		label: 'Productos y accesorios de otorrinolaringología',
	},
	{
		code: '42143501',
		label: 'Ayudas para la limpieza de moldes auditivos',
	},
	{
		code: '42143502',
		label: 'Dispositivos o accesorios para irrigación nasal',
	},
	{
		code: '42143503',
		label: 'Dispositivos o bombas para el control de la hemorragia nasal',
	},
	{
		code: '42143504',
		label: 'Moldes auditivos o sus accesorios',
	},
	{
		code: '42143505',
		label: 'Kits de reparación de moldes auditivos',
	},
	{
		code: '42143506',
		label: 'Sets filiformes para la trompa de eustaquio',
	},
	{
		code: '42143507',
		label: 'Paquetes de medicación de oídos',
	},
	{
		code: '42143508',
		label: 'Capuchas de viento para oídos',
	},
	{
		code: '42143509',
		label: 'Pegantes o cementos para moldes auditivos',
	},
	{
		code: '42143510',
		label: 'Pantallas protectoras de viento para oídos',
	},
	{
		code: '42143511',
		label: 'Ganchos de cera de oídos',
	},
	{
		code: '42143512',
		label: 'Mechas para oídos',
	},
	{
		code: '42143513',
		label: 'Instrumentos o accesorios otológicos',
	},
	{
		code: '42143514',
		label: 'Sistema para lavado de oídos',
	},
	{
		code: '42143600',
		label: 'Sujeciones y accesorios',
	},
	{
		code: '42143601',
		label: 'Chalecos y chaquetas de fuerza (de restricción)',
	},
	{
		code: '42143602',
		label: 'Elementos de restricción de cintura y torso',
	},
	{
		code: '42143603',
		label: 'Elementos de restricción de extremidades',
	},
	{
		code: '42143604',
		label: 'Elementos de restricción de cabeza no para ems',
	},
	{
		code: '42143605',
		label: 'Correas o hebillas o accesorios o suministros de restricción',
	},
	{
		code: '42143606',
		label: 'Elementos de restricción para todo el cuerpo',
	},
	{
		code: '42143607',
		label: 'Sensores o alarmas o accesorios de movimiento de pacientes',
	},
	{
		code: '42143608',
		label:
			'Dispositivos o accesorios para estabilización o prevención de caídas de pacientes',
	},
	{
		code: '42143609',
		label: 'Tablas o accesorios para restricción de pacientes',
	},
	{
		code: '42143700',
		label: 'Sistemas y accesorios de fototerapia',
	},
	{
		code: '42143701',
		label: 'Circuladores de aire o accesorios para fototerapia',
	},
	{
		code: '42143702',
		label: 'Cobijas para fototerapia',
	},
	{
		code: '42143703',
		label: 'Bombillos para fototerapia',
	},
	{
		code: '42143704',
		label: 'Esteras de luz para fototerapia',
	},
	{
		code: '42143705',
		label: 'Dispositivos de protección de pacientes para fototerapia',
	},
	{
		code: '42143706',
		label: 'Unidades eléctricas para fototerapia',
	},
	{
		code: '42143707',
		label: 'Calentadores de camas o accesorios para fototerapia',
	},
	{
		code: '42143708',
		label: 'Chaleco para fototerapia',
	},
	{
		code: '42143709',
		label: 'Radiador de luz visible',
	},
	{
		code: '42143800',
		label:
			'Instrumentos y suministros y equipos para la medición de presión urológica',
	},
	{
		code: '42143801',
		label: 'Sistemas o accesorios para cistometría',
	},
	{
		code: '42143802',
		label: 'Transductores para cistometría',
	},
	{
		code: '42143803',
		label: 'Catéteres uro dinámicos',
	},
	{
		code: '42143900',
		label: 'Productos para la incontinencia fecal',
	},
	{
		code: '42143901',
		label: 'Bolsa o accesorios de recolección de incontinencia fecal',
	},
	{
		code: '42143902',
		label: 'Sistema o kit de manejo fecal',
	},
	{
		code: '42143903',
		label: 'Catéter de colon o tubo rectal',
	},
	{
		code: '42144000',
		label: 'Partes y accesorios para dispositivos auditivos externos',
	},
	{
		code: '42144001',
		label:
			'Procesador de sonido externo para dispositivos auditivos implantados',
	},
	{
		code: '42144002',
		label: 'Cable externo para dispositivos auditivos implantados',
	},
	{
		code: '42144003',
		label:
			'Audífonos procesadores de sonido externos para dispositivos auditivos implantados',
	},
	{
		code: '42144004',
		label: 'Magneto externo para dispositivos auditivos implantados',
	},
	{
		code: '42144005',
		label: 'Micrófono externo para dispositivos auditivos implantados',
	},
	{
		code: '42144100',
		label: 'Productos para drenaje de la cavidad pleural',
	},
	{
		code: '42144101',
		label:
			'Unidad o contenedor o accesorios para drenaje de la cavidad pléurica',
	},
	{
		code: '42144102',
		label: 'Tubo de pecho',
	},
	{
		code: '42144103',
		label: 'Kit de tubos de pecho',
	},
	{
		code: '42144200',
		label: 'Equipo de acupuntura',
	},
	{
		code: '42144201',
		label: 'Aguja de acupuntura',
	},
	{
		code: '42144202',
		label: 'Bolita o semilla magnética para acupuntura',
	},
	{
		code: '42144203',
		label: 'Brazalete para acu presión',
	},
	{
		code: '42144300',
		label: 'Productos de contra pulsación externa',
	},
	{
		code: '42144301',
		label: 'Sistema de contra pulsación externa ecp',
	},
	{
		code: '42144302',
		label: 'Mangas o pantalones para contra pulsación externa ecp',
	},
	{
		code: '42151500',
		label: 'Suministros y equipos de odontología cosmética',
	},
	{
		code: '42151501',
		label: 'Luces de curación o accesorios para odontología estética',
	},
	{
		code: '42151502',
		label: 'Pozos mezcladores para odontología estética',
	},
	{
		code: '42151503',
		label: 'Coronas o formas de coronas',
	},
	{
		code: '42151504',
		label: 'Laca dental',
	},
	{
		code: '42151505',
		label: 'Suministros de grabado de dientes',
	},
	{
		code: '42151506',
		label: 'Suministros blanqueadores o decolorantes de dientes',
	},
	{
		code: '42151507',
		label: 'Sombras dentales',
	},
	{
		code: '42151600',
		label: 'Instrumentos y dispositivos dentales y de subespecialidad',
	},
	{
		code: '42151601',
		label: 'Accesorios o partes de repuesto para instrumentos dentales',
	},
	{
		code: '42151602',
		label: 'Bandas para matriz dental',
	},
	{
		code: '42151603',
		label: 'Herramientas de colocación de hidróxido de calcio',
	},
	{
		code: '42151604',
		label: 'Herramientas de colocación de compuestos',
	},
	{
		code: '42151605',
		label: 'Removedores de coronas o puentes',
	},
	{
		code: '42151606',
		label: 'Talladores de amalgamas dentales',
	},
	{
		code: '42151607',
		label: 'Casetes para instrumentos dentales',
	},
	{
		code: '42151608',
		label: 'Bandejas o cubetas para instrumentos dentales',
	},
	{
		code: '42151609',
		label: 'Esterillas para instrumentos dentales',
	},
	{
		code: '42151610',
		label: 'Alicates dentales',
	},
	{
		code: '42151611',
		label: 'Cepillos operativos dentales',
	},
	{
		code: '42151612',
		label: 'Retractores dentales',
	},
	{
		code: '42151613',
		label: 'Pulidores dentales',
	},
	{
		code: '42151614',
		label: 'Fresas dentales',
	},
	{
		code: '42151615',
		label: 'Unidades crio quirúrgicas dentales',
	},
	{
		code: '42151616',
		label: 'Medidores de profundidad dentales',
	},
	{
		code: '42151617',
		label: 'Taladros o brocas dentales',
	},
	{
		code: '42151618',
		label: 'Elevadores dentales',
	},
	{
		code: '42151619',
		label: 'Excavadoras dentales',
	},
	{
		code: '42151620',
		label: 'Limas o curetas dentales',
	},
	{
		code: '42151621',
		label: 'Instrumentos para contorno de calzas dentales',
	},
	{
		code: '42151622',
		label: 'Protectores de dedos para uso odontológico',
	},
	{
		code: '42151623',
		label: 'Fórceps dentales',
	},
	{
		code: '42151624',
		label: 'Piezas manuales o accesorios para uso odontológico',
	},
	{
		code: '42151625',
		label: 'Instrumentos de higiene dental',
	},
	{
		code: '42151626',
		label: 'Accesorios para afilar instrumentos dentales',
	},
	{
		code: '42151627',
		label: 'Espejos o mangos de espejo para uso odontológico',
	},
	{
		code: '42151628',
		label: 'Lozas mezcladoras para uso odontológico',
	},
	{
		code: '42151629',
		label: 'Organizadores dentales',
	},
	{
		code: '42151630',
		label: 'Instrumentos de colocación para uso odontológico',
	},
	{
		code: '42151631',
		label: 'Sondas dentales',
	},
	{
		code: '42151632',
		label: 'Escariadores dentales',
	},
	{
		code: '42151633',
		label:
			'Instrumentos de empaque de cordones de retracción para uso odontológico',
	},
	{
		code: '42151634',
		label: 'Picos de punta de raíz para uso dental',
	},
	{
		code: '42151635',
		label:
			'Eyectores de saliva o dispositivos de succión oral o suministros dentales',
	},
	{
		code: '42151636',
		label: 'Raspadores dentales o accesorios',
	},
	{
		code: '42151637',
		label: 'Escalas dentales',
	},
	{
		code: '42151638',
		label: 'Tijeras dentales',
	},
	{
		code: '42151639',
		label: 'Espátulas dentales',
	},
	{
		code: '42151640',
		label: 'Pinzas dentales',
	},
	{
		code: '42151641',
		label: 'Grabadores de cera para uso odontológico',
	},
	{
		code: '42151642',
		label: 'Cuchillos de gingivectomía',
	},
	{
		code: '42151643',
		label: 'Cortadoras de margen dental',
	},
	{
		code: '42151644',
		label: 'Soportes para la boca para uso odontológico',
	},
	{
		code: '42151645',
		label:
			'Kits o bandejas operativas reusables pre ensambladas para uso odontológico',
	},
	{
		code: '42151646',
		label: 'Dispositivos protectores para dientes',
	},
	{
		code: '42151647',
		label: 'Sujetadores absorbentes para uso odontológico',
	},
	{
		code: '42151648',
		label: 'Calibradores para uso odontológico',
	},
	{
		code: '42151650',
		label: 'Herramientas de detección de fracturas para uso odontológico',
	},
	{
		code: '42151651',
		label: 'Separadores de dientes para uso odontológico',
	},
	{
		code: '42151652',
		label: 'Introductor de espigas para uso odontológico',
	},
	{
		code: '42151653',
		label: 'Doblador de espigas para uso odontológico',
	},
	{
		code: '42151654',
		label: 'Guías para uso odontológico',
	},
	{
		code: '42151655',
		label: 'Probadores de nervio o de vitalidad para uso odontológico',
	},
	{
		code: '42151656',
		label: 'Esparcidores para uso odontológico',
	},
	{
		code: '42151657',
		label: 'Tubos o accesorios para uso odontológico',
	},
	{
		code: '42151658',
		label: 'Enhebradores de seda dental',
	},
	{
		code: '42151659',
		label: 'Kit de extracción de partes de dientes',
	},
	{
		code: '42151660',
		label: 'Aplicadores o absorbentes para uso odontológico',
	},
	{
		code: '42151661',
		label: 'Estuches o bolsas para instrumentos dentales',
	},
	{
		code: '42151662',
		label: 'Electrodos de anestesia o repuestos para uso odontológico',
	},
	{
		code: '42151663',
		label: 'Cuñas o sets para uso odontológico',
	},
	{
		code: '42151664',
		label: 'Discos cortadores o separadores para uso odontológico',
	},
	{
		code: '42151665',
		label: 'Calibradores o accesorios para uso odontológico',
	},
	{
		code: '42151666',
		label: 'Sujetadores bucales para uso odontológico',
	},
	{
		code: '42151667',
		label: 'Matrices o sets para uso odontológico',
	},
	{
		code: '42151668',
		label:
			'Enrolladores de instrumentos para instrumentos o accesorios dentales',
	},
	{
		code: '42151669',
		label: 'Deshidratadores para uso odontológico',
	},
	{
		code: '42151670',
		label: 'Portadores de calor para uso odontológico',
	},
	{
		code: '42151671',
		label: 'Calzas o kits de calzas para sistemas post dentales',
	},
	{
		code: '42151672',
		label: 'Expansores para uso odontológico',
	},
	{
		code: '42151673',
		label: 'Martillos para uso odontológico',
	},
	{
		code: '42151674',
		label: 'Sujetadores de fresas para uso odontológico',
	},
	{
		code: '42151675',
		label: 'Kits de restauración para uso odontológico',
	},
	{
		code: '42151676',
		label: 'Cuchillos para uso odontológico',
	},
	{
		code: '42151677',
		label: 'Kits indicadores de presión para uso odontológico',
	},
	{
		code: '42151678',
		label: 'Sujetadores para almohadillas o láminas mezcladoras',
	},
	{
		code: '42151679',
		label: 'Bolsas mezcladoras para uso odontológico',
	},
	{
		code: '42151680',
		label: 'Topes o accesorios endodónticos',
	},
	{
		code: '42151681',
		label: 'Sets o accesorios de anestesia para uso odontológico',
	},
	{
		code: '42151682',
		label: 'Broca endodóntica',
	},
	{
		code: '42151683',
		label: 'Arco facial',
	},
	{
		code: '42151700',
		label: 'Muebles para la clínica dental',
	},
	{
		code: '42151701',
		label: 'Sillas para examen dental o partes relacionadas o accesorios',
	},
	{
		code: '42151702',
		label: 'Taburetes para uso odontológico',
	},
	{
		code: '42151703',
		label: 'Gabinetes para uso odontológico',
	},
	{
		code: '42151704',
		label: 'Mesas o accesorios para uso odontológico',
	},
	{
		code: '42151705',
		label: 'Sets de mobiliarios combinados para procedimientos dentales',

		palabrasSimilares: 'Gabinetes combinados para consultorio dental',
	},
	{
		code: '42151800',
		label: 'Rellenos y acabados dentales y suministros para pulir',
	},
	{
		code: '42151801',
		label: 'Portadores de amalgamas',
	},
	{
		code: '42151802',
		label: 'Obturadores o puntas o accesorios para uso odontológico',
	},
	{
		code: '42151803',
		label: 'Cápsulas de amalgama para uso odontológico',
	},
	{
		code: '42151804',
		label: 'Empaquetadores de cable para uso odontológico',
	},
	{
		code: '42151805',
		label: 'Discos pulidores o de terminado para uso odontológico',
	},
	{
		code: '42151806',
		label: 'Tiras pulidoras o de terminado para uso odontológico',
	},
	{
		code: '42151807',
		label: 'Puntas pulidoras o de terminado para uso odontológico',
	},
	{
		code: '42151808',
		label: 'Compuestos brilladores para uso odontológico',
	},
	{
		code: '42151809',
		label: 'Paños comprimibles para uso odontológico',

		palabrasSimilares: 'Paños comprimidos para uso odontológico',
	},
	{
		code: '42151810',
		label: 'Kits pulidores o de terminado para uso odontológico',
	},
	{
		code: '42151811',
		label: 'Copas o sets pulidores para uso odontológico',
	},
	{
		code: '42151812',
		label: 'Resortes para abrasión o máquinas pulidoras para uso odontológico',
	},
	{
		code: '42151813',
		label: 'Tubos de calzas para uso odontológico',
	},
	{
		code: '42151814',
		label:
			'Adaptadores de placas de sujeción para tallado o máquinas pulidoras para uso odontológico',
	},
	{
		code: '42151815',
		label: 'Conos para tallado o máquinas pulidoras para uso odontológico',
	},
	{
		code: '42151816',
		label: 'Portadores de lámina de oro',
	},
	{
		code: '42151900',
		label: 'Equipo y suministros para higiene dental y cuidado preventivo',
	},
	{
		code: '42151901',
		label: 'Cepillos para paladares o prótesis dentales',
	},
	{
		code: '42151902',
		label: 'Kits de profilaxis para uso odontológico',
	},
	{
		code: '42151903',
		label: 'Copas o contenedores para prótesis dentales',
	},
	{
		code: '42151904',
		label: 'Soluciones o tabletas reveladoras',
	},
	{
		code: '42151905',
		label: 'Geles o enjuagues de fluoruro',

		palabrasSimilares: 'Fluoruro de sodio o enjuagues bucales',
	},
	{
		code: '42151906',
		label: 'Tabletas o gotas de fluoruro',
	},
	{
		code: '42151907',
		label: 'Tanques de profilaxis para uso odontológico',
	},
	{
		code: '42151908',
		label: 'Frascos o sets para prótesis dentales',
	},
	{
		code: '42151909',
		label: 'Pastas o kits de prevención dental',
	},
	{
		code: '42151910',
		label: 'Dispositivos o accesorios para limpiar dientes',
	},
	{
		code: '42151911',
		label: 'Sets acondicionadores de tejido dental',
	},
	{
		code: '42151912',
		label: 'Aerosoles endodónticos',

		palabrasSimilares: 'Aerosoles dentales',
	},
	{
		code: '42152000',
		label: 'Equipo y suministros para hacer imágenes dentales',
	},
	{
		code: '42152001',
		label: 'Sujetadores de aleta de mordida',
	},
	{
		code: '42152002',
		label: 'Bloques o aletas o tabletas de mordida para uso odontológico',
	},
	{
		code: '42152003',
		label: 'Procesadores de película para uso odontológico',
	},
	{
		code: '42152004',
		label: 'Colgadores de película radiológica para uso odontológico',

		palabrasSimilares:
			'Colgadores para película de rayos x para uso odontológico',
	},
	{
		code: '42152005',
		label: 'Sujetadores de película radiológica para uso odontológico',

		palabrasSimilares: 'Soporte de película radiológica para uso odontológico',
	},
	{
		code: '42152006',
		label: 'Monturas de película radiológica para uso odontológico',
	},
	{
		code: '42152007',
		label: 'Duplicadores de rayos x para uso odontológico',
	},
	{
		code: '42152008',
		label: 'Unidades de rayos x para uso odontológico',
	},
	{
		code: '42152009',
		label: 'Visores o accesorios de rayos x para uso odontológico',
	},
	{
		code: '42152010',
		label: 'Película radiológica para uso odontológico',
	},
	{
		code: '42152011',
		label: 'Adaptadores de película de rayos x para uso odontológico',
	},
	{
		code: '42152012',
		label:
			'Partes o kits o accesorios para aparatos de rayos x para uso odontológico',

		palabrasSimilares:
			'Partes o accesorios para aparatos de rayos x para uso odontológico',
	},
	{
		code: '42152013',
		label: 'Analizadores de película radiográfica para uso odontológico',
	},
	{
		code: '42152014',
		label: 'Trazador radioactivo para uso odontológico',
	},
	{
		code: '42152100',
		label: 'Equipo y suministros dentales para impresión y formar',
	},
	{
		code: '42152101',
		label: 'Anillos de molde o suministros relacionados para uso odontológico',
	},
	{
		code: '42152102',
		label: 'Formadores para uso odontológico',
	},
	{
		code: '42152103',
		label: 'Adhesivos para bandejas de impresión para uso odontológico',
	},
	{
		code: '42152104',
		label: 'Limpiadores de bandejas de impresión para uso odontológico',
	},
	{
		code: '42152105',
		label: 'Bandejas de impresión para uso odontológico',
	},
	{
		code: '42152106',
		label: 'Cuchillos para yeso para uso odontológico',
	},
	{
		code: '42152107',
		label: 'Instrumentos de encerado para uso odontológico',
	},
	{
		code: '42152108',
		label:
			'Jeringas de material de impresión o accesorios para uso odontológico',
	},
	{
		code: '42152109',
		label:
			'Baños de agua para material de impresión o accesorios para uso odontológico',
	},
	{
		code: '42152110',
		label: 'Dispositivos para marcar dientes',
	},
	{
		code: '42152111',
		label: 'Endurecedores de material de impresión para uso odontológico',
	},
	{
		code: '42152112',
		label: 'Instrumentos de secado o accesorios para uso odontológico',
	},
	{
		code: '42152113',
		label: 'Kits de formación de base para prótesis dentales',
	},
	{
		code: '42152114',
		label: 'Kits de entrega de materiales de impresión para uso odontológico',
	},
	{
		code: '42152115',
		label: 'Canastas de impresión para uso odontológico',
	},
	{
		code: '42152200',
		label: 'Equipo y suministros dentales de laboratorio y de esterilización',
	},
	{
		code: '42152201',
		label: 'Láminas dentales',
	},
	{
		code: '42152202',
		label: 'Unidades de abrasión por aire de laboratorio dental',
	},
	{
		code: '42152203',
		label: 'Quemadores o antorchas de laboratorio dental',
	},
	{
		code: '42152204',
		label:
			'Máquinas para enyesar, sus partes o accesorios de laboratorio dental',
	},
	{
		code: '42152205',
		label: 'Unidades de curado de laboratorio dental',
	},
	{
		code: '42152206',
		label: 'Tintes de laboratorio dental',
	},
	{
		code: '42152207',
		label: 'Recolectores de polvo de laboratorio dental',
	},
	{
		code: '42152208',
		label: 'Máquinas o accesorios de laboratorio dental',
	},
	{
		code: '42152209',
		label: 'Hornos de uso odontológico para laboratorios',
	},
	{
		code: '42152210',
		label: 'Chapas de oro de laboratorio dental',

		palabrasSimilares: 'Piezas o suministros de oro para laboratorio dental',
	},
	{
		code: '42152211',
		label: 'Tornos o accesorios de laboratorio dental',
	},
	{
		code: '42152212',
		label: 'Bordeadoras de modelos de laboratorio dental',
	},
	{
		code: '42152213',
		label: 'Modelos de laboratorio dentales',
	},
	{
		code: '42152214',
		label: 'Trampas de yeso de laboratorio dental',
	},
	{
		code: '42152215',
		label: 'Lijadoras de chorro de arena o suministros de laboratorio dental',

		palabrasSimilares: 'Arenadora dental o sus suministros para laboratorio',
	},
	{
		code: '42152216',
		label: 'Máquinas soldadoras o suministros de laboratorio dental',
	},
	{
		code: '42152217',
		label: 'Unidades de vacío o suministros de laboratorio dental',
	},
	{
		code: '42152218',
		label: 'Vibradores de uso odontológico para laboratorios',
	},
	{
		code: '42152219',
		label: 'Unidades de encerado de laboratorio dental',
	},
	{
		code: '42152220',
		label: 'Piedras dentales para laboratorios',
	},
	{
		code: '42152221',
		label: 'Protectores de moldes o estuches de laboratorio dental',
	},
	{
		code: '42152222',
		label: 'Crisoles para máquinas de fundición de uso odontológico',
	},
	{
		code: '42152223',
		label:
			'Unidades o accesorios de procesamiento de resinas de laboratorio dental',
	},
	{
		code: '42152224',
		label: 'Cantimploras de laboratorio dental',
	},
	{
		code: '42152300',
		label:
			'Láseres y equipo de iluminación y fibra óptica para odontología, y suministros',
	},
	{
		code: '42152301',
		label: 'Láseres de uso odontológico',

		palabrasSimilares: 'Láseres dentales',
	},
	{
		code: '42152302',
		label: 'Dentoscopios',
	},
	{
		code: '42152303',
		label: 'Luces para uso odontológico general o sus accesorios',

		palabrasSimilares: 'Lámparas dentales generales o sus accesorios',
	},
	{
		code: '42152304',
		label: 'Luces intraorales',

		palabrasSimilares: 'Lámparas intraorales',
	},
	{
		code: '42152305',
		label: 'Trípodes de luces de operación para uso odontológico',

		palabrasSimilares:
			'Tripié para lámparas de operación para uso odontológico',
	},
	{
		code: '42152306',
		label: 'Luces o accesorios de fibra óptica para uso odontológico',

		palabrasSimilares:
			'Lámparas o accesorios de fibra óptica para uso odontológico',
	},
	{
		code: '42152307',
		label: 'Sets de iluminación de operación para uso odontológico',

		palabrasSimilares:
			'Conjunto de iluminación de operación para uso odontológico',
	},
	{
		code: '42152400',
		label: 'Materiales dentales',
	},
	{
		code: '42152401',
		label: 'Aleaciones para amalgamas de uso odontológico',
	},
	{
		code: '42152402',
		label: 'Materiales protectores de la boca para atletas',
	},
	{
		code: '42152403',
		label:
			'Aleaciones de molibdeno cromo cobalto fundido para implantes dentales',
	},
	{
		code: '42152404',
		label:
			'Materiales de impresión hidrocoloides irreversibles reversibles combinados',
	},
	{
		code: '42152405',
		label: 'Plásticos para coronas o puentes',
	},
	{
		code: '42152406',
		label: 'Pastas abrasivas de uso odontológico',
	},
	{
		code: '42152407',
		label: 'Polvos abrasivos de uso odontológico',
	},
	{
		code: '42152408',
		label: 'Puntos absorbentes de uso odontológico',
	},
	{
		code: '42152409',
		label: 'Materiales de impresión agar de uso odontológico',
	},
	{
		code: '42152410',
		label: 'Materiales de impresión alginadas de uso odontológico',

		palabrasSimilares: 'Alginatos de uso odontológico',
	},
	{
		code: '42152411',
		label: 'Aleaciones de fundición de metal de base de uso odontológico',
	},
	{
		code: '42152412',
		label: 'Ceras de placa de base de uso odontológico',
	},
	{
		code: '42152413',
		label: 'Aleaciones de soldadura fuerte de uso odontológico',
	},
	{
		code: '42152414',
		label: 'Aleaciones de fundición de uso odontológico',
	},
	{
		code: '42152415',
		label: 'Cerámicas de uso odontológico',
	},
	{
		code: '42152416',
		label: 'Materiales de duplicación de uso odontológico',
	},
	{
		code: '42152417',
		label: 'Inversiones de sílice etilo de uso odontológico',
	},
	{
		code: '42152418',
		label: 'Productos de yeso de uso odontológico',
	},
	{
		code: '42152419',
		label:
			'Materiales de impresión de pasta de óxido de zinc eugenol de uso odontológico',
	},
	{
		code: '42152420',
		label: 'Ceras de fundición para incrustaciones de uso odontológico',
	},
	{
		code: '42152421',
		label: 'Mercurio de uso odontológico',
	},
	{
		code: '42152422',
		label: 'Puntos obturadores de uso odontológico',
	},
	{
		code: '42152423',
		label: 'Sellantes de huecos o fisuras de uso odontológico',

		palabrasSimilares: 'Selladores de huecos o fisuras de uso odontológico',
	},
	{
		code: '42152424',
		label: 'Cementos de base de agua de uso odontológico',

		palabrasSimilares: 'Cementos dentales a base de agua',
	},
	{
		code: '42152425',
		label: 'Resinas de base para prótesis dentales',
	},
	{
		code: '42152426',
		label: 'Resinas de forrado temporales de base para prótesis dentales',
	},
	{
		code: '42152427',
		label: 'Resinas de reparación de cura fría para prótesis dentales',
	},
	{
		code: '42152428',
		label: 'Resinas de relleno directo',
	},
	{
		code: '42152429',
		label: 'Materiales elastoméricos para impresiones dentales',
	},
	{
		code: '42152430',
		label: 'Materiales de relleno endodóntico',
	},
	{
		code: '42152431',
		label: 'Elastómeros de prótesis maxilofacial extra oral',
	},
	{
		code: '42152432',
		label: 'Inversiones de fundición de yeso unido para aleaciones de oro',
	},
	{
		code: '42152433',
		label: 'Película radiográfica dental intraoral',
	},
	{
		code: '42152434',
		label: 'Amalgamantes para uso odontológico',
	},
	{
		code: '42152435',
		label: 'Materiales elastómeros para ortodoncia',
	},
	{
		code: '42152436',
		label: 'Inversiones unidas de fosfato',
	},
	{
		code: '42152437',
		label: 'Dientes de porcelana',
	},
	{
		code: '42152438',
		label: 'Materiales refractarios de tinte',
	},
	{
		code: '42152439',
		label:
			'Materiales resistentes de recubrimiento para prótesis dentales removibles',
	},
	{
		code: '42152440',
		label: 'Inversiones de soldadura',
	},
	{
		code: '42152441',
		label: 'Dientes de polímeros sintéticos',
	},
	{
		code: '42152442',
		label: 'Titanio sin aleación para implantes dentales',
	},
	{
		code: '42152443',
		label: 'Cementos de óxido de zinc eugenol y de no eugenol',
	},
	{
		code: '42152444',
		label: 'Líquido de retracción gingival',
	},
	{
		code: '42152445',
		label: 'Sets de partes anteriores o posteriores de dientes',

		palabrasSimilares: 'Juego de carillas dentales',
	},
	{
		code: '42152446',
		label:
			'Recubrimientos o materiales adelgazantes o sets para caries dentales',

		palabrasSimilares: 'Revestimientos para cavidad dental y diluyentes',
	},
	{
		code: '42152447',
		label: 'Tintes o suministros de uso odontológico',
	},
	{
		code: '42152449',
		label: 'Catalizadores de material de impresión de uso odontológico',
	},
	{
		code: '42152450',
		label: 'Compuestos limpiadores de instrumentos de uso odontológico',
	},
	{
		code: '42152451',
		label: 'Compuestos indicadores de presión de uso odontológico',
	},
	{
		code: '42152452',
		label: 'Kits o accesorios de aislamiento de pastas de uso odontológico',
	},
	{
		code: '42152453',
		label: 'Compuestos restauradores de uso odontológico',
	},
	{
		code: '42152454',
		label: 'Protectores de nervios dentales',
	},
	{
		code: '42152455',
		label: 'Estantes o soportes para productos abrasivos de uso odontológico',
	},
	{
		code: '42152456',
		label: 'Agentes enmascaradores de uso odontológico',
	},
	{
		code: '42152457',
		label: 'Kits de cementación de uso odontológico',
	},
	{
		code: '42152458',
		label: 'Espaciadores de tintes de uso odontológico',
	},
	{
		code: '42152459',
		label: 'Kits post endodoncia',
	},
	{
		code: '42152460',
		label: 'Compuestos de recubrimiento para prótesis dentales',
	},
	{
		code: '42152461',
		label: 'Compuestos de recubrimiento para modelos dentales',
	},
	{
		code: '42152462',
		label: 'Kits de revestimiento de prótesis dentales',
	},
	{
		code: '42152463',
		label: 'Solventes de cera de uso odontológico',
	},
	{
		code: '42152464',
		label: 'Kits para implantes dentales',
	},
	{
		code: '42152465',
		label: 'Lubricantes dentales',
	},
	{
		code: '42152466',
		label: 'Probador de ajuste de material',
	},
	{
		code: '42152468',
		label:
			'Mezclador de material de impresión dental de alginato operado eléctricamente',
	},
	{
		code: '42152469',
		label:
			'Mezclador de material de impresión dental basado en caucho operado eléctricamente',
	},
	{
		code: '42152470',
		label: 'Resina para aplicación de ortodoncia',
	},
	{
		code: '42152500',
		label: 'Suministros dentales generales',
	},
	{
		code: '42152501',
		label: 'Kits o bandejas desechables pre ensambladas de uso odontológico',
	},
	{
		code: '42152502',
		label: 'Baberos de uso odontológico',
	},
	{
		code: '42152503',
		label: 'Suministros hembra de uso odontológico',

		palabrasSimilares: 'Presas de goma de uso odontológico',
	},
	{
		code: '42152504',
		label: 'Recubrimientos de uso odontológico',
	},
	{
		code: '42152505',
		label:
			'Forros para el apoyacabezas de la silla de examen de uso odontológico',
	},
	{
		code: '42152506',
		label: 'Bolitas absorbentes de uso odontológico',
	},
	{
		code: '42152507',
		label: 'Rollos de uso odontológico',
	},
	{
		code: '42152508',
		label: 'Jeringas o agujas o jeringas con agujas de uso odontológico',
	},
	{
		code: '42152509',
		label: 'Cubiertas desechables para bandejas de uso odontológico',
	},
	{
		code: '42152510',
		label: 'Estuches o cubiertas para instrumentos o sets de uso odontológico',
	},
	{
		code: '42152511',
		label: 'Platos de uso odontológico',
	},
	{
		code: '42152512',
		label: 'Pinzas de uso odontológico',
	},
	{
		code: '42152513',
		label: 'Tazas medidoras de uso odontológico',
	},
	{
		code: '42152514',
		label:
			'Contenedores o accesorios de materiales de desecho de uso odontológico',
	},
	{
		code: '42152516',
		label: 'Tazones mezcladores de uso odontológico',
	},
	{
		code: '42152517',
		label: 'Sujetadores o dispensadores de servilletas de uso odontológico',
	},
	{
		code: '42152518',
		label: 'Dispensadores de materiales de uso odontológico',
	},
	{
		code: '42152519',
		label: 'Kits de accesorios para jeringas de uso odontológico',
	},
	{
		code: '42152520',
		label: 'Escupideras de uso odontológico',
	},
	{
		code: '42152521',
		label: 'Pantógrafo',
	},
	{
		code: '42152600',
		label: 'Suministros específicos a la operación dental',
	},
	{
		code: '42152601',
		label:
			'Papeles articulados para operación o productos relacionados de uso odontológico',
	},
	{
		code: '42152602',
		label:
			'Barreras de control de infección para operaciones de uso odontológico',
	},
	{
		code: '42152603',
		label:
			'Tornillos de retención o productos relacionados para operaciones de uso odontológico',
	},
	{
		code: '42152604',
		label: 'Cordones de retracción para operaciones de uso odontológico',
	},
	{
		code: '42152605',
		label: 'Sets de sialografía de uso odontológico',
	},
	{
		code: '42152606',
		label: 'Tablillas o sets de uso odontológico',
	},
	{
		code: '42152607',
		label: 'Anillos para instrumentos de uso odontológico',
	},
	{
		code: '42152608',
		label: 'Cartuchos de ligadura para ortodoncia',
	},
	{
		code: '42152700',
		label: 'Equipo y suministros ortodoncia y prostodónticos',
	},
	{
		code: '42152701',
		label: 'Articuladores o accesorios de uso odontológico',
	},
	{
		code: '42152702',
		label: 'Soluciones hemostáticas de uso odontológico',
	},
	{
		code: '42152703',
		label:
			'Tornillos o fijadores o suministros relacionados de uso odontológico',
	},
	{
		code: '42152704',
		label: 'Retenedores de uso odontológico',
	},
	{
		code: '42152705',
		label: 'Pinzas de aplicación para ortodoncia',
	},
	{
		code: '42152706',
		label: 'Frenillos "brackets" para ortodoncia',
	},
	{
		code: '42152707',
		label: 'Tubos bucales para ortodoncia',
	},
	{
		code: '42152708',
		label: 'Bobinas de resorte para ortodoncia',
	},
	{
		code: '42152709',
		label: 'Elásticos para ortodoncia',
	},
	{
		code: '42152710',
		label: 'Elastómeros para ortodoncia',
	},
	{
		code: '42152711',
		label: 'Tornillos de expansión para ortodoncia',
	},
	{
		code: '42152712',
		label: 'Bandas molares para ortodoncia',
	},
	{
		code: '42152713',
		label: 'Alicates para ortodoncia',
	},
	{
		code: '42152714',
		label: 'Estuches retenedores para ortodoncia',
	},
	{
		code: '42152715',
		label: 'Bandas de colocación para ortodoncia',
	},
	{
		code: '42152716',
		label: 'Topes o bloqueos para ortodoncia',
	},
	{
		code: '42152717',
		label: 'Alambre para ortodoncia',
	},
	{
		code: '42152718',
		label: 'Arco de alambre para ortodoncia',
	},
	{
		code: '42152800',
		label: 'Equipo y suministro periodontal',
	},
	{
		code: '42152801',
		label: 'Cinceles para periodoncia',
	},
	{
		code: '42152802',
		label: 'Limas para periodoncia',
	},
	{
		code: '42152803',
		label: 'Separadores para periodoncia',
	},
	{
		code: '42152804',
		label:
			'Instrumentos de prueba de implante periodontal a interface de hueso',
	},
	{
		code: '42152805',
		label: 'Instrumentos de control para periodoncia',
	},
	{
		code: '42152806',
		label: 'Disectores de tejido para periodoncia',
	},
	{
		code: '42152807',
		label: 'Pastas protectoras para periodoncia',
	},
	{
		code: '42152808',
		label: 'Curetas para periodoncia',
	},
	{
		code: '42152809',
		label: 'Cuchillos para periodoncia',
	},
	{
		code: '42152810',
		label: 'Membranas para periodoncia',
	},
	{
		code: '42161500',
		label: 'Equipo de diálisis peritoneal y de equilibrio y suministros',
	},
	{
		code: '42161501',
		label:
			'Sets de transferencia de diálisis peritoneal ambulatoria continua capd',
	},
	{
		code: '42161502',
		label: 'Calentadores de dialisato peritoneal',
	},
	{
		code: '42161503',
		label: 'Sets de administración o cateterización de diálisis peritoneal',
	},
	{
		code: '42161504',
		label:
			'Adaptadores o pinzas o conectores de catéter para diálisis peritoneal',
	},
	{
		code: '42161505',
		label: 'Bolsas o contenedores de drenaje de diálisis peritoneal',
	},
	{
		code: '42161506',
		label:
			'Derivaciones o catéteres o dispositivos de acceso permanente para diálisis peritoneal',
	},
	{
		code: '42161507',
		label: 'Soluciones para diálisis peritoneal',
	},
	{
		code: '42161508',
		label: 'Correas o arneses para unidades de diálisis peritoneal',
	},
	{
		code: '42161509',
		label: 'Unidades de diálisis peritoneal',
	},
	{
		code: '42161510',
		label: 'Kits de lavado para diálisis peritoneal',
	},
	{
		code: '42161600',
		label: 'Equipo de hemodiálisis extracorpórea y suministros',
	},
	{
		code: '42161601',
		label: 'Kits o sets o accesorios de administración de hemodiálisis',
	},
	{
		code: '42161602',
		label: 'Muestreadores de bolsas de sangre para hemodiálisis',
	},
	{
		code: '42161603',
		label: 'Aparato de demanda de oxígeno en la sangre para hemodiálisis',
	},
	{
		code: '42161604',
		label: 'Sillas para hemodiálisis',
	},
	{
		code: '42161605',
		label: 'Medidores de conductividad de dialisato para hemodiálisis',
	},
	{
		code: '42161606',
		label: 'Detectores de nivel de dialisato para hemodiálisis',
	},
	{
		code: '42161607',
		label: 'Bombas de presión de dialisato para hemodiálisis',
	},
	{
		code: '42161608',
		label: 'Soluciones de dialisato para hemodiálisis',
	},
	{
		code: '42161609',
		label: 'Tanques de dialisato para hemodiálisis',
	},
	{
		code: '42161610',
		label: 'Tubos de dialisato para hemodiálisis',
	},
	{
		code: '42161611',
		label: 'Baños calentadores de dialisato para hemodiálisis',
	},
	{
		code: '42161612',
		label: 'Filtros de celuloide de dializador para hemodiálisis',
	},
	{
		code: '42161613',
		label: 'Filtros de colodión de dializador para hemodiálisis',
	},
	{
		code: '42161614',
		label: 'Filtros de filamento hueco de dializador para hemodiálisis',
	},
	{
		code: '42161615',
		label: 'Sistemas de reprocesamiento de dializador para hemodiálisis',
	},
	{
		code: '42161616',
		label:
			'Detectores de burbujas de aire o espuma o coágulos, o trampas o alarmas para unidades de hemodiálisis',
	},
	{
		code: '42161617',
		label: 'Monitores de presión arterial para unidades de hemodiálisis',
	},
	{
		code: '42161618',
		label: 'Pinzas de línea de sangre para unidades de hemodiálisis',
	},
	{
		code: '42161619',
		label: 'Tapas para válvulas de sangre para unidades de hemodiálisis',
	},
	{
		code: '42161620',
		label: 'Bombas de sangre para unidades de hemodiálisis',
	},
	{
		code: '42161621',
		label: 'Tapas para válvulas de dialisato unidades de hemodiálisis',
	},
	{
		code: '42161622',
		label: 'Desinfectantes o limpiadores para unidades de hemodiálisis',
	},
	{
		code: '42161623',
		label: 'Bombas de infusión de heparina unidades de hemodiálisis',
	},
	{
		code: '42161624',
		label: 'Controladores de agujas únicas para unidades de hemodiálisis',
	},
	{
		code: '42161625',
		label: 'Convertidores de un solo paso para unidades de hemodiálisis',
	},
	{
		code: '42161626',
		label: 'Estantes o soportes o carritos para unidades de hemodiálisis',
	},
	{
		code: '42161627',
		label: 'Monitores de temperatura para unidades de hemodiálisis',
	},
	{
		code: '42161628',
		label: 'Equipos de prueba para unidades de hemodiálisis',
	},
	{
		code: '42161629',
		label: 'Filtros transductores para unidades de hemodiálisis',
	},
	{
		code: '42161630',
		label: 'Protectores transductores para unidades de hemodiálisis',
	},
	{
		code: '42161631',
		label: 'Sistemas de purificación del agua para unidades de hemodiálisis',
	},
	{
		code: '42161632',
		label: 'Unidades de hemodiálisis',
	},
	{
		code: '42161633',
		label: 'Membranas dializadoras para aparatos de hemodiálisis',
	},
	{
		code: '42161634',
		label: 'Bandejas de procedimiento o accesorios para hemodiálisis',
	},
	{
		code: '42161635',
		label: 'Cartuchos para aparatos de hemodiálisis',
	},
	{
		code: '42161700',
		label: 'Equipo de hemofiltración y suministros',
	},
	{
		code: '42161701',
		label: 'Hemofiltro',
	},
	{
		code: '42161702',
		label: 'Bolsas de recolección de hemofiltración',
	},
	{
		code: '42161703',
		label: 'Puertos de infusión para hemofiltración',
	},
	{
		code: '42161704',
		label: 'Puertos de muestreo para hemofiltración',
	},
	{
		code: '42161800',
		label: 'Equipo y suministros de terapia renal sustitutiva continua (CRRT)',
	},
	{
		code: '42161801',
		label:
			'Unidades de diálisis arterio venosa continua cavhd o productos relacionados',
	},
	{
		code: '42161802',
		label:
			'Unidades de hemofiltración arterio venosa continua cavh o productos relacionados',
	},
	{
		code: '42161803',
		label:
			'Unidades de hemodiálisis o hemofiltración  venosa continua o productos relacionados',
	},
	{
		code: '42161804',
		label:
			'Unidades de ultrafiltración continua lenta scuf o productos relacionados',
	},
	{
		code: '42161900',
		label: 'Equipo y suministros para aféresis terapéutica',
	},
	{
		code: '42161901',
		label: 'Kit para procedimientos de aféresis terapéutica',
	},
	{
		code: '42171500',
		label: 'Productos de servicio médico de urgencia en la gestión de desastre',
	},
	{
		code: '42171501',
		label:
			'Bolsas para cuerpos para desastres para servicios médicos de emergencia',
	},
	{
		code: '42171502',
		label: 'Etiquetas de triage para servicios médicos de emergencia',
	},
	{
		code: '42171600',
		label:
			'Productos de sacar, inmovilizar y trasladar de servicios médicos de urgencia',
	},
	{
		code: '42171601',
		label: 'Camillas o accesorios para evacuación aérea',
	},
	{
		code: '42171602',
		label: 'Camillas o accesorios para ambulancias',
	},
	{
		code: '42171603',
		label: 'Prendas anti choque',
	},
	{
		code: '42171604',
		label: 'Camillas canasta o accesorios',
	},
	{
		code: '42171605',
		label: 'Ojales de cable de rescate',
	},
	{
		code: '42171606',
		label: 'Férulas inflables para servicios médicos de emergencia',
	},
	{
		code: '42171607',
		label:
			'Collares cervicales o de extracción de víctimas para servicios médicos de emergencia',
	},
	{
		code: '42171608',
		label: 'Inmovilizadores de cabeza para servicios médicos de emergencia',
	},
	{
		code: '42171609',
		label:
			'Correas de retención o espinales para servicios médicos de emergencia',
	},
	{
		code: '42171610',
		label: 'Inmovilizadores de torso para servicios médicos de emergencia',
	},
	{
		code: '42171611',
		label: 'Literas o camillas o accesorios para respuesta de emergencia',
	},
	{
		code: '42171612',
		label: 'Camillas o accesorios redondas',
	},
	{
		code: '42171613',
		label: 'Tablas espinales',
	},
	{
		code: '42171614',
		label: 'Tubos o contenedores para rescate en agua',
	},
	{
		code: '42171700',
		label: 'Mantas de servicios médicos de urgencia',
	},
	{
		code: '42171701',
		label: 'Cobijas para emergencias o rescates',
	},
	{
		code: '42171702',
		label: 'Cobijas para primeros auxilios',
	},
	{
		code: '42171703',
		label:
			'Cobertores o cobijas de protección contra el calor para servicios médicos de emergencia',
	},
	{
		code: '42171704',
		label:
			'Fajas para bebés o porta bebés para servicios médicos de emergencia',
	},
	{
		code: '42171800',
		label: 'Equipo de servicios médicos de urgencia de gestión del vía de aire',
	},
	{
		code: '42171801',
		label:
			'Kits de intubación o vía aérea oro faríngea para servicios médicos de emergencia',
	},
	{
		code: '42171802',
		label: 'Kits de laringoscopia para servicios médicos de emergencia',
	},
	{
		code: '42171803',
		label:
			'Unidades o accesorios de succión para servicios médicos de emergencia',
	},
	{
		code: '42171804',
		label:
			'Kits de cricotiroidotomía o tubo traqueal para servicios médicos de emergencia',
	},
	{
		code: '42171805',
		label: 'Desplegadores de mandíbula',
	},
	{
		code: '42171806',
		label: 'Palos para convulsiones',
	},
	{
		code: '42171900',
		label: 'Cajas o bolsas de almacenaje para servicios médicos de urgencia',
	},
	{
		code: '42171901',
		label: 'Bolsas de manejo de vía aérea para servicios médicos de emergencia',
	},
	{
		code: '42171902',
		label: 'Estuches de desfibriladores para servicios médicos de emergencia',
	},
	{
		code: '42171903',
		label: 'Estuches de medicamentos para servicios médicos de emergencia',
	},
	{
		code: '42171904',
		label:
			'Estuches o bolsas de productos de extracción para servicios médicos de emergencia',
	},
	{
		code: '42171905',
		label: 'Estuches intravenosos iv para servicios médicos de emergencia',
	},
	{
		code: '42171906',
		label: 'Estuches de intubación para servicios médicos de emergencia',
	},
	{
		code: '42171907',
		label: 'Bolsas de laringoscopia para servicios médicos de emergencia',
	},
	{
		code: '42171908',
		label: 'Estuches de soporte de vida para servicios médicos de emergencia',
	},
	{
		code: '42171909',
		label:
			'Paquetes de trauma para respuesta de larga distancia ldr para servicios médicos de emergencia',
	},
	{
		code: '42171910',
		label: 'Estuches paramédicos para servicios médicos de emergencia',

		palabrasSimilares: 'Botiquines para servicios de emergencia',
	},
	{
		code: '42171911',
		label:
			'Estuches de oxígeno portátil o resucitación para servicios médicos de emergencia',
	},
	{
		code: '42171912',
		label: 'Estuches o bolsas de rescate para servicios médicos de emergencia',
	},
	{
		code: '42171913',
		label:
			'Estuches o bolsas de respuesta para servicios médicos de emergencia',

		palabrasSimilares: 'Maletines para sevicios médicos de emergencia',
	},
	{
		code: '42171914',
		label: 'Estuches o bolsas de trauma para servicios médicos de emergencia',
	},
	{
		code: '42171915',
		label: 'Estuches o bolsas para técnicos médicos de emergencia emt',
	},
	{
		code: '42171916',
		label:
			'Estuches para transportar o almacenar sets de inmovilización para servicios médicos de emergencia',
	},
	{
		code: '42171917',
		label:
			'Estuches o bolsas o accesorios de primeros auxilios para servicios médicos de emergencia',
	},
	{
		code: '42171918',
		label:
			'Estuches de equipos de tratamiento de víctimas de gas para servicios médicos de emergencia',
	},
	{
		code: '42171919',
		label:
			'Bolsas o revestimientos de evacuación para servicios médicos de emergencia',
	},
	{
		code: '42171920',
		label: 'Estuches o accesorios de ropa para servicios médicos de emergencia',
	},
	{
		code: '42172000',
		label: 'Kits para los servicios médicos de urgencias y campo',
	},
	{
		code: '42172001',
		label: 'Kits de primeros auxilios para servicios médicos de emergencia',

		palabrasSimilares: 'Botiquín de primeros auxilios, Botiquín médico',
	},
	{
		code: '42172002',
		label: 'Kits de primera respuesta para servicios médicos de emergencia',

		palabrasSimilares: 'Maletín paramédico para emergencias pre-hospitalarias',
	},
	{
		code: '42172003',
		label: 'Kits intravenosos iv para servicios médicos de emergencia',
	},
	{
		code: '42172004',
		label: 'Kits de soporte de vida para servicios médicos de emergencia',
	},
	{
		code: '42172005',
		label:
			'Kits de trauma de respuesta de larga distancia ldr para servicios médicos de emergencia',
	},
	{
		code: '42172006',
		label: 'Kits obstétricos para servicios médicos de emergencia',
	},
	{
		code: '42172007',
		label:
			'Kits de oxígeno o resucitación para servicios médicos de emergencia',
	},
	{
		code: '42172008',
		label: 'Kits de rapel para servicios médicos de emergencia',
	},
	{
		code: '42172009',
		label: 'Kits de búsqueda y rescate para servicios médicos de emergencia',
	},
	{
		code: '42172010',
		label: 'Kits de trauma para servicios médicos de emergencia',
	},
	{
		code: '42172011',
		label: 'Kits para técnicos médicos de emergencia emt',
	},
	{
		code: '42172012',
		label: 'Kits de ventriculostomía para servicios médicos de emergencia',
	},
	{
		code: '42172013',
		label: 'Kits de evacuación para servicios médicos de emergencia',
	},
	{
		code: '42172014',
		label:
			'Kits o suministros de transporte de pacientes para servicios médicos de emergencia',
	},
	{
		code: '42172015',
		label: 'Kits de tratamiento dental para servicios médicos de emergencia',
	},
	{
		code: '42172016',
		label: 'Kits de fracturas para servicios médicos de emergencia',
	},
	{
		code: '42172017',
		label:
			'Kits de equipos médicos de laboratorio o de campo o productos relacionados',
	},
	{
		code: '42172018',
		label: 'Kit de lavado gástrico',
	},
	{
		code: '42172100',
		label: 'Productos de resucitación de servicios médicos de emergencia',
	},
	{
		code: '42172101',
		label: 'Desfibriladores externos automatizados aed o paletas duras',
	},
	{
		code: '42172102',
		label:
			'Escudos o máscaras protectoras para resucitación cardiopulmonar cpr',
	},
	{
		code: '42172103',
		label: 'Kits aspiradores o resucitadores para emergencias',
	},
	{
		code: '42172104',
		label: 'Tablas de resucitación cardiopulmonar cpr',
	},
	{
		code: '42172105',
		label: 'Accesorios para desfibriladores externos automatizados aed',
	},
	{
		code: '42172200',
		label: 'Suministros de servicios médicos de emergencia',
	},
	{
		code: '42172201',
		label: 'Pinzas o torniquetes para servicios médicos de emergencia',
	},
	{
		code: '42181500',
		label: 'Evaluación diagnóstica y productos de examen de uso general',
	},
	{
		code: '42181501',
		label: 'Depresores de lengua o cuchillos o baja lenguas',

		palabrasSimilares: 'Abatelenguas, Depresor lingual',
	},
	{
		code: '42181502',
		label: 'Transiluminadores para exámenes de uso médico',
	},
	{
		code: '42181503',
		label: 'Lubricantes o gelatinas personales o para examen',
	},
	{
		code: '42181504',
		label: 'Sets de hemacitómetros',
	},
	{
		code: '42181505',
		label: 'Sets o accesorios de muestreadores de células endometriales',
	},
	{
		code: '42181506',
		label: 'Kits de determinación de asalto sexual',
	},
	{
		code: '42181507',
		label:
			'Bandas para sujetar el espejo a la frente o accesorios para exámenes médicos',
	},
	{
		code: '42181508',
		label: 'Pañitos limpiadores para equipo diagnóstico',
	},
	{
		code: '42181509',
		label: 'Sets de examen físico para cirujanos de vuelo',
	},
	{
		code: '42181510',
		label: 'Medidores de ictericia transcutáneos',
	},
	{
		code: '42181511',
		label: 'Evaluadores de impotencia masculina',
	},
	{
		code: '42181512',
		label: 'Cucharas para examen de portadores de tifoidea',
	},
	{
		code: '42181513',
		label: 'Kits de irrigación de los senos nasales',
	},
	{
		code: '42181514',
		label: 'Fotómetros de hemoglobina',
	},
	{
		code: '42181515',
		label: 'Bandejas de procedimiento para exámenes',
	},
	{
		code: '42181516',
		label: 'Unidades o accesorios de electromiografía emg',
	},
	{
		code: '42181518',
		label: 'Estuche para sets de instrumentos médicos o sus accesorios',
	},
	{
		code: '42181519',
		label: 'Portador de gotas',
	},
	{
		code: '42181520',
		label: 'Medidor de presión intratoráxica',
	},
	{
		code: '42181521',
		label: 'Monitor de presión de fluido espinal',
	},
	{
		code: '42181522',
		label: 'Aparato para análisis de manera de caminar',
	},
	{
		code: '42181523',
		label: 'Sistema de prueba y evaluación isocinética',
	},
	{
		code: '42181524',
		label: 'Analizador de rigidez',
	},
	{
		code: '42181525',
		label: 'Ataxiágrafo',
	},
	{
		code: '42181526',
		label: 'Algesímetro',
	},
	{
		code: '42181527',
		label: 'Dinamómetro médico',
	},
	{
		code: '42181528',
		label: 'Dispositivo para medir la resistencia de la piel',
	},
	{
		code: '42181529',
		label: 'Estesiómetro',
	},
	{
		code: '42181530',
		label: 'Sistema de inducción de sudor de iontoforesis',
	},
	{
		code: '42181531',
		label: 'Instrumento medidor de conductividad eléctrica de la piel',
	},
	{
		code: '42181532',
		label: 'Medidor de respuesta galvánica de la piel',
	},
	{
		code: '42181533',
		label: 'Dispositivo de medición de la velocidad de conducción del nervio',
	},
	{
		code: '42181534',
		label: 'Electroglotógrafo',
	},
	{
		code: '42181535',
		label: 'Condicionador de señales electrónicas fisiológicas',
	},
	{
		code: '42181536',
		label: 'Dispositivo eléctrico de detección de caries',
	},
	{
		code: '42181537',
		label:
			'Instrumento para medir la fuerza de la mordida para uso odontológico',
	},
	{
		code: '42181538',
		label: 'Dispositivo medidor del sonido de oclusión para uso odontológico',
	},
	{
		code: '42181600',
		label: 'Unidades de presión sanguínea y productos relacionados',
	},
	{
		code: '42181601',
		label: 'Unidades de presión de sangre aneroides',
	},
	{
		code: '42181602',
		label: 'Unidades de presión de sangre electrónicas',
	},
	{
		code: '42181603',
		label: 'Unidades de presión de sangre de mercurio',
	},
	{
		code: '42181604',
		label:
			'Válvulas o peras inflables de liberación de presión del aire en la sangre',
	},
	{
		code: '42181605',
		label: 'Mangas o vejigas de presión de la sangre',
	},
	{
		code: '42181606',
		label:
			'Mangueras de inflar o mangueras neumáticas o adaptadores de presión de sangre',
	},
	{
		code: '42181607',
		label: 'Unidades de registro de presión de sangre',
	},
	{
		code: '42181608',
		label: 'Accesorios para instrumentos de medición de presión de sangre',
	},
	{
		code: '42181609',
		label: 'Kits de domo de monitoreo de presión de sangre',
	},
	{
		code: '42181610',
		label: 'Kits de mangas de presión de sangre',
	},
	{
		code: '42181611',
		label: 'Manómetro de presión venosa central cvp',
	},
	{
		code: '42181612',
		label: 'Soportes móviles de presión de sangre',
	},
	{
		code: '42181700',
		label: 'Unidades de electrocardiografía (ECG) y productos relacionados',
	},
	{
		code: '42181701',
		label: 'Unidades de electrocardiografía ekg',
	},
	{
		code: '42181702',
		label: 'Adaptadores o cables o conductores para electrocardiografía ekg',
	},
	{
		code: '42181703',
		label:
			'Probadores de cables o conductores o unidades de electrocardiografía ekg',
	},
	{
		code: '42181704',
		label: 'Calibradores o reglas de electrocardiografía ekg',
	},
	{
		code: '42181705',
		label: 'Registros gráficos de electrocardiografía ekg',
	},
	{
		code: '42181706',
		label: 'Pantallas de monitor para electrocardiografía ekg',
	},
	{
		code: '42181707',
		label: 'Electrodos de tira o anillo para electrocardiografía ekg neonatal',
	},
	{
		code: '42181708',
		label: 'Electrodos de parche para electrocardiografía ekg',
	},
	{
		code: '42181710',
		label:
			'Probadores de superficie de electrodos para electrocardiografía ekg',
	},
	{
		code: '42181711',
		label:
			'Sistemas telefónicos receptores o transmisores para electrocardiografía ekg',
	},
	{
		code: '42181712',
		label: 'Analizadores de unidad de electrocardiografía ekg',
	},
	{
		code: '42181713',
		label:
			'Sistemas de monitoreo de electrocardiografía ekg continua de larga duración o holter',
	},
	{
		code: '42181714',
		label: 'Kits de accesorios de monitoreo para electrocardiografía ekg',
	},
	{
		code: '42181715',
		label: 'Soluciones o cremas para electrodos',
	},
	{
		code: '42181716',
		label: 'Accesorios para electrocardiografía ekg',
	},
	{
		code: '42181717',
		label: 'Bombillos de electrodos para electrocardiografía ekg',
	},
	{
		code: '42181718',
		label: 'Esferos para registro de electrocardiografía ekg',
	},
	{
		code: '42181719',
		label: 'Transmisor o telemetría o accesorios para electrocardiografía ekg',
	},
	{
		code: '42181720',
		label: 'Sistema de monitoreo de estrés por ejercicio',
	},
	{
		code: '42181721',
		label: 'Esfigmógrafo',
	},
	{
		code: '42181722',
		label: 'Analizador de ondas de pulso',
	},
	{
		code: '42181800',
		label: 'Oxímetros de pulso',
	},
	{
		code: '42181801',
		label: 'Unidades para oxímetros de pulso',
	},
	{
		code: '42181802',
		label: 'Cables para oxímetros de pulso',
	},
	{
		code: '42181803',
		label: 'Sondas o sensores para oxímetros de pulso',
	},
	{
		code: '42181804',
		label: 'Accesorios para sondas o sensores para oxímetros de pulso',
	},
	{
		code: '42181805',
		label: 'Accesorios para unidades de oxímetros de pulso',
	},
	{
		code: '42181900',
		label: 'Unidades de control de cuidado intenso y productos relacionados',
	},
	{
		code: '42181901',
		label:
			'Unidades o accesorios para cuidado intensivo fetal o monitoreo materno',
	},
	{
		code: '42181902',
		label: 'Unidades o accesorios para monitoreo de presión intracraneal icp',
	},
	{
		code: '42181903',
		label: 'Unidades o accesorios para monitoreo de salida cardiaca co',
	},
	{
		code: '42181904',
		label:
			'Unidades o accesorios para unidades de signos vitales multi parámetro',
	},
	{
		code: '42181905',
		label: 'Cables para monitor transductor para uso médico',
	},
	{
		code: '42181906',
		label: 'Catéteres para monitoreo de presión intrauterina',
	},
	{
		code: '42181907',
		label: 'Aparatos para metabolismo basal',
	},
	{
		code: '42181908',
		label: 'Sets o accesorios de monitoreo de presión intra compartimientos',
	},
	{
		code: '42181910',
		label: 'Kits intrauterinos transcervicales',
	},
	{
		code: '42181911',
		label: 'Electrodos para cuero cabelludo fetal',
	},
	{
		code: '42181912',
		label: 'Monitor electroencefalográfico fetal',
	},
	{
		code: '42182000',
		label:
			'Especulo y scopios y accesorios para uso del reconocimiento diagnóstico médico',
	},
	{
		code: '42182001',
		label: 'Espéculos para examen anal o rectal',
	},
	{
		code: '42182002',
		label: 'Anoscopios o proctoscopios',
	},
	{
		code: '42182003',
		label: 'Colposcopios o vaginoscopios o accesorios',
	},
	{
		code: '42182004',
		label: 'Dermatoscopios',
	},
	{
		code: '42182005',
		label: 'Oftalmoscopios u otoscopios o sets de escopios',
	},
	{
		code: '42182006',
		label: 'Espéculos para examen de laringe o bucofaríngeo',
	},
	{
		code: '42182007',
		label: 'Bombillos o lámparas de escopios para exámenes médicos',
	},
	{
		code: '42182008',
		label: 'Manijas de escopios o cargadores de escopios para exámenes médicos',
	},
	{
		code: '42182009',
		label:
			'Puntas de espéculos para escopios o dispensadores de puntas de espéculos para uso médico',
	},
	{
		code: '42182010',
		label: 'Sujetadores o soportes para espéculos para exámenes médicos',
	},
	{
		code: '42182011',
		label:
			'Espéculos o puntas de dilatación o dispensadores de puntas para exámenes médicos',
	},
	{
		code: '42182012',
		label: 'Espéculos o dilatadores para exámenes nasales',
	},
	{
		code: '42182013',
		label: 'Espéculos para examen vaginal',
	},
	{
		code: '42182014',
		label: 'Accesorios para otoscopios u oftalmoscopios',
	},
	{
		code: '42182015',
		label: 'Espéculos para otoscopia',
	},
	{
		code: '42182016',
		label: 'Nasofaringoscopios o accesorios',
	},
	{
		code: '42182017',
		label: 'Sets de espéculos para oído',
	},
	{
		code: '42182018',
		label: 'Broncoscopios o accesorios',
	},
	{
		code: '42182019',
		label: 'Kits de dilatación',
	},
	{
		code: '42182020',
		label: 'Angioscopios o accesorios',
	},
	{
		code: '42182100',
		label: 'Estetoscopio y productos relacionados',
	},
	{
		code: '42182101',
		label: 'Estetoscopios electrónicos o accesorios',
	},
	{
		code: '42182102',
		label: 'Dopplers vasculares de mano o accesorios',
	},
	{
		code: '42182103',
		label: 'Estetoscopio acústico para uso médico o accesorios',
	},
	{
		code: '42182104',
		label: 'Estetoscopio de cabeza',
	},
	{
		code: '42182105',
		label: 'Cubiertas para estetoscopios de cabeza',
	},
	{
		code: '42182106',
		label: 'Fonocardiográfos estetoscópicos',
	},
	{
		code: '42182107',
		label: 'Estetoscopios auriculares',
	},
	{
		code: '42182108',
		label: 'Termorreguladores de pacientes',
	},
	{
		code: '42182200',
		label: 'Termómetros médicos y accesorios',
	},
	{
		code: '42182201',
		label: 'Termómetros electrónicos para uso médico',
	},
	{
		code: '42182202',
		label: 'Termómetros de fibra óptica para uso médico',
	},
	{
		code: '42182203',
		label: 'Estuches o forros para transportar termómetros para uso médico',
	},
	{
		code: '42182204',
		label: 'Estantes para termómetros para uso médico',
	},
	{
		code: '42182205',
		label: 'Fundas para puntas o sondas de termómetros para uso médico',
	},
	{
		code: '42182206',
		label: 'Termómetros de mercurio para uso médico',
	},
	{
		code: '42182207',
		label:
			'Monitores de temperatura continua o de tendencia de temperatura del paciente',
	},
	{
		code: '42182208',
		label: 'Bandas de temperatura del paciente',
	},
	{
		code: '42182209',
		label: 'Sondas para termómetros',
	},
	{
		code: '42182210',
		label: 'Termómetros de vidrio no de mercurio para uso médico',
	},
	{
		code: '42182211',
		label: 'Sistema de termografía infrarroja',
	},
	{
		code: '42182212',
		label: 'Termómetro de cristal líquido',
	},
	{
		code: '42182300',
		label: 'Productos del examen neurológico',
	},
	{
		code: '42182301',
		label: 'Molinillos de diagnóstico para uso médico',
	},
	{
		code: '42182302',
		label: 'Martillos o martillos quirúrgicos para reflejos',
	},
	{
		code: '42182303',
		label: 'Tarjetas de examen neurosiquiátrico',
	},
	{
		code: '42182304',
		label: 'Sets o kits de pruebas de diagnóstico sicológico',
	},
	{
		code: '42182305',
		label: 'Sets para procedimientos mielográficos',
	},
	{
		code: '42182306',
		label: 'Discriminadores neurológicos',
	},
	{
		code: '42182307',
		label: 'Alfileres neurológicos',
	},
	{
		code: '42182308',
		label: 'Electroencefalógrafo eeg o accesorios',
	},
	{
		code: '42182310',
		label: 'Electromiógrafos',
	},
	{
		code: '42182311',
		label: 'Sensores neurológicos',
	},
	{
		code: '42182312',
		label: 'Electrodos o sets para electromiógrafos',
	},
	{
		code: '42182313',
		label: 'Sets para diagnóstico neurológico',
	},
	{
		code: '42182314',
		label: 'Dispositivo de retroalimentación biológica',
	},
	{
		code: '42182315',
		label: 'Analizador de espectro electroencefalográfico',
	},
	{
		code: '42182316',
		label: 'Detector de respuesta evocada',
	},
	{
		code: '42182400',
		label: 'Productos para probar el oír',
	},
	{
		code: '42182401',
		label: 'Audiómetros o accesorios',
	},
	{
		code: '42182402',
		label: 'Vibradores de hueso audiométricos o analizadores de oído medio',
	},
	{
		code: '42182403',
		label: 'Cabinas audiométricas o cámaras acústicas para pruebas de audición',
	},
	{
		code: '42182404',
		label: 'Sets de calibración de unidades de evaluación de función auditiva',
	},
	{
		code: '42182405',
		label: 'Unidades de evaluación de función auditiva',
	},
	{
		code: '42182406',
		label: 'Grabadoras gráficas de pruebas auditivas',
	},
	{
		code: '42182407',
		label: 'Fenestrómetros de oído',
	},
	{
		code: '42182408',
		label: 'Electrococleógrafos',
	},
	{
		code: '42182409',
		label: 'Analizadores de audífonos o sistemas de prueba',
	},
	{
		code: '42182410',
		label: 'Estuches para diapasones para uso médico',
	},
	{
		code: '42182411',
		label: 'Martillos para diapasones para uso médico',
	},
	{
		code: '42182412',
		label: 'Diapasones para uso médico',
	},
	{
		code: '42182413',
		label: 'Sets de diapasones para uso médico',
	},
	{
		code: '42182414',
		label: 'Analizadores de tinnitus (acúfenos)',
	},
	{
		code: '42182415',
		label: 'Tubos de toynbee para diagnóstico',
	},
	{
		code: '42182416',
		label: 'Timpanómetros o sus accesorios',
	},
	{
		code: '42182417',
		label: 'Cordones de audiometría',
	},
	{
		code: '42182418',
		label: 'Guías para fenestrómetro',
	},
	{
		code: '42182419',
		label: 'Aparatos para control de audición',
	},
	{
		code: '42182420',
		label: 'Sondas aurales',
	},
	{
		code: '42182421',
		label: 'Calibradores de tapones o accesorios',
	},
	{
		code: '42182422',
		label: 'Bolsas inflables para oído',
	},
	{
		code: '42182500',
		label: 'Metros de función nasal',
	},
	{
		code: '42182501',
		label: 'Olfatómetros',
	},
	{
		code: '42182502',
		label: 'Medidores de flujo nasal o rinoanemómetros',
	},
	{
		code: '42182600',
		label: 'Lámparas o luces de examen médico',
	},
	{
		code: '42182601',
		label: 'Luces o lámparas de pie para exámenes médicos',
	},
	{
		code: '42182602',
		label: 'Luces o lámparas instaladas para exámenes médicos',
	},
	{
		code: '42182603',
		label:
			'Luces de techo o lámparas de techo o accesorios para exámenes médicos',
	},
	{
		code: '42182604',
		label: 'Linternas para exámenes médicos',
	},
	{
		code: '42182700',
		label: 'Dispositivos de medición de talla de examen médico',
	},
	{
		code: '42182701',
		label: 'Goniómetros',
	},
	{
		code: '42182702',
		label: 'Cintas de medición para uso médico',
	},
	{
		code: '42182703',
		label: 'Reglas de medición de altura de pacientes',
	},
	{
		code: '42182704',
		label: 'Evaluadores de dobleces de piel',
	},
	{
		code: '42182705',
		label: 'Analizador de composición del cuerpo',
	},
	{
		code: '42182706',
		label: 'Malla o dispositivo de medición de heridas',
	},
	{
		code: '42182707',
		label: 'Inclinómetro',
	},
	{
		code: '42182800',
		label: 'Básculas médicas de peso',
	},
	{
		code: '42182801',
		label: 'Básculas de pañales',
	},
	{
		code: '42182802',
		label: 'Básculas de bebés',
	},
	{
		code: '42182803',
		label: 'Básculas de mesa o cama para pacientes para uso general',
	},
	{
		code: '42182804',
		label: 'Básculas de asiento para pacientes',
	},
	{
		code: '42182805',
		label: 'Básculas de piso para pacientes',
	},
	{
		code: '42182806',
		label: 'Básculas de eslinga para pacientes',
	},
	{
		code: '42182807',
		label: 'Básculas de plataforma para sillas de ruedas',
	},
	{
		code: '42182808',
		label: 'Forros o revestimientos para básculas de pesaje',
	},
	{
		code: '42182900',
		label: 'Mesas de examen de especialidad y productos relacionados',
	},
	{
		code: '42182901',
		label: 'Mesas de examen obstétrico o ginecológico',
	},
	{
		code: '42182902',
		label:
			'Mesas de examen con apoya pies para examen obstétrico o ginecológico',
	},
	{
		code: '42182903',
		label: 'Mesas para examen pediátrico',
	},
	{
		code: '42182904',
		label: 'Mesas de retención o sistemas de medición para examen pediátrico',
	},
	{
		code: '42183000',
		label: 'Productos de examen diagnóstico oftálmico',
	},
	{
		code: '42183001',
		label: 'Carteleras para examen de ojos o tarjetas de visión',
	},
	{
		code: '42183002',
		label: 'Topógrafos corneales',
	},
	{
		code: '42183003',
		label: 'Exoftalmómetros',
	},
	{
		code: '42183004',
		label: 'Queratoscopios',
	},
	{
		code: '42183005',
		label: 'Colorímetros oftálmicos',
	},
	{
		code: '42183006',
		label: 'Distómetros oftálmicos',
	},
	{
		code: '42183007',
		label: 'Tambores oftálmicos o sus accesorios',
	},
	{
		code: '42183008',
		label: 'Eutiscopios oftálmicos',
	},
	{
		code: '42183009',
		label: 'Lentes de prueba del ojo o sus accesorios para uso oftálmico',
	},
	{
		code: '42183010',
		label: 'Lensómetros para uso oftálmico',
	},
	{
		code: '42183011',
		label: 'Perímetros para uso oftálmico',
	},
	{
		code: '42183012',
		label: 'Fotómetros para uso oftálmico',
	},
	{
		code: '42183013',
		label: 'Prismas para uso oftálmico',
	},
	{
		code: '42183014',
		label: 'Retinoscopios para uso oftálmico',
	},
	{
		code: '42183015',
		label: 'Lámparas de hendidura para uso oftálmico',
	},
	{
		code: '42183016',
		label: 'Espectrofotómetros para uso oftálmico',
	},
	{
		code: '42183017',
		label: 'Espéculos para uso oftálmico',
	},
	{
		code: '42183018',
		label: 'Tonómetros o accesorios para uso oftálmico',
	},
	{
		code: '42183019',
		label: 'Transiluminadores para uso oftálmico',
	},
	{
		code: '42183020',
		label: 'Trazadores de campo visual para uso oftálmico',
	},
	{
		code: '42183021',
		label: 'Analizadores de función visual para uso oftálmico',
	},
	{
		code: '42183022',
		label: 'Visiómetros para uso oftálmico',
	},
	{
		code: '42183023',
		label: 'Oftalmómetros',
	},
	{
		code: '42183024',
		label: 'Mesas para instrumentos o accesorios para uso oftálmico',
	},
	{
		code: '42183026',
		label: 'Oftalmodinamómetros',
	},
	{
		code: '42183027',
		label: 'Kits o accesorios de pruebas de monitoreo tangente',
	},
	{
		code: '42183028',
		label: 'Accesorios para retinoscopio para uso oftálmico',
	},
	{
		code: '42183029',
		label: 'Unidades de forópter',
	},
	{
		code: '42183030',
		label: 'Oclusores de ojos',
	},
	{
		code: '42183031',
		label: 'Sets o accesorios de placas pseudoisocromáticas',
	},
	{
		code: '42183032',
		label: 'Taquistoscopio',
	},
	{
		code: '42183033',
		label: 'Sets de ajuste de anteojos',
	},
	{
		code: '42183034',
		label: 'Estereoscopios para probar la visión',
	},
	{
		code: '42183035',
		label: 'Queratómetros refractores de combinación',
	},
	{
		code: '42183036',
		label: 'Placas base para oftalmómetros',
	},
	{
		code: '42183037',
		label: 'Proyectores de cuadros o accesorios',
	},
	{
		code: '42183038',
		label: 'Almohadillas de uso oftálmico',
	},
	{
		code: '42183039',
		label: 'Sujetadores de lentes oftálmicos',
	},
	{
		code: '42183040',
		label: 'Herramientas o accesorios para optómetras',
	},
	{
		code: '42183041',
		label: 'Linternas de pruebas de percepción del color',
	},
	{
		code: '42183042',
		label: 'Aparatos de percepción de profundidad',
	},
	{
		code: '42183043',
		label: 'Bombillos para oftalmómetros',
	},
	{
		code: '42183044',
		label: 'Pantallas tangentes',
	},
	{
		code: '42183045',
		label: 'Sistemas de electroretinograma',
	},
	{
		code: '42183046',
		label: 'Sets o accesorios de prueba de visión binocular',
	},
	{
		code: '42183047',
		label: 'Soportes miradores para pruebas de agudeza visual',
	},
	{
		code: '42183048',
		label: 'Barras de fijación de niños para uso oftálmico',
	},
	{
		code: '42183049',
		label: 'Cámara de fundus',
	},
	{
		code: '42183050',
		label: 'Nistagmógrafo',
	},
	{
		code: '42183051',
		label: 'Pupilómetro',
	},
	{
		code: '42183052',
		label: 'Regla de punto cercano',
	},
	{
		code: '42183053',
		label: 'Medidor de distancia entre pupilas',
	},
	{
		code: '42183054',
		label: 'Pletismografía ocular',
	},
	{
		code: '42183055',
		label: 'Contador de células endoteliales',
	},
	{
		code: '42183056',
		label: 'Cámara anterior',
	},
	{
		code: '42183057',
		label: 'Monitor de movimiento del ojo',
	},
	{
		code: '42183058',
		label: 'Instrumento láser de campo de visión',
	},
	{
		code: '42183059',
		label: 'Foto simulador',
	},
	{
		code: '42183060',
		label: 'Tiras de schirmer',
	},
	{
		code: '42183061',
		label: 'Aplicadores de presión ocular',
	},
	{
		code: '42183062',
		label: 'Cámara de iris',
	},
	{
		code: '42183063',
		label: 'Unidad de electrólisis oftálmica',
	},
	{
		code: '42183064',
		label: 'Lentes de contacto terapéuticos',
	},
	{
		code: '42183065',
		label: 'Lentes de contacto prescritos',
	},
	{
		code: '42183066',
		label: 'Cámara corneal',
	},
	{
		code: '42183067',
		label: 'Lentes de examen de diagnóstico de visión para uso oftálmico',
	},
	{
		code: '42183100',
		label: 'Medidores de la capacidad gustativa',
	},
	{
		code: '42183101',
		label: 'Gustómetros',
	},
	{
		code: '42183200',
		label: 'Equipo y suministros de alergología',
	},
	{
		code: '42183201',
		label: 'Instrumentos o accesorios para detectar o probar alergias',
	},
	{
		code: '42183300',
		label:
			'Accesorios de unidades de examen de otorrinolaringología y productos relacionados',
	},
	{
		code: '42183301',
		label: 'Accesorios para espejos de examen de oído nariz garganta ent',
	},
	{
		code: '42191500',
		label: 'Manejo de materiales de facilidad médica y equipo de distribución',
	},
	{
		code: '42191501',
		label: 'Sistema de tubo neumático clínico',
	},
	{
		code: '42191502',
		label: 'Bandejas o cubiertas para medicinas',
	},
	{
		code: '42191600',
		label: 'Sistemas de construcción de facilidad médica',
	},
	{
		code: '42191601',
		label: 'Iluminación para habitaciones de pacientes o accesorios',
	},
	{
		code: '42191602',
		label: 'Iluminación para salas de cirugía o accesorios',
	},
	{
		code: '42191603',
		label: 'Paneles de instrumentos para equipos hospitalarios',
	},
	{
		code: '42191604',
		label: 'Brazos de monitoreo clínico',
	},
	{
		code: '42191605',
		label: 'Columnas para equipos eléctricos hospitalarios',
	},
	{
		code: '42191606',
		label: 'Brazos de techo para instalaciones médicas',
	},
	{
		code: '42191607',
		label:
			'Cortinas de cubículo o pantallas o hardware de rieles de cortinas para pacientes',
	},
	{
		code: '42191608',
		label: 'Controles de enfermería o monitores de salida',
	},
	{
		code: '42191609',
		label: 'Sistemas de pared principal para uso clínico',
	},
	{
		code: '42191610',
		label: 'Módulos de trabajo para uso clínico',
	},
	{
		code: '42191611',
		label: 'Módulos o sistemas de comunicación de enfermería',
	},
	{
		code: '42191612',
		label: 'Sistemas de intercomunicación hospitalaria',
	},
	{
		code: '42191613',
		label: 'Transmisor de seguridad para bebés o niños',
	},
	{
		code: '42191700',
		label: 'Productos de gas de hospital',
	},
	{
		code: '42191701',
		label: 'Pistas de servicio de electricidad o gas para uso médico',
	},
	{
		code: '42191702',
		label: 'Columnas de entrega de gas para uso médico',
	},
	{
		code: '42191703',
		label: 'Salidas de gas para uso médico',
	},
	{
		code: '42191704',
		label: 'Sistemas de compresión de aire gas para uso médico',
	},
	{
		code: '42191705',
		label: 'Alarmas de gas para uso médico',
	},
	{
		code: '42191706',
		label: 'Colector de gas para uso medico',
	},
	{
		code: '42191707',
		label: 'Sistemas de vacío para uso médico',
	},
	{
		code: '42191708',
		label: 'Gabinetes de control de presión de aire para uso médico',
	},
	{
		code: '42191709',
		label: 'Válvulas de cierre de gas o cajas de válvulas para uso médico',
	},
	{
		code: '42191710',
		label:
			'Carros o puestos o accesorios para cilindros de gas para uso médico',
	},
	{
		code: '42191711',
		label: 'Tanques de aire comprimido o accesorios para uso quirúrgico',
	},
	{
		code: '42191800',
		label: 'Camas de paciente y accesorios',
	},
	{
		code: '42191801',
		label: 'Mesas para encima de la cama o accesorios',
	},
	{
		code: '42191802',
		label: 'Incubadoras o calentadores de bebés para uso clínico',
	},
	{
		code: '42191803',
		label: 'Moisés o cunas o camas pediátricas o accesorios',
	},
	{
		code: '42191804',
		label: 'Barandas para camas para uso médico o quirúrgico',
	},
	{
		code: '42191805',
		label: 'Columnas suspendidas para uso médico',
	},
	{
		code: '42191806',
		label: 'Barras de trapecio para uso clínico',
	},
	{
		code: '42191807',
		label: 'Camas o accesorios de cuidado del paciente para uso general',
	},
	{
		code: '42191808',
		label: 'Camas o accesorios de cuidado del paciente para cuidado especial',
	},
	{
		code: '42191809',
		label: 'Almohadillas o bombas de presión alterna',
	},
	{
		code: '42191810',
		label: 'Colchones o accesorios para el cuidado del paciente',
	},
	{
		code: '42191811',
		label: 'Cunas de posicionamiento para bebés',
	},
	{
		code: '42191812',
		label: 'Kits de suministros de incubadoras para bebés',
	},
	{
		code: '42191813',
		label: 'Forros de catre para el cuidado del paciente',
	},
	{
		code: '42191814',
		label: 'Accesorios para incubadora o calentadora de bebés para uso clínico',
	},
	{
		code: '42191900',
		label: 'Armarios clínicos',
	},
	{
		code: '42191901',
		label: 'Gabinetes clínicos para junto a la cama o accesorios',
	},
	{
		code: '42191902',
		label: 'Armarios para uso hospitalario',
	},
	{
		code: '42191903',
		label: 'Gabinetes de monitoreo para uso médico',
	},
	{
		code: '42191904',
		label: 'Gabinetes o cajas fuertes para narcóticos',
	},
	{
		code: '42191905',
		label: 'Gabinetes de tratamiento para uso médico',
	},
	{
		code: '42191906',
		label: 'Gabinetes calentadores de cobijas o soluciones',
	},
	{
		code: '42191907',
		label:
			'Gabinetes o muebles de almacenamiento de instrumentos para uso médico',
	},
	{
		code: '42191908',
		label: 'Carritos de historias clínicas o accesorios',
	},
	{
		code: '42191909',
		label: 'Gabinete computarizado dispensador de medicamentos',
	},
	{
		code: '42192000',
		label: 'Mesas de examen o de procedimiento clínico',
	},
	{
		code: '42192001',
		label: 'Mesas de examen o procedimientos médicos para uso general',
	},
	{
		code: '42192002',
		label:
			'Accesorios para mesas de examen o procedimientos médicos para uso general excluyendo sábanas para cubrirlas',
	},
	{
		code: '42192003',
		label: 'Mesas para masajes de terapia física',
	},
	{
		code: '42192100',
		label: 'Asientos y taburetes clínicos y productos relacionados',
	},
	{
		code: '42192101',
		label: 'Asientos o accesorios para sacar sangre o flebotomía',
	},
	{
		code: '42192102',
		label: 'Reclinadoras o accesorios para uso hospitalario',
	},
	{
		code: '42192103',
		label: 'Asientos para pacientes',
	},
	{
		code: '42192104',
		label: 'Taburetes médicos y accesorios',
	},
	{
		code: '42192106',
		label: 'Asientos para visitantes de instalaciones médicas',
	},
	{
		code: '42192107',
		label: 'Asientos de examen clínico o accesorios',
	},
	{
		code: '42192200',
		label: 'Productos para transporte de paciente',
	},
	{
		code: '42192201',
		label: 'Camillas con ruedas o accesorios para el transporte de pacientes',
	},
	{
		code: '42192202',
		label: 'Elevadores de camillas o de tijeras',
	},
	{
		code: '42192203',
		label: 'Asientos geriátricos o accesorios',
	},
	{
		code: '42192204',
		label: 'Incubadoras para el transporte de pacientes o accesorios',
	},
	{
		code: '42192205',
		label: 'Accesorios para carritos de pacientes',
	},
	{
		code: '42192206',
		label: 'Carritos de pacientes',
	},
	{
		code: '42192207',
		label: 'Camillas para pacientes o accesorios para camillas',
	},
	{
		code: '42192208',
		label: 'Accesorios para sillas de ruedas',
	},
	{
		code: '42192209',
		label: 'Rampas para sillas de ruedas',
	},
	{
		code: '42192210',
		label: 'Sillas de ruedas',
	},
	{
		code: '42192211',
		label: 'Tablas para mover pacientes o sus accesorios',
	},
	{
		code: '42192212',
		label: 'Esterilla o sábana para transferencia de pacientes',
	},
	{
		code: '42192213',
		label:
			'Unidad de sistema de calefacción para mantener o evacuar pacientes o accesorios',
	},
	{
		code: '42192214',
		label: 'Elevador de sillas de ruedas',
	},
	{
		code: '42192300',
		label: 'Elevadores de paciente',
	},
	{
		code: '42192301',
		label: 'Elevadores de pacientes o accesorios',
	},
	{
		code: '42192302',
		label: 'Elevadores hidráulicos o accesorios para uso clínico',
	},
	{
		code: '42192303',
		label: 'Asientos suspendidos o eslingas para pacientes',
	},
	{
		code: '42192304',
		label: 'Montacargas de techo para pacientes',
	},
	{
		code: '42192305',
		label: 'Eslingas para bebés o accesorios',
	},
	{
		code: '42192400',
		label: 'Transporte de equipo médico y traslado de productos',
	},
	{
		code: '42192401',
		label: 'Carritos de emergencia o resucitación',
	},
	{
		code: '42192402',
		label: 'Carritos específicos para equipos de diagnóstico o monitoreo',
	},
	{
		code: '42192403',
		label: 'Carritos de aislamiento para uso médico',
	},
	{
		code: '42192404',
		label: 'Carritos o accesorios para uso médico',
	},
	{
		code: '42192405',
		label: 'Puestos móviles de irrigación',
	},
	{
		code: '42192406',
		label: 'Carritos para transportar orinales',
	},
	{
		code: '42192500',
		label: 'Protectores para equipo médico',
	},
	{
		code: '42192501',
		label: 'Forros para equipos médicos',
	},
	{
		code: '42192502',
		label: 'Bolsas para equipos médicos',
	},
	{
		code: '42192600',
		label:
			'Dispositivos de dosificación y medición de medicamentos y suministros',
	},
	{
		code: '42192601',
		label: 'Moldes de supositorios',
	},
	{
		code: '42192602',
		label: 'Dispensadores de pastillas o medicamentos o accesorios',
	},
	{
		code: '42192603',
		label: 'Tazas o botellas para administrar medicamentos o accesorios',
	},
	{
		code: '42192604',
		label: 'Sistemas o accesorios para la entrega de drogas',

		palabrasSimilares: 'Sistema de administración de fármacos',
	},
	{
		code: '42192605',
		label: 'Kits de encapsulamiento de aneurismas',
	},
	{
		code: '42192606',
		label: 'Soplador de polvos',

		palabrasSimilares: 'Nebulizador manual',
	},
	{
		code: '42201500',
		label:
			'Sistemas de tomografía informatizada (TAC o CT) médica y productos relacionados',
	},
	{
		code: '42201501',
		label:
			'Instalación de unidad estacionaria completa para tomografía computarizada ct o cat para uso médico',
	},
	{
		code: '42201502',
		label:
			'Unidades móviles o transportables o unidad de camión para tomografía computarizada ct o cat para uso médico',
	},
	{
		code: '42201503',
		label:
			'Componentes de sistema tridimensional para tomografía computarizada ct o cat para uso médico',
	},
	{
		code: '42201504',
		label:
			'Componentes de hueso de contenido mineral para tomografía computarizada ct o cat para uso médico',
	},
	{
		code: '42201505',
		label: 'Consolas para tomografía computarizada ct o cat para uso médico',
	},
	{
		code: '42201507',
		label:
			'Componentes de sistema helicoidal para tomografía computarizada ct o cat para uso médico',
	},
	{
		code: '42201508',
		label: 'Monitores para tomografía computarizada ct o cat para uso médico',
	},
	{
		code: '42201509',
		label:
			'Acondicionadores de energía para tomografía computarizada ct o cat para uso médico',
	},
	{
		code: '42201510',
		label:
			'Simuladores o dispositivos de aseguramiento de calidad o de calibración para tomografía computarizada ct o cat para uso médico',
	},
	{
		code: '42201511',
		label:
			'Escáneres o tubos para tomografía computarizada ct o cat para uso médico',
	},
	{
		code: '42201512',
		label:
			'Mesas o puestos o asientos para tomografía computarizada ct o cat para uso médico',
	},
	{
		code: '42201513',
		label:
			'Componentes de sistema ultra rápido para tomografía computarizada ct o cat para uso médico',
	},
	{
		code: '42201600',
		label: 'Productos de resonancia magnética (MRI) médica',
	},
	{
		code: '42201601',
		label:
			'Instalación de unidad estacionaria completa de imágenes de resonancia magnética mri para uso médico',
	},
	{
		code: '42201602',
		label:
			'Sistemas móviles o transportables o de camión de imágenes de resonancia magnética mri para uso médico',
	},
	{
		code: '42201603',
		label:
			'Componentes de sistema tridimensional de imágenes de resonancia magnética mri para uso médico',
	},
	{
		code: '42201604',
		label:
			'Simuladores o dispositivos de aseguramiento de calidad o calibración de imágenes de resonancia magnética mri para uso médico',
	},
	{
		code: '42201605',
		label: 'Bobinas de imágenes de resonancia magnética mri para uso médico',
	},
	{
		code: '42201607',
		label: 'Monitores de imágenes de resonancia magnética mri para uso médico',
	},
	{
		code: '42201608',
		label:
			'Consolas primarias o remotas o secundarias de imágenes de resonancia magnética mri para uso médico',
	},
	{
		code: '42201609',
		label: 'Escáneres de imágenes de resonancia magnética mri para uso médico',
	},
	{
		code: '42201610',
		label:
			'Instrumentos quirúrgicos o sistemas de guía de imágenes de resonancia magnética mri para uso médico',
	},
	{
		code: '42201611',
		label: 'Mesas de imágenes de resonancia magnética mri para uso médico',
	},
	{
		code: '42201700',
		label:
			'Productos de hacer imágenes de ultrasonido médico y de doppler y de eco',
	},
	{
		code: '42201701',
		label: 'Ultrasonido cardiaco o doppler o unidades de eco o cardioscopios',
	},
	{
		code: '42201702',
		label: 'Ultrasonido o unidades de eco fetales o ginecológicas',
	},
	{
		code: '42201703',
		label: 'Ultrasonido o unidades de eco mamográficas',
	},
	{
		code: '42201704',
		label: 'Densitómetros de hueso por ultrasonido para uso médico',
	},
	{
		code: '42201705',
		label: 'Forros para ultrasonido o doppler o eco sonda para uso médico',
	},
	{
		code: '42201706',
		label: 'Sondas para ultrasonido o doppler o eco para uso médico',
	},
	{
		code: '42201707',
		label:
			'Calentadores de gel para ultrasonido o doppler o eco para uso médico',
	},
	{
		code: '42201708',
		label: 'Geles para ultrasonido o doppler o eco para uso médico',
	},
	{
		code: '42201709',
		label: 'Monitores para ultrasonido o doppler o eco para uso médico',
	},
	{
		code: '42201710',
		label: 'Impresoras para ultrasonido o doppler o eco para uso médico',
	},
	{
		code: '42201711',
		label:
			'Transductores o accesorios para ultrasonido o doppler o eco para uso médico',
	},
	{
		code: '42201712',
		label:
			'Unidades de ultrasonido o doppler o eco pulso o ecografía de diagnóstico general para uso médico',
	},
	{
		code: '42201713',
		label:
			'Componentes tridimensionales para ultrasonido o doppler o eco para uso médico',
	},
	{
		code: '42201714',
		label: 'Tensiómetros',
	},
	{
		code: '42201715',
		label: 'Sondas o accesorios para ultrasonido o eco vaginal',
	},
	{
		code: '42201716',
		label: 'Unidades de ultrasonido vascular',
	},
	{
		code: '42201717',
		label: 'Almohadillas de transmisión de ultrasonido para uso médico',
	},
	{
		code: '42201718',
		label: 'Escáneres oftálmicos de ultrasonido para uso médico',
	},
	{
		code: '42201719',
		label: 'Lociones de escáner por ultrasonido para uso médico',
	},
	{
		code: '42201800',
		label: 'Productos radiográficos de diagnóstico médico',
	},
	{
		code: '42201801',
		label: 'Películas de rayos x para cardiología',
	},
	{
		code: '42201802',
		label: 'Equipos de rayos x para cardiología',
	},
	{
		code: '42201803',
		label: 'Equipos de rayos x para mamografías',
	},
	{
		code: '42201804',
		label: 'Unidades de brazo c de rayos x para uso médico',
	},
	{
		code: '42201805',
		label: 'Equipo de cine fluoroscopio para uso médico',
	},
	{
		code: '42201806',
		label: 'Equipo de radiología y fluoroscopia rf para uso médico',
	},
	{
		code: '42201807',
		label: 'Escáneres de radioisótopos para uso médico',
	},
	{
		code: '42201808',
		label: 'Rejillas móviles de rayos x para uso médico',
	},
	{
		code: '42201809',
		label:
			'Inyectores o accesorios de agentes de contraste para imágenes para uso médico',
	},
	{
		code: '42201810',
		label: 'Casetes o película de rayos x de uso general para uso médico',
	},
	{
		code: '42201811',
		label:
			'Dispositivos de aseguramiento de calidad o calibración de rayos x para uso médico',
	},
	{
		code: '42201812',
		label:
			'Mesas o puestos o asientos o gabinetes o accesorios de rayos x para uso médico',
	},
	{
		code: '42201813',
		label: 'Unidades de tomografía de rayos x para uso médico',
	},
	{
		code: '42201814',
		label: 'Tubos de rayos x para uso médico',
	},
	{
		code: '42201815',
		label: 'Unidades de rayos x de uso diagnóstico general para uso médico',
	},
	{
		code: '42201816',
		label: 'Unidades de xerorradiografía para uso médico',
	},
	{
		code: '42201817',
		label: 'Densitómetros de hueso de rayos x',
	},
	{
		code: '42201818',
		label: 'Cuñas de pisada y trompos de combinación para equipo radiográfico',
	},
	{
		code: '42201819',
		label: 'Colgadores o sus accesorios para películas de rayos x',
	},
	{
		code: '42201820',
		label: 'Rejillas de equipo radiográfico para uso médico',
	},
	{
		code: '42201821',
		label: 'Sujeta casetes de película radiográfica',
	},
	{
		code: '42201822',
		label:
			'Estuches o fundas o accesorios para equipos de rayos x para uso médico',
	},
	{
		code: '42201823',
		label: 'Unidad de tubo y transformador de rayos x para uso médico',
	},
	{
		code: '42201824',
		label: 'Sets de artrografía para uso médico',
	},
	{
		code: '42201825',
		label: 'Insertos de tubo de aparatos de rayos x para uso médico',
	},
	{
		code: '42201826',
		label: 'Kits de reparación de aparatos de rayos x para uso médico',
	},
	{
		code: '42201827',
		label:
			'Kits de reparación de carpa de cuarto oscuro de rayos x para uso médico',
	},
	{
		code: '42201828',
		label: 'Filtros de aparatos de rayos x para uso médico',
	},
	{
		code: '42201829',
		label: 'Localizadores radiográficos',
	},
	{
		code: '42201830',
		label: 'Pantallas intensificadoras de rayos x para uso médico',
	},
	{
		code: '42201831',
		label: 'Tapas de casetes o películas de rayos x para uso médico',
	},
	{
		code: '42201832',
		label: 'Cubiertas de casetes o película radiográfica',
	},
	{
		code: '42201833',
		label: 'Cambiadores de casetes o película radiográfica',
	},
	{
		code: '42201834',
		label:
			'Ensamblajes rectificadores de aparatos de rayos x radiográficos para uso médico',
	},
	{
		code: '42201835',
		label:
			'Ensamblajes de unidades de tubos de aparatos de rayos x para uso médico',
	},
	{
		code: '42201836',
		label:
			'Ensamblajes de bandas de compresión de aparatos de rayos x para uso médico',
	},
	{
		code: '42201837',
		label: 'Enfriadores de agua de rayos x para uso médico',
	},
	{
		code: '42201838',
		label: 'Catéteres o kits de catéteres de enteroclisis para uso médico',
	},
	{
		code: '42201839',
		label: 'Bandejas de procedimientos de imágenes para uso médico',
	},
	{
		code: '42201840',
		label: 'Dispositivos sellantes vasculares',
	},
	{
		code: '42201841',
		label: 'Papeles de rayos x diagnósticos para uso médico',
	},
	{
		code: '42201843',
		label: 'Marcador de piel de rayos x para uso médico',
	},
	{
		code: '42201844',
		label: 'Regla diagnóstica de rayos x para uso médico',
	},
	{
		code: '42201845',
		label: 'Funda o inserto o sobre para película de radiología',
	},
	{
		code: '42201846',
		label: 'Colimador de rayos x radiográfico y fluoroscópico',
	},
	{
		code: '42201847',
		label: 'Sistema móvil de rayos x',
	},
	{
		code: '42201848',
		label: 'Sistema digital de imágenes de rayos x',
	},
	{
		code: '42201849',
		label: 'Escáner fluorescente',
	},
	{
		code: '42201850',
		label: 'Sistema de tomografía nuclear',
	},
	{
		code: '42201851',
		label: 'Contador beta o gama para uso clínico',
	},
	{
		code: '42201852',
		label: 'Pantalla fluorescente',
	},
	{
		code: '42201900',
		label:
			'Fuentes de iluminación y equipo de visión de película radiográfica médica',
	},
	{
		code: '42201901',
		label: 'Luces de punto caliente de película de rayos x para uso médico',
	},
	{
		code: '42201902',
		label:
			'Sistemas de estanterías de despliegue largas de rayos x para uso médico',
	},
	{
		code: '42201903',
		label: 'Cajas de despliegue de película de rayos x para uso médico',
	},
	{
		code: '42201904',
		label:
			'Ventanas o pantallas iluminadoras de película de rayos x para uso médico',
	},
	{
		code: '42201905',
		label: 'Estuches de transferencia de película de rayos x para uso médico',
	},
	{
		code: '42201906',
		label: 'Ganchos iluminadores de película de rayos x para uso médico',
	},
	{
		code: '42201907',
		label: 'Estereoscopios de película de rayos x para uso médico',
	},
	{
		code: '42201908',
		label: 'Viseras para monitores fluoroscopios para uso médico',
	},
	{
		code: '42202000',
		label: 'Cámaras médicas gamma de diagnóstico y productos relacionados',
	},
	{
		code: '42202001',
		label: 'Cámaras gamma de uso general para uso médico',
	},
	{
		code: '42202002',
		label: 'Sistemas de navegación o accesorios para mapeo linfático',
	},
	{
		code: '42202003',
		label: 'Sondas para mapeo linfático',
	},
	{
		code: '42202004',
		label: 'Colimadores para mapeo linfático',
	},
	{
		code: '42202005',
		label: 'Paquete de procedimiento para mapeo linfático',
	},
	{
		code: '42202006',
		label: 'Densitómetro óseo',
	},
	{
		code: '42202100',
		label: 'Productos de Braquiterapia',
	},
	{
		code: '42202101',
		label: 'Contenedores o semillas de intra cavidad para braquiterapia',
	},
	{
		code: '42202102',
		label: 'Catéteres o jeringas o insertores o aplicadores para braquiterapia',
	},
	{
		code: '42202103',
		label: 'Contenedores de almacenamiento de semillas para braquiterapia',
	},
	{
		code: '42202104',
		label: 'Kits de captura de semillas para braquiterapia',
	},
	{
		code: '42202105',
		label: 'Unidades de braquiterapia',
	},
	{
		code: '42202106',
		label: 'Sistema de aplicador radionúclido manual',
	},
	{
		code: '42202200',
		label: 'Productos de radiación gamma',
	},
	{
		code: '42202201',
		label: 'Colimadores o cascos de cuchillo gamma para radio cirugía',
	},
	{
		code: '42202202',
		label: 'Unidades o centelladores de cuchillos gamma radio quirúrgicos',
	},
	{
		code: '42202203',
		label: 'Sets de almohadillas para cascos de radio cirugía',
	},
	{
		code: '42202204',
		label: 'Sistema de re inhalación radionúclido',
	},
	{
		code: '42202205',
		label: 'Bloque de moldeo de haz de terapia de radiación',
	},
	{
		code: '42202300',
		label:
			'Productos de acelerador lineal de terapia de radiación de intensidad modulada (IMRT) médica',
	},
	{
		code: '42202301',
		label:
			'Unidades bidimensionales de terapia de radiación de intensidad modulada de acelerador lineal imrt para uso médico',
	},
	{
		code: '42202302',
		label:
			'Unidades tridimensionales de terapia de radiación de intensidad modulada de acelerador lineal imrt para uso médico',
	},
	{
		code: '42202303',
		label:
			'Colimadores de terapia de radiación de intensidad modulada de acelerador lineal imrt para uso médico',
	},
	{
		code: '42202400',
		label:
			'Equipo de tomografía por emisión de positrones (PET) médica y productos relacionados',
	},
	{
		code: '42202401',
		label:
			'Unidades de tomografía de emisión de positrones pet para uso médico',
	},
	{
		code: '42202500',
		label:
			'Equipo de tomografía informatizada por emisión de fotones simples (SPECT) médica y productos relacionados',
	},
	{
		code: '42202501',
		label:
			'Unidades de tomografía de emisión computarizada de fotones únicos spect para uso médico',
	},
	{
		code: '42202600',
		label: 'Productos de administración de radioisótopos y radioimunoterapia',
	},
	{
		code: '42202601',
		label: 'Suministros para terapia de irradiación de tiroides',
	},
	{
		code: '42202602',
		label: 'Kits de pruebas para radio inmunoterapia',
	},
	{
		code: '42202700',
		label: 'Productos de radioterapia tele terapia',
	},
	{
		code: '42202701',
		label: 'Equipo de cobalto 60 para teleterapia radioterapia',
	},
	{
		code: '42202702',
		label: 'Aceleradores lineales para teleterapia radioterapia',
	},
	{
		code: '42202703',
		label: 'Máquinas de rayos x de ortovoltaje para teleterapia radioterapia',
	},
	{
		code: '42202704',
		label: 'Máquinas de rayos x superficiales para teleterapia radioterapia',
	},
	{
		code: '42202900',
		label: 'Equipo radiográfico médico de bajo consumo eléctrico',
	},
	{
		code: '42202901',
		label: 'Unidades de rayos x de baja energía para uso médico',
	},
	{
		code: '42203000',
		label: 'Aceleradores médicos lineales y productos relacionados',
	},
	{
		code: '42203001',
		label: 'Aceleradores lineales móviles o transportables para uso médico',
	},
	{
		code: '42203100',
		label: 'Instrumentos radio biológicos',
	},
	{
		code: '42203101',
		label: 'Micro dosímetros de efecto radio biológico',
	},
	{
		code: '42203200',
		label: 'Simuladores de radioterapia',
	},
	{
		code: '42203201',
		label:
			'Simuladores de planeación de radioterapia rf de fluoroscopia y rayos x',
	},
	{
		code: '42203202',
		label: 'Simuladores de radioterapia de tomografía computarizada ct o cat',
	},
	{
		code: '42203300',
		label: 'Sistemas médicos estereotáctica',
	},
	{
		code: '42203301',
		label: 'Sistemas de terapia estereotáctica sin marco',
	},
	{
		code: '42203302',
		label: 'Marcos para la cabeza para terapia estereotáctica',
	},
	{
		code: '42203303',
		label: 'Sistemas de biopsia estereotáctica',
	},
	{
		code: '42203400',
		label:
			'Productos para hacer imágenes vasculares y para cardiología intervencionista y laboratorios de cateterización cardiaca',
	},
	{
		code: '42203401',
		label: 'Stents coronarios',
	},
	{
		code: '42203402',
		label: 'Catéteres o sets de diagnóstico o intervención vascular',
	},
	{
		code: '42203403',
		label:
			'Introductores de catéteres o sets de diagnóstico o intervención vascular',
	},
	{
		code: '42203404',
		label: 'Alambre guía para imágenes vasculares',
	},
	{
		code: '42203405',
		label: 'Catéter inflable para angioplastia',
	},
	{
		code: '42203406',
		label:
			'Dispositivos de remoción de catéteres o sets de diagnóstico o intervención vascular',
	},
	{
		code: '42203407',
		label: 'Sets de entrega de medios de contraste para angiografía',
	},
	{
		code: '42203408',
		label: 'Kits de fundas cardiovasculares',
	},
	{
		code: '42203409',
		label: 'Valvulotomos angioscópicos',
	},
	{
		code: '42203410',
		label: 'Funda de catéter cardiovascular',
	},
	{
		code: '42203411',
		label: 'Carritos para catéteres',
	},
	{
		code: '42203412',
		label: 'Stents periféricos',
	},
	{
		code: '42203413',
		label: 'Bobina vascular',
	},
	{
		code: '42203414',
		label: 'Filtro vascular',
	},
	{
		code: '42203415',
		label: 'Equipo de laboratorio de cateterización cardíaca',
	},
	{
		code: '42203416',
		label: 'Catéter de ablación cardíaca',
	},
	{
		code: '42203417',
		label: 'Endógrafo',
	},
	{
		code: '42203418',
		label: 'Catéter para arterectomía',
	},
	{
		code: '42203419',
		label: 'Stents biliares',
	},
	{
		code: '42203420',
		label: 'Lazo para imágenes vasculares',
	},
	{
		code: '42203421',
		label: 'Bandejas o paquetes para diagnóstico o intervención vascular',
	},
	{
		code: '42203422',
		label: 'Pegante de embolización',
	},
	{
		code: '42203423',
		label: 'Esfera de embolización',
	},
	{
		code: '42203424',
		label: 'Dispositivo de cierre de sitio de procedimiento de cateterización',
	},
	{
		code: '42203425',
		label: 'Catéter para embolectomía trombectomía',
	},
	{
		code: '42203426',
		label: 'Dispositivo de torsión de alambre de guía para angiografía',
	},
	{
		code: '42203427',
		label: 'Aguja de introducción para angiografía',
	},
	{
		code: '42203428',
		label: 'Válvula de control y válvula hemostática para angiografía',
	},
	{
		code: '42203429',
		label: 'Catéter para pericardiocentesis',
	},
	{
		code: '42203430',
		label: 'Catéter de mapeo para electrofisiología',
	},
	{
		code: '42203500',
		label: 'Marcapasos cardiacos o productos relacionados',
	},
	{
		code: '42203501',
		label:
			'Generador de marcapasos cardíaco o marcapasos de terapia de re sincronización cardíaca',
	},
	{
		code: '42203502',
		label: 'Cables o electrodos o accesorios para marcapasos cardíacos',
	},
	{
		code: '42203503',
		label: 'Introductores de cables o sets para marcapasos cardíacos',
	},
	{
		code: '42203504',
		label: 'Grabadora cardíaca',
	},
	{
		code: '42203505',
		label:
			'Desfibrilador cardiovertidor implantable icd o desfibrilador para terapia de re sincronización cardíaca crt-d',
	},
	{
		code: '42203506',
		label:
			'Cables o electrodos de desfibrilador cardiovertidor implantable icd o desfibrilador para terapia de re sincronización cardíaca crt-d',
	},
	{
		code: '42203600',
		label:
			'Productos de archivar y información de toma de imágenes radiológicos médicos',
	},
	{
		code: '42203601',
		label: 'Equipos de sistema de red de imágenes digitales de defensa din',
	},
	{
		code: '42203602',
		label:
			'Equipos de sistema estándar de comunicaciones de imágenes digitales en medicina dicom',
	},
	{
		code: '42203603',
		label: 'Sistemas de computador para archivo de fotografías médicas pacs',
	},
	{
		code: '42203604',
		label:
			'Hardware de sistema de archivo de película de rayos x para usos médicos',
	},
	{
		code: '42203605',
		label:
			'Software de sistema de archivo de película de rayos x para usos médicos',
	},
	{
		code: '42203606',
		label: 'Sistemas de telerradiografía',
	},
	{
		code: '42203700',
		label: 'Suministros y equipo de procesado de toma de imágenes médicos',
	},
	{
		code: '42203701',
		label:
			'Estampadores de luz del día para películas de rayos x o impresoras de identificación para uso médico',
	},
	{
		code: '42203702',
		label: 'Procesadores para luz del día o cuarto oscuro de imágenes médicas',
	},
	{
		code: '42203703',
		label: 'Cajas de paso de seguridad de rayos x para uso médico',
	},
	{
		code: '42203704',
		label: 'Toners o desarrolladores para uso médico',
	},
	{
		code: '42203705',
		label:
			'Impresoras láser en seco para imágenes o reproductores de imágenes para uso médico',
	},
	{
		code: '42203706',
		label: 'Kits de químicos para procesar películas de rayos x de uso médico',
	},
	{
		code: '42203707',
		label: 'Equipos o suministros de cuarto oscuro de rayos x para uso médico',
	},
	{
		code: '42203708',
		label: 'Fijadores para procesar película de rayos x para uso médico',
	},
	{
		code: '42203709',
		label:
			'Sets de calibradores para sistemas de combinación de desplegadores e impresoras de rayos x',
	},
	{
		code: '42203710',
		label: 'Marcadores de películas de rayos x para uso médico',
	},
	{
		code: '42203800',
		label: 'Auxilios de posicionamiento radiológico médico',
	},
	{
		code: '42203801',
		label:
			'Ayudas de posicionamiento para tomografía computarizada ct o cat para uso médico',
	},
	{
		code: '42203802',
		label:
			'Ayudas de posicionamiento para imágenes de resonancia magnética mri para uso médico',
	},
	{
		code: '42203803',
		label:
			'Ayudas de posicionamiento para uso radiológico general para uso médico',
	},
	{
		code: '42203804',
		label: 'Ayudas de posicionamiento para terapias de radiación',
	},
	{
		code: '42203900',
		label: 'Productos para controlar o detectar radiación médica',
	},
	{
		code: '42203901',
		label: 'Dosímetros de radiación para uso médico',
	},
	{
		code: '42203902',
		label: 'Películas o brazaletes de radiación para uso médico',
	},
	{
		code: '42203903',
		label: 'Sistema de terapia de radiación de neutrones para uso médico',
	},
	{
		code: '42204000',
		label: 'Productos de protección y resguardo radiológico médico',
	},
	{
		code: '42204001',
		label: 'Estantes para delantales de protección radiológica para uso médico',
	},
	{
		code: '42204002',
		label:
			'Delantales o máscaras o cortinas de protección radiológica para uso médico',
	},
	{
		code: '42204003',
		label:
			'Contenedores portátiles de protección para materiales radiológicos radiactivos para uso médico',
	},
	{
		code: '42204004',
		label: 'Auriculares de protección radiológica para uso médico',
	},
	{
		code: '42204005',
		label: 'Guantes de protección radiológica para uso médico',
	},
	{
		code: '42204006',
		label:
			'Pantallas de pie o portátiles de protección radiológica para uso médico',
	},
	{
		code: '42204007',
		label:
			'Paneles instalados de piso o techo o pared de protección radiológica para uso médico',
	},
	{
		code: '42204008',
		label:
			'Cámaras o habitaciones o cajas fuertes de protección radiológica para uso médico',
	},
	{
		code: '42204009',
		label: 'Anteojos de protección radiológica para uso médico',
	},
	{
		code: '42204100',
		label:
			'Equipo caliente de laboratorio y productos relacionados para medicina nuclear',
	},
	{
		code: '42204101',
		label: 'Sujetador o cargador o protector para jeringas',
	},
	{
		code: '42211500',
		label:
			'Ayuda de ambulación y traslado y posicionar para personas físicamente discapacitadas',
	},
	{
		code: '42211501',
		label: 'Bastones o accesorios para bastones',
	},
	{
		code: '42211502',
		label: 'Muletas o accesorios para muletas',
	},
	{
		code: '42211503',
		label: 'Dispositivos de posicionamiento',
	},
	{
		code: '42211504',
		label: 'Levantadores o ayudas para ponerse de pie',
	},
	{
		code: '42211505',
		label: 'Accesorios para caminadores o andadores',
	},
	{
		code: '42211506',
		label: 'Caminadores o andadores',
	},
	{
		code: '42211507',
		label: 'Dispositivos de cuerpo entero para deslizarse o voltearse',
	},
	{
		code: '42211508',
		label: 'Dispositivos o accesorios de movilidad multifuncional',
	},
	{
		code: '42211509',
		label:
			'Cascos protectores de cabeza o cara o dispositivos o accesorios para los discapacitados físicamente',
	},
	{
		code: '42211600',
		label: 'Baño y ayudas de baño para personas físicamente discapacitadas',
	},
	{
		code: '42211601',
		label: 'Tablas para bañarse para los discapacitados físicamente',
	},
	{
		code: '42211602',
		label:
			'Cepillos de baño o esponjas o estropajos para los discapacitados físicamente',
	},
	{
		code: '42211603',
		label:
			'Elevadores para baño o accesorios para los discapacitados físicamente',
	},
	{
		code: '42211604',
		label: 'Guantes de baño para los discapacitados físicamente',
	},
	{
		code: '42211605',
		label: 'Almohadas de baño para los discapacitados físicamente',
	},
	{
		code: '42211606',
		label: 'Cómodas o accesorios para los discapacitados físicamente',
	},
	{
		code: '42211607',
		label: 'Asientos de inodoro elevados para los discapacitados físicamente',
	},
	{
		code: '42211608',
		label:
			'Barras de sujeción o barandas para la tina para los discapacitados físicamente',
	},
	{
		code: '42211610',
		label:
			'Asientos o sillas para la ducha o el baño para los discapacitados físicamente',
	},
	{
		code: '42211611',
		label: 'Baños de asiento para los discapacitados físicamente',
	},
	{
		code: '42211612',
		label:
			'Soportes de brazos para el inodoro para los discapacitados físicamente',
	},
	{
		code: '42211613',
		label: 'Marcos para el inodoro para los discapacitados físicamente',
	},
	{
		code: '42211614',
		label: 'Asientos de inodoro para los discapacitados físicamente',
	},
	{
		code: '42211615',
		label:
			'Levantadores de asientos de inodoro para los discapacitados físicamente',
	},
	{
		code: '42211616',
		label:
			'Ayudas de higiene o estimulación de tocador para los discapacitados físicamente',
	},
	{
		code: '42211617',
		label: 'Bancos de transferencia para los discapacitados físicamente',
	},
	{
		code: '42211618',
		label:
			'Alarma de pulso de mojada en la cama para los discapacitados físicamente',
	},
	{
		code: '42211619',
		label:
			'Jabón protector de ojos y oídos para los discapacitados físicamente',
	},
	{
		code: '42211620',
		label: 'Bandeja o contenedor de champú para los discapacitados físicamente',
	},
	{
		code: '42211700',
		label: 'Ayudas de comunicación para personas físicamente discapacitadas',
	},
	{
		code: '42211701',
		label:
			'Interruptores de comunicación adaptativa para los discapacitados físicamente',
	},
	{
		code: '42211702',
		label: 'Dispositivos braille para los discapacitados físicamente',
	},
	{
		code: '42211703',
		label:
			'Papel o plástico para escribir braille para los discapacitados físicamente',
	},
	{
		code: '42211704',
		label:
			'Señaladores de cabeza o palitos para la boca para los discapacitados físicamente',
	},
	{
		code: '42211705',
		label: 'Ayudas auditivas para los discapacitados físicamente',
	},
	{
		code: '42211706',
		label: 'Tableros de letras o símbolos para los discapacitados físicamente',
	},
	{
		code: '42211707',
		label:
			'Dispositivos de telecomunicación tdd o teletipos tty para los discapacitados físicamente',
	},
	{
		code: '42211708',
		label: 'Ayudas telefónicas para los discapacitados físicamente',
	},
	{
		code: '42211709',
		label: 'Ayudas de mecanografía para los discapacitados físicamente',
	},
	{
		code: '42211710',
		label: 'Ayudas de escritura para los discapacitados físicamente',
	},
	{
		code: '42211711',
		label: 'Sintetizadores de voz para los discapacitados físicamente',
	},
	{
		code: '42211712',
		label: 'Estuches para ayudas auditivas',
	},
	{
		code: '42211800',
		label:
			'Ayuda para vestirse y asearse para personas físicamente discapacitadas',
	},
	{
		code: '42211801',
		label: 'Ganchos para abotonarse para los discapacitados físicamente',
	},
	{
		code: '42211802',
		label: 'Kits para vestirse para los discapacitados físicamente',
	},
	{
		code: '42211803',
		label: 'Palos para vestirse para los discapacitados físicamente',
	},
	{
		code: '42211804',
		label: 'Cepillos de pelo o peinillas para los discapacitados físicamente',
	},
	{
		code: '42211805',
		label: 'Espejo de inspección para los discapacitados físicamente',
	},
	{
		code: '42211806',
		label: 'Aplicadores de lociones para los discapacitados físicamente',
	},
	{
		code: '42211807',
		label: 'Cuidado de la boca para los discapacitados físicamente',
	},
	{
		code: '42211808',
		label: 'Cortaúñas o limas para los discapacitados físicamente',
	},
	{
		code: '42211809',
		label:
			'Ganchos para sujetarse los pantalones para los discapacitados físicamente',
	},
	{
		code: '42211810',
		label: 'Calzadores para los discapacitados físicamente',
	},
	{
		code: '42211811',
		label: 'Hormas de zapatos para los discapacitados físicamente',
	},
	{
		code: '42211812',
		label: 'Media o ayudas para apilar para los discapacitados físicamente',
	},
	{
		code: '42211813',
		label: 'Jaladores de cremalleras para los discapacitados físicamente',
	},
	{
		code: '42211900',
		label:
			'Ayuda de preparación de comida y bebida y alimentos para personas físicamente discapacitadas',
	},
	{
		code: '42211901',
		label: 'Materiales anti resbalón para los discapacitados físicamente',
	},
	{
		code: '42211902',
		label:
			'Dispositivos de ayuda para cocinar para los discapacitados físicamente',
	},
	{
		code: '42211903',
		label: 'Abre latas para los físicamente discapacitados',
	},
	{
		code: '42211904',
		label: 'Picadores para los discapacitados físicamente',
	},
	{
		code: '42211905',
		label: 'Tazas o tazones (mugs) para los discapacitados físicamente',
	},
	{
		code: '42211906',
		label:
			'Sujetadores de cubiertos o de utensilios para los discapacitados físicamente',
	},
	{
		code: '42211907',
		label: 'Cubiertos o utensilios para los discapacitados físicamente',
	},
	{
		code: '42211908',
		label: 'Tablas para cortar o pelar para los discapacitados físicamente',
	},
	{
		code: '42211909',
		label: 'Sujetadores de bebida para los discapacitados físicamente',
	},
	{
		code: '42211910',
		label:
			'Baberos o recogedores de comida para los discapacitados físicamente',
	},
	{
		code: '42211911',
		label: 'Forros para comida para los discapacitados físicamente',
	},
	{
		code: '42211912',
		label:
			'Sujetadores para dispositivos de cocina para los discapacitados físicamente',
	},
	{
		code: '42211913',
		label: 'Temporizadores digitales jumbo para los discapacitados físicamente',
	},
	{
		code: '42211914',
		label: 'Ayudas para medir para los discapacitados físicamente',
	},
	{
		code: '42211915',
		label:
			'Auto alimentadores o accesorios para los discapacitados físicamente',
	},
	{
		code: '42211916',
		label: 'Pitillos o sujeta pitillos para los discapacitados físicamente',
	},
	{
		code: '42211917',
		label: 'Vajillas para los discapacitados físicamente',
	},
	{
		code: '42211918',
		label: 'Balanzas de comida habladoras para los discapacitados físicamente',
	},
	{
		code: '42212000',
		label:
			'Cuidado de la casa y ayuda para el cuidado de la casa para personas físicamente discapacitadas',
	},
	{
		code: '42212001',
		label: 'Abridores de puertas para los discapacitados físicamente',
	},
	{
		code: '42212002',
		label:
			'Dispositivos para echar llave o sujetadores para los discapacitados físicamente',
	},
	{
		code: '42212003',
		label:
			'Dispositivos para abrir puertas para los discapacitados físicamente',
	},
	{
		code: '42212004',
		label:
			'Extensiones de interruptores de luz para los discapacitados físicamente',
	},
	{
		code: '42212005',
		label:
			'Plumeros o cepillos de mango largo para los discapacitados físicamente',
	},
	{
		code: '42212006',
		label: 'Tijeras que se abren solas para los discapacitados físicamente',
	},
	{
		code: '42212007',
		label: 'Cepillos de succión para los discapacitados físicamente',
	},
	{
		code: '42212100',
		label:
			'Aparatos de tiempo libre y recreo para personas físicamente discapacitadas',
	},
	{
		code: '42212101',
		label:
			'Barajadores de naipe automáticos para los discapacitados físicamente',
	},
	{
		code: '42212102',
		label: 'Juegos de mesa para los discapacitados físicamente',
	},
	{
		code: '42212103',
		label: 'Sujetadores de libros para los discapacitados físicamente',
	},
	{
		code: '42212104',
		label:
			'Naipes de figuras grandes o braille para los discapacitados físicamente',
	},
	{
		code: '42212105',
		label: 'Equipo de camping para los discapacitados físicamente',
	},
	{
		code: '42212106',
		label: 'Ayudas de pesca o cacería para los discapacitados físicamente',
	},
	{
		code: '42212107',
		label: 'Ayudas de flotación o natación para los discapacitados físicamente',
	},
	{
		code: '42212108',
		label: 'Herramientas de jardinería para los discapacitados físicamente',
	},
	{
		code: '42212109',
		label:
			'Herramientas o materiales o equipos de manualidades para los discapacitados físicamente',
	},
	{
		code: '42212110',
		label: 'Volteadores de páginas para los discapacitados físicamente',
	},
	{
		code: '42212111',
		label: 'Sujetadores de naipes para los discapacitados físicamente',
	},
	{
		code: '42212112',
		label: 'Ayudas de costura para los discapacitados físicamente',
	},
	{
		code: '42212113',
		label: 'Ayudas para fumar para los discapacitados físicamente',
	},
	{
		code: '42212200',
		label:
			'Auxilio de manejo de medicamentos para personas físicamente discapacitadas',
	},
	{
		code: '42212201',
		label:
			'Trituradores o partidores de píldoras para los discapacitados físicamente',
	},
	{
		code: '42212202',
		label: 'Organizadores de píldoras para los discapacitados físicamente',
	},
	{
		code: '42212203',
		label: 'Recordadores de píldoras para los discapacitados físicamente',
	},
	{
		code: '42212204',
		label: 'Exprimidores de tubos para los discapacitados físicamente',
	},
	{
		code: '42212300',
		label:
			'Ayuda para llegar o agarrar para personas físicamente discapacitadas',
	},
	{
		code: '42212301',
		label:
			'Materiales o dispositivos de agarre para los discapacitados físicamente',
	},
	{
		code: '42212302',
		label: 'Sujetadores para los discapacitados físicamente',
	},
	{
		code: '42212303',
		label: 'Abridores de contenedores para los discapacitados físicamente',
	},
	{
		code: '42212304',
		label: 'Alcanzadores para los discapacitados físicamente',
	},
	{
		code: '42221500',
		label: 'Accesorios y catéteres y cánulas intravenosas y arteriales',
	},
	{
		code: '42221501',
		label: 'Catéteres de línea arterial',
	},
	{
		code: '42221502',
		label: 'Válvulas de descarga de catéteres arteriales de línea continua',
	},
	{
		code: '42221503',
		label: 'Catéteres venosos centrales',
	},
	{
		code: '42221504',
		label: 'Catéteres intravenosos periféricos para uso general',
	},
	{
		code: '42221505',
		label:
			'Catéteres pediátricos o intravenosos para venas del cuero cabelludo o arteriales',
	},
	{
		code: '42221506',
		label: 'Catéteres umbilicales',
	},
	{
		code: '42221507',
		label: 'Kits de arranque intravenoso o arterial sin catéter',
	},
	{
		code: '42221508',
		label: 'Kits de cuidado de la piel para catéter arterial o intravenoso',
	},
	{
		code: '42221509',
		label: 'Bandejas para catéteres intravenosos o arteriales',
	},
	{
		code: '42221512',
		label: 'Cánulas intravenosas o arteriales y accesorios',
	},
	{
		code: '42221513',
		label: 'Kits de cateterización cardiovascular',
	},
	{
		code: '42221514',
		label: 'Catéter de arteria pulmonar',
	},
	{
		code: '42221515',
		label: 'Kit de reparación de catéter venoso central',
	},
	{
		code: '42221516',
		label: 'Oclusor de la punta del catéter',
	},
	{
		code: '42221517',
		label: 'Catéter para hemodiálisis',
	},
	{
		code: '42221518',
		label: 'Material de vendaje de férulas',
	},
	{
		code: '42221600',
		label:
			'Tubería y juegos de administración arterial o intravenoso y productos relacionados',
	},
	{
		code: '42221601',
		label:
			'Detectores de burbujas de aire para administración arterial o intravenosa',
	},
	{
		code: '42221602',
		label:
			'Puertos o lugares de inyección o tapas o protectores para administración arterial o intravenosa',
	},
	{
		code: '42221603',
		label: 'Tubos de extensión arteriales o intravenosos',
	},
	{
		code: '42221604',
		label:
			'Adaptadores o conectores o candados o tapas o protectores para tubos arteriales o intravenosos',
	},
	{
		code: '42221605',
		label: 'Válvulas de chequeo de tubos arteriales o intravenosos',
	},
	{
		code: '42221606',
		label: 'Pinzas de tubos intravenosos o arteriales',
	},
	{
		code: '42221607',
		label:
			'Filtros o pantallas para tubos arteriales o intravenosos de uso general',
	},
	{
		code: '42221608',
		label:
			'Etiquetas o cintas de identificación de tubos intravenosos o arteriales',
	},
	{
		code: '42221609',
		label: 'Sets de administración de tubos intravenosos o arteriales',
	},
	{
		code: '42221610',
		label:
			'Puertos o tapas o protectores de entrada de espiga de tubos intravenosos',
	},
	{
		code: '42221611',
		label: 'Espigas o tapas o protectores de tubos intravenosos',
	},
	{
		code: '42221612',
		label:
			'Puertos de inyección o llaves de paso o colectores sin aguja de tubos arteriales o intravenosos',
	},
	{
		code: '42221613',
		label:
			'Cierres o broches de extensión de transferencia de tubos intravenosos',
	},
	{
		code: '42221614',
		label: 'Kits de administración de tubos intravenosos con catéter',
	},
	{
		code: '42221615',
		label: 'Tubos intravenosos de medicación secundaria',
	},
	{
		code: '42221616',
		label: 'Sets de extensión arterial o intravenosa',
	},
	{
		code: '42221617',
		label: 'Calentadores de resistencia para agujas',
	},
	{
		code: '42221618',
		label: 'Bandejas para procedimientos arteriales o intravenosos',
	},
	{
		code: '42221619',
		label: 'Kit colector de inyecciones sin aguja',
	},
	{
		code: '42221700',
		label:
			'Bolsas de infusión y recipientes y productos relacionados intravenosos y arteriales',
	},
	{
		code: '42221701',
		label:
			'Bolsas o contenedores de infusión arterial o intravenosa de único puerto',
	},
	{
		code: '42221702',
		label:
			'Bolsas o contenedores de transferencia de infusión arterial o intravenosa',
	},
	{
		code: '42221703',
		label: 'Calentadores de fluido de infusión arterial o intravenosa',
	},
	{
		code: '42221704',
		label: 'Bolsas de infusión de presión arterial o intravenosa',
	},
	{
		code: '42221705',
		label: 'Ensamblajes de vial de infusión de analgésicos',
	},
	{
		code: '42221706',
		label:
			'Picos de bolsas o contenedores de transferencia de infusión arterial o intravenosa',
	},
	{
		code: '42221707',
		label: 'Sets o kits de infusión de analgésicos',
	},
	{
		code: '42221800',
		label: 'Aparatos para posicionar aguja y catéter intravenoso o arterial',
	},
	{
		code: '42221801',
		label: 'Forros para tablas de brazo arterial o intravenoso',
	},
	{
		code: '42221802',
		label: 'Tablas de brazo arterial o intravenoso',
	},
	{
		code: '42221803',
		label:
			'Cintas o vendajes o correas o mangas para posicionamiento de catéter arterial o intravenoso',
	},
	{
		code: '42221900',
		label: 'Productos de regulación y medida del flujo intravenoso o arterial',
	},
	{
		code: '42221901',
		label: 'Flujómetros o reguladores intravenosos calibrados por dial',
	},
	{
		code: '42221902',
		label: 'Contadores o reguladores de goteo intravenoso',
	},
	{
		code: '42221903',
		label: 'Detectores ultrasónicos de flujo de sangre',
	},
	{
		code: '42221904',
		label: 'Flujómetro de sangre electromagnético',
	},
	{
		code: '42221905',
		label: 'Flujómetro de sangre láser',
	},
	{
		code: '42221906',
		label: 'Flujómetro de sangre de resonancia magnética',
	},
	{
		code: '42222000',
		label:
			'Bombas de infusión intravenosa, así como sus analizadores, sensores y accesorios',
	},
	{
		code: '42222001',
		label: 'Bombas de infusión intravenosa de uso general',
	},
	{
		code: '42222002',
		label: 'Bombas de infusión de jeringa intravenosa',
	},
	{
		code: '42222003',
		label: 'Bombas de infusión intravenosa multicanal',
	},
	{
		code: '42222004',
		label: 'Bombas de infusión controlada de analgésicos para pacientes',
	},
	{
		code: '42222005',
		label: 'Partes o accesorios de bombas intravenosas',
	},
	{
		code: '42222006',
		label: 'Analizadores o sensores de bombas de infusión intravenosas',
	},
	{
		code: '42222007',
		label: 'Transductores de bombas de infusión intravenosas',
	},
	{
		code: '42222008',
		label: 'Kits o accesorios de bombas de infusión',
	},
	{
		code: '42222009',
		label: 'Actuador de jeringa para un inyector',
	},
	{
		code: '42222100',
		label:
			'Sistemas de transporte y suspensión de equipo arterial y intravenoso',
	},
	{
		code: '42222101',
		label: 'Colgadores de equipos de línea arterial o intravenosa',
	},
	{
		code: '42222102',
		label:
			'Rieles o colgadores montados para sistemas de infusión intravenosa por gravedad',
	},
	{
		code: '42222103',
		label: 'Postes de infusión intravenosa para sillas de ruedas',
	},
	{
		code: '42222104',
		label: 'Postes o puestos de línea arterial o intravenosa',
	},
	{
		code: '42222200',
		label: 'Sistemas de inyectar y retirar intravenosos sin aguja',
	},
	{
		code: '42222201',
		label:
			'Sets de jeringas de inyección intravenosa sin aguja o cánula de inyección',
	},
	{
		code: '42222202',
		label:
			'Cánulas o adaptadores o decantadores para el retiro de vial o bolsa sin aguja',
	},
	{
		code: '42222300',
		label: 'Productos de transfusión y administración de sangre',
	},
	{
		code: '42222301',
		label: 'Kits de administración de transfusión de sangre',
	},
	{
		code: '42222302',
		label: 'Filtros o accesorios o pantallas para transfusión de sangre',
	},
	{
		code: '42222303',
		label:
			'Sistemas de identificación para administración o transfusión de sangre',
	},
	{
		code: '42222304',
		label: 'Tubos para administración o transfusión de sangre',
	},
	{
		code: '42222305',
		label: 'Pinzas para tubos de administración o transfusión de sangre',
	},
	{
		code: '42222306',
		label:
			'Sistemas de recolección de desechos para administración o transfusión de sangre',
	},
	{
		code: '42222307',
		label: 'Sistemas de calentamiento o transfusión de sangre',
	},
	{
		code: '42222308',
		label: 'Bolsas o contenedores para administración o transfusión de sangre',
	},
	{
		code: '42222309',
		label:
			'Sistemas de conservación para administración o transfusión de sangre',
	},
	{
		code: '42231500',
		label: 'Suministros y equipo de alimentación enteral',
	},
	{
		code: '42231501',
		label: 'Bombas de infusión para alimentación enteral',
	},
	{
		code: '42231502',
		label: 'Sets de administración de alimentación enteral',
	},
	{
		code: '42231503',
		label: 'Sets o bandejas de irrigación para alimentación enteral',
	},
	{
		code: '42231504',
		label: 'Bolsas o contenedores para nutrición enteral',
	},
	{
		code: '42231505',
		label: 'Adaptadores o conectores o extensiones para alimentación enteral',
	},
	{
		code: '42231506',
		label: 'Dispositivos para asegurar tubos naso entéricos',
	},
	{
		code: '42231507',
		label: 'Cepillos para limpiar tubos enterales',
	},
	{
		code: '42231508',
		label: 'Válvulas de fijación de alimentación enteral',
	},
	{
		code: '42231509',
		label: 'Cámaras de pesaje de nutrición enteral',
	},
	{
		code: '42231510',
		label: 'Sets de tubos de bombeo de infusión para alimentación enteral',
	},
	{
		code: '42231600',
		label: 'Accesorios o dispositivos de acceso de jejunostomía y gastronomía',
	},
	{
		code: '42231601',
		label: 'Tubos de gastrostomía para uso general',
	},
	{
		code: '42231602',
		label: 'Tubos para gastrostomía endoscópica percutánea',
	},
	{
		code: '42231603',
		label: 'Tubos para yeyunostomía',
	},
	{
		code: '42231604',
		label: 'Botones de acceso gástrico',
	},
	{
		code: '42231605',
		label: 'Sujetadores de tubos de gastrostomía o yeyunostomía',
	},
	{
		code: '42231606',
		label: 'Kits de descompresión del colon',
	},
	{
		code: '42231608',
		label: 'Sets de catéter y aguja para yeyunostomía',
	},
	{
		code: '42231609',
		label: 'Kits de tubos de alimentación para gastrostomía',
	},
	{
		code: '42231700',
		label: 'Tubos naso-entéricos',
	},
	{
		code: '42231701',
		label: 'Tubos nasogástricos',
	},
	{
		code: '42231702',
		label: 'Tubos nasoyeyunales',
	},
	{
		code: '42231703',
		label: 'Tubos de descompresión gástrica',
	},
	{
		code: '42231704',
		label: 'Tubos naso entéricos no clasificados en otra parte',
	},
	{
		code: '42231705',
		label: 'Filtros nasogástricos',
	},
	{
		code: '42231800',
		label: 'Fórmulas y productos para apoyo nutritivo',

		palabrasSimilares: 'Suplementos alimenticios',
	},
	{
		code: '42231801',
		label: 'Fórmulas de suplementos para adultos de uso general',
	},
	{
		code: '42231802',
		label: 'Fórmulas de suplementos pediátricos',
	},
	{
		code: '42231803',
		label: 'Fórmulas de suplementos específicos para enfermedades de adultos',
	},
	{
		code: '42231804',
		label: 'Fórmulas de suplementos específicos para enfermedades pediátricas',
	},
	{
		code: '42231805',
		label: 'Barras nutricionales o pudín u otros suplementos',
	},
	{
		code: '42231806',
		label: 'Espesantes de comidas o bebidas nutricionales para uso médico',
	},
	{
		code: '42231807',
		label: 'Chupos de teteros',
	},
	{
		code: '42231808',
		label: 'Botellas de alimentación o accesorios',
	},
	{
		code: '42231900',
		label: 'Equipo y suministros de lactancia materna',
	},
	{
		code: '42231901',
		label: 'Bombas de seno o sus accesorios',
	},
	{
		code: '42231902',
		label: 'Conchas o protectores de senos',
	},
	{
		code: '42231903',
		label: 'Kits de bombas de senos',
	},
	{
		code: '42231904',
		label: 'Almohada para lactancia',
	},
	{
		code: '42232000',
		label: 'Trituradores de pastillas, cortapastillas y productos relacionados',
	},
	{
		code: '42232001',
		label: 'Pulverizadores de tabletas o accesorios',
	},
	{
		code: '42232002',
		label: 'Dispensadores o accesorios de pulverizadores de tabletas',
	},
	{
		code: '42232003',
		label: 'Cortadores de tabletas o accesorios',
	},
	{
		code: '42241500',
		label: 'Suministros de vaciado de escayola y tablillas',
	},
	{
		code: '42241501',
		label: 'Zapatos de yeso',
	},
	{
		code: '42241502',
		label: 'Materiales para acolchonar yesos o tablillas',
	},
	{
		code: '42241503',
		label: 'Protectores de yesos o tablillas',
	},
	{
		code: '42241504',
		label: 'Media o revestimientos para yeso o tablillas',
	},
	{
		code: '42241505',
		label: 'Rollos o cintas para enyesar para uso ortopédico',
	},
	{
		code: '42241506',
		label: 'Material de yeso para tablillas para uso ortopédico',
	},
	{
		code: '42241507',
		label: 'Sistemas de tablillas ortopédicas',
	},
	{
		code: '42241509',
		label: 'Componentes de ortesis termoplástica',
	},
	{
		code: '42241510',
		label: 'Kits o materiales para tablillas termoplásticas',
	},
	{
		code: '42241511',
		label: 'Sets de tablillas de tracción',
	},
	{
		code: '42241512',
		label: 'Materiales de unión de yesos o tablillas',
	},
	{
		code: '42241513',
		label:
			'Estuches para transportar y almacenar tablillas o tablillas pre cortadas o sistemas de tablillas',
	},
	{
		code: '42241514',
		label: 'Rollos de instrumentos para sets o accesorios de tablillas',
	},
	{
		code: '42241515',
		label: 'Estuches para tablillas o accesorios',
	},
	{
		code: '42241516',
		label: 'Marcador para material de entablillado',
	},
	{
		code: '42241517',
		label: 'Esparcidor de yeso',
	},
	{
		code: '42241600',
		label: 'Equipo y piezas y accesorios de vaciado de escayola',
	},
	{
		code: '42241601',
		label: 'Carritos para yeso o tablillas',
	},
	{
		code: '42241602',
		label: 'Cortadores o sierras para yeso',
	},
	{
		code: '42241603',
		label: 'Sistemas de remoción de yeso',
	},
	{
		code: '42241604',
		label: 'Vacíos de yeso',
	},
	{
		code: '42241606',
		label: 'Bandejas de impresión de yeso',
	},
	{
		code: '42241607',
		label: 'Puestos de yeso',
	},
	{
		code: '42241700',
		label: 'Productos blandos ortopédicos para la extremidad inferior',
	},
	{
		code: '42241701',
		label: 'Fieltros de recubrimiento ortopédico para tobillo o pie',
	},
	{
		code: '42241702',
		label: 'Fieltros de recubrimiento ortopédico para cadera',
	},
	{
		code: '42241703',
		label:
			'Soportes de abrazaderas de rodilla o de abrazaderas de rodilla articuladas',
	},
	{
		code: '42241704',
		label: 'Inmovilizadores o vendas artroscópicas para rodilla',
	},
	{
		code: '42241705',
		label: 'Fieltros de recubrimiento ortopédico para pierna o accesorios',
	},
	{
		code: '42241706',
		label: 'Productos ortóticos o para el cuidado de los pies',
	},
	{
		code: '42241707',
		label: 'Abrazaderas para caminar',
	},
	{
		code: '42241708',
		label: 'Almohadas para fractura del fémur',
	},
	{
		code: '42241709',
		label: 'Zapatos post operatorios o accesorios',
	},
	{
		code: '42241800',
		label: 'Productos blando ortopédicos para el torso y extremidad superior',
	},
	{
		code: '42241801',
		label: 'Fieltros de recubrimiento ortopédico para el brazo',
	},
	{
		code: '42241802',
		label:
			'Fieltros de recubrimiento ortopédico para la espalda o lumbares o para el sacro',
	},
	{
		code: '42241803',
		label: 'Collares cervicales o abrazaderas para el cuello',
	},
	{
		code: '42241804',
		label: 'Fieltros de recubrimiento ortopédico para la clavícula',
	},
	{
		code: '42241805',
		label: 'Fieltros de recubrimiento ortopédico para el codo',
	},
	{
		code: '42241806',
		label:
			'Fieltros de recubrimiento ortopédico para el antebrazo o la muñeca o el pulgar',
	},
	{
		code: '42241807',
		label: 'Fieltros de recubrimiento ortopédico para la mano o el dedo',
	},
	{
		code: '42241808',
		label:
			'Fieltros de recubrimiento ortopédico para las costillas o el abdomen',
	},
	{
		code: '42241809',
		label: 'Fieltros de recubrimiento ortopédico para los hombros',
	},
	{
		code: '42241811',
		label: 'Faja para hernias',
	},
	{
		code: '42241900',
		label: 'Suministros de tablillas dinámicas y batangas',
	},
	{
		code: '42241901',
		label: 'Kits de tablillas estabilizadoras',
	},
	{
		code: '42241902',
		label: 'Partes o accesorios de tablillas estabilizadoras',
	},
	{
		code: '42242000',
		label: 'Dispositivos, accesorios y suministros protésicos',
	},
	{
		code: '42242001',
		label: 'Dispositivos prostéticos para las extremidades inferiores',
	},
	{
		code: '42242002',
		label: 'Dispositivos prostéticos para las extremidades superiores',
	},
	{
		code: '42242003',
		label: 'Dispositivos o accesorios de sujeción prostética',
	},
	{
		code: '42242004',
		label: 'Media o forro prostético de muñón',
	},
	{
		code: '42242100',
		label: 'Suministros y accesorios de tracción ortopédica',
	},
	{
		code: '42242101',
		label: 'Suministros de tracción de brazo',
	},
	{
		code: '42242102',
		label: 'Suministros de tracción de mano o dedos',
	},
	{
		code: '42242103',
		label: 'Suministros de tracción de cabeza o cuello',
	},
	{
		code: '42242104',
		label: 'Suministros de tracción de piernas',
	},
	{
		code: '42242105',
		label: 'Carritos móviles de tracción',
	},
	{
		code: '42242106',
		label: 'Suministros de tracción de pelvis o espalda',
	},
	{
		code: '42242107',
		label: 'Suministros de terapia de tracción de perno',
	},
	{
		code: '42242108',
		label: 'Hardware o pesas de tracción ortopédica',
	},
	{
		code: '42242109',
		label: 'Fieltros de recubrimiento de tracción ortopédica para uso general',
	},
	{
		code: '42242300',
		label: 'Equipo y suministros ortopédicos',
	},
	{
		code: '42242301',
		label: 'Bombas de cicatriz ortopédica',
	},
	{
		code: '42242302',
		label: 'Aplicaciones ortopédicas de miembro superior',
	},
	{
		code: '42251500',
		label:
			'Productos de terapia y evaluación sensoria y perceptual y dexteridad y cognitiva',
	},
	{
		code: '42251501',
		label: 'Productos de enseñanza para vestirse',
	},
	{
		code: '42251502',
		label:
			'Productos para evaluación o pruebas cognitivas o de destreza o de percepción o sensoriales',
	},
	{
		code: '42251503',
		label: 'Juegos terapéuticos',
	},
	{
		code: '42251504',
		label: 'Tableros perforados o tableros de actividades de uso terapéutico',
	},
	{
		code: '42251505',
		label: 'Rompecabezas de uso terapéutico',
	},
	{
		code: '42251506',
		label: 'Cajas de decoración de uso terapéutico',
	},
	{
		code: '42251600',
		label: 'Equipo y dispositivos de ejercicio de rehabilitación',
	},
	{
		code: '42251601',
		label:
			'Vigas de balance o de soporte o mecedoras para rehabilitación o terapia',
	},
	{
		code: '42251602',
		label: 'Dispositivos para escalar para rehabilitación o terapia',
	},
	{
		code: '42251603',
		label: 'Dispositivos o accesorios de moción pasiva continua cpm',
	},
	{
		code: '42251604',
		label: 'Patines de ejercicio de extremidades para rehabilitación o terapia',
	},
	{
		code: '42251605',
		label: 'Pedales de ejercicio para rehabilitación o terapia',
	},
	{
		code: '42251606',
		label: 'Tablas para ejercicios sin gravedad para rehabilitación o terapia',
	},
	{
		code: '42251607',
		label: 'Poleas o accesorios para rehabilitación o terapia',
	},
	{
		code: '42251608',
		label:
			'Bandas de ejercicio o masilla o tubos o accesorios para rehabilitación o terapia',
	},
	{
		code: '42251609',
		label: 'Patinetas o patines de ocho figuras para rehabilitación o terapia',
	},
	{
		code: '42251610',
		label: 'Pelotas o accesorios terapéuticos',
	},
	{
		code: '42251611',
		label:
			'Dispositivos de movimiento vestibular para rehabilitación o terapia',
	},
	{
		code: '42251612',
		label: 'Pesas o sets o accesorios para rehabilitación o terapia',
	},
	{
		code: '42251613',
		label: 'Bandas caminadoras ejercitadoras para rehabilitación o terapia',
	},
	{
		code: '42251614',
		label:
			'Bolsas de arena o sets de bolsas de arena para rehabilitación o terapia',
	},
	{
		code: '42251615',
		label: 'Cinturones de pesas o kits para rehabilitación o terapia',
	},
	{
		code: '42251616',
		label: 'Vibradores eléctricos para rehabilitación o terapia',
	},
	{
		code: '42251617',
		label: 'Asientos acolchonados o accesorios terapéuticos',
	},
	{
		code: '42251618',
		label: 'Ejercitadoras de muñecas para rehabilitación o terapia',
	},
	{
		code: '42251619',
		label: 'Arcilla cerámica terapéutica o accesorios',
	},
	{
		code: '42251620',
		label: 'Esterillas o plataformas para rehabilitación o terapia',
	},
	{
		code: '42251621',
		label: 'Ejercitadoras de bota para rehabilitación o terapia',
	},
	{
		code: '42251622',
		label: 'Ejercitadoras de pulmón para rehabilitación o terapia',
	},
	{
		code: '42251623',
		label: 'Aparatos o suministros para terapia ultrasónica',
	},
	{
		code: '42251624',
		label: 'Máquinas de pesas para rehabilitación o terapia',
	},
	{
		code: '42251625',
		label: 'Equipos de ejercicio de mandíbulas',
	},
	{
		code: '42251700',
		label: 'Productos para entrenamiento del modo de andar',
	},
	{
		code: '42251701',
		label: 'Cinturones de manera de caminar para rehabilitación o terapia',
	},
	{
		code: '42251702',
		label: 'Rampas de entrenamiento para rehabilitación o terapia',
	},
	{
		code: '42251703',
		label: 'Escaleras de entrenamiento para rehabilitación o terapia',
	},
	{
		code: '42251704',
		label: 'Barras de manera de caminar para rehabilitación o terapia',
	},
	{
		code: '42251705',
		label: 'Barras paralelas para rehabilitación o terapia',
	},
	{
		code: '42251706',
		label: 'Caminadoras o bicicletas o ejercitadoras de manera de caminar',
	},
	{
		code: '42251800',
		label: 'Equipo para hacer dura el trabajo para rehabilitación o terapia',
	},
	{
		code: '42251801',
		label: 'Tableros de salto o accesorios para rehabilitación o terapia',
	},
	{
		code: '42251802',
		label: 'Cajas de levantar o accesorios para rehabilitación o terapia',
	},
	{
		code: '42251803',
		label: 'Árboles de tubos o accesorios para rehabilitación o terapia',
	},
	{
		code: '42251804',
		label:
			'Carros de halar o empujar o accesorios para rehabilitación o terapia',
	},
	{
		code: '42251805',
		label:
			'Mesas o estaciones de trabajo o accesorios para rehabilitación o terapia',
	},
	{
		code: '42261500',
		label: 'Suministros y instrumentos para disección de patología',
	},
	{
		code: '42261501',
		label: 'Tijeras para autopsias',
	},
	{
		code: '42261502',
		label: 'Fórceps de disección de uso general para autopsias',
	},
	{
		code: '42261503',
		label: 'Sondas de bala para autopsias',
	},
	{
		code: '42261504',
		label: 'Jaladores de hilo o aguja para autopsias',
	},
	{
		code: '42261505',
		label: 'Cuchillos o cuchillas para autopsias',
	},
	{
		code: '42261506',
		label: 'Cinceles u osteotomos para autopsias',
	},
	{
		code: '42261507',
		label: 'Hilo post mortem',
	},
	{
		code: '42261508',
		label: 'Agujas post mortem',
	},
	{
		code: '42261509',
		label: 'Kits de disección para autopsias',
	},
	{
		code: '42261510',
		label: 'Ganchos de incisión post mortem',
	},
	{
		code: '42261511',
		label: 'Directores de vena para autopsias',
	},
	{
		code: '42261512',
		label: 'Sierras para autopsias',
	},
	{
		code: '42261513',
		label: 'Cuchillas de sierra o accesorios para autopsias',
	},
	{
		code: '42261514',
		label: 'Tablas o almohadillas de disección',
	},
	{
		code: '42261515',
		label: 'Estuches para instrumentos o accesorios quirúrgicos post mortem',
	},
	{
		code: '42261516',
		label:
			'Rollos de instrumentos para instrumentos o accesorios quirúrgicos post mortem',
	},
	{
		code: '42261600',
		label: 'Suministros y equipo de autopsia',
	},
	{
		code: '42261601',
		label: 'Recolectores de polvo de huesos',
	},
	{
		code: '42261602',
		label: 'Bolsa para cadáver de uso médico',
	},
	{
		code: '42261604',
		label: 'Apoya cabezas para autopsias',
	},
	{
		code: '42261605',
		label: 'Tablas de cuerpo para autopsias',
	},
	{
		code: '42261606',
		label: 'Balanzas colgantes para autopsias',
	},
	{
		code: '42261607',
		label: 'Bolsas o contenedores para especímenes para autopsias',
	},
	{
		code: '42261608',
		label: 'Kits de enfermedades infecciosas para autopsias',
	},
	{
		code: '42261609',
		label: 'Cintas o brazaletes de identificación post mortem',
	},
	{
		code: '42261610',
		label:
			'Aspiradoras o tubos de vacío para recolección de fluidos para autopsias',
	},
	{
		code: '42261611',
		label: 'Termómetros rectales post mortem',
	},
	{
		code: '42261612',
		label: 'Dispositivos para enderezar dedos post mortem',
	},
	{
		code: '42261613',
		label: 'Kits de construcción de tejidos de cadáveres',
	},
	{
		code: '42261700',
		label: 'Muebles de autopsia',
	},
	{
		code: '42261701',
		label: 'Estaciones de trabajo para autopsia macroscópica o accesorios',
	},
	{
		code: '42261702',
		label: 'Lavaderos o accesorios para autopsias',
	},
	{
		code: '42261703',
		label: 'Tablas o accesorios para autopsias',
	},
	{
		code: '42261704',
		label: 'Tablas para necropsia o accesorios',
	},
	{
		code: '42261705',
		label: 'Tablas o accesorios para disección de animales post mortem',
	},
	{
		code: '42261706',
		label: 'Estaciones de trabajo para embalsamar o accesorios',
	},
	{
		code: '42261707',
		label: 'Estaciones de trabajo para autopsias de drenaje o accesorios',
	},
	{
		code: '42261800',
		label: 'Equipo y suministros de transporte y almacenaje del cadáver',
	},
	{
		code: '42261801',
		label: 'Gabinetes de almacenamiento de cadáveres',
	},
	{
		code: '42261802',
		label: 'Transportadores de cadáveres',
	},
	{
		code: '42261803',
		label: 'Poleas de elevación de tijeras para cadáveres',
	},
	{
		code: '42261804',
		label: 'Gabinetes refrigeradores para las morgues',
	},
	{
		code: '42261805',
		label: 'Cuartos refrigeradores para la morgue',
	},
	{
		code: '42261806',
		label: 'Congeladores para morgues',
	},
	{
		code: '42261807',
		label: 'Carritos de autopsia',
	},
	{
		code: '42261808',
		label: 'Bandejas para cadáveres',
	},
	{
		code: '42261809',
		label: 'Dispositivos de elevación o transferencia de cadáveres',
	},
	{
		code: '42261810',
		label: 'Contenedores para transporte de cuerpos',
	},
	{
		code: '42261900',
		label: 'Equipo y suministros forenses clínicos',
	},
	{
		code: '42261901',
		label: 'Materiales de huellas dactilares o impresión post mortem',
	},
	{
		code: '42261902',
		label: 'Máscaras anti putrefacción',
	},
	{
		code: '42261903',
		label: 'Kits o suministros de detección de sangre post mortem',
	},
	{
		code: '42261904',
		label: 'Kits de recolección de evidencia biológica',
	},
	{
		code: '42262000',
		label: 'Equipo y suministros de embalsamar',
	},
	{
		code: '42262001',
		label: 'Inyectores de cavidad para embalsamar',
	},
	{
		code: '42262002',
		label: 'Tubos de drenaje de venas para embalsamar',
	},
	{
		code: '42262003',
		label: 'Fluidos para embalsamar o tratamientos químicos',
	},
	{
		code: '42262004',
		label: 'Tubos de inyección para embalsamar',
	},
	{
		code: '42262005',
		label: 'Lavaderos o accesorios para embalsamar',
	},
	{
		code: '42262006',
		label: 'Kits para embalsamar',
	},
	{
		code: '42262007',
		label: 'Agujas inyectoras para embalsamar',
	},
	{
		code: '42262008',
		label: 'Protectores de ojos',
	},
	{
		code: '42262100',
		label: 'Equipo y suministros funerarios',
	},
	{
		code: '42262101',
		label: 'Trajes mortuorios',
	},
	{
		code: '42262102',
		label: 'Paquetes mortuorios',
	},
	{
		code: '42262103',
		label: 'Lienzos mortuorios',
	},
	{
		code: '42262104',
		label: 'Aspiradoras mortuorias',
	},
	{
		code: '42262105',
		label: 'Compuestos endurecedores mortuorios',
	},
	{
		code: '42271500',
		label: 'Productos para el control respiratorio',
	},
	{
		code: '42271501',
		label: 'Monitores de apnea o accesorios',
	},
	{
		code: '42271502',
		label: 'Monitores de gas arterial o accesorios',
	},
	{
		code: '42271503',
		label: 'Monitores de óxido de carbono exhalado o suministros',
	},
	{
		code: '42271504',
		label: 'Estetoscopios esofágicos',
	},
	{
		code: '42271505',
		label: 'Kits de monitoreo respiratorio o sus accesorios',
	},
	{
		code: '42271506',
		label: 'Monitores de oxígeno o suministros',
	},
	{
		code: '42271600',
		label:
			'Productos de comprobación y tratamiento de las funciones pulmonares',
	},
	{
		code: '42271601',
		label: 'Pletismógrafos de cuerpo',
	},
	{
		code: '42271602',
		label: 'Espirómetros o sus accesorios o suministros',
	},
	{
		code: '42271603',
		label: 'Pantallas de función pulmonar para junto a la cama',
	},
	{
		code: '42271604',
		label: 'Flujómetros de pico',
	},
	{
		code: '42271605',
		label: 'Calculadoras de función pulmonar',
	},
	{
		code: '42271606',
		label: 'Dispositivos de calibración pulmonar',
	},
	{
		code: '42271607',
		label: 'Tubos de función pulmonar o accesorios',
	},
	{
		code: '42271608',
		label: 'Productos de prueba de estrés pulmonar',
	},
	{
		code: '42271609',
		label: 'Monitores de estudio de sueño o accesorios',
	},
	{
		code: '42271610',
		label: 'Monitores transcutáneos o productos relacionados',
	},
	{
		code: '42271611',
		label: 'Monitores de ventilación pulmonar',
	},
	{
		code: '42271612',
		label: 'Analizadores o monitores de gas pulmonar',
	},
	{
		code: '42271613',
		label: 'Monitores de presión pulmonar',
	},
	{
		code: '42271614',
		label: 'Monitores de temperatura respiratoria',
	},
	{
		code: '42271615',
		label: 'Neumotacos',
	},
	{
		code: '42271616',
		label: 'Filtros de monitoreo de función pulmonar',
	},
	{
		code: '42271617',
		label: 'Percusores de pecho',
	},
	{
		code: '42271618',
		label: 'Estilógrafos grabadores para espirómetros',
	},
	{
		code: '42271619',
		label: 'Entrenador de músculos de inhalación',
	},
	{
		code: '42271620',
		label: 'Monitor de agua en los pulmones',
	},
	{
		code: '42271621',
		label: 'Dispositivo de terapia de presión de exhalación positiva pep',
	},
	{
		code: '42271622',
		label: 'Chaleco de despeje de vías aéreas',
	},
	{
		code: '42271700',
		label: 'Sistemas y dispositivos de terapia de entrega de oxígeno',
	},
	{
		code: '42271701',
		label: 'Cilindros de gas o dispositivos relacionados para uso médico',
	},
	{
		code: '42271702',
		label: 'Concentradores de oxígeno',
	},
	{
		code: '42271703',
		label: 'Mezcladores de aire de oxígeno',
	},
	{
		code: '42271704',
		label: 'Temporizadores de oxígeno',
	},
	{
		code: '42271705',
		label: 'Conectores o adaptadores de suministro de oxígeno',
	},
	{
		code: '42271706',
		label: 'Compresores para terapia respiratoria',
	},
	{
		code: '42271707',
		label: 'Sensores de flujo o reguladores o componentes',
	},
	{
		code: '42271708',
		label: 'Máscaras de oxígeno o partes para uso médico',
	},
	{
		code: '42271709',
		label: 'Cánulas nasales para uso médico',
	},
	{
		code: '42271710',
		label: 'Catéteres nasales o kits de cateterización para uso médico',
	},
	{
		code: '42271711',
		label: 'Capuchas para la cabeza para uso médico',
	},
	{
		code: '42271712',
		label: 'Carpas de aerosol para uso médico',
	},
	{
		code: '42271713',
		label: 'Cámaras hiperbáricas para uso médico',
	},
	{
		code: '42271714',
		label: 'Incubadoras para uso médico',
	},
	{
		code: '42271715',
		label: 'Tubos o conectores de oxígeno para uso médico',
	},
	{
		code: '42271716',
		label: 'Estuches para insufladores nasales',
	},
	{
		code: '42271717',
		label: 'Inhaladores o sets',
	},
	{
		code: '42271718',
		label:
			'Accesorios para productos de sistemas de entrega de oxígeno para terapia o sus suministros',
	},
	{
		code: '42271719',
		label: 'Insuflador de oxígeno o sus accesorios',
	},
	{
		code: '42271720',
		label: 'Convertidores de oxígeno líquido',
	},
	{
		code: '42271721',
		label: 'Filtros de concentradores de oxígeno',
	},
	{
		code: '42271722',
		label: 'Computador de toma de oxígeno',
	},
	{
		code: '42271800',
		label: 'Productos de terapia aerosol y humedad respiratoria',
	},
	{
		code: '42271801',
		label: 'Humidificadores o vaporizadores respiratorios',
	},
	{
		code: '42271802',
		label: 'Nebulizadores o accesorios',
	},
	{
		code: '42271803',
		label: 'Sets de transferencia para terapia respiratoria',
	},
	{
		code: '42271900',
		label: 'Productos para la gestión de la vía aérea',
	},
	{
		code: '42271901',
		label: 'Vías aéreas faríngeas',
	},
	{
		code: '42271902',
		label: 'Tubos esofágicos',
	},
	{
		code: '42271903',
		label: 'Tubos endotraqueales',
	},
	{
		code: '42271904',
		label: 'Tubos de traqueotomía',
	},
	{
		code: '42271905',
		label: 'Tubos endobronquiales',
	},
	{
		code: '42271906',
		label: 'Kits de reparación de tubos endotraqueales o de traqueotomía',
	},
	{
		code: '42271907',
		label: 'Productos de aspiradores respiratorios o accesorios',
	},
	{
		code: '42271908',
		label: 'Sujetadores de vías aéreas artificiales',
	},
	{
		code: '42271909',
		label: 'Accesorios de vías aéreas artificiales',
	},
	{
		code: '42271910',
		label: 'Sets endotraqueales o de traqueotomía',
	},
	{
		code: '42271911',
		label: 'Kits de manómetros respiratorios',
	},
	{
		code: '42271912',
		label: 'Tubos nasofaríngeos',
	},
	{
		code: '42271913',
		label: 'Vías aéreas faríngeas o kits de vías aéreas',
	},
	{
		code: '42271914',
		label: 'Mordazas de presión de vías aéreas',
	},
	{
		code: '42271915',
		label: 'Accesorios de traqueotomía',
	},
	{
		code: '42271916',
		label: 'Faringómetro o accesorios',
	},
	{
		code: '42272000',
		label: 'Suministros de intubación',
	},
	{
		code: '42272001',
		label: 'Laringoscopios o accesorios',
	},
	{
		code: '42272002',
		label: 'Aerosoles laríngeos',
	},
	{
		code: '42272003',
		label: 'Bloques de mordida',
	},
	{
		code: '42272004',
		label: 'Estiletes de intubación',
	},
	{
		code: '42272005',
		label: 'Fórceps de intubación',
	},
	{
		code: '42272006',
		label: 'Introductores',
	},
	{
		code: '42272007',
		label: 'Herramientas de doblado',
	},
	{
		code: '42272008',
		label: 'Sondas o guías de intubación',
	},
	{
		code: '42272009',
		label: 'Detectores de dióxido de carbono para pacientes',
	},
	{
		code: '42272011',
		label: 'Catéteres de succión o sus accesorios',
	},
	{
		code: '42272016',
		label: 'Quillas laríngeas o accesorios',
	},
	{
		code: '42272017',
		label: 'Componentes o accesorios de intubación',
	},
	{
		code: '42272100',
		label: 'Ventiladores de presión mecánica negativa',
	},
	{
		code: '42272101',
		label: 'Pulmón de hierro',
	},
	{
		code: '42272102',
		label: 'Productos de coraza de pecho',
	},
	{
		code: '42272200',
		label: 'Ventiladores y accesorios de presión mecánica positiva',
	},
	{
		code: '42272201',
		label: 'Máquinas respiradoras de presión positiva intermitente ippb',

		palabrasSimilares:
			'Respiradores para SARS-CoV-2, Respiradores para COVID, Respiradores para COVID-19',
	},
	{
		code: '42272202',
		label: 'Máquinas de presión de aire positivo continuo no invasivo',
	},
	{
		code: '42272203',
		label: 'Máquinas de doble nivel no invasivas',
	},
	{
		code: '42272204',
		label: 'Ventiladores de transporte',
	},
	{
		code: '42272205',
		label: 'Ventiladores para cuidados intensivos de adultos o pediátricos',
	},
	{
		code: '42272206',
		label: 'Ventiladores para cuidado intensivo de bebés',
	},
	{
		code: '42272207',
		label: 'Ventiladores de alta frecuencia',
	},
	{
		code: '42272208',
		label: 'Ventiladores de cuidado en casa',
	},
	{
		code: '42272209',
		label: 'Circuitos de ventiladores o de respiración',
	},
	{
		code: '42272210',
		label: 'Bolsas de circuito de respiración',
	},
	{
		code: '42272211',
		label: 'Productos de hiperventilación',
	},
	{
		code: '42272212',
		label: 'Válvulas de presión de exhalación y positiva peep',
	},
	{
		code: '42272213',
		label:
			'Máscaras o correas de presión de vías aéreas positivas continuas cpap',
	},
	{
		code: '42272214',
		label: 'Conectores o adaptadores o válvulas de circuitos',
	},
	{
		code: '42272215',
		label: 'Suministros de chequeo de ventiladores',
	},
	{
		code: '42272216',
		label: 'Termómetros de ventiladores',
	},
	{
		code: '42272217',
		label: 'Trampas de agua para ventiladores',
	},
	{
		code: '42272218',
		label: 'Puertos o líneas de muestreo de gas para ventiladores',
	},
	{
		code: '42272219',
		label: 'Intercambiadores o filtros de calor o humedad para ventiladores',
	},
	{
		code: '42272220',
		label: 'Accesorios para ventiladores',
	},
	{
		code: '42272221',
		label: 'Productos de humidificación para ventiladores',
	},
	{
		code: '42272222',
		label: 'Productos de retiro del tubo para ventiladores',
	},
	{
		code: '42272223',
		label: 'Accesorios o suministros para respiradores',
	},
	{
		code: '42272224',
		label: 'Kits de circuitos para ventiladores',
	},
	{
		code: '42272225',
		label:
			'Accesorios de presión para vías aéreas positivas de doble nivel bi pap',
	},
	{
		code: '42272300',
		label: 'Suministros de resucitadores',
	},
	{
		code: '42272301',
		label: 'Resucitadores manuales',
	},
	{
		code: '42272302',
		label: 'Resucitadores neumáticos',
	},
	{
		code: '42272303',
		label: 'Máscaras o accesorios de resucitación',
	},
	{
		code: '42272304',
		label: 'Componentes o accesorios de resucitación',
	},
	{
		code: '42272305',
		label: 'Conectores de resucitación',
	},
	{
		code: '42272306',
		label: 'Kits de resucitación',
	},
	{
		code: '42272307',
		label: 'Estuches para aparatos o accesorios de resucitación',
	},
	{
		code: '42272500',
		label: 'Aparatos de anestesia, y accesorios y suministros',
	},
	{
		code: '42272501',
		label: 'Aparatos de gas de anestesia',
	},
	{
		code: '42272502',
		label: 'Unidades de absorción para aparatos de gas de anestesia',
	},
	{
		code: '42272503',
		label: 'Inhaladores de anestesia o unidades de inhalación o accesorios',
	},
	{
		code: '42272505',
		label:
			'Tubos para aparatos de gas de anestesia o ensamblajes de tubos o ajustes de tubos o accesorios',
	},
	{
		code: '42272506',
		label: 'Filtros de pantalla para aparatos de anestesia',
	},
	{
		code: '42272507',
		label: 'Control de temperatura para aparatos de anestesia',
	},
	{
		code: '42272508',
		label: 'Bomba intratecal',
	},
	{
		code: '42272509',
		label: 'Calibradores para máquina de anestesia o accesorios',
	},
	{
		code: '42272510',
		label: 'Kits o accesorios para actualización de máquina de anestesia',
	},
	{
		code: '42281500',
		label: 'Equipo y accesorios de esterilizadores y de autoclave',
	},
	{
		code: '42281501',
		label: 'Esterilizadores químicos o de gas',
	},
	{
		code: '42281502',
		label: 'Esterilizadores de aire seco o de aire caliente',
	},
	{
		code: '42281503',
		label: 'Esterilizadores de filtro',
	},
	{
		code: '42281504',
		label: 'Esterilizadores de bolas de vidrio',
	},
	{
		code: '42281505',
		label:
			'Manijas de elevación para contenedores o bandejas de esterilización',
	},
	{
		code: '42281506',
		label: 'Dispositivos o accesorios eléctricos para limpieza de instrumentos',
	},
	{
		code: '42281507',
		label: 'Esterilizadores de radiación',
	},
	{
		code: '42281508',
		label: 'Autoclaves o esterilizadores de vapor',
	},
	{
		code: '42281509',
		label: 'Contenedores o bandejas de esterilización',
	},
	{
		code: '42281510',
		label:
			'Ganchos o estantes o sujetadores para instrumentos de esterilización',
	},
	{
		code: '42281511',
		label: 'Lámparas de esterilización',
	},
	{
		code: '42281512',
		label: 'Tapas de esterilización',
	},
	{
		code: '42281513',
		label: 'Placas de identificación de esterilización',
	},
	{
		code: '42281514',
		label: 'Calentadores sanitarios o accesorios',
	},
	{
		code: '42281515',
		label: 'Gabinetes de esterilización',
	},
	{
		code: '42281516',
		label: 'Filtros de esterilización',
	},
	{
		code: '42281517',
		label: 'Sistemas de recuperación de agua de esterilización',
	},
	{
		code: '42281518',
		label: 'Barras para cánulas de esterilización',
	},
	{
		code: '42281519',
		label: 'Esterilizadores de agujas',
	},
	{
		code: '42281521',
		label: 'Sets de esterilización',
	},
	{
		code: '42281522',
		label:
			'Instrumentos de esterilización o insertos de estuches de esterilización',
	},
	{
		code: '42281523',
		label: 'Mangas de filtro de esterilización',
	},
	{
		code: '42281524',
		label: 'Adaptadores o ensamblajes de adaptadores de esterilización',
	},
	{
		code: '42281525',
		label: 'Aireador de gas o químico o de radiación de esterilización',
	},
	{
		code: '42281526',
		label: 'Prueba de indicador biológico para incubadoras',
	},
	{
		code: '42281527',
		label: 'Concentrado o casete o cartucho de esterilizante de esterilización',
	},
	{
		code: '42281528',
		label: 'Accesorios para esterilizador químico o de gas',
	},
	{
		code: '42281529',
		label: 'Accesorios para esterilizador de aire seco o de aire caliente',
	},
	{
		code: '42281530',
		label: 'Accesorios para esterilizador o autoclave de vapor',
	},
	{
		code: '42281531',
		label: 'Esterilizador ultravioleta',
	},
	{
		code: '42281532',
		label: 'Esterilizador de plasma de gas',
	},
	{
		code: '42281600',
		label: 'Soluciones de desinfectantes y esterilización en frío',
	},
	{
		code: '42281603',
		label: 'Desinfectante o esterilizador de instrumentos',
	},
	{
		code: '42281604',
		label: 'Desinfectantes de superficies para uso médico',
	},
	{
		code: '42281605',
		label: 'Compuestos anti fijación para uso médico',
	},
	{
		code: '42281606',
		label: 'Fumigadores de gas para uso médico',
	},
	{
		code: '42281700',
		label: 'Soluciones y equipo de limpieza pre- esterilización',
	},
	{
		code: '42281701',
		label: 'Limpiadores de cámara para autoclaves o esterilizadores',
	},
	{
		code: '42281702',
		label: 'Baldes de remojo desinfectantes',

		palabrasSimilares: 'Cubetas de remojo desinfectantes',
	},
	{
		code: '42281703',
		label: 'Kits de cuidado de instrumentos',
	},
	{
		code: '42281704',
		label: 'Limpiadores o detergentes para instrumentos',
	},
	{
		code: '42281705',
		label:
			'Equipo de lavado desinfectante para equipos e instrumentos de uso médico',
	},
	{
		code: '42281706',
		label: 'Lubricantes o leche para instrumentos',
	},
	{
		code: '42281707',
		label: 'Almohadillas para remover manchas de los instrumentos',
	},
	{
		code: '42281708',
		label: 'Limpiadores de carros de esterilización',
	},
	{
		code: '42281709',
		label: 'Cepillos de limpieza de esterilización',
	},
	{
		code: '42281710',
		label: 'Desodorantes de esterilización',
	},
	{
		code: '42281711',
		label: 'Desincrustadores de esterilización',
	},
	{
		code: '42281712',
		label: 'Equipo de limpieza ultrasónica',
	},
	{
		code: '42281713',
		label: 'Baldes de escurrido de esterilización',

		palabrasSimilares: 'Cubetas de escurrido para esterilización',
	},
	{
		code: '42281800',
		label: 'Controles y indicadores de esterilización',
	},
	{
		code: '42281801',
		label: 'Tiras de prueba de desinfección',
	},
	{
		code: '42281802',
		label: 'Etiquetas de esterilización',
	},
	{
		code: '42281803',
		label: 'Kits biológicos de esterilización',
	},
	{
		code: '42281804',
		label: 'Controles de esterilización',
	},
	{
		code: '42281805',
		label: 'Registros indicadores de esterilización',
	},
	{
		code: '42281806',
		label: 'Tiras indicadoras de esterilización',
	},
	{
		code: '42281807',
		label: 'Cintas indicadoras de esterilización',
	},
	{
		code: '42281808',
		label: 'Papeles u hojas de esterilización',
	},
	{
		code: '42281809',
		label: 'Sobres de almacenamiento de registros de esterilización',
	},
	{
		code: '42281810',
		label: 'Paquetes de prueba y accesorios de esterilización',
	},
	{
		code: '42281811',
		label: 'Candado para contenedor de esterilización',
	},
	{
		code: '42281900',
		label: 'Suministros de empaquetar y envoltura de esterilización',
	},
	{
		code: '42281901',
		label: 'Sujetadores o carritos para envoltorios o sobres de esterilización',
	},
	{
		code: '42281902',
		label: 'Fundas o sobre fundas de esterilización',
	},
	{
		code: '42281903',
		label: 'Cubiertas contra el polvo de esterilización',
	},
	{
		code: '42281904',
		label: 'Bolsas de esterilización',
	},
	{
		code: '42281905',
		label: 'Selladores en caliente de esterilización',
	},
	{
		code: '42281906',
		label: 'Bandas de instrumentos de esterilización',
	},
	{
		code: '42281907',
		label: 'Protectores de instrumentos de esterilización',
	},
	{
		code: '42281908',
		label: 'Recubrimientos de bandejas de instrumentos de esterilización',
	},
	{
		code: '42281909',
		label: 'Pistolas o cintas o esferos etiquetadores de esterilización',
	},
	{
		code: '42281912',
		label: 'Toallas de esterilización',
	},
	{
		code: '42281913',
		label: 'Tunos de esterilización',
	},
	{
		code: '42281914',
		label: 'Contenedores desechables de esterilización',
	},
	{
		code: '42281915',
		label: 'Rollos de esterilización',
	},
	{
		code: '42281916',
		label: 'Talegos de esterilización',
	},
	{
		code: '42291500',
		label:
			'Instrumentos quirúrgicos y productos relacionados para biopsia de los huesos',
	},
	{
		code: '42291501',
		label:
			'Molinos para biopsia de hueso para uso quirúrgico o productos relacionados',
	},
	{
		code: '42291502',
		label: 'Trepanadores para biopsia de hueso para uso quirúrgico',
	},
	{
		code: '42291600',
		label:
			'Instrumentos quirúrgicos de cortar y lazos y productos relacionados',
	},
	{
		code: '42291601',
		label:
			'Escalpelos o cuchillos o manijas de cuchillos láser para uso quirúrgico',
	},
	{
		code: '42291602',
		label:
			'Tornillos o cables o alfileres o instrumentos para cortar alambre para uso quirúrgico',
	},
	{
		code: '42291603',
		label: 'Fórceps para cortar hueso para uso quirúrgico',
	},
	{
		code: '42291604',
		label:
			'Sierras de mano o sierras de alambre o manijas para sierras para huesos para uso quirúrgico',
	},
	{
		code: '42291605',
		label: 'Brocas para uso quirúrgico',
	},
	{
		code: '42291606',
		label: 'Cinceles o perforadoras para uso quirúrgico',
	},
	{
		code: '42291607',
		label: 'Curetas o lanzaderas para uso quirúrgico',
	},
	{
		code: '42291608',
		label: 'Bloques o tablas o plataformas de cortado para uso quirúrgico',
	},
	{
		code: '42291609',
		label: 'Pinzas para uso quirúrgico',
	},
	{
		code: '42291610',
		label: 'Planos para uso quirúrgico',
	},
	{
		code: '42291611',
		label: 'Raspadores para uso quirúrgico',
	},
	{
		code: '42291612',
		label: 'Tenazas para uso quirúrgico',
	},
	{
		code: '42291613',
		label:
			'Escalpelos o cuchillos o cuchillas o trepanadores o accesorios para uso quirúrgico',
	},
	{
		code: '42291614',
		label: 'Tijeras para uso quirúrgico',
	},
	{
		code: '42291615',
		label: 'Tijeras para uso quirúrgico',
	},
	{
		code: '42291616',
		label: 'Pinzas o cables de pinzas para uso quirúrgico',
	},
	{
		code: '42291617',
		label: 'Espitas para uso quirúrgico',
	},
	{
		code: '42291619',
		label: 'Tomos (pinzas de resección) para uso quirúrgico',
	},
	{
		code: '42291620',
		label: 'Trocadores quirúrgicos para uso general o accesorios',
	},
	{
		code: '42291621',
		label: 'Removedores de anillos de los dedos',
	},
	{
		code: '42291622',
		label: 'Adenotomos',
	},
	{
		code: '42291623',
		label: 'Periosteotomos',
	},
	{
		code: '42291624',
		label: 'Meniscotomos',
	},
	{
		code: '42291625',
		label: 'Instrumentos de incisión del talón de los bebés',
	},
	{
		code: '42291627',
		label: 'Taladros quirúrgicos o sus accesorios',
	},
	{
		code: '42291700',
		label:
			'Taladros quirúrgicos de mano y escariadores y instrumentos de punción y accesorios y productos relacionados',
	},
	{
		code: '42291701',
		label:
			'Brocas de mano o de torsión o kits de brocas o accesorios para uso quirúrgico',
	},
	{
		code: '42291702',
		label: 'Escariadores o punzones de mano para uso quirúrgico',
	},
	{
		code: '42291703',
		label: 'Perforadoras para uso quirúrgico',
	},
	{
		code: '42291704',
		label: 'Punzones o sujeta punzones o accesorios para uso quirúrgico',
	},
	{
		code: '42291705',
		label: 'Adaptadores de escariadores para uso quirúrgico',
	},
	{
		code: '42291707',
		label: 'Kits de craneotomía',
	},
	{
		code: '42291708',
		label: 'Brocas o accesorios para uso quirúrgico',
	},
	{
		code: '42291709',
		label: 'Cuchillas de sierra o accesorios para uso quirúrgico',
	},
	{
		code: '42291710',
		label: 'Gancho amniótico',
	},
	{
		code: '42291800',
		label:
			'Abrazaderas quirúrgicas y fórceps y ligadores quirúrgicos y instrumentos relacionados',
	},
	{
		code: '42291801',
		label:
			'Aplicadores de ligantes de bandas o bandas o productos relacionados para uso quirúrgico',
	},
	{
		code: '42291802',
		label: 'Pinzas o ganchos o fórceps o accesorios para uso quirúrgico',
	},
	{
		code: '42291803',
		label: 'Pinzas o fórceps láser para uso quirúrgico',
	},
	{
		code: '42291804',
		label: 'Pinzas o campana de circuncisión',
	},
	{
		code: '42291805',
		label: 'Pinza de cordón umbilical de recién nacidos',
	},
	{
		code: '42291900',
		label: 'Posicionadores y portadores de tubos y instrumentos quirúrgicos',
	},
	{
		code: '42291901',
		label: 'Sujetadores o posicionadores de instrumentos quirúrgicos',
	},
	{
		code: '42291902',
		label: 'Sujetadores o posicionadores de tubos para uso quirúrgico',
	},
	{
		code: '42292000',
		label: 'Espejos quirúrgicos',
	},
	{
		code: '42292001',
		label: 'Espejos de otolaringología o accesorios para uso quirúrgico',
	},
	{
		code: '42292100',
		label: 'Insertadores y extractores quirúrgicos y productos relacionados',
	},
	{
		code: '42292101',
		label: 'Insertores o kits de insertores para uso quirúrgico',
	},
	{
		code: '42292102',
		label: 'Extractores para uso quirúrgico',
	},
	{
		code: '42292103',
		label: 'Porta tubos o llaves para uso quirúrgico',
	},
	{
		code: '42292200',
		label:
			'Aproximadores y compresores y depresores quirúrgicos y productos relacionados',
	},
	{
		code: '42292201',
		label: 'Aproximadores para uso quirúrgico',
	},
	{
		code: '42292202',
		label: 'Compresores para uso quirúrgico',
	},
	{
		code: '42292203',
		label: 'Depresores para uso quirúrgico',
	},
	{
		code: '42292300',
		label:
			'Hierros de flexión y herramientas de plegar y tenazas y tensionadores y torceduras quirúrgicos y productos relacionados',
	},
	{
		code: '42292301',
		label: 'Instrumentos para doblar para uso quirúrgico',
	},
	{
		code: '42292302',
		label: 'Engarzadores para uso quirúrgico',
	},
	{
		code: '42292303',
		label: 'Alicates para uso quirúrgico',
	},
	{
		code: '42292304',
		label: 'Tensores para uso quirúrgico',
	},
	{
		code: '42292305',
		label: 'Agarres para uso quirúrgico',
	},
	{
		code: '42292306',
		label: 'Fórceps o tenazas de alambre para uso quirúrgico',
	},
	{
		code: '42292307',
		label: 'Llaves para uso quirúrgico',
	},
	{
		code: '42292400',
		label: 'Machos de roscar y impulsores quirúrgicos y productos relacionados',
	},
	{
		code: '42292401',
		label: 'Roscas para uso quirúrgico',
	},
	{
		code: '42292402',
		label: 'Controladores o sus partes o accesorios para uso quirúrgico',
	},
	{
		code: '42292403',
		label: 'Mangos de hachas para uso quirúrgico',
	},
	{
		code: '42292500',
		label:
			'Martillos y malletes y impactadores y prensas quirúrgicos y productos relacionados',
	},
	{
		code: '42292501',
		label: 'Tapones para uso quirúrgico',
	},
	{
		code: '42292502',
		label: 'Martillos o martillos quirúrgicos para uso quirúrgico',
	},
	{
		code: '42292503',
		label: 'Impactadores o empacadores para uso quirúrgico',
	},
	{
		code: '42292504',
		label: 'Prensas para uso quirúrgico',
	},
	{
		code: '42292505',
		label: 'Martillos o cabezas de martillo para uso quirúrgico',
	},
	{
		code: '42292600',
		label:
			'Dilatadores y sondas y ranuras quirúrgicas y productos relacionados',
	},
	{
		code: '42292601',
		label: 'Dilatadores o accesorios para uso quirúrgico',
	},
	{
		code: '42292602',
		label: 'Muescas para uso quirúrgico',
	},
	{
		code: '42292603',
		label: 'Sondas o directores para uso quirúrgico',
	},
	{
		code: '42292700',
		label:
			'Disectores y Elevadores y Piquetas quirúrgicos y productos relacionados',
	},
	{
		code: '42292701',
		label: 'Disectores para uso quirúrgico',
	},
	{
		code: '42292702',
		label: 'Levantadores o niveladores para uso quirúrgico',
	},
	{
		code: '42292703',
		label: 'Elevadores para uso quirúrgico',
	},
	{
		code: '42292704',
		label: 'Picas para uso quirúrgico',
	},
	{
		code: '42292800',
		label: 'Instrumentos quirúrgicos de marcar',
	},
	{
		code: '42292801',
		label: 'Instrumentos de marcación oftálmica',
	},
	{
		code: '42292802',
		label: 'Instrumentos de marcación quirúrgica para uso general',
	},
	{
		code: '42292803',
		label: 'Dispositivo de sellamiento y marcado de biopsias',
	},
	{
		code: '42292900',
		label:
			'Instrumentos quirúrgicos de cerrar la sutura de tejido y productos relacionados',
	},
	{
		code: '42292901',
		label: 'Instrumentos de cerclaje para uso quirúrgico',
	},
	{
		code: '42292902',
		label: 'Sujetadores de aguja láser para uso quirúrgico',
	},
	{
		code: '42292903',
		label: 'Sujetadores de agujas quirúrgicas para uso general',
	},
	{
		code: '42292904',
		label:
			'Suturas quirúrgicas o pasadores de alambre o productos relacionados',
	},
	{
		code: '42292907',
		label: 'Sistemas para estirar la piel',
	},
	{
		code: '42292908',
		label: 'Dispositivos de costura de bolsa para uso quirúrgico',
	},
	{
		code: '42293000',
		label: 'Dispositivos quirúrgicos de medir y productos relacionados',
	},
	{
		code: '42293001',
		label: 'Calibradores o reglas para uso quirúrgico',
	},
	{
		code: '42293002',
		label: 'Sondas o varas de medición para uso quirúrgico',
	},
	{
		code: '42293003',
		label: 'Instrumentos de medición de ganchos para uso quirúrgico',
	},
	{
		code: '42293004',
		label:
			'Instrumentos quirúrgicos de determinación de tamaño para uso general',
	},
	{
		code: '42293005',
		label:
			'Instrumentos de determinación de tamaño de válvula para uso quirúrgico',
	},
	{
		code: '42293006',
		label: 'Cintas de medición para uso quirúrgico',
	},
	{
		code: '42293100',
		label: 'Retractores quirúrgicos y productos relacionados',
	},
	{
		code: '42293101',
		label: 'Retractores láser para uso quirúrgico',
	},
	{
		code: '42293102',
		label: 'Ganchos de retracción para uso quirúrgico',
	},
	{
		code: '42293103',
		label: 'Retractores de fibra óptica iluminados para uso quirúrgico',
	},
	{
		code: '42293104',
		label: 'Mordazas o accesorios para uso quirúrgico',
	},
	{
		code: '42293105',
		label: 'Retractores de inclinación para uso quirúrgico',
	},
	{
		code: '42293106',
		label: 'Sets de retractores para uso quirúrgico',
	},
	{
		code: '42293107',
		label: 'Retractores quirúrgicos para uso general',
	},
	{
		code: '42293108',
		label: 'Estabilizadores para uso quirúrgico',
	},
	{
		code: '42293109',
		label: 'Protectores de tejidos para uso quirúrgico',
	},
	{
		code: '42293110',
		label: 'Retractores ortopédicos',
	},
	{
		code: '42293111',
		label: 'Retractores oftálmicos',
	},
	{
		code: '42293112',
		label: 'Retractores torácicos o cardiovasculares',
	},
	{
		code: '42293113',
		label: 'Retractores de vena',
	},
	{
		code: '42293114',
		label: 'Retractores bucales',
	},
	{
		code: '42293115',
		label: 'Retractores traqueales',
	},
	{
		code: '42293116',
		label: 'Retractores rectales',
	},
	{
		code: '42293117',
		label: 'Retractores gastrointestinales',
	},
	{
		code: '42293118',
		label: 'Retractores uterinos',
	},
	{
		code: '42293119',
		label: 'Retractores abdominales',
	},
	{
		code: '42293120',
		label: 'Retractores de columna o nervios',
	},
	{
		code: '42293121',
		label: 'Retractores de glándulas',
	},
	{
		code: '42293122',
		label: 'Retractores de oído',
	},
	{
		code: '42293123',
		label: 'Retractores para cirugía plástica',
	},
	{
		code: '42293124',
		label: 'Retractores de nervios',
	},
	{
		code: '42293125',
		label: 'Retractores de esternón',
	},
	{
		code: '42293126',
		label: 'Retractores para amputación',
	},
	{
		code: '42293127',
		label: 'Retractores de tejidos',
	},
	{
		code: '42293128',
		label: 'Retractores de piel',
	},
	{
		code: '42293129',
		label: 'Retractores para micro cirugía',
	},
	{
		code: '42293130',
		label: 'Retractores de pulmón',
	},
	{
		code: '42293131',
		label: 'Retractores de párpado',
	},
	{
		code: '42293132',
		label: 'Retractores de dedos',
	},
	{
		code: '42293133',
		label: 'Anillos de retracción para uso quirúrgico',
	},
	{
		code: '42293134',
		label: 'Retractores cervicales',
	},
	{
		code: '42293135',
		label: 'Retractores de labios',
	},
	{
		code: '42293136',
		label: 'Adaptadores de retractores',
	},
	{
		code: '42293137',
		label: 'Cuchillas retractoras ortopédicas',
	},
	{
		code: '42293138',
		label: 'Retractores para urología o sus accesorios para uso quirúrgico',
	},
	{
		code: '42293139',
		label: 'Accesorios para retractores',
	},
	{
		code: '42293200',
		label: 'Instrumentos quirúrgicos de mioma',
	},
	{
		code: '42293201',
		label: 'Destornilladores para miomas para uso quirúrgico',
	},
	{
		code: '42293300',
		label:
			'Distractores y esparcidores y separadores quirúrgicos y productos relacionados',
	},
	{
		code: '42293301',
		label: 'Distractores o accesorios para uso quirúrgico',
	},
	{
		code: '42293302',
		label: 'Separadores para uso quirúrgico',
	},
	{
		code: '42293303',
		label: 'Espéculos para uso quirúrgico',
	},
	{
		code: '42293304',
		label: 'Extensores para uso quirúrgico',
	},
	{
		code: '42293400',
		label:
			'Manipuladores y posicionadores quirúrgicos de implante y productos relacionados',
	},
	{
		code: '42293401',
		label: 'Guías para uso quirúrgico',
	},
	{
		code: '42293403',
		label: 'Sujetadores de implantes para uso quirúrgico',
	},
	{
		code: '42293404',
		label: 'Empujadores para uso quirúrgico',
	},
	{
		code: '42293405',
		label: 'Instrumentos de manipulación para uso quirúrgico',
	},
	{
		code: '42293406',
		label: 'Posicionadores de implantes para uso quirúrgico',
	},
	{
		code: '42293407',
		label: 'Filiforme para dilatar el uréter o la uretra',
	},
	{
		code: '42293408',
		label: 'Seguidor para uréter o uretra',
	},
	{
		code: '42293500',
		label:
			'Cánulas y puntas y estiletes de irrigación y succión quirúrgica y productos relacionados',
	},
	{
		code: '42293501',
		label:
			'Piezas de mano de irrigación o succión o cánulas o puntas o productos relacionados para uso quirúrgico',
	},
	{
		code: '42293502',
		label:
			'Cánulas o puntas de succión o irrigación láser o productos relacionados para uso quirúrgico',
	},
	{
		code: '42293503',
		label:
			'Dispositivos o curetas de extracción al vacío o productos relacionados para uso quirúrgico',
	},
	{
		code: '42293504',
		label: 'Suministros o accesorios de irrigación o aspiración oftálmica',
	},
	{
		code: '42293505',
		label: 'Sondas de drenaje de succión para uso quirúrgico',
	},
	{
		code: '42293506',
		label: 'Bulbos de succión para uso quirúrgico',
	},
	{
		code: '42293507',
		label: 'Reservorios de succión para uso quirúrgico',
	},
	{
		code: '42293508',
		label:
			'Suministros o accesorios de irrigación o aspiración de oído nariz y garganta ent',
	},
	{
		code: '42293509',
		label: 'Sets o accesorios de irrigación para uso quirúrgico',
	},
	{
		code: '42293600',
		label:
			'Bougies quirúrgicos y sondas y obturadores y productos relacionados',
	},
	{
		code: '42293601',
		label: 'Bujías para uso quirúrgico',
	},
	{
		code: '42293602',
		label: 'Obturadores para uso quirúrgico',
	},
	{
		code: '42293603',
		label: 'Sondas para uso quirúrgico',
	},
	{
		code: '42293700',
		label:
			'Machacadoras y excavadoras y fragmentadoras quirúrgicas y productos relacionados',
	},
	{
		code: '42293701',
		label: 'Trituradores para uso quirúrgico',
	},
	{
		code: '42293702',
		label: 'Excavadoras para uso quirúrgico',
	},
	{
		code: '42293703',
		label: 'Molinos de hueso para uso quirúrgico',
	},
	{
		code: '42293800',
		label:
			'Agarradores y buscadores y excavadores y desmoldadores quirúrgicos y productos relacionados',
	},
	{
		code: '42293801',
		label: 'Pasadores para uso quirúrgico',
	},
	{
		code: '42293802',
		label: 'Buscadores para uso quirúrgico',
	},
	{
		code: '42293803',
		label: 'Removedores para uso quirúrgico',
	},
	{
		code: '42293804',
		label: 'Excavadoras o accesorios quirúrgicos.',
	},
	{
		code: '42293900',
		label:
			'Instrumentos quirúrgicos para empaque de heridas y productos relacionados',
	},
	{
		code: '42293901',
		label: 'Anillos de laparoscopia para uso quirúrgico',
	},
	{
		code: '42293902',
		label: 'Instrumentos para cubrir heridas para uso quirúrgico',
	},
	{
		code: '42294000',
		label:
			'Espátulas y cucharas y cucharones quirúrgicos y productos relacionados',
	},
	{
		code: '42294001',
		label: 'Cucharas hondas para uso quirúrgico',
	},
	{
		code: '42294002',
		label: 'Espátulas para uso quirúrgico',
	},
	{
		code: '42294003',
		label: 'Cucharas para uso quirúrgico',
	},
	{
		code: '42294100',
		label:
			'Dispositivos esqueléticos quirúrgicos de tracción y productos relacionados',
	},
	{
		code: '42294101',
		label:
			'Lazos de tracción o bucles de tracción o productos relacionados para uso quirúrgico',
	},
	{
		code: '42294102',
		label:
			'Dispositivos de tracción del cráneo para uso quirúrgico o productos relacionados',
	},
	{
		code: '42294103',
		label: 'Collares de tracción para uso quirúrgico',
	},
	{
		code: '42294200',
		label: 'Juegos de instrumentos y sistemas y bandejas quirúrgicos',
	},
	{
		code: '42294201',
		label: 'Sets de instrumentos quirúrgicos torácicos o cardiovasculares',
	},
	{
		code: '42294202',
		label: 'Sets o sistemas de instrumentos de fijación externa',
	},
	{
		code: '42294203',
		label: 'Sets de instrumentos quirúrgicos generales',
	},
	{
		code: '42294204',
		label:
			'Sets de instrumentos para micro cirugía, cirugía plástica o cirugía delicada',
	},
	{
		code: '42294205',
		label: 'Sets de instrumentos neuroquirúrgicos o espinales',
	},
	{
		code: '42294206',
		label: 'Sets de instrumentos para cirugía oftálmica',
	},
	{
		code: '42294207',
		label: 'Sets de instrumentos para cirugía maxilofacial',
	},
	{
		code: '42294208',
		label:
			'Sistemas de instrumentos para revisión ortopédica o completa de articulaciones',
	},
	{
		code: '42294209',
		label: 'Sistemas de instrumentos para fijación de trauma ortopédico',
	},
	{
		code: '42294210',
		label: 'Sets de instrumentos para cirugía otolaringológica',
	},
	{
		code: '42294211',
		label:
			'Bandejas de procedimientos o instrumentos especiales o a la medida para uso quirúrgico',
	},
	{
		code: '42294212',
		label: 'Sets de instrumentos para cirugía urológica',
	},
	{
		code: '42294213',
		label: 'Sets de instrumentos para cirugía por laparoscopia',
	},
	{
		code: '42294214',
		label: 'Sets de instrumentos para cirugía de traqueotomía',
	},
	{
		code: '42294215',
		label: 'Sets de instrumentos para cirugía de craneotomía',
	},
	{
		code: '42294216',
		label: 'Sets de instrumentos para cirugía de angiografía',
	},
	{
		code: '42294217',
		label: 'Sets de instrumentos para cirugía gastroscópica',
	},
	{
		code: '42294218',
		label: 'Sets de instrumentos para cirugía de oído nariz y garganta ent',
	},
	{
		code: '42294219',
		label: 'Sets de instrumentos para cirugía ortopédica',
	},
	{
		code: '42294220',
		label: 'Sistemas de recuperación y entrega de sangre',
	},
	{
		code: '42294300',
		label:
			'Instrumentos de biopsia de invasiva mínima del pecho y suministros y equipo',
	},
	{
		code: '42294301',
		label:
			'Conductores o módulos de disparo o accesorios para biopsia de seno mínimamente invasiva',
	},
	{
		code: '42294302',
		label:
			'Unidades de carga premium para biopsia de seno mínimamente invasiva',
	},
	{
		code: '42294303',
		label: 'Guías de aguja para biopsia de seno mínimamente invasiva',
	},
	{
		code: '42294304',
		label: 'Instrumentos marcadores para biopsia de seno mínimamente invasiva',
	},
	{
		code: '42294305',
		label:
			'Unidades de vacío o accesorios para biopsia de seno mínimamente invasiva',
	},
	{
		code: '42294306',
		label: 'Aguja de localización de seno',
	},
	{
		code: '42294400',
		label: 'Sistemas cardiacos y vasculares',
	},
	{
		code: '42294401',
		label: 'Sistemas de cosecha de venas',
	},
	{
		code: '42294402',
		label: 'Sistemas de visualización coronaria',
	},
	{
		code: '42294500',
		label: 'Instrumentos de especialidad oftálmica y productos relacionados',
	},
	{
		code: '42294501',
		label: 'Conformadores o protectores para cirugía oftálmica',
	},
	{
		code: '42294502',
		label: 'Pesas de párpados para cirugía oftálmica',
	},
	{
		code: '42294503',
		label: 'Anillos de fijación para cirugía oftálmica',
	},
	{
		code: '42294504',
		label: 'Instrumentos de membrana intraocular para cirugía oftálmica',
	},
	{
		code: '42294505',
		label: 'Placas de párpado para cirugía oftálmica',
	},
	{
		code: '42294506',
		label: 'Rotadores de núcleo para cirugía oftálmica',
	},
	{
		code: '42294507',
		label:
			'Fresas o manijas o removedores de anillos de óxido para uso oftálmico',
	},
	{
		code: '42294508',
		label: 'Puntas de aguja de irrigación o aspiración para uso oftálmico',
	},
	{
		code: '42294509',
		label: 'Agujas para cirugía oftálmica',
	},
	{
		code: '42294510',
		label: 'Esponjas para cirugía oftálmica',
	},
	{
		code: '42294511',
		label:
			'Cuchillos o cuchillas o tijeras o accesorios para cirugía oftálmica',
	},
	{
		code: '42294512',
		label: 'Protectores de ojos o sus accesorios',
	},
	{
		code: '42294513',
		label: 'Kits de vitrectomía oftálmica',
	},
	{
		code: '42294514',
		label: 'Sondas de borrado hemostático',
	},
	{
		code: '42294515',
		label: 'Pulidores de lentes oftálmicos',
	},
	{
		code: '42294516',
		label: 'Sujetadores de ojos o sus accesorios',
	},
	{
		code: '42294517',
		label: 'Insertos ópticos',
	},
	{
		code: '42294518',
		label: 'Sets de ajuste de insertos ópticos',
	},
	{
		code: '42294519',
		label: 'Cucharas o curetas oftálmicas',
	},
	{
		code: '42294520',
		label: 'Sujetadores de lentes o accesorios para uso oftálmico',
	},
	{
		code: '42294521',
		label: 'Componentes de combado escleral',
	},
	{
		code: '42294522',
		label: 'Sets de punctum de tapón para uso oftálmico',
	},
	{
		code: '42294523',
		label:
			'Suministros para cirugía plástica oftálmica o sus productos relacionados',
	},
	{
		code: '42294524',
		label: 'Magnetos de ojos para cirugía oftálmica',
	},
	{
		code: '42294525',
		label: 'Sets de instrumentos médicos oftálmicos',
	},
	{
		code: '42294526',
		label: 'Dilatadores o sets de lagrimales',
	},
	{
		code: '42294527',
		label: 'Gas intraocular',
	},
	{
		code: '42294528',
		label: 'Agente visco elástico o dispositivo visco quirúrgico',
	},
	{
		code: '42294529',
		label: 'Cistotomo',
	},
	{
		code: '42294530',
		label: 'Lente de vitrectomía',
	},
	{
		code: '42294531',
		label: 'Lente láser para uso oftálmico',
	},
	{
		code: '42294600',
		label: 'Productos de autotransfusión',
	},
	{
		code: '42294601',
		label: 'Bolsas de autotransfusión o transferencia de sangre',
	},
	{
		code: '42294602',
		label: 'Kits de contenedores de autotransfusión o kits centrífugos',
	},
	{
		code: '42294603',
		label: 'Unidades de autotransfusión',
	},
	{
		code: '42294604',
		label: 'Filtros de autotransfusión',
	},
	{
		code: '42294605',
		label: 'Reservorios o sus accesorios de autotransfusión',
	},
	{
		code: '42294606',
		label: 'Sets o kits de tubos de autotransfusión',
	},
	{
		code: '42294607',
		label: 'Válvulas de autotransfusión',
	},
	{
		code: '42294700',
		label:
			'Equipo de perfusión de corazón abierto y monitores y accesorios y productos relacionados',
	},
	{
		code: '42294701',
		label: 'Máquinas o accesorios de corazón y pulmones',
	},
	{
		code: '42294702',
		label: 'Bombas y accesorios de balón intra aórtico',
	},
	{
		code: '42294703',
		label: 'Dispositivos o accesorios de succión intra cardíaca',
	},
	{
		code: '42294704',
		label: 'Filtros de perfusión o productos relacionados',
	},
	{
		code: '42294705',
		label:
			'Monitores de parámetros de sangre de perfusión o accesorios o productos relacionados',
	},
	{
		code: '42294706',
		label: 'Trampas de burbujas de perfusión',
	},
	{
		code: '42294707',
		label: 'Sets de cardioplegia de perfusión',
	},
	{
		code: '42294708',
		label: 'Reservorios de cardiotomía de perfusión',
	},
	{
		code: '42294709',
		label: 'Sistemas centrífugos de perfusión o accesorios',
	},
	{
		code: '42294710',
		label:
			'Equipos o accesorios calentadores o enfriadores o duales calentadores o enfriadores de perfusión',
	},
	{
		code: '42294711',
		label: 'Hemoconcentradores de perfusión o accesorios',
	},
	{
		code: '42294712',
		label:
			'Monitores de saturación de oxígeno o hematocritos de perfusión o accesorios',
	},
	{
		code: '42294713',
		label: 'Oxigenadores de perfusión o accesorios',
	},
	{
		code: '42294714',
		label: 'Cabezas de bombas de perfusión',
	},
	{
		code: '42294715',
		label: 'Tubos de empaque de bombas de perfusión',
	},
	{
		code: '42294716',
		label: 'Reservorios venosos de perfusión',
	},
	{
		code: '42294717',
		label: 'Dispositivos de asistencia ventricular',
	},
	{
		code: '42294718',
		label: 'Bombas de perfusión',
	},
	{
		code: '42294719',
		label: 'Reservorios cardiovasculares',
	},
	{
		code: '42294720',
		label: 'Agujas de monitoreo de temperatura',
	},
	{
		code: '42294721',
		label: 'Punzones aórticos',
	},
	{
		code: '42294722',
		label: 'Sets de drenaje ventricular',
	},
	{
		code: '42294723',
		label: 'Kits de inserción de bomba de inflar intra aórtica',
	},
	{
		code: '42294724',
		label: 'Introductor de bomba de inflar intra aórtica',
	},
	{
		code: '42294800',
		label: 'Endoscopios y accesorios y productos relacionados',
	},
	{
		code: '42294801',
		label: 'Endoscopios rígidos o accesorios o productos relacionados',
	},
	{
		code: '42294802',
		label: 'Endoscopios flexibles o accesorios o productos relacionados',
	},
	{
		code: '42294803',
		label: 'Cistouretroscopios',
	},
	{
		code: '42294804',
		label: 'Resectoscopios',
	},
	{
		code: '42294805',
		label: 'Laparascopios o telescopios laparoscópicos',
	},
	{
		code: '42294806',
		label: 'Cistoscopios',
	},
	{
		code: '42294807',
		label: 'Esfinterómetros endoscópicos',
	},
	{
		code: '42294808',
		label: 'Esofagoscopios o sus accesorios',
	},
	{
		code: '42294900',
		label:
			'Instrumentos endoscópicos y suministros accesorios y productos relacionados',
	},
	{
		code: '42294901',
		label: 'Posicionadores o sujetadores de endoscopios o instrumentos',
	},
	{
		code: '42294902',
		label: 'Aplicadores o elevadores de endoscopios',
	},
	{
		code: '42294903',
		label: 'Agujas de aspiración o biopsia endoscópica',
	},
	{
		code: '42294904',
		label: 'Bloques de mordida o correas endoscópicas',
	},
	{
		code: '42294905',
		label: 'Cepillos de limpieza endoscópica o productos relacionados',
	},
	{
		code: '42294906',
		label: 'Instrumentos de cortar para uso endoscópico',
	},
	{
		code: '42294907',
		label: 'Cepillos de citología endoscópica o microbiología',
	},
	{
		code: '42294908',
		label:
			'Pinzas o disectores o agarraderas o fórceps o ligantes endoscópicos',
	},
	{
		code: '42294909',
		label:
			'Dilatadores o dispositivos inflables endoscópicos o productos relacionados',
	},
	{
		code: '42294910',
		label: 'Electrodos o cables endoscópicos',
	},
	{
		code: '42294911',
		label: 'Kits de fijación endoscópica',
	},
	{
		code: '42294912',
		label: 'Sistemas o accesorios de manejo de fluido endoscópico',
	},
	{
		code: '42294913',
		label: 'Balones hemostáticos o agujas o tubos o accesorios endoscópicos',
	},
	{
		code: '42294914',
		label: 'Sets de instrumentos endoscópicos',
	},
	{
		code: '42294915',
		label: 'Desplegadores de instrumentos endoscópicos',
	},
	{
		code: '42294916',
		label: 'Filtros de insuflación endoscópica',
	},
	{
		code: '42294917',
		label: 'Agujas de insuflación endoscópica',
	},
	{
		code: '42294918',
		label: 'Tubos de insuflación endoscópica',
	},
	{
		code: '42294919',
		label:
			'Introductores o alambres guía o alambres de deslizamiento endoscópicos',
	},
	{
		code: '42294920',
		label: 'Instrumentos o accesorios láser endoscópicos',
	},
	{
		code: '42294921',
		label: 'Sistemas de empujadores de nudos o de entrega endoscópicos',
	},
	{
		code: '42294922',
		label: 'Ligantes endoscópicos',
	},
	{
		code: '42294923',
		label: 'Manipuladores endoscópicos',
	},
	{
		code: '42294924',
		label:
			'Instrumentos o accesorios manuales monopolares o bipolares para endoscopia o productos relacionados',
	},
	{
		code: '42294925',
		label: 'Agujas o punzones para endoscopia',
	},
	{
		code: '42294926',
		label: 'Sobre tubos para endoscopia',
	},
	{
		code: '42294927',
		label: 'Paquetes o bandejas o kits de instrumentos para endoscopia',
	},
	{
		code: '42294928',
		label: 'Sondas para endoscopia',
	},
	{
		code: '42294929',
		label: 'Retractores para endoscopia',
	},
	{
		code: '42294930',
		label: 'Pinzas o alambres de pinzas o accesorios para endoscopia',
	},
	{
		code: '42294931',
		label:
			'Fórceps o dispositivos de recuperación de especímenes para endoscopia',
	},
	{
		code: '42294933',
		label:
			'Puntas de irrigación o succión o sondas de coagulación o accesorios para endoscopia',
	},
	{
		code: '42294934',
		label: 'Dispositivos de sutura para endoscopia',
	},
	{
		code: '42294935',
		label: 'Trocar o funda u obturador o cánula para endoscopia',
	},
	{
		code: '42294936',
		label: 'Elementos de trabajo o canales de trabajo para endoscopia',
	},
	{
		code: '42294937',
		label: 'Dispositivos reductores de empañe para endoscopia o espejos',
	},
	{
		code: '42294938',
		label: 'Tapas sellantes para endoscopia',
	},
	{
		code: '42294939',
		label: 'Válvulas o accesorios para endoscopia',
	},
	{
		code: '42294940',
		label: 'Convertidores para endoscopia',
	},
	{
		code: '42294941',
		label: 'Sets de drenaje biliar para endoscopia',
	},
	{
		code: '42294942',
		label: 'Sellos de instrumentos para endoscopia',
	},
	{
		code: '42294943',
		label: 'Unidades de válvulas para endoscopia',
	},
	{
		code: '42294944',
		label: 'Kits de accesorios para endoscopia',
	},
	{
		code: '42294945',
		label: 'Esponjas para endoscopia',
	},
	{
		code: '42294946',
		label: 'Mordazas para endoscopia',
	},
	{
		code: '42294947',
		label: 'Diafragmas para endoscopia',
	},
	{
		code: '42294948',
		label: 'Piezas bucales para endoscopia',
	},
	{
		code: '42294949',
		label: 'Manijas de guía de alambre para endoscopia',
	},
	{
		code: '42294950',
		label: 'Brocas o taladros para endoscopia',
	},
	{
		code: '42294951',
		label: 'Sets de instrumentos para uniones pequeñas para endoscopia',
	},
	{
		code: '42294952',
		label: 'Recuperadores o sets para endoscopia',
	},
	{
		code: '42294953',
		label: 'Extractores para endoscopia',
	},
	{
		code: '42294954',
		label:
			'Dispositivos o accesorios para remover especímenes o tejidos para endoscopia',
	},
	{
		code: '42294955',
		label: 'Ganchos o accesorios para endoscopia',
	},
	{
		code: '42294956',
		label: 'Localizadores de guía de alambre para endoscopia',
	},
	{
		code: '42294957',
		label: 'Cuchillas de afeitar o accesorios para endoscopia',
	},
	{
		code: '42294958',
		label: 'Aditamentos y accesorios de cortar y sellar vasos para endoscopia',
	},
	{
		code: '42295000',
		label: 'Equipo endoscópico y accesorios y productos relacionados',
	},
	{
		code: '42295001',
		label: 'Unidades o accesorios de mantenimiento para endoscopia',
	},
	{
		code: '42295002',
		label: 'Gabinetes de almacenamiento o accesorios para endoscopia',
	},
	{
		code: '42295003',
		label: 'Colgadores de pared o accesorios para endoscopia',
	},
	{
		code: '42295004',
		label: 'Carritos de equipos o procedimientos o accesorios para endoscopia',
	},
	{
		code: '42295005',
		label: 'Sets de equipos endoscópicos',
	},
	{
		code: '42295006',
		label:
			'Unidades de sonda de calor o sondas de calor o accesorios para endoscopia',
	},
	{
		code: '42295007',
		label: 'Sistemas de imágenes o accesorios para endoscopia',
	},
	{
		code: '42295008',
		label: 'Unidades o accesorios de insuflación o distensión para endoscopia',
	},
	{
		code: '42295009',
		label: 'Fuentes de luz quirúrgica o accesorios para endoscopia',
	},
	{
		code: '42295010',
		label: 'Impresoras o accesorios para endoscopia',
	},
	{
		code: '42295011',
		label:
			'Video cámaras o grabadoras o adaptadores o accesorios para endoscopia',
	},
	{
		code: '42295012',
		label: 'Botellas de agua o accesorios para endoscopia',
	},
	{
		code: '42295013',
		label: 'Protectores o cubiertas de puntas de endoscopios',
	},
	{
		code: '42295014',
		label: 'Estuches de instrumentos para endoscopia',
	},
	{
		code: '42295015',
		label: 'Lentes endoscópicos',
	},
	{
		code: '42295016',
		label: 'Video cable quirúrgico o endoscópico',
	},
	{
		code: '42295100',
		label: 'Equipo quirúrgico y accesorios y productos relacionados',
	},
	{
		code: '42295101',
		label: 'Puestos de lavamanos para uso quirúrgico',
	},
	{
		code: '42295102',
		label: 'Equipos o accesorios para criocirugía',
	},
	{
		code: '42295103',
		label:
			'Mesas o accesorios para procedimientos de cesáreas o salas de partos o productos relacionados',
	},
	{
		code: '42295104',
		label: 'Equipo electro quirúrgico o electro cauterizante',
	},
	{
		code: '42295105',
		label:
			'Mesas de instrumentos para uso quirúrgico u obstétrico o accesorios o productos relacionados',
	},
	{
		code: '42295106',
		label:
			'Bandejas mayo para el canto o puestos mayo para uso quirúrgico o sus accesorios',
	},
	{
		code: '42295107',
		label:
			'Carritos para estuches de salas de cirugía o carritos de procedimiento o gabinetes de pared o accesorios',
	},
	{
		code: '42295108',
		label:
			'Mesas de fractura de pacientes para salas de cirugía o mesas ortopédicas o accesorios o productos relacionados',
	},
	{
		code: '42295109',
		label: 'Baldes para salas de cirugía o accesorios o productos relacionados',
	},
	{
		code: '42295111',
		label:
			'Dispositivos de posicionamiento de los pacientes para salas de cirugía o accesorios',
	},
	{
		code: '42295112',
		label:
			'Mesas de procedimientos para salas de cirugía o accesorios o productos relacionados',
	},
	{
		code: '42295114',
		label:
			'Equipos de facoemulsificación o extrusión o accesorios para cirugía oftálmica',
	},
	{
		code: '42295115',
		label: 'Taburetes de cirujanos o accesorios',
	},
	{
		code: '42295116',
		label: 'Escalones para salas de cirugía o accesorios',
	},
	{
		code: '42295118',
		label:
			'Equipos de bombas de irrigación para uso quirúrgico o lavado pulsado o accesorios con o sin succión',
	},
	{
		code: '42295119',
		label: 'Láseres para uso quirúrgico o accesorios',
	},
	{
		code: '42295120',
		label: 'Litotriptores o accesorios para uso quirúrgico',
	},
	{
		code: '42295121',
		label:
			'Microscopios o lupas o magnificadores o accesorios para uso quirúrgico',
	},
	{
		code: '42295122',
		label:
			'Torniquetes neumáticos o eléctricos o accesorios para uso quirúrgico',
	},
	{
		code: '42295123',
		label:
			'Máquinas de succión o extractores al vacío o aspiradores quirúrgicos ultrasónicos o reguladores o accesorios para uso quirúrgico',
	},
	{
		code: '42295124',
		label: 'Evacuadores de humo o accesorios para uso quirúrgico',
	},
	{
		code: '42295125',
		label: 'Mesas urológicas o accesorios para uso quirúrgico',
	},
	{
		code: '42295126',
		label:
			'Equipos de cirugía fragmatoma de retina vítrea para cirugía oftálmica',
	},
	{
		code: '42295127',
		label: 'Equipos o accesorios de microcirugía',
	},
	{
		code: '42295128',
		label: 'Instrumentos quirúrgicos maxilofaciales o accesorios',
	},
	{
		code: '42295129',
		label:
			'Dispensadores de medicamentos para salas de cirugía o productos relacionados',
	},
	{
		code: '42295130',
		label: 'Tubos o accesorios de conexión de instrumentos quirúrgicos',
	},
	{
		code: '42295131',
		label: 'Puestos o accesorios de equipos quirúrgicos',
	},
	{
		code: '42295132',
		label: 'Estuches o accesorios para equipos quirúrgicos',
	},
	{
		code: '42295133',
		label: 'Estantes de tornillos ortopédicos o accesorios',
	},
	{
		code: '42295134',
		label: 'Sets o accesorios de suministro quirúrgico general',
	},
	{
		code: '42295135',
		label: 'Dispositivos o accesorios de control ortopédico',
	},
	{
		code: '42295136',
		label: 'Instrumentos de control para uso quirúrgico',
	},
	{
		code: '42295137',
		label: 'Equipos o suministros o accesorios para gastroenterología',
	},
	{
		code: '42295138',
		label:
			'Dispositivos o accesorios de dilatación urológica para uso quirúrgico',
	},
	{
		code: '42295139',
		label: 'Rollos de instrumentos para instrumentos quirúrgicos o accesorios',
	},
	{
		code: '42295140',
		label: 'Gabinetes o muebles para instrumentos quirúrgicos',
	},
	{
		code: '42295141',
		label: 'Kit de salvamento de sangre intra operativa',
	},
	{
		code: '42295142',
		label: 'Máquina de salvamento de sangre intra operativa',
	},
	{
		code: '42295143',
		label:
			'Accesorios o aditamentos electro quirúrgicos o electro cauterizantes',
	},
	{
		code: '42295144',
		label: 'Unidad de microondas quirúrgico',
	},
	{
		code: '42295145',
		label: 'Depilador tipo aguja',
	},
	{
		code: '42295146',
		label: 'Depilador tipo pinza',
	},
	{
		code: '42295147',
		label: 'Instrumento de ultrasonido para uso quirúrgico',
	},
	{
		code: '42295148',
		label: 'Sistema de navegación para uso quirúrgico',
	},
	{
		code: '42295200',
		label: 'Equipo quirúrgico de energía y accesorios y productos relacionados',
	},
	{
		code: '42295201',
		label:
			'Dermotomos o aparatos para dermoabrasión o dermoreticulador para uso quirúrgico o accesorios',
	},
	{
		code: '42295202',
		label:
			'Sierras eléctricas o de pila o neumáticas o colocadores de tornillos o accesorios para uso quirúrgico',
	},
	{
		code: '42295203',
		label: 'Sets o accesorios de equipos eléctricos para uso quirúrgico',
	},
	{
		code: '42295204',
		label: 'Escariadores para uso quirúrgico',
	},
	{
		code: '42295205',
		label:
			'Equipos de afeitado o piezas de mano o cuchillas o accesorios para uso quirúrgico',
	},
	{
		code: '42295206',
		label: 'Unidades de cirugía endoscópica',
	},
	{
		code: '42295207',
		label: 'Guía o manga para taladro de uso quirúrgico',
	},
	{
		code: '42295300',
		label:
			'Suministros y accesorios para cirugía de corazón abierto y productos relacionados',
	},
	{
		code: '42295301',
		label: 'Sopladores o vaporizadores o accesorios de uso quirúrgico',
	},
	{
		code: '42295302',
		label: 'Cánulas de perfusión de uso quirúrgico',
	},
	{
		code: '42295303',
		label: 'Catéteres o conectores o accesorios de uso quirúrgico',
	},
	{
		code: '42295304',
		label:
			'Almohadillas para nervios frénicos o almohadas cardíacas de uso quirúrgico',
	},
	{
		code: '42295305',
		label:
			'Torniquetes u oclusores vasculares o ligantes o accesorios de uso quirúrgico',
	},
	{
		code: '42295306',
		label: 'Lanzaderas de vasos o cintas de retracción de uso quirúrgico',
	},
	{
		code: '42295307',
		label: 'Decapantes de arteria carótida o accesorios de uso quirúrgico',
	},
	{
		code: '42295308',
		label: 'Sets de perfusión coronaria',
	},
	{
		code: '42295400',
		label: 'Suministros quirúrgicos auxiliares',
	},
	{
		code: '42295401',
		label: 'Lápices de cauterización operados mediante pilas de uso quirúrgico',
	},
	{
		code: '42295402',
		label: 'Esferos para marcar de uso quirúrgico',
	},
	{
		code: '42295405',
		label:
			'Introductores o ganchos de guía o guías de alambre o deslizadores de alambre para cirugía no endoscópica o procedimientos de corazón abierto',
	},
	{
		code: '42295406',
		label:
			'Esponja especial para laparotomía o detectable por rayos x o de uso quirúrgico',
	},
	{
		code: '42295407',
		label: 'Máscaras para pacientes de uso quirúrgico',
	},
	{
		code: '42295408',
		label: 'Cepillos para manos para cirujanos o soluciones o accesorios',
	},
	{
		code: '42295409',
		label: 'Aplicadores de uso quirúrgico',
	},
	{
		code: '42295410',
		label: 'Sets o paquetes de cuencas de uso quirúrgico',
	},
	{
		code: '42295411',
		label: 'Sujetadores o interruptores de cuchilla de uso quirúrgico',
	},
	{
		code: '42295413',
		label: 'Cepillos de canal de uso quirúrgico',
	},
	{
		code: '42295414',
		label:
			'Paquetes para procedimientos especiales o a la medida de uso quirúrgico',
	},
	{
		code: '42295415',
		label: 'Fundas para equipos de uso quirúrgico',
	},
	{
		code: '42295416',
		label: 'Evacuadores de uso quirúrgico',
	},
	{
		code: '42295417',
		label: 'Fundas o manijas para luces de uso quirúrgico',
	},
	{
		code: '42295418',
		label: 'Esterillas magnéticas de uso quirúrgico',
	},
	{
		code: '42295419',
		label: 'Estimuladores de nervios o accesorios de uso quirúrgico',
	},
	{
		code: '42295420',
		label:
			'Catéteres endoscópicos o quirúrgicos o kits de cateterización o bolsas de drenaje',
	},
	{
		code: '42295421',
		label: 'Lavado de preparación o soluciones de pintura para uso quirúrgico',
	},
	{
		code: '42295422',
		label: 'Lavado o kits de preparación del paciente para uso quirúrgico',
	},
	{
		code: '42295423',
		label:
			'Mostradores de instrumentos afilados o esponjas para uso quirúrgico',
	},
	{
		code: '42295424',
		label:
			'Kits de afeitado o cuchillas de preparación o corta uñas para uso quirúrgico',
	},
	{
		code: '42295425',
		label:
			'Palitos o lápices o cristales de nitrato de plata para uso quirúrgico',
	},
	{
		code: '42295426',
		label:
			'Trampas o contenedores de recolección de especímenes para uso quirúrgico',
	},
	{
		code: '42295427',
		label:
			'Cepillos para instrumentos estériles o estiletes instrumentales o pañitos limpiadores de instrumentos para uso quirúrgico',
	},
	{
		code: '42295428',
		label: 'Tubos o accesorios de irrigación o succión para uso quirúrgico',
	},
	{
		code: '42295431',
		label:
			'Cortinas transparentes de incisión o bolsas para instrumentos para uso quirúrgico',
	},
	{
		code: '42295433',
		label: 'Catéteres urológicos o accesorios para uso quirúrgico',
	},
	{
		code: '42295435',
		label: 'Protectores o escudos para oídos',
	},
	{
		code: '42295436',
		label: 'Anillos de anastomosis',
	},
	{
		code: '42295437',
		label: 'Sets de utensilios o gabinetes o accesorios para uso quirúrgico',
	},
	{
		code: '42295439',
		label: 'Alisadores de hueso para uso ortopédico',
	},
	{
		code: '42295440',
		label: 'Adaptadores de catéteres endoscópicos',
	},
	{
		code: '42295441',
		label: 'Sets de recuperación para uso quirúrgico',
	},
	{
		code: '42295445',
		label: 'Insertos de bandejas para aparatos quirúrgicos',
	},
	{
		code: '42295446',
		label: 'Retenedores de órganos internos',
	},
	{
		code: '42295448',
		label: 'Guardas de salpicaduras o accesorios para uso quirúrgico',
	},
	{
		code: '42295450',
		label: 'Cargadores de baldes o accesorios para uso quirúrgico',
	},
	{
		code: '42295451',
		label: 'Guantes de preparación para uso quirúrgico',
	},
	{
		code: '42295452',
		label: 'Tazas de preparación para uso quirúrgico',
	},
	{
		code: '42295453',
		label: 'Drenajes o sets o accesorios para uso quirúrgico',
	},
	{
		code: '42295454',
		label: 'Protectores de mano para uso quirúrgico',
	},
	{
		code: '42295455',
		label: 'Kits de resina para reparación craneal',
	},
	{
		code: '42295456',
		label: 'Fieltros para uso quirúrgico',
	},
	{
		code: '42295457',
		label: 'Lentes o capuchas de cascos para uso quirúrgico',
	},
	{
		code: '42295458',
		label: 'Equipos para secar o empolvar guantes quirúrgicos',
	},
	{
		code: '42295459',
		label: 'Dispositivos para decantar fluidos para uso quirúrgico',
	},
	{
		code: '42295460',
		label: 'Capas protectoras para implantes ortopédicos',
	},
	{
		code: '42295461',
		label: 'Pegamento para tejidos o sistemas o aplicadores o accesorios',
	},
	{
		code: '42295462',
		label: 'Catéteres uro dinámicos o accesorios',
	},
	{
		code: '42295463',
		label: 'Catéteres uretrales',
	},
	{
		code: '42295464',
		label: 'Solución para preservación de órganos',
	},
	{
		code: '42295465',
		label: 'Sistema de monitoreo neurofisiológico',
	},
	{
		code: '42295466',
		label: 'Cemento para huesos para uso quirúrgico',
	},
	{
		code: '42295467',
		label: 'Mezcladora de cemento para huesos para uso quirúrgico',
	},
	{
		code: '42295468',
		label: 'Inyector de cemento para huesos o accesorios para uso quirúrgico',
	},
	{
		code: '42295469',
		label: 'Kits de cemento para huesos o accesorios para uso quirúrgico',
	},
	{
		code: '42295470',
		label: 'Sistema de concentración de plaquetas',
	},
	{
		code: '42295471',
		label: 'Sistema de aspiración de médula ósea',
	},
	{
		code: '42295500',
		label:
			'Implantes quirúrgico y ensanchadores y extensores y alambres quirúrgicos y productos relacionados',
	},
	{
		code: '42295502',
		label: 'Implantes de tejido humano',
	},
	{
		code: '42295503',
		label: 'Puertos de infusión implantables o accesorios',
	},
	{
		code: '42295505',
		label: 'Implantes oftálmicos',
	},
	{
		code: '42295506',
		label: 'Implantes maxilofaciales bucales o sets',
	},
	{
		code: '42295508',
		label: 'Implantes para otolaringología o sets',
	},
	{
		code: '42295509',
		label: 'Implantes plásticos o cosméticos o expansores de tejidos o sets',
	},
	{
		code: '42295510',
		label: 'Barreras de adhesión para uso quirúrgico',
	},
	{
		code: '42295511',
		label: 'Estimuladores de hueso para uso quirúrgico',
	},
	{
		code: '42295512',
		label:
			'Derivaciones implantables o extensores de derivaciones para uso quirúrgico',
	},
	{
		code: '42295513',
		label: 'Productos de malla o de barreras de tejido para uso quirúrgico',
	},
	{
		code: '42295514',
		label: 'Láminas de silicona',
	},
	{
		code: '42295515',
		label: 'Implantes de tejidos sintéticos',
	},
	{
		code: '42295516',
		label: 'Implantes urológicos o sets',
	},
	{
		code: '42295517',
		label: 'Extensores cardiovasculares',
	},
	{
		code: '42295518',
		label: 'Protectores de ganchos',
	},
	{
		code: '42295519',
		label: 'Kit de preparación para prótesis de pene',
	},
	{
		code: '42295520',
		label: 'Kit de ventriculostomía',
	},
	{
		code: '42295521',
		label: 'Implante gastrointestinal',
	},
	{
		code: '42295522',
		label: 'Estimulador de nervios implantable',
	},
	{
		code: '42295523',
		label: 'Implante o eslinga ginecológica',
	},
	{
		code: '42295524',
		label: 'Lente intraocular iol',
	},
	{
		code: '42295525',
		label: 'Implante de tejido biológico',
	},
	{
		code: '42295526',
		label: 'Sistema de implantes de pelo',
	},
	{
		code: '42295600',
		label: 'Productos y accesorios de drenaje de fluido cerebroespinal',
	},
	{
		code: '42295601',
		label: 'Catéter o adaptador de drenaje ventricular cerebral',
	},
	{
		code: '42295602',
		label: 'Bolsa o reservorio de drenaje ventricular cerebral externa',
	},
	{
		code: '42295603',
		label: 'Drenaje de fluido cerebroespinal lumbar o kit',
	},
	{
		code: '42295800',
		label: 'Sistemas y accesorios de termo - ablación quirúrgica',
	},
	{
		code: '42295801',
		label: 'Casete de ablación térmica para uso quirúrgico',
	},
	{
		code: '42295802',
		label: 'Set de tubos de ablación térmica para uso quirúrgico',
	},
	{
		code: '42295803',
		label: 'Catéteres de ablación térmica para uso quirúrgico',
	},
	{
		code: '42295900',
		label: 'Stents quirúrgicos',
	},
	{
		code: '42295901',
		label: 'Stents gastrointestinales',
	},
	{
		code: '42295902',
		label: 'Stents traqueales',
	},
	{
		code: '42295903',
		label: 'Stents urológicos',
	},
	{
		code: '42295904',
		label: 'Stents uterinos o vaginales',
	},
	{
		code: '42295905',
		label: 'Endoprótesis o stents de pared o tubos',
	},
	{
		code: '42295906',
		label: 'Stents esofágicos',
	},
	{
		code: '42296000',
		label: 'Implantes cardiovasculares',
	},
	{
		code: '42296001',
		label: 'Válvula aórtica',
	},
	{
		code: '42296002',
		label: 'Conducto cardiovascular',
	},
	{
		code: '42296003',
		label: 'Válvula mitral',
	},
	{
		code: '42296004',
		label: 'Válvula tricúspide',
	},
	{
		code: '42296005',
		label: 'Anillo de anuloplastia',
	},
	{
		code: '42296006',
		label: 'Gancho vascular',
	},
	{
		code: '42296007',
		label: 'Oclusor cardiovascular',
	},
	{
		code: '42296008',
		label: 'Válvula pulmonar',
	},
	{
		code: '42296100',
		label: 'Implantes neuroquirúrgicos',
	},
	{
		code: '42296101',
		label: 'Clip implantable para aneurisma',
	},
	{
		code: '42296102',
		label: 'Clip temporal para aneurisma',
	},
	{
		code: '42296103',
		label: 'Reemplazo dural o dispositivo de reparación',
	},
	{
		code: '42296104',
		label: 'Placa craneal o cubierta de orificio de broca',
	},
	{
		code: '42296105',
		label: 'Malla craneal',
	},
	{
		code: '42296106',
		label: 'Gancho de reparación de nervios',
	},
	{
		code: '42296107',
		label: 'Pinza craneal',
	},
	{
		code: '42296200',
		label:
			'Equipo y accesorios de robótica quirúrgica y productos relacionados',
	},
	{
		code: '42296201',
		label: 'Instrumentos robóticos de aplicación de clips para uso quirúrgico',
	},
	{
		code: '42296202',
		label: 'Instrumentos robóticos de disección para uso quirúrgico',
	},
	{
		code: '42296203',
		label:
			'Instrumentos robóticos de electro cauterización para uso quirúrgico',
	},
	{
		code: '42296204',
		label: 'Agarradores o fórceps robóticos para uso quirúrgico',
	},
	{
		code: '42296205',
		label: 'Instrumentos robóticos de gancho para uso quirúrgico',
	},
	{
		code: '42296206',
		label:
			'Empujador de nudos o sistema de entrega robótico para uso quirúrgico',
	},
	{
		code: '42296207',
		label: 'Conductor de aguja robótico para uso quirúrgico',
	},
	{
		code: '42296208',
		label: 'Instrumento robótico de retracción para uso quirúrgico',
	},
	{
		code: '42296209',
		label: 'Escalpelo y cuchilla robótica para uso quirúrgico',
	},
	{
		code: '42296210',
		label: 'Tijeras robóticas para uso quirúrgico',
	},
	{
		code: '42296211',
		label: 'Instrumento robótico de energía ultrasónica para uso quirúrgico',
	},
	{
		code: '42296212',
		label: 'Trocar o funda u obturador o cánula robótica para uso quirúrgico',
	},
	{
		code: '42301500',
		label: 'Ayudas para formación médica',
	},
	{
		code: '42301501',
		label: 'Modelos humanos anatómicos para educación o entrenamiento médico',
	},
	{
		code: '42301502',
		label: 'Maniquíes humanos anatómicos para educación o entrenamiento médico',
	},
	{
		code: '42301503',
		label: 'Ayudas de entrenamiento para resucitación cardiopulmonar cpr',
	},
	{
		code: '42301504',
		label: 'Kits para educación o entrenamiento médico',
	},
	{
		code: '42301505',
		label: 'Portapapeles para enfermeras o médicos',
	},
	{
		code: '42301506',
		label: 'Estetoscopios de doble audífono',
	},
	{
		code: '42301507',
		label: 'Videos de entrenamiento para la educación del personal médico',
	},
	{
		code: '42301508',
		label: 'Videos de operación o de instrucciones para equipos médicos',
	},
	{
		code: '42311500',
		label: 'Vendas y vendajes y productos relacionados',

		palabrasSimilares: 'Vendas, apósitos y productos relacionados',
	},
	{
		code: '42311501',
		label: 'Clips para vendajes o compresas',

		palabrasSimilares: 'Ganchos para vendas elásticas, Material de curación',
	},
	{
		code: '42311502',
		label: 'Kits de inicio de vendajes o compresas',

		palabrasSimilares:
			'Juego de vendas, vendajes y gasas, Material de curación',
	},
	{
		code: '42311503',
		label: 'Enrolladores de vendajes',

		palabrasSimilares: 'Rodillos para vendas',
	},
	{
		code: '42311504',
		label: 'Vendajes o compresas para el cuidado de quemaduras',
	},
	{
		code: '42311505',
		label: 'Vendajes o compresas para uso general',

		palabrasSimilares: 'Material de curación, Vendas de uso general',
	},
	{
		code: '42311506',
		label: 'Vendas o compresas de compresión o presión',
	},
	{
		code: '42311508',
		label: 'Bandejas para compresas',
	},
	{
		code: '42311510',
		label: 'Vendajes de espuma',
	},
	{
		code: '42311511',
		label: 'Vendajes de gasa',

		palabrasSimilares: 'Gasas de uso general, Material de curación',
	},
	{
		code: '42311512',
		label: 'Esponjas de gasa',
	},
	{
		code: '42311513',
		label: 'Compresas de gel',
	},
	{
		code: '42311514',
		label: 'Vendajes germicidas',
	},
	{
		code: '42311515',
		label: 'Vendajes hidrocoloides',
	},
	{
		code: '42311517',
		label: 'Adhesivos líquidos para vendajes o compresas',
	},
	{
		code: '42311518',
		label: 'Parches o almohadillas para los ojos para uso médico',
	},
	{
		code: '42311519',
		label: 'Tiras no adhesivas para uso médico',
	},
	{
		code: '42311520',
		label: 'Cintas no adhesivas para uso médico',
	},
	{
		code: '42311521',
		label: 'Compresas de presión negativa',

		palabrasSimilares: 'Apósitos de presión negativa',
	},
	{
		code: '42311522',
		label: 'Compresas oclusivas',
	},
	{
		code: '42311523',
		label: 'Vendajes de pasta',

		palabrasSimilares: 'Vendas de yeso',
	},
	{
		code: '42311524',
		label: 'Compresas de petrolato',
	},
	{
		code: '42311527',
		label: 'Compresas de película transparente',
	},
	{
		code: '42311528',
		label: 'Sistemas de compresas húmedas',
	},
	{
		code: '42311531',
		label: 'Cubiertas para compresas',
	},
	{
		code: '42311537',
		label: 'Tijeras para vendajes o sus suministros',
	},
	{
		code: '42311538',
		label: 'Kits de hiperalimentación',
	},
	{
		code: '42311539',
		label: 'Aplicadores de vendajes',
	},
	{
		code: '42311540',
		label: 'Compresas de alginato',
	},
	{
		code: '42311541',
		label: 'Compresas biológicas',
	},
	{
		code: '42311542',
		label: 'Compresas de control de olor',
	},
	{
		code: '42311543',
		label: 'Retenedor de vendajes',

		palabrasSimilares: 'Mallas elásticas para vendajes',
	},
	{
		code: '42311600',
		label: 'Agentes hemoestáticos tópicos exógenos',
	},
	{
		code: '42311601',
		label: 'Esponjas de gelatina absorbible',
	},
	{
		code: '42311602',
		label: 'Cera para huesos',
	},
	{
		code: '42311603',
		label: 'Celulosa oxidada',
	},
	{
		code: '42311604',
		label: 'Hemostáticos de colágeno micro fibrilar',
	},
	{
		code: '42311605',
		label: 'Parche no absorbible de trombina',
	},
	{
		code: '42311700',
		label:
			'Cintas adhesivas médicas y quirúrgicas y productos relacionados para el uso en especialidades',
	},
	{
		code: '42311702',
		label: 'Cintas umbilicales para bebés',
	},
	{
		code: '42311703',
		label: 'Cintas médicas o quirúrgicas para pegar la piel',
	},
	{
		code: '42311704',
		label: 'Dispensadores de cinta médica o quirúrgica',
	},
	{
		code: '42311705',
		label: 'Removedores de cinta médica o quirúrgica',
	},
	{
		code: '42311707',
		label: 'Cintas de tejido para uso quirúrgico',
	},
	{
		code: '42311708',
		label: 'Cintas adherentes médicas o quirúrgicas para uso general',
	},
	{
		code: '42311900',
		label:
			'Drenajes médicos de incisión y bolsas de drenaje y depósitos y productos relacionados',
	},
	{
		code: '42311901',
		label: 'Accesorios de drenaje para uso médico',
	},
	{
		code: '42311902',
		label: 'Bolsas o reservorios de drenaje de incisiones para uso médico',
	},
	{
		code: '42311903',
		label: 'Drenajes de incisión para uso médico',
	},
	{
		code: '42312000',
		label: 'Cierre de tejidos médicos y productos relacionados',
	},
	{
		code: '42312001',
		label: 'Cierres de mariposa para piel',
	},
	{
		code: '42312002',
		label: 'Clips para cierre de piel',
	},
	{
		code: '42312003',
		label: 'Tiras de cierre para la piel o para heridas',
	},
	{
		code: '42312004',
		label: 'Removedores de pegamento o adhesivo para uso médico',
	},
	{
		code: '42312005',
		label: 'Adhesivos o pegamentos de cierre de piel para uso médico',
	},
	{
		code: '42312006',
		label: 'Aplicadores de clip para uso interno para uso médico',
	},
	{
		code: '42312007',
		label: 'Clips de uso interno para uso médico',
	},
	{
		code: '42312008',
		label: 'Removedores de ganchos o clips para uso médico',
	},
	{
		code: '42312009',
		label: 'Cosedoras para uso interno',
	},
	{
		code: '42312010',
		label: 'Cosedoras para cierre de piel',
	},
	{
		code: '42312011',
		label: 'Ganchos para uso interno',
	},
	{
		code: '42312012',
		label: 'Ganchos para cierre de piel',
	},
	{
		code: '42312014',
		label: 'Kits de cierre de tejidos o bandejas o paquetes o sets',
	},
	{
		code: '42312100',
		label:
			'Suministros de ostomía y productos no quirúrgicos de drenaje de heridas',
	},
	{
		code: '42312101',
		label: 'Adhesivos para aplicación en ostomía',
	},
	{
		code: '42312102',
		label: 'Aplicaciones para ostomía',
	},
	{
		code: '42312103',
		label: 'Limpiadores o desodorantes para ostomía',
	},
	{
		code: '42312104',
		label: 'Suministros de recolección para ostomía',
	},
	{
		code: '42312105',
		label: 'Kits de barreras de piel o cuidado protector para ostomía',
	},
	{
		code: '42312106',
		label: 'Insertos para ostomía',
	},
	{
		code: '42312107',
		label: 'Obleas para ostomía',
	},
	{
		code: '42312108',
		label: 'Bolsas de drenaje para ostomía',
	},
	{
		code: '42312109',
		label: 'Anillos de bolsa para ostomía',
	},
	{
		code: '42312110',
		label: 'Kits de inicio para ostomía',
	},
	{
		code: '42312111',
		label: 'Fundas de bolsa para ostomía',
	},
	{
		code: '42312112',
		label: 'Cinturón o accesorios para ostomía',
	},
	{
		code: '42312113',
		label: 'Tapones de bolsa para ostomía',
	},
	{
		code: '42312115',
		label: 'Mangas de irrigación para ostomía',
	},
	{
		code: '42312200',
		label: 'Sutura y productos relacionados',
	},
	{
		code: '42312201',
		label: 'Suturas',
	},
	{
		code: '42312202',
		label: 'Kits o bandejas o paquetes o sets para sutura',
	},
	{
		code: '42312203',
		label: 'Botones o puentes o accesorios relacionados para sutura',
	},
	{
		code: '42312204',
		label: 'Botas y dispositivos de captura para sutura',
	},
	{
		code: '42312205',
		label: 'Carritos o estantes para sutura',
	},
	{
		code: '42312206',
		label: 'Agujas para sutura',
	},
	{
		code: '42312207',
		label: 'Kits de remoción o bandejas o paquetes o sets para sutura',
	},
	{
		code: '42312208',
		label: 'Removedores de suturas',
	},
	{
		code: '42312300',
		label: 'Productos para limpiar la herida y desbridamiento',
	},
	{
		code: '42312301',
		label: 'Absorbentes para limpieza de heridas',
	},
	{
		code: '42312302',
		label: 'Esponjas de debridación',
	},
	{
		code: '42312303',
		label:
			'Sistemas de lavado pulsado o accesorios relacionados para el tratamiento de heridas',
	},
	{
		code: '42312304',
		label: 'Productos de debridación autolítica para uso médico',
	},
	{
		code: '42312305',
		label: 'Productos de debridación enzimática para uso médico',
	},
	{
		code: '42312306',
		label: 'Productos de debridación mecánica para uso médico',
	},
	{
		code: '42312307',
		label: 'Productos de debridación quirúrgica para uso médico',
	},
	{
		code: '42312309',
		label: 'Sistemas de irrigación de heridas',
	},
	{
		code: '42312310',
		label: 'Botellas limpiadoras',
	},
	{
		code: '42312311',
		label: 'Kits de desinfectantes',
	},
	{
		code: '42312312',
		label: 'Bandejas de cuidado de heridas o de limpieza',
	},
	{
		code: '42312313',
		label: 'Soluciones de limpieza de heridas',

		palabrasSimilares: 'Soluciones antisépticas',
	},
	{
		code: '42312400',
		label: 'Productos para la curación de heridas',
	},
	{
		code: '42312401',
		label: 'Relleno de alginato de calcio para heridas',
	},
	{
		code: '42312402',
		label: 'Férulas o stents nasales',
	},
	{
		code: '42312403',
		label: 'Tiras de relleno para cuidado de heridas',
	},
	{
		code: '42312500',
		label: 'Dispositivos de sujeción de heridas, y accesorios y suministros',
	},
	{
		code: '42312501',
		label: 'Ligaduras de soporte mamario',
	},
	{
		code: '42312502',
		label: 'Ligaduras abdominales',
	},
	{
		code: '42312503',
		label: 'Soportes para escroto',
	},
	{
		code: '42312504',
		label: 'Prenda de soporte facial',
	},
	{
		code: '42312600',
		label: 'Productos de terapia de presión negativa para heridas',
	},
	{
		code: '42312601',
		label: 'Equipo de terapia de presión negativa para heridas',
	},
	{
		code: '42312602',
		label: 'Kits o sistemas de terapia de presión negativa para heridas',
	},
	{
		code: '42312700',
		label: 'Productos de terapia de oxígeno para heridas tópicas',
	},
	{
		code: '42312701',
		label: 'Cámara de uso único de terapia de oxígeno para heridas tópicas',
	},
	{
		code: '42321500',
		label: 'Implantes de trauma ortopédico',
	},
	{
		code: '42321501',
		label: 'Alambre quirúrgico ortopédico',
	},
	{
		code: '42321502',
		label: 'Guía de alambre ortopédico o gancho guía o varilla guía',
	},
	{
		code: '42321503',
		label: 'Extensor de gancho óseo o sustituto',
	},
	{
		code: '42321504',
		label: 'Clavo intramedular',
	},
	{
		code: '42321505',
		label: 'Placas óseas',
	},
	{
		code: '42321506',
		label: 'Destornillador óseo',
	},
	{
		code: '42321507',
		label: 'Set de fijación interna',
	},
	{
		code: '42321508',
		label: 'Tuercas ortopédicas',
	},
	{
		code: '42321509',
		label: 'Lavadora ortopédica',
	},
	{
		code: '42321510',
		label: 'Tapa ortopédica',
	},
	{
		code: '42321512',
		label: 'Pernos de fijación ósea',
	},
	{
		code: '42321513',
		label: 'Perno o alambre de fijación temporal',
	},
	{
		code: '42321514',
		label: 'Grapa de fijación ósea',
	},
	{
		code: '42321515',
		label: 'Hoja de sierra intramedular',
	},
	{
		code: '42321516',
		label: 'Tornillo de cierre intramedular',
	},
	{
		code: '42321600',
		label: 'Implantes espinales',
	},
	{
		code: '42321601',
		label: 'Disco intervertebral',
	},
	{
		code: '42321602',
		label: 'Cable espinal',
	},
	{
		code: '42321603',
		label: 'Balón de cifoplastia',
	},
	{
		code: '42321604',
		label: 'Dispositivo de unión cruzada espinal',
	},
	{
		code: '42321605',
		label: 'Armazón intracorpórea espinal',
	},
	{
		code: '42321606',
		label: 'Dispositivo de descompresión espinal',
	},
	{
		code: '42321607',
		label: 'Gancho espinal',
	},
	{
		code: '42321608',
		label: 'Dispositivo de fusión intracorpórea',
	},
	{
		code: '42321609',
		label: 'Varilla espinal',
	},
	{
		code: '42321610',
		label: 'Destornillador espinal',
	},
	{
		code: '42321611',
		label: 'Placa espinal',
	},
	{
		code: '42321612',
		label: 'Anillo de manga o tapa de extremo espinal',
	},
	{
		code: '42321613',
		label: 'Tornillo múltiple o bloqueador espinal',
	},
	{
		code: '42321614',
		label: 'Perno espinal',
	},
	{
		code: '42321615',
		label: 'Silla o cuna espinal',
	},
	{
		code: '42321616',
		label: 'Conector o acoplador espinal',
	},
	{
		code: '42321617',
		label: 'Grapa espinal',
	},
	{
		code: '42321618',
		label: 'Lavadora espinal',
	},
	{
		code: '42321619',
		label: 'Restrictor de cemento espinal',
	},
	{
		code: '42321620',
		label: 'Perno espinal',
	},
	{
		code: '42321621',
		label: 'Tornillo o alambre de fijación espinal temporal',
	},
	{
		code: '42321622',
		label: 'Kit o sistema de implante espinal',
	},
	{
		code: '42321700',
		label: 'Implantes de cadera',
	},
	{
		code: '42321701',
		label: 'Tapa acetabular de una pieza',
	},
	{
		code: '42321702',
		label: 'Concha acetabular',
	},
	{
		code: '42321703',
		label: 'Revestimiento de taza acetabular',
	},
	{
		code: '42321704',
		label: 'Cabeza femoral',
	},
	{
		code: '42321705',
		label: 'Centralizador distal de tallo femoral',
	},
	{
		code: '42321706',
		label: 'Extensión de vástago de la cadera femoral',
	},
	{
		code: '42321707',
		label: 'Kit o sistema de reemplazo total de cadera',
	},
	{
		code: '42321708',
		label: 'Vástago de la cadera femoral',
	},
	{
		code: '42321709',
		label: 'Prótesis hemiartroplástica de cadera de una pieza',
	},
	{
		code: '42321710',
		label: 'Adaptador de componente de cadera modular femoral',
	},
	{
		code: '42321711',
		label: 'Componente femoral resurgente',
	},
	{
		code: '42321712',
		label: 'Centralizador proximal de tallo femoral',
	},
	{
		code: '42321713',
		label: 'Tornillo acetabular',
	},
	{
		code: '42321714',
		label: 'Aumento acetabular',
	},
	{
		code: '42321715',
		label: 'Componente de cadera bipolar o unipolar',
	},
	{
		code: '42321716',
		label: 'Restrictor de cemento de canal femoral',
	},
	{
		code: '42321717',
		label: 'Tornillo componente de cadera',
	},
	{
		code: '42321718',
		label: 'Armazón acetabular',
	},
	{
		code: '42321719',
		label: 'Malla acetabular',
	},
	{
		code: '42321720',
		label: 'Cubierta de orificio de tornillo acetabular',
	},
	{
		code: '42321721',
		label: 'Eliminador o tapón de orificio acetabular',
	},
	{
		code: '42321722',
		label: 'Anillo acetabular',
	},
	{
		code: '42321723',
		label: 'Cuello femoral',
	},
	{
		code: '42321800',
		label: 'Implantes de rodilla',
	},
	{
		code: '42321801',
		label: 'Componente de rodilla femoral',
	},
	{
		code: '42321802',
		label: 'Tallo de extensión de rodilla femoral',
	},
	{
		code: '42321803',
		label: 'Tallo de extensión de tibia',
	},
	{
		code: '42321804',
		label: 'Implante de rótula',
	},
	{
		code: '42321805',
		label: 'Placa de base para la tibia',
	},
	{
		code: '42321806',
		label: 'Inserto para la tibia',
	},
	{
		code: '42321808',
		label: 'Kit o sistema de remplazo total de rodilla',
	},
	{
		code: '42321809',
		label: 'Aumento de tibia o fémur o rótula',
	},
	{
		code: '42321810',
		label: 'Tornillo de placa de base de la tibia',
	},
	{
		code: '42321811',
		label: 'Tornillo de conexión de aumento',
	},
	{
		code: '42321812',
		label: 'Espaciador de articulación de rodilla',
	},
	{
		code: '42321813',
		label: 'Cuña de rodilla femoral',
	},
	{
		code: '42321900',
		label: 'Implantes de hombro',
	},
	{
		code: '42321902',
		label: 'Cabeza de húmero',
	},
	{
		code: '42321903',
		label: 'Tallo de húmero',
	},
	{
		code: '42321904',
		label: 'Componente de húmero resurgente',
	},
	{
		code: '42321905',
		label: 'Esfera glenoidea',
	},
	{
		code: '42321906',
		label: 'Componente de fijación glenoidea',
	},
	{
		code: '42321907',
		label: 'Revestimiento o protector de inserto de húmero',
	},
	{
		code: '42321908',
		label: 'Restrictor de cemento para el canal humeral',
	},
	{
		code: '42321909',
		label: 'Concha o receptáculo humeral',
	},
	{
		code: '42321910',
		label: 'Centralizador o manga de tallo humeral',
	},
	{
		code: '42321911',
		label: 'Cuerpo humeral',
	},
	{
		code: '42321912',
		label: 'Kit o sistema de remplazo total o parcial de hombro',
	},
	{
		code: '42322000',
		label: 'Implantes ortopédicos de articulaciones distales',
	},
	{
		code: '42322001',
		label: 'Implante de articulación de tobillo',
	},
	{
		code: '42322002',
		label: 'Implante de articulación de muñeca',
	},
	{
		code: '42322003',
		label: 'Implante de articulación de codo',
	},
	{
		code: '42322004',
		label: 'Implante de articulación de dedo',
	},
	{
		code: '42322005',
		label: 'Implante de articulación de dedos del pie',
	},
	{
		code: '42322006',
		label: 'Varilla o espaciador de tendones',
	},
	{
		code: '42322100',
		label: 'Implantes ortopédicos de prueba',
	},
	{
		code: '42322101',
		label: 'Implante de ensayo de rodilla',
	},
	{
		code: '42322102',
		label: 'Implante de ensayo de cadera',
	},
	{
		code: '42322103',
		label: 'Implante de ensayo de hombro',
	},
	{
		code: '42322104',
		label: 'V de articulación distal',
	},
	{
		code: '42322105',
		label: 'Implante de ensayo de columna',
	},
	{
		code: '42322106',
		label: 'Implante de ensayo de trauma',
	},
	{
		code: '42322200',
		label: 'Productos para la fijación de tejidos blandos',
	},
	{
		code: '42322201',
		label: 'Tornillo de interferencia',
	},
	{
		code: '42322202',
		label: 'Anclaje no suturado de tejidos blandos',
	},
	{
		code: '42322203',
		label: 'Anclaje de sutura',
	},
	{
		code: '42322204',
		label: 'Lavador de fijación de tejidos blandos',
	},
	{
		code: '42322205',
		label: 'Perno de fijación de tejidos blandos',
	},
	{
		code: '42322206',
		label: 'Grapa de tejidos blandos',
	},
	{
		code: '43191500',
		label: 'Dispositivos de comunicación personal',
	},
	{
		code: '43191501',
		label: 'Teléfonos móviles',

		palabrasSimilares: 'Celular o Smartphone',
	},
	{
		code: '43191502',
		label: 'Busca personas',
	},
	{
		code: '43191503',
		label: 'Teléfonos de pago',
	},
	{
		code: '43191504',
		label: 'Teléfonos fijos',
	},
	{
		code: '43191505',
		label: 'Máquinas contestadoras',
	},
	{
		code: '43191507',
		label: 'Teléfonos para propósitos especiales',
	},
	{
		code: '43191508',
		label: 'Teléfonos digitales',
	},
	{
		code: '43191509',
		label: 'Teléfonos análogos',
	},
	{
		code: '43191510',
		label: 'Radios de dos vías',
	},
	{
		code: '43191511',
		label: 'Teléfonos ip',
	},
	{
		code: '43191512',
		label:
			'Teléfonos inalámbricos de telecomunicaciones inalámbricas digitales optimizadas dect',
	},
	{
		code: '43191513',
		label: 'Video teléfono',
	},
	{
		code: '43191514',
		label: 'Teléfono satelital',
	},
	{
		code: '43191515',
		label: 'Teléfono manos libres',
	},
	{
		code: '43191516',
		label: 'Sistema de conmutador telefónico',
	},
	{
		code: '43191600',
		label: 'Partes o accesorios de dispositivos de comunicación personal',
	},
	{
		code: '43191601',
		label: 'Placas frontales de teléfonos móviles',

		palabrasSimilares:
			'Display para celular o Smartphone, Micas para celular o Smartphone',
	},
	{
		code: '43191602',
		label: 'Marcadores de teléfonos',
	},
	{
		code: '43191603',
		label: 'Cables de extensión para teléfonos',
	},
	{
		code: '43191604',
		label: 'Placas frontales de teléfonos',
	},
	{
		code: '43191605',
		label: 'Cables para auriculares de teléfonos',
	},
	{
		code: '43191606',
		label: 'Auriculares de teléfonos',

		palabrasSimilares: 'Diademas para teléfonos',
	},
	{
		code: '43191607',
		label:
			'Almohadillas para el oído o el parlante de los auriculares de teléfonos',
	},
	{
		code: '43191608',
		label: 'Tubos de voz para los auriculares de teléfonos',
	},
	{
		code: '43191609',
		label: 'Teléfonos de diadema',
	},
	{
		code: '43191610',
		label: 'Soportes o sujetadores o puestos de comunicación personal',
	},
	{
		code: '43191611',
		label: 'Protectores de líneas telefónicas',
	},
	{
		code: '43191612',
		label: 'Descansa teléfonos',
	},
	{
		code: '43191614',
		label: 'Convertidores de voz para teléfonos',
	},
	{
		code: '43191615',
		label: 'Set telefónico manos libres para vehículos',
	},
	{
		code: '43191616',
		label: 'Consola central para teléfonos',
	},
	{
		code: '43191618',
		label: 'Unidades de grabación de conversaciones',
	},
	{
		code: '43191619',
		label: 'Dispositivos de señalización para teléfonos',
	},
	{
		code: '43191621',
		label: 'Adaptadores de auriculares',
	},
	{
		code: '43191622',
		label: 'Módulos o accesorios de busca personas',
	},
	{
		code: '43191623',
		label: 'Teléfono de pago con mecanismos',
	},
	{
		code: '43191624',
		label: 'Recipientes de monedas para teléfonos de pago',
	},
	{
		code: '43191625',
		label: 'Cajas de monedas para teléfonos de pago',
	},
	{
		code: '43191626',
		label: 'Tolvas para teléfonos de pago',
	},
	{
		code: '43191627',
		label:
			'Puertas de seguridad para las cajas de monedas de teléfonos de pago',
	},
	{
		code: '43191628',
		label: 'Protectores de micrófono para teléfonos de pago',
	},
	{
		code: '43191629',
		label: 'Pieles o placas frontales para notebook o palm',

		palabrasSimilares: 'Carcasas o micas para tabletas',
	},
	{
		code: '43191630',
		label: 'Kits de iniciación para teléfonos móviles',
	},
	{
		code: '43191631',
		label:
			'Clavijas de adaptadores o kits de campo o kits de viaje para teléfonos o módems',

		palabrasSimilares: 'Cargadores de celular para vehículos',
	},
	{
		code: '43191632',
		label: 'Antenas para teléfonos',
	},
	{
		code: '43191633',
		label: 'Subconjuntos para conmutadores telefónicos',
	},
	{
		code: '43191634',
		label: 'Tablero de extensión para conmutador telefónico',
	},
	{
		code: '43201400',
		label: 'Tarjetas del sistema',
	},
	{
		code: '43201401',
		label: 'Tarjetas aceleradoras de gráficas o video',
	},
	{
		code: '43201402',
		label: 'Tarjetas de módulos de memoria',
	},
	{
		code: '43201403',
		label: 'Tarjetas de módem',
	},
	{
		code: '43201404',
		label: 'Tarjetas de interface de red',
	},
	{
		code: '43201405',
		label: 'Tarjetas receptoras de red óptica',
	},
	{
		code: '43201406',
		label: 'Tarjetas transmisoras de red óptica',
	},
	{
		code: '43201407',
		label: 'Tarjetas periféricas controladoras',
	},
	{
		code: '43201409',
		label: 'Tarjetas de interface de red inalámbrica',
	},
	{
		code: '43201410',
		label: 'Tarjetas o puertos de interruptor',
	},
	{
		code: '43201411',
		label: 'Tarjeta de interconexión de componente periférico pci',
	},
	{
		code: '43201412',
		label: 'Tarjeta pcmcia o pc',
	},
	{
		code: '43201413',
		label: 'Tarjeta debanda magnética',
	},
	{
		code: '43201414',
		label: 'Protector de disco duro',
	},
	{
		code: '43201415',
		label: 'Tarjeta de módulo de identidad del suscriptor sim',
	},
	{
		code: '43201416',
		label: 'Tablero de adquisición de datos',
	},
	{
		code: '43201417',
		label: 'Tableros multimedia integrados',
	},
	{
		code: '43201500',
		label:
			'Módulos o interfaces de procesadores de tablero (board) del sistema',
	},
	{
		code: '43201501',
		label:
			'Tarjetas de interface de telecomunicaciones de modo de transferencia asincrónico atm',
	},
	{
		code: '43201502',
		label: 'Tarjetas de aceleración de audio',
	},
	{
		code: '43201503',
		label: 'Procesadores de unidad de procesamiento central cpu',
	},
	{
		code: '43201507',
		label: 'Placas secundarias',
	},
	{
		code: '43201508',
		label: 'Módulo de fibra de compensación de dispersión dcfm',
	},
	{
		code: '43201509',
		label: 'Módulos de intercambio de comunicación de datos',
	},
	{
		code: '43201513',
		label: 'Placa central de microordenador.',
	},
	{
		code: '43201522',
		label: 'Tarjetas de puertos paralelos',
	},
	{
		code: '43201531',
		label: 'Tarjetas de captura de video',
	},
	{
		code: '43201533',
		label: 'Interfaces de interface digital de instrumentos musicales midi',
	},
	{
		code: '43201534',
		label: 'Interfaces de componentes de intercambio codec',
	},
	{
		code: '43201535',
		label: 'Puertos seriales infrarrojos',
	},
	{
		code: '43201537',
		label: 'Servidores de impresoras',
	},
	{
		code: '43201538',
		label: 'Enfriadores de unidades de procesamiento central',
	},
	{
		code: '43201539',
		label: 'Procesador central controlador de consola',
	},
	{
		code: '43201540',
		label: 'Convertidor de canales',
	},
	{
		code: '43201541',
		label: 'Procesador central de interface canal a canal',
	},
	{
		code: '43201542',
		label: 'Unidad de control',
	},
	{
		code: '43201543',
		label: 'Procesador central de instalación de acoplamientos',
	},
	{
		code: '43201544',
		label: 'Convertidor o controlador de bus de interface',
	},
	{
		code: '43201545',
		label: 'Tarjetas de fax',
	},
	{
		code: '43201546',
		label: 'Tarjetas de audio conferencia',
	},
	{
		code: '43201547',
		label: 'Tarjetas de voz',
	},
	{
		code: '43201549',
		label: 'Interruptores de interface de bus',
	},
	{
		code: '43201550',
		label: 'Dispositivo de sincronización de paquete de datos de red',
	},
	{
		code: '43201552',
		label: 'Adaptadores para hardware o telefonía',
	},
	{
		code: '43201553',
		label: 'Convertidores de transceptores y medios',
	},
	{
		code: '43201554',
		label: 'Sintonizadores de televisión personal por computador pc tv',
	},
	{
		code: '43201555',
		label: 'Sintonizadores de radio para pc',
	},
	{
		code: '43201556',
		label:
			'Adaptadores de interconexión de sistemas para pequeños computadores scsi',
	},
	{
		code: '43201557',
		label: 'Controladores de arreglo redundante de discos independientes raid',
	},
	{
		code: '43201558',
		label: 'Controlador de canales de fibra',
	},
	{
		code: '43201559',
		label: 'Tarjetas de puerto serial',
	},
	{
		code: '43201560',
		label: 'Coprocesador',
	},
	{
		code: '43201600',
		label: 'Componentes del chasis',
	},
	{
		code: '43201601',
		label: 'Carcasas de computadoras',
	},
	{
		code: '43201602',
		label: 'Carcasas de equipos de red',
	},
	{
		code: '43201603',
		label: 'Componentes de apilamiento de carcasas',
	},
	{
		code: '43201604',
		label: 'Bahías o canastas para equipos electrónicos',
	},
	{
		code: '43201605',
		label: 'Expansores',
	},
	{
		code: '43201608',
		label: 'Marcos de drive removibles',
	},
	{
		code: '43201609',
		label: 'Bandejas o ensamblajes para almacenamiento de dispositivos',
	},
	{
		code: '43201610',
		label: 'Placas traseras o paneles o ensamblajes',
	},
	{
		code: '43201611',
		label: 'Bastidores (dispositivos de montaje) para computadores',
	},
	{
		code: '43201612',
		label: 'Placas frontales para computadores',
	},
	{
		code: '43201614',
		label: 'Extensores de consola',
	},
	{
		code: '43201615',
		label: 'Kits de cubiertas para bahías de drives',
	},
	{
		code: '43201616',
		label: 'Torres de arreglo de drives de discos duros',
	},
	{
		code: '43201617',
		label: 'Armazones para tarjetas',
	},
	{
		code: '43201618',
		label: 'Componente de rack de computador',
	},
	{
		code: '43201619',
		label: 'Ventilador enfriador de computador',
	},
	{
		code: '43201800',
		label: 'Dispositivos de almacenamiento',
	},
	{
		code: '43201801',
		label: 'Unidades de disco flexible',
	},
	{
		code: '43201802',
		label: 'Series de disco duro',
	},
	{
		code: '43201803',
		label: 'Unidades de disco duro',
	},
	{
		code: '43201806',
		label: 'Series de cintas',
	},
	{
		code: '43201807',
		label: 'Unidades de cintas',
	},
	{
		code: '43201808',
		label: 'Disco compacto cd de sólo lectura',
	},
	{
		code: '43201809',
		label: 'Disco compacto cd de lectura y escritura',
	},
	{
		code: '43201810',
		label: 'Disco versátil digital dvd de sólo lectura',
	},
	{
		code: '43201811',
		label: 'Disco versátil digital dvd de lectura y escritura',
	},
	{
		code: '43201812',
		label: 'Unidades de magneto óptico mo',
	},
	{
		code: '43201813',
		label: 'Unidades de medios removibles de alta capacidad',
	},
	{
		code: '43201814',
		label: 'Equipos electrónicos de duplicación de medios o información',
	},
	{
		code: '43201815',
		label:
			'Unidades de lectura y escritura de arquitectura de interconexión de componentes periféricos',
	},
	{
		code: '43201816',
		label:
			'Unidades ópticas de ultra densidad udo o autocargardores o bibliotecas',
	},
	{
		code: '43201817',
		label: 'Unidades de disco compacto cd',
	},
	{
		code: '43201818',
		label: 'Unidades de discos digitales de video dvd',
	},
	{
		code: '43201819',
		label: 'Disco versátil digital de alta definición hd dvd de sólo lectura',
	},
	{
		code: '43201820',
		label:
			'Disco versátil digital de alta definición hd dvd de lectura y escritura',
	},
	{
		code: '43201821',
		label: 'Disco de blu-ray bd de sólo lectura',
	},
	{
		code: '43201822',
		label: 'Disco de blu-ray br de lectura y escritura',
	},
	{
		code: '43201823',
		label: 'Reproductor de unidades multimedia de disco duro hdd',
	},
	{
		code: '43201825',
		label: 'Publicador de discos',
	},
	{
		code: '43201826',
		label: 'Series de memoria de discos compactos cd de sólo lectura',
	},
	{
		code: '43201827',
		label: 'Dispositivo de almacenamiento de disco duro portátil',
	},
	{
		code: '43201828',
		label: 'Audio disco de video digital dvd a',
	},
	{
		code: '43201829',
		label: 'Video disco de video digital dvd v',
	},
	{
		code: '43201830',
		label: 'Unidad de estado sólido ssd',
	},
	{
		code: '43201831',
		label: 'Unidad de memoria de telecomunicaciones',
	},
	{
		code: '43201832',
		label: 'Cargador de discos (juke box) compactos u ópticos',
	},
	{
		code: '43201833',
		label: 'Sistema de conversión de digital a análogo',
	},
	{
		code: '43201834',
		label: 'Controlador de dispositivo de almacenamiento',
	},
	{
		code: '43201835',
		label: 'Dispositivo de almacenamiento unido a la red nas',
	},
	{
		code: '43201900',
		label: 'Accesorios de dispositivos de almacenamiento',
	},
	{
		code: '43201902',
		label: 'Cambiadores de discos ópticos',
	},
	{
		code: '43201903',
		label: 'Bibliotecas de unidades de cinta',
	},
	{
		code: '43201904',
		label: 'Audio video de video discos digitales dvd av',
	},
	{
		code: '43202000',
		label: 'Dispositivos de almacenamiento extraíbles',
	},
	{
		code: '43202001',
		label: 'Discos compactos cd',
	},
	{
		code: '43202002',
		label: 'Cintas en blanco',
	},
	{
		code: '43202003',
		label: 'Discos versátiles digitales dvd',
	},
	{
		code: '43202004',
		label: 'Discos flexibles',
	},
	{
		code: '43202005',
		label: 'Tarjeta flash de almacenamiento de memoria',
	},
	{
		code: '43202006',
		label: 'Discos magneto ópticos mo',
	},
	{
		code: '43202007',
		label: 'Discos en blanco de medios removibles de gran capacidad',
	},
	{
		code: '43202008',
		label: 'Discos en blanco de alta densidad óptica udo',
	},
	{
		code: '43202009',
		label: 'Cintas de audio en blanco',
	},
	{
		code: '43202010',
		label: 'Unidad pen o flash',
	},
	{
		code: '43202100',
		label: 'Accesorios de dispositivos de almacenamiento extraíbles',
	},
	{
		code: '43202101',
		label: 'Estuches para discos compactos',
	},
	{
		code: '43202102',
		label: 'Estuches para discos flexibles',
	},
	{
		code: '43202103',
		label: 'Sujetadores de almacenadores de multimedia',
	},
	{
		code: '43202104',
		label:
			'Almacenadores para video cintas de escáner de hélice vertical vhs o accesorios',
	},
	{
		code: '43202105',
		label: 'Gabinetes para medios múltiples',
	},
	{
		code: '43202106',
		label: 'Estuches o billeteras para medios portátiles',
	},
	{
		code: '43202107',
		label: 'Maleta para discos compactos o discos digitales versátiles cd/dvd',
	},
	{
		code: '43202108',
		label: 'Estuche para tarjeta de memoria',
	},
	{
		code: '43202200',
		label: 'Subconjuntos para dispositivos electrónicos',

		palabrasSimilares: 'Accesorios de computadora',
	},
	{
		code: '43202201',
		label: 'Partes de piezas de teléfono',
	},
	{
		code: '43202202',
		label: 'Generadores de timbres de teléfono',
	},
	{
		code: '43202204',
		label: 'Timbres externos o sus partes',
	},
	{
		code: '43202205',
		label: 'Teclas o teclados',
	},
	{
		code: '43202206',
		label: 'Componentes de dispositivo de entrada o unidad de almacenamiento',
	},
	{
		code: '43202207',
		label: 'Brazos y ensamblaje de cabezal',
	},
	{
		code: '43202208',
		label: 'Ensamblajes de plomo',
	},
	{
		code: '43202209',
		label: 'Ensamblajes de apilamiento de cabezales',
	},
	{
		code: '43202210',
		label: 'Tope de protección anti choque',
	},
	{
		code: '43202211',
		label: 'Platos o discos',
	},
	{
		code: '43202212',
		label: 'Ensamblajes de cabezal de lectura y escritura',
	},
	{
		code: '43202213',
		label: 'Unidades de disco de motor',
	},
	{
		code: '43202214',
		label: 'Ensamblajes de regleta',
	},
	{
		code: '43202215',
		label: 'Cable de teclado o mouse',
	},
	{
		code: '43202216',
		label: 'Cable de conexión de impresora',
	},
	{
		code: '43202217',
		label: 'Sujetador de cables de computador',
	},
	{
		code: '43202218',
		label: 'Cubierta de cables de computador',
	},
	{
		code: '43202219',
		label:
			'Ensamblaje de cabezales de control de aire acondicionado de ventilación de automotores hvac',
	},
	{
		code: '43202220',
		label:
			'Módulo de energía de aire acondicionado de ventilación de automotores hvac',
	},
	{
		code: '43202221',
		label:
			'Ensamblaje de resistencias de aire acondicionado de ventilación de automotores hvac',
	},
	{
		code: '43202222',
		label: 'Cables de computador',

		palabrasSimilares: 'Cables de computadora',
	},
	{
		code: '43211500',
		label: 'Computadores',

		palabrasSimilares: 'Computadoras',
	},
	{
		code: '43211501',
		label: 'Servidores de computador',

		palabrasSimilares: 'Servidores de computadoras',
	},
	{
		code: '43211502',
		label: 'Servidores de computador de gama alta',

		palabrasSimilares: 'Servidores de computadoras de gama alta',
	},
	{
		code: '43211503',
		label: 'Computadores notebook',

		palabrasSimilares: 'Computadoras notebook',
	},
	{
		code: '43211504',
		label: 'Asistentes personales digitales pda u organizadores',

		palabrasSimilares: 'Agendas u organizadores digitales',
	},
	{
		code: '43211505',
		label: 'Terminal de punto de venta pos',
	},
	{
		code: '43211506',
		label: 'Computadores de cliente ligero',

		palabrasSimilares: 'Ordenadores ligeros',
	},
	{
		code: '43211507',
		label: 'Computadores de escritorio',

		palabrasSimilares: 'Computadora de escritorio, Equipo de cómputo',
	},
	{
		code: '43211508',
		label: 'Computadores personales',

		palabrasSimilares: 'Computadoras personales o laptop, Equipo de cómputo',
	},
	{
		code: '43211509',
		label: 'Computadores de tableta',

		palabrasSimilares: 'Tabletas',
	},
	{
		code: '43211510',
		label: 'Consola central o terminales básicos (no inteligentes)',
	},
	{
		code: '43211511',
		label: 'Dispositivos de computación de vestir',
	},
	{
		code: '43211512',
		label: 'Computadores centrales',

		palabrasSimilares: 'Computadoras centrales',
	},
	{
		code: '43211513',
		label: 'Computadores personales ultra móviles',

		palabrasSimilares: 'Computadoras personales ultrabook',
	},
	{
		code: '43211514',
		label: 'Kioscos de computadores',

		palabrasSimilares: 'Kioscos de computadoras',
	},
	{
		code: '43211515',
		label: 'Estaciones de trabajo para computadores',

		palabrasSimilares: 'Estaciones de trabajo para computadoras',
	},
	{
		code: '43211516',
		label: 'Minicomputadores',

		palabrasSimilares: 'Minicomputadoras',
	},
	{
		code: '43211517',
		label: 'Computadores análogos',

		palabrasSimilares: 'Computadoras análogas',
	},
	{
		code: '43211518',
		label: 'Computadores de múltiples pantallas',

		palabrasSimilares: 'Computadoras de múltiples pantallas',
	},
	{
		code: '43211600',
		label: 'Accesorios de computador',

		palabrasSimilares: 'Accesorios de computadora',
	},
	{
		code: '43211601',
		label: 'Cajas de interruptores de computador',

		palabrasSimilares: 'Cajas de interruptores de computadora',
	},
	{
		code: '43211602',
		label: 'Estaciones de acoplamiento',
	},
	{
		code: '43211603',
		label: 'Replicadores de puertos',
	},
	{
		code: '43211604',
		label: 'Cajas de interruptores periféricos',
	},
	{
		code: '43211605',
		label: 'Actualizaciones de procesadores de señales',
	},
	{
		code: '43211606',
		label: 'Kits de multimedia',
	},
	{
		code: '43211607',
		label: 'Parlantes de computador',

		palabrasSimilares: 'Altavoz para computadora, Bocina para computadora',
	},
	{
		code: '43211608',
		label: 'Equipo codificador y decodificador',
	},
	{
		code: '43211609',
		label: 'Concentrador de bus serial universal o conectores',
	},
	{
		code: '43211610',
		label: 'Kits de países o localización para computadores',
	},
	{
		code: '43211611',
		label: 'Kits de arranque de mano pda',
	},
	{
		code: '43211612',
		label: 'Kits de accesorios de computador',

		palabrasSimilares: 'Kits de accesorios para computadoras',
	},
	{
		code: '43211613',
		label: 'Puestos para computadores o notebooks',

		palabrasSimilares: 'Puestos para computadoras o notebooks',
	},
	{
		code: '43211614',
		label: 'Adaptador de bus serial universal usb bluetooth',
	},
	{
		code: '43211615',
		label: 'Puerto de expansión de computador notebook',

		palabrasSimilares: 'Puerto de expansión de computadora notebook',
	},
	{
		code: '43211616',
		label: 'Sujetador de asistente personal digital pda',
	},
	{
		code: '43211617',
		label: 'Cable de extensión de bus serial universal usb',
	},
	{
		code: '43211618',
		label: 'Módem de radio servicio de paquete general de bus serial universal',
	},
	{
		code: '43211619',
		label: 'Estuche para transportar computadores notebook',

		palabrasSimilares: 'Estuche para transportar computadoras notebook',
	},
	{
		code: '43211700',
		label: 'Dispositivos informáticos de entrada de datos',
	},
	{
		code: '43211701',
		label: 'Equipo de lectura de código de barras',
	},
	{
		code: '43211702',
		label: 'Lectores y codificadores de banda magnética',
	},
	{
		code: '43211704',
		label: 'Equipo de reconocimiento de billetes',
	},
	{
		code: '43211705',
		label: 'Almohadillas o joy sticks de juegos',
	},
	{
		code: '43211706',
		label: 'Teclados',
	},
	{
		code: '43211707',
		label: 'Lápiz (stylus) para computador de luz',
	},
	{
		code: '43211708',
		label: 'Mouse o bola de seguimiento para computador',
	},
	{
		code: '43211709',
		label: 'Lápiz (stylus) de presión',
	},
	{
		code: '43211710',
		label: 'Dispositivos de identificación de radio frecuencia',
	},
	{
		code: '43211711',
		label: 'Escáneres',
	},
	{
		code: '43211712',
		label: 'Tabletas de gráficos',
	},
	{
		code: '43211713',
		label: 'Almohadillas (pads) táctil (touch)',
	},
	{
		code: '43211714',
		label: 'Equipos de identificación biométrica',
	},
	{
		code: '43211715',
		label: 'Terminales portátiles de ingreso de información',
	},
	{
		code: '43211717',
		label: 'Sistemas de reconocimiento de caracteres ópticos',
	},
	{
		code: '43211718',
		label:
			'Sistemas de visión basados en cámaras para recolección automática de información',
	},
	{
		code: '43211719',
		label: 'Micrófonos de voz para computadores',
	},
	{
		code: '43211720',
		label: 'Terminales de pago para puntos de venta',
	},
	{
		code: '43211721',
		label: 'Lectores de tarjetas perforadas',
	},
	{
		code: '43211722',
		label: 'Escáneres de tarjetas de presentación',
	},
	{
		code: '43211723',
		label: 'Equipos de votación electrónica o de conteo de votos',
	},
	{
		code: '43211724',
		label: 'Kit periférico para computador',
	},
	{
		code: '43211725',
		label:
			'Limpiador de discos compactos o de discos versátiles digitales cd/dvd',
	},
	{
		code: '43211726',
		label: 'Esferográfico digital',
	},
	{
		code: '43211727',
		label:
			'Dispositivo magnético de reconocimiento de caracteres en tinta micr',
	},
	{
		code: '43211728',
		label: 'Terminal simple de tarjetas inteligentes',
	},
	{
		code: '43211729',
		label: 'Lector de marcas ópticas',
	},
	{
		code: '43211730',
		label: 'Sistema de adquisición de datos',
	},
	{
		code: '43211731',
		label: 'Analizador de datos',
	},
	{
		code: '43211732',
		label: 'Lector de películas digitales',
	},
	{
		code: '43211800',
		label: 'Accesorios de dispositivos de entrada de datos de computador',
	},
	{
		code: '43211801',
		label: 'Forros de dispositivos de ingreso de datos al computador',
	},
	{
		code: '43211802',
		label: 'Almohadillas (pads) para mouse',
	},
	{
		code: '43211803',
		label: 'Pieles (películas transparentes) para teclados',
	},
	{
		code: '43211804',
		label: 'Cajones o estantes para tableros',
	},
	{
		code: '43211805',
		label: 'Dispositivos para almacenamiento de kits de servicio',
	},
	{
		code: '43211806',
		label: 'Descansa muñecas para teclado',
	},
	{
		code: '43211807',
		label: 'Descansa muñecas para mouse',
	},
	{
		code: '43211900',
		label: 'Monitores y pantallas de computador',
	},
	{
		code: '43211901',
		label: 'Monitores para tubos de rayo catódico crt',
	},
	{
		code: '43211902',
		label: 'Paneles o monitores de pantalla de cristal líquido lcd',
	},
	{
		code: '43211903',
		label: 'Monitores de pantalla táctil (touch)',
	},
	{
		code: '43211904',
		label: 'Paneles de pantalla de plasma pdp',
	},
	{
		code: '43211905',
		label: 'Pantallas emisoras de luz orgánica',
	},
	{
		code: '43211906',
		label: 'Pantallas de caracteres',
	},
	{
		code: '43211907',
		label: 'Cascos de realidad virtual',
	},
	{
		code: '43211908',
		label: 'Pantallas de vacío fluorescente',
	},
	{
		code: '43211909',
		label: 'Pantallas emisoras de diodos de poli luz',
	},
	{
		code: '43211910',
		label: 'Mecanismo de inclinación de pantallas',
	},
	{
		code: '43211911',
		label: 'Vidrio de pantalla táctil (touch)',
	},
	{
		code: '43211912',
		label: 'Película de pantalla táctil (touch)',
	},
	{
		code: '43211913',
		label: 'Pantalla activa lcd',
	},
	{
		code: '43211914',
		label: 'Pantalla pasiva lcd',
	},
	{
		code: '43212000',
		label: 'Accesorios de pantallas de computador',
	},
	{
		code: '43212001',
		label: 'Protectores de brillo para pantallas de computador',
	},
	{
		code: '43212002',
		label: 'Brazos o soportes para monitores',
	},
	{
		code: '43212100',
		label: 'Impresoras de computador',
	},
	{
		code: '43212101',
		label: 'Impresoras de banda',
	},
	{
		code: '43212102',
		label: 'Impresoras de matriz de puntos',
	},
	{
		code: '43212103',
		label: 'Impresoras de sublimación de teñido',
	},
	{
		code: '43212104',
		label: 'Impresoras de inyección de tinta',
	},
	{
		code: '43212105',
		label: 'Impresoras láser',
	},
	{
		code: '43212106',
		label: 'Impresoras de matriz de líneas',
	},
	{
		code: '43212107',
		label: 'Impresoras de plotter',
	},
	{
		code: '43212108',
		label: 'Impresoras de cinta térmica',
	},
	{
		code: '43212109',
		label: 'Impresoras de etiquetas para bolsas',
	},
	{
		code: '43212110',
		label: 'Impresoras de múltiples funciones',
	},
	{
		code: '43212111',
		label: 'Impresoras de tiquetes aéreos o pases de abordaje atb',
	},
	{
		code: '43212112',
		label: 'Impresoras de recibos para puntos de venta pos',
	},
	{
		code: '43212113',
		label: 'Impresoras de discos compactos cd o de etiquetado',
	},
	{
		code: '43212114',
		label: 'Impresoras de imágenes digitales',
	},
	{
		code: '43212115',
		label: 'Impresoras de códigos de barra',
	},
	{
		code: '43212116',
		label: 'Impresoras de etiquetas de identificación de radio frecuencia rfid',
	},
	{
		code: '43212117',
		label: 'Controlador para impresoras',
	},
	{
		code: '43212200',
		label: 'Sistemas de manejo de almacenamiento de datos de computador',
	},
	{
		code: '43212201',
		label: 'Sistema de almacenamiento virtual',
	},
	{
		code: '43221500',
		label: 'Sistemas de gestión de llamadas o accesorios',
	},
	{
		code: '43221501',
		label: 'Sistemas de atención automatizada',
	},
	{
		code: '43221502',
		label: 'Distribuidor automático de llamadas acd',
	},
	{
		code: '43221503',
		label: 'Alto parlantes para telecomunicaciones',
	},
	{
		code: '43221504',
		label: 'Central telefónica interna pbx',
	},
	{
		code: '43221505',
		label: 'Identificador de llamadas telefónicas autónoma',
	},
	{
		code: '43221506',
		label: 'Consola de teleconferencias',
	},
	{
		code: '43221507',
		label: 'Auto marcadores',
	},
	{
		code: '43221508',
		label: 'Campos de lámpara de teléfono ocupado',
	},
	{
		code: '43221509',
		label: 'Sistemas de contabilización de llamadas telefónicas',
	},
	{
		code: '43221510',
		label: 'Desviador o retransmisor de llamadas telefónicas',
	},
	{
		code: '43221513',
		label: 'Secuenciadores de llamadas telefónicas',
	},
	{
		code: '43221514',
		label: 'Unidades de respaldo de marcación telefónica',
	},
	{
		code: '43221515',
		label: 'Dispositivos para compartir líneas telefónicas',
	},
	{
		code: '43221516',
		label: 'Monitores de estado de líneas telefónicas',
	},
	{
		code: '43221517',
		label: 'Unidades de observación de servicio de equipos de telefonía',
	},
	{
		code: '43221518',
		label: 'Dispositivos para restringir llamadas para equipos de telefonía',
	},
	{
		code: '43221519',
		label: 'Sistemas de correo de voz',
	},
	{
		code: '43221520',
		label: 'Equipos interactivos de reconocimiento de voz',
	},
	{
		code: '43221521',
		label: 'Unidades de acceso remoto de telecomunicaciones',
	},
	{
		code: '43221522',
		label: 'Equipos de teleconferencia',
	},
	{
		code: '43221523',
		label: 'Grabadora de música o mensaje en espera',
	},
	{
		code: '43221524',
		label: 'Adaptador de música en espera',
	},
	{
		code: '43221525',
		label: 'Sistemas de intercomunicación',
	},
	{
		code: '43221526',
		label: 'Sistemas de entrada de teléfono',
	},
	{
		code: '43221527',
		label: 'Protector de “chuzadas” (acceso ilegal) de cable',
	},
	{
		code: '43221528',
		label: 'Unidad de señales de supervisión',
	},
	{
		code: '43221529',
		label: 'Medidor de llamadas',
	},
	{
		code: '43221530',
		label: 'Dispositivo de señales de conmutador',
	},
	{
		code: '43221600',
		label:
			'Componentes y equipo de acceso de abonado de línea digital (DSL) y accesorios',
	},
	{
		code: '43221601',
		label:
			'Separador de sistema de antiguo teléfono simple de empresa cautiva pots de circuito de subscriptor digital dsl',
	},
	{
		code: '43221602',
		label:
			'Estante para separador de empresa cautiva de circuito de subscriptor digital dsl',
	},
	{
		code: '43221603',
		label:
			'Separador de sistema de antiguo teléfono simple pots de equipo de premisa de cliente cpe de circuito de subscriptor digital dsl',
	},
	{
		code: '43221604',
		label: 'Equipo de circuito o línea de subscriptor digital xdsl',
	},
	{
		code: '43221700',
		label: 'Equipo fijo de red y componentes',
	},
	{
		code: '43221701',
		label: 'Equipo central de televisión',
	},
	{
		code: '43221702',
		label: 'Equipo de acceso de televisión',
	},
	{
		code: '43221703',
		label: 'Antenas de televisión',
	},
	{
		code: '43221704',
		label: 'Equipo central de radio',
	},
	{
		code: '43221705',
		label: 'Equipo de radio acceso',
	},
	{
		code: '43221706',
		label: 'Antenas de radio',
	},
	{
		code: '43221707',
		label: 'Equipo central de microondas',
	},
	{
		code: '43221708',
		label: 'Equipo de acceso de microondas',
	},
	{
		code: '43221709',
		label: 'Antenas de microondas',
	},
	{
		code: '43221710',
		label: 'Equipo central de satélite',
	},
	{
		code: '43221711',
		label: 'Equipo de acceso de satélite',
	},
	{
		code: '43221712',
		label: 'Antenas de satélite',
	},
	{
		code: '43221713',
		label: 'Equipo central de onda corta',
	},
	{
		code: '43221714',
		label: 'Equipo de acceso de onda corta',
	},
	{
		code: '43221715',
		label: 'Antenas de onda corta',
	},
	{
		code: '43221716',
		label: 'Equipo central de buscapersonas',
	},
	{
		code: '43221717',
		label: 'Equipo de acceso de buscapersonas',
	},
	{
		code: '43221718',
		label: 'Antenas de radar',
	},
	{
		code: '43221719',
		label: 'Antenas de aeronaves',
	},
	{
		code: '43221720',
		label: 'Antenas de automotores',
	},
	{
		code: '43221721',
		label: 'Equipo de comunicación de información de radio frecuencia',
	},
	{
		code: '43221722',
		label: 'Antena de sistema de posicionamiento geográfico gps',
	},
	{
		code: '43221723',
		label: 'Antena de celular',
	},
	{
		code: '43221724',
		label: 'Generador sincronizado de tiempo',
	},
	{
		code: '43221725',
		label: 'Divisor de señales',
	},
	{
		code: '43221726',
		label: 'Reflector para antenas',
	},
	{
		code: '43221727',
		label: 'Accesorio para antenas',
	},
	{
		code: '43221728',
		label: 'Radomos o domo para radares',
	},
	{
		code: '43221729',
		label: 'Antenas portátiles',
	},
	{
		code: '43221730',
		label: 'Transmisor de telecomunicaciones por cable',
	},
	{
		code: '43221731',
		label: 'Sistema de comunicación bajo el agua',
	},
	{
		code: '43221732',
		label: 'Unidad de control de altavoces',
	},
	{
		code: '43221733',
		label: 'Sistema de medición de lectura automática remota',
	},
	{
		code: '43221800',
		label: 'Dispositivos de red óptica',
	},
	{
		code: '43221801',
		label: 'Amplificadores ópticos',
	},
	{
		code: '43221802',
		label: 'Filtros de redes o comunicaciones ópticas',
	},
	{
		code: '43221803',
		label: 'Adaptadores ópticos',
	},
	{
		code: '43221804',
		label: 'Láseres de red óptica',
	},
	{
		code: '43221805',
		label: 'Equipo de red de modo de transferencia asincrónica atm',
	},
	{
		code: '43221806',
		label: 'Equipos de red de red óptica sincrónica sonet',
	},
	{
		code: '43221807',
		label:
			'Filtros multiplexados de división de longitud de onda densa de telecomunicaciones dwdm',
	},
	{
		code: '43221808',
		label:
			'Equipos de telecomunicaciones jerárquicas digitales sincrónicas sdh',
	},
	{
		code: '43221809',
		label: 'Transmisor óptico',
	},
	{
		code: '43221810',
		label: 'Multiplexador óptico',
	},
	{
		code: '43221811',
		label: 'Interruptor óptico',
	},
	{
		code: '43222500',
		label: 'Equipo de seguridad de red',
	},
	{
		code: '43222501',
		label: 'Equipo de seguridad de red cortafuegos (firewall)',
	},
	{
		code: '43222502',
		label: 'Equipo de seguridad de redes vpn',
	},
	{
		code: '43222503',
		label: 'Equipos de seguridad de evaluación de vulnerabilidad',
	},
	{
		code: '43222504',
		label: 'Transmisor de interferencias de radio',
	},
	{
		code: '43222600',
		label: 'Equipo de servicio de red',
	},
	{
		code: '43222602',
		label: 'Equipo de centro distribuidor de cables',
	},
	{
		code: '43222604',
		label: 'Equipo de red de entrega de contenido',
	},
	{
		code: '43222605',
		label: 'Puerta de acceso de redes',
	},
	{
		code: '43222606',
		label: 'Kit de inicio de nodo de servicio de internet',
	},
	{
		code: '43222607',
		label: 'Equipo de motor de caché',
	},
	{
		code: '43222608',
		label: 'Repetidores de red',
	},
	{
		code: '43222609',
		label: 'Enrutadores (routers) de red',
	},
	{
		code: '43222610',
		label: 'Concentradores o hubs de servicio de red',
	},
	{
		code: '43222611',
		label: 'Unidades de servicio de canales o información de red',
	},
	{
		code: '43222612',
		label: 'Interruptores de red',
	},
	{
		code: '43222615',
		label: 'Interruptor de área de almacenamiento de red san',
	},
	{
		code: '43222619',
		label: 'Equipo de video de red',
	},
	{
		code: '43222620',
		label: 'Interruptor multi servicios',
	},
	{
		code: '43222621',
		label: 'Interruptor de contenido',
	},
	{
		code: '43222622',
		label: 'Dispositivo para balancear la carga del servidor',
	},
	{
		code: '43222623',
		label: 'Equipos de conexión cruzada digital dcx',
	},
	{
		code: '43222624',
		label: 'Equipos de conexión cruzada óptica',
	},
	{
		code: '43222625',
		label: 'Servidores de acceso',
	},
	{
		code: '43222626',
		label: 'Módems de cable',
	},
	{
		code: '43222627',
		label:
			'Dispositivos de acceso de redes digitales de servicios integrados isdn',
	},
	{
		code: '43222628',
		label: 'Módems',
	},
	{
		code: '43222629',
		label: 'Bancos de módems',
	},
	{
		code: '43222630',
		label: 'Unidades de acceso multi estaciones',
	},
	{
		code: '43222631',
		label: 'Estaciones base de fidelidad inalámbricas',
	},
	{
		code: '43222632',
		label: 'Agregadores de banda ancha',
	},
	{
		code: '43222633',
		label: 'Adaptadores de manejo remoto',
	},
	{
		code: '43222634',
		label: 'Dispositivo de monitoreo o manejo de redes',
	},
	{
		code: '43222635',
		label: 'Kit de actualización de equipo de redes',
	},
	{
		code: '43222636',
		label: 'Motor de aplicación de redes',
	},
	{
		code: '43222637',
		label: 'Red óptica pasiva de gigabytes gpon',
	},
	{
		code: '43222638',
		label: 'Equipos de red de línea dedicada manejada mlln',
	},
	{
		code: '43222639',
		label: 'Hardware de subsistemas multimedia de protocolo de internet ip',
	},
	{
		code: '43222640',
		label: 'Punto de acceso inalámbrico',
	},
	{
		code: '43222641',
		label: 'Dispositivo de protocolo de internet compartido',
	},
	{
		code: '43222642',
		label: 'Interruptor de enrutador (router)',
	},
	{
		code: '43222643',
		label: 'Probador de cable de red',
	},
	{
		code: '43222644',
		label: 'Controlador de tráfico de red',
	},
	{
		code: '43222700',
		label: 'Equipo telegráfico',
	},
	{
		code: '43222701',
		label: 'Teclas de telégrafo',
	},
	{
		code: '43222702',
		label: 'Electro magnetos de telégrafo',

		palabrasSimilares: 'Electroimanes de telégrafo',
	},
	{
		code: '43222703',
		label: 'Receptor acústico de telégrafo',

		palabrasSimilares: 'Sondas para telégrafo',
	},
	{
		code: '43222704',
		label: 'Conmutador de telégrafo',
	},
	{
		code: '43222800',
		label: 'Equipo de telefonía',
	},
	{
		code: '43222801',
		label:
			'Equipo de conexión cruzada digital dcx de banda estrecha o de banda ancha',

		palabrasSimilares:
			'Equipos DCX de banda ancha o banda estrecha de interconexión digital',
	},
	{
		code: '43222802',
		label: 'Equipo de circuito de conmutador',

		palabrasSimilares: 'Equipos de conmutadores de circuitos',
	},
	{
		code: '43222803',
		label: 'Cargador de circuito digital dlc',

		palabrasSimilares: 'Porta bobina digital DLC',
	},
	{
		code: '43222805',
		label: 'Equipo de central telefónica privada pbx',

		palabrasSimilares: 'Central privada automática PBX',
	},
	{
		code: '43222806',
		label: 'Bloques de conexiones',

		palabrasSimilares: 'Bloque de conexión rápida para telefonía',
	},
	{
		code: '43222811',
		label: 'Unidades de alarma de equipos de telefonía',

		palabrasSimilares: 'Unidades de alarma de equipos de telefonía',
	},
	{
		code: '43222813',
		label: 'Kits de partes de conmutadores telefónicos',
	},
	{
		code: '43222814',
		label:
			'Kits de instalación o modificación de equipos de telecomunicaciones',
	},
	{
		code: '43222815',
		label: 'Unidades de terminales de telecomunicaciones',
	},
	{
		code: '43222816',
		label: 'Operadores de introducción de datos de telefonía',
	},
	{
		code: '43222817',
		label: 'Repetidores de telecomunicaciones',

		palabrasSimilares: 'Amplificadores de telecomunicaciones',
	},
	{
		code: '43222818',
		label: 'Estructura de terminal de distribución de teléfono',
	},
	{
		code: '43222819',
		label: 'Paneles de conexión de puertos',

		palabrasSimilares: 'Bahía de rutas de puertos',
	},
	{
		code: '43222820',
		label: 'Equipo electrónico para cancelar eco de voz',

		palabrasSimilares: 'Anuladores de eco de voz',
	},
	{
		code: '43222822',
		label: 'Multiplexor de división de tiempo tdm',
	},
	{
		code: '43222823',
		label: 'Multiplexor de división de onda wdm',
	},
	{
		code: '43222824',
		label: 'Enrolladores de cables aéreos',
	},
	{
		code: '43222825',
		label: 'Kits de modificación de teléfonos',
	},
	{
		code: '43222826',
		label: 'Terminal portadora',
	},
	{
		code: '43222827',
		label: 'Multiplexor o mux',
	},
	{
		code: '43222900',
		label: 'Accesorios de equipo de telefonía',
	},
	{
		code: '43222901',
		label: 'Acondicionadores de línea',

		palabrasSimilares: 'Reguladores de voltaje',
	},
	{
		code: '43222902',
		label: 'Secadores de aire para cables de telefonía',
	},
	{
		code: '43222903',
		label: 'Torre o soporte para telecomunicaciones',
	},
	{
		code: '43223000',
		label: 'Equipo de teletipo',
	},
	{
		code: '43223001',
		label: 'Dispositivos de entrada de teletipos',
	},
	{
		code: '43223100',
		label:
			'Componentes y equipo de infraestructura de redes móviles y digitales',
	},
	{
		code: '43223101',
		label: 'Equipos y componentes de red básica móvil gsm 2g',
	},
	{
		code: '43223102',
		label: 'Equipos y componentes de red de acceso inalámbrica gsm 2g',
	},
	{
		code: '43223103',
		label: 'Equipos y componentes de red básica móvil gprs 2.5g',
	},
	{
		code: '43223104',
		label: 'Equipos y componentes de red de acceso inalámbrica gprs 2.5g',
	},
	{
		code: '43223105',
		label: 'Equipos y componentes de red básica móvil umts 3g',
	},
	{
		code: '43223106',
		label: 'Equipos y componentes de red de acceso inalámbrica 3g umts',
	},
	{
		code: '43223107',
		label: 'Equipos y componentes de red básica móvil wlan',
	},
	{
		code: '43223108',
		label: 'Equipos y componentes de acceso inalámbrico wlan',
	},
	{
		code: '43223109',
		label: 'Equipo de interrupción ssp in',
	},
	{
		code: '43223110',
		label: 'Equipo básico móvil in',
	},
	{
		code: '43223111',
		label: 'Equipos y componentes de red básica móvil oss',
	},
	{
		code: '43223112',
		label: 'Equipos y componentes de red de acceso inalámbrica oss',
	},
	{
		code: '43223113',
		label: 'Antenas lan umt gsm',
	},
	{
		code: '43223200',
		label: 'Plataformas de mensajería por móvil',
	},
	{
		code: '43223201',
		label: 'Portal de mensajes de voz',
	},
	{
		code: '43223202',
		label: 'Centro de servicio de mensajes cortos',
	},
	{
		code: '43223203',
		label: 'Centros de servicio multimedia',
	},
	{
		code: '43223204',
		label: 'Plataforma de mensajes unificados',
	},
	{
		code: '43223205',
		label: 'Plataforma de mensajes instantáneos',
	},
	{
		code: '43223206',
		label: 'Puerta de enlace de internet inalámbrico',
	},
	{
		code: '43223207',
		label: 'Sistema de flujo de video',
	},
	{
		code: '43223208',
		label: 'Plataforma de juegos móvil o de mensajes',
	},
	{
		code: '43223209',
		label: 'Plataforma de servicios de mensajes basados en locación',
	},
	{
		code: '43223210',
		label: 'Sistemas de mensajes de micro pagos',
	},
	{
		code: '43223211',
		label: 'Controladores de buscapersonas',
	},
	{
		code: '43223212',
		label: 'Terminales de buscapersonas',
	},
	{
		code: '43223300',
		label:
			'Dispositivos y equipos para instalación de conectividad de redes y Datacom',
	},
	{
		code: '43223301',
		label: 'Sistema y accesorios de conexión cruzada de comunicación de datos',
	},
	{
		code: '43223302',
		label: 'Etiqueta de comunicación de datos',
	},
	{
		code: '43223303',
		label: 'Cable de conexión de comunicación de datos',
	},
	{
		code: '43223305',
		label: 'Ensamblaje de paneles de manejo de cables de red',
	},
	{
		code: '43223306',
		label: 'Gabinete o cerramiento para sistemas de red',
	},
	{
		code: '43223307',
		label: 'Caja de cables para sistemas de red',
	},
	{
		code: '43223308',
		label: 'Estantes para equipos de sistemas de red',
	},
	{
		code: '43223309',
		label: 'Panel de conexión',

		palabrasSimilares: 'Bahía de rutas, Panel de conexiones',
	},
	{
		code: '43223310',
		label: 'Conector de fibra óptica',
	},
	{
		code: '43223311',
		label: 'Atenuador de fibra óptica',
	},
	{
		code: '43223312',
		label: 'Pela cables de fibra óptica',

		palabrasSimilares: 'Pinza Pela cables de fibra óptica',
	},
	{
		code: '43223313',
		label: 'Engarzador de fibra óptica',

		palabrasSimilares: 'Pinza Engarzador de fibra óptica',
	},
	{
		code: '43223314',
		label: 'Dispositivo para pulir fibra óptica',

		palabrasSimilares: 'Lustrador de fibra óptica',
	},
	{
		code: '43223315',
		label: 'Set de cuchillas para herramientas de fibra óptica',

		palabrasSimilares: 'Juego de herramientas de fibra óptica',
	},
	{
		code: '43223316',
		label: 'Punzón de fibra óptica',
	},
	{
		code: '43223317',
		label: 'Removedor de búfer de fibra óptica',

		palabrasSimilares: 'Removedor de tampón de fibra óptica',
	},
	{
		code: '43223318',
		label: 'Emisor de fibra óptica',
	},
	{
		code: '43223319',
		label: 'Detector de fibra óptica',
	},
	{
		code: '43223320',
		label: 'Probador de continuidad de fibra óptica',
	},
	{
		code: '43223321',
		label: 'Clip conector de fibra óptica',

		palabrasSimilares: 'Broche conector de fibra óptica',
	},
	{
		code: '43223322',
		label: 'Gancho de soporte de fibra óptica',
	},
	{
		code: '43223323',
		label: 'Adaptador de fibra óptica',
	},
	{
		code: '43223324',
		label: 'Película pulidora de fibra óptica',
	},
	{
		code: '43223325',
		label: 'Hisopos para fibra óptica',
	},
	{
		code: '43223326',
		label: 'Cable de conexión de fibra óptica',
	},
	{
		code: '43223327',
		label: 'Localizador de fallas de fibra óptica',
	},
	{
		code: '43223328',
		label: 'Panel de conexión de fibra óptica',

		palabrasSimilares: 'Panel de conexiones de fibra óptica',
	},
	{
		code: '43223329',
		label: 'Fuente de luz de fibra óptica',

		palabrasSimilares: 'Alimentador de luz de fibra óptica',
	},
	{
		code: '43223330',
		label: 'Herramientas de ensamblaje de conector de fibra óptica',

		palabrasSimilares:
			'Herramientas de montaje de conector de fibra óptica, Instrumentos de ensamble de conector de fibra óptica',
	},
	{
		code: '43223331',
		label: 'Inserto de fibra óptica',
	},
	{
		code: '43223332',
		label: 'Kit de herramientas de fibra óptica',
	},
	{
		code: '43223333',
		label: 'Lente de alcance de fibra óptica',

		palabrasSimilares: 'Ocular de alcance de fibra óptica',
	},
	{
		code: '43223334',
		label: 'Set de herramientas y tintes de fibra óptica',
	},
	{
		code: '43223335',
		label: 'Cable de música de fibra óptica',

		palabrasSimilares: 'Cable de fibra óptica de música',
	},
	{
		code: '43223336',
		label: 'Adaptador de alcance de fibra óptica',
	},
	{
		code: '43223337',
		label: 'Cerramiento de fibra óptica',

		palabrasSimilares: 'Caja de fibra óptica',
	},
	{
		code: '43223338',
		label: 'Cerramiento de conexión de fibra óptica',

		palabrasSimilares: 'Cierre de conexión para fibra óptica',
	},
	{
		code: '43223339',
		label: 'Caja de distribución de fibra óptica',
	},
	{
		code: '43223340',
		label: 'Empalmadora de fusión de fibra óptica',
	},
	{
		code: '43223341',
		label: 'Combinadora',

		palabrasSimilares: 'Combinador',
	},
	{
		code: '43223342',
		label: 'Kit de conexión y guía de onda',

		palabrasSimilares: 'Guía de ondas y kit de conexión',
	},
	{
		code: '43223343',
		label: 'Herramienta de “punchdown” de red',

		palabrasSimilares: 'Herramienta de perforación de red',
	},
	{
		code: '43231500',
		label: 'Software funcional específico de la empresa',
	},
	{
		code: '43231501',
		label: 'Software de mesa de ayuda o centro de llamadas (call center)',
	},
	{
		code: '43231503',
		label: 'Software de adquisiciones',
	},
	{
		code: '43231505',
		label: 'Software de recursos humanos',

		palabrasSimilares: 'Programa de recursos humanos',
	},
	{
		code: '43231506',
		label:
			'Software de logística de planeación de requerimiento de materiales y cadena de suministros',

		palabrasSimilares:
			'Programa de logística de planeación de requerimiento de materiales y cadena de suministros',
	},
	{
		code: '43231507',
		label: 'Software de manejo de proyectos',

		palabrasSimilares: 'Programa de manejo de proyectos',
	},
	{
		code: '43231508',
		label: 'Software de manejo de inventarios',

		palabrasSimilares: 'Programa de manejo de inventarios',
	},
	{
		code: '43231509',
		label: 'Software de barras de códigos',

		palabrasSimilares: 'Programa de barras de códigos',
	},
	{
		code: '43231510',
		label: 'Software para hacer etiquetas',

		palabrasSimilares: 'Programa para hacer etiquetas',
	},
	{
		code: '43231511',
		label: 'Software de sistemas expertos',

		palabrasSimilares: 'Programa de sistemas expertos',
	},
	{
		code: '43231512',
		label: 'Software de manejo de licencias',

		palabrasSimilares: 'Programa de manejo de licencias',
	},
	{
		code: '43231513',
		label: 'Software para oficinas',

		palabrasSimilares: 'Programa para oficinas',
	},
	{
		code: '43231514',
		label: 'Software de ventas y mercadeo',

		palabrasSimilares: 'Programa de ventas y mercadeo',
	},
	{
		code: '43231515',
		label: 'Software de envío y embarque',

		palabrasSimilares: 'Programa de envío y embarque',
	},
	{
		code: '43231600',
		label:
			'Software de planificación de recursos empresariales (ERP) y contabilidad financiera',
	},
	{
		code: '43231601',
		label: 'Software de contabilidad',

		palabrasSimilares: 'Programa de contabilidad',
	},
	{
		code: '43231602',
		label: 'Software de planeación de recursos del negocio erp',

		palabrasSimilares: 'Programa de planeación de recursos del negocio erp',
	},
	{
		code: '43231603',
		label: 'Software de preparación tributaria',

		palabrasSimilares: 'Programa de preparación tributaria',
	},
	{
		code: '43231604',
		label: 'Software de análisis financiero',

		palabrasSimilares: 'Programa de análisis financiero',
	},
	{
		code: '43231605',
		label: 'Software de contabilidad de tiempo',

		palabrasSimilares: 'Programa de contabilidad de tiempo',
	},
	{
		code: '43232000',
		label: 'Software de entretenimiento o juegos de computador',
	},
	{
		code: '43232001',
		label: 'Juegos de acción',

		palabrasSimilares: 'Videojuego de acción',
	},
	{
		code: '43232002',
		label: 'Juegos de aventuras',

		palabrasSimilares: 'Videojuego de aventuras',
	},
	{
		code: '43232003',
		label: 'Juegos de deportes',

		palabrasSimilares: 'Videojuegos de deportes',
	},
	{
		code: '43232004',
		label: 'Software familiar',

		palabrasSimilares: 'Programa familiar',
	},
	{
		code: '43232005',
		label: 'Software de edición de música o sonido',

		palabrasSimilares: 'Programa de edición de música de sonido',
	},
	{
		code: '43232100',
		label: 'Software de edición y creación de contenidos',
	},
	{
		code: '43232101',
		label: 'Software de diseño de patrones',

		palabrasSimilares: 'Programa de diseño de patrones',
	},
	{
		code: '43232102',
		label: 'Software de imágenes gráficas o de fotografía',

		palabrasSimilares: 'Programa de imágenes gráficas o de fotografía',
	},
	{
		code: '43232103',
		label: 'Software de creación y edición de video',

		palabrasSimilares: 'Programa de creación y edición de video',
	},
	{
		code: '43232104',
		label: 'Software de procesamiento de palabras',

		palabrasSimilares: 'Programa de procesamiento de palabras',
	},
	{
		code: '43232105',
		label: 'Software de gráficas',

		palabrasSimilares: 'Programa de gráficas',
	},
	{
		code: '43232106',
		label: 'Software de presentación',

		palabrasSimilares: 'Programa de presentación',
	},
	{
		code: '43232107',
		label: 'Software de creación y edición de páginas web',

		palabrasSimilares: 'Programa de creación y edición de páginas web',
	},
	{
		code: '43232108',
		label: 'Software de calendario y programación de citas',

		palabrasSimilares: 'Programa de calendario y programación de citas',
	},
	{
		code: '43232110',
		label: 'Software de hoja de cálculo',

		palabrasSimilares: 'Programa de hoja de cálculo',
	},
	{
		code: '43232111',
		label: 'Software de lector de caracteres ópticos ocr o de escáner',

		palabrasSimilares:
			'Lector óptico de caracteres OCR o programa de escaneado',
	},
	{
		code: '43232112',
		label: 'Software de autoedición',

		palabrasSimilares: 'Programa de edición electrónica',
	},
	{
		code: '43232200',
		label: 'Software de gestión de contenidos',
	},
	{
		code: '43232201',
		label: 'Software de flujo de trabajo de contenido',

		palabrasSimilares: 'Programa de flujo de trabajo de contenido',
	},
	{
		code: '43232202',
		label: 'Software de manejo de documentos',

		palabrasSimilares: 'Programa de administración de documentos',
	},
	{
		code: '43232203',
		label: 'Software de versiones de archivo',

		palabrasSimilares: 'Programa de control de versiones de archivos',
	},
	{
		code: '43232204',
		label: 'Software de ingreso de texto incrustado',

		palabrasSimilares: 'Programa de ingreso de texto incrustado',
	},
	{
		code: '43232205',
		label: 'Software de tipos de letra',

		palabrasSimilares: 'Programa de fuentes, Programa de tips de letra',
	},
	{
		code: '43232300',
		label: 'Software de consultas y gestión de datos',
	},
	{
		code: '43232301',
		label: 'Software de categorización o clasificación',

		palabrasSimilares: 'Programa de categorización o clasificación',
	},
	{
		code: '43232302',
		label: 'Software de agrupamiento de recursos',

		palabrasSimilares: 'Programa de agrupamiento de recursos',
	},
	{
		code: '43232303',
		label: 'Software de manejo de relaciones con el cliente crm',

		palabrasSimilares: 'Programa de manejo de relaciones con el cliente crm',
	},
	{
		code: '43232304',
		label: 'Software de sistemas de manejo de base datos',

		palabrasSimilares: 'Programa de sistemas de manejo de base de datos',
	},
	{
		code: '43232305',
		label: 'Software de reportes de bases de datos',

		palabrasSimilares:
			'Programa de informes de bases de datos, Programa de reportes de bases de datos',
	},
	{
		code: '43232306',
		label: 'Software de interface y preguntas de usuario de base de datos',

		palabrasSimilares:
			'Interfaz para usuario de base de datos y programa de consulta',
	},
	{
		code: '43232307',
		label: 'Software de extracción de datos',

		palabrasSimilares: 'Programa de extracción de datos',
	},
	{
		code: '43232309',
		label: 'Software de recuperación o búsqueda de información',

		palabrasSimilares: 'Programa de recuperación o búsqueda de información',
	},
	{
		code: '43232310',
		label: 'Software de manejo de metadata',

		palabrasSimilares:
			'Programa de administración de datos, Programa de manejo de datos',
	},
	{
		code: '43232311',
		label: 'Software de manejo de base de datos orientada al objeto',

		palabrasSimilares:
			'Programa de administración de base de datos orientada a objetos, Programa de manejo de base de datos orientada al objeto',
	},
	{
		code: '43232312',
		label: 'Software de servidor de portales',

		palabrasSimilares: 'Programa de servidor de portales',
	},
	{
		code: '43232313',
		label: 'Software de servidor de transacciones',

		palabrasSimilares: 'Programa de servidor de transacciones',
	},
	{
		code: '43232400',
		label: 'Programas de desarrollo',
	},
	{
		code: '43232401',
		label: 'Software de manejo de configuraciones',

		palabrasSimilares:
			'Programa de administración de configuraciones, Programa de manejo de configuraciones',
	},
	{
		code: '43232402',
		label: 'Software de entorno de desarrollo',

		palabrasSimilares: 'Programa de entorno de desarrollo',
	},
	{
		code: '43232403',
		label: 'Software de integración de aplicaciones de empresas',

		palabrasSimilares: 'Programa de integración de aplicaciones de empresas',
	},
	{
		code: '43232404',
		label: 'Software de desarrollo de interface de usuario gráfica',

		palabrasSimilares: 'Programa de desarrollo de interfaz gráfica de usuario',
	},
	{
		code: '43232405',
		label: 'Software de desarrollo orientado a objetos o componentes',

		palabrasSimilares:
			'Programa de desarrollo orientado a objetos o componentes',
	},
	{
		code: '43232406',
		label: 'Software de pruebas de programas',

		palabrasSimilares: 'Programa para pruebas de calidad de uso o ejecución',
	},
	{
		code: '43232407',
		label: 'Software de arquitectura de sistemas y análisis de requerimientos',

		palabrasSimilares:
			'Programa de arquitectura de sistemas y análisis de requerimientos',
	},
	{
		code: '43232408',
		label: 'Software de desarrollo de plataformas web',

		palabrasSimilares: 'Programa de desarrollo de plataformas web',
	},
	{
		code: '43232409',
		label: 'Software para compilar y descompilar',

		palabrasSimilares: 'Programa del compilador y del decompilador',
	},
	{
		code: '43232500',
		label: 'Software educativo o de referencia',
	},
	{
		code: '43232501',
		label: 'Software de idiomas extranjeros (traductores)',

		palabrasSimilares:
			'Programa de lengua extranjera, Programa para traducciones',
	},
	{
		code: '43232502',
		label: 'Software de entrenamiento basado en computadores',

		palabrasSimilares: 'Programa de entrenamiento basado en computadoras',
	},
	{
		code: '43232503',
		label: 'Correctores de ortografía',

		palabrasSimilares: 'Correctores de ortográficos',
	},
	{
		code: '43232504',
		label: 'Software de navegación de rutas',

		palabrasSimilares: 'Programa de navegación de rutas',
	},
	{
		code: '43232505',
		label: 'Software educacional multimedios',

		palabrasSimilares: 'Programa educacional multimedios',
	},
	{
		code: '43232506',
		label: 'Software de enciclopedias',

		palabrasSimilares: 'Programa de enciclopedias',
	},
	{
		code: '43232507',
		label: 'Software de diccionarios',

		palabrasSimilares: 'Programa de diccionarios',
	},
	{
		code: '43232508',
		label: 'Software de libretas de teléfonos',

		palabrasSimilares:
			'Programa de la agenda telefónica, Programa de libretas de teléfonos',
	},
	{
		code: '43232600',
		label: 'Software específico para la industria',
	},
	{
		code: '43232601',
		label: 'Software de soporte de aviación en tierra',

		palabrasSimilares: 'Programa de soporte terrestre de aviación',
	},
	{
		code: '43232602',
		label: 'Software de pruebas de aviación',

		palabrasSimilares: 'Programa de pruebas de aviación',
	},
	{
		code: '43232603',
		label: 'Software de manejo de instalaciones',

		palabrasSimilares: 'Programa de administración de instalaciones',
	},
	{
		code: '43232604',
		label: 'Software de diseño asistido de computador cad',

		palabrasSimilares:
			'Programa CAD de diseño asistido por computadora, Programa CAD de diseño asistido por ordenador',
	},
	{
		code: '43232605',
		label: 'Software analítico o científico',

		palabrasSimilares: 'Programa analítico o científico',
	},
	{
		code: '43232606',
		label: 'Software de cumplimiento (compliance)',

		palabrasSimilares: 'Programa de GRC',
	},
	{
		code: '43232607',
		label: 'Software de control de vuelos',

		palabrasSimilares: 'Programa de control de vuelos',
	},
	{
		code: '43232608',
		label: 'Software de control industrial',

		palabrasSimilares:
			'Programa de Vigilancia industrial, Programa de control industrial, Programa de supervisión industria',
	},
	{
		code: '43232609',
		label: 'Software de bibliotecas',

		palabrasSimilares: 'Programa de bibliotecas',
	},
	{
		code: '43232610',
		label: 'Software médico',
	},
	{
		code: '43232611',
		label: 'Software de puntos de venta pos',

		palabrasSimilares: 'Programa punto de venta POS',
	},
	{
		code: '43232612',
		label: 'Software de fabricación asistida por computador cam',

		palabrasSimilares: 'Programa de fabricación asistida por computadora CAM',
	},
	{
		code: '43232613',
		label: 'Software de sistema de ejecución de fabricación mes',

		palabrasSimilares: 'Programa de sistema de ejecución de fabricación mes',
	},
	{
		code: '43232614',
		label:
			'Sistema de fabricación asistida por computador cam y de diseño asistido por computador cad',

		palabrasSimilares:
			'Sistema de fabricación asistida por computadora CAM y de diseño asistido CAD',
	},
	{
		code: '43232700',
		label: 'Software de aplicaciones de red',
	},
	{
		code: '43232701',
		label: 'Software de servidor de aplicaciones',

		palabrasSimilares: 'Programa de servidor de aplicaciones',
	},
	{
		code: '43232702',
		label: 'Software de comunicaciones de escritorio',

		palabrasSimilares: 'Programa de comunicaciones de escritorio',
	},
	{
		code: '43232703',
		label: 'Software de respuesta de voz interactiva',

		palabrasSimilares: 'Programa interactivo de respuesta de voz',
	},
	{
		code: '43232704',
		label: 'Software de servicios de directorio por internet',

		palabrasSimilares: 'Programa de servicios de directorio por internet',
	},
	{
		code: '43232705',
		label: 'Software de navegador de internet',

		palabrasSimilares: 'Programa de navegador de internet',
	},
	{
		code: '43232800',
		label: 'Software de administración de redes',
	},
	{
		code: '43232801',
		label: 'Software de monitoreo de red',

		palabrasSimilares: 'Programa de monitoreo de red',
	},
	{
		code: '43232802',
		label: 'Software de optimización del sistema operativo de red',

		palabrasSimilares:
			'Programa de mejora del sistema operativo de red, Programa de optimización del sistema operativo de red',
	},
	{
		code: '43232803',
		label: 'Software de manejo de red óptica',

		palabrasSimilares:
			'Programa de administración de red óptica, Programa de manejo de red óptica',
	},
	{
		code: '43232804',
		label: 'Software de administración',

		palabrasSimilares: 'Programa de administración',
	},
	{
		code: '43232805',
		label: 'Software de subsistema de multimedia de protocolo de internet ip',

		palabrasSimilares:
			'Programa de subsistema de multimedia de protocolo de internet ip',
	},
	{
		code: '43232900',
		label: 'Software para trabajo en redes',
	},
	{
		code: '43232901',
		label: 'Software de acceso',

		palabrasSimilares: 'Programa de acceso',
	},
	{
		code: '43232902',
		label: 'Software de servidor de comunicaciones',

		palabrasSimilares: 'Programa de servidor de comunicaciones',
	},
	{
		code: '43232903',
		label: 'Software de centro de contactos',

		palabrasSimilares: 'Programa de centro de contactos',
	},
	{
		code: '43232904',
		label: 'Software de fax',

		palabrasSimilares: 'Programa de fax',
	},
	{
		code: '43232905',
		label: 'Software de lan',

		palabrasSimilares: 'Programa de red de área local (LAN)',
	},
	{
		code: '43232906',
		label: 'Software de multiplexor',

		palabrasSimilares: 'Programa de multiplexor',
	},
	{
		code: '43232907',
		label: 'Software de almacenamiento de red',

		palabrasSimilares: 'Programa de almacenamiento de red',
	},
	{
		code: '43232908',
		label: 'Software de interruptor o enrutador',

		palabrasSimilares: 'Programa de interruptor o enrutador',
	},
	{
		code: '43232909',
		label: 'Software y firmware de interruptor wan',

		palabrasSimilares:
			'Programa de conmutación o red de área amplia o firmware',
	},
	{
		code: '43232910',
		label: 'Software inalámbrico',

		palabrasSimilares: 'Programa inalámbrico',
	},
	{
		code: '43232911',
		label: 'Software de emulación de terminal de conectividad de red',

		palabrasSimilares:
			'Programa de emulación de terminal de conectividad de red',
	},
	{
		code: '43232912',
		label: 'Software de puerta de acceso',

		palabrasSimilares: 'Programa de puerta de acceso',
	},
	{
		code: '43232913',
		label: 'Software de puente',

		palabrasSimilares: 'Programa de puente',
	},
	{
		code: '43232914',
		label: 'Software de módem',

		palabrasSimilares: 'Programa de módem',
	},
	{
		code: '43232915',
		label: 'Software de interconectividad de plataformas',

		palabrasSimilares: 'Programa de interconectividad de plataformas',
	},
	{
		code: '43232916',
		label: 'Software irda de transferencia de información infrarrojo',
	},
	{
		code: '43233000',
		label: 'Software de entorno operativo',
	},
	{
		code: '43233001',
		label: 'Software de sistema de archivo',

		palabrasSimilares: 'Programa de sistema de archivo',
	},
	{
		code: '43233002',
		label: 'Software de sistema de operación de red',

		palabrasSimilares: 'Programa de sistema de operación de red',
	},
	{
		code: '43233004',
		label: 'Software de sistema operativo',

		palabrasSimilares: 'Programa de sistema operativo',
	},
	{
		code: '43233005',
		label: 'Firmware de computador',

		palabrasSimilares: 'Firmware de computadora, Firmware de ordenador',
	},
	{
		code: '43233200',
		label: 'Software de seguridad y protección',
	},
	{
		code: '43233201',
		label: 'Software de servidor de autenticación',

		palabrasSimilares: 'Programa de servidor de autenticación',
	},
	{
		code: '43233203',
		label:
			'Software de manejo de seguridad de red o de redes privadas virtuales vpn',

		palabrasSimilares:
			'Programa de administración de seguridad de red o de redes privadas virtuales vpn, Programa de manejo de seguridad de red o de redes privadas virtuales vpn',
	},
	{
		code: '43233204',
		label:
			'Software de equipos de seguridad de red y de redes privadas virtuales vpn',

		palabrasSimilares:
			'Programa de equipos de seguridad de red y de redes privadas virtuales vpn',
	},
	{
		code: '43233205',
		label:
			'Software de seguridad de transacciones y de protección contra virus',

		palabrasSimilares:
			'Programa de seguridad de transacciones y de protección contra virus',
	},
	{
		code: '43233400',
		label: 'Software de controladores de dispositivos y utilidades',
	},
	{
		code: '43233401',
		label: 'Software de servidor de discos compactos cd',

		palabrasSimilares: 'Programa de servidor de discos compactos cd',
	},
	{
		code: '43233402',
		label: 'Software de conversión de información',

		palabrasSimilares:
			'Programa de conversión de datos, Programa de conversión de información, Programa de transformación de informació',
	},
	{
		code: '43233403',
		label: 'Software de compresión de información',

		palabrasSimilares:
			'Programa de compresión de información, Programa de contracción de información, Programa para comprimir informació',
	},
	{
		code: '43233404',
		label: 'Software discos compactos cd o dvd o tarjetas de sonido',

		palabrasSimilares:
			'Software o controladores para unidades de CD, DVD o tarjeta de sonido',
	},
	{
		code: '43233405',
		label: 'Software de controladores o sistemas de dispositivos',

		palabrasSimilares: 'Programa de controladores o sistemas de dispositivos',
	},
	{
		code: '43233406',
		label: 'Software de controladores de ethernet',

		palabrasSimilares: 'Programa de controladores de ethernet',
	},
	{
		code: '43233407',
		label: 'Software de controladores de tarjetas de gráficos',

		palabrasSimilares: 'Programa de controladores de tarjetas de gráficos',
	},
	{
		code: '43233410',
		label: 'Software de controladores de impresoras',

		palabrasSimilares: 'Programa de controladores de impresoras',
	},
	{
		code: '43233411',
		label: 'Software de protectores de pantalla',

		palabrasSimilares: 'Programa de protectores de pantalla',
	},
	{
		code: '43233413',
		label: 'Software de reconocimiento de voz',

		palabrasSimilares: 'Programa de reconocimiento de voz',
	},
	{
		code: '43233414',
		label: 'Software de carga de almacenamiento de medios',

		palabrasSimilares: 'Programa de carga de medios de almacenamiento',
	},
	{
		code: '43233415',
		label: 'Software de respaldo o archivo',

		palabrasSimilares: 'Programa de respaldo o archivo',
	},
	{
		code: '43233416',
		label: 'Plataformas de codificación – decodificación',
	},
	{
		code: '43233417',
		label: 'Componentes de software de reconocimiento de escritura manuscrita',

		palabrasSimilares:
			'Componentes del programa de reconocimiento de escritura a mano',
	},
	{
		code: '43233418',
		label: 'Controladores de memoria',
	},
	{
		code: '43233419',
		label: 'Plataformas de multimedia',
	},
	{
		code: '43233420',
		label: 'Software de conversión de texto a voz',

		palabrasSimilares: 'Programa de conversión de texto a voz',
	},
	{
		code: '43233421',
		label: 'Controladores de video',
	},
	{
		code: '43233500',
		label: 'Software de intercambio de información',
	},
	{
		code: '43233501',
		label: 'Software de correo electrónico',

		palabrasSimilares: 'Programa de correo electrónico',
	},
	{
		code: '43233502',
		label: 'Software de video conferencias',

		palabrasSimilares: 'Programa de videoconferencias',
	},
	{
		code: '43233503',
		label: 'Software de conferencias de red',

		palabrasSimilares: 'Programa de conferencias de red',
	},
	{
		code: '43233504',
		label: 'Software de mensajería instantánea',

		palabrasSimilares: 'Programa de mensajería instantánea',
	},
	{
		code: '43233505',
		label: 'Software de música ambiental o publicidad para mensajería',

		palabrasSimilares:
			'Programa de música ambiental o publicidad para mensajería',
	},
	{
		code: '43233506',
		label: 'Software de creación de mapas',

		palabrasSimilares: 'Programa de creación de mapas',
	},
	{
		code: '43233507',
		label: 'Software estándar específico para operadores de móviles',

		palabrasSimilares:
			'Programa estándar específico para operadores de móviles',
	},
	{
		code: '43233508',
		label: 'Software de aplicación específica para operadores de móviles',

		palabrasSimilares:
			'Programa de aplicación específica para operadores de móviles',
	},
	{
		code: '43233509',
		label: 'Software de servicios de mensajería para móviles',

		palabrasSimilares: 'Programa de servicios de mensajería para móviles',
	},
	{
		code: '43233510',
		label: 'Software de servicios de internet para móviles',

		palabrasSimilares: 'Programa de servicios de internet para móviles',
	},
	{
		code: '43233511',
		label: 'Software de servicios basados en ubicación para móviles',

		palabrasSimilares:
			'Programa de servicios basados en localización móvil, Programa de servicios basados en ubicación para móviles',
	},
	{
		code: '43233512',
		label: 'Software de tonos de timbre',

		palabrasSimilares: 'Programa de tonos de timbre',
	},
	{
		code: '43233600',
		label: 'Software de equipo eléctrico',
	},
	{
		code: '43233601',
		label: 'Software de controlador del motor',

		palabrasSimilares: 'Programa de controlador del motor',
	},
	{
		code: '43233602',
		label: 'Software de monitor de energía',

		palabrasSimilares: 'Programa de monitor de energía',
	},
	{
		code: '43233603',
		label: 'Software de control lógico programable',

		palabrasSimilares: 'Programa de control lógico programable',
	},
	{
		code: '43233700',
		label: 'Software de administración de sistemas',
	},
	{
		code: '43233701',
		label: 'Software de manejo de sistemas de empresas',

		palabrasSimilares:
			'Programa de administración de sistemas de empresas, Programa de manejo de sistemas de empresas',
	},
	{
		code: '44101500',
		label: 'Máquinas de duplicación',
	},
	{
		code: '44101501',
		label: 'Fotocopiadoras',

		palabrasSimilares: 'Copiadora',
	},
	{
		code: '44101503',
		label: 'Máquinas multifuncionales',
	},
	{
		code: '44101504',
		label: 'Emisores (senders) digitales',
	},
	{
		code: '44101505',
		label: 'Duplicadores digitales',
	},
	{
		code: '44101506',
		label: 'Máquinas de interruptor de fax',
	},
	{
		code: '44101507',
		label: 'Máquina de fax de inyección de tinta',
	},
	{
		code: '44101508',
		label: 'Máquina de fax láser',
	},
	{
		code: '44101509',
		label: 'Máquina de fax térmica',
	},
	{
		code: '44101510',
		label: 'Fax o radiofax climático',
	},
	{
		code: '44101600',
		label: 'Máquinas para procesamiento de papel y accesorios',
	},
	{
		code: '44101601',
		label: 'Máquinas cortadoras de papel o accesorios',
	},
	{
		code: '44101602',
		label: 'Máquinas perforadoras o para unir papel',

		palabrasSimilares: 'Máquinas para perforar o encuadernar papel',
	},
	{
		code: '44101603',
		label: 'Máquinas trituradoras de papel o accesorios',
	},
	{
		code: '44101604',
		label: 'Tablas de protección de base',

		palabrasSimilares: 'Tableros de protección base',
	},
	{
		code: '44101605',
		label: 'Máquinas para emparejar papel',

		palabrasSimilares: 'Máquinas para nivelar papel',
	},
	{
		code: '44101606',
		label: 'Máquinas para clasificar papel',
	},
	{
		code: '44101607',
		label: 'Máquinas de prensa de papel',
	},
	{
		code: '44101700',
		label: 'Accesorios para impresoras, fotocopiadoras y aparatos de fax',
	},
	{
		code: '44101701',
		label: 'Opciones de color o actualizaciones',
	},
	{
		code: '44101702',
		label: 'Bandejas de impresión a doble cara',

		palabrasSimilares: 'Bandejas de impresión  dúplex',
	},
	{
		code: '44101703',
		label: 'Unidades de impresión a doble cara',

		palabrasSimilares: 'Unidades de impresión dúplex',
	},
	{
		code: '44101704',
		label: 'Terminales de facsímil',
	},
	{
		code: '44101705',
		label: 'Bandejas o alimentadores de máquinas de oficina',
	},
	{
		code: '44101706',
		label: 'Unidades de fotoconductores o imágenes',
	},
	{
		code: '44101707',
		label: 'Unidades de grapadoras',
	},
	{
		code: '44101708',
		label: 'Filtros de ozono',
	},
	{
		code: '44101709',
		label: 'Ensamblajes de réplica',
	},
	{
		code: '44101710',
		label: 'Ensamblajes magnéticos de recogida',
	},
	{
		code: '44101711',
		label: 'Compresor de ensamblaje',
	},
	{
		code: '44101712',
		label: 'Apiladores de buzón de correo',
	},
	{
		code: '44101713',
		label: 'Contadores de copias',
	},
	{
		code: '44101714',
		label: 'Unidades de facsímil para máquinas de oficina',
	},
	{
		code: '44101715',
		label: 'Cubiertas de platinas',
	},
	{
		code: '44101716',
		label: 'Unidades de perforación de orificios',
	},
	{
		code: '44101718',
		label: 'Adaptadores infrarrojos',
	},
	{
		code: '44101719',
		label: 'Accesorios de copiado o escaneado',
	},
	{
		code: '44101720',
		label: 'Fuentes de lenguaje',

		palabrasSimilares: 'Fuentes de idioma',
	},
	{
		code: '44101721',
		label: 'Husos de medios',
	},
	{
		code: '44101722',
		label: 'Actualizaciones de energía de buzón de correo multi recipientes',

		palabrasSimilares:
			'Actualizaciones de alimentación de buzones de múltiples compartimientos',
	},
	{
		code: '44101723',
		label: 'Buzones de correo multi recipientes',
	},
	{
		code: '44101724',
		label: 'Actualizaciones de multi funciones',

		palabrasSimilares: 'Actualizaciones multifuncionales',
	},
	{
		code: '44101725',
		label: 'Gabinetes de impresora',
	},
	{
		code: '44101726',
		label: 'Actualizaciones de emulación de impresoras',
	},
	{
		code: '44101727',
		label: 'Puestos para impresoras',
	},
	{
		code: '44101728',
		label: 'Alimentadores de rollos',
	},
	{
		code: '44101729',
		label: 'Apiladores de salida',
	},
	{
		code: '44101730',
		label: 'Clasificador automático de documentos',
	},
	{
		code: '44101800',
		label: 'Máquinas calculadoras y accesorios',
	},
	{
		code: '44101802',
		label: 'Máquinas sumadoras',

		palabrasSimilares: 'Sumadoras',
	},
	{
		code: '44101803',
		label: 'Máquinas contabilizadoras',

		palabrasSimilares: 'Máquinas de contabilidad',
	},
	{
		code: '44101804',
		label: 'Cajas registradoras',

		palabrasSimilares: 'Máquinas registradoras',
	},
	{
		code: '44101805',
		label: 'Cintas para calculadoras',

		palabrasSimilares: 'Carrete de cinta para calculadora',
	},
	{
		code: '44101806',
		label: 'Cintas para cajas registradoras',
	},
	{
		code: '44101807',
		label: 'Calculadoras de bolsillo',
	},
	{
		code: '44101808',
		label: 'Calculadoras científicas',
	},
	{
		code: '44101809',
		label: 'Calculadoras de escritorio',
	},
	{
		code: '44101810',
		label: 'Calculadoras impresoras',
	},
	{
		code: '44101900',
		label: 'Máquinas para endosar y escribir cheques',
	},
	{
		code: '44101901',
		label: 'Máquinas de endoso de cheques',
	},
	{
		code: '44101902',
		label: 'Máquinas para escribir cheques',
	},
	{
		code: '44101903',
		label: 'Máquinas de actualización de chequeras o tiqueteras',
	},
	{
		code: '44102000',
		label: 'Suministros para plastificado',
	},
	{
		code: '44102001',
		label: 'Película de laminación',
	},
	{
		code: '44102002',
		label: 'Bolsas de laminadores',
	},
	{
		code: '44102003',
		label: 'Láminas de transferencia',
	},
	{
		code: '44102004',
		label: 'Láminas creativas',
	},
	{
		code: '44102100',
		label: 'Máquinas de correo',
	},
	{
		code: '44102101',
		label: 'Máquinas de franqueo o estampillado',
	},
	{
		code: '44102102',
		label: 'Máquinas para abrir correspondencia',

		palabrasSimilares: 'Máquinas de apertura de correspondencia',
	},
	{
		code: '44102103',
		label: 'Máquinas para sellar correspondencia',
	},
	{
		code: '44102104',
		label: 'Máquinas para cancelar estampillas',
	},
	{
		code: '44102105',
		label: 'Máquinas para direcciones',

		palabrasSimilares: 'Máquinas de direccionamiento',
	},
	{
		code: '44102106',
		label: 'Dobladores de cartas',
	},
	{
		code: '44102107',
		label: 'Colocadores de estampillas',
	},
	{
		code: '44102108',
		label: 'Accesorios para máquinas de correo',
	},
	{
		code: '44102109',
		label: 'Máquina automática para postales o correspondencia',
	},
	{
		code: '44102200',
		label: 'Accesorios para escáneres',
	},
	{
		code: '44102201',
		label: 'Endosador',
	},
	{
		code: '44102202',
		label: 'Alimentadores de documentos para escáneres',
	},
	{
		code: '44102203',
		label: 'Adaptadores de transparencia para escáneres',
	},
	{
		code: '44102300',
		label: 'Empaquetadoras',
	},
	{
		code: '44102301',
		label: 'Máquinas de agrupación',
	},
	{
		code: '44102302',
		label: 'Máquinas para envolver paquetes',
	},
	{
		code: '44102303',
		label: 'Prensas de sellamiento',

		palabrasSimilares: 'Prensas de sellado',
	},
	{
		code: '44102304',
		label: 'Máquinas selladoras',

		palabrasSimilares: 'Selladoras',
	},
	{
		code: '44102305',
		label: 'Tensionadores o selladoras para zunchado',

		palabrasSimilares: 'Tensores o selladores de fleje y cintillo',
	},
	{
		code: '44102306',
		label: 'Máquinas para amarrar',

		palabrasSimilares: 'Máquinas para sujetar',
	},
	{
		code: '44102307',
		label: 'Intercaladores',
	},
	{
		code: '44102400',
		label: 'Etiquetadoras',
	},
	{
		code: '44102402',
		label: 'Máquinas para fechar o numerar',
	},
	{
		code: '44102403',
		label: 'Máquinas de prensa de identificación id',
	},
	{
		code: '44102404',
		label: 'Máquinas para aplicar de etiquetas',

		palabrasSimilares: 'Máquinas etiquetadoras',
	},
	{
		code: '44102405',
		label: 'Máquinas para hacer etiquetas',
	},
	{
		code: '44102406',
		label: 'Equipos para rotulado',
	},
	{
		code: '44102407',
		label: 'Grabadora de relieve para cinta',
	},
	{
		code: '44102408',
		label: 'Sistemas de etiquetado automático',
	},
	{
		code: '44102409',
		label: 'Sistemas de etiquetado semiautomáticos',
	},
	{
		code: '44102411',
		label: 'Dispensadores de etiquetas',
	},
	{
		code: '44102412',
		label: 'Cartuchos de etiquetas adhesivas',
	},
	{
		code: '44102413',
		label:
			'Kit de sistema de etiquetado de discos versátiles digitales o discos compactos cd/dvd',
	},
	{
		code: '44102414',
		label: 'Estampilla auto abastecedora de tinta',
	},
	{
		code: '44102500',
		label: 'Máquinas clasificadoras',
	},
	{
		code: '44102501',
		label: 'Máquinas contadoras de dinero',
	},
	{
		code: '44102502',
		label: 'Sorteadoras',
	},
	{
		code: '44102503',
		label: 'Máquinas para envolver monedas',
	},
	{
		code: '44102600',
		label: 'Máquinas de escribir y accesorios',
	},
	{
		code: '44102602',
		label: 'Máquinas de escribir',
	},
	{
		code: '44102603',
		label: 'Ruedas de margarita para impresoras',
	},
	{
		code: '44102604',
		label: 'Máquinas de estenotipio',
	},
	{
		code: '44102605',
		label: 'Máquinas para dictado',
	},
	{
		code: '44102606',
		label: 'Cinta de máquinas de escribir',

		palabrasSimilares: 'Cinta de nylon para máquinas de escribir',
	},
	{
		code: '44102607',
		label: 'Procesadores de palabras',
	},
	{
		code: '44102608',
		label: 'Elementos de impresión para máquinas de escribir',

		palabrasSimilares:
			'Componentes de impresión para máquinas de escribir, Dispositivos de impresión para máquinas de escribir',
	},
	{
		code: '44102609',
		label: 'Kits de accesorios o suministros para máquinas de escribir',
	},
	{
		code: '44102610',
		label: 'Kits de inicio para máquinas de escribir',
	},
	{
		code: '44102800',
		label: 'Máquinas encuadernadoras y plastificadoras',
	},
	{
		code: '44102801',
		label: 'Laminadoras',
	},
	{
		code: '44102802',
		label: 'Máquinas de encuadernación térmica',

		palabrasSimilares:
			'Máquinas para empastado térmico, Máquinas para encuadernado térmico',
	},
	{
		code: '44102803',
		label: 'Máquina de encuadernación en espiral',

		palabrasSimilares: 'Máquina de encuadernado en espiral',
	},
	{
		code: '44102804',
		label: 'Máquina de encuadernación de peinilla',

		palabrasSimilares: 'Máquina de encuadernado de peine',
	},
	{
		code: '44102805',
		label: 'Máquina perforadora de encuadernación',

		palabrasSimilares: 'Máquina perforadora para encuadernado',
	},
	{
		code: '44102806',
		label: 'Máquina de encuadernación de alambre',

		palabrasSimilares: 'Máquina para encuadernado de alambre',
	},
	{
		code: '44102900',
		label: 'Accesorios de máquinas de oficina',
	},
	{
		code: '44102901',
		label: 'Kits de viaje para máquinas de oficina',
	},
	{
		code: '44102902',
		label: 'Accesorios de almacenamiento para máquinas de almacenamiento',

		palabrasSimilares: 'Accesorios de almacenamiento para máquinas de oficina',
	},
	{
		code: '44102903',
		label: 'Limpiadoras de cinta',

		palabrasSimilares: 'Limpiadores de cinta',
	},
	{
		code: '44102904',
		label: 'Aerosol de aire comprimido',
	},
	{
		code: '44102905',
		label: 'Pequeñas bolsas de papel de sales absorbentes húmedas',
	},
	{
		code: '44102906',
		label: 'Kits de limpieza de computadores o equipos de oficina',

		palabrasSimilares:
			'Kits de limpieza de computadoras o equipos de oficina, Kits de limpieza de ordenadores o equipos de oficina',
	},
	{
		code: '44102907',
		label: 'Forros para equipos para protegerlos del polvo',

		palabrasSimilares:
			'Cubiertas antipolvo para equipos, Fundas antipolvo para equipos',
	},
	{
		code: '44102908',
		label: 'Limpiadores de discos compactos o removedores de rayones',
	},
	{
		code: '44102909',
		label: 'Limpiadores de unidades de discos compactos',
	},
	{
		code: '44102910',
		label: 'Laminadora',
	},
	{
		code: '44102911',
		label: 'Pañitos limpiadores para máquinas de oficina',

		palabrasSimilares: 'Paños de limpieza para máquinas de oficina',
	},
	{
		code: '44102912',
		label: 'Soluciones limpiadoras para equipos de oficina',

		palabrasSimilares: 'Soluciones de limpieza para equipos de oficina',
	},
	{
		code: '44102913',
		label: 'Destructor de discos compactos',
	},
	{
		code: '44103000',
		label: 'Fusores y Accesorios',
	},
	{
		code: '44103001',
		label: 'Almohadillas o filtros limpiadores de fusores',
	},
	{
		code: '44103002',
		label: 'Aceite de fusores',
	},
	{
		code: '44103003',
		label: 'Limpiador de fusores',
	},
	{
		code: '44103004',
		label: 'Fusores',
	},
	{
		code: '44103005',
		label: 'Lámparas o ensamblajes de fusores',
	},
	{
		code: '44103100',
		label: 'Suministros para impresora, fax y fotocopiadora',
	},
	{
		code: '44103101',
		label: 'Correas de impresoras, fax o fotocopiadoras',

		palabrasSimilares:
			'Cinturones de impresora o de facsímil o de fotocopiadora',
	},
	{
		code: '44103103',
		label: 'Tóner para impresoras o fax',
	},
	{
		code: '44103104',
		label: 'Rollos de transferencia',

		palabrasSimilares: 'Rodillos de transferencia',
	},
	{
		code: '44103105',
		label: 'Cartuchos de tinta',
	},
	{
		code: '44103106',
		label: 'Barras de tinta',
	},
	{
		code: '44103107',
		label: 'Suministros de limpieza de impresoras o faxes o fotocopiadoras',
	},
	{
		code: '44103108',
		label: 'Reveladores para impresoras o fotocopiadoras',
	},
	{
		code: '44103109',
		label: 'Tambores para impresoras o faxes o fotocopiadoras',
	},
	{
		code: '44103110',
		label: 'Cabezales de impresión',
	},
	{
		code: '44103111',
		label: 'Rollos de tinta',

		palabrasSimilares: 'Rodillos de tinta',
	},
	{
		code: '44103112',
		label: 'Cinta de impresora',
	},
	{
		code: '44103113',
		label: 'Kits de correctores de fase o inyección de tinta',
	},
	{
		code: '44103114',
		label: 'Kits de recubrimiento de inyección de tinta',
	},
	{
		code: '44103116',
		label: 'Kit para impresora',
	},
	{
		code: '44103117',
		label: 'Cintas de fax',
	},
	{
		code: '44103118',
		label: 'Película de transparencia',
	},
	{
		code: '44103119',
		label: 'Papel de transferencia en caliente para copiadoras',

		palabrasSimilares: 'Papel térmico para copiadoras',
	},
	{
		code: '44103120',
		label: 'Recolectores de tóner',
	},
	{
		code: '44103121',
		label: 'Guías de rollo para impresoras, faxes o fotocopiadoras',
	},
	{
		code: '44103122',
		label: 'Bandas de impresión',
	},
	{
		code: '44103123',
		label: 'Lápices de plotter',

		palabrasSimilares: 'Plumas para plotter',
	},
	{
		code: '44103124',
		label: 'Cinta térmica',
	},
	{
		code: '44103125',
		label: 'Kit de mantenimiento de impresoras',
	},
	{
		code: '44103126',
		label: 'Tinta de máquina duplicadora digital',
	},
	{
		code: '44103200',
		label: 'Máquinas y accesorios de registrar la hora para la oficina',
	},
	{
		code: '44103201',
		label: 'Máquina de tarjetas de tiempo',
	},
	{
		code: '44103202',
		label: 'Máquinas estampadoras de tiempo',
	},
	{
		code: '44103203',
		label: 'Cinta de repuesto para máquinas de tarjetas de tiempo',

		palabrasSimilares: 'Cinta de reemplazo de máquina de tarjeta de tiempo',
	},
	{
		code: '44103204',
		label: 'Estantes para tarjetas de tiempo',
	},
	{
		code: '44103205',
		label: 'Tarjetas u hojas de tiempo',
	},
	{
		code: '44103206',
		label: 'Máquina de huellas para control de tiempo y de acceso',

		palabrasSimilares: 'Biométrico para control de tiempo y acceso',
	},
	{
		code: '44103500',
		label: 'Suministros de máquinas de encuadernar',
	},
	{
		code: '44103502',
		label: 'Tapas de encuadernación',

		palabrasSimilares: 'Cubiertas de encuadernación, Pastas de encuadernación',
	},
	{
		code: '44103503',
		label: 'Lomos o cierres de encuadernación',

		palabrasSimilares: 'Encuadernación de espinas o broches',
	},
	{
		code: '44103504',
		label: 'Alambres o espirales de encuadernación',

		palabrasSimilares: 'Bobinas de encuadernación o bucles de alambre',
	},
	{
		code: '44103505',
		label: 'Peinillas o tiras de encuadernación',

		palabrasSimilares: 'Peines o tiras de encuadernación',
	},
	{
		code: '44103506',
		label: 'Cinta de encuadernación',

		palabrasSimilares: 'Cinta adhesiva para encuadernación',
	},
	{
		code: '44103507',
		label: 'Kits de encuadernación',
	},
	{
		code: '44103508',
		label: 'Punzones de tinta de encuadernación',
	},
	{
		code: '44103600',
		label: 'Equipo de eliminación de casetes y accesorios',
	},
	{
		code: '44103601',
		label: 'Trituradores de casetes o cintas',
	},
	{
		code: '44111500',
		label: 'Agendas y accesorios',
	},
	{
		code: '44111501',
		label: 'Sujetadores o dispensadores de mensajes',
	},
	{
		code: '44111502',
		label: 'Organizadores de cajones de escritorio',
	},
	{
		code: '44111503',
		label: 'Organizadores o bandejas para el escritorio',
	},
	{
		code: '44111506',
		label: 'Sujetadores o dispensadores de papeles o tacos',
	},
	{
		code: '44111507',
		label: 'Sujeta libros',
	},
	{
		code: '44111509',
		label: 'Sujetadores de esferos o lápices',
	},
	{
		code: '44111510',
		label: 'Organizadores o accesorios de colgar',
	},
	{
		code: '44111511',
		label: 'Sistemas de despliegue o sus accesorios',
	},
	{
		code: '44111512',
		label: 'Estante de literatura',

		palabrasSimilares:
			'Anaquel de literatura, Estantería de literatura, Librer',
	},
	{
		code: '44111513',
		label: 'Soportes para diarios o calendarios',
	},
	{
		code: '44111514',
		label: 'Estantes u organizadores para estampillas',

		palabrasSimilares: 'Bastidores de estampillas u organizadores',
	},
	{
		code: '44111515',
		label: 'Cajas u organizadores de almacenamiento de archivos',

		palabrasSimilares:
			'Caja de almacenamiento de libros, Organizador de expedientes',
	},
	{
		code: '44111516',
		label: 'Organizadores personales',

		palabrasSimilares: 'Agendas',
	},
	{
		code: '44111517',
		label: 'Puestos de estudio',

		palabrasSimilares: 'Mueble de estudio, Mueble de trabajo, Puesto de trabaj',
	},
	{
		code: '44111518',
		label: 'Sujetadores de tarjetas de presentación',

		palabrasSimilares: 'Tarjetero',
	},
	{
		code: '44111519',
		label: 'Repisas de distribución de documentos',
	},
	{
		code: '44111520',
		label: 'Almohadillas o protectores de superficie',
	},
	{
		code: '44111521',
		label: 'Sujetadores de copias',

		palabrasSimilares:
			'Broche sujetador de archivo, Clip, Clip sujetador de papel tipo maripos',
	},
	{
		code: '44111522',
		label: 'Sujetadores de libros',
	},
	{
		code: '44111600',
		label: 'Suministros para el manejo de efectivo',
	},
	{
		code: '44111601',
		label: 'Bolsas para billetes o billeteras',
	},
	{
		code: '44111603',
		label: 'Sorteadores de monedas',

		palabrasSimilares: 'Contador de monedas',
	},
	{
		code: '44111604',
		label: 'Envoltorios para monedas o cintas para billetes',
	},
	{
		code: '44111605',
		label: 'Cajas para efectivo o tiquetes',

		palabrasSimilares: 'Cajas para efectivo o Maquinas registradoras',
	},
	{
		code: '44111606',
		label: 'Bandejas de cajas de efectivo',
	},
	{
		code: '44111607',
		label: 'Archivos de cheques',

		palabrasSimilares: 'Chequera',
	},
	{
		code: '44111608',
		label: 'Bancos de monedas',
	},
	{
		code: '44111609',
		label: 'Detectores de billetes falsos o suministros',
	},
	{
		code: '44111610',
		label: 'Bandejas de monedas',
	},
	{
		code: '44111611',
		label: 'Clips para billetes',

		palabrasSimilares: 'Pinza para billetes',
	},
	{
		code: '44111612',
		label: 'Sellos para bolsas de monedas',

		palabrasSimilares: 'Tira plástica para bolsa de monedas',
	},
	{
		code: '44111613',
		label: 'Sujetadores de tarjetas de crédito',

		palabrasSimilares: 'Pinzas para sujetar tarjetas de crédito',
	},
	{
		code: '44111614',
		label: 'Estantes de correas de billetes',
	},
	{
		code: '44111615',
		label: 'Bolsas de depósito',
	},
	{
		code: '44111616',
		label: 'Separadores de cheques',
	},
	{
		code: '44111617',
		label: 'Máquinas de inspección de monedas',
	},
	{
		code: '44111618',
		label: 'Tarjetas de teléfono público o tarjetas de teléfono',
	},
	{
		code: '44111800',
		label: 'Suministros de dibujo',
	},
	{
		code: '44111801',
		label: 'Ayudas para esténciles o textos',
	},
	{
		code: '44111802',
		label: 'Película de dibujo',

		palabrasSimilares: 'Acetato para dibujo',
	},
	{
		code: '44111803',
		label: 'Compases',

		palabrasSimilares: 'Compás',
	},
	{
		code: '44111804',
		label: 'Papeles de dibujo',
	},
	{
		code: '44111805',
		label: 'Curvas',

		palabrasSimilares: 'Curvas para dibujo',
	},
	{
		code: '44111806',
		label: 'Transportadores',
	},
	{
		code: '44111807',
		label: 'Escalas',

		palabrasSimilares: 'Escalímetro',
	},
	{
		code: '44111808',
		label: 'Reglas t',
	},
	{
		code: '44111809',
		label: 'Plantillas',
	},
	{
		code: '44111810',
		label: 'Triángulos',
	},
	{
		code: '44111812',
		label: 'Kits o sets de dibujo',
	},
	{
		code: '44111813',
		label: 'Puntos o cintas de dibujo',
	},
	{
		code: '44111814',
		label: 'Forros de protección para superficies de trabajo',
	},
	{
		code: '44111815',
		label: 'Forros para mesas de dibujo',
	},
	{
		code: '44111816',
		label: 'Máquina de dibujo multiplexora',
	},
	{
		code: '44111817',
		label: 'Divisor de dibujo',
	},
	{
		code: '44111818',
		label: 'Eidógrafo',

		palabrasSimilares: 'Pantógrafo',
	},
	{
		code: '44111900',
		label: 'Tableros',
	},
	{
		code: '44111901',
		label: 'Tableros de planeación o accesorios',
	},
	{
		code: '44111902',
		label: 'Tableros electrónicos de copia o accesorios',
	},
	{
		code: '44111903',
		label: 'Caballetes o accesorios',
	},
	{
		code: '44111904',
		label: 'Tableros de cartas o accesorios',
	},
	{
		code: '44111905',
		label: 'Tableros de borrado en seco o accesorios',
	},
	{
		code: '44111906',
		label: 'Tableros de tiza o accesorios',

		palabrasSimilares: 'Pizarrón de gis',
	},
	{
		code: '44111907',
		label: 'Tableros de noticias o accesorios',
	},
	{
		code: '44111908',
		label: 'Tableros magnéticos o accesorios',
	},
	{
		code: '44111909',
		label: 'Kits o accesorios para limpieza de tableros',
	},
	{
		code: '44111910',
		label: 'Rieles o sujetadores para colgar tableros',
	},
	{
		code: '44111911',
		label: 'Tableros blancos interactivos o accesorios',

		palabrasSimilares: 'Pizarrones blancos interactivos o accesorios',
	},
	{
		code: '44111912',
		label: 'Borradores para tableros blancos',

		palabrasSimilares: 'Borradores para pizarrones blancos',
	},
	{
		code: '44111913',
		label: 'Borradores de pila para tableros blancos',

		palabrasSimilares: 'Borradores de pila para pizarrones blancos',
	},
	{
		code: '44111914',
		label: 'Tabla de soporte para escribir',
	},
	{
		code: '44112000',
		label: 'Sistemas de planificación',
	},
	{
		code: '44112001',
		label: 'Libretas de direcciones o repuestos',

		palabrasSimilares: 'Directorio',
	},
	{
		code: '44112002',
		label: 'Calendarios',

		palabrasSimilares: 'Almanaque',
	},
	{
		code: '44112004',
		label: 'Planeadores de reuniones',
	},
	{
		code: '44112005',
		label: 'Libretas de citas o repuestos',
	},
	{
		code: '44112006',
		label: 'Diarios o repuestos',
	},
	{
		code: '44112007',
		label: 'Cajas de sugerencias',
	},
	{
		code: '44112008',
		label: 'Planeadores de pared o repuestos',
	},
	{
		code: '44121500',
		label: 'Suministros de correo',
	},
	{
		code: '44121501',
		label: 'Tubos de sobres',

		palabrasSimilares: 'Tubos de envío',
	},
	{
		code: '44121503',
		label: 'Sobres',
	},
	{
		code: '44121504',
		label: 'Sobres de ventana',
	},
	{
		code: '44121505',
		label: 'Sobres especiales',
	},
	{
		code: '44121506',
		label: 'Sobres estándar',
	},
	{
		code: '44121507',
		label: 'Sobres de catálogos o de gancho',
	},
	{
		code: '44121508',
		label: 'Repositorios para mensajes',
	},
	{
		code: '44121509',
		label: 'Bolsas para correo',
	},
	{
		code: '44121510',
		label: 'Sellos para correo',
	},
	{
		code: '44121511',
		label: 'Cajas para correo',
	},
	{
		code: '44121512',
		label: 'Tapas de tubos para correo',
	},
	{
		code: '44121513',
		label: 'Estampillas postales',
	},
	{
		code: '44121600',
		label: 'Suministros de escritorio',

		palabrasSimilares: 'Artículos de papelería',
	},
	{
		code: '44121604',
		label: 'Estampillas',
	},
	{
		code: '44121605',
		label: 'Dispensadores de cinta',
	},
	{
		code: '44121611',
		label: 'Punzones para papel u ojales',

		palabrasSimilares: 'Perforadoras',
	},
	{
		code: '44121612',
		label: 'Cortadoras de papel o repuestos',
	},
	{
		code: '44121613',
		label: 'Removedores de grapas (saca ganchos)',

		palabrasSimilares: 'Quita grapas',
	},
	{
		code: '44121614',
		label: 'Campanas de llamada',

		palabrasSimilares: 'Timbre de llamada',
	},
	{
		code: '44121615',
		label: 'Grapadoras',

		palabrasSimilares: 'Engrapadoras',
	},
	{
		code: '44121617',
		label: 'Abridor manual de cartas',

		palabrasSimilares: 'Abrecartas',
	},
	{
		code: '44121618',
		label: 'Tijeras',
	},
	{
		code: '44121619',
		label: 'Tajalápices manuales',

		palabrasSimilares: 'Sacapuntas',
	},
	{
		code: '44121620',
		label: 'Protector de plástico para dedos',

		palabrasSimilares: 'Dedal de plástico',
	},
	{
		code: '44121621',
		label: 'Almohadillas para escritorio o sus accesorios',
	},
	{
		code: '44121622',
		label: 'Humidificadores',

		palabrasSimilares: 'Cera de dedos para contar',
	},
	{
		code: '44121623',
		label: 'Abridor de cartas mecánico',

		palabrasSimilares: 'Abrecartas mecánico',
	},
	{
		code: '44121624',
		label: 'Herramientas de grabado en relieve',

		palabrasSimilares: 'Puntas de grabado',
	},
	{
		code: '44121625',
		label: 'Pisa papeles',
	},
	{
		code: '44121626',
		label: 'Removedor de adhesivo',
	},
	{
		code: '44121627',
		label: 'Marcadores de libros',

		palabrasSimilares: 'Separadores para libros',
	},
	{
		code: '44121628',
		label: 'Contenedores o dispensadores de clips',
	},
	{
		code: '44121630',
		label: 'Kit de cosedora',

		palabrasSimilares: 'Juego de repuestos para engrapadoras',
	},
	{
		code: '44121631',
		label: 'Dispensadores de goma o repuestos',

		palabrasSimilares: 'Dispensadores de rollos adhesivos y repuestos',
	},
	{
		code: '44121632',
		label: 'Afilador de tijeras',
	},
	{
		code: '44121633',
		label: 'Dispensadores de estampillas postales',
	},
	{
		code: '44121634',
		label: 'Rollos adhesivos',
	},
	{
		code: '44121635',
		label: 'Husos para cinta adhesiva',

		palabrasSimilares: 'Carrete para cinta adhesiva',
	},
	{
		code: '44121636',
		label: 'Tajalápices eléctricos',

		palabrasSimilares: 'Sacapuntas eléctrico',
	},
	{
		code: '44121700',
		label: 'Instrumentos de escritura',

		palabrasSimilares: 'Artículos de papelería',
	},
	{
		code: '44121701',
		label: 'Bolígrafos',
	},
	{
		code: '44121702',
		label: 'Sets de esferos o lápices',

		palabrasSimilares: 'Sets de bolígrafos o lápices',
	},
	{
		code: '44121703',
		label: 'Estilógrafos',
	},
	{
		code: '44121704',
		label: 'Esferos de punta redonda',

		palabrasSimilares: 'Bolígrafos de punta redonda',
	},
	{
		code: '44121705',
		label: 'Lápices mecánicos',

		palabrasSimilares: 'Lapiceros',
	},
	{
		code: '44121706',
		label: 'Lápices de madera',
	},
	{
		code: '44121707',
		label: 'Lápices de colores',
	},
	{
		code: '44121708',
		label: 'Marcadores',
	},
	{
		code: '44121709',
		label: 'Crayolas',
	},
	{
		code: '44121710',
		label: 'Tiza para escribir o accesorios',

		palabrasSimilares: 'Gis para escribir o accesorios',
	},
	{
		code: '44121711',
		label: 'Rotuladores',

		palabrasSimilares: 'Plumines',
	},
	{
		code: '44121712',
		label: 'Repuestos de marcadores',
	},
	{
		code: '44121713',
		label: 'Plumas de estilógrafos',
	},
	{
		code: '44121714',
		label: 'Asideras para lápices o esferos',

		palabrasSimilares: 'Goma ergonómica para lápices',
	},
	{
		code: '44121715',
		label: 'Combinaciones de esfero y lápiz',

		palabrasSimilares: 'Bolígrafo multifunción con lápiz',
	},
	{
		code: '44121716',
		label: 'Resaltadores',

		palabrasSimilares: 'Marcatextos',
	},
	{
		code: '44121717',
		label: 'Combinación de esfero y resaltador',

		palabrasSimilares: 'Bolígrafo multifunción con marcatextos',
	},
	{
		code: '44121718',
		label: 'Sets de esferos asegurados',

		palabrasSimilares: 'Estuche de bolígrafos giratorios',
	},
	{
		code: '44121719',
		label: 'Estilógrafos desechables',
	},
	{
		code: '44121720',
		label: 'Esferos multi función',

		palabrasSimilares: 'Bolígrafos multifunción',
	},
	{
		code: '44121721',
		label: 'Esfero de tinta borrable',

		palabrasSimilares: 'Bolígrafo de tinta borrable',
	},
	{
		code: '44121800',
		label: 'Medios de corrección',

		palabrasSimilares: 'Artículos de papelería',
	},
	{
		code: '44121801',
		label: 'Película o cinta de corrección',

		palabrasSimilares: 'Corrector de cinta',
	},
	{
		code: '44121802',
		label: 'Fluido de corrección',

		palabrasSimilares: 'Corrector líquido',
	},
	{
		code: '44121804',
		label: 'Borradores',

		palabrasSimilares: 'Goma para borrar',
	},
	{
		code: '44121805',
		label: 'Esferos de corrección',

		palabrasSimilares: 'Lápiz corrector',
	},
	{
		code: '44121806',
		label: 'Repuestos para esferos de corrección',

		palabrasSimilares: 'Repuestos para lápiz corrector',
	},
	{
		code: '44121807',
		label: 'Repuestos para borradores',
	},
	{
		code: '44121808',
		label: 'Borradores eléctricos',

		palabrasSimilares: 'Borrador eléctrico',
	},
	{
		code: '44121809',
		label: 'Sujeta borrador',

		palabrasSimilares: 'Borrador mecánico',
	},
	{
		code: '44121900',
		label: 'Repuestos de tinta y minas de lápices',

		palabrasSimilares: 'Artículos de papelería',
	},
	{
		code: '44121902',
		label: 'Repuestos de minas',

		palabrasSimilares: 'Puntillas de grafito',
	},
	{
		code: '44121904',
		label: 'Repuestos de tinta',
	},
	{
		code: '44121905',
		label: 'Almohadillas de tinta o estampillas',
	},
	{
		code: '44121906',
		label: 'Cartuchos para rotuladores',
	},
	{
		code: '44121907',
		label: 'Repuestos de tinta para estilógrafos',
	},
	{
		code: '44121908',
		label: 'Repuestos de tinta para bolígrafos',
	},
	{
		code: '44122000',
		label: 'Carpetas de archivo, carpetas y separadores',

		palabrasSimilares: 'Artículos de papelería',
	},
	{
		code: '44122001',
		label: 'Archivos para tarjetas de índex',

		palabrasSimilares: 'Carpetas para archivos con índice',
	},
	{
		code: '44122002',
		label: 'Protectores de hojas',
	},
	{
		code: '44122003',
		label: 'Carpetas',
	},
	{
		code: '44122005',
		label: 'Cubiertas para revistas o libros',
	},
	{
		code: '44122008',
		label: 'Índices de fichas',
	},
	{
		code: '44122009',
		label: 'Archivos para tarjetas rotativas o de presentación',
	},
	{
		code: '44122010',
		label: 'Separadores',

		palabrasSimilares: 'Separadores de hojas',
	},
	{
		code: '44122011',
		label: 'Folders',
	},
	{
		code: '44122012',
		label: 'Portapapeles',
	},
	{
		code: '44122013',
		label: 'Cubiertas para informes',
	},
	{
		code: '44122014',
		label: 'Levantadores de hojas',
	},
	{
		code: '44122015',
		label: 'Respaldos para archivos',
	},
	{
		code: '44122016',
		label: 'Sujetador de documentos',

		palabrasSimilares: 'Tabla sujetadora de documentos',
	},
	{
		code: '44122017',
		label: 'Folders de colgar o accesorios',

		palabrasSimilares: 'Bastidor para carpetas colgantes de archivo',
	},
	{
		code: '44122018',
		label: 'Insertos o pestañas para archivos',
	},
	{
		code: '44122019',
		label: 'Bolsillos para archivos o accesorios',

		palabrasSimilares: 'Protector de hojas para carpeta',
	},
	{
		code: '44122020',
		label: 'Bolsillos para tarjetas',

		palabrasSimilares: 'Protector de tarjetas',
	},
	{
		code: '44122021',
		label: 'Álbumes de estampillas',
	},
	{
		code: '44122022',
		label: 'Accesorios de carpetas de folders',
	},
	{
		code: '44122023',
		label: 'Tabletas gráficas para arquitectura',
	},
	{
		code: '44122024',
		label: 'Manijas de carpetas',
	},
	{
		code: '44122025',
		label: 'Bolsillos de carpetas o accesorios',
	},
	{
		code: '44122026',
		label: 'Garras para papel',
	},
	{
		code: '44122027',
		label: 'Folders de archivo expandibles',
	},
	{
		code: '44122028',
		label: 'Canales para montar carpetas',
	},
	{
		code: '44122029',
		label: 'Folders de clasificación',
	},
	{
		code: '44122030',
		label: 'Kit de folders',

		palabrasSimilares: 'Paquete de folders',
	},
	{
		code: '44122031',
		label: 'Marco de rieles laterales para colgar folders',
	},
	{
		code: '44122032',
		label: 'Folders de conferencias',
	},
	{
		code: '44122033',
		label: 'Archivo de acordeón para folders',
	},
	{
		code: '44122034',
		label: 'Separador de páginas de libros',

		palabrasSimilares: 'Separadores de libros',
	},
	{
		code: '44122100',
		label: 'Suministros de sujeción',

		palabrasSimilares: 'Artículos de papelería',
	},
	{
		code: '44122101',
		label: 'Cauchos',

		palabrasSimilares: 'Ligas',
	},
	{
		code: '44122103',
		label: 'Sujetadores de cierre',
	},
	{
		code: '44122104',
		label: 'Clips para papel',
	},
	{
		code: '44122105',
		label: 'Clips para carpetas o bulldog',

		palabrasSimilares: 'Broches para hojas perforadas',
	},
	{
		code: '44122106',
		label: 'Alfileres o taches',
	},
	{
		code: '44122107',
		label: 'Grapas',
	},
	{
		code: '44122109',
		label: 'Sujetador de aro y bucle',
	},
	{
		code: '44122110',
		label: 'Monturas adhesivas',
	},
	{
		code: '44122111',
		label: 'Refuerzos para orificios',

		palabrasSimilares: 'Refuerzos adhesivos',
	},
	{
		code: '44122112',
		label: 'Sujetadores de cabeza redonda',
	},
	{
		code: '44122113',
		label: 'Sujetadores de etiquetas',
	},
	{
		code: '44122114',
		label: 'Postes roscados',
	},
	{
		code: '44122115',
		label: 'Esquinas adhesivas',
	},
	{
		code: '44122116',
		label: 'Clips de bolsas',
	},
	{
		code: '44122117',
		label: 'Anillos para libros',
	},
	{
		code: '44122118',
		label: 'Sujetadores de pinza',
	},
	{
		code: '44122119',
		label: 'Sujetadores auto adhesivos',
	},
	{
		code: '44122120',
		label: 'Postes de carpetas',
	},
	{
		code: '44122121',
		label: 'Clips de pared o tablero',
	},
	{
		code: '44122122',
		label: 'Tachuelas magnéticas',
	},
	{
		code: '45101500',
		label: 'Maquinaria y equipo de imprenta',
	},
	{
		code: '45101501',
		label: 'Impresoras heliográficas',
	},
	{
		code: '45101502',
		label: 'Prensas de impresión de offset',
	},
	{
		code: '45101503',
		label: 'Equipos de impresión tipográfica',
	},
	{
		code: '45101504',
		label: 'Equipos litográficos',
	},
	{
		code: '45101505',
		label: 'Máquinas impresoras de fotograbados',
	},
	{
		code: '45101506',
		label: 'Máquinas de serigrafía',
	},
	{
		code: '45101507',
		label: 'Prensas impresoras',
	},
	{
		code: '45101508',
		label: 'Máquinas perforadoras',
	},
	{
		code: '45101509',
		label: 'Impresora rotativa ultravioleta uv',
	},
	{
		code: '45101510',
		label: 'Impresora flexo gráfica',
	},
	{
		code: '45101511',
		label:
			'Impresora de inyección de tinta para aplicaciones de impresión comercial',
	},
	{
		code: '45101512',
		label:
			'Impresora de transferencia térmica para aplicaciones de impresión comercial',
	},
	{
		code: '45101513',
		label: 'Impresora de estampado en caliente',
	},
	{
		code: '45101514',
		label: 'Impresora de almohadillas',
	},
	{
		code: '45101515',
		label: 'Impresora básica',
	},
	{
		code: '45101516',
		label: 'Clichés de impresión de almohadillas',
	},
	{
		code: '45101517',
		label: 'Prensa de pruebas de offset',
	},
	{
		code: '45101518',
		label: 'Prensa de platinas de impresión',
	},
	{
		code: '45101600',
		label: 'Accesorios para máquinas de imprenta',
	},
	{
		code: '45101602',
		label: 'Equipos de cuarto oscuro de offset',
	},
	{
		code: '45101603',
		label: 'Consumibles de impresión de offset',
	},
	{
		code: '45101604',
		label: 'Procesadores de platinas de impresión de offset',
	},
	{
		code: '45101606',
		label: 'Procesadores de película de offset',
	},
	{
		code: '45101607',
		label: 'Lámparas de arco de serigrafía',
	},
	{
		code: '45101608',
		label: 'Pantallas de serigrafía',
	},
	{
		code: '45101609',
		label: 'Bastidores de impresión de serigrafía',
	},
	{
		code: '45101610',
		label: 'Armazones de impresión al vacío de serigrafía',
	},
	{
		code: '45101611',
		label: 'Escobillas de serigrafía',
	},
	{
		code: '45101612',
		label: 'Plecas (cuchillas doctor)',
	},
	{
		code: '45101700',
		label: 'Accesorios de imprenta',
	},
	{
		code: '45101701',
		label: 'Ensambladoras de impresión',
	},
	{
		code: '45101702',
		label: 'Guillotinas de impresión',
	},
	{
		code: '45101703',
		label: 'Intercaladores o desintercaladores de impresión',
	},
	{
		code: '45101704',
		label: 'Cortadoras de impresión',
	},
	{
		code: '45101705',
		label: 'Bordeadoras de impresión',
	},
	{
		code: '45101706',
		label: 'Punzones de impresión',
	},
	{
		code: '45101707',
		label: 'Platinas de impresión',
	},
	{
		code: '45101708',
		label: 'Leznas de impresión',
	},
	{
		code: '45101709',
		label: 'Película de impresión',
	},
	{
		code: '45101800',
		label: 'Equipo y accesorios para el cosido y encuadernación de libros',
	},
	{
		code: '45101801',
		label: 'Máquinas para arrugas de los libros',
	},
	{
		code: '45101802',
		label: 'Máquinas cortadoras de libros',
	},
	{
		code: '45101803',
		label: 'Máquinas perforadoras de libros',
	},
	{
		code: '45101804',
		label: 'Máquinas cosedoras de libros',
	},
	{
		code: '45101805',
		label: 'Máquinas de emparejamiento de libros',
	},
	{
		code: '45101806',
		label: 'Máquinas recogedoras de libros',
	},
	{
		code: '45101807',
		label: 'Máquinas dobladoras de libros',
	},
	{
		code: '45101808',
		label: 'Máquinas de encuadernado térmico de libros',
	},
	{
		code: '45101900',
		label: 'Equipo y accesorios de laboratorio de imprenta',
	},
	{
		code: '45101901',
		label: 'Máquinas de anillado de libros',
	},
	{
		code: '45101902',
		label: 'Clisadores',
	},
	{
		code: '45101903',
		label: 'Máquinas de perforación de papel',
	},
	{
		code: '45101904',
		label: 'Alargadores',
	},
	{
		code: '45101905',
		label: 'Tableros de dibujo o retoque',
	},
	{
		code: '45102000',
		label: 'Máquinas y accesorios de composición',
	},
	{
		code: '45102001',
		label: 'Máquinas de composición de intertipos',
	},
	{
		code: '45102002',
		label: 'Máquinas de composición de linotipos',
	},
	{
		code: '45102003',
		label: 'Materiales de fotocomposición',
	},
	{
		code: '45102004',
		label: 'Máquinas de composición de monotipos',
	},
	{
		code: '45102005',
		label: 'Máquinas de configuración de fototipos',
	},
	{
		code: '45111500',
		label: 'Atriles, sistemas de sonido y accesorios',
	},
	{
		code: '45111501',
		label: 'Atriles autónomos',
	},
	{
		code: '45111502',
		label: 'Atriles de mesa',
	},
	{
		code: '45111503',
		label: 'Mazos o bloques de sonido',
	},
	{
		code: '45111504',
		label: 'Componentes de luz o energía o datos para atriles',
	},
	{
		code: '45111600',
		label: 'Proyectores y suministros',
	},
	{
		code: '45111601',
		label: 'Señaladores',

		palabrasSimilares: 'Señalador laser y punteros',
	},
	{
		code: '45111602',
		label: 'Lámparas de proyección',
	},
	{
		code: '45111603',
		label: 'Pantallas o desplegadores para proyección',
	},
	{
		code: '45111604',
		label: 'Proyectores de filminas',
	},
	{
		code: '45111605',
		label: 'Equipos de transparencias o suministros',
	},
	{
		code: '45111606',
		label: 'Paneles de proyección de despliegue de cristal líquido',
	},
	{
		code: '45111607',
		label: 'Proyectores de techo',
	},
	{
		code: '45111608',
		label: 'Proyectores de películas',
	},
	{
		code: '45111609',
		label: 'Proyectores multimedia',
	},
	{
		code: '45111610',
		label: 'Epidiascopios',

		palabrasSimilares: 'Proyector de opacos',
	},
	{
		code: '45111612',
		label: 'Controles de disolución',
	},
	{
		code: '45111613',
		label: 'Proyectores de tubo de rayo catódico',
	},
	{
		code: '45111614',
		label: 'Proyectores de despliegue de cristal líquido',
	},
	{
		code: '45111615',
		label: 'Lentes de proyección',
	},
	{
		code: '45111616',
		label: 'Proyectores de video',
	},
	{
		code: '45111617',
		label: 'Proyector de techo o carritos de video',
	},
	{
		code: '45111618',
		label: 'Cajas de luces de presentaciones',
	},
	{
		code: '45111619',
		label: 'Bobina para películas',
	},
	{
		code: '45111620',
		label: 'Copiadora de películas de filminas',

		palabrasSimilares: 'Copiadora de diapositivas y negativos fotográficos',
	},
	{
		code: '45111700',
		label:
			'Equipo de composición y presentación de sonido, hardware y controladores',
	},
	{
		code: '45111701',
		label: 'Dispositivos de audición asistida',
	},
	{
		code: '45111702',
		label: 'Cajas de conectores de audio',
	},
	{
		code: '45111703',
		label: 'Centros de escucha',

		palabrasSimilares: 'Estéreo modular',
	},
	{
		code: '45111704',
		label: 'Consolas de mezclado de audio',
	},
	{
		code: '45111705',
		label: 'Sistemas de comunicación pública',
	},
	{
		code: '45111706',
		label: 'Combinación de mezclador y amplificador de audio',

		palabrasSimilares: 'Mezclador y amplificador',
	},
	{
		code: '45111707',
		label: 'Conector y caja de escenario de equipo de audio',

		palabrasSimilares: 'Cajas de conexión para empotrar en escenarios',
	},
	{
		code: '45111708',
		label: 'Controlador de dominio de transmisión',
	},
	{
		code: '45111709',
		label: 'Amplificador remoto',
	},
	{
		code: '45111710',
		label: 'Unidad de timbre y sirena',
	},
	{
		code: '45111711',
		label: 'Deflector (bafle) de micrófono',
	},
	{
		code: '45111712',
		label: 'Duplicador de cintas',

		palabrasSimilares: 'Duplicadores de casete',
	},
	{
		code: '45111713',
		label: 'Amplificador de distribución de audio',
	},
	{
		code: '45111714',
		label: 'Convertidor de audio de análogo a digital ad',
	},
	{
		code: '45111715',
		label: 'Convertidor de audio de digital a análogo da',
	},
	{
		code: '45111716',
		label: 'Estación de audio digital daw',
	},
	{
		code: '45111717',
		label: 'Reverberador',
	},
	{
		code: '45111718',
		label: 'Unidad de retardo',
	},
	{
		code: '45111719',
		label: 'Monitor de audio',
	},
	{
		code: '45111720',
		label: 'Analizador de espectro de audio',
	},
	{
		code: '45111721',
		label: 'Grabadora de cintas bobina a bobina',
	},
	{
		code: '45111722',
		label: 'Lector de casetes',
	},
	{
		code: '45111723',
		label: 'Grabadora de discos duros',
	},
	{
		code: '45111724',
		label: 'Cabezas magnéticas',
	},
	{
		code: '45111800',
		label:
			'Equipo de presentación de vídeo y de mezcla de vídeo y sonido, hardware y controladores',
	},
	{
		code: '45111801',
		label: 'Sistemas de control de medios',
	},
	{
		code: '45111802',
		label: 'Soportes para televisiones',
	},
	{
		code: '45111803',
		label: 'Convertidores de barrido (scan)',
	},
	{
		code: '45111804',
		label: 'Duplicadores de línea',
	},
	{
		code: '45111805',
		label: 'Editores de video',
	},
	{
		code: '45111806',
		label: 'Sistemas de aprendizaje a distancia',
	},
	{
		code: '45111807',
		label: 'Interfaces',
	},
	{
		code: '45111808',
		label: 'Controles de iluminación',
	},
	{
		code: '45111809',
		label: 'Accesorios de soporte de televisiones',
	},
	{
		code: '45111810',
		label: 'Presentadores visuales',
	},
	{
		code: '45111811',
		label: 'Monitor de precisión de video',
	},
	{
		code: '45111812',
		label: 'Dispositivo de despliegue de información visual',
	},
	{
		code: '45111813',
		label: 'Operador de introducción de datos corriente abajo',
	},
	{
		code: '45111814',
		label: 'Equipo de efectos de video digital dve',
	},
	{
		code: '45111815',
		label: 'Consola de audio video',
	},
	{
		code: '45111816',
		label: 'Generador de logos',
	},
	{
		code: '45111817',
		label: 'Generador de caracteres',
	},
	{
		code: '45111818',
		label: 'Reducidor de ruido de videos',
	},
	{
		code: '45111819',
		label: 'Mezclador de videos',
	},
	{
		code: '45111820',
		label: 'Amplificador de procesamiento de videos',
	},
	{
		code: '45111821',
		label: 'Generador de sincronización',
	},
	{
		code: '45111822',
		label: 'Convertidor de tasa de aspecto de televisión',
	},
	{
		code: '45111823',
		label: 'Convertidor de estándares de video',
	},
	{
		code: '45111824',
		label: 'Convertidor de sincronización',
	},
	{
		code: '45111825',
		label: 'Sincronizador de marco',
	},
	{
		code: '45111826',
		label: 'Codificador de grupo de expertos de películas animadas mpeg',
	},
	{
		code: '45111827',
		label: 'Verificador de video cintas',
	},
	{
		code: '45111828',
		label: 'Indicador (prompter)',
	},
	{
		code: '45111829',
		label: 'Cámara de proceso',
	},
	{
		code: '45111900',
		label:
			'Equipos, hardware y controladores para sistemas de teleconferencia y videoconferencia',
	},
	{
		code: '45111901',
		label: 'Sistemas de audio conferencias',
	},
	{
		code: '45111902',
		label: 'Sistemas de video conferencias',
	},
	{
		code: '45112000',
		label: 'Equipo y suministros de microfilm',
	},
	{
		code: '45112001',
		label: 'Dispositivos para ver micro fichas o micro cintas',
	},
	{
		code: '45112002',
		label: 'Impresoras lectoras de micro fichas',
	},
	{
		code: '45112003',
		label: 'Componentes o accesorios de micro fichas o micro cintas',
	},
	{
		code: '45112004',
		label: 'Componentes o accesorios de impresoras lectoras de micro fichas',
	},
	{
		code: '45121500',
		label: 'Cámaras',
	},
	{
		code: '45121501',
		label: 'Cámaras fijas',
	},
	{
		code: '45121502',
		label: 'Cámaras de impresión instantánea',
	},
	{
		code: '45121503',
		label: 'Cámaras desechables',
	},
	{
		code: '45121504',
		label: 'Cámaras digitales',
	},
	{
		code: '45121505',
		label: 'Cámaras cinematográficas',
	},
	{
		code: '45121506',
		label: 'Cámaras de video conferencia',
	},
	{
		code: '45121510',
		label: 'Cámaras aéreas',
	},
	{
		code: '45121511',
		label: 'Cámaras de alta velocidad',
	},
	{
		code: '45121512',
		label: 'Cámaras para debajo del agua',
	},
	{
		code: '45121513',
		label: 'Cámaras de offset',
	},
	{
		code: '45121514',
		label: 'Cámara fotocopiadora',
	},
	{
		code: '45121515',
		label: 'Cámaras grabadoras o video cámaras manuales',
	},
	{
		code: '45121516',
		label: 'Cámaras grabadoras o video cámaras digitales',
	},
	{
		code: '45121517',
		label: 'Cámaras para documentos',
	},
	{
		code: '45121518',
		label: 'Kits de cámaras',
	},
	{
		code: '45121519',
		label: 'Cámaras de baja luz',
	},
	{
		code: '45121520',
		label: 'Cámaras de web',
	},
	{
		code: '45121521',
		label: 'Cámaras de inspección',
	},
	{
		code: '45121522',
		label: 'Cámaras infrarrojas',
	},
	{
		code: '45121523',
		label: 'Cámaras astronómicas',
	},
	{
		code: '45121600',
		label: 'Accesorios para cámaras',
	},
	{
		code: '45121601',
		label: 'Flashes o iluminación para cámaras',
	},
	{
		code: '45121602',
		label: 'Trípodes para cámaras',

		palabrasSimilares: 'Tripiés para cámara',
	},
	{
		code: '45121603',
		label: 'Lentes para cámaras',
	},
	{
		code: '45121604',
		label: 'Oclusores para cámaras',
	},
	{
		code: '45121605',
		label: 'Marcos de pantalla',
	},
	{
		code: '45121606',
		label: 'Arneses para cámaras',
	},
	{
		code: '45121607',
		label: 'Bloques o sujetadores para cámaras',
	},
	{
		code: '45121608',
		label: 'Ensamblajes para cámaras',
	},
	{
		code: '45121609',
		label: 'Abrazaderas para cámaras',
	},
	{
		code: '45121610',
		label: 'Cables para cámaras',
	},
	{
		code: '45121611',
		label: 'Cubiertas para lentes',
	},
	{
		code: '45121612',
		label: 'Mesas para cámaras',
	},
	{
		code: '45121613',
		label: 'Contenedores o forros para cámaras',

		palabrasSimilares: 'Estuches para cámaras, Fundas para cámaras',
	},
	{
		code: '45121614',
		label: 'Kits de reacondicionamiento',
	},
	{
		code: '45121615',
		label: 'Anillos para cámaras',
	},
	{
		code: '45121616',
		label: 'Cabezales panorámicos',
	},
	{
		code: '45121617',
		label: 'Bolsas para cámaras',

		palabrasSimilares: 'Estuche para cámaras, Mochilas para cámaras',
	},
	{
		code: '45121618',
		label: 'Adaptadores de lentes para cámaras',
	},
	{
		code: '45121619',
		label: 'Billeteras para “picture card”',
	},
	{
		code: '45121620',
		label: 'Adaptadores de electricidad para cámaras',
	},
	{
		code: '45121621',
		label: 'Adaptadores para “picture card”',
	},
	{
		code: '45121622',
		label: 'Limpiadores para lentes de cámara',
	},
	{
		code: '45121623',
		label: 'Controladores de cámara',
	},
	{
		code: '45121624',
		label: 'Reflector de luz para fotografía',
	},
	{
		code: '45121625',
		label: 'Disparador',
	},
	{
		code: '45121626',
		label:
			'Respaldo de cámara digital (dispositivo que permite editar e imprimir)',
	},
	{
		code: '45121627',
		label: 'Difusor de flash de cámara',
	},
	{
		code: '45121628',
		label: 'Filtro de lente de cámara',
	},
	{
		code: '45121629',
		label: 'Motor de arrastre de cámara',
	},
	{
		code: '45121630',
		label: 'Reflector de parasol de cámara',
	},
	{
		code: '45121700',
		label: 'Equipo de procesamiento fotográfico',
	},
	{
		code: '45121701',
		label: 'Secadores de película',
	},
	{
		code: '45121702',
		label: 'Lavadores de película',
	},
	{
		code: '45121703',
		label: 'Cortadores de película',
	},
	{
		code: '45121704',
		label: 'Editores de película',
	},
	{
		code: '45121705',
		label: 'Alargadores fotográficos',
	},
	{
		code: '45121706',
		label: 'Cortadoras o bordeadoras de fotografías',
	},
	{
		code: '45121707',
		label: 'Secadora de impresión de fotografías',
	},
	{
		code: '45121708',
		label: 'Lavadora de impresión de fotografías',
	},
	{
		code: '45121709',
		label: 'Procesador de película automático',
	},
	{
		code: '45121710',
		label: 'Tambor de hojas de película',
	},
	{
		code: '45121711',
		label: 'Controlador de color',
	},
	{
		code: '45121712',
		label: 'Escritorio de edición de película',
	},
	{
		code: '45121713',
		label: 'Máquina de inspección de película',
	},
	{
		code: '45121714',
		label: 'Lector de película',
	},
	{
		code: '45121715',
		label: 'Colgador de película',
	},
	{
		code: '45121716',
		label: 'Impresora de contacto',
	},
	{
		code: '45121717',
		label: 'Sistema de platinas de película',
	},
	{
		code: '45121718',
		label: 'Densitómetro de película',
	},
	{
		code: '45121719',
		label: 'Kiosco de impresión de fotografías digitales',
	},
	{
		code: '45121720',
		label: 'Cargador de película',
	},
	{
		code: '45121800',
		label: 'Equipo y suministros de producción de microfilmes',
	},
	{
		code: '45121801',
		label: 'Cámaras de micro filmado',
	},
	{
		code: '45121802',
		label: 'Duplicadores de micro filmado',
	},
	{
		code: '45121803',
		label: 'Filtros de cubierta de micro filmado',
	},
	{
		code: '45121804',
		label: 'Procesadores de micro filmado',
	},
	{
		code: '45121805',
		label: 'Componentes o accesorios de cámara de micro filmado',
	},
	{
		code: '45121806',
		label: 'Componentes o accesorios de duplicador de micro filmado',
	},
	{
		code: '45121807',
		label: 'Componentes o accesorios de filtro de cubierta de micro filmado',
	},
	{
		code: '45121808',
		label: 'Componentes o accesorios de procesadores de micro filmado',
	},
	{
		code: '45121809',
		label: 'Suministros de película de micro filmado',
	},
	{
		code: '45121810',
		label: 'Componentes o accesorios diversos de micro filmado',
	},
	{
		code: '45131500',
		label: 'Película para cámara fotográfica',
	},
	{
		code: '45131501',
		label: 'Película de color',
	},
	{
		code: '45131502',
		label: 'Película de blanco y negro',
	},
	{
		code: '45131503',
		label: 'Película de fotografía instantánea',
	},
	{
		code: '45131505',
		label: 'Película de rayos x',
	},
	{
		code: '45131506',
		label: 'Película de filminas',

		palabrasSimilares: 'Película de diapositivas',
	},
	{
		code: '45131507',
		label: 'Micro filmado procesado',
	},
	{
		code: '45131508',
		label: 'Película de negativos',
	},
	{
		code: '45131509',
		label: 'Película reconocedora de color',
	},
	{
		code: '45131510',
		label: 'Tinte fotográfico',
	},
	{
		code: '45131600',
		label: 'Medios cinematográficos',
	},
	{
		code: '45131601',
		label: 'Película de cámara de películas animadas',
	},
	{
		code: '45131604',
		label: 'Cintas de video en blanco',
	},
	{
		code: '45131700',
		label: 'Almacenamiento para soportes audiovisuales',
	},
	{
		code: '45131701',
		label: 'Bandejas u organizadores para filminas',

		palabrasSimilares: 'Organizador de diapositivas',
	},
	{
		code: '45141500',
		label: 'Productos químicos para elaboración fotográfica',
	},
	{
		code: '45141501',
		label: 'Solución reveladora',
	},
	{
		code: '45141502',
		label: 'Fijadores',

		palabrasSimilares: 'Solución fijadora para fotografías',
	},
	{
		code: '45141503',
		label: 'Kit de procesamiento de revelado de fotografías',
	},
	{
		code: '45141504',
		label: 'Solución de optimización y corrección de fotografías',
	},
	{
		code: '45141600',
		label: 'Suministros de cuarto oscuro',
	},
	{
		code: '45141601',
		label: 'Bandejas de revelado',
	},
	{
		code: '45141602',
		label: 'Tanques de revelado',
	},
	{
		code: '45141603',
		label: 'Pinzas de revelado',
	},
	{
		code: '45141604',
		label: 'Procesador de impresión fotográfica',
	},
	{
		code: '45141605',
		label: 'Purificador de fotografías',
	},
	{
		code: '45141606',
		label: 'Bobina de tanque de revelado',
	},
	{
		code: '45141607',
		label: 'Cámara oscura',
	},
	{
		code: '45141608',
		label: 'Escurridor de película',
	},
	{
		code: '45141609',
		label: 'Mesa de trabajo de máquina reveladora de película',
	},
	{
		code: '45141610',
		label: 'Temporizador fotográfico',
	},
	{
		code: '45141611',
		label: 'Regulador de temperatura para procesamiento fotográfico',
	},
	{
		code: '45141612',
		label: 'Cuarto oscuro portátil',
	},
	{
		code: '46101500',
		label: 'Armas de fuego',
	},
	{
		code: '46101501',
		label: 'Ametralladoras',

		palabrasSimilares: 'Escopeta, Metralleta, Subfusi',
	},
	{
		code: '46101502',
		label: 'Escopetas para la policía p seguridad',

		palabrasSimilares: 'Fusil',
	},
	{
		code: '46101503',
		label: 'Rifles militares',
	},
	{
		code: '46101504',
		label: 'Pistolas',
	},
	{
		code: '46101505',
		label: 'Rifles de aire o pistolas de aire',

		palabrasSimilares: 'Rifles de municiones, Rifles de salva',
	},
	{
		code: '46101506',
		label: 'Partes de revólveres o pistolas',
	},
	{
		code: '46101600',
		label: 'Munición',
	},
	{
		code: '46101601',
		label: 'Municiones de defensa u orden público',

		palabrasSimilares: 'Armamentos, Balas',
	},
	{
		code: '46101700',
		label: 'Sistemas de manipulación de munición',
	},
	{
		code: '46101701',
		label: 'Sistemas de manipulación de municiones de tanque',

		palabrasSimilares:
			'Sistemas de manejo de balas de tanque, Sistemas de manejo de municiones de tanque',
	},
	{
		code: '46101702',
		label: 'Sistemas de manipulación de municiones de aeronaves',

		palabrasSimilares: 'Sistemas de manejo de armamento de aeronaves',
	},
	{
		code: '46101703',
		label: 'Contenedor de explosivos',
	},
	{
		code: '46101800',
		label: 'Accesorios de armas y municiones',
	},
	{
		code: '46101801',
		label: 'Estuches para revólveres',

		palabrasSimilares: 'Estuches para pistolas, Fundas para armas de fuego',
	},
	{
		code: '46101802',
		label: 'Cinturón para cartuchos',

		palabrasSimilares: 'Canana para cartuchos, Carrillera para cartuchos',
	},
	{
		code: '46111700',
		label: 'Sensores de rayos infrarrojos (IR)',
	},
	{
		code: '46111701',
		label: 'Enfriadores infrarrojos ir',
	},
	{
		code: '46111702',
		label: 'Detectores infrarrojos ir',

		palabrasSimilares: 'Sensores infrarrojos ir',
	},
	{
		code: '46111703',
		label: 'Iluminadores infrarrojos ir',
	},
	{
		code: '46111704',
		label: 'Receptores infrarrojos ir',

		palabrasSimilares: 'Recibidores infrarrojos ir',
	},
	{
		code: '46111705',
		label: 'Telescopios infrarrojos ir',

		palabrasSimilares: 'Lente infrarrojos ir',
	},
	{
		code: '46151500',
		label: 'Equipo de control de masas',
	},
	{
		code: '46151501',
		label: 'Barricadas',

		palabrasSimilares: 'Muros, Parapetos',
	},
	{
		code: '46151502',
		label: 'Cascos anti motines',
	},
	{
		code: '46151503',
		label: 'Escudos anti motines',

		palabrasSimilares: 'Protecciones antimotines',
	},
	{
		code: '46151504',
		label: 'Armadura para el cuerpo',

		palabrasSimilares: 'Vestidura para el cuerpo',
	},
	{
		code: '46151505',
		label: 'Barreras',
	},
	{
		code: '46151506',
		label: 'Bastones anti motines',

		palabrasSimilares:
			'Cachavas anti motines, Garrotes anti motines, Macanas anti motine',
	},
	{
		code: '46151507',
		label: 'Sistema de control de filas',

		palabrasSimilares: 'Sistema de manejo de filas',
	},
	{
		code: '46151600',
		label: 'Equipo de seguridad y control',
	},
	{
		code: '46151601',
		label: 'Esposas',

		palabrasSimilares: 'Ataduras, Grilletes',
	},
	{
		code: '46151602',
		label: 'Cachiporras',

		palabrasSimilares: 'Bastones, Garrotes',
	},
	{
		code: '46151604',
		label: 'Analizadores de alcohol',

		palabrasSimilares: 'Alcoholímetro, Alcohómetro',
	},
	{
		code: '46151605',
		label: 'Detectores de armas o explosivos y suministros',
	},
	{
		code: '46151606',
		label: 'Kits de pruebas de narcóticos',

		palabrasSimilares: 'Kits de pruebas de drogas, Kits de pruebas de sedantes',
	},
	{
		code: '46151607',
		label: 'Cabos de amarre de seguridad',

		palabrasSimilares:
			'Aparejos de amarre de seguridad, Destorcedores de amarre de seguridad',
	},
	{
		code: '46151608',
		label: 'Dispositivos de protección contra bombas y suministros',

		palabrasSimilares: 'Dispositivos y suministros de protección contra bombas',
	},
	{
		code: '46151609',
		label: 'Discriminador de documentos de identificación',

		palabrasSimilares: 'Discriminador de tarjeta de identificación',
	},
	{
		code: '46151700',
		label: 'Equipo forense y accesorios y suministros',
	},
	{
		code: '46151702',
		label: 'Aplicadores o cepillos de huellas dactilares',
	},
	{
		code: '46151703',
		label: 'Tinta de huellas dactilares',
	},
	{
		code: '46151704',
		label: 'Removedores de tinta de huellas dactilares',
	},
	{
		code: '46151705',
		label: 'Rodillos de tinta para huellas dactilares o de palma de la mano',
	},
	{
		code: '46151706',
		label: 'Kit de impresión latente de huellas dactilares',
	},
	{
		code: '46151707',
		label: 'Levantadores de huellas dactilares',
	},
	{
		code: '46151708',
		label: 'Magnificadores para uso forense',
	},
	{
		code: '46151709',
		label: 'Esferos de marcado de huellas dactilares',

		palabrasSimilares: 'Marcadores de huellas dactilares',
	},
	{
		code: '46151710',
		label: 'Polvos de huellas dactilares',
	},
	{
		code: '46151711',
		label: 'Levantadores de huellas plantares',
	},
	{
		code: '46151712',
		label: 'Estaciones de trabajo químico para uso forense',
	},
	{
		code: '46151713',
		label: 'Químicos de impresiones latentes para uso forense',
	},
	{
		code: '46151714',
		label: 'Gabinetes de secado de evidencia',
	},
	{
		code: '46151715',
		label: 'Equipo de huellas dactilares',
	},
	{
		code: '46151716',
		label: 'Detector de mentiras',
	},
	{
		code: '46151800',
		label: 'Equipo y accesorios y suministros de control de explosivos',
	},
	{
		code: '46151801',
		label: 'Contenedor de protección de onda expansiva',

		palabrasSimilares: 'Recipiente de contención de onda expansiva',
	},
	{
		code: '46151900',
		label:
			'Equipo y accesorios y suministros para el control químico biológico',
	},
	{
		code: '46151901',
		label: 'Remolque de descontaminación biológica química',
	},
	{
		code: '46161500',
		label: 'Control de tráfico',
	},
	{
		code: '46161501',
		label: 'Sistemas de señalización para aeropuertos',

		palabrasSimilares: 'Sistemas de señalización aeroportuaria',
	},
	{
		code: '46161502',
		label: 'Sistemas de señalización para ferrocarriles',

		palabrasSimilares: 'Sistemas de señalización ferroviaria',
	},
	{
		code: '46161503',
		label: 'Sistemas de señalización marinos',

		palabrasSimilares: 'Sistemas de señalización marítima',
	},
	{
		code: '46161504',
		label: 'Señales de tráfico',

		palabrasSimilares: 'Señalamientos de tránsito vehicular',
	},
	{
		code: '46161505',
		label: 'Medidores de estacionamiento',

		palabrasSimilares: 'Parquímetros',
	},
	{
		code: '46161506',
		label: 'Máquinas para derretir nieve o hielo',
	},
	{
		code: '46161507',
		label: 'Cintas o cadenas de barrera',
	},
	{
		code: '46161508',
		label: 'Conos o delineadores de tráfico',
	},
	{
		code: '46161509',
		label: 'Paradas de velocidad',
	},
	{
		code: '46161510',
		label: 'Sistemas de barrera para puertas',

		palabrasSimilares: 'Métodos de barrera de puertas',
	},
	{
		code: '46161511',
		label: 'Balizas de tráfico',

		palabrasSimilares: 'Faro para control de tráfico, Semáforos',
	},
	{
		code: '46161512',
		label: 'Cadenas de tráfico',
	},
	{
		code: '46161513',
		label: 'Cerca de seguridad de tráfico',

		palabrasSimilares:
			'Barrera de seguridad de tráfico, Malla de seguridad de tráfico',
	},
	{
		code: '46161514',
		label: 'Montantes de carretera cats eye',

		palabrasSimilares: 'Montantes ojo de gato',
	},
	{
		code: '46161515',
		label: 'Amortiguador de choques',

		palabrasSimilares: 'Cojín de choque, Muro de contención',
	},
	{
		code: '46161516',
		label: 'Sistema de reconocimiento de placas',

		palabrasSimilares: 'Sistema de reconocimiento de matrículas',
	},
	{
		code: '46161517',
		label: 'Riel de división de carriles',
	},
	{
		code: '46161518',
		label: 'Tambor canalizador de control de tráfico',

		palabrasSimilares: 'Barril de tráfico',
	},
	{
		code: '46161519',
		label: 'Cinta de carriles de tráfico',

		palabrasSimilares: 'Cinta de marcado de carril',
	},
	{
		code: '46161520',
		label: 'Señales de mensaje variable',
	},
	{
		code: '46161521',
		label: 'Luz de advertencia de entrada y salida de estacionamientos',
	},
	{
		code: '46161522',
		label: 'Luz de advertencia de cercanía de estacionamientos',
	},
	{
		code: '46161523',
		label: 'Máquina para derretir nieve en estacionamientos',

		palabrasSimilares: 'Máquina para fundir nieve en estacionamientos',
	},
	{
		code: '46161524',
		label: 'Analizador de flujo de tráfico',
	},
	{
		code: '46161525',
		label: 'Cable de bucle para detección de vehículos',

		palabrasSimilares: 'Bobina de detección de vehículos',
	},
	{
		code: '46161526',
		label: 'Sensor de peatones',
	},
	{
		code: '46161527',
		label: 'Sensor de flujo de tráfico',
	},
	{
		code: '46161528',
		label: 'Luz de advertencia para aeropuertos',
	},
	{
		code: '46161529',
		label: 'Dispositivo de guía de voz para personas invidentes',
	},
	{
		code: '46161530',
		label: 'Puerta de barrera de estacionamiento',

		palabrasSimilares: 'Barrera de estacionamiento',
	},
	{
		code: '46161531',
		label: 'Espejo de seguridad de tráfico',
	},
	{
		code: '46161600',
		label: 'Seguridad del Agua',
	},
	{
		code: '46161601',
		label: 'Líneas de flotadores',

		palabrasSimilares: 'Líneas de flotación de cuerda',
	},
	{
		code: '46161602',
		label: 'Salvavidas',

		palabrasSimilares: 'Boya de vida, Flotadores',
	},
	{
		code: '46161603',
		label: 'Alarmas de piscina',
	},
	{
		code: '46161604',
		label: 'Chalecos o protectores salvavidas',

		palabrasSimilares: 'Chalecos salvavidas',
	},
	{
		code: '46161605',
		label: 'Boya salvavidas',
	},
	{
		code: '46161700',
		label: 'Equipo y accesorios de rescate',
	},
	{
		code: '46161701',
		label: 'Línea salvavidas descendente',
	},
	{
		code: '46161702',
		label: 'Bolsa de aire de rescate',
	},
	{
		code: '46161703',
		label: 'Esterilla aérea de seguridad',

		palabrasSimilares: 'Colchón neumático de rescate',
	},
	{
		code: '46161704',
		label: 'Lanzador de línea de rescate',
	},
	{
		code: '46161705',
		label: 'Set de equipos hidráulicos de rescate',

		palabrasSimilares: 'Equipo de rescate hidráulico',
	},
	{
		code: '46161706',
		label: 'Tienda de aire',
	},
	{
		code: '46161707',
		label: 'Abridor de puertas de rescate',
	},
	{
		code: '46161708',
		label: 'Red de rescate',

		palabrasSimilares: 'Malla de rescate',
	},
	{
		code: '46161710',
		label: 'Luz de rescate',
	},
	{
		code: '46161711',
		label: 'Detector de vida',
	},
	{
		code: '46161712',
		label: 'Alerta de seguridad personal',
	},
	{
		code: '46161713',
		label: 'Dispositivo de señales de humo',

		palabrasSimilares: 'Aparato de señales de humo',
	},
	{
		code: '46161714',
		label: 'Línea salvavidas',
	},
	{
		code: '46161715',
		label: 'Equipo de rescate en espacios confinados',
	},
	{
		code: '46171500',
		label: 'Cerraduras, elementos de seguridad y accesorios',
	},
	{
		code: '46171501',
		label: 'Candados',
	},
	{
		code: '46171502',
		label: 'Candados de cable',
	},
	{
		code: '46171503',
		label: 'Sets de candados',

		palabrasSimilares: 'Juegos de candados',
	},
	{
		code: '46171504',
		label: 'Candados de botón para oprimir',

		palabrasSimilares: 'Cerraduras electrónicas',
	},
	{
		code: '46171505',
		label: 'Llaves',
	},
	{
		code: '46171506',
		label: 'Cajas fuertes',
	},
	{
		code: '46171507',
		label: 'Barras de seguridad',
	},
	{
		code: '46171508',
		label: 'Candados de números',

		palabrasSimilares: 'Candados de combinación',
	},
	{
		code: '46171509',
		label: 'Gabinetes u organizadores con llave',

		palabrasSimilares: 'Armarios y organizadores con llave',
	},
	{
		code: '46171510',
		label: 'Candados de tiempo',
	},
	{
		code: '46171511',
		label: 'Dispositivos de bloqueo',

		palabrasSimilares: 'Aparatos de bloqueo',
	},
	{
		code: '46171512',
		label: 'Candados de instrumentos',

		palabrasSimilares: 'Cerraduras de instrumentos',
	},
	{
		code: '46171513',
		label: 'Levas de bloqueo',
	},
	{
		code: '46171514',
		label: 'Cadenas de seguridad o accesorios',

		palabrasSimilares: 'Cadenas o accesorios de seguridad',
	},
	{
		code: '46171515',
		label: 'Cadenas de llaves o estuches de llaves',

		palabrasSimilares: 'Llaveros o estuches para llaves',
	},
	{
		code: '46171516',
		label: 'Guardas para puertas',

		palabrasSimilares: 'Protectores de puertas',
	},
	{
		code: '46171517',
		label: 'Señales de chapa',
	},
	{
		code: '46171518',
		label: 'Placa de impacto eléctrica',

		palabrasSimilares: 'Placa eléctrica',
	},
	{
		code: '46171519',
		label: 'Accesorio cilíndrico para candados',
	},
	{
		code: '46171520',
		label: 'Candados eléctricos',
	},
	{
		code: '46171521',
		label: 'Placa de impacto no eléctrica',
	},
	{
		code: '46171522',
		label: 'Cerramientos para candados',

		palabrasSimilares: 'Caja de cerradura',
	},
	{
		code: '46171523',
		label: 'Sistema de bloqueo mecánico',
	},
	{
		code: '46171524',
		label: 'Candado de llave de tarjeta',

		palabrasSimilares: 'Cerradura con tarjeta',
	},
	{
		code: '46171600',
		label: 'Equipo de vigilancia y detección',
	},
	{
		code: '46171602',
		label: 'Alarmas de seguridad',
	},
	{
		code: '46171603',
		label: 'Temporizadores de reloj',
	},
	{
		code: '46171604',
		label: 'Sistemas de alarma',

		palabrasSimilares: 'Sistemas de seguridad',
	},
	{
		code: '46171605',
		label: 'Timbres de puerta',
	},
	{
		code: '46171606',
		label: 'Sirenas',

		palabrasSimilares: 'Alarmas',
	},
	{
		code: '46171607',
		label: 'Timbres de zumbido',
	},
	{
		code: '46171608',
		label: 'Detectores de movimiento',

		palabrasSimilares: 'Sensores de movimiento',
	},
	{
		code: '46171609',
		label: 'Espejos convexos de seguridad',
	},
	{
		code: '46171610',
		label: 'Cámaras de seguridad',

		palabrasSimilares: 'Videocámaras de seguridad',
	},
	{
		code: '46171611',
		label: 'Sistemas de identificación de video',

		palabrasSimilares: 'Dispositivos de identificación de video',
	},
	{
		code: '46171612',
		label: 'Monitores de video',

		palabrasSimilares: 'Pantallas de video',
	},
	{
		code: '46171613',
		label: 'Detectores de gas',
	},
	{
		code: '46171615',
		label: 'Cámaras de optimización de luz o dispositivos de visión',

		palabrasSimilares: 'Cámaras o dispositivos de visión que mejoran la luz',
	},
	{
		code: '46171616',
		label: 'Detectores de radar',
	},
	{
		code: '46171617',
		label: 'Ojos (mirillas) para puertas',
	},
	{
		code: '46171618',
		label: 'Campanas para puertas',

		palabrasSimilares: 'Timbres',
	},
	{
		code: '46171619',
		label: 'Sistemas de seguridad o de control de acceso',
	},
	{
		code: '46171620',
		label: 'Cortinas de luz de seguridad',
	},
	{
		code: '46171621',
		label: 'Grabadoras de video o audio de vigilancia',

		palabrasSimilares: 'Grabadoras de video o audio de vigilancia',
	},
	{
		code: '46171622',
		label: 'Sistema de televisión de circuito cerrado cctv',

		palabrasSimilares: 'Sistema de circuito cerrado de televisión',
	},
	{
		code: '46171623',
		label: 'Alarma de seguridad para el hogar',
	},
	{
		code: '46171624',
		label: 'Sistema de rayos x para inspección de equipaje',
	},
	{
		code: '46171625',
		label: 'Sistema de monitoreo de comunicaciones por radio',

		palabrasSimilares: 'Sistema de control de comunicaciones por radio',
	},
	{
		code: '46171626',
		label: 'Equipo de visión nocturna',

		palabrasSimilares: 'Gafas de visión nocturna, Lentes de visión nocturna',
	},
	{
		code: '46171627',
		label: 'Sistema de señalización inalámbrica para sordos',

		palabrasSimilares:
			'Sistema de señalización inalámbrica para personas con impedimentos auditivos',
	},
	{
		code: '46171628',
		label: 'Indicador automático de la hora',

		palabrasSimilares: 'Relojes digitales',
	},
	{
		code: '46171629',
		label: 'Espejo de señales',
	},
	{
		code: '46171630',
		label: 'Sensor de nivel de agua y lluvia para vehículos',

		palabrasSimilares: 'Detector de nivel de agua y lluvia para vehículos',
	},
	{
		code: '46171631',
		label: 'Alarma sísmica',
	},
	{
		code: '46171632',
		label: 'Sensor infrarrojo pasivo',

		palabrasSimilares: 'Detector infrarrojo pasivo',
	},
	{
		code: '46171633',
		label: 'Detector de metales de seguridad',
	},
	{
		code: '46171634',
		label: 'Papel detector de agentes químicos',
	},
	{
		code: '46171635',
		label: 'Detector de agentes químicos',
	},
	{
		code: '46171636',
		label: 'Equipo de transmisión de facsímil de seguridad',
	},
	{
		code: '46171637',
		label: 'Dispositivo de interceptación electrónica',

		palabrasSimilares: 'Aparatos de interceptación electrónica',
	},
	{
		code: '46171638',
		label: 'Detector y alarma de radiación',
	},
	{
		code: '46171639',
		label: 'Video compensador de tráfico',
	},
	{
		code: '46171640',
		label: 'Sistema de monitoreo automático de tráfico',
	},
	{
		code: '46181500',
		label: 'Ropa de seguridad',
	},
	{
		code: '46181501',
		label: 'Delantales protectores',

		palabrasSimilares:
			'Batas de protección, Guardapolvos de protección, Mandiles de protecció',
	},
	{
		code: '46181502',
		label: 'Chalecos anti balas',
	},
	{
		code: '46181503',
		label: 'Overoles de protección',

		palabrasSimilares: 'Prenda de uso industrial',
	},
	{
		code: '46181504',
		label: 'Guantes de protección',

		palabrasSimilares: 'Guantes protectores',
	},
	{
		code: '46181505',
		label: 'Rodilleras de protección',
	},
	{
		code: '46181506',
		label: 'Ponchos de protección',

		palabrasSimilares: 'Impermeables',
	},
	{
		code: '46181507',
		label: 'Chalecos de seguridad',

		palabrasSimilares: 'Prendas de seguridad',
	},
	{
		code: '46181508',
		label: 'Ropa para demorar el fuego',

		palabrasSimilares: 'Ropa ignífuga o de antifuego',
	},
	{
		code: '46181509',
		label: 'Ropa de protección contra materiales peligrosos',
	},
	{
		code: '46181512',
		label: 'Ropa para cuartos de limpieza',

		palabrasSimilares: 'Ropa para la limpieza de habitaciones',
	},
	{
		code: '46181514',
		label: 'Protectores de codos',
	},
	{
		code: '46181516',
		label: 'Mangas de seguridad',
	},
	{
		code: '46181517',
		label: 'Vestidos de aislamiento o de flotación',

		palabrasSimilares: 'Trajes aislados o de flotación',
	},
	{
		code: '46181518',
		label: 'Ropa resistente al calor',
	},
	{
		code: '46181520',
		label: 'Protectores de piernas',

		palabrasSimilares: 'Protectores para las extremidades',
	},
	{
		code: '46181522',
		label: 'Capuchas de seguridad',

		palabrasSimilares: 'Capuchones de seguridad, Gorros de seguridad',
	},
	{
		code: '46181526',
		label: 'Camisas protectoras',

		palabrasSimilares: 'Camisas de protección',
	},
	{
		code: '46181527',
		label: 'Pantalones protectores',

		palabrasSimilares: 'Pantalones de seguridad',
	},
	{
		code: '46181528',
		label: 'Vestido protector',

		palabrasSimilares: 'Traje de protección',
	},
	{
		code: '46181529',
		label: 'Ropa aislante para entornos fríos',
	},
	{
		code: '46181530',
		label: 'Forros protectores de dedos',

		palabrasSimilares: 'Dediles de protección',
	},
	{
		code: '46181531',
		label: 'Ropa reflectora o accesorios',
	},
	{
		code: '46181532',
		label: 'Batas de laboratorio',

		palabrasSimilares: 'Delantales de laboratorio, Mandiles de laboratorio',
	},
	{
		code: '46181533',
		label: 'Batas protectoras',

		palabrasSimilares: 'Delantales de protección, Mandiles de protección',
	},
	{
		code: '46181534',
		label: 'Muñequeras protectoras',
	},
	{
		code: '46181535',
		label: 'Medias o medias largas protectoras',

		palabrasSimilares: 'Calcetines o medias de protección',
	},
	{
		code: '46181536',
		label: 'Guantes anti cortadas',

		palabrasSimilares: 'Mitones anti cortadas',
	},
	{
		code: '46181537',
		label: 'Guantes aislantes',

		palabrasSimilares: 'Mitones aislantes',
	},
	{
		code: '46181538',
		label: 'Guantes térmicos',

		palabrasSimilares: 'Mitones térmicos',
	},
	{
		code: '46181539',
		label: 'Guantes anti vibraciones',

		palabrasSimilares: 'Mitones anti vibraciones',
	},
	{
		code: '46181540',
		label: 'Guantes de soldadura',

		palabrasSimilares: 'Mitones de soldadura',
	},
	{
		code: '46181541',
		label: 'Guantes resistentes a los químicos',

		palabrasSimilares: 'Mitones resistentes a los químicos',
	},
	{
		code: '46181542',
		label: 'Mitones protectores',

		palabrasSimilares: 'Guantes de protección para deporte',
	},
	{
		code: '46181543',
		label: 'Chaqueta o gabardina impermeable',

		palabrasSimilares: 'Chaquetas o impermeables',
	},
	{
		code: '46181544',
		label: 'Pantalones impermeable',

		palabrasSimilares: 'Pantalones aislantes',
	},
	{
		code: '46181545',
		label: 'Vestido impermeable',

		palabrasSimilares: 'Traje impermeable',
	},
	{
		code: '46181546',
		label: 'Capa impermeable',
	},
	{
		code: '46181547',
		label: 'Cubiertas de capa impermeables',
	},
	{
		code: '46181548',
		label: 'Delantal de soldadura',

		palabrasSimilares: 'Bata para soldadura, Mandil para soldadura',
	},
	{
		code: '46181549',
		label: 'Bufandas',

		palabrasSimilares: 'Polaina de cuello',
	},
	{
		code: '46181550',
		label: 'Bufandas protectoras',

		palabrasSimilares: 'Bandas protectoras',
	},
	{
		code: '46181551',
		label: 'Chaqueta de malla protectora',

		palabrasSimilares: 'Chamarra de malla para protección',
	},
	{
		code: '46181552',
		label: 'Lámpara de cabeza para minería',

		palabrasSimilares:
			'Faro de cabeza para minería, Foco de cabeza para minería, Luz de cabeza para minerí',
	},
	{
		code: '46181600',
		label: 'Calzado de protección',
	},
	{
		code: '46181601',
		label: 'Calzado para demorar el fuego',

		palabrasSimilares:
			'Calzado ignífugo o antifuego, Calzado para combatir el fuego',
	},
	{
		code: '46181602',
		label: 'Calzado protector contra materiales peligrosos',

		palabrasSimilares: 'Calzado protector de materiales peligrosos',
	},
	{
		code: '46181603',
		label: 'Calzado para cuartos de limpieza',
	},
	{
		code: '46181604',
		label: 'Botas de seguridad',
	},
	{
		code: '46181605',
		label: 'Zapatos de seguridad',
	},
	{
		code: '46181606',
		label: 'Forros para calzado',

		palabrasSimilares: 'Cubiertas para calzado',
	},
	{
		code: '46181607',
		label: 'Zuecos protectores',

		palabrasSimilares: 'Calzado zueco de protección',
	},
	{
		code: '46181608',
		label: 'Sandalias protectoras',
	},
	{
		code: '46181609',
		label: 'Plantillas protectoras',

		palabrasSimilares: 'Plantillas de protección',
	},
	{
		code: '46181610',
		label: 'Zapatos aislados para clima frío',

		palabrasSimilares: 'Zapato con aislamiento de clima frío',
	},
	{
		code: '46181611',
		label: 'Botas impermeables',

		palabrasSimilares: 'Calzado impermeable',
	},
	{
		code: '46181612',
		label: 'Botas militares',

		palabrasSimilares: 'Calzado militar',
	},
	{
		code: '46181613',
		label: 'Botas para escalar montañas',

		palabrasSimilares: 'Botas de montaña',
	},
	{
		code: '46181700',
		label: 'Protectores de cara y cabeza',
	},
	{
		code: '46181701',
		label: 'Cascos',
	},
	{
		code: '46181702',
		label: 'Escudos faciales',

		palabrasSimilares: 'Protectores faciales',
	},
	{
		code: '46181703',
		label: 'Máscaras de soldadura',

		palabrasSimilares: 'Máscaras y caretas para soldar',
	},
	{
		code: '46181704',
		label: 'Cascos de seguridad',

		palabrasSimilares: 'Cascos industriales',
	},
	{
		code: '46181705',
		label: 'Cascos para motociclistas',
	},
	{
		code: '46181706',
		label: 'Partes de cascos o accesorios',

		palabrasSimilares: 'Partes o accesorios para casco',
	},
	{
		code: '46181707',
		label: 'Partes de escudos faciales o accesorios',

		palabrasSimilares: 'Partes o accesorios para protectores faciales',
	},
	{
		code: '46181708',
		label: 'Red protectora para el cabello',

		palabrasSimilares: 'Malla protectora para el cabello',
	},
	{
		code: '46181709',
		label: 'Kit de protección facial',

		palabrasSimilares: 'Accesorios de protección facial',
	},
	{
		code: '46181710',
		label: 'Capucha protectora',
	},
	{
		code: '46181711',
		label: 'Casco de soldadura',
	},
	{
		code: '46181800',
		label: 'Protección y accesorios para la visión',
	},
	{
		code: '46181801',
		label: 'Sujetadores o estuches de anteojos',

		palabrasSimilares: 'Fundas para lentes, Soportes o estuches para lentes',
	},
	{
		code: '46181802',
		label: 'Anteojos de seguridad',
	},
	{
		code: '46181803',
		label: 'Protectores de ojos',
	},
	{
		code: '46181804',
		label: 'Gafas protectoras',
	},
	{
		code: '46181805',
		label: 'Filtros de despliegue de video',

		palabrasSimilares: 'Filtros de visualización de vídeo',
	},
	{
		code: '46181806',
		label: 'Limpiadores de lentes',

		palabrasSimilares: 'Limpiadores de gafas',
	},
	{
		code: '46181808',
		label: 'Cubiertas protectoras para gafas de seguridad',

		palabrasSimilares: 'Tapas protectoras para gafas de seguridad',
	},
	{
		code: '46181809',
		label: 'Ligas para protectores de ojos',

		palabrasSimilares: 'Ligas para lentes',
	},
	{
		code: '46181810',
		label: 'Lavadores de ojos o estaciones para lavado de ojos',
	},
	{
		code: '46181811',
		label: 'Lentes protectores',
	},
	{
		code: '46181900',
		label: 'Protectores auditivos',
	},
	{
		code: '49161709',
		label: 'Círculo de lanzamiento de disco',
	},
	{
		code: '49161710',
		label: 'Martillo para lanzar',
	},
	{
		code: '70121504',
		label: 'Servicios de laboratorio de lácteos',
	},
	{
		code: '70121505',
		label: 'Elaboración propia de fincas  lecheras',

		palabrasSimilares: 'Granja lechera',
	},
	{
		code: '70121600',
		label: 'Industria ganadera',

		palabrasSimilares: 'Servicios para la industria ganadera',
	},
	{
		code: '70121601',
		label: 'Cría de ganado',
	},
	{
		code: '70121602',
		label: 'Servicios de genética ganadera',
	},
	{
		code: '70121603',
		label: 'Sericultura',

		palabrasSimilares: 'Cría de gusano de seda',
	},
	{
		code: '70121604',
		label: 'Explotación ganadera',
	},
	{
		code: '70121605',
		label: 'Sistemas de cría en fincas',
	},
	{
		code: '70121606',
		label: 'Servicios de producción de aves de corral',
	},
	{
		code: '70121607',
		label: 'Servicios de reproducción de ganado menor',
	},
	{
		code: '70121608',
		label: 'Servicios de producción bovina',

		palabrasSimilares: 'Servicios de producción vacuna',
	},
	{
		code: '70121610',
		label: 'Apicultura',

		palabrasSimilares: 'Cría de abejas',
	},
	{
		code: '70121700',
		label: 'Gestión ganadera',

		palabrasSimilares: 'Servicios de gestión ganadera',
	},
	{
		code: '70121701',
		label: 'Selección ganadera',
	},
	{
		code: '70121702',
		label: 'Servicios de exhibición de ganado',
	},
	{
		code: '70121703',
		label: 'Servicios de sacrificio de ganado',
	},
	{
		code: '70121704',
		label: 'Administración de hatos',

		palabrasSimilares: 'Administración de ganado',
	},
	{
		code: '70121705',
		label: 'Servicios de cría y cuidado del ganado',

		palabrasSimilares: 'Servicios de cría y cuidado del hato',
	},
	{
		code: '70121800',
		label: 'Industria de animales domésticos',

		palabrasSimilares: 'Servicios para la industria de animales domésticos',
	},
	{
		code: '70121801',
		label: 'Servicios de cría de animales domésticos',
	},
	{
		code: '70121802',
		label: 'Servicios de cuidado de animales domésticos',
	},
	{
		code: '70121803',
		label: 'Servicios de residencia canina',

		palabrasSimilares: 'Refugios de animales',
	},
	{
		code: '70121900',
		label: 'Servicios de pastos y dehesas',

		palabrasSimilares: 'Servicios de pastoreo y manejo de su extensión',
	},
	{
		code: '70121901',
		label: 'Mejoramiento de pastizales',

		palabrasSimilares: 'Mejoramiento del prado',
	},
	{
		code: '70121902',
		label: 'Manejo de pasturas',

		palabrasSimilares: 'Manejo de hierba',
	},
	{
		code: '70121903',
		label: 'Investigación de tierras de pastoreo',
	},
	{
		code: '70122000',
		label: 'Salud animal',

		palabrasSimilares: 'Servicios veterinarios',
	},
	{
		code: '70122001',
		label: 'Nutrición animal',
	},
	{
		code: '70122002',
		label: 'Control de enfermedades animales',
	},
	{
		code: '70122003',
		label: 'Tripanosomiasis animal',
	},
	{
		code: '70122004',
		label: 'Servicios de control de patas y boca',
	},
	{
		code: '70122005',
		label: 'Servicios de medicación preventiva de salud animal',

		palabrasSimilares: 'Medicación preventiva para la salud animal',
	},
	{
		code: '70122006',
		label: 'Servicios de vacunación animal',
	},
	{
		code: '70122007',
		label: 'Administración veterinaria',

		palabrasSimilares: 'Gestión veterinaria',
	},
	{
		code: '70122008',
		label: 'Tecnología para laboratorio veterinario',
	},
	{
		code: '70122009',
		label: 'Servicios hospitalarios para animales',
	},
	{
		code: '70122010',
		label: 'Servicios de información de salud animal',
	},
	{
		code: '70131500',
		label: 'Protección del terreno y del suelo',

		palabrasSimilares: 'Servicios de protección del terreno y suelo',
	},
	{
		code: '70131501',
		label: 'Servicios de control o evaluación de la desertificación',
	},
	{
		code: '70131502',
		label: 'Servicios de conservación o protección del suelo',

		palabrasSimilares: 'Servicios de preservación o protección del suelo',
	},
	{
		code: '70131503',
		label: 'Servicios de control de la erosión',

		palabrasSimilares: 'Servicios de control del deterioro de suelo',
	},
	{
		code: '70131504',
		label: 'Fijación de dunas',
	},
	{
		code: '70131505',
		label: 'Acondicionamiento del suelo',

		palabrasSimilares: 'Acondicionamiento del terreno',
	},
	{
		code: '70131506',
		label: 'Mejoramiento del suelo',

		palabrasSimilares: 'Mejoramiento del terreno',
	},
	{
		code: '70131600',
		label: 'Preparación del terreno y del suelo',

		palabrasSimilares: 'Servicios de preparación del terreno y suelo',
	},
	{
		code: '70131601',
		label: 'Servicios de aplicación de fertilizantes',

		palabrasSimilares: 'Servicios de aplicación de abono',
	},
	{
		code: '70131602',
		label: 'Servicios de esparcido de cal',

		palabrasSimilares: 'Servicios de esparcido de caliza',
	},
	{
		code: '70131603',
		label: 'Servicios de arado',
	},
	{
		code: '70131604',
		label: 'Servicios de preparación de semilleros',
	},
	{
		code: '70131605',
		label: 'Servicios de tratamiento químico del suelo',

		palabrasSimilares: 'Servicios de tratamientos químicos para terrenos',
	},
	{
		code: '70131700',
		label: 'Gestión del terreno y del suelo',

		palabrasSimilares: 'Servicios de gestión para terrenos y suelos',
	},
	{
		code: '70131701',
		label: 'Planificación de tierras',

		palabrasSimilares: 'Planificación del uso del terreno',
	},
	{
		code: '70131702',
		label: 'Evaluación de tierras',
	},
	{
		code: '70131703',
		label: 'Recuperación de tierras',
	},
	{
		code: '70131704',
		label: 'Planificación o valoración de zonas agroecológicas',

		palabrasSimilares: 'Evaluación o planificación de zonas agroecológicas',
	},
	{
		code: '70131705',
		label: 'Clasificación del suelo',

		palabrasSimilares: 'Clasificación de terrenos',
	},
	{
		code: '70131706',
		label: 'Ordenación de las cuencas hidrográficas',

		palabrasSimilares: 'Manejo de cuencas',
	},
	{
		code: '70131707',
		label: 'Análisis de fertilidad del suelo',

		palabrasSimilares: 'Análisis de fertilidad del terreno',
	},
	{
		code: '70131708',
		label: 'Pedología',

		palabrasSimilares:
			'Estudio y clasificación del suelo en su ambiente natural',
	},
	{
		code: '70141500',
		label: 'Producción de cultivos',

		palabrasSimilares: 'Servicios de producción de cultivos',
	},
	{
		code: '70141501',
		label: 'Servicios de multiplicación de semillas',

		palabrasSimilares: 'Servicios de producción de semillas',
	},
	{
		code: '70141502',
		label: 'Hidroponía',

		palabrasSimilares: 'Cultivo sin suelo',
	},
	{
		code: '70141503',
		label: 'Producción de hierba o forraje',

		palabrasSimilares: 'Producción de pasto o forraje',
	},
	{
		code: '70141504',
		label: 'Producción de plantas aromáticas',
	},
	{
		code: '70141505',
		label: 'Producción de productos agrícolas para elaboración de bebidas',
	},
	{
		code: '70141506',
		label: 'Producción de cacao',
	},
	{
		code: '70141507',
		label: 'Producción de remolacha azucarera o caña de azúcar',

		palabrasSimilares: 'Producción de betabel de azúcar o caña de azúcar',
	},
	{
		code: '70141508',
		label: 'Producción de nueces',
	},
	{
		code: '70141509',
		label: 'Producción de cultivos productores de aceites esenciales',

		palabrasSimilares:
			'Producción de cultivos para la obtención de aceites esenciales',
	},
	{
		code: '70141510',
		label: 'Producción de plantas textiles',

		palabrasSimilares: 'Producción de cultivos de fibra',
	},
	{
		code: '70141511',
		label: 'Producción de fruta',
	},
	{
		code: '70141512',
		label: 'Producción de granos o legumbres',
	},
	{
		code: '70141513',
		label: 'Producción de plantas insecticidas',
	},
	{
		code: '70141514',
		label: 'Producción de plantas medicinales',
	},
	{
		code: '70141515',
		label: 'Producción de raíces o tubérculos',
	},
	{
		code: '70141516',
		label: 'Producción de cereales',
	},
	{
		code: '70141517',
		label: 'Producción de plantas de caucho',
	},
	{
		code: '70141518',
		label: 'Producción de cultivos de especias',
	},
	{
		code: '70141519',
		label: 'Producción de cultivos de tabaco',
	},
	{
		code: '70141520',
		label: 'Producción de hortalizas',

		palabrasSimilares: 'Producción de vegetales',
	},
	{
		code: '70141600',
		label: 'Protección de cultivos',

		palabrasSimilares: 'Servicios de protección de cultivos',
	},
	{
		code: '70141601',
		label: 'Servicios de fumigación de cultivos',
	},
	{
		code: '70141602',
		label: 'Servicios de control biológico',
	},
	{
		code: '70141603',
		label: 'Servicios de control de las malas hierbas',

		palabrasSimilares: 'Servicios de control de malezas',
	},
	{
		code: '70141604',
		label: 'Servicios de herbicidas',

		palabrasSimilares:
			'Servicio de productos químicos para eliminar plantas indeseadas',
	},
	{
		code: '70141605',
		label: 'Servicios de manejo integrado de plagas',

		palabrasSimilares: 'Servicios integrados para el control de plagas',
	},
	{
		code: '70141606',
		label: 'Fitopatología',

		palabrasSimilares: 'Servicios de control de enfermedades de las plantas',
	},
	{
		code: '70141607',
		label: 'Control de langostas',
	},
	{
		code: '70141700',
		label: 'Gestión de cultivos',

		palabrasSimilares: 'Servicios de gestión de cultivos',
	},
	{
		code: '70141701',
		label: 'Servicios de invernaderos',

		palabrasSimilares: 'Servicio de criadero de plantas',
	},
	{
		code: '70141702',
		label: 'Servicios de fertilizantes',

		palabrasSimilares: 'Servicios de abono de cultivos',
	},
	{
		code: '70141703',
		label: 'Administración de cultivos',
	},
	{
		code: '70141704',
		label: 'Sustitución de cultivos',
	},
	{
		code: '70141705',
		label: 'Servicios de extensión',
	},
	{
		code: '70141706',
		label: 'Especialización de cultivos',
	},
	{
		code: '70141707',
		label: 'Gestión de sistemas de explotación agrícola de cultivo',

		palabrasSimilares: 'Manejo del sistema de cultivo',
	},
	{
		code: '70141708',
		label: 'Servicios de asesoría en la rotación o diversificación de cultivos',

		palabrasSimilares:
			'Servicios de consultoría de rotación o diversificación de cultivos',
	},
	{
		code: '70141709',
		label: 'Servicios de taxonomía de plantas',

		palabrasSimilares: 'Servicios de clasificación de plantas',
	},
	{
		code: '70141710',
		label: 'Entomología de cultivos extensivos',

		palabrasSimilares:
			'Servicios de estudios científicos de insectos en los cultivos',
	},
	{
		code: '70141800',
		label: 'Plantación y cultivo de cultivos',

		palabrasSimilares: 'Servicios de plantación y cultivos',
	},
	{
		code: '70141801',
		label: 'Servicios de siembra de árboles de huerta o viñedos',

		palabrasSimilares: 'Servicios de plantación de árboles o viñedos',
	},
	{
		code: '70141802',
		label: 'Servicios de brotes o ramitas',
	},
	{
		code: '70141803',
		label: 'Servicios de cultivos agrícolas',
	},
	{
		code: '70141804',
		label: 'Servicios de siembra de cultivos',
	},
	{
		code: '70141900',
		label: 'Cosecha de cultivos',

		palabrasSimilares: 'Servicios de cosecha de cultivos',
	},
	{
		code: '70141901',
		label: 'Servicios de cosecha de granos comerciales',
	},
	{
		code: '70141902',
		label: 'Servicios de cosecha de frutales o nueces',

		palabrasSimilares: 'Servicio de cosecha de granos',
	},
	{
		code: '70141903',
		label: 'Servicios de cosecha de cultivos extensivos',

		palabrasSimilares: 'Servicios de recolección de campos de cultivo',
	},
	{
		code: '70141904',
		label: 'Servicios de cosecha de semillas',
	},
	{
		code: '70142000',
		label: 'Elaboración de cultivos posterior a la cosecha',

		palabrasSimilares:
			'Servicios de tratamientos posteriores a la cosecha de cultivos',
	},
	{
		code: '70142001',
		label: 'Servicios relativos a silos',

		palabrasSimilares: 'Servicios relativos a los almacenes de granos',
	},
	{
		code: '70142002',
		label: 'Servicios de secado de granos',
	},
	{
		code: '70142003',
		label: 'Servicios de procesamiento de la cosecha',

		palabrasSimilares: 'Servicios de procesamiento de los cultivos',
	},
	{
		code: '70142004',
		label: 'Servicios de preparación del mercado para cultivos extensivos',
	},
	{
		code: '70142005',
		label:
			'Servicios de preparación del mercado para cultivos comerciales de granos',
	},
	{
		code: '70142006',
		label: 'Servicios de preparación del mercado para productos hortenses',

		palabrasSimilares:
			'Servicios de preparación de mercado de cultivos vegetales',
	},
	{
		code: '70142007',
		label: 'Servicios de preparación del mercado para productos frutales',
	},
	{
		code: '70142008',
		label: 'Servicios de preparación del mercado para nueces',
	},
	{
		code: '70142009',
		label: 'Servicios de desmotado',

		palabrasSimilares:
			'Servicios de separación de fibras de algodón de las semillas',
	},
	{
		code: '70142010',
		label: 'Servicios de limpieza de cultivos',
	},
	{
		code: '70142011',
		label: 'Servicios de enfriamiento o refrigeración',
	},
	{
		code: '70151500',
		label: 'Gestión de cultivos forestales',

		palabrasSimilares: 'Servicios de gestión de cultivos forestales',
	},
	{
		code: '70151501',
		label: 'Servicios de gestión de recursos forestales',

		palabrasSimilares: 'Servicios de gestión de recursos de los bosques',
	},
	{
		code: '70151502',
		label: 'Control de plagas forestales',

		palabrasSimilares: 'Control de plagas en los bosques',
	},
	{
		code: '70151503',
		label: 'Organizaciones, asociaciones o cooperativas forestales',
	},
	{
		code: '70151504',
		label: 'Servicios de administración forestal',
	},
	{
		code: '70151505',
		label: 'Inventario forestal',

		palabrasSimilares: 'Inventario de los bosques',
	},
	{
		code: '70151506',
		label: 'Seguimiento o evaluación forestal',

		palabrasSimilares: 'Seguimiento o evaluación de los bosques',
	},
	{
		code: '70151507',
		label: 'Servicios de forestación',

		palabrasSimilares: 'Servicios de sembrado de árboles',
	},
	{
		code: '70151508',
		label: 'Servicios de asistencia forestal',
	},
	{
		code: '70151509',
		label: 'Gestión de viveros forestales',

		palabrasSimilares: 'Gestión de criadero de árboles',
	},
	{
		code: '70151510',
		label: 'Planificación sectorial forestal',
	},
	{
		code: '70151600',
		label: 'Industria forestal',

		palabrasSimilares: 'Servicios para la industria forestal',
	},
	{
		code: '70151601',
		label: 'Servicios de producción no maderera',
	},
	{
		code: '70151602',
		label: 'Producción de aceites esenciales',
	},
	{
		code: '70151603',
		label: 'Servicios de producción maderera',
	},
	{
		code: '70151604',
		label: 'Producción de colorantes',
	},
	{
		code: '70151605',
		label: 'Producción forestal comestible',

		palabrasSimilares: 'Producción de bosque comestible',
	},
	{
		code: '70151606',
		label: 'Servicios de prueba de madera',
	},
	{
		code: '70151700',
		label: 'Cosecha forestal',

		palabrasSimilares: 'Servicios para la cosecha forestal',
	},
	{
		code: '70151701',
		label: 'Corte o tala de troncos',

		palabrasSimilares: 'Corte o tala de árboles',
	},
	{
		code: '70151702',
		label: 'Cosecha forestal de zonas montañosas',
	},
	{
		code: '70151703',
		label: 'Operaciones especializadas para la cosecha forestal',
	},
	{
		code: '70151704',
		label: 'Cosecha de plantaciones',
	},
	{
		code: '70151705',
		label: 'Cosecha forestal de pantanos o manglares',
	},
	{
		code: '70151706',
		label: 'Cosecha de bosques de zonas templadas',
	},
	{
		code: '70151707',
		label: 'Cosecha de bosques altos tropicales',
	},
	{
		code: '70151800',
		label: 'Servicios de conservación forestal',
	},
	{
		code: '70151801',
		label: 'Conservación de recursos genéticos forestales',
	},
	{
		code: '70151802',
		label: 'Servicios de protección forestal',

		palabrasSimilares: 'Servicios de protección de bosques',
	},
	{
		code: '70151803',
		label: 'Rehabilitación de tierras forestales áridas',
	},
	{
		code: '70151804',
		label: 'Rompevientos o barreras naturales de protección forestal',
	},
	{
		code: '70151805',
		label: 'Servicios de conservación de parques o reservas forestales',
	},
	{
		code: '70151806',
		label: 'Ordenación de las cuencas hidrográficas forestales',
	},
	{
		code: '70151807',
		label: 'Control de las torrentes forestales',
	},
	{
		code: '70151900',
		label: 'Recursos forestales',

		palabrasSimilares: 'Servicios para recursos forestales',
	},
	{
		code: '70151901',
		label: 'Desarrollo de los recursos forestales',
	},
	{
		code: '70151902',
		label: 'Recursos agrosilvícolas',

		palabrasSimilares: 'Recursos agroforestales',
	},
	{
		code: '70151903',
		label: 'Recursos de madera combustible',
	},
	{
		code: '70151904',
		label: 'Silvicultura',

		palabrasSimilares: 'Cultivo de los bosques, cerros o montes',
	},
	{
		code: '70151905',
		label: 'Recursos de plantaciones áridas con agua de lluvia',
	},
	{
		code: '70151906',
		label: 'Recursos de plantaciones de hoja ancha',
	},
	{
		code: '70151907',
		label: 'Plantaciones de coníferas',

		palabrasSimilares: 'Plantaciones de pínidas',
	},
	{
		code: '70151909',
		label: 'Plantaciones de bosques tropicales húmedos',
	},
	{
		code: '70151910',
		label: 'Recursos de manglares',
	},
	{
		code: '70161500',
		label: 'Fauna',

		palabrasSimilares: 'Servicios de protección para la fauna',
	},
	{
		code: '70161501',
		label: 'Protección de la fauna',
	},
	{
		code: '70161600',
		label: 'Flora',

		palabrasSimilares: 'Servicios de protección para la flora',
	},
	{
		code: '70161601',
		label: 'Protección de la flora',
	},
	{
		code: '70161700',
		label: 'Ecosistemas',

		palabrasSimilares: 'Servicios para ecosistemas',
	},
	{
		code: '70161701',
		label: 'Servicios de ecodesarrollo',
	},
	{
		code: '70161702',
		label: 'Servicios de administración del ecosistema marino',

		palabrasSimilares: 'Servicios de administración del hábitat marino',
	},
	{
		code: '70161703',
		label: 'Servicios de administración del ecosistema terrestre',

		palabrasSimilares: 'Servicios de administración del hábitat terrestre',
	},
	{
		code: '70161704',
		label: 'Servicios de protección de los ecosistemas',

		palabrasSimilares: 'Servicios de protección de los hábitat',
	},
	{
		code: '70171500',
		label: 'Desarrollo',

		palabrasSimilares: 'Servicios de desarrollo',
	},
	{
		code: '70171501',
		label: 'Servicios de evaluación de la calidad del agua',
	},
	{
		code: '70171502',
		label: 'Servicios de planificación de los recursos hidráulicos',

		palabrasSimilares: 'Servicios de planificación de los recursos hídricos',
	},
	{
		code: '70171503',
		label: 'Inspección de aguas subterráneas o superficiales',
	},
	{
		code: '70171504',
		label: 'Servicios de cartografía de los recursos hidráulicos',
	},
	{
		code: '70171505',
		label: 'Aprovechamiento de los ríos',

		palabrasSimilares: 'Desarrollo de cuencas hidrográficas',
	},
	{
		code: '70171506',
		label: 'Servicios para modelado de aguas subterráneas o superficiales',
	},
	{
		code: '70171600',
		label: 'Vigilancia',

		palabrasSimilares: 'Servicios de vigilancia',
	},
	{
		code: '70171601',
		label: 'Gestión de la calidad del agua',
	},
	{
		code: '70171602',
		label: 'Servicios de pruebas de agua',
	},
	{
		code: '70171603',
		label: 'Manejo de suelos inundables',

		palabrasSimilares: 'Gestión de las inundaciones',
	},
	{
		code: '70171604',
		label: 'Servicios de asesoría en conservación del agua',

		palabrasSimilares: 'Servicios de consultoría para la conservación del agua',
	},
	{
		code: '70171605',
		label: 'Servicios de asesoría en derechos de agua',

		palabrasSimilares: 'Servicios de consultoría en derechos de agua',
	},
	{
		code: '70171606',
		label: 'Servicios de recuperación de recursos hidráulicos',

		palabrasSimilares: 'Servicios de recuperación de recursos hídricos',
	},
	{
		code: '70171607',
		label: 'Servicios de tasación del agua',

		palabrasSimilares: 'Servicios de fijación de precios del agua',
	},
	{
		code: '70171700',
		label: 'Riego',

		palabrasSimilares: 'Servicios para riegos',
	},
	{
		code: '70171701',
		label: 'Servicios de  mantenimiento o administración  de canales',
	},
	{
		code: '70171702',
		label: 'Servicios de mantenimiento o administración de represas',
	},
	{
		code: '70171703',
		label: 'Servicios de  mantenimiento o administración  de embalses',
	},
	{
		code: '70171704',
		label:
			'Servicios de  mantenimiento o administración  de estaciones de bombeo',
	},
	{
		code: '70171705',
		label: 'Servicios de  mantenimiento o administración  de canalizaciones',

		palabrasSimilares: 'Servicios de mantenimiento o gestión de gasoductos',
	},
	{
		code: '70171706',
		label:
			'Servicios de  mantenimiento o administración  de malecones o diques',
	},
	{
		code: '70171707',
		label: 'Servicios de  mantenimiento o administración  de pozos de agua',
	},
	{
		code: '70171708',
		label: 'Servicios de asesoría en riego',

		palabrasSimilares: 'Servicios de consultoría en sistemas de riego',
	},
	{
		code: '70171709',
		label: 'Servicios de administración de sistemas de riego',
	},
	{
		code: '70171800',
		label: 'Servicios de drenaje',
	},
	{
		code: '70171801',
		label: 'Servicios de drenaje de tierras',
	},
	{
		code: '70171802',
		label: 'Drenaje de agua pluviales',

		palabrasSimilares: 'Drenaje para agua de lluvia',
	},
	{
		code: '70171803',
		label:
			'Servicios de protección contra inundaciones o de control de inundaciones',

		palabrasSimilares: 'Servicios de protección o control de inundaciones',
	},
	{
		code: '71101500',
		label: 'Prospección minera',

		palabrasSimilares: 'Servicios de descubrimientos de yacimientos mineros',
	},
	{
		code: '71101501',
		label: 'Desarrollos mineros',
	},
	{
		code: '71101502',
		label: 'Sondeos de exploración o extracción de testigos',

		palabrasSimilares: 'Taladrado de prueba o perforación de núcleo',
	},
	{
		code: '71101600',
		label: 'Servicios mineros de perforación y voladura',
	},
	{
		code: '71101601',
		label: 'Profundización de pozos de mina',
	},
	{
		code: '71101602',
		label: 'Servicios de voladura de minas',

		palabrasSimilares: 'Servicios de fragmentación de minas',
	},
	{
		code: '71101603',
		label: 'Servicio de perforación de pozos de sondeo de minas',
	},
	{
		code: '71101604',
		label: 'Servicio de construcción de pozos de pendiente',
	},
	{
		code: '71101605',
		label: 'Servicio de perforación horizontal de minas',
	},
	{
		code: '71101606',
		label: 'Servicio de instalación de sellamiento subterráneo',
	},
	{
		code: '71101607',
		label: 'Servicio de construcción de techado subterráneo',

		palabrasSimilares: 'Servicio de construcción de techos subterráneos',
	},
	{
		code: '71101608',
		label: 'Servicio de construcción de búnkeres',
	},
	{
		code: '71101609',
		label: 'Servicio de construcción de ventilación subterránea',
	},
	{
		code: '71101700',
		label: 'Extracción',

		palabrasSimilares: 'Servicios de extracción',
	},
	{
		code: '71101701',
		label: 'Servicios de minería de pozos',
	},
	{
		code: '71101702',
		label: 'Servicios de minería  a cielo abierto',
	},
	{
		code: '71101703',
		label: 'Servicios de explotación a cielo abierto',
	},
	{
		code: '71101704',
		label: 'Servicios de lixiviación sobre el terreno',

		palabrasSimilares:
			'Servicios de extracción de sólido-líquido sobre el terreno',
	},
	{
		code: '71101705',
		label: 'Bombeo o drenaje',

		palabrasSimilares: 'Servicios de bombeo o drenaje',
	},
	{
		code: '71101706',
		label: 'Eliminación de cobertura de rocas',

		palabrasSimilares: 'Servicios de eliminación de la sobrecarga',
	},
	{
		code: '71101707',
		label: 'Servicios de embalsamiento o almacenamiento de aguas',
	},
	{
		code: '71101708',
		label: 'Servicios de baldeo de la tierra',

		palabrasSimilares: 'Servicios de limpieza del suelo',
	},
	{
		code: '71101709',
		label: 'Servicios de relleno de minas',
	},
	{
		code: '71101710',
		label: 'Servicio de alquiler o leasing de maquinaria para minería',

		palabrasSimilares:
			'Servicio de alquiler o arrendamiento de maquinaria para minas',
	},
	{
		code: '71112000',
		label: 'Servicios de registrar hoyo encerrado de pozos',

		palabrasSimilares: 'Servicios de registro de pozos',
	},
	{
		code: '71112001',
		label: 'Servicios de pruebas de muestras de formación de pozos entubados',
	},
	{
		code: '71112002',
		label: 'Servicios de medición del espesor del revestimiento',
	},
	{
		code: '71112003',
		label: 'Servicios de cortadoras químicas',
	},
	{
		code: '71112004',
		label: 'Servicios de recuperación de tubería electromagnética',
	},
	{
		code: '71112005',
		label: 'Servicios de medición del flujo del pozo',
	},
	{
		code: '71112006',
		label: 'Servicios de medición  de la densidad del fluido del pozo',
	},
	{
		code: '71112007',
		label: 'Servicios de medición de la temperatura del fluido del pozo',
	},
	{
		code: '71112008',
		label: 'Servicios de herramientas de punto libre',

		palabrasSimilares: 'Servicios de medidores de deformación de punto libre',
	},
	{
		code: '71112009',
		label: 'Servicios de rayos gamma',
	},
	{
		code: '71112010',
		label: 'Servicios de cortadora a  chorro',

		palabrasSimilares: 'Servicios de cortadora con agua a presión',
	},
	{
		code: '71112011',
		label: 'Servicios de medición durante la perforación',
	},
	{
		code: '71112012',
		label: 'Servicios de porosidad por neutrón',
	},
	{
		code: '71112013',
		label: 'Servicios de espectroscopia nuclear',
	},
	{
		code: '71112014',
		label: 'Servicios de obturación de pozos',

		palabrasSimilares: 'Servicios de obstrucción de pozos',
	},
	{
		code: '71112015',
		label: 'Servicios de recuperación permanente de tuberías magnéticas',

		palabrasSimilares:
			'Servicios de recuperación de tubos de imanes permanentes',
	},
	{
		code: '71112017',
		label: 'Servicios de control de medición de la presión del pozo',
	},
	{
		code: '71112018',
		label:
			'Servicios de medición del fluido de perforación para registros de producción',
	},
	{
		code: '71112019',
		label: 'Servicios de medición de la densidad para registros de producción',
	},
	{
		code: '71112020',
		label: 'Servicios de video en fondo de pozo para registros de producción',
	},
	{
		code: '71112021',
		label: 'Servicios de medición de flujo para registros de producción',
	},
	{
		code: '71112022',
		label: 'Servicios de medición de presión para registros de producción',
	},
	{
		code: '71112023',
		label: 'Otros servicios para registros de producción',
	},
	{
		code: '71112024',
		label: 'Servicios de medición de temperatura para registros de producción',
	},
	{
		code: '71112025',
		label: 'Servicios de colisión y separación',
	},
	{
		code: '71112026',
		label: 'Servicios acústicos para registros  del pozo',
	},
	{
		code: '71112027',
		label: 'Servicios de herramienta para puntos de atascamiento',
	},
	{
		code: '71112028',
		label: 'Servicios de registro de pozos  de descomposición térmica',

		palabrasSimilares: 'Servicios de registro de pozos  de deterioro térmico',
	},
	{
		code: '71112029',
		label: 'Servicios de evaluación de la corrosión de tubos del pozo',
	},
	{
		code: '71112030',
		label: 'Servicios de rendimiento del pozo',
	},
	{
		code: '71112031',
		label: 'Servicios de tubos para pozos',
	},
	{
		code: '71112100',
		label: 'Servicios de registrar pozo de perforación abierta',

		palabrasSimilares: 'Servicios de registro abierto de pozos',
	},
	{
		code: '71112101',
		label: 'Servicios de registro acústicos digital',
	},
	{
		code: '71112102',
		label: 'Servicios de registro de la geometría de la perforación',
	},
	{
		code: '71112103',
		label: 'Servicios de registro nuclear de litología y densidad',
	},
	{
		code: '71112105',
		label: 'Servicios de registro acústico dipolar',
	},
	{
		code: '71112106',
		label: 'Servicios de registro de desviaciones',

		palabrasSimilares: 'Servicios de registro direccional',
	},
	{
		code: '71112107',
		label: 'Servicios de planimetría direccional',

		palabrasSimilares: 'Servicios de sondeo direccional',
	},
	{
		code: '71112108',
		label: 'Servicios de registro de propagación electromagnética',
	},
	{
		code: '71112109',
		label: 'Servicios de muestreo de pruebas de formación',
	},
	{
		code: '71112110',
		label: 'Servicios de registro de identificación de fracturas',
	},
	{
		code: '71112111',
		label: 'Servicios de registro por rayos gamma',
	},
	{
		code: '71112112',
		label: 'Servicios de registro espectroscópico por rayos gamma',
	},
	{
		code: '71112113',
		label: 'Servicios de registro nuclear geoquímico',
	},
	{
		code: '71112114',
		label: 'Servicios de generación de imágenes del pozo',

		palabrasSimilares: 'Servicios de generación de figuras del pozo',
	},
	{
		code: '71112115',
		label: 'Servicios de registro por resonancia magnética',
	},
	{
		code: '71112116',
		label: 'Servicios de registro de micro resistencia',
	},
	{
		code: '71112117',
		label: 'Servicios de registro de porosidad  por neutrón',
	},
	{
		code: '71112119',
		label: 'Servicios de registro de resistencia',
	},
	{
		code: '71112120',
		label: 'Servicios de registro acústico de acceso angosto',

		palabrasSimilares: 'Servicios de registro acústico de acceso estrecho',
	},
	{
		code: '71112121',
		label:
			'Servicios de registro acústico de la relación de adherencia del cemento',

		palabrasSimilares: 'Servicios de registro de adherencia del cemento',
	},
	{
		code: '71112122',
		label: 'Servicios de generación de imágenes acústicas del pozo',
	},
	{
		code: '71112123',
		label:
			'Servicios de registro de formación de ángulo y dirección de inclinación',
	},
	{
		code: '71112200',
		label: 'Otros servicios de registración',

		palabrasSimilares: 'Otros servicios para el registro de pozos',
	},
	{
		code: '71112202',
		label: 'Registro durante servicios de perforación',

		palabrasSimilares:
			'Servicios de registro de pozos durante las pruebas de perforación',
	},
	{
		code: '71112203',
		label: 'Servicios de registro durante la pesca',

		palabrasSimilares: 'Servicios de registro de pozos durante la pesca',
	},
	{
		code: '71112204',
		label: 'Servicios de registro  durante la perforación',

		palabrasSimilares: 'Servicios de registro de pozos durante la perforación',
	},
	{
		code: '71112205',
		label: 'Servicios de tubería de perforación transportadas para pozos',

		palabrasSimilares:
			'Servicios de registro de pozos conectados a tuberías de transportación',
	},
	{
		code: '71112206',
		label: 'Servicios generales de registro de pozos',
	},
	{
		code: '71112300',
		label: 'Servicios sísmicos',
	},
	{
		code: '71112301',
		label:
			'Servicios de adquisición de datos sísmicos de tierras de 2d/ 3d/ 4d',
	},
	{
		code: '71112302',
		label: 'Servicios de adquisición de datos sísmicos  marinos de 2d/ 3d/ 4d',
	},
	{
		code: '71112303',
		label: 'Servicios de procesamiento de datos sísmicos de 2d/ 3d/ 4d',
	},
	{
		code: '71112322',
		label: 'Interpretación de datos sísmicos de 2d/ 3d/ 4d',
	},
	{
		code: '71112323',
		label: 'Servicios de estudio de la velocidad del pozo',

		palabrasSimilares: 'Servicios de investigación de velocidad de perforación',
	},
	{
		code: '71112324',
		label: 'Otros servicios sísmicos',
	},
	{
		code: '71112325',
		label:
			'Servicios de adquisición de datos sísmicos  por sondeo para tierras',
	},
	{
		code: '71112326',
		label: 'Servicios de adquisición de datos sísmicos marinos  por sondeo',
	},
	{
		code: '71112327',
		label: 'Servicios de procesamiento de datos sísmicos por sondeo',
	},
	{
		code: '71112328',
		label: 'Servicios de adquisición de monitoreo de datos sísmicos por sondeo',
	},
	{
		code: '71112329',
		label:
			'Servicios de adquisición de datos sísmicos por sondeo durante la perforación',
	},
	{
		code: '71112330',
		label:
			'Servicios simultáneos de adquisición de datos sísmicos por sondeo y de superficie',
	},
	{
		code: '71121000',
		label: 'Servicios de bombear cemento',

		palabrasSimilares: 'Servicios de bombeo de cemento',
	},
	{
		code: '71121001',
		label:
			'Servicios de hardware de tuberías de revestimiento para campos petroleros',

		palabrasSimilares:
			'Servicios de construcción de carcasas para campos petrolíferos',
	},
	{
		code: '71121002',
		label:
			'Servicios de pruebas de laboratorios de cemento para campos petroleros',
	},
	{
		code: '71121008',
		label: 'Servicios de circulación perdida del pozo petrolero',
	},
	{
		code: '71121009',
		label: 'Servicios de eliminación de lodo  del  campo petrolero',
	},
	{
		code: '71121010',
		label: 'Servicios de cementación de tapones',
	},
	{
		code: '71121011',
		label: 'Servicios de bombeo a presión del pozo en el emplazamiento',

		palabrasSimilares: 'Servicios de bombeo a presión del pozo en sitio',
	},
	{
		code: '71121012',
		label: 'Servicios de prueba de presión del campo petrolero',
	},
	{
		code: '71121016',
		label: 'Servicios de cementación del pozo con forzamiento de inyección',

		palabrasSimilares:
			'Servicios de cementación a presión, Servicios de cementación forzada',
	},
	{
		code: '71121017',
		label: 'Servicios de control de agua del pozo',
	},
	{
		code: '71121018',
		label: 'Servicios de evaluación de la cementación del pozo',
	},
	{
		code: '71121024',
		label: 'Servicio de cementación de tubería',
	},
	{
		code: '71121025',
		label: 'Otros servicios de cementación',
	},
	{
		code: '71121100',
		label: 'Servicios de tubería adujada',
	},
	{
		code: '71121101',
		label: 'Servicios de acidización mediante tubería flexible contínua',

		palabrasSimilares:
			'Servicios de acidización mediante carrete de tubería flexible',
	},
	{
		code: '71121102',
		label:
			'Servicios de instalación de cables  mediante tubería  flexible contínua',
	},
	{
		code: '71121103',
		label: 'Servicios de cementación mediante tubería  flexible contínua',
	},
	{
		code: '71121104',
		label: 'Servicios de corte químico mediante tubería flexible contínua',
	},
	{
		code: '71121105',
		label: 'Servicios de limpieza mediante tubería flexible contínua',
	},
	{
		code: '71121106',
		label: 'Servicios de terminación mediante tubería flexible contínua',
	},
	{
		code: '71121107',
		label:
			'Servicios de transporte de inflables  mediante tubería flexible contínua',
	},
	{
		code: '71121108',
		label:
			'Servicios de extracción de testigos mediante tubería flexible contínua',
	},
	{
		code: '71121109',
		label: 'Servicios de alcance extendido del pozo',
	},
	{
		code: '71121110',
		label: 'Servicios de pesca mediante tubería flexible contínua',
	},
	{
		code: '71121111',
		label: 'Servicios de fracturación mediante tubería flexible contínua',
	},
	{
		code: '71121112',
		label: 'Servicios de presión alta mediante tubería flexible contínua',
	},
	{
		code: '71121113',
		label: 'Servicios de aislamiento horizontal del campo petrolífero',
	},
	{
		code: '71121114',
		label: 'Servicios de registro mediante tubería flexible contínua',
	},
	{
		code: '71121115',
		label: 'Servicios de fresado mediante tubería flexible contínua',
	},
	{
		code: '71121116',
		label:
			'Aplicaciones relacionadas con nitrógeno mediante tubería flexible continua',
	},
	{
		code: '71121117',
		label: 'Servicios de perforación mediante tubería flexible contínua',
	},
	{
		code: '71121118',
		label: 'Servicios de tendido de conductos o tuberías de producción',
	},
	{
		code: '71121119',
		label: 'Servicios de intervención en los conductos',

		palabrasSimilares:
			'Servicios de intervención en la tubería para transportar petróleo, Servicios de intervención en oleoductos',
	},
	{
		code: '71121120',
		label: 'Servicios de control de arena mediante tubería flexible contínua',
	},
	{
		code: '71121121',
		label: 'Servicios abrasivos de limpieza tubular',

		palabrasSimilares: 'Servicios corrosivos de limpieza tubular',
	},
	{
		code: '71121122',
		label:
			'Servicios de matar el pozo (well kil)l mediante tubería flexible contínua',
	},
	{
		code: '71121123',
		label: 'Servicios de salida de tubería mediante tubería flexible contínua',
	},
	{
		code: '71121200',
		label: 'Servicios de extracción de muestras',
	},
	{
		code: '71121201',
		label: 'Servicios convencionales de extracción de testigos',
	},
	{
		code: '71121202',
		label: 'Servicios de aislamiento de testigos',
	},
	{
		code: '71121203',
		label: 'Servicios de conservación de testigos',
	},
	{
		code: '71121204',
		label: 'Servicios de extracción horizontal de testigos',
	},
	{
		code: '71121205',
		label: 'Servicios de extracción de testigos orientada',
	},
	{
		code: '71121206',
		label: 'Servicios de análisis de testigos ("Core analysis service ")',
	},
	{
		code: '71121207',
		label: 'Servicios de extracción de testigos con esponja',

		palabrasSimilares: 'Servicios de extracción de núcleo petrolero',
	},
	{
		code: '71121208',
		label:
			'Servicios de extracción de testigos mediante cable conductor recuperable',

		palabrasSimilares:
			'Servicios de extracción mediante línea de acero recuperable',
	},
	{
		code: '71121209',
		label: 'Servicios de sistemas antiatascamiento',

		palabrasSimilares:
			'Servicios de espectro ensanchado, Servicios de sistemas anti interferencias intencionadas',
	},
	{
		code: '71121210',
		label: 'Servicios de extracción  motorizada de testigos',
	},
	{
		code: '71121211',
		label: 'Servicios de sistemas de cierre completo',
	},
	{
		code: '71121300',
		label: 'Servicios de herramientas barrenos hacia abajo',
	},
	{
		code: '71121301',
		label:
			'Servicios de control de la vibración de perforación en el fondo del pozo',
	},
	{
		code: '71121302',
		label: 'Servicios de ensanchamiento de la perforación en el fondo del pozo',
	},
	{
		code: '71121303',
		label:
			'Servicios de protección del ensanche y calibración de la perforación en el fondo del pozo',
	},
	{
		code: '71121304',
		label:
			'Servicios de reducción del arrastre o torque en la perforación en el fondo del pozo',
	},
	{
		code: '71121305',
		label:
			'Servicios de alivio de atascamiento de los tubos en la perforación en el fondo del pozo',
	},
	{
		code: '71121307',
		label:
			'Servicios de reparación de herramientas de perforación en el fondo del pozo',
	},
	{
		code: '71121309',
		label: 'Servicios de estabilización de la perforación en el fondo del pozo',
	},
	{
		code: '71121310',
		label: 'Alquiler de armadura de urgencia bop',

		palabrasSimilares: 'Alquiler de preventores de superficie',
	},
	{
		code: '71121400',
		label: 'Servicios de broca de barrena del campo petrolífero',
	},
	{
		code: '71121401',
		label: 'Servicios de diseño de brocas de perforación del campo petrolero',
	},
	{
		code: '71121402',
		label:
			'Servicios de optimización hidráulica de las brocas de perforación del campo petrolero',
	},
	{
		code: '71121403',
		label:
			'Servicios de planificación de las brocas de perforación del campo petrolero',
	},
	{
		code: '71121404',
		label:
			'Servicios de registro de las brocas de perforación del campo petrolero',
	},
	{
		code: '71121405',
		label:
			'Servicios de reparación de brocas de perforación del campo petrolero',
	},
	{
		code: '71121406',
		label:
			'Contratos de longitud excavada de las brocas de perforación del campo petrolero',
	},
	{
		code: '71121407',
		label:
			'Servicio de asistencia para la optimización de la perforación en el emplazamiento del pozo',
	},
	{
		code: '71121408',
		label: 'Servicios de elevación hidráulica de las brocas de perforación',
	},
	{
		code: '71121500',
		label: 'Servicios de medida de perforación',
	},
	{
		code: '71121501',
		label: 'Servicios de medición acústica durante la perforación',
	},
	{
		code: '71121502',
		label: 'Servicios de telemetría acústica durante la perforación',
	},
	{
		code: '71121503',
		label: 'Servicios de medición de calibre durante la perforación',
	},
	{
		code: '71121504',
		label: 'Servicios de medición de la densidad durante la perforación',
	},
	{
		code: '71121505',
		label: 'Servicios del rendimiento de la perforación del campo  petrolero',
	},
	{
		code: '71121506',
		label: 'Servicios de telemetría electromagnética durante la perforación',
	},
	{
		code: '71121507',
		label:
			'Servicios de medición de la inclinación de formación durante la perforación',
	},
	{
		code: '71121508',
		label:
			'Servicios de medición de la presión de formación durante la perforación',
	},
	{
		code: '71121509',
		label: 'Pozos dirigidos geológicamente',

		palabrasSimilares: 'Pozos con dirección geológica',
	},
	{
		code: '71121510',
		label: 'Servicios de geoparada',
	},
	{
		code: '71121511',
		label: 'Servicios de registro de lodo',
	},
	{
		code: '71121512',
		label: 'Servicios de resonancia magnética nuclear',
	},
	{
		code: '71121513',
		label: 'Servicios de medición de la porosidad durante la perforación',
	},
	{
		code: '71121514',
		label: 'Servicios de medición de la resistividad durante la perforación',
	},
	{
		code: '71121515',
		label: 'Servicios de gestión de levantamiento de planos  del pozo',
	},
	{
		code: '71121516',
		label: 'Servicios de inspección de los cables conductores',
	},
	{
		code: '71121600',
		label: 'Servicios de perforación del pozo petrolero',
	},
	{
		code: '71121601',
		label: 'Servicios de personal para revestimiento de  tuberías del pozo',

		palabrasSimilares:
			'Equipos de trabajo para revestimiento de  tuberías del pozo',
	},
	{
		code: '71121602',
		label:
			'Servicios de planificación para el revestimiento de tuberías del pozo',
	},
	{
		code: '71121603',
		label: 'Servicios de fresado para el revestimiento de tuberías del pozo',
	},
	{
		code: '71121604',
		label: 'Servicios de perforación con tubería flexible continua',

		palabrasSimilares:
			'Servicios de perforación con carrete de tubería flexible',
	},
	{
		code: '71121605',
		label: 'Servicios de planificación de la terminación del pozo',
	},
	{
		code: '71121606',
		label: 'Servicios de perforación direccional de pozo convencional',
	},
	{
		code: '71121607',
		label: 'Servicios de perforación direccional de pozos',
	},
	{
		code: '71121608',
		label: 'Servicios de desarrollo de contratos  para perforación de pozos',
	},
	{
		code: '71121610',
		label: 'Servicios de ingeniería para perforación de pozos',
	},
	{
		code: '71121611',
		label: 'Servicios de fluido o lodo para perforación de pozos',
	},
	{
		code: '71121612',
		label: 'Servicios de perforación de pozos  para entrada de agua',

		palabrasSimilares: 'Servicios de perforación de pozos  de agua',
	},
	{
		code: '71121613',
		label: 'Servicios de monitoreo de la torre de perforación del pozo',
	},
	{
		code: '71121614',
		label: 'Control de sólidos durante perforación de pozos',
	},
	{
		code: '71121615',
		label:
			'Servicios de apertura de hoyos o estabilización de la perforación de pozos',

		palabrasSimilares:
			'Servicios de apertura o estabilización de la perforación de pozos',
	},
	{
		code: '71121616',
		label: 'Supervisión de perforación de pozos',
	},
	{
		code: '71121617',
		label: 'Servicios de control de perforación de pozos',
	},
	{
		code: '71121618',
		label: 'Otros servicios generales para perforación de pozos',
	},
	{
		code: '71121619',
		label: 'Servicios de perforación direccional de alcance extendido de pozos',

		palabrasSimilares: 'Servicios de perforación horizontal de pozos',
	},
	{
		code: '71121620',
		label: 'Servicios de perforación direccional multilateral de pozos',
	},
	{
		code: '71121621',
		label:
			'Servicios de pruebas  o modificaciones de tubos para pozos de petróleo',
	},
	{
		code: '71121622',
		label:
			'Servicio de almacenamiento transitorio o recogida de tubos para perforación de pozos',

		palabrasSimilares:
			'Perforación de pozos de recolección o puesta en servicio',
	},
	{
		code: '71121623',
		label: 'Almacenamiento de tubería de perforación de pozo',
	},
	{
		code: '71121624',
		label: 'Enroscado de tubería de perforación de pozos',

		palabrasSimilares: 'Roscado de tubería de perforación de pozos',
	},
	{
		code: '71121625',
		label: 'Servicio de perforación de los orificio de conexión o ratonera',

		palabrasSimilares: 'Servicio de perforación de pozo piloto o ratonera',
	},
	{
		code: '71121626',
		label: 'Servicios de perforación direccional de pozos de radio corto',
	},
	{
		code: '71121627',
		label:
			'Servicios de ampliación dirigible de paredes por debajo de la tubería de revestimiento durante la perforación del pozo',
	},
	{
		code: '71121628',
		label: 'Servicios de perforación direccional de pozos de radio ultracorto',
	},
	{
		code: '71121629',
		label: 'Servicios de perforación desbalanceada de pozos',
	},
	{
		code: '71121630',
		label: 'Servicios de planificación del pozos',

		palabrasSimilares: 'Servicios de planificación de pozos',
	},
	{
		code: '71121631',
		label: 'Servicios de reperforación o retrabajo',
	},
	{
		code: '71121632',
		label: 'Control de desviaciones  en la perforación del pozo',
	},
	{
		code: '71121633',
		label: 'Servicios de empacado de grava del campo petrolero',
	},
	{
		code: '71121634',
		label: 'Servicios de sistemas de cascos  del campo petrolero',
	},
	{
		code: '71121635',
		label: 'Servicios de personal para arrastre de aparejos del pozo',
	},
	{
		code: '71121636',
		label: 'Servicios de equipo de la cabeza del pozo',

		palabrasSimilares: 'Servicios de equipo de cabezales del pozo',
	},
	{
		code: '71121637',
		label:
			'Servicios de desviación con aparato para cambiar el trazo de perforación',

		palabrasSimilares: 'Servicio de desviadores de pared de pozo',
	},
	{
		code: '71121638',
		label: 'Servicios de limpieza de conductos de perforación de pozos',
	},
	{
		code: '71121639',
		label:
			'Servicio de alquiler de tubería de perforación en el fondo del pozo',
	},
	{
		code: '71121640',
		label: 'Servicio rodante de tubería',

		palabrasSimilares: 'Servicio de collarín de seguridad de perforación',
	},
	{
		code: '71121641',
		label: 'Servicio de perforación direccional dirigible y rotativa',
	},
	{
		code: '71121700',
		label: 'Servicios de pesca en el campo petrolífero',
	},
	{
		code: '71121701',
		label: 'Servicios de diseño del servicio de pesca en el campo petrolífero',
	},
	{
		code: '71121702',
		label:
			'Servicios de evaluación económica del servicio de pesca en el campo petrolífero',
	},
	{
		code: '71121703',
		label:
			'Servicios de operación del servicio de pesca en el campo petrolífero',
	},
	{
		code: '71121704',
		label: 'Servicios de recuperación de desechos en el campo petrolífero',
	},
	{
		code: '71121705',
		label: 'Servicios de recuperación de tubería en el campo petrolífero',
	},
	{
		code: '71121706',
		label: 'Servicios de perforación o pesca en el campo petrolífero',

		palabrasSimilares:
			'Servicios de perforación o pesca para el campo petrolero',
	},
	{
		code: '71121800',
		label: 'Servicios de elevación por presión de gas',
	},
	{
		code: '71121805',
		label: 'Servicios de elevadores del eje mayor del yacimiento',

		palabrasSimilares: 'Servicios de levantamiento Pistón Accionado a Gas',
	},
	{
		code: '71121806',
		label: 'Servicios de elevadores recíprocos de varilla',
	},
	{
		code: '71121807',
		label: 'Servicios de elevación hidráulica',
	},
	{
		code: '71121808',
		label: 'Servicios de elevadores de cavidad progresiva cvx',
	},
	{
		code: '71121809',
		label:
			'Servicios de automatización y optimización de pozos en el emplazamiento',
	},
	{
		code: '71121810',
		label: 'Servicios de elevación por bombeo sumergible electrónico',
	},
	{
		code: '71121811',
		label: 'Otros sistemas de elevación',
	},
	{
		code: '71121812',
		label: 'Servicios de elevadores de gas',
	},
	{
		code: '71121900',
		label: 'Servicios de fluido barreno hacia abajo',
	},
	{
		code: '71121901',
		label: 'Servicios de evaluación de fluidos en el fondo del pozo',
	},
	{
		code: '71121902',
		label: 'Servicios de laboratorio de fluidos de fondo del pozo',
	},
	{
		code: '71121903',
		label: 'Servicios de muestreo de fluidos de fondo de pozo',
	},
	{
		code: '71121904',
		label: 'Servicios de ingeniería para fluidos de fondo de pozo',
	},
	{
		code: '71121905',
		label: 'Servicio de manejo de fluidos residuales',
	},
	{
		code: '71122000',
		label: 'Servicios de perforación de pozo',
	},
	{
		code: '71122001',
		label: 'Servicios de perforación de pozos con tubería flexible continua',

		palabrasSimilares:
			'Servicios de perforación de pozos con carrete de tubería flexible',
	},
	{
		code: '71122002',
		label: 'Servicios de perforación de pozos con alambre (slickline)',
	},
	{
		code: '71122003',
		label: 'Servicios de perforación de pozos con  tubo directo',
	},
	{
		code: '71122004',
		label: 'Servicios de perforación de pozos con  tubo transportado',
	},
	{
		code: '71122005',
		label: 'Servicios de perforación de pozos con cables conductores',
	},
	{
		code: '71122006',
		label: 'Servicios de perforación de tuberías de pozos',
	},
	{
		code: '71122100',
		label: 'Servicios de control de arena del campo petrolero',
	},
	{
		code: '71122101',
		label: 'Servicios de bombeo de control de arena ácida',
	},
	{
		code: '71122102',
		label: 'Servicios de mezclado de control de arena',
	},
	{
		code: '71122103',
		label: 'Servicios de control de arena en el fluido de limpieza',
	},
	{
		code: '71122104',
		label: 'Servicios de control de arena en el fluido de terminación',
	},
	{
		code: '71122105',
		label: 'Servicios de consolidación de pozos',
	},
	{
		code: '71122107',
		label: 'Servicios de control de arena en el fluido de fracturación',
	},
	{
		code: '71122108',
		label: 'Servicios de pruebas de diseño de fracturación o pre fracturación',
	},
	{
		code: '71122109',
		label: 'Servicios generales/misceláneos  de control de arena',
	},
	{
		code: '71122110',
		label: 'Servicios de control de arena en el fluido transportador de grava',
	},
	{
		code: '71122111',
		label: 'Servicios de pruebas de laboratorio para el control de arena',
	},
	{
		code: '71122112',
		label: 'Servicios de control de arena multizonal',
	},
	{
		code: '71122113',
		label: 'Servicios de monitoreo del control de arena',
	},
	{
		code: '71122114',
		label: 'Servicios de aislamiento temporal del control de arena',
	},
	{
		code: '71122115',
		label: 'Servicios de reacondicionamiento de herramientas del pozo',
	},
	{
		code: '71122116',
		label: 'Servicios de bombeo para control de arena de no fracturación',
	},
	{
		code: '71122200',
		label: 'Servicios de película oleosa',
	},
	{
		code: '71122201',
		label: 'Servicios de pesca con alambre (slickline)',

		palabrasSimilares: 'Servicios de pesca con línea de acero',
	},
	{
		code: '71122202',
		label: 'Servicios de elevación con alambre (slickline)',

		palabrasSimilares: 'Servicios de elevación con línea de acero',
	},
	{
		code: '71122203',
		label: 'Servicios de manipulación con alambre (slickline)',

		palabrasSimilares: 'Servicios de manipulación con línea de acero',
	},
	{
		code: '71122204',
		label: 'Servicios de terminación con alambre (slickline)',

		palabrasSimilares: 'Servicios de terminación con línea de acero',
	},
	{
		code: '71122205',
		label: 'Servicios de registro con alambre (slickline)',

		palabrasSimilares: 'Servicios de registro con línea de acero',
	},
	{
		code: '71122206',
		label: 'Servicios mecánicos con alambre (slickline)',

		palabrasSimilares: 'Servicios mecánicos con línea de acero',
	},
	{
		code: '71122207',
		label: 'Servicios de recuperación de tubos con alambre (slickline)',

		palabrasSimilares: 'Servicios de recuperación de tubos con línea de acero',
	},
	{
		code: '71122300',
		label: 'Servicios de pozo submarino',
	},
	{
		code: '71122301',
		label: 'Servicios de buceo en pozos submarinos',
	},
	{
		code: '71122302',
		label:
			'Servicios de vehículos operados a control remoto (rov) para pozos submarinos',
	},
	{
		code: '71122303',
		label: 'Servicios de equipo de pruebas o accesorios de pozos submarinos',
	},
	{
		code: '71122304',
		label:
			'Servicios de terminación o intervención de pozos submarinos submarino',
	},
	{
		code: '71122305',
		label:
			'Servicios de estructura jerárquica (tree) de las pruebas de seguridad de pozos submarinos',

		palabrasSimilares: 'Servicio de pruebas de seguridad del árbol submarino',
	},
	{
		code: '71122306',
		label: 'Servicios de mantenimiento de equipo del pozo submarino',
	},
	{
		code: '71122307',
		label: 'Servicios de dragado, zanjado y excavación submarina',
	},
	{
		code: '71122308',
		label: 'Servicio de tendido de cables y tubería submarina',
	},
	{
		code: '71122309',
		label:
			'Servicio de inspección y monitoreo del equipo de producción de pozos submarinos',
	},
	{
		code: '71122310',
		label:
			'Servicio de ingeniería y administración de proyectos de pozos submarinos',
	},
	{
		code: '71122311',
		label:
			'Servicio de ingeniería y diseño inicial de proyectos de pozos submarinos feed',
	},
	{
		code: '71122400',
		label: 'Servicios de pruebas del pozo submarino',
	},
	{
		code: '71122407',
		label: 'Servicios de pruebas de rellenos de perforación',

		palabrasSimilares: 'Servicios de pruebas de vástago de perforación',
	},
	{
		code: '71122408',
		label: 'Servicio de pruebas periódicas del pozo',
	},
	{
		code: '71122409',
		label: 'Servicios de pruebas de superficie del pozo',
	},
	{
		code: '71122410',
		label: 'Servicio de pruebas desbalanceadas durante la perforación',
	},
	{
		code: '71122500',
		label: 'Servicios de control de gas o agua',
	},
	{
		code: '71122501',
		label: 'Servicios de diseño del control de gas o agua',
	},
	{
		code: '71122502',
		label: 'Servicios de evaluación del control de gas o agua',
	},
	{
		code: '71122503',
		label: 'Servicios de aislamiento del control de gas o agua',
	},
	{
		code: '71122504',
		label: 'Servicios de pruebas del control de gas o agua',
	},
	{
		code: '71122505',
		label: 'Servicios de pruebas de gas o agua',
	},
	{
		code: '71122506',
		label: 'Servicios de llamarada',
	},
	{
		code: '71122600',
		label: 'Servicios de terminación del pozo',
	},
	{
		code: '71122601',
		label: 'Servicios de terminación de pozos de agujero revestido',

		palabrasSimilares: 'Servicio de terminación de pozo entubado',
	},
	{
		code: '71122602',
		label: 'Servicios de fluidos de terminación del pozo',
	},
	{
		code: '71122605',
		label: 'Servicio de terminación de la elevación de gas del pozo',
	},
	{
		code: '71122606',
		label: 'Servicios de terminación de pozos inteligentes',
	},
	{
		code: '71122608',
		label: 'Servicios de terminación multilateral de pozos',
	},
	{
		code: '71122610',
		label: 'Servicios de terminación del control de arena del pozo',

		palabrasSimilares: 'Servicios de finalización de pozos de control de arena',
	},
	{
		code: '71122614',
		label: 'Servicios para el rodamiento de líneas',
	},
	{
		code: '71122615',
		label: 'Servicios de terminación de hoyo abierto',
	},
	{
		code: '71122616',
		label: 'Servicios de terminación de pozo con tubo/malla expandible',
	},
	{
		code: '71122700',
		label: 'Servicios de mantenimiento del pozo',
	},
	{
		code: '71122701',
		label: 'Servicios de mantenimiento de la plataforma de pozos petroleros',
	},
	{
		code: '71122702',
		label:
			'Servicios de mantenimiento de la unidad de bombeo del campo petrolero',
	},
	{
		code: '71122703',
		label: 'Servicios de reparación de bomba de vástago del campo petrolero',

		palabrasSimilares:
			'Servicios de reparación de bombas de varilla de campo petrolífero',
	},
	{
		code: '71122704',
		label: 'Servicios de socavación del campo petrolero',

		palabrasSimilares:
			'Servicio de entubación bajo presión de pozos petroleros',
	},
	{
		code: '71122705',
		label: 'Servicios de mantenimiento vástago de succión del campo petrolero',
	},
	{
		code: '71122706',
		label: 'Servicios de achique de pozos',
	},
	{
		code: '71122707',
		label: 'Servicios de anclaje de tubería del campo petrolero',
	},
	{
		code: '71122708',
		label: 'Servicios mecánicos para bombas del campo petrolero',
	},
	{
		code: '71122709',
		label: 'Servicio de mantenimiento de herramientas en el fondo del pozo',
	},
	{
		code: '71122710',
		label: 'Servicios de mantenimiento de tubos del campo petrolero',
	},
	{
		code: '71122711',
		label: 'Servicio de reacondicionamiento de pozos',
	},
	{
		code: '71122800',
		label: 'Servicios de monitor del pozo petrolero',
	},
	{
		code: '71122801',
		label: 'Servicios de grabación en el fondo del pozo',
	},
	{
		code: '71122802',
		label: 'Servicios de monitoreo del flujo del pozo',
	},
	{
		code: '71122803',
		label: 'Servicios de monitoreo del flujo del pozo petrolero o de gas',
	},
	{
		code: '71122804',
		label: 'Servicios de monitoreo de las fases en el emplazamiento  del pozo',
	},
	{
		code: '71122805',
		label: 'Servicios de monitoreo de las bombas en el emplazamiento del pozo',
	},
	{
		code: '71122806',
		label:
			'Servicios de adquisición de datos de la superficie en el emplazamiento del pozo',
	},
	{
		code: '71122807',
		label: 'Servicios de lectura de la superficie en el emplazamiento del pozo',
	},
	{
		code: '71122808',
		label:
			'Servicios de grabación de la superficie en el emplazamiento del pozo',
	},
	{
		code: '71122810',
		label: 'Servicios de pruebas de la superficie inferior del pozo',

		palabrasSimilares: 'Servicio de prueba del subsuelo del pozo',
	},
	{
		code: '71122900',
		label: 'Aparejos del campo petrolífero',
	},
	{
		code: '71122901',
		label: 'Servicios de aparejos del campo petrolero en aguas profundas',
	},
	{
		code: '71122902',
		label: 'Servicios de plataformas  petroleras autoelevables (jackup)',
	},
	{
		code: '71122903',
		label: 'Servicios de plataformas petroleras en barcazas',

		palabrasSimilares: 'Servicios de plataformas petroleras en embarcaciones',
	},
	{
		code: '71122904',
		label: 'Servicios de plataforma petroleras en tierra',
	},
	{
		code: '71122905',
		label: 'Servicios de plataformas petroleras',
	},
	{
		code: '71123000',
		label: 'Servicios integrados',
	},
	{
		code: '71123001',
		label:
			'Administración y provisión de todas los servicios de las instalaciones, ingeniería, modificación y mantenimiento para un emplazamiento o plataforma',
	},
	{
		code: '71123002',
		label:
			'Administración y operación de todas los servicios de las instalaciones, ingeniería, modificación y mantenimiento para un emplazamiento o plataforma',
	},
	{
		code: '71123003',
		label:
			'Servicios de producción y desarrollo de los depósitos de hidrocarburos',

		palabrasSimilares:
			'Servicio de desarrollo y producción de yacimientos de hidrocarburos',
	},
	{
		code: '71123004',
		label: 'Servicios de construcción/administración de pozos',
	},
	{
		code: '71123005',
		label:
			'Administración y provisión de todos los servicios de catering, limpieza, oficina y seguridad en el emplazamiento o plataforma',
	},
	{
		code: '71123006',
		label: 'Desarrollo de campos',
	},
	{
		code: '71123007',
		label: 'Servicios de rehabilitación de campos',
	},
	{
		code: '71131000',
		label: 'Servicios de fracturar del pozo',
	},
	{
		code: '71131001',
		label: 'Servicios de fracturación del pozo con fluido a base de ácido',

		palabrasSimilares: 'Servicios de fracturación con fluido  base ácido',
	},
	{
		code: '71131002',
		label: 'Servicios de fracturación del pozo con fluido a base de emulsión',
	},
	{
		code: '71131003',
		label: 'Servicios de fracturación del pozo con fluido a base de espuma',
	},
	{
		code: '71131004',
		label: 'Servicios de fracturación  del pozo de petróleo',
	},
	{
		code: '71131005',
		label: 'Servicios de control de la báscula del pozo de petróleo',
	},
	{
		code: '71131006',
		label: 'Servicios de pruebas de fracturación del pozo',
	},
	{
		code: '71131007',
		label:
			'Servicios de evaluación de la fracturación del pozo en el fondo del pozo',
	},
	{
		code: '71131008',
		label: 'Servicios de control de la altura de la fracturación del pozo',
	},
	{
		code: '71131009',
		label: 'Servicios de monitoreo de la fracturación del pozo',
	},
	{
		code: '71131010',
		label: 'Servicios de diseño del servicio de fracturación del pozo',
	},
	{
		code: '71131011',
		label: 'Servicios de evaluación del servicio de fracturación del pozo',
	},
	{
		code: '71131012',
		label: 'Servicios de evaluación de superficie de la fracturación del pozo',
	},
	{
		code: '71131013',
		label:
			'Servicios de control de calidad del tratamiento de la fracturación del pozo',
	},
	{
		code: '71131014',
		label: 'Servicios de fracturación  del pozo con fluidos a base de aceite',
	},
	{
		code: '71131015',
		label: 'Otros servicios de fracturación de pozos',
	},
	{
		code: '71131016',
		label: 'Servicios de manejo del esfuerzo de fracturación del pozo',
	},
	{
		code: '71131018',
		label:
			'Servicios de fracturación del pozo con fluido lineal a base de agua',

		palabrasSimilares: 'Servicios de fracturación con fluido lineal  base agua',
	},
	{
		code: '71131019',
		label:
			'Servicios de fracturación de pozos con fluido entrecruzado a base de agua',

		palabrasSimilares:
			'Servicios de fracturación con fluido entrecruzado  base  agua',
	},
	{
		code: '71131100',
		label: 'Servicios de estimulación de la matriz',
	},
	{
		code: '71131101',
		label: 'Servicios de estimulación de la matriz  a base de ácido',
	},
	{
		code: '71131102',
		label:
			'Servicios de estimulación de la matriz de sellamiento de la formación',
	},
	{
		code: '71131103',
		label: 'Servicios de limpieza total orgánica de la matriz',

		palabrasSimilares: 'Servicios de limpieza con una matriz orgánica',
	},
	{
		code: '71131104',
		label: 'Servicios de inhibición orgánica de la matriz',

		palabrasSimilares: 'Servicios de inhibición con una matriz orgánica',
	},
	{
		code: '71131105',
		label: 'Servicios de limpieza general de la balanza de la matriz',
	},
	{
		code: '71131106',
		label: 'Servicios de inhibición de la balanza de la matriz',
	},
	{
		code: '71131107',
		label: 'Servicios de diseño del tratamiento de la matriz',

		palabrasSimilares: 'Servicios de diseño de tratamientos matriciales',
	},
	{
		code: '71131108',
		label: 'Servicios de desviación del tratamiento de la matriz',

		palabrasSimilares: 'Servicios de desviación de tratamiento matricial',
	},
	{
		code: '71131109',
		label: 'Servicios de evaluación del tratamiento de la matriz',
	},
	{
		code: '71131110',
		label: 'Servicios del control de calidad del tratamiento de la matriz',
	},
	{
		code: '71131111',
		label: 'Servicios de estimulación de la matriz de base no ácida',

		palabrasSimilares:
			'Servicios de estimulación de la matriz de base no ácida',
	},
	{
		code: '71131200',
		label: 'Servicios de nitrógeno del campo petrolífero',
	},
	{
		code: '71131201',
		label: 'Servicios de nitrógeno para el pozo',
	},
	{
		code: '71131300',
		label: 'Otros servicios de bombeo del campo petrolífero',
	},
	{
		code: '71131301',
		label: 'Servicios de bombeo del campo petrolero con bocatoma de fondo',
	},
	{
		code: '71131302',
		label: 'Servicios de bombeo del campo petrolero utilizando cables',
	},
	{
		code: '71131303',
		label: 'Servicios de bombeo del campo petrolero con co2',
	},
	{
		code: '71131304',
		label:
			'Servicios de bombeo del campo petrolero utilizando tubería flexible continua',
	},
	{
		code: '71131305',
		label: 'Servicios de bombeo en el fondo del pozo',
	},
	{
		code: '71131306',
		label: 'Servicios de terminación dual del pozo',
	},
	{
		code: '71131307',
		label: 'Servicios de bombeo horizontal del pozo',
	},
	{
		code: '71131308',
		label:
			'Servicios de operación o arrastre de la instalación de bombeo del campo petrolífero',

		palabrasSimilares:
			'Servicios de extracción o de operación de instalaciones de bombeo de campos petrolíferos',
	},
	{
		code: '71131309',
		label: 'Servicios de modificación del perfil del pozo',
	},
	{
		code: '71131310',
		label: 'Servicios de bobinado del campo petrolífero',
	},
	{
		code: '71131311',
		label: 'Servicios de bombeo de cavidad progresiva',
	},
	{
		code: '71131312',
		label: 'Servicios de bombeo electrónico sumergible',
	},
	{
		code: '71131313',
		label: 'Servicios de bombeo convencional',
	},
	{
		code: '71131400',
		label: 'Servicios de producción del pozo',
	},
	{
		code: '71131401',
		label: 'Servicios del sistema de llamarada del campo petrolífero',

		palabrasSimilares: 'Servicios del sistema de llamarada del campo petrolero',
	},
	{
		code: '71131402',
		label: 'Servicio de aceite o agua caliente para el campo petrolero',
	},
	{
		code: '71131403',
		label: 'Servicio de vapor del campo petrolero',
	},
	{
		code: '71131404',
		label: 'Servicios de recuperación de sulfuro',

		palabrasSimilares: 'Servicios de eliminación de sulfuro',
	},
	{
		code: '71131405',
		label:
			'Servicios de control de formación de hidratos en las tuberías de producción',
	},
	{
		code: '71131406',
		label: 'Servicios de eliminación de depósitos de sulfato de hierro',
	},
	{
		code: '71131407',
		label: 'Servicios de modelado de forzamiento de inyección',
	},
	{
		code: '71131408',
		label: 'Servicios de modelado de formación de hidratos',
	},
	{
		code: '71131409',
		label: 'Servicios de monitoreo de tratamientos químicos',
	},
	{
		code: '71131410',
		label: 'Servicios de limpieza de conductos',
	},
	{
		code: '71131411',
		label: 'Servicio de mejoramiento del flujo en los conductos',
	},
	{
		code: '71131412',
		label: 'Servicios de mejoramiento y restauración de pozos',
	},
	{
		code: '71131413',
		label: 'Servicios de inhibición de la corrosión',
	},
	{
		code: '71131414',
		label: 'Servicios de inhibición de escamas',

		palabrasSimilares: 'Servicios de inhibición de incrustaciones',
	},
	{
		code: '71131415',
		label: 'Servicios de garantía del flujo en los conductos',
	},
	{
		code: '71131416',
		label: 'Servicios de eliminación de impurezas',
	},
	{
		code: '71131417',
		label: 'Servicios de control de incrustaciones orgánicas',
	},
	{
		code: '71131418',
		label: 'Servicios de control de hongos y bacterias',
	},
	{
		code: '71131419',
		label: 'Servicio de recolección de gas',
	},
	{
		code: '71131420',
		label: 'Servicio de filtraciones de reflujo del pozo',
	},
	{
		code: '71141000',
		label: 'Servicios de emergencia de control del pozo',
	},
	{
		code: '71141001',
		label: 'Servicios de control de la presión del pozo',
	},
	{
		code: '71141002',
		label: 'Servicios de tapado del pozo',
	},
	{
		code: '71141003',
		label: 'Servicios de lucha contra incendios en el pozo',
	},
	{
		code: '71141004',
		label: 'Servicios de control de pozos descontrolados',
	},
	{
		code: '71141005',
		label: 'Reacondicionamiento de pozos muertos',
	},
	{
		code: '71141006',
		label: 'Operaciones de congelamiento del pozo',
	},
	{
		code: '71141007',
		label: 'Diseño e implementación de pozos de alivio',
	},
	{
		code: '71141008',
		label: 'Servicios de ingeniería para el control de pozos',
	},
	{
		code: '71141100',
		label: 'Servicios de tapar y abandono del pozo',
	},
	{
		code: '71141101',
		label: 'Servicios de abandono de pozos',
	},
	{
		code: '71141102',
		label: 'Servicios de taponamiento de pozos',

		palabrasSimilares: 'Servicios de cierre de pozos',
	},
	{
		code: '71141103',
		label:
			'Servicios de eliminación de la plataforma para el abandono del pozo',
	},
	{
		code: '71141104',
		label:
			'Servicios de corte de tubería para el abandono y taponamiento de pozos',

		palabrasSimilares:
			'Servicios de corte de tubería para el abandono y cierre de pozos',
	},
	{
		code: '71141200',
		label: 'Servicios de restaurar el sitio del pozo',
	},
	{
		code: '71141201',
		label: 'Servicios de limpieza o achique del pozo',
	},
	{
		code: '71141202',
		label: 'Servicios de restauración del emplazamiento del pozo',
	},
	{
		code: '71151000',
		label: 'Servicios de comunicación de datos del campo petrolífero',
	},
	{
		code: '71151001',
		label: 'Servicios de transmisión de mensajes y datos del campo petrolero',
	},
	{
		code: '71151002',
		label: 'Servicios de transmisión de gráficas del campo petrolero',
	},
	{
		code: '71151003',
		label:
			'Servicios de monitoreo en tiempo real de datos del pozo en el campo petrolero',
	},
	{
		code: '71151004',
		label:
			'Servicios de transmisión satelital de datos del pozo en el campo petrolero',
	},
	{
		code: '71151005',
		label: 'Servicios de transmisión de datos del pozo en el campo petrolero',
	},
	{
		code: '71151007',
		label:
			'Servicios de programas de capacitación de simulación basados en computador',
	},
	{
		code: '71151100',
		label: 'Servicios de gerencia de datos del campo petrolífero',
	},
	{
		code: '71151101',
		label:
			'Servicios de administración de datos de los activos del campo petrolero',
	},
	{
		code: '71151102',
		label: 'Servicios de minería de datos del campo petrolero',
	},
	{
		code: '71151103',
		label: 'Servicios de gestión  de datos de registro del campo petrolero',
	},
	{
		code: '71151104',
		label: 'Servicios de gestión de datos cartográficos del campo petrolero',
	},
	{
		code: '71151105',
		label: 'Servicios de gestión de datos sísmicos',
	},
	{
		code: '71151106',
		label: 'Copias de seguridad  y almacenamiento de datos',
	},
	{
		code: '71151200',
		label: 'Servicios de modelo económico y de riesgo del campo petrolífero',
	},
	{
		code: '71151201',
		label: 'Servicios de preparación del presupuesto del campo petrolero',
	},
	{
		code: '71151202',
		label: 'Servicios de planificación del capital del campo petrolero',
	},
	{
		code: '71151203',
		label:
			'Servicios de estructura jerárquica (tree) de las decisiones del campo petrolero',
	},
	{
		code: '71151300',
		label: 'Servicios de interpretación del campo petrolífero',
	},
	{
		code: '71151301',
		label: 'Servicios de mapas de burbujas del campo petrolero',
	},
	{
		code: '71151302',
		label: 'Estudios de casos del campo petrolero',
	},
	{
		code: '71151303',
		label: 'Análisis del decaimiento del campo petrolero',
	},
	{
		code: '71151304',
		label: 'Estudios de campo del campo petrolero',
	},
	{
		code: '71151305',
		label:
			'Servicios de interpretación de la fracturación  del campo petrolero',
	},
	{
		code: '71151306',
		label: 'Servicios de geología',
	},
	{
		code: '71151307',
		label: 'Servicios de geofísica',
	},
	{
		code: '71151308',
		label: 'Servicios de interpretación del empaque con grava',
	},
	{
		code: '71151309',
		label: 'Servicios de cartografía de cuadrícula del campo petrolero',
	},
	{
		code: '71151310',
		label: 'Servicios de visualización o cartografía del campo petrolero',
	},
	{
		code: '71151311',
		label: 'Servicios de petrofísica',
	},
	{
		code: '71151315',
		label: 'Servicios de mecánica de rocas',
	},
	{
		code: '71151316',
		label: 'Servicios de tanques de almacenamiento',

		palabrasSimilares: 'Servicios de depósitos de almacenamiento',
	},
	{
		code: '71151317',
		label: 'Evaluación general de la formación',
	},
	{
		code: '71151318',
		label: 'Servicios de procesamiento de la forma de ondas acústicas',

		palabrasSimilares: 'Servicios de procesamiento de ondas acústicas',
	},
	{
		code: '71151319',
		label: 'Procesamiento de servicios geomecánicos',
	},
	{
		code: '71151320',
		label:
			'Servicios de interpretación y procesamiento de resonancia magnética nuclear',
	},
	{
		code: '71151321',
		label: 'Servicios de interpretación y procesamiento de la resistividad',
	},
	{
		code: '71151322',
		label: 'Procesamiento de la dirección y el ángulo de inclinación',
	},
	{
		code: '71151323',
		label: 'Procesamiento de imágenes de la perforación',
	},
	{
		code: '71151324',
		label: 'Registro durante procesamiento de perforación',
	},
	{
		code: '71151325',
		label:
			'Servicios de procesamiento e interpretación de los neutrones pulsados',
	},
	{
		code: '71151326',
		label:
			'Procesamiento general de los registros de producción y agujeros revestidos',
	},
	{
		code: '71151327',
		label:
			'Procesamiento de los servicios de análisis de inspección de la tubería',
	},
	{
		code: '71151400',
		label: 'Servicios de ingeniería del pozo',
	},
	{
		code: '71151401',
		label: 'Servicios de diseño de las tareas de cementación del pozo',
	},
	{
		code: '71151402',
		label: 'Servicios de diseño de las tareas con tubería flexible continua',
	},
	{
		code: '71151403',
		label: 'Servicios de diseño de la tarea de perforar el pozo',

		palabrasSimilares:
			'Servicios de diseño de los trabajos de perforación de pozos',
	},
	{
		code: '71151404',
		label: 'Servicios de diseño de las tareas de fracturación del pozo',

		palabrasSimilares:
			'Servicios de diseño de los trabajos de fractura de pozos',
	},
	{
		code: '71151405',
		label: 'Servicios de diseño de la tareas de estimulación de la matriz',

		palabrasSimilares:
			'Servicios de diseño de los trabajos de estimulación matricial',
	},
	{
		code: '71151406',
		label:
			'Servicios de diseño de las tareas para el control de arena del pozo',

		palabrasSimilares:
			'Servicios de diseño de los trabajos para el control de arena del pozo',
	},
	{
		code: '71161000',
		label: 'Servicios de modelar del campo petrolífero',
	},
	{
		code: '71161001',
		label: 'Modelos de terminación  del campo petrolero',
	},
	{
		code: '71161002',
		label: 'Modelos de perforación del campo petrolero',
	},
	{
		code: '71161003',
		label: 'Modelos económicos del campo petrolero',
	},
	{
		code: '71161004',
		label: 'Modelos del desarrollo del campo del campo petrolero',
	},
	{
		code: '71161005',
		label: 'Modelos de producción del campo petrolero',
	},
	{
		code: '71161006',
		label: 'Servicios de gestión de riesgo del campo petrolero',
	},
	{
		code: '71161007',
		label: 'Modelos geológicos o geofísicos',
	},
	{
		code: '71161008',
		label: 'Modelos de yacimientos',
	},
	{
		code: '71161100',
		label: 'Gerencia de la ingeniería de producción del campo petrolífero',
	},
	{
		code: '71161101',
		label: 'Servicios de elevadores artificiales del campo petrolero',
	},
	{
		code: '71161102',
		label: 'Servicios de recuperación aumentada del petróleo',

		palabrasSimilares: 'Servicios mejorados de recuperación de petróleo',
	},
	{
		code: '71161103',
		label: 'Servicios de inyección del pozo',
	},
	{
		code: '71161104',
		label:
			'Servicios de análisis del sistema de producción del campo petrolero',
	},
	{
		code: '71161105',
		label: 'Servicios de perforación de pozos',
	},
	{
		code: '71161106',
		label: 'Servicios de química de la producción del campo petrolero',
	},
	{
		code: '71161107',
		label: 'Servicios de monitoreo de la producción del campo  petrolero',
	},
	{
		code: '71161109',
		label: 'Servicios de estimulación  del pozo',
	},
	{
		code: '71161110',
		label: 'Servicios de almacenamiento subterráneo de gas',
	},
	{
		code: '71161111',
		label: 'Servicios de administración del agua del campo petrolífero',

		palabrasSimilares: 'Servicios de gestión del agua para campos petrolíferos',
	},
	{
		code: '71161200',
		label: 'Gerencia de las operaciones de producción del campo petrolífero',
	},
	{
		code: '71161201',
		label: 'Servicios de lubricación en caliente del campo petrolero',

		palabrasSimilares: 'Servicio de unidades de aceite caliente',
	},
	{
		code: '71161202',
		label: 'Servicios de operaciones de arriendo',

		palabrasSimilares:
			'Operaciones de arrendamiento de yacimientos petrolíferos',
	},
	{
		code: '71161203',
		label: 'Servicios de registro del campo petrolero',
	},
	{
		code: '71161204',
		label: 'Servicios de corte de parafina en el campo petrolero',
	},
	{
		code: '71161205',
		label: 'Servicios de planificación de campos petroleros',
	},
	{
		code: '71161206',
		label: 'Servicios de procesamiento de campos petroleros',
	},
	{
		code: '71161300',
		label: 'Servicios de manejo de proyectos del campo',
	},
	{
		code: '71161301',
		label: 'Servicios de inspección o auditoría de campos petroleros',
	},
	{
		code: '71161302',
		label:
			'Servicios de planificación de respuesta de emergencia del campo petrolero',
	},
	{
		code: '71161303',
		label: 'Servicios de desarrollo de campos petroleros',
	},
	{
		code: '71161304',
		label: 'Servicios de monitoreo del rendimiento del campo petrolero',
	},
	{
		code: '71161305',
		label: 'Servicios de presentación informes del campo petrolero',
	},
	{
		code: '71161306',
		label:
			'Servicios de entrega (handover) o plan de sucesión del campo petrolero',

		palabrasSimilares:
			'Plan de sucesión de campos petrolíferos o servicios de traspaso',
	},
	{
		code: '71161307',
		label: 'Servicios de matriz de entrenamiento del campo petrolero',
	},
	{
		code: '71161308',
		label:
			'Servicios de aprovisionamiento o logística del emplazamiento del pozo',
	},
	{
		code: '71161400',
		label: 'Servicios de manejo de proyectos del campo petrolero',
	},
	{
		code: '71161402',
		label: 'Servicios de ingeniería para la terminación del pozo',
	},
	{
		code: '71161403',
		label: 'Servicios de anclaje (deadman)',
	},
	{
		code: '71161405',
		label: 'Servicios de revestimiento de pozos en su emplazamiento',
	},
	{
		code: '71161407',
		label: 'Servicios de unidad  jaladora para el pozo',

		palabrasSimilares: 'Servicio de unidad de tracción de pozos',
	},
	{
		code: '71161408',
		label: 'Servicios en la costa',
	},
	{
		code: '71161409',
		label: 'Servicios de revestimiento de pozos de exploración',
	},
	{
		code: '71161410',
		label: 'Servicios de camiones de vacío en el emplazamiento del pozo',
	},
	{
		code: '71161411',
		label: 'Servicios de ingeniería de aplicaciones desbalanceadas',
	},
	{
		code: '71161413',
		label: 'Servicios de construcción o fabricación de pozos',
	},
	{
		code: '71161414',
		label: 'Servicio de botes elevadores para el reacondicionamiento de pozos',

		palabrasSimilares:
			'Servicio de barcos elevadores para el reacondicionamiento de pozos',
	},
	{
		code: '71161500',
		label: 'Servicios de operación del lugar de la perforación',
	},
	{
		code: '71161503',
		label:
			'Servicios de pruebas de equipo o inspección del emplazamiento del pozo',
	},
	{
		code: '71161600',
		label: 'Otros servicios de soporte para campos petroleros',
	},
	{
		code: '71161601',
		label: 'Servicios de dibujo para campos petroleros',

		palabrasSimilares: 'Servicios de planos para campos petroleros',
	},
	{
		code: '71161602',
		label: 'Servicios de corretaje de equipos para campos petroleros',
	},
	{
		code: '71161603',
		label: 'Servicios de investigación y desarrollo de campos petroleros',
	},
	{
		code: '71161604',
		label: 'Servicios de certificación de campos petroleros',
	},
	{
		code: '71161605',
		label: 'Servicios de consultoría de campos petroleros',
	},
	{
		code: '71161606',
		label: 'Servicio de licencia tecnológica para gas y petróleo',
	},
	{
		code: '72101500',
		label: 'Servicios de apoyo para la construcción',
	},
	{
		code: '72101501',
		label: 'Servicios de todero',

		palabrasSimilares:
			'Servicio de empleados de mantenimiento, Servicio de trabajadores de mantenimiento',
	},
	{
		code: '72101504',
		label: 'Servicios de verificación de desastres o contingencias',
	},
	{
		code: '72101505',
		label: 'Servicios de cerrajería',
	},
	{
		code: '72101506',
		label: 'Servicios de mantenimiento de ascensores',

		palabrasSimilares: 'Servicios de mantenimiento de elevadores',
	},
	{
		code: '72101507',
		label: 'Servicio de mantenimiento de edificios',
	},
	{
		code: '72101508',
		label: 'Servicio de limpieza de pisos',
	},
	{
		code: '72101509',
		label:
			'Servicio de mantenimiento o reparación de equipos y sistemas de protección contra incendios',
	},
	{
		code: '72101510',
		label: 'Mantenimiento o reparación del sistema de plomería',
	},
	{
		code: '72101511',
		label:
			'Servicio de instalación o mantenimiento o reparación de aires acondicionados',
	},
	{
		code: '72101512',
		label: 'Servicio de construcción de malacates',

		palabrasSimilares: 'Servicio de elevadores de construcción',
	},
	{
		code: '72101513',
		label: 'Servicio de construcción fuera del sitio (offsite)',

		palabrasSimilares: 'Servicio de construcción exterior',
	},
	{
		code: '72101514',
		label: 'Servicio de construcción de zonas de desperdicios',
	},
	{
		code: '72101515',
		label: 'Servicio de trabajos por hundimiento',
	},
	{
		code: '72101516',
		label:
			'Servicio de inspección, mantenimiento o reparación de extinguidores de fuego',
	},
	{
		code: '72101517',
		label: 'Servicio de mantenimiento o reparación de generadores portátiles',
	},
	{
		code: '72101518',
		label: 'Servicio de alquiler de generadores portátiles',

		palabrasSimilares:
			'Servicio de arrendamiento de generadores portátiles, Servicio de renta de generadores portátiles',
	},
	{
		code: '72102100',
		label: 'Control de plagas',
	},
	{
		code: '72102101',
		label: 'Servicios de verificación de presencia de aves',

		palabrasSimilares: 'Servicios de sistemas anti aves',
	},
	{
		code: '72102102',
		label: 'Servicios de control de termitas',
	},
	{
		code: '72102103',
		label: 'Servicios de exterminación o fumigación',
	},
	{
		code: '72102104',
		label: 'Control estructural de plagas',

		palabrasSimilares: 'Control estructural de pestes',
	},
	{
		code: '72102105',
		label: 'Captura de animales',
	},
	{
		code: '72102106',
		label: 'Control de roedores',

		palabrasSimilares: 'Control de ratones',
	},
	{
		code: '72102900',
		label: 'Servicios de mantenimiento y reparación de instalaciones',
	},
	{
		code: '72102902',
		label: 'Servicios de paisajismo',

		palabrasSimilares: 'Servicios de jardinería',
	},
	{
		code: '72102903',
		label: 'Servicios de eliminación de nieve',

		palabrasSimilares: 'Servicios de retirada de nieve',
	},
	{
		code: '72102905',
		label: 'Mantenimiento de terrenos exteriores',
	},
	{
		code: '72103100',
		label: 'Instalación y reparación de servicios de bandas transportadoras',
	},
	{
		code: '72103101',
		label: 'Instalación de sistemas de transporte de materiales',
	},
	{
		code: '72103102',
		label: 'Reparación de sistemas de transporte de materiales',
	},
	{
		code: '72103103',
		label: 'Servicio transportador de superficie',
	},
	{
		code: '72103104',
		label: 'Servicio transportador subterráneo',
	},
	{
		code: '72103300',
		label: 'Servicios de mantenimiento y reparación de infraestructura',
	},
	{
		code: '72103301',
		label: 'Servicios o reparaciones o mantenimiento de calles o parqueaderos',

		palabrasSimilares:
			'Mantenimiento o reparaciones o servicios de estacionamiento o de caminos',
	},
	{
		code: '72103302',
		label: 'Mantenimiento o soporte de equipo de telecomunicaciones',
	},
	{
		code: '72103304',
		label: 'Servicios de barrido de calles o parqueaderos',

		palabrasSimilares: 'Servicios de barrido de aparcamiento o de carretera',
	},
	{
		code: '72111000',
		label: 'Servicios de construcción de unidades unifamiliares',
	},
	{
		code: '72111001',
		label:
			'Servicios de reparación o ampliación por remodelación de viviendas unifamiliares',
	},
	{
		code: '72111002',
		label: 'Servicio de remodelación general de viviendas unifamiliares',
	},
	{
		code: '72111003',
		label: 'Servicio de reparación de casas móviles en el sitio',

		palabrasSimilares: 'Servicios de reparación a domicilio',
	},
	{
		code: '72111004',
		label: 'Servicio de construcción y reparación de patios y terrazas',
	},
	{
		code: '72111005',
		label:
			'Servicio de reparación por daños ocasionados por fuego de viviendas unifamiliares',
	},
	{
		code: '72111006',
		label: 'Servicio de construcción de casas unifamiliares nuevas',
	},
	{
		code: '72111007',
		label: 'Servicio de instalación de casas unifamiliares prefabricadas',
	},
	{
		code: '72111008',
		label:
			'Servicio de construcción de casas en la ciudad o casas jardín unifamiliares nuevas',
	},
	{
		code: '72111100',
		label: 'Servicios de construcción de unidades multifamiliares',
	},
	{
		code: '72111101',
		label: 'Servicio de construcción de apartamentos nuevos',
	},
	{
		code: '72111102',
		label: 'Servicio de construcción de cooperativas nuevas',
	},
	{
		code: '72111103',
		label: 'Servicio de construcción de condominios nuevos',
	},
	{
		code: '72111104',
		label: 'Servicio de construcción de residenciales estudiantiles nuevas',

		palabrasSimilares:
			'Servicio de construcción de residencias estudiantiles nuevas',
	},
	{
		code: '72111105',
		label: 'Servicio de construcción de hoteles o moteles nuevos',
	},
	{
		code: '72111106',
		label: 'Servicio de remodelación de apartamentos',

		palabrasSimilares: 'Servicio de remodelación de departamentos',
	},
	{
		code: '72111107',
		label: 'Servicio de remodelación de apartamentos cooperativos',

		palabrasSimilares: 'Servicio de remodelación de departamentos cooperativos',
	},
	{
		code: '72111108',
		label: 'Servicio de remodelación de condominios',
	},
	{
		code: '72111109',
		label: 'Servicio de remodelación de residencias estudiantiles',
	},
	{
		code: '72111110',
		label: 'Servicio de remodelación de hoteles o moteles',
	},
	{
		code: '72111111',
		label:
			'Servicios de contratación general para la construcción de residencias',

		palabrasSimilares:
			'Servicios de contratista general para la construcción de residencias',
	},
	{
		code: '72121000',
		label:
			'Servicios de construcción de edificios industriales y bodegas nuevas',
	},
	{
		code: '72121001',
		label:
			'Servicio de construcción y remodelación de plantas de lavado en seco',
	},
	{
		code: '72121002',
		label:
			'Servicio de construcción y remodelación de plantas de elaboración o empaque de productos alimenticios',
	},
	{
		code: '72121003',
		label: 'Servicio de construcción y remodelación de elevadores de granos',
	},
	{
		code: '72121004',
		label:
			'Servicio de construcción o remodelación de molinos de papel o pulpa',
	},
	{
		code: '72121005',
		label:
			'Servicio de construcción y remodelación de plantas de productos farmacéuticos',
	},
	{
		code: '72121006',
		label:
			'Servicio de instalación y remodelación de edificaciones industriales prefabricadas',
	},
	{
		code: '72121007',
		label:
			'Servicio de construcción y remodelación de plantas ensambladoras de automóviles y camiones',
	},
	{
		code: '72121008',
		label: 'Servicio de construcción y remodelación de bodegas',

		palabrasSimilares: 'Servicio de construcción y remodelación de almacenes',
	},
	{
		code: '72121100',
		label: 'Servicios de construcción de edificios comerciales y de oficina',
	},
	{
		code: '72121101',
		label:
			'Servicio de construcción de edificios comerciales y de oficinas nuevos',
	},
	{
		code: '72121102',
		label:
			'Servicio de instalación de edificios comerciales y de oficinas prefabricados',
	},
	{
		code: '72121103',
		label:
			'Servicios de renovación y reparación de edificios comerciales y de oficinas',
	},
	{
		code: '72121104',
		label: 'Servicio de construcción de restaurantes',
	},
	{
		code: '72121105',
		label: 'Servicio de construcción de centros y galerías comerciales',
	},
	{
		code: '72121200',
		label: 'Servicios de construcción de edificios agrícolas',
	},
	{
		code: '72121201',
		label: 'Servicio de construcción de edificaciones para fincas',

		palabrasSimilares: 'Servicio de construcción de granjas',
	},
	{
		code: '72121202',
		label: 'Servicio de construcción de invernaderos',
	},
	{
		code: '72121203',
		label:
			'Servicio de construcción de edificaciones para servicios agrícolas y de silos',
	},
	{
		code: '72121300',
		label:
			'Servicios de construcción de talleres automotrices y estaciones de servicio',
	},
	{
		code: '72121301',
		label: 'Servicio de construcción de garajes automotrices',

		palabrasSimilares: 'Servicio de construcción de cocheras automotrices',
	},
	{
		code: '72121302',
		label: 'Servicio de construcción de estaciones de servicios automotrices',
	},
	{
		code: '72121400',
		label: 'Servicios de construcción de edificios públicos especializados',
	},
	{
		code: '72121401',
		label: 'Servicio de construcción de edificios de bancos',

		palabrasSimilares: 'Servicio de construcción de edificios bancarios',
	},
	{
		code: '72121402',
		label: 'Servicio de construcción de estaciones de bomberos',
	},
	{
		code: '72121403',
		label: 'Servicio de construcción de hospitales',
	},
	{
		code: '72121404',
		label: 'Servicio de construcción de oficinas de correo',
	},
	{
		code: '72121405',
		label: 'Servicios de construcción de edificios religiosos',

		palabrasSimilares: 'Servicios de construcción de iglesias',
	},
	{
		code: '72121406',
		label: 'Servicio de construcción de edificios de escuelas',
	},
	{
		code: '72121407',
		label: 'Servicio de construcción de mausoleos',

		palabrasSimilares: 'Servicio de construcción de criptas',
	},
	{
		code: '72121408',
		label: 'Servicio de construcción de estadios',
	},
	{
		code: '72121409',
		label: 'Construcción de bibliotecas públicas',
	},
	{
		code: '72121410',
		label: 'Construcción de terminales y hangares de aeropuertos',
	},
	{
		code: '72121500',
		label: 'Servicios de construcción de plantas industriales',
	},
	{
		code: '72121501',
		label: 'Servicio de construcción de plantas químicas',
	},
	{
		code: '72121502',
		label:
			'Servicio de construcción de estaciones de carga y descarga de las minas',
	},
	{
		code: '72121503',
		label: 'Servicio de construcción de refinerías de petróleo',
	},
	{
		code: '72121504',
		label: 'Servicio de construcción de plantas de eliminación de aguas',
	},
	{
		code: '72121505',
		label:
			'Servicio construcción de plantas de tratamiento de aguas residuales y aguas negras',
	},
	{
		code: '72121506',
		label: 'Servicio de construcción de centrales eléctricas',
	},
	{
		code: '72121507',
		label: 'Construcción, mantenimiento y reparación de tanques',
	},
	{
		code: '72121508',
		label: 'Servicio de construcción de plantas de preparación',
	},
	{
		code: '72121509',
		label: 'Servicios eléctricos subterráneos',
	},
	{
		code: '72121510',
		label:
			'Servicio de construcción de sistemas flotantes de descarga y almacenamiento de la producción de gas y petróleo',
	},
	{
		code: '72121511',
		label:
			'Servicio de montaje y puesta en servicio de instalaciones de gas y petróleo mar adentro',

		palabrasSimilares:
			'Servicio de conexión y puesta en servicio de instalaciones de producción de petróleo y gas en alta mar',
	},
	{
		code: '72121512',
		label:
			'Servicio de integración e instalación de complejos de producción de gas y petróleo mar adentro',

		palabrasSimilares:
			'Servicio de instalación e integración de estructuras de producción de petróleo y gas en alta mar',
	},
	{
		code: '72121513',
		label: 'Servicio de fabricación modular de plantas de gas y petróleo',
	},
	{
		code: '72121514',
		label:
			'Servicio de fabricación de plataformas y superestructuras  de producción de gas y petróleo',
	},
	{
		code: '72121515',
		label:
			'Servicio de fabricación de cascos para sistema  flotantes de producción de gas y petróleo',
	},
	{
		code: '72121516',
		label:
			'Servicios de fabricación de instalaciones fijas de producción de gas y petróleo mar adentro',
	},
	{
		code: '72121517',
		label: 'Servicio de construcción de plantas de gas natural líquido gnl',
	},
	{
		code: '72141000',
		label: 'Servicios de construcción de autopistas y carreteras',
	},
	{
		code: '72141001',
		label: 'Servicio de construcción de calles y carreteras nuevas',
	},
	{
		code: '72141002',
		label:
			'Servicio de construcción y reparación de barandas de seguridad y señales de calles y carreteras',

		palabrasSimilares:
			'Servicio de construcción y reparación de vallas de contención y señales viales y carreteras',
	},
	{
		code: '72141003',
		label: 'Servicio de mantenimiento de calles y carreteras',
	},
	{
		code: '72141004',
		label: 'Servicio de instalación de reflectores para carreteras',
	},
	{
		code: '72141100',
		label:
			'Servicios de pavimentación y superficies de edificios de infraestructura',
	},
	{
		code: '72141101',
		label: 'Servicio de construcción de pistas aeroportuarias',
	},
	{
		code: '72141102',
		label: 'Servicio de nivelación del terreno',
	},
	{
		code: '72141103',
		label: 'Servicio de pavimentación de calles y carreteras',
	},
	{
		code: '72141104',
		label: 'Servicio de repavimentación de calles y carreteras',
	},
	{
		code: '72141105',
		label: 'Servicio de construcción y reparación de aceras',
	},
	{
		code: '72141106',
		label: 'Servicio de construcción de carreteras de grava o tierra',
	},
	{
		code: '72141107',
		label: 'Servicio de construcción y reparación de puentes',
	},
	{
		code: '72141108',
		label: 'Servicio de construcción y reparación de túneles',
	},
	{
		code: '72141109',
		label: 'Servicio de construcción y reparación de viaductos',
	},
	{
		code: '72141110',
		label: 'Servicio de construcción de ramales para gas y petróleo',

		palabrasSimilares:
			'Servicio de construcción de líneas de distribución de petróleo y gas',
	},
	{
		code: '72141111',
		label: 'Servicio de construcción de tubería maestra de gas',

		palabrasSimilares: 'Servicio de construcción de tubería principal de gas',
	},
	{
		code: '72141112',
		label: 'Servicio de construcción de estaciones compresoras de gas natural',
	},
	{
		code: '72141113',
		label: 'Servicio de construcción de tuberías para gas y petróleo',
	},
	{
		code: '72141114',
		label: 'Servicio de revestimiento de tuberías',
	},
	{
		code: '72141115',
		label: 'Servicio de tendido de cables eléctricos',
	},
	{
		code: '72141116',
		label: 'Servicio de tendido de cables de televisión',
	},
	{
		code: '72141117',
		label: 'Servicio de tendido de cables de comunicación y teléfonos',
	},
	{
		code: '72141118',
		label:
			'Servicio de construcción de torres de transmisión para telecomunicaciones',
	},
	{
		code: '72141119',
		label: 'Servicio de construcción de acueductos',
	},
	{
		code: '72141120',
		label: 'Servicio de construcción de líneas de alcantarillado',

		palabrasSimilares: 'Servicio de construcción de líneas de desagüe',
	},
	{
		code: '72141121',
		label: 'Servicio de construcción de tubería maestra de agua',

		palabrasSimilares: 'Servicio de construcción de tubería principal de agua',
	},
	{
		code: '72141122',
		label: 'Servicio de construcción de líneas de energía eléctrica',
	},
	{
		code: '72141123',
		label: 'Servicio de construcción de bocas de acceso',

		palabrasSimilares: 'Servicio de construcción de coladeras',
	},
	{
		code: '72141124',
		label: 'Servicio de tendido de tubería',
	},
	{
		code: '72141125',
		label: 'Servicio de construcción de estaciones de bombeo',
	},
	{
		code: '72141126',
		label: 'Servicio de construcción de servicios públicos subterráneos',
	},
	{
		code: '72141200',
		label: 'Servicios de construcción marina',

		palabrasSimilares: 'Servicios de construcciones marítimas',
	},
	{
		code: '72141201',
		label: 'Servicio de perforación de compuertas flotantes',

		palabrasSimilares: 'Servicio de perforación de cajón hidráulico',
	},
	{
		code: '72141202',
		label: 'Servicio de construcción de canales',
	},
	{
		code: '72141203',
		label: 'Servicio de construcción de represas',
	},
	{
		code: '72141204',
		label: 'Servicio de construcción de dársenas',

		palabrasSimilares: 'Servicio de construcción de muelles',
	},
	{
		code: '72141205',
		label: 'Servicio de construcción de sistemas de drenaje',
	},
	{
		code: '72141206',
		label: 'Servicio de dragado',

		palabrasSimilares:
			'Servicio de  limpieza de los sedimentos en cursos de agua, lagos, bahías',
	},
	{
		code: '72141207',
		label: 'Servicio de construcción de muelles',

		palabrasSimilares: 'Servicio de construcción portuaria',
	},
	{
		code: '72141208',
		label: 'Servicio de construcción de sistemas de riego',

		palabrasSimilares: 'Servicio de construcción de sistemas de irrigación',
	},
	{
		code: '72141209',
		label: 'Servicio de construcción de diques o malecones',

		palabrasSimilares: 'Servicio de construcción de escolleras',
	},
	{
		code: '72141210',
		label: 'Servicio de construcción de marinas',

		palabrasSimilares: 'Servicio construcción de puerto deportivo',
	},
	{
		code: '72141211',
		label: 'Servicio de construcción de embarcaderos',
	},
	{
		code: '72141212',
		label: 'servicio de construcción de estanques',
	},
	{
		code: '72141213',
		label: 'Servicio de construcción de vías de agua',
	},
	{
		code: '72141214',
		label: 'Servicio de movimiento de pilas de láminas',
	},
	{
		code: '72141215',
		label: 'Servicio de construcción submarina',
	},
	{
		code: '72141216',
		label: 'Servicio de embarcaciones para construcciones en alta mar',
	},
	{
		code: '72141300',
		label: 'Servicio de construcción de facilidades atléticas y recreativas',

		palabrasSimilares:
			'Servicio de construcción de instalaciones atléticas y recreativas',
	},
	{
		code: '72141301',
		label: 'Servicio de construcción de canchas de atletismo',
	},
	{
		code: '72141302',
		label: 'Servicio de construcción de campos de golf',
	},
	{
		code: '72141303',
		label: 'Servicio de construcción de canchas de tenis',
	},
	{
		code: '72141400',
		label: 'Servicios de construcción y reparación de facilidades de detención',

		palabrasSimilares:
			'Servicios de construcción y reparación de instalaciones penitenciarias',
	},
	{
		code: '72141401',
		label: 'Servicio de construcción de instalaciones de detención',

		palabrasSimilares:
			'Servicio de construcción de cárceles, Servicio de construcción de prisiones',
	},
	{
		code: '72141402',
		label: 'Remodelación y reparación de instalaciones de detención',

		palabrasSimilares:
			'Remodelación y reparación de cárceles, Remodelación y reparación de prisiones',
	},
	{
		code: '72141500',
		label: 'Servicios de preparación de tierras',
	},
	{
		code: '72141502',
		label: 'Servicio de recuperación de tierras',
	},
	{
		code: '72141503',
		label: 'Servicio de remoción de piedras',
	},
	{
		code: '72141504',
		label: 'Servicio de remoción de madera',
	},
	{
		code: '72141505',
		label: 'Servicio de movimiento de tierras',
	},
	{
		code: '72141507',
		label: 'Servicio de movimiento de pilotes',
	},
	{
		code: '72141508',
		label: 'Servicio de voladura, excepto para demolición de edificios',
	},
	{
		code: '72141509',
		label: 'Servicio de zanjado',
	},
	{
		code: '72141510',
		label: 'Servicios de demolición',
	},
	{
		code: '72141511',
		label: 'Servicio de excavación',
	},
	{
		code: '72141600',
		label: 'Servicios de construcción de sistemas de tránsito masivo',
	},
	{
		code: '72141601',
		label: 'Servicio de construcción de riel ligero',
	},
	{
		code: '72141602',
		label: 'Servicio de corte para derecho de vía',
	},
	{
		code: '72141603',
		label:
			'Servicio de construcción de lechos de vía para ferrocarril y vías férreas',
	},
	{
		code: '72141604',
		label: 'Servicio de construcción de metro',
	},
	{
		code: '72141605',
		label: 'Servicio de tendido de rieles ferroviarios',
	},
	{
		code: '72141700',
		label:
			'Servicios de alquiler o arrendamiento de equipo y maquinaria de construcción',
	},
	{
		code: '72141701',
		label: 'Servicio de alquiler o leasing de maquinaria para construcción',

		palabrasSimilares: 'Renta de maquinaria de construcción',
	},
	{
		code: '72141702',
		label: 'Servicio de alquiler o leasing de equipo para construcción',

		palabrasSimilares: 'Renta de equipo para construcción',
	},
	{
		code: '72151000',
		label: 'Servicios de construcción y mantenimiento de calderas y hornos',
	},
	{
		code: '72151001',
		label: 'Servicio de mantenimiento de calderas',

		palabrasSimilares:
			'Servicio de mantenimiento de boiler, Servicio de mantenimiento de calentador',
	},
	{
		code: '72151002',
		label: 'Servicio de instalación y montaje de calderas',

		palabrasSimilares:
			'Servicio de instalación y montaje de boilers, Servicio de instalación y montaje de calentadores',
	},
	{
		code: '72151003',
		label: 'Servicio de mantenimiento y reparación de sistemas de calefacción',
	},
	{
		code: '72151004',
		label:
			'Servicio de mantenimiento y reparación de sistemas de calefacción por agua caliente',
	},
	{
		code: '72151005',
		label: 'Instalación de controladores de presión de calderas',

		palabrasSimilares:
			'Instalación de controladores de presión de boilers, Instalación de controladores de presión de calentadores',
	},
	{
		code: '72151006',
		label:
			'Mantenimiento o reparación u operación de  controladores de presión de calderas',

		palabrasSimilares:
			'Mantenimiento o reparación u operación de  controladores de presión de boilers, Mantenimiento o reparación u operación de  controladores de presión de calentadores',
	},
	{
		code: '72151100',
		label: 'Servicios de construcción de plomería',
	},
	{
		code: '72151101',
		label: 'Servicio de construcción de sistemas sépticos',
	},
	{
		code: '72151102',
		label: 'Servicio de instalación de sistemas de rociadores contra incendio',
	},
	{
		code: '72151103',
		label: 'Servicio de instalación de sistemas de rociadores para riego',
	},
	{
		code: '72151200',
		label:
			'Servicios de construcción y mantenimiento de HVAC calefacción y enfriamiento y aire acondicionado',
	},
	{
		code: '72151201',
		label: 'Servicio de construcción mecánica hvac',
	},
	{
		code: '72151202',
		label: 'Servicio de construcción de tubería mediante proceso hvac',
	},
	{
		code: '72151203',
		label: 'Servicio de construcción de energía solar hvac',
	},
	{
		code: '72151204',
		label: 'Servicio de trabajo de ductos y ventilación hvac',
	},
	{
		code: '72151205',
		label: 'Servicio de construcción de refrigeración hvac',
	},
	{
		code: '72151206',
		label: 'Servicio de construcción de sistemas de calefacción hvac',
	},
	{
		code: '72151207',
		label:
			'Servicio de instalación y mantenimiento acondicionamiento del aire, enfriamiento y calefacción hvac',
	},
	{
		code: '72151300',
		label: 'Servicios de pintura e instalación de papel de colgadura',

		palabrasSimilares: 'Servicios de pintura e instalación de papel de tapiz',
	},
	{
		code: '72151301',
		label: 'Servicio de pintura residencial',
	},
	{
		code: '72151302',
		label: 'Servicio de pintura comercial',
	},
	{
		code: '72151303',
		label: 'Servicio de pintura industrial',
	},
	{
		code: '72151304',
		label: 'Servicio de pintura de aeronaves',
	},
	{
		code: '72151305',
		label: 'Servicio de pintura de puentes',
	},
	{
		code: '72151306',
		label: 'Servicio de marcación de pavimento',

		palabrasSimilares: 'Servicio de marcación de asfalto',
	},
	{
		code: '72151307',
		label: 'Servicio de pintura de buques',
	},
	{
		code: '72151308',
		label: 'Servicio de colgadura de papel',

		palabrasSimilares: 'Servicio de tapizado de papel',
	},
	{
		code: '72151400',
		label: 'Servicios de construcción de recubrimientos de muros',
	},
	{
		code: '72151401',
		label: 'Servicio comercial de construcción y revestimiento de paredes',
	},
	{
		code: '72151402',
		label: 'Servicio residencial de construcción y cubrimiento de paredes',
	},
	{
		code: '72151500',
		label: 'Servicios de sistemas eléctricos',

		palabrasSimilares: 'Servicios de instalaciones eléctricas',
	},
	{
		code: '72151501',
		label: 'Servicios de instalación de iluminación',

		palabrasSimilares:
			'Servicio de instalación eléctrica, Servicio de instalación para cableado',
	},
	{
		code: '72151502',
		label: 'Servicio de construcción de sistemas de energía eléctrica',
	},
	{
		code: '72151503',
		label: 'Servicio de instalación de plantas de cogeneración',
	},
	{
		code: '72151504',
		label: 'Servicio de acondicionamiento de energía para computadores',

		palabrasSimilares:
			'Servicio de acondicionamiento de la energía del ordenador',
	},
	{
		code: '72151505',
		label: 'Servicio de instalación de energía de emergencia o de reserva',
	},
	{
		code: '72151506',
		label:
			'Servicio de instalación de mecanismos de control y dispositivos relacionados',

		palabrasSimilares:
			'Servicio de instalación de aparellaje y dispositivos relacionados',
	},
	{
		code: '72151507',
		label: 'Servicio de instalación de controles electrónicos',
	},
	{
		code: '72151508',
		label: 'Servicio de instalación de controles computarizados',
	},
	{
		code: '72151509',
		label:
			'Servicio de instalación de controles de la  administración de la energía',

		palabrasSimilares:
			'Servicio de instalación de controles de administración de energía',
	},
	{
		code: '72151510',
		label: 'Servicio de instalación de controles del sistema ambiental',
	},
	{
		code: '72151511',
		label: 'Servicio de mantenimiento o reparación de sistemas de iluminación',
	},
	{
		code: '72151514',
		label: 'Servicio de mantenimiento de energía de emergencia o de reserva',
	},
	{
		code: '72151515',
		label: 'Servicio de inspección eléctrica',
	},
	{
		code: '72151600',
		label: 'Servicios de sistemas especializados de comunicación',
	},
	{
		code: '72151601',
		label: 'Servicio de instalación de televisión por cable',
	},
	{
		code: '72151602',
		label: 'Servicio de instalación de cables de fibra óptica',
	},
	{
		code: '72151603',
		label: 'Servicio de instalación de equipos de sonido especializados',
	},
	{
		code: '72151604',
		label: 'Servicio de  instalación de teléfonos y equipos para teléfonos',
	},
	{
		code: '72151605',
		label: 'Servicio de cableado para video, datos y voz',
	},
	{
		code: '72151606',
		label: 'Ingeniería subterránea para equipos de comunicaciones',
	},
	{
		code: '72151607',
		label: 'Ingeniería de superficie para equipos de comunicaciones',

		palabrasSimilares: 'Ingeniería de campo para equipos de comunicación',
	},
	{
		code: '72151608',
		label: 'Servicio de mantenimiento o reparación de sistemas satelitales',
	},
	{
		code: '72151609',
		label: 'Servicio de soporte del eje de sistemas satelitales',

		palabrasSimilares:
			'Servicio de soporte del concentrador del sistema satelital',
	},
	{
		code: '72151700',
		label:
			'Servicios de instalación de sistemas de seguridad física e industrial',
	},
	{
		code: '72151701',
		label: 'Servicio de instalación de sistemas de control de acceso',
	},
	{
		code: '72151702',
		label:
			'Servicio de instalación de sistemas de televisión de circuito cerrado',
	},
	{
		code: '72151703',
		label:
			'Servicio de instalación de sistemas de alarmas contra robo y detección de fuego',

		palabrasSimilares:
			'Servicio de instalación de sistemas de detección de incendios y sistemas de alarma antirrobo',
	},
	{
		code: '72151704',
		label:
			'Servicio de instalación y mantenimiento de sistemas instrumentados de seguridad',
	},
	{
		code: '72151800',
		label: 'Servicios de mantenimiento y reparación de instalación de máquinas',
	},
	{
		code: '72151801',
		label: 'Instalación y mantenimiento cajeros automáticos',

		palabrasSimilares: 'Instalación y mantenimiento de máquinas bancarias',
	},
	{
		code: '72151802',
		label: 'Servicio de refacción y reparación de componentes de maquinaria',
	},
	{
		code: '72151803',
		label: 'Servicio de instalación y mantenimiento de máquinas expendedoras',
	},
	{
		code: '72151900',
		label: 'Servicios de albañilería y mampostería',
	},
	{
		code: '72151901',
		label: 'Servicio de cimentación de construcciones',
	},
	{
		code: '72151903',
		label: 'Servicio de colocación de ladrillos',
	},
	{
		code: '72151904',
		label: 'Servicio de construcción y mantenimiento de chimeneas',
	},
	{
		code: '72151905',
		label: 'Servicio de mampostería en bloques de concreto',

		palabrasSimilares: 'Servicio de colocación de bloques de concreto',
	},
	{
		code: '72151906',
		label: 'Servicio de instalación de tubería de drenaje',
	},
	{
		code: '72151907',
		label: 'Servicio de mampostería en mármol en exteriores',

		palabrasSimilares: 'Servicio de colocación de mármol en exteriores',
	},
	{
		code: '72151908',
		label:
			'Servicios de mampostería en ladrillos a prueba de ácido o refractarios',

		palabrasSimilares:
			'Servicio de colocación de ladrillos a prueba de ácido o refractarios',
	},
	{
		code: '72151909',
		label: 'Servicio de mampostería en piedra',

		palabrasSimilares: 'Servicio de colocación de piedra',
	},
	{
		code: '72151910',
		label: 'Servicio de restauración o renovación de juntas con mortero',
	},
	{
		code: '72151911',
		label: 'Servicio de instalación de adoquines unitarios',
	},
	{
		code: '72152000',
		label: 'Servicios de pañetado y drywall',
	},
	{
		code: '72152001',
		label: 'Servicio de instalación y reparación de drywall',

		palabrasSimilares:
			'Servicio de instalación y reparación de paneles de yeso, Servicio de instalación y reparación de tablaroca',
	},
	{
		code: '72152002',
		label: 'Servicio de instalación y reparación de frescos',
	},
	{
		code: '72152003',
		label: 'Servicio de instalación y reparación de repisas para chimenea',
	},
	{
		code: '72152004',
		label: 'Servicio de enyesado ornamental o sencillo',
	},
	{
		code: '72152005',
		label: 'Servicio de instalación y reparación de estuco',
	},
	{
		code: '72152100',
		label: 'Servicios acústicos y de aislamiento',
	},
	{
		code: '72152101',
		label: 'Servicio de trabajos acústicos y de cielorraso',
	},
	{
		code: '72152102',
		label: 'Servicio de aislamiento y acabado de exteriores',
	},
	{
		code: '72152103',
		label: 'Servicio de aislamiento de edificaciones',
	},
	{
		code: '72152104',
		label: 'Servicio de películas de aislamiento de reflexión solar',
	},
	{
		code: '72152200',
		label: 'Servicios de baldosas Terrazo y mármol y mosaicos',
	},
	{
		code: '72152201',
		label: 'Servicio de instalación de mármol en interiores',
	},
	{
		code: '72152202',
		label: 'Servicio creación y reparación de mosaicos',
	},
	{
		code: '72152203',
		label: 'Servicio de instalación y reparación de terrazos',
	},
	{
		code: '72152204',
		label: 'Servicio de instalación de baldosas de cerámica',

		palabrasSimilares: 'Servicio de instalación de losas de cerámica',
	},
	{
		code: '72152300',
		label: 'Servicios de carpintería',
	},
	{
		code: '72152301',
		label: 'Servicio de carpintería rústica',
	},
	{
		code: '72152302',
		label: 'Servicio de ebanistería',
	},
	{
		code: '72152303',
		label: 'Servicio de construcción e instalación de gabinetes',
	},
	{
		code: '72152400',
		label: 'Servicios de montaje e instalación de ventanas y puertas',
	},
	{
		code: '72152401',
		label: 'Servicio de instalación o colocación de puertas de garaje',

		palabrasSimilares:
			'Servicio de instalación o colocación de puertas de cocheras',
	},
	{
		code: '72152402',
		label: 'Servicio de instalación de ventanas y puertas prefabricadas',
	},
	{
		code: '72152403',
		label: 'Servicio de enmarcación de vías de entrada y de salida',
	},
	{
		code: '72152404',
		label: 'Servicio de instalación de clavos metálicos',
	},
	{
		code: '72152405',
		label: 'Servicio de instalación de artefactos fijos en tiendas',
	},
	{
		code: '72152500',
		label: 'Servicios de instalación de pisos',
	},
	{
		code: '72152501',
		label: 'Servicio de instalación de sistemas de pisos de acceso',
	},
	{
		code: '72152502',
		label: 'Servicio de instalación de baldosas de asfalto',

		palabrasSimilares: 'Servicio de instalación de losa de asfalto',
	},
	{
		code: '72152503',
		label: 'Servicio de instalación de tapetes',

		palabrasSimilares: 'Servicio de instalación de alfombras',
	},
	{
		code: '72152504',
		label: 'Servicio de instalación de baldosas de cerámica  para pisos',
	},
	{
		code: '72152505',
		label: 'Servicio de instalación de linóleo',
	},
	{
		code: '72152506',
		label: 'Servicio de instalación de piso de revestimiento elástico',

		palabrasSimilares:
			'Servicio de instalación de suelo con revestimiento elástico',
	},
	{
		code: '72152507',
		label: 'Servicio de instalación de láminas y baldosas de vinilo para pisos',
	},
	{
		code: '72152508',
		label: 'Servicio de instalación y acabados de pisos de madera',
	},
	{
		code: '72152509',
		label: 'Servicio de nivelación de pisos',
	},
	{
		code: '72152600',
		label: 'Servicios de techado y paredes externas y láminas de metal',
	},
	{
		code: '72152601',
		label: 'Servicio de techado',
	},
	{
		code: '72152602',
		label: 'Servicio de tubos de desagüe y bajantes',
	},
	{
		code: '72152603',
		label: 'Servicio de instalación de claraboyas',

		palabrasSimilares: 'Servicio de instalación de tragaluces',
	},
	{
		code: '72152604',
		label: 'Servicio de laminas metálicas arquitectónicas',
	},
	{
		code: '72152605',
		label: 'Servicio de instalación y reparación de cielorrasos',
	},
	{
		code: '72152606',
		label: 'Servicio de instalación y reparación de paredes externas',
	},
	{
		code: '72152607',
		label: 'Servicio de instalación de canaletas de descarga',

		palabrasSimilares: 'Servicio de instalación de tolvas',
	},
	{
		code: '72152700',
		label: 'Servicios de instalación y reparación de concreto',
	},
	{
		code: '72152701',
		label: 'Servicio de estuco de concreto en exteriores',
	},
	{
		code: '72152702',
		label: 'Servicio de lechada',
	},
	{
		code: '72152703',
		label: 'Servicio de instalación de hormigón proyectado',
	},
	{
		code: '72152704',
		label: 'Servicio de construcción de bordes de acera',

		palabrasSimilares: 'Servicio de construcción de banquetas',
	},
	{
		code: '72152705',
		label: 'Servicio de instalación de aceras o rampas',

		palabrasSimilares: 'Servicio de instalación de banquetas o rampas',
	},
	{
		code: '72152706',
		label: 'Servicio de construcción de caminos particulares para vehículos',
	},
	{
		code: '72152707',
		label: 'Servicio de construcción de muros de retención',

		palabrasSimilares: 'Servicio de construcción de muros de contención',
	},
	{
		code: '72152708',
		label: 'Servicio de construcción de parqueaderos',

		palabrasSimilares: 'Servicio de construcción de estacionamientos',
	},
	{
		code: '72152709',
		label: 'Servicio de trasiego de concreto',

		palabrasSimilares: 'Servicio de bombeo de hormigón',
	},
	{
		code: '72152710',
		label: 'Servicio de cimentación y nivelación para construcciones',
	},
	{
		code: '72152711',
		label: 'Servicio de construcción de patios de concreto',
	},
	{
		code: '72152800',
		label: 'Servicios de perforación de pozos de agua',
	},
	{
		code: '72152801',
		label: 'Servicio de perforación de pozos de agua domésticos',
	},
	{
		code: '72152802',
		label: 'Servicio de perforación de pozos de aguas geotermales',
	},
	{
		code: '72152900',
		label: 'Servicios de montaje de acero estructural',
	},
	{
		code: '72152901',
		label: 'Servicio de instalación de frentes metálicos de edificios',
	},
	{
		code: '72152902',
		label: 'Servicio de colocación de refuerzos de concreto',

		palabrasSimilares: 'Servicio de colocación de refuerzo de hormigón',
	},
	{
		code: '72152903',
		label: 'Servicio de instalación de la parte frontal del ascensor',
	},
	{
		code: '72152904',
		label:
			'Servicio de instalación de sistemas de muros metálicos en exteriores',
	},
	{
		code: '72152905',
		label: 'Servicio de trabajos en hierro estructural',
	},
	{
		code: '72152906',
		label: 'Servicio de enrasillado y enlistonado metálicos',
	},
	{
		code: '72152907',
		label:
			'Servicio de colocación de armaduras estructurales para concreto prevaciado',
	},
	{
		code: '72152908',
		label: 'Servicio de instalación de conductos de humo',
	},
	{
		code: '72152909',
		label: 'Servicio de instalación de tanques de almacenamiento metálicos',
	},
	{
		code: '72153000',
		label: 'Servicios de vidrios y ventanería',
	},
	{
		code: '72153001',
		label: 'Servicio de instalación de vidriado a prueba de balas',

		palabrasSimilares: 'Servicio de instalación de vidrio a prueba de balas',
	},
	{
		code: '72153002',
		label: 'Servicio de instalación y reparación de vidrios en ventanas',
	},
	{
		code: '72153100',
		label: 'Servicios de construcción de facilidades atléticas y recreativas',
	},
	{
		code: '72153101',
		label: 'Servicio de instalación de canchas de bolos',
	},
	{
		code: '72153102',
		label: 'Servicio de construcción de canchas deportivas en interiores',
	},
	{
		code: '72153103',
		label:
			'Servicio de construcción de campos de juegos e instalación de equipos',
	},
	{
		code: '72153104',
		label: 'Servicio de construcción e instalación de spas o yacusis',
	},
	{
		code: '72153105',
		label: 'Servicio de construcción de piscinas',
	},
	{
		code: '72153200',
		label:
			'Servicios de recubrimiento, impermeabilización protección contra clima y fuego',
	},
	{
		code: '72153201',
		label: 'Servicio de revestimiento en plástico para estructuras de concreto',
	},
	{
		code: '72153202',
		label: 'Servicio de revestimiento para estructuras metálicas',
	},
	{
		code: '72153203',
		label: 'Servicio de control de corrosión',
	},
	{
		code: '72153204',
		label: 'Servicio de impermeabilización',

		palabrasSimilares: 'Servicio de aplicación de materiales hidrófugos',
	},
	{
		code: '72153205',
		label: 'Servicio de enmasillado',

		palabrasSimilares: 'Servicio de sellado',
	},
	{
		code: '72153206',
		label: 'Servicio de protección ignífuga en edificios',
	},
	{
		code: '72153207',
		label: 'Servicio de alisado de superficies de concreto',
	},
	{
		code: '72153208',
		label: 'Servicio de instalación de tuberías y calderas',

		palabrasSimilares:
			'Servicio de instalación de tuberías y boilers, Servicio de instalación de tuberías y calentadores',
	},
	{
		code: '72153209',
		label: 'Servicio de impermeabilización',
	},
	{
		code: '72153300',
		label:
			'Servicios de mantenimiento e instalación de equipo de estación de servicio',
	},
	{
		code: '72153301',
		label: 'Servicio de instalación de bombas de gasolina',
	},
	{
		code: '72153302',
		label: 'Servicio de instalación de bombas de diesel',
	},
	{
		code: '72153303',
		label:
			'Servicio de mantenimiento o reparación de equipos de estaciones de servicio',
	},
	{
		code: '72153400',
		label: 'Servicios de aparejamiento y andamiaje',
	},
	{
		code: '72153401',
		label: 'Servicios de aparejos',
	},
	{
		code: '72153402',
		label: 'Servicios de andamiaje',
	},
	{
		code: '72153500',
		label: 'Servicios de limpieza estructural externa',
	},
	{
		code: '72153501',
		label: 'Servicio de limpieza de exteriores de edificios',
	},
	{
		code: '72153502',
		label:
			'Servicio de limpieza con chorro de arena en exteriores de edificios',
	},
	{
		code: '72153503',
		label: 'Servicio de limpieza a vapor en exteriores de edificios',
	},
	{
		code: '72153504',
		label: 'Limpieza con chorro de agua de alta presión',
	},
	{
		code: '72153505',
		label: 'Servicio de limpieza del emplazamiento de la obra',

		palabrasSimilares: 'Servicio de limpieza del lugar de la obra',
	},
	{
		code: '72153506',
		label: 'Servicio de limpieza general posconstrucción de edificios nuevos',
	},
	{
		code: '72153507',
		label:
			'Servicio para retiro de materiales en el emplazamiento de la construcción',

		palabrasSimilares:
			'Servicio para retiro de materiales en el lugar de la construcción',
	},
	{
		code: '72153600',
		label: 'Servicios de terminado interior, dotación y remodelación',
	},
	{
		code: '72153601',
		label: 'Servicio de acabado y reparación de tinas',
	},
	{
		code: '72153602',
		label: 'Servicio de instalación y reparación de organizadores de clósets',
	},
	{
		code: '72153603',
		label: 'Servicio de instalación de mostradores',
	},
	{
		code: '72153604',
		label: 'Servicio de instalación de rieles para cortinas',
	},
	{
		code: '72153605',
		label: 'Servicio de remodelación de cocinas y baños',
	},
	{
		code: '72153606',
		label: 'Servicio de instalación de muebles de oficina',
	},
	{
		code: '72153607',
		label: 'Servicio de instalación de azulejos plásticos en la pared',

		palabrasSimilares: 'Servicio de instalación de loseta vinílica',
	},
	{
		code: '72153608',
		label: 'Servicio de instalación de cortinas o persianas',
	},
	{
		code: '72153609',
		label: 'Servicio de instalación de aparatos comerciales y domésticos',
	},
	{
		code: '72153610',
		label: 'Servicio de instalación de chimeneas prefabricadas',
	},
	{
		code: '72153611',
		label: 'Servicio de instalación de gabinetes de cocina',
	},
	{
		code: '72153612',
		label: 'Diseño o decoración de interiores',
	},
	{
		code: '72153613',
		label: 'Servicio de alquiler y mantenimiento de mobiliario para oficina',

		palabrasSimilares:
			'Renta de mobiliario para oficina, Servicio de arrendamiento y mantenimiento de mobiliario para oficina',
	},
	{
		code: '72153700',
		label:
			'Servicios de instalación, mantenimiento y reparación de equipos y construcción de parqueaderos',

		palabrasSimilares:
			'Servicios de instalación, mantenimiento y reparación de equipos y construcción de estacionamientos',
	},
	{
		code: '72153701',
		label:
			'Servicio de instalación de equipos en instalaciones de parqueaderos',

		palabrasSimilares:
			'Servicio de instalación de equipos para estacionamientos',
	},
	{
		code: '72153702',
		label: 'Mantenimiento de parqueaderos',

		palabrasSimilares: 'Servicio de mantenimiento de estacionamientos',
	},
	{
		code: '72153900',
		label: 'Servicio de preparación de obras de construcción',
	},
	{
		code: '72153901',
		label: 'Trabajos de apuntalamiento y socalzado',
	},
	{
		code: '72153902',
		label: 'Montaje y amarre en el sitio para casas móviles',

		palabrasSimilares: 'Instalación y amarre en el sitio para casas móviles',
	},
	{
		code: '72154000',
		label: 'Servicios de edificios especializados y comercios',
	},
	{
		code: '72154001',
		label: 'Servicio de instalación de antenas',
	},
	{
		code: '72154002',
		label: 'Servicio de instalación de césped artificial',

		palabrasSimilares: 'Servicio de instalación de pasto artificial',
	},
	{
		code: '72154003',
		label: 'Servicio de instalación de toldos',

		palabrasSimilares: 'Servicio de instalación de cubiertas de lona',
	},
	{
		code: '72154004',
		label: 'Servicio de movimiento de edificios',

		palabrasSimilares: 'Servicio de transporte de casa móvil',
	},
	{
		code: '72154005',
		label: 'Servicio de empalme de cables',
	},
	{
		code: '72154006',
		label: 'Servicio de perforación con extracción de testigos y corte',
	},
	{
		code: '72154007',
		label: 'Servicio de achique',

		palabrasSimilares: 'Servicio de extracción de agua',
	},
	{
		code: '72154008',
		label: 'Servicio de taladro y aserrado con diamante',

		palabrasSimilares: 'Servicio de perforación y aserrado con diamante',
	},
	{
		code: '72154009',
		label: 'Servicios de instalación de dársenas industriales y sus equipos',

		palabrasSimilares:
			'Servicios de instalación de rampas mecánicas para carga y descarga y sus equipos',
	},
	{
		code: '72154010',
		label: 'Servicio de instalación, mantenimiento y reparación de ascensores',
	},
	{
		code: '72154011',
		label: 'Servicio de aplicación de resina epoxi',

		palabrasSimilares: 'Servicio de aplicación de poliepóxido',
	},
	{
		code: '72154012',
		label: 'Servicio de instalación y desmantelamiento de formas de concreto',

		palabrasSimilares: 'Servicio de montaje y desmontaje de hormigón',
	},
	{
		code: '72154013',
		label: 'Servicio de construcción de cercas',

		palabrasSimilares: 'Servicio de construcción de vallas',
	},
	{
		code: '72154014',
		label: 'Servicio de instalación y mantenimiento de fibra de vidrio',
	},
	{
		code: '72154015',
		label: 'Servicio de instalación de escaleras de emergencia',
	},
	{
		code: '72154016',
		label: 'Servicio de construcción de carrozas (desfile)',
	},
	{
		code: '72154017',
		label: 'Servicio de instalación de equipos para servicio de comida',
	},
	{
		code: '72154018',
		label: 'Servicios de instalación de fuentes',
	},
	{
		code: '72154019',
		label: 'Servicio de detección de fugas de gases',
	},
	{
		code: '72154020',
		label: 'Servicio de instalación de sistemas de gases médicos',
	},
	{
		code: '72154021',
		label: 'Servicio de coloreado de vidrio',

		palabrasSimilares: 'Servicio de tintado de vidrio',
	},
	{
		code: '72154022',
		label: 'Servicio de instalación y mantenimiento de equipos hidráulicos',
	},
	{
		code: '72154023',
		label: 'Servicio de instalación de conductores de rayos',

		palabrasSimilares: 'Servicio de instalación de pararrayos',
	},
	{
		code: '72154024',
		label: 'Servicio de herrajes y trabajos de metalistería ornamentales',

		palabrasSimilares: 'Servicio de trabajo de hierro y metal decorativo',
	},
	{
		code: '72154025',
		label:
			'Servicio de bombeo y drenaje de tanques de almacenamiento de petróleo',
	},
	{
		code: '72154026',
		label: 'Servicio de reabastecimiento de pilas de hidrógeno',
	},
	{
		code: '72154027',
		label: 'Servicio de excavación de agujeros para postes',
	},
	{
		code: '72154028',
		label: 'Servicio de instalación y mantenimiento de avisos',

		palabrasSimilares:
			'Servicio de instalación y mantenimiento de espectaculares',
	},
	{
		code: '72154029',
		label: 'Servicio de instalación y reparación de campanario jack',

		palabrasSimilares:
			'Servicio de instalación y reparación de ornamentos en edificios',
	},
	{
		code: '72154030',
		label: 'Servicio de instalación y reparación campanas de torre',

		palabrasSimilares: 'Servicio de instalación y reparación campanarios',
	},
	{
		code: '72154031',
		label: 'Servicios de soldadura en el sitio',

		palabrasSimilares: 'Servicios de soldadura a domicilio',
	},
	{
		code: '72154032',
		label:
			'Servicio de instalación y reparación de ventanas, puertas y alambrado',
	},
	{
		code: '72154033',
		label:
			'Servicio de construcción, erección y reparación de la utilería o el plató o del decorado teatral',
	},
	{
		code: '72154034',
		label:
			'Servicio de instalación de tanques de almacenamiento subterráneos de petróleo',
	},
	{
		code: '72154035',
		label: 'Servicio de reabastecimiento de combustible de centrales nucleares',
	},
	{
		code: '72154036',
		label:
			'Servicio de instalación de sistemas centralizados de limpieza por aspirado',

		palabrasSimilares: 'Servicio de instalación de aspiración central',
	},
	{
		code: '72154037',
		label: 'Servicio de instalación de cajas fuertes o bóvedas',
	},
	{
		code: '72154038',
		label:
			'Instalación de protección de onda aérea para el servicio de salas de computadores',
	},
	{
		code: '72154039',
		label: 'Servicio de entablado de emergencia de edificaciones',
	},
	{
		code: '72154040',
		label: 'Servicio de instalación de líneas de protección subterráneas',
	},
	{
		code: '72154041',
		label: 'Servicio de instalación de sistemas de determinación de objetivos',
	},
	{
		code: '72154042',
		label: 'Servicio de erección de mástiles de bandera',

		palabrasSimilares: 'Servicio de instalación de mástiles de bandera',
	},
	{
		code: '72154043',
		label: 'Servicio de fumigación industrial y comercial',
	},
	{
		code: '72154044',
		label: 'Servicio de uso o canalización de agua en madera',
	},
	{
		code: '72154045',
		label: 'Servicio de disminución del nivel de aguas de superficie',
	},
	{
		code: '72154046',
		label: 'Servicio de instalación de escaleras eléctricas',
	},
	{
		code: '72154047',
		label: 'Servicio de mesa elevadora',
	},
	{
		code: '72154048',
		label: 'Servicio de balcones y pasillos exteriores',
	},
	{
		code: '72154049',
		label: 'Servicio de protección solar',

		palabrasSimilares: 'Servicio de láminas de protección solar',
	},
	{
		code: '72154050',
		label: 'Servicio de mantenimiento o reparación de carritos de la compra',

		palabrasSimilares:
			'Servicio de mantenimiento o reparación de carritos de autoservicio',
	},
	{
		code: '72154051',
		label: 'Servicio de reubicación de tumbas',

		palabrasSimilares: 'Servicio de reubicación de lapidas',
	},
	{
		code: '72154052',
		label: 'Servicio de renovación  y reparación posdesastre',
	},
	{
		code: '72154053',
		label: 'Servicio de reparación de emisoras',
	},
	{
		code: '72154054',
		label: 'Servicios de carga o eliminación de catalizadores',
	},
	{
		code: '72154055',
		label: 'Servicio de limpieza de tanques',
	},
	{
		code: '72154056',
		label: 'Servicio de mantenimiento o reparación de tanques',
	},
	{
		code: '72154057',
		label: 'Servicio de alquiler de tanques de almacenamiento',

		palabrasSimilares: 'Servicio de renta de tanques de almacenamiento',
	},
	{
		code: '72154058',
		label: 'Servicio de prueba de tanques y líneas',
	},
	{
		code: '72154059',
		label: 'Servicios de detección, sellado y reparación de fugas',
	},
	{
		code: '72154060',
		label: 'Servicio de desgasificación',
	},
	{
		code: '72154061',
		label: 'Servicio de filtración de aire',
	},
	{
		code: '72154062',
		label: 'Servicio de fabricación de roscas en caliente',

		palabrasSimilares: 'Servicio de interconexión en vivo en tuberías',
	},
	{
		code: '72154063',
		label: 'Servicio de cierre de línea o de taponamiento',
	},
	{
		code: '72154064',
		label: 'Servicio de alquiler de equipos portátiles para iluminación',

		palabrasSimilares:
			'Servicio de renta de equipos portátiles para iluminación',
	},
	{
		code: '72154065',
		label: 'Mantenimiento de equipos eléctricos de copiado',
	},
	{
		code: '72154066',
		label: 'Mantenimiento general de equipos de oficina',
	},
	{
		code: '72154100',
		label:
			'Servicios de mantenimiento y reparación de equipo de sistemas de distribución y acondicionamiento',
	},
	{
		code: '72154101',
		label: 'Servicio de alquiler y mantenimiento de compresores de aire',

		palabrasSimilares:
			'Servicio de renta y mantenimiento de compresores de aire',
	},
	{
		code: '72154102',
		label:
			'Servicio de mantenimiento o reparación de intercambiadores de calor',
	},
	{
		code: '72154103',
		label: 'Servicio de mantenimiento o reparación de bombas rotativas',
	},
	{
		code: '72154104',
		label: 'Servicio de mantenimiento y reparación de cajas de cambios',

		palabrasSimilares: 'Servicio de mantenimiento y reparación de reductores',
	},
	{
		code: '72154105',
		label: 'Servicio de mantenimiento y reparación de válvulas de control',
	},
	{
		code: '72154106',
		label: 'Servicio de alquiler y mantenimiento de compresores alternativos',

		palabrasSimilares:
			'Servicio de renta y mantenimiento de compresores alternativos',
	},
	{
		code: '72154107',
		label: 'Servicio de alquiler y mantenimiento de compresores centrífugos',

		palabrasSimilares:
			'Servicio de renta y mantenimiento de compresores centrífugos',
	},
	{
		code: '72154108',
		label: 'Servicio de mantenimiento o reparación de bombas alternativas',
	},
	{
		code: '72154109',
		label: 'Servicio de mantenimiento o reparación de bombas centrífugas',
	},
	{
		code: '72154110',
		label:
			'Servicio de mantenimiento y reparación de torres de refrigeración con ventilador de aletas',

		palabrasSimilares:
			'Servicio de mantenimiento y reparación de torres de enfriamiento',
	},
	{
		code: '72154200',
		label:
			'Servicios de mantenimiento y reparación de instalación de instrumentación',
	},
	{
		code: '72154201',
		label:
			'Servicio de instalación y mantenimiento de instrumentos o medidores',
	},
	{
		code: '72154300',
		label:
			'Servicios de mantenimiento y reparación de equipo motivo y generación de energía eléctrica',
	},
	{
		code: '72154301',
		label: 'Servicio de mantenimiento o reparación de equipos de turbina',
	},
	{
		code: '72154302',
		label: 'Servicio de instalación y mantenimiento de motores',
	},
	{
		code: '72154400',
		label:
			'Servicios de mantenimiento y fabricación de accesorios para tuberías',
	},
	{
		code: '72154401',
		label: 'Servicios de fabricación de accesorios para tuberías',
	},
	{
		code: '72154402',
		label: 'Servicio de mantenimiento o reparación de accesorios para tuberías',
	},
	{
		code: '72154500',
		label: 'Servicios de mantenimiento e instalación de equipo pesado',
	},
	{
		code: '72154501',
		label: 'Servicio de mantenimiento y reparación de equipo pesado',
	},
	{
		code: '72154502',
		label: 'Servicio de mantenimiento y reparación de grúas',
	},
	{
		code: '72154503',
		label: 'Servicio de alquiler de grúas',

		palabrasSimilares: 'Servicio de renta de grúas',
	},
	{
		code: '73101500',
		label: 'Producción petroquímica y de plástico',
	},
	{
		code: '73101501',
		label: 'Servicios de refinación de petróleo',
	},
	{
		code: '73101502',
		label: 'Servicios de producción de gas natural',
	},
	{
		code: '73101503',
		label: 'Servicios de producción de aceites o grasas',
	},
	{
		code: '73101504',
		label: 'Servicios de producción de carbón',
	},
	{
		code: '73101505',
		label: 'Servicios de fabricación de plásticos, resinas o fibras',
	},
	{
		code: '73101600',
		label: 'Producción de químicos y fertilizantes',
	},
	{
		code: '73101601',
		label: 'Servicios de producción de químicos inorgánicos',
	},
	{
		code: '73101602',
		label: 'Servicios de producción de sosa, cloro o soda cáustica',

		palabrasSimilares: 'Servicios de producción de sosa, cloro o sosa cáustica',
	},
	{
		code: '73101603',
		label: 'Servicios de producción de ácidos inorgánicos',
	},
	{
		code: '73101604',
		label: 'Servicios de producción de químicos orgánicos',
	},
	{
		code: '73101605',
		label: 'Servicios de producción de acetileno o derivados',
	},
	{
		code: '73101606',
		label: 'Servicios de producción de etileno o derivados',

		palabrasSimilares: 'Servicios de producción de eteno o derivados',
	},
	{
		code: '73101607',
		label: 'Servicios de producción de etileno o metanol o derivados',

		palabrasSimilares:
			'Servicios de producción de alcohol etílico o alcohol metanol o derivados',
	},
	{
		code: '73101608',
		label: 'Servicios de producción de fertilizantes',
	},
	{
		code: '73101609',
		label: 'Servicios de minería o transformación de potasa',

		palabrasSimilares:
			'Servicios de minería o transformación de carbonato de potasio',
	},
	{
		code: '73101610',
		label: 'Servicios de producción de pesticidas',

		palabrasSimilares: 'Servicios de producción de plaguicida',
	},
	{
		code: '73101611',
		label: 'Servicios de producción de pinturas, barnices o lacas',
	},
	{
		code: '73101612',
		label:
			'Servicios de producción de jabones o preparaciones para limpieza o perfumes o cosméticos',
	},
	{
		code: '73101613',
		label: 'Servicios de producción de disolventes, glicoles o detergentes',

		palabrasSimilares:
			'Servicios de producción de disolventes, etilenglicoles o detergentes',
	},
	{
		code: '73101614',
		label: 'Servicios de fermentos o enzimas',
	},
	{
		code: '73101700',
		label: 'Producción farmacéutica',
	},
	{
		code: '73101701',
		label: 'Servicios de producción de medicamentos o medicinas',
	},
	{
		code: '73101702',
		label: 'Servicios de producción de vacunas, sueros o antibióticos',
	},
	{
		code: '73101703',
		label: 'Servicios de producción de productos farmacéuticos',

		palabrasSimilares: 'Servicios de fabricación de medicamentos homeopáticos',
	},
	{
		code: '73101800',
		label: 'Producción bioquímica y de biotecnología',
	},
	{
		code: '73101801',
		label: 'Servicios de producción de biomasa',
	},
	{
		code: '73101802',
		label: 'Servicios de producción de bioproteínas',
	},
	{
		code: '73101900',
		label: 'Producción de caucho',
	},
	{
		code: '73101901',
		label: 'Servicios de moledura de caucho',

		palabrasSimilares: 'Servicios de fresadora de caucho',
	},
	{
		code: '73101902',
		label: 'Servicios de producción de neumáticos o tubos de caucho',

		palabrasSimilares: 'Servicios de producción de llantas o tubos de caucho',
	},
	{
		code: '73101903',
		label: 'Servicios de producción de calzado de caucho o plástico',

		palabrasSimilares:
			'Servicios de reparación y mantenimiento de calzado de caucho o plástico',
	},
	{
		code: '73111500',
		label: 'Procesamiento de madera',
	},
	{
		code: '73111501',
		label: 'Servicios de aserradero',
	},
	{
		code: '73111502',
		label: 'Servicios de producción de chapas',
	},
	{
		code: '73111503',
		label: 'Servicios de fabricación de paneles de base de madera',
	},
	{
		code: '73111504',
		label: 'Servicios de fabricación de envases de madera',
	},
	{
		code: '73111505',
		label: 'Servicios de fabricación de muebles',

		palabrasSimilares:
			'Reparación de muebles, Servicio de tapicería de muebles',
	},
	{
		code: '73111506',
		label: 'Servicios de fabricación de productos de corcho',
	},
	{
		code: '73111507',
		label: 'Servicios de elaboración de bejuco o mimbre',

		palabrasSimilares: 'Servicios de procesamiento de caña o mimbre',
	},
	{
		code: '73111600',
		label: 'Procesamiento de pulpa y papel',
	},
	{
		code: '73111601',
		label: 'Servicios de producción de pulpa',
	},
	{
		code: '73111602',
		label: 'Servicios de producción de papel o cartón',
	},
	{
		code: '73111603',
		label: 'Servicios de producción de tabla de madera dura o de fibra',

		palabrasSimilares: 'Servicios de producción de paneles duros o de fibra',
	},
	{
		code: '73111604',
		label: 'Servicios de producción o reciclaje de papel',
	},
	{
		code: '73121500',
		label: 'Procesos de fundición y refinación y formado de metales',
	},
	{
		code: '73121501',
		label: 'Servicios de producción de aleaciones ferrosas',
	},
	{
		code: '73121502',
		label: 'Servicios de procesos de combinación de metales básicos',
	},
	{
		code: '73121503',
		label: 'Servicios de refracción para la producción de hierro o acero',
	},
	{
		code: '73121504',
		label: 'Servicios de fabricación de hierro o acero',
	},
	{
		code: '73121505',
		label: 'Servicios de forja de  hierro o acero',
	},
	{
		code: '73121506',
		label: 'Servicios de procesos de preacabado de hierro o acero',
	},
	{
		code: '73121507',
		label: 'Servicios de  acabado en la transformación de metales',
	},
	{
		code: '73121508',
		label: 'Servicios de fundición de metales',
	},
	{
		code: '73121509',
		label: 'Servicios de purificación de metales',

		palabrasSimilares: 'Servicios de refinación de metales',
	},
	{
		code: '73121600',
		label: 'Terminación de metales',
	},
	{
		code: '73121601',
		label: 'Servicios de corte de metales',
	},
	{
		code: '73121602',
		label: 'Servicios de herrería',

		palabrasSimilares: 'Servicio de fragua',
	},
	{
		code: '73121603',
		label: 'Servicios de calentamiento de metales',
	},
	{
		code: '73121606',
		label: 'Servicios de forja de metales',
	},
	{
		code: '73121607',
		label: 'Servicios de extracción de metales',
	},
	{
		code: '73121608',
		label: 'Servicios de extrusión de metales',
	},
	{
		code: '73121610',
		label: 'Servicios de herrería',

		palabrasSimilares: 'Servicios de herraduras',
	},
	{
		code: '73121611',
		label: 'Servicios de hojalatería',
	},
	{
		code: '73121612',
		label: 'Servicios de revestimiento con metal antifricción',
	},
	{
		code: '73121613',
		label: 'Servicios de fundición de metales',
	},
	{
		code: '73121614',
		label: 'Servicios de pruebas no destructivas',
	},
	{
		code: '73121800',
		label: 'Servicios de industria de productos de minerales no metálicos',
	},
	{
		code: '73121801',
		label: 'Servicios de fabricación de cerámica o porcelana o loza de barro',
	},
	{
		code: '73121802',
		label: 'Servicios de fabricación vidrio o productos de vidrio',
	},
	{
		code: '73121803',
		label: 'Servicios de fabricación de productos de arcilla estructural',
	},
	{
		code: '73121804',
		label: 'Servicios de fabricación de cemento o cal o yeso',
	},
	{
		code: '73121805',
		label:
			'Servicios de fabricación de productos de concreto o agregados o piedra',

		palabrasSimilares: 'Servicio de fabricación de ladrillos',
	},
	{
		code: '73121806',
		label: 'Servicios de fabricación de abrasivos',
	},
	{
		code: '73121807',
		label: 'Servicios de fabricación de productos de asbesto',
	},
	{
		code: '73131500',
		label: 'Procesamiento de bebidas',
	},
	{
		code: '73131501',
		label: 'Servicios de destilación o mezcla de licores',
	},
	{
		code: '73131502',
		label: 'Servicios de elaboración del vino',
	},
	{
		code: '73131503',
		label: 'Servicios de elaboración de la cerveza',
	},
	{
		code: '73131504',
		label: 'Servicios de elaboración de bebidas de frutas no alcohólicas',

		palabrasSimilares:
			'Servicios de elaboración de bebidas sin alcohol a base de frutas',
	},
	{
		code: '73131505',
		label: 'Servicios de elaboración de bebidas de agua',
	},
	{
		code: '73131506',
		label: 'Servicios de elaboración de bebidas de infusión',
	},
	{
		code: '73131507',
		label: 'Servicios de elaboración del café',
	},
	{
		code: '73131508',
		label: 'Servicios de elaboración del té',
	},
	{
		code: '73131600',
		label: 'Procesamiento de carnes y aves y peces y mariscos',
	},
	{
		code: '73131601',
		label: 'Servicios de elaboración de productos cárnicos o derivados',
	},
	{
		code: '73131602',
		label: 'Servicios de procesamiento de pescado o productos de pescado',
	},
	{
		code: '73131603',
		label: 'Servicios de procesamiento de aves de corral',
	},
	{
		code: '73131604',
		label: 'Servicios de inspección o higiene de la carne',
	},
	{
		code: '73131605',
		label: 'Servicios de operación o administración de plantas cárnicas',

		palabrasSimilares: 'Servicios de operación o gestión de plantas cárnicas',
	},
	{
		code: '73131606',
		label: 'Servicios de matadero',
	},
	{
		code: '73131607',
		label: 'Servicios de carnicería',
	},
	{
		code: '73131608',
		label: 'Servicios de conservación en cámara frigorífica',
	},
	{
		code: '73131700',
		label: 'Procesamiento de frutas y vegetales',
	},
	{
		code: '73131701',
		label: 'Servicios de limpieza de frutas o verduras',
	},
	{
		code: '73131702',
		label: 'Servicios de rociado de frutas o verduras',
	},
	{
		code: '73131703',
		label: 'Servicios de empaque de frutas o verduras',
	},
	{
		code: '73131800',
		label: 'Procesamiento de lácteos y huevos',
	},
	{
		code: '73131801',
		label: 'Servicios de elaboración de la leche',

		palabrasSimilares: 'Servicios de procesamiento de la leche',
	},
	{
		code: '73131802',
		label: 'Servicios de la elaboración  de huevos',

		palabrasSimilares: 'Servicios de procesamiento de huevos',
	},
	{
		code: '73131803',
		label: 'Servicios de elaboración de quesos',

		palabrasSimilares: 'Servicios de procesamiento de quesos',
	},
	{
		code: '73131804',
		label: 'Servicios de elaboración de mantequilla o crema de leche',
	},
	{
		code: '73131900',
		label: 'Procesamiento de granos y azúcar y aceites y grasas',
	},
	{
		code: '73131902',
		label: 'Servicios de elaboración de productos a base de cereal',

		palabrasSimilares:
			'Servicios de procesamiento de productos a base de cereal',
	},
	{
		code: '73131903',
		label: 'Servicios de elaboración de azúcar o productos de azúcar',

		palabrasSimilares:
			'Servicios de procesamiento de azúcar o productos de azúcar',
	},
	{
		code: '73131904',
		label: 'Servicios de elaboración de aceites o grasas vegetales',

		palabrasSimilares:
			'Servicios de procesamiento de aceites o grasas vegetales',
	},
	{
		code: '73131905',
		label: 'Servicios de elaboración de especias',

		palabrasSimilares: 'Servicios de procesamiento de especias',
	},
	{
		code: '73131906',
		label: 'Servicios de elaboración de productos de panadería',

		palabrasSimilares: 'Servicios de procesamiento de productos de panadería',
	},
	{
		code: '73141500',
		label: 'Producción de fibra',
	},
	{
		code: '73141501',
		label: 'Servicios de fabricación de fibra de rayón o acetato',
	},
	{
		code: '73141502',
		label: 'Servicios de fabricación de fibra de vidrio',
	},
	{
		code: '73141503',
		label: 'Servicios de fabricación de fibra de seda',
	},
	{
		code: '73141504',
		label: 'Servicios de fabricación de fibra de algodón',
	},
	{
		code: '73141505',
		label: 'Servicios de fabricación de fibra de lana',
	},
	{
		code: '73141506',
		label: 'Servicios de fabricación de fibra de poliéster',
	},
	{
		code: '73141507',
		label: 'Servicios de fabricación de fibra de poliamida',
	},
	{
		code: '73141508',
		label: 'Servicios de fabricación de fibra acrílica',
	},
	{
		code: '73141600',
		label: 'Procesamiento de hebras e hilos',
	},
	{
		code: '73141601',
		label: 'Servicios de elaboración de hilo',

		palabrasSimilares: 'Servicios de procesamiento de hilo',
	},
	{
		code: '73141602',
		label: 'Servicios de elaboración de hilado',

		palabrasSimilares: 'Servicios de procesamiento de hilado',
	},
	{
		code: '73141700',
		label: 'Producción de telas y cuero',
	},
	{
		code: '73141701',
		label: 'Servicios de fabricación de géneros de tejido ancho',

		palabrasSimilares: 'Servicios de fabricación de telas de tejido ancho',
	},
	{
		code: '73141702',
		label: 'Servicios de fabricación de géneros de tejido angosto',

		palabrasSimilares: 'Servicios de fabricación de telas de tejido angosto',
	},
	{
		code: '73141703',
		label: 'Servicios de fabricación de tejidos de punto',
	},
	{
		code: '73141704',
		label: 'Servicios de fabricación de tapetes o alfombras',
	},
	{
		code: '73141705',
		label: 'Servicios de fabricación de sogas, cuerdas o cordeles',
	},
	{
		code: '73141706',
		label: 'Servicios de teñido o impresión y acabado',
	},
	{
		code: '73141707',
		label: 'Servicios de fabricación de   trajes o chaquetas o abrigos tejidos',
	},
	{
		code: '73141708',
		label: 'Servicios de fabricación de prendas exteriores tejidas',
	},
	{
		code: '73141709',
		label: 'Servicios de curtido o teñido de pieles',
	},
	{
		code: '73141710',
		label: 'Servicios de fabricación de calzado de cuero',

		palabrasSimilares:
			'Servicios de reparación y mantenimiento de calzado de cuero',
	},
	{
		code: '73141711',
		label: 'Servicios de fabricación de maletas o bolsos de cuero',

		palabrasSimilares:
			'Servicios de reparación y mantenimiento de maletas o bolsos de cuero',
	},
	{
		code: '73141712',
		label: 'Servicios de fabricación de curtidos o acabados de cuero',
	},
	{
		code: '73141713',
		label: 'Servicios de géneros telas no tejidas',

		palabrasSimilares: 'Servicios de telas no tejidas',
	},
	{
		code: '73141714',
		label: 'Servicios de hilo o tejido trenzado',
	},
	{
		code: '73141715',
		label: 'Servicios de costura industrial',
	},
	{
		code: '73151500',
		label: 'Servicios de ensamble',

		palabrasSimilares: 'Servicios de maquila',
	},
	{
		code: '73151501',
		label: 'Servicios de cadena de montaje',

		palabrasSimilares: 'Servicios de línea de montaje',
	},
	{
		code: '73151502',
		label: 'Servicios de sellamiento de uniones',
	},
	{
		code: '73151503',
		label: 'Servicio de fabricación y diseños originales',
	},
	{
		code: '73151504',
		label: 'Servicio de fabricación de productos electrónicos',
	},
	{
		code: '73151505',
		label: 'Servicio de entrega en secuencia',
	},
	{
		code: '73151506',
		label: 'Servicio de subensamble o ensamble definitivo',
	},
	{
		code: '73151600',
		label: 'Servicios de empaque',
	},
	{
		code: '73151601',
		label: 'Servicios de plantas de conservas',
	},
	{
		code: '73151602',
		label: 'Servicios de empacado de subproductos agrícolas',
	},
	{
		code: '73151603',
		label: 'Servicios de empacado de productos no alimenticios',
	},
	{
		code: '73151604',
		label: 'Servicios de empacado de productos farmacéuticos',
	},
	{
		code: '73151605',
		label: 'Servicios de empacado para exhibición en el punto de venta',
	},
	{
		code: '73151606',
		label: 'Servicios de empacado a mano',
	},
	{
		code: '73151607',
		label: 'Servicios de empacado mecanizado',

		palabrasSimilares: 'Servicios de embalaje asistido por máquina',
	},
	{
		code: '73151608',
		label: 'Servicio de empaque en estuche',
	},
	{
		code: '73151700',
		label: 'Tratamiento de materiales',
	},
	{
		code: '73151701',
		label: 'Servicios de tratamiento de materiales  de impermeabilización',
	},
	{
		code: '73151702',
		label:
			'Servicios de tratamiento de materiales de protección contra incendios',
	},
	{
		code: '73151703',
		label: 'Servicios de tratamiento de materiales  anticorrosivos',
	},
	{
		code: '73151704',
		label: 'Servicio de anodización del aluminio',
	},
	{
		code: '73151705',
		label: 'Servicio de acabado para el tratamiento de materiales anodizados',
	},
	{
		code: '73151800',
		label: 'Servicios de conversión',
	},
	{
		code: '73151801',
		label: 'Servicios de enchapes',

		palabrasSimilares: 'Servicios de laminado',
	},
	{
		code: '73151802',
		label: 'Servicios de corte en tiras',
	},
	{
		code: '73151803',
		label: 'Servicios de corte a troquel',
	},
	{
		code: '73151804',
		label: 'Servicios de plegado',
	},
	{
		code: '73151805',
		label: 'Servicios de laminación',
	},
	{
		code: '73151900',
		label: 'Servicios a la industria de impresión',
	},
	{
		code: '73151901',
		label: 'Servicios de impresión industrial flexográfica',

		palabrasSimilares:
			'Servicios de impresión industrial con placa flexible con relieve',
	},
	{
		code: '73151902',
		label: 'Servicios de impresión industrial a roto grabado',
	},
	{
		code: '73151903',
		label: 'Servicios de impresión industrial  a pantalla',
	},
	{
		code: '73151904',
		label: 'Servicios de impresión industrial offset',
	},
	{
		code: '73151905',
		label: 'Servicios de impresión industrial digital',
	},
	{
		code: '73151906',
		label: 'Servicios de impresión industrial de transferencia térmica',
	},
	{
		code: '73151907',
		label: 'Servicios de impresión y duplicación de discos compactos dc',
	},
	{
		code: '73152000',
		label: 'Servicios de llenado',
	},
	{
		code: '73152001',
		label: 'Servicios de llenado con líquido',

		palabrasSimilares: 'Servicios de llenado de líquido',
	},
	{
		code: '73152002',
		label: 'Servicios de llenado con aerosol',

		palabrasSimilares: 'Servicios de llenado de aerosol',
	},
	{
		code: '73152003',
		label: 'Servicios de llenado con pasta',

		palabrasSimilares: 'Servicios de llenado de pasta',
	},
	{
		code: '73152004',
		label: 'Servicios de llenado con  polvo',

		palabrasSimilares: 'Servicios de llenado de polvo',
	},
	{
		code: '73152100',
		label: 'Servicios de mantenimiento y reparación de equipo de manufactura',
	},
	{
		code: '73152101',
		label: 'Servicio de mantenimiento de equipo industrial',
	},
	{
		code: '73152102',
		label: 'Servicio de reparación de equipo industrial',
	},
	{
		code: '73152103',
		label: 'Servicios de mantenimiento de equipos de ingeniería',
	},
	{
		code: '73152104',
		label: 'Servicio de mantenimiento y reparación de equipos para empacar',

		palabrasSimilares:
			'Servicio de mantenimiento y reparación de equipos de embalaje',
	},
	{
		code: '73152105',
		label: 'Refacción de equipos de pruebas',

		palabrasSimilares: 'Restauración de equipos de pruebas',
	},
	{
		code: '73152106',
		label:
			'Servicio de mantenimiento y reparación de equipos para calentar y hornear',
	},
	{
		code: '73152107',
		label: 'Servicio de reconstrucción y refacción de equipos maquinados',

		palabrasSimilares:
			'Servicio de reconstrucción y restauración de equipos maquinados',
	},
	{
		code: '73152108',
		label: 'Servicio de mantenimiento y reparación de equipos eléctricos',
	},
	{
		code: '73152109',
		label: 'Servicios de mantenimiento y alquiler de básculas industriales',

		palabrasSimilares:
			'Servicios de mantenimiento y renta de básculas industriales',
	},
	{
		code: '73152112',
		label:
			'Servicio de prueba, mantenimiento o reparación de válvulas o partes de válvulas',
	},
	{
		code: '73161500',
		label: 'Manufactura de maquinaria',

		palabrasSimilares: 'Maquila de maquinaria',
	},
	{
		code: '73161501',
		label: 'Servicios de fabricación de motores o  turbinas',
	},
	{
		code: '73161502',
		label: 'Servicios de fabricación de maquinaria o equipos agrícolas',
	},
	{
		code: '73161503',
		label:
			'Servicios de fabricación de máquinas herramienta, labrado de metales o madera',
	},
	{
		code: '73161504',
		label:
			'Servicios de fabricación de maquinaria o plantas industriales especiales',
	},
	{
		code: '73161505',
		label: 'Servicios de fabricación de maquinaria o equipos para construcción',
	},
	{
		code: '73161506',
		label: 'Servicios de fabricación de maquinaria o equipos para minería',
	},
	{
		code: '73161507',
		label:
			'Servicios de fabricación de maquinaria o equipo para productos alimenticios',
	},
	{
		code: '73161508',
		label:
			'Servicios de fabricación de maquinaria o equipos para impresión de papel',
	},
	{
		code: '73161509',
		label: 'Servicios de fabricación de maquinaria o equipos metalúrgicos',
	},
	{
		code: '73161510',
		label:
			'Servicios de fabricación de maquinaria o equipos para productos químicos o farmacéuticos',
	},
	{
		code: '73161511',
		label:
			'Servicios de fabricación de maquinaria o equipo para fábricas de cemento',

		palabrasSimilares:
			'Servicios de fabricación de maquinaria o equipo para plantas de cemento',
	},
	{
		code: '73161512',
		label: 'Servicios de fabricación de maquinaria o equipo textil',
	},
	{
		code: '73161513',
		label: 'Servicios de fabricación de calderas para centrales eléctricas',

		palabrasSimilares:
			'Servicios de fabricación de boilers para centrales eléctricas',
	},
	{
		code: '73161514',
		label: 'Servicios de fabricación de hornos',
	},
	{
		code: '73161515',
		label: 'Servicios de fabricación de maquinaria o equipo de oficina',
	},
	{
		code: '73161516',
		label:
			'Servicios de fabricación de equipo de elevación, elevación o transporte',
	},
	{
		code: '73161517',
		label:
			'Servicios de fabricación de equipos de aire acondicionado, ventilación o refrigeración',
	},
	{
		code: '73161518',
		label:
			'Servicios de fabricación de electrodomésticos o máquinas de uso doméstico excepto eléctricas',
	},
	{
		code: '73161519',
		label: 'Servicios de fabricación de bombas o compresores',
	},
	{
		code: '73161600',
		label: 'Manufactura de equipo de transporte',

		palabrasSimilares: 'Maquila de equipo de transporte',
	},
	{
		code: '73161601',
		label: 'Servicios de construcción de barcos o botes de pesca',
	},
	{
		code: '73161602',
		label: 'Servicios de fabricación de material rodante para ferrocarriles',

		palabrasSimilares:
			'Servicios de fabricación de material rodante ferroviario',
	},
	{
		code: '73161603',
		label: 'Servicios de fabricación de locomotoras',
	},
	{
		code: '73161604',
		label: 'Servicios de fabricación de vehículos de motor',
	},
	{
		code: '73161605',
		label:
			'Servicios de fabricación de partes y accesorios de vehículos de motor',
	},
	{
		code: '73161606',
		label: 'Servicios de fabricación de motocicletas o bicicletas',
	},
	{
		code: '73161607',
		label: 'Servicios de fabricación de aeronaves o naves espaciales',
	},
	{
		code: '73171500',
		label: 'Manufactura de bienes eléctricos',

		palabrasSimilares: 'Maquila de bienes eléctricos',
	},
	{
		code: '73171501',
		label:
			'Servicios de fabricación de equipo de generación, transmisión o distribución de energía',
	},
	{
		code: '73171502',
		label: 'Servicios de fabricación de pilas secas o acumuladores',

		palabrasSimilares:
			'Servicios de fabricación de baterías secas o acumuladores',
	},
	{
		code: '73171503',
		label: 'Servicios de fabricación de herramientas eléctricas',
	},
	{
		code: '73171504',
		label:
			'Servicios de fabricación de instrumentos de pruebas o de mediciones',
	},
	{
		code: '73171505',
		label: 'Servicios de fabricación de aparatos de radio o televisión',
	},
	{
		code: '73171506',
		label: 'Servicios de fabricación de equipos de comunicaciones',
	},
	{
		code: '73171507',
		label: 'Servicios de fabricación de aparatos electrodomésticos',
	},
	{
		code: '73171508',
		label: 'Servicios de fabricación de alambre o cable aislado',
	},
	{
		code: '73171510',
		label: 'Servicios de fabricación de accesorios o material eléctricos',
	},
	{
		code: '73171511',
		label: 'Servicios de fabricación de equipos electrónicos',
	},
	{
		code: '73171512',
		label:
			'Servicios de fabricación de computadores electrónicos o equipos de procesamiento de datos',
	},
	{
		code: '73171513',
		label: 'Servicios epitaxiales',
	},
	{
		code: '73171600',
		label: 'Manufactura de instrumentos de precisión',

		palabrasSimilares: 'Maquila de instrumentos de precisión',
	},
	{
		code: '73171601',
		label:
			'Servicios de fabricación de instrumentos científicos o equipos de medición',
	},
	{
		code: '73171602',
		label: 'Servicios de fabricación de equipo médico o dental',
	},
	{
		code: '73171603',
		label: 'Servicios de fabricación de equipo óptico o fotográfico',
	},
	{
		code: '73171604',
		label: 'Servicios de fabricación de relojes o relojes de pulsera',
	},
	{
		code: '73171605',
		label: 'Servicios de fabricación de equipos de laboratorio',
	},
	{
		code: '73171606',
		label: 'Servicios de actualización de equipos de pruebas',
	},
	{
		code: '73181000',
		label: 'Servicios de maquinado',
	},
	{
		code: '73181001',
		label: 'Servicios de tornería',

		palabrasSimilares: 'Servicios de torneado',
	},
	{
		code: '73181002',
		label: 'Servicios de maquinado por electrodescarga edm',
	},
	{
		code: '73181003',
		label: 'Servicios de maquinado electroquímico ecm',
	},
	{
		code: '73181004',
		label: 'Servicios de fresado químico',
	},
	{
		code: '73181005',
		label: 'Servicios de troquelado',
	},
	{
		code: '73181006',
		label: 'Servicios de estampación',
	},
	{
		code: '73181007',
		label: 'Servicios de taladrado',
	},
	{
		code: '73181008',
		label: 'Servicios de perforación',
	},
	{
		code: '73181009',
		label: 'Servicios de tarraja con macho',
	},
	{
		code: '73181010',
		label: 'Servicios de láser',
	},
	{
		code: '73181011',
		label: 'Servicios de doblado',
	},
	{
		code: '73181012',
		label: 'Servicios de fresado',

		palabrasSimilares: 'Servicios de molienda',
	},
	{
		code: '73181013',
		label: 'Servicios de limpieza con chorro de perdigones',

		palabrasSimilares: 'Servicios de limpieza con agua a presión',
	},
	{
		code: '73181014',
		label: 'Servicios de pulida',

		palabrasSimilares: 'Servicio de encerado, Servicio de lustrado',
	},
	{
		code: '73181015',
		label: 'Servicios de corte con llama de gas',

		palabrasSimilares: 'Servicio de oxicorte',
	},
	{
		code: '73181016',
		label: 'Servicios de corte con láser',
	},
	{
		code: '73181017',
		label: 'Servicios de corte con plasma',
	},
	{
		code: '73181018',
		label: 'Servicios de corte con chorro de agua',

		palabrasSimilares: 'Servicios de corte con presión de agua',
	},
	{
		code: '73181019',
		label: 'Servicios de rodamiento',

		palabrasSimilares: 'Servicios de rolado',
	},
	{
		code: '73181020',
		label: 'Servicios de tratamiento de superficies',
	},
	{
		code: '73181021',
		label: 'Servicios de fresado',
	},
	{
		code: '73181022',
		label: 'Servicios de  pulverización',

		palabrasSimilares: 'Servicios de pulverización de metales',
	},
	{
		code: '73181023',
		label: 'Servicio de afilado',
	},
	{
		code: '73181100',
		label: 'Servicios de revestimiento',
	},
	{
		code: '73181101',
		label: 'Servicios de electro revestimiento',
	},
	{
		code: '73181102',
		label: 'Servicios de bañado',

		palabrasSimilares: 'Servicios de pintado por inmersión de agua',
	},
	{
		code: '73181103',
		label: 'Servicios de envoltura',
	},
	{
		code: '73181104',
		label: 'Servicios de pintura',
	},
	{
		code: '73181105',
		label: 'Servicios de recubrimiento rollo a rollo (web)',
	},
	{
		code: '73181106',
		label: 'Servicios de enchapado',

		palabrasSimilares: 'Servicios de enchapado de metales',
	},
	{
		code: '73181107',
		label: 'Servicio de enchapado con barril de aleación de cinc',
	},
	{
		code: '73181108',
		label: 'Servicio de enchapado con estaño',
	},
	{
		code: '73181109',
		label: 'Servicio de niquelado',
	},
	{
		code: '73181110',
		label: 'Servicio de niquelado químico',
	},
	{
		code: '73181111',
		label: 'Servicio de enchapado con metales preciosos',
	},
	{
		code: '73181112',
		label: 'Servicio de recubrimiento con teflón',
	},
	{
		code: '73181113',
		label: 'Servicio de recubrimiento orgánico con aluminio de cinc',
	},
	{
		code: '73181114',
		label: 'Servicio de electro recubrimiento con barril',
	},
	{
		code: '73181115',
		label: 'Servicio de cromoniquelado',
	},
	{
		code: '73181116',
		label: 'Servicio de cromado denso delgado',
	},
	{
		code: '73181117',
		label: 'Servicio de recubrimiento con soplete',
	},
	{
		code: '73181118',
		label: 'Servicio de electrorecubrimiento en  estantería',

		palabrasSimilares: 'Servicio de recubrimiento eléctrico',
	},
	{
		code: '73181119',
		label: 'Servicio de recubrimiento con pintura en polvo',
	},
	{
		code: '73181120',
		label: 'Servicio de enchapado en barril de cinc',

		palabrasSimilares: 'Servicio de galvanizado',
	},
	{
		code: '73181121',
		label: 'Servicio de enchapado en estantería con aleación de cinc',

		palabrasSimilares: 'Servicio de galvanoplastia de aleación de zinc',
	},
	{
		code: '73181122',
		label: 'Servicio de recubrimiento en estantería de cinc con barniz',
	},
	{
		code: '73181123',
		label: 'Servicio de recubrimiento en barril de cinc con barniz',
	},
	{
		code: '73181124',
		label: 'Servicio de recubrimiento de fosfato',
	},
	{
		code: '73181125',
		label: 'Servicio de recubrimiento tipo diamante',
	},
	{
		code: '73181200',
		label: 'Servicios de formado',
	},
	{
		code: '73181201',
		label: 'Servicios de  formación por extracción',

		palabrasSimilares: 'Servicios de conformado en frío',
	},
	{
		code: '73181202',
		label: 'Servicios de hidro conformado',
	},
	{
		code: '73181203',
		label: 'Servicios de conformación por enrollado',

		palabrasSimilares: 'Servicios de maquina de perfiladora',
	},
	{
		code: '73181204',
		label: 'Servicios de conformación  por estirado',
	},
	{
		code: '73181205',
		label: 'Servicios de conformación porgiro',
	},
	{
		code: '73181206',
		label: 'Servicios de formación con explosivos',

		palabrasSimilares: 'Servicios de hidroconformado por explosión',
	},
	{
		code: '73181300',
		label: 'Servicios de tratamiento de calor',
	},
	{
		code: '73181301',
		label: 'servicios de temple y enfriamiento',
	},
	{
		code: '73181302',
		label: 'Servicios de recocido',
	},
	{
		code: '73181303',
		label: 'Servicios de normalización',
	},
	{
		code: '73181304',
		label: 'Servicios de  envejecimiento o estabilización',
	},
	{
		code: '73181305',
		label: 'Servicio de tratamiento térmico de latón',
	},
	{
		code: '73181306',
		label: 'Servicio de tratamiento térmico de temple y enfriamiento',
	},
	{
		code: '73181307',
		label:
			'Servicio de tratamiento térmico de endurecimiento por precipitación',
	},
	{
		code: '73181308',
		label: 'Servicio de tratamiento térmico de carbonitruro',
	},
	{
		code: '73181309',
		label: 'Servicio de tratamiento térmico de nitruro',
	},
	{
		code: '73181310',
		label: 'Servicio de tratamiento térmico de temple y enfriamiento al vacío',
	},
	{
		code: '73181311',
		label: 'Servicio de tratamiento térmico de recocido al vacío',
	},
	{
		code: '73181312',
		label: 'Servicio de tratamiento térmico carburante',
	},
	{
		code: '73181313',
		label: 'Servicio de tratamiento térmico de inducción',
	},
	{
		code: '73181314',
		label: 'Servicio de tratamiento térmico austempering',
	},
	{
		code: '73181900',
		label: 'Servicios de soldadura',
	},
	{
		code: '73181901',
		label: 'Servicios de soldadura de arco',

		palabrasSimilares: 'Servicios de soldadura de electrógena',
	},
	{
		code: '73181902',
		label: 'Servicios de soldadura con gas metal inerte mig',

		palabrasSimilares: 'Servicios de soldadura a gas y arco metálico',
	},
	{
		code: '73181903',
		label: 'Servicios de soldadura con tungsteno a gas inerte (tig)',

		palabrasSimilares: 'Servicios de soldadura de gas inerte tungsteno',
	},
	{
		code: '73181904',
		label: 'Servicios de soldadura con láser',
	},
	{
		code: '73181905',
		label: 'Servicios de soldadura  por puntos',
	},
	{
		code: '73181906',
		label: 'Servicios de soldadura con proyección',
	},
	{
		code: '73181907',
		label: 'Servicios de soldadura fuerte con latón',
	},
	{
		code: '73181908',
		label: 'Servicios de soldadura',
	},
	{
		code: '76101500',
		label: 'Desinfección',
	},
	{
		code: '76101501',
		label: 'Servicios de higienización de lavabos',

		palabrasSimilares: 'Servicios de sanitización de baños',
	},
	{
		code: '76101502',
		label: 'Servicios de limpieza de baños',
	},
	{
		code: '76101503',
		label: 'Servicios de desinfección o desodorización',

		palabrasSimilares: 'Servicios de esterilización o desodorización',
	},
	{
		code: '76101600',
		label: 'Descontaminación de materiales peligrosos',
	},
	{
		code: '76101601',
		label: 'Servicios de descontaminación radiactiva',
	},
	{
		code: '76101602',
		label: 'Encapsulación  o eliminación de asbesto',

		palabrasSimilares: 'Encapsulación  o eliminación de amianto',
	},
	{
		code: '76101603',
		label: 'Encapsulación o reducción/eliminación de pintura con plomo',
	},
	{
		code: '76101604',
		label: 'Servicio de reducción/eliminación de moho',
	},
	{
		code: '76101605',
		label: 'Servicio de filtración y descontaminación a base de carbono',
	},
	{
		code: '76101606',
		label: 'Servicio de limpieza de equipos a base de químicos',
	},
	{
		code: '76111500',
		label:
			'Servicios de limpieza y mantenimiento de edificios generales y de oficinas',
	},
	{
		code: '76111501',
		label: 'Servicios de limpieza de edificios',

		palabrasSimilares: 'Servicios de aseo de edificios',
	},
	{
		code: '76111503',
		label: 'Servicios de mantenimiento del alumbrado',

		palabrasSimilares: 'Servicios de mantenimiento de iluminación',
	},
	{
		code: '76111504',
		label: 'Servicios de limpieza de ventanas o persianas',
	},
	{
		code: '76111505',
		label: 'Servicios de limpieza de telas y muebles',

		palabrasSimilares: 'Servicios de lavado de telas y muebles',
	},
	{
		code: '76111506',
		label: 'Servicios de paisajismo de plantas interiores',
	},
	{
		code: '76111600',
		label: 'Servicios de limpieza de componentes de edificios',
	},
	{
		code: '76111601',
		label: 'Servicios de limpieza de baldosas o cielorraso acústicos',

		palabrasSimilares: 'Servicios de limpieza de azulejos o techos falsos',
	},
	{
		code: '76111602',
		label: 'Limpieza de conductos de aire',

		palabrasSimilares: 'Lavado de conductos de aire',
	},
	{
		code: '76111603',
		label: 'Limpieza de chimeneas',

		palabrasSimilares: 'Lavado de chimeneas',
	},
	{
		code: '76111604',
		label: 'Encerado de pisos y limpieza de tapetes',

		palabrasSimilares: 'Pulido de pisos y limpieza de alfombras',
	},
	{
		code: '76111605',
		label: 'Limpieza de campanas o ventiladores extractores de humo',
	},
	{
		code: '76111800',
		label: 'Limpieza de vehículos de transporte',
	},
	{
		code: '76111801',
		label: 'Limpieza de carros o barcos',

		palabrasSimilares: 'Lavado de carros o barcos, Servicio de autolavado',
	},
	{
		code: '76121500',
		label: 'Recolección y disposición de basuras',
	},
	{
		code: '76121501',
		label:
			'Recolección o destrucción o transformación o eliminación de basuras',
	},
	{
		code: '76121502',
		label: 'Recolección o transformación o eliminación de residuos líquidos',
	},
	{
		code: '76121503',
		label: 'Servicios de limpieza de calles',

		palabrasSimilares: 'Servicios de barrido de calles',
	},
	{
		code: '76121600',
		label: 'Disposición de desechos no peligrosos',
	},
	{
		code: '76121601',
		label: 'Vertedero de basuras',

		palabrasSimilares: 'Basurero',
	},
	{
		code: '76121602',
		label: 'Operaciones de relleno sanitario',
	},
	{
		code: '76121603',
		label: 'Eliminación de fangos cloacales',
	},
	{
		code: '76121604',
		label: 'Servicios de disposición de animales muertos',

		palabrasSimilares: 'Servicios de eliminación de animales muertos',
	},
	{
		code: '76121700',
		label: 'Tratamiento de desechos líquidos',
	},
	{
		code: '76121701',
		label: 'Servicios de tratamiento de aguas negras',

		palabrasSimilares: 'Servicios de tratamiento de aguas residuales',
	},
	{
		code: '76121702',
		label: 'Servicios de tratamiento químico',
	},
	{
		code: '76121900',
		label: 'Disposición de desechos peligrosos',
	},
	{
		code: '76121901',
		label: 'Eliminación de residuos médicos',
	},
	{
		code: '76121902',
		label: 'Recolección o eliminación de residuos ácidos',
	},
	{
		code: '76121903',
		label: 'Destoxificación química',

		palabrasSimilares: 'Desintoxicación química',
	},
	{
		code: '76121904',
		label: 'Eliminación aguas residuales peligrosas',
	},
	{
		code: '76122000',
		label: 'Servicios de basurero',
	},
	{
		code: '76122001',
		label: 'Relleno sanitario para residuos genéricos no peligrosos',
	},
	{
		code: '76122002',
		label: 'Relleno sanitario para residuos especiales no peligrosos',
	},
	{
		code: '76122003',
		label: 'Relleno sanitario para residuos peligrosos',
	},
	{
		code: '76122004',
		label:
			'Relleno sanitario para residuos universales o peligrosos de bajo riesgo',
	},
	{
		code: '76122100',
		label: 'Servicios de mezcla de desechos a combustible',
	},
	{
		code: '76122101',
		label: 'Mezcla de combustibles para residuos genéricos no peligrosos',
	},
	{
		code: '76122102',
		label: 'Mezcla de combustible para residuos especiales no peligrosos',
	},
	{
		code: '76122103',
		label: 'Mezcla de combustibles para residuos peligrosos',
	},
	{
		code: '76122200',
		label: 'Servicios de incineración de desechos',
	},
	{
		code: '76122201',
		label: 'Incineración para residuos genéricos no peligrosos',
	},
	{
		code: '76122202',
		label: 'Incineración para residuos especiales no peligrosos',
	},
	{
		code: '76122203',
		label: 'Incineración para residuos peligrosos',
	},
	{
		code: '76122300',
		label: 'Servicios de reciclables',
	},
	{
		code: '76122301',
		label: 'Reciclaje de disolventes',

		palabrasSimilares: 'Reciclaje de solventes',
	},
	{
		code: '76122302',
		label: 'Reciclaje de aceite usado',
	},
	{
		code: '76122303',
		label: 'Reciclaje de trapos para limpiar',
	},
	{
		code: '76122304',
		label: 'Reciclaje de residuos peligrosos',
	},
	{
		code: '76122305',
		label: 'Reciclaje de productos basados en computadores',
	},
	{
		code: '76122306',
		label: 'Reciclaje de papel',
	},
	{
		code: '76122307',
		label: 'Reciclaje de cartón',
	},
	{
		code: '76122308',
		label: 'Reciclaje de madera',
	},
	{
		code: '76122309',
		label: 'Reciclaje de plástico',
	},
	{
		code: '76122310',
		label: 'Reciclaje de metal',
	},
	{
		code: '76122311',
		label: 'Reciclaje de vidrio',
	},
	{
		code: '76122312',
		label: 'Reciclaje de icopor',

		palabrasSimilares:
			'Reciclaje de espuma de poliestireno EPS, Reciclaje de poliestireno expandido EPS, Reciclaje de unice',
	},
	{
		code: '76122313',
		label: 'Reciclaje de vinilo',
	},
	{
		code: '76122314',
		label: 'Reciclaje de lámparas fluorescentes',
	},
	{
		code: '76122315',
		label: 'Reciclaje de balastros para lámparas',
	},
	{
		code: '76122400',
		label: 'Honorarios de disposición y tratamiento de basuras',

		palabrasSimilares: 'Servicios de disposición y tratamiento de basuras',
	},
	{
		code: '76122401',
		label: 'Tarifa de sobreestadía',

		palabrasSimilares: 'Costo por demora, Tarifa por demora',
	},
	{
		code: '76122402',
		label: 'Tarifa de uso de equipos',

		palabrasSimilares: 'Costo de uso de equipos',
	},
	{
		code: '76122403',
		label: 'Tarifa de recuperación de combustible',

		palabrasSimilares: 'Costo de recuperación de combustible',
	},
	{
		code: '76122404',
		label: 'Tarifa de residuos peligrosos',

		palabrasSimilares: 'Costo de residuos peligrosos',
	},
	{
		code: '76122405',
		label: 'Tarifa de mano de obra',

		palabrasSimilares: 'Costo de mano de obra',
	},
	{
		code: '76122406',
		label: 'Tarifa de equipo de protección personal',

		palabrasSimilares: 'Costo de equipo de protección personal',
	},
	{
		code: '76122407',
		label: 'Tarifa de transporte de basura',

		palabrasSimilares: 'Costo de transporte de basura',
	},
	{
		code: '76122408',
		label: 'Tarifa estatal, departamental o local de residuos o reciclaje',

		palabrasSimilares:
			'Costo estatal, departamental o local de residuos o reciclaje',
	},
	{
		code: '76131500',
		label: 'Tratamiento de basura nuclear',
	},
	{
		code: '76131501',
		label: 'tratamiento de desechos radioactivos',

		palabrasSimilares: 'Tratamiento de residuos radiactivos',
	},
	{
		code: '76131502',
		label: 'Servicios de confinación de material  radioactivo',
	},
	{
		code: '76131600',
		label: 'Limpieza de derrames tóxicos',
	},
	{
		code: '76131601',
		label: 'Confinación de vertidos tóxicos',
	},
	{
		code: '76131602',
		label: 'Limpieza total de derramamiento de sustancias tóxicas',
	},
	{
		code: '76131700',
		label: 'Limpieza de derrames de petróleo',
	},
	{
		code: '76131701',
		label: 'Servicios de eliminación o control de residuos petroleros',
	},
	{
		code: '76131702',
		label: 'Servicios de tratamiento de derramamiento  de petróleo',
	},
	{
		code: '77101500',
		label: 'Evaluación de impacto ambiental',
	},
	{
		code: '77101501',
		label: 'Evaluación riesgos o peligros',
	},
	{
		code: '77101502',
		label: 'Estándares ambientales',
	},
	{
		code: '77101503',
		label: 'Análisis de indicadores ambientales',
	},
	{
		code: '77101504',
		label: 'Servicios de evaluación de impacto ambiental (eia)',
	},
	{
		code: '77101505',
		label: 'Monitoreo ambiental',

		palabrasSimilares: 'Vigilancia ambiental',
	},
	{
		code: '77101600',
		label: 'Planeación ambiental',
	},
	{
		code: '77101601',
		label: 'Planificación del desarrollo ambiental urbano',
	},
	{
		code: '77101602',
		label: 'Planificación de la estrategia de conservación forestal',
	},
	{
		code: '77101603',
		label: 'Planificación de la estrategia de conservación marítima',

		palabrasSimilares: 'Planificación de la estrategia de conservación marina',
	},
	{
		code: '77101604',
		label:
			'Servicios de planificación de la estrategia de gestión o conservación de recursos naturales',
	},
	{
		code: '77101605',
		label: 'Creación o planificación de instituciones ambientales',
	},
	{
		code: '77101700',
		label: 'Servicios de asesoría ambiental',

		palabrasSimilares: 'Servicios de consultoría ambiental',
	},
	{
		code: '77101701',
		label: 'Servicios de asesoramiento sobre ciencias ambientales',

		palabrasSimilares: 'Servicios de consultoría sobre ciencias ambientales',
	},
	{
		code: '77101702',
		label: 'Servicios de asesoramiento sobre química ambiental',

		palabrasSimilares: 'Servicios de consultoría sobre química ambiental',
	},
	{
		code: '77101703',
		label: 'Servicios de asesoramiento sobre ética ambiental',

		palabrasSimilares: 'Servicios de consultoría sobre ética ambiental',
	},
	{
		code: '77101704',
		label: 'Servicios de asesoramiento sobre tecnología ambiental',

		palabrasSimilares: 'Servicios de consultoría sobre tecnología ambiental',
	},
	{
		code: '77101705',
		label: 'Servicios de  asesoramiento sobre economía ambiental',

		palabrasSimilares: 'Servicios de consultoría sobre economía ambiental',
	},
	{
		code: '77101706',
		label: 'Servicios de asesoramiento sobre derecho ambiental',

		palabrasSimilares: 'Servicios de consultoría sobre derecho ambiental',
	},
	{
		code: '77101707',
		label: 'Servicios de asesoramiento sobre ecología',

		palabrasSimilares: 'Servicios de consultoría sobre ecología',
	},
	{
		code: '77101800',
		label: 'Auditoría ambiental',
	},
	{
		code: '77101801',
		label: 'Sistemas de información ambiental',
	},
	{
		code: '77101802',
		label: 'Servicios de auditoria ambiental de empresas',
	},
	{
		code: '77101803',
		label: 'Servicios de auditoria ambiental sectoriales',
	},
	{
		code: '77101804',
		label: 'Servicios de auditoria ambiental de actividades específicas',
	},
	{
		code: '77101805',
		label: 'Servicios de control de la calidad ambiental',
	},
	{
		code: '77101806',
		label: 'Servicios de control de seguridad ambiental',
	},
	{
		code: '77101900',
		label: 'Servicios de investigación de contaminación',
	},
	{
		code: '77101901',
		label: 'Estudio de emplazamientos industriales',

		palabrasSimilares: 'Investigación de emplazamientos industriales',
	},
	{
		code: '77101902',
		label: 'Estudio de vertederos de desechos industriales',

		palabrasSimilares: 'Investigación de vertederos de desechos industriales',
	},
	{
		code: '77101903',
		label: 'Estudio de emplazamientos de fábricas de gas',

		palabrasSimilares: 'Investigación de emplazamientos de fábricas de gas',
	},
	{
		code: '77101904',
		label:
			'Estudios de emplazamientos  de vertederos de desechos de fábricas de productos químicos o refinerías de petróleo',

		palabrasSimilares:
			'Investigación de emplazamientos  de vertederos de desechos de fábricas de productos químicos o refinerías de petróleo',
	},
	{
		code: '77101905',
		label: 'Estudio de emplazamientos de plantas de tratamiento  de la madera',

		palabrasSimilares:
			'Investigación de emplazamientos de plantas de tratamiento  de la madera',
	},
	{
		code: '77101906',
		label: 'Estudios de emplazamientos de depósitos o terminales de petroleros',

		palabrasSimilares:
			'Investigación de emplazamientos de depósitos o terminales de petroleros',
	},
	{
		code: '77101907',
		label: 'Estudio de emplazamientos de plantas de limpieza en seco',

		palabrasSimilares:
			'Investigación de emplazamientos de plantas de limpieza en seco',
	},
	{
		code: '77101908',
		label: 'Estudio de emplazamientos de fundición',

		palabrasSimilares: 'Investigación de emplazamientos de fundición',
	},
	{
		code: '77101909',
		label: 'Estudios de emplazamientos de planta de reciclado',

		palabrasSimilares: 'Investigación de emplazamientos de planta de reciclado',
	},
	{
		code: '77101910',
		label: 'Estudio de plantas de transformación de alimentos en el sitio',

		palabrasSimilares:
			'Investigación de plantas de transformación de alimentos en el sitio',
	},
	{
		code: '77102000',
		label: 'Servicios de reporte ambiental',
	},
	{
		code: '77102001',
		label: 'Servicio de certificación de cumplimiento de requisitos legales',
	},
	{
		code: '77102002',
		label:
			'Servicio de cumplimiento de presentación de informes sobre emisiones',
	},
	{
		code: '77102003',
		label:
			'Servicio de presentación de informes sobre accidentes o de cumplimiento de normas de seguridad',
	},
	{
		code: '77102004',
		label:
			'Servicio de presentación de informes sobre generación o eliminación de residuos',
	},
	{
		code: '77111500',
		label: 'Servicios de seguridad ambiental',
	},
	{
		code: '77111501',
		label: 'Servicios de protección del paisaje',
	},
	{
		code: '77111502',
		label: 'Servicios de protección de la capa de ozono',
	},
	{
		code: '77111503',
		label:
			'Servicios de protección contra la contaminación de alimentos o pienso',

		palabrasSimilares:
			'Servicios de protección contra la contaminación de alimentos o comida',
	},
	{
		code: '77111504',
		label: 'Servicios de protección recursos genéticos',
	},
	{
		code: '77111505',
		label: 'Servicios de protección de sustancias tóxicas',
	},
	{
		code: '77111506',
		label: 'Servicios de protección contra la radiación',
	},
	{
		code: '77111507',
		label: 'Servicios de protección de las especies en peligro de extinción',
	},
	{
		code: '77111508',
		label: 'Servicios de protección contra riesgos o peligros naturales',
	},
	{
		code: '77111600',
		label: 'Rehabilitación ambiental',
	},
	{
		code: '77111601',
		label: 'Rehabilitación en emplazamientos industriales',

		palabrasSimilares: 'Restauración en emplazamientos industriales',
	},
	{
		code: '77111602',
		label: 'Servicios de descontaminación ambiental',
	},
	{
		code: '77111603',
		label: 'Servicios de recuperación de tierras',

		palabrasSimilares: 'Servicios de restitución de tierras',
	},
	{
		code: '77121500',
		label: 'Contaminación del aire',
	},
	{
		code: '77121501',
		label: 'Gestión de la calidad del aire',
	},
	{
		code: '77121502',
		label:
			'Servicios transfronterizos de gestión o control de la contaminación del aire',
	},
	{
		code: '77121503',
		label: 'Servicios de protección contra la contaminación del aire',

		palabrasSimilares: 'Servicios de centros de verificación vehicular',
	},
	{
		code: '77121504',
		label: 'Servicios de monitoreo o medición de la contaminación del aire',

		palabrasSimilares:
			'Servicios de vigilancia o medición de la contaminación del aire',
	},
	{
		code: '77121505',
		label: 'Servicios de detección de gases tóxicos',
	},
	{
		code: '77121506',
		label: 'Control del metano',
	},
	{
		code: '77121507',
		label: 'Servicios de monitoreo del dióxido de carbono',

		palabrasSimilares: 'Servicios de monitoreo del bióxido de carbono',
	},
	{
		code: '77121508',
		label: 'Monitoreo de partículas en el aire',
	},
	{
		code: '77121509',
		label: 'Servicios de monitoreo de la reducción de la capa de ozono',

		palabrasSimilares:
			'Servicios de vigilancia de la reducción de la capa de ozono',
	},
	{
		code: '77121600',
		label: 'Contaminación del suelo',
	},
	{
		code: '77121601',
		label: 'Servicios de protección contra la contaminación del suelo',
	},
	{
		code: '77121602',
		label: 'Servicios de eliminación de suelos contaminados',

		palabrasSimilares: 'Servicios de eliminación de tierras contaminadas',
	},
	{
		code: '77121603',
		label: 'Tratamiento o rehabilitación de suelos contaminados',

		palabrasSimilares: 'Tratamiento o restauración de suelos contaminados',
	},
	{
		code: '77121604',
		label: 'Servicios de asesorías sobre la contaminación del suelo',

		palabrasSimilares:
			'Servicios de consultoría sobre la contaminación del suelo',
	},
	{
		code: '77121605',
		label: 'Cartografía de la contaminación del suelo',
	},
	{
		code: '77121606',
		label: 'Medición o monitoreo de la contaminación del suelo',
	},
	{
		code: '77121607',
		label: 'Evaluación de la contaminación por fertilizantes orgánicos',
	},
	{
		code: '77121608',
		label: 'Evaluación de la contaminación por pesticidas',
	},
	{
		code: '77121609',
		label: 'Evaluación de la contaminación por nitratos',
	},
	{
		code: '77121610',
		label: 'Evaluación de la contaminación por fosfatos',
	},
	{
		code: '77121700',
		label: 'Contaminación del agua',
	},
	{
		code: '77121701',
		label:
			'Servicio de monitoreo o control de la contaminación de las aguas de superficie',

		palabrasSimilares:
			'Servicio de vigilancia o control de la contaminación de las aguas de superficie',
	},
	{
		code: '77121702',
		label: 'Servicios de rehabilitación de las aguas de superficie',

		palabrasSimilares:
			'Servicios de rehabilitación de aguas superficiales contaminadas',
	},
	{
		code: '77121703',
		label:
			'Servicios de protección contra la polución de las aguas de superficie',

		palabrasSimilares:
			'Servicios de protección de las aguas de superficiales contra la contaminación',
	},
	{
		code: '77121704',
		label: 'Servicios de tratamiento de las aguas de superficie',
	},
	{
		code: '77121705',
		label:
			'Servicios de drenaje de la contaminación de las aguas de superficie',
	},
	{
		code: '77121706',
		label:
			'Servicios transfronterizos de gestión o control de la contaminación de las agua',
	},
	{
		code: '77121707',
		label:
			'Servicios de monitoreo o control de la contaminación de las aguas subterráneas',

		palabrasSimilares:
			'Servicios de vigilancia o control de la contaminación de las aguas subterráneas',
	},
	{
		code: '77121708',
		label: 'Servicios de drenaje de la contaminación de las aguas subterráneas',
	},
	{
		code: '77121709',
		label:
			'Tratamiento o rehabilitación de la contaminación de las aguas subterráneas',

		palabrasSimilares:
			'Tratamiento o restauración de la contaminación de las aguas subterráneas',
	},
	{
		code: '77131500',
		label: 'Contaminación de petróleo',
	},
	{
		code: '77131501',
		label: 'Servicios de monitoreo del derrame de petróleo',

		palabrasSimilares: 'Servicios de vigilancia del derrame de petróleo',
	},
	{
		code: '77131502',
		label: 'Servicios de control del derrame de petróleo',
	},
	{
		code: '77131503',
		label: 'Servicios de rehabilitación por el derrame de petróleo',

		palabrasSimilares: 'Servicios de restauración por el derrame de petróleo',
	},
	{
		code: '77131600',
		label: 'Contaminación auditiva',
	},
	{
		code: '77131601',
		label: 'Servicios de control del ruido',
	},
	{
		code: '77131602',
		label: 'Servicios de protección contra la contaminación acústica',
	},
	{
		code: '77131603',
		label: 'Servicios de monitoreo de la contaminación acústica',

		palabrasSimilares: 'Servicios de vigilancia de la contaminación acústica',
	},
	{
		code: '77131604',
		label: 'Servicios de asesoría sobre la contaminación acústica',

		palabrasSimilares:
			'Servicios de consultoría sobre la contaminación acústica',
	},
	{
		code: '77131700',
		label: 'Contaminación de sustancias tóxicas',
	},
	{
		code: '77131701',
		label: 'Servicios de monitoreo de sustancias tóxicas',

		palabrasSimilares: 'Servicios de vigilancia de sustancias tóxicas',
	},
	{
		code: '77131702',
		label: 'Servicios de rehabilitación por sustancias tóxicas',

		palabrasSimilares: 'Servicios de restauración por sustancias tóxicas',
	},
	{
		code: '78101500',
		label: 'Transporte de carga aérea',
	},
	{
		code: '78101501',
		label: 'Transporte nacional aéreo de carga',
	},
	{
		code: '78101502',
		label: 'Transporte internacional aéreo de carga',
	},
	{
		code: '78101503',
		label: 'Transporte aéreo blindado',
	},
	{
		code: '78101600',
		label: 'Transporte de carga por ferrocarril',
	},
	{
		code: '78101601',
		label: 'Servicios de transporte en furgones',

		palabrasSimilares: 'Servicios de transporte en vagones',
	},
	{
		code: '78101602',
		label: 'Servicios de transporte ferroviario de carga a granel',

		palabrasSimilares:
			'Servicios de transporte ferroviario de mercancía a granel',
	},
	{
		code: '78101603',
		label: 'Transporte de ganado por ferrocarril',

		palabrasSimilares: 'Transporte ferroviario de ganado',
	},
	{
		code: '78101604',
		label: 'Vehículos de servicios de transporte',

		palabrasSimilares: 'Servicios de transporte de vehículos por ferrocarril',
	},
	{
		code: '78101605',
		label: 'Servicio de alquiler de carrotanques de ferrocarril',

		palabrasSimilares: 'Servicio de renta de carrotanques de ferrocarril',
	},
	{
		code: '78101606',
		label: 'Servicio de alquiler de furgones de ferrocarril o carros de carga',

		palabrasSimilares:
			'Servicio de renta de vagones de ferrocarril o carros de carga',
	},
	{
		code: '78101700',
		label: 'Transporte de carga por mar',
	},
	{
		code: '78101701',
		label: 'Servicios de transporte nacional por buque',

		palabrasSimilares: 'Servicios de transporte nacional por embarcación',
	},
	{
		code: '78101702',
		label: 'Servicios de transporte internacional por buque',

		palabrasSimilares: 'Servicios de transporte internacional por embarcación',
	},
	{
		code: '78101703',
		label: 'Servicios de transporte nacional por barcazas',
	},
	{
		code: '78101704',
		label: 'Servicios de transporte internacional por barcazas',
	},
	{
		code: '78101705',
		label: 'Transporte marítimo blindado',
	},
	{
		code: '78101706',
		label:
			'Servicio de embarcaciones para suministros a plataformas de gas y petróleo mar adentro',

		palabrasSimilares:
			'Servicio de buques para suministros a plataformas de gas y petróleo en alta mar',
	},
	{
		code: '78101800',
		label: 'Transporte de carga por carretera',
	},
	{
		code: '78101801',
		label:
			'Servicios de transporte de carga por carretera (en camión) en área local',

		palabrasSimilares: 'Servicios de transporte de carga local',
	},
	{
		code: '78101802',
		label:
			'Servicios transporte de carga por carretera (en camión) a nivel regional y nacional',

		palabrasSimilares: 'Servicios de transporte de carga foráneo',
	},
	{
		code: '78101803',
		label: 'Servicios de transporte de vehículos',

		palabrasSimilares: 'Servicios de transporte de vehículos por carretera',
	},
	{
		code: '78101804',
		label: 'Servicios de reubicación',

		palabrasSimilares: 'Servicios de fletes y mudanzas',
	},
	{
		code: '78101805',
		label: 'Servicio de alquiler de remolques o camiones cisterna',

		palabrasSimilares: 'Servicio de renta de remolques o camiones cisterna',
	},
	{
		code: '78101806',
		label: 'Servicios transporte de carga por carretera a nivel internacional',
	},
	{
		code: '78101807',
		label:
			'Servicios de transporte de carga de petróleo o químicos  por carretera',
	},
	{
		code: '78101900',
		label: 'Transporte de carga intermodal',
	},
	{
		code: '78101901',
		label: 'Transporte aéreo a marítimo',

		palabrasSimilares: 'Transporte con salida aérea y destino marítimo',
	},
	{
		code: '78101902',
		label: 'Transporte marítimo a ferroviario',

		palabrasSimilares: 'Transporte con salida marítima y destino ferroviario',
	},
	{
		code: '78101903',
		label: 'Transporte marino a carretera (por camión)',

		palabrasSimilares:
			'Transporte con salida marítima y destino a carretera (por camión)',
	},
	{
		code: '78101904',
		label: 'Transporte aéreo a carretera (por camión)',

		palabrasSimilares:
			'Transporte con salida aérea y destino a carretera (por camión)',
	},
	{
		code: '78101905',
		label: 'Transporte por vagones de ferrocarril',
	},
	{
		code: '78102000',
		label: 'Transporte de carga en naves espaciales',
	},
	{
		code: '78102001',
		label: 'Servicios de lanzamiento de satélites',
	},
	{
		code: '78102002',
		label: 'Servicios de carga explosiva experimental',
	},
	{
		code: '78102100',
		label: 'Servicios de oleoductos',
	},
	{
		code: '78102101',
		label: 'Transporte de productos derivados del petróleo',

		palabrasSimilares: 'Transporte de productos petrolíferos',
	},
	{
		code: '78102102',
		label: 'Transporte de agua',
	},
	{
		code: '78102103',
		label: 'Servicio de inspección en línea  de tuberías',
	},
	{
		code: '78102200',
		label: 'Servicios postales de paqueteo y courrier',
	},
	{
		code: '78102201',
		label: 'Servicios de entrega postal nacional',
	},
	{
		code: '78102202',
		label: 'Servicios de apartado postal',

		palabrasSimilares: 'Servicios de apartado de correos',
	},
	{
		code: '78102203',
		label: 'Servicios de envío, recogida o entrega de correo',
	},
	{
		code: '78102204',
		label: 'Servicios de entrega a nivel mundial de cartas o paquetes pequeños',

		palabrasSimilares: 'Servicios de mensajería y paquetería a nivel mundial',
	},
	{
		code: '78102205',
		label: 'Servicios de entrega local de cartas o paquetes pequeños',

		palabrasSimilares:
			'Servicios de mensajería y paquetería en territorio nacional',
	},
	{
		code: '78102206',
		label: 'Servicios de mensajería en bicicleta o motocicleta',
	},
	{
		code: '78111500',
		label: 'Transporte de pasajeros aérea',
	},
	{
		code: '78111501',
		label: 'Servicios de helicópteros',
	},
	{
		code: '78111502',
		label: 'Viajes en aviones comerciales',
	},
	{
		code: '78111503',
		label: 'Viajes en aviones fletados',

		palabrasSimilares: 'Viajes en aviones alquilados',
	},
	{
		code: '78111505',
		label: 'Servicio de alquiler o arrendamiento de aeronaves de pasajeros',

		fechaInicioVigencia: '23-12-2022',
		fechaFinVigencia: '',
		estimuloFranjaFronteriza: '01',
		palabrasSimilares:
			'Servicio de alquiler de aeronaves de carga, arrendamiento de aeronaves de carga, leasing de aeronaves de carga',
	},
	{
		code: '78111600',
		label: 'Transporte de pasajeros por ferrocarril',
	},
	{
		code: '78111601',
		label: 'Servicios de transporte en vehículos de tren ligero lrv',

		palabrasSimilares: 'Servicios de transporte de tren ligero',
	},
	{
		code: '78111602',
		label: 'Transporte en metro',
	},
	{
		code: '78111603',
		label: 'Servicios ferroviarios continentales o intercontinentales',
	},
	{
		code: '78111700',
		label: 'Transporte de pasajeros por mar',
	},
	{
		code: '78111701',
		label: 'Taxis acuáticos',
	},
	{
		code: '78111702',
		label: 'Cruceros nocturnos',

		palabrasSimilares: 'Cruceros por barco durante la noche',
	},
	{
		code: '78111703',
		label: 'Excursiones de recorrido turístico en barco',

		palabrasSimilares: 'Paseos en barco',
	},
	{
		code: '78111704',
		label: 'Servicio de alquiler o leasing de embarcaciones marinas',

		palabrasSimilares:
			'Servicio de alquiler o arrendamiento de embarcaciones marítimas',
	},
	{
		code: '78111705',
		label:
			'Servicio de transporte del personal de plataformas de gas y petróleo en alta mar',
	},
	{
		code: '78111802',
		label: 'Servicios de buses con horarios programados',

		palabrasSimilares: 'Servicios de autobuses programados',
	},
	{
		code: '78111803',
		label: 'Servicios de buses contratados',

		palabrasSimilares: 'Servicios de alquiler de autobuses',
	},
	{
		code: '78111804',
		label: 'Servicios de taxi',
	},
	{
		code: '78111807',
		label: 'Tarifas del parqueadero',

		palabrasSimilares: 'Tarifas de parquímetros',
	},
	{
		code: '78111808',
		label: 'Alquiler de vehículos',

		palabrasSimilares: 'Renta de vehículos',
	},
	{
		code: '78111809',
		label: 'Leasing de vehículos sedán, cupé o camioneta',
	},
	{
		code: '78111810',
		label: 'Servicio de limosinas o town cars',
	},
	{
		code: '78111811',
		label:
			'Alquiler de vehículos de camión ligero y vehículos de utilidad deportiva',
	},
	{
		code: '78111812',
		label: 'Leasing de vehículos  de van o minivans para pasajeros',
	},
	{
		code: '78111900',
		label: 'Transporte espacial',
	},
	{
		code: '78111901',
		label: 'Misiones experimentales o educativas',

		palabrasSimilares: 'Transporte para misiones experimentales o educativas',
	},
	{
		code: '78121500',
		label: 'Empaque',
	},
	{
		code: '78121501',
		label: 'Contenedorización de mercancías',

		palabrasSimilares:
			'Transporte de mercancías en cantidades menores de embalaje',
	},
	{
		code: '78121502',
		label: 'Servicios de embalaje',

		palabrasSimilares: 'Servicios de empaque',
	},
	{
		code: '78121600',
		label: 'Servicios de manejo de materiales',
	},
	{
		code: '78121601',
		label: 'Carga y descarga de mercancías',
	},
	{
		code: '78121602',
		label: 'Servicios de pesaje',
	},
	{
		code: '78121603',
		label: 'Tarifa de los fletes',

		palabrasSimilares: 'Servicio de fletes de carga',
	},
	{
		code: '78121604',
		label: 'Servicio de arriendo o leasing de montacargas',

		palabrasSimilares: 'Servicio de renta de montacargas',
	},
	{
		code: '78131500',
		label: 'Bodegaje de productos agrícolas',
	},
	{
		code: '78131501',
		label: 'Servicios de silos',

		palabrasSimilares: 'Servicio de almacenes para grano',
	},
	{
		code: '78131502',
		label: 'Servicios de elevadores de granos',
	},
	{
		code: '78131600',
		label: 'Almacenamiento de bienes generales',
	},
	{
		code: '78131601',
		label: 'Almacenaje de mercancías embandejadas',

		palabrasSimilares: 'Almacenaje de mercancías en palets',
	},
	{
		code: '78131602',
		label: 'Almacenaje de archivos de carpetas',
	},
	{
		code: '78131603',
		label: 'Almacenaje de muebles',
	},
	{
		code: '78131700',
		label: 'Almacenamiento a granel',
	},
	{
		code: '78131701',
		label: 'Servicios de almacenaje en tierra',
	},
	{
		code: '78131702',
		label: 'Servicio de almacenaje sobre la superficie o en depósitos',
	},
	{
		code: '78131800',
		label: 'Bodegaje y almacenamiento especializado',
	},
	{
		code: '78131801',
		label: 'Almacenaje refrigerado',
	},
	{
		code: '78131802',
		label: 'Servicios de almacenaje bajo control aduanero',
	},
	{
		code: '78131803',
		label: 'Almacenaje de materiales peligrosos',
	},
	{
		code: '78131804',
		label: 'Servicios de almacenaje de documentos',
	},
	{
		code: '78131805',
		label: 'Almacenaje de cajeros automáticos',
	},
	{
		code: '78131806',
		label: 'Servicio de autoalmacenaje o minialmacenaje',

		palabrasSimilares: 'Servicio de mini bodegas',
	},
	{
		code: '78141500',
		label: 'Servicios de organización de transportes',
	},
	{
		code: '78141501',
		label: 'Servicios de expedidores de fletes',

		palabrasSimilares: 'Servicios de agentes de carga',
	},
	{
		code: '78141502',
		label: 'Servicios de agentes aduaneros',
	},
	{
		code: '78141503',
		label:
			'Servicios de comparación de tarifas de la industria del transporte o de auditoria de fletes',
	},
	{
		code: '78141504',
		label: 'Refacturación y fletes manejados por proveedores o vendedores',
	},
	{
		code: '78141600',
		label: 'Inspección',
	},
	{
		code: '78141601',
		label: 'Servicios de inspección del empaquetado',

		palabrasSimilares: 'Servicios de inspección de embalajes',
	},
	{
		code: '78141602',
		label: 'Servicios de vigilancia de la carga',
	},
	{
		code: '78141603',
		label: 'Inspecciones de control de plagas',

		palabrasSimilares: 'Inspecciones de control de pestes',
	},
	{
		code: '78141700',
		label: 'Servicios a la navegación',
	},
	{
		code: '78141701',
		label: 'Servicios de remolcadores',
	},
	{
		code: '78141702',
		label: 'Operaciones de puentes levadizos',
	},
	{
		code: '78141703',
		label: 'Servicios de comunicaciones o de navegación marítima',
	},
	{
		code: '78141704',
		label: 'Servicio de anclaje (deadman)',

		palabrasSimilares: 'Servicio de anclaje de peso muerto',
	},
	{
		code: '78141800',
		label: 'Servicios al terminal',
	},
	{
		code: '78141801',
		label: 'Servicios de estiba',
	},
	{
		code: '78141802',
		label: 'Servicios de atraque de buques',

		palabrasSimilares:
			'Servicios de anclaje de buques en puerto, Servicios de anclaje de embarcaciones en puerto',
	},
	{
		code: '78141803',
		label: 'Servicios de tiendas en buques',
	},
	{
		code: '78141804',
		label: 'Gestión de instalaciones de terminales de carga',
	},
	{
		code: '78141805',
		label:
			'Servicio de explotación de instalaciones de aviación o aeropuertos o aeródromos',
	},
	{
		code: '78141806',
		label: 'Servicios de apoyo en la costa para gas y petróleo',
	},
	{
		code: '78141900',
		label: 'Servicios de alquiler de contenedores de transporte',
	},
	{
		code: '78141901',
		label: 'Servicios de alquiler de canastas de almacenamiento',

		palabrasSimilares: 'Servicios de renta de canastas de almacenamiento',
	},
	{
		code: '78141902',
		label: 'Alquiler de contenedores cerrados',

		palabrasSimilares: 'Renta de contenedores cerrados',
	},
	{
		code: '78181500',
		label: 'Servicios de mantenimiento y reparación de vehículos',

		palabrasSimilares: 'Servicio de taller mecánico',
	},
	{
		code: '78181501',
		label: 'Servicio de pintura o reparación de carrocerías de vehículos',

		palabrasSimilares: 'Servicios de hojalatería y pintura',
	},
	{
		code: '78181502',
		label: 'Reparación de transmisiones',
	},
	{
		code: '78181503',
		label: 'Servicio de cambio fluidos de transmisión o de aceite',
	},
	{
		code: '78181504',
		label: 'Reparación de trenes de aterrizaje',
	},
	{
		code: '78181505',
		label: 'Servicios de inspección de vehículos',

		palabrasSimilares: 'Servicios de inspección de autos',
	},
	{
		code: '78181506',
		label: 'Servicios de reposición de vidrios de vehículos',

		palabrasSimilares: 'Servicios de reposición de vidrios de autos',
	},
	{
		code: '78181507',
		label: 'Reparación y mantenimiento automotor y de camiones ligeros',

		palabrasSimilares:
			'Mantenimiento automotriz, Mantenimiento y reparación de automóviles y camiones ligeros',
	},
	{
		code: '78181508',
		label: 'Mantenimiento y reparación de camiones pesados',
	},
	{
		code: '78181509',
		label: 'Servicio de inspección y mantenimiento de vagones ferroviarios',

		palabrasSimilares:
			'Servicio de inspección y mantenimiento de vagones de ferrocarril',
	},
	{
		code: '78181600',
		label: 'Servicios de panel y pintura',

		palabrasSimilares: 'Servicio de taller mecánico',
	},
	{
		code: '78181601',
		label: 'Servicio de chapistería',
	},
	{
		code: '78181700',
		label:
			'Servicios de soporte de llenado de combustible de vehículos y almacenamiento de vehículos',
	},
	{
		code: '78181701',
		label: 'Servicio de abastecimiento de combustible para vehículos',
	},
	{
		code: '78181702',
		label: 'De almacenaje de vehículos de transporte',
	},
	{
		code: '78181703',
		label: 'Servicio de parqueadero de vehículos',

		palabrasSimilares: 'Servicio de estacionamiento de autos',
	},
	{
		code: '78181800',
		label: 'Servicios de mantenimiento y reparación de aeronaves',
	},
	{
		code: '78181801',
		label: 'Mantenimiento de las alas fijas de aeronaves',

		palabrasSimilares: 'Mantenimiento de las alas fijas de aviones',
	},
	{
		code: '78181802',
		label: 'Mantenimiento de las alas rotatorias de aeronaves',

		palabrasSimilares: 'Mantenimiento de las alas rotatorias de aviones',
	},
	{
		code: '78181900',
		label: 'Servicios de mantenimiento y reparación de equipo de navegación',
	},
	{
		code: '78181901',
		label: 'Mantenimiento o reparación de equipos de navegación',
	},
	{
		code: '80101500',
		label: 'Servicios de consultoría de negocios y administración corporativa',

		palabrasSimilares: 'Servicios administrativos',
	},
	{
		code: '80101501',
		label:
			'Servicios de asesoramiento sobre la puesta en marcha de empresas nuevas',

		palabrasSimilares: 'Consultoría para la apertura de nuevas empresas',
	},
	{
		code: '80101502',
		label: 'Servicios de asesoramiento sobre fusiones de empresas',

		palabrasSimilares: 'Consultoría para fusiones de empresas',
	},
	{
		code: '80101503',
		label:
			'Servicios de asesoramiento sobre liquidaciones o ventas de empresas',

		palabrasSimilares: 'Consultoría para liquidaciones o ventas de empresas',
	},
	{
		code: '80101504',
		label: 'Servicios de asesoramiento sobre planificación estratégica',

		palabrasSimilares: 'Consultoría en planificación estratégica',
	},
	{
		code: '80101505',
		label: 'Desarrollo de políticas u objetivos empresariales',

		palabrasSimilares:
			'Consultoría en políticas u objetivos empresariales, Viáticos pagados a empleados',
	},
	{
		code: '80101506',
		label: 'Asesoramiento en estructuras organizacionales',

		palabrasSimilares: 'Consultoría en estructuras organizacionales',
	},
	{
		code: '80101507',
		label: 'Servicios de asesoramiento sobre tecnologías de la información',

		palabrasSimilares: 'Consultoría en tecnologías de la información',
	},
	{
		code: '80101508',
		label: 'Servicios de asesoramiento sobre  inteligencia empresarial',

		palabrasSimilares: 'Consultoría en inteligencia empresarial',
	},
	{
		code: '80101509',
		label:
			'Servicios de asesoramiento para asuntos gubernamentales y de relaciones comunitarias',

		palabrasSimilares:
			'Consultoría en asuntos gubernamentales y de relaciones comunitarias',
	},
	{
		code: '80101510',
		label: 'Servicio de asesoramiento para la  gestión de riesgo',

		palabrasSimilares: 'Consultoría en gestión de riesgos',
	},
	{
		code: '80101511',
		label: 'Servicio de asesoramiento en recursos humanos',

		palabrasSimilares: 'Consultoría en recursos humanos',
	},
	{
		code: '80101600',
		label: 'Gerencia de proyectos',
	},
	{
		code: '80101601',
		label: 'Estudios de factibilidad o selección de ideas de proyectos',
	},
	{
		code: '80101602',
		label: 'Estudios regionales o locales para proyectos',
	},
	{
		code: '80101603',
		label: 'Evaluación económica o financiera de proyectos',
	},
	{
		code: '80101604',
		label: 'Planificación o administración de proyectos',
	},
	{
		code: '80101605',
		label: 'Servicios temporales de redacción',

		palabrasSimilares: 'Servicios de elaboración de proyectos',
	},
	{
		code: '80101700',
		label: 'Gerencia industrial',
	},
	{
		code: '80101701',
		label: 'Servicios de gestión de fábricas',
	},
	{
		code: '80101702',
		label: 'Estudios o implementación de productividad y eficacia',
	},
	{
		code: '80101703',
		label: 'Servicios de estandarización de especificaciones',
	},
	{
		code: '80101704',
		label: 'Análisis de cadenas de suministro o servicios de reingeniería',
	},
	{
		code: '80101705',
		label: 'Servicios de cooperativas o consorcios',
	},
	{
		code: '80101706',
		label: 'Servicios profesionales de adquisiciones',
	},
	{
		code: '80101707',
		label: 'Servicios de cabildeo',
	},
	{
		code: '80101708',
		label: 'Servicio de gestión de sustancias químicas',
	},
	{
		code: '80111500',
		label: 'Desarrollo de recursos humanos',

		palabrasSimilares: 'Servicios de capacitación de personal',
	},
	{
		code: '80111501',
		label: 'Perfeccionamiento de la función de gestión',

		palabrasSimilares: 'Servicios de mejoras en las habilidades de gestión',
	},
	{
		code: '80111502',
		label: 'Planificación de compensaciones o beneficios',
	},
	{
		code: '80111503',
		label: 'Relaciones laborales o con los sindicatos',

		palabrasSimilares: 'Relaciones laborales o sindicales',
	},
	{
		code: '80111504',
		label: 'Formación o desarrollo laboral',
	},
	{
		code: '80111505',
		label: 'Auditorías de productividad de los recursos humanos',

		palabrasSimilares: 'Auditorías de productividad del capital humano',
	},
	{
		code: '80111506',
		label: 'Reubicación de personal',
	},
	{
		code: '80111507',
		label: 'Servicios de empleo en otros organismos',

		palabrasSimilares: 'Servicios de recolocación de personal',
	},
	{
		code: '80111508',
		label: 'Programas de reconocimiento de servicios',
	},
	{
		code: '80111509',
		label: 'Servicio de evaluación de puestos de trabajo',
	},
	{
		code: '80111510',
		label:
			'Servicio de redacción y desarrollo de la descripción de puestos de trabajo',
	},
	{
		code: '80111600',
		label: 'Servicios de personal temporal',

		palabrasSimilares: 'Servicios de agencias de colocación de personal',
	},
	{
		code: '80111601',
		label: 'Asistencia de oficina o administrativa temporal',

		palabrasSimilares:
			'Colocación de personal temporal para asistencia administrativa o de oficina, Colocación de personal temporal para limpieza de inmuebles',
	},
	{
		code: '80111602',
		label: 'Necesidades de personal de mercadeo temporal',

		palabrasSimilares: 'Colocación de personal temporal para mercadotecnía',
	},
	{
		code: '80111603',
		label: 'Necesidades de dotación de personal de producción temporal',

		palabrasSimilares: 'Colocación de personal temporal para producción',
	},
	{
		code: '80111604',
		label: 'Necesidades de dotación de personal técnico temporal',

		palabrasSimilares: 'Colocación de personal temporal técnico',
	},
	{
		code: '80111605',
		label: 'Necesidades de dotación de personal financiero temporal',

		palabrasSimilares: 'Colocación de personal temporal financiero',
	},
	{
		code: '80111606',
		label: 'Necesidades de dotación de personal medico  temporal',

		palabrasSimilares: 'Colocación de personal temporal médico',
	},
	{
		code: '80111607',
		label: 'Necesidades de dotación de personal jurídico temporal',

		palabrasSimilares: 'Colocación de personal temporal jurídico',
	},
	{
		code: '80111608',
		label:
			'Desarrolladores temporales de software de tecnologías de la información',

		palabrasSimilares:
			'Colocación de personal temporal en desarrollo de software para tecnologías de la información',
	},
	{
		code: '80111609',
		label:
			'Administradores temporales de bases de datos o de sistemas de tecnologías de la información',

		palabrasSimilares:
			'Administradores temporales de bases de datos o de sistemas de tecnologías de la información',
	},
	{
		code: '80111610',
		label:
			'Especialistas en interconexión de tecnologías de la información temporales',

		palabrasSimilares:
			'Colocación de personal temporal especialista en interconexión de tecnologías de la información',
	},
	{
		code: '80111611',
		label: 'Personal  temporal  de almacén',

		palabrasSimilares: 'Colocación de personal temporal para almacén',
	},
	{
		code: '80111612',
		label: 'Conductores temporales',

		palabrasSimilares: 'Colocación de personal temporal para chofer',
	},
	{
		code: '80111613',
		label: 'Trabajadores manuales temporales',

		palabrasSimilares: 'Colocación de personal temporal para trabajos manuales',
	},
	{
		code: '80111614',
		label: 'Servicios temporales de ingeniería',

		palabrasSimilares: 'Colocación de personal temporal de ingeniería',
	},
	{
		code: '80111615',
		label: 'Personal maquinista temporal',

		palabrasSimilares: 'Colocación de personal temporal para maquinista',
	},
	{
		code: '80111616',
		label: 'Personal temporal de servicio al cliente',

		palabrasSimilares: 'Colocación de personal temporal de atención al cliente',
	},
	{
		code: '80111617',
		label: 'Servicios temporales de arquitectura',

		palabrasSimilares: 'Colocación de personal temporal de arquitectura',
	},
	{
		code: '80111618',
		label: 'Servicios temporales de construcción',

		palabrasSimilares: 'Colocación de personal temporal para construcción',
	},
	{
		code: '80111619',
		label: 'Servicios creativos temporales',

		palabrasSimilares:
			'Colocación de personal temporal para servicios creativos',
	},
	{
		code: '80111620',
		label: 'Servicios temporales de recursos humanos',

		palabrasSimilares: 'Colocación de personal temporal en recursos humanos',
	},
	{
		code: '80111621',
		label: 'Servicios temporales de investigación y desarrollo',

		palabrasSimilares:
			'Colocación de personal temporal para investigación y desarrollo',
	},
	{
		code: '80111622',
		label: 'Servicios temporales ambientales en salud y seguridad',

		palabrasSimilares:
			'Colocación de personal temporal para servicios ambientales, de salud y seguridad',
	},
	{
		code: '80111623',
		label: 'Servicios temporales de compras y logística',

		palabrasSimilares:
			'Colocación de personal temporal para compras y logística',
	},
	{
		code: '80111624',
		label: 'Dotación de personal de viaje temporal',

		palabrasSimilares: 'Colocación de personal temporal para viajes',
	},
	{
		code: '80111625',
		label: 'Trabajadores manuales de subterráneos temporales',

		palabrasSimilares:
			'Colocación de personal temporal para trabajos en subterráneos, minas o ductos',
	},
	{
		code: '80111700',
		label: 'Reclutamiento de personal',
		incluirIVATrasladado: 'Sí',
		incluirIEPSTrasladado: 'Opcional',
		palabrasSimilares: 'Servicios de agencias de colocación de personal',
	},
	{
		code: '80111701',
		label: 'Servicios de contratación de personal',
		incluirIVATrasladado: 'Sí',
		incluirIEPSTrasladado: 'Opcional',
		palabrasSimilares:
			'Servicios de outsourcing, Servicios de subcontratación de personal',
	},
	{
		code: '80111702',
		label: 'Servicios de comprobación de referencias o antecedentes',

		palabrasSimilares:
			'Servicios de verificación de referencias y antecedentes',
	},
	{
		code: '80111703',
		label: 'Servicios de preselección  de hojas de vida o  currículum vitae',
	},
	{
		code: '80111704',
		label: 'Necesidades de personal de mercadeo permanente',

		palabrasSimilares: 'Colocación de personal fijo para mercadotecnia',
	},
	{
		code: '80111705',
		label: 'Personal maquinista permanente',

		palabrasSimilares: 'Colocación de personal fijo para maquinista',
	},
	{
		code: '80111706',
		label: 'Asistencia administrativa o de oficina permanente',

		palabrasSimilares:
			'Colocación de personal fijo para asistencia administrativa o de oficina, Colocación de personal fijo para limpieza de inmuebles',
	},
	{
		code: '80111707',
		label: 'Necesidades de dotación de personal técnico permanente',

		palabrasSimilares: 'Colocación de personal fijo técnico',
	},
	{
		code: '80111708',
		label: 'Necesidades de dotación personal  financiero permanente',

		palabrasSimilares: 'Colocación de personal fijo financiero',
	},
	{
		code: '80111709',
		label: 'Necesidades de personal médico permanente',

		palabrasSimilares: 'Colocación de personal fijo médico',
	},
	{
		code: '80111710',
		label: 'Necesidades de dotación de personal jurídico permanente',

		palabrasSimilares: 'Colocación de personal fijol jurídico',
	},
	{
		code: '80111711',
		label:
			'Desarrolladores de software de tecnologías de la información permanentes',

		palabrasSimilares:
			'Colocación de personal fijo en desarrollo de software para tecnologías de la información',
	},
	{
		code: '80111712',
		label:
			'Especialistas en redes de tecnologías de la información permanentes',

		palabrasSimilares:
			'Colocación de personal fijo especialista en redes de tecnologías de la información',
	},
	{
		code: '80111713',
		label:
			'Administradores permanentes de bases de datos o de sistemas de tecnologías de la información',

		palabrasSimilares:
			'Administradores fijos de bases de datos o de sistemas de tecnologías de la información',
	},
	{
		code: '80111714',
		label: 'Conductores permanentes',

		palabrasSimilares: 'Colocación de personal fijo para chofer',
	},
	{
		code: '80111715',
		label: 'Personal profesional permanente',

		palabrasSimilares: 'Colocación de personal fijo profesional',
	},
	{
		code: '80111716',
		label:
			'Necesidades de dotación de personal de tecnologías de la información permanente',

		palabrasSimilares:
			'Colocación de personal fijo en tecnologías de la información',
	},
	{
		code: '80121500',
		label: 'Servicios de derecho penal',

		palabrasSimilares: 'Asesoría jurídica',
	},
	{
		code: '80121501',
		label: 'Servicios legales de justicia juvenil o de adolescentes',

		palabrasSimilares: 'Servicios legales de justicia de menores',
	},
	{
		code: '80121502',
		label: 'Servicios para procesos de apelación',
	},
	{
		code: '80121503',
		label: 'Servicios para defensa o de derecho penal',
	},
	{
		code: '80121600',
		label: 'Servicios de derecho comercial',

		palabrasSimilares: 'Asesoría jurídica',
	},
	{
		code: '80121601',
		label: 'Servicios legales sobre competencia o regulaciones gubernamentales',
	},
	{
		code: '80121602',
		label: 'Servicios legales de quiebra',

		palabrasSimilares:
			'Servicios legales de bancarrota, Servicios legales para embargos',
	},
	{
		code: '80121603',
		label: 'Derecho societario',

		palabrasSimilares:
			'Honorarios de notarios, Serivicios de derecho mercantil, Servicios notariale',
	},
	{
		code: '80121604',
		label: 'Derecho de patentes, marcas o derechos de autor',

		palabrasSimilares: 'Regalías por derechos de autor',
	},
	{
		code: '80121605',
		label: 'Derecho sobre liquidación (de sociedades)',
	},
	{
		code: '80121606',
		label: 'Derecho inmobiliario',

		palabrasSimilares: 'Derecho bienes inmuebles',
	},
	{
		code: '80121607',
		label: 'Derecho tributario',
	},
	{
		code: '80121608',
		label: 'Derecho de fusiones o adquisiciones',

		palabrasSimilares: 'Derecho de combinaciones o adquisiciones',
	},
	{
		code: '80121609',
		label: 'Servicios de investigación legal',
	},
	{
		code: '80121610',
		label: 'Servicios legales de cobro de deudas o cartera.',
	},
	{
		code: '80121611',
		label: 'Derecho de reclamación por tratamiento médico.',
	},
	{
		code: '80121700',
		label: 'Servicios de responsabilidad civil',

		palabrasSimilares: 'Asesoría jurídica',
	},
	{
		code: '80121701',
		label: 'Servicios legales de malpraxis o negligencia profesional',

		palabrasSimilares:
			'Servicios legales de mala practica o negligencia profesional',
	},
	{
		code: '80121702',
		label: 'Servicios legales sobre daños a personas',
	},
	{
		code: '80121703',
		label: 'Servicios legales de sobre la  propiedad',

		palabrasSimilares: 'Honorarios de notarios, Servicios notariales',
	},
	{
		code: '80121704',
		label: 'Servicios legales sobre contratos',

		palabrasSimilares: 'Honorarios de notarios, Servicios notariales',
	},
	{
		code: '80121705',
		label: 'Servicios legales sobre beneficios de los empleados',
	},
	{
		code: '80121706',
		label: 'Servicios legales sobre derecho laboral',
	},
	{
		code: '80121707',
		label: 'Servicios legales para disputas laborales',
	},
	{
		code: '80121800',
		label: 'Servicios de derecho de familia',

		palabrasSimilares: 'Asesoría jurídica',
	},
	{
		code: '80121801',
		label: 'Servicios sobre derecho de divorcio',
	},
	{
		code: '80121802',
		label: 'Servicios legales para adopción',
	},
	{
		code: '80121803',
		label: 'Derecho migratorio o de naturalización',
	},
	{
		code: '80121804',
		label: 'Servicios legales para tutorías o de custodia',

		palabrasSimilares: 'Servicios legales de tutela o de custodia',
	},
	{
		code: '80121900',
		label: 'Servicios de participación compensada legal',
	},
	{
		code: '80121901',
		label: 'Servicio como miembro del jurado',
	},
	{
		code: '80121902',
		label: 'Servicio como testigo',
	},
	{
		code: '80121903',
		label: 'Servicio como testigo experto',
	},
	{
		code: '80131500',
		label: 'Alquiler y arrendamiento de propiedades o edificaciones',

		palabrasSimilares: 'Arrendamiento de bienes inmuebles',
	},
	{
		code: '80131501',
		label: 'Arrendamiento de residencias',

		palabrasSimilares: 'Renta de casa habitación',
	},
	{
		code: '80131502',
		label: 'Arrendamiento de instalaciones comerciales o industriales',

		palabrasSimilares: 'Renta de local comercial',
	},
	{
		code: '80131503',
		label: 'Arrendamientos de tierras',

		palabrasSimilares: 'Renta de terreno',
	},
	{
		code: '80131504',
		label: 'Servicio de alojamiento temporal offshore',
	},
	{
		code: '80131505',
		label: 'Servicio de arrendamiento de oficinas portátiles o modulares',

		palabrasSimilares: 'Renta o alquiler de oficinas portátiles o modulares',
	},
	{
		code: '80131506',
		label: 'Servicio de arrendamiento de baños portátiles',

		palabrasSimilares: 'Renta o alquiler de baños portátiles',
	},
	{
		code: '80131600',
		label: 'Ventas de propiedades y edificios',
	},
	{
		code: '80131601',
		label: 'Corredores o agentes inmobiliarios',
	},
	{
		code: '80131602',
		label: 'Subasta inmobiliaria',

		palabrasSimilares: 'Subasta de bienes inmuebles',
	},
	{
		code: '80131603',
		label: 'Venta de tierras residenciales',
	},
	{
		code: '80131604',
		label: 'Venta de tierras comerciales o industriales',
	},
	{
		code: '80131605',
		label: 'Venta de edificio comercial',
	},
	{
		code: '80131700',
		label: 'Servicios de custodia y título',
	},
	{
		code: '80131701',
		label: 'Servicios para la restitución de títulos de propiedad',
	},
	{
		code: '80131702',
		label: 'Servicios de estudio de títulos',
	},
	{
		code: '80131703',
		label: 'Servicios de cuenta de depósito en garantía',
	},
	{
		code: '80131800',
		label: 'Servicios de administración inmobiliaria',
	},
	{
		code: '80131801',
		label: 'Administración de propiedades',

		palabrasSimilares: 'Cuotas de mantenimiento, Gestión de propiedades',
	},
	{
		code: '80131802',
		label: 'Servicios de avalúo de inmuebles',

		palabrasSimilares: 'Servicios de avalúo inmobiliario',
	},
	{
		code: '80131803',
		label: 'Servicios de listado de inmuebles',

		palabrasSimilares: 'Servicios de listado de inmobiliario',
	},
	{
		code: '80141500',
		label: 'Investigación de mercados',
	},
	{
		code: '80141501',
		label: 'Análisis de mercados',
	},
	{
		code: '80141502',
		label: 'Estadísticas comerciales de distribución o de servicios',
	},
	{
		code: '80141503',
		label: 'Previsión de precios de las mercancías',
	},
	{
		code: '80141504',
		label: 'Preparación de estudios de mercado de mercancías',
	},
	{
		code: '80141505',
		label: 'Planes de comercialización',

		palabrasSimilares: 'Planes de mercadeo',
	},
	{
		code: '80141506',
		label: 'Investigación de mercado s basada en internet',

		palabrasSimilares: 'Investigación de mercados basada en internet',
	},
	{
		code: '80141507',
		label:
			'Investigaciones  o clínicas o grupos focales  basados en el consumidor',

		palabrasSimilares:
			'Investigaciones y grupos de enfoque basados en el consumidor',
	},
	{
		code: '80141508',
		label: 'Estudios de predicciones sindicados o privados',

		palabrasSimilares: 'Estudios de exploración y diagnóstico',
	},
	{
		code: '80141509',
		label: 'Análisis competitivo o de inteligencia del mercado',
	},
	{
		code: '80141510',
		label: 'Encuestas telefónicas para investigaciones de mercado',
	},
	{
		code: '80141511',
		label: 'Encuestas impresas para investigaciones de mercado',
	},
	{
		code: '80141512',
		label: 'Encuestas para investigaciones de mercados en el lugar',

		palabrasSimilares: 'Encuestas en sitio para investigaciones de mercado',
	},
	{
		code: '80141513',
		label: 'Entrevistas personales para investigaciones de mercado',
	},
	{
		code: '80141514',
		label: 'Encuestas por correo electrónico para la investigación de mercados',
	},
	{
		code: '80141600',
		label: 'Actividades de ventas y promoción de negocios',

		palabrasSimilares: 'Comisiones',
	},
	{
		code: '80141601',
		label: 'Servicios de promoción de ventas',

		palabrasSimilares: 'Servicios de comisionistas',
	},
	{
		code: '80141602',
		label: 'Servicios de relaciones públicas',
	},
	{
		code: '80141603',
		label: 'Telemercadeo',
	},
	{
		code: '80141604',
		label: 'Servicios de posicionamiento del  nombre de los productos',
	},
	{
		code: '80141605',
		label: 'Mercancía promocional',
	},
	{
		code: '80141606',
		label: 'Cumplimiento del mercadeo directo',
	},
	{
		code: '80141607',
		label: 'Gestión de eventos',

		palabrasSimilares: 'Organización de eventos, Administración de eventos',
	},
	{
		code: '80141609',
		label: 'Patrocinio de eventos o de celebridades',
	},
	{
		code: '80141610',
		label: 'Liquidaciones',

		palabrasSimilares: 'Anticipos de clientes, Saldos',
	},
	{
		code: '80141611',
		label: 'Servicios de personalización de obsequios o productos',
	},
	{
		code: '80141612',
		label: 'Programas de venta o de mercadeo',

		palabrasSimilares: 'Servicios de comisionistas',
	},
	{
		code: '80141613',
		label: 'Programas posventas',

		palabrasSimilares: 'Servicios de comisionistas',
	},
	{
		code: '80141614',
		label: 'Servicios o programas de relaciones públicas',
	},
	{
		code: '80141615',
		label: 'Vehículo usado, de alquiler o de exposición',

		palabrasSimilares: 'Vehículo usado, de renta o de exposición',
	},
	{
		code: '80141616',
		label: 'Materiales en puntos de venta, excluido el material impreso',
	},
	{
		code: '80141617',
		label: 'Capacitación en iniciativas estratégicas en los concesionarios',
	},
	{
		code: '80141618',
		label: 'Agencias de mercadeo  de venta, incluido el material impreso',
	},
	{
		code: '80141619',
		label:
			'Servicios de administración del centro de relaciones con el consumidor (crc)',
	},
	{
		code: '80141620',
		label: 'Soporte de iniciativas estratégicas en los concesionarios',
	},
	{
		code: '80141621',
		label: 'Automovilismo',
	},
	{
		code: '80141622',
		label: 'Servicios de talleres postales',

		palabrasSimilares: 'Tiendas de sobres para cartas',
	},
	{
		code: '80141623',
		label: 'Servicio de comercialización',
	},
	{
		code: '80141624',
		label: 'Servicio de gestión de programas de reconocimiento',

		palabrasSimilares:
			'Servicio de administración de programas de reconocimiento',
	},
	{
		code: '80141625',
		label: 'Servicio de gestión de programas de incentivos',

		palabrasSimilares: 'Servicio de administración de programas de incentivos',
	},
	{
		code: '80141626',
		label: 'Servicio de gestión de programas promocionales',

		palabrasSimilares: 'Servicio de administración de programas promocionales',
	},
	{
		code: '80141627',
		label: 'Servicio de gestión de publicidad cooperativa o compartida',

		palabrasSimilares:
			'Servicio de administración de publicidad cooperativa o compartida',
	},
	{
		code: '80141628',
		label: 'Servicio de distribuidores por comisión',
	},
	{
		code: '80141629',
		label: 'Servicio de manejo de bonificaciones',

		palabrasSimilares: 'Servicio de gestión de reembolso',
	},
	{
		code: '80141630',
		label: 'Servicio de comercialización directa de materiales impresos',
	},
	{
		code: '80141700',
		label: 'Distribución',
	},
	{
		code: '80141701',
		label: 'Servicios de venta directa',
	},
	{
		code: '80141702',
		label: 'Servicios de distribución mayorista',

		palabrasSimilares: 'Servicios de distribución al mayoreo',
	},
	{
		code: '80141703',
		label: 'Servicios de distribución minorista',

		palabrasSimilares: 'Servicios de distribución al menudeo',
	},
	{
		code: '80141704',
		label: 'Operaciones de franquicias',
	},
	{
		code: '80141705',
		label: 'Servicios de subastas',
	},
	{
		code: '80141706',
		label: 'Servicio de intermediación de productos',
	},
	{
		code: '80141800',
		label: 'Servicios de correo',
	},
	{
		code: '80141801',
		label: 'Servicios de recopilación de listas de direcciones',

		palabrasSimilares: 'Servicios de compilación de listas de direcciones',
	},
	{
		code: '80141802',
		label: 'Servicio de administración de listas de direcciones',

		palabrasSimilares: 'Servicio de gestión de listas de direcciones',
	},
	{
		code: '80141803',
		label: 'Servicio de escritura de direcciones',
	},
	{
		code: '80141900',
		label: 'Exhibiciones y ferias comerciales',
	},
	{
		code: '80141901',
		label: 'Ferias de automóviles u otras exposiciones',

		palabrasSimilares: 'Exposiciones de autos u otras exposiciones',
	},
	{
		code: '80141902',
		label: 'Reuniones y eventos',
	},
	{
		code: '80141903',
		label: 'Talento o entretenimiento',
	},
	{
		code: '80151500',
		label: 'Facilitación del comercio',
	},
	{
		code: '80151501',
		label: 'Servicios de proyeción o políticas de productos básicos',
	},
	{
		code: '80151502',
		label: 'Expansión comercial',

		palabrasSimilares: 'Ampliación comercial',
	},
	{
		code: '80151503',
		label: 'Servicios de información comercial',
	},
	{
		code: '80151504',
		label: 'Servicios de promoción comercial',
	},
	{
		code: '80151505',
		label: 'Empresas multinacionales de comercialización',
	},
	{
		code: '80151600',
		label: 'Servicios de comercio internacional',

		palabrasSimilares: 'Comercio exterior',
	},
	{
		code: '80151601',
		label: 'Desarrollo de las exportaciones',
	},
	{
		code: '80151602',
		label: 'Planificación de las importaciones',
	},
	{
		code: '80151603',
		label: 'Proyecciones de las exportaciones',
	},
	{
		code: '80151604',
		label: 'Servicios de adquisición de importaciones',
	},
	{
		code: '80151605',
		label: 'Servicios de consultoría aduanera',

		palabrasSimilares: 'Servicios de asesoría aduanera',
	},
	{
		code: '80161500',
		label: 'Servicios de apoyo gerencial',
	},
	{
		code: '80161501',
		label: 'Servicios secretariales o de administración de oficinas',
	},
	{
		code: '80161502',
		label: 'Servicios de planificación de reuniones',
	},
	{
		code: '80161503',
		label: 'Servicios de entrada por teclado',
	},
	{
		code: '80161504',
		label: 'Servicios de oficina',
	},
	{
		code: '80161505',
		label: 'Servicios de administración de flotas',
	},
	{
		code: '80161506',
		label: 'Servicios de archivo de datos',
	},
	{
		code: '80161507',
		label: 'Servicios audiovisuales',
	},
	{
		code: '80161508',
		label: 'Servicios de destrucción de documentos',

		palabrasSimilares: 'Servicios de eliminación de documentos',
	},
	{
		code: '80161600',
		label: 'Supervisión de instalaciones de negocios',
	},
	{
		code: '80161601',
		label: 'Servicios de administración de la propiedad',

		palabrasSimilares: 'Servicios de gestión de la propiedad',
	},
	{
		code: '80161602',
		label: 'Servicios de recepción o inventariado',
	},
	{
		code: '80161603',
		label: 'Gestión o administración de proyectos de mobiliario',
	},
	{
		code: '80161700',
		label: 'Servicios de recuperación de activos',
	},
	{
		code: '80161701',
		label: 'Servicio de disposición o venta de subproductos',
	},
	{
		code: '80161702',
		label: 'Servicio de disposición o venta de bienes de capital',

		palabrasSimilares: 'Servicio de disposición o venta de bienes duraderos',
	},
	{
		code: '80161703',
		label:
			'Servicio de disposición o venta de materiales no capitalizables sobrantes u obsoletos',
	},
	{
		code: '80161800',
		label: 'Servicios de alquiler o arrendamiento de equipo de oficina',
	},
	{
		code: '80161801',
		label: 'Servicio de alquiler o leasing de fotocopiadoras',

		palabrasSimilares: 'Servicio de alquiler o arrendamiento de fotocopiadoras',
	},
	{
		code: '81101500',
		label: 'Ingeniería civil',
	},
	{
		code: '81101501',
		label: 'Ingeniería de pozos',
	},
	{
		code: '81101502',
		label: 'Dibujo técnico',
	},
	{
		code: '81101503',
		label: 'Ingeniería de puertos',

		palabrasSimilares: 'Ingeniería portuaria',
	},
	{
		code: '81101505',
		label: 'Ingeniería estructural',
	},
	{
		code: '81101506',
		label: 'Arquitectura naval',
	},
	{
		code: '81101507',
		label: 'Ingeniería de represas',

		palabrasSimilares: 'Ingeniería de presas',
	},
	{
		code: '81101508',
		label: 'Ingeniería arquitectónica',
	},
	{
		code: '81101509',
		label: 'Ingeniería aeroportuaria',
	},
	{
		code: '81101510',
		label: 'Ingeniería de carreteras',
	},
	{
		code: '81101511',
		label: 'Ingeniería ferroviaria',
	},
	{
		code: '81101512',
		label: 'Servicios de sistemas de información geográfica (sig)',

		palabrasSimilares: 'Servicios de topografía',
	},
	{
		code: '81101513',
		label: 'Gestión de construcción de edificios',

		palabrasSimilares:
			'Honorarios de arquitectos, Servicios de construcción, Servicios profesionales de arquitectur',
	},
	{
		code: '81101514',
		label: 'Ingeniería geotécnica o geosísmica',
	},
	{
		code: '81101515',
		label: 'Ingeniería de infraestructura de instalaciones o fábricas',
	},
	{
		code: '81101516',
		label: 'Servicio de consultoría de energía o servicios públicos',

		palabrasSimilares: 'Servicio de asesoría de energía o servicios públicos',
	},
	{
		code: '81101600',
		label: 'Ingeniería mecánica',
	},
	{
		code: '81101601',
		label: 'Dibujo mecánico',
	},
	{
		code: '81101602',
		label: 'Diseño de envolturas de productos mecánicos',

		palabrasSimilares: 'Diseño de gabinetes de productos mecánicos',
	},
	{
		code: '81101603',
		label: 'Diseño de máquinas herramientas',
	},
	{
		code: '81101604',
		label: 'Diseño de transmisión de potencia',
	},
	{
		code: '81101605',
		label: 'Servicios electromecánicos',
	},
	{
		code: '81101700',
		label: 'Ingeniería eléctrica y electrónica',
	},
	{
		code: '81101701',
		label: 'Servicios de ingeniería eléctrica',
	},
	{
		code: '81101702',
		label: 'Diseño de circuitos electrónicos',
	},
	{
		code: '81101703',
		label: 'Servicios de pruebas técnicas',

		palabrasSimilares: 'Servicios de pruebas de ingeniería',
	},
	{
		code: '81101706',
		label: 'Mantenimiento de equipos de laboratorio',
	},
	{
		code: '81101707',
		label: 'Mantenimiento de equipos de impresión',
	},
	{
		code: '81101710',
		label: 'Servicio de reclamación de wafers',

		palabrasSimilares: 'Servicios de recuperación de obleas de silicio',
	},
	{
		code: '81101711',
		label: 'Servicios de fabricación de componentes electrónicos',
	},
	{
		code: '81101713',
		label:
			'Servicio técnico y de diseño de instrumentos de medición y de registro electrónicos',
	},
	{
		code: '81101800',
		label: 'Ingeniería química',
	},
	{
		code: '81101801',
		label: 'Ingeniería de plásticos',
	},
	{
		code: '81101802',
		label: 'Servicios de ingeniería de procesos químicos',
	},
	{
		code: '81101900',
		label: 'Ingeniería de petróleos y gas',
	},
	{
		code: '81101902',
		label: 'Ingeniería de producción para petróleo o gas',
	},
	{
		code: '81102000',
		label: 'Ingeniería de minas',
	},
	{
		code: '81102001',
		label: 'Minería hidráulica',
	},
	{
		code: '81102100',
		label: 'Ingeniería de océanos',
	},
	{
		code: '81102101',
		label: 'Ingeniería de costas',

		palabrasSimilares: 'Ingeniería costera',
	},
	{
		code: '81102200',
		label: 'Ingeniería de transporte',
	},
	{
		code: '81102201',
		label: 'Ingeniería de tráfico',

		palabrasSimilares: 'Ingeniería de tránsito',
	},
	{
		code: '81102202',
		label: 'red de transporte urbano',
	},
	{
		code: '81102203',
		label: 'Vías de navegación interior',

		palabrasSimilares: 'Vías navegables interiores',
	},
	{
		code: '81102300',
		label: 'Ingeniería aeronáutica',
	},
	{
		code: '81102301',
		label: 'Diseño de aviónica',
	},
	{
		code: '81102400',
		label: 'Ingeniería de transmisión de energía eléctrica',
	},
	{
		code: '81102401',
		label: 'Construcción de líneas de tendido aéreo de alto voltaje',

		palabrasSimilares: 'Construcción de línea aérea de alto voltaje',
	},
	{
		code: '81102402',
		label:
			'Mantenimiento y reparación  de líneas de tendido aéreo de alto voltaje',

		palabrasSimilares:
			'Mantenimiento y reparación de líneas aéreas de alto voltaje',
	},
	{
		code: '81102500',
		label: 'Servicios de permisos',
	},
	{
		code: '81102501',
		label: 'Servicio para obtener licencia en operación de minas',
	},
	{
		code: '81102600',
		label: 'Servicios de muestreo',
	},
	{
		code: '81102601',
		label: 'Servicio de muestreo de carbón',
	},
	{
		code: '81102700',
		label:
			'Servicios de diseño e ingeniería de sistemas instrumentados de control',
	},
	{
		code: '81102701',
		label:
			'Servicio de ingeniería para sistemas de control y monitoreo de incendios y gas',
	},
	{
		code: '81102702',
		label:
			'Servicio de ingeniería y diseño para sistemas de control de procesos',
	},
	{
		code: '81111500',
		label: 'Ingeniería de software o hardware',
	},
	{
		code: '81111501',
		label: 'Diseño de aplicaciones de software de la unidad central',
	},
	{
		code: '81111502',
		label: 'Diseños de aplicaciones para computadores personales (pc)',

		palabrasSimilares:
			'Diseño de aplicaciones para ordenadores personales (PC)',
	},
	{
		code: '81111503',
		label: 'Diseño de integración de sistemas',
	},
	{
		code: '81111504',
		label: 'Servicios de programación de aplicaciones',
	},
	{
		code: '81111505',
		label: 'Servicios de programación de sistemas operativos',
	},
	{
		code: '81111506',
		label: 'Servicios de programación para clientes o servidores',
	},
	{
		code: '81111507',
		label: 'Servicios de programación de aplicaciones de bases de datos (erp)',
	},
	{
		code: '81111508',
		label: 'Servicios de implementación de aplicaciones',
	},
	{
		code: '81111509',
		label:
			'Servicios de desarrollo de aplicaciones para clientes de internet / intranet',
	},
	{
		code: '81111510',
		label:
			'Servicios de desarrollo de aplicaciones para servidores de internet / intranet',
	},
	{
		code: '81111600',
		label: 'Programadores de computador',
	},
	{
		code: '81111601',
		label: 'Programación para visual basic',
	},
	{
		code: '81111602',
		label: 'Programación para java',
	},
	{
		code: '81111603',
		label: 'Programación para html',

		palabrasSimilares: 'Programación para Lenguaje de Marcado para Hipertextos',
	},
	{
		code: '81111604',
		label: 'Programación para algol',

		palabrasSimilares: 'Programación para lenguaje algorítmico',
	},
	{
		code: '81111605',
		label: 'Programación para assembler',

		palabrasSimilares: 'Programación para lenguaje ensamblador',
	},
	{
		code: '81111606',
		label: 'Programación para basic',

		palabrasSimilares:
			'Programación de Código simbólico de instrucciones de propósito general para principiantes',
	},
	{
		code: '81111607',
		label: 'Programación para c, c++',
	},
	{
		code: '81111608',
		label: 'Programación para cobol',

		palabrasSimilares: 'Programación para Lenguaje Común Orientado a Negocios',
	},
	{
		code: '81111609',
		label: 'Programación para fortran',
	},
	{
		code: '81111610',
		label: 'Programación para pascal',
	},
	{
		code: '81111611',
		label: 'Programación para pl/1',

		palabrasSimilares: 'Lenguaje de programación 1',
	},
	{
		code: '81111612',
		label: 'Lenguajes de programación o de marca patentada',
	},
	{
		code: '81111613',
		label: 'Programación para perl',
	},
	{
		code: '81111700',
		label: 'Sistemas de manejo de información MIS',
	},
	{
		code: '81111701',
		label: 'Diseño de comunicaciones por redes de cobertura amplia',
	},
	{
		code: '81111702',
		label: 'Diseño de comunicaciones por redes de área local',
	},
	{
		code: '81111703',
		label: 'Diseño del intercambio electrónico de datos (ied)',
	},
	{
		code: '81111704',
		label: 'Diseño de bases de datos',
	},
	{
		code: '81111705',
		label: 'Arquitectura de sistemas',
	},
	{
		code: '81111706',
		label: 'Servicios de planificación de redes',
	},
	{
		code: '81111707',
		label: 'Servicios de planificación de sistemas',
	},
	{
		code: '81111708',
		label: 'Servicios de planificación de telecomunicaciones',
	},
	{
		code: '81111800',
		label: 'Servicios de sistemas y administración de componentes de sistemas',
	},
	{
		code: '81111801',
		label: 'Seguridad de los computadores, redes o internet',

		palabrasSimilares: 'Seguridad informática o en red o Internet',
	},
	{
		code: '81111802',
		label: 'Servicios de administración de la unidad central',
	},
	{
		code: '81111803',
		label: 'Mantenimiento o soporte de redes de área local (lan)',
	},
	{
		code: '81111804',
		label: 'Mantenimiento o soporte de redes de cobertura amplia (wan)',

		palabrasSimilares:
			'Mantenimiento o soporte de redes de cobertura ancha (WAN)',
	},
	{
		code: '81111805',
		label: 'Mantenimiento o soporte de sistemas patentados o autorizados',
	},
	{
		code: '81111806',
		label: 'Servicio de análisis de bases de datos',
	},
	{
		code: '81111808',
		label: 'Servicio de análisis de sistemas',
	},
	{
		code: '81111809',
		label: 'Servicio de instalación de sistemas',
	},
	{
		code: '81111810',
		label: 'Servicios de codificación de software',

		palabrasSimilares: 'Servicios de codificación de programas',
	},
	{
		code: '81111811',
		label: 'Servicios de soporte técnico o de mesa de ayuda',

		palabrasSimilares: 'Servicios asistencia técnica',
	},
	{
		code: '81111812',
		label: 'Servicio de mantenimiento o soporte del hardware del computador',

		palabrasSimilares:
			'Servicio de soporte de mantenimiento de hardware de la computadora',
	},
	{
		code: '81111814',
		label: 'Servicio de colocación',
	},
	{
		code: '81111818',
		label: 'Garantía de terceros',

		palabrasSimilares: 'Servicio de garantía de terceros',
	},
	{
		code: '81111819',
		label: 'Servicios de garantía de calidad',
	},
	{
		code: '81111820',
		label: 'Servicios de funcionalidad del sistema',
	},
	{
		code: '81111900',
		label: 'Sistemas de recuperación de información',
	},
	{
		code: '81111901',
		label: 'Recuperación de información de las bases de datos',
	},
	{
		code: '81111902',
		label:
			'Servicio de recuperación de información de las bases de datos en línea',
	},
	{
		code: '81112000',
		label: 'Servicios de datos',
	},
	{
		code: '81112001',
		label: 'Servicio de procesamiento de datos en línea',
	},
	{
		code: '81112002',
		label: 'Servicios de procesamiento o preparación de datos',
	},
	{
		code: '81112003',
		label: 'Servicios de centros de datos',
	},
	{
		code: '81112004',
		label: 'Servicios de recuperación de desastres',
	},
	{
		code: '81112005',
		label: 'Servicio de escaneo de documentos',

		palabrasSimilares: 'Servicio de digitalización de documentos',
	},
	{
		code: '81112006',
		label: 'servicios de almacenamiento de datos',
	},
	{
		code: '81112007',
		label: 'Servicios de estandarización de datos o contenidos',
	},
	{
		code: '81112008',
		label: 'Servicios de generación de originales de cd-rom',
	},
	{
		code: '81112009',
		label: 'Servicios de clasificación de datos o contenidos',
	},
	{
		code: '81112010',
		label: 'Servicio de conversión de datos',
	},
	{
		code: '81112100',
		label: 'Servicios de internet',
	},
	{
		code: '81112101',
		label: 'Proveedores de servicio de internet (psi)',
	},
	{
		code: '81112102',
		label: 'Proveedor de servicio de correo electrónico',
	},
	{
		code: '81112103',
		label: 'Servicios de diseño de sitios web www',
	},
	{
		code: '81112104',
		label: 'Proveedores de motores de búsqueda en la web',
	},
	{
		code: '81112105',
		label: 'Servicios de hospedaje de operación de sitios web',
	},
	{
		code: '81112106',
		label: 'Proveedores de servicios de aplicación',
	},
	{
		code: '81112107',
		label: 'Nombres de los dominio de internet',
	},
	{
		code: '81112200',
		label: 'Mantenimiento y soporte de software',

		palabrasSimilares: 'Mantenimiento y soporte de programas para computadoras',
	},
	{
		code: '81112201',
		label: 'Tarifas de soporte o mantenimiento',
	},
	{
		code: '81112202',
		label: 'Actualizaciones o parches de software',
	},
	{
		code: '81112203',
		label: 'Servicio de actualización o parcheo de firmware',
	},
	{
		code: '81112204',
		label: 'Mantenimiento de software de sistemas operativos',

		palabrasSimilares: 'Mantenimiento de programas para sistemas operativos',
	},
	{
		code: '81112205',
		label: 'Mantenimiento de software de sistemas de gestión de bases de datos',

		palabrasSimilares:
			'Mantenimiento de programas para sistemas de gestión de bases de datos',
	},
	{
		code: '81112206',
		label:
			'Mantenimiento de software de búsqueda o recuperación de la información',

		palabrasSimilares:
			'Mantenimiento de programas para búsqueda o recuperación de la información',
	},
	{
		code: '81112207',
		label: 'Mantenimiento de software de videoconferencias',

		palabrasSimilares: 'Mantenimiento de programas para videoconferencias',
	},
	{
		code: '81112208',
		label: 'Mantenimiento de software de protección y seguridad',

		palabrasSimilares: 'Mantenimiento de programas para protección y seguridad',
	},
	{
		code: '81112209',
		label: 'Mantenimiento de software de desarrollo',

		palabrasSimilares: 'Mantenimiento de programas para desarrollo',
	},
	{
		code: '81112210',
		label: 'Mantenimiento de software de gestión de sistemas',

		palabrasSimilares: 'Mantenimiento de programas para gestión de sistemas',
	},
	{
		code: '81112211',
		label:
			'Mantenimiento de software de planificación de recursos empresariales',

		palabrasSimilares:
			'Mantenimiento de programas para planificación de recursos empresariales',
	},
	{
		code: '81112212',
		label:
			'Mantenimiento de software de administración de relaciones con el consumidor',

		palabrasSimilares:
			'Mantenimiento de programas para administración de relaciones con el consumidor',
	},
	{
		code: '81112213',
		label: 'Mantenimiento del software de contabilidad',

		palabrasSimilares: 'Mantenimiento de programas para contabilidad',
	},
	{
		code: '81112214',
		label: 'Mantenimiento de software de autoría y edición de contenido',

		palabrasSimilares:
			'Mantenimiento de programas para autoría y edición de contenido',
	},
	{
		code: '81112215',
		label: 'Mantenimiento de software de gestión del contenido',

		palabrasSimilares: 'Mantenimiento de programas para gestión del contenido',
	},
	{
		code: '81112216',
		label: 'Mantenimiento de software de referencia o educativo',

		palabrasSimilares: 'Mantenimiento de programas para referencia o educativo',
	},
	{
		code: '81112217',
		label: 'Mantenimiento de software específico de industrias',

		palabrasSimilares: 'Mantenimiento de programa específico de industrias',
	},
	{
		code: '81112218',
		label: 'Aplicaciones para el mantenimiento de software.',

		palabrasSimilares: 'Aplicaciones para el mantenimiento de programas',
	},
	{
		code: '81112219',
		label:
			'Mantenimiento de software de juegos o entretenimiento de computador',

		palabrasSimilares:
			'Mantenimiento de programas para juegos o entretenimiento de computadora',
	},
	{
		code: '81112220',
		label: 'Mantenimiento de software de servidores',

		palabrasSimilares: 'Mantenimiento de programas para servidores',
	},
	{
		code: '81112221',
		label: 'Servicio de mantenimiento del software del punto de venta',

		palabrasSimilares: 'Mantenimiento de programas para puntos de venta',
	},
	{
		code: '81112222',
		label:
			'Mantenimiento de software de gestión mantenimiento y operación de las instalaciones',

		palabrasSimilares:
			'Mantenimiento de programas para gestión mantenimiento y operación de las instalaciones',
	},
	{
		code: '81112300',
		label: 'Mantenimiento y soporte de hardware de computador',

		palabrasSimilares: 'Mantenimiento y soporte de programas para computadoras',
	},
	{
		code: '81112301',
		label: 'Mantenimiento de sistemas de almacenamiento de discos',
	},
	{
		code: '81112302',
		label: 'Mantenimiento de sistemas de copias de seguridad o de nearline',
	},
	{
		code: '81112303',
		label: 'Mantenimiento de computadores “mainframe”',

		palabrasSimilares: 'Mantenimiento de grandes ordenadores',
	},
	{
		code: '81112304',
		label: 'Mantenimiento de servidores unix',
	},
	{
		code: '81112305',
		label: 'Mantenimiento de servidores x86',
	},
	{
		code: '81112306',
		label: 'Mantenimiento de impresoras',
	},
	{
		code: '81112307',
		label: 'Mantenimiento de pc o puestos de trabajo o cuadernos',

		palabrasSimilares:
			'Mantenimiento de PC, estaciones de trabajo o portátiles',
	},
	{
		code: '81112308',
		label: 'Servicio de soporte y mantenimiento de hardware de puntos de venta',
	},
	{
		code: '81112309',
		label:
			'Servicio de instalación o implementación de hardware de puntos de venta',
	},
	{
		code: '81112400',
		label: 'Servicios de alquiler o arrendamiento de hardware de computador',
	},
	{
		code: '81112401',
		label: 'Alquiler de hardware de computadores',

		palabrasSimilares:
			'Renta de componentes materiales de computadoras, Renta de equipos de cómputo, Servicio de cafeinternet o cibercaf',
	},
	{
		code: '81112500',
		label:
			'Servicios de alquiler o arrendamiento de licencias de software de computador',
	},
	{
		code: '81112501',
		label: 'Servicio de licencias del software del computador',

		palabrasSimilares: 'Servicio de licencias de programas para computadora',
	},
	{
		code: '81112502',
		label: 'Servicio de arriendo o leasing de software de computadores',

		palabrasSimilares:
			'Servicio de renta o alquiler de programas para computadora',
	},
	{
		code: '81121500',
		label: 'Análisis económico',

		palabrasSimilares: 'Servicio de análisis económico',
	},
	{
		code: '81121501',
		label: 'Análisis macroeconómico',
	},
	{
		code: '81121502',
		label: 'Análisis microeconómico',
	},
	{
		code: '81121503',
		label: 'Econometría',
	},
	{
		code: '81121504',
		label: 'Proyecciones económicas',

		palabrasSimilares: 'Servicio de previsiones económicas',
	},
	{
		code: '81121600',
		label: 'Sistemas y emisiones monetarias',
	},
	{
		code: '81121601',
		label: 'Política monetaria',
	},
	{
		code: '81121602',
		label: 'Sistemas monetarios',
	},
	{
		code: '81121603',
		label: 'Análisis monetario',
	},
	{
		code: '81121604',
		label: 'Liquidez monetaria',
	},
	{
		code: '81121605',
		label: 'Reservas de metales preciosos',
	},
	{
		code: '81121606',
		label: 'Control de cambio de divisas',
	},
	{
		code: '81121607',
		label: 'Mercados cambio de divisas',
	},
	{
		code: '81131500',
		label: 'Metodología y Análisis',

		palabrasSimilares: 'Servicios de metodología y análisis',
	},
	{
		code: '81131501',
		label: 'Análisis de factores',
	},
	{
		code: '81131502',
		label: 'Análisis multivariate',
	},
	{
		code: '81131503',
		label: 'Análisis de regresión',
	},
	{
		code: '81131504',
		label: 'Encuestas por muestreo',
	},
	{
		code: '81131505',
		label: 'Análisis de series temporales',
	},
	{
		code: '81141500',
		label: 'Control de calidad',

		palabrasSimilares: 'Servicios de control de calidad',
	},
	{
		code: '81141501',
		label: 'Ensayo de materiales',

		palabrasSimilares:
			'Determinación de propiedades mecánicas de los materiales',
	},
	{
		code: '81141502',
		label: 'Síntesis de materiales',
	},
	{
		code: '81141503',
		label: 'Inspección de materiales o productos',
	},
	{
		code: '81141504',
		label: 'Reparación o calibración de pruebas de equipo',
	},
	{
		code: '81141505',
		label: 'Desarrollo de estándares de producción',
	},
	{
		code: '81141506',
		label: 'Ensayo de productos',
	},
	{
		code: '81141600',
		label: 'Manejo de cadena de suministros',

		palabrasSimilares: 'Servicios de manejo de cadena de suministros',
	},
	{
		code: '81141601',
		label: 'Logística',

		palabrasSimilares: 'Maniobras terrestres, aéreas y marítimas',
	},
	{
		code: '81141602',
		label: 'Análisis de tránsito',
	},
	{
		code: '81141603',
		label: 'Economía o finanzas del transporte',
	},
	{
		code: '81141604',
		label: 'Facilitación del transporte',
	},
	{
		code: '81141605',
		label: 'Infraestructura del transporte',
	},
	{
		code: '81141606',
		label: 'Planificación del transporte',
	},
	{
		code: '81141700',
		label: 'Planeación y control de producción',

		palabrasSimilares: 'Servicios de planeación y control de producción',
	},
	{
		code: '81141701',
		label: 'Planificación de la producción',
	},
	{
		code: '81141702',
		label: 'Control de la producción',
	},
	{
		code: '81141703',
		label: 'Programación de la producción',

		palabrasSimilares: 'Calendarización de la producción',
	},
	{
		code: '81141704',
		label: 'Servicios de análisis o recopilación de estadísticas de producción',
	},
	{
		code: '81141800',
		label: 'Administración de instalaciones',

		palabrasSimilares: 'Servicios de administración de instalaciones',
	},
	{
		code: '81141801',
		label: 'Análisis de riesgo o seguridad',
	},
	{
		code: '81141802',
		label: 'Higiene o ventilación industrial',
	},
	{
		code: '81141803',
		label: 'Control del ruido o de la acústica',
	},
	{
		code: '81141804',
		label: 'Servicio de inspección de equipos',
	},
	{
		code: '81141805',
		label: 'Servicio de inspección de edificios',

		palabrasSimilares: 'Servicio de inspección de instalaciones',
	},
	{
		code: '81141806',
		label: 'Servicios de inspección de líneas de energía eléctrica',
	},
	{
		code: '81141807',
		label: 'Servicios de inspección de plomería o alcantarillado',
	},
	{
		code: '81141900',
		label:
			'Servicios de investigación y desarrollo de tecnología manufacturera',
	},
	{
		code: '81141901',
		label: 'Servicio de investigación y desarrollo de productos',
	},
	{
		code: '81141902',
		label:
			'Servicio de investigación y desarrollo de aplicaciones o tecnología',
	},
	{
		code: '81151500',
		label: 'Meteorología',

		palabrasSimilares: 'Servicios de meteorología',
	},
	{
		code: '81151501',
		label: 'Climatología',

		palabrasSimilares: 'Servicios de estudios del clima y sus variaciones',
	},
	{
		code: '81151502',
		label: 'Servicios meteorológicos',
	},
	{
		code: '81151503',
		label: 'Hidrometeorología',

		palabrasSimilares: 'Servicios de estudios del ciclo del agua',
	},
	{
		code: '81151600',
		label: 'Cartografía',

		palabrasSimilares: 'Servicios de cartografía',
	},
	{
		code: '81151601',
		label: 'Cartografía',

		palabrasSimilares: 'Servicios de cartografía',
	},
	{
		code: '81151602',
		label: 'Producción de mapas',

		palabrasSimilares: 'Servicios de producción de mapas',
	},
	{
		code: '81151603',
		label: 'Fotogrametría',

		palabrasSimilares: 'Servicios de mapas mediante fotografía aérea',
	},
	{
		code: '81151604',
		label: 'Agrimensura',

		palabrasSimilares:
			'Servicios de delimitación de superficies conforme a derecho',
	},
	{
		code: '81151700',
		label: 'Geología',

		palabrasSimilares: 'Servicios de geología',
	},
	{
		code: '81151701',
		label: 'Fotogeología',

		palabrasSimilares: 'Servicios de interpretación de fotografía aérea',
	},
	{
		code: '81151702',
		label: 'Geología estratigráfica',

		palabrasSimilares:
			'Servicios de estudio e interpretación de rocas sedimentarias estratificadas',
	},
	{
		code: '81151703',
		label: 'Estudios geológicos',

		palabrasSimilares: 'Servicios de estudios geológicos',
	},
	{
		code: '81151704',
		label: 'Exploración geológica',

		palabrasSimilares: 'Servicios de exploración geológica',
	},
	{
		code: '81151705',
		label: 'Servicios arqueológicos',
	},
	{
		code: '81151800',
		label: 'Oceanografía e hidrología',

		palabrasSimilares: 'Servicios de oceanografía e hidrología',
	},
	{
		code: '81151801',
		label: 'Estudio oceanográfico',

		palabrasSimilares: 'Servicios de estudios oceanográficos',
	},
	{
		code: '81151802',
		label: 'Oceanografía estuarina',

		palabrasSimilares:
			'Servicios de estudios de desembocaduras de ríos a mares u océanos',
	},
	{
		code: '81151803',
		label: 'Oceanografía física',

		palabrasSimilares:
			'Servicios de estudios de las condiciones y procesos físicos de los océanos',
	},
	{
		code: '81151804',
		label: 'Estudios hidrológicos',

		palabrasSimilares: 'Servicios de estudios hidrológicos',
	},
	{
		code: '81151805',
		label: 'Estudios batimétricos',

		palabrasSimilares: 'Servicios de estudios de profundidades marinas',
	},
	{
		code: '81151806',
		label: 'Exploración submarina',

		palabrasSimilares: 'Servicios de exploración submarina',
	},
	{
		code: '81151900',
		label: 'Geofísica',

		palabrasSimilares: 'Servicios de geofísica',
	},
	{
		code: '81151901',
		label: 'Estudios geofísicos',

		palabrasSimilares: 'Servicios de estudios geofísicos',
	},
	{
		code: '81151902',
		label: 'Exploración geofísica',

		palabrasSimilares: 'Servicios de exploración geofísica',
	},
	{
		code: '81151903',
		label: 'Interpretación de fotografía geofísica',

		palabrasSimilares: 'Servicios de interpretación de fotografía geofísica',
	},
	{
		code: '81151904',
		label: 'Geofísica aeromagnética',

		palabrasSimilares: 'Servicios de geofísica aeromagnética',
	},
	{
		code: '81161500',
		label: 'Servicios de administración de acceso',

		palabrasSimilares:
			'Servicios de administración de acceso a través de redes',
	},
	{
		code: '81161501',
		label: 'Servicio de administración de aplicaciones de software',
	},
	{
		code: '81161502',
		label: 'Servicio de administración de cuentas en la red',
	},
	{
		code: '81161503',
		label: 'Servicio de administración de carpetas en la red',
	},
	{
		code: '81161600',
		label: 'Servicios de correo electrónico y mensajería',
	},
	{
		code: '81161601',
		label: 'Servicio de administración de mensajería instantánea',
	},
	{
		code: '81161700',
		label: 'Servicios de telecomunicaciones',
	},
	{
		code: '81161701',
		label: 'Servicio de administración de fax',
	},
	{
		code: '81161702',
		label: 'Servicio de soporte para fax',
	},
	{
		code: '81161703',
		label: 'Servicio de administración de telefonía móvil',

		palabrasSimilares: 'Servicio de administración de telefonía celular',
	},
	{
		code: '81161704',
		label: 'Servicio de soporte para telefonía móvil',

		palabrasSimilares: 'Servicio de soporte para telefonía celular',
	},
	{
		code: '81161705',
		label: 'Servicio de administración de radiobúsquedas',
	},
	{
		code: '81161706',
		label: 'Servicio de soporte para radiobúsquedas',
	},
	{
		code: '81161707',
		label: 'Servicio de administración telefónica',
	},
	{
		code: '81161708',
		label: 'Servicio de soporte telefónico',
	},
	{
		code: '81161709',
		label: 'Servicio de administración del correo de voz',
	},
	{
		code: '81161710',
		label: 'Servicios de soporte del correo de voz',
	},
	{
		code: '81161711',
		label: 'Servicio de videoconferencias',
	},
	{
		code: '81161712',
		label: 'Servicio de voz en la red',
	},
	{
		code: '81161800',
		label:
			'Servicios de alquiler o arrendamiento de equipos o plataformas de voz y datos o multimedia',
	},
	{
		code: '81161801',
		label:
			'Servicio de arriendo o leasing de plataformas o equipos de comunicación de datos',

		palabrasSimilares:
			'Servicios de renta o alquiler de plataformas o equipos de comunicación de datos',
	},
	{
		code: '82101500',
		label: 'Publicidad impresa',

		palabrasSimilares: 'Servicios de publicidad impresa',
	},
	{
		code: '82101501',
		label: 'Publicidad en vallas',

		palabrasSimilares: 'Servicios de publicidad en espectaculares',
	},
	{
		code: '82101502',
		label: 'Publicidad en afiches',

		palabrasSimilares:
			'Servicios de publicidad en cartel, Servicios de publicidad en posters',
	},
	{
		code: '82101503',
		label: 'Publicidad en revistas',
	},
	{
		code: '82101504',
		label: 'Publicidad en periódicos',
	},
	{
		code: '82101505',
		label: 'Publicidad en volantes o cupones',

		palabrasSimilares: 'Servicios de publicidad en folletos o cupones',
	},
	{
		code: '82101506',
		label: 'Servicios de publicidad en transporte público',
	},
	{
		code: '82101507',
		label:
			'Servicios de distribución o de publicidad o de boletines de sobre compras',
	},
	{
		code: '82101508',
		label:
			'Publicidad en páginas amarillas o en directorios comerciales o de servicios',

		palabrasSimilares:
			'Servicios de publicidad en páginas amarillas o en directorios comerciales o de servicios',
	},
	{
		code: '82101600',
		label: 'Publicidad difundida',

		palabrasSimilares: 'Servicios de publicidad difundida',
	},
	{
		code: '82101601',
		label: 'Publicidad en radio',

		palabrasSimilares: 'Servicios de publicidad en radio',
	},
	{
		code: '82101602',
		label: 'Publicidad en televisión',

		palabrasSimilares: 'Servicios de publicidad en televisión',
	},
	{
		code: '82101603',
		label: 'Publicidad en internet',

		palabrasSimilares: 'Servicios de publicidad en internet',
	},
	{
		code: '82101604',
		label: 'Publicidad en los cines',

		palabrasSimilares: 'Servicios de publicidad en cines',
	},
	{
		code: '82101605',
		label: 'Servicio de producción de comerciales para televisión',
	},
	{
		code: '82101700',
		label: 'Publicidad aérea',

		palabrasSimilares: 'Servicios de publicidad aérea',
	},
	{
		code: '82101701',
		label: 'Servicios de publicidad en pancartas',

		palabrasSimilares: 'Servicios de publicidad en lonas',
	},
	{
		code: '82101702',
		label: 'Servicios de publicidad aérea',
	},
	{
		code: '82101800',
		label: 'Servicios de agencia de publicidad',
	},
	{
		code: '82101801',
		label: 'Servicios de campañas publicitarias',
	},
	{
		code: '82101802',
		label: 'Servicios de producción publicitaria',
	},
	{
		code: '82101900',
		label: 'Colocación y cumplimiento de medios',

		palabrasSimilares: 'Servicios de colocación y cumplimiento en medios',
	},
	{
		code: '82101901',
		label: 'Inserción en radio',

		palabrasSimilares: 'Servicios de colocación de publicidad en radio',
	},
	{
		code: '82101902',
		label: 'Inserción en televisión',

		palabrasSimilares: 'Servicios de colocación de publicidad en televisión',
	},
	{
		code: '82101903',
		label: 'Inserción en internet',

		palabrasSimilares: 'Servicios de colocación de publicidad en internet',
	},
	{
		code: '82101904',
		label: 'Inserción en cines',

		palabrasSimilares: 'Servicios de colocación de publicidad en cines',
	},
	{
		code: '82101905',
		label: 'Inserción en medios impresos',

		palabrasSimilares:
			'Servicios de colocación de publicidad en medios impresos',
	},
	{
		code: '82111500',
		label: 'Escritura técnica',

		palabrasSimilares: 'Servicios de escritura técnica',
	},
	{
		code: '82111501',
		label: 'Servicios de redacción  de instrucciones',
	},
	{
		code: '82111502',
		label: 'Servicios de redacción de manuales',

		palabrasSimilares: 'Servicios de elaboración de manuales',
	},
	{
		code: '82111503',
		label: 'Redacción de artículos académicos o científicos',
	},
	{
		code: '82111600',
		label: 'Escritura no técnica',

		palabrasSimilares: 'Servicios de escritura no técnica',
	},
	{
		code: '82111601',
		label: 'Servicios de redacción de cartas',
	},
	{
		code: '82111602',
		label: 'Servicios de redacción del currículum vítae',
	},
	{
		code: '82111603',
		label: 'Servicios de informes de tribunales',
	},
	{
		code: '82111604',
		label: 'Servicios de transcripción',
	},
	{
		code: '82111700',
		label: 'Escritura creativa',

		palabrasSimilares: 'Servicios de escritura creativa',
	},
	{
		code: '82111701',
		label: 'Servicios de escritores de artículos',

		palabrasSimilares: 'Regalías por derechos de autor',
	},
	{
		code: '82111702',
		label: 'Servicios de autores de libros',

		palabrasSimilares: 'Regalías por derechos de autor',
	},
	{
		code: '82111703',
		label: 'Servicios de autores de poesía',

		palabrasSimilares: 'Regalías por derechos de autor',
	},
	{
		code: '82111704',
		label: 'Escritura de textos publicitarios',

		palabrasSimilares: 'Servicios de redactor creativo',
	},
	{
		code: '82111705',
		label: 'Escritura de discursos',
	},
	{
		code: '82111800',
		label: 'Servicios editoriales y de soporte',
	},
	{
		code: '82111801',
		label: 'Servicios de edición',
	},
	{
		code: '82111802',
		label: 'Servicios de comprobación de hechos',

		palabrasSimilares: 'Servicios de verificación de hechos',
	},
	{
		code: '82111803',
		label: 'Servicios de corrección de pruebas',
	},
	{
		code: '82111804',
		label: 'Servicios de traducción escrita',
	},
	{
		code: '82111900',
		label: 'Servicios de noticias y publicidad',
	},
	{
		code: '82111901',
		label: 'Servicios de comunicados de prensa',
	},
	{
		code: '82111902',
		label: 'Servicios de boletines informativos de interés especial',
	},
	{
		code: '82111903',
		label: 'Servicios telegráficos de agencias de noticias',
	},
	{
		code: '82111904',
		label: 'Servicios de entrega de periódicos o material  publicitario',
	},
	{
		code: '82112000',
		label: 'Servicios de interpretación de idiomas de forma presencial',

		palabrasSimilares: 'Servicios de traductores de idiomas',
	},
	{
		code: '82112001',
		label: 'Servicio de interpretación en persona de afgano o pastún',

		palabrasSimilares: 'Servicios de traductor en afgano o pastún',
	},
	{
		code: '82112002',
		label: 'Servicio de interpretación en persona de albanés',

		palabrasSimilares: 'Servicios de traductor en albanés',
	},
	{
		code: '82112003',
		label: 'Servicio de interpretación en persona amárico',

		palabrasSimilares: 'Servicios de traductor en amárico',
	},
	{
		code: '82112004',
		label: 'Servicio de interpretación en persona de árabe',

		palabrasSimilares: 'Servicios de traductor en árabe',
	},
	{
		code: '82112005',
		label: 'Servicio de interpretación en persona de armenio',

		palabrasSimilares: 'Servicios de traductor en armenio',
	},
	{
		code: '82112006',
		label: 'Servicio de interpretación en persona de bangladés',

		palabrasSimilares: 'Servicios de traductor en bangladés',
	},
	{
		code: '82112007',
		label: 'Servicio de interpretación en persona de bielorruso',

		palabrasSimilares: 'Servicios de traductor en bielorruso',
	},
	{
		code: '82112008',
		label: 'Servicio de interpretación en persona de bengalí',

		palabrasSimilares: 'Servicios de traductor en bengalí',
	},
	{
		code: '82112009',
		label: 'Servicio de interpretación en persona de bosnio',

		palabrasSimilares: 'Servicios de traductor en bosnio',
	},
	{
		code: '82112010',
		label: 'Servicio de interpretación en persona de búlgaro',

		palabrasSimilares: 'Servicios de traductor en búlgaro',
	},
	{
		code: '82112011',
		label: 'Servicio de interpretación en persona de camboyano',

		palabrasSimilares: 'Servicios de traductor en camboyano',
	},
	{
		code: '82112012',
		label: 'Servicio de interpretación en persona de chino',

		palabrasSimilares: 'Servicios de traductor en chino',
	},
	{
		code: '82112013',
		label: 'Servicio de interpretación en persona de creole',

		palabrasSimilares: 'Servicios de traductor en creole',
	},
	{
		code: '82112014',
		label: 'Servicio de interpretación en persona de croata',

		palabrasSimilares: 'Servicios de traductor en croata',
	},
	{
		code: '82112015',
		label: 'Servicio de interpretación en persona de checo',

		palabrasSimilares: 'Servicios de traductor en checo',
	},
	{
		code: '82112016',
		label: 'Servicio de interpretación en persona de danés',

		palabrasSimilares: 'Servicios de traductor en danés',
	},
	{
		code: '82112017',
		label: 'Servicio de interpretación en persona de dinka',

		palabrasSimilares: 'Servicios de traductor en dinka',
	},
	{
		code: '82112018',
		label: 'Servicio de interpretación en persona de holandés',

		palabrasSimilares: 'Servicios de traductor en holandés',
	},
	{
		code: '82112019',
		label: 'Servicio de interpretación en persona de egipcio',

		palabrasSimilares: 'Servicios de traductor en egipcio',
	},
	{
		code: '82112020',
		label: 'Servicio de interpretación en persona de fanti',

		palabrasSimilares: 'Servicios de traductor en fanti',
	},
	{
		code: '82112021',
		label: 'Servicio de interpretación en persona de fanti o persa',

		palabrasSimilares: 'Servicios de traductor en fanti o persa',
	},
	{
		code: '82112022',
		label: 'Servicio de interpretación en persona de francés',

		palabrasSimilares: 'Servicios de traductor en francés',
	},
	{
		code: '82112023',
		label: 'Servicio de interpretación en persona de alemán',

		palabrasSimilares: 'Servicios de traductor en alemán',
	},
	{
		code: '82112024',
		label: 'Servicio de interpretación en persona de griego',

		palabrasSimilares: 'Servicios de traductor en griego',
	},
	{
		code: '82112025',
		label: 'Servicio de interpretación en persona de guyaratí',

		palabrasSimilares: 'Servicios de traductor en guyaratí',
	},
	{
		code: '82112026',
		label: 'Servicio de interpretación en persona de hebreo',

		palabrasSimilares: 'Servicios de traductor en hebreo',
	},
	{
		code: '82112027',
		label: 'Servicio de interpretación en persona de hindi',

		palabrasSimilares: 'Servicios de traductor en hindi',
	},
	{
		code: '82112028',
		label: 'Servicio de interpretación en persona de hmong',

		palabrasSimilares: 'Servicios de traductor en hmong',
	},
	{
		code: '82112029',
		label: 'Servicio de interpretación en persona de indoamericano',

		palabrasSimilares: 'Servicios de traductor en indoamericano',
	},
	{
		code: '82112030',
		label: 'Servicio de interpretación en persona de indonesio',

		palabrasSimilares: 'Servicios de traductor en indonesio',
	},
	{
		code: '82112031',
		label: 'Servicio de interpretación en persona de italiano',

		palabrasSimilares: 'Servicios de traductor en italiano',
	},
	{
		code: '82112032',
		label: 'Servicio de interpretación en persona de jamaiquino',

		palabrasSimilares: 'Servicios de traductor en jamaiquino',
	},
	{
		code: '82112033',
		label: 'Servicio de interpretación en persona de japonés',

		palabrasSimilares: 'Servicios de traductor en japonés',
	},
	{
		code: '82112034',
		label: 'Servicio de interpretación en persona de kirguís',

		palabrasSimilares: 'Servicios de traductor en kirguís',
	},
	{
		code: '82112035',
		label: 'Servicio de interpretación en persona de coreano',

		palabrasSimilares: 'Servicios de traductor en coreano',
	},
	{
		code: '82112036',
		label: 'Servicio de interpretación en persona de kurdo',

		palabrasSimilares: 'Servicios de traductor en kurdo',
	},
	{
		code: '82112037',
		label: 'Servicio de interpretación en persona de lituano',

		palabrasSimilares: 'Servicios de traductor en lituano',
	},
	{
		code: '82112038',
		label: 'Servicio de interpretación en persona de malayalam',

		palabrasSimilares: 'Servicios de traductor en malayalam',
	},
	{
		code: '82112039',
		label: 'Servicio de interpretación en persona de mandinga',

		palabrasSimilares: 'Servicios de traductor en mandinga',
	},
	{
		code: '82112040',
		label: 'Servicio de interpretación en persona de nativo americano',

		palabrasSimilares: 'Servicios de traductor en nativo americano',
	},
	{
		code: '82112041',
		label: 'Servicio de interpretación en persona de pakistaní',

		palabrasSimilares: 'Servicios de traductor en pakistaní',
	},
	{
		code: '82112042',
		label: 'Servicio de interpretación en persona de panyabí oriental',

		palabrasSimilares: 'Servicios de traductor en panyabí oriental',
	},
	{
		code: '82112043',
		label: 'Servicio de interpretación en persona de panyabí occidental',

		palabrasSimilares: 'Servicios de traductor en panyabí occidental',
	},
	{
		code: '82112044',
		label: 'Servicio de interpretación en persona de polaco',

		palabrasSimilares: 'Servicios de traductor en polaco',
	},
	{
		code: '82112045',
		label: 'Servicio de interpretación en persona de portugués',

		palabrasSimilares: 'Servicios de traductor en portugués',
	},
	{
		code: '82112046',
		label: 'Servicio de interpretación en persona de rumano',

		palabrasSimilares: 'Servicios de traductor en rumano',
	},
	{
		code: '82112047',
		label: 'Servicio de interpretación en persona de romaní',

		palabrasSimilares: 'Servicios de traductor en romaní',
	},
	{
		code: '82112048',
		label: 'Servicio de interpretación en persona de ruso',

		palabrasSimilares: 'Servicios de traductor en ruso',
	},
	{
		code: '82112049',
		label: 'Servicio de interpretación en persona de ruandés',

		palabrasSimilares: 'Servicios de traductor en ruandés',
	},
	{
		code: '82112050',
		label: 'Servicio de interpretación en persona de samoano',

		palabrasSimilares: 'Servicios de traductor en samoano',
	},
	{
		code: '82112051',
		label: 'Servicio de interpretación en persona de serbio',

		palabrasSimilares: 'Servicios de traductor en serbio',
	},
	{
		code: '82112052',
		label: 'Servicio de interpretación en persona de serbocroata',

		palabrasSimilares: 'Servicios de traductor en serbocroata',
	},
	{
		code: '82112053',
		label: 'Servicio de interpretación en persona de esloveno',

		palabrasSimilares: 'Servicios de traductor en esloveno',
	},
	{
		code: '82112054',
		label: 'Servicio de interpretación en persona de somalí',

		palabrasSimilares: 'Servicios de traductor en somalí',
	},
	{
		code: '82112055',
		label: 'Servicio de interpretación en persona de español',

		palabrasSimilares: 'Servicios de traductor en español',
	},
	{
		code: '82112056',
		label: 'Servicio de interpretación en persona de suajili',

		palabrasSimilares: 'Servicios de traductor en suajili',
	},
	{
		code: '82112057',
		label: 'Servicio de interpretación en persona de sueco',

		palabrasSimilares: 'Servicios de traductor en sueco',
	},
	{
		code: '82112058',
		label: 'Servicio de interpretación en persona de taiwanés',

		palabrasSimilares: 'Servicios de traductor en taiwanés',
	},
	{
		code: '82112059',
		label: 'Servicio de interpretación en persona de tailandés',

		palabrasSimilares: 'Servicios de traductor en tailandés',
	},
	{
		code: '82112060',
		label: 'Servicio de interpretación en persona de tibetano',

		palabrasSimilares: 'Servicios de traductor en tibetano',
	},
	{
		code: '82112061',
		label: 'Servicio de interpretación en persona de turco',

		palabrasSimilares: 'Servicios de traductor en turco',
	},
	{
		code: '82112062',
		label: 'Servicio de interpretación en persona de ucraniano',

		palabrasSimilares: 'Servicios de traductor en ucraniano',
	},
	{
		code: '82112063',
		label: 'Servicio de interpretación en persona de vietnamita',

		palabrasSimilares: 'Servicios de traductor en vietnamita',
	},
	{
		code: '82112064',
		label: 'Servicio de interpretación en persona de yidis',

		palabrasSimilares: 'Servicios de traductor en yidis',
	},
	{
		code: '82112065',
		label: 'Servicio de interpretación en persona de yugoslavo',

		palabrasSimilares: 'Servicios de traductor en yugoslavo',
	},
	{
		code: '82112066',
		label: 'Servicio de interpretación en persona de húngaro',

		palabrasSimilares: 'Servicios de traductor en húngaro',
	},
	{
		code: '82121500',
		label: 'Impresión',
	},
	{
		code: '82121501',
		label: 'Planificación  y trazados de producciones gráficas',
	},
	{
		code: '82121502',
		label: 'Tipografía',
	},
	{
		code: '82121503',
		label: 'Impresión digital',
	},
	{
		code: '82121504',
		label: 'Impresión tipográfica o por serigrafía',
	},
	{
		code: '82121505',
		label: 'Impresión promocional o publicitaria',
	},
	{
		code: '82121506',
		label: 'Impresión de publicaciones',
	},
	{
		code: '82121507',
		label: 'Impresión de papelería o formularios comerciales',
	},
	{
		code: '82121508',
		label: 'Impresión de envolturas, etiquetas, sellos o bolsas',
	},
	{
		code: '82121509',
		label: 'Impresión de valores o instrumentos financieros',
	},
	{
		code: '82121510',
		label: 'Impresión textil',
	},
	{
		code: '82121511',
		label: 'Impresión de hoja de instrucciones o manual técnico',
	},
	{
		code: '82121512',
		label: 'Impresión en relieve',
	},
	{
		code: '82121600',
		label: 'Grabado',
	},
	{
		code: '82121601',
		label: 'Grabado de monedas',
	},
	{
		code: '82121602',
		label: 'Impresión de rollo grabado',
	},
	{
		code: '82121603',
		label: 'Grabado de planchas metálicas',
	},
	{
		code: '82121700',
		label: 'Fotocopiado',
	},
	{
		code: '82121701',
		label: 'Servicios de copias en blanco y negro o de cotejo',
	},
	{
		code: '82121702',
		label: 'Servicios de copias a color o de cotejo',
	},
	{
		code: '82121800',
		label: 'Publicación',
	},
	{
		code: '82121801',
		label: 'Publicación de libros de texto o de investigación',
	},
	{
		code: '82121802',
		label: 'Servicios de publicaciones financiadas por el autor',
	},
	{
		code: '82121900',
		label: 'Empaste de libros',
	},
	{
		code: '82121901',
		label: 'Encuadernación en rústica',
	},
	{
		code: '82121902',
		label: 'Encuadernación espiral',

		palabrasSimilares: 'Servicios de engargolado',
	},
	{
		code: '82121903',
		label: 'Encuadernación con pegante',
	},
	{
		code: '82121904',
		label: 'Encuadernación por carda o grapa',
	},
	{
		code: '82121905',
		label: 'Restauración o reparación de encuadernaciones',

		palabrasSimilares: 'Restauración o reparación de engargolado',
	},
	{
		code: '82121906',
		label: 'Bronceado o dorado o ribeteado o bordeado',
	},
	{
		code: '82121907',
		label: 'Servicios de encuadernación en vitela',
	},
	{
		code: '82121908',
		label: 'Servicios de fabricación de maletas',
	},
	{
		code: '82131500',
		label: 'Servicios de procesado de películas',
	},
	{
		code: '82131501',
		label: 'Elaboración o reproducción de película fija',
	},
	{
		code: '82131502',
		label: 'Elaboración o reproducción de películas de cine',
	},
	{
		code: '82131503',
		label: 'Servicios de microficha',
	},
	{
		code: '82131504',
		label: 'Separación de colores',
	},
	{
		code: '82131505',
		label: 'Servicio de posproducción de películas',
	},
	{
		code: '82131600',
		label: 'Fotógrafos y cinematógrafos',
	},
	{
		code: '82131601',
		label: 'Servicios de fotografía aérea',
	},
	{
		code: '82131602',
		label: 'Cinematografía',
	},
	{
		code: '82131603',
		label: 'Servicios de producción de vídeos',
	},
	{
		code: '82131604',
		label: 'Servicios de estudio fotográfico  o fotos fijas',
	},
	{
		code: '82141500',
		label: 'Servicios de diseño artístico',
	},
	{
		code: '82141501',
		label: 'Servicios de redacción de gráficas y planes y trazados',
	},
	{
		code: '82141502',
		label: 'Diseño o gráficos artísticos',
	},
	{
		code: '82141503',
		label: 'Fotocomposición',
	},
	{
		code: '82141504',
		label: 'Servicios de diseño de gráficos o gráficas',
	},
	{
		code: '82141505',
		label: 'Servicios de diseño por computador',
	},
	{
		code: '82141506',
		label: 'Servicios de diseño de empaques',
	},
	{
		code: '82141507',
		label: 'Servicios de diseño de serigrafía',
	},
	{
		code: '82141600',
		label: 'Servicios de exhibición gráfica',
	},
	{
		code: '82141601',
		label: 'Servicios fotográficos, de montaje o enmarcado',
	},
	{
		code: '82141602',
		label: 'Montaje de exposición de artículos',

		palabrasSimilares: 'Desmontaje de exposición de artículos',
	},
	{
		code: '82151500',
		label: 'Servicios de artes visuales',
	},
	{
		code: '82151501',
		label: 'Servicios de pintores',
	},
	{
		code: '82151502',
		label: 'Servicios de litógrafos',
	},
	{
		code: '82151503',
		label: 'Servicios de caricaturistas',
	},
	{
		code: '82151504',
		label: 'Servicios de escultores',
	},
	{
		code: '82151505',
		label: 'Servicios de fabricantes de cerámica',
	},
	{
		code: '82151506',
		label: 'Servicios de sopladores de vidrio',
	},
	{
		code: '82151507',
		label: 'Servicios de hiladoras, telares o tejedoras',
	},
	{
		code: '82151508',
		label: 'Servicios de taxidermia',
	},
	{
		code: '82151600',
		label: 'Artistas de circo',
	},
	{
		code: '82151601',
		label: 'Servicios de entrenadores de animales',
	},
	{
		code: '82151602',
		label: 'Servicios de acróbatas',
	},
	{
		code: '82151603',
		label: 'Servicios de magos',
	},
	{
		code: '82151604',
		label: 'Servicios de payasos',
	},
	{
		code: '82151700',
		label: 'Profesionales de las artes escénicas',
	},
	{
		code: '82151701',
		label: 'Servicios de actuación',
	},
	{
		code: '82151702',
		label: 'Servicios de comediantes',
	},
	{
		code: '82151703',
		label: 'Servicios de bailarines',
	},
	{
		code: '82151704',
		label: 'Servicios de músicos',
	},
	{
		code: '82151705',
		label: 'Servicios de vocalistas',
	},
	{
		code: '82151706',
		label: 'Servicios coreográficos',
	},
	{
		code: '83101500',
		label: 'Servicios de acueducto y alcantarillado',

		palabrasSimilares: 'Servicios de agua y alcantarillado',
	},
	{
		code: '83101501',
		label: 'Abastecimiento de agua',
	},
	{
		code: '83101502',
		label: 'Gestión de recursos hidráulicos',
	},
	{
		code: '83101503',
		label: 'Gestión de control de la calidad del agua',
	},
	{
		code: '83101504',
		label: 'Gestión de distribución del agua',
	},
	{
		code: '83101505',
		label: 'Servicios de asesoramiento de política hidráulica',
	},
	{
		code: '83101506',
		label: 'Servicios de tratamiento de aguas',
	},
	{
		code: '83101507',
		label: 'Servicios de desalinización',
	},
	{
		code: '83101508',
		label: 'Agua para la ciudad',
	},
	{
		code: '83101509',
		label: 'Agua para servicios',
	},
	{
		code: '83101510',
		label: 'Agua fría',
	},
	{
		code: '83101600',
		label: 'Servicios de petróleo y gas',
	},
	{
		code: '83101601',
		label: 'Suministro de gas natural',
	},
	{
		code: '83101602',
		label: 'Suministro de fuel-oil',
	},
	{
		code: '83101603',
		label: 'Servicios de oleoductos',
	},
	{
		code: '83101604',
		label: 'Servicios de gasoductos',
	},
	{
		code: '83101605',
		label: 'Carga de las instalaciones de gas',
	},
	{
		code: '83101800',
		label: 'Servicios eléctricos',
	},
	{
		code: '83101801',
		label: 'Suministro de electricidad monofásica',
	},
	{
		code: '83101802',
		label: 'Suministro de electricidad bifásica',
	},
	{
		code: '83101803',
		label: 'Suministro de electricidad  trifásica',
	},
	{
		code: '83101804',
		label: 'Servicios de transmisión de energía eléctrica',

		palabrasSimilares:
			'Servicio de carga de batería para vehículos eléctricos, servicio de carga rápida de vehículos eléctricos',
	},
	{
		code: '83101805',
		label: 'Distribución de energía eléctrica industrial',
	},
	{
		code: '83101806',
		label: 'Distribución de energía eléctrica rural',
	},
	{
		code: '83101807',
		label: 'Distribución de energía eléctrica municipal',
	},
	{
		code: '83101808',
		label: 'Monitoreo de la calidad de la energía',
	},
	{
		code: '83101900',
		label: 'Conservación de energía',
	},
	{
		code: '83101901',
		label: 'Programas de conservación de energía',
	},
	{
		code: '83101902',
		label: 'Medidas de reducción de utilización de la energía',
	},
	{
		code: '83101903',
		label: 'Calefacción del distrito',
	},
	{
		code: '83111500',
		label: 'Comunicaciones telefónicas locales y de larga distancia',
	},
	{
		code: '83111501',
		label: 'Servicio de telefonía local',
	},
	{
		code: '83111502',
		label: 'Servicios telefónicos de larga distancia',
	},
	{
		code: '83111503',
		label: 'Servicios de proveedores de teléfonos con pago',
	},
	{
		code: '83111504',
		label: 'Servicios de tarjetas telefónicas prepagadas',
	},
	{
		code: '83111505',
		label: 'Servicios de asistencia telefónica',
	},
	{
		code: '83111506',
		label: 'Servicios de conferencia telefónica',
	},
	{
		code: '83111507',
		label: 'Servicios de buró de central de llamadas ("call center")',
	},
	{
		code: '83111508',
		label: 'Servicios de llamar sin pago (hacia el interior)',
	},
	{
		code: '83111510',
		label: 'Servicio de respuesta de voz interactiva',
	},
	{
		code: '83111511',
		label: 'Servicio de telecomunicaciones de retransmisión en trama',
	},
	{
		code: '83111600',
		label: 'Servicios de comunicaciones móviles',
	},
	{
		code: '83111601',
		label: 'Servicios en la red para mejorar las señales de telecomunicaciones',
	},
	{
		code: '83111602',
		label: 'Servicios de sistemas de comunicación por satélite o terrestre',
	},
	{
		code: '83111603',
		label: 'Servicios de telefonía celular',
	},
	{
		code: '83111604',
		label: 'Servicios de radiobúsqueda',
	},
	{
		code: '83111605',
		label: 'Leasing de segmentos de espacio',
	},
	{
		code: '83111700',
		label: 'Servicios de facsímil y telégrafos',
	},
	{
		code: '83111701',
		label: 'Servicios de transmisión por fax',
	},
	{
		code: '83111702',
		label: 'Servicios de transmisión telegráfica',
	},
	{
		code: '83111703',
		label: 'Servicios de transmisión por telex',
	},
	{
		code: '83111800',
		label: 'Servicios de televisión',
	},
	{
		code: '83111801',
		label: 'Servicios de televisión por cable',
	},
	{
		code: '83111802',
		label: 'Servicios de televisión por circuito cerrado',
	},
	{
		code: '83111803',
		label: 'Servicios de construcción o alquiler de antenas de televisión',
	},
	{
		code: '83111804',
		label: 'Administración de emisoras de televisión',
	},
	{
		code: '83111900',
		label: 'Servicios de radio',
	},
	{
		code: '83111901',
		label: 'Administración de emisoras de radio',
	},
	{
		code: '83111902',
		label: 'Servicios o redes de radioaficionados',
	},
	{
		code: '83111903',
		label: 'Sistemas de radio de corto alcance',
	},
	{
		code: '83111904',
		label: 'Servicios de estudios o equipos radiofónicos',
	},
	{
		code: '83111905',
		label:
			'Servicios bilaterales internacionales y líneas privadas internacionales arrendadas',
	},
	{
		code: '83112200',
		label: 'Servicios mejorados de telecomunicaciones',
	},
	{
		code: '83112201',
		label: 'Servicios de red en modo de transferencia asíncrona (atm)',
	},
	{
		code: '83112202',
		label: 'Servicios de redes públicas de retransmisión de tramas',
	},
	{
		code: '83112203',
		label: 'Servicios de redes gestionadas por redes  virtuales privadas (vpn)',
	},
	{
		code: '83112204',
		label: 'Servicios de redes manejadas  x75',
	},
	{
		code: '83112205',
		label: 'Servicios de redes manejadas x25',
	},
	{
		code: '83112206',
		label:
			'Servicio de administración de la capacidad del radio direccional para la recuperación de desastres',
	},
	{
		code: '83112300',
		label: 'Servicios de telecomunicaciones por fibra',
	},
	{
		code: '83112301',
		label: 'Fibra oscura',
	},
	{
		code: '83112302',
		label: 'Multiplexado de división de la longitud de onda densa (dwdm)',
	},
	{
		code: '83112303',
		label: 'Multiplexado de división de onda (wdm)',
	},
	{
		code: '83112304',
		label: 'Servicios de transmisión óptica (ocx)',
	},
	{
		code: '83112400',
		label:
			'Servicios de telecomunicaciones de marcado conmutado y circuito de línea dedicada alquilada',
	},
	{
		code: '83112401',
		label: 'Servicios de circuitos de conmutación automática de alta velocidad',
	},
	{
		code: '83112402',
		label: 'Servicios de redes digitales de servicios integrados (isdn)',
	},
	{
		code: '83112403',
		label: 'Circuitos de telecomunicaciones digitales punto a punto',
	},
	{
		code: '83112404',
		label: 'Circuitos de telecomunicaciones analógicas multipunto',
	},
	{
		code: '83112405',
		label: 'Circuitos de telecomunicaciones analógicas punto a punto',
	},
	{
		code: '83112406',
		label: 'Línea digital de abonado (dsl)',
	},
	{
		code: '83112500',
		label: 'Capacidades de eje troncal',
	},
	{
		code: '83112501',
		label:
			'Capacidad del cable submarino y capacidad pop a pop del cable submarino',
	},
	{
		code: '83112502',
		label: 'Capacidades de eje troncal terrestre',
	},
	{
		code: '83112503',
		label:
			'Derechos de paso para el tránsito por sistemas de semicircuitos, procesamiento de datos distribuidos (ddp) y alquiler administrativo',
	},
	{
		code: '83112504',
		label:
			'Derechos no defendibles (iru) de utilización de sistemas de cables submarinos o terrestres',
	},
	{
		code: '83112505',
		label: 'Funcionalidad de interconexión',
	},
	{
		code: '83112506',
		label:
			'Servicio de infraestructura técnica de la capacidad de soporte del radio direccional',
	},
	{
		code: '83112600',
		label: 'Acceso de clientes',

		palabrasSimilares: 'Accesos digitales para clientes',
	},
	{
		code: '83112601',
		label: 'Capacidades de bucle local',

		palabrasSimilares: 'Línea de suscripción digital (DSL)',
	},
	{
		code: '83112602',
		label: 'Líneas domésticas alquiladas',
	},
	{
		code: '83112603',
		label: 'Líneas de acceso internacional',
	},
	{
		code: '83112604',
		label: 'Servicios de acceso de discado',
	},
	{
		code: '83112605',
		label: 'Líneas internacionales externas',
	},
	{
		code: '83112606',
		label:
			'Servicio de acceso del cliente en la capacidad del radio direccional',
	},
	{
		code: '83121500',
		label: 'Bibliotecas',
	},
	{
		code: '83121501',
		label: 'Bibliotecas municipales para uso del público en general',
	},
	{
		code: '83121502',
		label: 'Bibliotecas de universidades o colleges',
	},
	{
		code: '83121503',
		label: 'Bibliotecas privadas',
	},
	{
		code: '83121504',
		label: 'Bibliotecas gubernamentales o militares nacionales',
	},
	{
		code: '83121600',
		label: 'Centros de información',
	},
	{
		code: '83121601',
		label: 'Cámaras de comercio',
	},
	{
		code: '83121602',
		label: 'Servicios de juntas de turismo',
	},
	{
		code: '83121603',
		label: 'Sistemas de recuperación de la información computarizada',
	},
	{
		code: '83121604',
		label:
			'Sistemas de recuperación de la información de la base de datos en línea',
	},
	{
		code: '83121605',
		label:
			'Servicios de recuperación de información de la base de datos a distancia',
	},
	{
		code: '83121606',
		label: 'Localización de saltos',
	},
	{
		code: '83121700',
		label: 'Servicios de comunicación masiva',
	},
	{
		code: '83121701',
		label: 'Servicios relacionados con la televisión',
	},
	{
		code: '83121702',
		label: 'Servicios relacionados con la radio',
	},
	{
		code: '83121703',
		label: 'Servicios relacionados con el internet',
	},
	{
		code: '83121704',
		label: 'Sistemas de alerta ciudadana',
	},
	{
		code: '84101500',
		label: 'Asistencia de desarrollo',
	},
	{
		code: '84101501',
		label: 'Asistencia financiera',
	},
	{
		code: '84101502',
		label: 'Programas de movilización de ahorros',
	},
	{
		code: '84101503',
		label: 'Acuerdos de garantía',
	},
	{
		code: '84101600',
		label: 'Financiación de ayudas',

		palabrasSimilares: 'Ayuda financiera',
	},
	{
		code: '84101601',
		label: 'Cofinanciación',

		palabrasSimilares: 'Cofinanciamiento',
	},
	{
		code: '84101602',
		label: 'Ayuda bilateral o multilateral',

		palabrasSimilares: 'Donativos del extranjero',
	},
	{
		code: '84101603',
		label: 'Ayuda no gubernamental',

		palabrasSimilares: 'Donativos',
	},
	{
		code: '84101604',
		label: 'Ayuda gubernamental',

		palabrasSimilares: 'Programas gubernamentales de apoyo',
	},
	{
		code: '84101700',
		label: 'Manejo de deuda',

		palabrasSimilares: 'Intereses no provenientes del sistema financiero',
	},
	{
		code: '84101701',
		label: 'Negociación de la deuda',
	},
	{
		code: '84101702',
		label: 'Reorganización de la deuda',

		palabrasSimilares: 'Reorganización de la deuda con intereses moratorios',
	},
	{
		code: '84101703',
		label: 'Amortización de la deuda',
	},
	{
		code: '84101704',
		label: 'Servicios de cobro de la deuda',

		palabrasSimilares: 'Servicios de cobranza',
	},
	{
		code: '84101705',
		label: 'Servicios de recuperación',
	},
	{
		code: '84111500',
		label: 'Servicios contables',

		palabrasSimilares: 'Honorarios contables',
	},
	{
		code: '84111501',
		label: 'Servicio de contabilidad de costos',
	},
	{
		code: '84111502',
		label: 'Servicio de contabilidad financiera',
	},
	{
		code: '84111503',
		label: 'Servicio de contabilidad fiscal',
	},
	{
		code: '84111504',
		label: 'Servicios de  teneduría de libros',

		palabrasSimilares: 'Servicios de resguardo de contabilidad',
	},
	{
		code: '84111505',
		label: 'Servicios de contabilidad de sueldos y salarios',

		palabrasSimilares: 'Servicios de nómina',
	},
	{
		code: '84111506',
		label: 'Servicios de facturación',
	},
	{
		code: '84111507',
		label: 'Servicio de contabilidad de inventario',
	},
	{
		code: '84111508',
		label: 'Servicio de administración de exportaciones  y de contabilidad',
	},
	{
		code: '84111600',
		label: 'Servicios de auditoría',

		palabrasSimilares: 'Honorarios contables',
	},
	{
		code: '84111601',
		label: 'Auditorias de cierre del ejercicio',
	},
	{
		code: '84111602',
		label: 'Revisiones trimestrales',
	},
	{
		code: '84111603',
		label: 'Auditorias internas',
	},
	{
		code: '84111700',
		label: 'Finanzas corporativas',
	},
	{
		code: '84111701',
		label: 'Servicios de tesorería',
	},
	{
		code: '84111702',
		label: 'Servicios o programas de relaciones de inversionistas',
	},
	{
		code: '84111703',
		label: 'Servicios de preparación o revisión de presupuestos',
	},
	{
		code: '84111800',
		label: 'Asuntos y preparación de impuestos',
	},
	{
		code: '84111801',
		label: 'Gestores fiscales',
	},
	{
		code: '84111802',
		label: 'Servicios de asesoría  fiscal',

		palabrasSimilares: 'Servicios de consultoría fiscal',
	},
	{
		code: '84121500',
		label: 'Instituciones bancarias',
	},
	{
		code: '84121501',
		label: 'Bancos privados',
	},
	{
		code: '84121502',
		label: 'Bancos públicos',
	},
	{
		code: '84121503',
		label: 'Cooperativas de crédito',
	},
	{
		code: '84121504',
		label: 'Instituciones financieras de fomento',
	},
	{
		code: '84121600',
		label: 'Servicios de transferencia de fondos y canje y cambios',
	},
	{
		code: '84121601',
		label: 'Servicios de compensación de fondos',
	},
	{
		code: '84121602',
		label: 'Servicios de cartas de crédito',
	},
	{
		code: '84121603',
		label: 'Servicios de cambio de divisas',
		incluirIVATrasladado: 'Sí',
		incluirIEPSTrasladado: 'Opcional',
		palabrasSimilares: '',
	},
	{
		code: '84121604',
		label: 'Servicios de transacción de divisas al contado',
		incluirIVATrasladado: 'Sí',
		incluirIEPSTrasladado: 'Opcional',
		palabrasSimilares: '',
	},
	{
		code: '84121605',
		label: 'Servicios de conversión de moneda',
	},
	{
		code: '84121606',
		label: 'Servicios de procesamiento de remesas',
	},
	{
		code: '84121607',
		label: 'Servicio financiero de alquiler de operaciones',
	},
	{
		code: '84121700',
		label: 'Asesoría de inversiones',
	},
	{
		code: '84121701',
		label: 'Asesores de inversiones',
	},
	{
		code: '84121702',
		label: 'Política de inversiones',
	},
	{
		code: '84121703',
		label: 'Análisis de inversiones',
	},
	{
		code: '84121704',
		label: 'Acuerdos de inversiones',
	},
	{
		code: '84121705',
		label: 'Datos del mercado',
	},
	{
		code: '84121800',
		label: 'Servicios de mercados de títulos valores y commodities',
	},
	{
		code: '84121801',
		label: 'Servicios de contratación bursátil',
	},
	{
		code: '84121802',
		label: 'Servicios de mercados de productos básicos o de futuros',
	},
	{
		code: '84121803',
		label: 'Bonos del estado',
	},
	{
		code: '84121804',
		label: 'Bonos emitidos por el sector privado',
	},
	{
		code: '84121805',
		label: 'Servicios de mercado de metales preciosos',
	},
	{
		code: '84121806',
		label: 'Servicios de custodia de valores',
	},
	{
		code: '84121900',
		label: 'Banca hipotecaria',
	},
	{
		code: '84121901',
		label: 'Financiación de vivienda',
	},
	{
		code: '84121902',
		label: 'Servicios de refinanciación',
	},
	{
		code: '84121903',
		label: 'Financiación de hipotecas comerciales',
	},
	{
		code: '84122000',
		label: 'Servicios de cajillas de seguridad',
	},
	{
		code: '84122001',
		label: 'Servicios de verificación de depósitos',
	},
	{
		code: '84131500',
		label: 'Servicios de seguros para estructuras y propiedades y posesiones',
	},
	{
		code: '84131501',
		label: 'Seguros de edificios o del contenido de edificios',
	},
	{
		code: '84131502',
		label: 'Seguros de propietarios de casa o rentistas',
	},
	{
		code: '84131503',
		label: 'Seguro de automóviles o camiones',
	},
	{
		code: '84131504',
		label: 'Seguros de carga',
	},
	{
		code: '84131505',
		label: 'Seguros marítimos',
	},
	{
		code: '84131506',
		label: 'Servicios de reaseguros',
	},
	{
		code: '84131507',
		label: 'Seguro de interrupción de los procesos de negocios',
	},
	{
		code: '84131508',
		label: 'Seguro de aportación de dinero durante el viaje',
	},
	{
		code: '84131509',
		label: 'Seguro completo de proyectos',
	},
	{
		code: '84131510',
		label: 'Seguro a todo riesgo para contratistas',
	},
	{
		code: '84131511',
		label: 'Seguro de deterioro de valores',
	},
	{
		code: '84131512',
		label: 'Seguro de equipos electrónicos',
	},
	{
		code: '84131513',
		label: 'Seguro a todo riesgo de levantamientos',
	},
	{
		code: '84131514',
		label: 'Seguro de garantía de fidelidad',
	},
	{
		code: '84131515',
		label: 'Seguro colectivo de joyeros',
	},
	{
		code: '84131516',
		label: 'Seguro de indemnización profesional',
	},
	{
		code: '84131517',
		label: 'Seguro de viaje',
	},
	{
		code: '84131601',
		label: 'Seguros de vida',

		palabrasSimilares: 'Seguros personales',
	},
	{
		code: '84131602',
		label: 'Seguros de asistencia médica y hospitalización',

		palabrasSimilares:
			'Seguros de gastos médicos mayores, Seguros de salud, Seguros personale',
	},
	{
		code: '84131603',
		label: 'Seguros de daños personales por accidente',

		palabrasSimilares: 'Seguros personales',
	},
	{
		code: '84131604',
		label: 'Seguro de invalidez',

		palabrasSimilares: 'Seguros laborales',
	},
	{
		code: '84131605',
		label: 'Seguro de accidentes de trabajo',

		palabrasSimilares: 'Seguros laborales',
	},
	{
		code: '84131606',
		label: 'Seguros de desempleo',

		palabrasSimilares: 'Seguros laborales',
	},
	{
		code: '84131607',
		label: 'Seguro de responsabilidad civil',

		palabrasSimilares: 'Seguros contra terceros',
	},
	{
		code: '84131608',
		label: 'Administración y revisión de reclamos médicos',

		palabrasSimilares: 'Seguros contra terceros',
	},
	{
		code: '84131609',
		label: 'Programas de asistencia a empleados',

		palabrasSimilares: 'Seguros laborales',
	},
	{
		code: '84131610',
		label: 'Cuentas de gasto flexible (fsa)',

		palabrasSimilares: 'Seguros personales',
	},
	{
		code: '84131700',
		label: 'Fondos de pensiones',
	},
	{
		code: '84131701',
		label: 'Fondos de pensiones administrados por el empleador',
	},
	{
		code: '84131702',
		label: 'Fondos de pensiones administrados por sindicatos o gremios',
	},
	{
		code: '84131801',
		label: 'Fondos de pensiones autodirigidos o patrocinados por el empleador',

		palabrasSimilares: 'Planes privados de pensión',
	},
	{
		code: '84131802',
		label: 'Planes de jubilación autodirigidos o de iniciativa propia',

		palabrasSimilares: 'Planes privados de pensión',
	},
	{
		code: '84141500',
		label: 'Agencias  de crédito gubernamental',
	},
	{
		code: '84141501',
		label: 'Servicios de crédito agrícola',
	},
	{
		code: '84141502',
		label: 'Entidades de préstamos para la pequeña empresa',
	},
	{
		code: '84141503',
		label: 'Programas empresariales de propiedad minoritaria',
	},
	{
		code: '84141600',
		label: 'Agencias de crédito personal',

		palabrasSimilares: 'Agencias de crédito personal o monederos electrónicos',
	},
	{
		code: '84141601',
		label:
			'Servicios de recopilación o reporte de información para créditos al consumidor',
	},
	{
		code: '84141602',
		label: 'Proveedores de servicios de tarjetas de crédito',

		palabrasSimilares:
			'Proveedores de servicios de tarjetas de crédito o monederos electrónicos',
	},
	{
		code: '84141700',
		label: 'Agencias de crédito comercial',
	},
	{
		code: '84141701',
		label:
			'Servicios de recopilación de información  o reporte para créditos empresariales',
	},
	{
		code: '84141702',
		label: 'Servicios de red de valor agregado (van)',
	},
	{
		code: '85101501',
		label: 'Servicios hospitalarios de emergencia o quirúrgicos',
	},
	{
		code: '85101502',
		label: 'Servicios clínicos especializados privados',
	},
	{
		code: '85101503',
		label: 'Servicios de consultorios médicos',

		palabrasSimilares: 'Servicios de sutura y curación',
	},
	{
		code: '85101504',
		label: 'Servicios de hospitales siquiátricos',
	},
	{
		code: '85101505',
		label: 'Servicios respiratorios hospitalarios',
	},
	{
		code: '85101506',
		label: 'Servicios hospitalarios para el abuso de sustancias',
	},
	{
		code: '85101507',
		label: 'Centros asistenciales de urgencia',
	},
	{
		code: '85101508',
		label: 'Centros o servicios móviles de atención de salud',
	},
	{
		code: '85101509',
		label: 'Servicios hospitalarios ginecológicos u obstétricos',
	},
	{
		code: '85101601',
		label: 'Servicios de enfermería',
	},
	{
		code: '85101602',
		label: 'Servicios de obstetricia o de preparación para el parto',
	},
	{
		code: '85101603',
		label: 'Servicios de cuidados personal en instituciones especializadas',
	},
	{
		code: '85101604',
		label: 'Servicios de asistencia de personal médico',
	},
	{
		code: '85101605',
		label: 'Auxiliares de salud a domicilio',
	},
	{
		code: '85101701',
		label: 'Política de salud',
	},
	{
		code: '85101702',
		label: 'Legislación o regulaciones sobre salud',
	},
	{
		code: '85101703',
		label: 'Planificación de servicios médicos',
	},
	{
		code: '85101704',
		label: 'Economía de la salud',
	},
	{
		code: '85101705',
		label: 'Administración de salud pública',
	},
	{
		code: '85101706',
		label: 'Servicios tradicionales de la atención de salud',
	},
	{
		code: '85101707',
		label: 'Servicios de evaluación de sistemas de salud',
	},
	{
		code: '85111500',
		label: 'Prevención y control de enfermedades contagiosas',
	},
	{
		code: '85111501',
		label: 'Servicios de prevención o control del sida',
	},
	{
		code: '85111502',
		label: 'Servicios de prevención o control de enfermedades parasitarias',
	},
	{
		code: '85111503',
		label:
			'Servicios de prevención o control de enfermedades causadas por hongos',
	},
	{
		code: '85111504',
		label: 'Servicios de prevención o control de la tuberculosis',
	},
	{
		code: '85111505',
		label: 'Servicios de prevención o control de la lepra',
	},
	{
		code: '85111506',
		label: 'Servicios de prevención o control de enfermedades bacterianas',
	},
	{
		code: '85111507',
		label:
			'Servicios de prevención o control de enfermedades de transmisión sexual',
	},
	{
		code: '85111508',
		label: 'Servicios de prevención o control de enfermedades virales',
	},
	{
		code: '85111509',
		label: 'Servicios de prevención o control de enfermedades zoonóticas',
	},
	{
		code: '85111510',
		label: 'Servicios de vacunación',
	},
	{
		code: '85111511',
		label: 'Servicios de cuarentena',
	},
	{
		code: '85111512',
		label: 'Servicios de inmunización',
	},
	{
		code: '85111513',
		label: 'Servicios de desinsectación',
	},
	{
		code: '85111514',
		label: 'Servicios de prevención o control de epidemias',
	},
	{
		code: '85111600',
		label: 'Prevención y control de enfermedades no contagiosas',
	},
	{
		code: '85111601',
		label: 'Servicios de prevención o control de enfermedades óseas',
	},
	{
		code: '85111602',
		label: 'Servicios de prevención o control de cáncer o leucemia',
	},
	{
		code: '85111603',
		label: 'Servicios de prevención o control de enfermedades endocrinas',
	},
	{
		code: '85111604',
		label: 'Servicios de prevención o control de enfermedades cardiovasculares',
	},
	{
		code: '85111605',
		label: 'Servicios de prevención o control inmunológico',
	},
	{
		code: '85111606',
		label: 'Servicios de prevención o control de alergias',
	},
	{
		code: '85111607',
		label: 'Servicios de prevención o control de trastornos neurológicos',
	},
	{
		code: '85111608',
		label: 'Servicios de prevención o control de enfermedades nutricionales',
	},
	{
		code: '85111609',
		label: 'Servicios de prevención o control de la enfermedad por radiación',
	},
	{
		code: '85111610',
		label:
			'Servicios de prevención o control de enfermedades del sistema digestivo',
	},
	{
		code: '85111611',
		label: 'Servicios de prevención o control de enfermedades oculares',
	},
	{
		code: '85111612',
		label: 'Servicios de prevención o control de enfermedades respiratorias',
	},
	{
		code: '85111613',
		label: 'Servicios de prevención o control de enfermedades tropicales',
	},
	{
		code: '85111614',
		label: 'Servicios de prevención o control de enfermedades de la niñez',
	},
	{
		code: '85111615',
		label: 'Servicios de prevención o control de enfermedades diarreicas',
	},
	{
		code: '85111616',
		label: 'Servicios de prevención o control del alcoholismo',
	},
	{
		code: '85111617',
		label: 'Servicios de prevención o control de la drogadicción',
	},
	{
		code: '85111700',
		label: 'Manejo y control de vectores de enfermedad',
	},
	{
		code: '85111701',
		label: 'Servicios de manejo o control de garrapatas',
	},
	{
		code: '85111702',
		label: 'Servicios de manejo o control de la mosca tsé-tsé',
	},
	{
		code: '85111703',
		label: 'Servicios de manejo o control de bacterias',
	},
	{
		code: '85111704',
		label: 'Servicios de manejo o control de mosquitos',
	},
	{
		code: '85121501',
		label:
			'Servicios de atención domiciliaria por médicos de atención primaria',
	},
	{
		code: '85121502',
		label: 'Servicios de consulta de médicos de atención primaria',

		palabrasSimilares: 'Servicios de consulta médica',
	},
	{
		code: '85121503',
		label: 'Servicios de control de médicos de atención primaria',
	},
	{
		code: '85121504',
		label: 'Servicios médicos de emergencia de médicos de atención primaria',
	},
	{
		code: '85121600',
		label: 'Servicios médicos de doctores especialistas',

		palabrasSimilares: 'Honorarios médicos',
	},
	{
		code: '85121601',
		label: 'Servicios de ginecología y obstetricia',
	},
	{
		code: '85121602',
		label: 'Servicios de nefrología',
	},
	{
		code: '85121603',
		label: 'Servicios de cardiología',
	},
	{
		code: '85121604',
		label: 'Servicios de especialistas pulmonares',
	},
	{
		code: '85121605',
		label: 'Servicios de gastroenterólogos',
	},
	{
		code: '85121606',
		label: 'Servicios geriátricos',
	},
	{
		code: '85121607',
		label: 'Servicios de siquiatras',

		palabrasSimilares: 'Servicios de psiquiatras',
	},
	{
		code: '85121608',
		label: 'Servicios de psicología',

		palabrasSimilares: 'Honorarios de psicología',
	},
	{
		code: '85121609',
		label: 'Servicios de  cirugía',
	},
	{
		code: '85121610',
		label: 'Servicios de oftalmólogos',
	},
	{
		code: '85121611',
		label: 'Servicios de dermatología',
	},
	{
		code: '85121612',
		label: 'Servicios de ortopedia',
	},
	{
		code: '85121613',
		label: 'Servicios pediátricos',
	},
	{
		code: '85121614',
		label: 'Servicios de especialista del sistema nervioso',
	},
	{
		code: '85121700',
		label: 'Servicios de prestadores especialistas de servicios de salud',

		palabrasSimilares: 'Honorarios médicos',
	},
	{
		code: '85121701',
		label: 'Servicios de psicoterapeutas',

		palabrasSimilares: 'Servicios de psicoterapia',
	},
	{
		code: '85121702',
		label: 'Servicios de optómetras',
	},
	{
		code: '85121703',
		label: 'Servicios de podiatras',
	},
	{
		code: '85121704',
		label: 'Servicios de logopedas',
	},
	{
		code: '85121705',
		label: 'Servicios de acupuntura',
	},
	{
		code: '85121706',
		label: 'Servicios quiroprácticos',
	},
	{
		code: '85121800',
		label: 'Laboratorios médicos',

		palabrasSimilares: 'Servicios de análisis clínicos',
	},
	{
		code: '85121801',
		label: 'Servicios de laboratorios de análisis de sangre',
	},
	{
		code: '85121802',
		label: 'Servicios de laboratorios bacteriológicos',
	},
	{
		code: '85121803',
		label: 'Servicios de laboratorios biológicos',
	},
	{
		code: '85121804',
		label: 'Servicios de laboratorios patológicos',
	},
	{
		code: '85121805',
		label: 'Servicios de laboratorios de análisis de orina',
	},
	{
		code: '85121806',
		label: 'Servicios de laboratorios neurológicos',
	},
	{
		code: '85121807',
		label: 'Servicios de laboratorios de ultrasonido',
	},
	{
		code: '85121808',
		label: 'Servicios de laboratorios de rayos x',
	},
	{
		code: '85121809',
		label: 'Servicios de bancos de sangre, esperma u órganos de trasplante',
	},
	{
		code: '85121810',
		label: 'Detección de drogas o alcohol',
	},
	{
		code: '85121811',
		label: 'Servicios de laboratorios de detección del COVID',

		fechaInicioVigencia: '29-12-2020',
		fechaFinVigencia: '',
		estimuloFranjaFronteriza: '01',
		palabrasSimilares:
			'Análisis clínicos, Pruebas para detección del SARS-CoV-2, Pruebas para detección del COVID, Pruebas para detección del COVID-19',
	},
	{
		code: '85121900',
		label: 'Farmacéuticos',
	},
	{
		code: '85121901',
		label: 'Servicios de preparación farmacéutica',

		palabrasSimilares: 'Servicios de preparación de medicamentos homeopáticos',
	},
	{
		code: '85121902',
		label: 'Servicios farmacéuticos comerciales',
	},
	{
		code: '85122000',
		label: 'Servicios dentales',

		palabrasSimilares: 'Honorarios médicos',
	},
	{
		code: '85122001',
		label: 'Servicios de odontólogos',

		palabrasSimilares: 'Servicios de dentistas',
	},
	{
		code: '85122002',
		label: 'Servicios de higienistas dentales',
	},
	{
		code: '85122003',
		label: 'Servicios de personal de apoyo odontológico',
	},
	{
		code: '85122004',
		label: 'Servicios de cirujanos orales',

		palabrasSimilares: 'Servicios de cirujanos maxilofaciales',
	},
	{
		code: '85122005',
		label: 'Servicios de ortodoncia',
	},
	{
		code: '85122100',
		label: 'Servicios de rehabilitación',
	},
	{
		code: '85122101',
		label: 'Servicios de fisioterapia',
	},
	{
		code: '85122102',
		label: 'Servicios de terapia ocupacional',
	},
	{
		code: '85122103',
		label: 'Servicios de rehabilitación para el abuso de sustancias',
	},
	{
		code: '85122104',
		label: 'Servicios de rehabilitación deportiva',
	},
	{
		code: '85122105',
		label: 'Servicios de trastornos alimenticios',
	},
	{
		code: '85122106',
		label: 'Servicios de lesiones cerebrales o de la médula espinal',
	},
	{
		code: '85122107',
		label:
			'Servicios de rehabilitación para personas invidentes o con problemas de vista',
	},
	{
		code: '85122108',
		label: 'Terapia del habla o del lenguaje',
	},
	{
		code: '85122109',
		label:
			'Servicios de rehabilitación para personas con discapacidades crónicas',
	},
	{
		code: '85122200',
		label: 'Servicios de evaluación y valoración de salud individual',
	},
	{
		code: '85122201',
		label: 'Valoración del estado de salud individual',
	},
	{
		code: '85131500',
		label: 'Servicios de medicina experimental',
	},
	{
		code: '85131501',
		label: 'Servicios de trasplante de órganos',
	},
	{
		code: '85131502',
		label: 'Ensayos clínicos de medicamentos de uso humano',
	},
	{
		code: '85131503',
		label: 'Experimentación con animales',
	},
	{
		code: '85131504',
		label: 'Experimentación con humanos',
	},
	{
		code: '85131505',
		label: 'Experimentación espacial',
	},
	{
		code: '85131600',
		label: 'Ética médica',
	},
	{
		code: '85131602',
		label: 'Código de conducta médica',
	},
	{
		code: '85131603',
		label: 'Sociedades médicas',
	},
	{
		code: '85131604',
		label: 'Servicios internacionales de monitoreo de  medicamentos',
	},
	{
		code: '85131700',
		label: 'Ciencia e investigación médica',
	},
	{
		code: '85131701',
		label: 'Servicios de investigación farmacéutica',
	},
	{
		code: '85131702',
		label: 'Servicios de investigación bacteriológica',
	},
	{
		code: '85131703',
		label: 'Servicios de investigación biomédica',
	},
	{
		code: '85131704',
		label: 'Servicios de investigación cardiológica',
	},
	{
		code: '85131705',
		label: 'Servicios de investigación anatómica',
	},
	{
		code: '85131706',
		label: 'Servicios de investigaciones patológicas',
	},
	{
		code: '85131707',
		label: 'Servicios de investigación embriológica',
	},
	{
		code: '85131708',
		label: 'Servicios de investigación epidemiológica',
	},
	{
		code: '85131709',
		label: 'Servicios de investigación genética',
	},
	{
		code: '85131710',
		label: 'Servicios de investigación inmunológica',
	},
	{
		code: '85131711',
		label: 'Servicios de investigación fisiológica',
	},
	{
		code: '85131712',
		label: 'Servicios de investigación toxicológica',
	},
	{
		code: '85131713',
		label: 'Servicios de investigación neurológica',
	},
	{
		code: '85141500',
		label: 'Curanderos',
	},
	{
		code: '85141501',
		label: 'Servicios de hechiceros o vudús',
	},
	{
		code: '85141502',
		label: 'Servicios de curanderos',
	},
	{
		code: '85141503',
		label: 'Chamanes',
	},
	{
		code: '85141504',
		label: 'Trabajo con la energía',
	},
	{
		code: '85141600',
		label: 'Tratamientos herbales',
	},
	{
		code: '85141601',
		label: 'Servicios de medicina herbolaria  o de herbolarios',
	},
	{
		code: '85141602',
		label: 'Curas médicas con algas o algas marinas',
	},
	{
		code: '85141603',
		label: 'Servicios de curas con fuentes termales',
	},
	{
		code: '85141700',
		label: 'Práctica homeopática',

		palabrasSimilares: 'Servicios médicos de homeopatía',
	},
	{
		code: '85141701',
		label: 'Valoración del diagnóstico inicial',
	},
	{
		code: '85141702',
		label: 'Consultas de remedios',
	},
	{
		code: '85151500',
		label: 'Tecnología de alimentos',
	},
	{
		code: '85151501',
		label: 'Servicios de control de la higiene de los alimentos',
	},
	{
		code: '85151502',
		label: 'Servicios de control de la contaminación de alimentos',
	},
	{
		code: '85151503',
		label: 'Servicios de gestión o control de la conservación de los alimentos',
	},
	{
		code: '85151504',
		label: 'Servicios de asesoría o control en la  preparación de alimentos',

		palabrasSimilares:
			'Servicios de consultoría para el control en la preparación de alimentos',
	},
	{
		code: '85151505',
		label: 'Servicios de investigación alimentaria',
	},
	{
		code: '85151506',
		label: 'Estudios sobre alimentos o hábitos alimentarios',
	},
	{
		code: '85151507',
		label: 'Servicios de estándares de calidad o aditivos alimentarios',
	},
	{
		code: '85151508',
		label: 'Servicios de análisis de alimentos',
	},
	{
		code: '85151600',
		label: 'Asuntos nutricionales',
	},
	{
		code: '85151601',
		label: 'Servicios de programación de la nutrición',
	},
	{
		code: '85151603',
		label: 'Servicios de rehabilitación nutricional',
	},
	{
		code: '85151604',
		label: 'Evaluación de proyectos de nutrición',
	},
	{
		code: '85151605',
		label: 'Estrategias de desarrollo alimentario o nutricional',
	},
	{
		code: '85151607',
		label: 'Control o programas de dietas',
	},
	{
		code: '85151700',
		label: 'Planeación y ayuda de política de alimentos',
	},
	{
		code: '85161500',
		label: 'Reparación de equipo médico o quirúrgico',
	},
	{
		code: '85161501',
		label: 'Mantenimiento o reparación de equipo médico mayor (capital)',
	},
	{
		code: '85161502',
		label: 'Mantenimiento o reparación de equipo médico menor',
	},
	{
		code: '85161503',
		label: 'Mantenimiento o reparación instrumental medico o quirúrgico',
	},
	{
		code: '85161504',
		label: 'Acuerdo de servicios de equipos médicos o quirúrgicos',
	},
	{
		code: '85161505',
		label:
			'Tarifa de embarque y alquiler de equipo medico o quirúrgico o de implantes',
	},
	{
		code: '85171500',
		label: 'Servicios funerarios y asociados',
	},
	{
		code: '85171501',
		label: 'Excavación de tumbas',
	},
	{
		code: '85171600',
		label: 'Cuidado en hospicios',
	},
	{
		code: '85171601',
		label: 'Servicio de administración de hospicios',
	},
	{
		code: '86101500',
		label:
			'Servicios de entrenamiento en Agricultura y silvicultura y otros recursos naturales',
	},
	{
		code: '86101501',
		label: 'Formación profesional para la agroindustria',
	},
	{
		code: '86101502',
		label: 'Formación profesional para la industria láctea',
	},
	{
		code: '86101503',
		label: 'Formación profesional para la industria cárnica',
	},
	{
		code: '86101504',
		label: 'Servicios de formación profesional agrícola',
	},
	{
		code: '86101505',
		label:
			'Servicios de formación profesional para jóvenes rurales o granjeros',
	},
	{
		code: '86101506',
		label: 'Servicios de formación profesional forestal',
	},
	{
		code: '86101507',
		label: 'Servicios de formación profesional pesquera',
	},
	{
		code: '86101508',
		label: 'Servicios de formación profesional ambiental',
	},
	{
		code: '86101509',
		label: 'Servicios de formación profesional en recursos naturales',
	},
	{
		code: '86101600',
		label: 'Servicios de capacitación vocacional científica',
	},
	{
		code: '86101601',
		label: 'Servicios de formación profesional en informática',
	},
	{
		code: '86101602',
		label: 'Servicios de formación profesional relacionada con la energía',
	},
	{
		code: '86101603',
		label: 'Servicios de formación  profesional  en química',
	},
	{
		code: '86101604',
		label: 'Servicios de formación  profesional en biología',
	},
	{
		code: '86101605',
		label: 'Servicios de formación  profesional médica',
	},
	{
		code: '86101606',
		label: 'Servicios de formación profesional en electrónica',
	},
	{
		code: '86101607',
		label: 'Servicios de formación profesional en telecomunicaciones',
	},
	{
		code: '86101608',
		label: 'Servicios de formación profesional en hidráulica',
	},
	{
		code: '86101609',
		label: 'Servicios de formación profesional industrial',
	},
	{
		code: '86101610',
		label: 'Servicios de formación profesional en ingeniería',
	},
	{
		code: '86101700',
		label: 'Servicios de capacitación vocacional no - científica',
	},
	{
		code: '86101701',
		label: 'Servicios de formación profesional en comunicaciones',
	},
	{
		code: '86101702',
		label: 'Capacitación relacionada con el turismo',
	},
	{
		code: '86101703',
		label: 'Capacitación sobre bibliotecas o documentación',
	},
	{
		code: '86101704',
		label: 'Capacitación sobre cadenas de aprovisionamiento o suministro',
	},
	{
		code: '86101705',
		label: 'Capacitación administrativa',
	},
	{
		code: '86101706',
		label: 'Servicios de formación profesional en asistencia sanitaria',
	},
	{
		code: '86101707',
		label: 'Servicios de formación profesional sobre cuidado personal',
	},
	{
		code: '86101708',
		label: 'Servicios de alfabetización',
	},
	{
		code: '86101709',
		label: 'Servicios de capacitación en seguridad',
	},
	{
		code: '86101710',
		label: 'Servicios de formación pedagógica',
	},
	{
		code: '86101711',
		label: 'Servicios de capacitación sobre la lucha contra incendios',
	},
	{
		code: '86101712',
		label: 'Servicios de formación profesional en artesanías',
	},
	{
		code: '86101713',
		label: 'Servicios de formación profesional en derecho',
	},
	{
		code: '86101714',
		label: 'Servicios de formación profesional  en ejecución de la ley',
	},
	{
		code: '86101715',
		label:
			'Servicios de formación profesional en transporte por carretera o ferrocarril',
	},
	{
		code: '86101716',
		label: 'Servicios de formación profesional en transporte marítimo',
	},
	{
		code: '86101800',
		label: 'Entrenamiento en servicio y desarrollo de mano de obra',
	},
	{
		code: '86101801',
		label: 'Formación de recursos humanos para el sector bancario o financiero',
	},
	{
		code: '86101802',
		label: 'Servicios de capacitación en readiestramiento o repaso',
	},
	{
		code: '86101803',
		label: 'Servicios de rehabilitación vocacional',
	},
	{
		code: '86101804',
		label: 'Formación de recursos humanos para el sector  comercial',
	},
	{
		code: '86101805',
		label: 'Formación de recursos humanos para el sector  industrial',
	},
	{
		code: '86101806',
		label: 'Formación de recursos humanos para el sector de la salud',
	},
	{
		code: '86101807',
		label: 'Formación de recursos humanos para el sector de gestión',
	},
	{
		code: '86101808',
		label: 'Servicios de formación de recursos humanos para el sector  público',
	},
	{
		code: '86101810',
		label: 'Capacitación en habilidades personales',
	},
	{
		code: '86111500',
		label: 'Servicios de aprendizaje a distancia',

		palabrasSimilares: 'Colegiaturas',
	},
	{
		code: '86111501',
		label: 'Servicios de orientación para el aprendizaje a distancia',
	},
	{
		code: '86111502',
		label: 'Servicios de enseñanza a distancia',
	},
	{
		code: '86111503',
		label: 'Servicios de aprendizaje diplomado a distancia',
	},
	{
		code: '86111504',
		label: 'Servicios de aprendizaje no diplomado a distancia',
	},
	{
		code: '86111505',
		label: 'Servicios de evaluación del aprendizaje a distancia',
	},
	{
		code: '86111600',
		label: 'Educación de adultos',
	},
	{
		code: '86111601',
		label: 'Cursos nocturnos',
	},
	{
		code: '86111602',
		label: 'Servicios de educación de tiempo parcial para adultos',
	},
	{
		code: '86111603',
		label: 'Educación para padres',
	},
	{
		code: '86111604',
		label: 'Educación para empleados',
	},
	{
		code: '86111700',
		label: 'Educación de idiomas',
	},
	{
		code: '86111701',
		label: 'Enseñanza de idiomas extranjeros basada en la conversación',
	},
	{
		code: '86111702',
		label: 'Enseñanza de idiomas extranjeros por inmersión',
	},
	{
		code: '86111800',
		label: 'Intercambios estudiantiles',
	},
	{
		code: '86111801',
		label: 'Intercambios educativos entre universidades',
	},
	{
		code: '86111802',
		label: 'Intercambios educativos entre escuelas',
	},
	{
		code: '86121500',
		label: 'Escuelas preescolares, primarias y secundarias',

		palabrasSimilares: 'Colegiaturas',
	},
	{
		code: '86121501',
		label: 'Servicios educativos preescolares',
	},
	{
		code: '86121502',
		label: 'Escuelas religiosas de educación primaria o secundaria',
	},
	{
		code: '86121503',
		label: 'Escuelas privadas de educación primaria o secundaria',
	},
	{
		code: '86121504',
		label: 'Escuelas públicas de educación primaria o secundaria',
	},
	{
		code: '86121600',
		label: 'Bachillerato o profesional técnico',

		palabrasSimilares: 'Colegiaturas',
	},
	{
		code: '86121601',
		label: 'Bachillerato o equivalente',
	},
	{
		code: '86121602',
		label: 'Profesional técnico',
	},
	{
		code: '86121700',
		label: 'Universidades y politécnicos',

		palabrasSimilares: 'Colegiaturas',
	},
	{
		code: '86121701',
		label: 'Programas de pregrado',
	},
	{
		code: '86121702',
		label: 'Programas de posgrado',
	},
	{
		code: '86121800',
		label: 'Escuelas profesionales',

		palabrasSimilares: 'Colegiaturas',
	},
	{
		code: '86121802',
		label: 'Seminarios teológicos',
	},
	{
		code: '86121803',
		label: 'Escuelas profesionales técnicas',
	},
	{
		code: '86121804',
		label: 'Escuelas profesionales no técnicas',
	},
	{
		code: '86131500',
		label: 'Bellas Artes',
	},
	{
		code: '86131501',
		label: 'Estudios de teatro',
	},
	{
		code: '86131502',
		label: 'Pintura',
	},
	{
		code: '90151802',
		label: 'Servicios de organización o administración de ferias',
	},
	{
		code: '90151803',
		label: 'Construcción o creación de pabellones de feria',
	},
	{
		code: '90151900',
		label: 'Establecimientos de Juegos de azar y de apuestas',
	},
	{
		code: '90151901',
		label: 'Casinos',
	},
	{
		code: '90151902',
		label: 'Clubes de cartas',
	},
	{
		code: '90151903',
		label: 'Hipódromos',
	},
	{
		code: '90152000',
		label: 'Clubes nocturnos y salones de baile',
	},
	{
		code: '90152001',
		label: 'Clubes nocturnos',
	},
	{
		code: '90152002',
		label: 'Salones de baile',
	},
	{
		code: '90152100',
		label: 'Servicios de conserjería',
	},
	{
		code: '90152101',
		label: 'Servicios de asistencia personal',
	},
	{
		code: '91101500',
		label: 'Facilidades de spa y ejercicios y rejuvenecimiento',
	},
	{
		code: '91101501',
		label: 'Gimnasios o centros de salud',
	},
	{
		code: '91101502',
		label: 'Spas',
	},
	{
		code: '91101503',
		label: 'Servicios de masajes',
	},
	{
		code: '91101504',
		label: 'Clases de aeróbicos o ejercicios',
	},
	{
		code: '91101505',
		label: 'Baños turcos o de vapor o rituales',
	},
	{
		code: '91101600',
		label: 'Cuidado facial y corporal',
	},
	{
		code: '91101601',
		label: 'Tratamientos faciales o corporales',
	},
	{
		code: '91101602',
		label: 'Consulta de maquillaje',
	},
	{
		code: '91101603',
		label: 'Servicios de tatuajes',
	},
	{
		code: '91101604',
		label: 'Servicios de pirsin corporal',
	},
	{
		code: '91101605',
		label: 'Electrólisis',
	},
	{
		code: '91101700',
		label: 'Cuidado del pelo',
	},
	{
		code: '91101701',
		label: 'Servicios de corte y tinte de pelo',
	},
	{
		code: '91101702',
		label: 'Servicios de extensiones o reemplazo de cabello',
	},
	{
		code: '91101800',
		label: 'Alquiler de ropa',
	},
	{
		code: '91101801',
		label: 'Alquiler de esmóquines o trajes de etiqueta',
	},
	{
		code: '91101802',
		label: 'Alquiler de vestidos o  trajes de noche o de novia',
	},
	{
		code: '91101803',
		label: 'Alquiler de disfraces',
	},
	{
		code: '91101900',
		label: 'Consultores de modas',

		palabrasSimilares:
			'Servicios de diseños de moda y otros diseños especializados',
	},
	{
		code: '91101901',
		label: 'Asesor de colores',
	},
	{
		code: '91101902',
		label: 'Estilista de moda',
	},
	{
		code: '91101903',
		label: 'Asesor de vestuario',
	},
	{
		code: '91101904',
		label: 'Servicio de sastrería',
	},
	{
		code: '91111500',
		label: 'Servicios de lavandería',
	},
	{
		code: '91111501',
		label: 'Alquiler de uniformes',
	},
	{
		code: '91111502',
		label: 'Servicios de lavandería',
	},
	{
		code: '91111503',
		label: 'Lavado en seco',
	},
	{
		code: '91111504',
		label: 'Lavandería de autoservicio operadas con monedas',
	},
	{
		code: '91111600',
		label: 'Asistencia y cuidado del hogar',

		palabrasSimilares: 'Servicios de cerrajería',
	},
	{
		code: '91111601',
		label: 'Servicios de ama de llaves',
	},
	{
		code: '91111602',
		label: 'Servicios de cuidado de patios o piscinas',
	},
	{
		code: '91111603',
		label: 'Servicios de cocina o preparación de comidas',

		palabrasSimilares: 'Servicios de cocineros domésticos',
	},
	{
		code: '91111700',
		label: 'Servicios de compra y trueque de consumo',
	},
	{
		code: '91111701',
		label: 'Servicios de envío de ropa usada',
	},
	{
		code: '91111703',
		label: 'Servicios de compra de vestuario',

		palabrasSimilares: 'Renta o alquiler de vestuario',
	},
	{
		code: '91111800',
		label: 'Cuidado y almacenamiento de ítems personales',

		palabrasSimilares: 'Cuidado y almacenamiento de artículos personales',
	},
	{
		code: '91111801',
		label: 'Servicios de valet',
	},
	{
		code: '91111803',
		label: 'Alquiler de casilleros',

		palabrasSimilares: 'Renta de casilleros, Servicios de paquetería',
	},
	{
		code: '91111900',
		label: 'Servicios de cuidado temporal',
	},
	{
		code: '91111901',
		label: 'Servicios de guardería para niños o bebés',

		palabrasSimilares: 'Servicios de estancias infantiles',
	},
	{
		code: '91111902',
		label: 'Servicios de niñera o cuidado de niños',
	},
	{
		code: '91111903',
		label: 'Servicios asistenciales diurnos para  ancianos',
	},
	{
		code: '91111904',
		label: 'Servicios de vivienda asistida',
	},
	{
		code: '92101500',
		label: 'Servicios de policía',
	},
	{
		code: '92101501',
		label: 'Servicios de vigilancia',
	},
	{
		code: '92101503',
		label: 'Programas de ayuda a la comunidad',
	},
	{
		code: '92101504',
		label: 'Programas para la disuasión de delitos',
	},
	{
		code: '92101600',
		label: 'Servicios de bomberos',
	},
	{
		code: '92101601',
		label: 'Servicios contra incendios municipales o nacionales',
	},
	{
		code: '92101602',
		label: 'Servicios de cuerpos de bomberos voluntarios',
	},
	{
		code: '92101603',
		label: 'Servicios para la  prevención de incendios',
	},
	{
		code: '92101604',
		label: 'Servicios contra incendios en bosques o tierras vírgenes',
	},
	{
		code: '92101700',
		label: 'Sistemas de cárceles y prisiones',
	},
	{
		code: '92101800',
		label: 'Sistemas judiciales',
	},
	{
		code: '92101804',
		label: 'Multas u honorarios de procesos penales',
	},
	{
		code: '92101900',
		label: 'Servicios de rescate',
	},
	{
		code: '92101901',
		label: 'Equipos de búsqueda y salvamento',
	},
	{
		code: '92101902',
		label: 'Servicios de ambulancia',
	},
	{
		code: '92101903',
		label: 'Servicios de helicópteros de salvamento',
	},
	{
		code: '92101904',
		label: 'Servicios de salvavidas para piscina o playa',
	},
	{
		code: '92111800',
		label: 'Personal y disciplina militar',
	},
	{
		code: '92121500',
		label: 'Servicios de guardias',
	},
	{
		code: '92121502',
		label: 'Servicios de protección contra robos',
	},
	{
		code: '92121504',
		label: 'Servicios de guardas de seguridad',
	},
	{
		code: '92121600',
		label: 'Servicios de detectives',
	},
	{
		code: '92121601',
		label: 'Agencias de detectives',
	},
	{
		code: '92121602',
		label: 'Servicios de huellas dactilares',
	},
	{
		code: '92121603',
		label: 'Servicios de detección de mentiras',
	},
	{
		code: '92121604',
		label: 'Servicios de investigación privada',
	},
	{
		code: '92121700',
		label: 'Servicios de sistemas de seguridad',
	},
	{
		code: '92121701',
		label: 'Vigilancia o mantenimiento o monitoreo de alarmas',
	},
	{
		code: '92121702',
		label: 'Mantenimiento o monitoreo de alarmas contra incendios',
	},
	{
		code: '92121703',
		label: 'Servicios antirrobo para  tiendas o empresas',
	},
	{
		code: '92121704',
		label:
			'Mantenimiento o monitoreo de sistemas de vigilancia de confinamiento',
	},
	{
		code: '92121800',
		label: 'Servicios de Carros blindados y transporte de valores',
	},
	{
		code: '92121801',
		label: 'Servicio de carros blindados',
	},
	{
		code: '92121802',
		label: 'Servicio de transporte de dinero',
	},
	{
		code: '92121803',
		label: 'Servicio de almacenamiento de dinero',
	},
	{
		code: '92121900',
		label: 'Servicios de seguridad marina',
	},
	{
		code: '92121901',
		label: 'Servicio de botes de seguridad',
	},
	{
		code: '93101500',
		label: 'Cuerpos políticos',
	},
	{
		code: '93101501',
		label: 'Servicios de representación de partidos políticos',
	},
	{
		code: '93121500',
		label: 'Diplomacia',
	},
	{
		code: '93121503',
		label: 'Servicios de seguridad para diplomáticos',
	},
	{
		code: '93121600',
		label: 'Relaciones internacionales y cooperación',
	},
	{
		code: '93121608',
		label: 'Servicio de enlace con organizaciones no gubernamentales',
	},
	{
		code: '93121700',
		label: 'Organizaciones internacionales',
	},
	{
		code: '93131500',
		label: 'Programas de refugiados',
	},
	{
		code: '93131501',
		label: 'Servicios de protección de los derechos humanos',
	},
	{
		code: '93131502',
		label: 'Servicios de promoción de los derechos humanos',
	},
	{
		code: '93131503',
		label:
			'Servicios de educación o divulgación de información sobre derechos humanos',
	},
	{
		code: '93131504',
		label: 'Servicios de asistencia de emergencia a refugiados',
	},
	{
		code: '93131505',
		label: 'Servicios de campos de refugiados',
	},
	{
		code: '93131506',
		label: 'Servicios de reasentamientos o repatriación de refugiados',
	},
	{
		code: '93131507',
		label: 'Servicios de asistencia a desplazados',
	},
	{
		code: '93131600',
		label: 'Planeación y programas de políticas de alimentación y nutrición',
	},
	{
		code: '93131601',
		label: 'Programas de erradicación del hambre',
	},
	{
		code: '93131602',
		label: 'Servicios de suministro de alimentos de emergencia',
	},
	{
		code: '93131603',
		label: 'Servicios del programa mundial de alimentos',
	},
	{
		code: '93131604',
		label:
			'Servicios de la organización para la  alimentación y la agricultura',
	},
	{
		code: '93131605',
		label: 'Servicios del fondo común para los  productos básicos',
	},
	{
		code: '93131606',
		label: 'Servicios del fondo internacional para el desarrollo agrícola',
	},
	{
		code: '93131607',
		label: 'Servicios de distribución de alimentos',
	},
	{
		code: '93131608',
		label: 'Servicios de suministro de alimentos',
	},
	{
		code: '93131609',
		label: 'Políticas o programas de ayuda alimentaría',
	},
	{
		code: '93131610',
		label: 'Servicios de planificación de alimentos',
	},
	{
		code: '93131611',
		label: 'Servicios de seguridad alimentaria',
	},
	{
		code: '93131612',
		label: 'Administración de reservas alimentarias',
	},
	{
		code: '93131613',
		label:
			'Servicios de control o  administración de excedentes o escasez de alimentos',
	},
	{
		code: '93131700',
		label: 'Programas de salud',
	},
	{
		code: '93131701',
		label: 'Campañas contra el tabaco',
	},
	{
		code: '93131702',
		label: 'Programas de sanidad',
	},
	{
		code: '93131703',
		label: 'Programas de investigación',
	},
	{
		code: '93131704',
		label: 'Servicios de prevención o control de enfermedades',
	},
	{
		code: '93131705',
		label: 'Programas de prevención o control de abuso de drogas',
	},
	{
		code: '93131800',
		label: 'Preparación y alivio de desastres',
	},
	{
		code: '93131801',
		label: 'Servicios de prevención de desastres',
	},
	{
		code: '93131802',
		label: 'Servicios de respuesta contra desastres',
	},
	{
		code: '93131803',
		label: 'Servicios de viviendas de emergencia',
	},
	{
		code: '93141500',
		label: 'Desarrollo y servicios sociales',
	},
	{
		code: '93141504',
		label: 'Servicios de cuidado en hogares adoptivos u orfanatos',
	},
	{
		code: '93141505',
		label: 'Servicios de adopción',
	},
	{
		code: '93141510',
		label: 'Estudios de estructura sociales o servicios relacionados',
	},
	{
		code: '93141511',
		label: 'Estudios de grupos sociales o servicios relacionados',
	},
	{
		code: '93141514',
		label: 'Servicios socioculturales',
	},
	{
		code: '93141600',
		label: 'Población',
	},
	{
		code: '93141601',
		label: 'Servicios de censo de la población',
	},
	{
		code: '93141602',
		label: 'Servicios de estudios de muestras de población',
	},
	{
		code: '93141605',
		label: 'Servicios de tendencias o proyecciones de la población',
	},
	{
		code: '93141606',
		label: 'Servicios de estadísticas de natalidad',
	},
	{
		code: '93141608',
		label: 'Servicios de distribución o análisis de la población',
	},
	{
		code: '93141609',
		label: 'Servicios de análisis de la composición de la población',
	},
	{
		code: '93141610',
		label: 'Estudios demográficos',
	},
	{
		code: '93141700',
		label: 'Cultura',
	},
	{
		code: '93141701',
		label: 'Organizaciones de eventos culturales',
	},
	{
		code: '93141702',
		label: 'Servicios de promoción cultural',
	},
	{
		code: '93141703',
		label: 'Servicios relacionados con el arte',

		palabrasSimilares:
			'Artesanías y obras de arte, Servicios de restauración de obras de arte y artesanías',
	},
	{
		code: '93141704',
		label: 'Servicios de composición de canciones',
	},
	{
		code: '93141705',
		label: 'Servicios de redacción literaria',
	},
	{
		code: '93141706',
		label: 'Servicios de protección de minorías',
	},
	{
		code: '93141707',
		label: 'Servicios de preservación o  promoción del patrimonio cultural',
	},
	{
		code: '93141708',
		label: 'Servicios de museos',
	},
	{
		code: '93141709',
		label: 'Servicios de política cultural',
	},
	{
		code: '93141710',
		label: 'Servicios de lenguas arcaicas o indígenas',
	},
	{
		code: '93141711',
		label: 'Servicios de promoción de artesanías tradicionales',
	},
	{
		code: '93141712',
		label: 'Servicios de protección de la propiedad intelectual o cultural',
	},
	{
		code: '93141713',
		label: 'Servicios de protección de sitios históricos o monumentos',
	},
];

export const unidadData = [
	{ code: 'H87', label: 'Pieza' },
	{ code: 'KGM', label: 'Kilogramo' },
	{ code: 'LTR', label: 'Litro' },
	{ code: 'MTR', label: 'Metro' },
	{ code: 'CMK', label: 'Centímetro cuadrado' },
	{ code: 'CMQ', label: 'Centímetro cúbico' },
	{ code: 'GRM', label: 'Gramo' },
	{ code: 'MLT', label: 'Mililitro' },
	{ code: 'MTK', label: 'Metro cuadrado' },
	{ code: 'MTQ', label: 'Metro cúbico' },
	{ code: 'LBR', label: 'Libra' },
	{ code: 'ONZ', label: 'Onza' },
	{ code: 'DZN', label: 'Docena' },
	{ code: 'MIL', label: 'Mil' },
	{ code: 'MMT', label: 'Milímetro' },
	{ code: 'INH', label: 'Pulgada' },
	{ code: 'SEC', label: 'Segundo' },
	{ code: 'MIN', label: 'Minuto' },
	{ code: 'HUR', label: 'Hora' },
	{ code: 'DAY', label: 'Día' },
	{ code: 'WEE', label: 'Semana' },
	{ code: 'MON', label: 'Mes' },
	{ code: 'ANN', label: 'Año' },
];

export const objetoDeImpuestoData = [
	{ code: '01', label: 'No objeto de impuesto' },
	{ code: '02', label: 'Sí objeto de impuesto' },
	{
		code: '03',
		label: 'Sí objeto del impuesto y no obligado al desglose',
	},
];

export const descripcionServicioData = [
	{ code: '01', label: 'Servicios profesionales (honorarios)' },
	{ code: '02', label: 'Arrendamiento' },
	{
		code: '03',
		label: 'Otro',
	},
];
