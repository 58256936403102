import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import checked from '../../../../../assets/images/checked.png';
import { profileActions } from '../../../../../store/profile';
import ClickOutsideReact from '../../../../common/Clickoutside';
import commonStyles from '../../../../common/Common.module.css';
import InfoItem from './InfoItem';
import InfoNotifications from './InfoNotifications';
import InfoUploadItem from './InfoUploadItem';
import classes from './Información.module.css';
import { firstColumn, secondColumn } from './constants';

export const Información = () => {
	// Client TOKEN / ID
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);
	const dispatch = useDispatch();
	const [modal, setModal] = useState(false);

	// Client status
	const {
		userEfirma,
		userCIEC,
		userKEYFile,
		userCERFile,
		userAnualPlan,
		userSubscription,
		userZipCode,
		userRegimenFiscal
	} = useSelector((state) => state.clientStatus);

	// Profile and Plan Complete
	const profileComplete = userZipCode && userRegimenFiscal && userEfirma && userCIEC && userKEYFile && userCERFile;
	const planComplete = userSubscription || userAnualPlan;

	const onClose = () => {
		setModal(false);
	};

	const setProfileData = (data) => {
		dispatch(profileActions.adduserprofiledata(data));
	};

	return (
		<div>
			<h2 className='md:text-[64px] md:leading-[81px] text-[32px] mb-5 font-bold leading-[37px]'>
				Mis Datos
			</h2>
			<InfoNotifications
				TOKEN={TOKEN}
				USERID={USERID}
				profileComplete={profileComplete}
				planComplete={planComplete}
			/>
			<div className='flex flex-col md:flex-row items-center justify-between max-w-[900px] mt-6'>
				<div>
					{firstColumn.map((x) => (
						<InfoItem
							key={x.title}
							title={x.title}
							type={x.type}
							fieldValue={x.fieldValue}
							fieldUserValue={x.fieldUserValue}
							tooltip={x?.tooltip}
							onComplete={setModal}
							dropdown={x?.dropdown}
							options={x?.options || []}
						/>
					))}
					<InfoUploadItem
						type={'cer'}
						fieldValue={'CERFile'}
						fieldUserValue={'userCERFile'}
						onComplete={setModal}
					/>
				</div>
				<div>
					{secondColumn.map((x) => (
						<InfoItem
							key={x.title}
							title={x.title}
							type={x.type}
							fieldValue={x.fieldValue}
							fieldUserValue={x.fieldUserValue}
							tooltip={x?.tooltip}
							onComplete={setModal}
							dropdown={x?.dropdown}
							options={x?.options || []}
						/>
					))}
					<InfoUploadItem
						type={'key'}
						fieldValue={'KEYFile'}
						fieldUserValue={'userKEYFile'}
						onComplete={setModal}
					/>
				</div>
			</div>
			{modal && (
				<div className={`${classes.uploadFile_container}`}>
					<ClickOutsideReact onClickOutside={onClose}>
						<div className={`${classes.uploadFile_modalComplete}`}>
							<div className={`${classes.uploadFile_bodyHeaderComplete}`}>
								<p className='flex items-center gap-[15px] md:text-[24px] text-[14px] font-normal'>
									<img
										src={checked}
										className='w-[35px]'
										alt='Successful Operation'
									/>
									¡Tu perfil Ciberconta está completo!
								</p>
							</div>
							<div className={`${classes.uploadFile_bodyTextComplete}`}>
								<p className='text-[#4285f4] px-[25px] md:text-[18px] text-[14px] font-normal'>
									Para terminar y poder empezar a disfrutar del servicio,
									contrata un plan de acuerdo a tus necesidades.
								</p>
							</div>
							<div className={`${classes.uploadFile_buttonWrapper}`}>
								<button
									onClick={() => {
										setModal(false);
										setProfileData('Mi');
									}}
									className={`${commonStyles.button} mt-[20px] py-4 px-5 bg-[#4285f4] text-white text-[14px] rounded-md font-medium md:text-[24px]`}
								>
									Ir a Mi Plan
								</button>
							</div>
						</div>
					</ClickOutsideReact>
				</div>
			)}
		</div>
	);
};

export default Información;
