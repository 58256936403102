import { default as React, useEffect, useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import mobileLogo2 from '../../../assets/images/Frame.png';
import logo from '../../../assets/images/logo.png';
import mobileLogo from '../../../assets/images/mobileLogo.png';
import bellIcon from '../../../assets/images/navbar/bell.png';
import hamIcon from '../../../assets/images/navbar/hamberger.png';
import logoutIcon from '../../../assets/images/navbar/logout.png';
import { persistor } from '../../../store/index';
import { profileActions } from '../../../store/profile';
import { countPendingNotifications } from '../../api/notifications';
import Notifications from '../notifications/Notifications';
import classes from './ProfileNav.module.css';

const ProfileNav = () => {
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [notifications, setNotifications] = useState(false);
	const [notificationsCount, setNotificationsCount] = useState('');
	const [refreshCount, setRefreshCount] = useState(false);
	const [showNav, setShowNav] = useState(false);

	const {
		userEfirma,
		userCIEC,
		userKEYFile,
		userCERFile,
		userAnualPlan,
		userSubscription,
		userZipCode, userRegimenFiscal
	} = useSelector((state) => state.clientStatus);

	const profileComplete = userZipCode && userRegimenFiscal && userEfirma && userCIEC && userKEYFile && userCERFile;
	const planComplete = userSubscription || userAnualPlan;

	const setProfileData = (data) => {
		dispatch(profileActions.adduserprofiledata(data));
		navigate('/profile');
	};

	const logout = () => {
		persistor.purge();
		navigate('/');
		setShowNav(false);
	};

	useEffect(() => {
		const fetchNotificationsCount = async () => {
			const totalNotifications = await countPendingNotifications(
				TOKEN,
				'client',
				USERID
			);
			setNotificationsCount(totalNotifications);
		};
		fetchNotificationsCount();
	}, [notifications, refreshCount]);

	return (
		<div className=' shadow-lg bg-white lg:h-[100px] h-[66px] fixed w-[100%] z-20 flex items-center'>
			<div className='container'>
				<div className='flex items-center justify-between relative'>
					<div className='logo'>
						<button
							className='hidden xl:flex'
							onClick={() => setProfileData('profile')}
						>
							<img src={logo} alt='' />
						</button>
						<button
							className=' xl:hidden'
							onClick={() => setProfileData('profile')}
						>
							<img src={mobileLogo} alt='' />
						</button>
					</div>
					<div className='flex gap-[40px] mt-2 items-center'>
						<div className='hidden lg:flex relative  gap-10'>
							<button
								onClick={() => setNotifications((prevState) => !prevState)}
							>
								<img
									src={bellIcon}
									alt='Notificaciones Ciberconta'
									className={`${classes.bell}`}
								/>
							</button>
							<button onClick={() => logout()}>
								<img
									src={logoutIcon}
									alt='Cerrar sesión'
									className={`${classes.logoutIcon} w-[27px]`}
								/>
							</button>
							{notificationsCount > 0 && (
								<div className={classes.blue_dot_wrapper}>
									<p className={classes.blue_dot_number}>
										{notificationsCount > 99 ? '99+' : notificationsCount}
									</p>
									<span className={classes.blue_dot}></span>
								</div>
							)}
						</div>
						<div className='xl:hidden flex items-center gap-5'>
							<button onClick={() => setShowNav(true)}>
								<img src={hamIcon} alt='' className='w-[20px]' />
							</button>
						</div>
					</div>
					<div
						className={`xl:hidden flex fixed top-0  w-[100%] z-20 h-screen opacity-100 duration-300 transition-all bg-[#4285F4] ${showNav ? 'left-0' : 'left-[-100%]'
							}`}
					>
						<div className='px-[30px] w-full pt-[30px]'>
							<div className='flex items-center justify-between w-full'>
								<button onClick={() => setProfileData('profile')}>
									<img src={mobileLogo2} alt='' />
								</button>
								<button
									onClick={() => setShowNav(false)}
									className='text-[30px] text-white'
								>
									<MdOutlineClose />
								</button>
							</div>
							<div>
								<div className='py-[80px]'>
									<div className='flex flex-col gap-6 items-start text-[14px] text-white'>
										<button
											onClick={() => {
												setProfileData('profile');
												setShowNav(false);
											}}
											className={`ml-5 w-full text-left`}
										>
											Dashboard
										</button>
										<div className='w-full h-[2px] bg-white'></div>
										<button
											onClick={() => {
												setProfileData('Información');
												setShowNav(false);
											}}
											className={`ml-5 w-full text-left`}
										>
											Mis Datos
										</button>
										<div className='w-full h-[2px] bg-white'></div>
										<button
											onClick={() => {
												setProfileData('Mi');
												setShowNav(false);
											}}
											className={`ml-5 w-full text-left`}
										>
											Mi Plan
										</button>
										<div className='w-full h-[2px] bg-white'></div>
										{profileComplete && planComplete && (
											<>
												<button
													onClick={() => {
														setProfileData('Declaraciones');
														setShowNav(false);
													}}
													className='ml-5 w-full text-left'
												>
													Declaraciones
												</button>
												<div className='w-full h-[2px] bg-white'></div>
												<button
													onClick={() => {
														setProfileData('Declaraciones');
														setShowNav(false);
													}}
													className='ml-5 w-full text-left'
												>
													Declaraciones
												</button>
											</>
										)}

										<button
											onClick={() => setProfileData('Aviso')}
											className={`ml-5 w-full text-left`}
										>
											Aviso de Privacidad
										</button>
										<div className='w-full h-[2px] bg-white'></div>
										<button
											onClick={() => logout()}
											className='ml-5 w-full text-left'
										>
											Cerrar sesión
										</button>
										<div className='w-full h-[2px] bg-white'></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{notifications && (
						<Notifications
							onRefreshCount={setRefreshCount}
							onClose={setNotifications}
							recipient={'client'}
							userId={USERID}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProfileNav;
