import CloseIcon from '@mui/icons-material/Close';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
} from '@mui/material';
import { default as React, useState } from 'react';
import generals from '../../../../../../css/Generals.module.css';
import Loading from '../../../../../common/Loading';
import {
	CFDI,
	monedas,
	paises,
	paymentForms,
	paymentMethods,
	regimenFiscal
} from '../constant/productData';
import classes from '../css/FacturaModal.module.css';
import FacturaModalNewConceptForm from './FacturaModalNewConceptForm';

const FacturaModalNewFacturaForm = ({
	loading,
	success,
	foreigner,
	error,
	facturaContact,
	setFacturaContact,
	isEmailValid,
	isRFCValid,
	isNombreClienteValid,
	isCpClienteValid,
	isRegimenFiscalClienteValid,
	isSubmitted,
	handleSubmit,
	handleClose,
	handleCheckbox,
	cleanErr,
}) => {
	const inputLabelStyles = {
		top: 14,
		left: 12.5,
		color: '#57585AD2',
		opacity: 0.4,
	};

	const [conceptModal, setConceptModal] = useState(false);
	const [conceptoSubmitted, setConceptoSubmitted] = useState(false);

	const handleSaveConcept = () => {
		setConceptoSubmitted(true);

		const requiredFields = [
			'producto',
			'cantidad',
			'precioUnitario',
			'unidad',
			'importe',
			'objetoDeImpuesto',
			'descripciondetallada',
		];

		const allFieldsFilled = requiredFields.every(
			(field) => facturaContact.concepto[field]
		);

		if (allFieldsFilled) {
			setConceptModal(false);
		}
	};

	const isConceptoValid = (facturaContact) => {
		return (
			facturaContact.concepto?.codigoProducto &&
			facturaContact.concepto?.producto &&
			facturaContact.concepto?.unidad &&
			facturaContact.concepto?.cantidad &&
			facturaContact.concepto?.precioUnitario &&
			facturaContact.concepto?.objetoDeImpuesto &&
			facturaContact.concepto?.importe &&
			facturaContact.concepto?.descripciondetallada
		)
	};

	return (
		<div className={classes.modal_overlay}>
			<div className={classes.modal_container}>
				<button className={classes.icon_close_wrapper} onClick={handleClose}>
					<CloseIcon sx={{ fontSize: 25, color: '#b9b9b9' }} />
				</button>
				<h1 className={classes.modal_title}>Nueva factura</h1>
				<h2 className={classes.modal_subtitle}>
					{success.state ? 'Operación exitosa' : 'Llena los campos para crear tu solicitud de factura'}
				</h2>
				{!loading && !success.state && (
					<div className={classes.modal_form}>
						{!conceptModal && (
							<div className={classes.modal_checkbox_wrapper}>
								<h2 className={generals.text_input_subtitle}>
									¿Tu cliente es extranjero?
								</h2>

								<FormControlLabel
									control={
										<Checkbox checked={foreigner} onChange={handleCheckbox} />
									}
									label='Sí'
								/>
								<FormControlLabel
									control={
										<Checkbox checked={!foreigner} onChange={handleCheckbox} />
									}
									label='No'
								/>
							</div>
						)}
						{!conceptModal && (
							<div className={classes.modal_form_wrapper_column}>
								<div className={classes.modal_form_column}>
									{foreigner && (
										<>
											<h2 className={generals.text_input_subtitle}>
												País de tu cliente
											</h2>
											<FormControl>
												<InputLabel htmlFor='regimen-fiscal' sx={inputLabelStyles}>
													País
												</InputLabel>
												<Select
													id='client-country'
													sx={{ width: 400, height: 65 }}
													className={
														isSubmitted === 'factura' &&
															!facturaContact.pais
															? `${generals.select_input} ${generals.select_input_error}`
															: generals.select_input
													}
													value={facturaContact.pais}
													onChange={(e) => {
														setFacturaContact((prevState) => ({
															...prevState,
															pais: e.target.value || '',
														}));
														cleanErr();
													}}
												>
													{paises.map((p) => (
														<MenuItem key={p} value={p}>
															{p}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</>
									)}
									<h2 className={generals.text_input_subtitle}>
										Nombre o razón social de tu cliente
									</h2>
									<TextField
										required
										sx={{ width: 400 }}
										className={
											isSubmitted === 'factura' && (!facturaContact.clientName || !isNombreClienteValid)
												? `${generals.text_input} ${generals.text_input_error}`
												: generals.text_input
										}
										id='cliente-nombre'
										variant='outlined'
										placeholder='Razón social'
										value={facturaContact.clientName || ''}
										onChange={(e) => {
											setFacturaContact((prevState) => ({
												...prevState,
												clientName: e.target.value || '',
											}));
											if (error.state) cleanErr();
										}}
									/>
									<h2 className={generals.text_input_subtitle}>
										Código postal de tu cliente
									</h2>
									<TextField
										required
										sx={{ width: 400 }}
										className={
											isSubmitted === 'factura' && (!facturaContact.clientZipCode || !isCpClienteValid)
												? `${generals.text_input} ${generals.text_input_error}`
												: generals.text_input
										}
										id='cliente-CP'
										variant='outlined'
										placeholder='C.P.'
										value={facturaContact.clientZipCode || ''}
										onChange={(e) => {
											setFacturaContact((prevState) => ({
												...prevState,
												clientZipCode: e.target.value.trim() || '',
											}));
											cleanErr();
										}}
									/>
									<h2 className={generals.text_input_subtitle}>
										Regimen Fiscal
									</h2>
									<FormControl>
										<InputLabel htmlFor='regimen-fiscal' sx={inputLabelStyles}>
											Regimen Fiscal
										</InputLabel>
										<Select
											id='regimen-fiscal'
											sx={{ width: 400, height: 65 }}
											className={
												isSubmitted === 'factura' &&
													(!facturaContact.regimenFiscal || !isRegimenFiscalClienteValid)
													? `${generals.select_input} ${generals.select_input_error}`
													: generals.select_input
											}
											value={facturaContact.regimenFiscal}
											onChange={(e) => {
												setFacturaContact((prevState) => ({
													...prevState,
													regimenFiscal: e.target.value || '',
												}));
												cleanErr();
											}}
										>
											{regimenFiscal.map((reg) => (
												<MenuItem key={reg} value={reg}>
													{reg}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									<h2 className={generals.text_input_subtitle}>Uso de CFDI</h2>
									<FormControl>
										<InputLabel htmlFor='uso-cfdi' sx={inputLabelStyles}>
											CFDI
										</InputLabel>
										<Select
											id='uso-cfdi'
											sx={{ width: 400, height: 65 }}
											className={
												isSubmitted === 'factura' && !facturaContact.CFDI
													? `${generals.select_input} ${generals.select_input_error}`
													: generals.select_input
											}
											value={facturaContact.CFDI}
											onChange={(e) => {
												setFacturaContact((prevState) => ({
													...prevState,
													CFDI: e.target.value || '',
												}));
												cleanErr();
											}}
											input={<OutlinedInput />}
										>
											{CFDI.map((cfdi) => (
												<MenuItem key={cfdi} value={cfdi}>
													{cfdi}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									<h2 className={generals.text_input_subtitle}>
										Método de pago
									</h2>
									<FormControl>
										<InputLabel htmlFor='metodo-de-pago' sx={inputLabelStyles}>
											Método de pago
										</InputLabel>
										<Select
											id='metodo-de-pago'
											sx={{ width: 400, height: 65 }}
											className={
												isSubmitted === 'factura' &&
													!facturaContact.paymentMethod
													? `${generals.select_input} ${generals.select_input_error}`
													: generals.select_input
											}
											value={facturaContact.paymentMethod}
											onChange={(e) => {
												setFacturaContact((prevState) => ({
													...prevState,
													paymentMethod: e.target.value || '',
												}));
												cleanErr();
											}}
										>
											{paymentMethods.map((method) => (
												<MenuItem key={method} value={method}>
													{method}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
								<div className={classes.modal_form_column}>
									{foreigner && (
										<>
											<h2 className={generals.text_input_subtitle}>
												Identificación fiscal
											</h2>
											<TextField
												required
												sx={{ width: 400 }}
												className={
													isSubmitted === 'factura' && !facturaContact.idfiscal
														? `${generals.text_input} ${generals.text_input_error}`
														: generals.text_input
												}
												id='cliente-idfiscal'
												variant='outlined'
												placeholder='ID Fiscal'
												value={facturaContact.idfiscal || ''}
												onChange={(e) => {
													setFacturaContact((prevState) => ({
														...prevState,
														idfiscal: e.target.value || '',
													}));
													cleanErr();
												}}
											/>
										</>
									)}
									<h2 className={generals.text_input_subtitle}>
										Correo de tu cliente
									</h2>
									<TextField
										required
										sx={{ width: 400 }}
										className={
											isSubmitted === 'factura' &&
												(!facturaContact.clientEmail || !isEmailValid)
												? `${generals.text_input} ${generals.text_input_error}`
												: generals.text_input
										}
										id='cliente-email'
										variant='outlined'
										placeholder='Email'
										value={facturaContact.clientEmail || ''}
										onChange={(e) => {
											setFacturaContact((prevState) => ({
												...prevState,
												clientEmail: e.target.value.trim() || '',
											}));
											if (error.state) cleanErr();
										}}
									/>
									<h2 className={generals.text_input_subtitle}>
										RFC de tu cliente
									</h2>
									<TextField
										required
										sx={{ width: 400 }}
										className={
											isSubmitted === 'factura' &&
												(!facturaContact.clientRFC || !isRFCValid)
												? `${generals.text_input} ${generals.text_input_error}`
												: generals.text_input
										}
										id='cliente-rfc'
										variant='outlined'
										placeholder='RFC'
										value={facturaContact.clientRFC || ''}
										onChange={(e) => {
											setFacturaContact((prevState) => ({
												...prevState,
												clientRFC: e.target.value.trim() || '',
											}));
											if (error.state) cleanErr();
										}}
									/>
									<h2 className={generals.text_input_subtitle}>
										Forma de pago
									</h2>
									<FormControl>
										<InputLabel htmlFor='forma-de-pago' sx={inputLabelStyles}>
											Forma de pago
										</InputLabel>
										<Select
											id='forma-de-pago'
											sx={{ width: 400, height: 65 }}
											className={
												isSubmitted === 'factura' &&
													!facturaContact.paymentForm
													? `${generals.select_input} ${generals.select_input_error}`
													: generals.select_input
											}
											value={facturaContact.paymentForm}
											onChange={(e) => {
												setFacturaContact((prevState) => ({
													...prevState,
													paymentForm: e.target.value || '',
												}));
												cleanErr();
											}}
											input={<OutlinedInput />}
										>
											{paymentForms.map((form) => (
												<MenuItem key={form} value={form}>
													{form}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									<h2 className={generals.text_input_subtitle}>Moneda</h2>
									<FormControl>
										<InputLabel htmlFor='moneda' sx={inputLabelStyles}>
											Moneda
										</InputLabel>
										<Select
											id='moneda'
											sx={{ width: 400, height: 65 }}
											className={
												isSubmitted === 'factura' && !facturaContact.currency
													? `${generals.select_input} ${generals.select_input_error}`
													: generals.select_input
											}
											value={facturaContact.currency}
											onChange={(e) => {
												setFacturaContact((prevState) => ({
													...prevState,
													currency: e.target.value || '',
												}));
												cleanErr();
											}}
											input={<OutlinedInput />}
										>
											{monedas.map((moneda) => (
												<MenuItem key={moneda} value={moneda}>
													{moneda}
												</MenuItem>
											))}
										</Select>

									</FormControl>
									<h2 className={generals.text_input_subtitle}>
										Concepto a facturar
									</h2>
									<button
										onClick={() => { cleanErr(); setConceptModal(true) }}
										className={
											(isSubmitted === 'factura' && !isConceptoValid(facturaContact))
												? `${classes.modal_factura_button_concept} ${classes.modal_factura_button_concept_error}`
												: classes.modal_factura_button_concept
										}
									>
										{isConceptoValid(facturaContact) ? 'Editar concepto' : '+ Seleccionar concepto'}
									</button>
								</div>
							</div>
						)}
						{conceptModal && (
							<FacturaModalNewConceptForm
								facturaContact={facturaContact}
								setFacturaContact={setFacturaContact}
								cleanErr={cleanErr}
								error={error}
								conceptoSubmitted={conceptoSubmitted}
							/>
						)}
					</div>
				)}
				{!loading && success.state && (
					<div className={classes.modal_info_loading_container}>
						<p className={`mt-5 ${generals.modal_success_text}`}>
							{success.message || 'Tu factura ha sido procesada de manera exitosa. Revisa su estatus en tu portal de facturas.'}
						</p>

						<button
							className={`${generals.button_blue}`}
							onClick={handleClose}
						>
							Cerrar
						</button>
					</div>
				)}
				{!loading && error.state && (
					<div className={classes.modal_message_wrapper}>
						<h2 className={classes.modal_error_title}>{error.title}</h2>
						<p className={classes.modal_error_message}>{error.message}</p>
					</div>
				)}
				{!loading && !success.state && !error.state && (
					<div className={classes.modal_button_wrapper}>
						<button
							className={`${generals.button_blue} my-4`}
							onClick={conceptModal ? handleSaveConcept : handleSubmit}
						>
							{conceptModal ? 'Guardar concepto' : 'Enviar solicitud'}
						</button>

						{conceptModal && <button
							className={`${generals.button_white}`}
							onClick={() => setConceptModal(false)}
						>
							Regresar
						</button>}
					</div>
				)}
				{loading && (
					<div className={classes.modal_loading}>
						<Loading />
					</div>
				)}
			</div>
		</div>
	);
};

export default FacturaModalNewFacturaForm;
