import EditNoteIcon from '@mui/icons-material/EditNote';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { useSelector } from 'react-redux';
import { getExistingUserFiles, getUserFiles } from '../../../../../api/userfiles';
import classes from '../css/DetailItem.module.css';
import { roundToTwoDecimals } from '../utils/utils';

const DetailPendings = ({ userData, setMoreInfo }) => {
	const { pending } = useSelector((state) => state.factura);
	const TOKEN = useSelector((state) => state.auth.tokenId);
	let documentTypeInfoText = '';

	switch (userData.estatus) {
		case 'emitida': {
			documentTypeInfoText = 'Factura emitida';
			break;
		}
		case 'cancelada': {
			documentTypeInfoText = 'Solicitud de cancelación';
			break;
		}
		case 'cancelación emitida': {
			documentTypeInfoText = 'Factura cancelada';
			break;
		}
		case 'solicitada': {
			documentTypeInfoText = 'Solicitud de factura';
			break;
		}
		default: {
			documentTypeInfoText = 'Factura emitida';
		}
	}

	const getStatusClassName = (status) => {
		switch (status) {
			case 'emitida': {
				return `${classes.detail_status}`;
			}
			case 'solicitada': {
				return `${classes.detail_status}`;
			}
			case 'cancelada': {
				return `${classes.detail_status} ${classes.pending}`;
			}
			case 'cancelación emitida': {
				return `${classes.detail_status} ${classes.pending}`;
			}
			default: {
				return `${classes.detail_status}`;
			}
		}
	};

	const handleOpenMoreInfoModal = () => {
		setMoreInfo({
			state: true,
			facturaId: userData.id,
			userRFC: userData.userRFC,
			folioFiscal: userData.folioFiscal,
			data: [
				{ field: 'Tipo', value: documentTypeInfoText },
				{ field: 'Folio fiscal', value: userData.folioFiscal },
				{ field: 'Nombre del usuario', value: userData.userName },
				{ field: 'RFC del usuario', value: userData.userRFC },
				{ field: 'Extranjero', value: userData.extranjero },
				{ field: 'País', value: userData.pais },
				{ field: 'Identificación fiscal', value: userData.idFiscal },
				{ field: 'Nombre / Razón social del cliente', value: userData.nombre },
				{ field: 'RFC del cliente', value: userData.RFC },
				{ field: 'Email del cliente', value: userData.email },
				{ field: 'Código Postal del cliente', value: userData.CP },
				{ field: 'Régimen fiscal', value: userData.regimenFiscal },
				{ field: 'Forma de pago', value: userData.formaPago },
				{ field: 'Uso CFDI', value: userData.usoCFDI },
				{ field: 'Descripción', value: userData.descripcion },
				{ field: 'Método de pago', value: userData.metodoPago },
				{ field: 'Total', value: `$${roundToTwoDecimals(userData.total)}` },
				{ field: 'Estatus', value: userData.estatus },
				{ field: 'Fecha', value: userData.fechaSolicitud },
			],
		});
	};

	const retrieveFiles = async () => {
		const fetchAndOpenFile = async (fileName) => {
			const existingFile = await getExistingUserFiles(TOKEN, fileName);
			if (existingFile.status === 'SUCCESS' && existingFile.data.data.length > 0) {
				const file = await getUserFiles(TOKEN, fileName);
				if (file.status === 'SUCCESS' && file.data) {
					window.open(file.data);
				}
			}
		};

		if (userData.estatus === 'emitida') {
			const fileNamePDF = `${userData.userRFC}_FACTURA_${userData.folioFiscal}.pdf`;
			const fileNameXML = `${userData.userRFC}_FACTURA_${userData.folioFiscal}.xml`;

			await fetchAndOpenFile(fileNamePDF);
			await fetchAndOpenFile(fileNameXML);
		} else {
			const fileNamePDF = `${userData.userRFC}_FACTURACANCELADA_${userData.folioFiscal}.pdf`;
			const fileNameXML = `${userData.userRFC}_FACTURACANCELADA_${userData.folioFiscal}.xml`;

			await fetchAndOpenFile(fileNamePDF);
			await fetchAndOpenFile(fileNameXML);
		}
	};

	return (
		<div className={classes.detail_wrapper}>
			{pending && (
				<button
					onClick={handleOpenMoreInfoModal}
					className={classes.detail_element_seemore}
				>
					<EditNoteIcon sx={{ fontSize: 30 }} />
				</button>
			)}

			<button
				onClick={handleOpenMoreInfoModal}
				className={classes.detail_element}
			>
				{userData.userRFC}
			</button>
			<button
				onClick={handleOpenMoreInfoModal}
				className={classes.detail_element}
			>
				{userData.nombre}
			</button>
			<button
				onClick={handleOpenMoreInfoModal}
				className={classes.detail_element}
			>
				${roundToTwoDecimals(userData.total)}
			</button>
			<button
				onClick={handleOpenMoreInfoModal}
				className={classes.detail_element}
			>
				{userData.fechaSolicitud}
			</button>
			<button
				onClick={handleOpenMoreInfoModal}
				className={classes.detail_element_download}
			>
				<h2 className={getStatusClassName(userData.estatus)}>
					{documentTypeInfoText || 'sin estatus'}
				</h2>
			</button>
			{!pending && (
				<button
					className={classes.detail_element_seemore}
					onClick={retrieveFiles}
				>
					<FileDownloadOutlinedIcon sx={{ fontSize: 25 }} />
				</button>
			)}
			{!pending && (
				<button
					onClick={handleOpenMoreInfoModal}
					className={classes.detail_element_seemore}
				>
					<MoreVertIcon sx={{ fontSize: 25 }} />
				</button>
			)}
		</div>
	);
};

export default DetailPendings;
