import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import accounting from '../../../../../assets/images/MiPlan/accounting.png';
import text from '../../../../../css/Text.module.css';
import { profileActions } from '../../../../../store/profile';
import Card from '../../../../common/Card';
import classes from './Miplan.module.css';

const Miplan = () => {
	const [monthIsActive, setMonthIsActive] = useState(true);
	const userEmail = useSelector((state) => state.clientStatus.userEmail);
	const userStripeId = useSelector((state) => state.clientStatus.userStripeId);
	const stripe = require('stripe')(process.env.REACT_APP_STRIPE);

	// Dispatch
	const dispatch = useDispatch();
	const setProfileData = (data) => {
		dispatch(profileActions.adduserprofiledata(data));
	};

	// Client status
	const {
		userEfirma,
		userCIEC,
		userKEYFile,
		userCERFile,
		userAnualPlan,
		userSubscription,
		userZipCode,
		userRegimenFiscal
	} = useSelector((state) => state.clientStatus);

	const profileComplete = userEfirma && userCIEC && userKEYFile && userCERFile && userZipCode && userRegimenFiscal;
	const planComplete = userSubscription || userAnualPlan;

	useEffect(() => {
		const createSessions = async () => {
			if (userStripeId) {
				// Plan Fácil Fiscal Mensual
				const sessionSubscription = await stripe.checkout.sessions.create({
					success_url: 'https://ciberconta.com/success',
					line_items: [
						{ price: 'price_1NVGCcG1EJAi3VRCacR1sJpl', quantity: 1 }, // LIVE
						// { price: 'price_1NVwyyG1EJAi3VRC2ZsHaKw0', quantity: 1 }, // TEST
					],
					mode: 'subscription',
					currency: 'mxn',
					customer: userStripeId,
					locale: 'es-419',
				});
				if (sessionSubscription?.url) {
					setSubscriptionURL(sessionSubscription?.url);
				}

				// Plan Fácil Fiscal Anual
				const sessionSubscriptionAnual = await stripe.checkout.sessions.create({
					success_url: 'https://ciberconta.com/success',
					line_items: [
						{ price: 'price_1OpuHCG1EJAi3VRCtgNOD2bb', quantity: 1 }, // LIVE
					],
					mode: 'subscription',
					currency: 'mxn',
					customer: userStripeId,
					locale: 'es-419',
				});
				if (sessionSubscriptionAnual?.url) {
					setSubscriptionAnualURL(sessionSubscriptionAnual?.url);
				}

				// Plan Rescate Fiscal Anual
				const sessionPayment = await stripe.checkout.sessions.create({
					success_url: 'https://ciberconta.com/success',
					line_items: [
						{ price: 'price_1OhEOWG1EJAi3VRCObwF6BpX', quantity: 1 }, // LIVE
						// { price: 'price_1NWPADG1EJAi3VRC91wq164X', quantity: 1 }, // TEST
					],
					mode: 'payment',
					currency: 'mxn',
					customer: userStripeId,
					locale: 'es-419',
				});
				if (sessionPayment?.url) {
					setAnualPaymentURL(sessionPayment?.url);
				}
			}
		};
		createSessions();
	}, []);

	const emailEncoded = encodeURIComponent(userEmail);

	const urlRescateFiscal =
		process.env.REACT_APP_STRIPE_MODE == 'TEST'
			? `https://buy.stripe.com/test_14k4j33GC0XgcOQeUV?prefilled_email=${emailEncoded}&locale=es-419`
			: `https://buy.stripe.com/6oEbKM54h9lCgGA7sz?prefilled_email=${emailEncoded}&locale=es-419`;

	const urlFacilFiscalMensual =
		process.env.REACT_APP_STRIPE_MODE == 'TEST'
			? `https://buy.stripe.com/test_9AQ9Dnfpk6hA0246oo?prefilled_email=${emailEncoded}&locale=es-419`
			: `https://buy.stripe.com/dR69CE54hgO40HC9AA?prefilled_email=${emailEncoded}&locale=es-419`;

	const urlFacilFiscalAnual = `https://buy.stripe.com/eVacOQ68l0P64XSbIO?prefilled_email=${emailEncoded}&locale=es-419`;

	const manageAccount =
		process.env.REACT_APP_STRIPE_MODE == 'TEST'
			? `https://billing.stripe.com/p/login/test_6oE7sN9nK9q8fy8000?prefilled_email=${emailEncoded}&locale=es-419`
			: `https://billing.stripe.com/p/login/8wM6pe4Unbvg8p2fYY?prefilled_email=${emailEncoded}&locale=es-419`;

	const [subscriptionURL, setSubscriptionURL] = useState(urlFacilFiscalMensual);
	const [anualPaymentURL, setAnualPaymentURL] = useState(urlRescateFiscal);
	const [subscriptionAnualURL, setSubscriptionAnualURL] =
		useState(urlFacilFiscalAnual);

	return (
		<div>
			<div>
				<div className='flex items-center gap-[20px] mb-5'>
					<h2 className='md:text-[64px] md:leading-[81px] text-[32px] font-bold leading-[37px]'>
						Mi plan
					</h2>
				</div>
				{userStripeId && profileComplete && planComplete && (
					<Card
						title={'Maneja tu suscripción'}
						subtitle={'Accede a tu portal.'}
						description={
							'Nos aliamos con Stripe, una plataforma digital reconocida a nivel mundial, para facilitar tu proceso de pago de manera segura. En este portal podrás manejar los datos de tu suscripción.'
						}
						img={accounting}
						imgAlt={'Maneja tu cuenta'}
						primaryCta={true}
						primaryCtaText={'Manejar mi plan'}
						onClickPrimaryCta={() => window.open(manageAccount)}
						secondaryCta={true}
						secondaryCtaText={'Contactar un asesor'}
						onClickSecondaryCta={() => window.Intercom('show')}
						overrideClass={'mb-[70px]'}
					/>
				)}
			</div>

			<h1 className={`${text.text_title_black_lg}`}>Planes Ciberconta</h1>

			<div className='flex xl:items-start items-center gap-9 xl:flex-row flex-col mt-[40px]'>
				<div className={classes.plan__MainContainer}>
					<div className='mx-10 md:mx-20 xl:mx-0 flex justify-center flex-wrap'>
						<div className={`${classes.plan__Container_Year} price_box`}>
							<h2 className={classes.plan__Title}>Plan RescateFiscal</h2>
							<p className='text-center text-[20px] leading-[37px]'>
								¡Ponte al Día con tus Impuestos!
							</p>
							<div className={classes.plan__PriceWrapper}>
								<h2 className='text-center text-[64px] leading-[75px] font-semibold'>
									$3,000
								</h2>
								<p className='text-center text-[24px] leading-[37px]'>
									Por año no declarado
								</p>
							</div>

							<h2 className={classes.plan__Body}>
								¿Te encuentras atrasado en tus declaraciones ante el SAT? ¡No te
								preocupes más!
							</h2>
							<button className={classes.plan__SelectPlan__button}>
								<a href={anualPaymentURL} target='_blank'>
									Elegir RescateFiscal
								</a>
							</button>
						</div>
						<div className={classes.plan__ContainerDescription}>
							<ul className={classes.plan__ContainerDescription__list}>
								<li className={classes.plan__ContainerDescription__item}>
									🔎{' '}
									<span style={{ fontWeight: '700' }}>
										Análisis y Regularización:
									</span>{' '}
									Examinamos tu situación fiscal y te ayudamos a regularizar tus
									declaraciones, sin importar cuántos años no hayas declarado.
								</li>
								<li className={classes.plan__ContainerDescription__item}>
									🤝{' '}
									<span style={{ fontWeight: '700' }}>
										Colaboración Confiable:
									</span>{' '}
									Trabajamos codo a codo contigo para resolver cualquier
									situación fiscal pendiente.
								</li>
								<li className={classes.plan__ContainerDescription__item}>
									🚀{' '}
									<span style={{ fontWeight: '700' }}>
										Ponte al día con tus Impuestos:
									</span>{' '}
									Ponemos orden en tus asuntos fiscales y te ayudamos a cumplir
									con tus obligaciones de manera eficiente.
								</li>
								<li className={classes.plan__ContainerDescription__item}>
									🔐{' '}
									<span style={{ fontWeight: '700' }}>Solución Integral:</span>{' '}
									Deja atrás las preocupaciones y céntrate en lo que más
									importa: hacer crecer tus ingresos.
								</li>
							</ul>
						</div>
					</div>
				</div>
				{monthIsActive ? (
					<div className={classes.plan__MainContainer}>
						<div className='mx-10 md:mx-20 xl:mx-0 mt-20 xl:mt-0 flex justify-center flex-wrap relative '>
							<div className={classes.plan__ContainerOptions__wrapper}>
								<button
									className={
										monthIsActive
											? `${classes.plan__ContainerOptions__itemLeft} ${classes.active}`
											: `${classes.plan__ContainerOptions__itemLeft}`
									}
									onClick={() => setMonthIsActive(true)}
								>
									<h2 className={classes.plan__ContainerOptions__itemText}>
										Mensual
									</h2>
								</button>
								<button
									className={
										monthIsActive
											? `${classes.plan__ContainerOptions__itemRight}`
											: `${classes.plan__ContainerOptions__itemRight} ${classes.active}`
									}
									onClick={() => setMonthIsActive(false)}
								>
									<h2 className={classes.plan__ContainerOptions__itemText}>
										Anual
									</h2>
									{/* <p className={classes.plan__ContainerOptions__promo}>
										Promo fin de año
									</p> */}
								</button>
							</div>

							<div className={`${classes.plan__Container} price_box`}>
								<h2 className={classes.plan__Title}>Ciberconta FácilFiscal</h2>
								<p className='text-center text-[20px] leading-[37px] '>
									¡Simplifica tus Finanzas con Nosotros!
								</p>
								<div className={classes.plan__PriceWrapper}>
									<h2 className='text-[64px] leading-[75px] font-semibold'>
										$300
									</h2>
									<p className='text-center text-[24px] leading-[37px] '>
										Mensual
									</p>
								</div>
								<h2 className={classes.plan__Body}>
									Por tan solo $300 al mes, olvídate de los líos fiscales y
									abraza la tranquilidad financiera.
								</h2>
								<button className={classes.plan__SelectPlan__button}>
									<a href={subscriptionURL} target='_blank'>
										Elegir FácilFiscal Mensual
									</a>
								</button>
							</div>
							<div className={classes.plan__ContainerDescription}>
								<ul className={classes.plan__ContainerDescription__list}>
									<li className={classes.plan__ContainerDescription__item}>
										📊{' '}
										<span style={{ fontWeight: '700' }}>
											Cálculo de Impuestos:
										</span>{' '}
										Calcula con facilidad impuestos como el IVA y el ISR. Deja
										que nuestra herramienta te guíe en cada paso.
									</li>
									<li className={classes.plan__ContainerDescription__item}>
										📤{' '}
										<span style={{ fontWeight: '700' }}>
											Presentación de Declaraciones:
										</span>{' '}
										Olvídate del estrés de las declaraciones fiscales. En abril,
										hay un pequeño cargo extra por la declaración anual.
									</li>
									<li className={classes.plan__ContainerDescription__item}>
										👁️ <span style={{ fontWeight: '700' }}>Transparencia:</span>{' '}
										Mantén un ojo en cada detalle. Ve lo que se va a presentar
										en tu declaración y apruébalo en un paso.
									</li>
									<li className={classes.plan__ContainerDescription__item}>
										💡 <span style={{ fontWeight: '700' }}>Evita Multas:</span>{' '}
										Nuestros recordatorios y seguimientos de fechas de
										vencimiento te mantienen a salvo de multas innecesarias.
									</li>
									<li className={classes.plan__ContainerDescription__item}>
										📱{' '}
										<span style={{ fontWeight: '700' }}> Accesibilidad:</span>{' '}
										Accede a tus comprobantes fiscales y líneas de captura en
										formato digital, todo desde la palma de tu mano.
									</li>
									<li className={classes.plan__ContainerDescription__item}>
										🤝{' '}
										<span style={{ fontWeight: '700' }}>
											{' '}
											Interfaz Amigable:
										</span>{' '}
										Nuestra plataforma es fácil de usar y amigable. No necesitas
										ser un experto en contabilidad para utilizarla con éxito.
									</li>
									<li className={classes.plan__ContainerDescription__item}>
										🧾{' '}
										<span style={{ fontWeight: '700' }}>
											{' '}
											Factura hasta 2 veces al mes sin complicaciones:
										</span>{' '}
										Simplifica la facturación y controla tus ingresos de manera
										efectiva.
									</li>
									<li className={classes.plan__ContainerDescription__item}>
										💰 <span style={{ fontWeight: '700' }}> Pago Mensual:</span>{' '}
										Una suscripción mensual te brinda tranquilidad. Mantén tus
										asuntos fiscales en orden sin complicaciones.
									</li>
									<li className={classes.plan__ContainerDescription__item}>
										🗣{' '}
										<span style={{ fontWeight: '700' }}>
											{' '}
											Asesoría Personalizada:{' '}
										</span>{' '}
										Contadores expertos disponibles por mail, chat y WhatsApp.
									</li>
								</ul>
							</div>
						</div>
					</div>
				) : (
					<div className={classes.plan__MainContainer}>
						<div className='mx-10 md:mx-20 xl:mx-0 mt-20 xl:mt-0 flex justify-center flex-wrap relative '>
							<div className={classes.plan__ContainerOptions__wrapper}>
								<button
									className={
										monthIsActive
											? `${classes.plan__ContainerOptions__itemLeft} ${classes.active}`
											: `${classes.plan__ContainerOptions__itemLeft}`
									}
									onClick={() => setMonthIsActive(true)}
								>
									<h2 className={classes.plan__ContainerOptions__itemText}>
										Mensual
									</h2>
								</button>
								<button
									className={
										monthIsActive
											? `${classes.plan__ContainerOptions__itemRight}`
											: `${classes.plan__ContainerOptions__itemRight} ${classes.active}`
									}
									onClick={() => setMonthIsActive(false)}
								>
									<h2 className={classes.plan__ContainerOptions__itemText}>
										Anual
									</h2>
									{/* <p className={classes.plan__ContainerOptions__promo}>
										Promo fin de año
									</p> */}
								</button>
							</div>
							<div className={`${classes.plan__Container} price_box`}>
								<h2 className={classes.plan__Title}>Ciberconta FácilFiscal</h2>
								<p className='text-center text-[20px] leading-[37px]'>
									¡Simplifica tus Finanzas con Nosotros!
								</p>
								<div className={classes.plan__PriceWrapper}>
									<h2 className='text-[64px] leading-[75px] font-semibold'>
										$2,999
									</h2>
									<p className='text-center text-[24px] leading-[37px]'>
										Anual
									</p>
								</div>
								<h2 className={classes.plan__Body}>
									💡¡Explora Ciberconta FácilFiscal anual por solo $2,999 al
									año! 💰
								</h2>
								<button className={classes.plan__SelectPlan__button}>
									<a href={subscriptionAnualURL} target='_blank'>
										Elegir FácilFiscal Anual
									</a>
								</button>
							</div>

							<div className={classes.plan__ContainerDescription}>
								<ul className={classes.plan__ContainerDescription__list}>
									<li className={classes.plan__ContainerDescription__item}>
										📊{' '}
										<span style={{ fontWeight: '700' }}>
											Cálculo de Impuestos:
										</span>{' '}
										Simplifica el IVA y el ISR con nuestra herramienta fácil y
										amigable.
									</li>
									<li className={classes.plan__ContainerDescription__item}>
										📤{' '}
										<span style={{ fontWeight: '700' }}>
											Presentación de Declaraciones:
										</span>{' '}
										Olvídate del estrés de las declaraciones fiscales.
									</li>
									<li className={classes.plan__ContainerDescription__item}>
										👁️ <span style={{ fontWeight: '700' }}>Transparencia:</span>{' '}
										Revisa y aprueba fácilmente lo que presentaremos en tu
										declaración.
									</li>
									<li className={classes.plan__ContainerDescription__item}>
										💡 <span style={{ fontWeight: '700' }}>Evita Multas:</span>{' '}
										Nuestras notificaciones te mantienen a salvo de multas
										innecesarias.
									</li>
									<li className={classes.plan__ContainerDescription__item}>
										📱{' '}
										<span style={{ fontWeight: '700' }}> Accesibilidad:</span>{' '}
										Tus comprobantes y líneas de captura siempre al alcance.
									</li>
									<li className={classes.plan__ContainerDescription__item}>
										🤝{' '}
										<span style={{ fontWeight: '700' }}>
											{' '}
											Interfaz Amigable:
										</span>{' '}
										Intuitiva y fácil de usar.
									</li>
									<li className={classes.plan__ContainerDescription__item}>
										🧾{' '}
										<span style={{ fontWeight: '700' }}>
											{' '}
											Hasta 50 Facturas al Año:
										</span>{' '}
										Simplifica tu facturación sin complicaciones.
									</li>
									<li className={classes.plan__ContainerDescription__item}>
										💰 <span style={{ fontWeight: '700' }}> Pago Anual:</span>{' '}
										Tranquilidad anual para tus asuntos fiscales.
									</li>
									<li className={classes.plan__ContainerDescription__item}>
										🗣{' '}
										<span style={{ fontWeight: '700' }}>
											{' '}
											Asesoría Personalizada:
										</span>{' '}
										Contadores expertos disponibles por mail, chat y WhatsApp.
										¡Simplifica tu vida financiera con Ciberconta! 🌐💼
									</li>
								</ul>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Miplan;
