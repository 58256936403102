import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { default as React } from 'react';
import { useSelector } from 'react-redux';
import { formatMexicoTz } from '../../../../../../utils/format';
import { getExistingUserFiles, getUserFiles } from '../../../../../api/userfiles';
import classes from '../css/DetailItem.module.css';
import { roundToTwoDecimals } from '../utils/utils';

const DetailFacturas = ({ userData, currentUser }) => {
	const TOKEN = useSelector((state) => state.auth.tokenId);

	const retrieveFiles = async () => {
		const fetchAndOpenFile = async (fileName) => {
			const existingFile = await getExistingUserFiles(TOKEN, fileName);
			if (existingFile.status === 'SUCCESS' && existingFile.data.data.length > 0) {
				const file = await getUserFiles(TOKEN, fileName);
				if (file.status === 'SUCCESS' && file.data) {
					window.open(file.data);
				}
			}
		};

		if (userData.estatus === 'emitida') {
			const fileNamePDF = `${userData.userRFC}_FACTURA_${userData.folioFiscal}.pdf`;
			const fileNameXML = `${userData.userRFC}_FACTURA_${userData.folioFiscal}.xml`;

			await fetchAndOpenFile(fileNamePDF);
			await fetchAndOpenFile(fileNameXML);
		} else {
			const fileNamePDF = `${userData.userRFC}_FACTURACANCELADA_${userData.folioFiscal}.pdf`;
			const fileNameXML = `${userData.userRFC}_FACTURACANCELADA_${userData.folioFiscal}.xml`;

			await fetchAndOpenFile(fileNamePDF);
			await fetchAndOpenFile(fileNameXML);
		}
	};

	const getStatusClassName = (status) => {
		switch (status) {
			case 'emitida': {
				return `${classes.detail_status}`;
			}
			case 'cancelada': {
				return `${classes.detail_status} ${classes.pending}`;
			}
			case 'solicitada': {
				return `${classes.detail_status} ${classes.approved}`;
			}
			default: {
				return `${classes.detail_status} ${classes.no_status}`;
			}
		}
	};

	return (
		<div className={classes.detail_wrapper}>
			<h2 className={classes.detail_element}>{userData.nombrecliente}</h2>
			<h2 className={classes.detail_element}>{userData.rfccliente}</h2>
			<h2 className={classes.detail_element}>
				${roundToTwoDecimals(userData.total)}
			</h2>
			<h2 className={classes.detail_element}>
				{formatMexicoTz(userData.createdAt) || '-'}
			</h2>
			<div className={classes.detail_element_download}>
				<h2 className={getStatusClassName(userData.estatus)}>
					{userData.estatus || 'sin estatus'}
				</h2>
			</div>

			{userData.estatus === 'emitida' ||
				userData.estatus === 'cancelación emitida' ? (
				<button
					onClick={retrieveFiles}
					className={classes.detail_element_seemore}
				>
					<FileDownloadOutlinedIcon sx={{ fontSize: 25 }} />
				</button>
			) : (
				<button
					onClick={retrieveFiles}
					className={classes.detail_element_seemore}
					disabled
				>
					<FileDownloadOutlinedIcon sx={{ fontSize: 25, color: '#b9b9b9' }} />
				</button>
			)}
		</div>
	);
};

export default DetailFacturas;
