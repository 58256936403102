import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import generals from '../../../../../../css/Generals.module.css';
import { validateEmail, validateRFC } from '../../../../../../utils/validation';
import {
	addFactura,
	addFacturaContact,
	editFactura,
} from '../../../../../api/facturas';
import ClickOutsideReact from '../../../../../common/Clickoutside';
import Loading from '../../../../../common/Loading';
import classes from '../css/FacturaModal.module.css';
import FacturaModalNewContactForm from './FacturaModalNewContactForm';
import FacturaModalNewFacturaForm from './FacturaModalNewFacturaForm';

const FacturaModalFormContainer = ({
	selectedContact,
	setContactModal,
	setRefetch,
}) => {
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);
	const { userRFC } = useSelector((state) => state.clientStatus);

	const initialState = {
		userId: USERID,
		userRFC: userRFC,
		foreigner:
			selectedContact.extranjero === 'extranjero'
				? selectedContact.extranjero
				: false,
		idfiscal: selectedContact.identificacionfiscal || '',
		country: selectedContact.pais || '',
		clientName: selectedContact.nombrecliente || '',
		clientZipCode: selectedContact.cpcliente || '',
		clientEmail: selectedContact.emailcliente || '',
		clientRFC: selectedContact.rfccliente || '',
		regimenFiscal: selectedContact.regimenfiscal || '',
		description: selectedContact.descripciondetallada || '',
		CFDI: selectedContact.usocfdi || '',
		total: selectedContact.total || '',
		currency: selectedContact.moneda || '',
		paymentForm: selectedContact.formadepago || '',
		paymentMethod: selectedContact.metododepago || '',
		idfactura: selectedContact.idfactura || '',
		concepto: selectedContact?.concepto
			? JSON.parse(selectedContact?.concepto)
			: {},
		impuestos: selectedContact?.impuestos
			? JSON.parse(selectedContact?.impuestos)
			: {},
	};

	const [facturaContact, setFacturaContact] = useState(initialState);
	const [factura, setFactura] = useState(selectedContact.rfccliente);
	const [loading, setLoading] = useState(false);
	const [foreigner, setForeigner] = useState(initialState.foreigner);
	const [isEmailValid, setIsEmailValid] = useState(true);
	const [isRFCValid, setIsRFCValid] = useState(true);
	const [isNombreClienteValid, setIsNombreClienteValid] = useState(true);
	const [isCpClienteValid, setIsCpClienteValid] = useState(true);
	const [isRegimenFiscalClienteValid, setIsRegimenFiscalClienteValid] = useState(true);
	const [isSubmitted, setIsSubmitted] = useState(false);

	const [error, setError] = useState({ state: false, message: '' });

	const [contactSuccess, setContactSuccess] = useState({
		state: false,
		message: '',
	});

	const [facturaSuccess, setFacturaSuccess] = useState({
		state: false,
		message: '',
	});

	const handleContactSubmit = async (e) => {
		e.preventDefault();

		if (foreigner) {
			if (!facturaContact.idfiscal || !facturaContact.country) {
				setError({
					state: true,
					title: 'Ocurrió un error con tu solicitud.',
					message: 'Favor de llenar todos los campos solicitados.',
				});
				setIsSubmitted('contact');
				return;
			}
		}
		let validEmail = false;
		let validRFC = false;

		if (facturaContact.clientEmail) {
			validEmail = validateEmail(facturaContact.clientEmail);
			setIsEmailValid(validEmail);
		} else {
			setError({
				state: true,
				title: 'Ocurrió un error con tu solicitud.',
				message: 'Favor de llenar todos los campos solicitados.',
			});
			setIsSubmitted('contact');
			return;
		}

		if (facturaContact.clientRFC) {
			validRFC = validateRFC(facturaContact.clientRFC);
			setIsRFCValid(validRFC);
		} else {
			setError({
				state: true,
				title: 'Ocurrió un error con tu solicitud.',
				message: 'Favor de llenar todos los campos solicitados.',
			});
			setIsSubmitted('contact');
			return;
		}

		if (
			validRFC &&
			validEmail &&
			facturaContact.clientName &&
			facturaContact.clientZipCode
		) {
			setLoading(true);
			try {
				const response = await addFacturaContact(TOKEN, facturaContact);
				if (response.status === 200) {
					setIsSubmitted('contact');
					setLoading(false);
					setContactSuccess({ state: true, msg: '' });
					setRefetch((prevState) => !prevState);
				} else {
					setIsSubmitted('contact');
					setLoading(false);
					setError({
						state: true,
						title: 'Ocurrió un error con tu solicitud.',
						message: 'Algo salió mal. Favor de volver a intentar más tarde.',
					});
				}
			} catch (error) {
				console.error(error);
				setIsSubmitted('contact');
				setLoading(false);
				setError({
					state: true,
					title: 'Ocurrió un error con tu solicitud.',
					message: 'Algo salió mal. Favor de volver a intentar más tarde.',
				});
			}
		} else {
			setIsSubmitted('contact');
		}
	};

	const getNestedField = (obj, path) => {
		return path.split('.').reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj);
	};

	const validateRequiredFields = (fields, fieldPaths) => {
		for (const path of fieldPaths) {
			if (!getNestedField(fields, path)) {
				return { isValid: false, message: `Favor de llenar todos los campos solicitados.` };
			}
		}
		return { isValid: true };
	};

	const validateFields = (fields) => {
		const validations = [
			{ isValid: validateEmail(fields.clientEmail), message: 'Correo electrónico inválido.' },
			{ isValid: validateRFC(fields.clientRFC), message: 'RFC inválido.' },
		];

		for (const validation of validations) {
			if (!validation.isValid) {
				return { isValid: false, message: validation.message };
			}
		}

		return { isValid: true };
	};

	const handleAPIResponse = (response, successMessage, refetchHandler) => {
		if (response.status === 'SUCCESS') {
			refetchHandler((prevState) => !prevState);
			setError({ state: false });
			let responseMessage = response.message === '000' ? successMessage : 'Tu solicitud de factura ha sido enviada. Pronto recibirás por correo tu factura.';

			setFacturaSuccess({ state: true, message: responseMessage });
		} else {
			if (response.data?.code) {
				if (response.data.code === 'CFDI40145') setIsNombreClienteValid(false);
				if (response.data.code === 'CFDI40147') setIsCpClienteValid(false);
				if (response.data.code === 'CFDI40158') setIsRegimenFiscalClienteValid(false);
			}

			setError({
				state: true,
				title: 'Ocurrió un error con tu solicitud.',
				message: response.data?.code ? response.message : 'Inténtalo más tarde o contacta a un agenete de soporte.',
				goBack: response.data?.code ? true : false,
			});
		}
	};

	const handleFacturaSubmit = async (e) => {
		e.preventDefault();

		const requiredFields = [
			'clientName', 'clientZipCode', 'userId', 'userRFC', 'regimenFiscal',
			'CFDI', 'paymentForm', 'paymentMethod', 'currency', 'total',
			'concepto.codigoProducto', 'concepto.producto', 'concepto.unidad',
			'concepto.cantidad', 'concepto.precioUnitario', 'concepto.objetoDeImpuesto',
			'concepto.importe', 'concepto.descripciondetallada'
		];

		const requiredCheck = validateRequiredFields(facturaContact, requiredFields);
		if (!requiredCheck.isValid) {
			setError({ state: true, title: 'Ocurrió un error con tu solicitud.', message: requiredCheck.message });
			setIsSubmitted('factura');
			return;
		}

		const fieldValidation = validateFields(facturaContact);
		if (!fieldValidation.isValid) {
			setError({ state: true, title: 'Ocurrió un error con tu solicitud.', message: fieldValidation.message });
			setIsSubmitted('factura');
			return;
		}

		setLoading(true);

		try {
			const response = facturaContact.idfactura
				? await editFactura(TOKEN, facturaContact, foreigner)
				: await addFactura(TOKEN, facturaContact, foreigner);

			handleAPIResponse(
				response,
				facturaContact.idfactura
					? 'Tu solicitud de factura ha sido actualizada. Pronto recibirás por correo tu factura.'
					: 'Tu factura ha sido emitida. Los archivos han sido enviados a tu correo.',
				setRefetch
			);
		} catch (error) {
			console.error(error);
			setError({
				state: true,
				title: 'Ocurrió un error con tu solicitud.',
				message: 'Algo salió mal. Favor de volver a intentar más tarde.',
			});
		} finally {
			setIsSubmitted('factura');
			setLoading(false);
		}
	};

	const handleClose = () => {
		const dialogOpen = document.querySelectorAll('div[role="dialog"]');
		const presentationOpen = document.querySelectorAll('[role="presentation"]');
		if (dialogOpen.length <= 0 && presentationOpen.length <= 0) {
			setFacturaContact(initialState);
			setFactura(false);
			setForeigner(false);
			setContactModal(false);
		}
	};

	const handleCheckbox = () => {
		setIsSubmitted(false);
		setFacturaContact((prevState) => ({
			...prevState,
			foreigner: !foreigner,
			clientRFC: !foreigner ? 'XEXX010101000' : '',
		}));
		setForeigner((prevState) => !prevState);
	};

	const cleanErr = () => {
		setError({
			state: false,
			title: '',
			message: '',
		});
		setIsEmailValid(true);
		setIsRFCValid(true);
		setIsNombreClienteValid(true);
		setIsCpClienteValid(true);
		setIsRegimenFiscalClienteValid(true);
		setIsSubmitted(false);
	};

	const handleFactura = () => {
		setFactura(true);
	};

	return (
		<div className={classes.modal_overlay}>
			<ClickOutsideReact onClickOutside={handleClose}>
				<div className={classes.modal_container}>
					<button className={classes.icon_close_wrapper} onClick={handleClose}>
						<CloseIcon sx={{ fontSize: 25, color: '#b9b9b9' }} />
					</button>
					<h1 className={classes.modal_title}>Nuevo cliente</h1>
					<h2 className={classes.modal_subtitle}>
						Llena los campos para crear tu nuevo cliente a facturar
					</h2>
					{!loading && !contactSuccess.state && !factura && (
						<FacturaModalNewContactForm
							loading={loading}
							success={contactSuccess}
							foreigner={foreigner}
							error={error}
							facturaContact={facturaContact}
							setFacturaContact={setFacturaContact}
							isEmailValid={isEmailValid}
							isRFCValid={isRFCValid}
							isSubmitted={isSubmitted}
							handleSubmit={handleContactSubmit}
							handleClose={handleClose}
							handleCheckbox={handleCheckbox}
							cleanErr={cleanErr}
						/>
					)}
					{!loading && factura && (
						<FacturaModalNewFacturaForm
							loading={loading}
							success={facturaSuccess}
							foreigner={foreigner}
							error={error}
							setError={setError}
							facturaContact={facturaContact}
							setFacturaContact={setFacturaContact}
							isEmailValid={isEmailValid}
							isRFCValid={isRFCValid}
							isNombreClienteValid={isNombreClienteValid}
							isCpClienteValid={isCpClienteValid}
							isRegimenFiscalClienteValid={isRegimenFiscalClienteValid}
							isSubmitted={isSubmitted}
							handleSubmit={handleFacturaSubmit}
							handleClose={handleClose}
							handleCheckbox={handleCheckbox}
							cleanErr={cleanErr}
						/>
					)}
					{!loading && contactSuccess.state && (
						<div className={classes.modal_info_loading_container}>
							<p className={`mt-5 ${generals.modal_success_text}`}>
								Nuevo cliente creado con éxito.
							</p>
						</div>
					)}
					{loading && (
						<div className={classes.modal_info_loading_container}>
							<Loading loading={loading} />
						</div>
					)}

					{!loading && contactSuccess.state && (
						<>
							<button className={generals.button_blue} onClick={handleFactura}>
								Continuar solicitud
							</button>
							<button
								onClick={handleClose}
								className={`my-4 ${generals.button_white}`}
							>
								Regresar
							</button>
						</>
					)}
				</div>
			</ClickOutsideReact>
		</div>
	);
};
export default FacturaModalFormContainer;
