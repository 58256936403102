import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import $ from 'jquery';
import { default as React } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import {
	formatMexicoTz,
	roundToTwoDecimals,
} from '../../../../../../utils/format';
import { getExistingUserFiles, getUserFiles } from '../../../../../api/userfiles';
import styles from '../css/FacturaItem.module.css';

const FacturaItem = ({ factura, setModalInfo, setModalClone }) => {
	const TOKEN = useSelector((state) => state.auth.tokenId);

	const retrieveFiles = async (e) => {
		e.stopPropagation();
		const fetchAndOpenFile = async (fileName) => {
			const existingFile = await getExistingUserFiles(TOKEN, fileName);
			if (existingFile.status === 'SUCCESS' && existingFile.data.data.length > 0) {
				const file = await getUserFiles(TOKEN, fileName);
				if (file.status === 'SUCCESS' && file.data) {
					window.open(file.data);
				}
			}
		};

		if (factura.estatus === 'emitida') {
			const fileNamePDF = `${factura.userRFC}_FACTURA_${factura.folioFiscal}.pdf`;
			const fileNameXML = `${factura.userRFC}_FACTURA_${factura.folioFiscal}.xml`;

			await fetchAndOpenFile(fileNamePDF);
			await fetchAndOpenFile(fileNameXML);
		} else {
			const fileNamePDF = `${factura.userRFC}_FACTURACANCELADA_${factura.folioFiscal}.pdf`;
			const fileNameXML = `${factura.userRFC}_FACTURACANCELADA_${factura.folioFiscal}.xml`;

			await fetchAndOpenFile(fileNamePDF);
			await fetchAndOpenFile(fileNameXML);
		}
	};

	const getStatusClassName = (status) => {
		switch (status) {
			case 'emitida': {
				return `${styles.detail_status}`;
			}
			case 'cancelada': {
				return `${styles.detail_status} ${styles.pending}`;
			}
			case 'solicitada': {
				return `${styles.detail_status} ${styles.approved}`;
			}
			default: {
				return `${styles.detail_status} ${styles.no_status}`;
			}
		}
	};

	const getStatusType = (status) => {
		switch (status) {
			case 'emitida':
				return 'Factura emitida';
			case 'cancelada':
				return 'Solicitud de cancelación';
			case 'cancelación emitida':
				return 'Factura cancelada';
			case 'solicitada':
				return 'Solicitud de factura';
			default:
				return 'Factura emitida';
		}
	};

	const handleOpenModalInfo = (e) => {
		e.stopPropagation();
		$('body').css('overflow', 'hidden');
		setModalInfo({
			state: true,
			fields: [
				{ field: 'Folio fiscal', value: factura.folioFiscal },
				{ field: 'RFC del usuario', value: factura.userRFC },
				{ field: 'Extranjero', value: factura.extranjero },
				{ field: 'País', value: factura.pais },
				{ field: 'Identificación fiscal', value: factura.identificacionfiscal },
				{
					field: 'Nombre / Razón social del cliente',
					value: factura.nombrecliente,
				},
				{ field: 'RFC del cliente', value: factura.rfccliente },
				{ field: 'Email del cliente', value: factura.emailcliente },
				{ field: 'Código Postal del cliente', value: factura.cpcliente },
				{ field: 'Régimen fiscal', value: factura.regimenfiscal },
				{ field: 'Forma de pago', value: factura.formadepago },
				{ field: 'Uso CFDI', value: factura.usocfdi },
				{ field: 'Descripción', value: factura.descripciondetallada },
				{ field: 'Método de pago', value: factura.metododepago },
				{ field: 'Total', value: `$${roundToTwoDecimals(factura.total)}` },
				{ field: 'Moneda', value: factura.moneda },
				{ field: 'Estatus', value: factura.estatus },
				{
					field: 'Fecha',
					value: formatMexicoTz(factura.updatedAt || factura.createdAt),
				},
			],
			retrieveFiles: retrieveFiles,
			factura: factura,
		});
	};

	const handleCloneFactura = (e) => {
		e.stopPropagation();
		$('body').css('overflow', 'hidden');
		setModalClone({
			state: true,
			fields: [
				{ field: 'RFC del usuario', value: factura.userRFC },
				{
					field: 'Extranjero',
					value: factura.extranjero === 'extranjero' ? 'Sí' : 'No',
				},
				{ field: 'País', value: factura.pais ? factura.pais : 'México' },
				{ field: 'Identificación fiscal', value: factura.identificacionfiscal },
				{
					field: 'Nombre / Razón social del cliente',
					value: factura.nombrecliente,
				},
				{ field: 'RFC del cliente', value: factura.rfccliente },
				{ field: 'Email del cliente', value: factura.emailcliente },
				{ field: 'Código Postal del cliente', value: factura.cpcliente },
				{ field: 'Régimen fiscal', value: factura.regimenfiscal },
				{ field: 'Forma de pago', value: factura.formadepago },
				{ field: 'Uso CFDI', value: factura.usocfdi },
				{ field: 'Descripción', value: factura.descripciondetallada },
				{ field: 'Método de pago', value: factura.metododepago },
				{ field: 'Total', value: `$${roundToTwoDecimals(factura.total)}` },
				{ field: 'Moneda', value: factura.moneda },
				{
					field: 'Fecha de solicitud',
					value: formatMexicoTz(new Date()),
				},
			],
			factura: factura,
		});
	};

	return (
		<div
			onClick={handleOpenModalInfo}
			className={`mb-[7px] ${styles.factura_item}`}
		>
			<button className={`${styles.factura_item_status_wrapper}`}>
				<h2 className={getStatusClassName(factura.estatus)}>
					{getStatusType(factura.estatus)}
				</h2>
			</button>
			<div className={`${styles.factura_item_body_wrapper}`}>
				<div className={`${styles.factura_item_text_wrapper}`}>
					<p className={`${styles.factura_item_body_title}`}>
						{factura.rfccliente}
					</p>
					<p className={`${styles.factura_item_body_date}`}>
						{formatMexicoTz(factura.updatedAt)}
					</p>
					<p className={`${styles.factura_item_body_text}`}>
						${roundToTwoDecimals(factura.total) || '0.00'}
					</p>
					<p
						className={`${styles.factura_item_body_text} ${styles.showOnLargeScreen}`}
					>
						{factura.folioFiscal}
					</p>
				</div>
				{(factura.estatus === 'emitida' ||
					factura.estatus === 'cancelación emitida') && (
						<div className={styles.factura_item_icon_wrapper}>
							{factura.estatus === 'emitida' && (
								<>
									<button
										onClick={handleCloneFactura}
										className={styles.detail_element_seemore}
										data-tooltip-id='clonar-factura'
										data-tooltip-html={'<p>Clonar factura</p>'}
									>
										<ContentCopyIcon sx={{ fontSize: 25, color: '#fff' }} />
									</button>
									<Tooltip
										style={{
											maxWidth: '200px',
											fontSize: '1rem',
											zIndex: 1000,
										}}
										id='clonar-factura'
										place='top'
										effect='solid'
										openOnClick={false}
									/>
								</>
							)}
							<button
								onClick={retrieveFiles}
								className={styles.detail_element_seemore}
								data-tooltip-id='descargar-factura'
								data-tooltip-html={'<p>Descargar factura</p>'}
							>
								<FileDownloadOutlinedIcon sx={{ fontSize: 25, color: '#fff' }} />
							</button>
							<Tooltip
								style={{
									maxWidth: '200px',
									fontSize: '1rem',
									zIndex: 1000,
								}}
								id='descargar-factura'
								place='top'
								effect='solid'
								openOnClick={false}
							/>
							{factura.estatus === 'emitida' && (
								<>
									<button
										onClick={handleOpenModalInfo}
										className={styles.detail_element_seemore}
										data-tooltip-id='cancelar-factura'
										data-tooltip-html={'<p>Cancelar factura</p>'}
									>
										<DoNotDisturbAltIcon sx={{ fontSize: 25, color: '#fff' }} />
									</button>
									<Tooltip
										style={{
											maxWidth: '200px',
											fontSize: '1rem',
											zIndex: 1000,
										}}
										id='cancelar-factura'
										place='top'
										effect='solid'
										openOnClick={false}
									/>
								</>
							)}
						</div>
					)}
			</div>
		</div>
	);
};

export default FacturaItem;
